import React, { useContext } from "react";
import { MyLimitsRequest, MyLimitsSingleRequest } from "./";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import { MyLimitsStoreCategoriesNullChecked } from "@v2/state/myLimits/MyLimitsStore";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const MyLimitsRequestList = observer(function MyLimitsRequestList() {
	const myLimitsStore = useContext(
		MyLimitsStoreContext
	) as MyLimitsStoreCategoriesNullChecked;

	const { t } = useTranslation();

	if (myLimitsStore.category?.requests.length === 0) {
		return null;
	}

	return (
		<div className="card--primary u-mar--bottom--big">
			<div className="card--primary__header u-padd--xsml">
				<div className="card--primary__title title--secondary u-mar--bottom--reset u-type--text--center">
					{t<string>(
						"USER.ACCOUNT_SETTINGS.MY_LIMITS.UPDATE_REQUESTS_TITLE"
					)}
				</div>
			</div>

			<div className="card--primary__body u-padd--reset">
				<div className="row row--no-gutters">
					<MyLimitsRequestMode />
				</div>
			</div>
		</div>
	);
});

const MyLimitsRequestMode = observer(function MyLimitsRequestMode() {
	const myLimitsStore = useContext(
		MyLimitsStoreContext
	) as MyLimitsStoreCategoriesNullChecked;
	if (myLimitsStore.category.isMultiLimitModeEnabled) {
		return (
			<>
				{myLimitsStore.category.requests.map((request, index) => (
					<MyLimitsRequest {...request} key={index} />
				))}
			</>
		);
	}

	return <MyLimitsSingleRequest />;
});

export default MyLimitsRequestList;
