import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

export default observer(function TeamName(props: {
	team?: { name: string; gender?: number };
	isInAdditionalOffer?: boolean;
	isEventTitle?: boolean;
}) {
	const t = useTranslation().t;
	if (props.team == null) {
		return null;
	}

	const teamClasses = classNames({
		offer__team__name: !props.isInAdditionalOffer && !props.isEventTitle,
		event__teams__name: props.isInAdditionalOffer,
		event__competitor__name:
			!props.isInAdditionalOffer && props.isEventTitle,
	});

	if (props.team.gender === 1) {
		return (
			<span className={teamClasses}>
				{props.team.name} ({t("OFFER.GENDER.WOMEN")})
			</span>
		);
	}

	return <span className={teamClasses}>{props.team.name}</span>;
});
