import React from "react";
import { LazyImportWithLoadFailHandle } from "./index";

export function ReactLazyImportWithGuard<T extends React.ComponentType<any>>(
	errorPath: string,
	factory: () => Promise<{
		default: T;
	}>
) {
	return React.lazy(() => LazyImportWithLoadFailHandle(errorPath, factory));
}
