import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import { useNotification } from "@v2/hooks/shared";
import { ResultsMenuSport } from "./";
import { useLoading } from "@hooks";
import {
	ResultsMenuSportContext,
	ResultsMenuStoreContext,
} from "@context/results";

export const ResultsMenu = function ResultsMenu() {
	const { setIsLoading } = useLoading();

	const { showError } = useNotification();

	const store = useContext(ResultsMenuStoreContext);

	useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			try {
				await store.onInitialize();
			} catch (error) {
				showError(error);
				setIsLoading(false);
			}
		}

		fetchData();
	}, []);

	return (
		<ul id="results-menu" className="nav--secondary__menu">
			<ResultMenuSportList />
		</ul>
	);
};

const ResultMenuSportList = observer(function ResultMenuSportList() {
	const { resultMenu } = useContext(ResultsMenuStoreContext);

	if (resultMenu == null) {
		return null;
	}

	return (
		<>
			{resultMenu.map((sport) => (
				<ResultsMenuSportContext.Provider key={sport.id} value={sport}>
					<ResultsMenuSport />
				</ResultsMenuSportContext.Provider>
			))}
		</>
	);
});
