import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, FormButton } from "@v2/components/desktop/shared/buttons";
import { AcceptNewTermsError, TermsAndConditionsSection } from ".";
import { useAcceptNewTerms } from "@v2/hooks/membership";
import { CheckboxField } from "../../shared/inputs";
import { useLoading } from "@hooks";
import { setTabTitle } from "@utils";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default function AcceptNewTerms() {
	const {
		onSuccess,
		logoutUser,
		acceptNewTermsError,
		setAcceptNewTermsError,
		getTermsEffectiveDate,
	} = useAcceptNewTerms();

	const { handleSubmit } = useFormContext();
	const { t } = useTranslation();
	const { setIsLoading } = useLoading();

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.ACCEPT_NEW_TERMS.TOC_HEAD"));
		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
		setIsLoading(false);

		return () => setAcceptNewTermsError(false);
	}, []);

	if (acceptNewTermsError != null) {
		return (
			<AcceptNewTermsError
				acceptNewTermsError={acceptNewTermsError}
				setAcceptNewTermsError={setAcceptNewTermsError}
			/>
		);
	}

	return (
		<>
			<form
				onSubmit={handleSubmit(onSuccess)}
				className="card--primary view--info view--info--terms"
			>
				<div className="card--primary__header">
					<h1 className="card--primary__title title--primary">
						{t<string>(
							"MEMBERSHIP.ACCEPT_NEW_TERMS.NEW_TERMS_TITLE"
						)}
					</h1>
				</div>

				<div className="card--primary__body">
					<InfoMessageComponent
						type="note"
						message={t<string>(
							"MEMBERSHIP.ACCEPT_NEW_TERMS.UPDATE_TERMS_DESCRIPTION",
							{
								interpolation: { escapeValue: false },
								date: getTermsEffectiveDate(),
							}
						)}
						addClassName="u-mar--bottom--big"
					/>

					<TermsAndConditionsSection />

					<CheckboxField
						label="MEMBERSHIP.ACCEPT_NEW_TERMS.AGREE_ON_TERMS"
						name="acceptNewTerms"
						checkboxClassname="u-mar--top--big"
						id="acceptNewTerms"
						required
					/>
				</div>

				<div className="card--primary__footer">
					<div className="card--primary__footer__inner u-type--left">
						<FormButton
							className="card--primary__footer__btn btn btn--lrg btn--primary"
							btnText="MEMBERSHIP.ACCEPT_NEW_TERMS.BUTTON_ACCEPT"
						/>
						<Button
							className="card--primary__footer__btn btn btn--lrg btn--secondary--inverted"
							btnText="MEMBERSHIP.ACCEPT_NEW_TERMS.BUTTON_CANCEL"
							type="button"
							onClick={logoutUser}
						/>
					</div>
				</div>
			</form>
		</>
	);
}
