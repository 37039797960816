import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { PasswordChangeFormSection } from "./components";
import { usePasswordChange } from "@v2/hooks/account-settings/password-change";

export function PasswordChangePage() {
	const {
		onInitialize,
		onSubmitData,
		userInfo,
		methods,
		activeAccount,
		setActiveAccount,
	} = usePasswordChange();

	useEffect(() => {
		onInitialize();
	}, [activeAccount]);

	return (
		<div className="l--content__main">
			<div className="container">
				<FormProvider {...methods}>
					<PasswordChangeFormSection
						onSubmitData={onSubmitData}
						activeAccount={activeAccount}
						setActiveAccount={setActiveAccount}
					/>
				</FormProvider>
			</div>
		</div>
	);
}
