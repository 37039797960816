import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useFormState } from "react-hook-form";
import classNames from "classnames";
import { get } from "lodash";
import { RequiredMark, ErrorMessage } from ".";

const TextareaField = (props) => {
	const { t } = useTranslation();

	const {
		label,
		name,
		className,
		errorMessage = undefined,
		type = "text",
		required = false,
		placeholder = undefined,
		astersisksClass = null,
		...rest
	} = props;

	const { register } = useFormContext();
	const { errors } = useFormState();
	let error = get(errors, name);

	const field = register(props.name, {
		required: {
			value: props.required,
			message: props.errorMessage || "COMMON.REQUIRED_FIELD",
		},
	});

	const textAreaClass = classNames("input input--base input--textarea", {
		"input-validation-error": error != undefined,
	});

	return (
		<div className={className ? className : "col col-sml-12 form__field"}>
			<label htmlFor={name} className="label">
				{t(label)}
				<RequiredMark required={required} />
			</label>
			<ErrorMessage name={name} />
			<textarea
				className={textAreaClass}
				placeholder={t(placeholder)}
				name={name}
				{...rest}
				{...field}
			></textarea>
		</div>
	);
};
export default TextareaField;
