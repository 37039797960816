import { Preferences } from "@capacitor/preferences";
import { StorageStateKeysEnum, ILocalStorageProvider } from ".";

type StorageStateType = { [key in `${StorageStateKeysEnum}`]: string | null };

export default class CapacitorStorage implements ILocalStorageProvider {
	constructor() {
		window.addEventListener("storage", this.storageActionListener, {});
	}

	private storageState: StorageStateType = {
		[StorageStateKeysEnum.USER_KEY]: null,
		[StorageStateKeysEnum.USER_TOKEN]: null,
		[StorageStateKeysEnum.SECONDARY_USER]: null,
		[StorageStateKeysEnum.SECONDARY_TOKEN]: null,
		[StorageStateKeysEnum.ACCOUNT_ACTIVATION]: null,
		[StorageStateKeysEnum.ACC]: null,
		[StorageStateKeysEnum.REALITY_CHECK_EXPIRATION]: null,
		[StorageStateKeysEnum.AUTO_LOGOUT_PERIOD]: null,
		[StorageStateKeysEnum.DSV]: null,
		[StorageStateKeysEnum.TOKEN_EXPIRATION_LAST_CHANGE]: null,
		[StorageStateKeysEnum.SECONDARY_TOKEN_EXPIRATION_DATE]: null,
		[StorageStateKeysEnum.LOGIN_HISTORY]: null,
		[StorageStateKeysEnum.GIG_TOKEN]: null,
		[StorageStateKeysEnum.USER_ACCOUNTS]: null,
		[StorageStateKeysEnum.USER_MATCHING]: null,
		[StorageStateKeysEnum.UDC]: null,
		[StorageStateKeysEnum.CONFIRMATION_DATA]: null,
		[StorageStateKeysEnum.SLN]: null,
		[StorageStateKeysEnum.SESSION_EXPIRATION_TIME]: null,
		[StorageStateKeysEnum.GS]: null,
		[StorageStateKeysEnum.WELCOME_POPUP]: null,
		[StorageStateKeysEnum.THEME]: null,
		[StorageStateKeysEnum.USER_DATA_TO_BE_CONFIRMED]: null,
		[StorageStateKeysEnum.MITID_LOGIN_HASH]: null,
		[StorageStateKeysEnum.ONBOARDING_REGISTER_POPUP]: null,
		[StorageStateKeysEnum.ONBOARDING_NEWSLETTER_POPUP]: null,
		[StorageStateKeysEnum.ONBOARDING_WELCOME_POPUPS]: null,
		[StorageStateKeysEnum.DECLINED_CAP_UPDATE]: null,
		[StorageStateKeysEnum.AFC]: null,
		[StorageStateKeysEnum.MOBILE_APP_POPUP_DISPLAY]: null,
		[StorageStateKeysEnum.CHAT_BOX_VISIBLE]: null,
	};

	private storageActionListener = async (event: StorageEvent) => {
		if (event.key == null) {
			return;
		}
		const keyValue = await this.getFromCapacitor(
			event.key as StorageStateKeysEnum
		);
		this.set(event.key as StorageStateKeysEnum, keyValue);
	};

	private getFromCapacitor = async (
		key: StorageStateKeysEnum
	): Promise<string | null> => {
		const result = await Preferences.get({ key });
		return result.value;
	};

	private getCurrentLocalStorageKeys = async (): Promise<string[]> => {
		const result = await Preferences.keys();
		return result?.keys || [];
	};

	initialLoad = async () => {
		const currentLocalStorage = await this.getCurrentLocalStorageKeys();
		await Promise.all(
			currentLocalStorage.map(async (key: StorageStateKeysEnum) => {
				const keyValue = await this.getFromCapacitor(key);
				//key value is stringified or null
				this.storageState[key] = keyValue;
			})
		);
	};

	/**
	 *
	 * @param key StorageStateKeysEnum
	 * @desc parses value from local storage state that are stringified
	 * @returns unknown value
	 */
	get(key: StorageStateKeysEnum): unknown {
		const value = this.storageState[key];
		if (!value) {
			return this.storageState[key];
		}
		return JSON.parse(value);
	}

	/**
	 *
	 * @param key StorageStateKeysEnum
	 * @param value any value
	 * @desc sets (value is stringifed) or removes value from local storage state
	 * @returns void
	 */
	set = (key: StorageStateKeysEnum, value: any): void => {
		if (value == null || value == "") {
			this.storageState[key] = null;
			Preferences.remove({ key });
			return;
		}
		this.storageState[key] = JSON.stringify(value);
		//need to stringify value always due to preferences not accepting anything other than string value
		Preferences.set({ key, value: JSON.stringify(value) });
	};

	remove = (key: StorageStateKeysEnum): void => {
		Preferences.remove({ key });
		this.storageState[key] = null;
	};

	flush = (): void => {
		Preferences.clear();
	};
}
