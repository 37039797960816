import React from "react";
import classNames from "classnames";
const RequiredMark = (props: {
	required?: boolean;
	astersisksClass?: string;
}) => {
	const { required, astersisksClass } = props;

	if (!required) {
		return null;
	}

	const asterisksClassName = classNames(
		"form__field__required",
		astersisksClass
	);

	return <span className={asterisksClassName}>*</span>;
};

export default RequiredMark;
