import React, { useEffect, useContext, useState } from "react";
import ReactDOM from "react-dom";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
	OfferStoreContext,
	LookupContext,
	SportOfferContext,
	EventContext,
	OfferOptionsContext,
} from "@gp/components";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import { GroupedEventOffer } from "../grouped-event-offer";

import {
	ChatButton,
	LiveStreamToggleButton,
	StatisticsButton,
	TvCoverageButton,
	WidgetToggleButton,
} from "../buttons";
import { EventTime } from "../details";

import { AdditionalOfferViewStore } from "../../../../state/offer/components/AdditionalOfferViewStore";
import { additionalOfferClose } from "@offer/common";

import FavoritesButton from "../buttons/FavoritesButton";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import useAdditionalOffer from "./useAdditionalOffer";
import EventInfo from "./AdditionalOfferEventInfo";
import { EventOffer, SportOffer } from "@gp/offer";
import AddOfferMyBetsToggleButton from "../buttons/AddOfferMyBetsToggleButton";
import AddOfferGroupedEventOffer from "../grouped-event-offer/AddOfferGroupedEventOffer";
import { AddOfferMyBetsTab } from "../common/myBetsAdditionalOffer/AddOfferMyBetsTab";
import { ChannelResourceTypeDto } from "@api-types/chat";
import { useRootOfferStore } from "@hooks";
import {
	LiveStreamConfigWeb,
	LiveWidgetContainer,
} from "@features/live-widgets/shared";

const AdditionalOffer = observer(function AdditionalOffer(props: {
	type?: "prematch" | "live";
}) {
	const parentOfferStore = useContext(OfferStoreContext);
	const favoritesStore = useContext(FavoritesStoreContext);
	const { viewStore, isThereOfferToDisplay, containerElement } =
		useAdditionalOffer({
			type: props.type,
			parentOfferStore,
			favoritesStore,
		});

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const isButtonToggled = params.get("mybets") === "true" ? true : false;

		if (isButtonToggled) {
			viewStore.setToggleButton(isButtonToggled);
		}
	}, []);

	if (!isThereOfferToDisplay || containerElement == null) {
		return null;
	}

	const options = {
		isLive: false,
	};

	return ReactDOM.createPortal(
		<OfferStoreContext.Provider value={viewStore}>
			<LookupContext.Provider value={viewStore.lookupsStore}>
				<OfferOptionsContext.Provider value={options}>
					<Core store={viewStore} />
				</OfferOptionsContext.Provider>
			</LookupContext.Provider>
		</OfferStoreContext.Provider>,
		containerElement
	);
});

const Core = observer(function Core(props: {
	store: AdditionalOfferViewStore;
}) {
	if (props.store.isLoading) {
		return (
			<div className="event event--loading">
				<div className="loader">
					<div className="loader__spinner loader__spinner--primary" />
				</div>
			</div>
		);
	}

	return (
		<>
			{props.store.orderedEvents.map((event) => (
				<SportOfferContext.Provider
					key={event.sport.id + event.id}
					value={event.sport}
				>
					<EventContext.Provider value={event}>
						<Event />
					</EventContext.Provider>
				</SportOfferContext.Provider>
			))}
		</>
	);
});

const Event = observer(function Event() {
	const { addWidget, removeWidget, findActiveWidget } = useLiveWidgetStore();

	const { t } = useTranslation();
	const sport = useContext(SportOfferContext) as SportOffer;
	const event = useContext(EventContext) as EventOffer;
	const store = useContext(OfferStoreContext) as AdditionalOfferViewStore;
	const rootOfferStore = useRootOfferStore();

	const teamOneName =
		store.lookupsStore.teams.get(event.teamOneId || "")?.name || "";
	const teamTwoName =
		store.lookupsStore.teams.get(event.teamTwoId || "")?.name || "";
	const matchId = event.source?.matchId ? event.source.matchId : null;
	const isActive = findActiveWidget(event.id, "EVENT_OFFER");

	useEffect(() => {
		// widget should be displayed only for BR and live events
		if (matchId == null || !event.isLive) {
			return;
		}

		addWidget(
			event.source!.providerName,
			event.liveStreamStatus,
			matchId,
			event.id,
			teamOneName,
			teamTwoName,
			"SIDE",
			sport.abrv
		);
		return () => {
			removeWidget(event.id, "EVENT_OFFER");
		};
	}, []);

	return (
		<div
			className={classNames("event", {
				"event--live": event.isLive,
				"event--upcoming": event.isUpcoming,
			})}
		>
			<div className="event__header">
				<div className="event__header__info">
					<div className="u-display--f u-display--f--row">
						{event.hasLiveCoverage && (
							<span className="event__header__tag tag tag--med tag--primary">
								{t<string>("COMMON.LIVE")}
							</span>
						)}
						<EventTime />
					</div>

					<div className="event__actions">
						<FavoritesButton />
						<StatisticsButton />
						<TvCoverageButton />
						<WidgetToggleButton />
						<LiveStreamToggleButton />
						<ChatButton
							channelType={ChannelResourceTypeDto.EVENT}
							resourceId={event.id}
						/>
						<AddOfferMyBetsToggleButton />
					</div>
				</div>
				<button
					className="btn btn--square btn--square--xtny btn--secondary--inverted event__close"
					type="button"
					onClick={() => {
						additionalOfferClose();
						rootOfferStore.myBetsViewStore.cashoutStore.disposeSubscription(
							event.id
						);
					}}
				>
					<i className="u-icon u-icon--xsml u-icon--close" />
				</button>
			</div>
			<div className="event__body">
				<EventInfo event={event} sport={sport} store={store} />

				<div
					className={classNames("event__stream", {
						"is-active": isActive,
						"is-inactive": !isActive,
					})}
				>
					<LiveWidgetContainer
						eventId={event.id}
						location="EVENT_OFFER"
						lmtProps={{
							divProps: {
								className:
									"event__stream__widget widget--stream",
							},
						}}
						lsProps={{
							divProps: {
								className:
									"event__stream__widget widget--stream is-live",
							},
							config: LiveStreamConfigWeb,
						}}
					/>
				</div>

				{/* OFFER FILTER */}
				<AddOfferGroupedEventOffer />
				<AddOfferMyBetsTab />
			</div>
		</div>
	);
});

export default AdditionalOffer;
