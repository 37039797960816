import React from "react";
import classNames from "classnames";
import { WidgetLocation } from "./models/LiveWidgetTypes";
import { DeviceDetector } from "@utils";

export function MessageContainer(props: {
	message: string | null | undefined;
	isError: boolean;
	location: WidgetLocation;
}) {
	const { message, isError, location } = props;

	const messageClasses = classNames("message", {
		"message--base": location != "SIDE" && !DeviceDetector.isMobileTheme,
		"message--sml": location == "SIDE" || DeviceDetector.isMobileTheme,
		"message--note": !isError,
		"message--danger": isError,
	});

	const iconClasses = classNames("message__icon u-icon", {
		"u-icon--lrg": location != "SIDE" && !DeviceDetector.isMobileTheme,
		"u-icon--big": location == "SIDE" || DeviceDetector.isMobileTheme,
		"u-icon--info-circle u-color--ui--note": !isError,
		"u-icon--remove-circle u-color--ui--danger": isError,
	});

	return (
		<div className={messageClasses}>
			<i className={iconClasses} />
			<div className="message__content">{message}</div>
		</div>
	);
}
