import React from "react";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import { ChatContainer } from "@features/chat/desktop";

export default observer(function ChatWrapper() {
	const { chatViewStore } = useRootAppStore();

	if (!chatViewStore.chatWidgetVisible) {
		return null;
	}

	return <ChatContainer />;
});
