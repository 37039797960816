import React from "react";
import { useTranslation } from "react-i18next";
import {
	LoginErrorCodes,
	LoginErrorResponseModel,
} from "../../../../../data/types";

import InfoMessageComponent from "@components-shared/InfoMessageComponent";

class LoginErrorPropType {
	loginError: LoginErrorResponseModel | null;
}

export default function LoginErrorMessageTemplate(props: LoginErrorPropType) {
	const { t } = useTranslation();
	const { loginError } = props;
	if (loginError == null) {
		return null;
	}

	if (
		[
			LoginErrorCodes.CPRNumberNotRegistered,
			LoginErrorCodes.CPRNumberVerificationError,
			LoginErrorCodes.playerTooYoung,
			LoginErrorCodes.spelpausGamingExclusionActive,
			LoginErrorCodes.lugasPlayerNotRegistered,
			LoginErrorCodes.lugasUnspecified,
			LoginErrorCodes.lugasValidationError,
		].includes(loginError?.errorCode as LoginErrorCodes)
	) {
		return (
			<div className="form__group">
				<div className="row">
					<div className="col col-sml-12">
						<InfoMessageComponent
							type="danger"
							message={t<string>(loginError?.message!)}
						/>
					</div>
				</div>
			</div>
		);
	} else if (loginError?.errorCode == LoginErrorCodes.temporaryLockCode) {
		return (
			<div className="form__group">
				<div className="row">
					<div className="col col-sml-12">
						<InfoMessageComponent
							type="danger"
							message={t<string>(
								`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.message}`,
								{
									0: loginError?.errorDuration,
									1: loginError?.errorFailedAttempts,
								}
							)}
						/>
					</div>
				</div>
			</div>
		);
	} else if (
		loginError?.errorCode == LoginErrorCodes.temporaryLockedWithDurationCode
	) {
		return (
			<div className="form__group">
				<div className="row">
					<div className="col col-sml-12">
						<InfoMessageComponent
							type="danger"
							message={t<string>(
								`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.message}`,
								{
									0: loginError?.errorFailedAttempts,
									1: loginError?.errorDuration,
								}
							)}
						/>
					</div>
				</div>
			</div>
		);
	} else if (
		loginError?.errorCode == LoginErrorCodes.dataConfirmationRequiredCode
	) {
		return (
			<div className="form__group">
				<div className="row">
					<div className="col col-sml-12">
						<InfoMessageComponent
							type="danger"
							message={t<string>(
								`USER.POPUPS.DATA_CONFIRMATION.USER_DATA_CONFIRMATION_REQUIRED`,
								{
									0: loginError?.message,
								}
							)}
						/>
					</div>
				</div>
			</div>
		);
	} else if (loginError?.errorCode != null) {
		if (loginError.errorCode === LoginErrorCodes.userLocked) {
			return (
				<div className="form__group">
					<div className="row">
						<div className="col col-sml-12">
							<InfoMessageComponent
								type="danger"
								message={t<string>(
									`specific:MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.message}`
								)}
								isMarkdown={true}
							/>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="form__group">
				<div className="row">
					<div className="col col-sml-12">
						<InfoMessageComponent
							type="danger"
							message={t<string>(
								`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.message}`
							)}
						/>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="form__group">
			<div className="row">
				<div className="col col-sml-12">
					<InfoMessageComponent
						type="danger"
						message={t<string>(
							`MEMBERSHIP.LOGIN.ERR_HANDLING.GENERIC_ERROR`
						)}
					/>
				</div>
			</div>
		</div>
	);
}
