import React from "react";
import { defaultTemplate } from "hoc";

export default defaultTemplate(function EmptyState(props) {
	const {
		styles: { wrapperStyle, iconStyle, imgStyle, imgSrc, imgAlt },
	} = props;

	const isImageAvailable =
		imgStyle != null && imgSrc != null && imgAlt != null;

	return (
		<div className={wrapperStyle}>
			{iconStyle != null && <i className={iconStyle} />}
			{isImageAvailable && (
				<div className={imgStyle}>
					<img src={imgSrc} alt={imgAlt} />
				</div>
			)}
			<div>{props.children}</div>
		</div>
	);
});
