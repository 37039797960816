import { observable, action, computed } from "mobx";
import { Period } from "@state/common/PeriodConverter";
import { TPrematchSubMenuParent as TParentMenu } from "./TPrematchSubMenuParent";

export class PrematchPeriodSubMenuStore {
	protected parentMenu: TParentMenu;

	constructor(parentMenu: TParentMenu) {
		this.parentMenu = parentMenu;
	}

	periodMenu: { period: Period; name: string }[] = [
		{
			period: "today",
			name: "PREMATCH.SIDEMENU.PERIOD.TODAY",
		},
		{
			period: "3h",
			name: "PREMATCH.SIDEMENU.PERIOD.3H",
		},
		{
			period: "48h",
			name: "PREMATCH.SIDEMENU.PERIOD.48H",
		},
		{
			period: "3d",
			name: "PREMATCH.SIDEMENU.PERIOD.3D",
		},
		{
			period: "full",
			name: "PREMATCH.SIDEMENU.PERIOD.FULL",
		},
	];

	//#region observable

	@observable isPeriodMenuExpanded = false;
	@observable selectedPeriodKey: Period = "full";

	@computed get selectedPeriod() {
		return (
			this.periodMenu.find(
				(i) => i.period === this.selectedPeriodKey
			) || { period: "", name: "" }
		);
	}

	//#endregion observable

	//#region period menu setters

	@action.bound
	setPeriodKey(period: Period, shouldOpenOfferMenu: boolean = true) {
		if (this.selectedPeriodKey !== period) {
			if (
				this.periodMenu.find((item) => item.period === period) == null
			) {
				// Period is not valid
				return;
			}
			this.selectedPeriodKey = period;

			this.parentMenu.fetchData();

			this.closePeriodMenu();
			if (shouldOpenOfferMenu) {
				this.parentMenu.openOfferMenu();
			}
		}
	}

	//#endregion period menu setters

	//#region menu actions

	@action.bound
	togglePeriodMenu() {
		if (this.isPeriodMenuExpanded) {
			this.closePeriodMenu();
		} else {
			this.expandPeriodMenu();
		}
	}

	@action.bound
	closePeriodMenu() {
		this.isPeriodMenuExpanded = false;
		this.parentMenu.rootStore.closeSideMenu();
	}

	@action.bound
	expandPeriodMenu() {
		this.isPeriodMenuExpanded = true;
		this.parentMenu.isOfferMenuOpen = false;
		this.parentMenu.rootStore.openSideMenu();
	}

	//#endregion menu actions
}
