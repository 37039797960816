import { action, computed, observable } from "mobx";

import { IStoreLifeCycle, IConsoleLogger } from "@interface";
import {
	LMTWidgetElement,
	BrWidgetDefaultConfig,
	WidgetInfo,
	WidgetLocation,
	LsWidgetDefaultConfig,
} from "@features/live-widgets/shared";
import { ProviderNameEnum } from "@gp/models";

export class LiveMatchTrackerState implements IStoreLifeCycle {
	@observable public isStoreInitialized: boolean = false;

	@observable private lmtsDisabled = false;
	@observable private elements: LMTWidgetElement[] = [];
	private logger: IConsoleLogger;

	/** @returns true when there is widget on element with 'lmt-side-container' id. */
	@computed
	get sideWidgetVisible() {
		return this.isInQueue({ eventId: "", location: "SIDE" });
	}

	/** @returns callback that can check if widget is loaded for selected container element and matchId. */
	@computed
	get activeWidgets() {
		return this.elements;
	}

	@computed public get findActiveWidget() {
		return (
			eventId: string,
			location: WidgetLocation
		): LMTWidgetElement | undefined => {
			return this.activeWidgets.find(
				(w) => w.location == location && w.eventId === eventId
			);
		};
	}

	@computed public get activeSideWidget() {
		return this.activeWidgets.find((w) => w.location === "SIDE");
	}

	@computed get isEmpty(): boolean {
		return this.elements.length === 0;
	}

	//#region constructor

	constructor(logger: IConsoleLogger) {
		this.logger = logger;
	}

	@action.bound
	public onInitialize(): void {
		this.isStoreInitialized = true;
	}

	//#endregion constructor

	//#region add, remove, update widget

	/** Toggle widget pin. */
	@action.bound
	toggleWidgetPin(eventId: string, location: WidgetLocation): void {
		const widget = this.activeWidgets.find(
			(w) => w.eventId === eventId && location === location
		);
		if (widget == null) {
			return;
		}

		this.updateItem({ ...widget, isPinned: !widget.isPinned });
	}

	/** Update item for given search object if it is in queue. */
	@action.bound
	public updateItem(newItem: LMTWidgetElement): void {
		const index = this.elements.findIndex((el) =>
			this.filterPredicate(el, {
				eventId: newItem.eventId,
				location: newItem.location,
			})
		);
		if (index < 0) return;
		if (index < 0 || index > this.elements.length) {
			this.logger.logTrace("Update element on index out of bounds");
			return;
		}
		this.elements[index] = newItem;
	}

	/** @param element remove from queue. */
	@action.bound
	remove(element: WidgetInfo): void {
		if (this.isEmpty) {
			return;
		}

		const elemIdx = this.elements.findIndex((el) =>
			this.filterPredicate(el, element)
		);

		if (elemIdx > -1) {
			this.elements.splice(elemIdx, 1);
		}
	}

	/** Disable lmts and disposes all active widgets */
	@action.bound
	public toggleLMTWidget(
		sport: string,
		provider: ProviderNameEnum,
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,

		location: WidgetLocation
	): void {
		if (this.findActiveWidget(eventId, location)) {
			this.removeLMTWidget(eventId, location);
		} else {
			this.addLMTWidget(
				sport,
				provider,
				matchId,
				eventId,
				teamOneName,
				teamTwoName,
				location
			);
		}
	}

	/** Add lmt widget, removes one from queue if it is at max length */
	@action.bound
	public addLMTWidget(
		sport: string,
		provider: ProviderNameEnum,
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,
		location: WidgetLocation,
		brOptions: SIROptions = {},
		lsOptions: LSOptions = {}
	): void {
		let isError = false;
		if (this.lmtsDisabled || !matchId) {
			if (location === "SIDE") {
				this.removeLMTWidget("", "SIDE");
			}
			return;
		}

		lsOptions = Object.assign({}, LsWidgetDefaultConfig);
		brOptions = Object.assign({}, BrWidgetDefaultConfig, brOptions);

		// see: https://widgets.sir.sportradar.com/docs/Widgets.html#.onTrack
		brOptions.onTrack = (event, data) => {
			// Error check
			if (event === "license_error") {
				// in case of an error
				this.logger.logError("LMT license error", data.error);
				isError = true;
			}
		};

		if (!this.isInQueue({ eventId, location })) {
			this.elements.push({
				provider,
				type: "LMT",
				matchId,
				BRWidgetOptions: brOptions,
				LSWidgetOptions: lsOptions,
				eventId,
				location,
				teamOneName,
				teamTwoName,
				sport,
				isError,
			});
		} else {
			this.updateItem({
				provider,
				type: "LMT",
				location,
				eventId,
				matchId,
				BRWidgetOptions: brOptions,
				LSWidgetOptions: lsOptions,
				teamOneName,
				teamTwoName,
				isError,
			});
		}
	}

	/** Removes widget from location for given eventId */
	@action.bound
	public removeLMTWidget(eventId: string, location: WidgetLocation): void {
		this.remove({ eventId, location });
	}

	//#endregion  add remove widget

	//#region helpers

	@action.bound
	private filterPredicate(w: LMTWidgetElement, searchArgs: WidgetInfo) {
		return (
			(searchArgs.location === "SIDE" && w.location === "SIDE") ||
			(w.location == searchArgs.location &&
				w.eventId === searchArgs.eventId)
		);
	}

	/** @returns element is in queue based on passed predicate */
	public isInQueuePredicate(
		element: WidgetInfo,
		predicate: (a: LMTWidgetElement, b: WidgetInfo) => boolean
	): boolean {
		if (element == null || predicate == null) {
			this.logger.logError("Element or predicate are null.");
			return false;
		}

		return this.elements.findIndex((el) => predicate(el, element)) > -1;
	}

	/** @returns element is in queue based on filterPredicate given to constructor */
	public isInQueue(element: WidgetInfo): boolean {
		if (this.filterPredicate) {
			return this.isInQueuePredicate(element, this.filterPredicate);
		}

		return false;
	}

	/** @returns true if sport radar widget is available */
	public isWidgetFunctionLoaded(): boolean {
		return window.SIR !== undefined;
	}

	//#endregion helpers

	//#region disposers

	/** disposes all active widgets */
	@action.bound
	private disableLmts(): void {
		this.onDispose();
	}

	/** Remove all active widgets */
	@action.bound
	public onDispose(): void {
		this.elements = [];
		this.isStoreInitialized = false;
	}

	//#endregion disposers
}
