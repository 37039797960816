import React, { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { WithdrawalContext } from "@context";

export const SelectedWithdrawalAmount: FC = observer(
	function SelectedWithdrawalAmount() {
		const { currentFormData, paymentMethods } =
			useContext(WithdrawalContext);
		const { t } = useTranslation();
		const selectedPaymentMethod = paymentMethods?.find(
			(item) => item.paymentMethodId === currentFormData?.paymentMethodId
		);

		return (
			<>
				<label htmlFor="currentAmount" className="label">
					Selected amount
				</label>
				<div className="form__field--icon">
					<input
						type="number"
						id="currentAmount"
						className="form__field--icon__input input input--base"
						value={currentFormData?.amount}
						disabled
					/>
					<div className="form__field--icon__symbol">
						{selectedPaymentMethod?.currencyDisplayCode}
					</div>
				</div>
			</>
		);
	}
);
