import TerminalRegistrationStore from "@v2/state/terminal-registration/TerminalRegistrationStore";
import { useContext, createContext } from "react";

export const TerminalRegistrationStoreContext = createContext(
	{} as TerminalRegistrationStore
);

export const useTerminalRegistrationStore = () => {
	return useContext(TerminalRegistrationStoreContext);
};
