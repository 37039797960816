import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';


export function defaultTranslation(TemplateComponent) {
    return withTranslation()(TemplateComponent);
}

export function defaultTemplate(TemplateComponent) {
    return defaultTranslation(observer(TemplateComponent));
}