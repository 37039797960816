import React from "react";
import { observer } from "mobx-react";
import { MenuSportItemButton } from "./";
import { BaseOfferMenuStore as OfferMenuStore } from '@offer/stores/components/offer-menu/BaseOfferMenuStore'

export const MainSportsList = observer(function MainSportsList(props: {
	store: OfferMenuStore
}) {
	if (props.store.isLoading) {
		return <SkeletonLoader />;
	}

	return <>
		{props.store.menu.children.map((sportMenuItem, idx) => (
			<MenuSportItemButton
				key={sportMenuItem.node.id}
				sportMenuItem={sportMenuItem}
			/>
		))}
	</>
});

function SkeletonLoader() {
	return (
		<div className="loader--skeleton loader--skeleton--content loader--skeleton--nav-secondary">
			{(new Array(11)).fill(1).map((_, i) => (
				<div key={i} className="loader--skeleton__item">
					<div className="loader--skeleton__icon loader--skeleton__icon--big" />
					<div className="loader--skeleton__title loader--skeleton__title--med" />
				</div>
			))}
		</div>
	);
}
