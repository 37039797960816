import {
	TMostPlayTicketResponse,
	TMostPlayTicketsFilter,
	TQuickBetFormRequest,
	TQuickBet,
	TQuickPlayGenericResponse,
} from "@api-types/quick-play";
import { getAgencyKey, getApplicationHeaders, httpClient } from "@utils";

export default class RegulationDefinitionsApiService {
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/quick-play/bet-slips`;
	}

	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async fetchMostPlayBetSlips(
		settings: TMostPlayTicketsFilter
	): Promise<TQuickPlayGenericResponse<TMostPlayTicketResponse[]>> {
		const filter = new URLSearchParams();
		if (settings.offerCount) {
			filter.append("offerCount", settings.offerCount);
		}
		if (settings.sportId) {
			filter.append("sportId", settings.sportId);
		}
		if (settings.sort) {
			filter.append("sort", settings.sort);
		} else {
			filter.append("sort", "submitCount");
			filter.append("isAscending", "false");
		}

		return await httpClient.get(
			`${this.baseUrl}?${filter.toString()}`,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	static async fetchBetSlipById(
		betSlipId: string
	): Promise<TQuickPlayGenericResponse<TMostPlayTicketResponse[]>> {
		return await httpClient.get(
			`${this.baseUrl}/${betSlipId}`,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	static async fetchQuickBet(
		data: TQuickBetFormRequest
	): Promise<TQuickPlayGenericResponse<TQuickBet>> {
		return await httpClient.post(
			`${WEBPACK_BASE_ADDRESS}${getAgencyKey()}
			/bet-slips/quick-bet-new`,
			JSON.stringify(data),
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}
}
