import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useScore } from "@v2/hooks/offer/useScore";

function ServeIcon() {
	return <i className="offer__score__serve" />;
}

export const Score = observer(function Score() {
	const { gameScore, serve, lastPeriodScore } = useScore();


	return (
		<div
			className={classNames("offer__body__data offer__score", {
				"server-only": gameScore == null && serve === "home",
				"set-only": gameScore == null && serve == null,
			})}
		>
			<div
				className={classNames(
					"offer__score__col offer__score__server",
					{
						"is-empty": serve == null,
					}
				)}
			>
				<div className="offer__score__item">
					{serve === "home" ? <ServeIcon /> : ""}
				</div>
				<div className="offer__score__item">
					{serve === "away" ? <ServeIcon /> : ""}
				</div>
			</div>

			{gameScore != null ? (
				<div className="offer__score__col offer__score__game">
					<div className="offer__score__item">
						{gameScore.home || 0}
					</div>
					<div className="offer__score__item">
						{gameScore.away || 0}
					</div>
				</div>
			) : (
				<div className="offer__score__col offer__score__game¨is-empty">
					&nbsp;
				</div>
			)}

			{lastPeriodScore != null ? (
				<div className="offer__score__col offer__score__set">
					<div className="offer__score__item">
						{lastPeriodScore.home || 0}
					</div>
					<div className="offer__score__item">
						{lastPeriodScore.away || 0}
					</div>
				</div>
			) : (
				<div className="offer__score__col offer__score__set is-empty">
					&nbsp;
				</div>
			)}
		</div>
	);
});
