export enum SportAbrv {
	TENNIS = "tennis",
	BADMINTON = "badminton",
	VOLLEYBALL = "volleyball",
	BEACH_VOLLEY = "beach-volley",
	AUSSIE_RULES = "aussie-rules",
	FIELD_HOCKEY = "field-hockey",
	AMERICAN_FOOTBALL = "american-football",
	DARTS = "darts",
	FORMULA_1 = "formula-1",
	BIKES = "bikes",
	BOXING = "boxing",
	MMA = "mma",
	CYCLING = "cycling",
	CRICKET = "cricket",
	TABLE_TENNIS = "table-tennis",
	BOWLS = "bowls",
	SQUASH = "squash",
	SNOOKER = "snooker",
	STOCK_CAR_RACING = "stock-car-racing",
	BASEBALL = "baseball",
	ICE_HOCKEY = "ice-hockey",
	WATERPOLO = "waterpolo",
	BASKETBALL = "basketball",
	FLOORBALL = "floorball",
	RUGBY = "rugby",
	SOCCER = "soccer",
	FUTSAL = "futsal",
	HANDBALL = "handball",
	GOLF = "golf",
	MOTORSPORT = "motorsports",
	PESAPALLO = "pesapallo",
	BEACH_SOCCER = "beach-soccer",
	ESOCCER = "esoccer",
	BANDY = "bandy",
}
