import { DateTime } from "luxon";
import { Response, ErrorResponse } from "../../shared";
import { UserNameRecoveryParamsDto } from "@api-types/user/UserNameRecoveryParamsDto";
import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));
class ForgotUsernameService {
	async forgotUsername(
		model: ForgetUserNameFormData,
		recaptchaToken: string | undefined
	) {
		try {
			const newModel: UserNameRecoveryParamsDto = {
				dateOfBirth: DateTime.fromISO(model.dateOfBirth).toFormat(
					"MM-dd-yyyy"
				),
				email: model.email,
				challengeResponse: recaptchaToken || "",
			};
			await (
				await AgencyUserApiService
			).default.recoverUsername(newModel);
			return new Response();
		} catch (error) {
			console.error(error);
			if (error.statusCode == 500) {
				const errorObject = await error.rawResponse.json();
				if (errorObject.errorCode === 400158) {
					throw new ErrorResponse({
						message: "FORGOT_USERNAME_INVALID_USERNAME_DOB",
					});
				} else if (errorObject.errorCode === 4030000000) {
					throw new ErrorResponse({
						message: "FORGOT_USERNAME_NOT_VERIFIED",
					});
				} else if (errorObject.errorCode === 400161) {
					throw new ErrorResponse({
						message: "FORGOT_USERNAME_SHOP_ADDRESS",
						options: errorObject.details,
					});
				}
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}
}

export const forgotUsernameServiceInstance = new ForgotUsernameService();
export type ForgetUserNameFormData = {
	/** Time string */
	dateOfBirth: string;
	email: string;
};
