import { AgencyUserDto } from "@api-types/user/AgencyUserDto";
import { AgencyUserFullUpdateDto } from "@api-types/user/AgencyUserFullUpdateDto";
import { DateTime } from "luxon";
import { UpdatePersonalDataRequestModel } from "src/data/types";
import { PhoneNumberUtil } from "google-libphonenumber";
import { isNullOrWhitespace } from "@utils";

export const isPhoneValid = (phone: string) => {
	const phoneUtil = PhoneNumberUtil.getInstance();

	if (phone == undefined) {
		return false;
	}
	if (isNullOrWhitespace(phone)) {
		return true;
	} else {
		try {
			return phoneUtil.isValidNumber(
				phoneUtil.parseAndKeepRawInput(phone)
			);
		} catch (error) {
			return false;
		}
	}
};

export const removeUndefinedFields = (data, fetchedData) => {
	const formData = {};
	const newObject = {};

	Object.keys(data).map((fieldName) => {
		if (data[fieldName] !== undefined) {
			formData[fieldName] = data[fieldName];
		}
	});

	return Object.assign(newObject, fetchedData, formData);
};

export const mapDataForPasswordConfirmation = (
	data,
	dirtyFields,
	fetchedData
) => {
	const processedData = processDirtyFields(data, dirtyFields);

	const dataEntries = Object.entries(processedData);

	const mappedData = dataEntries.map((entry) => {
		//add icon for + and -
		if (entry[1] === true) {
			entry[1] = "+";
		} else if (entry[1] === false) {
			entry[1] = "-";
		} else if (entry[0] === "dob") {
			entry[1] = DateTime.fromISO(entry[1]).toLocaleString(
				DateTime.DATE_SHORT
			);
		} else if (entry[0] === "documentExpiration") {
			entry[1] = DateTime.fromISO(entry[1]).toLocaleString(
				DateTime.DATE_SHORT
			);
		} else if (entry[0] === "receiveNewsletter") {
			return {
				name: "specific:USER.ACCOUNT_SETTINGS.PERSONAL_DATA.NEWSLETTER_LABEL",
				value: entry[1],
			};
		}
		return {
			name:
				"USER.ACCOUNT_SETTINGS.PERSONAL_DATA." + entry[0].toUpperCase(),
			value: entry[1],
		};
	});

	const getIDValues = changeIdToName(mappedData, fetchedData);
	return getIDValues;
};

export const mapLanguages = (defaultLangs) => {
	if (!defaultLangs) {
		return;
	}
	return defaultLangs.map((lang) => {
		return {
			id: lang.culture,
			name: lang.name,
		};
	});
};

export const filterLanguageList = (supportedLanguages, allLanguages) => {
	return allLanguages.filter((lookupLang) =>
		supportedLanguages.some(
			(supportedLang) =>
				lookupLang.iso === supportedLang.iso.toUpperCase()
		)
	);
};

export const mapDataForPersonalDataUpdate = (
	fetchedUserData: AgencyUserDto,
	dataModel: UpdatePersonalDataRequestModel
): AgencyUserFullUpdateDto => {
	const personalDataUpdateUserModel = new AgencyUserFullUpdateDto();
	personalDataUpdateUserModel.password = dataModel.password;
	personalDataUpdateUserModel.birthName = dataModel.birthName;
	personalDataUpdateUserModel.birthPlace = dataModel.birthPlace;
	personalDataUpdateUserModel.city = dataModel.city;
	personalDataUpdateUserModel.userName = fetchedUserData?.userName;
	personalDataUpdateUserModel.countryId = dataModel.countryId;
	personalDataUpdateUserModel.diagnosticLevel =
		fetchedUserData?.diagnosticLevel;
	personalDataUpdateUserModel.dOB = dataModel.dob;
	personalDataUpdateUserModel.documentExpiration =
		fetchedUserData.documentExpiration;
	personalDataUpdateUserModel.houseBox = fetchedUserData.houseBox;
	personalDataUpdateUserModel.houseNumber = dataModel.houseNumber;
	personalDataUpdateUserModel.isAgencyWideCashierEnabled =
		fetchedUserData?.isAgencyWideCashierEnabled;
	personalDataUpdateUserModel.isDiagnosticEnabled =
		fetchedUserData?.isDiagnosticEnabled;
	personalDataUpdateUserModel.isLogTransferEnabled =
		fetchedUserData?.isLogTransferEnabled;
	personalDataUpdateUserModel.language = dataModel.language;
	personalDataUpdateUserModel.mobilePhone = dataModel.mobilePhone;
	personalDataUpdateUserModel.nationality = dataModel?.nationality;
	personalDataUpdateUserModel.numericalId = fetchedUserData.numericalId;
	personalDataUpdateUserModel.occupationId = dataModel?.occupation;

	personalDataUpdateUserModel.passportId = fetchedUserData.passportId;
	personalDataUpdateUserModel.profession = dataModel.profession;
	personalDataUpdateUserModel.regionId = dataModel.state;
	personalDataUpdateUserModel.secondAddressReason =
		fetchedUserData.secondAddressReason;
	personalDataUpdateUserModel.secondCity = fetchedUserData.secondCity;
	personalDataUpdateUserModel.secondCountryId =
		fetchedUserData?.secondCountryId;
	personalDataUpdateUserModel.secondHouseNumber =
		fetchedUserData.secondHouseNumber;
	personalDataUpdateUserModel.secondStreet = fetchedUserData.secondStreet;
	personalDataUpdateUserModel.secondZIPCode = fetchedUserData.secondZIPCode;
	personalDataUpdateUserModel.shopId = fetchedUserData.shopId;
	personalDataUpdateUserModel.stakeAmount = fetchedUserData.stakeAmount;
	personalDataUpdateUserModel.street = dataModel.street;
	personalDataUpdateUserModel.telephoneNumber = dataModel.telephoneNumber;
	personalDataUpdateUserModel.titleId = dataModel.titleId;
	personalDataUpdateUserModel.userAccountRelationMatchStatus =
		fetchedUserData.userAccountRelationMatchStatus;
	personalDataUpdateUserModel.zipCode = dataModel.zipCode;
	personalDataUpdateUserModel.firstName = dataModel.firstName;
	personalDataUpdateUserModel.lastName = dataModel.lastName;
	personalDataUpdateUserModel.creationDate = fetchedUserData?.creationDate;
	personalDataUpdateUserModel.cultureInfoName = dataModel.language;
	personalDataUpdateUserModel.displayName = fetchedUserData?.displayName;
	personalDataUpdateUserModel.email = dataModel.currentEmail;
	personalDataUpdateUserModel.isApproved = fetchedUserData.isApproved;
	personalDataUpdateUserModel.isLockedOut = fetchedUserData.isLockedOut;
	personalDataUpdateUserModel.lastActivityDate =
		fetchedUserData?.lastActivityDate;
	personalDataUpdateUserModel.lastLockoutDate =
		fetchedUserData?.lastLockoutDate;
	personalDataUpdateUserModel.lastLoginDate = fetchedUserData?.lastLoginDate;
	personalDataUpdateUserModel.name = fetchedUserData?.name;
	personalDataUpdateUserModel.roles = fetchedUserData.roles;
	personalDataUpdateUserModel.timeZoneId = fetchedUserData.timeZoneId;
	personalDataUpdateUserModel.json = fetchedUserData?.json;
	personalDataUpdateUserModel.personalIdentificator =
		dataModel?.personalIdentificator;
	personalDataUpdateUserModel.documentExpiration =
		dataModel.documentExpiration;

	return personalDataUpdateUserModel;
};

const processDirtyFields = (data, dirtyFields) => {
	const touchedFields = {};
	Object.keys(dirtyFields).map((fieldName) => {
		touchedFields[fieldName] = data[fieldName];
	});
	return touchedFields;
};

const changeIdToName = (
	data: { name: string; value: string }[],
	fetchedData: {
		languages: { culture: string; name: string; nationality: string }[];
		userTitles: { id: string; name: string; nationality: string }[];
		countries: { id: string; name: string; nationality: string }[];
		countryRegions: { id: string; name: string; nationality: string }[];
		occupationData: { id: string; name: string; nationality: string }[];
	}
) => {
	const changedData = data.map((x) => {
		const isNationality = x.name.includes("NATIONALITY");
		const getName =
			fetchedData.languages.find((lang) => lang.culture === x.value) ||
			fetchedData.userTitles.find((title) => title.id === x.value) ||
			fetchedData.countries.find((country) => country.id === x.value) ||
			fetchedData.countryRegions.find((state) => state.id === x.value) ||
			fetchedData.occupationData.find(
				(occupation) => occupation.id === x.value
			);

		if (getName && getName.name) {
			x.value = getName.name;
			if (isNationality) {
				x.value = getName.nationality;
			}
		}
		return x;
	});
	return changedData;
};
