import { httpClient, getCurrentCulture, getApplicationHeaders } from "@utils";

const baseUrl = `${BasePrintOfferAddress}print`;

class OfferPrintServiceData {
	constructor() {}

	getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type"] = "application/json";
		return appHeaders;
	};

	async getOfferPrintData() {
		try {
			const response = await httpClient.get(
				`${baseUrl}/list-available-files?language=${getCurrentCulture()}&shopId=${WEBPACK_DEFAULT_SHOP_ID}&rpp=100`,
				this.getRequestHeaders(),
				{ responseType: "json" }
			);
			return response;
		} catch (e) {
			return [];
		}
	}
}

const offerPrintServiceData = new OfferPrintServiceData();

export default offerPrintServiceData;
