import React from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function RadioInput(props) {
	const {
		label,
		name,
		labelClassname,
		type = "radio",
		classname,
		id,
		iconClass,
		...rest
	} = props;

	const { t } = useTranslation();
	const form = useFormContext();

	const field = form.register(props.name, {
		required: {
			value: props.required,
			message: props.errorMessage || "COMMON.REQUIRED_FIELD",
		},
		disabled: props.disabled,
	});
	return (
		<>
			<input
				type={type}
				name={name}
				className={classNames(
					"input--radio",
					classname
				)}
				id={id}
				{...rest}
				{...field}
			/>
			<label htmlFor={id} className={labelClassname}>
				{iconClass && <i className={iconClass} />} &nbsp;
				<span className="u-align--v--middle">{t(label)}</span>
			</label>
		</>
	);
}