import React from "react";
import { useRootAppStore } from "@hooks";
import { useTranslation } from "react-i18next";
import OfferIcon from "@v2/components/shared/icons/Offer";
import MessageWrapper from "./MessageWrapper";

interface CustomMessageProps {
	customMessageData: any;
	isLink?: boolean;
}

const CustomMessage = ({
	customMessageData,
	isLink = false,
}: CustomMessageProps) => {
	const { t } = useTranslation();
	const { chatViewStore } = useRootAppStore();

	const sharedSlipData = customMessageData.sharedSlip;
	const message = customMessageData.msg;

	const {
		eventCount,
		maxCoefficient,
		dateCreated,
		sportAbrv,
		firstEventName,
		betSlipNumber,
	} = sharedSlipData;

	return (
		<>
			<MessageWrapper isLink={isLink} anchor={message}>
				<span>{message}</span>
			</MessageWrapper>
			<div
				style={{ cursor: "pointer" }}
				onClick={(e) =>
					chatViewStore.chatBetSlipShareHandler.openChatSharedSlipPopup(
						betSlipNumber
					)
				}
			>
				<div className="chat__slip u-mar--top--xtny">
					<div className="chat__slip__header">
						<OfferIcon
							type="sport"
							sport={sportAbrv}
							className="chat__icon__primary u-icon u-icon--lrg"
						/>
						<span className="chat__slip__count">{eventCount}</span>
						<span className="chat__slip__title">
							{firstEventName}
						</span>
					</div>

					<div className="chat__slip__footer">
						<span>
							{t("BET_SLIP.MAX_COEF")}:{" "}
							<strong>{maxCoefficient}</strong>
						</span>
						<span className="u-color--text--tertiary">
							{dateCreated}
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomMessage;
