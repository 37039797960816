import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@v2/components/desktop/shared/buttons";
import { observer } from "mobx-react";
import { useAppContext } from "@v2/hooks/shared";

export default observer(function BettingActivityError(props: {
	logoutUser: () => void;
}) {
	const { t } = useTranslation();
	const bettingActivityStore =
		App.state.rootStore.userAuthStore.bettingActivityStore;
	const { culture } = useAppContext();

	if (bettingActivityStore.bettingActivityError == null) {
		return null;
	}

	const handleErrorResponse = () => {
		props.logoutUser();
		App.state.history.push(`/${culture}/home/full/highlights`);
		bettingActivityStore.setBettingActivityError(null);
	};

	return (
		<div className="at-bettingActivityError card--message">
			<div className="card--message-full__thumb" />
			<div className="card--message__body">
				<div className="card--message__content">
					<i className="card--message__icon u-icon u-icon--xlrg u-icon--remove-circle u-icon--animated--bounce-in u-color--ui--danger" />
					<div className="card--message__title">
						{t<string>(
							"MEMBERSHIP.ACCOUNT_ACTIVATION.ERROR_HAS_OCCURED"
						)}
					</div>
					<div className="u-type--left">
						{t<string>(
							"MEMBERSHIP.ACCOUNT_ACTIVATION.ERROR_MESSAGE_ACCOUNT_ACTIVATION"
						)}
					</div>
				</div>
				<div className="card--message__footer">
					<Button
						className="card--message__cta btn--lrg btn--primary"
						btnText={t("COMMON.OK_BTN")}
						onClick={handleErrorResponse}
					/>
				</div>
			</div>
		</div>
	);
});
