import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import classnames from "classnames";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import { MainSportsList } from "./";
import { useLocation } from "react-router-dom";
import { OfferStoreContext } from "@gp/components";
import { useRootAppStore } from "@hooks";

export function MainMenu(props: { store: PrematchOfferMenuStore }) {
	return (
		<nav className="nav--secondary">
			<ul className="nav--secondary__menu">
				{/* <Hamburger /> */}
				<MainMenuSelectorPeriod store={props.store} />
				<FavoritesItem store={props.store} />
				<MainSportsList store={props.store} />
			</ul>
		</nav>
	);
}

const MainMenuSelectorPeriod = observer(function MainMenuSelectorPeriod(props: {
	store: PrematchOfferMenuStore;
}) {
	const t = useTranslation().t;

	return (
		<li className="nav--secondary__item">
			<a
				className={classnames(`nav--secondary__link`, {
					"is-expanded":
						props.store.periodSubMenu.isPeriodMenuExpanded,
				})}
				onClick={() => props.store.periodSubMenu.togglePeriodMenu()}
			>
				<i
					className={`nav--secondary__icon u-icon u-icon--lrg u-icon--period--${props.store.periodSubMenu.selectedPeriod.period}`}
				/>
				<div className="nav--secondary__title">
					{t(props.store.periodSubMenu.selectedPeriod.name)}
				</div>
			</a>
		</li>
	);
});

const FavoritesItem = observer(function FavoritesItem(props: {
	store: PrematchOfferMenuStore;
}) {
	const {
		// @ts-expect-error
		periodSubMenu: {
			selectedPeriod: { period },
		},
	} = useContext(OfferStoreContext);
	const { t } = useTranslation();
	const location = useLocation();
	const rootStateStore = useRootAppStore();

	if (!rootStateStore.userAuthStore.isLoggedIn) {
		return null;
	}

	return (
		<li className="nav--secondary__item">
			<button
				className={classnames(`nav--secondary__link`, {
					"is-expanded": props.store.isFavoritesSubMenuOpen,
					"is-selected": location.pathname.includes("my-favorites"),
				})}
				onClick={() => {
					props.store.toggleFavoritesMenu();
				}}
			>
				<i
					className={`nav--secondary__icon u-icon u-icon--lrg u-icon--favorites`}
				/>

				<div className="nav--secondary__title">
					{t("SPORTS_MENU.MY_FAVORITES.TITLE")}
				</div>
				{/* <span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
					{favoritesStore.prematchFavoritesCount}
				</span> */}
			</button>
		</li>
	);
});
const Hamburger = observer(function Hamburger(props: {
	store: PrematchOfferMenuStore;
}) {
	const buttonClasses = classnames("l--header__toggle__btn", {
		"is-expanded":
			props.store.isOfferMenuOpen && !props.store.isExpandDisabled,
	});

	return (
		<div className="l--header__toggle">
			<button
				id="burger"
				className={buttonClasses}
				type="button"
				onClick={() => props.store.toggleOpenOfferMenu()}
				disabled={props.store.isExpandDisabled}
			>
				<span />
				<span />
				<span />
			</button>
		</div>
	);
});
