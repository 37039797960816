import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons";
import { Footer } from "../footer";
import { Header } from "../header";
import { ErrorBoundaryHoc } from "@v2/hoc";

export default function ErrorPageContent(props: {
	reload: () => void;
	full?: boolean;
}) {
	if (props.full) {
		return (
			<>
				<Header />
				<main className="at-errorPageFull l--main l--main--full">
					<section className="l--content l--content--full">
						<div className="l--content__main">
							<div className="container">
								<div className="card--message">
									<div className="card--message__thumb" />
									<div className="card--message__body">
										<div className="card--message__content">
											<Message />
											<div className="card--message__footer">
												<Button
													className="btn--lrg btn--block btn--primary btn--icon btn--animate-rotate"
													atClassName="errorPageFullBtn"
													type="button"
													onClick={() =>
														props.reload()
													}
													btnContent={<BtnContent />}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
				<footer className="l--footer">
					<Footer />
				</footer>
			</>
		);
	}

	return (
		<div className="at-errorPage card--message u-mar--top--xsml">
			<div className="card--message__thumb" />
			<div className="card--message__body">
				<div className="card--message__content">
					<Message />
					<div className="card--message__footer">
						<Button
							atClassName="errorPageBtn"
							className="btn btn--lrg btn--block btn--primary btn--icon btn--animate-rotate"
							type="button"
							onClick={() => props.reload()}
							btnContent={<BtnContent />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

function Message() {
	const t = useTranslation().t;
	return (
		<>
			<i className="card--message__icon u-icon u-icon--xlrg u-icon--refresh u-icon--animated--bounce-in u-color--ui--success" />
			<div className="card--message__title">
				{t<string>("APP_UPDATED.TITLE")}
			</div>
			<div className="u-type--left">
				{t<string>("APP_UPDATED.MESSAGE_DETAILS")}
			</div>
		</>
	);
}

function BtnContent() {
	const t = useTranslation().t;

	return (
		<>
			<i className="u-icon u-icon--sml u-icon--refresh u-color--core--snow" />
			<span>{t<string>("APP_UPDATED.UPDATE_BUTTON")}</span>
		</>
	);
}
