import { legalLockTranslationKeys } from "@v2/helpers/account-settings/account-lock/utils";
import React from "react";
import { useTranslation } from "react-i18next";

export default function AccountLockTitleDisplay() {
	const { t } = useTranslation();

	const isOasisLockEnabled =
		App.state.rootStore.userAuthStore.user?.isOasisEnabled;

	return (
		<div className="col col-sml-12 form__field">
			<div className="message message--base message--note">
				<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
				<div className="message__content">
					<ul className="list--unordered">
						{!isOasisLockEnabled && (
							<li>
								{t(
									"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.NO_LOCK"
								)}
							</li>
						)}
						<li>
							{legalLockTranslationKeys
								.map((key) => t(key))
								.join(" ")}
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
