import { toast } from "react-toastify";
import { NotificationMessage } from "@v2/components/shared/utility";
import { localizationService } from "@state";

class NotificationStore {
	constructor() {
		toast.configure({
			bodyClassName: "toast-container",
			limit: 1,
		});
	}

	success(message: string) {
		return showToast(message, {
			className: "Toastify__toast--success",
			delay: 400,
		});
	}

	warning(message: string) {
		return showToast(message, {
			className: "Toastify__toast--warning",
		});
	}

	error(message: string) {
		return showToast(message, {
			className: "Toastify__toast--error",
		});
	}

	info(message: string) {
		return showToast(message, {
			className: "Toastify__toast--info",
		});
	}

	muted(message: string) {
		return showToast(message, {
			className: "Toastify__toast--disabled",
		});
	}

	onError(message: string, originalMessage: unknown) {
		console.error(originalMessage);
		return showToast(localizationService.t(message), {
			className: "Toastify__toast--error",
		});
	}
}
function showToast(message: string, options: object) {
	toast.clearWaitingQueue();
	toast.dismiss();
	toast(() => NotificationMessage({ message }), options);
}

export default NotificationStore;
