import React from "react";
import AccountStatementTransactionDetailsError from "./AccountStatementBetDetailsError";
import { Button } from "../../shared/buttons";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { DateTime } from "luxon";
import { AccountStatementBetContext } from "../../../../context/AccountStatementBetContext";
import { useContext } from "react";
export default function AccountStatementTransactionDetails() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);

	if (contextValue === null) {
		return null;
	}

	const { slipDetails, setDetailsType } = contextValue;

	if (!slipDetails || !("netAmount" in slipDetails)) {
		return (
			<AccountStatementTransactionDetailsError
				setDetailsType={setDetailsType}
			/>
		);
	}

	const {
		paymentMethod,
		amount,
		currency,
		transactionStatus,
		ccMaskedPan,
		ccExpiryDate,
		startDate,
		endDate,
		numericalId,
	} = slipDetails;

	const paymentMethodIcon = classnames(
		"card--payment__logo u-icon u-icon--payment u-icon--payment--base",
		[`u-icon--payment--${paymentMethod.abrv.toLowerCase()}`]
	);

	return (
		<div className="popup">
			<div className="popup__card popup__card--reset-width">
				<div className="popup__header">
					<div className="popup__header__title u-type--xmed u-type--wgt--regular">
						{t<string>(
							"ACC_STATEMENT.TRANS_DETAILS.TRANSACTION_DETAILS"
						)}
					</div>

					<Button
						btnType="button"
						className="btn--square btn--square--tny btn--secondary--inverted"
						iconOnly={true}
						iconClassName="u-icon--sml u-icon--close"
						onClick={() => setDetailsType(0)}
					/>
				</div>
				<div className="popup__body">
					<table className="table table--fixed">
						<thead className="table__head u-type--wgt--regular">
							<tr className="table__head__row table__row">
								<th className="table__head__cell table__cell u-color--text--secondary">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.TRANSACTION_ID"
									)}
								</th>
								<th className="table__head__cell table__cell u-color--text--primary">
									{numericalId}
								</th>
							</tr>
						</thead>
						<tbody className="table__body">
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.AMOUNT"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{amount}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.CURRENCY"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{currency.displayCode}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.STATUS"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									<i
										className={`u-icon u-icon--xsml u-icon--check u-color--ui--${transactionStatus.abrv} u-align--v--middle u-mar--right--xtny`}
									/>
									<span
										className={`u-align--v--middle u-type--case--capitalize u-color--ui--${transactionStatus.abrv}`}
									>
										{transactionStatus.name}
									</span>
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.PAYMENT_METHOD"
									)}
								</td>
								<td className="table__body__cell table__cell">
									<div className="card--payment card--payment--borderless u-mar--bottom--reset">
										<div className="card--payment__area u-padd--reset">
											<span className="card--payment__icon">
												<i
													className={
														paymentMethodIcon
													}
												/>
											</span>
											<span className="card--payment__details card--payment__details--centered">
												<span className="card--payment__name u-type--wgt--regular">
													{ccMaskedPan}
												</span>
												<span
													className="card--payment__type"
													title={t<string>(
														"ACC_STATEMENT.TRANS_DETAILS.CARD_EXPIRY"
													)}
												>
													{ccExpiryDate}
												</span>
											</span>
										</div>
									</div>
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.START_DATE"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{DateTime.fromISO(startDate).toLocaleString(
										DateTime.DATETIME_SHORT_WITH_SECONDS
									)}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.END_DATE"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{DateTime.fromISO(endDate).toLocaleString(
										DateTime.DATETIME_SHORT_WITH_SECONDS
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
