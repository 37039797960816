import { createBrowserHistory, Path } from "history";
import { logger } from "@state";

/** Browser history used by react router. */
const history = createBrowserHistory<
	| {
			scrollPositionApp?: number;
			eventIdsForEventSwitcher?: string[];
			tournamentSwitcher?: { segmentsList: string[] };
	  }
	| null
	| undefined
>();

/**
 * @param url new url
 * @param replace if true it will performed history replace instated of push
 * It will not preserve search params if they are not included in url
 * It will not preserve history state
 */
export function redirect(url: Path, replace: boolean = false) {
	if (replace) {
		history.replace(url, history.location.state);
	} else {
		history.push(url);
	}
}

/**
 * @param url new url
 * @param replace if true it will performed history replace instated of push
 * It will perform url update without removing search params from url
 * It will not preserve history state
 */
export function redirectPreserveSearchParams(
	url: Path,
	replace: boolean = false
) {
	const currentSearchParams = App.state.history.location.search;
	if (replace) {
		redirect(url + currentSearchParams, true);
	} else {
		history.push(url + currentSearchParams);
	}
}

/**
 * @param params object that will replace current search with key/value pairs
 * @param replace if true it will performed history replace instated of push
 */
export function updateUrlParams(params: Object = {}, replace: boolean = false) {
	const newUrlParams = new URLSearchParams(history.location.search);

	/** Add new query keys to url. */
	for (const [key, value] of Object.entries(params)) {
		newUrlParams.set(key, JSON.stringify(value));
	}

	/** Get new query parameters for url. */
	const newQueryParams =
		newUrlParams.toString() === "" ? "" : "?" + newUrlParams.toString();
	if (replace) {
		if (newQueryParams !== history.location.search) {
			/** If it should only replace url, do only when parameters have changed. */
			redirect(history.location.pathname + newQueryParams, replace);
		}
	} else {
		redirect(history.location.pathname + newQueryParams);
	}
}

let lastSearch: string = "";
let lastUrlObj: any = {};

/**
 * @returns T object with key/value pares from urls search string
 */
export function getUrlParamsAsObj<T>(): T {
	const currentSearchString = history.location.search;

	// Return memoized object
	if (lastSearch === currentSearchString) {
		return lastUrlObj;
	}

	const currentUrlParams = new URLSearchParams(currentSearchString);
	const result: any = {};
	for (const [key, value] of Array.from(currentUrlParams.entries())) {
		try {
			result[key] = JSON.parse(value);
		} catch (e) {
			logger.logError(e);
		}
	}

	lastSearch = currentSearchString;
	lastUrlObj = result;
	return result;
}

/**
 * @param params object of key/value to be set as current query parameters
 * @param addHistoryEntry indicates whether or not new history entry will be added
 */
export function setUrlParams(params: Object = {}, addHistoryEntry = false) {
	const newUrlParams = new URLSearchParams("");

	/** Add new query keys to url. */
	for (const [key, value] of Object.entries(params)) {
		newUrlParams.set(key, JSON.stringify(value));
	}

	/** Get new query parameters for url. */
	const newQueryParams =
		newUrlParams.toString() === "" ? "" : "?" + newUrlParams.toString();
	redirect(history.location.pathname + newQueryParams, !addHistoryEntry);
}

/**
 * @param keys list of keys to be removed from url search params
 * @param addHistoryEntry indicates whether or not new history entry will be added
 */
export function removeFromUrlParams(keys: string[], addHistoryEntry = false) {
	const currentParams = getUrlParamsAsObj<any>();
	for (const key of keys) {
		delete currentParams[key];
	}
	setUrlParams(currentParams, addHistoryEntry);
}

/**
 * @param params object of key/value to be added to url params
 * @param addHistoryEntry indicates whether or not new history entry will be added
 */
export function addToUrlParams(params: Object = {}, addHistoryEntry = false) {
	const currentParams = getUrlParamsAsObj<Object>();
	const newParams = Object.assign(currentParams || {}, params);
	setUrlParams(newParams, addHistoryEntry);
}

export default history;
