import React, { useContext, useEffect } from "react";
import { useRootOfferStore } from "@hooks";
import { MyBetsBetContext } from "@v2/context";
import { observer } from "mobx-react";

import { EventContext, OfferStoreContext } from "@gp/components";

import { Pager } from "@v2/components/mobile/shared/inputs";
import MyBetsTabItem from "./MyBetsTabItem";
import { AdditionalOfferViewStore } from "@v2/state/offer/components/AdditionalOfferViewStore";
import Modals from "./Modals";
import EmptyMyBetsMessage from "./EmptyMyBetsMessage";
import MyBetsAutosellConfirmation from "./MyBetsAutosellConfirmation";
import { LoaderSpinner } from "@v2/components/shared";

export const MyBetsTab = observer(function MyBetsTab() {
	const store = useContext(OfferStoreContext) as AdditionalOfferViewStore;
	const myBetsViewStore = useRootOfferStore().myBetsViewStore;
	const event = useContext(EventContext);

	const eventInList: { eventId: string; isMyBetsOpen: boolean } =
		//@ts-expect-error
		store.myBetsToggleStateList.find(
			(eItem: { eventId: string; isMyBetsOpen: boolean }) =>
				eItem.eventId == event.id
		);

	useEffect(() => {
		if (eventInList != null && eventInList.isMyBetsOpen) {
			myBetsViewStore.fetchAdditionalOffers(event.id);
		}

		return () => myBetsViewStore.onEventBetsDispose(event.id);
	}, [eventInList?.isMyBetsOpen]);

	if (eventInList != null && !eventInList.isMyBetsOpen) {
		return null;
	}

	return <MyBetsBody />;
});

const MyBetsBody = observer(function MyBetsBody() {
	const myBetsViewStore = useRootOfferStore().myBetsViewStore;
	const store = useContext(OfferStoreContext) as AdditionalOfferViewStore;
	const event = useContext(EventContext);

	const foundBetsList = myBetsViewStore.betsPerEvent.find(
		(item) => item.eventId === event.id
	);

	const eventInList: { eventId: string; isMyBetsOpen: boolean } =
		//@ts-expect-error
		store.myBetsToggleStateList?.find(
			(eItem: { eventId: string; isMyBetsOpen: boolean }) =>
				eItem.eventId == event.id
		);

	if (myBetsViewStore.isFetchingData || myBetsViewStore.isEventBetsLoading) {
		return (
			<LoaderSpinner
				isLoading={
					myBetsViewStore.isFetchingData ||
					myBetsViewStore.isEventBetsLoading
				}
			/>
		);
	}

	if (
		eventInList != null &&
		eventInList.isMyBetsOpen &&
		foundBetsList != null &&
		foundBetsList.bets.length === 0
	) {
		return (
			<div className="card--primary__body card--primary__body--bordered">
				<EmptyMyBetsMessage />
			</div>
		);
	}

	return (
		<>
			<Modals isOnOffer={true} />
			<div className="card--primary__body card--primary__body--bordered">
				<BetsList />
				<MyBetsAutosellConfirmation
					store={myBetsViewStore.cashoutStore}
				/>
			</div>
		</>
	);
});

const BetsList = observer(function BetsList() {
	const myBetsViewStore = useRootOfferStore().myBetsViewStore;
	const event = useContext(EventContext);

	const foundBetsList = myBetsViewStore.betsPerEvent.find(
		(item) => item.eventId === event.id
	);

	return (
		<>
			{foundBetsList?.bets.map(
				(bet) =>
					!bet.isRemoved && (
						<MyBetsBetContext.Provider key={bet.id} value={bet}>
							<MyBetsTabItem />
						</MyBetsBetContext.Provider>
					)
			)}
			<PagerWrapper />
		</>
	);
});

const PagerWrapper = observer(function PagerWrapper() {
	const store = useRootOfferStore().myBetsViewStore;
	const event = useContext(EventContext);

	const foundBetsList = store.betsPerEvent.find(
		(item) => item.eventId === event.id
	);

	if (foundBetsList == null) {
		return null;
	}

	return (
		<Pager
			pageNumber={foundBetsList.pageNumber}
			pageSize={store.pageSize}
			totalItems={foundBetsList.totalItems}
			onPageChange={(page: number) =>
				store.setEventPageNumber(event.id, page)
			}
			withDropdown
		/>
	);
});
