import React, { FC, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { FormProvider, useForm } from "react-hook-form";
import { DepositContext } from "@context";
import { RawFilterData } from "@data-types";
import { Pager } from "@v2/components/desktop/shared/inputs";
import {
	DepositCustomPeriodInput,
	DepositPaymentMethodFilterSection,
	DepositPeriodsFilterSection,
	DepositTransactionStatusFilterSection,
	MyDepositsTable,
} from "./components";

import { getCurrentCulture } from "@utils";
import { useAppContext } from "@v2/hooks";
import { NavLink } from "react-router-dom";
import Button from "@components/buttons/Button";

export const MyDepositsBody: FC = observer(function MyDepositsBody() {
	const {
		disposeCurrentDepositState,
		depositFilterStore: {
			setRawFilterData,
			setDefaultFilterData,
			rawData,
			pageNumber,
		},
		pageSize,
		totalItems,
		isStoreInitialized,
		isLoadingProcess,
	} = useContext(DepositContext);
	const methods = useForm();
	const { handleSubmit, reset } = methods;

	const { t } = useTranslation();

	useEffect(() => {
		if (location.search !== "" && isStoreInitialized) {
			const params = App.state.getUrlParamsAsObj<RawFilterData>();
			reset(params);
			onSubmit(params);
		} else {
			setRawFilterData({
				pageNumber: 1,
				period: "today",
				paymentMethodId: "all",
				transactionStatusId: "all",
			});
			reset({
				period: "today",
				paymentMethodId: "all",
				transactionStatusId: "all",
			});
		}

		return () => {
			disposeCurrentDepositState();
			setDefaultFilterData();
		};
	}, [isStoreInitialized]);

	const onSubmit = (data: RawFilterData) => {
		setRawFilterData({
			...data,
		});
	};

	const onPageChange = (pageNumber: number) => {
		setRawFilterData({
			...rawData,
			pageNumber: pageNumber,
		});
	};

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="card--primary">
						<div className="card--primary__header">
							<h1 className="card--primary__title title--primary">
								{t<string>("DEPOSIT.TITLE")}
							</h1>
						</div>

						<DepositTabSwitcher />

						<div className="card--primary__body">
							<DepositPeriodsFilterSection />

							<DepositCustomPeriodInput />

							<DepositPaymentMethodFilterSection />

							<DepositTransactionStatusFilterSection />

							<Button
								type="submit"
								className="btn btn--lrg btn--primary"
								btnText={t<string>("DEPOSIT.SUBMIT_BUTTON")}
								isDisabled={isLoadingProcess}
							></Button>
						</div>
					</div>
				</form>
			</FormProvider>

			<MyDepositsTable />

			<Pager
				pageNumber={pageNumber}
				pageSize={pageSize}
				totalItems={totalItems}
				onPageChange={onPageChange}
				withNumberInput
			/>
		</>
	);
});

const DepositTabSwitcher: FC = observer(function DepositTabSwitcher() {
	const { t } = useTranslation();
	const { culture } = useAppContext();

	return (
		<div className="card--primary__tabs u-padd--x--reset">
			<nav className="tabs--primary">
				<NavLink
					className="tabs--primary__item"
					activeClassName="is-active"
					replace={true}
					to={`/${culture}/deposit`}
					isActive={(match, location) => {
						return (
							location.pathname ==
							`/${getCurrentCulture()}/deposit`
						);
					}}
				>
					{t<string>("DEPOSIT.NEW_DEPOSIT")}
				</NavLink>
				<NavLink
					to={`/${culture}/deposit/history`}
					className="tabs--primary__item"
					replace={true}
					activeClassName="is-active"
					isActive={(match, location) => {
						return (
							location.pathname ==
							`/${getCurrentCulture()}/deposit/history`
						);
					}}
				>
					{t<string>("DEPOSIT.MY_DEPOSITS")}
				</NavLink>
			</nav>
		</div>
	);
});
