import { useEffect } from "react";
import { useLoading } from "@hooks";
import { useTranslation } from "react-i18next";
import { setTabTitle } from "@utils";
import {
	LoginRequestDto,
	LoginSSORequestDto,
} from "@api-types/user/LoginRequestDto";
import { useUser } from "@v2/hooks/shared";
import { MitAuthDto } from "@api-types/integrations/MitAuthDto";
import { LoginHash } from "@api-types/integrations/MitIdWidgetDto";
import { LoginType } from "@utils/common/loginTypeEnum";
import { StorageStateKeysEnum } from "@utils/storage";
import { useLocation } from "react-router-dom";
import { getRedirectOriginUrl } from "@utils/specific/capacitor";

export default function useLogin(
	resetField: (name: string, options?: Record<string, boolean | any>) => void
) {
	const { loginStore } = useUser();
	const { t } = useTranslation();
	const { setIsLoading } = useLoading();

	const { search } = useLocation();

	const params = new URLSearchParams(search);

	let pathname =
		window.location.pathname[0] == "/" //if window.location.pathname starts with slash remove
			? window.location.pathname.slice(1)
			: window.location.pathname;

	const redirectUrl = getRedirectOriginUrl(pathname, true);

	const onSuccess = async (data: LoginRequestDto & LoginSSORequestDto) => {
		setIsLoading(true);
		loginStore.resetLoginErrorState();
		await loginStore.onLoginSubmit(data);
		checkLoginErrors();
	};

	const onMitLoginFinalize = async (data: MitAuthDto) => {
		setIsLoading(true);
		loginStore.resetLoginErrorState();
		await loginStore.onLoginSubmit(data, LoginType.MITID);
		checkLoginErrors();
		App.state.rootStore.localStorageProvider.remove(
			StorageStateKeysEnum.MITID_LOGIN_HASH
		);
	};

	const checkLoginErrors = () => {
		if (
			loginStore.loginError != null &&
			typeof loginStore.loginError.userData !== "string" &&
			loginStore.loginError.userData?.activationToken == null
		) {
			resetField("password");
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.LOGIN.TITLE"));
		document.querySelector("html, body")?.scrollTo(0, 0);

		loginStore.onInitializeLogin();
		return () => {
			loginStore.resetLoginErrorState();
			setIsLoading(false);
		};
	}, []);

	useEffect(() => {
		const initializeLogin = () => {
			if (loginStore.isSSOLoginInitialized) {
				return;
			}

			if (params.get("token")) {
				loginStore.isSSOLoginInitialized = true;
				// @ts-expect-error
				onSuccess(params.get("token"));
			}

			if (params.get("code")) {
				loginStore.isSSOLoginInitialized = true;

				const loginHash = JSON.parse(
					App.state.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.MITID_LOGIN_HASH
					) as string
				) as LoginHash;
				if (loginHash?.state == null || loginHash?.nonce == null) {
					loginStore.loginError = {
						errorCode: "5555",
						message: "MIT_MISSING_ORIGINAL_STATE",
					};
					checkLoginErrors();
					return;
				}

				const mitData: MitAuthDto = {
					code: params.get("code")!,
					scope: params.get("scope")!,
					state: params.get("state")!,
					iss: params.get("iss")!,
					originalState: loginHash.state,
					originalNonce: loginHash.nonce,
					redirectUrl: redirectUrl,
				};

				onMitLoginFinalize(mitData);
			}
			if (params.get("error")) {
				App.state.rootStore.localStorageProvider.remove(
					StorageStateKeysEnum.MITID_LOGIN_HASH
				);
			}
		};

		initializeLogin();
	}, [params]);

	return {
		onSuccess: onSuccess,
		loginError: loginStore.loginError,
	};
}
