import React from "react";
import {
	Results,
	Statistics,
	MyBets,
	BetSlip,
	OneClickBetNotification,
} from "./";
import LmtSideContainer from "./side-widget/SideWidget";
import { WorldCup } from "@v2/components/shared/offer/worldcup";
import { MaintenanceNotice } from "../shared/maintenance";
import QuickBet from "@features/quick-play/desktop/quick-bet/QuickBet";
import { MostPlayedTicketsAsideWidgets } from "./most-played-tickets";
import { QuickPlayAsideWidget } from "./quick-play";

export function AsideWidgets(props: { hideQuickBet?: boolean }) {
	return (
		<aside className="l--aside--right">
			<MaintenanceNotice />

			<OneClickBetNotification />

			<LmtSideContainer />
			<BetSlip />
			<MyBets />
			{!props.hideQuickBet && <QuickBet isWidget />}
			{!props.hideQuickBet && <QuickPlayAsideWidget />}
			{props.hideQuickBet && <MostPlayedTicketsAsideWidgets />}
			<WorldCup />
			<Statistics />
			<Results />
		</aside>
	);
}
