import { useLoading } from "@hooks";
import { useNotification } from "../../../../ui/common/hooks";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture, setTabTitle } from "@utils";
import { PasswordChangeService } from "@services/account-settings/PasswordChangeService";
import { PasswordChangeDto } from "@data-types";
import { useUser } from "@v2/hooks/shared";
import { AccountTypes, UserTypes } from "@utils";

export function usePasswordChange() {
	const [userInfo, setUserInfo] = useState<string[]>([]);
	const user = useUser();
	const culture = getCurrentCulture();
	const [activeAccount, setActiveAccount] = useState(
		user?.secondaryUser ? AccountTypes.ONLINE : AccountTypes.OFFLINE
	);

	const methods = useForm({ mode: "onChange" });
	const { reset } = methods;

	const { setIsLoading } = useLoading();
	const { showError, showSuccess } = useNotification();

	const { t } = useTranslation();

	function onInitialize() {
		//active tab
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_PC"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
		reset();
		const fetchUserData = () => {
			try {
				setIsLoading(true);

				const response =
					PasswordChangeService.getUserEmailAndUserName(
						activeAccount
					);

				const userData = Object.values(response);

				if (userData.length !== 0) {
					setUserInfo(userData);
				}
			} catch (error) {
				showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR");
			} finally {
				setIsLoading(false);
			}
		};
		fetchUserData();
	}

	async function onSubmitData(formData: PasswordChangeDto) {
		const model = {
			oldPassword: formData.oldPassword,
			newPassword: formData.newPassword,
		};

		try {
			setIsLoading(true);
			const response = await PasswordChangeService.changeUserPassword(
				model,
				activeAccount
			);
			if (response) {
				showSuccess(
					"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.SUCCESS_PWD_CHANGE"
				);
				user.removeUserFromLocalStorage();
				App.state.redirect(`/${culture}/auth/login`);
			} else {
				showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR");
			}
		} catch (error) {
			if (error.message) {
				showError(
					"USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error.message
				);
			}
		} finally {
			reset();
			setIsLoading(false);
		}
	}

	return {
		onInitialize,
		onSubmitData,
		userInfo,
		methods,
		user,
		activeAccount,
		setActiveAccount,
	};
}
