import {
	ChannelDataDto,
	ChannelDataResponseDto,
	ChannelDto,
	UserSessionStatusDto,
} from "@api-types/chat";
import { getApplicationHeaders, httpClient } from "@utils";

class ChatApiService {
	static get baseUrl(): string {
		return `${WEBPACK_CHAT_API_ADDRESS}/chat`;
	}

	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		appHeaders["Accept" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async fetchAmityApiInfo(): Promise<{
		apiKey: string;
		apiRegion: string;
	}> {
		return httpClient.get(
			`${this.baseUrl}/api-key`,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	static async fetchAuthToken(userId: string): Promise<{
		authenticationToken: string;
		displayName: string;
		userId: string;
	}> {
		//returns 400 status if user doesnt exist or something went wrong
		return httpClient.post(
			`${this.baseUrl}/user-authentication-token/${userId}`,
			null,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async fetchChannelData(
		channelData: ChannelDataDto
	): Promise<ChannelDataResponseDto> {
		return httpClient.post(
			`${this.baseUrl}/create-or-get-channel`,
			JSON.stringify(channelData),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async fetchUserChannels(
		username: string,
		culture: string,
		accessToken: string
	): Promise<ChannelDataResponseDto> {
		return httpClient.post(
			`${this.baseUrl}/user-channels`,
			JSON.stringify({ username, culture, accessToken }),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async revokeUserSession(
		userId: string
	): Promise<UserSessionStatusDto> {
		return httpClient.delete(
			`${this.baseUrl}/revoke-user-session/${userId}`,
			this.getRequestHeaders()
		);
	}

	static async updateChatName(
		chatName: string,
		agencyId: string,
		userId: string
	): Promise<void> {
		return httpClient.put(
			`${WEBPACK_BASE_ADDRESS}platform/agency-users/update-chat-name/${agencyId}/${userId}`,
			JSON.stringify(chatName),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async queryChannelInfo(
		channelId: string,
		culture: string
	): Promise<ChannelDto> {
		return httpClient.get(
			`${this.baseUrl}/channel/${channelId}?culture=${culture}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}
}

export default ChatApiService;
