import React from "react";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import OfferIcons from "@v2/components/shared/icons/Offer";
import classnames from "classnames";
import { useFormContext } from "react-hook-form";
import { TFormFilter } from "@v2/state/quick-play-store/QuickBetStore";

export default observer(function QuickBetFilterBar() {
	const rootStateStore = useRootAppStore();
	const quickBetStore = rootStateStore.quickPlayStore.quickBetStore;
	const { getValues } = useFormContext<TFormFilter>();

	if (
		quickBetStore.menuSportsList == null ||
		quickBetStore.menuSportsList.length == 1
	) {
		return null;
	}

	const anyButtonClasses = classnames(
		"tabs--secondary__item btn btn--sml btn--auto btn--icon",
		{
			"btn--tertiary": quickBetStore.selectedSportId !== "any",
			"btn--secondary is-active": quickBetStore.selectedSportId === "any",
		}
	);

	const anyIconClasses = classnames("tabs--secondary__circle u-icon--big", {
		"is-active": quickBetStore.selectedSportId === "any",
	});

	return (
		<div className="offer__tabs borderless u-padd--top--xsml">
			<nav className="tabs--secondary u-padd--top--xtny u-padd--bottom--xtny">
				<button
					className={anyButtonClasses}
					onClick={async () => {
						quickBetStore.setSelectedSportId("any");
						await quickBetStore.fetchData(getValues());
					}}
					key="any"
				>
					<span className={anyIconClasses}>
						<OfferIcons
							type="sport"
							sport=""
							className="tabs--secondary__icon u-icon u-icon--xmed"
						/>
					</span>
					<span>Multi</span>
				</button>
				{quickBetStore.menuSportsList?.map((sport) => {
					const buttonClasses = classnames(
						"tabs--secondary__item btn btn--sml btn--auto btn--icon",
						{
							"btn--tertiary":
								quickBetStore.selectedSportId !== sport.id,
							"btn--secondary is-active":
								quickBetStore.selectedSportId === sport.id,
						}
					);

					const iconClasses = classnames(
						"tabs--secondary__circle u-icon--big",
						{
							"is-active":
								quickBetStore.selectedSportId === sport.id,
						}
					);
					return (
						<button
							className={buttonClasses}
							onClick={async () => {
								quickBetStore.setSelectedSportId(sport.id);
								await quickBetStore.fetchData(getValues());
							}}
							key={sport.abrv}
						>
							<span className={iconClasses}>
								<OfferIcons
									type="sport"
									sport={sport.abrv || ""}
									className="tabs--secondary__icon u-icon u-icon--xmed"
								/>
							</span>
							<span>{sport.name}</span>
						</button>
					);
				})}
			</nav>
		</div>
	);
});
