import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons";
import { Footer } from "../footer";
import { Header } from "../header";
import { ErrorBoundaryHoc } from "@v2/hoc";
import { getCurrentCulture } from "@utils";
import { history } from "@state";
import { useNewRelicHandler } from "@v2/hooks/shared/useNewRelicLogger";

export const ErrorPage = ErrorBoundaryHoc(ErrorPageContent);

function ErrorPageContent(props: {
	error: any;
	errorInfo: any;
	reload: () => void;
	full?: boolean;
}) {
	const { error, errorInfo, full } = props;

	useNewRelicHandler(error);

	const redirectToHomePage = async () => {
		if (App.state.rootStore.userAuthStore.isLoggedIn) {
			await App.state.rootStore.userAuthStore.logoutUser();
		} else {
			App.state.rootStore.userAuthStore.removeUserFromLocalStorage();
		}
		//refresh whole page<
		window.location.href = `/${getCurrentCulture()}/home/full/highlights`;
	};

	if (full) {
		return (
			<>
				<Header />
				<main className="at-errorPageFull l--main l--main--full">
					<section className="l--content l--content--full">
						<div className="l--content__main">
							<div className="container">
								<div className="card--message">
									<div className="card--message__thumb" />
									<div className="card--message__body">
										<ErrorPageBody
											error={error}
											errorInfo={errorInfo}
											reload={redirectToHomePage}
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
				<footer className="l--footer">
					<Footer />
				</footer>
			</>
		);
	}

	return (
		<ErrorPageBodyNotFull
			error={error}
			errorInfo={errorInfo}
			reload={redirectToHomePage}
		/>
	);
}

function ErrorPageBody(props: {
	error: any;
	errorInfo: any;
	reload: () => void;
}) {
	const { error, errorInfo, reload } = props;

	const { t } = useTranslation();

	return (
		<div className="card--message__content">
			<i className="card--message__icon u-icon u-icon--xlrg u-icon--remove-circle u-icon--animated--bounce-in u-color--ui--danger" />
			<div className="card--message__title">
				{t<string>("ERROR.TITLE")}
			</div>
			<div className="u-mar--bottom--xsml u-type--left">
				{t<string>("ERROR.MESSAGE")}
			</div>
			<div className="u-type--left">
				{t<string>("ERROR.MESSAGE_DETAILS")}
			</div>
			<details
				className="u-display--none u-mar--top--xsml"
				style={{
					whiteSpace: "pre-wrap",
				}}
			>
				<div className="u-mar--bottom--xsml">
					{error && error.toString()}
				</div>
				<div>{errorInfo?.componentStack}</div>
			</details>
			<div className="card--message__footer">
				<Button
					className="btn--lrg btn--block btn--primary btn--icon btn--animate-rotate"
					atClassName="errorPageFullBtn"
					type="button"
					onClick={() => history.replace("/")}
					btnContent={
						<>
							<i className="u-icon u-icon--sml u-icon--refresh u-color--core--snow" />
							<span>{t("ERROR.404.BACK_TO_HOME")}</span>
						</>
					}
				/>
				<div className="u-mar--bottom--xsml">
					{t("ERROR.404.RESET_SESSION_SECTION")}
				</div>
				<Button
					className="btn--lrg btn--block btn--primary btn--icon btn--animate-rotate"
					atClassName="errorPageFullBtn"
					type="button"
					onClick={() => reload()}
					btnContent={
						<>
							<i className="u-icon u-icon--sml u-icon--refresh u-color--core--snow" />
							<span>{t("ERROR.RESET_SESSION_LABEL")}</span>
						</>
					}
				/>
			</div>
		</div>
	);
}

function ErrorPageBodyNotFull(props: {
	error: any;
	errorInfo: any;
	reload: () => void;
}) {
	const { t } = useTranslation();
	const { error, errorInfo, reload } = props;

	return (
		<div className="at-errorPage card--message u-mar--top--xsml">
			<div className="card--message__thumb" />
			<div className="card--message__body">
				<div className="card--message__content">
					<i className="card--message__icon u-icon u-icon--xlrg u-icon--remove-circle u-icon--animated--bounce-in u-color--ui--danger" />
					<div className="card--message__title">
						{t<string>("ERROR.TITLE")}
					</div>
					<div className="u-mar--bottom--xsml u-type--left">
						{t<string>("ERROR.MESSAGE")}
					</div>
					<div className="u-type--left">
						{t<string>("ERROR.MESSAGE_DETAILS")}
					</div>
					<details
						className="u-display--none u-mar--top--xsml"
						style={{ whiteSpace: "pre-wrap" }}
					>
						<div className="u-mar--bottom--xsml">
							{error && error.toString()}
						</div>
						<div>{errorInfo?.componentStack}</div>
					</details>

					<div className="card--message__footer">
						<Button
							className="btn--lrg btn--block btn--secondary--inverted btn--icon"
							atClassName="errorPageFullBtn"
							type="button"
							onClick={() => history.replace("/")}
							btnContent={
								<>
									<i className="u-icon u-icon--sml u-icon--nav--home" />
									<span>{t("ERROR.404.BACK_TO_HOME")}</span>
								</>
							}
						/>
						<div className="u-mar--bottom--xsml">
							{t("ERROR.404.RESET_SESSION_SECTION")}
						</div>
						<Button
							atClassName="errorPageBtn"
							className="btn btn--lrg btn--block btn--primary btn--icon btn--animate-rotate"
							type="button"
							onClick={() => reload()}
							btnContent={
								<>
									<i className="u-icon u-icon--sml u-icon--refresh u-color--core--snow" />
									<span>
										<span>
											{t("ERROR.RESET_SESSION_LABEL")}
										</span>
									</span>
								</>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
