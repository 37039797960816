import React, { useCallback } from "react";

export default function useReplaceOgMetaTags(tags: OGMetaTags) {
	const defaultValuesRef = React.useRef<OGMetaTags>([]);

	const setDefaultValues = useCallback(() => {
		tags.forEach((tag) => {
			const contentValue = document.head
				.querySelector(`meta[property="${tag.property}"]`)
				?.getAttribute("content");
			if (contentValue) {
				defaultValuesRef.current.push({
					property: tag.property,
					content: contentValue,
				});
			}
		});
	}, []);

	const updateTags = useCallback((fields: OGMetaTags) => {
		fields.forEach((tag) => {
			document.head
				.querySelector(`meta[property="${tag.property}"]`)
				?.setAttribute("content", tag.content);
		});
	}, []);

	React.useEffect(() => {
		setDefaultValues();

		updateTags(tags);

		return () => {
			updateTags(defaultValuesRef.current);
		};
	}, []);

	return null;
}

type OGMetaTags = {
	property: "og:title" | "og:image" | "og:description";
	content: string;
}[];
