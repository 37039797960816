import React, { useMemo, useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { WindowsScrollerWrapper } from "@lib/virtualization-components";
import LiveSkeletonLoader from "./live-virtualized-offer-components/LiveSkeletonLoader";
import SportsList from "./live-virtualized-offer-components/LiveVirtualizedOfferSports";
import { useReturnToScroll } from "@hooks/useReturnToScroll";

export default observer(function VirtualizedOfferInner(props: {
	store: {
		isEmpty: boolean;
		isLoading: boolean;
		isFetchingData: boolean;
		isStoreInitialized: boolean;
		period: string;
	} & Parameters<typeof SportsList>[0]["store"];
}) {
	const isWorking = useReturnToScroll(
		props.store.isLoading ||
			props.store.isFetchingData ||
			!props.store.isStoreInitialized,
		null
	);

	const [height, setHeight] = useState<number>(0);
	const [el, setEl] = useState<HTMLElement | null>(null);

	//#region observe element width

	const setWidthRef = useRef(() => {});
	setWidthRef.current = () => setHeight(el?.clientHeight || 0);

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver(() => {
				setWidthRef.current();
			}),
		[]
	);

	useEffect(() => {
		el && resizeObserver.observe(el);
	}, [el]);

	useEffect(() => {
		return () => resizeObserver.disconnect();
	}, []);

	if (props.store.isLoading || isWorking) {
		return <LiveSkeletonLoader />;
	}

	return (
		<div
			ref={(newRef) => setEl(newRef)}
			className="offer offer--virtual offer--live offer--full"
		>
			<WindowsScrollerWrapper scrollElement={null}>
				<SportsList
					store={props.store}
					isScrollRestoreWorking={isWorking}
					listHeight={height}
				/>
			</WindowsScrollerWrapper>
		</div>
	);
});
