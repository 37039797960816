import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const Button = forwardRef(
	(
		{
			className,
			btnContent,
			btnText,
			isDisabled,

			// Icons
			iconLeft = false,
			iconRight = false,
			iconOnly = false,
			iconClassName = undefined,
			switchIcon,

			//Test classes
			atClassName = undefined,

			//Types
			btnType = "submit",
			isLoading = false,
			isMostPlayed,

			...rest
		}: PropTypes,
		ref: React.LegacyRef<HTMLButtonElement> | undefined
	) => {
		const { t } = useTranslation();

		const btnClasses = classNames(
			{
				[`at-${atClassName}`]: atClassName,
			},
			"btn",
			className,
			{
				"btn--icon": iconLeft || iconRight || iconOnly,
			}
		);

		const iconClasses = classNames("btn__icon u-icon", iconClassName);

		const loaderClasses = classNames("btn--load", {
			"btn--load--primary": isMostPlayed,
		});

		return (
			<button
				className={btnClasses}
				type={btnType}
				ref={ref}
				{...rest}
				disabled={isDisabled}
			>
				{iconLeft || iconOnly ? (
					switchIcon && isLoading ? (
						<span className={loaderClasses}></span>
					) : (
						<i className={iconClasses} />
					)
				) : null}

				{btnText && !isLoading && (
					<div className="btn__label">{t(btnText)}</div>
				)}

				{!switchIcon && isLoading ? (
					<span className={loaderClasses}></span>
				) : null}

				{btnContent != undefined && btnContent}

				{iconRight ? (
					switchIcon && isLoading ? (
						<span className={loaderClasses}></span>
					) : (
						<i className={iconClasses} />
					)
				) : null}
			</button>
		);
	}
);

export default forwardRef(function (
	props: Omit<Parameters<typeof Button>[0], "ref">,
	ref?: Parameters<typeof Button>[0]["ref"]
) {
	return <Button {...props} ref={ref} />;
});

type PropTypes = {
	className: string;
	btnContent?: JSX.Element;
	btnText?: string;
	isDisabled?: boolean;

	// Icons
	iconLeft?: boolean;
	iconRight?: boolean;
	iconOnly?: boolean;
	iconClassName?: string;
	switchIcon?: boolean;

	//Test classes
	atClassName?: string;

	//Types
	btnType?: string;

	isLoading?: boolean;
	isMostPlayed?: boolean;
} & React.HTMLProps<HTMLButtonElement> &
	React.ButtonHTMLAttributes<HTMLButtonElement>;
