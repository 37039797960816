import React, { useContext } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";
import { OfferStoreContext } from "@gp/components";

export const ToggleIndicator = observer(function ToggleIndicator(props: any) {
	const {
		sportMenuItem: {
			checkState,
			node: { id },
		},
		isSelected,
	} = props;

	const {
		// @ts-expect-error
		periodSubMenu: { selectedPeriodKey },
		// @ts-expect-error
		isOfferMenuOpen,
		// @ts-expect-error
		isExpandDisabled,
		// @ts-expect-error
		selectedSport,
	} = useContext(OfferStoreContext);

	//checkState > 0 && (selectedPeriodKey === 'events' && selectedSport != null || selectedPeriodKey !== 'events');
	const isEnabled =
		(selectedPeriodKey === "events" && checkState > 0) ||
		(["live-highlights", "upcoming"].includes(selectedPeriodKey) &&
			!isExpandDisabled);

	if (!isEnabled) {
		return null;
	}

	const isExpanded = selectedSport?.node?.id === id && isOfferMenuOpen;

	const toggleClasses = classnames(
		"nav--secondary__toggle u-icon u-icon--tny",
		{
			"u-icon--caret--right u-color--core--snow": !isExpanded && isSelected,
			"u-icon--close u-color--core--snow": isExpanded && isSelected,
			"u-icon--caret--right": !isExpanded && !isSelected,
			"u-icon--close": isExpanded && !isSelected,
		}
	);

	return <i className={toggleClasses} />;
});