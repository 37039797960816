import classNames from "classnames";
import React, { useState, Suspense } from "react";
import { Switch } from "react-router-dom";
import { Footer } from "@v2/components/desktop/shared/footer";
import { HeaderWithSideMenu } from "@v2/components/desktop/shared/header";
import { NoMatchRoute, RouteElement } from "@v2/components/shared/navigation";
import { AdministrationSideMenu } from "@v2/components/desktop/shared/side-menu";
import {
	LimitsHistoryPage,
	PersonalDataPage,
	AccountLockPage,
	EmailChangePage,
	PasswordChangePage,
	MyLimitsPage,
} from "@v2/components/desktop/account-settings";

import { PasswordConfirmationContainer } from "@v2/containers";
import { MyBonusesPage } from "@components-desktop/account-settings/my-bonuses";
import { AutomaticWithdrawalPage } from "@components-desktop/account-settings/automatic-withdrawal";
import { useUser } from "@v2/hooks";
import { AccountVerificationPage } from "@v2/components/desktop/account-verification";
import Loader from "@v2/components/desktop/shared/loader/Loader";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";

//#region lazy imports

import { getCurrentCulture } from "@utils";

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const GamingSummaryPage = lazy(loadFailPath, () => import("@v2/components/desktop/gaming-summary/GamingSummaryPage"));

//#regionend lazy

export default function AccountSettingsLayoutTemplate(props: {
	prematchOfferMenuStore: PrematchOfferMenuStore;
}) {
	const [isSideMenuExpanded, setIsSideMenuExpanded] = useState(true);

	const sideMenuClassName = classNames("l--aside--left", {
		"is-expanded": isSideMenuExpanded,
	});

	const mainContainerClassName = classNames("l--main l--main--med", {
		"sidebar-left-expanded": isSideMenuExpanded,
	});
	const user = useUser();

	return (
		<PasswordConfirmationContainer>
			<HeaderWithSideMenu />
			<main role="main" className={mainContainerClassName}>
				<aside className={sideMenuClassName}>
					<AdministrationSideMenu
						setIsSideMenuExpanded={setIsSideMenuExpanded}
						isSideMenuExpanded={isSideMenuExpanded}
						isUserSettingsActive
					/>
				</aside>
				<section className="l--content l--content--full">
					<Switch>
						<RouteElement
							exact
							path={[
								"/:culture/account-settings/personal-data",
								"/:culture/account-settings/",
							]}
						>
							<PersonalDataPage />
						</RouteElement>
						<RouteElement
							exact
							path="/:culture/account-settings/account-verification"
						>
							<AccountVerificationPage />
						</RouteElement>
						<RouteElement
							exact
							path="/:culture/account-settings/password-change"
						>
							<PasswordChangePage />
						</RouteElement>

						<RouteElement
							exact
							path="/:culture/account-settings/email-change"
						>
							<EmailChangePage />
						</RouteElement>

						<RouteElement
							exact
							path={[
								"/:culture/account-settings/my-limits",
								"/:culture/account-settings/my-limits/:tab_id/:accountType",
							]}
						>
							<MyLimitsPage />
						</RouteElement>

						<RouteElement
							exact
							path="/:culture/account-settings/limits-history"
						>
							<LimitsHistoryPage />
						</RouteElement>

						<RouteElement
							exact
							path="/:culture/account-settings/my-bonuses"
						>
							<MyBonusesPage />
						</RouteElement>

						{user.isAutoWithdrawalEnabled ? (
							<RouteElement
								exact
								path="/:culture/account-settings/automatic-withdrawal"
							>
								<AutomaticWithdrawalPage />
							</RouteElement>
						) : null}
						<RouteElement
							exact
							path={[
								"/:culture/account-settings/account-lock",
								"/:culture/account-settings/account-lock/:pagename",
							]}
						>
							<AccountLockPage />
						</RouteElement>
						<RouteElement
							exact
							path="/:culture/account-settings/gaming-summary"
						>
							<GamingSummaryPage />
						</RouteElement>
						<NoMatchRoute />
					</Switch>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</PasswordConfirmationContainer>
	);
}
