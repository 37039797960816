import React, { useState } from "react";
import classNames from "classnames";
import { useFormContext, Controller } from "react-hook-form";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import useLocaleDateFormatForDatePicker from "@v2/hooks/shared/useLocaleDateFormatForDatePicker";
import { RequiredMark, PopoverMessage, ErrorMessage } from ".";
import { getCurrentCulture } from "@utils";

//#region lazy

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
const loadFailPath = `/${getCurrentCulture()}/app-update`;
const ReactDatePicker = lazy(loadFailPath, () =>
	import("react-datetime-picker/dist/entry.nostyle")
);

//#endregion lazy

export default function DatePicker(props) {
	const {
		label,
		name,
		className,
		labelClassName,
		errorMessage = undefined,
		required = false,
		disabled = false,
		calendarDisabled = false,
		readOnly = false,
		hasBorder = false,
		validate,
		format,
		disableClearIcon,
		popoverMsg = null,
		...dateTimePickerProps
	} = props;

	const { t } = useTranslation();
	const { control } = useFormContext;
	const currentDateFormat = useLocaleDateFormatForDatePicker();

	const wrapperClassNames = classNames({
		"react-datetime-picker--readonly": readOnly,
		"react-datetime-picker--bordered": hasBorder,
	});

	return (
		<div className={className}>
			<Label data={props} />
			<ErrorMessage name={name} />
			<Controller
				name={name}
				control={control}
				rules={{
					required: {
						value: required,
						message: errorMessage || "COMMON.REQUIRED_FIELD",
					},
					validate: validate,
				}}
				render={({ field }) => (
					<ReactDatePicker
						className={wrapperClassNames}
						value={
							field.value
								? DateTime.fromISO(field.value).toJSDate()
								: undefined
						}
						onChange={(date) =>
							field.onChange(
								date ? date.toISOString() : undefined
							)
						}
						locale={getCurrentCulture()}
						dayPlaceholder={t("COMMON.DAY_PLACEHOLDER")}
						monthPlaceholder={t("COMMON.MONTH_PLACEHOLDER")}
						yearPlaceholder={t("COMMON.YEAR_PLACEHOLDER")}
						format={format || currentDateFormat}
						disableCalendar={calendarDisabled}
						clearIcon={
							disabled || disableClearIcon ? null : undefined
						}
						disabled={disabled}
						{...dateTimePickerProps}
					/>
				)}
			/>
		</div>
	);
}

function Label(props) {
	const { t } = useTranslation();

	const [mouseOverState, setMouseOverState] = useState(false);
	if (!props.data.label) {
		return null;
	}
	return (
		<label
			className={classNames("label", props.data.labelClassName)}
			onMouseEnter={() => setMouseOverState(true)}
			onMouseLeave={() => setMouseOverState(false)}
		>
			{t(props.data.label)}{" "}
			<RequiredMark required={props.data.required} />
			<PopoverMessage
				popoverMsg={props.data.popoverMsg}
				mouseOverState={mouseOverState}
			/>
		</label>
	);
}
