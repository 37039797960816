import React from "react";
import { useUser } from "@v2/hooks";

export function Currency(props: { component?: string; className?: string }) {
	const userStore = useUser();

	return (
		<>
			{props.component != null && props.className != null
				? React.createElement(
						props.component,
						props,
						userStore.currency
				  )
				: userStore.currency}
		</>
	);
}
