import { action, runInAction, autorun } from "mobx";
import { BasePopupStore } from ".";
import { getCurrentCulture } from "@utils";

class AutomaticLogoutStore extends BasePopupStore {
    constructor(rootStore: typeof App.state.rootStore) {
        super(rootStore);

        autorun(() => {
            if (this.rootStore.userAuthStore.isLoggedIn && this.rootStore.userAuthStore.autoLogoutPeriod !== null) {
                this.startTimer();
            } else {
                this.clearTimer();
            }
        });
    }

    @action.bound
    private startTimer() {
        let automaticLogoutTimeStamp: number | string | null | undefined =
            App.state.rootStore.userAuthStore.autoLogoutPeriod;

        if (automaticLogoutTimeStamp == null && this.rootStore.userAuthStore.loginHistory?.currentLogin != null) {
            const lastLoginTimeStamp = new Date(this.rootStore.userAuthStore.loginHistory?.currentLogin).getTime();

            automaticLogoutTimeStamp =
                lastLoginTimeStamp +
                (this.rootStore.userAuthStore.autoLogoutPeriod || new Date().getTime()) * 60 * 60 * 1000;
        }

        if (automaticLogoutTimeStamp != null) {
            const triggerInMs = automaticLogoutTimeStamp - new Date().getTime();

            this.clearTimer();
            this.timerId = setTimeout(async () => {
                const isPrivateRouteActive =
                    location.pathname.includes("account-settings") ||
                    location.pathname.includes("account-statement") ||
                    location.pathname.includes("my-bets");

                const deactivateRestOfThePopups = () => {
                    App.state.rootStore.realityCheckStore.deactivatePopup();
                };

                if (this.rootStore.userAuthStore.isLoggedIn) {
                    runInAction(async () => {
                        if (isPrivateRouteActive) {
                            deactivateRestOfThePopups();
                            this.deactivatePopup();
                        } else {
                            deactivateRestOfThePopups();
                            this.activatePopup();
                        }
                    });

                    await App.state.rootStore.userAuthStore.logoutUser({
                        isAutomaticLogout: true,
                        isRealityCheckLogout: false,
                    });

                    if (isPrivateRouteActive) {
                        const url = window.location.pathname;
                        const returnUrl = url.substring(3);

                        App.state.redirect(
                            `/${getCurrentCulture()}/auth/login?isAutomaticLogout=true&returnUrl=${returnUrl}`
                        );
                    }
                }
            }, triggerInMs);
        }
    }
}

export default AutomaticLogoutStore;
