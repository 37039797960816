import { action, computed, observable } from "mobx";
import { IStoreLifeCycle, IConsoleLogger } from "@interface";
import {
	LSWidgetElement,
	WidgetInfo,
	WidgetLocation,
} from "@features/live-widgets/shared";

export class LiveStreamState implements IStoreLifeCycle {
	private logger: IConsoleLogger;

	@observable private lssDisabled = false;
	@observable private elements: LSWidgetElement[] = [];
	@observable isStoreInitialized: boolean = false;

	@computed public get activeWidgets() {
		return this.elements;
	}

	@computed public get findActiveLiveStream() {
		return (
			eventId: string,
			location: WidgetLocation
		): LSWidgetElement | undefined => {
			return this.activeWidgets.find(
				(w) => w.location == location && w.eventId === eventId
			);
		};
	}

	@computed public get sideWidgetVisible() {
		return this.isInQueue({ eventId: "", location: "SIDE" });
	}

	@computed public get activeSideWidget() {
		return this.activeWidgets.find((w) => w.location === "SIDE");
	}

	@computed get isEmpty(): boolean {
		return this.elements.length === 0;
	}

	//#region constructor

	constructor(logger: IConsoleLogger) {
		this.logger = logger;
	}

	@action.bound
	onInitialize() {
		this.isStoreInitialized = true;
	}

	//#endregion constructor

	//#region add remove widget

	@action.bound
	toggleWidgetPin(eventId: string, location: WidgetLocation): void {
		const widget = this.activeWidgets.find(
			(w) => w.eventId === eventId && location === location
		);
		if (widget == null) {
			return;
		}

		this.updateItem({ ...widget, isPinned: !widget.isPinned });
	}

	/** Toggles widget for given eventId on location. */
	@action.bound
	toggleLiveStream(
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,
		location: WidgetLocation
	): void {
		if (this.findActiveLiveStream(eventId, location)) {
			this.removeLiveStream(eventId, location);
		} else {
			this.addLiveStream(
				matchId,
				eventId,
				teamOneName,
				teamTwoName,
				location
			);
		}
	}

	@action.bound
	toggleSideWidget(
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,
		activeSideWidget: LSWidgetElement | undefined,
		isMultiEvent: boolean
	): void {
		if (!activeSideWidget && !isMultiEvent) {
			this.addLiveStream(
				matchId,
				eventId,
				teamOneName,
				teamTwoName,
				"SIDE"
			);
		} else if (
			this.findActiveLiveStream(matchId, "SIDE") &&
			activeSideWidget?.matchId === matchId
		) {
			this.removeLiveStream(eventId, "SIDE");
		}
	}

	/** Add ls widget, removes one from queue if it is at max length */
	@action.bound
	addLiveStream(
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,
		location: WidgetLocation
	): void {
		if (this.lssDisabled || !matchId) {
			if (location === "SIDE") {
				this.removeLiveStream("", "SIDE");
			}
			return;
		}

		if (!this.isInQueue({ eventId, location })) {
			this.elements.push({
				type: "LS",
				matchId,
				eventId,
				location,
				teamOneName,
				teamTwoName,
			});
		} else {
			this.updateItem({
				type: "LS",
				location,
				eventId,
				matchId,
				teamOneName,
				teamTwoName,
			});
		}
	}

	/** Update item for given search object if it is in queue. */
	@action.bound
	public updateItem(newItem: LSWidgetElement): void {
		const index = this.elements.findIndex((el) =>
			this.filterPredicate(el, {
				eventId: newItem.eventId,
				location: newItem.location,
			})
		);
		if (index < 0) return;
		if (index < 0 || index > this.elements.length) {
			this.logger.logTrace("Update element on index out of bounds");
			return;
		}
		this.elements[index] = newItem;
	}

	/** @param element remove from queue. */
	@action.bound
	remove(element: WidgetInfo): void {
		if (this.isEmpty) {
			return;
		}

		const elemIdx = this.elements.findIndex((el) =>
			this.filterPredicate(el, element)
		);

		if (elemIdx > -1) {
			this.elements.splice(elemIdx, 1);
		}
	}

	@action.bound
	updateSideWidget(newWidget: Omit<LSWidgetElement, "options">) {
		this.updateItem(newWidget);
	}

	/** Disable lmts and disposes all active widgets */
	@action.bound
	removeLiveStream(eventId: string, location: WidgetLocation): void {
		this.remove({ eventId, location });
	}

	//#endregion add remove widget

	//#region helpers

	/** @returns element is in queue based on passed predicate */
	public isInQueuePredicate(
		element: WidgetInfo,
		predicate: (a: LSWidgetElement, b: WidgetInfo) => boolean
	): boolean {
		if (element == null || predicate == null) {
			this.logger.logError("Element or predicate are null.");
			return false;
		}

		return this.elements.findIndex((el) => predicate(el, element)) > -1;
	}

	@action.bound
	private filterPredicate(w: LSWidgetElement, searchArgs: WidgetInfo) {
		return (
			(searchArgs.location === "SIDE" && w.location === "SIDE") ||
			(w.location == searchArgs.location &&
				w.eventId === searchArgs.eventId)
		);
	}

	/** @returns element is in queue based on filterPredicate given to constructor */
	public isInQueue(element: WidgetInfo): boolean {
		if (this.filterPredicate) {
			return this.isInQueuePredicate(element, this.filterPredicate);
		}

		return false;
	}

	//#endregion helpers

	//#region disposers

	/** Disable lmts and disposes all active widgets */
	@action.bound
	disableLss(): void {
		this.logger.logWarn("lss disabled");
		this.lssDisabled = true;
		this.onDispose();
	}

	/** Remove all active widgets */
	@action.bound
	onDispose(): void {
		this.elements = [];
		this.isStoreInitialized = false;
	}

	//#endregion disposers
}
