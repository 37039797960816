import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EventContext } from "@gp/components";

export const BackButton = observer(function BackButton() {
	const { t } = useTranslation();

	const location = useLocation();
	const event = useContext(EventContext);

	const urlParams = new URLSearchParams(location.search);

	let msgKey = "COMMON.BACK";

	if (urlParams.has("submitted")) {
		msgKey = "FOOTER.MY_BETS";
	}

	return (
		<button
			className="btn btn--tny btn--ghost btn--icon u-padd--x--reset"
			onClick={App.state.history.goBack}
		>
			<i className="u-icon u-icon--xsml u-icon--arrow--left" />
			{msgKey && <span>{t<string>(msgKey)}</span>}
		</button>
	);
});
