import React from "react";
import { observer } from "mobx-react";
import { useRootAppStore } from "@hooks";
import { ChatEntryPoint } from "@data-types/chat";
import classNames from "classnames";
import { ChatSportIcon } from "../shared";

export default observer(function ChatSportsHeader() {
	const { chatViewStore } = useRootAppStore();

	return (
		<nav className="chat__nav">
			{chatViewStore?.chatListHandler?.channelList.map((channel) => (
				<button
					// style={{
					// 	margin: "0 5px",
					// 	cursor: "pointer",
					// 	backgroundColor:
					// 		channel.channelId ===
					// 		chatViewStore.chatChannelHandler
					// 			.activeChannelInfo?.channelId
					// 			? "darkred"
					// 			: "",
					// }}
					className={classNames(
						"chat__nav__btn btn btn--icon btn--ghost",
						{
							"is-active":
								channel.channelId ===
								chatViewStore.chatChannelHandler
									.activeChannelInfo?.channelId,
						}
					)}
					key={channel.channelId}
					onClick={async () => {
						await chatViewStore.onInitialize(ChatEntryPoint.LIST, {
							channelId: channel.channelId,
							displayName: channel.displayName,
							sportAbrv: channel.sportAbrv,
							categoryAbrv: channel.categoryAbrv,
							tournamentAbrv: channel.tournamentAbrv,
						});
					}}
				>
					<ChatSportIcon
						sportAbrv={channel.sportAbrv || "multi"}
						iconClassName={"u-icon u-icon--big"}
						isActive={
							channel.channelId ==
							chatViewStore.chatChannelHandler.activeChannelInfo
								?.channelId
						}
					/>
				</button>
			))}
		</nav>
	);
});
