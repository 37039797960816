import {
	observable,
	action,
	runInAction,
	IReactionDisposer,
	reaction,
} from "mobx";

import { TRootStore } from "../BaseOfferMenuStore";
import FavoritesStore from "@offer/stores/FavoritesStore";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { getCurrentCulture } from "@utils";
import { TOfferRequest } from "@gp/hub";
import { EventType, LiveStatus } from "@gp/models";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

const PrematchOfferApiService = lazy(
	loadFailPath,
	() => import("@api-services/offer/PrematchOfferApiService")
);

export class FavoriteTournamentsCounterStore {
	//#region observable
	rootStore: TRootStore;
	favoritesStore: FavoritesStore;

	@observable tournamentFavoritesCount = 0;

	favoriteTournamentIdsReaction: IReactionDisposer | null;

	//#endregion observable

	//#region constructor

	constructor(rootStore: TRootStore, favoritesStore: FavoritesStore) {
		this.rootStore = rootStore;
		this.favoritesStore = favoritesStore;
	}

	//#endregion constructor
	async onInitialize() {
		this.favoriteTournamentIdsReaction = reaction(
			() => ({
				favoriteTournamentsIds:
					this.favoritesStore.userFavoriteTournamentsSet,
				period: this.favoritesStore.period,
			}),
			() => {
				runInAction(() => {
					this.fetchFavoriteTournaments();
				});
			},
			{
				fireImmediately: true,
			}
		);
	}

	//#region fetching data

	@action.bound
	async fetchFavoriteTournaments() {
		if (this.favoritesStore.userFavoriteTournamentsSet.size === 0) {
			this.tournamentFavoritesCount = 0;
			return;
		}

		const favoriteTournamentFilter: TOfferRequest = {
			filter: {
				tournamentId: {
					eq: [...this.favoritesStore.userFavoriteTournamentsSet],
				},
				liveStatus: LiveStatus.PREMATCH,
				offers: null,
				isOutright: false,
				eventType: EventType.NORMAL,
			},

			grouping: "tournamentGroup",
		};

		if (this.favoritesStore.period != "full") {
			favoriteTournamentFilter.filter = {
				...favoriteTournamentFilter.filter,
				...this.favoritesStore.getTime(),
			};
		}

		try {
			const favTournamentCount = await (
				await PrematchOfferApiService
			).default.GetOffer(favoriteTournamentFilter);

			runInAction(() => {
				this.tournamentFavoritesCount = Object.keys(
					favTournamentCount.lookup?.tournaments || {}
				).length;
			});
		} catch (e) {
			console.error(e);
		}
	}
	//#endregion fetching data

	//#region actions

	//#endregion actions

	//#region disposers

	@action.bound
	onDispose() {
		this.tournamentFavoritesCount = 0;
		this.disposeSubscription();
	}

	@action.bound
	disposeSubscription() {
		if (this.favoriteTournamentIdsReaction) {
			this.favoriteTournamentIdsReaction();
			this.favoriteTournamentIdsReaction = null;
		}
	}

	//#endregion disposers

	//#region utility

	//#endregion utility
}
