import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";

import { EventContext } from "@gp/components";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import ToggleWidgetFloatButton from "./ToggleWidgetFloatButton";
import { TSideWidgetView } from "./TSideWidgetView";
import LmtLsEventsStore from "./LmtLsEventStore";
import LiveStreamToggleButton from "../../offer/buttons/LiveStreamToggleButton";
import SideWidgetHeaderTitle from "./SideWidgetHeaderTitle";

export default observer(function SideWidgetHeader(props: {
	setHeaderRef: (header: HTMLDivElement | null) => void;
	headerRef: HTMLDivElement | null;

	setView: (view: TSideWidgetView) => void;
	currentView: TSideWidgetView;
	lmtLsStore: LmtLsEventsStore;
}) {
	const t = useTranslation().t;

	const widgetStore = useLiveWidgetStore();
	const activeWidget = widgetStore.activeSideWidget;

	useEffect(() => {
		switch (activeWidget?.type) {
			case "LMT":
				props.setView("LIVE_MATCH_TRACKER");
				break;
			case "LS":
				props.setView("LIVE_STREAM");
				break;
			default:
				// Empty
				break;
		}
	}, [activeWidget?.type, activeWidget?.eventId]);

	const event = props.lmtLsStore.eventsMap.get(activeWidget?.eventId || "");

	const homeTeam =
		props.lmtLsStore.lookupsStore.teams.get(event?.teamOneId || "")?.name ||
		"";
	const awayTeam =
		props.lmtLsStore.lookupsStore.teams.get(event?.teamTwoId || "")?.name ||
		"";

	const matchId = event?.source?.matchId;
	const sport = props.lmtLsStore.lookupsStore.sports.get(
		event?.sportId || ""
	);

	const headerClasses = classNames("widget__header", {
		"widget__header--collapsed": widgetStore.isSideWidgetCollapsed,
		"widget__header--expanded": !widgetStore.isSideWidgetCollapsed,
		"widget--draggable__header": widgetStore.isWidgetFloating,
	});

	const simulationIconClasses = classNames("u-icon u-icon--xmed", {
		[`u-icon--field--active u-icon--field--${sport?.abrv}--active u-color--text--primary`]:
			activeWidget?.type !== "LMT",
		[`u-icon--field--selected u-icon--field--${sport?.abrv}--selected u-color--text--primary`]:
			activeWidget?.type === "LMT",
	});

	const listIconClasses = classNames(
		"u-icon u-icon--sml u-icon--list u-color--text--primary",
		{
			"u-icon--list--selected u-color--text--primary":
				props.currentView === "EVENT_LIST" &&
				!widgetStore.isSideWidgetCollapsed,
			"u-color--ui--muted": props.lmtLsStore.events.length <= 0,
		}
	);

	const tooltipId = "side-widget-header-tooltips";

	return (
		<>
			<div
				ref={(newRef) => props.setHeaderRef(newRef)}
				className={headerClasses}
			>
				{!widgetStore.isWidgetFloating && (
					<SideWidgetHeaderTitle
						lmtLsStore={props.lmtLsStore}
						isMainTitle={true}
					/>
				)}

				<div className="widget__actions">
					{/* LMT - Live Match Tracker */}
					{((event != null && activeWidget != null) ||
						(event == null && activeWidget?.type === "LMT")) && (
						<button
							type="button"
							className="widget__actions__btn btn btn--square btn--square--sml btn--ghost"
							onClick={() => {
								if (
									activeWidget?.type === "LMT" &&
									props.currentView === "LIVE_MATCH_TRACKER"
								) {
									widgetStore.removeWidget("", "SIDE");
									return;
								}
								if (widgetStore.isSideWidgetCollapsed) {
									widgetStore.expandSideWidget();
								}
								props.setView("LIVE_MATCH_TRACKER");
								if (matchId != null && event?.id != null) {
									widgetStore.addLmtWidget(
										sport?.abrv || "",
										event.source!.providerName,
										matchId,
										event?.id,
										homeTeam,
										awayTeam,
										"SIDE"
									);
								}
							}}
						>
							<i className={simulationIconClasses} />
						</button>
					)}

					{/* LS - Live Stream */}
					{((activeWidget != null &&
						(event?.liveStreamStatus === "live" ||
							event?.liveStreamStatus === "upcoming")) ||
						activeWidget?.type == "LS") && (
						// @ts-expect-error
						<EventContext.Provider value={event}>
							<LiveStreamToggleButton isForSideWidget />
						</EventContext.Provider>
					)}

					<ToggleWidgetFloatButton
						headerRef={props.headerRef}
						tooltipId={tooltipId}
					/>

					{/* Show events list */}
					<button
						type="button"
						className="widget__actions__btn widget__toggle btn btn--square btn--square--base btn--ghost"
						onClick={() => {
							props.setView("EVENT_LIST");
							if (props.currentView === "EVENT_LIST") {
								widgetStore.removeWidget("", "SIDE");
								widgetStore.toggleSideWidgetCollapse();
							} else {
								widgetStore.expandSideWidget();
							}
						}}
						disabled={props.lmtLsStore.events.length <= 0}
						data-tooltip-id={tooltipId}
						data-tooltip-content={t<string>(
							"LIVE_WIDGET.TOOLTIP.LIST"
						)}
						data-tooltip-position-strategy="fixed"
					>
						<i className={listIconClasses} />
					</button>

					<Tooltip
						id={tooltipId}
						className="react-tooltip--primary"
						place="bottom"
						closeOnResize
					/>
				</div>
			</div>

			{activeWidget != null && !widgetStore.isWidgetFloating && (
				<SideWidgetHeaderTitle
					lmtLsStore={props.lmtLsStore}
					isMainTitle={false}
				/>
			)}

			{widgetStore.isWidgetFloating && (
				<SideWidgetHeaderTitle
					lmtLsStore={props.lmtLsStore}
					isMainTitle={false}
				/>
			)}
		</>
	);
});
