import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorMessage, RequiredMark } from ".";
import classNames from "classnames";

export default function CheckboxField(props) {
	const { t } = useTranslation();
	const { register } = useFormContext();
	const {
		label,
		name,
		checkboxClassname,
		id,
		errorMessage = undefined,
		required = false,
		customLabel = null,
		labelClassName,
		astersisksClass,
		...rest
	} = props;

	const field = register(props.name, {
		required: {
			value: props.required,
			message: errorMessage || "COMMON.REQUIRED_FIELD",
		},
		disabled: props.disabled,
	});

	return (
		<div className={checkboxClassname}>
			<ErrorMessage name={name} />
			<input
				id={id}
				className="input--check"
				type="checkbox"
				name={name}
				{...rest}
				{...field}
			/>
			<label htmlFor={id} className={classNames(labelClassName)}>
				{customLabel ? (
					<span>
						{customLabel}{" "}
						<RequiredMark
							required={required}
							astersisksClass={astersisksClass}
						/>
					</span>
				) : (
					<span>
						{t(label)}{" "}
						<RequiredMark
							required={required}
							astersisksClass={astersisksClass}
						/>
					</span>
				)}
			</label>
		</div>
	);
}
