import { BetSlipExtended } from "@api-types/bet-slip/BetSlipExtendedDto";
import { DateTime } from "luxon";
import { isNullOrWhitespace } from "@v2/helpers/utility";
import { ISOToDisplayCode, UserTypes, getAgencyId, getShopId } from "@utils";
import LookupApiService from "@api-services/LookupApiService";
import { UserPaymentTransactionsApiService } from "@api-services/user/UserPaymentTransactionsApiService";

import { AccountStatementApiService } from "@api-services/account-settings/AccountStatementApiService";
import {
	AccountActivityReviewReportDto,
	BettingActivityReviewReportDto,
} from "@api-types/reports/ReportResultDto";
import { AccountStatementFilter } from "@api-types/account-settings/AccountStatementFilter";
import { BaseLookupDto } from "@api-types/lookups/BaseLookupDto";

import { ReportOptionsDto } from "@api-types/reports/ReportOptionsDto";
import { UserPaymentTransactionLimited } from "@api-types/account-settings/UserPaymentTransactionLimited";
import { BetSlipEvent } from "../../../data/api-types/bet-slip/BetSlipEvent";
import { UserAccountType } from "@common/constants";
import ReportApiService from "@api-services/reports/ReportApiService";
import { OfferService } from "@api-services/offer/OfferApiService";
import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const BetSlipApiService = lazy(loadFailPath, ()=>import("@api-services/bet-slip/BetSlipApiService"));

const typesWithoutDetails = [
	"deposit-casino-200",
	"withdrawal-virtual-game",
	"withdrawal-live-casino2-211",
	"deposit-casino",
	"deposit-live-casino2-210",
	"deposit-casino-2-206",
	"withdrawal-live-casino",
	"withdrawal-live-casino2",
	"deposit-virtual-game",
	"withdrawal-virtual-game-205",
	"bonus",
	"deposit-live-casino-202",
	"withdrawal-casino-201",
	"withdrawal-live-casino-203",
	"deposit-virtual-game-204",
	"withdrawal-casino-2-207",
	"deposit-live-casino",
	"withdrawal-casino",
	"cash-payment",
	"cash-payout",
	"withdrawal-paylado",
	"deposit-paylado",
	"deposit-cash-to-digital-paylado",
];

const availableUserWalletAbrvs = [
	UserAccountType.SportBettingAccountOnline,
	UserAccountType.SportBettingAccountShop,
	UserAccountType.SportBettingAccountShopOnline,
	UserAccountType.CasinoAccount,
	UserAccountType.GigAccount,
	UserAccountType.LiveCasinoAccount,
];

export const periods = [
	{ name: "ACC_STATEMENT.FILTER.TODAY", id: "today" },
	{ name: "ACC_STATEMENT.FILTER.LAST_3_DAYS", id: "last3days" },
	{ name: "ACC_STATEMENT.FILTER.LAST_7_DAYS", id: "last7days" },
	{ name: "ACC_STATEMENT.FILTER.LAST_14_DAYS", id: "last14days" },
	{ name: "ACC_STATEMENT.FILTER.LAST_30_DAYS", id: "last30days" },
	{ name: "ACC_STATEMENT.FILTER.LAST_60_DAYS", id: "last60days" },
	{ name: "ACC_STATEMENT.FILTER.CUSTOM", id: "custom" },
];

export type AccountStatementFilterData = {
	periodLookup: typeof periods;
	transactionLookup: {
		wallet: string;
		transactionTypes: void | BaseLookupDto[];
	}[];
	bettingAccountTypeIds: string;
	transactionLookupSecondAgency?: void | BaseLookupDto[];
};
export class Params {
	asPeriod: Periods | "custom";
	endDate: string;
	startDate?: string;
	asType: string;
	bettingAccountTypeIds: string;
}

export class AccountStatementService {
	static differentAgencyWallets: { [index: string]: boolean } = {};
	static async getFilterData(): Promise<AccountStatementFilterData> {
		try {
			let bettingAccountTypeIds =
				App.state.rootStore.userAuthStore.availableUserWallets![0];
			let transactionTypes = [];
			for (const wallet of App.state.rootStore.userAuthStore
				.userAccounts!) {
				if (
					availableUserWalletAbrvs.includes(wallet.abrv) &&
					App.state.rootStore.userAuthStore.availableUserWallets?.includes(
						wallet.accountTypeId
					)
				) {
					const userType: UserTypes =
						App.state.rootStore.userAuthStore.getWalletOwner(
							wallet.accountTypeId
						);
					transactionTypes.push({
						wallet: wallet.accountTypeId,
						transactionTypes:
							await LookupApiService.getAccountStatementTypes(
								userType,
								wallet.accountTypeId
							),
					});
				}
			}
			const filterData = {
				periodLookup: periods,
				transactionLookup: transactionTypes,
				bettingAccountTypeIds: bettingAccountTypeIds,
			};
			return filterData;
		} catch (error) {
			console.error(error);
			throw new Error("ERROR_OCCURED");
		}
	}

	//Get USER TRANSACTIONS BETS SLIP,ETC...
	static async fetchTransactionsTableData(
		model: Params,
		page: number = 1
	): Promise<TransactionTableResponse> {
		const isTransactionTypeValid = (transactionTypeId: string) => {
			return (
				!isNullOrWhitespace(transactionTypeId) &&
				transactionTypeId !== "all"
			);
		};

		try {
			const shopOnlineWallet: string[] | undefined =
				App.state.rootStore.userAuthStore.userAccounts
					?.filter(
						(type) =>
							type.abrv ===
								UserAccountType.SportBettingAccountShopOnline ||
							type.abrv === UserAccountType.CashToDigitalAccount
					)
					.map((wallets) => wallets.accountTypeId);

			const filterParametars: Parameters<
				typeof AccountStatementService["setupUserTransactionFilter"]
			>[0] = {
				page: page,
				dates:
					model.asPeriod == "custom"
						? null
						: this.fromToDates(model.asPeriod),
				rpp: 10,
				sort: "dateCreated|desc",
				embed: "transactionType,currency,BetSlipStatus,TransactionStatus,BetStatus",
				to: model.endDate,
				from: model.startDate,
				transactionTypesIds: isTransactionTypeValid(model.asType)
					? model.asType
					: "",
				bettingAccountTypeIds:
					model.bettingAccountTypeIds ==
					(shopOnlineWallet && shopOnlineWallet[0])
						? shopOnlineWallet
						: model.bettingAccountTypeIds,
			};

			const filter = this.setupUserTransactionFilter(filterParametars);

			const transactionQuery = this.createQuery(filter);

			const wallet = App.state.rootStore.userAuthStore.getWalletOwner(
				model.bettingAccountTypeIds
			);

			const result =
				await AccountStatementApiService.findAccountStatements(
					transactionQuery,
					wallet
				);

			return {
				transactions: this.filterAccountStatementDetails(result.item),
				totalRecords: result.totalRecords,
				page: result.page,
				recordsPerPage: result.recordsPerPage,
			};
		} catch (error) {
			console.error(error);
			throw new Error("ERROR_OCCURED");
		}
	}

	static async getBettingAndPaymentTableData(
		model: Params,
		bettingAccountTypeIds: string | undefined,
		activeWalletAbrv: string | undefined
	): Promise<UserActivityTypes | null> {
		const wallet = App.state.rootStore.userAuthStore.getWalletOwner(
			bettingAccountTypeIds
		);

		try {
			const reportFilter: ReportOptionsDto = {
				Filter: {
					AccessChannels: {
						Agencies: null,
						Shops: [
							{
								AgencyId: getAgencyId(wallet),
								Id: getShopId(wallet),
							},
						],
					},
					Parameters: [
						{
							Column: "StartDate",
							Value: "0",
						},
					],
				},
				Paging: null,
			};
			if (model.asPeriod == "custom") {
				const now = DateTime.now();
				const startDate = DateTime.fromISO(model.startDate);
				const endDate = DateTime.fromISO(model.endDate);
				let startDateCustom = now.diff(startDate, ["days"]).toObject()
					.days!;
				let endDateCustom = now.diff(endDate, ["days"]).toObject()
					.days!;
				reportFilter.Filter.Parameters = [
					{
						Column: "StartDate",
						Value: Math.floor(startDateCustom) + "",
					},
				];

				if (startDateCustom !== endDateCustom) {
					reportFilter.Filter.Parameters.push({
						Column: "EndDate",
						Value: Math.floor(endDateCustom) + "",
					});
				}
			} else {
				const period = AccountStatementService.fromToDates(
					model.asPeriod
				);
				const date1 = DateTime.fromISO(period.from);
				const date2 = DateTime.fromISO(period.to);
				const diffInDays = Math.floor(
					date2.diff(date1, ["days"]).toObject().days!
				);

				reportFilter.Filter.Parameters = [
					{
						Column: "StartDate",
						Value: diffInDays + "",
					},
				];
			}

			if (activeWalletAbrv != null) {
				reportFilter.Filter.Parameters.push({
					Column: "BettingAccountTypeAbrv",
					Value: activeWalletAbrv,
				});
			}

			const userActivityResult =
				await ReportApiService.fetchUserAccountActivity(
					reportFilter,
					wallet
				);

			const bettingActivityResult =
				await ReportApiService.fetchUserBettingActivity(
					reportFilter,
					wallet
				);

			let userActivity = null;
			let bettingActivity = null;

			if (
				userActivityResult.result != null &&
				userActivityResult.result.length > 0
			) {
				const activity = userActivityResult.result[0];
				if (
					"NumberOfTransactions" in activity &&
					activity.NumberOfTransactions > 0
				) {
					userActivity = activity;
				}
			}

			if (
				bettingActivityResult.result != null &&
				bettingActivityResult.result.length > 0
			) {
				const activity = bettingActivityResult.result[0];
				if ("NumberOfBets" in activity && activity.NumberOfBets > 0) {
					bettingActivity = activity;
				}
			}
			return {
				userActivity: userActivity,
				bettingActivity: bettingActivity,
			};
		} catch (error) {
			console.error(error);
			throw new Error("ERROR_OCCURED");
		}
	}

	static async getSlipDetails(
		betSlipId: string,
		bettingAccountTypeIds: string | undefined
	): Promise<BetSlipEventInOffer | null> {
		const wallet = App.state.rootStore.userAuthStore.getWalletOwner(
			bettingAccountTypeIds
		);

		try {
			const slip = await (
				await BetSlipApiService
			).default.getBet(
				betSlipId,
				"betSlipOffers,player.coreUser,betSlipStatus,betStatus,betSlipType,betSlipCombinations,bettingAccountType",
				wallet
			);

			slip.currency =
				App.state.rootStore.userAuthStore.userAccounts?.find(
					(acc) => acc.accountTypeId === slip.bettingAccountTypeId
				)?.currency.displayCode ||
				ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY);

			const initialEventIds = this.filterEventIds(slip.betSlipOffers);

			const eventIds = await this.filterEventIdsWithOffer(
				initialEventIds,
				wallet
			);

			const betSlipOfferMapped: BetSlipOfferIsEventInOffer[] =
				slip.betSlipOffers.map((offer) => ({
					...offer,
					isEventInOffer: offer.eventId
						? eventIds.includes(offer.eventId)
						: false,
				}));

			return { ...slip, betSlipOffers: { ...betSlipOfferMapped } };
		} catch (error) {
			console.error(error);
			throw new Error();
		}
	}

	static async getTransactionDetails(
		transactionId: string,
		bettingAccountTypeIds: string | undefined
	): Promise<UserPaymentTransactionLimited> {
		const wallet = App.state.rootStore.userAuthStore.getWalletOwner(
			bettingAccountTypeIds
		);

		try {
			const transaction =
				await UserPaymentTransactionsApiService.getTransactionDetails(
					transactionId,
					wallet
				);
			return transaction;
		} catch (error) {
			console.error(error);
			throw new Error();
		}
	}

	/**
	 * Filters event ids and returns only those that have offer
	 * @param {array} eventIds list of event ids
	 * @returns {Promise} array of event Ids that are in offer
	 */
	static async filterEventIdsWithOffer(
		eventIds: FilterEventId,
		wallet: UserTypes
	) {
		try {
			const response = await OfferService.findOffer(eventIds, wallet);

			const eventIdsInOffer =
				response.offerChanges?.updates?.map(
					(eventObj: { eventId: string }) => eventObj.eventId
				) || [];

			return eventIdsInOffer;
		} catch (err) {
			console.error(err);
		}
		// default in case of exception
		return [];
	}

	static filterEventIds(offers: BetSlipEvent[]): FilterEventId {
		const eventIds = offers.map((eventObj) => eventObj.eventId) || [];

		return {
			channels: [
				{
					name: "event",
					filter: [
						{
							startTime: {
								gt: DateTime.local()
									.plus({ days: -1 })
									.toUTC()
									.toString(),
							},
							id: {
								eq: eventIds,
							},
						},
					],
				},
			],
		};
	}

	static filterAccountStatementDetails(
		items: UserPaymentTransactionLimited[]
	): UserPaymentTransactionLimitedHasDetails[] {
		let mappedItems = items;
		return mappedItems.map(
			(item: UserPaymentTransactionLimitedHasDetails) => {
				item.hasDetails = !typesWithoutDetails.includes(
					item.transactionType.abrv
				);
				return item;
			}
		);
	}

	static setupUserTransactionFilter(filterParams: filterParams) {
		const filter: AccountStatementFilter = {
			embed: filterParams.embed,
			page: filterParams.page,
			rpp: filterParams.rpp,
			bettingAccountTypeIds: filterParams.bettingAccountTypeIds,
		};

		if (filterParams.dates != null) {
			filter.from = filterParams.dates.from;
			filter.to = filterParams.dates.to;
		} else {
			if (filterParams.to != null) {
				filter.to = filterParams.to;
			}
			if (filterParams.from != null) {
				filter.from = filterParams.from;
			}
		}

		if (!isNullOrWhitespace(filterParams.transactionTypesIds)) {
			filter.transactionTypeIds = filterParams.transactionTypesIds;
		}

		filter.bettingAccountTypeIds = filterParams.bettingAccountTypeIds;

		return filter;
	}

	static fromToDates(period: Periods): {
		from: string;
		to: string;
	} {
		const now = DateTime.now();
		const to = now;
		let from;

		switch (period) {
			case "today":
				from = now.startOf("day");
				break;
			case "last3days":
				from = now.startOf("day").minus({ days: 3 });
				break;
			case "last7days":
				from = now.startOf("day").minus({ days: 7 });
				break;
			case "last14days":
				from = now.startOf("day").minus({ days: 14 });
				break;
			case "last30days":
				from = now.startOf("day").minus({ days: 30 });
				break;
			case "last60days":
				from = now.startOf("day").minus({ days: 60 });
				break;
			default:
				from = now.startOf("day");
		}

		return {
			from: from.toJSDate().toISOString(),
			to: to.toJSDate().toISOString(),
		};
	}

	static createQuery(filter: AccountStatementFilter): AccountStatementFilter {
		const queryParams: AccountStatementFilter = {
			embed: filter.embed,
			page: filter.page,
			rpp: filter.rpp,
			bettingAccountTypeIds: filter.bettingAccountTypeIds,
		};

		if (filter.from != null) {
			queryParams.from = filter.from;
		}

		if (filter.to != null) {
			queryParams.to = filter.to;
		}

		if (
			filter.transactionTypeIds != null &&
			!isNullOrWhitespace(filter.transactionTypeIds)
		) {
			queryParams.transactionTypeIds = filter.transactionTypeIds;
		}

		if (filter.bettingAccountTypeIds) {
			queryParams.bettingAccountTypeIds = filter.bettingAccountTypeIds;
		}
		return queryParams;
	}
}

export type BetSlipOfferIsEventInOffer = BetSlipEvent & {
	isEventInOffer: boolean;
};
export type BetSlipEventInOffer = Omit<BetSlipExtended, "betSlipOffers"> & {
	betSlipOffers: BetSlipOfferIsEventInOffer[];
};

export type FilterEventId = {
	channels: {
		name: string;
		filter: {
			startTime: {
				gt: string;
			};
			id: {
				eq: (string | null)[];
			};
		}[];
	}[];
};

export type UserPaymentTransactionLimitedHasDetails =
	UserPaymentTransactionLimited & {
		hasDetails: boolean;
	};

type filterParams = {
	page: number;
	dates: {
		from: string;
		to: string;
	} | null;
	rpp: number;
	sort: string;
	embed: string;
	to: string;
	from: string;
	transactionTypesIds: string;
	bettingAccountTypeIds: string | string[];
};

type Periods =
	| "today"
	| "last3days"
	| "last7days"
	| "last14days"
	| "last30days"
	| "last60days";

export class UserActivityTypes {
	userActivity: AccountActivityReviewReportDto | null;
	bettingActivity: BettingActivityReviewReportDto | null;
}

export class TransactionTableResponse {
	transactions: UserPaymentTransactionLimitedHasDetails[];
	totalRecords: number;
	page: number;
	recordsPerPage: number;
}

export type AccountStatementBetSlip = {
	[key: string]: (BetSlipEventInOffer["betSlipOffers"][0] & {
		isMulti: boolean;
		multiEventName: string;
	})[];
};

export type MultiEvent = {
	offer: AccountStatementBetSlip["string"][0];
	parsedResult?: {} | null;
	isLive?: boolean;
	currentResult?: string;
	currentScorePerPeriod?: string;
	scorePerPeriod?: string;
	status?: boolean;
};
