import { useEffect, useState } from "react";

type DirectionProps = "down" | "up";

function useScrollDirection() {
	const [scrollDirection, setScrollDirection] =
		useState<DirectionProps>("up");

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			const isEndOfPage = checkEndOfPage();

			if (currentScrollY === 0 || currentScrollY < 0) {
				setScrollDirection("up");
			} else if (isEndOfPage) {
				setScrollDirection("down");
			} else {
				const direction: DirectionProps =
					currentScrollY > lastScrollY ? "down" : "up";
				setScrollDirection(direction);
			}

			lastScrollY = currentScrollY;
		};

		let lastScrollY = 0;

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return scrollDirection;
}

export default useScrollDirection;

const checkEndOfPage = () => {
	const scrollPosition = window.scrollY || document.documentElement.scrollTop;

	const totalPageHeight = Math.max(
		document.body.scrollHeight,
		document.documentElement.scrollHeight
	);

	const viewportHeight = window.innerHeight;

	return scrollPosition + viewportHeight >= totalPageHeight;
};
