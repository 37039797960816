import React from "react";
import { observer } from "mobx-react";
import { EventKeyOffer, MainOfferStore } from "@gp/offer";
import { EventOutrightOfferBettingTypeRow } from "./";

export const EventOutrightOffer = observer(function EventOutrightOffer(props: {
	eventOffer?: Map<string, EventKeyOffer>;
	offerStore: MainOfferStore;
}) {
	if (props.eventOffer == null || props.eventOffer.size === 0) {
		return null;
	}

	const eventKeys = Array.from(props.eventOffer.values());

	if (eventKeys.length === 0) {
		return null;
	}

	return (
		<div className="offer__body__data offer__outright__grid">
			{eventKeys.map((k) => (
				<EventOutrightOfferBettingTypeRow
					key={k.id}
					bettingType={k}
					offerStore={props.offerStore}
				/>
			))}
		</div>
	);
});
