import React, { ErrorInfo } from "react";
import { IConsoleLogger } from "@interface";
import { ConsoleLogger } from "@state";

export type ErrorBoundaryTypes = {
	children: boolean | JSX.Element | JSX.Element[] | boolean | null;
	fallBack?: JSX.Element | JSX.Element[];
	logger: IConsoleLogger | ConsoleLogger;
};

type ErrorBoundaryState = {
	hasError: boolean;
	error: any;
	errorInfo: ErrorInfo | null;
};

export class ErrorBoundary extends React.Component<
	ErrorBoundaryTypes,
	ErrorBoundaryState
> {
	static getDerivedStateFromError(error: any) {
		/**
		 * Is called during the “render” phase, so side-effects are not permitted.
		 * For those use cases, use componentDidCatch() instead.
		 */
		return { hasError: true, error };
	}

	constructor(props: ErrorBoundaryTypes) {
		super(props);

		if (this.props.fallBack == null) {
			this.props.logger.logInfo("ErrorBoundary fall back not provided.");
		}

		this.state = {
			hasError: false,
			error: null,
			errorInfo: null,
		};
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		/**
		 * Note
		 * In the event of an error, you can render a fallback UI with componentDidCatch() by calling setState, but this will be deprecated in a future release.
		 * Use static getDerivedStateFromError() to handle fallback rendering instead.
		 *
		 * Is called during the “commit” phase, so side-effects are permitted.
		 * It should be used for things like logging error
		 */

		this.setState({
			hasError: true,
			error,
			errorInfo,
		});
	}

	render() {
		if (this.state.hasError) {
			this.props.logger.logError("ErrorBoundaryCaught", this.state);

			if (this.props.fallBack != null) {
				return this.props.fallBack;
			}

			return null;
		}

		return this.props.children;
	}
}
