import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import {
	MyBetsCashoutConfirmation,
	MyBetsReuseConfirmation,
	MyBetsAutosellConfirmation,
	ShareBetSlipModal,
} from "../../../my-bets/modals";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";

import { MultiItem, SingleItem, SkeletonLoader } from "./";
import { MyBetsViewStore } from "@offer/stores/components/my-bets";

const BetListDisplayAuthUser = observer(function BetListDisplayAuthUser(props: {
	myBetsViewStore: MyBetsViewStore;
}) {
	const { t } = useTranslation();

	const quickFilterOptions: ("open" | "live" | "closed" | "all")[] = [
		"open",
		"live",
		/*"cashout",*/ "closed",
		"all",
	];

	const isFull =
		getCurrentCulture() == "de" ||
		getCurrentCulture() == "fr" ||
		getCurrentCulture() == "hr" ||
		getCurrentCulture() == "it";

	if (props.myBetsViewStore.isLoading) {
		return <SkeletonLoader />;
	}

	return (
		<React.Fragment>
			<div className="tabs--primary tabs--primary--bordered">
				{quickFilterOptions.map((option) => (
					<a
						className={classNames("tabs--primary__item", {
							"is-active":
								option ===
								props.myBetsViewStore.filterOptionType,
						})}
						onClick={(e) =>
							props.myBetsViewStore.filterMiniBets(option)
						}
						key={option}
					>
						{t<string>("MY_BETS.FILTER_OPTION.OPTION_" + option)}
					</a>
				))}
			</div>
			<div
				className={
					"widget__body widget--bets widget--bets--" +
					getCurrentCulture()
				}
			>
				{props.myBetsViewStore.isEmpty ? (
					<div className="widget--bets__empty">
						<i className="u-icon u-icon--huge u-icon--empty--bets u-align--h--center u-mar--top--xsml u-mar--bottom--big" />
						<div className="u-type--center">
							{t<string>("MY_BETS.EMPTY")}
						</div>
					</div>
				) : (
					<div className="widget--bets__list">
						{props.myBetsViewStore.bets.map((bet) => {
							if (bet.betSlipOffers.length === 1) {
								return (
									<SingleItem
										key={bet.id}
										bet={bet}
										isFull={isFull}
										myBetsViewStore={props.myBetsViewStore}
									/>
								);
							} else {
								return (
									<MultiItem
										key={bet.id}
										bet={bet}
										isFull={isFull}
										myBetsViewStore={props.myBetsViewStore}
									/>
								);
							}
						})}
					</div>
				)}
			</div>
			<CashoutConfirmationWrapper
				myBetsViewStore={props.myBetsViewStore}
			/>
			<ReuseConfirmationWrapper myBetsViewStore={props.myBetsViewStore} />
			<AutosellConfirmationWrapper
				myBetsViewStore={props.myBetsViewStore}
			/>
			<ShareBetSlipModal />
		</React.Fragment>
	);
});

const CashoutConfirmationWrapper = observer(
	function CashoutConfirmationWrapper(props: {
		myBetsViewStore: MyBetsViewStore;
	}) {
		if (!props.myBetsViewStore.cashoutStore.isCashoutModalOpen) {
			return null;
		}

		if (props.myBetsViewStore.cashoutStore.cashoutModalData == null) {
			console.error("Expected cashout modal, got null.");
			return null;
		}

		return (
			<MyBetsCashoutConfirmation
				isOpen={props.myBetsViewStore.cashoutStore.isCashoutModalOpen}
				{...props.myBetsViewStore.cashoutStore.cashoutModalData}
				onAccept={props.myBetsViewStore.cashoutStore.cashout}
				onClose={props.myBetsViewStore.cashoutStore.closeCashoutModal}
				isSubmitting={
					props.myBetsViewStore.cashoutStore.isCashoutFormSubmitting
				}
			/>
		);
	}
);

const ReuseConfirmationWrapper = observer(
	function ReuseConfirmationWrapper(props: {
		myBetsViewStore: MyBetsViewStore;
	}) {
		if (!props.myBetsViewStore.isReuseModalOpen) {
			return null;
		}
		return (
			<MyBetsReuseConfirmation
				isOpen={props.myBetsViewStore.isReuseModalOpen}
				{...props.myBetsViewStore.reuseModalData}
				onAccept={props.myBetsViewStore.reuseBetSlip}
				onClose={props.myBetsViewStore.closeReuseBetSlipModal}
			/>
		);
	}
);

const AutosellConfirmationWrapper = observer(
	function AutosellConfirmationWrapper(props: {
		myBetsViewStore: MyBetsViewStore;
	}) {
		if (!props.myBetsViewStore.cashoutStore.isAutosellModalOpen) {
			return null;
		}

		return (
			<MyBetsAutosellConfirmation
				store={props.myBetsViewStore.cashoutStore}
			/>
		);
	}
);

export default BetListDisplayAuthUser;
