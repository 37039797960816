import { observable, action, runInAction } from "mobx";
import { LoaderStore } from "../../../state/stores/common";
import { localizationService } from "@state";
import RootOfferStore from "@offer/stores/RootStore";

import { Bet } from "./model";
// @ts-expect-error
import MyBetsStore from "./MyBetsStore.ts";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { DocumentExpirationErrorCodes } from "@api-types/documentExpiration/documentExpiration";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { BetSlipInteractionTypeEnum } from "@data-types/analytics/BetSlipInteractionTypeEnum";
import { DateTime } from "luxon";
import { MyBetsViewStore } from "@offer/stores/components/my-bets";
import ModalService from "@services/modals/ModalService";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const BetSlipApiService = lazy(loadFailPath, ()=>import("@api-services/bet-slip/BetSlipApiService"));

export class BetCancelStore {
	rootStore: RootOfferStore;
	myBetsStore: MyBetsStore | MyBetsViewStore;
	loader: LoaderStore;
	constructor(
		rootStore: RootOfferStore,
		myBetsStore: MyBetsStore | MyBetsViewStore
	) {
		this.rootStore = rootStore;
		this.myBetsStore = myBetsStore;

		// Helper stores
		this.loader = new LoaderStore();

		this.getResponseErrorMessage = this.getResponseErrorMessage.bind(this);
	}

	//#region observables

	@observable isCancelPopUpOpen = false;
	@observable betToCancel: Bet | null = null;
	@observable betCancelMessage:
		| string
		| object
		| (string | object)[]
		| undefined
		| null = null;

	//#endregion observables

	//#region bet cancel

	@action.bound
	async onBetCancelConfirm() {
		if (this.betToCancel) {
			try {
				const response = await (
					await BetSlipApiService
				).default.putCancelBet(
					this.betToCancel.id,
					this.myBetsStore.activeAccountOwner
				);
				// const gainValue =
				// 	response.betSlip.payout ?? response.betSlip.gain;
				App.state.rootStore.userAuthStore
					.resolveBalance
					// gainValue,
					// response.betSlip.bettingAccountTypeId
					();

				this.myBetsStore.refresh();
				runInAction(() => {
					this.betCancelMessage =
						this.getResponseErrorMessage(response);
				});
				AnalyticsService.logBetSlipCancelInteraction({
					category: BetSlipInteractionTypeEnum.ConfirmCancel,
				});
			} catch (error) {
				console.error(error);
				runInAction(() => {
					this.betCancelMessage = error?.data
						? this.getResponseErrorMessage(error?.data)
						: localizationService.t(
								"MY_BETS.BET_CANCEL.CANCEL_FAILED"
						  );
				});
			}
		}
	}

	@action.bound
	getResponseErrorMessage(response: BetSlipResult) {
		if (
			response.validationErrors != null &&
			response.validationErrors.length >= 0
		) {
			return response.validationErrors[0].errorMessage;
		}

		if (
			response.betSlipProcessingResponses != null &&
			response.betSlipProcessingResponses.length >= 0
		) {
			if (response.betSlipProcessingResponses[0].errorCode === 400262) {
				response.betSlipProcessingResponses[0].errorMessage =
					response.betSlipProcessingResponses[0].errorMessage.replace(
						"{0}",
						DateTime.fromISO(
							//@ts-expect-error
							// prettier-ignore
							response.betSlipProcessingResponses[0].previouslyClosedAt
						).toFormat("f")
					);
				response.betSlipProcessingResponses[0].errorMessage =
					response.betSlipProcessingResponses[0].errorMessage.replace(
						"{1}",
						DateTime.fromISO(
							//@ts-expect-error
							response.betSlipProcessingResponses[0].nextOpening
						).toFormat("f")
					);
			}
			if (
				response.betSlipProcessingResponses[0].errorCode ==
					DocumentExpirationErrorCodes.UserDocumentExpirationDateExpired ||
				response.betSlipProcessingResponses[0].errorCode ==
					DocumentExpirationErrorCodes.UserDocumentExpirationDateNotSet
			) {
				App.state.rootStore.AccountVerificationStore.showPopup(
					"account-settings/document-expiration",
					"DOCUMENT_EXPIRATION.TITLE",
					response.betSlipProcessingResponses[0].errorMessage
				);
				this.onExitBetCancel();
				return;
			}
			return response.betSlipProcessingResponses[0].errorMessage;
		}

		if (response.betSlip != null) {
			return localizationService.t("MY_BETS.BET_CANCEL.CANCEL_SUCCESS");
		}

		return localizationService.t("MY_BETS.BET_CANCEL.CANCEL_FAILED");
	}

	@action.bound
	onBetCancel(bet: Bet) {
		ModalService.onOpen();
		this.isCancelPopUpOpen = true;
		this.betToCancel = bet;
	}

	@action.bound
	onExitBetCancel() {
		this.isCancelPopUpOpen = false;
		this.betToCancel = null;
		this.betCancelMessage = null;
	}

	//#endregion bet cancel

	//#region disposers

	@action.bound
	onDispose() {
		this.onExitBetCancel();
	}

	//#endregion disposers
}

type BetSlipResult = {
	availableCombinations: {
		key: number;
		numberOfCombinations: number;
		total: number;
	};
	betSlip: Bet;
	betSlipProcessingResponses: {
		bettingOfferId: string;
		bettingOfferKeyId: string;
		errorCode: number;
		errorMessage: string;
		eventId: string;
		totalNumberOfCombinations: number;
	}[];
	submitLiveBetSlipDelayTimeRespones: {
		level: string;
		value: number;
	};
	isRequestForPaymentEnabled?: boolean;
	userValidationOnBetSlipSubmitResponses: {
		errorMessage: string;
		errorCode: number;
	};
	validationErrors: {
		correlationId: string;
		correlationTwoId: string;
		bettingOfferKeyId: string;
		errorCode: number;
		errorMessage: string;
		errorOnLevel: number;
		eventId: string;
		eventTwoId: string;
		playerId: string;
		teamId: string;
		suggestedValue: number;
	}[];
};
