import React from "react";
import { useParams } from "react-router-dom";
import {
	AccountLockTabButtons,
	AccountLockHeader,
	AccountClosureView,
	OasisLockView,
} from ".";
class ParamsType {
	pagename: string;
}

export default function AccountLockWithOasisViewDisplay() {
	const { pagename } = useParams<ParamsType>();

	if (pagename === "oasis-lock") {
		return (
			<div className="l--content__main">
				<div className="container">
					<div className="card--primary">
						<AccountLockHeader />
						<AccountLockTabButtons />
						<OasisLockView />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="l--content__main">
			<div className="container">
				<div className="card--primary">
					<AccountLockHeader />
					<AccountLockTabButtons />
					<AccountClosureView />
				</div>
			</div>
		</div>
	);
}
