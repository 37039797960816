import { runInAction } from 'mobx';
import { additionalOfferClose, useGetAdditionalOfferEvent } from "@offer/common";
import { MainOfferStore } from "@gp/offer";

export default function useRemoveAdditionalOfferCount(
	parentOfferStore: {
		eventsInSports: MainOfferStore['eventsInSports'],
		eventsMap: MainOfferStore['eventsMap']
	},
	removeOffer: boolean
) {
	const eventId = useGetAdditionalOfferEvent()?.eventId;

	if (!removeOffer) {
		return;
	}

	runInAction(() => {
		const e = parentOfferStore.eventsInSports.sports.reduce((result, sp) => {
			if (result != null) {
				return result
			}

			return sp.categories?.reduce((catResult, cat) => {
				if (catResult != null) {
					return catResult;
				}

				return cat.tournaments.reduce((tResult, t) => {
					if (tResult != null) {
						return tResult
					}

					return t.events.find(e => e.id === eventId)
				}, null)
			}, null)
		}, null)


		if (e != null) {
			e.totalOfferCount = -1;
		}

		const event = parentOfferStore.eventsMap.get(eventId || "");

		if (event != null) {
			event.totalOfferCount = -1;
		}
		additionalOfferClose();
	})

}