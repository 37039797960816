import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	MyLimitsRequestList,
	MyLimitsTabDescription,
	MyLimitsLimitList,
} from ".";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import { MyLimitsStoreTabsNullChecked } from "@v2/state/myLimits/MyLimitsStore";

import { observer } from "mobx-react";

const MyLimitsTabBody = observer(function MyLimitsTabBody() {
	const { t } = useTranslation();

	const myLimitsStore = useContext(
		MyLimitsStoreContext
	) as MyLimitsStoreTabsNullChecked;

	const { id } = myLimitsStore.tabs[0];

	const { tab_id, accountType } = useParams<{
		tab_id: string;
		accountType: string;
	}>();

	useEffect(() => {
		tab_id
			? myLimitsStore.fetchLimitInfo(tab_id)
			: myLimitsStore.fetchLimitInfo(id);
	}, [tab_id, accountType]);

	if (myLimitsStore.category == null) {
		return null;
	}
	return (
		<div className="at-myLimits card--primary__body">
			<div className="message message message--base message--note message--limits u-mar--bottom--big">
				<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
				<div className="message__content">
					<MyLimitsTabDescription
						category={myLimitsStore.category}
						description={myLimitsStore.category?.description}
					/>
				</div>
			</div>

			<MyLimitsLimitList />

			{myLimitsStore.category?.requests.length > 0 && (
				<MyLimitsRequestList />
			)}
		</div>
	);
});

export default MyLimitsTabBody;
