export function numberInRange(
	number: number,
	bottom: number,
	top: number
): boolean {
	number = Number(number);
	if (number >= bottom && number <= top) return true;
	else return false;
}

export function parsePageNumberToValid(pageNumber: number): number {
	pageNumber = Number(pageNumber);

	if (numberInRange(pageNumber, 1, Math.pow(2, 16) - 1)) return pageNumber;
	else return 1;
}
