import { useRootOfferStore } from "@hooks";
import { logger } from "@state";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { getCurrentCulture } from "@utils";
import { Bet } from "@administration/pages/my-bets/model";

export default observer(function ShareBetSlipButton(props: {
	bet: Bet;
	isMini?: boolean;
	btnTiny?: boolean;
}) {
	const { t } = useTranslation();

	const store = useRootOfferStore().myBetsViewStore;

	if (store == null) {
		logger.logError("Expected store, got null.");
		return null;
	}

	const btnClasses = classNames("btn btn--icon", {
		"btn--wrap btn--wrap--xtny btn--secondary--inverted": props.btnTiny,
		"card--bet__btn card--bet__btn--reuse btn--tny btn--secondary--inverted":
			!props.btnTiny,
	});

	const iconClasses = classNames("u-icon u-icon--arrow--share", {
		"u-icon--xsml": props.btnTiny,
		"u-icon--med": !props.btnTiny,
	});

	const labelClasses = classNames({
		[`card--bet__btn--${getCurrentCulture()}`]: props.btnTiny,
	});

	return (
		<button
			className={btnClasses}
			type="button"
			onClick={() => {
				store.betSlipShareStore.showPopup(props.bet);
				//chatViewStore.sharedBetSlipNumber = props.betSlipNumber;
			}}
			title={t<string>("MY_BETS.SHARE.BUTTON_LABEL")}
		>
			<i className={iconClasses}></i>
			{!props.isMini ? (
				<>
					<span className={labelClasses}>
						{t("MY_BETS.SHARE.BUTTON_LABEL")}
					</span>
					<span className="tag tag--tny u-type--case--uppercase">
						{t("CASINO.NEW_GAME_LABEL")}
					</span>
				</>
			) : null}
		</button>
	);
});
