import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useFixBody } from "@v2/hooks/shared";
import { useRootAppStore } from "@hooks";

export default observer(function LiveSessionExpirePopupTemplate() {
    const { t } = useTranslation();

    const rootAppStore = useRootAppStore();

    useFixBody(true);

    return (
        <div className="popup">
            <div className="popup__card popup__card--sml">
                <div className="popup__header">
                    <div>{t<string>("SESSION.LIVE_SESSION_EXPIRE.LIVE_SESSION_EXPIRED_LABEL")}</div>
                </div>
                <div className="popup__body">
                    <i className="u-icon u-icon--huge u-icon--empty u-icon--empty--session u-display--b u-align--h--center u-mar--bottom--xsml"></i>
                    <div>{t<string>("SESSION.LIVE_SESSION_EXPIRE.LIVE_SESSION_EXPIRED_MESSAGE")}</div>
                </div>
                <div className="popup__footer">
                    <button
                        className="popup__footer__btn btn btn--tny btn--primary btn--icon btn--animate-rotate"
                        onClick={() => {
                            rootAppStore.offerHub.restartSession();
                            rootAppStore.cashoutHubStore.restartSession();
                        }}
                        type="button"
                    >
                        <i className="u-icon u-icon--sml u-icon--refresh u-color--core--snow" />
                        {t<string>("SESSION.LIVE_SESSION_EXPIRE.BUTTON_REFRESH")}
                    </button>
                </div>
            </div>
        </div>
    );
});
