import { Bet } from "@administration/pages/my-bets/model";

export type TMostPlayTicketResponse = {
	betSlipId: string;
	betSlipOffers: TBetSlipOffer[];
	eventCount: number;
	maxCoefficient: number;
	sportAbrv: string;
	sportSortOrder: number;
	sportName: string;
	submitCount: number;
};

export type TMostPlayTicketsFilter = {
	sportId?: string;
	offerCount?: OfferCountLimit;
	sort?: "submitCount";
};

export enum OfferCountLimit {
	"HomePage" = "2",
	"MostPlayedTicketsPage" = "3",
}
export type TBetSlipOffer = {
	bettingOfferId?: string;
	bettingOfferKeyId?: string;
	bettingTypeId?: string;
	bettingTypeTip?: string;
	eventId?: string;
	isLocked?: boolean;
	playerId?: string;
	// maybe not sure
	specifier?: null | { player: string };
	sportData?: TSportData;
	teamId?: string;
	value?: number;
	playerFirstName?: string;
	playerLastName?: string;
};

export type TSportData = {
	bettingTypeNameForBettingSlip?: string;
	eventName?: string;
	startTime?: Date;
	teamOneGender?: string;
	teamOneId?: string;
	teamOneName?: string;
	teamTwoGender?: string;
	teamTwoId?: string;
	teamTwoName?: string;
	bettingTypeAbrv?: string;
};

export type TQuickBetFormFilter = {
	eventCount: number;
	stake: string;
	quickBetType: "weekly" | "daily" | "live";
	useUserFavorites: boolean;
	minOddValue: number;
	maxOddValue: number;
	userSportTreeFilter?: TUserSportTreeFilter | null;
	mainBettingTypesPerSport?: TMainBettingTypesPerSport[];
};

export type TQuickBetFormRequest = Omit<
	TQuickBetFormFilter,
	"minOddValue" | "maxOddValue"
> & { minOddValue: string; maxOddValue: string };

export type TMainBettingTypesPerSport = {
	sportAbrv?: string;
	bettingTypeAbrvs?: string[];
};

export type TUserSportTreeFilter = {
	sportIds?: string[];
	categoryIds?: string[];
	tournamentIds?: string[];
};

export type TQuickBet = {
	availableCombinations?: null;
	betSlip?: TMostPlayTicketResponse;
	betSlipProcessingResponses?: any[];
	isRequestForPaymentEnabled?: boolean;
	submitLiveBetSlipDelayTimeRespones?: null;
	userValidationOnBetSlipSubmitResponses?: null;
	validationErrors?: any[];
};

export type TQuickPlayGenericResponse<T> = {
	details: unknown | null;
	error: unknown | null;
	errorCode: number | null;
	message: string | null;
	response: T | null;
	statusCode: number;
};
