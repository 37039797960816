import { useEffect } from "react";
import { useLoading } from "@hooks";

export function useConfirmationHandlingPopup() {
	const loginStore = App.state.rootStore.userAuthStore.loginStore;
	useLoading().setIsLoading(false);
	useEffect(() => {
		loginStore.onInitializeUserDataConfirmation();
	}, []);

	return {
		countryName: loginStore.usersCountryName,
		userData: loginStore.userDataToBeConfirmed,
		confirmVerification: loginStore.onUserConfirmData,
		confirmOnlineUserVerification: loginStore.onOnlineUserConfirmData,
		cancelVerification: loginStore.onCancelUserDataConfirmation,
	};
}
