export class LoginErrorResponseModel {
	errorCode?: string;
	message?: string;
	errorFailedAttempts?: number;
	errorDuration?: number;
	mobileApkLink?: string;
	userData?:
		| string
		| {
				activationToken: string;
		  };

	constructor(errorModel: LoginErrorResponseModel) {
		this.errorCode = errorModel.errorCode;
		this.message = errorModel.message;
		this.errorFailedAttempts = errorModel.errorFailedAttempts;
		this.errorDuration = errorModel.errorDuration;
		this.userData = errorModel.userData;
		this.mobileApkLink = errorModel.mobileApkLink;
	}
}

export enum LoginErrorCodes {
	wrongapk = "600301",
	temporaryLockCode = "400168",
	temporaryLockedWithDurationCode = "400166",
	dataConfirmationRequiredCode = "400213",
	matchingAvailable = "400218",
	CPRNumberNotRegistered = "400901",
	CPRNumberVerificationError = "400902",
	loginExclusionCheckError = "400904",
	loginIndefiniteExclusion = "400905",
	loginTemporaryExclusion = "400906",
	playerTooYoung = "400907",
	spelpausGamingExclusionActive = "400228",
	lugasPlayerNotRegistered = "400243",
	lugasValidationError = "400242",
	lugasUnspecified = "400256",
	userLocked = "400111",
}
