import { observable, computed, action, runInAction } from "mobx";
import { CashoutStore } from "./CashoutStore";
import { ReuseBetSlipStore } from "./ReuseBetSlipStore";
import { ActiveEventsStore } from "./ActiveEventsStore";
import { BetCancelStore } from "./BetCancelStore";
import * as helpers from "./helpers";
import { Bet, BetOffer } from "./model";
import { LoaderStore } from "../../../state/stores/common";
import { UserTypes } from "@utils";
import RootOfferStore from "@offer/stores/RootStore";
import { BetSlipService } from "@v2/data-access/bet-slip";
import { getCurrentCulture } from "@utils";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import BetSlipShareStore from "./BetSlipShareStore";
import { getUserFromLocalStorage } from "@utils";
import { BetSlipProcessingError } from "@api-types/bet-slip/BetSlipProcessingError";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const LookupService = lazy(loadFailPath, ()=>import("@services/LookupService"));
// prettier-ignore
const  MyBetsService = lazy(loadFailPath, ()=>import("@services/my-bets/MyBetsService"));

type ShortBasicLookupModel = {
	abrv: string;
	name: string;
	id: string;
};

export default class BetDetailsStore {
	type: "BetDetailsStore" = "BetDetailsStore";
	rootStore: RootOfferStore;
	betSlipService: BetSlipService;
	activeEventsStore: ActiveEventsStore;
	cashoutStore: CashoutStore;
	reuseBetSlipStore: ReuseBetSlipStore;
	betCancelStore: BetCancelStore;
	betSlipShareStore: BetSlipShareStore;
	loader: LoaderStore;
	location:
		| "MyBetsPage"
		| "SharedBetSlipPage"
		| "BetSlipReceiptPage"
		| "BetSlipDetails"
		| "ChatBetSlipSharePopup" = "MyBetsPage";

	constructor(
		rootStore: RootOfferStore,
		location:
			| "MyBetsPage"
			| "SharedBetSlipPage"
			| "BetSlipReceiptPage"
			| "BetSlipDetails"
			| "ChatBetSlipSharePopup"
	) {
		this.rootStore = rootStore;

		// Services
		this.betSlipService = new BetSlipService();

		// Helper stores
		this.activeEventsStore = new ActiveEventsStore(rootStore);
		this.cashoutStore = new CashoutStore(this);
		// @ts-expect-error
		this.reuseBetSlipStore = new ReuseBetSlipStore(rootStore, this);
		// @ts-expect-error
		this.betCancelStore = new BetCancelStore(rootStore, this);
		// @ts-expect-error
		this.betSlipShareStore = new BetSlipShareStore(rootStore, this);
		this.loader = new LoaderStore();
	}

	//#region observables
	@observable activeAccountOwner = UserTypes.PRIMARY;
	@observable isStoreInitialized = false;
	@observable isFetchingData = true; // It has to initially fetch data
	@observable bet: Bet | null = null;
	@observable betSlipProcessingResponses: BetSlipProcessingError[] | null =
		null;
	@computed get betsList() {
		if (this.bet != null) {
			return [this.bet];
		}

		return [];
	}
	@computed get isEmpty() {
		return (
			this.bet == null && this.isStoreInitialized && !this.isFetchingData
		);
	}

	@computed public get isLoading() {
		return this.loader.isLoading;
	}

	@computed public get isLoadingProcess(): boolean {
		return this.loader.isLoadingProcess;
	}

	@observable betCombinationsMap = observable.map();

	@observable betSlipStatusLookups: ShortBasicLookupModel[] = [];
	@observable betStatusLookups: ShortBasicLookupModel[] = [];

	//#endregion observables

	//#region store initialization

	@action.bound
	async onInitialize(owner = UserTypes.PRIMARY) {
		this.loader.suspend();
		this.cashoutStore.onInitialize();
		let betSlipStatusLookups: ShortBasicLookupModel[] = [];
		let betStatusesLookups: ShortBasicLookupModel[] = [];

		try {
			betSlipStatusLookups = await (
				await LookupService
			).default.findBetSlipStatuses(owner);
			betStatusesLookups = await (
				await LookupService
			).default.findBetStatuses(owner);
		} catch (error) {
			console.log(error);
		}

		runInAction(() => {
			this.loader.resume();
			this.betSlipStatusLookups = betSlipStatusLookups;
			this.betStatusLookups = betStatusesLookups;

			this.activeAccountOwner = owner;
		});
	}

	//#endregion store initialization

	//#region data fetching

	@action.bound
	async getBetDetails(slipId: string) {
		this.isFetchingData = true;
		this.loader.suspend();
		try {
			const bet: Bet =
				await this.betSlipService.getSlipWithoutCombinations(
					slipId,
					this.activeAccountOwner
				);

			bet?.betSlipOffers?.forEach((offer) => {
				if (
					offer?.specifier?.player == null ||
					![
						"player-total-assists",
						"player-total-rebounds",
						"player-total-3-point-field-goals",
						"player-total-points",
						"player-total-rushing-yards",
						"player-total-passing-touchdowns",
						"player-total-passing-yards",
						"player-total-receiving-yards",
					].includes(offer.sportData.bettingTypeAbrv)
				) {
					return;
				}

				offer.specifier.player =
					offer.playerFirstName +
					" " +
					offer.playerLastName +
					` (${
						offer.sportData.teamOneId === offer.teamId
							? offer.sportData.teamOneName
							: offer.sportData.teamTwoName
					})`;
				offer.playerId = undefined;
				offer.teamId = undefined;
			});

			const eventIdsFromBet = helpers.getEventIdsFromMyBets([bet]);
			this.activeEventsStore.fetchActiveEvents(eventIdsFromBet);

			runInAction(() => {
				this.isFetchingData = false;
				this.bet = new Bet(bet);
				this.cashoutStore.initializeCashoutSubscription();
			});
		} catch (error) {
			console.error(error);
		} finally {
			runInAction(() => {
				this.loader.resume();
				this.isStoreInitialized = true;
			});
		}
	}
	@action.bound
	async getSharedBetSlipDetails(slipNmbr: string) {
		this.isFetchingData = true;
		try {
			const bet = await this.betSlipService.getBetSlipWithBetSlipNumber(
				slipNmbr
			);

			bet?.betSlipOffers?.forEach((offer) => {
				if (
					offer?.specifier?.player == null ||
					![
						"player-total-assists",
						"player-total-rebounds",
						"player-total-3-point-field-goals",
						"player-total-points",
						"player-total-rushing-yards",
						"player-total-passing-touchdowns",
						"player-total-passing-yards",
						"player-total-receiving-yards",
					].includes(offer.sportData.bettingTypeAbrv)
				) {
					return;
				}

				offer.specifier.player =
					offer.playerFirstName +
					" " +
					offer.playerLastName +
					` (${
						offer.sportData.teamOneId === offer.teamId
							? offer.sportData.teamOneName
							: offer.sportData.teamTwoName
					})`;
				offer.playerId = undefined;
				offer.teamId = undefined;
			});

			const eventIdsFromBet = helpers.getEventIdsFromMyBets([bet]);
			this.activeEventsStore.fetchActiveEvents(eventIdsFromBet);

			runInAction(() => {
				this.bet = new Bet(bet);
				this.isFetchingData = false;
				this.isStoreInitialized = true;
			});
		} catch (error) {
			runInAction(() => {
				console.error(error);
				this.isFetchingData = false;
				this.isStoreInitialized = true;
			});
		}
	}

	@action.bound
	refresh() {
		if (this.bet?.id == null) {
			return;
		}
		this.getBetDetails(this.bet.id);
	}

	@action.bound
	async getQrBetDetails(encryptedBetSlipNumber: string) {
		this.isFetchingData = true;
		try {
			const response =
				await this.betSlipService.getSlipByEncryptedBetSlipNumber(
					encryptedBetSlipNumber
				);

			if (response.betSlip != null) {
				response.betSlip?.betSlipOffers?.forEach((offer: BetOffer) => {
					if (
						offer?.specifier?.player == null ||
						![
							"player-total-assists",
							"player-total-rebounds",
							"player-total-3-point-field-goals",
							"player-total-points",
							"player-total-rushing-yards",
							"player-total-passing-touchdowns",
							"player-total-passing-yards",
							"player-total-receiving-yards",
						].includes(offer.sportData.bettingTypeAbrv)
					) {
						return;
					}

					offer.specifier.player =
						offer.playerFirstName +
						" " +
						offer.playerLastName +
						` (${
							offer.sportData.teamOneId === offer.teamId
								? offer.sportData.teamOneName
								: offer.sportData.teamTwoName
						})`;
					offer.playerId = undefined;
					offer.teamId = undefined;
				});

				const eventIdsFromBet = helpers.getEventIdsFromMyBets([
					response.betSlip,
				]);
				this.activeEventsStore.fetchActiveEvents(eventIdsFromBet);

				runInAction(() => {
					this.bet = new Bet(response.betSlip);
					this.betSlipProcessingResponses =
						response.betSlipProcessingResponses;
					this.isFetchingData = false;
					this.isStoreInitialized = true;
					if (getUserFromLocalStorage()?.id != undefined) {
						this.cashoutStore.initializeCashoutSubscription();
					}
				});
			} else {
				this.betSlipProcessingResponses =
					response.betSlipProcessingResponses;
				this.isStoreInitialized = true;
			}
		} catch (error) {
			runInAction(() => {
				console.error(error);
				this.isFetchingData = false;
				this.isStoreInitialized = true;
			});
		}
	}

	@action.bound
	refreshWithQrCode() {
		const encryptedBetSlipNumber = window.location.href.substring(
			window.location.href.lastIndexOf("/") + 1
		);
		if (encryptedBetSlipNumber != null) {
			this.getQrBetDetails(encryptedBetSlipNumber);
		}
	}

	@action.bound
	async initBetCombinationForBetSlip(betSlipId: string) {
		if (this.betCombinationsMap.has(betSlipId)) {
			return;
		}

		this.betCombinationsMap.set(betSlipId, null);

		const combinations = await (
			await MyBetsService
		).default.getSystemCombinations(betSlipId, this.activeAccountOwner);
		this.betCombinationsMap.set(betSlipId, combinations);
	}

	//#endregion data fetching

	//#region disposers

	@action.bound
	onDispose() {
		this.betSlipStatusLookups = [];
		this.betStatusLookups = [];
		this.betCancelStore.onDispose();
		this.cashoutStore.onDispose();
		this.activeEventsStore.onDispose();
		this.betCombinationsMap.clear();
		this.isStoreInitialized = false;
		this.bet = null;
		this.isFetchingData = false;
		this.activeAccountOwner = UserTypes.PRIMARY;
	}

	//#endregion disposers
}
