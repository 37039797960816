import React from "react";
import { useTranslation } from "react-i18next";
import { LimitHistoryViewModel } from "@data-types";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default function LimitsHistoryMessageSection(props: PropTypes) {
	const { t } = useTranslation();

	const { fetchedData } = props;

	if (fetchedData?.items?.length != 0) {
		return null;
	}

	return (
		<InfoMessageComponent
			type="note"
			message={t<string>(
				"USER.ACCOUNT_SETTINGS.GAMING_HISTORY.NO_LIMITHISTORY_TXT"
			)}
			addClassName="u-mar--top--xsml"
		/>
	);
}

type PropTypes = {
	fetchedData: LimitHistoryViewModel;
};
