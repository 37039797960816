export enum QuickBetActionsTypeEnum {
	TicketGen = "quick-bet-action-ticket-generation",
	PageVisit = "quick-bet-action-page-visit",
	TicketUse = "quick-bet-action-ticket-use",
}

export enum MostPlayTicketActionsTypeEnum {
	TicketClick = "most-play-tickets-action-ticket-click",
	TicketUse = "most-play-tickets-action-ticket-use",
	PageVisit = "most-play-tickets-action-page-visit",
}
