import { ActiveEventsStore } from "./../../../../administration/pages/my-bets/ActiveEventsStore";
import {
	observable,
	action,
	runInAction,
	computed,
	reaction,
	IReactionDisposer,
} from "mobx";

import { BetSlipService } from "@v2/data-access/bet-slip";
import { getCurrentCulture, UserTypes } from "@utils";
import { localizationService, logger } from "@state";
import { CashoutStore } from "@administration/pages/my-bets/CashoutStore";
import { ICashoutStoreParent } from "@administration/pages/my-bets/ICashoutStoreParent";
import { Bet } from "@administration/pages/my-bets/model";
import { LoaderStore } from "@state/stores/common";
import RootOfferStore from "@offer/stores/RootStore";
import { LookupDto } from "@api-types";
import * as helpers from "../../../../administration/pages/my-bets/helpers";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { AdditionalOfferApiService } from "@v2/data-access/bet-slip/AdditionalOfferBetSlipService";
import LookupService from "@services/LookupService";
import { BetCancelStore } from "@administration/pages/my-bets/BetCancelStore";
import BetSlipShareStore from "@administration/pages/my-bets/BetSlipShareStore";
import ModalService from "@services/modals/ModalService";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const MyBetsService = lazy(loadFailPath, ()=>import("@services/my-bets/MyBetsService"));

class MyBetsViewStore implements ICashoutStoreParent {
	rootStore: RootOfferStore;
	refreshDebounceId: ReturnType<typeof setTimeout> | undefined;
	userLoggedInReaction: IReactionDisposer | null = null;
	betSlipService: BetSlipService;
	cashoutStore: CashoutStore;
	betCancelStore: BetCancelStore;
	betSlipShareStore: BetSlipShareStore;
	activeEventsStore: ActiveEventsStore;
	loader: LoaderStore = new LoaderStore();
	eventBetsLoader: LoaderStore = new LoaderStore();

	@computed get isLoading() {
		return this.loader.isLoading;
	}

	@computed get isEventBetsLoading() {
		return this.eventBetsLoader.isLoading;
	}

	@computed get isLoadingProcess() {
		return this.loader.isLoadingProcess;
	}

	@observable isFirstLoad = true;
	@observable isFetchingData = false;
	@observable bets: Bet[] = [];
	@observable betsPerEvent: {
		eventId: string;
		bets: Bet[];
		pageNumber: number;
		totalItems: number;
	}[] = [];
	@computed get betsList(): Bet[] {
		return this.bets;
	}

	@observable openedBetId: string | null;
	@observable isReuseModalOpen = false;
	@observable reuseModalData: {
		id: string;
		betSlipNumber: string;
		isLive: boolean;
	} | null;
	@observable isReuseFormSubmitting = false;
	@observable pageNumber = 1;
	pageSize = 10;
	@observable totalItems = 0;
	@observable eventId: string | null = null;

	@computed get isEmpty() {
		return this.bets.length === 0;
	}

	@computed get openedBet() {
		const idx = this.bets.findIndex((i) => i.id === this.openedBetId);

		if (idx > -1) return this.bets[idx];
		return null;
	}

	//mini bet filter option type
	@observable public filterOptionType:
		| "open"
		| "live"
		| "closed"
		| "all"
		| null = "open";

	@computed get isBetOpen(): boolean {
		return this.openedBet !== null;
	}

	/** @deprecated computed should not be used for this. getWalletOwner relies on other observables which may not be set at the time. It is better to call it directly where needed when userAuthStore.isLoggedIn is true */
	@computed get activeAccountOwner() {
		return (
			App.state.rootStore.userAuthStore.getWalletOwner(
				App.state.rootStore.userAuthStore.activeAccountTypeId
			) || UserTypes.PRIMARY
		);
	}

	constructor(rootStore: RootOfferStore) {
		this.rootStore = rootStore;
		this.betSlipService = new BetSlipService();
		this.activeEventsStore = new ActiveEventsStore(rootStore);

		this.cashoutStore = new CashoutStore(this);
		this.betCancelStore = new BetCancelStore(rootStore, this);
		// @ts-expect-error
		this.betSlipShareStore = new BetSlipShareStore(rootStore, this);

		if (App.state.rootStore.userAuthStore.isLoggedIn) {
			this.loader.suspend();
		}
	}

	@action.bound
	async onInitialize() {
		this.disposeUserLoggedInReaction();
		this.userLoggedInReaction = reaction(
			() => ({
				isLoggedIn: App.state.rootStore.userAuthStore.isLoggedIn,
				activeAccountOwner: this.activeAccountOwner,
			}),
			({ isLoggedIn, activeAccountOwner }) => {
				if (isLoggedIn) {
					if (!this.cashoutStore.isStoreInitialized) {
						this.cashoutStore.onInitialize();
					}
					this.fetchData();
				} else {
					this.onClearData();
				}
			},
			{
				fireImmediately: true,
			}
		);
	}

	@action.bound
	async fetchData() {
		this.loader.suspend();
		try {
			const filter: "open" | "live" | "closed" | null =
				this.filterOptionType === "all" ? null : this.filterOptionType;
			const result: LookupDto<Bet> = await (
				await MyBetsService
			).default.getMiniBets(
				filter,
				App.state.rootStore.userAuthStore.getWalletOwner(
					App.state.rootStore.userAuthStore.activeAccountTypeId
				)
			);
			const eventIds: string[] = [];
			result.item?.forEach((betSlip) => {
				betSlip?.betSlipOffers?.forEach((offer) => {
					eventIds.push(offer.eventId);
					if (
						offer?.specifier?.player == null ||
						![
							"player-total-assists",
							"player-total-rebounds",
							"player-total-3-point-field-goals",
							"player-total-points",
							"player-total-rushing-yards",
							"player-total-passing-touchdowns",
							"player-total-passing-yards",
							"player-total-receiving-yards",
						].includes(offer.sportData.bettingTypeAbrv)
					) {
						return;
					}

					offer.specifier.player =
						offer.playerFirstName +
						" " +
						offer.playerLastName +
						` (${
							offer.sportData.teamOneId === offer.teamId
								? offer.sportData.teamOneName
								: offer.sportData.teamTwoName
						})`;
					offer.playerId = undefined;
					offer.teamId = undefined;
				});
			});

			this.activeEventsStore.fetchActiveEvents(
				eventIds,
				this.filterOptionType
			);
			runInAction(() => {
				this.bets = result.item.map((bet) => new Bet(bet));
				this.cashoutStore.initializeCashoutSubscription();
			});
		} catch (ex) {
			console.error(ex);
			logger.logError("Failed to fetch my bets", ex);
		}

		this.loader.resume();
	}

	async fetchAdditionalOffers(eventId: string): Promise<void> {
		this.eventBetsLoader.suspend();
		this.isFetchingData = true;
		this.eventId = eventId;
		this.cashoutStore.disposeSubscription(eventId);

		const currentEvent = this.betsPerEvent.find(
			(item) => item.eventId === eventId
		);

		const wallet = App.state.rootStore.userAuthStore.activeUserWallet
			? App.state.rootStore.userAuthStore.activeUserWallet
			: UserTypes.PRIMARY;

		const filter = {
			eventId: eventId,
			pageSize: 10,
			page: currentEvent?.pageNumber || 1,
		};

		try {
			const response = await AdditionalOfferApiService.findBets(
				wallet,
				filter
			);

			const eventIdsFromBets = helpers.getEventIdsFromMyBets(
				response.item
			);

			response.item?.forEach((betSlip) => {
				betSlip?.betSlipOffers?.forEach((offer) => {
					if (
						offer?.specifier?.player == null ||
						![
							"player-total-assists",
							"player-total-rebounds",
							"player-total-3-point-field-goals",
							"player-total-points",
							"player-total-rushing-yards",
							"player-total-passing-touchdowns",
							"player-total-passing-yards",
							"player-total-receiving-yards",
						].includes(offer.sportData.bettingTypeAbrv)
					) {
						return;
					}

					offer.specifier.player =
						offer.playerFirstName +
						" " +
						offer.playerLastName +
						` (${
							offer.sportData.teamOneId === offer.teamId
								? offer.sportData.teamOneName
								: offer.sportData.teamTwoName
						})`;
					offer.playerId = undefined;
					offer.teamId = undefined;
				});
			});

			const betStatuses = await LookupService.findBetStatuses(wallet);

			this.activeEventsStore.fetchActiveEvents(eventIdsFromBets, [
				betStatuses.find((s) => s.abrv == "open")?.id,
			]);

			runInAction(() => {
				const isEventAlreadyOnList = this.betsPerEvent.some(
					(ebComb) => ebComb.eventId === eventId
				);

				if (isEventAlreadyOnList) {
					const ebCombIdx = this.betsPerEvent.findIndex(
						(item) => item.eventId === eventId
					);
					this.betsPerEvent[ebCombIdx].bets = response.item.map(
						(bet) => new Bet(bet)
					);
					this.betsPerEvent[ebCombIdx].totalItems =
						response.totalRecords;
					this.betsPerEvent[ebCombIdx].pageNumber = response.page;
				} else {
					this.betsPerEvent.push({
						eventId: eventId,
						bets: response.item.map((bet) => new Bet(bet)),
						pageNumber: 1,
						totalItems: response.totalRecords,
					});
				}

				this.cashoutStore.initializeCashoutSubscription(
					eventId,
					response.item
				);
				this.eventBetsLoader.resume();
				this.isFetchingData = false;
			});
		} catch (error) {
			runInAction(() => {
				console.error(error);
				const errorMessage = localizationService.t("ERROR.TITLE");
				App.state.rootStore.notificationStore.error(errorMessage);

				const isEventAlreadyOnList = this.betsPerEvent.some(
					(ebComb) => ebComb.eventId === eventId
				);

				if (isEventAlreadyOnList) {
					const ebCombIdx = this.betsPerEvent.findIndex(
						(item) => item.eventId === eventId
					);
					this.betsPerEvent[ebCombIdx].bets = [];
					this.betsPerEvent[ebCombIdx].pageNumber = 1;
				}

				this.eventBetsLoader.resume();
				this.isFetchingData = false;
			});
		}
	}

	@action.bound
	async filterMiniBets(type: "open" | "live" | "closed" | "all" | null) {
		this.filterOptionType = type;
		this.fetchData();
	}

	@action.bound
	async refresh() {
		clearTimeout(this.refreshDebounceId);
		this.refreshDebounceId = setTimeout(this.fetchData, 500);
	}

	@action.bound
	async reuseBetSlip(reuseModalData: { id: string; isLive: boolean }) {
		// this.rootStore.prematchOfferMenuStore.closeOfferMenu();
		await this.rootStore.betSlipStore.getSlipAndMap(
			reuseModalData.id,
			this.activeAccountOwner
		);

		if (reuseModalData.isLive) {
			App.state.redirect(`/${getCurrentCulture()}/live/events`);
		} else {
			App.state.redirect(`/${getCurrentCulture()}/sports/full`);
		}

		this.closeReuseBetSlipModal();
	}

	@action.bound
	onOpen(betId: string) {
		this.openedBetId = betId;
	}

	@action.bound
	onClose() {
		this.openedBetId = null;
	}

	@action.bound
	openReuseBetSlipModal(modalData: {
		id: string;
		betSlipNumber: string;
		isLive: boolean;
	}) {
		this.isReuseModalOpen = true;
		this.reuseModalData = modalData;
		ModalService.onOpen();
	}

	@action.bound
	closeReuseBetSlipModal() {
		this.isReuseModalOpen = false;
		this.reuseModalData = null;
	}

	@action.bound
	setPageNumber(nmbr: number) {
		if (nmbr !== this.pageNumber) {
			this.pageNumber = nmbr;
		}
		if (this.eventId != null) {
			this.fetchAdditionalOffers(this.eventId);
		}
	}

	@action.bound
	setEventPageNumber(eventId: string, nmbr: number) {
		const isEventAlreadyOnList = this.betsPerEvent.some(
			(ebComb) => ebComb.eventId === eventId
		);

		if (isEventAlreadyOnList) {
			const ebCombIdx = this.betsPerEvent.findIndex(
				(item) => item.eventId === eventId
			);

			if (this.betsPerEvent[ebCombIdx].pageNumber != nmbr) {
				this.betsPerEvent[ebCombIdx].pageNumber = nmbr;

				this.fetchAdditionalOffers(eventId);
			}
		}
	}

	@action.bound
	onEventBetsDispose(eventId: string) {
		const isEventAlreadyOnList = this.betsPerEvent.some(
			(ebComb) => ebComb.eventId === eventId
		);

		if (isEventAlreadyOnList) {
			const ebCombIdx = this.betsPerEvent.findIndex(
				(item) => item.eventId === eventId
			);
			this.betsPerEvent[ebCombIdx].bets = [];
			this.betsPerEvent[ebCombIdx].pageNumber = 1;
		}

		this.pageNumber = 1;
		this.totalItems = 0;
	}

	@action.bound
	onClearData() {
		this.bets = [];
		this.betsPerEvent = [];
		this.filterOptionType = "open";
		this.loader.resume();
		this.cashoutStore.onDispose();
		this.closeReuseBetSlipModal();
	}

	@action.bound
	onDispose() {
		this.disposeUserLoggedInReaction();
		this.cashoutStore.onDispose();
		this.activeEventsStore.onDispose();
		this.betSlipShareStore.onDispose();
	}

	@action.bound
	disposeUserLoggedInReaction() {
		if (this.userLoggedInReaction != null) {
			this.userLoggedInReaction();
			this.userLoggedInReaction = null;
		}
	}
}

export default MyBetsViewStore;
