import { useNotification } from "@v2/hooks/shared";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function PopupMessage() {
	const { t } = useTranslation();

	const { notification } = useNotification();
	useEffect(() => {
		let notificationDataMessage = "";
		if (notification == null) {
			return;
		}
		const type = typeof notification.data;

		type !== "string"
			? notification.data.map((entry) => {
					notificationDataMessage =
						notificationDataMessage +
						" " +
						t(entry.message, entry.options);
			  })
			: (notificationDataMessage = t(notification.data));

		if (notification.type == "success") {
			App.state.rootStore.notificationStore.success(
				notificationDataMessage
			);
		} else if (notification.type == "error") {
			App.state.rootStore.notificationStore.error(
				notificationDataMessage
			);
		} else if (notification.type == "warning") {
			App.state.rootStore.notificationStore.warning(
				notificationDataMessage
			);
		} else {
			App.state.rootStore.notificationStore.info(notificationDataMessage);
		}
	}, [notification]);

	if (!notification?.buttons) {
		return null;
	}

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					{t("MEMBERSHIP.FORGOT_PASSWORD.INFO")}
				</div>
				<div className="popup__body">
					{t(notification.data)}
				</div>
				<div className="popup__footer">
					{notification.buttons.map((notificationButton, i) => (
						<NotificationButtonWithRedirect
							key={i}
							notificationButton={notificationButton}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

function NotificationButtonWithRedirect({ notificationButton }) {
	const { t } = useTranslation();

	const { btnText, redirectFunction } = notificationButton;

	return (
		<button
			className="popup__footer__btn btn btn--base btn--block btn--secondary--inverted"
			onClick={redirectFunction}
		>
			{t(btnText)}
		</button>
	);
}
