import RootStore from "@state/stores/RootStore";

import { LoaderStore } from "@state/stores/common";
import QuickBetStore from "./QuickBetStore";
import MostPlayTicketsStore from "./MostPlayedTicketsStore";

export default class QuickPlayStore {
	rootStore: RootStore;
	quickBetStore: QuickBetStore;
	mostPlayedTicketsStore: MostPlayTicketsStore;
	public loaderStore: LoaderStore;

	constructor(rootStore: RootStore) {
		this.loaderStore = new LoaderStore();

		this.rootStore = rootStore;

		this.quickBetStore = new QuickBetStore(this);
		this.mostPlayedTicketsStore = new MostPlayTicketsStore(this);
	}
}
