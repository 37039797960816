import React, { useRef } from "react";

import { WidgetLocation } from "../models";
import { useLSContainerCycles } from "../hooks/useLSContainerCycles";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";
import { useTranslation } from "react-i18next";

export const LSWidgetContainter = (props: {
	eventId: string;
	location: WidgetLocation;
	divProps?: React.HTMLProps<HTMLDivElement>;
}) => {
	const widgetRef = useRef<HTMLDivElement>(null);
	const t = useTranslation().t;

	const isError = useLSContainerCycles(
		widgetRef,
		props.eventId,
		props.location
	);

	return (
		<>
			<div ref={widgetRef} {...props.divProps}></div>
			{isError && (
				<div className={props.location == "SIDE" ? "u-padd--xsml" : ""}>
					<InfoMessageComponent
						type="note"
						size="base"
						message={t<string>("LIVE_WIDGET.FALLBACK_MESSAGE")}
					/>
				</div>
			)}
		</>
	);
};
