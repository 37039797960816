import React, { useEffect } from "react";
import { AccountVerificationStatus } from "@api-types/account-verification/AccountVerificationType";

export default function InsicWidget({
	status,
}: {
	status: AccountVerificationStatus["widget"];
}) {
	useEffect(() => {
		const script = document.createElement("script");
		script.src =
			status?.widgetLoaderScriptUrl ||
			"https://tip.insic.net/frontend2/static/js/avs-loader.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			if (window.AVS_LOGOUT && window.AVS_UNMOUNT) {
				window.AVS_LOGOUT(false);
				window.AVS_UNMOUNT();
			}
			document.body.removeChild(script);
		};
	}, []);

	window.__AVS_CONFIG__ = status?.widgetParameters;

	return <div id="___avs-wrapper"></div>;
}
