import { observable, action, computed } from "mobx";
import { ChatChannelHandler } from "./ChatChannelHandler";
import { ChatClientHandler } from "./ChatClientHandler";
import { ChannelListHandler } from "./ChannelListHandler";
import AgencyUserApiService from "@api-services/user/AgencyUserApiService";
import { ChannelSessionHandler } from "./ChannelSessionHandler";
import { localizationService } from "@state/services";
import {
	ActiveSharedBetSlip,
	ChannelInfoDataFromList,
	ChannelInfoDataFromOffer,
	ChannelInfoDataFromSession,
	ChatEntryPoint,
	ChatWindowState,
} from "@data-types/chat";
import RootStore from "../RootStore";
import { LoaderStore } from "../common";
import { ChatSDKService } from "@services/chat";
import { ChatApiError } from "./ChatApiErrorModel";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { Bet } from "@administration/pages/my-bets/model";
import { DateTime } from "luxon";
import { ChatBetSlipShareHandler } from "./ChatBetSlipShareHandler";

export class ChatViewStore {
	rootStore: RootStore;
	chatLoader: LoaderStore;
	chatClientHandler: ChatClientHandler;
	chatChannelHandler: ChatChannelHandler;
	chatListHandler: ChannelListHandler;
	channelSessionHandler: ChannelSessionHandler;
	chatBetSlipShareHandler: ChatBetSlipShareHandler;

	entryPoint: ChatEntryPoint | null;
	channelData:
		| ChannelInfoDataFromOffer
		| ChannelInfoDataFromList
		| ChannelInfoDataFromSession
		| null;

	@observable sharedBetSlipData: ActiveSharedBetSlip | null;

	@observable currentHighlightedMessageId: string;

	//flag for showing chat box
	@observable chatBoxVisible: boolean = false;

	//flag for showing chat box and chat button
	@observable chatWidgetVisible: boolean = false;

	//flag for showing chat list
	@observable chatListVisible: boolean = false;

	@observable errorMessage: string | null;

	@observable leaveChannelDropdownOpened: boolean = false;

	@observable isDisposing: boolean = false;

	@computed get chatDisplayName(): string | undefined {
		return this.rootStore.userAuthStore.user?.chatName;
	}

	@computed get userId() {
		return this.rootStore.userAuthStore.user?.username;
	}

	//loaders
	@computed get channelListLoader() {
		//triggered when fetching channel list
		return this.chatListHandler.loaderStore.isLoadingProcess;
	}

	@computed get messageSendingLoader() {
		//triggered when sending message in a channel
		return this.chatChannelHandler.messageLoader.isLoadingProcess;
	}

	@computed get flaggingLoader() {
		//triggered when trying to flag message
		return this.chatChannelHandler.flaggingLoader.isLoading;
	}

	@computed get channelInfoLoader() {
		return this.chatChannelHandler.channelInfoLoader.isLoadingProcess;
	}

	@computed get messageListLoader() {
		return this.chatChannelHandler.messageListLoader.isLoadingProcess;
	}

	@computed get shouldRenderMainLoader() {
		return (
			this.chatLoader.isLoadingProcess ||
			this.chatChannelHandler.messageListLoader.isLoadingProcess ||
			this.channelListLoader
		);
	}

	@computed get chatClient() {
		return this.chatClientHandler.currentClient;
	}

	@computed get chatBetSlipShareData() {
		return this.chatBetSlipShareHandler.activePopupBet;
	}

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		this.chatClientHandler = new ChatClientHandler(this);

		this.chatChannelHandler = new ChatChannelHandler(this);

		this.chatListHandler = new ChannelListHandler(this);

		this.channelSessionHandler = new ChannelSessionHandler(this);

		this.chatLoader = new LoaderStore();

		this.chatBetSlipShareHandler = new ChatBetSlipShareHandler(this);
	}

	async updateChatOnLoginChange(isUserLoggedIn: boolean) {
		if (isUserLoggedIn) {
			this.channelSessionHandler.handleJoiningChannelFromSession();
		} else {
			this.channelSessionHandler.removeSessionChannelInfo();
			await this.closeChat();
		}
	}

	async onInitialize(
		entryPoint: ChatEntryPoint,
		channelData:
			| ChannelInfoDataFromOffer
			| ChannelInfoDataFromList
			| ChannelInfoDataFromSession
			| null
	) {
		if (this.isDisposing) {
			//client and session still disposing
			return;
		}

		if (!WEBPACK_IS_CHAT_AVAILABLE) {
			//do not initiate chat
			return;
		}

		if (!this.userId) {
			//user not logged in
			App.state.rootStore.notificationStore.info(
				localizationService.t("CHAT.ERR_HANDLING.PLEASE_LOGIN")
			);
			return;
		}

		AnalyticsService.logOpenChatInteraction({
			category: "Chat",
			label: "chat-open",
		});

		const shouldUpdateChatName = this.checkForUserChatName(
			entryPoint,
			channelData,
			this.chatDisplayName
		);

		if (shouldUpdateChatName) {
			//updating chat name screen
			return;
		}

		if (
			this.chatWidgetVisible &&
			this.chatClient?.userId &&
			ChatEntryPoint.USER_MENU == entryPoint
		) {
			//if chat is visible, user is logged in into sdk client and entry point is user menu (quick link or user menu)
			this.toggleChatBox(true);
			return;
		}

		//trigger bet details store initializing,
		//(cannot do it in constructor due to betdetailsstore requiring offer store which is undefined in that moment)
		this.chatBetSlipShareHandler.initializeBetDetailsStore();

		try {
			this.chatLoader.suspend();

			this.toggleChatWidget(true);
			if (
				entryPoint !== ChatEntryPoint.SESSION ||
				(channelData &&
					"chatState" in channelData &&
					channelData.chatState !== ChatWindowState.MINIMIZED)
			) {
				this.toggleChatBox(true);
			}

			await this.chatClientHandler.handleClient();

			if (this.chatChannelHandler.disposerList.length > 0) {
				this.chatChannelHandler.disposeChannelHandler();
			}

			await this.chatListHandler.initialize(this.userId);

			await this.chatChannelHandler.joinUserIntoChannelPerType(
				entryPoint,
				channelData
			);

			this.channelSessionHandler.onInitialize();
		} catch (error) {
			this.chatLoader.resume();
			this.handleError(error);
		}
	}

	async openBetSlipMessagePreview(sharedBetSlip: Bet) {
		const thumbnailData = this.mapBetSlipThumbnailData(sharedBetSlip);

		this.setSharedBetSlipData(thumbnailData);

		if (!this.chatBoxVisible && !this.chatWidgetVisible) {
			this.onInitialize(ChatEntryPoint.BET_SLIP_SHARE, null);
		} else if (!this.chatBoxVisible) {
			this.toggleChatBox(true);
		}
	}

	@action.bound
	setSharedBetSlipData(betSlipData: ActiveSharedBetSlip | null) {
		this.sharedBetSlipData = betSlipData;
	}

	@action.bound
	toggleChatWidget(value: boolean) {
		this.chatWidgetVisible = value;
	}

	@action.bound
	setIsDisposing(value: boolean) {
		this.isDisposing = value;
	}

	@action.bound
	toggleChatBox(value?: boolean) {
		if (value !== undefined) {
			this.channelSessionHandler.updateChannelChatStateIntoSession({
				chatState: value
					? ChatWindowState.OPENED
					: ChatWindowState.MINIMIZED,
			});
			this.chatBoxVisible = value;
			return;
		}

		this.chatBoxVisible = !this.chatBoxVisible;
		this.channelSessionHandler.updateChannelChatStateIntoSession({
			chatState: this.chatBoxVisible
				? ChatWindowState.OPENED
				: ChatWindowState.MINIMIZED,
		});
	}

	@action.bound
	async toggleChatList(value: boolean) {
		if (value && this.userId) {
			//refresh user channels list on toggle
			await this.chatListHandler.initialize(this.userId);
		}
		this.chatListVisible = value;
	}

	async updateChatName(chatName: string, agencyId: string, userId: string) {
		if (this.entryPoint === null) {
			console.error(
				"CHAT ERR: Could not update chat name, entry point or channel data is null"
			);
			this.handleError(new ChatApiError(true));
			return;
		}

		try {
			await AgencyUserApiService.updateChatName(
				chatName,
				agencyId,
				userId
			);
			this.rootStore.userAuthStore.updateUserChatName(chatName);
			await this.onInitialize(this.entryPoint, this.channelData);
		} catch (error) {
			console.error("CHAT ERR: Failed updating chat name");
			this.handleError(new ChatApiError(true));
		}
	}

	mapBetSlipThumbnailData(sharedBetSlip: Bet): ActiveSharedBetSlip {
		let eventName: string;
		const bet = new Bet(sharedBetSlip);

		const firstOfferElement = bet.betSlipOffers[0];

		const firstElementSportAbrv: string =
			firstOfferElement.sportData.sportAbrv;

		const isMultiSport = !bet.betSlipOffers.every(
			(x) => firstElementSportAbrv === x.sportData.sportAbrv
		);

		if (firstOfferElement.sportData.eventType != 0) {
			eventName =
				firstOfferElement.hasOwnProperty("isMulti") &&
				firstOfferElement.isMulti == true
					? firstOfferElement.multiEventName
					: firstOfferElement.sportData.eventName;
		} else {
			eventName = firstOfferElement.isMulti
				? firstOfferElement.multiEventName
				: [
						firstOfferElement.teamOneString,
						firstOfferElement.teamTwoString,
				  ].join(" - ");
		}

		return {
			eventCount: sharedBetSlip.eventCount,
			maxCoefficient: sharedBetSlip.maxCoefficient,
			betSlipNumber: sharedBetSlip.betSlipNumber,
			dateCreated: DateTime.fromISO(
				sharedBetSlip.dateCreated
			).toLocaleString(DateTime.DATETIME_SHORT),
			sportAbrv: isMultiSport ? "multi" : firstElementSportAbrv,
			firstEventName: eventName,
		};
	}

	checkForUserChatName(
		entryPoint: ChatEntryPoint | null,
		channelData:
			| ChannelInfoDataFromOffer
			| ChannelInfoDataFromList
			| ChannelInfoDataFromSession
			| null,
		chatDisplayName: string | undefined
	): boolean {
		if (!chatDisplayName) {
			this.toggleChatWidget(true);
			this.toggleChatBox(true);
			this.entryPoint = entryPoint;
			this.channelData = channelData;
			return true;
		}

		this.entryPoint = null;
		this.channelData = null;
		return false;
	}

	async leaveCurrentChannel() {
		try {
			this.chatLoader.suspend();
			//dispose listeners
			this.chatChannelHandler.disposeChannelListeners();

			const hasUserLeftTheChannel =
				await this.chatChannelHandler.leaveCurrentChannel();

			if (hasUserLeftTheChannel) {
				//dispose selected channel
				this.chatChannelHandler.disposeChannelHandler();
				this.closeChat();
			}
		} catch (error) {
			this.handleError(error);
		} finally {
			this.chatLoader.resume();
		}
	}

	handleSDKError(error: {
		errorName: string;
		isBlocking: boolean;
		localizedErrorKey: string;
	}) {
		this.errorMessage = error.localizedErrorKey;

		this.rootStore.notificationStore.error(
			localizationService.t(
				error?.localizedErrorKey || "COMMON.ERROR_MESSAGES.DEFAULT"
			)
		);

		if (error.isBlocking) {
			//only dispose session info on blocking error
			this.channelSessionHandler.disposeChannelSessionInfo();
			this.closeChat();
		}
	}

	@action.bound
	setLeaveChannelDropdownOpened(value: boolean) {
		this.leaveChannelDropdownOpened = value;
	}

	@action.bound
	handleError(error: any) {
		//called with catch for api calls or sdk calls
		const clientErrors = ChatSDKService.getClientErrors(error?.code);
		if (clientErrors) {
			//sdk caught an error
			this.handleSDKError(clientErrors);
			return;
		}

		//api error
		this.errorMessage = error?.message;
		this.rootStore.notificationStore.error(
			localizationService.t(
				error?.message || "COMMON.ERROR_MESSAGES.DEFAULT"
			)
		);

		if (error?.isBlocking) {
			this.closeChat();
			//only dispose session info on blocking error
			this.channelSessionHandler.disposeChannelSessionInfo();
		}
	}

	async closeChat() {
		this.setIsDisposing(true);
		this.onDispose();
		if (this.channelSessionHandler.getChannelInfoFromSession()) {
			this.channelSessionHandler.updateChannelChatStateIntoSession({
				shouldOpenOnRefresh: false,
			});
		}
		this.chatChannelHandler.disposeChannelHandler();
		await this.chatClientHandler.disposeChatClient();
		this.chatListHandler.disposeChannelList();
		this.setIsDisposing(false);
	}

	@action.bound
	onDispose() {
		this.chatWidgetVisible = false;
		this.chatBoxVisible = false;
		this.chatListVisible = false;
		this.sharedBetSlipData = null;
		this.channelSessionHandler.disposeSessionExpiry();
		this.chatBetSlipShareHandler.onDispose();
	}
}
