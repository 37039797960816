import RootStore from "@state/stores/RootStore";
import { getCurrentCulture } from "@utils";
import { StorageStateKeysEnum } from "@utils/storage";
import { observable, action } from "mobx";

type TRootStore = {
	localStorageProvider: RootStore["localStorageProvider"];
	userAuthStore: RootStore["userAuthStore"];
};

class OnboardingRegisterStore {
	@observable isActive: boolean = false;

	rootStore: TRootStore;

	constructor(rootStore: TRootStore) {
		this.rootStore = rootStore;
	}

	@action.bound
	onInitialize() {
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.ONBOARDING_REGISTER_POPUP,
			JSON.stringify(true)
		);
	}

	@action.bound
	showPopup() {
		this.isActive = true;
	}

	@action.bound
	hidePopup() {
		this.isActive = false;
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.ONBOARDING_REGISTER_POPUP,
			JSON.stringify(true)
		);
	}

	@action.bound
	onOnboardingRedirection() {
		this.isActive = false;
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.ONBOARDING_REGISTER_POPUP,
			JSON.stringify(true)
		);
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.ONBOARDING_NEWSLETTER_POPUP,
			JSON.stringify(true)
		);
		if (this.rootStore.userAuthStore.isLoggedIn) {
			App.state.redirect(
				`/${getCurrentCulture()}/account-settings/my-bonuses`
			);
		} else {
			App.state.redirect(`/${getCurrentCulture()}/auth/register`);
		}
	}
}

export default OnboardingRegisterStore;
