import { UserDocumentFileStreamsApiService } from "@api-services/document-upload/UserDocumentFileStreamsApiService";

export class DocumentUploadService {
	static async sendUserDocumentFiles(files: File[]) {
		try {
			const formData = new FormData();
			files.forEach((file, id) => {
				formData.append("file" + id, file);
				formData.append(
					"fileData" + id,
					JSON.stringify([{ filename: file.name }])
				);
			});
			await UserDocumentFileStreamsApiService.sendUserDocumentFiles(
				formData
			);
		} catch (e) {
			console.error(e);
			/* TooManyUnreviewedDocuments = 400225; */
			if (e.data && e.data.errorCode == 400225) {
				throw {
					message: "ACCOUNT_VERIFICATION.UNREVIEWED_DOCUMENTS",
				};
			}
			if (e.data && e.data.message) {
				throw { message: e.data.message };
			}
			throw { message: "ERROR.MESSAGE" };
		}
	}
}
