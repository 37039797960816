import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { MyBetsViewStore } from "@offer/stores/components/my-bets";

const CountDown = observer(function CountDown(props: {
	myBetsViewStore: MyBetsViewStore;
}) {
	const { t } = useTranslation();
	const cashoutStore = props.myBetsViewStore.cashoutStore;

	if (cashoutStore.cashoutLocation !== "my-bets") {
		return null;
	}

	if (cashoutStore.isCountdownVisible && cashoutStore.countdownCounter > 0) {
		return (
			<div className="loader--countdown is-mini">
				<div className="loader--countdown__inner is-mini">
					<div className="loader--countdown__box">
						{props.myBetsViewStore.cashoutStore.countdownCounter}
					</div>
					<div className="loader--countdown__title">
						{t<string>("HOME.LOADING")}...
					</div>
				</div>
			</div>
		);
	}

	if (cashoutStore.isCashoutFormSubmitting) {
		return (
			<div className="loader--countdown is-mini">
				<div className="loader--countdown__inner is-mini">
					<div className="loader__card">
						<div className="loader__spinner u-display--b" />
						<div className="loader__title u-type--color--white">
							<>{t("HOME.LOADING")}...</>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return null;
});

export default CountDown;
