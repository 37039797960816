import React, { FC, useContext } from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { WithdrawalResponseItem } from "@api-types/withdrawal";
import { WithdrawalContext } from "@context";
import PaymentOptionImage from "@v2/components/shared/icons/PaymentOptionImage";
import { getSVGExtension } from "@utils";

export const WithdrawalHistoryTableItem: FC<{
	withdrawalHistoryItem: WithdrawalResponseItem;
}> = observer(function WithdrawalHistoryTableItem(props) {
	const { t } = useTranslation();
	const { userPaymentMethods } = useContext(WithdrawalContext);
	const { withdrawalHistoryItem } = props;

	const itemPaymentMethod = userPaymentMethods?.find(
		(method) =>
			method.paymentMethodId === withdrawalHistoryItem.paymentMethodId
	);

	//use this abrv for icon
	const itemWalletAbrv = App.state.rootStore.userAuthStore.userAccounts?.find(
		(w) => w.accountTypeId === withdrawalHistoryItem.bettingAccountTypeId
	)?.abrv;

	const endDate = DateTime.fromISO(withdrawalHistoryItem.endDate)
		.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
		.replace(",", "");
	const startDate = DateTime.fromISO(withdrawalHistoryItem.startDate)
		.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
		.replace(",", "");

	const statusClass = classnames(
		"u-align--v--middle u-type--case--capitalize",
		{
			"u-color--ui--success":
				withdrawalHistoryItem.status?.abrv === "success",
			"u-color--text--primary":
				withdrawalHistoryItem.status?.abrv === "pending",
			"u-color--ui--muted":
				withdrawalHistoryItem.status?.abrv === "canceled",
			"u-color--ui--danger":
				withdrawalHistoryItem.status?.abrv === "failed",
		}
	);

	const iconClass = classnames(
		"u-icon u-icon--xsml",
		{
			"u-icon--check u-color--ui--success":
				withdrawalHistoryItem.status?.abrv === "success",
			"u-icon--clock": withdrawalHistoryItem.status?.abrv === "pending",
			"u-icon--delete u-color--ui--muted":
				withdrawalHistoryItem.status?.abrv === "canceled",
			"u-icon--remove u-color--ui--danger":
				withdrawalHistoryItem.status?.abrv === "failed",
		},
		"u-align--v--middle u-mar--right--xtny"
	);

	return (
		<>
			<tr className="table--primary__body__row">
				<td className="table--primary__body__data u-padd--xsml">
					<div>{withdrawalHistoryItem.numericalId}</div>
					<WithdrawalHistoryItemCancel
						status={withdrawalHistoryItem.status?.abrv}
						id={withdrawalHistoryItem.id}
					/>
				</td>
				<td className="table--primary__body__data u-padd--xsml u-type--wgt--medium">
					{withdrawalHistoryItem.amount}
					{withdrawalHistoryItem.currency.displayCode}
				</td>
				<td className="table--primary__body__data u-padd--xsml">
					<div className="card--payment card--payment--borderless u-mar--bottom--reset">
						<div className="card--payment__area u-padd--reset">
							{/* todo: replace display name with abrv */}
							<span className="card--payment__icon">
								<PaymentOptionImage
									className={`card--payment__logo u-icon u-icon--payment u-icon--payment--sml u-icon--payment--${itemPaymentMethod?.paymentMethodAbrv.toLowerCase()}`}
									svgName={
										itemPaymentMethod?.paymentMethodAbrv.toLowerCase() +
										getSVGExtension()
									}
								/>
							</span>
							<span className="card--payment__details card--payment__details--centered">
								<span className="card--payment__name u-type--wgt--regular">
									{withdrawalHistoryItem.ccmaskedPan}
								</span>
								<span className="card--payment__type">
									{withdrawalHistoryItem.ccexpiryDate}
								</span>
							</span>
						</div>
					</div>
				</td>
				<td className="table--primary__body__data u-padd--xsml">
					{startDate}
				</td>
				<td className="table--primary__body__data u-padd--xsml">
					{withdrawalHistoryItem.status?.abrv === "pending"
						? "--------"
						: endDate}
				</td>
				<td className="table--primary__body__data u-padd--xsml u-type--center">
					<i
						className={`wallet__icon wallet__icon--menu u-icon u-icon--sml u-icon--wallet u-icon--wallet--${itemWalletAbrv} u-mar--right--reset u-mar--left--reset`}
					/>
				</td>
				<td className="table--primary__body__data u-padd--xsml">
					<i className={iconClass}></i>
					<span className={statusClass}>
						{withdrawalHistoryItem.status?.name}
					</span>
				</td>
			</tr>
		</>
	);
});

const WithdrawalHistoryItemCancel: FC<{ status?: string; id: string }> =
	observer(function WithdrawalHistoryItemCancel(props) {
		const { t } = useTranslation();
		const { setShowConfirmationModal } = useContext(WithdrawalContext);

		const { id, status } = props;

		if (status !== "pending") {
			return null;
		}

		const cancelTransaction = () => {
			setShowConfirmationModal(true, id);
		};

		return (
			<button
				onClick={cancelTransaction}
				className="btn btn--xtny btn--block btn--secondary--inverted u-color--ui--danger"
			>
				{t<string>("COMMON.CANCEL")}
			</button>
		);
	});
