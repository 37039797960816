import {
	observable,
	computed,
	action,
	reaction,
	when,
	IReactionDisposer,
	runInAction,
} from "mobx";
import { generatePrematchOfferMenuFilter } from "../sports-menu/helpers";

import { BaseOfferMenuStore } from "./BaseOfferMenuStore";
import { TRootStore as TBaseRootStore } from "./BaseOfferMenuStore";
import NodeStore from "./node/NodeStore";
import { PrematchPeriodSubMenuStore } from "./period-sub-menu";
import { TPrematchSubMenuParent } from "./period-sub-menu/TPrematchSubMenuParent";
import { getCurrentCulture } from "@utils";
import { SportMenuNodeType } from "./SportNodeType";
import { Period } from "@state/common/PeriodConverter";
import RootOfferStore from "@offer/stores/RootStore";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

type TRootStore = {
	promoOfferViewStore: RootOfferStore["promoOfferViewStore"];
} & TBaseRootStore;

// prettier-ignore
const SportsMenuApiService = lazy(loadFailPath, ()=>import("@api-services/offer/SportsMenuApiService"));

export default class PrematchOfferMenuStore
	extends BaseOfferMenuStore
	implements TPrematchSubMenuParent
{
	public type: "PrematchOfferMenuStore" = "PrematchOfferMenuStore";
	private urlSync: IReactionDisposer | null;
	protected waitForStoreInitialize: IReactionDisposer | null;

	public periodSubMenu: PrematchPeriodSubMenuStore;
	@observable public isProcessingParams: boolean = false;
	@observable public selectedSport: this["menu"] | null = null;
	@observable isUpdateProcessing: boolean = false;
	@observable public topTournamentTournamentIds: string[] | null;
	public rootStore: TRootStore;

	@observable isFavoritesSubMenuOpen = false;

	@computed get period() {
		return this.periodSubMenu.selectedPeriodKey;
	}

	//#region sideMenu actions overrides

	/** Sport node to be selected in main sports list. */
	@action.bound
	toggleSport(node: this["menu"]) {
		if (node?.node?.id === this.selectedSport?.node?.id) {
			this.deselectSport();
		} else {
			this.selectSport(node);
		}
	}

	/** Sport node to be selected in main sports list. */
	@action.bound
	selectSport(node: this["menu"]) {
		this.selectedSport = node;

		this.openOfferMenu();
	}

	/** Removes sport from side menu and closes side menu. */
	@action.bound
	deselectSport() {
		this.selectedSport = null;
		this.closeOfferMenu();
	}

	//#endregion sideMenu actions overrides

	//#region observable

	@observable menu = new NodeStore<SportMenuNodeType>({
		id: "",
		count: 0,
		type: "root",
		name: "",
	});

	//#endregion observable

	//#region constructor

	constructor(rootStore: TRootStore) {
		super(rootStore);
		this.rootStore = rootStore;

		this.periodSubMenu = new PrematchPeriodSubMenuStore(this);
		this.startUrlSync();
	}

	//#endregion constructor

	//#region sport menu

	@observable isOfferMenuOpen = false;

	@computed get isExpandDisabled() {
		return this.menu.children.length <= 0;
	}

	@action.bound
	uncheckAndCollapse() {
		this.menu.onCheck(false);
		this.menu.collapseTree();
	}

	@action.bound
	toggleOpenOfferMenu() {
		if (this.isOfferMenuOpen) {
			this.closeOfferMenu();
		} else {
			this.openOfferMenu();
		}
	}

	@action.bound
	openOfferMenu() {
		if (
			this.selectedSport === null &&
			this.menu?.children != null &&
			this.menu.children.length > 0
		) {
			this.selectedSport = this.menu?.children?.[0];
		}

		this.isOfferMenuOpen = true;
		this.periodSubMenu.isPeriodMenuExpanded = false;
		this.isFavoritesSubMenuOpen = false;
		this.rootStore.openSideMenu();
	}

	@action.bound
	closeOfferMenu() {
		this.selectedSport = null;

		this.isOfferMenuOpen = false;
		this.rootStore.closeSideMenu();
	}
	@action.bound
	openFavoritesMenu() {
		this.isOfferMenuOpen = false;
		this.periodSubMenu.isPeriodMenuExpanded = false;
		this.isFavoritesSubMenuOpen = true;
		this.deselectSport();
		// this.resetMenu();
		this.rootStore.openSideMenu();
	}

	@action.bound
	closeFavoritesMenu() {
		this.isOfferMenuOpen = false;
		this.periodSubMenu.isPeriodMenuExpanded = false;
		this.isFavoritesSubMenuOpen = false;
		this.rootStore.closeSideMenu();
	}

	@action.bound toggleFavoritesMenu() {
		if (this.isFavoritesSubMenuOpen) {
			this.closeFavoritesMenu();
		} else {
			this.openFavoritesMenu();
		}
	}
	@action.bound
	resetMenu() {
		this.menu.onCheck(false);
		this.menu.collapseTree();
	}

	//#endregion sport menu

	//#region fetching data

	@action.bound
	async fetchData() {
		if (!this.isFetchingData) {
			this.onStartFetching();
		}

		const fetchingForPeriod = this.periodSubMenu.selectedPeriodKey;
		const filter = generatePrematchOfferMenuFilter(fetchingForPeriod);
		const menuResponse = await (
			await SportsMenuApiService
		).default.getPrematchMenu(filter);

		if (fetchingForPeriod !== this.periodSubMenu.selectedPeriodKey) {
			return;
		}

		this.assignMenu(menuResponse);

		this.onBeforeFetchFinish();

		this.onDoneFetching();
	}

	@action.bound
	selectFirstSport() {
		// Don't select first sport on home page;
		if (!App.state.history.location.pathname.includes("land")) {
			return;
		}
		if (!App.state.history.location.pathname.includes("my-favorites"))
			return;

		const selectedSport = this.menu?.children?.[0];
		this.selectSport(selectedSport);
	}

	@action.bound
	onBeforeInitialize() {
		this.openOfferMenu();
	}

	@action.bound
	onBeforeFetchFinish() {
		// Since store is constantly being reinitialized check first if there is a valid first sport
		if (
			this.selectedSport == null ||
			this.menu.children?.find(
				(sport) => sport.node.id === this.selectedSport?.node.id
			) == null
		) {
			const selectedSport = this.menu?.children?.[0];
			if (!location.pathname.includes("/my-favorites")) {
				this.selectSport(selectedSport);
			}
		}

		if (this.urlSync == null) {
			this.startUrlSync();
		}
	}

	//#endregion fetching data

	//#region disposers

	@action.bound
	onDispose() {
		this.onBaseDispose();
		this.disposeWhenStoreIsInitialized();
		this.disposeUrlSyn();
	}

	@action.bound
	disposeWhenStoreIsInitialized() {
		if (this.waitForStoreInitialize != null) {
			this.waitForStoreInitialize();
			this.waitForStoreInitialize = null;
		}
	}

	@action.bound
	disposeUrlSyn() {
		if (this.urlSync != null) {
			this.urlSync();
			this.urlSync = null;
		}
	}

	//#endregion disposers

	//#region url update

	@action.bound
	toggleUpdateProcessing() {
		this.isUpdateProcessing = !this.isUpdateProcessing;
	}

	@action.bound
	updateUrl() {
		// if (App.state.history.location.pathname.includes("event")) {
		// 	return;
		// }
		const url = this.getUrl();
		App.state.redirect(url, true);
	}

	protected getUrl() {
		const segments = this.menu.urlStructure;
		// If there are any segments then it will redirect to sports page
		let urlBase = this.getBaseUrl(segments === "");
		const queryParams = App.state.history.location.search;
		if (
			App.state.history.location.pathname.includes("my-favorites") &&
			!segments
		) {
			if (App.state.history.location.pathname.includes("events")) {
				return (urlBase += "/my-favorites/events");
			}
			return (urlBase += "/my-favorites/tournaments");
		}
		if (segments == null || segments === "") {
			return urlBase + queryParams;
		}

		return urlBase + `/f${segments}` + queryParams;
	}

	getBaseUrl(persistPage = false) {
		const period = this.periodSubMenu.selectedPeriodKey;
		const page =
			App.state.history.location.pathname.includes("home") && persistPage
				? "home"
				: "sports";
		if (page == "home") {
			const currentHomePageOffer =
				App.state.history.location.pathname.split("/");

			return `/${getCurrentCulture()}/${page}/${period}/${
				currentHomePageOffer[4]
			}`;
		}
		return `/${getCurrentCulture()}/${page}/${period}`;
	}

	//#endregion url update

	//#region update params

	@action.bound
	async setRouterMatchObj(routerMatch: {
		params: {
			period: Period;
			segments: string;
			tournamentIds?: string;
		};
		path: string;
	}) {
		this.isProcessingParams = true;
		const { params, path } = routerMatch;
		this.topTournamentTournamentIds = null;
		let topTournamentIds: string[] | null = null;

		if (params.tournamentIds != null) {
			console.log("await top tournament ids");
			topTournamentIds =
				await this.rootStore.promoOfferViewStore.getTopTournamentIds(
					params.tournamentIds
				);
			console.log("run store init", params.tournamentIds);
		}

		// If store is not yet initialized then after initialization is done reset menu based on url
		const resetMenuOnUrl =
			!this.isStoreInitialized ||
			App.state.history.action === "POP" ||
			path.includes("tournament") ||
			params.segments == null ||
			params.segments === "";
		this.disposeWhenStoreIsInitialized();

		this.waitForStoreInitialize = when(
			() => this.isStoreInitialized,
			() =>
				runInAction(() => {
					console.log("run store init", topTournamentIds);
					this.setParams(params, resetMenuOnUrl);

					this.topTournamentTournamentIds = topTournamentIds;
					if (path.includes("land")) {
						this.selectFirstSport();
					}

					// Clear menu selection if we switch to home page
					if (path.includes("home")) {
						this.menu.onCheck(false);
						this.menu.collapseTree();
					}
					this.isProcessingParams = false;
				})
		);
	}

	@action.bound
	setParams(
		params: { period: Period; segments: string; topTournamentId?: string },
		shouldResetMenuOnUrl: boolean
	) {
		const { period, segments } = params;

		if (shouldResetMenuOnUrl) {
			this.resetMenuOnUrlParamsFromRouter(segments);
		}
		const shouldOpenOfferMenu = location.pathname.includes("my-favorites");
		this.periodSubMenu.setPeriodKey(period, !shouldOpenOfferMenu);
	}

	//#endregion update params

	//#region  url sync

	@action.bound
	startUrlSync(): void {
		this.urlSync = reaction(
			() => {
				return {
					selected: this.menu.urlStructure,
					period: this.periodSubMenu.selectedPeriodKey,
				};
			},
			() => {
				if (this.isUpdateProcessing) {
					this.toggleUpdateProcessing();
					this.updateUrl();
				}
			}
		);
	}

	//#endregion url syn
}
