import React from "react";
import { useTranslation } from "react-i18next";

import { DateTime } from "luxon";
import classNames from "classnames";

import { BonusTypeEnum, UserBonusInfoREST } from "@api-types/myBonuses/Bonus";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export function UsedBonusMessage({
	status,
	infoMessage,
}: {
	status: UserBonusInfoREST;
	infoMessage?: string;
}) {
	const { t } = useTranslation();

	const iconClasses = classNames("u-icon u-icon--xxlrg", {
		"u-icon--deposit--alt": status.bonusType == BonusTypeEnum.deposit,
		"u-icon--my-bonuses--alt": status.bonusType == BonusTypeEnum.voucher,
	});
	const bonusTitle =
		status.bonusType == BonusTypeEnum.deposit
			? "USER.ACCOUNT_SETTINGS.MY_BONUSES.DEPOSIT_BONUS"
			: "USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_BONUS";

	return (
		<>
			<InfoMessageComponent
				type="note"
				message={t<string>(
					infoMessage ||
						`USER.ACCOUNT_SETTINGS.MY_BONUSES.BONUS_ACTIVE`
				)}
				addClassName="u-mar--bottom--med"
			/>

			<div className="card--bonus">
				<div className="card--bonus__header">
					<i className={iconClasses} />
					<div className="card--bonus__title">
						<div className="card--bonus__type">
							<span>{t<string>(bonusTitle)}: </span>
							<strong>
								{status.rolloverInfo.bonusAmount.toFixed(2)}{" "}
								{status.rolloverInfo?.currencyDisplayCode}
							</strong>
						</div>
						<div className="card--bonus__date">
							<span>
								{t<string>(
									`USER.ACCOUNT_SETTINGS.MY_BONUSES.BONUS_ACTIVATED`
								)}
								:{" "}
							</span>
							<span>
								{DateTime.fromISO(
									status.rolloverInfo.activationTime
								).toLocaleString(DateTime.DATE_SHORT)}
							</span>
						</div>
					</div>
				</div>
				<div className="card--bonus__body">
					<div className="row">
						<div className="col col-sml-4">
							<div className="u-color--text--secondary">
								{t<string>(
									`USER.ACCOUNT_SETTINGS.MY_BONUSES.BONUS_REQ_ROLLOVER`
								)}
								:
							</div>
							<div>
								{status.rolloverInfo.requiredRollover.toFixed(
									2
								)}{" "}
								{status.rolloverInfo.currencyDisplayCode}
							</div>
						</div>
						<div className="col col-sml-4">
							<div className="u-color--text--secondary">
								{t<string>(
									`USER.ACCOUNT_SETTINGS.MY_BONUSES.BONUS_CURR_ROLLOVER`
								)}
								:
							</div>
							<div className="u-type--wgt--medium u-color--ui--success">
								{status.rolloverInfo.currentRollover.toFixed(2)}{" "}
								{status.rolloverInfo.currencyDisplayCode} (
								{status.rolloverInfo.currentRolloverPercentage}{" "}
								%)
							</div>
						</div>
						<div className="col col-sml-4">
							<div className="u-color--text--secondary">
								{t<string>(
									`USER.ACCOUNT_SETTINGS.MY_BONUSES.BONUS_REMAINING_ROLLOVER`
								)}
								:
							</div>
							<div className="u-type--wgt--medium u-color--ui--danger">
								{status.rolloverInfo.rolloverRemainder.toFixed(
									2
								)}{" "}
								{status.rolloverInfo.currencyDisplayCode} (
								{
									status.rolloverInfo
										.rolloverRemainderPercentage
								}{" "}
								%)
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
