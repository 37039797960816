import React, { useMemo, useEffect, useState } from "react";
import { observer } from "mobx-react";
// import { AppUpdate } from "@capawesome/capacitor-app-update";
// import { App as CapacitorApp } from "@capacitor/app";

// @ts-ignore
import MainLayout from "themes/layouts/MainLayout";
import { RootOfferStoreContext, RootAppStoreContext } from "@context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SessionStorageProvider } from "@state";
import VersionChangeDetector from "@state/utils/VersionChangeDetector";
import { DeviceDetector, getCurrentCulture } from "@utils";

import {
	useCapacitorPlatform,
	isCapacitorPlatform,
} from "@utils/specific/capacitor";
import AnalyticsService from "@services/analytics/AnalyticsService";
import RestrictionService from "@services/restriction/RestrictionService";
import { StorageStateKeysEnum } from "@utils/storage";
import { LoginResponseUser } from "@api-types/user/LoginResponseDto";
import CookieConsent from "@components-shared/CookieConsent";

//#region lazy

// import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
// const loadFailPath = `/${getCurrentCulture()}/app-update`;

//const GigIframeMainLayout = lazy(loadFailPath, ()=>import("../gig-payment/GigIframeMainLayout"));
// import GigIframeMainLayout from "themes/layouts/GigIframeMainLayout";

//#endregion lazy

export default observer(function AppRoot() {
	console.log("....", WEBPACK_GIT_COMMIT_HASH);
	// show only on dev, qa, tl
	if (WEBPACK_BASE_ADDRESS.indexOf("develop") != -1) {
		console.log("....", WEBPACK_GIT_BRANCH);
		console.log("....", WEBPACK_GIT_LAST_COMMIT_DATE);
	}

	const culture = getCurrentCulture();

	const versionChangeDetector = useMemo(
		() =>
			// Interval is 10 min
			new VersionChangeDetector(() => {
				if (
					!App.state.history.location.pathname.includes("/app-update")
				) {
					App.state.redirect(`/${getCurrentCulture()}/app-update`);
				}
			}, 600000),
		[]
	);

	// const appVersion = async () => {
	// 	const info = await CapacitorApp.getInfo();
	// 	console.log(info);
	// 	return info.version;
	// };

	// const openAppStore = async () => {
	// 	await AppUpdate.openAppStore();
	// };

	// const CurrentThemeComponent = () => {
	// 	const isDarkTheme = useSystemTheme();
	// 	return <p>Current Theme is: {isDarkTheme ? "dark" : "light"}</p>;
	// };

	useEffect(() => {
		if (App.state.history.location.pathname.includes("/app-update")) {
			App.state.history.goBack();
		}

		versionChangeDetector.onInitialize();
		return () => {
			versionChangeDetector.onDispose();
		};
	}, []);

	useEffect(() => {
		App.offer.rootStore.onInitialize();
		App.state.rootStore.onInitialize();

		return () => {
			App.offer.rootStore.onDispose();
			App.state.rootStore.onDispose();
		};
	}, []);

	useEffect(() => {
		const user = JSON.parse(
			App.state.rootStore.localStorageProvider.get(
				StorageStateKeysEnum.USER_KEY
			) as string
		) as LoginResponseUser | null;

		AnalyticsService.setUserId(user != null ? user.id : "anon");
	}, []);

	//listener for capacitor events
	useCapacitorPlatform();

	//check if app is loaded in iframe
	// if (window.location !== window.parent.location) {
	// 	return <GigIframeMainLayout />;
	// }

	return (
		<RootOfferStoreContext.Provider value={App.offer.rootStore}>
			<RootAppStoreContext.Provider value={App.state.rootStore}>
				<ToastContainer
					containerId={"toast-container"}
					position="bottom-right"
				/>
				<MainLayout />
				{!isCapacitorPlatform() && <CookieConsent />}
			</RootAppStoreContext.Provider>
		</RootOfferStoreContext.Provider>
	);
});
