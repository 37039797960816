import { DeviceDetector } from "@utils";
import classNames from "classnames";
import React, { useEffect, useState, useContext } from "react";
import { EventContext } from "@gp/components";
import { VideoPlayerConfig } from "./config/LiveStreamConfig";
import { isTablet } from "./config/LiveStreamConfig";

import { getCurrentCulture } from "@utils";

//#region lazy
import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
import {
	isCapacitorPlatform,
	setCapacitorDeviceOrientation,
} from "@utils/specific/capacitor";
import { useLiveWidgetStore } from "@state/hooks/live-widgets";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
const ReactPlayer = lazy(loadFailPath, () => import("react-player"));

//#endregion lazy

export function VideoPlayer(props: {
	url: string;
	config: VideoPlayerConfig;
	eventId: string;
	setErrorMessage: (value: string | undefined) => void;
}) {
	const { url, config, eventId, setErrorMessage } = props;
	const [liveStreamStatus, setLiveStreamStatus] = useState<boolean>(false);
	const [pausedStatus, setPausedState] = useState<boolean>(true);
	const store = useLiveWidgetStore();
	const currentLiveStream: HTMLVideoElement | null = document?.querySelector(
		`#ds-${eventId} video`
	);

	useEffect(() => {
		const handleFullscreenChange = () => {
			if (document.fullscreenElement === currentLiveStream) {
				setCapacitorDeviceOrientation("landscape");
			} else {
				setCapacitorDeviceOrientation("portrait");
			}
		};

		isCapacitorPlatform() &&
			currentLiveStream?.addEventListener(
				"fullscreenchange",
				handleFullscreenChange
			);

		return () => {
			currentLiveStream?.removeEventListener(
				"fullscreenchange",
				handleFullscreenChange
			);
			setCapacitorDeviceOrientation("portrait");
		};
	}, [currentLiveStream]);

	let controlSettings = [];
	if (!config.allowFullScreen || config.isTablet) {
		controlSettings.push("nofullscreen");
	}
	if (!config.allowPlayBack) {
		controlSettings.push("noplaybackrate");
	}

	const onError = (
		error: any,
		data?: any,
		hlsInstance?: any,
		hlsGlobal?: any
	): void => {
		store.logger.logError(
			"Error",
			error,
			"Data: ",
			data,
			"HlsInstance: ",
			hlsInstance,
			"HlsGlobal",
			hlsGlobal
		);
		// setErrorMessage("Not supported for this device");
		setCapacitorDeviceOrientation("portrait");
	};

	const onEnd = () => {
		setErrorMessage("Stream has ended.");
		setCapacitorDeviceOrientation("portrait");
	};

	return (
		<div
			className="widget--stream__inner"
			style={{ maxWidth: config.maxWidth || "" }}
		>
			<>
				<ReactPlayer
					url={url}
					controls={config.areControlsEnabled}
					config={{
						file: {
							// forceSafariHLS: true,
							attributes: {
								controlsList: controlSettings.join(" "),
								disablepictureinpicture: JSON.stringify(
									config.pip
								),
							},
							hlsVersion: "1.3.4",
						},
					}}
					playsinline={true}
					playing={config.isAutoPlayEnabled}
					width={config.playerWidth}
					height={config.playerHeight}
					style={config.playerInlineStyle}
					muted={config.isMuteOnShowEnabled}
					onPause={() => setPausedState(true)}
					onPlay={() => setPausedState(false)}
					pip={false}
					id={"ds-" + eventId}
					className="widget--stream__player"
					onStart={() => setLiveStreamStatus(true)}
					onError={onError}
					onEnded={onEnd}
					data-allow-fullscreen="true"
				/>
				{liveStreamStatus && (
					<LiveStreamActionButtons
						eventId={eventId}
						pausedStatus={pausedStatus}
						allowFullScreen={config.allowFullScreen}
					/>
				)}
			</>
		</div>
	);
}

function LiveStreamActionButtons(props: {
	eventId: string;
	pausedStatus: boolean;
	allowFullScreen: boolean;
}) {
	const { eventId, pausedStatus, allowFullScreen } = props;

	const currentLiveStream: HTMLVideoElement | null = document?.querySelector(
		`#ds-${eventId} video`
	);

	const [muted, setMuted] = useState<boolean>(
		currentLiveStream?.muted || false
	);
	useEffect(() => {
		const fullscreenEventListener = () => {
			setMuted(currentLiveStream?.muted || false);
		};
		currentLiveStream?.addEventListener(
			"fullscreenchange",
			fullscreenEventListener
		);
		currentLiveStream?.addEventListener(
			"webkitendfullscreen",
			fullscreenEventListener
		);
		return () => {
			currentLiveStream?.removeEventListener(
				"fullscreenchange",
				fullscreenEventListener
			);
			currentLiveStream?.removeEventListener(
				"webkitendfullscreen",
				fullscreenEventListener
			);
		};
	}, []);

	if (isTablet() || !allowFullScreen) {
		return (
			<div
				className={classNames(
					"widget__actions widget__actions--tablet",
					{
						"widget__actions--over": !DeviceDetector.isMobileTheme,
					}
				)}
			>
				<PlayPauseButton
					currentLiveStream={currentLiveStream}
					pausedStatus={pausedStatus}
				/>
				<MuteButton
					currentLiveStream={currentLiveStream}
					muted={muted}
					setMuted={setMuted}
				/>
			</div>
		);
	}

	return (
		<div
			className={classNames("widget__actions", {
				"widget__actions--over": !DeviceDetector.isMobileTheme,
				"widget--ls__actions": DeviceDetector.isMobileTheme,
			})}
		>
			<PlayPauseButton
				currentLiveStream={currentLiveStream}
				pausedStatus={pausedStatus}
			/>
			<MuteButton
				currentLiveStream={currentLiveStream}
				muted={muted}
				setMuted={setMuted}
			/>
			<FullScreenButton currentLiveStream={currentLiveStream} />
		</div>
	);
}

function PlayPauseButton(props: {
	currentLiveStream: HTMLVideoElement | null;
	pausedStatus: boolean;
}) {
	const event = useContext(EventContext);
	const { findActiveLiveStreamWidget } = useLiveWidgetStore();
	const isActiveStream = findActiveLiveStreamWidget(event.id, "EVENT_OFFER");

	const { currentLiveStream, pausedStatus } = props;

	const togglePlayPause = () => {
		if (pausedStatus) {
			currentLiveStream!.play();
		} else {
			currentLiveStream!.pause();
		}
	};

	const playPauseBtnClasses = classNames("widget__btn btn btn--square", {
		"btn--square--xtny": !DeviceDetector.isMobileTheme,
		"btn--square--sml  btn--neutral--inverted widget--ls__btn":
			DeviceDetector.isMobileTheme,
	});

	const playPauseIconClasses = classNames(
		"u-icon",
		{
			"u-icon--xsml": !DeviceDetector.isMobileTheme,
			"u-icon--med": DeviceDetector.isMobileTheme,
			"u-icon--video-play": pausedStatus,
			"u-icon--video-pause": !pausedStatus,
		},
		"u-color--core--snow"
	);

	return (
		<button
			type="button"
			className={playPauseBtnClasses}
			onClick={() => {
				togglePlayPause();
			}}
		>
			<i className={playPauseIconClasses} />
		</button>
	);
}

function MuteButton(props: {
	currentLiveStream: HTMLVideoElement | null;
	muted: boolean;
	setMuted: (value: boolean) => void;
}) {
	const event = useContext(EventContext);
	const { findActiveLiveStreamWidget } = useLiveWidgetStore();
	const isActiveStream = findActiveLiveStreamWidget(event.id, "EVENT_OFFER");

	const { currentLiveStream, muted, setMuted } = props;

	const muteBtnClasses = classNames("widget__btn btn btn--square", {
		"btn--square--xtny": !DeviceDetector.isMobileTheme,
		"btn--square--sml btn--neutral--inverted widget--ls__btn":
			DeviceDetector.isMobileTheme,
	});

	const muteIconClass = classNames(
		"u-icon",
		{
			"u-icon--xsml": !DeviceDetector.isMobileTheme,
			"u-icon--med": DeviceDetector.isMobileTheme,
			"u-icon--video-volume-off": muted,
			"u-icon--video-volume-on": !muted,
		},
		"u-color--core--snow"
	);

	return (
		<button
			type="button"
			className={muteBtnClasses}
			onClick={() => {
				currentLiveStream!.muted = !currentLiveStream!.muted;
				setMuted(currentLiveStream!.muted);
			}}
		>
			<i className={muteIconClass} />
		</button>
	);
}

function FullScreenButton(props: {
	currentLiveStream: HTMLVideoElement | null;
}) {
	const event = useContext(EventContext);
	const { findActiveLiveStreamWidget } = useLiveWidgetStore();
	const isActiveStream = findActiveLiveStreamWidget(event.id, "EVENT_OFFER");

	const { currentLiveStream } = props;

	const requestFullscreen = () => {
		if (currentLiveStream?.requestFullscreen) {
			currentLiveStream?.requestFullscreen();
			// @ts-expect-error
		} else if (currentLiveStream?.webkitEnterFullScreen) {
			// @ts-expect-error used on ios
			currentLiveStream?.webkitEnterFullScreen();
		}
	};

	const fullScreenBtnClasses = classNames("widget__btn btn btn--square", {
		"btn--square--xtny": !DeviceDetector.isMobileTheme,
		"btn--square--sml btn--neutral--inverted widget--ls__btn":
			DeviceDetector.isMobileTheme,
	});

	const fullScreenIconCLasses = classNames(
		"u-icon",
		{
			"u-icon--xsml": !DeviceDetector.isMobileTheme,
			"u-icon--sml": DeviceDetector.isMobileTheme,
		},
		"u-icon--video-full u-color--core--snow"
	);

	return (
		<button
			type="button"
			className={fullScreenBtnClasses}
			onClick={() => requestFullscreen()}
		>
			<i className={fullScreenIconCLasses} />
		</button>
	);
}
