import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture, setTabTitle } from "@utils";
import { redirect } from "@state";
import { HeaderWithSideMenu } from "../shared/header";
import { PaymentInfo } from "@data-types/payment-info";
import { Footer } from "../shared/footer";
import { AdministrationSideMenu } from "../shared/side-menu";
import { useRootAppStore } from "@hooks";

export default observer(function PaymentInfoPage() {
	const { t } = useTranslation();
	const [transactionDetails, setTransactionDetails] = useState<PaymentInfo>(
		{} as PaymentInfo
	);

	const { userAuthStore } = useRootAppStore();

	useEffect(() => {
		setTabTitle(t("PAYMENT_INFO.PAYMENT_DETAILS"));
		const params = new URLSearchParams(window.location.search);
		const hasBalanceBeenRefreshed = params.get("hasBalanceBeenRefreshed");
		//we get data in the url named like this from payment provider
		let paymentInfo: PaymentInfo = {
			totalAmount: params.get("totalAmount"),
			currency: params.get("currency"),
			transactionId: params.get("TransactionID"),
			transactionDate: params.get("customField1"),
			paymentMethod: params.get("payment_method"),
			cardCompany: params.get("cardCompany"),
			status: params.get("ppp_status"),
		};
		setTransactionDetails(paymentInfo);
		if (!hasBalanceBeenRefreshed && userAuthStore.isLoggedIn) {
			//refresh user balance on transaction result, if user refreshes his page, we don't want to refresh his balance again
			App.state.rootStore.userAuthStore.refreshUserBalance();
			params.append("hasBalanceBeenRefreshed", "true");
			redirect(window.location.pathname + "?" + params.toString());
		}
	}, []);

	return (
		<>
			<HeaderWithSideMenu />
			<aside className="l--aside--left">
				<AdministrationSideMenu />
			</aside>
			<main className="l--main l--main--med">
				<section className="l--content l--content--full">
					<div className="l--content__main">
						<div className="container">
							<div className="card--primary">
								<div className="card--primary__body">
									<TransactionStatusMessage />

									<PaymentDetailsTable
										transactionDetails={transactionDetails}
									/>

									<Link
										to={`/${getCurrentCulture()}/home/full/highlights`}
										className="at-accountActivationOk card--message__cta btn btn--lrg btn--primary u-mar--top--big"
									>
										{t("PAYMENT_INFO.HOME_PAGE")}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</>
	);
});

function TransactionStatusMessage() {
	const { t } = useTranslation();
	//status can be success, failure, pending
	const { status } = useParams<{ status: string }>();

	if (status === "success") {
		return (
			<>
				<div className="card--primary__header card--primary__header--icon">
					<i className="u-icon u-icon--xxxlrg u-icon--check-circle u-color--ui--success"></i>
					<div className="u-mar--top--xsml u-type--lrg">
						{t("PAYMENT_INFO.TRANSACTION_SUCCESS_TITLE")}
					</div>
				</div>

				<div className="u-type--base u-type--center u-mar--bottom--big">
					{t("PAYMENT_INFO.SUCCESSFUL_TRANSACTION")}
				</div>
			</>
		);
	} else if (status === "pending") {
		return (
			<>
				<div className="card--primary__header card--primary__header--icon">
					<i className="u-icon u-icon--xxxlrg u-icon--pending"></i>
					<div className="u-mar--top--xsml u-type--lrg">
						{t("PAYMENT_INFO.TRANSACTION_PENDING_TITLE")}
					</div>
				</div>

				<div className="u-type--base u-type--center u-mar--bottom--big">
					{t("PAYMENT_INFO.TRANSACTION_PENDING_MESSAGE")}
				</div>
			</>
		);
	}
	return (
		<>
			<div className="card--primary__header card--primary__header--icon">
				<i className="u-icon u-icon--xxxlrg u-icon--remove-circle u-color--ui--danger"></i>
				<div className="u-mar--top--xsml u-type--lrg">
					{t("PAYMENT_INFO.TRANSACTION_FAILED_TITLE")}
				</div>
			</div>

			<div className="u-type--base u-type--center u-mar--bottom--big">
				{t("PAYMENT_INFO.TRANSACTION_UNSUCCESSFUL_MESSAGE")}
			</div>
		</>
	);
}

function PaymentDetailsTable(props: { transactionDetails: PaymentInfo }) {
	const { t } = useTranslation();
	const { transactionDetails } = props;

	const formattedDateTime = DateTime.fromJSDate(
		new Date(transactionDetails.transactionDate + "Z")
	).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);

	const statusTagClasses = classNames(
		"u-align--v--middle u-type--case--capitalize",
		{
			"u-color--ui--success": transactionDetails.status === "OK",
			"u-color--text--primary": transactionDetails.status === "PENDING",
			"u-color--ui--danger": transactionDetails.status === "FAIL",
		}
	);

	const statusIconClasses = classNames(
		"u-icon u-icon--xsml",
		{
			"u-icon--check u-color--ui--success":
				transactionDetails.status === "OK",
			"u-icon--clock": transactionDetails.status === "PENDING",
			"u-icon--remove u-color--ui--danger":
				transactionDetails.status === "FAIL",
		},
		"u-align--v--middle u-mar--right--xtny"
	);

	return (
		<table className="table--primary table--primary--bordered-row table--fixed u-mar--top--big">
			<thead className="table--primary__header table--primary__header--neutral">
				<tr className="table--primary__header__row">
					<th
						className="table--primary__header__data u-padd--xsml"
						colSpan={2}
					>
						{t("PAYMENT_INFO.DEPOSIT_DETAILS")}
					</th>
				</tr>
			</thead>
			<tbody className="table--primary__body">
				{transactionDetails.transactionId && (
					<tr className="table--primary__body__row">
						<td className="table--primary__body__data u-padd--xsml">
							{t("PAYMENT_INFO.TRANSACTION_ID")}
						</td>
						<td className="table--primary__body__data u-padd--xsml u-color--text--primary">
							{transactionDetails.transactionId}
						</td>
					</tr>
				)}

				<tr className="table--primary__body__row">
					<td className="table--primary__body__data u-padd--xsml">
						{t("PAYMENT_INFO.AMOUNT")}
					</td>
					<td className="table--primary__body__data u-padd--xsml u-color--text--primary">
						{transactionDetails.totalAmount}
					</td>
				</tr>
				<tr className="table--primary__body__row">
					<td className="table--primary__body__data u-padd--xsml">
						{t("PAYMENT_INFO.CURRENCY")}
					</td>
					<td className="table--primary__body__data u-padd--xsml u-color--text--primary">
						{transactionDetails.currency}
					</td>
				</tr>
				{transactionDetails?.cardCompany && (
					<tr className="table--primary__body__row">
						<td className="table--primary__body__data u-padd--xsml">
							{t("PAYMENT_INFO.PAYMENT_METHOD")}
						</td>
						<td className="table--primary__body__data u-padd--xsml u-color--text--primary">
							<div className="card--payment card--payment--borderless u-mar--bottom--reset">
								<div className="card--payment__area u-padd--reset">
									<span className="card--payment__icon">
										<i
											className={
												"card--payment__logo u-icon u-icon--payment u-icon--payment--base u-icon--payment--" +
												transactionDetails.cardCompany?.toLowerCase()
											}
										></i>
									</span>
									<span className="card--payment__details card--payment__details--centered">
										<span className="card--payment__name u-type--sml u-type--wgt--regular">
											{transactionDetails.cardCompany}
										</span>
										{/* <span className="card--payment__type u-type--xxsml">{transactionDetails.paymentMethod}</span> */}
									</span>
								</div>
							</div>
						</td>
					</tr>
				)}
				<tr className="table--primary__body__row">
					<td className="table--primary__body__data u-padd--xsml">
						{t("PAYMENT_INFO.STATUS")}
					</td>
					<td className="table--primary__body__data u-padd--xsml u-color--text--primary">
						<i className={statusIconClasses}></i>
						<span className={statusTagClasses}>
							{transactionDetails.status?.toLowerCase()}
						</span>
					</td>
				</tr>
				{transactionDetails.transactionDate && (
					<tr className="table--primary__body__row">
						<td className="table--primary__body__data u-padd--xsml">
							{t("PAYMENT_INFO.START_DATE")}
						</td>
						<td className="table--primary__body__data u-padd--xsml u-color--text--primary">
							{formattedDateTime}
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
}
