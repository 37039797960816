import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDisplayedAmount } from "@utils";

const itemClassName =
	"table--primary__body__data u-type--right u-type--wgt--medium";

export default observer(function BettingActivitySection(props: {
	bettingActivity?: {
		NumberOfBets: number;
		BettingAccountTypeAbrv: string;
		Payment: number;
		CurrencyDisplayCode: string;
		Gain: number;
		NetGain: number;
		Loss: number;
	}[];
}) {
	const { t } = useTranslation();
	const { bettingActivity } = props;

	if (bettingActivity == null) {
		return null;
	}

	return (
		<table className="table--primary table--primary--bordered table--fixed u-mar--top--big">
			<thead className="table--primary__header table--primary__header--light">
				<tr className="table--primary__header__row">
					<th className="table--primary__header__data" colSpan={2}>
						{t<string>("MEMBERSHIP.BETTING_ACTIVITY.TABLE_TITLE")}
					</th>
				</tr>
			</thead>

			{bettingActivity.map((bettingActivityItem, index) => {
				return (
					<tbody className="table--primary__body" key={index}>
						<tr className="table--primary__body__row">
							<th
								className="table--primary__header__data"
								colSpan={2}
							>
								<strong>
									{t<string>(
										`HEADER.${bettingActivityItem.BettingAccountTypeAbrv.toUpperCase()}`
									)}
								</strong>
							</th>
						</tr>
						<tr className="table--primary__body__row">
							<td className="table--primary__body__data">
								{t<string>(
									"MEMBERSHIP.BETTING_ACTIVITY.TABLE_NUMBER_OF_BETS"
								)}
							</td>
							<td className="table--primary__body__data u-type--right">
								{bettingActivityItem.NumberOfBets}
							</td>
						</tr>
						<tr className="table--primary__body__row">
							<td className="table--primary__body__data">
								{t<string>(
									"MEMBERSHIP.BETTING_ACTIVITY.TABLE_STAKE"
								)}
							</td>
							<td
								className={classNames(itemClassName, {
									"u-color--ui--danger":
										bettingActivityItem.Payment < 0,
								})}
							>
								{getDisplayedAmount(
									bettingActivityItem.Payment,
									bettingActivityItem.CurrencyDisplayCode
								)}
							</td>
						</tr>
						<tr className="table--primary__body__row">
							<td className="table--primary__body__data">
								{t<string>(
									"MEMBERSHIP.BETTING_ACTIVITY.TABLE_GAIN"
								)}
							</td>
							<td
								className={classNames(itemClassName, {
									"u-color--ui--success":
										bettingActivityItem.Gain > 0,
								})}
							>
								{getDisplayedAmount(
									bettingActivityItem.Gain,
									bettingActivityItem.CurrencyDisplayCode
								)}
							</td>
						</tr>
						<tr className="table--primary__body__row">
							<td className="table--primary__body__data">
								{t<string>(
									"MEMBERSHIP.BETTING_ACTIVITY.TABLE_NET_GAIN"
								)}
							</td>
							<td
								className={classNames(itemClassName, {
									"u-color--ui--success":
										bettingActivityItem.NetGain > 0,
									"u-color--ui--danger":
										bettingActivityItem.NetGain < 0,
								})}
							>
								{getDisplayedAmount(
									bettingActivityItem.NetGain,
									bettingActivityItem.CurrencyDisplayCode
								)}
							</td>
						</tr>
						<tr className="table--primary__body__row">
							<td className="table--primary__body__data">
								{t<string>(
									"MEMBERSHIP.BETTING_ACTIVITY.TABLE_LOSS"
								)}
							</td>
							<td
								className={classNames(itemClassName, {
									"u-color--ui--danger":
										bettingActivityItem.Loss < 0,
								})}
							>
								{getDisplayedAmount(
									bettingActivityItem.Loss,
									bettingActivityItem.CurrencyDisplayCode
								)}
							</td>
						</tr>
					</tbody>
				);
			})}
		</table>
	);
});
