import React, { useContext } from "react";
import RadioInput from "../../shared/inputs/RadioInput";
import { useUser } from "@v2/hooks";
import { useFormContext, useWatch } from "react-hook-form";
import { AccountStatementBetContext } from "@v2/context/AccountStatementBetContext";
import { UserAccountType } from "@common/constants";

export default function AccountStatementWalletFilter({
	onFilterSubmit,
}: {
	onFilterSubmit: Function;
}) {
	const user = useUser();
	const { handleSubmit, getValues, setValue } = useFormContext();
	useWatch({ name: "bettingAccountTypeIds" });

	const contextValue = useContext(AccountStatementBetContext);

	if (
		(user.availableUserWallets != null &&
			user.availableUserWallets.length == 1) ||
		contextValue == null
	) {
		return null;
	}
	const onlineWallet = App.state.rootStore.userAuthStore.userAccounts?.find(
		(type) => type.abrv === UserAccountType.SportBettingAccountOnline
	);

	const casinoWallet = App.state.rootStore.userAuthStore.userAccounts?.find(
		(type) => type.abrv === UserAccountType.CasinoAccount
	);

	const liveCasinoWallet =
		App.state.rootStore.userAuthStore.userAccounts?.find(
			(type) => type.abrv === UserAccountType.LiveCasinoAccount
		);

	const gigWallet = App.state.rootStore.userAuthStore.userAccounts?.find(
		(type) => type.abrv === UserAccountType.GigAccount
	);

	const shopOnlineWallet =
		App.state.rootStore.userAuthStore.userAccounts?.find(
			(type) =>
				type.abrv === UserAccountType.SportBettingAccountShopOnline
		);
	const offlineWallet = App.state.rootStore.userAuthStore.userAccounts?.find(
		(type) => type.abrv === UserAccountType.SportBettingAccountShop
	);
	function setFilterToDefault() {
		handleSubmit(
			onFilterSubmit({
				...getValues(),
				asType: "all",
			})
		);
	}
	return (
		<div className="card--primary__filter no-print">
			<div className="card--primary__filter__list">
				{onlineWallet &&
					App.state.rootStore.userAuthStore.availableUserWallets?.includes(
						onlineWallet.accountTypeId
					) && (
						<div className="card--primary__filter__item">
							<RadioInput
								label="ACC_STATEMENT.FILTER.FILTER_ONLINE_WALLET"
								name="bettingAccountTypeIds"
								id="onlineWallet"
								value={onlineWallet.accountTypeId}
								labelClassname="u-display--ib"
								className="at-OnlineWalletId input--radio"
								iconClass="u-icon u-icon--med u-icon--wallet--online u-align--v--middle"
								onClick={() => {
									setValue(
										"bettingAccountTypeIds",
										onlineWallet.accountTypeId
									);
									setFilterToDefault();
								}}
							/>
						</div>
					)}
				{casinoWallet &&
					App.state.rootStore.userAuthStore.availableUserWallets?.includes(
						casinoWallet.accountTypeId
					) && (
						<div className="card--primary__filter__item">
							<RadioInput
								label="HEADER.CASINO"
								name="bettingAccountTypeIds"
								id="casinoWallet"
								value={casinoWallet.accountTypeId}
								labelClassname="u-display--ib"
								className="at-OnlineWalletId input--radio"
								iconClass="u-icon u-icon--med u-icon--wallet--casino u-align--v--middle"
								onClick={() => {
									setValue(
										"bettingAccountTypeIds",
										casinoWallet.accountTypeId
									);
									setFilterToDefault();
								}}
							/>
						</div>
					)}
				{liveCasinoWallet &&
					App.state.rootStore.userAuthStore.availableUserWallets?.includes(
						liveCasinoWallet.accountTypeId
					) && (
						<div className="card--primary__filter__item">
							<RadioInput
								label="HEADER.CASINO-LIVE"
								name="bettingAccountTypeIds"
								id="liveCasinoWallet"
								value={liveCasinoWallet.accountTypeId}
								labelClassname="u-display--ib"
								className="at-OnlineWalletId input--radio"
								iconClass="u-icon u-icon--med u-icon--wallet--live-casino u-align--v--middle"
								onClick={() => {
									setValue(
										"bettingAccountTypeIds",
										liveCasinoWallet.accountTypeId
									);
									setFilterToDefault();
								}}
							/>
						</div>
					)}
				{gigWallet &&
					App.state.rootStore.userAuthStore.availableUserWallets?.includes(
						gigWallet.accountTypeId
					) && (
						<div className="card--primary__filter__item">
							<RadioInput
								label="HEADER.GIG-ACCOUNT"
								name="bettingAccountTypeIds"
								id="gigWallet"
								value={gigWallet.accountTypeId}
								labelClassname="u-display--ib"
								className="at-OnlineWalletId input--radio"
								iconClass="u-icon u-icon--med u-icon--wallet--gig-account u-align--v--middle"
								onClick={() => {
									setValue(
										"bettingAccountTypeIds",
										gigWallet.accountTypeId
									);
									setFilterToDefault();
								}}
							/>
						</div>
					)}
				{shopOnlineWallet &&
					App.state.rootStore.userAuthStore.availableUserWallets?.includes(
						shopOnlineWallet.accountTypeId
					) && (
						<div className="card--primary__filter__item">
							<RadioInput
								label="ACC_STATEMENT.FILTER.FILTER_SHOP_ONLINE_WALLET"
								name="bettingAccountTypeIds"
								id="shopOnlineWallet"
								value={shopOnlineWallet.accountTypeId}
								labelClassname="u-display--ib"
								className="at-OnlineWalletId input--radio"
								iconClass="u-icon u-icon--med u-icon--wallet--sport-betting-account-shop-online u-align--v--middle"
								onClick={() => {
									setValue(
										"bettingAccountTypeIds",
										shopOnlineWallet.accountTypeId
									);
									setFilterToDefault();
								}}
							/>
						</div>
					)}
				{offlineWallet &&
					App.state.rootStore.userAuthStore.availableUserWallets?.includes(
						offlineWallet.accountTypeId
					) && (
						<div className="card--primary__filter__item">
							<RadioInput
								label="ACC_STATEMENT.FILTER.FILTER_OFFLINE_WALLET"
								name="bettingAccountTypeIds"
								id="offlineWallet"
								value={offlineWallet.accountTypeId}
								labelClassname="u-display--ib"
								className="at-OfflineWalletId input--radio"
								iconClass="u-icon u-icon--med u-icon--wallet--offline u-align--v--middle"
								onClick={() => {
									setValue(
										"bettingAccountTypeIds",
										offlineWallet.accountTypeId
									);
									setFilterToDefault();
								}}
							/>
						</div>
					)}
			</div>
		</div>
	);
}
