import { action, observable, computed } from "mobx";

import { ResultsMenuStoreMobile } from "./";
import RootStore from "@offer/stores/RootStore";
import { MappedEvent, ResultFormattedData } from "@data-types/results";
import ResultsService from "@services/results/ResultsService.mobile";

export class ResultsDataStoreMobile {
	rootStore: RootStore;
	menuStore: ResultsMenuStoreMobile;
	@observable resultData: ResultFormattedData<MappedEvent[]>[] | null;
	@observable isSearch = false;
	@observable searchTerm = "";

	constructor(rootStore: RootStore, menuStore: ResultsMenuStoreMobile) {
		this.rootStore = rootStore;
		this.menuStore = menuStore;
	}

	@computed get filteredResultData():
		| ResultFormattedData<MappedEvent[]>[]
		| null {
		if (this.searchTerm === "") {
			return this.resultData;
		}

		if (this.resultData === null || this.resultData.length === 0) {
			return null;
		}

		let resultDataCopy: ResultFormattedData<MappedEvent[]>[] = JSON.parse(
			JSON.stringify(this.resultData)
		);

		resultDataCopy[0].tournaments[0].events =
			resultDataCopy[0].tournaments[0].events.filter(
				(ev: MappedEvent) =>
					ev.teamOneName
						.toLowerCase()
						.includes(this.searchTerm.toLowerCase()) ||
					ev.teamTwoName
						.toLowerCase()
						.includes(this.searchTerm.toLowerCase())
			);

		return resultDataCopy;
	}

	@action.bound
	async onInitialize() {
		const selectedTournament = this.menuStore.selectedTournament;
		const selectedCategory = this.menuStore.selectedCategory;
		const searchString = this.rootStore.searchBarViewStore.searchTerm;

		this.isSearch = searchString != null && searchString !== "";

		const resultDataRequest = await ResultsService.getResults({
			previousDays: 7,
			tournamentIds:
				selectedTournament != null ? [selectedTournament.id] : null,
			sportCategoryIds:
				selectedTournament == null && selectedCategory != null
					? [selectedCategory.id]
					: null,
			searchPhrase: this.isSearch ? searchString.toLowerCase() : null,
		});

		if (resultDataRequest.success) {
			this.resultData = resultDataRequest.data;
		}
	}

	@action.bound
	async onReset() {
		this.searchTerm = "";
		this.resultData = null;
	}

	@action.bound
	onSearchTermChange(value: string) {
		this.searchTerm = value;
	}
}
