import { observable, action, runInAction } from "mobx";
import UserDataApiService from "@api-services/user/UserDataApiService";
import { FailedLogin } from "@api-types/user/FailedLoginDataDto";
import { DateTime } from "luxon";
import { FailedLoginsReportFilter, FailedLoginReportMode } from "@data-types";

export default class FailedLoginsCheckPopupStore {
	@observable isActive: boolean = false;
	@observable failedLoginData: FailedLogin[] | null = null;
	@observable isFetchingDataOnOpen: boolean = true;
	@observable reportFromDate: string | null = null;

	@action.bound
	async onInitialize(): Promise<void> {
		const reportFilter: FailedLoginsReportFilter = {
			reportMode: WEBPACK_FAILED_LOGIN_REPORT.reportMode,
			rpp:
				WEBPACK_FAILED_LOGIN_REPORT.queryLimit > 0
					? WEBPACK_FAILED_LOGIN_REPORT.queryLimit
					: 20,
		};

		if (
			reportFilter.reportMode === FailedLoginReportMode.FROM_INTERVAL_HOUR
		) {
			reportFilter.hourInterval =
				WEBPACK_FAILED_LOGIN_REPORT.queryIntervalInHours;
		}

		try {
			const data = await UserDataApiService.getUserFailedLogins(
				reportFilter
			);

			runInAction(() => {
				this.failedLoginData = data.failedLogins;
				this.reportFromDate = DateTime.fromISO(
					data.startDate
				).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
			});
		} catch (err) {
			console.error(err);
			//if data is null error message will be shown
		} finally {
			this.isFetchingDataOnOpen = false;
		}
	}

	@action.bound
	togglePopup(): void {
		this.isActive = !this.isActive;
	}

	@action.bound
	onDispose(): void {
		this.isActive = false;
		this.failedLoginData = null;
		this.isFetchingDataOnOpen = true;
		this.reportFromDate = null;
	}
}
