import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { logger } from "@state/providers/consoleLogger";
import { useRootOfferStore } from "@hooks";
import { EventContext } from "@gp/components";
import { Modal } from "@lib/modal";

export default observer(function CashoutCounter() {
	const store = useRootOfferStore().myBetsViewStore;
	const t = useTranslation().t;
	const event = useContext(EventContext);

	if (store == null) {
		logger.logError("Expected store got something else.");
		return null;
	}

	if (
		store.cashoutStore.cashoutLocation !== "offer" ||
		store.cashoutStore.cashoutEventId !== event.id
	) {
		return null;
	}

	if (
		store.cashoutStore.isCountdownVisible &&
		store.cashoutStore.countdownCounter > 0
	) {
		return (
			<Modal>
				<div id="loader" className="loader--full">
					<div className="loader--countdown">
						<div className="loader--countdown__box">
							{store.cashoutStore.countdownCounter}
						</div>
					</div>
				</div>
			</Modal>
		);
	}

	if (store.cashoutStore.isCashoutFormSubmitting) {
		return (
			<Modal>
				<div id="loader" className="loader--full">
					<div className="loader__card">
						<div className="loader__spinner u-display--b" />
						<div className="loader__title u-type--color--white">
							<>{t("HOME.LOADING")}...</>
						</div>
					</div>
				</div>
			</Modal>
		);
	}

	return null;
});
