import { ValidatePlayerResponseDto } from "@api-types/locks/ValidatePlayerDto";
import { getApplicationHeaders, httpClient } from "@utils";

export class ValidatePlayerApiService {
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}platform/oasis-blocks/validate-player`;
	}

	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async validatePlayer(
		userId: string,
		shopId: string,
		agencyIdentifier: string
	): Promise<ValidatePlayerResponseDto> {
		const body = JSON.stringify({
			AgencyIdentifier: agencyIdentifier,
			ShopId: shopId,
			UserId: userId,
		});
		return await httpClient.post(
			this.baseUrl,
			body,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}
}
