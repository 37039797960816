import React, { useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { get } from "lodash";
import classNames from "classnames";

import { RequiredMark, ErrorMessage, PopoverMessage } from ".";

export default function FormSelectField(props) {
	const {
		name,
		className,
		labelClassName,
		label,
		inputClassName = undefined,
		dropdownData = [],
		placeholder = undefined,
		required = false,
		validate,
		errorMessage = undefined,
		popoverMsg = null,
		selected,
		property = "name",
		...rest
	} = props;

	const [mouseOverState, setMouseOverState] = useState(false);

	const { t } = useTranslation();
	const { register } = useFormContext();

	const { errors } = useFormState();
	const error = get(errors, name);

	const field = register(name, {
		required: {
			value: required,
			message: errorMessage || "COMMON.REQUIRED_FIELD",
		},
		validate: validate,
	});

	const labelClasses = classNames("label", labelClassName);

	const inputClass = classNames(inputClassName, {
		"input input--base input--select":
			inputClassName == undefined || inputClassName == null,
		"input-validation-error": error != undefined,
	});

	return (
		<div className={className}>
			<label
				htmlFor={name}
				className={labelClasses}
				onMouseEnter={() => setMouseOverState(true)}
				onMouseLeave={() => setMouseOverState(false)}
			>
				{t(label)}
				<RequiredMark required={required} />
				<PopoverMessage
					popoverMsg={popoverMsg}
					mouseOverState={mouseOverState}
				/>
			</label>

			<ErrorMessage name={name} />

			<select
				id={name}
				name={name}
				className={inputClass}
				defaultValue={selected}
				{...field}
				{...rest}
			>
				{placeholder ? (
					<option value="">{t(placeholder)}</option>
				) : null}

				{dropdownData.map((value) => (
					<option key={value.id} value={value.id}>
						{t(value[property])}
					</option>
				))}
			</select>
		</div>
	);
}
