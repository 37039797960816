import { observable, computed, action, runInAction } from "mobx";
import {
	RegistrationLookupService,
	RegistrationService,
} from "../../data-access/membership/registration";
import { LoaderStore } from "@state/stores/common";
import {
	CountryDataType,
	FormDataType,
	GamingLimitDataType,
	LanguageDataType,
	LimitDurationDataType,
	PasswordSimilarityRequestModel,
	RegionDataType,
	TitleDataType,
} from "@data-types/membership/registration";
import { SUPPORTED_LANGUAGES } from "@common/constants/SupportedLanguages";
import { DateTime } from "luxon";
import { registrationValidationSingle } from "@utils/membership/registrationValidation";

export default class RegistrationStore {
	@observable currentStep = 1;
	@observable totalSteps = 2;
	@observable isRegistrationSuccessful: boolean | null = null;
	@observable isFirstStepDone: boolean = false;
	@observable isSwitchingSteps: boolean = false;

	//lookup data
	@observable titleData: TitleDataType[] | null;
	@observable countryData: CountryDataType[] | null;
	@observable languageData: LanguageDataType[] | null;
	@observable countryRegionsData: RegionDataType[] | null;
	@observable gamingLimitsData: GamingLimitDataType | null;
	@observable limitDurationData: LimitDurationDataType[] | null;

	lookupService: RegistrationLookupService;
	registrationService: RegistrationService;
	loader: LoaderStore;
	lookups: [
		TitleDataType[],
		CountryDataType[],
		LanguageDataType[],
		RegionDataType[],
		GamingLimitDataType
	];

	constructor() {
		this.lookupService = new RegistrationLookupService();
		this.registrationService = new RegistrationService();
		this.loader = new LoaderStore();
	}

	@computed get isLoading() {
		return this.loader.isLoading;
	}

	@computed get isLoadingProcess() {
		return this.loader.isLoadingProcess;
	}

	@action.bound
	async onInitialize() {
		if (App.state.rootStore.isIpRestricted) {
			return;
		}

		this.loader.suspend();

		try {
			this.lookups = await Promise.all([
				this.fetchTitleLookupData(),
				this.fetchCountryLookupData(),
				this.fetchLanguageLookupData(),
				this.fetchCountryRegionsLookupData(),
				this.fetchGamingLimits(),
			]);
		} catch (error) {
			console.error(error);
			this.isRegistrationSuccessful = false;
		}

		runInAction(() => {
			this.titleData = this.lookups[0];
			this.countryData = this.lookups[1].sort((a, b) =>
				a.name.localeCompare(b.name)
			);
			this.languageData = this.lookups[2].sort((a, b) =>
				a.name.localeCompare(b.name)
			);
			this.countryRegionsData = this.lookups[3].sort((a, b) =>
				a.name.localeCompare(b.name)
			);
			this.gamingLimitsData = this.lookups[4];
			this.loader.resume();
		});
	}

	@action.bound
	setCurrentStep(step: number, isValid?: boolean) {
		if (isValid || this.currentStep > step) {
			if (step === 2 && !this.isFirstStepDone) {
				this.isFirstStepDone = true;
			}
			this.currentStep = step;
			document.querySelector("main")?.scrollTo(0, 0);
		}
	}

	@action.bound
	previousStep() {
		if (this.currentStep > 1) {
			this.currentStep--;
			document.querySelector("main")?.scrollTo(0, 0);
		}
	}

	@action.bound
	nextStep() {
		if (this.currentStep < this.totalSteps) {
			this.currentStep++;
			document.querySelector("main")?.scrollTo(0, 0);
		}
	}

	@action.bound
	async register(
		formData: FormDataType,
		recaptcha: (value: string) => Promise<string>,
		affiliateCode: string | null
	) {
		try {
			const recaptchaToken = await recaptcha?.("registerUser");
			this.loader.suspend();

			const newFormData = formData;

			if (newFormData.day && newFormData.month && newFormData.year) {
				newFormData.dob = DateTime.fromObject({
					day: parseInt(newFormData.day),
					month: parseInt(newFormData.month),
					year: parseInt(newFormData.year),
				}).toISO() as string;

				delete newFormData.day;
				delete newFormData.month;
				delete newFormData.year;
			}

			if (
				newFormData.limitDurationId == "" &&
				newFormData.limitAmount == ""
			) {
				delete newFormData!.limitDurationId;
				delete newFormData!.limitAmount;
			}
			if (
				newFormData.mobilePhone?.startsWith("+") &&
				newFormData.mobilePhone?.length <= 5
			) {
				delete newFormData!.mobilePhone;
			}
			if (newFormData.mobilePhone?.includes(" ")) {
				newFormData.mobilePhone = newFormData.mobilePhone.replaceAll(
					" ",
					""
				);
			}

			const registrationFormData = Object.assign(newFormData, {
				challengeResponse: recaptchaToken,
			});

			registrationFormData.playerPromoSendingMode = JSON.parse(
				registrationFormData.playerPromoSendingMode
			);

			await this.registrationService.registerUser(
				registrationFormData,
				affiliateCode
			);

			this.isRegistrationSuccessful = true;
		} catch (error) {
			console.error(error);

			const errorObject = await error.rawResponse.json();

			registrationValidationSingle(errorObject);
		} finally {
			this.loader.resume();
			document.querySelector("main")?.scrollTo(0, 0);
		}
	}

	@action.bound
	async checkUserNameAvailability(username: string) {
		try {
			const response =
				await this.registrationService.checkUserNameAvailability(
					username
				);
			return response;
		} catch (err) {
			console.error(err);
			return false;
		}
	}

	@action.bound
	async checkIsEmailAvailable(email: string) {
		try {
			const response =
				await this.registrationService.checkIsEmailAvailable(email);
			return response;
		} catch (err) {
			console.error(err);
			return false;
		}
	}

	@action.bound
	async checkPasswordSimilarity(
		dataset: PasswordSimilarityRequestModel
	): Promise<boolean> {
		let response = false;
		try {
			response = await this.registrationService.checkPasswordSimilarity(
				dataset
			);
		} catch (err) {
			console.error(err);
		}

		return response;
	}

	@action.bound
	toggleStepSwitchFlag(value: boolean) {
		if (this.isSwitchingSteps != value) {
			this.isSwitchingSteps = value;
		}
	}

	@action.bound
	async fetchGamingLimits() {
		const response = await this.registrationService.fetchGamingLimits();
		return response;
	}

	@action.bound
	async fetchTitleLookupData() {
		const response = await this.lookupService.findTitles();
		return response.item;
	}

	@action.bound
	async fetchCountryLookupData() {
		const response = await this.lookupService.findCountries();
		return response.item;
	}

	@action.bound
	async fetchLanguageLookupData() {
		const getIsoFromSupportedLanguages = SUPPORTED_LANGUAGES.map((lang) => {
			return lang.iso;
		}).join(",");
		const response = await this.lookupService.findLanguages(
			getIsoFromSupportedLanguages
		);
		return response.item;
	}

	@action.bound
	async fetchCountryRegionsLookupData() {
		const response = await this.lookupService.findCountryRegions();
		return response.item;
	}

	@action.bound
	async fetchLimitDuration() {
		const response = await this.lookupService.findLimitDurations();

		runInAction(() => {
			this.limitDurationData = response.item.sort(
				(a, b) => a.units - b.units
			);
		});
	}

	@action.bound
	onDispose() {
		this.currentStep = 1;
		this.isRegistrationSuccessful = null;

		//lookups
		this.titleData = [];
		this.countryData = [];
		this.languageData = [];
		this.countryRegionsData = [];
		this.gamingLimitsData = null;
	}
}
