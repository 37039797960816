import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import React from "react";

export default observer(function LoaderSpinner() {
	const rootAppStore = useRootAppStore();

	if (!rootAppStore.quickPlayStore.loaderStore?.isLoading) {
		return null;
	}

	return (
		<div id="loader" className="loader--full">
			<div className="loader--countdown" style={{ display: "none" }}>
				<div className="loader--countdown__box"></div>
			</div>
			<div className="loader__spinner"></div>
		</div>
	);
});
