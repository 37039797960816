import React, { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { WalletItem } from "@v2/components/desktop/shared/inputs";
import { WithdrawalContext } from "@context";

export const SelectedWithdrawalWallet: FC = observer(
	function SelectedWithdrawalWallet() {
		const { currentFormData } = useContext(WithdrawalContext);
		const { t } = useTranslation();
		const selectedWallet =
			App.state.rootStore.userAuthStore.userAccounts?.find((w) => {
				return (
					w.accountTypeId === currentFormData?.bettingAccountTypeId
				);
			});

		return (
			<>
				<div className="label">
					{t("WITHDRAWAL.SELECTED_WITHDRAWAL_WALLET")}
				</div>
				<ul className="wallet">
					<WalletItem
						wallet={selectedWallet}
						isDisabled
						isDefaultChecked
						shouldRegister={false}
						isList={true}
					/>
				</ul>
			</>
		);
	}
);
