import { useContext } from "react";
import { maxBy } from "lodash";

import { Sport, Event } from "@gp/models";
import { EventContext, SportOfferContext } from "@gp/components";
import { PeriodType } from "@utils";

import { formatTennisGameScore } from "@offer/common/helpers";

const pauseStatuses = [
	"not-started",
	"first-break",
	"second-break",
	"third-break",
	"fourth-break",
	"fifth-break",
	"sixth-break",
	"ended",
];

const pauseSkipSports = ["volleyball", "beach-volley"];

const periodSports = [
	"tennis",
	"table-tennis",
	"volleyball",
	"beach-volley",
	"snooker",
];

const htSports = ["soccer", "futsal"];

export function useScore() {
	const event: Event = useContext(EventContext);
	const sport: Sport = useContext(SportOfferContext);

	let gameScore;
	if (sport.abrv === "tennis") {
		gameScore = { home: "0", away: "0" };
		if (event.result?.currentGameScore) {
			if (event.result?.currentGameScore?.home) {
				gameScore.home = formatTennisGameScore(
					event.result?.currentGameScore?.home
				);
			}
			if (event.result?.currentGameScore?.away) {
				gameScore.away = formatTennisGameScore(
					event.result?.currentGameScore?.away
				);
			}
		}

		if (event.result?.tiebreak) {
			if (event.result?.tiebreakScore) {
				gameScore.home = String(event.result?.tiebreakScore.home || 0);
				gameScore.away = String(event.result?.tiebreakScore.away || 0);
			} else {
				gameScore.home = "0";
				gameScore.away = "0";
			}
		}
	}

	let lastPeriodScore = null;
	const spp =
		event.result?.scorePerPeriod != null
			? event.result?.scorePerPeriod.slice()
			: [];
	if (periodSports.includes(sport.abrv)) {
		if (
			pauseSkipSports.includes(sport.abrv) &&
			pauseStatuses.includes(event.matchStatus)
		) {
			lastPeriodScore = {
				home: "-",
				away: "-",
			};
		} else {
			// @ts-expect-error
			if (spp.some((el) => el.periodType === PeriodType.OT)) {
				lastPeriodScore = spp
					.reverse()
					// @ts-expect-error
					.find((el) => el.periodType === PeriodType.OT);
			} else {
				lastPeriodScore = maxBy(spp, "periodNumber");
			}
		}
	} else if (spp.length > 1 && htSports.includes(sport.abrv)) {
		// find first HT
		// @ts-expect-error
		if (spp.some((el) => el.periodType === PeriodType.OT)) {
			lastPeriodScore = spp.find(
				// @ts-expect-error
				(el) => el.periodType === PeriodType.OT && el.periodNumber === 1
			);
		} else {
			lastPeriodScore = spp.find(
				(el) =>
					// @ts-expect-error
					el.periodType === PeriodType.HALF && el.periodNumber === 1
			);
		}
	}

	return {
		serve: event.result?.serve,
		currentScore: event.currentScore,
		gameScore: gameScore,
		lastPeriodScore: lastPeriodScore,
	};
}
