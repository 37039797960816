import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import {
	FormInputFieldCurrency,
	FormSelectField,
} from "@v2/components/desktop/shared/inputs";
import Button from "@components/buttons/Button";
import FormButton from "@components/buttons/FormButton";
import { SubmitSingleLimitData, LimitSubmit } from "@data-types";
import {
	LimitRequestAction,
	MyLimitsStoreCategoriesNullChecked,
	SubmitLimitAction,
	timeLimitsDropDown,
} from "@v2/state/myLimits/MyLimitsStore";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import { observer } from "mobx-react";
import { useNotification } from "@v2/hooks";
import {
	IntervalType,
	formatLoginLimitTime,
} from "@v2/helpers/account-settings/my-limits/formatLoginLimitTime";

export default observer(function MyLimitsSingleLimit() {
	const { t } = useTranslation();

	const myLimitsStore = useContext(
		MyLimitsStoreContext
	) as MyLimitsStoreCategoriesNullChecked;

	const { canBeDeleted, canBeUpdated, amount, durationId, typeId, limitId } =
		myLimitsStore.getSingleLimitDurations;

	const methods = useForm({
		mode: "onChange",
		defaultValues: { amount: myLimitsStore.getSingleLimitDurations.amount },
	});

	const {
		reset,
		handleSubmit,
		formState: { isSubmitted },
	} = methods;

	useEffect(() => {
		reset({
			//@ts-expect-error
			amount: amount || "",
			limitDuration: durationId || "",
			limitType: typeId || "",
		});
	}, [amount, durationId, typeId, isSubmitted]);

	async function submitForm(
		formData: {
			amount: LimitRequestAction;
			limitDuration: string;
			limitType: string;
		},
		action: SubmitLimitAction
	) {
		const model = {
			amount: formData.amount,
			action,
			categoryId: myLimitsStore.category.categoryId,
			limitId,
			durationId: formData.limitDuration,
			typeId: formData.limitType,
		};

		await myLimitsStore.submitLimit(model);
	}

	if (myLimitsStore.category.activeCategoryAbrv == "login-time") {
		return <MyLimitsTimeLimit />;
	}

	return (
		<FormProvider {...methods}>
			<form className="at-myLimitsForm u-mar--bottom--big">
				<div className="card--primary">
					<div className="card--primary__body u-padd--xsml">
						<div className="form__group">
							<div className="row">
								{myLimitsStore.category.isMultiTypeMode && (
									<FormSelectField
										className="col col-sml-12 form__field"
										name="limitType"
										label="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_TYPE"
										placeholder="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_TYPE_PLACEHOLDER"
										dropdownData={
											myLimitsStore.lookups?.limitTypes
										}
										required
									/>
								)}

								<FormSelectField
									className="col col-sml-12 form__field"
									name="limitDuration"
									label="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_INTERVAL"
									placeholder="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DURATION_PLACEHOLDER"
									dropdownData={
										myLimitsStore.lookups?.limitDurations
									}
									required
								/>

								<FormInputFieldCurrency
									label="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_AMOUNT_LABEL"
									name="amount"
									type="number"
									className="col col-sml-12 form__field"
									placeholder="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_AMOUNT_PLACEHOLDER"
									validate={{
										isNotNegative: (value: number) =>
											value >= 1 ||
											t(
												"USER.ACCOUNT_SETTINGS.MY_LIMITS.ZERO_OR_GREATER"
											),
									}}
									required
									maxLength={10}
								/>
							</div>
						</div>

						<div className="btn-group">
							{canBeDeleted && (
								<Button
									className="btn btn--base btn--secondary--inverted"
									onClick={handleSubmit(
										(data: SubmitSingleLimitData) =>
											submitForm(
												data,
												SubmitLimitAction.REMOVE
											)
									)}
									btnText="COMMON.DEACTIVATE_BUTTON"
									isDisabled={myLimitsStore.isRequestPending}
								/>
							)}

							{canBeUpdated ? (
								<FormButton
									className="btn btn--base btn--primary"
									onClick={handleSubmit(
										(data: SubmitSingleLimitData) =>
											submitForm(
												data,
												SubmitLimitAction.UPDATE
											)
									)}
									btnText="COMMON.SAVE_BUTTON"
								/>
							) : (
								<FormButton
									className="btn btn--base btn--primary"
									onClick={handleSubmit(
										(data: SubmitSingleLimitData) =>
											submitForm(
												data,
												SubmitLimitAction.ADD
											)
									)}
									btnText="COMMON.SAVE_BUTTON"
								/>
							)}
						</div>
					</div>
				</div>
			</form>
		</FormProvider>
	);
});

const MyLimitsTimeLimit = observer(function MyLimitsTimeLimit() {
	const myLimitsStore = useContext(
		MyLimitsStoreContext
	) as MyLimitsStoreCategoriesNullChecked;
	const { t } = useTranslation();

	const { canBeDeleted, canBeUpdated, limitId, amount, typeId } =
		myLimitsStore.getSingleLimitDurations;

	const methods = useForm<{ amount: number | null }>({
		mode: "onChange",
		defaultValues: { amount: myLimitsStore.getSingleLimitDurations.amount },
	});
	const notification = useNotification();

	useEffect(() => {
		methods.reset({
			//@ts-expect-error
			amount: amount || "",
			limitType: typeId || "",
		});
	}, [amount]);

	async function submitForm(
		formData: {
			amount: LimitRequestAction;
			limitDuration: string;
			limitType: string;
		},
		action: SubmitLimitAction
	) {
		// must be daily limit
		const durationId = myLimitsStore.lookups?.limitDurations.find(
			(limit) => limit.abrv == "daily-limit"
		);
		if (!durationId) {
			console.error("durationId not found");
			notification.showError(
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
			);
			return;
		}
		const model = {
			amount: formData.amount,
			action,
			categoryId: myLimitsStore.category.categoryId,
			limitId,
			durationId: durationId.id,
			typeId: formData.limitType,
		};
		await myLimitsStore.submitLimit(model);
		methods.reset();
	}

	const weeklyTimeObject = formatLoginLimitTime(
		methods.getValues("amount") || amount || 0,
		IntervalType.Week
	);
	const monthlyTimeObject = formatLoginLimitTime(
		methods.getValues("amount") || amount || 0,
		IntervalType.Month
	);
	methods.watch("amount");

	return (
		<FormProvider {...methods}>
			<form className="at-myLimitsForm u-mar--bottom--big">
				<div className="card--primary">
					<div className="card--primary__body u-padd--xsml">
						<div className="form__group">
							<div className="row">
								<FormSelectField
									className="col col-sml-12 form__field"
									name="amount"
									label="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_INTERVAL"
									placeholder="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DURATION_PLACEHOLDER"
									dropdownData={timeLimitsDropDown}
									required
								/>
							</div>
						</div>
						<div id="loginTimeTotals">
							{weeklyTimeObject && (
								<div>
									{t(
										"specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.WEEKLY_LIMIT"
									)}
									:{" "}
									{!weeklyTimeObject.days
										? null
										: t(`${weeklyTimeObject.days.text}`, {
												0: weeklyTimeObject.days.amount,
										  })}{" "}
									{!weeklyTimeObject.hours
										? null
										: t(`${weeklyTimeObject.hours.text}`, {
												0: weeklyTimeObject.hours
													.amount,
										  })}{" "}
									{!weeklyTimeObject.minutes
										? null
										: t(
												`${weeklyTimeObject.minutes.text}`,
												{
													0: weeklyTimeObject.minutes
														.amount,
												}
										  )}
								</div>
							)}
							{monthlyTimeObject && (
								<div className="u-mar--bottom--big">
									{t(
										"specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.MONTHLY_LIMIT"
									)}
									:{" "}
									{!monthlyTimeObject.days
										? null
										: t(`${monthlyTimeObject.days.text}`, {
												0: monthlyTimeObject.days
													.amount,
										  })}{" "}
									{!monthlyTimeObject.hours
										? null
										: t(`${monthlyTimeObject.hours.text}`, {
												0: monthlyTimeObject.hours
													.amount,
										  })}{" "}
									{!monthlyTimeObject.minutes
										? null
										: t(
												`${monthlyTimeObject.minutes.text}`,
												{
													0: monthlyTimeObject.minutes
														.amount,
												}
										  )}
								</div>
							)}
						</div>
						<div className="btn-group">
							{canBeDeleted && (
								<Button
									className="btn btn--base btn--secondary--inverted"
									onClick={methods.handleSubmit(
										(data: SubmitSingleLimitData) =>
											submitForm(
												data,
												SubmitLimitAction.REMOVE
											)
									)}
									btnText="COMMON.DEACTIVATE_BUTTON"
									isDisabled={myLimitsStore.isRequestPending}
								/>
							)}

							{canBeUpdated ? (
								<FormButton
									className="btn btn--base btn--primary"
									onClick={methods.handleSubmit(
										(data: SubmitSingleLimitData) =>
											submitForm(
												data,
												SubmitLimitAction.UPDATE
											)
									)}
									btnText="COMMON.SAVE_BUTTON"
								/>
							) : (
								<FormButton
									className="btn btn--base btn--primary"
									onClick={methods.handleSubmit(
										(data: SubmitSingleLimitData) =>
											submitForm(
												data,
												SubmitLimitAction.ADD
											)
									)}
									btnText="COMMON.SAVE_BUTTON"
								/>
							)}
						</div>
					</div>
				</div>
			</form>
		</FormProvider>
	);
});
