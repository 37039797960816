import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { setTabTitle } from "@utils";
import SearchViewStore from "../../../state/offer/pages/SearchViewStore";
import { useRootOfferStore } from "@hooks";

export function useSearchStore(searchTerm: string) {
	const { t } = useTranslation();
	const rootStore = useRootOfferStore();
	const searchViewStore = useMemo(() => new SearchViewStore(rootStore), []);

	useEffect(() => {
		setTabTitle(t("SEARCH.TITLE", { searchTerm: searchTerm }));

		searchViewStore.rootStore.searchBarViewStore.setValue(searchTerm);
		searchViewStore.onInitialize(1);

		//clear search on unmount
		return () => searchViewStore.rootStore.searchBarViewStore.setValue("");
	}, [searchTerm]);

	return searchViewStore;
}
