import React, { useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { List } from "react-virtualized";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { EventContext } from "@gp/components";
import {
	SportOffer,
	BettingTypeSelectorsStore,
	MainOfferStore,
} from "@gp/offer";
import {
	useIsAdditionalOfferVisible,
	useGetAdditionalOfferEvent,
} from "@offer/common";

import {
	useWsProps,
	useMultiListScrollOffset,
} from "@lib/virtualization-components";

import { TFlatVirtualizedSportItem } from "@v2/state/offer/pages/TFlatVirtualizedSportItem";
import EventRenderErrorBoundary from "../EventRenderErrorBoundary";
import { Event, EventOffer } from "../../event-row";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";

const UNIT = 6;
const OFFER_ROW = UNIT * 9;
const OFFER_HT = UNIT * 7;

export default observer(function VirtualizedSportOffer(props: {
	isScrollRestoreWorking: boolean;
	flatSport: TFlatVirtualizedSportItem[];
	isSpCollapsed: boolean;
	store: {
		period: string;
		eventsInSports: {
			sports: SportOffer[];
		};
		configuration: {
			bettingTypeSelectorsStore: {
				getSportSelector: BettingTypeSelectorsStore["getSportSelector"];
			};
		};
		eventKeysMap: MainOfferStore["eventKeysMap"];
		keyOffersMap: MainOfferStore["keyOffersMap"];
		toggleSportCollapse: (sportId: string) => void;
	};
	listHeight: number;
	parentWrapperDiv: HTMLDivElement | null;
}) {
	const t = useTranslation().t;
	const sport = props.flatSport[0].sport;
	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();
	const additionalOfferEventId = useGetAdditionalOfferEvent()?.eventId;
	const favoriteStore = useContext(FavoritesStoreContext);

	const wsProps = useWsProps();
	const listRef = useRef<List>(null);
	const [listWrapperDivRef, setListWrapperDivRef] =
		React.useState<HTMLDivElement | null>(null);

	useEffect(() => {
		listRef.current?.recomputeRowHeights();
	}, [favoriteStore.userFavoriteEventsSet, props.flatSport]);

	const listScrollTop = useMultiListScrollOffset(
		props.parentWrapperDiv || listWrapperDivRef,
		props.store.eventsInSports.sports[0] === sport,
		window,
		props.listHeight
	);

	if (sport == null) {
		console.error(
			"Expected sport to be first in flat sport list, got nill"
		);
		return null;
	}

	const _getRowHeight = (getRowHeightProps: { index: number }): number => {
		const item = props.flatSport[getRowHeightProps.index + 1];

		// Half time offer
		if (item.isSecondary) {
			return OFFER_HT;
		}

		// event main and secondary offer have the same height
		return OFFER_ROW;
	};

	const _renderRow = (renderRowProps: {
		index: number;
		style: React.CSSProperties;
		key: React.Key;
	}) => {
		const item = props.flatSport[renderRowProps.index + 1];

		if (props.isScrollRestoreWorking) {
			return (
				<div style={renderRowProps.style} key={renderRowProps.key} />
			);
		}

		if (item.event == null) {
			return null;
		}

		// render half time offer
		if (item.isSecondary) {
			return (
				<div
					key={item.event.id + "-secondary"}
					className={classNames("offer--virtual__tbody offer__ht", {
						"is-selected": additionalOfferEventId === item.event.id,
					})}
					style={renderRowProps.style}
				>
					<div className="offer__body offer--virtual__body">
						<EventContext.Provider value={item.event}>
							<div
								className={classNames(
									"offer__body__row offer__ht__row",
									{
										"offer__ht__row--sml":
											props.store.period == "upcoming",
										"is-collapsed":
											isAdditionalOfferVisible,
										odd: renderRowProps.index % 2 !== 0,
									}
								)}
							>
								<div className="offer__body__data offer__ht__inner">
									<span className="offer__ht__title">
										{t("LIVE.FIRST_HALF_TIME")}
									</span>
								</div>

								<EventOffer columns={item.secondaryOffer} />
							</div>
						</EventContext.Provider>
					</div>
				</div>
			);
		}

		// render event
		return (
			<div
				className="offer--virtual__tbody"
				key={item.event.id}
				style={renderRowProps.style}
			>
				<EventRenderErrorBoundary event={item.event}>
					<div
						className={classNames(
							"offer__body offer--virtual__body",
							{
								offer__main: item.secondaryCount > 0,
							}
						)}
					>
						<EventContext.Provider value={item.event}>
							<Event
								isAlternate={renderRowProps.index % 2 === 0}
								hasSecondaryOffer={item.secondaryCount > 0}
								noSecondaryOffer
								secondaryOfferCount={item.secondaryCount}
							/>
						</EventContext.Provider>
					</div>
				</EventRenderErrorBoundary>
			</div>
		);
	};

	return (
		<div ref={setListWrapperDivRef}>
			<List
				ref={listRef}
				autoHeight
				height={wsProps.height}
				width={wsProps.width}
				overscanRowCount={2}
				rowHeight={_getRowHeight}
				rowCount={props.flatSport.length - 1}
				rowRenderer={_renderRow}
				scrollTop={listScrollTop}
				style={{ width: "100%" }}
			/>
		</div>
	);
});
