import {
	AccountTypes,
	getAgencyKey,
	getApplicationHeaders,
	httpClient,
} from "@utils";

export class PaymentManagerApiService {
	//use only Online USER headers
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}/payment-manager`;
	}

	static getRequestHeaders = () => {
		//use only Online USER headers
		const internetUser =
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			);
		const appHeaders = getApplicationHeaders(internetUser);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		appHeaders["Accept" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async cancelWithdrawalTransaction(
		transactionId: string
	): Promise<any> {
		return await httpClient.put(
			`${this.baseUrl}/cancel/${transactionId}`,
			null,
			this.getRequestHeaders()
		);
	}
}
