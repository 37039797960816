import { useEmailActivation } from "@v2/hooks/membership/email-activation";
import React, { useEffect } from "react";
import { Footer } from "../../shared/footer";
import { ActivationSuccess, ActivationError, ActivationInProgress } from ".";
import { Header } from "../../shared/header";
import { setTabTitle } from "@utils";

/** 
	This component is used for "Email change" action from account settings on website (we send URL to API where should link from mail lead) for online and retail user and after user card (retail user) is activated.
	API endpoint: platform/agency-users/email-change
*/
export default function EmailActivationPage() {
	const { activateUserEmail, activationState, errorMessage } =
		useEmailActivation();

	useEffect(() => {
		setTabTitle("Email activation");
		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);

		activateUserEmail();
	}, []);

	return (
		<>
			<Header />
			<main role="main" className="l--main l--main--med">
				<section className="l--content l--content--full">
					<div className="l--content__main">
						{/* success */}
						<ActivationSuccess activationState={activationState} />

						{/* errorr */}
						<ActivationError
							activationState={activationState}
							errorMessage={errorMessage}
						/>

						{/* ostalo : waiting... */}
						<ActivationInProgress />
					</div>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</>
	);
}
