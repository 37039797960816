import { observable, action, computed } from "mobx";
import { BaseOfferMenuStore } from "../BaseOfferMenuStore";

export type TLivePeriod = "events" | "my-favorites" | "played-events";

export class LivePeriodSubMenuStore {
	private parentMenu: {
		menu: BaseOfferMenuStore["menu"];
		rootStore: BaseOfferMenuStore["rootStore"];
		liveEventsFromMyBets?: { count: number };
		deselectSport: (sp: any) => void;
		closeOfferMenu: () => void;
	};

	constructor(parentMenu: LivePeriodSubMenuStore["parentMenu"]) {
		this.parentMenu = parentMenu;
	}

	@observable public selectedPeriodKey = "events";
	@observable public prevPeriod = "events";

	/** @deprecated this returns selected period key */
	@computed get selectedPeriod() {
		return { period: this.selectedPeriodKey };
	}

	@action.bound
	setPeriodKey(period: TLivePeriod) {
		if (this.selectedPeriodKey === period) {
			return;
		}

		this.prevPeriod = this.selectedPeriodKey;
		this.selectedPeriodKey = period;

		if (this.selectedPeriodKey !== "events") {
			if (App.state.history.action !== "POP") {
				this.parentMenu.menu.onCheck(false);
			}
			this.parentMenu.deselectSport(null);
			this.parentMenu.closeOfferMenu();
		}

		// Scroll to top of the page on period change
		window.scroll(0, 0);

		if (
			this.selectedPeriodKey === "played-events" &&
			this.parentMenu?.liveEventsFromMyBets?.count === 0
		) {
			App.state.redirect("events");
		}
	}
}
