import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const Button = forwardRef(
	(
		{
			className,
			btnContent = undefined,
			btnText,

			// Icons
			iconLeft = false,
			iconRight = false,
			iconOnly = false,
			iconClassName = undefined,

			//Test classes
			atClassName = undefined,

			//Types
			btnType = "submit",
			isLoading = false,

			...rest
		}: PropTypes,
		ref: React.LegacyRef<HTMLButtonElement> | undefined
	) => {
		const { t } = useTranslation();

		const btnClasses = classNames(
			{
				[`at-${atClassName}`]: atClassName,
			},
			"btn",
			className,
			{
				"btn--icon": iconLeft || iconRight || iconOnly,
			}
		);

		const iconClasses = classNames("btn__icon u-icon", iconClassName);

		return (
			<button className={btnClasses} type={btnType} ref={ref} {...rest}>
				{iconLeft && <i className={iconClasses} />}

				{!isLoading && btnText != null && btnText != undefined && (
					<span className="btn__label">{t(btnText)}</span>
				)}

				{isLoading ? <span className="btn--load"></span> : null}

				{btnContent != undefined && btnContent}

				{!isLoading && (iconRight || iconOnly) && (
					<i className={iconClasses} />
				)}
			</button>
		);
	}
);

export default forwardRef(function (
	props: Omit<Parameters<typeof Button>[0], "ref">,
	ref?: Parameters<typeof Button>[0]["ref"]
) {
	return <Button {...props} ref={ref} />;
});

type PropTypes = {
	className: string;
	btnContent?: JSX.Element;
	btnText?: string;

	// Icons
	iconLeft?: boolean;
	iconRight?: boolean;
	iconOnly?: boolean;
	iconClassName?: string;

	//Test classes
	atClassName?: string;

	//Types
	btnType?: string;

	isLoading?: boolean;
} & React.HTMLProps<HTMLButtonElement>;
