import React from "react";
import { Redirect, Route } from "react-router-dom";
import { DeviceDetector, getCurrentCulture, isNullOrWhitespace } from "@utils";

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const MobileError404 = lazy(loadFailPath, ()=>import("@v2/components/mobile/shared/error/Error404"));
// prettier-ignore
const DesktopError404 = lazy(loadFailPath, ()=>import("@v2/components/desktop/shared/error/Error404"));
// prettier-ignore
const  LegacyRedirects = lazy(loadFailPath, ()=>import("./LegacyRedirects"));

//#region lazy

export function NoMatchRoute(props: { full?: boolean }) {
	const path404 = window.location.pathname + window.location.search;
	return (
		<Route path="*">
			{/* legacy mvc routes */}
			<Route
				path="/:culture/Membership/Authentication/Login"
				render={({ match }) => (
					<Redirect to={`/${match.params.culture}/auth/login`} />
				)}
			/>
			<Route
				path="/:culture/Membership/Authentication/ForgotUsername"
				render={({ match }) => (
					<Redirect
						to={`/${match.params.culture}/auth/forgot-username`}
					/>
				)}
			/>
			<Route
				path="/:culture/Membership/Password/ForgotPassword"
				render={({ match }) => (
					<Redirect
						to={`/${match.params.culture}/auth/forgot-password`}
					/>
				)}
			/>
			<Route
				path="/:culture/Administration/AccountSettings"
				render={({ match }) => (
					<Redirect
						to={`/${match.params.culture}/account-settings/personal-data`}
					/>
				)}
			/>
			<Route
				path="/:culture/Administration/AccountSettings/EmailActivation"
				render={({ match }) => (
					<Redirect
						// @ts-expect-error TODO
						to={`/${match.params.culture}/email-activation?${match.params.search}`}
					/>
				)}
			/>
			<Route
				path="/:culture/bets"
				render={({ match }) => (
					<Redirect to={`/${match.params.culture}/my-bets`} />
				)}
			/>

			<LegacyRedirects />

			{/* fallback */}
			{/* if hash exists do not go to fallback because when hash exists path is "/" */}
			{isNullOrWhitespace(window.location.hash) && (
				<Route exact path="/">
					<Redirect
						to={`/${getCurrentCulture()}/home/full/highlights`}
					/>
				</Route>
			)}

			{(window.location.pathname == "/" ||
				isNullOrWhitespace(window.location.pathname)) && (
				<Route>
					<Redirect
						to={`/${getCurrentCulture()}/home/full${
							window.location.search
						}`}
					/>
				</Route>
			)}

			{DeviceDetector.isMobileTheme ? (
				<MobileError404 {...props} path404={path404} />
			) : (
				<DesktopError404 {...props} path404={path404} />
			)}
		</Route>
	);
}
