export class PaymentRequestFilter {
	to: string | null | undefined;
	isDeposit: boolean;
	pageNumber: number;
	paymentMethodId?: string;
	from: string | null | undefined;
	transactionStatusId?: string;
	userId?: string;
	pageSize?: number;
}
