import {
	AccountTypes,
	getAgencyKey,
	getApplicationHeaders,
	getUserId,
	httpClient,
} from "@utils";

export class UserDocumentFileStreamsApiService {
	static get baseUrl(): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}/user-document-file-streams`;
	}

	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		);
		return appHeaders;
	};

	static async sendUserDocumentFiles(data: FormData) {
		return await httpClient.post(
			`${this.baseUrl}/${getUserId()}/batch/restricted`,
			data,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}
}
