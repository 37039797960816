import { DateTime } from "luxon";
import { Currency } from "../common/Currency";
import { UserData } from "@v2/state";
import { DepositBonusInfo } from "@api-types/deposit";

export class LoginResponseDto {
	primary: LoginAccountDto;
	secondary?: LoginAccountDto;
	errorCode?: string;
	dataVerificationResponse?: UserData;
}

export class RedirectLoginResponseDto {
	redirectUrl: string;
}

export class LoginUserMatchingResponseDto extends LoginResponseDto {
	message?: string;
	matchingErrorCode?: string;
	userAccounts: LoginBalanceDto[];
}

export class LoginAccountDto {
	accountActivation: LoginActivationResponseDto;
	token: LoginResponseToken;
	user: LoginResponseUser;
	userAccounts: LoginBalanceDto[];
	loginHistory: LoginHistoryResponseDto;
}

export class LoginActivationResponseDto {
	activationToken: string;
	agencyKey: string;
	agencyId: string;
	shopId: string;
	activationType: ActivationTypeEnum;
	minDepositLimit: number | null;
	maxDepositLimit: number | null;
	currencyDisplayCode: string;
}

export class LoginHistoryResponseDto {
	currentLogin: string;
	lastLogin: string;
}

export class LoginResponseToken {
	expirationDate: string;
	expiresIn: number | null;
	// slidingWindow: number | null; TODO can this really be null?
	slidingWindow: number;
	token: string;
}

export class LoginResponseUser {
	accountVerificationStatus: AccountVerificationStatus;
	agencyKey: string;
	agencyId: string;
	email: string;
	id: string;
	shopId: string;
	username: string;
	autoLogoutPeriod: number;
	bettingActivityNeeded: boolean;
	shouldAcceptTermsAndConditions: boolean;
	isOasisEnabled: boolean;
	isInternetUser: boolean;
	isOfflineUserMailApproved: boolean;
	accountLockInfo: AccountLockInfoModel;
	isAlreadyLoggedIn: boolean;
	bettingTimeoutInfo: BettingTimeoutInfoModel;
	stakeAmount: number;
	depositLimitUpdate?: boolean;
	isAutoWithdrawalEnabled: boolean;
	isValid: boolean;
	gracePeriodExpirationDate: string | null;
	recheckDueDate: null | string;
	userVerifiedPopup?: boolean;
	isGigCasinoAllowed: boolean;
	isInsicEnabled: boolean;
	isBettingDisabled?: boolean | null;
	shouldAcceptGdpr: boolean;
	isRofusCSRPValidationEnabled: boolean;
	lastRefreshTime: string | undefined;
	userDocumentDateExpiresSoon?: boolean | null;
	userDocumentDateNotSet?: boolean | null;
	userDocumentDateExpired?: boolean | null;
	realityCheckDuration: number;
	chatName: string;
	isCustomWebRole: boolean;
	shouldOfferBonus?: boolean | null;
	isBonusDeclined?: boolean | null;
	depositBonusInfo?: DepositBonusInfo | null;
}

export class LoginBalanceDto {
	id: string;
	accountTypeId: string;
	name: string;
	abrv: string;
	balance: number;
	currency: Currency;
	userId: string; // TODO is this really here?
}

export class LoginHistoryResponseModel {
	/** ISO Date */
	currentLogin: string;
	/** ISO Date */
	lastLogin: string;
}

export enum ActivationTypeEnum {
	AccountActivation,
	PasswordChange,
}

export class BettingTimeoutInfoModel {
	duration: number | null;
	endDateTime: DateTime | null;
}

export class AccountLockInfoModel {
	duration: number;
	startDateTime: DateTime;
	endDateTime: DateTime;
}

export enum UserStatus {
	NotChecked,
	PreChecked,
	Checked,
	RecheckRequired,
}

export enum AccountVerificationStatus {
	Unverified = 0,
	ReverificationRequired = 1,
	VerificationUncompleted = 2,
	FullyVerified = 3,
}
