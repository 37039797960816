import {
	HTTPClient,
	UserFavoritesResponseModel,
} from "@gp/offer/dist/types/stores/favorites/types";
import { getAgencyKey, getApplicationHeaders, httpClient } from "@utils";

export class FavoritesService implements HTTPClient {
	get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/user-favorite-user-favorite-groups/user-settings/`;
	}

	getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";

		return appHeaders;
	};

	async get(): Promise<UserFavoritesResponseModel> {
		return await httpClient.get(
			`${this.baseUrl}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	async update(
		data: UserFavoritesResponseModel
	): Promise<UserFavoritesResponseModel> {
		return await httpClient.post(
			`${this.baseUrl}`,
			JSON.stringify(data),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	async delete(
		data: UserFavoritesResponseModel
	): Promise<UserFavoritesResponseModel> {
		return await httpClient.delete(
			`${this.baseUrl}`,
			this.getRequestHeaders(),
			{ body: JSON.stringify(data), responseType: "json" }
		);
	}
}
