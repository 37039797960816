import React, { FC, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { DepositContext } from "@context";
import Button from "@components/buttons/Button";

export const DepositMultiStepFooter: FC = observer(
	function DepositMultiStepFooter() {
		const {
			resetMultiStepFlags,
			isRedirectingToPaymentPage,
			isLoadingProcess,
		} = useContext(DepositContext);

		const { t } = useTranslation();

		return (
			<>
				<Button
					className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
					type="submit"
					isDisabled={isLoadingProcess || isRedirectingToPaymentPage}
					btnText={t<string>("COMMON.NEXT_BUTTON")}
					iconClassName="u-icon u-icon--xsml u-icon--arrow--right u-color--core--snow"
				></Button>

				<button
					className="card--primary__footer__btn btn btn--lrg btn--secondary--inverted"
					onClick={() => {
						resetMultiStepFlags(), window.scrollTo(0, 0);
					}}
				>
					<span className="btn__label">
						{t<string>("COMMON.BACK")}
					</span>
				</button>
			</>
		);
	}
);
