import { WebBonusesApiService } from "@api-services/myBonus/WebBonusesApiService";
import {
	BonusStatusEnum,
	HistoryStatus,
	UserBonusInfoREST,
} from "@api-types/myBonuses/Bonus";
import { useLoading } from "@hooks";
import { useContext, useState } from "react";
import { useNotification } from "../shared";
import { useTranslation } from "react-i18next";
import { RootAppStoreContext } from "@context";
import { AccountTypes } from "@utils";

export function useMyBonuses() {
	const [status, setStatus] = useState<null | UserBonusInfoREST>(null);
	const [history, setHistory] = useState<null | HistoryStatus[]>(null);
	const [isInput, setIsInput] = useState<boolean>(false);
	const { setIsLoading } = useLoading();
	const { showError, showSuccess } = useNotification();
	const { t } = useTranslation();
	const { userAuthStore } = useContext(RootAppStoreContext);

	async function fetchData(userType: AccountTypes) {
		try {
			setIsLoading(true);
			const user = userAuthStore.getUserAccountType(userType);
			let data = await WebBonusesApiService.getBonusStatus(user);

			let bonus = null;
			if (data.bonusHistoryExists) {
				let id = "";
				if (data.bonusStatus == BonusStatusEnum.ActiveBonus) {
					id = data.lastPlayerBonusId;
				}
				bonus = await WebBonusesApiService.getHistoryStatus(id, user);
			}
			setStatus(data);
			setHistory(bonus);
		} catch (e) {
			console.error(e);
			showError("COMMON.ERROR_MESSAGES.DEFAULT");
		} finally {
			setIsLoading(false);
		}
	}

	async function activateVoucher(
		bettingAccountTypeId: string,
		code: { voucher: string },
		userType: AccountTypes
	) {
		try {
			const user = userAuthStore.getUserAccountType(userType);
			const response = await WebBonusesApiService.activateVoucher(
				bettingAccountTypeId,
				code.voucher,
				user
			);
			if (response.status == BonusStatusEnum.UsedBonus) {
				showError(
					"USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_BONUS_ALREADY_USED"
				);
			} else if (response.status == BonusStatusEnum.InvalidCode) {
				showError("USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_NOT_FOUND");
			} else if (response.status == BonusStatusEnum.NotVerified) {
				showError(
					"USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_USER_NOT_VERIFIED"
				);
			} else if (response.status == BonusStatusEnum.CanGetBonus) {
				showSuccess(
					t(
						"USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_SUCCESSFULLY_ASSIGNED",
						{
							0: response.bonusInfo?.bonusAmount,
							1: response.bonusInfo?.currencyDisplayCode,
						}
					)
				);
				userAuthStore.refreshUserBalance();
				setIsInput(false);
				await fetchData(userType);
			} else {
				showError("COMMON.ERROR_MESSAGES.DEFAULT");
			}
		} catch (e) {
			console.error(e);
			showError("COMMON.ERROR_MESSAGES.DEFAULT");
		}
	}

	return {
		status,
		history,
		activateVoucher,
		fetchData,
		isInput,
		setIsInput,
	};
}
