import React from "react";
import { observer } from "mobx-react";

import { EventKeyOffer } from "@gp/offer";
import { EventKeyBettingOffer } from "@gp/models";

import { EventOutrightOfferRowItem } from "./";

export const EventOutrightOfferRow = observer(
	function EventOutrightOfferRow(props: {
		eventKey: EventKeyOffer;
		offers: EventKeyBettingOffer[];
	}) {
		const betSlipStore = App.offer.rootStore.betSlipStore;

		if (props.offers == null || props.offers.length === 0) {
			return null;
		}

		return (
			<>
				{props.offers.map((tip) => {
					const isSelected = betSlipStore.isTipSelected(tip.id);

					return (
						<EventOutrightOfferRowItem
							key={tip.id}
							tip={tip}
							eventKey={props.eventKey}
							isSelected={isSelected}
						/>
					);
				})}
			</>
		);
	}
);
