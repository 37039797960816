import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLoading } from "@hooks";
import { useNotification } from "@v2/hooks/shared";
import usePersonalData from "./usePersonalData";
import { isNullOrWhitespace } from "@utils";

export default function usePersonalDataPayPinSection(
	payPin: string | null = null
) {
	const { updateUserPin, getNewPayPin } = usePersonalData();
	const { setValue, getValues } = useFormContext();
	const [newPayPin, setNewPayPin] = useState<boolean>(false);
	const { showSuccess, showError } = useNotification();
	const { setIsLoading } = useLoading();

	const handleSavePin = async (): Promise<void> => {
		const currPayPin = getValues("payPin");
		try {
			setIsLoading(true);
			const response = await updateUserPin(currPayPin);
			if (response == null) {
				setValue("payPin", currPayPin);
				setNewPayPin(false);
				showSuccess(
					"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONAL_DATA_UPDATE_SUCCESS"
				);
			}
		} catch (error) {
			showError(
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error?.error?.message
			);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCancelPin = (): void => {
		setValue("payPin", payPin);
		setNewPayPin(false);
	};

	const getNewPin = (): void => {
		const newPin = getNewPayPin();
		setValue("payPin", newPin.toString());
		setNewPayPin(true);
	};

	const shouldHidePayPinSection = (payPin: string): boolean => {
		return (
			isNullOrWhitespace(payPin) ||
			(App.state.rootStore.userAuthStore.isPrimaryUserOnline &&
				!App.state.rootStore.userAuthStore.secondaryUser)
		);
	};

	return {
		getNewPin,
		handleCancelPin,
		handleSavePin,
		newPayPin,
		shouldHidePayPinSection,
	};
}
