import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { EventContext } from "@gp/components";
import { EventKeyOffer, MainOfferStore } from "@gp/offer";
import { EventKeyBettingOffer } from "@gp/models";
import { toDisplayOdd } from "@gp/utility";

export const EventOutrightOfferRowItem = observer(
	function EventOutrightOfferRowItem(props: {
		tip: EventKeyBettingOffer;
		isSelected: boolean;
		eventKey: EventKeyOffer;
	}) {
		const { t } = useTranslation();
		const event = useContext(EventContext);
		const betSlipStore = App.offer.rootStore.betSlipStore;

		const displayTipValue = toDisplayOdd(props.tip.value);

		if (displayTipValue == null) {
			return null;
		}

		const btnClasses = classNames("quote", "quote--button", {
			"one-click": betSlipStore.isOneClickBetActive,
			"is-active": props.isSelected,
			desc: props.tip.indicator === -1,
			inc: props.tip.indicator === 1,
		});

		return (
			<div key={props.tip.id} className="offer__outright__item">
				<div className="offer__outright__tip" title={props.tip.tip}>
					{props.tip.tip}{" "}
					{props.tip.gender === 1 ? (
						<>({t<string>("OFFER.GENDER.WOMEN")})</>
					) : null}
				</div>
				<button
					type="button"
					className={btnClasses}
					title={displayTipValue}
					disabled={
						props.tip.isLocked ||
						event.isLocked ||
						props.eventKey.isLocked
					}
					onClick={() => betSlipStore.addRemoveOffer(props.tip)}
				>
					{displayTipValue}
				</button>
			</div>
		);
	}
);
