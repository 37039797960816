import React from "react";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Bet } from "@administration/pages/my-bets/model";
import { Button } from "@components/buttons";
import { useRootAppStore } from "@hooks";
import { BetOfferItem } from "@v2/components/desktop/my-bets/components";
import { MyBetsBetContext } from "@v2/context";
import { useFixBody } from "@v2/hooks";

export default observer(function ChatBetSlipSharedPopup() {
	const { chatViewStore } = useRootAppStore();

	useFixBody(true, !!chatViewStore.chatBetSlipShareData);

	if (!chatViewStore.chatBetSlipShareData) {
		return null;
	}

	return (
		<div className="popup popup--full" style={{ zIndex: 99999999 }}>
			<PopupContent bet={chatViewStore.chatBetSlipShareData} />
		</div>
	);
});

const PopupContent = observer(function PopupContent(props: { bet: Bet }) {
	return (
		<div className="popup__card popup--most__card">
			<PopupHeader />
			<PopupBody bet={props.bet} />
			<PopupFooter bet={props.bet} />
		</div>
	);
});

const PopupHeader = observer(function PopupHeader() {
	const { t } = useTranslation();
	const { chatViewStore } = useRootAppStore();

	return (
		<div className="popup__header">
			<span>{t("MY_BETS.SHARE.TITLE")}</span>
			<button
				type="button"
				className="btn btn--icon btn--square btn--square--sml btn--secondary--inverted"
				onClick={() =>
					chatViewStore.chatBetSlipShareHandler.onDispose()
				}
			>
				<i className="u-icon u-icon--sml u-icon--close" />
			</button>
		</div>
	);
});

const PopupBody = observer(function PopupBody(props: { bet: Bet }) {
	const { t } = useTranslation();

	return (
		<div className="popup__body u-padd--reset">
			<div className="bet__header__inner bet--slip__header__inner">
				<div className="bet__header__col">
					<span className="u-type--xxsml u-color--text--tertiary">
						{t("BET_SLIP.DATE")}:{" "}
					</span>

					<span className="u-type--sml u-type--wgt--semibold u-color--text--primary">
						{DateTime.fromISO(props.bet.dateCreated).toLocaleString(
							DateTime.DATETIME_SHORT
						)}
					</span>
				</div>

				<div className="bet__header__col">
					<span className="u-type--xxsml u-color--text--tertiary">
						{t("BET_SLIP.MAX_COEF")}:{" "}
					</span>

					<span className="u-type--sml u-type--wgt--semibold u-color--text--primary">
						{props.bet.maxCoefficient.toFixed(2)}
					</span>
				</div>
			</div>

			<div className="u-padd--x--xsml u-padd--bottom--xsml">
				<div className="card--bet">
					<table
						className="table--bets table--slip"
						cellSpacing={0}
						cellPadding={0}
					>
						<thead className="table--bets__head">
							<tr className="table--bets__subhead">
								<th className="table--bets__ts table--bets__ts--time">
									{t<string>("MY_BETS.ITEM.TIME_COLUMN")}
								</th>
								<th className="table--bets__ts table--bets__ts--combi">
									&nbsp;
								</th>
								<th className="table--bets__ts table--bets__ts--event">
									{t<string>(
										"MY_BETS.ITEM.EVENT_NAME_COLUMN"
									)}
								</th>
								<th className="table--bets__ts table--bets__ts--bank">
									&nbsp;
								</th>
								<th className="table--bets__ts table--bets__ts--bet-type">
									{t<string>("MY_BETS.ITEM.TIP_NAME_COLUMN")}
								</th>
								<th className="table--bets__ts table--bets__ts--odds">
									{t<string>("MY_BETS.ITEM.QUOTA_COLUMN")}
								</th>
								<th className="table--bets__ts table--bets__ts--results">
									{t<string>("MY_BETS.ITEM.RESULT_COLUMN")}
								</th>
								<th className="table--bets__ts table--bets__ts--icon">
									&nbsp;
								</th>
							</tr>
						</thead>
						<tbody className="table--bets__body">
							<MyBetsBetContext.Provider value={props.bet}>
								{props.bet.betSlipOffers.map((offer) => (
									<BetOfferItem
										key={offer.id}
										offer={offer}
									/>
								))}
							</MyBetsBetContext.Provider>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
});

const PopupFooter = observer(function PopupFooter(props: { bet: Bet }) {
	const { chatViewStore } = useRootAppStore();
	const { t } = useTranslation();
	const { bet } = props;

	return (
		<div className="popup__footer">
			<Button
				className="btn btn--base btn--primary"
				onClick={() =>
					chatViewStore.chatBetSlipShareHandler.addToSlip(bet)
				}
				type="button"
				iconClassName="u-icon u-icon--sml u-icon--refresh u-color--core--snow"
				btnText={t("MY_BETS.SHARE.ADD_TO_SLIP")}
			/>
		</div>
	);
});
