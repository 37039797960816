import React from "react";

export function Number(props: {
	value: number;
	decimalPlaces?: number
}) {
	return (
		<React.Fragment>
			{props.value.toFixed(props.decimalPlaces || 2)}
		</React.Fragment>
	);
}
