import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { OfferStoreContext, EventContext } from "@gp/components";
import { AdditionalOfferViewStore } from "@v2/state/offer/components/AdditionalOfferViewStore";

export const EventFooter = observer(function EventFooter() {
	// @ts-expect-error
	const { isMultiEvent } = useContext(OfferStoreContext);

	if (!isMultiEvent) {
		return null;
	}

	return (
		<div className="event__footer">
			<EventFooterCore />
		</div>
	);
});

const EventFooterCore = observer(function EventFooterCore() {
	const { t } = useTranslation();
	const event = useContext(EventContext);
	const store = useContext(OfferStoreContext) as AdditionalOfferViewStore;
	// @ts-expect-error
	const { expandEvent, collapseEvent, expandedEvents } =
		useContext(OfferStoreContext);

	// @ts-expect-error
	if (event.bettingTypeGroups.length === 0) {
		return null;
	}

	const eventInList: { eventId: string; isMyBetsOpen: boolean } =
		//@ts-expect-error
		store.myBetsToggleStateList?.find(
			(eItem: { eventId: string; isMyBetsOpen: boolean }) =>
				eItem.eventId == event.id
		);

	if (eventInList != null && eventInList.isMyBetsOpen) {
		return null;
	}

	if (expandedEvents.includes(event.id)) {
		return (
			<button
				className="event__footer__btn btn btn--sml btn--block btn--ghost btn--icon btn--animate-rotate-down"
				type="button"
				onClick={() => collapseEvent(event.id)}
			>
				{/* {t("EVENT.MULTI.HIDE_ALL_BETS")} */}
				{t("COMMON.CLOSE")}
				<i className="u-icon u-icon--xsml u-icon--caret--up" />
			</button>
		);
	}

	return (
		<button
			className="event__footer__btn btn btn--sml btn--block btn--ghost btn--icon btn--animate-rotate-down"
			type="button"
			onClick={() => expandEvent(event.id)}
		>
			{/* {t("EVENT.MULTI.SHOW_ALL_BETS")} */}
			{t("COMMON.SHOW_MORE")}
			<i className="u-icon u-icon--xsml u-icon--caret--right" />
		</button>
	);
});
