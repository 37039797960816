import {
	observable,
	computed,
	action,
	runInAction,
	reaction,
	IReactionDisposer,
} from "mobx";
import { DateTime } from "luxon";
import { casinoService } from "@v2/data-access";
import {
	GamingHistoryFilter,
	GamingHistoryRawFilterData,
} from "@data-types/gaming-history";
import RootStore from "@state/stores/RootStore";
import GamingHistoryStore from "./GamingHistoryStore";
import { GameCategoryDto } from "@api-types/casino/GameCategoryDto";
import { LoaderStore } from "@state/stores/common";

function createDefaultRawData() {
	const defaultRawFilterData = observable.object(
		new GamingHistoryRawFilterData()
	);

	defaultRawFilterData.period = null;
	defaultRawFilterData.from = null;
	defaultRawFilterData.to = null;
	defaultRawFilterData.pageNumber = 1;
	defaultRawFilterData.casinoGameCategoryId = null;
	defaultRawFilterData.liveGameTableGroup = null;
	defaultRawFilterData.roundStatus = null;
	defaultRawFilterData.onlyWin = false;

	return defaultRawFilterData;
}

export default class GamingHistoryFilterStore {
	constructor(rootStore: RootStore, gamingHistoryStore: GamingHistoryStore) {
		this.rootStore = rootStore;
		this.loading = new LoaderStore();
		this.gamingHistoryStore = gamingHistoryStore;

		this.setDefaultFilterData();

		this.getUrlData = this.getUrlData.bind(this);
	}

	rootStore: RootStore;
	gamingHistoryStore: GamingHistoryStore;
	urlParamsUpdatedReaction: IReactionDisposer | null;
	@observable rawData = observable.object(new GamingHistoryRawFilterData());
	@observable isDefaultFilter: boolean = false;
	@observable categories: Array<GameCategoryDto> = [];
	loading: LoaderStore;

	@computed get isLoading() {
		return this.loading.isLoading;
	}

	@computed get filter(): GamingHistoryFilter {
		const filter = new GamingHistoryFilter();

		if (this.dateRange != null) {
			filter.dateFrom = this.dateRange.from;
			filter.dateTo = this.dateRange.to;
		}

		if (this.pageNumber != null) {
			filter.pageNumber = this.pageNumber;
		}

		if (this.casinoGameCategoryId != null) {
			filter.casinoGameCategoryId = this.casinoGameCategoryId;
		}

		if (this.liveGameTableGroup != null) {
			filter.liveGameTableGroup = this.liveGameTableGroup;
		}

		if (this.roundStatus != null) {
			filter.roundStatus = this.roundStatus;
		}

		filter.onlyWin = this.onlyWin;

		return filter;
	}

	//#region filter components

	@computed get dateRange(): {
		from: string | null | undefined;
		to: string | null | undefined;
	} | null {
		const { period, from, to } = this.rawData;

		if (
			(period == null && from == null && to == null) ||
			period === "all"
		) {
			return null;
		}

		if (period !== "custom") {
			const timeInterval = this.gamingHistoryStore.betPeriods
				.find((p) => p.value === period)
				?.getTimeInterval();
			if (timeInterval == null) {
				// Return default
				return this.gamingHistoryStore.betPeriods[0].getTimeInterval();
			}
			return timeInterval;
		}

		return {
			from,
			to: DateTime.fromISO(to || "")
				.endOf("day")
				.toUTC()
				.toString(),
		};
	}

	@computed get pageNumber(): number {
		return this.rawData.pageNumber;
	}

	@computed get casinoGameCategoryId(): string | null {
		return this.rawData.casinoGameCategoryId;
	}

	@computed get liveGameTableGroup(): string | null {
		return this.rawData.liveGameTableGroup;
	}

	@computed get roundStatus(): string | null {
		return this.rawData.roundStatus;
	}

	@computed get onlyWin(): boolean | null {
		return this.rawData.onlyWin;
	}

	//#endregion filter components

	//#region setters

	@action.bound
	setPeriod(period: string): void {
		this.rawData.period = period;
		this.isDefaultFilter = false;
	}

	@action.bound
	setFrom(from: string): void {
		this.rawData.from = from;
		this.isDefaultFilter = false;
	}

	@action.bound
	setTo(to: string): void {
		this.rawData.to = to;
		this.isDefaultFilter = false;
	}

	@action.bound
	setRawFilterData(filterData: GamingHistoryRawFilterData): void {
		this.rawData = Object.assign(createDefaultRawData(), filterData);
		this.isDefaultFilter = false;
	}

	@action.bound
	setDefaultFilterData(): void {
		this.rawData = createDefaultRawData();
		this.isDefaultFilter = true;
	}

	@action.bound
	setPageNumber(number: number): void {
		this.rawData.pageNumber = number;
		this.isDefaultFilter = false;
	}

	//#endregion setters

	@action.bound
	async onInitialize(): Promise<void> {
		this.loading.suspend();
		const urlData = this.getUrlData();

		if (App.state.history.location.search !== "") {
			this.setRawFilterData(
				Object.assign(createDefaultRawData(), urlData)
			);
		}

		this.categories = await casinoService.getCategories();
		this.onDisposeUrlParamsUpdated();
		this.urlParamsUpdatedReaction = reaction(
			() => ({
				filter: this.filter,
			}),
			() => this.updateUrlParamsFromRawData(),
			{
				fireImmediately: false,
			}
		);
		runInAction(() => {
			this.loading.resume();
		});
	}

	getUrlData() {
		const urlData = App.state.getUrlParamsAsObj<{
			pageNumber?: string | number;
		}>();

		if ("pageNumber" in urlData) {
			urlData.pageNumber = parseInt(urlData.pageNumber as string);
			if (isNaN(urlData.pageNumber) || urlData.pageNumber < 1) {
				urlData.pageNumber = 1;
			}
		}

		return urlData;
	}

	@action.bound
	updateUrlParamsFromRawData(): void {
		// Filter out null params to not clutter url
		const newUrlParams: Partial<GamingHistoryFilterStore["rawData"]> = {};
		for (const [key, value] of Array.from(Object.entries(this.rawData))) {
			if (value == null) {
				continue;
			}
			newUrlParams[key as keyof GamingHistoryFilterStore["rawData"]] =
				value;
		}

		if (!this.isDefaultFilter) {
			App.state.updateUrlParams(newUrlParams, true);
		}
	}

	//#region disposers

	@action.bound
	onDispose(): void {
		this.onDisposeUrlParamsUpdated();
		this.setDefaultFilterData();
	}

	@action.bound
	onDisposeUrlParamsUpdated(): void {
		if (this.urlParamsUpdatedReaction != null) {
			this.urlParamsUpdatedReaction();
			this.urlParamsUpdatedReaction = null;
		}
	}

	//#endregion disposers
}
