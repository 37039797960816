export type UserBonusInfoREST = {
	bonusHistoryExists: boolean;
	bonusStatus: BonusStatusEnum;
	bonusType: BonusTypeEnum;
	depositBonus: BonusInfoREST;
	isDepositBonusAvailable: boolean;
	isUserValid: boolean;
	isVoucherBonusAvailable: boolean;
	lastPlayerBonusId: string;
	rolloverInfo: BonusRolloverInfoREST;
	totalPlayerBonuses: number;
	voucherBonus: BonusInfoREST;
};

export enum BonusStatusEnum {
	None = 0,
	CanGetBonus = 1,
	InvalidCode = 2, //also VoucherNotFound in task
	UsedBonus = 3,
	NotOnlineUser = 4,
	ActiveBonus = 5,
	NotVerified = 6,
	ActiveRelatedUserBonus = 7, //not used (maybe will be used in future)
}

type BonusInfoREST = {
	activationTime: string;
	bonusAmount: number;
	bonusDepositDetails: BonusDepositDetailsREST;
	bonusPercentage: number;
	bonusTypeREST: BonusType;
	depositAmount: number;
	maxBonusAmount: number;
	minQuote: number;
	minRolloverCoefficient: number;
	requiredRollover: number;
	currencyDisplayCode: string;
};
export type BonusRolloverInfoREST = {
	currencyDisplayCode: string;
	activationTime: string;
	bonusAmount: number;
	currentRollover: number;
	currentRolloverPercentage: number;
	depositAmount: number;
	requiredRollover: number;
	rolloverRemainder: number;
	rolloverRemainderPercentage: number;
};

type BonusType = {
	abrv: string;
	dateCreated: string;
	dateUpdated: string;
	id: string;
	isActive: boolean;
	json: null | string;
	name: string;
	sortOrder: number;
	timeStamp: string;
	languageMetadata: object;
};
export type BonusDepositDetailsREST = {
	bonusInfo: BonusInfoREST;
	isAccountVerificationRequired: boolean;
	isBonusAcceptedByUser: boolean;
	isDepositBonusAvailable: boolean;
	isUserQualifiedForDepositBonus: boolean;
	isVoucherAssignedToPlayer: boolean;
	status: BonusStatusEnum;
	voucherCode: string;
};

export type HistoryStatus = {
	activationTime: string;
	availabilityStatus: number;
	bonusAmount: number;
	bonusDepositDetails: {
		amount: number;
		depositTransactionId: string;
	};
	bonusPercentage: number;
	bonusType: {
		abrv: string;
		dateCreated: string;
		dateUpdated: string;
		id: string;
		isActive: boolean;
		json: null;
		name: string;
		sortOrder: number;
		timeStamp: string;
		languageMetadata: string;
	};
	currencyDisplayCode: string;
	depositAmount: number;
	maxBonusAmount: number;
	minQuote: number;
	minRolloverCoefficient: number;
	requiredRollover: number;
};

export enum BonusTypeEnum {
	manual = 0,
	deposit = 1,
	voucher = 2,
}

export enum BonusTabs {
	bonus = 0,
	history = 1,
}

export type MarketplaceBonusResponse = {
	bonusStatusEnum: BonusStatusEnum;
	errorCode?: number;
	isSuccess: boolean;
};

export type MarketplaceBonusStatus = {
	shouldOfferBonus: boolean;
	bonusInfo?: BonusInfoREST;
};
