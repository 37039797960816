import React from "react";
import { useLoading } from "@hooks";

import classNames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getCurrentCulture } from "@utils";

export default observer(function Loader(props: {
	loaderClassName?: string;
	isLoading?: boolean;
}) {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { isLoading } = useLoading();

	const { loaderClassName = "loader--content" } = props;

	const { isSideMenuExpanded } = App.state.rootStore;
	const showFullLoader =
		pathname.includes(`/${getCurrentCulture()}/auth`) ||
		pathname.includes(`/${getCurrentCulture()}/info`) ||
		pathname.includes(`/${getCurrentCulture()}/results`) ||
		pathname.includes(`/${getCurrentCulture()}/offer`) ||
		pathname.includes(`/${getCurrentCulture()}/games`);

	if (isLoading || props?.isLoading) {
		return (
			<div
				className={classNames("loader", loaderClassName, {
					"sidebar-left-expanded":
						isSideMenuExpanded && !showFullLoader,
					"sidebar-left-collapsed":
						!isSideMenuExpanded && !showFullLoader,
					"loader--full": showFullLoader,
				})}
			>
				<div className="loader__card">
					<div className="loader__spinner u-display--b" />
					<div className="loader__title u-type--color--white">
						{t("HOME.LOADING")}...
					</div>
				</div>
			</div>
		);
	}

	return null;
});
