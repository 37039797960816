import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";

export function BRStatistics() {
	const { t } = useTranslation();

	return (
		<ul className="nav--secondary__menu">
			<li className="nav--secondary__item">
				<a
					href={`https://s5.sir.sportradar.com/tipwin/${getCurrentCulture()}`}
					className="nav--secondary__link"
					target="_blank"
				>
					<i className="nav--secondary__icon u-icon u-icon--lrg u-icon--stats" />
					<div className="nav--secondary__title">{t("RESULTS.RESULT_MENU.STATISTICS_LABEL")}</div>
				</a>
			</li>
		</ul>
	);
}