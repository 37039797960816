import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { setTabTitle } from "@utils";

export function useOnPageMount(title: string) {
	const { t } = useTranslation();

	useEffect(() => {
		if (title) {
			setTabTitle(t(title));
		}

		window.scroll(0, 0);
	}, []);
}
