import { UserAuthStore } from "@v2/state";
import { UserAccount } from "@data-types";
import { UserAccountType } from "@common/constants/UserAccountType";

export function addCanBeSelected(
	wallets: UserAccount[],
	user: UserAuthStore
): SelectableWallets[] {
	const selectableWallets = wallets.map<SelectableWallets>(
		(wallet: SelectableWallets) => {
			if (
				wallet.abrv === UserAccountType.SportBettingAccountShop ||
				wallet.abrv === UserAccountType.CasinoAccount ||
				wallet.abrv === UserAccountType.LiveCasinoAccount ||
				wallet.abrv === UserAccountType.GigAccount
			) {
				wallet.canBeSelected = false;
				return wallet;
			}

			if (user.secondaryUser == null && user.user?.id != wallet.userId) {
				wallet.canBeSelected = false;
				return wallet;
			}

			wallet.canBeSelected = true;
			return wallet;
		}
	);
	return selectableWallets;
}

export type SelectableWallets = UserAccount & { canBeSelected: boolean };
