import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDisplayedAmount } from "@utils";

const itemClassName =
	"table--primary__body__data u-type--right u-type--wgt--medium";

export default observer(function AccountActivitySection(props: {
	userActivity?: {
		BettingAccountTypeAbrv: string;
		NumberOfTransactions: number;
		CurrencyDisplayCode: string;
		Withdrawal: number;
		Gain: number;
		Loss: number;
		Deposit: number;
	}[];
}) {
	const { userActivity } = props;

	const { t } = useTranslation();

	if (userActivity == null) {
		return null;
	}

	return (
		<>
			<table className="table--primary table--primary--bordered table--fixed u-mar--top--big">
				<thead className="table--primary__header table--primary__header--light">
					<tr className="table--primary__header__row">
						<th
							className="table--primary__header__data"
							colSpan={2}
						>
							{t<string>(
								"MEMBERSHIP.BETTING_ACTIVITY.TABLE_PAYMENT_TITLE"
							)}
						</th>
					</tr>
				</thead>

				{userActivity.map((userActivityItem, index) => {
					return (
						<tbody className="table--primary__body" key={index}>
							<tr className="table--primary__body__row">
								<th
									className="table--primary__header__data"
									colSpan={2}
								>
									<strong>
										{t<string>(
											`HEADER.${userActivityItem.BettingAccountTypeAbrv.toUpperCase()}`
										)}
									</strong>
								</th>
							</tr>
							<tr className="table--primary__body__row">
								<td className="table--primary__body__data">
									{t<string>(
										"MEMBERSHIP.BETTING_ACTIVITY.TABLE_NUMBER_OF_TRANSACTIONS"
									)}
								</td>
								<td className="table--primary__body__data u-type--right">
									{userActivityItem.NumberOfTransactions}
								</td>
							</tr>
							<tr className="table--primary__body__row">
								<td className="table--primary__body__data">
									{t<string>(
										"MEMBERSHIP.BETTING_ACTIVITY.TABLE_DEPOSIT"
									)}
								</td>
								<td
									className={classNames(itemClassName, {
										"u-color--ui--danger":
											userActivityItem.Deposit < 0,
									})}
								>
									{getDisplayedAmount(
										userActivityItem.Deposit,
										userActivityItem.CurrencyDisplayCode
									)}
								</td>
							</tr>
							<tr className="table--primary__body__row">
								<td className="table--primary__body__data">
									{t<string>(
										"MEMBERSHIP.BETTING_ACTIVITY.TABLE_WITHDRAWAL"
									)}
								</td>
								<td
									className={classNames(itemClassName, {
										"u-color--ui--success":
											userActivityItem.Withdrawal > 0,
									})}
								>
									{getDisplayedAmount(
										userActivityItem.Withdrawal,
										userActivityItem.CurrencyDisplayCode
									)}
								</td>
							</tr>
							<tr className="table--primary__body__row">
								<td className="table--primary__body__data">
									{t<string>(
										"MEMBERSHIP.BETTING_ACTIVITY.TABLE_GAIN"
									)}
								</td>
								<td
									className={classNames(itemClassName, {
										"u-color--ui--success":
											userActivityItem.Gain > 0,
									})}
								>
									{getDisplayedAmount(
										userActivityItem.Gain,
										userActivityItem.CurrencyDisplayCode
									)}
								</td>
							</tr>
							<tr className="table--primary__body__row">
								<td className="table--primary__body__data">
									{t<string>(
										"MEMBERSHIP.BETTING_ACTIVITY.TABLE_LOSS"
									)}
								</td>
								<td
									className={classNames(itemClassName, {
										"u-color--ui--danger":
											userActivityItem.Loss != 0,
									})}
								>
									{getDisplayedAmount(
										userActivityItem.Loss,
										userActivityItem.CurrencyDisplayCode
									)}
								</td>
							</tr>
						</tbody>
					);
				})}
			</table>
		</>
	);
});
