import { useRootAppStore } from "@hooks";
import { getCurrentCulture } from "@utils";
import { isWelcomeTextEnabled } from "@v2/helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function WelcomeInfo() {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();

	if (!isWelcomeTextEnabled) {
		return null;
	}

	return (
		<>
			<div className="popup__col col col-sml-12">
				<div className="message message--base">
					<div className="message__content u-padd--reset">
						<div className="message__title u-type--xmed">
							<i className="message__icon u-icon u-icon--lrg u-icon--age-restriction u-color--ui--warning u-mar--right--tny" />
							<span className="u-align--v--middle">
								{t("WELCOME_POPUP.RESPONSBILE_GAMING_TITLE")}
							</span>
						</div>
						<div className="message__text">
							{t("WELCOME_POPUP.RESPONSBILE_GAMING_HINT")}
						</div>
						<Link
							to={`/${getCurrentCulture()}/responsible-gaming`}
							className="at-welcomeModalBtnResponsible u-anchor u-anchor--primary u-anchor--underline has-arrow"
							onClick={rootStore.welcomeStore.hidePopup}
						>
							{t("WELCOME_POPUP.RESPONSBILE_GAMING_TITLE")}
						</Link>
					</div>
				</div>
			</div>

			<div className="popup__col col col-sml-12">
				<div className="message message--base">
					<div className="message__content u-padd--reset">
						<div className="message__title u-type--xmed">
							<i className="message__icon u-icon u-icon--lrg u-icon--limits u-mar--right--tny" />
							<span className="u-align--v--middle">
								{t("WELCOME_POPUP.LIMITS_TITLE")}
							</span>
						</div>
						<div className="message__text">
							{t("WELCOME_POPUP.LIMITS_HINT")}
						</div>
						<div className="message__text">
							<ul className="list list--disc">
								<li>
									<span>
										{t("WELCOME_POPUP.LIMITS_HINT_DEPOSIT")}
										:{" "}
									</span>
									<Link
										to={`/${getCurrentCulture()}/account-settings/my-limits`}
										className="at-welcomeModalBtnDepositLimit u-anchor u-anchor--primary u-anchor--underline has-arrow"
										onClick={
											rootStore.welcomeStore.hidePopup
										}
									>
										{t("WELCOME_POPUP.DEPOSIT_LIMIT")}
									</Link>
								</li>
								<li>
									{/* ima visible = false ne znam zasto ?? <div>{t("WELCOME_POPUP.LIMITS_HINT_LOGIN_TIME")}</div> */}
									<span className="message__text">
										{t("WELCOME_POPUP.LIMITS_HINT_GAMING")}:{" "}
									</span>
									<Link
										to={`/${getCurrentCulture()}/account-settings/my-limits`}
										className="at-welcomeModalBtnGamingLimit u-anchor u-anchor--primary u-anchor--underline has-arrow"
										onClick={
											rootStore.welcomeStore.hidePopup
										}
									>
										{t("WELCOME_POPUP.GAMING_LIMITS")}
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="popup__col col col-sml-12">
				<div className="message message--base">
					<div className="message__content u-padd--reset">
						<div className="message__title u-type--xmed">
							<i className="message__icon u-icon u-icon--lrg u-icon--gaming-wins u-mar--right--tny" />
							<span className="u-align--v--middle">
								{t("WELCOME_POPUP.GAMING_WINS&LOSES_TITLE")}
							</span>
						</div>
						<div className="message__text">
							{t("WELCOME_POPUP.GAMING_WINS&LOSES_HINT")}
						</div>
						<Link
							to={`/${getCurrentCulture()}/account-settings/gaming-summary`}
							className="at-welcomeModalBtnSumary u-anchor u-anchor--primary u-anchor--underline has-arrow"
							onClick={rootStore.welcomeStore.hidePopup}
						>
							{t("WELCOME_POPUP.GAMING_WINS&LOSES_TITLE")}
						</Link>
					</div>
				</div>
			</div>

			<div className="popup__col col col-sml-12">
				<div className="message message--base">
					<div className="message__content u-padd--reset">
						<div className="message__title u-type--xmed">
							<i className="message__icon u-icon u-icon--lrg u-icon--exclusion u-mar--right--tny" />
							<span className="u-align--v--middle">
								{t("WELCOME_POPUP.EXCLUSION_TITLE")}
							</span>
						</div>
						<div className="message__text">
							{t("WELCOME_POPUP.EXCLUSION_HINT")}
						</div>
						<div className="message__text">
							<ul className="list list--disc">
								<li>
									<span>
										{t(
											"WELCOME_POPUP.EXCLUSION_HINT_PROVIDER"
										)}
										:{" "}
									</span>
									<Link
										to={`/${getCurrentCulture()}/account-settings/account-lock`}
										className="at-welcomeModalBtnExclusion u-anchor u-anchor--primary u-anchor--underline has-arrow"
										onClick={
											rootStore.welcomeStore.hidePopup
										}
									>
										{t("WELCOME_POPUP.TIPWIN_EXCLUSION")}
									</Link>
								</li>
								<li>
									<span>
										{t(
											"WELCOME_POPUP.EXCLUSION_HINT_GLOBALLY"
										)}
										:{" "}
									</span>
									<a
										href="https://spelpaus.se"
										className="at-welcomeModalBtnSpelpaus u-anchor u-anchor--primary u-anchor--underline has-arrow"
										onClick={
											rootStore.welcomeStore.hidePopup
										}
									>
										{/* hardkodano i na v1 */}
										Spelpaus
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
