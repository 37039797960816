import React, { FC, useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { WithdrawalContext } from "@context";
import { RawFilterData } from "@data-types";
import { Pager } from "@v2/components/desktop/shared/inputs";
import {
	WithdrawalCustomPeriodInput,
	WithdrawalPaymentMethodFilterSection,
	WithdrawalPeriodsFilterSection,
	WithdrawalTransactionStatusFilterSection,
	MyWithdrawalsTable,
} from "./components";
import { Modal } from "@lib/modal";
import { getCurrentCulture } from "@utils";
import { useAppContext } from "@v2/hooks";
import { NavLink } from "react-router-dom";
import Button from "@components/buttons/Button";

export const MyWithdrawalsBody: FC = observer(function MyWithdrawalsBody() {
	const {
		disposeCurrentWithdrawalState,
		pageSize,
		totalItems,
		withdrawalFilterStore: {
			setRawFilterData,
			setDefaultFilterData,
			pageNumber,
			rawData,
		},
		isStoreInitialized,
		isLoadingProcess,
	} = useContext(WithdrawalContext);
	const methods = useForm();
	const { handleSubmit, reset } = methods;

	const { t } = useTranslation();

	useEffect(() => {
		if (location.search !== "" && isStoreInitialized) {
			const params = App.state.getUrlParamsAsObj<RawFilterData>();
			reset(params);
			onSubmit(params);
		} else {
			setRawFilterData({
				period: "today",
				pageNumber: 1,
				paymentMethodId: "all",
				transactionStatusId: "all",
			});
			reset({
				period: "today",
				paymentMethodId: "all",
				transactionStatusId: "all",
			});
		}

		return () => {
			disposeCurrentWithdrawalState();
			setDefaultFilterData();
		};
	}, [isStoreInitialized]);

	const onSubmit = (data: RawFilterData) => {
		setRawFilterData({
			...data,
		});
	};

	const onPageChange = (pageNumber: number) => {
		setRawFilterData({
			...rawData,
			pageNumber: pageNumber,
		});
	};

	return (
		<>
			<FormProvider {...methods}>
				<form
					className="at-myWithdrawals card--primary"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="card--primary__header">
						<h1 className="card--primary__title title--primary">
							{t<string>("WITHDRAWAL.TITLE")}
						</h1>
					</div>

					<WithdrawalTabSwitcher />

					<div className="card--primary__body">
						<WithdrawalPeriodsFilterSection />

						<WithdrawalCustomPeriodInput />

						<WithdrawalPaymentMethodFilterSection />

						<WithdrawalTransactionStatusFilterSection />

						<Button
							type="submit"
							className="btn btn--lrg btn--primary"
							btnText={t<string>("WITHDRAWAL.SUBMIT_BUTTON")}
							isDisabled={isLoadingProcess}
						></Button>
					</div>
				</form>
			</FormProvider>

			<MyWithdrawalsTable />

			<Pager
				pageNumber={pageNumber}
				pageSize={pageSize}
				totalItems={totalItems}
				onPageChange={onPageChange}
				withNumberInput
			/>
			<TransactionCancelConfirmModal />
		</>
	);
});

const TransactionCancelConfirmModal: FC = observer(
	function TransactionCancelConfirmModal() {
		const {
			showConfirmationModal,
			setShowConfirmationModal,
			cancelWithdrawalTransaction,
			disableCancelConfirmationTransaction,
		} = useContext(WithdrawalContext);
		const { t } = useTranslation();

		if (showConfirmationModal === false) {
			return null;
		}

		const onExitTransactionCancel = () => {
			setShowConfirmationModal(false);
		};

		const onConfirmTransactionCancel = () => {
			cancelWithdrawalTransaction();
		};

		return (
			<div className="popup">
				<div className="popup__card">
					<div className="popup__header">
						{t("WITHDRAWAL.CONFIRMATION_LABEL")}
					</div>
					<div className="popup__body">
						{t("WITHDRAWAL.CANCEL_CONFIRM")}
					</div>
					<div className="popup__footer">
						<button
							className="popup__footer__btn btn btn--sml btn--secondary--inverted"
							type="button"
							disabled={disableCancelConfirmationTransaction}
							onClick={onExitTransactionCancel}
						>
							{t("COMMON.CANCEL")}
						</button>
						<button
							className="popup__footer__btn btn btn--sml btn--primary"
							type="button"
							disabled={disableCancelConfirmationTransaction}
							onClick={onConfirmTransactionCancel}
						>
							{t("COMMON.CONFIRM")}
						</button>
					</div>
				</div>
			</div>
		);
	}
);

const WithdrawalTabSwitcher: FC = observer(function WithdrawalTabSwitcher() {
	const { t } = useTranslation();
	const { culture } = useAppContext();

	return (
		<div className="card--primary__tabs u-padd--x--reset">
			<nav className="tabs--primary">
				<NavLink
					className="tabs--primary__item"
					activeClassName="is-active"
					replace={true}
					to={`/${culture}/withdrawal`}
					isActive={(match, location) => {
						return (
							location.pathname ==
							`/${getCurrentCulture()}/withdrawal`
						);
					}}
				>
					{t<string>("WITHDRAWAL.NEW_WITHDRAWAL")}
				</NavLink>
				<NavLink
					to={`/${culture}/withdrawal/history`}
					className="tabs--primary__item"
					replace={true}
					activeClassName="is-active"
					isActive={(match, location) => {
						return (
							location.pathname ==
							`/${getCurrentCulture()}/withdrawal/history`
						);
					}}
				>
					{t<string>("WITHDRAWAL.MY_WITHDRAWALS")}
				</NavLink>
			</nav>
		</div>
	);
});
