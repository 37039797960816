import React from "react";
import { useAppContext, useUser } from "@v2/hooks/shared";
import { useTranslation } from "react-i18next";
import { Button } from "../../shared/buttons";
import { observer } from "mobx-react";
import { OnBoardingSlider } from "@components-desktop/membership/shared";

export default observer(function AccountActivationError(props: PropTypes) {
	const { t } = useTranslation();

	if (props.activationError == null) {
		return null;
	}

	const { culture } = useAppContext();
	const user = useUser();

	const handleErrorResponse = () => {
		user.removeUserFromLocalStorage();
		App.state.history.push(`/${culture}/home/full/highlights`);
		props.clearAccountActivationError();
	};

	return (
		<>
			<div className="at-accountActivationError card--message">
				<OnBoardingSlider />
				<div className="card--message__body">
					<div className="card--message__content">
						<i className="card--message__icon u-icon u-icon--xlrg u-icon--remove-circle u-icon--animated--bounce-in u-color--ui--danger" />
						<div className="card--message__title">
							{t<string>(
								"MEMBERSHIP.ACCOUNT_ACTIVATION.ERROR_HAS_OCCURED"
							)}
						</div>
						<div className="u-type--left">
							{t<string>(
								"MEMBERSHIP.ACCOUNT_ACTIVATION.ERROR_MESSAGE_ACCOUNT_ACTIVATION"
							)}
						</div>
					</div>
					<div className="card--message__footer">
						<Button
							className="card--message__cta btn--lrg btn--primary"
							btnText={t("COMMON.OK_BTN")}
							onClick={handleErrorResponse}
						/>
					</div>
				</div>
			</div>
		</>
	);
});

type PropTypes = {
	activationError: boolean | null;
	clearAccountActivationError: () => void;
};
