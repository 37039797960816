import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
	FormProvider,
	useForm,
	useFormContext,
	useWatch,
} from "react-hook-form";

import classNames from "classnames";

import { AccountTypes, setTabTitle } from "@utils";

import { useAppContext } from "@v2/hooks";
import { useMyBonuses } from "@v2/hooks/myBonuses";

import { Button } from "@components/buttons";

import {
	UserBonusInfoREST,
	BonusStatusEnum,
	HistoryStatus,
	BonusTabs,
	MarketplaceBonusStatus,
} from "@api-types/myBonuses/Bonus";

import {
	BonusHistory,
	BonusMessage,
	VoucherBonusForm,
	UsedBonusMessage,
} from "./components";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";
import { useUser } from "@common/hooks";
import { RadioInput } from "@v2/components/desktop/shared/inputs";
import { useLoading, useRootAppStore } from "@hooks";
import { Modal } from "@lib/modal/Modal";
import { WebBonusesApiService } from "@api-services/myBonus/WebBonusesApiService";
import UseMarketplaceBonusConfirmPopup from "@v2/components/desktop/shared/popups/UseMarketplaceBonusConfirmPopup";
import { AircashMarketplaceBonusLabel } from "@common/constants/BonusConstant";

export default function MyBonusesPage() {
	const { t } = useTranslation();
	const [page, setPage] = useState<BonusTabs>(BonusTabs.bonus);
	const [marketplaceBonusStatusData, setMarketplaceBonusStatusData] =
		useState<MarketplaceBonusStatus | null>(null);
	const { status, history, fetchData, activateVoucher, setIsInput, isInput } =
		useMyBonuses();
	const user = useUser();

	const fetchMarketplaceBonusStatusData = async () => {
		if (user.user != null) {
			try {
				const response =
					await WebBonusesApiService.getMarketplaceBonusStatusAndData(
						user.isPrimaryUserOnline
							? user.user.id
							: (user.secondaryUser?.id as string),
						user.getUserAccountType(AccountTypes.ONLINE)
					);

				setMarketplaceBonusStatusData(response);
			} catch (err) {
				console.error(err);
				setMarketplaceBonusStatusData(null);
			}
		}
	};

	useEffect(() => {
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.MY_BONUSES"));

		window.scrollTo(0, 0);
	}, []);

	const methods = useForm({
		mode: "onChange",
		shouldUnregister: true,
		defaultValues: {
			userTypes: user?.isInternetUser
				? AccountTypes.ONLINE.toString()
				: AccountTypes.OFFLINE.toString(),
		},
	});

	return (
		<div className="l--content__main">
			<div className="container">
				<FormProvider {...methods}>
					<div className="card--primary">
						<div className="card--primary__header">
							<div className="card--primary__title title--primary">
								{t<string>(
									"USER.ACCOUNT_SETTINGS.NAVIGATION.MY_BONUSES"
								)}
							</div>
							{WEBPACK_IS_DK_MW || user.secondaryUser == null ? (
								<></>
							) : (
								<>
									<div className="card--primary__filter__item">
										<RadioInput
											label="USER.ACCOUNT_SETTINGS.MY_BONUSES.ONLINE_WALLET"
											name="userTypes"
											id="internet-account"
											value={AccountTypes.ONLINE}
											labelClassname="u-display--ib"
											className="at-OnlineWalletId input--radio"
											iconClass="u-icon u-icon--med u-icon--wallet--online u-align--v--middle"
											defaultChecked={AccountTypes.ONLINE}
										/>
									</div>
									<div className="card--primary__filter__item">
										<RadioInput
											label="USER.ACCOUNT_SETTINGS.MY_BONUSES.SHOP_ONLINE_WALLET"
											name="userTypes"
											id="shop-account"
											value={AccountTypes.OFFLINE}
											labelClassname="u-display--ib"
											className="at-OnlineWalletId input--radio"
											iconClass="u-icon u-icon--med u-icon--wallet--sport-betting-account-shop-online u-align--v--middle"
										/>
									</div>
								</>
							)}
						</div>

						<MyBonusesTabs
							page={page}
							history={history}
							setPage={setPage}
							setIsInput={setIsInput}
							fetchData={fetchData}
							fetchMarketplaceBonusStatusData={
								fetchMarketplaceBonusStatusData
							}
						/>

						<Body
							status={status}
							history={history}
							activateVoucher={activateVoucher}
							page={page}
							isInput={isInput}
							setIsInput={setIsInput}
							marketplaceBonusStatusData={
								marketplaceBonusStatusData
							}
							fetchData={fetchData}
						/>
					</div>
				</FormProvider>
			</div>
		</div>
	);
}

function MyBonusesTabs({
	page,
	history,
	setPage,
	setIsInput,
	fetchData,
	fetchMarketplaceBonusStatusData,
}: {
	page: BonusTabs;
	history: HistoryStatus[] | null;
	setPage: Function;
	setIsInput: Function;
	fetchData: Function;
	fetchMarketplaceBonusStatusData: Function;
}) {
	const { t } = useTranslation();
	const user = useUser();
	const { isLoading } = useLoading();

	const { getValues } = useFormContext();

	const defaultUserType =
		getValues("userTypes") == undefined
			? user?.isInternetUser
				? AccountTypes.ONLINE.toString()
				: AccountTypes.OFFLINE.toString()
			: Number(getValues("userTypes"));

	useWatch({ name: "userTypes" });

	useEffect(() => {
		const fetchBonusData = async () => {
			if (
				defaultUserType === AccountTypes.ONLINE ||
				defaultUserType === "1"
			) {
				await fetchMarketplaceBonusStatusData();
			}
			return await fetchData(Number(getValues("userTypes")));
		};
		setPage(BonusTabs.bonus);
		fetchBonusData();
	}, [defaultUserType]);

	if (isLoading) {
		return null;
	}

	if (history == null || !history.length) {
		return null;
	}

	const tabBonusClasses = classNames("tabs--primary__item", {
		"is-active": page == BonusTabs.bonus,
	});

	const tabHistoryClasses = classNames("tabs--primary__item", {
		"is-active": page == BonusTabs.history,
	});

	return (
		<div className="card--primary__tabs u-padd--reset">
			<div className="tabs--primary">
				<button
					type="button"
					className={tabBonusClasses}
					onClick={() => {
						setPage(BonusTabs.bonus);
						setIsInput(false);
					}}
				>
					{t("USER.ACCOUNT_SETTINGS.MY_BONUSES.SPORT_BETTING_BONUS")}
				</button>

				<button
					type="button"
					className={tabHistoryClasses}
					onClick={() => {
						setPage(BonusTabs.history);
						setIsInput(false);
					}}
				>
					{t("USER.ACCOUNT_SETTINGS.MY_BONUSES.HISTORY_MAIN_TITLE")}
				</button>
			</div>
		</div>
	);
}

function Body(props: {
	status: UserBonusInfoREST | null;
	history: HistoryStatus[] | null;
	activateVoucher: Function;
	page: BonusTabs;
	isInput: boolean;
	setIsInput: Function;
	marketplaceBonusStatusData: MarketplaceBonusStatus | null;
	fetchData: Function;
}) {
	const { isLoading } = useLoading();

	if (isLoading) {
		return null;
	}

	if (props.isInput) {
		return (
			<VoucherBonusForm
				isInput={props.isInput}
				setIsInput={props.setIsInput}
				activateVoucher={props.activateVoucher}
			/>
		);
	}

	return (
		<div className="card--primary__body">
			<MyBonusesSwitch
				status={props.status}
				setIsInput={props.setIsInput}
				page={props.page}
				marketplaceBonusStatusData={props.marketplaceBonusStatusData}
				fetchData={props.fetchData}
			/>
			<BonusHistory history={props.history} page={props.page} />
		</div>
	);
}

function MyBonusesSwitch({
	status,
	page,
	setIsInput,
	marketplaceBonusStatusData,
	fetchData,
}: {
	status: UserBonusInfoREST | null;
	page: BonusTabs;
	setIsInput: Function;
	marketplaceBonusStatusData: MarketplaceBonusStatus | null;
	fetchData: Function;
}) {
	const { getValues } = useFormContext();

	if (page !== BonusTabs.bonus) {
		return null;
	}

	if (status == null) {
		return (
			<BonusMessage
				message={
					"USER.ACCOUNT_SETTINGS.MY_BONUSES.TEMPORARY_UNVAILABLE"
				}
			/>
		);
	}

	if (
		status.bonusStatus == BonusStatusEnum.CanGetBonus &&
		status.isUserValid == false
	) {
		return (
			<>
				<BonusMessage
					message={"USER.ACCOUNT_SETTINGS.MY_BONUSES.BONUS_AVAILABLE"}
					verified={"BONUS_USER_NOT_VERIFIED"}
				/>

				{status.isDepositBonusAvailable == true && (
					<BonusCard
						bonusLimits={status.depositBonus}
						title={"DEPOSIT_BONUS"}
						description={"DEPOSIT_BONUS_VERIFICATION"}
						route={"account-settings/account-verification"}
						buttonTxt={"ACCOUNT_VERIFICATION"}
						isDepositBonusAvailable={true}
					/>
				)}

				{status.isVoucherBonusAvailable == true && (
					<BonusCard
						bonusLimits={status.voucherBonus}
						title={"VOUCHER_BONUS"}
						description={"VOUCHER_BONUS_VERIFICATION"}
						route={"account-settings/account-verification"}
						buttonTxt={"ACCOUNT_VERIFICATION"}
						isVoucherBonusAvailable={true}
					/>
				)}
			</>
		);
	}
	if (
		status.bonusStatus == BonusStatusEnum.CanGetBonus &&
		status.isUserValid == true
	) {
		return (
			<>
				<BonusMessage
					message={"USER.ACCOUNT_SETTINGS.MY_BONUSES.BONUS_AVAILABLE"}
				/>

				{status.isDepositBonusAvailable == true && (
					<BonusCard
						bonusLimits={status.depositBonus}
						title={"DEPOSIT_BONUS"}
						description={"DEPOSIT_BONUS_DESCRIPTION"}
						route={"deposit"}
						buttonTxt={"DEPOSIT"}
						isDepositBonusAvailable={true}
						status={status}
					/>
				)}

				{status.isVoucherBonusAvailable == true && (
					<VoucherBonusInfo
						bonusLimits={status.voucherBonus}
						setIsInput={setIsInput}
					/>
				)}

				{Number(getValues("userTypes")) == AccountTypes.ONLINE &&
				marketplaceBonusStatusData != null &&
				marketplaceBonusStatusData.bonusInfo != null &&
				marketplaceBonusStatusData.shouldOfferBonus ? (
					<MarketplaceBonus
						bonusLimits={status.depositBonus}
						description={"MARKETPLACE_BONUS_DESCRIPTION"}
						route={"deposit"}
						isDepositBonusAvailable={true}
						status={status}
						fetchData={fetchData}
						marketplaceBonusStatusData={marketplaceBonusStatusData}
					/>
				) : null}
			</>
		);
	}
	if (status.bonusStatus == BonusStatusEnum.ActiveBonus) {
		return <UsedBonusMessage status={status} />;
	}

	return <NoActiveBonusMessage />;
}

function MarketplaceBonus(props: {
	bonusLimits:
		| UserBonusInfoREST["depositBonus"]
		| UserBonusInfoREST["voucherBonus"];
	route: string;
	description: string;
	isDepositBonusAvailable?: boolean;
	isVoucherBonusAvailable?: boolean;
	status?: UserBonusInfoREST | null;
	fetchData: Function;
	marketplaceBonusStatusData: MarketplaceBonusStatus | null;
}) {
	const { t } = useTranslation();
	const { getValues } = useFormContext();
	const [popupState, setPopupState] = useState<boolean>(false);
	const userStore = useRootAppStore().userAuthStore;

	return (
		<>
			{popupState ? (
				<Modal>
					<UseMarketplaceBonusConfirmPopup
						hidePopup={() => setPopupState(false)}
						sendConfirmation={async (value: boolean) =>
							await userStore.sendMyBonusesUseBonusConfirmation(
								value
							)
						}
						fetchData={async () => {
							await props.fetchData(
								Number(getValues("userTypes"))
							);
						}}
					/>
				</Modal>
			) : null}
			<div className="card--bonus">
				{props.status?.isDepositBonusAvailable == true &&
					Number(getValues("userTypes")) == AccountTypes.OFFLINE && (
						<InfoMessageComponent
							type="warning"
							message={t(
								"USER.ACCOUNT_SETTINGS.MY_BONUSES.MAKE_TERMINAL_PAYMENT"
							)}
							textClass="message__text"
							addClassName="u-mar--bottom--med"
						/>
					)}
				<div className="card--bonus__header">
					<i className="u-icon u-icon--xxlrg u-icon--marketplace-bonus--alt" />
					<div className="card--bonus__title">
						<div className="u-type--xmed">
							{AircashMarketplaceBonusLabel}
						</div>
						<div className="u-type--base u-color--text--secondary">
							{t<string>(
								`USER.ACCOUNT_SETTINGS.MY_BONUSES.MARKETPLACE_BONUS.${props.description}`,
								{
									0: props.marketplaceBonusStatusData
										?.bonusInfo?.bonusAmount,
									1: props.marketplaceBonusStatusData
										?.bonusInfo?.currencyDisplayCode,
								}
							)}
						</div>
					</div>
					{Number(getValues("userTypes")) == AccountTypes.ONLINE && (
						<button
							className="card--bonus__btn btn btn btn--sml btn--primary"
							onClick={() => setPopupState(true)}
						>
							{t<string>(`COMMON.APPLY_BUTTON`)}
						</button>
					)}
				</div>
			</div>
		</>
	);
}

function BonusCard(props: {
	bonusLimits:
		| UserBonusInfoREST["depositBonus"]
		| UserBonusInfoREST["voucherBonus"];
	title: string;
	route: string;
	description: string;
	buttonTxt: string;
	isDepositBonusAvailable?: boolean;
	isVoucherBonusAvailable?: boolean;
	status?: UserBonusInfoREST | null;
}) {
	const { t } = useTranslation();
	const { culture } = useAppContext();
	const { getValues } = useFormContext();

	const iconClasses = classNames("u-icon u-icon--xxlrg", {
		"u-icon--deposit--alt": props.isDepositBonusAvailable,
		"u-icon--my-bonuses--alt": props.isVoucherBonusAvailable,
	});

	return (
		<div className="card--bonus">
			{props.status?.isDepositBonusAvailable == true &&
				Number(getValues("userTypes")) == AccountTypes.OFFLINE && (
					<InfoMessageComponent
						type="warning"
						message={t(
							"USER.ACCOUNT_SETTINGS.MY_BONUSES.MAKE_TERMINAL_PAYMENT"
						)}
						textClass="message__text"
						addClassName="u-mar--bottom--med"
					/>
				)}
			<div className="card--bonus__header">
				<i className={iconClasses} />
				<div className="card--bonus__title">
					<div className="u-type--xmed">
						{t<string>(
							`USER.ACCOUNT_SETTINGS.MY_BONUSES.${props.title}`
						)}
					</div>
					<div className="u-type--base u-color--text--secondary">
						{t<string>(
							`USER.ACCOUNT_SETTINGS.MY_BONUSES.${props.description}`,
							{
								0: props.bonusLimits?.bonusPercentage,
								1: props.bonusLimits?.maxBonusAmount,
								2: props.bonusLimits?.currencyDisplayCode,
							}
						)}
					</div>
				</div>
				{Number(getValues("userTypes")) == AccountTypes.ONLINE && (
					<NavLink
						to={`/${culture}/${props.route}`}
						className="card--bonus__btn btn btn btn--sml btn--primary"
					>
						{t<string>(
							`USER.ACCOUNT_SETTINGS.NAVIGATION.${props.buttonTxt}`
						)}
					</NavLink>
				)}
			</div>
		</div>
	);
}

function VoucherBonusInfo(props: {
	setIsInput: Function;
	bonusLimits: UserBonusInfoREST["voucherBonus"];
}) {
	const { t } = useTranslation();

	const iconClasses = classNames(
		"u-icon u-icon--xxlrg u-icon--my-bonuses--alt"
	);

	return (
		<div className="card--bonus">
			<div className="card--bonus__header">
				<i className={iconClasses} />
				<div className="card--bonus__title">
					<div className="u-type--xmed">
						{t<string>(
							`USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_BONUS`
						)}
					</div>
					<div className="u-type--base u-color--text--secondary">
						{t<string>(
							`USER.ACCOUNT_SETTINGS.MY_BONUSES.OVERVIEW_VOUCHER_BONUS`,
							{
								0: props.bonusLimits?.maxBonusAmount,
								1: props.bonusLimits?.currencyDisplayCode,
							}
						)}
					</div>
				</div>
				<Button
					type="button"
					className="card--bonus__btn btn--sml btn--primary"
					btnText="USER.ACCOUNT_SETTINGS.MY_BONUSES.REDEEM_VOUCHER"
					onClick={() => props.setIsInput(true)}
				/>
			</div>
		</div>
	);
}

function NoActiveBonusMessage() {
	const { t } = useTranslation();

	return (
		<div>
			<InfoMessageComponent
				type="note"
				message={t<string>(
					`USER.ACCOUNT_SETTINGS.MY_BONUSES.NO_ACTIVE`
				)}
				textClass="message__text"
			/>
		</div>
	);
}
