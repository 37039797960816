import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { MyBetsStoreContext, MyBetsBetContext } from "../../../../context";
import { BetCombinationItem } from ".";
import { useFixBody } from "@v2/hooks/shared";

export default observer(function BetCombinations(props: {
	setShowCombinations: (v: boolean) => void;
	showCombinations: boolean;
}) {
	const bet = useContext(MyBetsBetContext);
	// @ts-expect-error
	const { initBetCombinationForBetSlip, betCombinationsMap } =
		useContext(MyBetsStoreContext);

	useEffect(() => {
		initBetCombinationForBetSlip(bet.id);
	}, []);

	useFixBody(true, props.showCombinations);

	if (
		!betCombinationsMap.has(bet.id) ||
		betCombinationsMap.get(bet.id) == null
	) {
		return (
			<div className="popup">
				<div className="popup__card">
					<BetCombinationsHeader
						setShowCombinations={props.setShowCombinations}
					/>
					<div className="popup__body">
						<div className="loader__spinner loader__spinner--primary" />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="popup">
			<div className="popup__card">
				<BetCombinationsHeader
					setShowCombinations={props.setShowCombinations}
				/>
				<BetCombinationsBody />
				{/* <BetCombinationsFooter
					setShowCombinations={setShowCombinations}
				/> */}
			</div>
		</div>
	);
});

const BetCombinationsHeader = observer(function BetCombinationsHeader(props: {
	setShowCombinations: (v: boolean) => void;
}) {
	const { setShowCombinations } = props;

	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);

	return (
		<div className="popup__header">
			<div className="popup__header__title u-type--base">
				{t<string>("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.TITLE", {
					0: bet.betSlipNumber,
				})}
			</div>
			<button
				className="btn btn--tny btn--square--tny btn--secondary--inverted btn--icon btn--animate-grow"
				type="button"
				onClick={() => setShowCombinations(false)}
				title={t("COMMON.CLOSE")}
			>
				<i className="u-icon u-icon--xsml u-icon--remove" />
			</button>
		</div>
	);
});

const BetCombinationsBody = observer(function BetCombinationsBody() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);
	// @ts-expect-error
	const { betCombinationsMap } = useContext(MyBetsStoreContext);

	const combinations = betCombinationsMap.get(bet.id);

	return (
		<div className="popup__body">
			<table className="table--primary">
				<thead className="table--primary__header table--primary__header--light">
					<tr className="table--primary__header__row">
						<th className="table--primary__header__data u-type--center">
							{t(
								"MY_BETS.ITEM.BET_SLIP_COMBINATIONS.ORDINAL_NUMBER"
							)}
						</th>
						<th className="table--primary__header__data u-type--center">
							{t(
								"MY_BETS.ITEM.BET_SLIP_COMBINATIONS.COMBINATION"
							)}
						</th>
						<th className="table--primary__header__data u-type--center">
							{t(
								"MY_BETS.ITEM.BET_SLIP_COMBINATIONS.TOTAL_QUOTA"
							)}
						</th>
					</tr>
				</thead>
				<tbody className="table--primary__body">
					{combinations != null &&
						combinations.length > 0 &&
						// @ts-expect-error
						combinations.map((combination, index) => (
							<BetCombinationItem
								combination={combination}
								key={index}
								ordinalNumber={index + 1}
							/>
						))}
				</tbody>
			</table>
			<div className="u-mar--top--xsml">
				<div className="tag tag--med tag--bet--open u-display--ib u-mar--right--xsml">
					{t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.OPEN")}
				</div>
				<div className="tag tag--med tag--bet--won u-display--ib u-mar--right--xsml">
					{t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.WON")}
				</div>
				<div className="tag tag--med tag--bet--lost u-display--ib u-mar--right--xsml">
					{t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.LOST")}
				</div>
				<div className="tag tag--med tag--bet--cancelled u-display--ib u-mar--right--xsml">
					{t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.CANCELLED")}
				</div>
				<div className="tag tag--med tag--bet--returned u-display--ib u-mar--right--xsml">
					{t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.RETURNED")}
				</div>
			</div>
		</div>
	);
});

// const BetCombinationsFooter = observer(function BetCombinationsFooter(props) {
// 	const { setShowCombinations } = props;

// 	const { t } = useTranslation();

// 	return (
// 		<div className="popup__footer">
// 			<button
// 				className="popup__footer__btn btn btn--tny btn--secondary--inverted"
// 				onClick={() => setShowCombinations(false)}
// 			>
// 				{t("COMMON.CLOSE")}
// 			</button>
// 		</div>
// 	);
// });
