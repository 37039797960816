import { observable, action, runInAction } from "mobx";

import BaseSearchBarViewStore from "./BaseSearchBarViewStore";
import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

class SearchBarViewStore extends BaseSearchBarViewStore {
	@observable public persistentTerm: string;
	@observable private period: string;

	constructor() {
		super({
			onTermChange: (val) => (this.persistentTerm = val),
		});

		this.onSubmit = this.onSubmit.bind(this);
	}

	@action.bound
	onTermChange(term: SearchBarViewStore["persistentTerm"]) {
		this.searchTerm = term;
	}

	async onSubmit(
		{
			event,
			period,
		}: {
			event: { preventDefault: () => void; stopPropagation: () => void };
			period: string;
		},
		submitFn?: (() => void) | null
	) {
		event.preventDefault();
		event.stopPropagation();

		if (this.trimSearchTerm === "") {
			return;
		}

		const isValid = (await lazy(loadFailPath, () => import("xregexp")))
			.default("^[\\p{L}0-9-. ]{4,20}$")
			.test(this.searchTerm);

		runInAction(() => {
			if (!isValid) {
				this.displayValidationPopup = true;
				return;
			} else {
				this.displayValidationPopup = false;
			}

			this.persistentTerm = this.trimSearchTerm;

			if (submitFn != null) {
				submitFn();
			} else {
				App.state.redirectPreserveSearchParams(
					`/${getCurrentCulture()}/search/${period}/${
						this.persistentTerm
					}`
				);
			}
		});
	}
}

export default SearchBarViewStore;
