import { useUser } from "@v2/hooks";
import React from "react";
import classNames from "classnames";

type WalletIcon_Props = {
	bettingAccountTypeAbrv?: string | null;
	classList?: string;
	iconSize?: string;
};

export const WalletIcon = function WalletIcon(props: WalletIcon_Props) {
	const user = useUser();

	const numberOfUsersWallets = user.userAccounts?.length;

	if (props.bettingAccountTypeAbrv === "" || numberOfUsersWallets == 1) {
		return null;
	}

	let iconClasses = classNames(
		"u-icon",
		{
			"u-icon--sml": props.iconSize == null,
		},
		props.iconSize,
		[`u-icon--wallet u-icon--wallet--${props.bettingAccountTypeAbrv}`],
		props.classList
	);

	return <i className={iconClasses} />;
};
