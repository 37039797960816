import React, { useEffect, useState, useRef } from "react";

export default function TotalStake(props: {
	value: number;
	onChange: (newValue: number) => void;
	disabled?: boolean;
	name?: string;
	className?: string;
	id?: string;
	step?: string;
	min?: number;
	type?: string;
}) {
	const [fieldValue, setFiledValue] = useState(props.value.toFixed(2));
	const debounceRef = useRef<ReturnType<typeof setTimeout> | undefined>(
		undefined
	);
	const currentValueRef = useRef<number>(props.value);
	useEffect(() => {
		if (props.value != parseFloat(fieldValue)) {
			setFiledValue(props.value.toFixed(2));
		}
	}, [props.value]);

	return (
		<input
			id={props.id}
			min={props.min}
			step={props.step}
			type={props.type}
			disabled={props.disabled}
			className={props.className}
			name={props.name}
			value={fieldValue}
			onChange={(e) => {
				const value = parseFloat(e.target.value);
				setFiledValue(e.target.value);

				if (!isNaN(value) && value > 0) {
					currentValueRef.current = value;
					clearTimeout(debounceRef.current);
					debounceRef.current = setTimeout(() => {
						props.onChange(currentValueRef.current);
						debounceRef.current = undefined;
					}, 200);
				}
			}}
			onBlur={() => {
				setFiledValue(props.value.toFixed(2));
				if (debounceRef.current != null) {
					clearInterval(debounceRef.current);
					debounceRef.current = undefined;
					props.onChange(currentValueRef.current);
				}
			}}
		/>
	);
}
