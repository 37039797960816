import React from "react";
export default function NotificationMessage({ message }: { message: string }) {
	if (message.indexOf("<p") > -1)
		return createParagraphs(message).map((val) => {
			return val.class ? (
				<p className={val.class}>{val.message}</p>
			) : (
				val.message
			);
		});
	return message;
}

function stringToHTML(str: string) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(str, "text/html");
	return doc.body;
}

function createParagraphs(str: string): { class?: string; message: string }[] {
	const paragraphList: { class?: string; message: string }[] = [];

	stringToHTML(str).childNodes.forEach((element) => {
		if (element.classList == undefined) {
			paragraphList.push({ message: element.textContent });
		} else {
			paragraphList.push({
				message: element.textContent,
				class: element.classList,
			});
		}
	});
	return paragraphList;
}
