import { DateTime } from "luxon";
import { observable, action, computed, runInAction } from "mobx";
import {
	AccountTypes,
	UserTypes,
	ISOToDisplayCode,
	getUserFromLocalStorage,
	getUserBettingAccountTypeId,
} from "@utils";
import { UserAccount } from "@data-types/user";
import {
	AccountVerificationStatus,
	LoginHistoryResponseModel,
} from "@api-types/user/LoginResponseDto";

import { localizationService, logger, SessionStorageProvider } from "@state";

import { BettingActivityStore, LoginStore } from ".";
import {
	LoginResponseUser,
	LoginResponseToken,
	LoginActivationResponseDto,
} from "@api-types/user/LoginResponseDto";
import KeepAliveService from "@services/KeepAliveService";
import { LogoutReason } from "@api-types/user/LoginRequestDto";
import { UserAccountType } from "@common/constants/UserAccountType";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

import { StorageStateKeysEnum } from "@utils/storage";
import { isCapacitorPlatform } from "@utils/specific/capacitor";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { isAccountVerificationSectionEnabled } from "@v2/helpers";
import { WebBonusesApiService } from "@api-services/myBonus/WebBonusesApiService";
import { BonusStatusEnum } from "@api-types/myBonuses/Bonus";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const UserService = lazy(loadFailPath, ()=>import("@services/user/UserService"))
// prettier-ignore
const UserDataApiService = lazy(loadFailPath, ()=>import("@api-services/user/UserDataApiService"))

export class UserAuthStore {
	private rootStore: typeof App.state.rootStore;
	private keepAliveInterval: number | undefined;
	bettingActivityStore: BettingActivityStore;

	@observable userAccounts: UserAccount[] | null = observable.array();
	@observable user: User | null = null;
	@observable secondaryUser: User | null = null;
	@observable token: UserToken | null = null;
	@observable secondaryToken: UserToken | null = null;

	@observable accountActivation: AccountActivation | null = null;
	@observable loginHistory: LoginHistoryResponseModel | null = null;
	@observable activeAccountTypeId: string | null = null;

	@observable lastTimeChangedTokenExpiration: string | null = null;
	@observable lastTimeChangedSecondaryTokenExpiration: string | null = null;

	@observable realityCheckTime: number | null = null;
	@observable autoLogoutPeriod: number | null = null;

	public loginStore: LoginStore;

	//#region user field getters

	// TODO: why would any method return boolean || enum value ???
	@computed public get activeUserWallet() {
		if (!this.isLoggedIn) {
			return false;
		}
		const userType = this.getWalletOwner(this.activeAccountTypeId);

		return userType;
	}

	@computed public get isUserCritical(): boolean {
		if (!this.isLoggedIn) {
			return false;
		}

		const onlineUser = getUserFromLocalStorage(
			this.isPrimaryUserOnline == true
				? UserTypes.PRIMARY
				: UserTypes.SECONDARY
		);

		if (this.isInternetUser && isAccountVerificationSectionEnabled) {
			if (
				onlineUser?.accountVerificationStatus !==
				AccountVerificationStatus.FullyVerified
			) {
				return true;
			}
		}

		if (this.secondaryUser) {
			if (onlineUser?.depositLimitUpdate) {
				return true;
			}
		} else if (this.user && this.user?.depositLimitUpdate) {
			return true;
		}

		const userType = this.getWalletOwner(this.activeAccountTypeId);

		const user =
			UserTypes.PRIMARY == userType ? this.user : this.secondaryUser;

		if (user?.isBettingDisabled) {
			return true;
		}

		return false;
	}

	@computed public get isUserWarning(): boolean {
		if (!this.isLoggedIn) {
			return false;
		}

		if (
			(this.user && this.user.userDocumentDateExpired) ||
			this.user?.userDocumentDateExpiresSoon ||
			this.user?.userDocumentDateNotSet
		) {
			return true;
		}

		if (
			(this.secondaryUser &&
				this.secondaryUser.userDocumentDateExpired) ||
			this.secondaryUser?.userDocumentDateExpiresSoon ||
			this.secondaryUser?.userDocumentDateNotSet
		) {
			return true;
		}

		return false;
	}

	@computed public get isBettingDisabledPerWallet(): boolean {
		if (!this.isLoggedIn) {
			return false;
		}

		const activeWalletUserId = this.userAccounts?.find(
			(wallet) => wallet.accountTypeId === this.activeAccountTypeId
		)?.userId;

		if (this.secondaryUser?.id === activeWalletUserId) {
			return this.secondaryUser?.isBettingDisabled ?? false;
		}
		return this.user?.isBettingDisabled ?? false;
	}

	@computed public get isRefreshButtonDisabled(): boolean {
		const lastRefresh = DateTime.fromISO(
			this.user?.lastRefreshTime as string
		).toUTC();

		const currentTime = DateTime.now().toUTC();

		return (
			lastRefresh.plus({
				hours: 1,
			}) >= currentTime
		);
	}

	@computed public get availableUserWallets() {
		const availableWallets = this.userAccounts?.filter(
			(wallet) =>
				wallet.userId === this.user?.id ||
				wallet.userId === this.secondaryUser?.id
		);
		return availableWallets
			?.filter(
				(wallet) =>
					wallet.abrv === UserAccountType.SportBettingAccountOnline ||
					wallet.abrv ===
						UserAccountType.SportBettingAccountShopOnline ||
					wallet.abrv === UserAccountType.SportBettingAccountShop ||
					wallet.abrv === UserAccountType.CasinoAccount ||
					wallet.abrv === UserAccountType.GigAccount ||
					wallet.abrv === UserAccountType.LiveCasinoAccount
			)
			.map((wallet) => wallet.accountTypeId);
	}

	@computed public get isAutoWithdrawalEnabled() {
		return (
			(this.user && this.user.isAutoWithdrawalEnabled) ||
			(this.secondaryUser && this.secondaryUser.isAutoWithdrawalEnabled)
		);
	}

	@computed public get isPrimaryUserOnline(): boolean {
		return this.user?.isInternetUser === true;
	}

	@computed public get isInternetUser(): boolean {
		return (
			(this.user != null && this.user.isInternetUser) ||
			(this.secondaryUser != null && this.secondaryUser.isInternetUser)
		);
	}

	@computed public get isLogoutDisabled(): boolean {
		return (
			this.rootStore.myBetsViewStore?.isCountdownVisible ||
			App.offer.rootStore.betSlipStore?.isCountdownVisible
		);
	}

	@computed public get agencyKey(): string {
		return (
			this.user?.agencyKey ||
			this.accountActivation?.agencyKey ||
			WEBPACK_DEFAULT_AGENCY_KEY
		);
	}
	@computed public get secondaryAgencyKey(): string {
		return this.secondaryUser?.agencyKey || WEBPACK_DEFAULT_AGENCY_KEY;
	}

	@computed public get shopId(): string {
		return this.user?.shopId || WEBPACK_DEFAULT_SHOP_ID;
	}
	@computed public get secondaryShopId(): string {
		return this.secondaryUser?.shopId || WEBPACK_DEFAULT_SHOP_ID;
	}

	@computed public get agencyId(): string {
		return this.user?.agencyId || this.accountActivation?.agencyId || "";
	}

	@computed public get secondaryAgencyId(): string {
		return this.secondaryUser?.agencyId || "";
	}

	@computed public get isLoggedIn(): boolean {
		return this.token != null && this.user != null;
	}

	/**
	 * True if there is any user data present. Not all observables are loaded at once.
	 * They are loaded one by one local storage key.
	 */
	@computed public get isPartiallyLoggedIn(): boolean {
		return (
			this.token != null ||
			this.user != null ||
			this.accountActivation != null
		);
	}

	@computed public get userWithDiffAgencyKey(): boolean {
		if (this.secondaryUser == null) {
			return false;
		}
		return this.user?.agencyKey !== this.secondaryUser?.agencyKey;
	}

	@computed public get currency(): string {
		if (this.user == null) {
			return ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY);
		} else {
			if (this.userAccounts != null) {
				const foundAccount = this.userAccounts.find(
					(acc) => acc.accountTypeId === this.activeAccountTypeId
				);

				if (foundAccount != null) {
					return foundAccount.currency.displayCode;
				}

				return ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY);
			}

			return ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY);
		}
	}

	@computed public get areCasinoWalletsAvailable(): boolean {
		const casinoWallet = this.userAccounts?.find(
			(acc) => acc.abrv === UserAccountType.CasinoAccount
		);
		const liveCasinoWallet = this.userAccounts?.find(
			(acc) => acc.abrv === UserAccountType.LiveCasinoAccount
		);

		return casinoWallet != null && liveCasinoWallet != null;
	}

	@computed public get recheckDueDateObj(): {
		now: Date;
		recheckDueDateUTC: Date;
		recheckDiffTimeStamp: number;
		recheckDiffDays: number;
	} {
		const onlineUser = this.getUserAccountType(AccountTypes.ONLINE);
		const user = getUserFromLocalStorage(onlineUser);
		const now = new Date();
		// @ts-expect-error
		const recheckDueDateUTC = new Date(user.recheckDueDate);
		// @ts-expect-error
		const recheckDiffTimeStamp = recheckDueDateUTC - now;
		const recheckDiffDays = Math.floor(
			recheckDiffTimeStamp / (24 * 60 * 60 * 1000)
		);
		return {
			now,
			recheckDueDateUTC,
			recheckDiffTimeStamp,
			recheckDiffDays,
		};
	}

	//#endregion user field getters

	constructor(rootStore: typeof App.state.rootStore) {
		this.rootStore = rootStore;

		// TODO: remove once all methods are in userService
		this.bettingActivityStore = new BettingActivityStore();
		this.loginStore = new LoginStore(this, logger);

		//document.addEventListener("click", this.handleKeepAlive);
		clearInterval(this.keepAliveInterval);
		this.keepAliveInterval = Number(
			setInterval(
				this.handleKeepAlive,
				WEBPACK_KEEP_ALIVE_INTERVAL * 1000
			)
		);
	}

	/* initialize user data  */
	@action.bound
	async onInitialize(): Promise<void> {
		this.loginStore.onInitialize();
		this.readFromLocalStorage(null);
		window.addEventListener("storage", this.storageActionListener, {});

		//if user accounts don't contain user id, refresh user balance
		if (
			this.user != null &&
			this.userAccounts != null &&
			this.userAccounts[0]?.userId == null
		) {
			await this.refreshUserBalance();
		}

		// secondary token expiration

		// if (this.secondaryToken != null) {
		// 	runInAction(() => {
		// 		const secondaryTokenExpirationDate =
		// 			this.lastTimeChangedSecondaryTokenExpiration == null
		// 				? 0
		// 				: new Date(
		// 						this.lastTimeChangedSecondaryTokenExpiration
		// 				  ).getTime();

		// 		// Subtract secondary token expiration date from current date
		// 		const calculatedDate =
		// 			secondaryTokenExpirationDate - new Date().getTime();

		// 		if (calculatedDate > 0) {
		// 			const calculatedTimeInSeconds = calculatedDate / 1000;
		// 			this.prolongSecondaryToken(calculatedTimeInSeconds);
		// 		}
		// 	});
		// }

		// user matching data preparation if user matching process is active
		await this.loginStore.checkUserMatchingState();

		// on initialize try to get user data from gig only IF user is NOT auth and if gig is enabled
		if (
			WEBPACK_IS_GIG_ENABLED &&
			this.user == null &&
			!isCapacitorPlatform()
		) {
			UserService.then((service) => {
				service.default.initGigGetUser().then((response) => {
					if (response) {
						this.login(response);
					}
				});
			});
		}
	}

	@action.bound
	syncToken(): void {
		if (this.user != null && this.token != null) {
			const currentDateTime = DateTime.utc().toMillis();
			const storageTokenTimeInMillis = DateTime.fromISO(
				this.token.expirationDate
			).toMillis();

			if (
				currentDateTime >= storageTokenTimeInMillis &&
				!App.state.rootStore.sessionExpireStore.isGameOpen
			) {
				this.removeUserFromLocalStorage();
			} else {
				this.setLastTimeChangedTokenExpiration(DateTime.utc().toISO());
				const newTokenExpirationDate = DateTime.utc().plus(
					this.token.slidingWindow * 1000
				);
				this.setTokenExpirationDate(
					newTokenExpirationDate.toISO() as string
				);
			}
		}
	}

	@action.bound
	removeUserFromLocalStorage(): void {
		if (this.rootStore.chatViewStore.chatClient) {
			//triggering this because of 401s, token expired, automatic logout, etc, where logout is not called
			this.rootStore.chatViewStore.updateChatOnLoginChange(false);
		}

		// clear state
		this.bettingActivityStore.bettingActivityFetchedData = null;
		this.bettingActivityStore.bettingActivityError = null;

		this.setUser(null);
		this.setToken(null);

		this.setSecondaryUser(null);
		this.setSecondaryToken(null);

		this.setAuthenticationToken(null);

		this.setLoginHistory(null);
		this.setUserAccounts(null);

		this.setLastTimeChangedTokenExpiration(null);
		this.setLastTimeChangedSecondaryTokenExpiration(null);

		this.setRealityCheckTime(null);
		this.setAutoLogoutPeriod(null);

		this.setActiveUserAccountTypeId(null);

		this.loginStore.setUserMatchingState(false);
		this.loginStore.setUserDataConfirmationState(false);
		this.loginStore.setUserConfirmationData(null);

		// clear local storage
		this.rootStore.localStorageProvider.remove(StorageStateKeysEnum.SLN);
		// this.rootStore.localStorageProvider.remove(StorageStateKeysEnum.OCB); in session currently
		this.removeInsicTokensFromLocalStorage();
	}

	@action.bound
	removeInsicTokensFromLocalStorage(): void {
		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			// Check if the key contains the word "avs"
			if (key?.includes("avs-")) {
				localStorage.removeItem(key);
			}
		}
	}

	@action.bound
	setAcceptGdprFlag(): void {
		runInAction(() => {
			if (this.user != null) {
				this.user.shouldAcceptGdpr = false;
				this.setUser(this.user);
			}
		});
	}

	@action.bound
	updateUserChatName(chatName: string): void {
		if (this.user == null) {
			return;
		}
		this.user.chatName = chatName;
		this.setUser(this.user);
	}

	@action.bound
	updateUserVerificationStatusToFullyVerified(isVerified: boolean): void {
		if (this.user == null) {
			return;
		}
		const onlineUser = this.isPrimaryUserOnline
			? UserTypes.PRIMARY
			: UserTypes.SECONDARY;

		const shouldUpdatePrimary =
			!this.secondaryUser || onlineUser === UserTypes.PRIMARY;

		if (
			isVerified &&
			this.user.accountVerificationStatus !==
				AccountVerificationStatus.FullyVerified
		) {
			if (shouldUpdatePrimary) {
				this.user.accountVerificationStatus =
					AccountVerificationStatus.FullyVerified;
				this.setUser(this.user);
			} else if (this.secondaryUser) {
				this.secondaryUser.accountVerificationStatus =
					AccountVerificationStatus.FullyVerified;
				this.setSecondaryUser(this.secondaryUser);
			}
		}

		if (
			!isVerified &&
			this.user.accountVerificationStatus ===
				AccountVerificationStatus.FullyVerified
		) {
			if (shouldUpdatePrimary) {
				this.user.accountVerificationStatus =
					AccountVerificationStatus.Unverified;
				this.setUser(this.user);
			} else if (this.secondaryUser) {
				this.secondaryUser.accountVerificationStatus =
					AccountVerificationStatus.Unverified;
				this.setSecondaryUser(this.secondaryUser);
			}
		}
	}

	@action.bound
	async checkBalanceEntry(): Promise<void> {
		if (this.user && this.activeAccountTypeId?.includes("object")) {
			await this.refreshUserBalance();
		}
	}

	@action.bound
	async refreshUserBalance(): Promise<void> {
		const response = await (
			await UserDataApiService
		).default.getUserAccounts();
		this.setUserAccounts(response);
	}

	@action.bound
	setBettingDisabled(isDisabled: boolean): void {
		if (this.secondaryUser) {
			this.secondaryUser.isBettingDisabled = isDisabled;
			this.setSecondaryUser(this.secondaryUser);
		}
		if (this.user) {
			this.user.isBettingDisabled = isDisabled;
			this.setUser(this.user);
		}
	}

	@action.bound
	login(userData: {
		primary: {
			user: User;
			token: UserToken;
			accountActivation?: AccountActivation | null;
			loginHistory: LoginHistoryResponseModel;
			userAccounts: UserAccount[];
		};
		secondary?: {
			user: User;
			token: UserToken;
		};
	}): void {
		// primary account
		this.setToken(userData.primary.token);

		this.setUser(userData.primary.user);
		this.setAuthenticationToken(userData.primary.accountActivation);

		if (!this.user?.isCustomWebRole) {
			this.setLoginHistory(userData.primary.loginHistory);

			this.rootStore.chatViewStore.updateChatOnLoginChange(
				this.isLoggedIn
			);
			//secondary account
			this.setSecondaryUser(userData.secondary?.user || null);

			this.setUserAccounts(userData.primary.userAccounts);
			this.setLastTimeChangedTokenExpiration(new Date().toISOString());

			// set expiration of secondary token and start timer for prolong token
			if (userData.secondary?.token != null) {
				this.setSecondaryToken(userData.secondary.token);
				// const currentDate = new Date();
				// this.setLastTimeChangedSecondaryTokenExpiration(
				// 	new Date(
				// 		currentDate.getTime() + WEBPACK_KEEP_ALIVE_INTERVAL * 1000
				// 	).toISOString()
				// );
				// this.prolongSecondaryToken(WEBPACK_KEEP_ALIVE_INTERVAL);
			}
		}

		if (userData.primary.user) {
			if (
				userData.primary.user.autoLogoutPeriod != null &&
				userData.primary.user.autoLogoutPeriod > 0
			) {
				const currentDate = new Date();
				this.setAutoLogoutPeriod(
					currentDate.getTime() +
						userData.primary.user.autoLogoutPeriod * 60 * 60 * 1000
				);
			}

			if (WEBPACK_IS_REALITY_CHECK_ENABLED) {
				const currentDate = new Date();
				const newRealityCheckTime =
					currentDate.getTime() +
					(userData.primary.user?.realityCheckDuration ||
						WEBPACK_REALITY_CHECK_INTERVAL) *
						60 *
						1000;
				this.setRealityCheckTime(newRealityCheckTime);
			}
		}

		if (App.offer.rootStore.betSlipStore.isSlipEmpty) {
			App.offer.rootStore.betSlipStore.onResetSlip();
		}

		this.rootStore.cashoutHubStore.initializeCashoutHub();

		this.rootStore.initClick();
		this.rootStore.welcomeStore.setShouldDisplay();
		if (userData.primary?.user != null) {
			AnalyticsService.setUserId(userData.primary.user.id);
		}
	}

	sortUserAccounts = (userAccounts: UserAccount[]): UserAccount[] => {
		const sortOrderOfAccounts = [
			UserAccountType.SportBettingAccountOnline,
			UserAccountType.SportBettingAccountShopOnline,
			UserAccountType.CashToDigitalAccount,
			UserAccountType.CasinoAccount,
			UserAccountType.LiveCasinoAccount,
			UserAccountType.SportBettingAccountShop,
			UserAccountType.GigAccount,
		];

		return userAccounts.sort(
			(a, b) =>
				sortOrderOfAccounts.indexOf(a.abrv) -
				sortOrderOfAccounts.indexOf(b.abrv)
		);
	};

	getUserTypeFromBettingAccountTypeId(bettingAccountTypeId: string) {
		if (
			this.secondaryUser?.id ===
			this.userAccounts?.find(
				(acc) => bettingAccountTypeId === acc.accountTypeId
			)?.userId
		) {
			return UserTypes.SECONDARY;
		} else {
			return UserTypes.PRIMARY;
		}
	}

	@action.bound
	async logoutUser(reasonsObj?: LogoutReason): Promise<void> {
		await this.rootStore.chatViewStore.updateChatOnLoginChange(false);
		await (await UserService).default.logoutUser(reasonsObj);
		this.removeUserFromLocalStorage();
		this.rootStore.welcomeStore.onDispose();
		SessionStorageProvider.remove("slip");
		if (this.rootStore) {
			App.offer.rootStore.betSlipStore.onResetSlip();
		}

		if (App.offer.rootStore.betSlipStore.deactivateOneClickBet) {
			App.offer.rootStore.betSlipStore.deactivateOneClickBet();
		}
		if (App.offer.rootStore.betSlipStore.resetBetSlipTypeToDefault) {
			App.offer.rootStore.betSlipStore.resetBetSlipTypeToDefault();
		}

		this.loginStore.isSSOLoginInitialized = false;
		AnalyticsService.setUserId(null);
		this.rootStore.cashoutHubStore.destroyConnectionOnUserLogout();
	}

	//#region prolong secondary token

	// private prolongSecondaryTokenTimer: NodeJS.Timer | null = null;
	// @action.bound
	// prolongSecondaryToken(expiresIn: number) {
	// 	this.clearProlongTimer();

	// 	this.prolongSecondaryTokenTimer = setTimeout(() => {
	// 		const currentDate = new Date();

	// 		if (this.isLoggedIn && this.secondaryToken != null) {
	// 			const newTokenExpirationDate = DateTime.utc().plus(
	// 				this.secondaryToken.slidingWindow * 1000
	// 			);
	// 			const newSecondaryTokenData = {
	// 				...this.secondaryToken,
	// 				expirationDate: newTokenExpirationDate.toISO(),
	// 			};
	// 			this.keepAlive(UserTypes.SECONDARY);
	// 			this.setSecondaryToken(newSecondaryTokenData);

	// 			//start timer again in 10 minutes or 30 seconds (depending on configuration param) and set time in local storage
	// 			this.setLastTimeChangedSecondaryTokenExpiration(
	// 				new Date(
	// 					currentDate.getTime() +
	// 						WEBPACK_KEEP_ALIVE_INTERVAL * 1000
	// 				).toISOString()
	// 			);
	// 			this.prolongSecondaryToken(WEBPACK_KEEP_ALIVE_INTERVAL);
	// 		}
	// 	}, expiresIn * 1000);
	// }

	// clearProlongTimer() {
	// 	if (this.prolongSecondaryTokenTimer != null) {
	// 		clearTimeout(this.prolongSecondaryTokenTimer);
	// 		this.prolongSecondaryTokenTimer = null;
	// 	}
	// }

	//#endregion

	handleKeepAlive = async (): Promise<void> => {
		if (this.isLoggedIn) {
			if (this.token != null) {
				await this.keepAlive(UserTypes.PRIMARY);
				this.syncToken();

				if (this.secondaryToken != null) {
					const currentDate = new Date();
					const newTokenExpirationDate = DateTime.utc().plus(
						this.secondaryToken.slidingWindow * 1000
					);
					const newSecondaryTokenData = {
						...this.secondaryToken,
						expirationDate:
							newTokenExpirationDate.toISO() as string,
					};
					this.keepAlive(UserTypes.SECONDARY);
					this.setSecondaryToken(newSecondaryTokenData);

					//start timer again in 10 minutes or 30 seconds (depending on configuration param) and set time in local storage
					this.setLastTimeChangedSecondaryTokenExpiration(
						new Date(
							currentDate.getTime() +
								WEBPACK_KEEP_ALIVE_INTERVAL * 1000
						).toISOString()
					);
				}
			}
		}
	};

	@action.bound
	async getMarketplaceBonusInfo() {
		try {
			const response = await WebBonusesApiService.getMarketplaceBonusInfo(
				this.getUserAccountType(AccountTypes.ONLINE)
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	}

	@action.bound
	async sendWelcomeUseBonusConfirmation(isBonusDeclined: boolean) {
		const shouldOfferBonus = this.isPrimaryUserOnline
			? this.user?.shouldOfferBonus
			: this.secondaryUser?.shouldOfferBonus;

		const bonusDeclineStatus = this.isPrimaryUserOnline
			? this.user?.isBonusDeclined
			: this.secondaryUser?.isBonusDeclined;

		if (!this.isInternetUser || !shouldOfferBonus || bonusDeclineStatus) {
			return;
		}

		await this.sendUseBonusConfirmation(isBonusDeclined);
	}

	@action.bound
	async sendMyBonusesUseBonusConfirmation(isBonusDeclined: boolean) {
		if (!this.isInternetUser) {
			return;
		}

		await this.sendUseBonusConfirmation(isBonusDeclined);
	}

	@action.bound
	async sendUseBonusConfirmation(isBonusDeclined: boolean) {
		try {
			const responseData = await WebBonusesApiService.useMarketplaceBonus(
				this.activeAccountTypeId as string,
				this.isPrimaryUserOnline
					? (this.user?.id as string)
					: (this.secondaryUser?.id as string),
				isBonusDeclined,
				this.getUserAccountType(AccountTypes.ONLINE)
			);

			if (
				!isBonusDeclined &&
				responseData != null &&
				responseData.bonusStatusEnum === BonusStatusEnum.CanGetBonus &&
				responseData.isSuccess
			) {
				App.state.rootStore.notificationStore.success(
					localizationService.t(
						"USER.ACCOUNT_SETTINGS.MY_BONUSES.MARKETPLACE_BONUS.SUCCESS_MESSAGE"
					)
				);
				this.setShouldOfferBonusFlag(false);
			}
		} catch (err) {
			console.log(err);
			if (err.data?.errorCode != null) {
				switch (err.data.errorCode) {
					case 400149:
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"USER.ACCOUNT_SETTINGS.ERR_HANDLING.INVALID_USER"
							)
						);
						break;
					case 400185:
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"ACCOUNT_VERIFICATION.USER_ACCOUNT_NOT_VERIFIED_HEADER"
							)
						);
						break;
					case 400219:
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"USER.ACCOUNT_SETTINGS.MY_BONUSES.MARKETPLACE_BONUS.NOT_ONLINE_PLAYER"
							)
						);
						break;
					case 400234:
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"USER.ACCOUNT_SETTINGS.MY_BONUSES.NO_ACTIVE"
							)
						);
						break;
					case 400235:
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_BONUS_ALREADY_USED"
							)
						);
						break;
					case 4040020000:
					default:
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
							)
						);
				}
				this.setShouldOfferBonusFlag(false);
			} else {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
					)
				);
			}
		}
	}

	@action.bound
	resolveAccountActivation(
		user: User,
		token: UserToken,
		userAccounts: UserAccount[],
		loginHistory: LoginHistoryResponseModel
	) {
		this.setAuthenticationToken(null);
		user["bettingActivityNeeded"] = false;
		this.setUser(user);
		this.setToken(token);
		this.setUserAccounts(userAccounts);
		if (App.offer.rootStore.betSlipStore.isSlipEmpty) {
			App.offer.rootStore.betSlipStore.onResetSlip();
		}
	}

	async keepAlive(activeAccount: UserTypes): Promise<void> {
		if (!this.isLoggedIn) return;
		try {
			await KeepAliveService.ping(activeAccount);
		} catch (err) {
			console.error(err);
		}
	}

	@action.bound
	resolveForcePasswordChange(
		user: User,
		token: UserToken,
		userAccounts: UserAccount[],
		loginHistory: LoginHistoryResponseModel
	) {
		this.setAuthenticationToken(null);
		user["bettingActivityNeeded"] = false;
		this.setUser(user);
		this.setToken(token);
		this.setUserAccounts(userAccounts);
		this.setLoginHistory(loginHistory);
		if (App.offer.rootStore.betSlipStore.isSlipEmpty) {
			App.offer.rootStore.betSlipStore.onResetSlip();
		}
	}

	//#region resolvers

	@action.bound
	resolveBettingActivity(): void {
		if (this.user == null) {
			return;
		}
		this.user.bettingActivityNeeded = false;
		if (this.user == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.USER_KEY
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.USER_KEY,
				JSON.stringify(this.user)
			);
		}
	}

	@action.bound
	resolveAcceptNewTerms(): void {
		if (this.user == null) {
			return;
		}
		this.user.shouldAcceptTermsAndConditions = false;
		if (this.user == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.USER_KEY
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.USER_KEY,
				JSON.stringify(this.user)
			);
		}
	}

	@action.bound
	resolveUserEmail(newEmail: string): void {
		if (this.user == null) {
			return;
		}
		//set new email and set approve flag to true so we can render correctly email fields
		this.user.email = newEmail;
		this.user.isOfflineUserMailApproved = true;
		if (this.user == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.USER_KEY
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.USER_KEY,
				JSON.stringify(this.user)
			);
		}
	}

	@action.bound
	resolveBalance(): void {
		// resolveBalance(update: any, accountTypeId: any = null): void {
		this.refreshUserBalance();

		// // for future: change abrv check with user-selected user account
		// let accounts = this.userAccounts;

		// const checkForAccount = (a) => {
		//     return accountTypeId != null ?
		//         a.accountTypeId.value === accountTypeId : a.abrv === UserAccountType.SportBettingAccountShopOnline;
		// }

		// const currentAccountBalance = accounts.find(a => checkForAccount(a))['balance'];
		// const calculatedNewAccountBalance = currentAccountBalance + update;
		// if (calculatedNewAccountBalance < 0) {
		//     this.refreshUserBalance();
		// } else {
		//     this.setUserAccounts(accounts.filter(a => {
		//         if (checkForAccount(a)) {
		//             a.balance += update;
		//         }
		//         return a;
		//     }))
		// }
	}

	//#endregion resolvers

	@action.bound
	async refreshLoggedInUser() {
		if (this.user == null) {
			return;
		}

		let userResponse = await (
			await UserService
		).default.refreshUser(this.user.id, true);

		// we need to inherit current user state values for flags because user might have altered their state during login process
		// and that altered state is not on API nor in cache
		if (this.user != null) {
			// we get this property on user after saving to cache so we need to inherit that value as well
			userResponse.isInternetUser = this.user.isInternetUser;
		}
		this.setUser(userResponse);

		if (this.secondaryUser != null) {
			let secondaryUserResponse = await (
				await UserService
			).default.refreshUser(this.secondaryUser.id, false);

			// the same goes for secondary user
			secondaryUserResponse.isInternetUser =
				// we get this property on user after saving to cache so we need to inherit that value as well
				this.secondaryUser.isInternetUser;
			this.setSecondaryUser(secondaryUserResponse);
		}
	}

	//#region setters

	@action.bound
	async setUser(user: User | null): Promise<void> {
		this.user = user;

		if (this.user?.bettingActivityNeeded) {
			await this.bettingActivityStore.fetchBettingActivityData();

			if (
				this.bettingActivityStore.bettingActivityFetchedData
					?.bettingActivity == null &&
				this.bettingActivityStore.bettingActivityFetchedData
					?.userActivity == null
			) {
				this.resolveBettingActivity();
				await this.bettingActivityStore.updateBettingActivityReview();
			}
		}
		if (this.user == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.USER_KEY
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.USER_KEY,
				JSON.stringify(this.user)
			);
		}
	}

	@action.bound
	setToken(value: UserToken | null): void {
		this.token = value;
		if (this.token == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.USER_TOKEN
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.USER_TOKEN,
				JSON.stringify(this.token)
			);
		}
	}

	@action.bound
	setSecondaryUser(user: User | null | undefined): void {
		this.secondaryUser = user || null;
		if (this.secondaryUser == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.SECONDARY_USER
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.SECONDARY_USER,
				JSON.stringify(this.secondaryUser)
			);
		}
	}

	@action.bound
	setSecondaryToken(secondaryToken: UserToken | null): void {
		this.secondaryToken = secondaryToken;
		if (this.secondaryToken == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.SECONDARY_TOKEN
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.SECONDARY_TOKEN,
				JSON.stringify(this.secondaryToken)
			);
		}
	}

	@action.bound
	setAuthenticationToken(
		accountActivation: AccountActivation | null | undefined
	): void {
		this.accountActivation = accountActivation || null;
		if (this.accountActivation == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.ACCOUNT_ACTIVATION
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.ACCOUNT_ACTIVATION,
				JSON.stringify(this.accountActivation)
			);
		}
	}

	@action.bound
	setActiveUserAccountTypeId(id: string | null): void {
		this.activeAccountTypeId = id;
		if (this.activeAccountTypeId == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.ACC
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.ACC,
				this.activeAccountTypeId
			);
		}
	}

	@action.bound
	setRealityCheckTime(value: number | null) {
		this.realityCheckTime = value;
		if (this.realityCheckTime == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.REALITY_CHECK_EXPIRATION
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.REALITY_CHECK_EXPIRATION,
				JSON.stringify(this.realityCheckTime)
			);
		}
	}

	@action.bound
	setAutoLogoutPeriod(value: typeof this.autoLogoutPeriod) {
		this.autoLogoutPeriod = value;
		if (this.autoLogoutPeriod == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.AUTO_LOGOUT_PERIOD
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.AUTO_LOGOUT_PERIOD,
				JSON.stringify(this.autoLogoutPeriod)
			);
		}
	}

	/**
	 * Used to track is token expired.
	 * @param value ISO Time string
	 */
	@action.bound
	setLastTimeChangedTokenExpiration(value?: string | null): void {
		if (value != null) {
			this.lastTimeChangedTokenExpiration = value;
		} else {
			this.lastTimeChangedTokenExpiration = null;
		}
		if (this.lastTimeChangedTokenExpiration == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.TOKEN_EXPIRATION_LAST_CHANGE
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.TOKEN_EXPIRATION_LAST_CHANGE,
				this.lastTimeChangedTokenExpiration
			);
		}
	}
	@action.bound
	setLastTimeChangedSecondaryTokenExpiration(value?: string | null): void {
		if (value != null) {
			this.lastTimeChangedSecondaryTokenExpiration = value;
		} else {
			this.lastTimeChangedSecondaryTokenExpiration = null;
		}

		if (this.lastTimeChangedSecondaryTokenExpiration == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.SECONDARY_TOKEN_EXPIRATION_DATE
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.SECONDARY_TOKEN_EXPIRATION_DATE,
				this.lastTimeChangedSecondaryTokenExpiration
			);
		}
	}

	@action.bound
	setTokenExpirationDate(expirationDate: string): void {
		const newUserToken: UserToken = {
			...(this.token as UserToken),
			expirationDate: expirationDate,
		};
		this.setToken(newUserToken);
	}

	@action.bound
	setLoginHistory(value: LoginHistoryResponseModel | null): void {
		this.loginHistory = value;
		if (this.loginHistory == null) {
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.LOGIN_HISTORY
			);
		} else {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.LOGIN_HISTORY,
				JSON.stringify(this.loginHistory)
			);
		}
	}

	@action.bound
	setUserAccounts(value: UserAccount[] | null): void {
		if (value == null || value.length <= 0) {
			this.userAccounts = null;
			this.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.USER_ACCOUNTS
			);
			return;
		}

		this.userAccounts = this.sortUserAccounts(value);
		if (this.activeAccountTypeId == null) {
			// @ts-expect-error && appsettings constant
			if (DefaultAccount != null && DefaultAccount !== "") {
				const defaultUserAccount = this.userAccounts.find(
					// @ts-expect-error
					(ua) => ua.abrv === DefaultAccount
				);

				if (defaultUserAccount != null) {
					this.setActiveUserAccountTypeId(
						defaultUserAccount.accountTypeId
					);
				} else {
					this.setActiveUserAccountTypeId(
						this.userAccounts[0].accountTypeId
					);
				}
			} else {
				// const sportBettingAccount = this.userAccounts.find(
				// 	(ua) =>
				// 		ua.abrv ===
				// 		UserAccountType.SportBettingAccountShopOnline
				// );

				// if (sportBettingAccount != null) {
				// 	this.setActiveUserAccountTypeId(
				// 		sportBettingAccount.accountTypeId
				// 	);
				// } else {
				// 	this.setActiveUserAccountTypeId(
				// 		this.userAccounts[0].accountTypeId
				// 	);
				// }

				// case when online user is locked take first wallet from offline user
				//  when sorted first wallet belongs to online user
				if (this.secondaryUser == null && this.user != null) {
					// typescript complaning about type even tho it's checked before
					// if you reassign value it's works fine
					const user = this.user;
					const userAccount = this.userAccounts.find(
						(wallet) => wallet.userId == user.id
					);
					if (userAccount) {
						this.setActiveUserAccountTypeId(
							userAccount.accountTypeId
						);
					} else {
						console.error(
							"Unabled to find wallet",
							this.userAccounts,
							this.user.id
						);
						this.setActiveUserAccountTypeId(
							this.userAccounts[0].accountTypeId
						);
					}
				} else {
					this.setActiveUserAccountTypeId(
						this.userAccounts[0].accountTypeId
					);
				}
			}
		} else {
			const isActiveAccountAvailable = this.userAccounts.some(
				(ua) => ua.accountTypeId === this.activeAccountTypeId
			);

			if (!isActiveAccountAvailable) {
				this.setActiveUserAccountTypeId(
					this.userAccounts[0].accountTypeId
				);
			}
		}

		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.USER_ACCOUNTS,
			JSON.stringify(this.userAccounts)
		);
	}

	@action.bound
	setShouldOfferBonusFlag(flagValue: boolean) {
		if (this.user != null) {
			this.setUser({ ...this.user, shouldOfferBonus: flagValue });
		}
	}

	//#endregion setters

	//#region helpers

	getUserAccountType(
		activeAccount: AccountTypes = AccountTypes.OFFLINE
	): UserTypes {
		if (this.secondaryUser == null) {
			return UserTypes.PRIMARY;
		}

		if (
			(this.isPrimaryUserOnline &&
				activeAccount === AccountTypes.OFFLINE) ||
			(!this.isPrimaryUserOnline && activeAccount === AccountTypes.ONLINE)
		) {
			return UserTypes.SECONDARY;
		}

		return UserTypes.PRIMARY;
	}

	@action.bound
	getWalletOwner(activeAccountId?: string | null): UserTypes {
		let activeWallet = this.userAccounts?.find(
			(wallet) => wallet.accountTypeId === activeAccountId
		);
		if (
			activeAccountId == null ||
			activeWallet?.userId === this?.user?.id
		) {
			return UserTypes.PRIMARY;
		}
		return UserTypes.SECONDARY;
	}

	//#endregion helpers

	//#region reading storage

	@action.bound
	readFromLocalStorage = (key: string | null) => {
		// JSON.parse does accept null!!!! TypeScript you were supposed to the chosen one

		if (key == null || key == "userToken") {
			this.setToken(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.USER_TOKEN
					) as string
				) as LoginResponseToken | null
			);
		}

		if (key == null || key == "user") {
			this.setUser(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.USER_KEY
					) as string
				) as LoginResponseUser | null
			);
		}

		if (key == null || key == "secondaryUser") {
			this.setSecondaryUser(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.SECONDARY_USER
					) as string
				) as LoginResponseUser | null
			);
		}

		if (key == null || key == "secondaryToken") {
			this.setSecondaryToken(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.SECONDARY_TOKEN
					) as string
				) as LoginResponseToken | null
			);
		}

		if (key == null || key == "accountActivation") {
			this.setAuthenticationToken(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.ACCOUNT_ACTIVATION
					) as string
				) as AccountActivation | null
			);
		}

		if (key == null || key == "loginHistory") {
			this.setLoginHistory(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.LOGIN_HISTORY
					) as string
				) as LoginHistoryResponseModel | null
			);
		}
		if (key == null || key == "acc") {
			this.setActiveUserAccountTypeId(
				this.rootStore.localStorageProvider.get(
					StorageStateKeysEnum.ACC
				) as string
			);
		}

		if (key == null || key == "userAccounts") {
			this.setUserAccounts(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.USER_ACCOUNTS
					) as string
				) as UserAccount[] | null
			);
		}

		if (key == null || key == "token-expiration-last-change") {
			this.setLastTimeChangedTokenExpiration(
				this.rootStore.localStorageProvider.get(
					StorageStateKeysEnum.TOKEN_EXPIRATION_LAST_CHANGE
				) as string
			);
		}

		if (key == null || key == "secondary-token-expiration-date") {
			this.setLastTimeChangedSecondaryTokenExpiration(
				this.rootStore.localStorageProvider.get(
					StorageStateKeysEnum.SECONDARY_TOKEN_EXPIRATION_DATE
				) as string
			);
		}

		if (key == null || key == "reality-check-expiration") {
			this.setRealityCheckTime(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.REALITY_CHECK_EXPIRATION
					) as string
				) as number
			);
		}

		if (key == null || key == "auto-logout-period") {
			this.setAutoLogoutPeriod(
				JSON.parse(
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.AUTO_LOGOUT_PERIOD
					) as string
				) as number
			);
		}
	};

	storageActionListener = (e: StorageEvent) => {
		this.readFromLocalStorage(e.key);
	};

	//#region reading storage

	//#region disposers

	@action.bound
	onDispose() {
		window.removeEventListener("storage", this.storageActionListener);
		clearInterval(this.keepAliveInterval);
		this.loginStore.onDispose();
	}

	//#endregion disposers
}

//

export type User = LoginResponseUser;

export type UserToken = LoginResponseToken;

export type AccountLockInfo = {
	duration: number;
	startDate: DateTime;
	endDate: DateTime;
};

export type BettingTimeoutInfo = {
	duration: number | null;
	endDate: DateTime | null;
};
export type AccountActivation = LoginActivationResponseDto;
