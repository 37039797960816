import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAccountLockWithPasswordConfirmation } from "@v2/hooks/account-settings/account-lock";
import { PasswordConfirmationWrapper } from ".";
import { setTabTitle } from "@utils";

export default function AccountLockWithPasswordConfirmation() {
	const { t } = useTranslation();
	const {
		onInitialize,
		onSuccess,
		onCancel,
		lookups,
		fetchedData,
		onSubmitData,
	} = useAccountLockWithPasswordConfirmation();

	useEffect(() => {
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_ACC_LOCK"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
		onInitialize();
	}, []);

	return (
		<>
			<PasswordConfirmationWrapper
				lookups={lookups}
				fetchedData={fetchedData}
				onSubmitData={onSubmitData}
				onSuccess={onSuccess}
				onCancel={onCancel}
			/>
		</>
	);
}
