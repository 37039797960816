import { Params } from "@v2/data-access/account-statement/AccountStatementService";
import { AccountStatementFilter } from "@v2/hooks/account-statement/useAccountStatementFilter";
import { AccountTransactions } from "@v2/hooks/account-statement/useAccountTransactions";
import { createContext } from "react";

export const AccountStatementBetContext = createContext<
	| null
	| ({
			statementFilter: {} | Params;
			setStatementFilter: Function;
	  } & AccountStatementFilter &
			AccountTransactions)
>(
	/* {
	statementFilter: {},
	setStatementFilter:()=>,
} */ null
);
