import React, { FC, useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EventType } from "@gp/models";

import { BetOffer } from "@administration/pages/my-bets/model";
import { MyBetsStoreContext, MyBetsBetContext } from "../../../../../context";
import { getCurrentCulture } from "@utils";
import { useRootOfferStore } from "@hooks";

export default observer(function EventOfferGroups(props: {
	groupHasTitle?: boolean;
	titleHasScore?: boolean;
	offersHaveColor?: boolean;
}) {
	const {
		groupHasTitle = false,
		titleHasScore = false,
		offersHaveColor = false,
	} = props;

	const bet = useContext(MyBetsBetContext);

	return (
		<>
			{bet.groupedOffersByEvent.map((offerGroup) => {
				return (
					<div key={offerGroup[0].eventId} className="bet__item">
						<LinkActiveEventsHOC
							className="bet__team bet__link"
							offer={offerGroup[0]}
						>
							<EventOfferGroups_Title
								offer={offerGroup[0]}
								groupHasTitle={groupHasTitle}
								titleHasScore={titleHasScore}
							/>
							<EventOfferGroups_OfferGroup
								offerGroup={offerGroup}
								groupHasTitle={groupHasTitle}
								offersHaveColor={offersHaveColor}
							/>
						</LinkActiveEventsHOC>
					</div>
				);
			})}
		</>
	);
});

function EventOfferGroups_Title(props: {
	groupHasTitle: boolean;
	titleHasScore: boolean;
	offer: BetOffer;
}) {
	const { groupHasTitle, titleHasScore, offer } = props;

	if (!groupHasTitle) {
		return null;
	}

	return (
		<div className="bet__event">
			<div className="bet__event__details">
				<EventOfferGroups_Time offer={offer} />
				<EventOfferGroups_EventName offer={offer} isDetails={true} />
				{titleHasScore && (
					<EventOfferGroups_EventScoreAtBetTime offer={offer} />
				)}
			</div>
			{titleHasScore && <EventOfferGroups_TitleScore offer={offer} />}
		</div>
	);
}

const EventOfferGroups_TitleScore = observer(
	function EventOfferGroups_TitleScore(props: { offer: BetOffer }) {
		const { offer } = props;
		const myBetsStore = useContext(MyBetsStoreContext);

		const getLiveEvent =
			myBetsStore?.activeEventsStore.eventsMap.get(offer.eventId) ||
			false;

		if (offer.formattedCurrentResult(getLiveEvent) == "") {
			return null;
		}

		return (
			<div className="bet__event__result">
				<div className="bet__event__current">
					{offer.formattedCurrentResult(getLiveEvent)}
				</div>

				{offer.scorePerPeriodString(getLiveEvent) != "" && (
					<div className="bet__event__period">
						{offer.scorePerPeriodString(getLiveEvent)}
					</div>
				)}
			</div>
		);
	}
);

const EventOfferGroups_OfferGroup = observer(
	function EventOfferGroups_OfferGroup(props: {
		offerGroup: BetOffer[];
		groupHasTitle: boolean;
		offersHaveColor: boolean;
	}) {
		const { offerGroup, groupHasTitle, offersHaveColor } = props;

		return (
			<>
				{offerGroup.map((offer) => {
					return (
						<EventOfferGroups_OfferItem
							key={offer.id}
							offer={offer}
							groupHasTitle={groupHasTitle}
							offersHaveColor={offersHaveColor}
						/>
					);
				})}
			</>
		);
	}
);
const EventOfferGroups_Time = observer(function EventOfferGroups_Time(props: {
	offer: BetOffer;
}) {
	const myBetsStore = useContext(MyBetsStoreContext);

	function checkIfIsMulti() {
		if (props.offer.isMulti) {
			return { time: "", cssClass: "" };
		}

		if (myBetsStore?.activeEventsStore.eventsMap.has(props.offer.eventId)) {
			if (
				myBetsStore.activeEventsStore.eventsMap.get(props.offer.eventId)
					?.matchTime?.displayTime
			) {
				return {
					time: myBetsStore.activeEventsStore.eventsMap.get(
						props.offer.eventId
					)?.matchTime?.displayTime,
					cssClass: " bet__time--live",
				};
			}
			return {
				time: props.offer.timeString,
				cssClass: "",
			};
		}

		return {
			time: props.offer.timeString,
			cssClass: "",
		};
	}
	const data = checkIfIsMulti();
	return <div className={"bet__time" + data.cssClass}>{data.time}</div>;
});
const EventOfferGroups_OfferItem = observer(
	function EventOfferGroups_OfferItem(props: {
		offer: BetOffer;
		groupHasTitle: boolean;
		offersHaveColor: boolean;
	}) {
		const { offer, groupHasTitle, offersHaveColor } = props;

		// offersHaveColor && console.log(offer.formattedBettingOfferStatus);

		const colorClass = offersHaveColor
			? `u-color--offer--${offer.formattedBettingOfferStatus}`
			: "";

		return (
			<>
				<EventOfferGroups_OfferTime
					offer={offer}
					groupHasTitle={groupHasTitle}
				/>
				<div className="bet__pick">
					<div className="bet__combi">{offer.systemIdentifier}</div>

					<div className={"bet__pick__inner"}>
						{!groupHasTitle && (
							<EventOfferGroups_EventName offer={offer} />
						)}
						<EventOfferGroups_BettingType
							offer={offer}
							colorClass={colorClass}
						/>
						{offer.isBank === true && (
							<div className="bet__bank tag tag--tny tag--success">
								B
							</div>
						)}
					</div>

					<div className={"bet__quote " + colorClass}>
						{offer.value.toFixed(2)}
					</div>
				</div>
			</>
		);
	}
);
const EventOfferGroups_OfferTime: FC<{
	offer: BetOffer;
	groupHasTitle: boolean;
}> = observer(function EventOfferGroups_OfferTime(props: {
	offer: BetOffer;
	groupHasTitle: boolean;
}) {
	const myBetsStore = useContext(MyBetsStoreContext);
	function checkIfIsMulti() {
		if (props.offer.isMulti || props.groupHasTitle) {
			return { time: "", cssClass: "" };
		}
		if (myBetsStore?.activeEventsStore.eventsMap.has(props.offer.eventId)) {
			if (
				myBetsStore.activeEventsStore.eventsMap.get(props.offer.eventId)
					?.matchTime?.displayTime
			) {
				return {
					time: myBetsStore.activeEventsStore.eventsMap.get(
						props.offer.eventId
					)?.matchTime?.displayTime,
					cssClass: " bet__time--live",
				};
			}
			return {
				time: props.offer.timeString,
				cssClass: "",
			};
		}
		return { time: props.offer.timeString, cssClass: "" };
	}

	const data = checkIfIsMulti();

	return <div className={"bet__time" + data.cssClass}>{data.time}</div>;
});
function EventOfferGroups_BettingType(props: {
	offer: BetOffer;
	colorClass: string;
}) {
	const { offer, colorClass } = props;

	if (offer.sportData.eventType !== EventType.NORMAL) {
		return <>{offer.formattedBettingTypeTip}</>;
	}

	return (
		<span className={colorClass}>
			{offer.formattedBettingType} ({offer.formattedBettingTypeTip})
		</span>
	);
}

const EventOfferGroups_EventName = observer(
	function EventOfferGroups_EventName(props: {
		offer: BetOffer;
		isDetails?: boolean;
	}) {
		const { offer, isDetails = false } = props;

		const nameClasses = classNames("bet__name", {
			"bet__name--multi": offer.isMulti,
			"bet__name--event": offer.sportData.eventType !== EventType.NORMAL,
			"bet__name--teams":
				!offer.isMulti &&
				offer.sportData.eventType === EventType.NORMAL,
			"bet__name--full": isDetails,
		});

		if (offer.isMulti) {
			return <span className={nameClasses}>{offer.multiEventName}</span>;
		}

		if (offer.sportData.eventType !== EventType.NORMAL) {
			return (
				<span className={nameClasses}>{offer.sportData.eventName}</span>
			);
		}

		return (
			<span className={nameClasses}>
				{offer.teamOneString} - {offer.teamTwoString}
			</span>
		);
	}
);

function EventOfferGroups_EventScoreAtBetTime(props: { offer: BetOffer }) {
	const { offer } = props;

	const { t } = useTranslation();

	const liveString = offer.isLive ? `*${t("MY_BETS.ITEM.LIVE_SCORE")}*` : "";

	return (
		<div className="table--bet__body__data--event--live table--bet__body__data--event--live">
			<div className="u-type--tny u-type--case--uppercase">
				{liveString} {offer.formattedResultAtBetTime}
				{offer.scorePerPeriodAtBetTimeString}
			</div>
		</div>
	);
}

const LinkActiveEventsHOC = observer(function LinkActiveEventsHOC(props: {
	className: string;
	children: JSX.Element | JSX.Element[];
	offer: BetOffer;
}) {
	const { className, children, offer } = props;

	const myBetsViewStore = useRootOfferStore().myBetsViewStore;

	if (
		myBetsViewStore.activeEventsStore.eventsMap.has(offer.eventId) &&
		!window.location.pathname.includes(offer.eventId)
	) {
		const page = offer.isLive ? "live/events" : `sports/full/event`;
		const url = `/${getCurrentCulture()}/${page}/${
			offer.eventId
		}-myBets=false`;

		return (
			<Link className={className} to={url}>
				{children}
			</Link>
		);
	}

	return <>{children}</>;
});
