export enum FailedLoginReportMode {
	FROM_LAST_LOGIN = 0,
	FROM_INTERVAL_HOUR = 1,
}

export type FailedLoginsReportFilter = {
	reportMode: FailedLoginReportMode;
	rpp: number;
	hourInterval?: number;
};
