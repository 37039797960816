import LookupApiService from "@api-services/LookupApiService";
import { DateTime } from "luxon";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));

export class AutomaticLogoutService {
	static async getAutoLogoutPeriodsLookupAsync() {
		const response = await LookupApiService.getAutoLogoutPeriods();
		return response;
	}

	static async getBettingTimeoutLookupAsync() {
		const response = await LookupApiService.getBettingTimeouts();
		return response;
	}

	static async getAutomaticLogoutData() {
		const automaticLogoutModelData = {
			data: {
				bettingTimeoutId: "",
				logoutIntervalId: "",
				deactivateBettingTimeoutId: "",
				deactivateLogoutIntervalId: "",
			},
			message: {
				settingsMessage: "",
				autoLogoutTime: "",
				logoutIntervaTime: "",
				bettingTimeoutTime: "",
			},
		};
		try {
			// using await for catching exception

			const response = await this.setAutomaticLogoutSettings(
				automaticLogoutModelData
			);

			return { data: response, success: true };
		} catch (error) {
			if (error.message == "Unauthorized") {
				return;
			}
			throw { message: "GENERIC_ERROR" };
		}
	}

	static async setAutomaticLogoutSettings(
		model: TSetAutomaticLogoutSettingsModel
	): Promise<{ message: string } | TSetAutomaticLogoutSettingsModel> {
		const user = await (await AgencyUserApiService).default.getUserInfo();
		const autoLogoutTypes = await this.getAutoLogoutPeriodsLookupAsync();
		const bettingTimeoutTypes = await this.getBettingTimeoutLookupAsync();
		if (
			autoLogoutTypes.item.length == 0 ||
			bettingTimeoutTypes.item.length == 0
		) {
			return { message: "GENERIC_ERROR" };
		}

		const deactivateInSevenDays = DateTime.local().minus({ days: 7 });

		if (user != null) {
			const deacivateAutoLogoutAllowed =
				user.autoLogoutPeriodStartDate != null &&
				user.autoLogoutPeriodStartDate <
					deactivateInSevenDays.toJSDate().toISOString();

			const filteredAutoLogoutTypes = !deacivateAutoLogoutAllowed
				? autoLogoutTypes.item
						.filter((x) => x.units != 0)
						.sort((x) => x.units)
				: autoLogoutTypes.item.sort((x) => x.units);

			model.data.logoutIntervalId = user.autoLogoutPeriodId;
			model.data.bettingTimeoutId = user.bettingTimeoutId;

			model.autoLogoutTypes = filteredAutoLogoutTypes.map((x) => {
				return {
					id: x.id,
					abrv: x.abrv,
					name: x.name,
					units: x.units,
				};
			});

			const deactivateBettingTimeoutAllowed =
				user.autoLogoutPeriodStartDate != null &&
				user.autoLogoutPeriodStartDate <
					deactivateInSevenDays.toJSDate().toISOString();

			const filteredBettingTimeoutTypes = !deactivateBettingTimeoutAllowed
				? bettingTimeoutTypes.item
						.filter((x) => x.units != 0)
						.sort((x) => x.units)
				: bettingTimeoutTypes.item.sort((x) => x.units);

			model.bettingTimeoutTypes = filteredBettingTimeoutTypes
				.filter((item) =>
					WEBPACK_BETTING_TIMEOUT_DURATIONS.includes(item.abrv)
				)
				.map((x) => {
					return {
						id: x.id,
						name: x.name,
						abrv: x.abrv,
					};
				});

			model.data.deactivateLogoutIntervalId = autoLogoutTypes.item.find(
				(x) => x.units == 0
			)?.id;
			model.data.deactivateBettingTimeoutId =
				bettingTimeoutTypes.item.find((x) => x.units == 0)?.id;

			const logoutInterval = autoLogoutTypes.item.find(
				(x) => x.id == user.autoLogoutPeriodId
			);
			const bettingTimeout = bettingTimeoutTypes.item.find(
				(x) => x.id == user.bettingTimeoutId
			);

			if (bettingTimeout != undefined) {
				model.message.bettingTimeoutTime = bettingTimeout.name;
			} else {
				model.message.bettingTimeoutTime = "";
			}

			if (logoutInterval != undefined) {
				model.message.logoutIntervalTime = logoutInterval.name;
			} else {
				model.message.logoutIntervalTime = "";
			}

			const userHasAutoLogout = user.autoLogoutPeriodId != null;
			const userHasBetTimeout = user.bettingTimeoutId != null;

			if (!userHasAutoLogout && !userHasBetTimeout) {
				model.message.settingsMessage = "NO_SETTINGS";
			} else {
				model.message.settingsMessage = "AUTO_LOGOUT_SETTINGS";
			}

			if (
				user.autoLogoutPeriodStartDate != null &&
				user.bettingTimeoutStartDate != null
			) {
				if (
					user.autoLogoutPeriodStartDate >
					user.bettingTimeoutStartDate
				) {
					model.message.autoLogoutTime =
						user.autoLogoutPeriodStartDate;
				} else {
					model.message.autoLogoutTime = user.bettingTimeoutStartDate;
				}
			} else if (
				user.autoLogoutPeriodStartDate != null &&
				user.bettingTimeoutStartDate == null
			) {
				model.message.autoLogoutTime = user.autoLogoutPeriodStartDate;
			} else if (
				user.autoLogoutPeriodStartDate == null &&
				user.bettingTimeoutStartDate != null
			) {
				model.message.autoLogoutTime = user.bettingTimeoutStartDate;
			}
		}

		return model;
	}

	static async updateAutomaticLogoutData(model) {
		const user = await (await AgencyUserApiService).default.getUserInfo();
		const userLimitSettings = {
			autoLogoutPeriodId: null,
			bettingTimeoutId: null,
		};

		try {
			if (
				user.bettingTimeoutId != model.bettingTimeoutId &&
				!(
					user.bettingTimeoutId == null &&
					model.bettingTimeoutId == null
				)
			) {
				userLimitSettings.bettingTimeoutId = model.bettingTimeoutId;
			}

			if (
				user.autoLogoutPeriodId != model.logoutIntervalId &&
				!(
					user.autoLogoutPeriodId == null &&
					model.logoutIntervalId == null
				)
			) {
				userLimitSettings.autoLogoutPeriodId = model.logoutIntervalId;
			}

			if (
				user.autoLogoutPeriodStartDate != null &&
				user.bettingTimeoutStartDate != null
			) {
				if (
					user.autoLogoutPeriodStartDate >
					user.bettingTimeoutStartDate
				) {
					model.message.autoLogoutTime =
						user.autoLogoutPeriodStartDate;
				} else {
					model.message.autoLogoutTime = user.bettingTimeoutStartDate;
				}
			} else if (
				user.autoLogoutPeriodStartDate != null &&
				user.bettingTimeoutStartDate == null
			) {
				model.message.autoLogoutTime = user.autoLogoutPeriodStartDate;
			} else if (
				user.autoLogoutPeriodStartDate == null &&
				user.bettingTimeoutStartDate != null
			) {
				model.message.autoLogoutTime = user.bettingTimeoutStartDate;
			}

			await (
				await AgencyUserApiService
			).default.setUserAutomaticLogoutSettings(userLimitSettings);

			const response = await this.setAutomaticLogoutSettings(model);
			return response;
		} catch (exception) {
			let errorMessage;

			if (exception.message == "Unauthorized") {
				return;
			}
			switch (exception?.data?.errorCode) {
				case 4000022003:
					errorMessage = "AUTO_LOGOUT_WARN";
					break;

				case 400140:
					errorMessage = "AUTO_LOGOUT_TIME_ERROR";
					break;

				case 400141:
					errorMessage = "AUTO_LOGOUT_DISABLE_ERROR";
					break;

				case 400142:
					errorMessage = "BETTING_TIME_ERROR";
					break;

				case 400143:
					errorMessage = "BETTING_TIME_DISABLE_ERROR";
					break;

				default:
					errorMessage = "AUTO_LOGOUT_ERR";
					break;
			}

			const modelError = {
				statusCode: exception.data.statusCode,
				errorCode: exception.data.errorCode,
				message: errorMessage,
			};
			throw modelError;
		}
	}

	static async updateRealityCheckDuration(durationValue: string) {
		return await (
			await AgencyUserApiService
		).default.updateRealityCheckDuration(durationValue);
	}
}

type TSetAutomaticLogoutSettingsModel = {
	data: {
		logoutIntervalId: string | undefined;
		bettingTimeoutId: string | undefined;
		deactivateLogoutIntervalId?: string;
		deactivateBettingTimeoutId?: string;
	};
	autoLogoutTypes?: {
		id: string;
		abrv: string;
		name: string;
		units: number;
	}[];
	bettingTimeoutTypes?: { id: string; abrv: string; name: string }[];
	message: {
		bettingTimeoutTime: string;
		logoutIntervalTime?: string;
		settingsMessage: string;
		autoLogoutTime: string;
	};
};
