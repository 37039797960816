import React, { useContext, useState } from "react";
import { observer } from "mobx-react";

import classNames from "classnames";

import { EventContext } from "@gp/components";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { useIsAdditionalOfferVisible } from "@offer/common";
import { useRootAppStore } from "@hooks";
import { useTranslation } from "react-i18next";

export default observer(function FavoritesIcon(props: {
	isOfferButton?: boolean;
	isOnHomepage?: boolean;
}) {
	const { isOfferButton = false, isOnHomepage } = props;

	const event = useContext(EventContext);
	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();
	const favoriteStore = useContext(FavoritesStoreContext);
	const [isHover, setIsHover] = useState(false);
	const rootStateStore = useRootAppStore();
	const { t } = useTranslation();
	const isFavorite = favoriteStore.isUserFavoriteEvent(event.id);

	const onButtonClick = () => {
		if (isFavorite) {
			favoriteStore.removeUserFavoriteEvent(event.id);
		} else {
			favoriteStore.addUserFavoriteEvent(event.id);
		}
	};

	// class order matters here
	const buttonClasses = classNames(
		{
			offer__favorites__btn: isOfferButton && !isOnHomepage,
			event__actions__btn: !isOfferButton,
		},
		"btn",
		{
			"btn--square--reset btn--ghost": isOfferButton,
			"btn--square btn--square--xtny btn--ghost": !isOfferButton,
		}
	);

	const iconClasses = classNames(
		{
			offer__favorites__icon: !isAdditionalOfferVisible,
			event__actions__icon: isAdditionalOfferVisible,
		},
		"u-icon",
		{
			// "u-icon--sml": isOnHomepage,
			"u-icon--med u-icon--med--responsive": isOfferButton,
			"u-icon--big": !isOfferButton,
			"u-icon--favorite--bold u-color--secondary--sunny":
				isFavorite || (!isFavorite && isHover),
			"u-icon--favorite--alt u-color--ui--muted": !isFavorite && !isHover,
		}
	);

	const tooltipMessage = !rootStateStore.userAuthStore.isLoggedIn
		? t("FAVORITES.NONAUTH_TOOLTIP")
		: "";

	return (
		<button
			data-tooltip-id="tv-description"
			data-tooltip-content={tooltipMessage}
			data-tooltip-position-strategy="fixed"
			className={buttonClasses}
			type="button"
			onClick={onButtonClick}
			disabled={
				!rootStateStore.userAuthStore.isLoggedIn || event.isOutright
			}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<i className={iconClasses} />
		</button>
	);
});
