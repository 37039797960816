import { UserPaymentTransactionLimited } from "@api-types/account-settings/UserPaymentTransactionLimited";
import {
	httpClient,
	getAgencyKey,
	getApplicationHeaders,
	UserTypes,
} from "@utils";

export class UserPaymentTransactionsApiService {
	static getRequestHeaders = (owner = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(owner);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getTransactionDetails(
		transactionId: string,
		owner = UserTypes.PRIMARY
	): Promise<UserPaymentTransactionLimited> {
		const URL = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			owner
		)}/user-payment-transactions/foruser/${transactionId}?embed=paymentMethod,currency,transactionStatus&translate=true`;

		return await httpClient.get(URL, this.getRequestHeaders(owner), {
			responseType: "json",
		});
	}
}
