import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isCapacitorPlatform } from "@utils/specific/capacitor";
import { bottom, top } from "safe-area-insets";
import { DeviceDetector } from "@utils";

interface LoaderSpinnerProps {
	isCountdown?: Countdown;
	contained?: boolean;
	isMobile?: boolean;
	isLoading?: boolean;
}

type Countdown = {
	countdownValue: number;
	isDesktop?: boolean;
};

export default observer(function LoaderSpinner(props: LoaderSpinnerProps) {
	if (!props.isLoading) {
		return null;
	}

	if (props.contained) {
		return <LoaderContained />;
	}
	if (DeviceDetector.isMobileTheme) {
		return <LoaderMobile />;
	}
	if (props.isCountdown !== undefined) {
		return (
			<LoaderCountdown
				counter={props.isCountdown?.countdownValue}
				isDesktop={props.isCountdown?.isDesktop}
			/>
		);
	}
	return <LoaderFull />;
});

function LoaderCountdown(props: { counter?: number; isDesktop?: boolean }) {
	const { t } = useTranslation();

	return props.isDesktop ? (
		<div className="loader loader--countdown is-sticky">
			<div className="loader--countdown__inner">
				<div className="loader--countdown__box">{props.counter}</div>
				<div className="loader--countdown__title">
					{t<string>("HOME.LOADING")}...
				</div>
			</div>
		</div>
	) : (
		<div className="loader loader--full loader--centered">
			<div className="loader--centered__countdown">{props.counter}</div>
		</div>
	);
}

function LoaderFull() {
	return (
		<div id="loader" className="loader--full">
			<div className="loader--countdown" style={{ display: "none" }}>
				<div className="loader--countdown__box" />
			</div>
			<div className="loader__spinner" />
		</div>
	);
}

function LoaderContained() {
	const { t } = useTranslation();

	return (
		<div className="loader loader--countdown is-sticky">
			<div className="loader--countdown__inner">
				<div className="loader__spinner u-mar--y--reset" />
				<div className="loader__title u-type--color--white">
					{t<string>("HOME.LOADING")}...
				</div>
			</div>
		</div>
	);
}

function LoaderMobile() {
	const loaderPadding = isCapacitorPlatform()
		? { top: top + 48, bottom: bottom + 54 }
		: {};

	return (
		<div className="loader" style={loaderPadding}>
			<div className="loader__spinner">
				<div className="loader__spinner__wrapper">
					<div />
					<div />
					<div />
				</div>
			</div>
		</div>
	);
}
