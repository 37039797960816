import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { BackButton } from "./";
import { getCurrentCulture } from "@utils";

export function EmptyOfferMessage() {
	const { t } = useTranslation();
	const location = useLocation();

	const isLiveRoute = location.pathname.includes("live");
	const culture = getCurrentCulture();

	const iconClassNames = classNames(
		"card--empty__icon u-icon u-icon--huge u-icon--empty",
		{
			"u-icon--empty--live-betting": isLiveRoute,
			"u-icon--empty--sports-betting": !isLiveRoute,
		}
	);

	return (
		<div className="l--content__main">
			<div className="container container--empty">
				<div className="card--primary">
					<div className="card--primary__header">
						<BackButton />
					</div>
					<div className="card--primary__body">
						<div className="card--empty card--empty--borderless u-mar--bottom--reset">
							<div className="card--empty__content">
								<i className={iconClassNames} />
								<div className="card--empty__title">
									{t<string>("EVENT.EMPTY_STATE.TITLE")}
								</div>
								<div className="card--empty__message">
									{t<string>("EVENT.EMPTY_STATE.DESCRIPTION")}
								</div>
								<Link
									to={`/${culture}/live/events`}
									className="card--empty__btn btn btn--sml btn--secondary--inverted btn--icon btn--animate-forward"
								>
									{t("EVENT.EMPTY_STATE.CTA_LABEL")}
									<i className="u-icon u-icon--xsml u-icon--arrow--right" />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
