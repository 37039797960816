import { useLoading } from "@hooks";
import { useAppContext, useNotification, useUser } from "@v2/hooks/shared";
import { useTranslation } from "react-i18next";

export default function useBettingActivity() {
	const bettingActivityStore =
		App.state.rootStore.userAuthStore.bettingActivityStore;
	const { culture } = useAppContext();
	const { showError } = useNotification();
	const { setIsLoading } = useLoading();
	const { t } = useTranslation();
	const user = useUser();

	const updateBettingActivityReview = async () => {
		try {
			setIsLoading(true);
			await bettingActivityStore.updateBettingActivityReview();
			user.resolveBettingActivity();
			if (user.user?.shouldAcceptTermsAndConditions) {
				App.state.history.push(`/${culture}/auth/accept-new-terms`);
			} else {
				App.state.history.push(`/${culture}/home/full/highlights`);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const logoutUser = async () => {
		try {
			setIsLoading(true);
			await user.logoutUser();
			App.state.history.push(`/${culture}/home/full/highlights`);
		} catch (error) {
			console.error(error);
			showError(t("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"));
		} finally {
			setIsLoading(false);
		}
	};

	return {
		logoutUser,
		updateBettingActivityReview,
	};
}
