import { LiveStatus } from "@gp/models";
import { useRootAppStore } from "@hooks";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { useContext, useEffect } from "react";

export function useFavoritesCounter(livestatus: LiveStatus) {
	const favoriteStore = useContext(FavoritesStoreContext);
	const rootStore = useRootAppStore();

	async function onInit() {
		if (rootStore.userAuthStore.isLoggedIn) {
			if (livestatus == LiveStatus.PREMATCH) {
				await favoriteStore.onInitializePrematchFavorites();
			} else {
				await favoriteStore.onInitializeLiveFavorites();
			}
		}
	}

	useEffect(() => {
		onInit();
		return () => {
			if (livestatus == LiveStatus.PREMATCH) {
				favoriteStore.disposePrematchReaction();
			} else {
				favoriteStore.disposeLiveReaction();
			}
		};
	}, [rootStore.userAuthStore.isLoggedIn]);
}
