import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useRootOfferStore } from "@hooks";
import { Bet, OpenCashoutModalType } from "@administration/pages/my-bets/model";
import { CashoutType } from "@gp/hub";
import { ISOToDisplayCode } from "@utils";

const MyBetsCashoutButton = observer(function MyBetsCashoutButton(props: {
	bet: Bet;
	openCashoutModal: (args: OpenCashoutModalType) => void;
}) {
	const { t } = useTranslation();
	const rootOfferStore = useRootOfferStore();

	const buttonCalssNames = classNames(
		"widget--bets__btn widget--bets__cashout btn btn--tny btn--block btn--cashout",
		{
			"btn--icon inc": props.bet.indicator === 1,
			"btn--icon dec": props.bet.indicator === -1,
		}
	);

	if (props.bet.cType == undefined) {
		console.error("cType can not be null");
		return null;
	}

	const cType = props.bet.cType;

	const isButtonDisabled =
		rootOfferStore.myBetsViewStore.cashoutStore.isCashoutFormSubmitting;

	const handleButtonClick = () => {
		props.openCashoutModal({
			id: props.bet.id,
			calculatedCashoutAmount: props.bet.calculatedCashoutAmount,
			// @ts-expect-error
			displayedAmount: props.bet.displayedAmount,
			cType: cType,
			isLive: props.bet.isLive,
			isChangeAccepted: false,
		});
	};

	if (
		props.bet.cType !== CashoutType.JACKTIME ||
		props.bet.calculatedCashoutAmount < 0
	) {
		return null;
	}

	return (
		<button
			className={buttonCalssNames}
			type="button"
			disabled={isButtonDisabled}
			onClick={handleButtonClick}
		>
			<CashoutIndicator bet={props.bet} />
			{t<string>("MY_BETS.CASHOUT.BUTTON_LABEL")}{" "}
			{props.bet.displayedAmount}{" "}
			{ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)}
		</button>
	);
});

const CashoutIndicator = observer(function CashoutIndicator(props: {
	bet: Bet;
}) {
	if (props.bet.indicator === 1) {
		return (
			<i className="u-icon u-icon--xsml u-icon--caret--up u-color--ui--success" />
		);
	}

	if (props.bet.indicator === -1) {
		return (
			<i className="u-icon u-icon--xsml u-icon--caret--down u-color--ui--danger" />
		);
	}
	return null;
});

export default MyBetsCashoutButton;
