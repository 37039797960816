import { OfflineUserDataDto } from "@api-types/user/OfflineUserDataDto";
import { CompanySettingLimited } from "@api-types/company/CompanySettingsLimitedDto";

export class ActivationResponse {
	userInfo: OfflineUserDataDto;
	companyInfo: CompanySettingLimited;

	constructor(
		cardUserInfo: OfflineUserDataDto,
		limitedCompanySettings: CompanySettingLimited
	) {
		this.userInfo = cardUserInfo;
		this.companyInfo = limitedCompanySettings;
	}
}
