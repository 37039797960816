import { getAgencyKey, getApplicationHeaders, httpClient } from "@utils";

export class RegistrationActivationApiService {
	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static registrationActivate(username: string, mailkey: string) {
		const url = `${WEBPACK_BASE_ADDRESS}platform/gp/register/activate/${username}/${mailkey}`;
		return httpClient.put(url, null, this.getRequestHeaders(), null);
	}
}
