import React from "react";
import { getCurrentCulture } from "@utils";
import { useFixBody } from "@v2/hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//#region lazy

// import { getCurrentCulture } from "@utils";

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
import { useRootAppStore } from "@hooks";
const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const ReactMarkdown = lazy(loadFailPath, () => import("react-markdown"));

//#endregion lazy

export default observer(function AccountVerificationPopup({
	isOpened,
}: {
	isOpened: boolean;
}) {
	const rootStore = useRootAppStore();
	const { t } = useTranslation();
	useFixBody(true, isOpened);

	return (
		<div className="at-accVerifPopup popup">
			<div className="popup__card popup__card--sml">
				<div className="popup__header">
					<div className="popup__header__title">
						{t(`${rootStore.AccountVerificationStore.buttonText}`)}
					</div>
				</div>
				<div className="popup__body">
					<div className="message message--base message--warning">
						<i className="message__icon u-icon u-icon--lrg u-icon--alert-circle u-color--ui--warning" />
						<ReactMarkdown className="message__content">
							{rootStore.AccountVerificationStore.message || ""}
						</ReactMarkdown>
					</div>
				</div>
				<div className="popup__footer">
					<button
						type="button"
						className="at-accVerifBtnSecondary popup__footer__btn btn btn--sml btn--secondary--inverted"
						onClick={rootStore.AccountVerificationStore.closePopup}
					>
						{t("COMMON.CLOSE")}
					</button>
					<Link
						className="at-accVerifBtnPrimary popup__footer__btn btn btn--sml btn--primary"
						to={`/${getCurrentCulture()}/${
							rootStore.AccountVerificationStore.url
						}`}
						onClick={rootStore.AccountVerificationStore.closePopup}
					>
						{t(`${rootStore.AccountVerificationStore.buttonText}`)}
					</Link>
				</div>
			</div>
		</div>
	);
});
