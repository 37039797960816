import { isCapacitorPlatform } from "@utils/specific/capacitor";
import { SUPPORTED_LANGUAGES } from "./constants";
import { useFormContext } from "react-hook-form";
import { UserTypes } from "./activeAccountEnum";
import { getUserFromLocalStorage } from "@utils/membership";

export function getCurrentLanguage(): string {
	const culture = getCurrentCulture(); // short
	return (
		SUPPORTED_LANGUAGES.find((s) => s.key === culture)?.keyLong ||
		SUPPORTED_LANGUAGES[0].keyLong
	);
}

export function getCookie(cname: string): string | null {
	const name = cname + "=";
	const ca = decodeURIComponent(document.cookie).split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") c = c.substring(1);
		if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
	}
	return null;
}

export function setCookie(
	key: string,
	value: string,
	days?: number,
	sameSite?: string
): void {
	let expires = "";

	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}

	const sameSiteString = sameSite != null ? `;samesite=${sameSite}` : "";

	document.cookie = `${key}=${
		value || ""
	}${expires}; path=/${sameSiteString}`;
}

export function getCurrentCulture(): string {
	// TODO: is this the best way to get current culture ?
	const culture = window.location.pathname.split("/")[1];
	return (
		SUPPORTED_LANGUAGES.find(
			(s) => s.key === culture || s.keyLong === culture
		)?.key ||
		getCookie("lng") ||
		WEBPACK_DEFAULT_CULTURE
	);
}

export function isNullOrWhitespace(
	value: string | undefined | null | void
): boolean {
	if (typeof value === "undefined" || value == null) {
		return true;
	}
	return value.replace(/\s/g, "").length < 1;
}

/** @todo this doesn't seem to be used. */
export function fontResize(): void {
	const d = document.querySelector(".linkbox");

	if (d != null) {
		const a = d.getBoundingClientRect().width;
		const b = a * 0.7;
		const c = a * 0.12;

		const twIcon = d.querySelector<HTMLElement>(".u-tw-icon");
		if (twIcon != null) {
			twIcon.style.fontSize = b.toString();
		}

		const linkBox = d.querySelector<HTMLElement>(".linkbox__link__title");
		if (linkBox != null) {
			linkBox.style.fontSize = c.toString();
		}
	}
}

export function setTabTitle(newTitle: string) {
	if (newTitle === "") {
		console.error("Title can not be empty string");
		return;
	}
	document.title = newTitle;
}

export function ISOToDisplayCode(currencyISO: string): string {
	switch (currencyISO) {
		// British pound
		case "GBP":
			return "£";
		// Swedish, Norwegian, Danish krone
		case "SEK":
		case "NOK":
		case "DKK":
			return "kr";
		// Croatian kuna
		case "HRK":
			return "KN";
		// Bosnia and Hercegovina convertible mark
		case "BAM":
			return "KM";
		// Turkish lira
		case "TRY":
			return "₺";
		case "USD":
			return "$";
		// Namibian dollar
		case "NAD":
			return "N$";
		// Bulgarian lev
		case "BGN":
			return "лв";
		case "EUR":
		default:
			return "€";
	}
}

export function getDisplayedAmount(
	value: number,
	currency: string,
	digits: number = 2
): string {
	if (value == null) {
		return "";
	}

	if (!digits) {
		return `${value} ${currency}`;
	}

	return `${value.toFixed(digits)} ${currency}`;
}

export function getDefaultCountryCodeForTheme(): string {
	if (WEBPACK_DEFAULT_COUNTRY_FLAG) {
		return WEBPACK_DEFAULT_COUNTRY_FLAG.toUpperCase();
	}
	return "";
}

// if user is on registration domain, after registration (error or success) call this method to redirect user back to home page !
// this will check if user is on registration domain, if it is user must be redirected back to default domain home page
export function redirectUserToHomePageAfterRegistration(): void {
	if (window.location.host.includes("registration")) {
		window.location.href = `${WEBPACK_WEBSITE_URL}/${getCurrentCulture()}/home/full/highlights`;
		return;
	}
	App.state.redirect(`/${getCurrentCulture()}/home/full/highlights`);
}

export function getWebApiUrl(): string {
	if (isCapacitorPlatform()) {
		return WEBPACK_WEBSITE_URL;
	}
	return "";
}

export function getSVGExtension(): string {
	const { isLightTheme } = App.state.rootStore.themeStore;
	const svgExtension = isLightTheme
		? "--filled--light.svg"
		: "--filled--dark.svg";
	return svgExtension;
}

export function getUserBettingAccountTypeId(): string {
	const { getValues } = useFormContext();

	let bettingAccountTypeId = "";
	const accountType = getValues("userTypes");
	const userType = App.state.rootStore.userAuthStore.getUserAccountType(
		Number(accountType)
	);

	const user = getUserFromLocalStorage(userType);

	if (App.state.rootStore.userAuthStore.userAccounts) {
		for (const entry of App.state.rootStore.userAuthStore.userAccounts) {
			if (
				entry.abrv === "sport-betting-account-online" &&
				user?.id === entry.userId
			) {
				bettingAccountTypeId = entry.accountTypeId;
			}
			if (
				entry.abrv === "sport-betting-account-shop-online" &&
				user?.id === entry.userId
			) {
				bettingAccountTypeId = entry.accountTypeId;
			}
		}
	}

	return bettingAccountTypeId;
}

export function getTopLevelDomain(url: string) {
	const TLD = new URL(url);
	return "." + TLD.hostname.split(/\./).slice(-2).join(".");
}
