import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useRootOfferStore } from "@hooks";

export default observer(function OneClickBetNotification() {
	const betSlipStore = useRootOfferStore().betSlipStore;
	const { t } = useTranslation();

	if (!betSlipStore.isOneClickBetActive) {
		return null;
	}

	return (
		<div className="message--oneclick is-active">
			<div className="message--oneclick__title">
				<i className="u-icon u-icon--lrg u-icon--one-click u-color--ui--success u-align--v--middle u-mar--right--xsml" />
				<span className="u-align--v--middle">
					{t<string>("BET_SLIP.ONE_CLICK_BET.ACTIVE_MESSAGE")}
				</span>
			</div>
			<div className="u-type--sml u-mar--bottom--xsml">
				{t<string>("BET_SLIP.ONE_CLICK_BET.ALERT")}
			</div>
			<button
				className="btn btn--tny btn--secondary--inverted u-color--ui--danger"
				onClick={betSlipStore.onDeactivateOneClickBet}
			>
				{t<string>("BET_SLIP.ONE_CLICK_BET.DEACTIVATE")}
			</button>
		</div>
	);
});
