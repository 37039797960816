import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";
import { Link } from "react-router-dom";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { MostPlayTicketActionsTypeEnum } from "@data-types/analytics/QuickPlayInteractionTypeEnum";

export default function MostPlayedTicketsAsideWidgets() {
	const { t } = useTranslation();
	const culture = getCurrentCulture();

	return (
		<div className="widget widget--stats">
			<div className="widget__banner">
				<Link
					to={`/${getCurrentCulture()}/quick-play/most-played-tickets`}
					className="widget__banner__link"
					onClick={() =>
						AnalyticsService.logQuickPlayInteraction({
							category: MostPlayTicketActionsTypeEnum.PageVisit,
						})
					}
				>
					<i className="widget__banner__icon u-icon u-icon--huge u-icon--bet-most-played-tickets" />
					<div className="widget__banner__content">
						<div className="widget__banner__tag">
							<div className="tag tag--med tag--sunny">
								{t("CASINO.NEW_GAME_LABEL")}
							</div>
						</div>
						<div className="widget__banner__title">
							{t("QUICK_PLAY.MOST_PLAYED_TICKETS.TITLE")}
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
}
