import React from "react";
import { AccountLockForm } from ".";
import { FormProvider, useForm } from "react-hook-form";

export default function AccountLockPage() {
	const methods = useForm({ mode: "onChange" });
	return (
		<FormProvider {...methods}>
			<AccountLockForm />
		</FormProvider>
	);
}
