import React from "react";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";

export default function InfoMessageComponent(props: {
	type?: "danger" | "warning" | "note" | "success" | "empty";
	size?: "base" | "sml";
	message?: string;
	addClassName?: string;
	textClass?: string;
	emptyIcon?: string;
	isMarkdown?: boolean;
}) {
	const {
		size,
		type,
		textClass,
		message,
		addClassName,
		emptyIcon,
		isMarkdown,
	} = props;

	// Classes for info messages

	const messageTypeClasses = classNames(
		"message",
		{
			"message--base": size === undefined || size === "base",
			[`message--${size}`]: size !== undefined && size !== "base",
			[`message--${type}`]: type !== undefined,
		},
		addClassName
	);

	const messageIconClasses = classNames("message__icon u-icon", {
		"u-icon--remove-circle u-color--ui--danger": type === "danger",
		"u-icon--alert-circle u-color--ui--warning": type === "warning",
		"u-icon--info-circle u-color--ui--note": type === "note",
		"u-icon--check-circle u-color--ui--success": type === "success",
		"u-icon--lrg": size === undefined || size === "base",
		"u-icon--big": size === "sml",
	});

	// Classes for empty state

	const emptyWrapperClasses = classNames("card--empty", addClassName);

	const emptyIconClasses = classNames(
		"card--empty__icon u-icon",
		{
			"u-icon--huge": size === undefined || size === "base",
			"u-icon--lrg": size === "sml",
		},
		"u-icon--empty",
		emptyIcon
	);

	const emptyMsgClasses = classNames("card--empty__message", textClass);

	if (type === "empty") {
		return (
			<div className={emptyWrapperClasses}>
				<div className="card--empty__content">
					{emptyIcon !== undefined && (
						<i className={emptyIconClasses} />
					)}
					<div className={emptyMsgClasses}>{message}</div>
				</div>
			</div>
		);
	}

	if (isMarkdown) {
		return (
			<div className={messageTypeClasses}>
				{type !== undefined && <i className={messageIconClasses} />}
				<div className="message__content">
					<div className={textClass}>
						<ReactMarkdown className="u-anchor--nested">
							{message as string}
						</ReactMarkdown>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={messageTypeClasses}>
			{type !== undefined && <i className={messageIconClasses} />}
			<div className="message__content">
				<div className={textClass}>{message}</div>
			</div>
		</div>
	);
}
