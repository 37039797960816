import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AccountActivationForm } from ".";

export default function AccountActivationPage() {
	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			email: "",
		},
	});

	return (
		<FormProvider {...methods}>
			<AccountActivationForm />
		</FormProvider>
	);
}
