import {
	FormDataType,
	PasswordSimilarityRequestModel,
} from "@data-types/membership/registration";
import { httpClient, isNullOrWhitespace } from "@utils";
import { getAgencyKey, getCurrentCulture, getApplicationHeaders } from "@utils";
import { getRedirectOriginUrl } from "@utils/specific/capacitor";
import { DateTime } from "luxon";

type RegistrationFormDataType = FormDataType & {
	challengeResponse: string;
};

class RegistrationService {
	getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	checkUserNameAvailability(username: string) {
		const body = JSON.stringify(username);
		return httpClient.post(
			`${WEBPACK_BASE_ADDRESS}platform/agency-users/userName-availability`,
			body,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	checkIsEmailAvailable(email: string) {
		const body = JSON.stringify(email);
		const checkEmailUrl = `${WEBPACK_BASE_ADDRESS}platform/agency-users/email-availability`;
		return httpClient.post(
			`${checkEmailUrl}`,
			body,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	checkPasswordSimilarity(dataset: PasswordSimilarityRequestModel) {
		const checkPasswordUrl = `${WEBPACK_BASE_ADDRESS}platform/agency-user-passwords/password-similarity`;
		return httpClient.post(
			checkPasswordUrl,
			JSON.stringify(dataset),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	registerTerminalUser(
		formData: Omit<RegistrationFormDataType, "challengeResponse">,
		affiliateCode: string | null,
		terminalShopId: string
	): Promise<Response> {
		//use only date not time
		formData.dob = DateTime.fromISO(formData.dob.toString()).toFormat(
			"yyyy-MM-dd"
		);

		if (formData.firstName && !isNullOrWhitespace(formData.firstName)) {
			formData.firstName = formData.firstName.trim();
		}

		if (formData.lastName && !isNullOrWhitespace(formData.lastName)) {
			formData.lastName = formData.lastName.trim();
		}

		const body = {
			...formData,
			applicationIdentifier: getAgencyKey(),
			activationUrl: getRedirectOriginUrl(
				`auth/register-activation?activationToken={{activationToken}}`
			),
		};

		if (affiliateCode !== null) {
			Object.assign(body, { affiliateCode: affiliateCode });
		}

		const url = `${WEBPACK_BASE_ADDRESS}platform/gp/register/retail-on-web`;
		return httpClient.post(url, JSON.stringify(body), {
			...this.getRequestHeaders(),
			"Shop-Id": terminalShopId,
		});
	}

	registerUser(
		formData: RegistrationFormDataType,
		affiliateCode: string | null
	) {
		//use only date not time
		formData.dob = DateTime.fromISO(formData.dob.toString()).toFormat(
			"yyyy-MM-dd"
		);

		if (formData.firstName && !isNullOrWhitespace(formData.firstName)) {
			formData.firstName = formData.firstName.trim();
		}

		if (formData.lastName && !isNullOrWhitespace(formData.lastName)) {
			formData.lastName = formData.lastName.trim();
		}

		const body = {
			...formData,
			applicationIdentifier: getAgencyKey(),
			activationUrl: getRedirectOriginUrl(
				"auth/register-activation?activationToken={{activationToken}}"
			),
		};

		if (affiliateCode !== null) {
			Object.assign(body, { affiliateCode: affiliateCode });
		}

		const url = `${WEBPACK_BASE_ADDRESS}platform/gp/register`;

		return httpClient.post(
			url,
			JSON.stringify(body),
			this.getRequestHeaders()
		);
	}

	fetchGamingLimits() {
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/tw-gaming-limits/compile-default`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}
}

export default RegistrationService;
