import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { isNullOrWhitespace } from "@v2/helpers/utility";
import { DatePicker } from "@v2/components/desktop/shared/inputs";
import { DateTime } from "luxon";
export default function AccountStatementCustomDate({
	showCustomDateSection,
}: {
	showCustomDateSection: boolean;
}) {
	const { trigger, getValues, unregister } = useFormContext();

	useEffect(() => {
		//cannot use getFieldState().isTouched because of ReactDateTimePicker
		//check if both fields have values and then trigger revalidation on endDate field
		if (
			!isNullOrWhitespace(getValues("startDate")) &&
			!isNullOrWhitespace(getValues("endDate"))
		) {
			trigger("endDate");
		}
	}, [useWatch({ name: "startDate" })]);

	useEffect(() => {
		return () => {
			unregister("startDate");
			unregister("endDate");
		};
	}, []);

	if (!showCustomDateSection) {
		return null;
	}

	return (
		<div className="form__group">
			<div className="row">
				<DatePicker
					label="ACC_STATEMENT.FILTER.START_DATE"
					name="startDate"
					className="form--grid__col col col-sml-6"
					required
				/>

				<DatePicker
					label="ACC_STATEMENT.FILTER.END_DATE"
					name="endDate"
					className="form--grid__col col col-sml-6"
					validate={{
						isBiggerThanStartDate: (value: string) => {
							const formattedStartDate = DateTime.fromISO(
								getValues("startDate")
							)
								.startOf("day")
								.toISO();
							const formattedEndDate = DateTime.fromISO(value)
								.endOf("day")
								.toISO();

							return (
								formattedEndDate > formattedStartDate ||
								"COMMON.ERROR_MESSAGES.END_DATE_LOWER"
							);
						},
					}}
					required
				/>
			</div>
		</div>
	);
}
