import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFixBody } from "@v2/hooks/shared";
import { useRootAppStore } from "@hooks";
import FormInputField from "../inputs/FormInputField";
import { FormProvider, useForm } from "react-hook-form";
import { CheckboxField } from "../inputs";
import { observer } from "mobx-react";
import { FormDataType } from "@data-types/membership/registration";
import GoogleRecaptchaWrapper from "@v2/components/shared/GoogleRecaptchaWrapper";
import { FormButton } from "@components/buttons";
import { EmailPattern } from "@common/constants";
import ReactMarkdown from "react-markdown";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { MarketingInteractionTypeEnum } from "@data-types/analytics/MarketingInteractionTypeEnum";

export default observer(function OnboardingNewsletterPopup(props: {
	isOpened?: boolean;
}) {
	const { t } = useTranslation();

	const {
		onboardingWelcomePopupStore: {
			onboardingNewsletterStore: { acceptNewsletter, hidePopup },
		},
	} = useRootAppStore();

	useFixBody(true, props.isOpened);

	const methods = useForm({
		mode: "onChange",
	});

	const { handleSubmit } = methods;

	const submitFunction = async (formData: FormDataType) => {
		await acceptNewsletter(formData.email, ReCaptchaChallengeKey);
	};

	return (
		<GoogleRecaptchaWrapper>
			<div className="at-newsletterModal popup popup--bonus">
				<div className="popup__card popup--bonus__card">
					<div className="popup--bonus__header">
						<img
							className="popup--bonus__logo"
							src="/assets/images/logo-vibrant.svg"
							alt=""
						/>

						<button
							type="button"
							className="popup--bonus__close btn btn--round btn--round--tny btn--secondary--inverted btn--icon"
							onClick={() => {
								AnalyticsService.logMarketingInteraction({
									category:
										MarketingInteractionTypeEnum.NewsletterClose,
								});
								hidePopup();
							}}
						>
							<i className="u-icon u-icon--xsml u-icon--close u-color--text--primary" />
						</button>
					</div>

					<div className="popup--bonus__body">
						<FormProvider {...methods}>
							<form
								className="form popup--bonus__content"
								onSubmit={handleSubmit(submitFunction)}
							>
								<div className="popup--bonus__text">
									<div className="popup--bonus__title">
										<ReactMarkdown className="">
											{t(
												"USER.POPUPS.ONBOARDING_WELCOME_POPUPS.NEWSLETTER.TITLE"
											)}
										</ReactMarkdown>
									</div>
									<div className="popup--bonus__desc">
										{t(
											"USER.POPUPS.ONBOARDING_WELCOME_POPUPS.NEWSLETTER.DESCRIPTION"
										)}
									</div>

									<FormInputField
										{...methods.register("email")}
										type="email"
										pattern={EmailPattern}
										placeholder="USER.POPUPS.ONBOARDING_WELCOME_POPUPS.NEWSLETTER.EMAIL_PLACEHOLDER"
										inputClassName="input input--base"
										required
									/>

									<CheckboxField
										id="checkbox"
										name="newsletter-checkbox"
										label="USER.POPUPS.ONBOARDING_WELCOME_POPUPS.NEWSLETTER.CHECKBOX_PLACEHOLDER"
										checkboxClassname="form__group u-mar--bottom--reset"
										labelClassName="u-display--f u-type--xsml u-color--text--secondary"
										required
									/>
								</div>

								<div className="popup--bonus__footer">
									<FormButton
										type="submit"
										className="popup--bonus__btn btn btn--base btn--primary"
										btnText="USER.POPUPS.ONBOARDING_WELCOME_POPUPS.NEWSLETTER.SUBSCRIBE_BTN"
										onClick={() => {
											AnalyticsService.logMarketingInteraction(
												{
													category:
														MarketingInteractionTypeEnum.NewsletterConfirm,
												}
											);
										}}
									/>
								</div>
							</form>
						</FormProvider>

						<div className="popup--bonus__media">
							<img
								className="popup--bonus__img"
								src="/assets/images/bg/athlete.png"
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</GoogleRecaptchaWrapper>
	);
});
