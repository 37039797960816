import React, { FC } from "react";
import { PaymentOption } from "@api-types/deposit";
import { observer } from "mobx-react";

export const DepositPaymentMethodMaskedPan: FC<{
	paymentMethod: PaymentOption;
}> = observer(function PaymentMethodMaskedPan(props: {
	paymentMethod: PaymentOption;
}) {
	const { paymentMethod } = props;
	if (
		paymentMethod.hasPaymentReference === true &&
		paymentMethod.hasMultipleReferences === false
	) {
		//with one reference
		return (
			<span className="card--payment__card">
				{paymentMethod.maskedPan}
			</span>
		);
	} else if (
		paymentMethod.hasPaymentReference === false &&
		paymentMethod.hasMultipleReferences === true &&
		paymentMethod.paymentReferences != null
	) {
		//with multiple references
		return (
            <span className="card--payment__card">
				{paymentMethod.paymentReferences.map((paymentReference, i) => (
					<span
						key={paymentReference.referenceId}
						className="card--payment__item"
					>
						{paymentReference.maskedPan}
						{/* add comma to item if not last item */}
						{paymentMethod.paymentReferences.length - 1 !== i &&
							","}
					</span>
				))}
            </span>
		);
	}

	//without reference ( masked pan probably does not exist here)
	return (
		<span className="card--payment__card">{paymentMethod.maskedPan}</span>
	);
});
