export const isRegistrationEnabled = EnabledFeatures.includes("registration");

export const isCasinoEnabled = EnabledFeatures.includes("casino");

export const isLiveCasinoEnabled = EnabledFeatures.includes("casino-live");

export const isAutoCashoutEnabled = EnabledFeatures.includes("autocashout");

export const isWelcomeTextEnabled = EnabledFeatures.includes("welcomeInfo");

export const isAccountVerificationSectionEnabled =
	EnabledFeatures.includes("verification-popup");

export const isMitIdLoginEnabled = EnabledFeatures.includes("mitIdLogin");

//prettier-ignore
export const isPlayerMoneyProtectionEnabled = EnabledFeatures.includes("playerMoneyProtection");

//prettier-ignore
export const isWhistleblowingEnabled = EnabledFeatures.includes("whistleblowingChannel");

//prettier-ignore
export const isAutomaticLogoutEnabled = EnabledFeatures.includes("automatic-logout");

//prettier-ignore
export const isSpelpausEnabled = EnabledFeatures.includes("Spelpaus");

//prettier-ignore
export const isGamingSummaryEnabled = EnabledFeatures.includes("gamingSummary");

//prettier-ignore
export const isPersonalNumberEnabled = EnabledFeatures.includes("shouldShowPersonalNumber");

//prettier-ignore
export const isGamingHistoryReportEnabled = EnabledFeatures.includes("gaming-history-report");

//prettier-ignore
export const isHeaderSEIconsEnabled = EnabledFeatures.includes("headerSEIcons");

//prettier-ignore
export const isCoinPusherAvailable = EnabledFeatures.includes("coin-pusher");

//prettier-ignore
export const isCapacitorEnabled = EnabledFeatures.includes("capacitor");

//prettier-ignore
export const is24hLockEnabled = EnabledFeatures.includes("24h-lock");

//prettier-ignore
export const isChatInOfferAvailable = EnabledFeatures.includes("chat-in-offer");
