import { action, observable } from "mobx";
import CapacitorUpdaterStore from "./CapacitorUpdaterStore";

export default class CapacitorUpdatePopupStore {
	@observable isPopupVisible = false;
	@observable shouldForceUpdate = false;
	@observable shouldForceNativeUpdate = false;

	capacitorUpdateStore: CapacitorUpdaterStore;

	constructor(capacitorUpdateStore: CapacitorUpdaterStore) {
		this.capacitorUpdateStore = capacitorUpdateStore;
	}

	@action.bound
	declineUpdate() {
		this.isPopupVisible = false;
		this.shouldForceUpdate = false;

		this.capacitorUpdateStore.declineUpdate();
	}

	@action.bound
	acceptUpdate() {
		this.isPopupVisible = false;
		this.shouldForceUpdate = false;

		this.capacitorUpdateStore.acceptUpdate(this.shouldForceNativeUpdate);
	}

	@action.bound
	onDispose() {
		this.isPopupVisible = false;
		this.shouldForceUpdate = false;
	}
}
