import {
	httpClient,
	getAgencyKey,
	getCurrentLanguage,
	getApplicationHeaders,
} from "@utils";

import { TOfferRequest } from "@gp/hub";
import { OfferSubscriptionResponse, PageableOfferResponse } from "@gp/models";

export class PrematchOfferApiService {
	private static GetRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		// @ts-expect-error
		appHeaders["Content-Type"] = "application/json";
		return appHeaders;
	};

	/** Fetch event additional offer. */
	static async GetEventAdditionalOffer(filter: {
		eventIds: string[];
	}): Promise<OfferSubscriptionResponse> {
		const baseUrl = `${WEBPACK_BASE_PROXY_ADDRESS}${getAgencyKey()}/offer`;
		var f = {
			subscriptionCallback: "not-relevant",
			channels: [
				{
					name: "event",
					filter: {
						id: {
							eq: filter.eventIds,
						},
					},
				},
				{
					name: "betOffer",
				},
			],
			numberOfEvents: null,
			language: getCurrentLanguage(),
		};

		const response = await httpClient.post(
			baseUrl,
			JSON.stringify(f),
			this.GetRequestHeaders(),
			{
				responseType: "json",
			}
		);

		return response;
	}

	/** Fetch event additional offer. */
	static async GetOffer(
		filter: TOfferRequest
	): Promise<PageableOfferResponse> {
		const baseUrl = `${WEBPACK_BASE_PROXY_ADDRESS}${getAgencyKey()}/offer/data`;

		const response = await httpClient.post(
			baseUrl,
			JSON.stringify(filter),
			this.GetRequestHeaders(),
			{
				responseType: "json",
			}
		);

		return response;
	}
}

export default PrematchOfferApiService;
