import React from "react";
import { useRootAppStore } from "@hooks";

export default function ChatMinimizeButton() {
	const { chatViewStore } = useRootAppStore();

	const minimizeChatWindow = () => {
		chatViewStore.toggleChatBox(false);
	};

	return (
		<button
			className="chat__btn--mini btn btn--square btn--square--tny btn--icon btn--secondary--inverted"
			onClick={minimizeChatWindow}
		>
			<i className="u-icon u-icon--xsml u-icon--minimize u-color--text--primary" />
		</button>
	);
}
