import React from "react";
import { Link } from "react-router-dom";
import { getCurrentCulture } from "@utils";
import { isHeaderSEIconsEnabled } from "@v2/helpers";

export default function HeaderIconActions() {
	if (!isHeaderSEIconsEnabled) {
		return null;
	}

	return (
		<div className="header__links">
			<HeaderLinks
				icon="game-pause"
				title="Game pause"
				to="https://spelpaus.se"
			/>
			<HeaderLinks
				icon="limit"
				title="Limit"
				to={`/${getCurrentCulture()}/account-settings/my-limits`}
				isInApp={true}
			/>
			<HeaderLinks
				icon="self-test"
				title="Self test"
				to="https://stodlinjen.se/spelberoende-test-pgsi"
			/>
		</div>
	);
}

function HeaderLinks(props: {
	icon: string;
	title: string;
	to: string;
	isInApp?: boolean;
}) {
	return (
		<HeaderLinkWrapper to={props.to} isInApp={props?.isInApp}>
			<>
                <i className={`header__links__icon u-icon u-icon--med u-icon--${props.icon}`} />
                <span className="header__links__label">{props.title}</span>
			</>
		</HeaderLinkWrapper>
	);
}

function HeaderLinkWrapper(props: {
	to: string;
	isInApp?: boolean;
	children: JSX.Element;
}) {
	//handle router or usual redirection
	if (props.isInApp) {
		return (
            <Link to={props.to} className="header__links__item u-anchor u-anchor--secondary">
				{props.children}
			</Link>
		);
	}
	return (
        <a href={props.to} target="_blank" className="header__links__item u-anchor u-anchor--secondary">
			{props.children}
		</a>
	);
}
