import { isCapacitorPlatform } from "@utils/specific/capacitor";
import { LocalStorage, CapacitorStorage } from ".";
import { StorageStateKeysEnum, ILocalStorageProvider } from ".";

export default class LocalStorageProvider {
	isNative: boolean;
	provider: ILocalStorageProvider;
	constructor() {
		this.isNative = isCapacitorPlatform();
		this.initializeProvider();
	}

	initializeProvider = () => {
		if (this.isNative) {
			this.provider = new CapacitorStorage();
		} else {
			this.provider = new LocalStorage();
		}
	};

	onInitialize = async () => {
		await this.provider.initialLoad();
	};

	get = (key: StorageStateKeysEnum): unknown => {
		return this.provider.get(key);
	};

	set = (key: StorageStateKeysEnum, value: any) => {
		this.provider.set(key, value);
	};

	remove = (key: StorageStateKeysEnum) => {
		this.provider.remove(key);
	};

	flush = () => {
		this.provider.flush();
	};
}
