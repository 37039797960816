import React, { useContext, useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import { BRStatistics } from "./components";
import { ResultDataWrapper, ResultsMenu } from "./components";
import { HeaderWithSideMenu } from "@v2/components/desktop/shared/header";
import { Footer } from "@v2/components/desktop/shared/footer";
import { useRootOfferStore } from "@hooks";
import { useTranslation } from "react-i18next";
import { setTabTitle } from "@utils";
import { ResultsMenuStore } from "@state/results";
import { useLoading } from "@hooks";
import { ResultsMenuStoreContext } from "@context/results";
import classnames from "classnames";
import { SelectedSportWrapper } from "./components/SelectedSportWrapper";

export default observer(function ResultsPage() {
	const offerStore = useRootOfferStore();
	const resultsMenuStore = useMemo(
		() => new ResultsMenuStore(offerStore),
		[]
	);
	const { setIsLoading } = useLoading();
	const { isMenuExpanded } = resultsMenuStore;

	const { t } = useTranslation();

	setIsLoading(resultsMenuStore.isLoading);

	useEffect(() => {
		setTabTitle(t("RESULTS.TITLE"));
		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
	}, []);

	const asideClasses = classnames("l--aside--left", {
		"is-expanded": isMenuExpanded,
	});

	const mainClasses = classnames("l--main l--main--med", {
		"sidebar-left-expanded": isMenuExpanded,
	});

	return (
		<>
			<HeaderWithSideMenu />
			<main role="main" className={mainClasses}>
				<ResultsMenuStoreContext.Provider value={resultsMenuStore}>
					<aside className={asideClasses}>
						<nav className="nav--secondary nav--results">
							{/* <ToggleButton /> */}
							<BRStatistics />
							<ResultsMenu />
						</nav>
						<SelectedSportWrapper />
					</aside>
					<ResultDataWrapper />
				</ResultsMenuStoreContext.Provider>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</>
	);
});

const ToggleButton = function ToggleButton() {
	const { toggleMenuExpanded } = useContext(ResultsMenuStoreContext);

	return (
		<div
			className="l--header__toggle"
			onClick={() => toggleMenuExpanded(true)}
		>
			<button className="l--header__toggle__btn" type="button">
				<span></span>
				<span></span>
				<span></span>
			</button>
		</div>
	);
};
