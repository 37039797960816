import { RealityCheckDto } from "@data-types/reality-check/RealityCheckDto";
import {
	httpClient,
	getAgencyKey,
	getApplicationHeaders,
	UserTypes,
} from "@utils";
import { isCasinoEnabled, isLiveCasinoEnabled } from "@v2/helpers";

class RealityCheckService {
	getRequestHeaders = (user: UserTypes = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(user);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	async getRealityCheckData(
		user: UserTypes = UserTypes.PRIMARY
	): Promise<RealityCheckDto> {
		let url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(user)}/reality-checks`;
		if (isCasinoEnabled && isLiveCasinoEnabled) {
			url += "?isCasinoEnabled=true&isLiveCasinoEnabled=true";
		} else if (isCasinoEnabled) {
			url += "?isCasinoEnabled=true";
		} else if (isLiveCasinoEnabled) {
			url += "?isLiveCasinoEnabled=true";
		}

		return await httpClient.get(url, this.getRequestHeaders(user), {
			responseType: "json",
		});
	}

	async resolveRealityCheckData(
		userId: string,
		logout: boolean = false
	): Promise<void> {
		const requestHeaders = getApplicationHeaders();
		// @ts-expect-error
		requestHeaders["Content-Type"] = "application/x-www-form-urlencoded";
		const resolveUrl = `${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/tw-reality-checks/`;
		return await httpClient.get(
			`${resolveUrl}${userId}?logout=${logout}`,
			requestHeaders
		);
	}
}

const realityCheckService = new RealityCheckService();
export { realityCheckService };
