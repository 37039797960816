import {
	Client,
	ChannelRepository,
	MessageRepository,
	MessageContentType,
} from "@amityco/ts-sdk";
import { ActiveSharedBetSlip } from "@data-types/chat";

class ChatSDKService {
	/**
	 *
	 * @param errorCode undefined | number
	 * @returns {
	 * 		errorName: string;
	 * 		localizedErrorKey: string;
	 * 		blocking: boolean;
	 * }
	 */
	static getClientErrors(errorCode?: number): {
		errorName: string;
		isBlocking: boolean;
		localizedErrorKey: string;
	} | null {
		if (!errorCode) {
			return null;
		}

		let errorName;
		let isBlocking = false;
		let localizedErrorKey;
		switch (errorCode) {
			case 400000:
				//Request contains invalid parameters.
				errorName = "BadRequestError";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				isBlocking = true;
				break;
			case 400001:
				//An invalid regex rule retrieved from or added to the Blocklist
				// errorName = "InvalidRegularExpression";
				// localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				// this error can happen if someone in admin dashboard ads an invalid regex rule,
				// user should not see an error message in this case
				errorName = null;
				localizedErrorKey = null;
				break;
			case 400002:
				//Video format not supported
				errorName = "VideoFormatInvalidRequestError";
				localizedErrorKey =
					"CHAT.ERR_HANDLING.VIDEO_FORMAT_NOT_SUPPORTED";
				break;
			case 400100:
				//Unverified user performs any action that requires access token verification.
				errorName = "UnauthorizedError";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				isBlocking = true;
				break;
			case 400300:
				//User performs forbidden action such as uploading a pdf file in an image message.
				errorName = "ForbiddenError";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				break;
			case 400301:
				//User has no permission to perform the action.
				errorName = "PermissionDenied";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				isBlocking = true;
				break;
			case 400302:
				//A muted user sends a message.
				errorName = "UserIsMuted";
				localizedErrorKey = "CHAT.ERR_HANDLING.USER_MUTED";
				break;
			case 400303:
				//User sends a message in a muted channel.
				errorName = "ChannelIsMuted";
				localizedErrorKey = "CHAT.ERR_HANDLING.CHANNEL_MUTED";
				break;
			case 400304:
				//User accessed a channel or community where he is banned.
				errorName = "UserIsBanned";
				localizedErrorKey =
					"CHAT.ERR_HANDLING.USER_BANNED_FROM_CHANNEL";
				isBlocking = true;
				break;
			case 400307:
				//User reached the limit of the number of sent messages containing blocklisted words.
				errorName = "MaxRepetitionExceed";
				localizedErrorKey =
					"CHAT.ERR_HANDLING.REACHED_BLOCKLIST_WORD_LIMIT";
				break;
			case 400308:
				//User sends a message that contains a blocklisted word.
				errorName = "BanWordFound";
				localizedErrorKey =
					"CHAT.ERR_HANDLING.MSG_INCLUDES_BLACKLIST_WORD";
				break;
			case 400309:
				//User sends a message that contains link that is not in the Allowlist.
				errorName = "LinkNotAllowed";
				localizedErrorKey = "CHAT.ERR_HANDLING.LINK_NOT_WHITELISTED";
				break;
			case 400311:
				//Web socket rate limit is exceeded.
				errorName = "RPCRateLimitError";
				localizedErrorKey = "CHAT.ERR_HANDLING.TOO_MANY_ACTIONS";
				break;
			case 400312:
				//Banned user performs any action.
				errorName = "GlobalBanError";
				localizedErrorKey = "CHAT.ERR_HANDLING.GLOBAL_BAN";
				isBlocking = true;
				break;
			case 400314:
				//Content moderation system detects unsafe content (eg. nudity).
				errorName = "UnsafeContent";
				localizedErrorKey = "CHAT.ERR_HANDLING.UNSAFE_CONTENT_DETECTED";
				break;
			case 400315:
				//Display name of a collection already exists (eg. for your channels or community categories).
				errorName = "DuplicateEntryError";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				break;
			case 400316:
				//Returned when unbanning a user that is not banned.
				errorName = "UserIsUnbanned";
				localizedErrorKey = "CHAT.ERR_HANDLING.UNBAN_ACTION_ERROR";
				break;
			case 400317:
				//The only active moderator in a channel/community attempts to leave and there are no other moderators in the group.
				errorName = "ForbiddenError";
				localizedErrorKey =
					"CHAT.ERR_HANDLING.LEAVE_CHANNEL_NOT_POSSIBLE";
				break;
			case 400318:
				//The only moderator in a channel/community attempts to leave and there are no other members in the group.
				errorName = "ForbiddenError";
				localizedErrorKey =
					"CHAT.ERR_HANDLING.LEAVE_CHANNEL_NOT_POSSIBLE";
				break;
			case 400319:
				//User changes module and user notification settings but the network notification setting is off.
				errorName = "ForbiddenError";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				isBlocking = true;
				break;
			case 400400:
				//System cannot find any resource matched with the requested condition.
				errorName = "ItemNotFound";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				isBlocking = true;
				break;
			case 400900:
				//System cannot create/update duplicated data.
				errorName = "Conflict";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				isBlocking = true;
				break;
			case 500000:
				//Uncategorized internal system errors not related to any user input./Video upload failed
				errorName = "ForbiddenError";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				isBlocking = false;
				break;
			case 800000:
				//Uncategorized errors.
				//To debug, refer to the 'error.message' property.
				errorName = "Unknown";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				break;
			case 800110:
				//Data type of the parameter is invalid.
				errorName = "InvalidParameter";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				break;
			case 800210:
				//Websocket connection of the SDK cannot reach the platform server. This could also be the case if a user is global-banned and try to register a session.
				errorName = "ConnectionError";
				localizedErrorKey = "COMMON.ERROR_MESSAGES.DEFAULT";
				isBlocking = true;
				break;
			default:
				errorName = null;
				localizedErrorKey = null;
		}

		if (!errorName || !localizedErrorKey) {
			//Not client error
			return null;
		}

		return {
			errorName,
			localizedErrorKey,
			isBlocking,
		};
	}

	static returnCurrentClient(): Amity.Client | null {
		try {
			//sdk throws an error if client does not exist
			return Client.getActiveClient();
		} catch (e) {
			return null;
		}
	}

	static createClientInstance(
		apiKey: string,
		apiRegion: string
	): Amity.Client {
		return Client.createClient(apiKey, apiRegion);
	}

	static async destroyClientInstance(): Promise<boolean> {
		return await Client.logout();
	}

	static async loginUser(
		userId: string,
		authToken: string,
		displayName: string,
		sessionHandler: Amity.SessionHandler
	): Promise<boolean> {
		return await Client.login(
			{ userId: userId, displayName: displayName, authToken: authToken },
			sessionHandler
		);
	}

	static getChannelInfo(
		channelId: string,
		callback: Amity.LiveObjectCallback<Amity.Channel>
	): Amity.Unsubscriber {
		return ChannelRepository.getChannel(channelId, callback);
	}

	static async joinChannel(channelId: string): Promise<boolean> {
		return await ChannelRepository.joinChannel(channelId);
	}

	static async leaveChannel(channelId: string): Promise<boolean> {
		return await ChannelRepository.leaveChannel(channelId);
	}

	static subscribeToNewMessages(
		subChannelId: string,
		callback: Amity.LiveCollectionCallback<Amity.Message>,
		msgLimit: number = 30
	): Amity.Unsubscriber {
		return MessageRepository.getMessages(
			{
				subChannelId: subChannelId,
				limit: msgLimit,
			},
			callback
		);
	}

	static async createMessage(
		subChannelId: string,
		message: string,
		isLink?: boolean
	): Promise<Amity.Cached<Amity.Message>> {
		return await MessageRepository.createMessage({
			subChannelId: subChannelId,
			dataType: MessageContentType.TEXT,
			data: {
				text: message,
			},
			metadata: {
				isLink: isLink,
			},
		});
	}

	static async createCustomMessage(
		subChannelId: string,
		sharedSlip: ActiveSharedBetSlip,
		msg: string,
		isLink?: boolean
	) {
		const customMessage = {
			subChannelId: subChannelId,
			dataType: MessageContentType.CUSTOM,
			data: {
				msg: msg,
				sharedSlip: sharedSlip,
			},
			metadata: {
				isLink: isLink,
			},
		};

		const { data: message } = await MessageRepository.createMessage(
			customMessage
		);

		return message;
	}

	static createSessionHandler(authToken: string): Amity.SessionHandler {
		const sessionHandler: Amity.SessionHandler = {
			sessionWillRenewAccessToken(renewal: Amity.AccessTokenRenewal) {
				try {
					renewal.renewWithAuthToken(authToken);
				} catch (err) {
					renewal.unableToRetrieveAuthToken();
				}
			},
		};

		return sessionHandler;
	}

	static async flagMessage(messageId: string) {
		const isFlagged = await MessageRepository.flagMessage(messageId);
		return isFlagged;
	}

	static async unFlagMessage(messageId: string) {
		const isUnFlagged = await MessageRepository.unflagMessage(messageId);
		return isUnFlagged;
	}

	static async isMessageFlaggedByMe(messageId: string) {
		const isFlaggedByMe = await MessageRepository.isMessageFlaggedByMe(
			messageId
		);
		return isFlaggedByMe;
	}
}

export default ChatSDKService;
