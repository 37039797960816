import React from "react";
import { observer } from "mobx-react";

import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";

import { MyBetsViewStore } from "@offer/stores/components/my-bets";

const BetListDisplayHeader = observer(function BetListDisplayHeader(props: {
	myBetsViewStore: MyBetsViewStore;
}) {
	const { t } = useTranslation();

	return (
		<div
			className={"widget__header widget__header--" + getCurrentCulture()}
		>
			<div className="widget__header__title">
				{t<string>("MY_BETS.PAGE.TITLE")}
			</div>
			{!props.myBetsViewStore.isEmpty &&
				App.state.rootStore.userAuthStore.user != null && (
					<a
						href={`/${getCurrentCulture()}/my-bets`}
						className="widget__header__btn btn btn--xtny btn--ghost btn--icon btn--animate-forward u-padd--x--reset"
					>
						<span>{t<string>("MY_BETS.VIEW_OLDER_BETS")}</span>
						<i className="u-icon u-icon--xsml u-icon--arrow--right" />
					</a>
				)}
		</div>
	);
});

export default BetListDisplayHeader;
