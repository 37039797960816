import { observable, action, computed } from "mobx";
import { localizationService } from "@state";
import { getRedirectOriginUrl } from "@utils/specific/capacitor";
import RootOfferStore from "@offer/stores/RootStore";
// @ts-expect-error
import MyBetsStore from "./MyBetsStore.ts";
import LoaderStore from "@state/stores/common/LoaderStore.js";
import { Bet } from "./model/Bet.js";
import { DeviceDetector, getCurrentCulture } from "@utils";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { BetSlipInteractionTypeEnum } from "@data-types/analytics/BetSlipInteractionTypeEnum";
import ModalService from "@services/modals/ModalService";

type ModalData = {
	id?: string;
	betSlipNumber?: string;
	isLive?: boolean;
	userId?: string;
};
class BetSlipShareStore {
	rootStore: RootOfferStore;
	myBetsStore: MyBetsStore;
	loader: LoaderStore;

	constructor(rootStore: RootOfferStore, myBetsStore: MyBetsStore) {
		this.rootStore = rootStore;
		this.myBetsStore = myBetsStore;
	}

	@observable isReuseShareModalOpen = false;
	@observable reuseShareModalData: ModalData | null;

	@observable isActive: boolean = false;

	@observable activeSharedBetSlip: Bet | null = null;

	@computed get isLoadingProcess(): boolean {
		return this.loader.isLoadingProcess;
	}

	@action.bound
	showPopup(sharedBetData: Bet) {
		this.activeSharedBetSlip = sharedBetData;
		this.isActive = true;

		AnalyticsService.logBetSlipInteraction(
			BetSlipInteractionTypeEnum.Share
		);
		ModalService.onOpen();
	}

	@action.bound
	hidePopup() {
		this.activeSharedBetSlip = null;
		this.isActive = false;
	}

	@action.bound
	onDispose() {
		this.activeSharedBetSlip = null;
		this.isActive = false;
		this.isReuseShareModalOpen = false;
		this.reuseShareModalData = null;
	}

	@action.bound
	reuseSharedBetSlip(isLive: boolean, bet: Bet) {
		// this.rootStore.prematchOfferMenuStore.closeOfferMenu();

		this.rootStore.betSlipStore.sharedSlipAndMap(bet);
		this.closeReuseBetSlipModal();

		if (DeviceDetector.isMobileTheme) {
			App.state.redirect(`/${getCurrentCulture()}/bet-slip`);
			return;
		}
		if (isLive) {
			App.state.redirect(`/${getCurrentCulture()}/live/events`);
		} else {
			App.state.redirect(`/${getCurrentCulture()}/sports/full`);
		}
	}

	/**
	 * Opens reuse bet slip modal
	 * @param {{id, betSlipNumber}} modalData
	 */
	@action.bound
	openReuseBetSlipModal(modalData: ModalData) {
		this.isReuseShareModalOpen = true;
		this.reuseShareModalData = modalData;
	}

	/**
	 * Closes reuse bet slip modal
	 */
	@action.bound
	closeReuseBetSlipModal() {
		this.isReuseShareModalOpen = false;
		this.reuseShareModalData = null;
	}

	async copyLink() {
		try {
			//this is code for copying link but execCommand is deprecated, using navigator.clipboard.writeText() is replacement but not working on http only https
			const el = document.createElement("textarea");
			el.value = `${getRedirectOriginUrl(
				`/bet-slip-shared/${this.activeSharedBetSlip?.betSlipNumber}`
			)}`;
			el.setAttribute("readonly", "");
			el.style.position = "absolute";
			el.style.left = "-9999px";
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);

			App.state.rootStore.notificationStore.success(
				localizationService.t("MY_BETS.SHARE.COPIED_LINK")
			);
		} catch (err) {
			console.error(err);
		}
	}
}

export default BetSlipShareStore;
