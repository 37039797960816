(function () {
	var originalFetch = fetch;
	// @ts-expect-error
	fetch = function () {
		return originalFetch.apply(this, arguments).then(function (data: any) {
			// TODO: add here anything for all requests!
			return data;
		});
	};
})();
