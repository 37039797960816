import { BaseLookupDto } from "@api-types/lookups/BaseLookupDto";

import { LookupDto } from "@api-types/LookupsDto";
import {
	LanguagesLookupModel,
	TitlesLookupModel,
	UserAccountLockDurationTypesModel,
	UserAccountLockReasonTypesModel,
	OasisLockReasonsTypesModel,
	CountriesLookupModel,
} from "@api-types/LookupsDto";

import {
	getAgencyKey,
	httpClient,
	UserTypes,
	AccountTypes,
	getWebApiUrl,
	getMvcApplicationHeaders,
	getApplicationHeaders,
} from "@utils";
import { BasicLookupModel } from "@api-types";
import { LimitTypeItem, TLimitDurationItem } from "@data-types";

export default class LookupApiService {
	static getRequestHeaders = (
		user: UserTypes = UserTypes.PRIMARY
	): HeadersInit => {
		const appHeaders = getApplicationHeaders(user);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static getMvcRequestHeaders = (
		user: UserTypes = UserTypes.PRIMARY
	): HeadersInit => {
		const appHeaders = getMvcApplicationHeaders(user);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async fetchGamingLimitDurationsLookup(): Promise<
		LookupDto<TLimitDurationItem>
	> {
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/gaming-limit-durations?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async fetchGamingLimitCategoriesLookup(
		activeAccount: UserTypes
	): Promise<LookupDto<LimitTypeItem>> {
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			activeAccount
		)}/gaming-limit-categories?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(activeAccount), {
			responseType: "json",
		});
	}

	static async fetchCountriesLookup(): Promise<
		LookupDto<CountriesLookupModel>
	> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/countries?sort=name|asc&rpp=1000&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async fetchLanguagesLookupByIso(
		isos: string
	): Promise<LookupDto<LanguagesLookupModel>> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/languages?sort=name|asc&rpp=1000&translate=true&isos=${isos}`;

		return await httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async fetchLanguagesLookup(): Promise<
		LookupDto<LanguagesLookupModel>
	> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/languages?sort=name|asc&rpp=1000&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async fetchTitlesLookup(): Promise<LookupDto<TitlesLookupModel>> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/user-titles?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async fetchUserAccountLockDurationTypes(): Promise<
		LookupDto<UserAccountLockDurationTypesModel>
	> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/platform-user-account-lock-duration-types?fields=id,abrv,name,units,languageMetadata&translate=true&rpp=20`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async fetchUserAccountLockReasonTypes(): Promise<
		LookupDto<UserAccountLockReasonTypesModel>
	> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/user-account-lock-reason-types?fields=id,abrv,name,languageMetadata&translate=true&rpp=20`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async fetchOasisLockReasonsTypes(): Promise<
		LookupDto<OasisLockReasonsTypesModel>
	> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/block-reasons?fields=abrv,code,id,name,languageMetadata&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async fetchGamingLimitTypes() {
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/agency-gaming-limit-types?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async getAccountStatementTypes(
		owner: UserTypes = UserTypes.PRIMARY,
		bettingAccountTypeIds: string
	): Promise<BaseLookupDto[] | void> {
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			owner
		)}/transaction-types/account-statement-types?bettingAccountTypeIds=${bettingAccountTypeIds}&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(owner), {
			responseType: "json",
		});
	}

	static async getAutoLogoutPeriods(): Promise<{
		// TODO this is extracted from existing code, confirm this is actual response
		item: { units: number; id: string; abrv: string; name: string }[];
	}> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/auto-logout-periods?fields=abrv,name,id,units,languageMetadata&rpp=20&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async getBettingTimeouts(): Promise<{
		// TODO this is extracted from existing code, confirm this is actual response
		item: { units: number; id: string; abrv: string; name: string }[];
	}> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/betting-timeouts?fields=abrv,units,id,name,languageMetadata&rpp=20&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	static async getUserPaymentMethods(isDeposit: boolean) {
		//TODO: use only internet owner
		//isDeposit = deposit true, withdraw false
		const internetOwner =
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			);
		const url = `${WEBPACK_BASE_ADDRESS}platform/payment-methods/enabled-per-payment-mode/${isDeposit}?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(internetOwner), {
			responseType: "json",
		});
	}

	static async getTransactionStatuses() {
		//TODO: use only internet owner
		const internetOwner =
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			);
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			internetOwner
		)}/transaction-status/user-status?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(internetOwner), {
			responseType: "json",
		});
	}

	static findLookup(
		type: string,
		activeWallet = UserTypes.PRIMARY
	): Promise<BasicLookupModel[]> {
		const url = `${getWebApiUrl()}/api/lookups/${type}`;
		return httpClient.get(url, this.getMvcRequestHeaders(activeWallet), {
			responseType: "json",
		});
	}
}
