import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Checkbox } from "@v2/components/shared/inputs";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import { CategoryItems } from "./";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { SportInteractionTypeEnum } from "@data-types/analytics/SportInteractionTypeEnum";
import OfferIcon from "@v2/components/shared/icons/Offer";

export const SportsList = observer(function SportsList(props: {
	store: {
		menu: PrematchOfferMenuStore["menu"];
		selectedSport: PrematchOfferMenuStore["selectedSport"];
		toggleUpdateProcessing?: PrematchOfferMenuStore["toggleUpdateProcessing"];
	} & Parameters<typeof SportItem>[0]["store"];
}) {
	return (
		<>
			{props.store.menu.children.map((sport) => (
				<SportItem
					key={sport.node.id}
					item={sport}
					store={props.store}
				/>
			))}
		</>
	);
});

export const SportItem = observer(function SportItem(props: {
	item: PrematchOfferMenuStore["menu"];
	store: {
		selectedSport: PrematchOfferMenuStore["selectedSport"];
		toggleUpdateProcessing?: PrematchOfferMenuStore["toggleUpdateProcessing"];
	} & Parameters<typeof CategoryItems>[0]["store"];
}) {
	return (
		<li className="nav--tertiary__item">
			<SportItemButton item={props.item} store={props.store} />
			<CategoryItems sport={props.item} store={props.store} />
		</li>
	);
});

export const SportItemButton = observer(function SportItemButton(props: {
	item: PrematchOfferMenuStore["menu"];
	store: {
		toggleUpdateProcessing?: PrematchOfferMenuStore["toggleUpdateProcessing"];
	};
}) {
	if (props.item.node.abrv == null) {
		console.error("Expected item, got null.");
		return null;
	}

	const labelId = `sport-${props.item.node.id}`;

	return (
		<button
			className={classNames("nav--tertiary__link", {
				"is-expanded": props.item.isExpanded,
				"is-active": props.item.checkState === 1,
			})}
			type="button"
			onClick={(e) => {
				e.stopPropagation();
				props.item.toggleExpanded();
			}}
		>
			<span className="nav--tertiary__title">
				<span>
					<Checkbox
						id={labelId}
						className="input--check"
						name={props.item.node.name}
						title={props.item.node.name}
						checked={props.item.checkState === 1}
						indeterminate={props.item.checkState === 2}
						// @ts-expect-error
						onChange={(e) => {
							e.stopPropagation();
							if (props.store.toggleUpdateProcessing != null) {
								props.store.toggleUpdateProcessing();
							}
							props.item.onToggleCheck();
							if (props.item.checkState === 1) {
								AnalyticsService.logSportInteraction({
									category:
										SportInteractionTypeEnum.LiveSport,
									label: props.item.node.abrv,
								});
							}
							window.scrollTo(0, 0);
						}}
					/>
					<label htmlFor={labelId} />
				</span>
				<OfferIcon
					type="sport"
					sport={props.item.node.abrv}
					className={
						"nav--tertiary__icon u-icon u-icon--med u-mar--right--micro"
					}
					treeIcon={true}
				/>
				<span>{props.item.node.name}</span>
			</span>
			<span className="nav--tertiary__counter counter counter--base counter--simple">
				{props.item.node.count}
			</span>
		</button>
	);
});
