import { Fiskaly } from "@data-types";
import { getDisplayedAmount } from "@utils";
import { MyBetsBetContext } from "@v2/context";
import { AccountStatementBetContext } from "@v2/context/AccountStatementBetContext";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default observer(function AccountStatementFiskalySection() {
	const contextValue = useContext(AccountStatementBetContext);

	const { t } = useTranslation();

	const { slipDetails } = contextValue!;

	if (!("metadata" in slipDetails!)) {
		return null;
	}
	let fiscalizationItem: Fiskaly = slipDetails.metadata;

	if (!fiscalizationItem) {
		return null;
	}

	if ("fiscalizationItem" in fiscalizationItem) {
		return null;
	}

	if ("fiskalyReceiptNumber" in fiscalizationItem) {
		if (fiscalizationItem.fiskalyReceiptNumber == null) {
			fiscalizationItem = {
				...fiscalizationItem,
				hints: ["Sicherheitseinrichtung ausgefallen"],
			};
		}
	}
	return (
		<>
			{fiscalizationItem.timeSignature && (
				<tr className="table--bets__tr table--bets__fiscalization table--bets__fiscalization--dashed">
					<td className="table--bets__tf" colSpan={2}>
						<span className="u-mar--right--xtny">
							{t("MY_BETS.ITEM.FISKALY_DATE")}:
						</span>
						<span>
							{DateTime.fromISO(
								fiscalizationItem.timeSignature + "Z"
							).toLocaleString(DateTime.DATETIME_SHORT)}
						</span>
					</td>
				</tr>
			)}
			{fiscalizationItem.fiskalyReceiptNumber && (
				<tr className="table--bets__tr table--bets__fiscalization">
					<td className="table--bets__tf" colSpan={2}>
						<span className="u-mar--right--xtny">
							{t("MY_BETS.ITEM.FISKALY_RECIPET_NUMBER")}:
						</span>
						<span>{fiscalizationItem.fiskalyReceiptNumber}</span>
					</td>
				</tr>
			)}
			{fiscalizationItem.fiskalyCashRegisterSerialNumber && (
				<tr className="table--bets__tr table--bets__fiscalization">
					<td className="table--bets__tf" colSpan={2}>
						<span className="u-mar--right--xtny">
							{t("MY_BETS.ITEM.FISKALY_SERIAL_NUMBER")}:
						</span>
						<span>
							{fiscalizationItem.fiskalyCashRegisterSerialNumber}
						</span>
					</td>
				</tr>
			)}

			{/* {fiscalizationItem.qrCodeData && (
				<tr className="table--bets__tr table--bets__fiscalization">
					<td className="table--bets__tf" colSpan={2}>
						<span className="u-mar--right--xtny">
							{t("MY_BETS.ITEM.QR_CODE")}:
						</span>
						<span>{fiscalizationItem.qrCodeData}</span>
					</td>
				</tr>
			)} */}
			{fiscalizationItem.grossAmountStandard && (
				<tr className="table--bets__tr table--bets__fiscalization">
					<td className="table--bets__tf" colSpan={2}>
						<span className="u-mar--right--xtny">
							{t("MY_BETS.ITEM.GROSS_AMOUNT_STANDARD")}:
						</span>
						<span>
							{getDisplayedAmount(
								+fiscalizationItem.grossAmountStandard,
								slipDetails.currency
							)}
						</span>
					</td>
				</tr>
			)}

			{fiscalizationItem.hints && fiscalizationItem.hints.length > 0 && (
				<tr className="table--bets__tr table--bets__fiscalization">
					<td className="table--bets__tf" colSpan={2}>
						<span className="u-mar--right--xtny">
							{t("MY_BETS.ITEM.HINTS")}:
						</span>
						<span>{fiscalizationItem.hints[0]}</span>
					</td>
				</tr>
			)}
		</>
	);
});
