import React, { useContext } from "react";
import { NavLink, match } from "react-router-dom";
import { getCurrentCulture, AccountTypes } from "@utils";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import { observer } from "mobx-react";
import { Location } from "history";

const MyLimitsTabs = observer(function MyLimitsTabs() {
	const myLimitsStore = useContext(MyLimitsStoreContext);

	function urlPath(accountType: AccountTypes) {
		if (accountType === AccountTypes.ONLINE) {
			return "online";
		} else {
			return "offline";
		}
	}
	if (myLimitsStore.tabs === null) {
		return null;
	}
	return (
		<>
			{myLimitsStore.tabs.map((category, index) => (
				<NavLink
					key={index}
					to={`/${getCurrentCulture()}/account-settings/my-limits/${
						category.id
					}/${urlPath(category.accountType)}`}
					className="tabs--primary__item"
					activeClassName="is-active"
					onClick={() =>
						myLimitsStore.setActiveAccount(category.accountType)
					}
					isActive={(match, location) =>
						isActive(
							index,
							category.accountType ===
								myLimitsStore.activeAccount,
							match,
							location
						)
					}
				>
					{category.name}
				</NavLink>
			))}
		</>
	);
});

function isActive(
	index: number,
	isActive: boolean,
	match: match | null,
	location: Location<unknown>
): boolean {
	if (
		(index === 0 &&
			location.pathname ==
				`/${getCurrentCulture()}/account-settings/my-limits`) ||
		(match && isActive)
	) {
		return true;
	}

	return false;
}
export default MyLimitsTabs;
