import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DateTime } from "luxon";
import classNames from "classnames";

import { BonusMessage } from "./BonusMessage";
import { BonusTabs, HistoryStatus } from "@api-types/myBonuses/Bonus";

import { Button } from "@v2/components/desktop/shared/buttons";
import { TransactionDetails } from "./TransactionDetails";

export function BonusHistory(props: {
	history: HistoryStatus[] | null;
	page: BonusTabs;
}) {
	if (props.page !== BonusTabs.history) {
		return null;
	}

	if (!props.history) {
		return (
			<BonusMessage
				message={
					"USER.ACCOUNT_SETTINGS.MY_BONUSES.HISTORY_ERROR_RESULTS"
				}
			/>
		);
	}

	if (props.history.length == 0) {
		return (
			<BonusMessage
				message={"USER.ACCOUNT_SETTINGS.MY_BONUSES.HISTORY_NO_RESULTS"}
			/>
		);
	}

	return (
		<>
			{props.history.map((data) => (
				<HistoryCard data={data} key={data.activationTime} />
			))}
		</>
	);
}

const HistoryCard = ({ data }: { data: HistoryStatus }) => {
	const { t } = useTranslation();
	const [isDetailsDisplayed, setIsDetailsDisplayed] = useState(false);

	const iconClasses = classNames("u-icon u-icon--xxlrg", {
		"u-icon--deposit--alt": data.bonusType.abrv == "deposit-bonus",
		"u-icon--my-bonuses--alt": data.bonusType.abrv == "voucher-bonus",
	});
	const isManualBonus = (data: HistoryStatus): boolean => {
		return (
			data.bonusType.abrv == "deposit-bonus" &&
			data.bonusDepositDetails.depositTransactionId ==
				"0000000000000000000000"
		);
	};
	return (
		<div key={data.activationTime} className="card--bonus">
			<div className="card--bonus__header">
				<i className={iconClasses} />
				<div className="card--bonus__title">
					<div className="card--bonus__type">
						<span>{data.bonusType.name}: </span>
						<strong>
							{data.bonusAmount.toFixed(2)}{" "}
							{data.currencyDisplayCode}
						</strong>
					</div>
					<div className="card--bonus__date">
						<span>
							{t<string>(
								`USER.ACCOUNT_SETTINGS.MY_BONUSES.BONUS_ACTIVATED`
							)}
							:{" "}
						</span>
						<span>
							{DateTime.fromISO(
								data.activationTime
							).toLocaleString(DateTime.DATE_SHORT)}
						</span>
					</div>
				</div>

				<TransactionHasDetails
					transactionId={
						data.bonusDepositDetails.depositTransactionId
					}
					isDetailsDisplayed={isDetailsDisplayed}
					setIsDetailsDisplayed={setIsDetailsDisplayed}
				/>
			</div>

			<div className="card--bonus__body">
				<div className="row">
					<div className="col col-sml-3">
						<div className="u-color--text--secondary">
							{t<string>(
								`USER.ACCOUNT_SETTINGS.MY_BONUSES.ROLLOVER_DEPOSIT_AMOUNT`
							)}
							:
						</div>
						<div>
							{data.bonusDepositDetails?.amount == 0 ? (
								<>----</>
							) : (
								<>
									{data.bonusDepositDetails?.amount.toFixed(
										2
									)}{" "}
									{data.currencyDisplayCode}
								</>
							)}
						</div>
					</div>
					<div className="col col-sml-3">
						<div className="u-color--text--secondary">
							{t<string>(
								`USER.ACCOUNT_SETTINGS.MY_BONUSES.HISTORY_PERCENTAGE`
							)}
							:
						</div>
						<div className="u-color--text--primary">
							{data.bonusPercentage == 0 ||
							isManualBonus(data) ? (
								<>----</>
							) : (
								<>{data.bonusPercentage} %</>
							)}
						</div>
					</div>
					<div className="col col-sml-3">
						<div className="u-color--text--secondary">
							{t<string>(
								`USER.ACCOUNT_SETTINGS.MY_BONUSES.HISTORY_MAX_BONUS`
							)}
							:
						</div>
						<div className="u-color--text--primary">
							{data.maxBonusAmount == 0 || isManualBonus(data) ? (
								<>----</>
							) : (
								<>
									{data.maxBonusAmount.toFixed(2)}{" "}
									{data.currencyDisplayCode}
								</>
							)}
						</div>
					</div>
					<div className="col col-sml-3">
						<div className="u-color--text--secondary">
							{t<string>(
								`USER.ACCOUNT_SETTINGS.MY_BONUSES.HISTORY_ROLLOVER`
							)}
							:
						</div>
						<div className="u-color--text--primary">
							{data.requiredRollover == 0 ? (
								<>----</>
							) : (
								<>
									{data.requiredRollover.toFixed(2)}{" "}
									{data.currencyDisplayCode}
								</>
							)}
						</div>
					</div>
				</div>
			</div>

			{isDetailsDisplayed && (
				<TransactionDetails
					isDetailsDisplayed={isDetailsDisplayed}
					setIsDetailsDisplayed={setIsDetailsDisplayed}
					transactionId={
						data.bonusDepositDetails.depositTransactionId
					}
				/>
			)}
		</div>
	);
};

function TransactionHasDetails(props: {
	transactionId: string;
	isDetailsDisplayed: boolean;
	setIsDetailsDisplayed: Function;
}) {
	if (props.transactionId == "0000000000000000000000") {
		return null;
	}

	const [isHover, setIsHover] = useState(false);

	const displayDetailsIndicatorClass = classNames("u-icon u-icon--xsml", {
		"u-icon--caret--down":
			props.isDetailsDisplayed || (!props.isDetailsDisplayed && isHover),
		"u-icon--caret--right": !props.isDetailsDisplayed && !isHover,
	});

	return (
		<Button
			btnType="button"
			className="card--bonus__toggle btn--square--reset btn--ghost"
			iconOnly={true}
			iconClassName={displayDetailsIndicatorClass}
			onClick={() =>
				props.setIsDetailsDisplayed(!props.isDetailsDisplayed)
			}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		/>
	);
}
