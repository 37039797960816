import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ForgotUsernameFormSection, ForgotUsernamePopupSuccess } from ".";
import { DateTime } from "luxon";
import { setTabTitle } from "@utils";

export default function ForgotUsernamePage() {
	const { t } = useTranslation();

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			dateOfBirth: DateTime.now().minus({ years: 18 }).toString(),
			email: "",
		},
	});

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.FORGOT_USERNAME.TITLE"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
	}, []);

	const [isForgotUsernameSuccess, setIsForgotUsernameSuccess] =
		useState(false);

	return (
		<FormProvider {...methods}>
			<div className="container">
				<ForgotUsernameFormSection
					isForgotUsernameSuccess={isForgotUsernameSuccess}
					setIsForgotUsernameSuccess={setIsForgotUsernameSuccess}
				/>

				<ForgotUsernamePopupSuccess
					isForgotUsernameSuccess={isForgotUsernameSuccess}
				/>
			</div>
		</FormProvider>
	);
}
