import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Event } from "@gp/models";
import useDayAndTime from "@hooks/useDayAndTimeFromEventStartTime";

export const StartTime = observer(function StartTime(props: {
	event: Event;
	className: string;
}) {
	const { t } = useTranslation();

	const { event, className } = props;
	const { day, time } = useDayAndTime(event.startTime, true);

	return (
		<div className={className}>
			<div className="offer__day__date">{day}</div>
			<strong className="offer__day__time">{time}</strong>
			{props.event.hasLiveCoverage && !props.event.isLive ? (
				<div className="offer__day__tag tag--tny tag--primary u-type--case--uppercase">
					{t<string>("COMMON.LIVE")}
				</div>
			) : null}
		</div>
	);
});
