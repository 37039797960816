import React from "react";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import OfferIcons from "@v2/components/shared/icons/Offer";
import classnames from "classnames";

export default observer(function MostPlayedTicketsFilterBar() {
	const rootStateStore = useRootAppStore();

	if (
		rootStateStore.quickPlayStore.mostPlayedTicketsStore.sportCategories
			.length == 1
	) {
		return null;
	}

	return (
		<div className="offer__tabs borderless u-padd--top--xsml">
			<nav className="tabs--secondary u-padd--top--xtny u-padd--bottom--xtny">
				{rootStateStore.quickPlayStore.mostPlayedTicketsStore.sportCategories.map(
					(sport) => {
						const buttonClasses = classnames(
							"tabs--secondary__item btn btn--sml btn--auto btn--icon",
							{
								"btn--tertiary":
									rootStateStore.quickPlayStore
										.mostPlayedTicketsStore
										.selectedSportCategory?.sportAbrv !=
									sport.sportAbrv,
								"btn--secondary is-active":
									rootStateStore.quickPlayStore
										.mostPlayedTicketsStore
										.selectedSportCategory?.sportAbrv ==
									sport.sportAbrv,
							}
						);

						const iconClasses = classnames(
							"tabs--secondary__circle u-icon--big",
							{
								"is-active":
									rootStateStore.quickPlayStore
										.mostPlayedTicketsStore
										.selectedSportCategory?.sportAbrv ==
									sport.sportAbrv,
							}
						);
						return (
							<button
								className={buttonClasses}
								onClick={() =>
									rootStateStore.quickPlayStore.mostPlayedTicketsStore.filterByCategory(
										sport.sportAbrv
									)
								}
								key={sport.sportAbrv}
							>
								<span className={iconClasses}>
									<OfferIcons
										type="sport"
										sport={sport.sportAbrv || ""}
										className="tabs--secondary__icon u-icon u-icon--xmed"
									/>
								</span>
								<span>{sport.sportName}</span>
							</button>
						);
					}
				)}
			</nav>
		</div>
	);
});
