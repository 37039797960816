import { sortDurations } from "@v2/helpers/account-settings/my-limits/sortDurations";
import React, { useContext } from "react";
import { MyLimitsLimit, MyLimitsSingleLimit } from ".";
import { UserLimit } from "@data-types";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import { MyLimitsStoreCategoriesNullChecked } from "@v2/state/myLimits/MyLimitsStore";
import { observer } from "mobx-react";

const MyLimitsLimitList = observer(function MyLimitsLimitList() {
	const myLimitsStore = useContext(
		MyLimitsStoreContext
	) as MyLimitsStoreCategoriesNullChecked;

	if (myLimitsStore.category.isMultiLimitModeEnabled) {
		return (
			<>
				{sortDurations<UserLimit>(
					myLimitsStore.category?.durations
				).map((duration) => (
					<MyLimitsLimit {...duration} key={duration.name} />
				))}
			</>
		);
	}

	return <MyLimitsSingleLimit />;
});

export default MyLimitsLimitList;
