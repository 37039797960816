import InfoMessage from "@components-shared/InfoMessageComponent";
import React from "react";
import { useTranslation } from "react-i18next";

export default function EmptyMyBetsMessage() {
	const { t } = useTranslation();

	let messageStr = t("MY_BETS.PAGE.NO_OPEN_BETS_ON_EVENT");

	return (
		<InfoMessage
			type="empty"
			message={messageStr}
			emptyIcon="u-icon--empty--bets"
		/>
	);
}
