import { useLoading, useRootAppStore } from "@hooks";
import { useState, useRef } from "react";
import { useNotification } from "../shared";
import { DocumentUploadService } from "@v2/data-access/document-upload";
import { useTranslation } from "react-i18next";
import AccountVerificationStatusApiService from "@api-services/account-verification/AccountVerificationStatusApiService";
import { AccountVerificationStatus } from "@api-types/account-verification/AccountVerificationType";
// import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { getCurrentCulture } from "@utils";
import { DateTime } from "luxon";
import { history } from "@state";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));
const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
const maxSize = 5 * 1024 * 1024;

export function useDocumentUpload() {
	const { setIsLoading } = useLoading();
	const { showError, showSuccess } = useNotification();
	const rootStore = useRootAppStore();
	const inputRef = useRef<HTMLInputElement>(null);
	const [images, setImages] = useState<File[]>([]);
	const { t } = useTranslation();
	const [status, setStatus] = useState<AccountVerificationStatus | null>(
		null
	);

	function validateFiles(files: File[] | FileList): boolean {
		for (let file of files) {
			if (!allowedTypes.includes(file.type)) {
				showError("ACCOUNT_VERIFICATION.GUIDELINE_FILE_EXTENSION");

				return false;
			}

			if (file.size > maxSize) {
				showError("ACCOUNT_VERIFICATION.GUIDELINE_FILE_SIZE");
				return false;
			}
		}
		return true;
	}

	function chooseFiles(event: React.ChangeEvent<HTMLInputElement>) {
		event.persist();
		try {
			setIsLoading(true);
			if (event.target.files) {
				if (!validateFiles(event.target.files)) {
					return;
				}
				const files = Array.from(event.target.files);
				const merge: File[] = [...files];
				setImages((i) => [...i, ...merge]);
				if (inputRef.current) {
					inputRef.current.value = ""; // Clear the input field
				}
			}
		} catch (e) {
			console.error(e);
			showError(e.data.message);
		} finally {
			setIsLoading(false);
		}
	}

	function removeImage(id: number) {
		setImages(images.filter((_, idx) => idx != id));
	}

	async function uploadFiles(id: number) {
		try {
			setIsLoading(true);
			await DocumentUploadService.sendUserDocumentFiles([images[id]]);
			removeImage(id);
			showSuccess(t("ACCOUNT_VERIFICATION.UPLOADED_DOCUMENTS"));
			await fetchData();
		} catch (e) {
			console.error(e);
			showError(e.message);
		} finally {
			setIsLoading(false);
		}
	}

	async function fetchData() {
		try {
			setIsLoading(true);
			const response =
				await AccountVerificationStatusApiService.getAccountVerificationStatus();
			if (
				response.shouldDisplayExternalWidget &&
				response.widget?.widgetParameters == null
			) {
				showError(t("COMMON.ERROR_MESSAGES.DEFAULT"));
			}

			rootStore.userAuthStore.updateUserVerificationStatusToFullyVerified(
				response.isUserAccountVerified
			);

			setStatus(response);
		} catch (e) {
			console.error(e);
			showError(t("COMMON.ERROR_MESSAGES.DEFAULT"));
		} finally {
			setIsLoading(false);
		}
	}

	async function updateDocumentExpiration(formData: {
		documentExpiration: string;
	}) {
		if (status?.documents?.data.length == 0 && images.length == 0) {
			showError(t("DOCUMENT_EXPIRATION.NO_IMAGES_UPLOADED"));
			return;
		}
		setIsLoading(true);
		if (images.length != 0) {
			try {
				await DocumentUploadService.sendUserDocumentFiles(images);
				setImages([]);
			} catch (e) {
				console.error(e);
				showError(t(e.message));
				setIsLoading(false);
				return;
			}
		}
		try {
			const userData = await (
				await AgencyUserApiService
			).default.getUserInfo();

			const documentExpiration = DateTime.fromISO(
				formData.documentExpiration
			).toFormat("yyyy-MM-dd");

			await (
				await AgencyUserApiService
			).default
				// @ts-expect-error
				.updatePersonalDataWithoutPassword({
					...userData,
					documentExpiration,
				});
			showSuccess(
				"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONAL_DATA_UPDATE_SUCCESS"
			);
			history.replace("/");
		} catch (e) {
			console.error(e);
			showError(e.message);
		} finally {
			setIsLoading(false);
		}
	}

	return {
		chooseFiles,
		inputRef,
		images,
		setImages,
		removeImage,
		uploadFiles,
		fetchData,
		status,
		updateDocumentExpiration,
	};
}

export type UseDocumentUpload = {
	chooseFiles: (event: React.ChangeEvent<HTMLInputElement>) => void;
	inputRef: React.RefObject<HTMLInputElement>;
	images: File[];
	setImages: React.Dispatch<React.SetStateAction<File[]>>;
	removeImage: (id: number) => void;
	uploadFiles: (id: number) => Promise<void>;
	fetchData: () => Promise<void>;
	status: AccountVerificationStatus | null;
	updateDocumentExpiration: (formData: {
		documentExpiration: string;
	}) => Promise<void>;
};
