export enum LiveStreamCodeEnum {
	InvalidIPAddress = 400803,
	InvalidStreamType = 400800,
	MissingStreamForEvent = 400807,
	RequestedTypeNotAvailable = 400802,
	StreamDisabled = 400806,
	StreamGeorestricted = 400804,
	StreamUnavailable = 400801,
	UnauthorizedIPAddress = 400805,
}
