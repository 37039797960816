import React, { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { EventContext, SportOfferContext, LookupContext } from "@gp/components";
import { formatTennisGameScore } from "@offer/common/helpers";
import OfferIcon from "@v2/components/shared/icons/Offer";
import { TeamName } from ".";

export default observer(function EventSummary(props: { isPage?: boolean }) {
	//#region hooks

	const { isPage = false } = props;

	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);
	const { teams } = useContext(LookupContext);

	//#endregion hooks

	if (!event.isLive) {
		// Event is not live and does not have score
		return null;
	}

	const homeTeam = teams.get(event.teamOneId || "");
	const awayTeam = teams.get(event.teamTwoId || "");

	const statsClasses = classNames("stats", {
		"stats--additional": !isPage,
		"stats--page": isPage,
	});

	return (
		<div className={statsClasses}>
			<div className="stats__left">
				<div className="stats__row stats__row--left">&nbsp;</div>
				<div className="stats__row stats__row--left">
					{event.result?.serve &&
						(event.result?.serve === "home" ? (
							<ServeIcon />
						) : (
							<i className="stats__icon stats__icon--server is-empty u-icon u-icon--tny" />
						))}
					<span>
						<TeamName team={homeTeam} />
					</span>
				</div>
				<div className="stats__row stats__row--left">
					{event.result?.serve &&
						(event.result?.serve === "away" ? (
							<ServeIcon />
						) : (
							<i className="stats__icon stats__icon--server is-empty u-icon u-icon--tny" />
						))}
					<span>
						<TeamName team={awayTeam} />
					</span>
				</div>
			</div>

			<EventStats />
		</div>
	);
});

const EventStats = observer(function EventStats() {
	//#region hooks

	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);

	const { t } = useTranslation();

	//#endregion hooks

	if (!event.isLive) {
		// Event is not live and does not have score
		return null;
	}

	const homeStats = event.result?.homeTeamTotalStatistics;
	const awayStats = event.result?.awayTeamTotalStatistics;

	const homeHalfTimeScore =
		event.result?.halfTimeScore && event.result?.halfTimeScore.home
			? event.result?.halfTimeScore.home
			: 0;
	const awayHalfTimeScore =
		event.result?.halfTimeScore && event.result?.halfTimeScore.away
			? event.result?.halfTimeScore.away
			: 0;

	const homeRedCards =
		homeStats && homeStats.redCards ? homeStats.redCards : 0;
	const awayRedCards =
		awayStats && awayStats.redCards ? awayStats.redCards : 0;

	const homeRedYellowCards =
		homeStats && homeStats.redYellowCards ? homeStats.redYellowCards : 0;
	const awayRedYellowCards =
		awayStats && awayStats.redYellowCards ? awayStats.redYellowCards : 0;

	let periods: { periodNumber: number[]; home: number[]; away: number[] } = {
		periodNumber: [],
		home: [],
		away: [],
	};
	if (event.result?.scorePerPeriod) {
		periods = event.result.scorePerPeriod.reduce<{
			periodNumber: number[];
			home: number[];
			away: number[];
		}>(
			(acc, item) => {
				if (acc.periodNumber.indexOf(item.periodNumber) === -1) {
					acc.periodNumber.push(item.periodNumber);
					acc.home.push(item.home || 0);
					acc.away.push(item.away || 0);
				}

				return acc;
			},
			{
				periodNumber: [],
				home: [],
				away: [],
			}
		);
	}

	const colClasses = classNames("stats__col", {
		"stats__col--sml": sport.abrv == "baseball" || sport.abrv == "snooker",
		stats__ot: event.result?.overTimeScore,
		stats__tbs: event.result?.tiebreakScore,
		stats__gs:
			event.result?.currentGameScore && !event.result?.tiebreakScore,
	});

	return (
		<div className="stats__right">
			{homeStats && awayStats && (
				<>
					{/* CORNERS */}
					<div className="at-cornerKicks stats__col">
						<div className="stats__row">
							<i className="u-icon u-icon--sml u-icon--corner" />
						</div>
						{homeStats ? (
							<div className="stats__row">
								{homeStats.cornerKicks || 0}
							</div>
						) : (
							<div className="stats__row">&nbsp;</div>
						)}
						{awayStats ? (
							<div className="stats__row">
								{awayStats.cornerKicks || 0}
							</div>
						) : (
							<div className="stats__row">&nbsp;</div>
						)}
					</div>

					{/* RED CARDS */}
					<div className="is-homeStats stats__col is-red">
						<div className="stats__row">
							<i className="stats__icon u-icon u-icon--sml u-icon--card u-icon--card--red" />
						</div>
						{homeStats ? (
							<div className="stats__row">
								{homeRedCards + homeRedYellowCards || 0}
							</div>
						) : (
							<div className="stats__row">&nbsp;</div>
						)}
						{awayStats ? (
							<div className="stats__row">
								{awayRedCards + awayRedYellowCards || 0}
							</div>
						) : (
							<div className="stats__row">&nbsp;</div>
						)}
					</div>

					{/* YELLOW CARDS */}
					<div className="is-yellowCards stats__col">
						<div className="stats__row">
							<i className="stats__icon u-icon u-icon--sml u-icon--card u-icon--card--yellow" />
						</div>
						{homeStats ? (
							<div className="stats__row">
								{homeStats.yellowCards || 0}
							</div>
						) : (
							<div className="stats__row">&nbsp;</div>
						)}
						{awayStats ? (
							<div className="stats__row">
								{awayStats.yellowCards || 0}
							</div>
						) : (
							<div className="stats__row">&nbsp;</div>
						)}
					</div>
				</>
			)}

			{/* HT - HALF TIME SCORE */}
			{event.result?.halfTimeScore && (
				<div
					className={classNames("is-halfTimeScore stats__col", {
						"stats__col--lrg": t("EVENT.DETAILS.HT").length >= 12,
					})}
				>
					<div
						className="stats__row"
						title={t("EVENT.DETAILS.HT_TITLE")}
					>
						<span className="stats__label">
							{t("EVENT.DETAILS.HT")}
						</span>
					</div>
					<div className="stats__row">{homeHalfTimeScore || 0}</div>
					<div className="stats__row">{awayHalfTimeScore || 0}</div>
				</div>
			)}

			{/* SCORE PER PERIOD */}
			{event.result?.scorePerPeriod && !event.result?.halfTimeScore && (
				<div className="is-period stats__col stats__col--xlrg">
					<div className="stats__row stats__row--inline">
						{event.result?.scorePerPeriod &&
						!event.result?.halfTimeScore
							? periods.periodNumber.map((p, idx) => (
									<div
										key={idx}
										className={colClasses}
										title={"P" + p}
									>
										P{p}
									</div>
							  ))
							: null}
					</div>

					<div className="stats__row stats__row--inline">
						{event.result?.scorePerPeriod &&
						!event.result?.halfTimeScore
							? periods.home.map((a, idx) => (
									<div key={idx} className={colClasses}>
										{a}
									</div>
							  ))
							: null}
					</div>

					<div className="stats__row stats__row--inline">
						{event.result?.scorePerPeriod &&
						!event.result?.halfTimeScore
							? periods.away.map((h, idx) => (
									<div key={idx} className={colClasses}>
										{h}
									</div>
							  ))
							: null}
					</div>
				</div>
			)}

			{/* OT - OVERTIME SCORE */}
			{event.result?.overTimeScore && (
				<div className={colClasses}>
					<div
						className="stats__row"
						title={t("EVENT.DETAILS.OT_TITLE")}
					>
						{t("EVENT.DETAILS.OT")}
					</div>
					<div className="stats__row">
						{event.result?.overTimeScore.home || 0}
					</div>
					<div className="stats__row">
						{event.result?.overTimeScore.away || 0}
					</div>
				</div>
			)}

			{/* TBS - TIE BREAKER SCORE */}
			{event.result?.tiebreakScore && (
				<div className={colClasses}>
					<div
						className="stats__row"
						title={t("EVENT.DETAILS.TBS_TITLE")}
					>
						{t("EVENT.DETAILS.TBS")}
					</div>
					<div className="stats__row">
						{event.result?.tiebreakScore.home || 0}
					</div>
					<div className="stats__row">
						{event.result?.tiebreakScore.away || 0}
					</div>
				</div>
			)}

			{/* GAME SCORE - TENNIS */}
			{event.result?.currentGameScore && !event.result?.tiebreakScore && (
				<div className={colClasses}>
					<div
						className="stats__row"
						title={t("EVENT.DETAILS.GAME_TITLE")}
					>
						{t("EVENT.DETAILS.GAME")}
					</div>
					<div className="stats__row u-type--wgt--medium u-color--secondary--carrot">
						{formatTennisGameScore(
							event.result?.currentGameScore.home || "0"
						)}
					</div>
					<div className="stats__row u-type--wgt--medium u-color--secondary--carrot">
						{formatTennisGameScore(
							event.result?.currentGameScore.away || "0"
						)}
					</div>
				</div>
			)}

			{/* CURRENT SCORE */}
			<div className={colClasses + " stats__cs"}>
				<div className="stats__row">
					{/* 	<i
						className={
							"stats__icon u-icon u-icon--xsml u-icon--score u-icon--" +
							sport.abrv +
							"--monochromatic"
						}
					/>  */}
					<OfferIcon
						type="sport"
						sport={sport.abrv || ""}
						className={
							"stats__icon u-icon u-icon--xsml u-icon--sport u-icon--sport--mono"
						}
						modifiers={{ mono: true }}
					/>
				</div>
				<div className="stats__row u-type--wgt--medium u-color--brand--highlight">
					{event.currentScore?.home || 0}
				</div>
				<div className="stats__row u-type--wgt--medium u-color--brand--highlight">
					{event.currentScore?.away || 0}
				</div>
			</div>
		</div>
	);
});

function ServeIcon() {
	return <i className="stats__icon stats__icon--server u-icon u-icon--tny" />;
}
