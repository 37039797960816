export class AccountActivationRequestDto {
	email: string;
	password: string;
	emailChangeUrl: string;
	username: string;
	depositLimit: number;
	playerPromoSendingMode: PlayerPromoSendingModeEnum;
	activationToken: string;
	agencyId: string;
	agencyKey: string;
	shopId: string;
}

export enum PlayerPromoSendingModeEnum {
	disabled,
	enabled,
	forbidden,
}
