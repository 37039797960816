import React from "react";
import {
	FormInputFieldCurrency,
	FormSelectField,
} from "@v2/components/desktop/shared/inputs";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
	GamingLimitDataType,
	LimitDurationDataType,
} from "@data-types/membership/registration";
import { sortDurations } from "@v2/helpers/account-settings/my-limits/sortDurations";

export default function RegistrationLimit(props: {
	limitDurationData: LimitDurationDataType[] | null;
	gamingLimitsData: GamingLimitDataType | null;
	labelClassName?: string;
	required?: boolean;
}) {
	const { limitDurationData, gamingLimitsData, labelClassName, required } =
		props;

	const { t } = useTranslation();
	const { getValues } = useFormContext();

	return (
		<>
			<FormSelectField
				label="MEMBERSHIP.REGISTRATION.LIMIT_DURATION_LABEL"
				labelClassName={labelClassName}
				name="limitDurationId"
				dropdownData={sortDurations(limitDurationData)}
				className="form__field"
				placeholder="MEMBERSHIP.REGISTRATION.LIMIT_DURATION_PLACEHOLDER"
				validate={{
					isValid: () =>
						!getValues("limitDurationId") &&
						getValues("limitAmount")
							? t("COMMON.REQUIRED_FIELD")
							: null,
				}}
				required={required}
			/>

			<FormInputFieldCurrency
				label="MEMBERSHIP.REGISTRATION.LIMIT_AMOUNT_LABEL"
				labelClassName={labelClassName}
				name="limitAmount"
				className="form__field"
				placeholder="MEMBERSHIP.REGISTRATION.LIMIT_AMOUNT_PLACEHOLDER"
				pattern={{
					value: /^\d+(\.\d{1,2})?$/,
					message: t(
						"CASINO.BALANCE_TRANSFER.AMOUNT_VALIDATION_ERROR"
					),
				}}
				validate={{
					isValid: () =>
						getValues("limitDurationId") &&
						!getValues("limitAmount")
							? t("COMMON.REQUIRED_FIELD")
							: null,
					isEqual: (value: string) =>
						!value ||
						(parseFloat(value) >= gamingLimitsData!.minLimit &&
							parseFloat(value) <= gamingLimitsData!.maxLimit) ||
						t("MEMBERSHIP.REGISTRATION.LIMIT_RANGE_ERROR", {
							0: gamingLimitsData!.minLimit,
							1: gamingLimitsData!.maxLimit,
						}),
				}}
				required={required}
			/>
		</>
	);
}
