import {
	httpClient,
	getAgencyKey,
	getShopId,
	getUserFromLocalStorage,
	getApplicationHeaders,
	UserTypes,
	getUserId,
} from "@utils";
import { FailedLoginsReportFilter, UserAccount } from "@data-types/user";
import { Currency } from "@api-types/common/Currency";
import { FailedLoginDataDto } from "@api-types/user/FailedLoginDataDto";

export default class UserDataApiService {
	static getRequestHeaders = (
		activeUser?: Parameters<typeof getApplicationHeaders>[0]
	) => {
		const appHeaders = getApplicationHeaders(activeUser);
		// @ts-expect-error
		appHeaders["Content-Type"] = "application/json";
		return appHeaders;
	};

	static async validatePlayer() {
		const user = App.state.rootStore.userAuthStore.user;

		if (user == null) {
			throw "User is not logged in.";
		}

		const userId = user.id;

		const url = `${WEBPACK_BASE_ADDRESS}/platform/oasis-blocks/validate-player`;

		const body = JSON.stringify({
			AgencyIdentifier: getAgencyKey(),
			ShopId: getShopId(),
			UserId: userId,
		});
		return await httpClient.post(url, body, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	//move this to special service

	// TODO move this offer service
	static async findOffers(filter: any /* TODO */) {
		return await httpClient.post(
			`${WEBPACK_BASE_PROXY_ADDRESS}${getAgencyKey()}/offer`,
			JSON.stringify(filter),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	// move to special service
	static async requestPasswordRecovery(
		model: RequestPasswordRecoveryFormData
	) {
		return await httpClient.post(
			`${WEBPACK_BASE_ADDRESS}platform/gp-recover-password`,
			JSON.stringify(model),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async acceptRegulation() {
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/regulation-consent-histories/accept-regulation`;
		return await httpClient.post(url, null, this.getRequestHeaders());
	}

	// TODO: this should be simple http request, do mapping elsewhere
	static async getUserAccounts(
		activeUser = UserTypes.PRIMARY
	): Promise<UserAccount[] | null> {
		var userBalance: TUserBalance = await httpClient.get(
			`${WEBPACK_BASE_ADDRESS}${getAgencyKey(
				activeUser
			)}/user-accounts/users/${
				getUserFromLocalStorage(activeUser)?.id
			}?embed=accountType,currency`,
			this.getRequestHeaders(activeUser),
			{ responseType: "json" }
		);
		if (userBalance && userBalance.item) {
			const allowed = userBalance.item.filter((ub) =>
				WEBPACK_ALLOWED_ACCOUNTS.includes(ub.accountType.abrv)
			);
			const mapped = allowed.map((a) => {
				return {
					id: a.id,
					accountTypeId: a.accountType.id,
					name: a.accountType.name,
					abrv: a.accountType.abrv,
					balance: a.balance,
					currency: a.currency,
					userId: a.userId,
				};
			});
			return mapped;
		}
		return null;
	}

	static async recoverPassword(model: TRecoverPasswordRequest) {
		return await httpClient.put(
			`${WEBPACK_BASE_ADDRESS}platform/gp-recover-password`,
			JSON.stringify(model),
			this.getRequestHeaders()
		);
	}

	static async getUserFailedLogins(
		filterObject: FailedLoginsReportFilter
	): Promise<FailedLoginDataDto> {
		const filter = new URLSearchParams();

		filter.append("reportMode", JSON.stringify(filterObject.reportMode));
		filter.append("rpp", JSON.stringify(filterObject.rpp));

		if (filterObject.hourInterval) {
			filter.append(
				"hourInterval",
				JSON.stringify(filterObject.hourInterval)
			);
		}

		return httpClient.get(
			`${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/user-login-histories/failed-logins/${getUserId()}?${filter.toString()}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}
}

export type RequestPasswordRecoveryFormData = {
	/** Time string */
	dateOfBirth: string;
	userName: string;
};

type TUserBalance = {
	item: {
		id: string;
		balance: number;
		currency: Currency;
		userId: string;
		accountType: {
			abrv: string;
			id: string;
			name: string;
		};
	}[];
};

type TRecoverPasswordRequest = {
	passwordRecoveryToken: string;
	username: string;
};
