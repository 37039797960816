import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { OfferStoreContext, EventContext } from "@gp/components";

import { BettingType } from "./";

import { useIsAdditionalOfferVisible } from "@offer/common";
import { FavoritesStoreContext } from "../../../../../context/FavoritesStoreContext";

export function BettingTypeGroup(props: {
	group: Parameters<typeof BettingTypeGroupHeader>[0]["group"] &
		Parameters<typeof BettingTypeGroupBody>[0]["group"];
	isFavorite?: boolean;
}) {
	const [isExpanded, setExpand] = useState(true);

	const containerElClasses = classNames("offer offer--additional", {
		"is-collapsed": !isExpanded,
		"is-expanded": isExpanded,
	});

	return (
		<div className={containerElClasses}>
			<BettingTypeGroupHeader
				isExpanded={isExpanded}
				onSetExpand={setExpand}
				group={props.group}
				isFavorite={props.isFavorite}
			/>
			<BettingTypeGroupBody
				isExpanded={isExpanded}
				group={props.group}
				isFavorite={props.isFavorite}
			/>
		</div>
	);
}

function BettingTypeGroupHeader(props: {
	isExpanded?: boolean;
	onSetExpand: (newValue: boolean) => void;
	group: { abrv: string; name: string };
	isFavorite?: boolean;
}) {
	const { isExpanded, onSetExpand, group, isFavorite } = props;
	const favoritesStore = useContext(FavoritesStoreContext);

	const event = useContext(EventContext);
	const { eventKeysMap } = useContext(OfferStoreContext);
	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();
	const eventKeys = eventKeysMap.get(event.id);

	if (eventKeys == null || eventKeys.size === 0) {
		return null;
	}

	let count;
	if (isFavorite) {
		count = Array.from(eventKeys.values()).reduce((acc, key) => {
			if (key.bettingType.groupId !== group.abrv) {
				return (acc += 0);
			}
			if (
				!favoritesStore.isUserFavoriteBettingType(
					key.bettingTypeId,
					key.specifier || null
				)
			) {
				return (acc += 0);
			}
			return (acc += 1);
		}, 0);
	} else {
		count = Array.from(eventKeys.values()).reduce(
			(acc, key) =>
				(acc += key.bettingType.groupId === group.abrv ? 1 : 0),
			0
		);
	}

	const headClass = classNames(
		"offer__head offer__head--secondary offer--additional__head",
		{
			"is-collapsed": !isExpanded,
			"is-expanded": isExpanded,
		}
	);

	const iconClass = classNames("offer__toggle__icon u-icon u-icon--xsml", {
		"u-icon--caret--down": isExpanded,
		"u-icon--caret--right": !isExpanded,
	});

	const toggleClasses = classNames("offer__head__data offer__toggle", {
		"offer__toggle--sml": isAdditionalOfferVisible,
	});

	return (
		<div className={headClass}>
			<div className="offer__head__row offer--additional__head__row">
				<div
					className="offer__head__data offer__title"
					title={group.name}
				>
					<div className="offer__title__wrapper">
						<span className="offer__name">{group.name}</span>
						<span className="counter counter--base counter--dark--outlined">
							{count}
						</span>
					</div>
				</div>
				<div className={toggleClasses}>
					<button
						className="offer__toggle__btn btn btn--square btn--square--tny btn--ghost"
						type="button"
						onClick={() => onSetExpand(!isExpanded)}
					>
						<i className={iconClass} />
					</button>
				</div>
			</div>
		</div>
	);
}

const BettingTypeGroupBody = observer(function BettingTypeGroupBody(props: {
	isExpanded?: boolean;
	group: { bettingTypes: Parameters<typeof BettingType>[0]["bettingType"][] };
	isFavorite?: boolean;
}) {
	const { isExpanded, group, isFavorite } = props;

	if (!isExpanded) {
		return null;
	}

	return (
		<div className="offer__body offer--additional__body">
			{group.bettingTypes.map((bt) => (
				<BettingType
					key={bt.id}
					bettingType={bt}
					isFavorite={isFavorite}
				/>
			))}
		</div>
	);
});
