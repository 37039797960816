import { runInAction, action, observable, computed } from "mobx";
import { IStoreLifeCycle, IConsoleLogger } from "@interface";

import {
	LoginErrorResponseModel,
	AccountActivationFormData,
	ActivationResponse,
	ForcePasswordChangeFormData,
	ActivationUserData,
} from "@data-types";
import { LoginErrorCodes, UserAccount } from "@data-types/user";

import {
	LoginHistoryResponseModel,
	ActivationTypeEnum as AccountActivationTypeEnum,
	LoginResponseDto,
} from "@api-types/user/LoginResponseDto";
import { PlayerPromoSendingModeEnum } from "@api-types/user/AccountActivationRequestDto";
import { UserMatchingRequestDto } from "@api-types/user/UserMatchingRequestDto";
import {
	LoginRequestDto,
	LoginSSORequestDto,
} from "@api-types/user/LoginRequestDto";

import { getCurrentCulture, isNullOrWhitespace } from "@utils";
import { localizationService } from "@state";

import { CountryLookup } from "@services/user/DataConfirmationService";
import { SessionStorageProvider } from "@state";
import { UserAuthStore as UserStore, User, UserToken } from "./";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { LoginType } from "@utils/common/loginTypeEnum";
import { MitAuthDto } from "@api-types/integrations/MitAuthDto";
import { EAccountActivationEmptyFields } from "@data-types";
import { StorageStateKeysEnum } from "@utils/storage";
import {
	getRedirectOriginUrl,
	isCapacitorPlatform,
} from "@utils/specific/capacitor";
import { LoaderStore } from "@state/stores/common";
import UserApiService from "@api-services/user/UserApiService";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const PersonalDataService = lazy(loadFailPath, ()=>import("@services/account-settings/PersonalDataService"));
// prettier-ignore
const UserService = lazy(loadFailPath, ()=>import("@services/user/UserService"))
// prettier-ignore
const DataConfirmationService = lazy(loadFailPath, ()=>import("@services/user/DataConfirmationService"))
// prettier-ignore
const  AccountActivationService = lazy(loadFailPath, ()=>import("@services/membership/account-activation/AccountActivationService"));
// prettier-ignore
const ForcePasswordChangeService = lazy(loadFailPath, ()=>import("@services/membership/force-password-change/ForcePasswordChangeService"));

/*
 * Dear reader good luck.
 */
export class LoginStore implements IStoreLifeCycle {
	@observable public isStoreInitialized: boolean = false;
	loader: LoaderStore;

	// in case of sso login (redirect with params) language service triggers rerender - resulting in useEffects triggering twice
	// flag is set to true on first render, value is checked on entering use effect to make sure we trigger login only once
	public isSSOLoginInitialized: boolean = false;

	@computed public get nextRequiredUserAction(): RequiredUserAction {
		if (this.isLoginWorking) {
			return RequiredUserAction.IsLoginWorking;
		}

		if (this.isDataConfirmationInProgress) {
			return RequiredUserAction.ConfirmUserData;
		}

		if (
			this.userStore.accountActivation?.activationType ===
			AccountActivationTypeEnum.AccountActivation
		) {
			return RequiredUserAction.AccountActivation;
		}

		if (this.isAccountActivationCongratulationsActive) {
			return RequiredUserAction.AccActivationCongratulation;
		}

		if (
			this.userStore.accountActivation?.activationType ===
			AccountActivationTypeEnum.PasswordChange
		) {
			return RequiredUserAction.PasswordChange;
		}

		if (this.isUserMatchingInProgress) {
			return RequiredUserAction.UserMatching;
		}

		if (
			this.userStore.user?.bettingActivityNeeded &&
			(this.userStore.bettingActivityStore.bettingActivityFetchedData
				?.bettingActivity != null ||
				this.userStore.bettingActivityStore.bettingActivityFetchedData
					?.userActivity != null)
		) {
			return RequiredUserAction.BettingActivity;
		}

		if (this.userStore.user?.bettingActivityNeeded) {
			return RequiredUserAction.IsLoginWorking;
		}

		if (this.userStore.user?.shouldAcceptTermsAndConditions) {
			return RequiredUserAction.AcceptNewTerms;
		}

		if (this.userStore.isPartiallyLoggedIn && !this.userStore.isLoggedIn) {
			return RequiredUserAction.IsLoginWorking;
		}

		if (this.userStore.user?.shouldAcceptGdpr && !isCapacitorPlatform()) {
			//currently disabled for capacitor platform
			return RequiredUserAction.AcceptGdpr;
		}

		return RequiredUserAction.None;
	}

	private userStore: UserStore;
	private logger: IConsoleLogger;

	constructor(userStore: UserStore, logger: IConsoleLogger) {
		this.logger = logger;
		this.userStore = userStore;
	}

	//#region login

	@observable public isLoginWorking: boolean = false;
	@observable public loginError: LoginErrorResponseModel | null = null;
	@observable public userMatchingDataList: string[] = [];
	@observable public isUserMatchingInProgress: boolean = false;
	@observable public isDataConfirmationInProgress: boolean = false;

	@action.bound
	public onInitialize(): void {
		this.readFromLocalStorage(null);
		window.addEventListener("storage", this.storageActionListener);
	}

	@action.bound
	public onInitializeLogin(): void {
		this.userStore.removeUserFromLocalStorage();

		App.state.rootStore.automaticLogoutStore.clearTimer();
		App.state.rootStore.realityCheckStore.clearTimer();
		App.state.rootStore.closePopups();

		//remove authorization cookies

		document.cookie = `authorization=;expires=${new Date().toUTCString()};path=/`;
		document.cookie = `authorization_token=;expires=${new Date().toUTCString()};path=/`;

		this.isStoreInitialized = true;
	}

	@action.bound
	public async onLoginSubmit(
		loginData: LoginRequestDto | LoginSSORequestDto | MitAuthDto,
		loginType: LoginType = LoginType.DEFAULT
	): Promise<void> {
		this.isLoginWorking = true;
		try {
			const response = await (
				await UserService
			).default.loginUser(loginData, loginType);

			if (response != null) {
				const sln = (loginData as LoginRequestDto).stayLoggedIn;
				if (sln) {
					App.state.rootStore.localStorageProvider.set(
						StorageStateKeysEnum.SLN,
						`${sln}`
					);
				}
				this.userStore.login(response);

				if (
					response.errorCode ===
					LoginErrorCodes.dataConfirmationRequiredCode
				) {
					this.setUserDataConfirmationState(true);
					await this.resolveUserDataConfirmationData(response);
				}

				await this.resolveUserMatchingData(response.errorCode);
			}
		} catch (error) {
			this.logger.logError(error);
			if (error?.userData) {
				if (error.userData?.activationToken) {
					//show page with user data
					this.setUserDataToBeConfirmed({
						...error.userData,
						isInternetUser: false,
					});
					this.setUserDataConfirmationState(true);
					this.setUserConfirmationData(error.userData);
					this.resetLoginErrorState();
				} else {
					//show message about shop
					runInAction(() => {
						this.loginError = {
							errorCode: error.errorCode,
							message: error.userData.shopInfo,
						};
					});
				}
			} else {
				runInAction(() => (this.loginError = error));
			}
		}

		runInAction(() => {
			this.isLoginWorking = false;
		});
	}

	@action.bound
	resetLoginErrorState() {
		this.loginError = null;
	}

	@action.bound
	public setUserDataConfirmationState(flag: boolean): void {
		if (!flag) {
			App.state.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.UDC
			);
		} else {
			App.state.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.UDC,
				`${flag}`
			);
		}

		this.isDataConfirmationInProgress = flag;
	}

	@action.bound
	public async resolveUserDataConfirmationData(
		response: LoginResponseDto
	): Promise<void> {
		const fetchedUserData = await (
			await PersonalDataService
		).default.getPersonalData(false);
		if (fetchedUserData != null && fetchedUserData.data != null) {
			const userDataModel = fetchedUserData.data;
			const userData: UserData = {
				activationToken:
					response.primary.accountActivation?.activationToken,
				agencyIdentifier: response.primary.user.agencyId,
				birthPlace: userDataModel.birthPlace,
				city: userDataModel.city,
				countryId: userDataModel.countryId!,
				firstName: userDataModel.firstName,
				houseNumber: userDataModel.houseNumber,
				lastName: userDataModel.lastName,
				shopId: response.primary.user.shopId,
				shopInfo: null,
				street: userDataModel.street,
				userName: userDataModel.userName,
				zipCode: userDataModel.zipCode,
				isInternetUser: response.primary.user.isInternetUser,
				currentEmail: "",
				dob: userDataModel.dob,
				language: userDataModel.language,
				mobilePhone: userDataModel.mobilePhone,
				payPin: userDataModel.payPin,
				receiveNewsletter: userDataModel.receiveNewsletter,
				telephoneNumber: userDataModel.telephoneNumber,
				titleId: userDataModel.titleId,
				occupation: userDataModel.occupation,
				nationality: userDataModel.nationality,
				birthName: userDataModel.birthName,
			};
			this.setUserDataToBeConfirmed(userData);
		}
	}

	//#endregion login

	//#region account activation

	@observable public isAccountActivationWorking: boolean = false;
	@observable public accountActivationData?: ActivationResponse | null = null;
	@observable
	public emptyFields: EAccountActivationEmptyFields =
		EAccountActivationEmptyFields.NONE;
	@observable public accountActivationError: boolean = false;
	@observable public isAccountActivationCongratulationsActive: boolean =
		false;

	@action.bound
	public async onInitializeAccountActivation(): Promise<void> {
		if (this.userStore.accountActivation == null) return;

		this.isAccountActivationWorking = true;

		const response = await (
			await AccountActivationService
		).default.getAccountActivationData(
			this.userStore.accountActivation?.activationToken,
			this.userStore.accountActivation?.agencyId
		);

		if (response.result != null) {
			runInAction(() => (this.accountActivationData = response.result));
		} else if (response?.error != null) {
			this.logger.logError(response.error);
		}

		runInAction(() => {
			this.isAccountActivationWorking = false;
		});
	}

	@action.bound
	public async onAccountActivationSubmit(
		data: AccountActivationFormData
	): Promise<void> {
		if (this.userStore.accountActivation == null) {
			return;
		}

		this.isAccountActivationWorking = true;
		this.emptyFields = EAccountActivationEmptyFields.NONE;

		const activationModel = {
			activationToken: this.userStore.accountActivation.activationToken,
			agencyId: this.userStore.accountActivation.agencyId,
			agencyKey: this.userStore.accountActivation.agencyKey,
			shopId: this.userStore.accountActivation.shopId,
			email: data.email,
			emailChangeUrl: getRedirectOriginUrl(
				`email-activation?mailkey={mailkey}&username={username}`
			),
			password: data.password,
			username: data.userCardNumber,
			depositLimit: Number(data.depositLimit),
			playerPromoSendingMode: data.isReceivingPromoEmailsEnabled
				? PlayerPromoSendingModeEnum.enabled
				: PlayerPromoSendingModeEnum.disabled,
		};

		const response = await (
			await AccountActivationService
		).default.createAccountActivation(activationModel);

		if (response.result != null) {
			this.resolveUserActivationLogin(
				response.result.primary,
				response.result.secondary
			);
			runInAction(() => {
				this.isAccountActivationCongratulationsActive = true;
			});
		} else if (response.error != null) {
			const error = response.error;
			this.logger.logError(error);
			const notifyError = App.state.rootStore.notificationStore.error;

			if (error?.userData) {
				if (error.userData?.activationToken) {
					//show popup with user data
					// @ts-expect-error
					this.setUserDataToBeConfirmed({
						...error.userData,
						isInternetUser: false,
					});
					this.setUserConfirmationData(error.userData);
					this.setUserDataConfirmationState(true);
				} else {
					if (error.userData.shopInfo) {
						//show message about shop
						notifyError(
							localizationService.t(
								"USER.POPUPS.DATA_CONFIRMATION.USER_DATA_CONFIRMATION_REQUIRED",
								{
									0: error.userData.shopInfo,
								}
							)
						);
					} else {
						notifyError(
							localizationService.t(
								"MEMBERSHIP.ACCOUNT_ACTIVATION.ERROR_HAS_OCCURED"
							)
						);
					}
				}
			} else {
				notifyError(
					localizationService.t(
						`MEMBERSHIP.ACCOUNT_ACTIVATION.${error?.message}`
					)
				);
			}
		}
		runInAction(() => {
			this.isAccountActivationWorking = false;
		});
	}

	@action.bound
	public async onAccountActivationFormSubmit(
		data: AccountActivationFormData
	): Promise<void> {
		if (
			!this.accountActivationData?.userInfo?.isOfflineUserMailApproved &&
			isNullOrWhitespace(data.email) &&
			!this.accountActivationData?.companyInfo
				.isOfflineUserPasswordChangeMandatory &&
			isNullOrWhitespace(data.password)
		) {
			this.emptyFields = EAccountActivationEmptyFields.MAIL_AND_PASSWORD;
		} else if (
			!this.accountActivationData?.userInfo?.isOfflineUserMailApproved &&
			isNullOrWhitespace(data.email)
		) {
			this.emptyFields = EAccountActivationEmptyFields.MAIL;
		} else if (
			!this.accountActivationData?.companyInfo
				.isOfflineUserPasswordChangeMandatory &&
			isNullOrWhitespace(data.password)
		) {
			this.emptyFields = EAccountActivationEmptyFields.PASSWORD;
		} else {
			await this.onAccountActivationSubmit(data);
		}
	}

	@action.bound
	public async onCancelAccountActivation(): Promise<void> {
		this.isAccountActivationWorking = true;

		this.userStore.logoutUser();

		runInAction(() => (this.isAccountActivationWorking = false));
	}

	@action.bound
	public clearAccountActivationError(): void {
		this.accountActivationError = false;
	}

	@action.bound
	public closeEmptyFieldsPopup(): void {
		this.emptyFields = EAccountActivationEmptyFields.NONE;
	}

	@action.bound
	public closeAccountActivationCongratulations(): void {
		this.isAccountActivationCongratulationsActive = false;
	}

	@action.bound
	public setUserConfirmationData(
		userData: ActivationUserData | UserData | null
	) {
		if (userData != null) {
			App.state.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.CONFIRMATION_DATA,
				JSON.stringify(userData)
			);
		} else {
			App.state.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.CONFIRMATION_DATA
			);
		}
	}

	@action.bound
	setConfirmationDataState() {
		this.setUserDataToBeConfirmed(
			JSON.parse(
				App.state.rootStore.localStorageProvider.get(
					StorageStateKeysEnum.CONFIRMATION_DATA
				) as string
			) as UserData
		);
	}

	@action.bound
	public onDisposeAccountActivation(): void {
		this.accountActivationData = null;
		this.emptyFields = EAccountActivationEmptyFields.NONE;
		this.clearAccountActivationError();
	}

	public onDisposeAccountActivationCongratulations(): void {
		this.isAccountActivationCongratulationsActive = false;
	}
	//#endregion account activation

	//#region password reset

	@observable public isPasswordResetWorking: boolean = false;
	@observable public forcePwdResetData: ActivationResponse | null = null;
	@observable public hasForcePwdResetError: boolean = false;

	@action.bound
	public async onInitializePasswordReset(): Promise<void> {
		this.isPasswordResetWorking = true;
		if (this.userStore.accountActivation?.activationToken == null) {
			this.logger.logError(
				"Can not reset password without activation token"
			);
			return;
		}

		let forcePwdResetData: ActivationResponse | null = null;
		try {
			forcePwdResetData = await (
				await ForcePasswordChangeService
			).default.getForcePasswordChangeData(
				this.userStore.accountActivation?.activationToken
			);
		} catch (error) {
			runInAction(() => {
				this.hasForcePwdResetError = true;
			});
		}

		runInAction(() => {
			this.forcePwdResetData = forcePwdResetData;
			this.isPasswordResetWorking = false;
		});
	}

	@action.bound
	public async handlePwdForceResetSubmit(
		data: ForcePasswordChangeFormData
	): Promise<void> {
		if (this.userStore.accountActivation == null) {
			return;
		}

		this.isPasswordResetWorking = true;

		const accountActivationModel = {
			recoveryToken: this.userStore.accountActivation.activationToken,
			agencyId: this.userStore.accountActivation.agencyId,
			shopId: this.userStore.accountActivation.shopId,
			password: data.password,
			confirmPassword: data.newPasswordConfirm,
			username: data.userCardNumber,
		};

		try {
			const response = await (
				await ForcePasswordChangeService
			).default.createForcePasswordChange(accountActivationModel);

			await this.resolveUserActivationLogin(
				response.primary,
				response.secondary,
				response.errorCode
			);
		} catch (error) {
			console.error(error);
			if (error?.userData) {
				if (error.userData?.activationToken) {
					//show popup with user data
					this.setUserDataToBeConfirmed({
						...error.userData,
						isInternetUser: false,
					});
					this.setUserConfirmationData(error.userData);
					this.setUserDataConfirmationState(true);
				} else {
					//show message about shop
					App.state.rootStore.notificationStore.error(
						localizationService.t(
							"USER.POPUPS.DATA_CONFIRMATION.USER_DATA_CONFIRMATION_REQUIRED",
							{ 0: error.userData.shopInfo }
						)
					);
				}
			} else {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						`MEMBERSHIP.FORCE_PASSWORD_CHANGE.${error?.message}`
					)
				);
			}
		}

		runInAction(() => {
			this.isPasswordResetWorking = false;
		});
	}

	@action.bound
	public async onCancelPwdForceReset(): Promise<void> {
		this.isPasswordResetWorking = true;

		try {
			this.userStore.removeUserFromLocalStorage();
			SessionStorageProvider.remove("slip");
		} catch (error) {
			console.error(error);
			App.state.rootStore.notificationStore.error(
				localizationService.t(
					"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
				)
			);
		}

		runInAction(() => {
			this.isPasswordResetWorking = false;
		});
	}

	@action.bound
	public clearForcePwdError(): void {
		this.hasForcePwdResetError = false;
	}
	@action.bound
	public onDisposePwdReset(): void {
		this.forcePwdResetData = null;
		this.clearForcePwdError();
	}

	//#endregion password reset

	//#region user data confirmation

	@observable public userDataToBeConfirmed?: UserData | null;
	@observable public isUserConfirmationInitialized: boolean = false;
	@observable public userDataConfirmationCountryLookups:
		| CountryLookup[]
		| null = null;

	@computed public get usersCountryName(): string | undefined | null {
		return this.userDataConfirmationCountryLookups?.find(
			(c) => c.id === this.userDataToBeConfirmed?.countryId
		)?.name;
	}

	@action.bound
	public async setUserDataToBeConfirmed(data: UserData): Promise<void> {
		this.userDataToBeConfirmed = data;
		if (this.userDataToBeConfirmed != null) {
			App.state.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.USER_DATA_TO_BE_CONFIRMED,
				JSON.stringify(this.userDataToBeConfirmed)
			);
		} else {
			App.state.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.USER_DATA_TO_BE_CONFIRMED
			);
		}
	}

	@action.bound
	public async onInitializeUserDataConfirmation(): Promise<void> {
		const result = await (
			await DataConfirmationService
		).default.FetchCountryLookups();

		if (result?.result != null) {
			this.userDataConfirmationCountryLookups = result.result.item;
		} else if (result?.error != null) {
			this.logger.logError(result.error);
		}

		runInAction(() => (this.isUserConfirmationInitialized = true));
	}

	@action.bound
	public async onUserConfirmData(): Promise<void> {
		if (this.userDataToBeConfirmed == null) {
			this.logger.logError("Cannot confirm null user data.");
			return;
		}

		const body = {
			username: this.userDataToBeConfirmed.userName,
			agencyKey: this.userDataToBeConfirmed.agencyIdentifier,
			agencyId: this.userDataToBeConfirmed.agencyId,
			shopId: this.userDataToBeConfirmed.shopId,
			activationToken: this.userDataToBeConfirmed.activationToken,
		};

		const result = await UserApiService.acceptDataVerification(body);

		if (result.result != null) {
			//this probably causes blank page for half a second after confirming user data
			runInAction(() => {
				this.userDataToBeConfirmed = null;
				this.setUserDataConfirmationState(false);
				this.resolveUserActivationLogin(
					result.result!.primary,
					result.result!.secondary,
					result.result!.errorCode
				);
			});
		} else if (result.error != null) {
			App.state.rootStore.notificationStore.error(
				localizationService.t(
					"USER.POPUPS.DATA_CONFIRMATION.GENERIC_ERROR"
				)
			);
		} else {
			this.logger.logError("Confirm user data request is empty");
		}
	}

	@action.bound
	public async onOnlineUserConfirmData(): Promise<void> {
		if (this.userDataToBeConfirmed == null) {
			this.logger.logError("Cannot confirm null user data.");
			return;
		}

		const response = await (
			await DataConfirmationService
		).default.AcceptOnlineUserDataVerification();

		if (response) {
			runInAction(() => {
				this.userDataToBeConfirmed = null;
				this.setUserDataConfirmationState(false);
			});
		} else {
			App.state.rootStore.notificationStore.error(
				localizationService.t(
					"USER.POPUPS.DATA_CONFIRMATION.GENERIC_ERROR"
				)
			);
		}
	}

	@action.bound
	public async onCancelUserDataConfirmation(): Promise<void> {
		this.onDisposeUserDataConfirmation();
		this.userStore.logoutUser();
	}

	@action.bound
	onDisposeUserDataConfirmation(): void {
		this.userDataConfirmationCountryLookups = null;
		this.userDataToBeConfirmed = null;
		this.isUserConfirmationInitialized = false;
	}

	//#endregion user data confirmation

	//#region user matching

	@action.bound
	public async checkUserMatchingState(): Promise<void> {
		if (this.isUserMatchingInProgress) {
			await this.getMatchingUsersList();
		}
	}

	@action.bound
	public async getMatchingUsersList(): Promise<void> {
		const matchingDataResponse = await (
			await UserService
		).default.findMatchingData();

		if (matchingDataResponse != null) {
			runInAction(() => {
				this.userMatchingDataList = matchingDataResponse;
			});
		}
	}

	@action.bound
	public setUserMatchingState(flag: boolean): void {
		if (!flag) {
			App.state.rootStore.localStorageProvider.remove(
				StorageStateKeysEnum.USER_MATCHING
			);
		} else {
			App.state.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.USER_MATCHING,
				`${flag}`
			);
		}

		this.isUserMatchingInProgress = flag;
		if (this.isUserMatchingInProgress) {
			this.getMatchingUsersList();
		}
	}

	@action.bound
	private async resolveUserMatchingData(errorCode?: string): Promise<void> {
		if (errorCode != null) {
			if (errorCode === LoginErrorCodes.matchingAvailable) {
				const matchingDataResponse = await (
					await UserService
				).default.findMatchingData();

				if (matchingDataResponse != null) {
					this.setUserMatchingState(true);
					runInAction(() => {
						this.userMatchingDataList = matchingDataResponse;
					});
				}
			}
		}
	}

	@action.bound
	public async onUserMatchingCancel(): Promise<void> {
		this.clearUserMatchingList();
		this.setUserMatchingState(false);

		await (await UserService).default.denyUserMatching();
	}

	@action.bound
	public clearUserMatchingList(): void {
		this.userMatchingDataList = [];
	}

	@action.bound
	public async onUserMatchingConfirm(
		userData: UserMatchingRequestDto
	): Promise<void> {
		this.userStore.loginStore.isLoginWorking = true;
		try {
			const stayLoggedInState =
				App.state.rootStore.localStorageProvider.get(
					StorageStateKeysEnum.SLN
				);
			userData.stayLoggedIn = stayLoggedInState === "true" ? true : false;
			userData.primaryUserId = this.userStore.user?.id!;

			const response = await (
				await UserService
			).default.confirmUserMatching(userData);

			if (response != null) {
				if (response.matchingErrorCode != null) {
					// 400168 - user temp lock
					// 400197 - oasis block
					// 400166 - temp lock (+ u message propu imati ćete broj minuta)
					// 400167 - user lock with reason
					// 400111 - locked user
					// 400112 - not approved
					// 400202 - not valid
					this.userStore.setUserAccounts(response.userAccounts);
					if (response.matchingErrorCode === "400111") {
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"specific:MEMBERSHIP.LOGIN.ERR_HANDLING.USER_LOCKED"
							)
						);
					}
					if (response.matchingErrorCode === "400112") {
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"MEMBERSHIP.LOGIN.ERR_HANDLING.USER_NOT_VERIFIED"
							)
						);
					}
					if (response.matchingErrorCode === "400166") {
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"MEMBERSHIP.LOGIN.ERR_HANDLING.USER_TEMP_LOCK_TIME",
								{ 0: response.message }
							)
						);
					}
					if (response.matchingErrorCode === "400167") {
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"specific:MEMBERSHIP.LOGIN.ERR_HANDLING.USER_LOCKED"
							)
						);
					}
					if (response.matchingErrorCode === "400168") {
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"MEMBERSHIP.LOGIN.ERR_HANDLING.USER_TEMP_LOCK"
							)
						);
					}
					if (response.matchingErrorCode === "400197") {
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"MEMBERSHIP.LOGIN.ERR_HANDLING.OASIS_GAMING_EXCLUSION"
							)
						);
					}
					if (response.matchingErrorCode === "400202") {
						App.state.rootStore.notificationStore.error(
							localizationService.t(
								"MEMBERSHIP.LOGIN.ERR_HANDLING.USER_DOCUMENTS_NOT_VERIFIED"
							)
						);
					}
				} else {
					this.userStore.login(response);
				}
			}

			this.clearUserMatchingList();
			this.setUserMatchingState(false);
		} catch (error) {
			this.logger.logError(error);
			if (error.errorCode === 400175) {
				// WRONG PASSWORD TOO MANY TIMES
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"USER.ACCOUNT_SETTINGS.ERR_HANDLING.WRONG_PASS_SESSION_TERMINATED"
					)
				);
				App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(`/${getCurrentCulture()}/auth/login`);
				// INVALID PASSWORD TRY AGAIN
			} else if (error.errorCode === 400176) {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"USER.ACCOUNT_SETTINGS.ERR_HANDLING.INVALID_PASSWORD"
					)
				);
			}
			// TRIED TO LOG IN INTO THE SAME ACCOUNT
			else if (error.errorCode === 400223) {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"USER.ACCOUNT_SETTINGS.ERR_HANDLING.LOGGED_IN_USER"
					)
				);
			}
			// USER DOESN'T EXIST
			else if (error.errorCode === 400149) {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"USER.ACCOUNT_SETTINGS.ERR_HANDLING.INVALID_USER"
					)
				);
			}
			// MATCHING FAILED - GENERIC ERROR
			else if (error.errorCode === 400224) {
				App.state.rootStore.notificationStore.error(
					localizationService.t("USER_MATCHING.GENERIC_ERROR")
				);
			} else {
				App.state.rootStore.notificationStore.error(
					localizationService.t("USER_MATCHING.GENERIC_ERROR")
				);
			}
		}

		runInAction(() => {
			this.userStore.loginStore.isLoginWorking = false;
		});
	}

	//#endregion user matching

	@action.bound
	private async resolveUserActivationLogin(
		primary: {
			user: User;
			token: UserToken;
			userAccounts: UserAccount[];
			loginHistory: LoginHistoryResponseModel;
		},
		secondary?: {
			user: User;
			token: UserToken;
		},
		errorCode?: string
	): Promise<void> {
		this.isLoginWorking = true;
		this.userStore.login({
			primary,
			secondary,
		});

		if (errorCode === LoginErrorCodes.dataConfirmationRequiredCode) {
			this.setUserDataConfirmationState(true);
			await this.resolveUserDataConfirmationData({
				//@ts-expect-error
				primary: primary,
				//@ts-expect-error
				secondary: secondary,
			});
		}

		await this.resolveUserMatchingData(errorCode);

		runInAction(() => {
			this.isLoginWorking = false;
		});
	}

	@action.bound
	private storageActionListener = (e: StorageEvent) => {
		this.readFromLocalStorage(e.key);
	};

	@action.bound
	private readFromLocalStorage(key: string | null) {
		if (key == null || key == "user_matching") {
			this.setUserMatchingState(
				Boolean(
					App.state.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.USER_MATCHING
					)
				)
			);
		}

		if (key == null || key == "udc") {
			this.setUserDataConfirmationState(
				Boolean(
					App.state.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.UDC
					)
				)
			);
		}
		if (key == null || key == "user-data-to-be-confirmed") {
			this.setUserDataToBeConfirmed(
				JSON.parse(
					App.state.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.USER_DATA_TO_BE_CONFIRMED
					) as string
				)
			);
		}
	}

	@action.bound
	public onDispose(): void {
		window.removeEventListener("storage", this.storageActionListener);
		this.onDisposeUserDataConfirmation();
		this.onDisposeAccountActivation();
		this.isStoreInitialized = false;
		this.isSSOLoginInitialized = false;
	}
}

export enum RequiredUserAction {
	None,
	IsLoginWorking,
	AccountActivation,
	PasswordChange,
	BettingActivity,
	AcceptNewTerms,
	ConfirmUserData,
	AccActivationCongratulation,
	UserMatching,
	AcceptGdpr,
}

export type UserData = {
	userName: string;
	agencyIdentifier: string;
	agencyId?: string;
	activationToken: string | undefined;
	birthPlace: string;
	city: string;
	firstName: string;
	lastName: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	shopInfo: string | null;
	shopId: string;
	dob: string;
	countryId: string;
	isInternetUser: boolean;
	currentEmail: string;
	language: string;
	mobilePhone: string;
	payPin: string;
	receiveNewsletter: boolean;
	telephoneNumber: string;
	titleId: string;
	occupation: string | null;
	nationality: string | null | undefined;
	birthName: string | null;
};
