import React from "react";
import classNames from "classnames";
import { NameProvider } from "@gp/utility";
import { EventType } from "@gp/models";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { formattedStatus } from "../../../../helpers/account-statement/utility";
import { isNullOrWhitespace } from "@v2/helpers/utility";

import {
	getTeamNames,
	getParsedCurrentResult,
	getParsedResult,
	getScorePerPeriod,
	getCurrentResult,
	getCurrentScorePerPeriod,
} from "../../../../helpers/account-statement/utility";
import { getCurrentCulture } from "@utils";
import {
	AccountStatementBetSlip,
	MultiEvent,
} from "@v2/data-access/account-statement/AccountStatementService";
import { Link } from "react-router-dom";

const nameProvider = new NameProvider();

export default function AccountStatementBetDetailsOffer(props: {
	offer: AccountStatementBetSlip["string"][0];
	totalCombinations?: number;
	isLive: boolean;
	isSystem: boolean;
	status: string;
}) {
	const { offer } = props;

	if (offer == null) {
		return null;
	}

	const offerSpecifier = {
		competitor1: offer.sportData.teamOneName || "",
		competitor2: offer.sportData.teamTwoName || "",
		...offer.specifier,
	};

	const teamNames = getTeamNames(offer);

	const bettingTypeName = nameProvider.getName(
		offer.sportData.bettingTypeNameForBettingSlip,
		offerSpecifier
	);

	const bettingTypeTip = nameProvider.getName(teamNames, offerSpecifier);

	const parsedCurrentResult = getParsedCurrentResult(offer);

	const parsedResult = getParsedResult(offer);

	const scorePerPeriod = getScorePerPeriod(parsedResult);

	const currentResult = getCurrentResult(parsedCurrentResult);

	const currentScorePerPeriod = getCurrentScorePerPeriod(
		currentResult,
		parsedCurrentResult
	);

	const { totalCombinations, isSystem, isLive, status } = props;

	const tableRowClassname = classNames("table--bets__tr", {
		"u-color--offer--cashout": status == "cashout",
		[`u-color--offer--${formattedStatus(offer.bettingOfferStatus)}`]:
			status != "cashout",
	});

	const dataCharClassname = classNames(
		"table--bets__td",
		"table--bets__td--combi",
		{
			"u-color--offer--cashout":
				(!isLive || offer.isMulti) && status == "cashout",
			[`u-color--offer--${formattedStatus(offer.bettingOfferStatus)}`]:
				(!isLive || offer.isMulti) && status != "cashout",
		}
	);

	const eventDataClassname = classNames(
		"table--bets__td",
		"table--bets__td--event",
		{
			"u-color--offer--cashout":
				(!isLive || offer.isMulti) && status == "cashout",
			[`u-color--offer--${formattedStatus(offer.bettingOfferStatus)}`]:
				(!isLive || offer.isMulti) && status != "cashout",
		}
	);

	const iconClassname = classNames(
		"u-icon u-icon--xsml",
		"u-icon--offer",
		{
			"u-icon--offer--cashout": status == "cashout",
			[`u-icon--offer--${formattedStatus(offer.bettingOfferStatus)}`]:
				status != "cashout",
		},
		"no-print"
	);

	return (
		<tr className={tableRowClassname}>
			<td className="table--bets__td table--bets__td--time">
				{offer?.isMulti != true &&
					DateTime.fromISO(
						offer.sportData.eventStartTime || ""
					).toFormat("dd.LL. HH:mm")}
			</td>
			<td className={dataCharClassname}>
				{isSystem ||
				(totalCombinations != null && totalCombinations > 1)
					? offer.systemIdentifier
					: ""}
			</td>

			<EventTypes
				offer={offer}
				isSystem={isSystem}
				isLive={isLive}
				parsedCurrentResult={parsedCurrentResult}
				currentResult={currentResult}
				currentScorePerPeriod={currentScorePerPeriod}
				eventDataClassname={eventDataClassname}
			/>

			<td className="table--bets__td table--bets__td--bank">
				{offer.isBank && (
					<span className="tag tag--sml tag--success u-padd--x--micro">
						B
					</span>
				)}
			</td>

			<BettingTypeTipNotNormal
				offer={offer}
				bettingTypeTip={bettingTypeTip}
			/>

			<BettingTypeTipNormal
				offer={offer}
				bettingTypeTip={bettingTypeTip}
				bettingTypeName={bettingTypeName}
			/>

			<td className="table--bets__td table--bets__td--odds">
				{offer.value.toFixed(2)}
			</td>
			<td className="table--bets__td table--bets__td--results">
				<div className="table--bets__result">
					<EventScores
						parsedResult={parsedResult}
						offer={offer}
						scorePerPeriod={scorePerPeriod}
					/>
				</div>
			</td>
			<td className="table--bets__td table--bets__td--icon">
				<i className={iconClassname} />
			</td>
		</tr>
	);
}

function EventAnchorWrapper(props: {
	children: JSX.Element | JSX.Element | string | null;
	offer: MultiEvent["offer"];
}) {
	if (!props.offer.isEventInOffer) {
		return <>{props.children}</>;
	}

	const url = `/${getCurrentCulture()}/${
		props.offer.isLive ? "live/events" : "sports/full/event"
	}/${props.offer.eventId}`;

	return (
		<Link to={url} className="u-anchor">
			{props.children}
		</Link>
	);
}

function EventTypes(
	props: MultiEvent & {
		isSystem: boolean;
		parsedCurrentResult: unknown | null;
		eventDataClassname: string;
	}
) {
	const {
		offer,
		isSystem,
		isLive,
		parsedCurrentResult,
		currentResult,
		currentScorePerPeriod,
		eventDataClassname,
	} = props;

	return (
		<>
			<EventTypeIsNotNormal offer={offer} />

			<EventTypeIsNormal
				offer={offer}
				isSystem={isSystem}
				isLive={isLive}
				parsedCurrentResult={parsedCurrentResult}
				currentResult={currentResult}
				currentScorePerPeriod={currentScorePerPeriod}
				eventDataClassname={eventDataClassname}
			/>
		</>
	);
}

function EventTypeIsNotNormal(props: { offer: MultiEvent["offer"] }) {
	const { offer } = props;

	if (offer.sportData.eventType == EventType.NORMAL) {
		return null;
	}
	return (
		<td className="table--bets__td table--bets__td--event">
			{offer.isMulti ? (
				offer.multiEventName
			) : (
				<EventAnchorWrapper offer={offer}>
					<>{offer.sportData.eventName}</>
				</EventAnchorWrapper>
			)}
		</td>
	);
}

function EventTypeIsNormal(
	props: MultiEvent & {
		isSystem: boolean;
		parsedCurrentResult: unknown | null;
		eventDataClassname: string;
	}
) {
	const {
		offer,
		isSystem,
		isLive,
		parsedCurrentResult,
		currentResult,
		currentScorePerPeriod,
		eventDataClassname,
	} = props;

	if (offer.sportData.eventType != EventType.NORMAL) {
		return null;
	}

	return (
		<>
			<EventTypeIsSystem
				isSystem={isSystem}
				offer={offer}
				isLive={isLive}
				parsedCurrentResult={parsedCurrentResult}
				currentResult={currentResult}
				currentScorePerPeriod={currentScorePerPeriod}
				eventDataClassname={eventDataClassname}
			/>

			<EventTypeNotSystem
				isSystem={isSystem}
				offer={offer}
				parsedCurrentResult={parsedCurrentResult}
				currentResult={currentResult}
				currentScorePerPeriod={currentScorePerPeriod}
				eventDataClassname={eventDataClassname}
			/>
		</>
	);
}

function EventTypeIsSystem(
	props: MultiEvent & {
		isSystem: boolean;
		parsedCurrentResult: unknown | null;
		eventDataClassname: string;
	}
) {
	const {
		isSystem,
		offer,
		isLive,
		parsedCurrentResult,
		currentResult,
		currentScorePerPeriod,
		eventDataClassname,
	} = props;

	if (!isSystem) {
		return null;
	}

	return (
		<td className={eventDataClassname}>
			<EventOfferMulti offer={offer} />

			<EventOfferNotMulti offer={offer} />

			<EventIsLive
				offer={offer}
				isLive={isLive}
				parsedCurrentResult={parsedCurrentResult}
				currentResult={currentResult}
				currentScorePerPeriod={currentScorePerPeriod}
			/>
		</td>
	);
}

function EventTypeNotSystem(
	props: MultiEvent & {
		isSystem: boolean;
		parsedCurrentResult: unknown | null;
		eventDataClassname: string;
	}
) {
	const {
		isSystem,
		offer,
		parsedCurrentResult,
		currentResult,
		currentScorePerPeriod,
		eventDataClassname,
	} = props;

	if (isSystem) {
		return null;
	}

	return (
		<td className={eventDataClassname}>
			<EventOfferMulti offer={offer} />

			<EventOfferNotMulti offer={offer} />

			<EventOfferIsLive
				offer={offer}
				parsedCurrentResult={parsedCurrentResult}
				currentResult={currentResult}
				currentScorePerPeriod={currentScorePerPeriod}
			/>
		</td>
	);
}

function EventOfferMulti(props: { offer: MultiEvent["offer"] }) {
	const { offer } = props;

	if (!offer.isMulti) {
		return null;
	}

	return (
		<div className="table--bets__event">
			<EventAnchorWrapper offer={offer}>
				{offer.multiEventName}
			</EventAnchorWrapper>
		</div>
	);
}

function EventOfferNotMulti(props: { offer: MultiEvent["offer"] }) {
	const { offer } = props;

	if (offer.isMulti) {
		return null;
	}

	return (
		<div className="table--bets__event">
			<EventAnchorWrapper offer={offer}>
				<>
					{offer.sportData.teamOneName} -{" "}
					{offer.sportData.teamTwoName}
				</>
			</EventAnchorWrapper>
		</div>
	);
}

function EventOfferIsLive(
	props: MultiEvent & { parsedCurrentResult: unknown | null }
) {
	const { t } = useTranslation();

	const { offer, parsedCurrentResult, currentResult, currentScorePerPeriod } =
		props;

	if (!offer.isLive || parsedCurrentResult == null || offer.isMulti) {
		return null;
	}

	return (
		<div className="table--bets__live-score">
			*{t("ACC_STATEMENT.BET_DETAILS.D_LIVE").toUpperCase()}*{" "}
			{currentResult}{" "}
			{!isNullOrWhitespace(currentScorePerPeriod)
				? currentScorePerPeriod
				: null}
		</div>
	);
}

function EventIsLive(
	props: MultiEvent & { parsedCurrentResult: unknown | null }
) {
	const { t } = useTranslation();

	const {
		offer,
		parsedCurrentResult,
		currentResult,
		currentScorePerPeriod,
		isLive,
	} = props;

	if (!isLive || parsedCurrentResult == null || offer.isMulti) {
		return null;
	}

	return (
		<div className="table--bets--bets__body__data--event__live-score">
			*{t<string>("ACC_STATEMENT.BET_DETAILS.D_LIVE")}* {currentResult}{" "}
			{!isNullOrWhitespace(currentScorePerPeriod)
				? currentScorePerPeriod
				: null}
		</div>
	);
}

function BettingTypeTipNotNormal({
	bettingTypeTip,
	offer,
}: {
	offer: MultiEvent["offer"];
	bettingTypeTip: string;
}) {
	if (offer.sportData.eventType == EventType.NORMAL) {
		return null;
	}

	return (
		<td className="table--bets__td table--bets__td--bet-type">
			{bettingTypeTip}
		</td>
	);
}

function BettingTypeTipNormal({
	bettingTypeName,
	bettingTypeTip,
	offer,
}: {
	bettingTypeName: string;
	bettingTypeTip: string;
	offer: AccountStatementBetSlip["string"][0];
}) {
	if (offer.sportData.eventType != EventType.NORMAL) {
		return null;
	}

	return (
		<td className="table--bets__td table--bets__td--bet-type">
			{bettingTypeName} ({bettingTypeTip})
		</td>
	);
}

function EventScores(props: MultiEvent) {
	const { parsedResult, offer, scorePerPeriod } = props;

	if (parsedResult == null || offer.isMulti) {
		return null;
	}

	return (
		<>
			<CurrentScore parsedResult={parsedResult} />

			<FullTimeScore parsedResult={parsedResult} />

			<PeriodScore scorePerPeriod={scorePerPeriod} />
		</>
	);
}

function CurrentScore({ parsedResult }: { parsedResult: any }) {
	if (
		parsedResult.currentScore == null ||
		parsedResult.fullTimeScore != null
	) {
		return null;
	}

	return (
		<div className="table--bets__result__ft">
			{parsedResult.currentScore.home || 0}:
			{parsedResult.currentScore.away || 0}
		</div>
	);
}

function FullTimeScore({ parsedResult }: { parsedResult: any }) {
	if (parsedResult.fullTimeScore == null) {
		return null;
	}

	return (
		<div className="table--bets__result__ft">
			{parsedResult.fullTimeScore.home || 0}:
			{parsedResult.fullTimeScore.away || 0}
		</div>
	);
}

function PeriodScore({
	scorePerPeriod,
}: {
	scorePerPeriod: string | undefined;
}) {
	if (isNullOrWhitespace(scorePerPeriod)) {
		return null;
	}

	return <div className="table--bets__result__ht">({scorePerPeriod})</div>;
}
