import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { DraggableContainer } from "@lib/draggable-container";
import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import { logger } from "@state";

export default observer(function SideWidgetDraggableContainer(props: {
	children: React.ReactNode;
	headerRef: HTMLElement | null;
}) {
	const ref = useRef<{ top: number; left: number; width: string } | null>(
		null
	);
	const store = useLiveWidgetStore();

	useEffect(() => {
		return () => {
			if (ref.current == null) {
				return;
			}

			store.updateWidgetFloat(store.isWidgetFloating, {
				...store.initialCss,
				top: ref.current.top,
				left: ref.current.left,
				width: ref.current.width,
			});
		};
	}, []);

	return (
		<DraggableContainer
			isDraggable={store.isWidgetFloating}
			resize={store.isWidgetFloating ? "horizontal" : "none"}
			draggableElement={props.headerRef}
			initialCss={store.initialCss}
			onPositionUpdate={(container) => {
				if (container == null) {
					return;
				}

				const top = parseInt(container.style.top);
				const left = parseInt(container.style.left);
				const width = container.style.width;
				ref.current = { top, left, width };
			}}
			logger={logger}
		>
			<div
				className={classNames("widget", {
					"widget--draggable": store.isWidgetFloating,
				})}
			>
				{props.children}
			</div>
		</DraggableContainer>
	);
});
