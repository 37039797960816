import React from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
const dateFormat = "d.L.yyyy HH:mm:ss";

export const LoginHistory = observer(function LoginHistory() {
	const { t } = useTranslation();
	const loginHistory = App.state.rootStore.userAuthStore.loginHistory;

	if (loginHistory == null) {
		return <div>{t<string>("HEADER.FIRST_LOGIN")}</div>;
	}

	const lastLogin = DateTime.fromISO(loginHistory.lastLogin).toFormat(
		dateFormat
	);
	const currentLogin = DateTime.fromISO(loginHistory.currentLogin).toFormat(
		dateFormat
	);

	return (
		<>
			<div>
				{t<string>("LOGIN_HISTORY.LAST_LOGIN", {
					date: lastLogin,
				})}
			</div>
			<div>
				{t<string>("LOGIN_HISTORY.LOGGED_IN_FROM", {
					date: currentLogin,
				})}
			</div>
		</>
	);
});
