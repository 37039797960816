import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { AccountVerificationStatus } from "@api-types/account-verification/AccountVerificationType";
import { UseDocumentUpload } from "@v2/hooks/Account-verification/useDocumentUpload";
import { Button } from "@v2/components/desktop/shared/buttons";
import { Header } from "./AccountVerificationPage";
import { formatDateTime } from "@v2/helpers";
import classNames from "classnames";

import { useAppContext } from "@v2/hooks";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";
import { supportEmail } from "@v2/helpers/membership/utils";
import { getCurrentCulture } from "@utils";

export default function DocumentUpload({
	documentUploadData,
}: {
	documentUploadData: Omit<UseDocumentUpload, "status"> & {
		status: AccountVerificationStatus;
	};
}) {
	if (documentUploadData.status.isUserAccountVerified == false) {
		return <UnverifiedDocuments {...documentUploadData} />;
	}

	if (documentUploadData.status.isUserAccountVerified == true) {
		return <VerifiedDocuments />;
	}
	return <ErrorMessage />;
}
function UnverifiedDocuments({
	chooseFiles,
	inputRef,
	images,
	removeImage,
	uploadFiles,
	status,
}: Omit<UseDocumentUpload, "document"> & {
	status: AccountVerificationStatus;
}): JSX.Element {
	const { t } = useTranslation();
	const [toggleFilter, setToggleFilter] = useState(true);

	const accordionHeaderClass = classNames(
		"card--primary__header btn btn--block btn--reset u-padd--xsml",
		{
			"is-expanded": toggleFilter,
			"is-collapsed": !toggleFilter,
		}
	);

	const accordionIconClass = classNames("u-icon u-icon--sml", {
		"u-icon--caret--up": toggleFilter,
		"u-icon--caret--down": !toggleFilter,
	});

	const uploadIconClass = classNames(
		"u-icon u-icon--xxlrg u-icon--animated--bounce-in",
		{
			"u-icon--upload": status.documents?.isDocumentUploadEnabled,
			"u-icon--pending": !status.documents?.isDocumentUploadEnabled,
		}
	);

	const documents = status?.documents?.data;
	return (
		<>
			<form
				id="uploadDropzone"
				encType="multipart/form-data"
				className="card--primary"
			>
				<Header />
				<div className="card--primary__body">
					<div className="u-type--center">
						<i className={uploadIconClass} />
						<div className="u-mar--top--xsml u-type--lrg">
							{t(
								"ACCOUNT_VERIFICATION.USER_ACCOUNT_NOT_VERIFIED_HEADER"
							)}
						</div>
						<div className="u-mar--top--xsml u-type--base u-color--text--secondary">
							<HeaderText status={status} />
						</div>
					</div>

					<div className="card--primary u-mar--top--big">
						<button
							type="button"
							className={accordionHeaderClass}
							onClick={() => setToggleFilter(!toggleFilter)}
						>
							<span className="card--primary__title u-type--base u-type--left">
								{t("ACCOUNT_VERIFICATION.INSTRUCTIONS")}
							</span>
							<i className={accordionIconClass} />
						</button>
						{toggleFilter && (
							<div className="card--primary__body u-padd--xsml u-type--sml">
								<div className="card--primary__section">
									<div className="u-mar--bottom--xtny">
										{t(
											"ACCOUNT_VERIFICATION.ACCEPTABLE_IDENTIFICATION_REQUIRES"
										)}
									</div>
									<ul className="list u-type--wgt--medium">
										<li className="u-mar--bottom--xtny">
											{t(
												"ACCOUNT_VERIFICATION.COPY_OF_ID"
											)}
										</li>
										<li>
											{t(
												"ACCOUNT_VERIFICATION.COPY_OF_BILL"
											)}
										</li>
									</ul>
								</div>

								<div className="card--primary__section">
									<div className="u-mar--bottom--xtny">
										{t("ACCOUNT_VERIFICATION.GUIDELINES")}
									</div>
									<ol className="list list--ordered">
										<li className="u-mar--bottom--xtny">
											{t(
												"ACCOUNT_VERIFICATION.GUIDELINE_FILE_EXTENSION"
											)}
										</li>
										<li className="u-mar--bottom--xtny">
											{t(
												"ACCOUNT_VERIFICATION.GUIDELINE_FILE_SIZE"
											)}
										</li>
										<li className="u-mar--bottom--xtny">
											{t(
												"ACCOUNT_VERIFICATION.GUIDELINE_VALID"
											)}
										</li>
										<li className="u-mar--bottom--xtny">
											{t(
												"ACCOUNT_VERIFICATION.GUIDELINE_COLORED"
											)}
										</li>
										<li className="u-mar--bottom--xtny">
											{t(
												"ACCOUNT_VERIFICATION.GUIDELINE_QUALITY"
											)}
										</li>
										<li>
											{t(
												"ACCOUNT_VERIFICATION.GUIDELINE_NOT_EDITED"
											)}
										</li>
									</ol>
								</div>

								<div>
									{t("ACCOUNT_VERIFICATION.DOCUMENTS_TIP", {
										email: supportEmail,
									})}
								</div>
							</div>
						)}
					</div>
				</div>

				{status.documents?.isDocumentUploadEnabled && (
					<div className="card--primary__footer">
						<input
							name="files"
							id="files"
							accept="image/*"
							type="file"
							multiple
							onChange={chooseFiles}
							ref={inputRef}
							className="u-display--none"
						/>
						<label
							htmlFor="files"
							className="card--primary__footer__btn btn btn--lrg btn--primary"
						>
							{t("ACCOUNT_VERIFICATION.SELECT_FILES")}
						</label>
					</div>
				)}
			</form>

			{images && images.length > 0 && (
				<div className="card--primary u-mar--top--xsml">
					<div className="card--primary__body">
						<div className="row">
							{images.map((file, idx) => {
								const fileTypeAbrv = file.type.split("/")[1];

								return (
									<div
										key={file.name + idx}
										className="col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col-ssvga-12 col-hdlow-12 col-xxlrg-12 col-huge-12 col-xxxlrg-6 u-mar--bottom--xsml"
									>
										<div className="card--file card--file--grow card--primary">
											<a
												href={URL.createObjectURL(file)}
												target="_blank"
												className="card--file__body"
											>
												<i
													className={
														"u-icon u-icon--xlrg u-icon--file-" +
														fileTypeAbrv
													}
												/>
												<div className="card--file__info">
													<div className="card--file__name">
														{file.name}
													</div>
													<div className="card--file__size">
														{(
															file.size / 1024
														).toFixed(2) + "KB"}
													</div>
												</div>
											</a>
											<div className="card--file__actions">
												<Button
													btnType="button"
													className="card--file__btn btn--sml btn--block btn--primary"
													btnText="MEMBERSHIP.FORGOT_USERNAME.SEND"
													onClick={() => {
														uploadFiles(idx);
													}}
												/>
												<Button
													btnType="button"
													className="card--file__btn btn--sml btn--block btn--secondary--inverted"
													btnText="COMMON.DELETE"
													onClick={() => {
														removeImage(idx);
													}}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}

			{documents && documents.length !== 0 && (
				<div
					id="uploadedDocuments"
					className="card--primary u-mar--top--xsml"
				>
					<div className="card--primary__body">
						<div className="u-mar--bottom--big u-type--med">
							{t("ACCOUNT_VERIFICATION.UPLOADED_DOCUMENTS")}
						</div>

						<div className="row">
							{documents.map((docs) => {
								const statusTagClasses = classNames(
									"card--file__status",
									{
										"u-color--text--secondary":
											docs.isValid == null,
										"u-color--ui--danger":
											docs.isValid == false,
										"u-color--ui--success":
											docs.isValid == true,
									}
								);

								const statusIconClasses = classNames(
									"u-icon u-icon--xsml",
									{
										"u-icon--clock u-color--text--secondary":
											docs.isValid == null,
										"u-icon--remove u-color--ui--danger":
											docs.isValid == false,
										"u-icon--check u-color--ui--success":
											docs.isValid == true,
									},
									"u-align--v--middle u-mar--right--micro"
								);

								return (
									<div
										key={docs.id}
										className="col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col-ssvga-12 col-hdlow-12 col-xxlrg-12 col-huge-12 col-xxxlrg-6 u-mar--bottom--xsml"
									>
										<div className="card--file card--file--grow card--primary">
											<div className="card--file__details">
												<div className="card--file__date">
													{formatDateTime(
														// @ts-expect-error
														docs.dateCreated
													)}
												</div>
												<div
													className={statusTagClasses}
												>
													<i
														className={
															statusIconClasses
														}
													/>
													<span className="u-align--v--middle">
														{docs.isValid ==
															null && (
															<>
																{t(
																	"ACCOUNT_VERIFICATION.DOC_NOT_REVIEWED"
																)}
															</>
														)}
														{docs.isValid ==
															false && (
															<>
																{t(
																	"ACCOUNT_VERIFICATION.DOC_UNAPPROVED"
																)}
															</>
														)}
														{docs.isValid ==
															true && (
															<>
																{t(
																	"ACCOUNT_VERIFICATION.DOC_APPROVED"
																)}
															</>
														)}
													</span>
												</div>
											</div>
											<div className="card--file__body">
												<i
													className={
														"u-icon u-icon--xlrg u-icon--file-" +
															docs?.userDocumentFileEntry?.fileExtension?.split(
																"."
															)[1] || ""
													}
												/>
												<div className="card--file__info">
													<div className="card--file__name">
														{docs
															?.userDocumentFileEntry
															?.fileName || ""}
													</div>
													<div className="card--file__size">
														{(
															(docs
																?.userDocumentFileEntry
																?.fileSize ||
																0) / 1024
														).toFixed(2) + "KB"}
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

function VerifiedDocuments() {
	const { t } = useTranslation();
	const { culture } = useAppContext();

	return (
		<div className="card--primary">
			<Header />
			<div className="card--primary__body">
				<div className="u-type--center">
					<i className="u-icon u-icon--xxlrg u-icon--check-circle u-color--ui--success u-icon--animated--bounce-in"></i>
					<div className="u-mar--top--xsml u-type--lrg">
						{t("ACCOUNT_VERIFICATION.USER_ACCOUNT_VERIFIED")}
					</div>
					<div className="u-mar--top--xsml u-type--base u-color--text--secondary">
						<span>
							{t(
								"ACCOUNT_VERIFICATION.USER_ACCOUNT_VERIFIED_LONG"
							)}
							.{" "}
						</span>
						<span>{t("ACCOUNT_VERIFICATION.WISHES_MESSAGE")}</span>
					</div>
				</div>
			</div>

			<div className="card--primary__footer">
				<NavLink
					to={`/${culture}/deposit`}
					className="card--primary__footer__btn btn btn--lrg btn--primary"
				>
					{t("ACCOUNT_VERIFICATION.DEPOSIT_NOW")}
				</NavLink>
				<NavLink
					to={`/${culture}/home/full/highlights`}
					className="card--primary__footer__btn btn btn--lrg btn--secondary--inverted"
				>
					{t("ACCOUNT_VERIFICATION.START_BETTING")}
				</NavLink>
			</div>
		</div>
	);
}

function ErrorMessage() {
	const { t } = useTranslation();
	return (
		<InfoMessageComponent
			type="note"
			message={t("COMMON.ERROR_MESSAGES.DEFAULT")}
		/>
	);
}

function HeaderText(props: { status: AccountVerificationStatus }) {
	const { t } = useTranslation();

	if (props.status.shouldUpdateProfile) {
		return (
			<>
				{t("ACCOUNT_VERIFICATION.MISSING_PERSONAL_INFO_PART_1")}{" "}
				<Link
					className="u-anchor u-anchor--underline u-anchor--primary"
					to={`/${getCurrentCulture()}/account-settings/personal-data`}
				>
					{t("USER.ACCOUNT_SETTINGS.NAVIGATION.PERSONAL_DATA")}
				</Link>{" "}
				{t("ACCOUNT_VERIFICATION.MISSING_PERSONAL_INFO_PART_2")}
			</>
		);
	}
	const message = props.status.errorMessage?.split(".").slice(1).join(".");

	if (props.status.errorMessage && message) {
		return <>{message}</>;
	}

	return <>{t("ACCOUNT_VERIFICATION.USER_ACCOUNT_NOT_VERIFIED_MESSAGE")}</>;
}
