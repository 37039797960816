import { observer } from "mobx-react";
import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Gravatar from "react-gravatar";
import { useTranslation } from "react-i18next";
import { HeaderLockButton, UserBalanceDisplay, UserMenu } from ".";
import { useAppContext, useUser } from "hooks/shared";
import OutsideClickDetector from "@v2/components/shared/utility/OutsideClickDetector";
import { isRegistrationEnabled } from "@v2/helpers";
import FailedLoginsCheckButton from "@v2/components/shared/FailedLoginsCheckButton";

export default observer(function HeaderUserDetails() {
	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
	const user = useUser();

	const { t } = useTranslation();
	const { culture } = useAppContext();
	const { pathname } = useLocation();

	if (user.user != null) {
		return (
			<>
				<HeaderLockButton />

				{user != null && WEBPACK_FAILED_LOGIN_REPORT?.isEnabled && (
					<FailedLoginsCheckButton />
				)}

				<OutsideClickDetector
					onOutsideClick={() => setIsUserMenuOpen(false)}
					wrapperClasses="header__user"
				>
					<>
						<div className="usermenu">
							<div className="usermenu__title">
								{user.user.username}
							</div>
							<ul className="wallet wallet--sml">
								<UserBalanceDisplay isInMenu={false} />
							</ul>
						</div>

						{user.isUserCritical ? (
							<div
								className="header__avatar header__avatar--critical"
								onClick={() =>
									setIsUserMenuOpen(!isUserMenuOpen)
								}
							>
								<div className="header__tag header__tag--critical" />

								<Gravatar
									email={user.user.email}
									default="mp"
									className="header__avatar__thumb"
								/>
							</div>
						) : user.isUserWarning ? (
							<div
								className="header__avatar header__avatar--warning"
								onClick={() =>
									setIsUserMenuOpen(!isUserMenuOpen)
								}
							>
								<div className="header__tag header__tag--warning" />

								<Gravatar
									email={user.user.email}
									default="mp"
									className="header__avatar__thumb"
								/>
							</div>
						) : (
							<div
								className="header__avatar"
								onClick={() =>
									setIsUserMenuOpen(!isUserMenuOpen)
								}
							>
								<Gravatar
									email={user.user.email}
									default="mp"
									className="header__avatar__thumb"
								/>
							</div>
						)}

						<UserMenu
							isUserMenuOpen={isUserMenuOpen}
							setIsUserMenuOpen={setIsUserMenuOpen}
						/>
					</>
				</OutsideClickDetector>
			</>
		);
	}
	return (
		<div className="header__user">
			<Link
				to={`/${culture}/auth/login`}
				className={
					"header__user__cta btn btn--sml" +
					(!isRegistrationEnabled ||
					pathname === `/${culture}/auth/register`
						? " btn--primary"
						: " btn--secondary")
				}
			>
				{t("HEADER.LOGIN")}
			</Link>

			<RegistrationButton />
		</div>
	);
});

function RegistrationButton() {
	const { culture } = useAppContext();
	const { t } = useTranslation();
	const { pathname } = useLocation();

	if (!isRegistrationEnabled || pathname === `/${culture}/auth/register`) {
		return null;
	}

	return (
		<Link
			to={`/${culture}/auth/register`}
			className="header__user__cta btn btn--sml btn--primary"
		>
			{t("MEMBERSHIP.LOGIN.OPEN_ACCOUNT_LINK")}
		</Link>
	);
}
