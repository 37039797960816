import { DepositApiService } from "@api-services/deposit/DepositApiService";
import { BaseLookupDto } from "@api-types/lookups/BaseLookupDto";
import {
	DepositDataDto,
	DepositResponseDto,
	UsedPaymentMethodDto,
} from "@api-types/deposit";
import {
	DepositInitRequestData,
	PaymentRequestFilter,
	DepositFinalizeMultistep,
} from "@data-types/deposit";
import { UserPaymentTransactionsApiService } from "@api-services/user-payment-transactions/UserPaymentTransactionsApiService";

import LookupApiService from "@api-services/LookupApiService";

import { FindResultDto } from "@api-types/FindResultDto";

export class DepositService {
	static async finalizeMultiStepDeposit(data: DepositFinalizeMultistep) {
		return await DepositApiService.finalizeMultiStepDeposit(data);
	}

	static async initializeNewDepositProcess(data: DepositInitRequestData) {
		return await DepositApiService.initializeNewDepositProcess(data);
	}

	static async getAvailableMethods(): Promise<DepositDataDto> {
		return await DepositApiService.getAvailableMethods();
	}

	static async getUserPaymentMethods(): Promise<UsedPaymentMethodDto[]> {
		const isDeposit = true;
		return await LookupApiService.getUserPaymentMethods(isDeposit);
	}

	static async getTransactionStatuses(): Promise<
		FindResultDto<BaseLookupDto>
	> {
		return await LookupApiService.getTransactionStatuses();
	}

	static async getDepositData(
		bodyData: PaymentRequestFilter
	): Promise<DepositResponseDto> {
		return await UserPaymentTransactionsApiService.getDepositData(bodyData);
	}
}
