import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "@v2/components/shared";

export default function BetSlipConfirmationModal(props: {
	headerText?: string;
	bodyText?: string;
	onReject: () => void;
	onAccept: () => void;
}) {
	const { t } = useTranslation();

	return (
		<Modal>
			<div className="popup">
				<div className="popup__card">
					<div className="popup__header">
						{/* {t('LIVE.INFO')} */}
						{props.headerText && props.headerText !== ""
							? t<string>(props.headerText)
							: null}
					</div>
					<div className="popup__body">
						{props.bodyText && props.bodyText !== ""
							? t<string>(props.bodyText)
							: null}
					</div>
					<div className="popup__footer">
						<button
							className="popup__footer__btn btn btn--sml btn--secondary--inverted"
							onClick={props.onReject}
						>
							{t<string>("BET_SLIP.NO")}
						</button>
						<button
							className="popup__footer__btn btn btn--sml btn--primary"
							onClick={props.onAccept}
						>
							{t<string>("BET_SLIP.YES")}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
