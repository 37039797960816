import { Offer } from "@api-types/account-settings/Offer";
import {
	httpClient,
	getAgencyKey,
	getApplicationHeaders,
	UserTypes,
} from "@utils";
import { FilterEventId } from "@v2/data-access/account-statement/AccountStatementService";

export class OfferService {
	static baseUrl(owner: UserTypes = UserTypes.PRIMARY): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(owner)}/offer`;
	}

	static getRequestHeaders = (owner = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(owner);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async findOffer(
		eventIds: FilterEventId,
		owner: UserTypes = UserTypes.PRIMARY
	): Promise<Offer> {
		return await httpClient.post(
			`${this.baseUrl(owner)}`,
			JSON.stringify(eventIds),
			this.getRequestHeaders(owner),
			{ responseType: "json" }
		);
	}
}
