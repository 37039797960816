import { LimitCategoriesViewModel } from "@data-types";
import React from "react";
import { useTranslation } from "react-i18next";

export default function MyLimitsTabDescription(props: PropTypes) {
	const { description } = props;
	const { t } = useTranslation();

	return (
		<>
			<ul className="list list--disc message--limits">
				{description.map((desc, index) => (
					<li key={index}>
						{index == 0
							? desc
							: t<string>(
									"USER.ACCOUNT_SETTINGS.MY_LIMITS." + desc,
									{
										time: props.category.updateInterval,
									}
							  )}
					</li>
				))}
			</ul>
		</>
	);
}
type PropTypes = {
	description: string[];
	category: LimitCategoriesViewModel;
};
