import AgencyUserApiService from "@api-services/user/AgencyUserApiService";
import RootStore from "@state/stores/RootStore";
import { StorageStateKeysEnum } from "@utils/storage";
import { observable, action } from "mobx";
import { localizationService } from "@state";

type TRootStore = {
	localStorageProvider: RootStore["localStorageProvider"];
	userAuthStore: RootStore["userAuthStore"];
};
class OnboardingNewsletterStore {
	@observable isActive: boolean = false;

	rootStore: TRootStore;

	constructor(rootStore: TRootStore) {
		this.rootStore = rootStore;
	}

	@action.bound
	showPopup() {
		this.isActive = true;
	}

	@action.bound
	hidePopup() {
		this.isActive = false;
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.ONBOARDING_NEWSLETTER_POPUP,
			JSON.stringify(true)
		);
	}

	@action.bound
	async acceptNewsletter(email: string, recaptcha: string) {
		try {
			await AgencyUserApiService.subscribeToNewsletter(email, recaptcha);
			this.hidePopup();
		} catch (err) {
			const errorObj = await err.rawResponse.json();
			if (errorObj.errorCode == 400126) {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"USER.POPUPS.ONBOARDING_WELCOME_POPUPS.NEWSLETTER.USER_WITH_EMAIL_ALREADY_EXISTS"
					)
				);
			} else {
				App.state.rootStore.notificationStore.error(
					localizationService.t("ERROR.TITLE")
				);
			}
			return false;
		}
	}
}

export default OnboardingNewsletterStore;
