import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccountStatementTableTransaction } from ".";
import { AccountStatementPopupDetails } from "../details";
import { Pager } from "@v2/components/desktop/shared/inputs";
import { AccountStatementBetContext } from "@v2/context/AccountStatementBetContext";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default function AccountStatementTransactionsTable() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);

	if (contextValue === null) return null;

	const { fetchSlipDetails, tableData, fetchTransactionsTable } =
		contextValue;

	if (tableData === null) {
		return null;
	}

	const { totalRecords, transactions, page, recordsPerPage } = tableData;

	if (!transactions || transactions.length == 0) {
		return (
			<InfoMessageComponent
				type="note"
				message={t<string>("ACC_STATEMENT.TABLES.NO_TRANSACTION_TXT")}
				addClassName="u-mar--top--xsml"
			/>
		);
	}

	return (
		<>
			<table className="table u-mar--top--xsml">
				<thead className="table__head">
					<tr className="table__head__row table__row">
						<th className="table__head__cell table__cell">ID</th>
						<th className="table__head__cell table__cell">
							{t<string>("ACC_STATEMENT.TABLES.TABLE_DATE")}
						</th>
						<th className="table__head__cell table__cell">
							{t<string>("ACC_STATEMENT.TABLES.TABLE_TYPE")}
						</th>
						<th className="table__head__cell table__cell u-type--right">
							{t("ACC_STATEMENT.TABLES.TABLE_AMOUNT")}
						</th>
						<th className="table__head__cell table__cell u-type--right">
							{t("ACC_STATEMENT.TABLES.TABLE_BAL")}
						</th>
						<th className="table__head__cell table__cell u-type--right">
							<span className="counter counter--base counter--dark--outlined">
								{totalRecords}
							</span>
						</th>
					</tr>
				</thead>
				<tbody className="table__body">
					{transactions.map((transaction) => (
						<AccountStatementTableTransaction
							key={transaction.numericalId}
							transaction={transaction}
							fetchSlipDetails={fetchSlipDetails}
						/>
					))}
				</tbody>
			</table>

			<AccountStatementPopupDetails />

			<Pager
				pageNumber={page}
				pageSize={recordsPerPage}
				totalItems={totalRecords}
				onPageChange={fetchTransactionsTable}
				isRemoveAllVisible={false}
				onCloseAll={false}
			/>
		</>
	);
}
