import { observable, action, computed } from "mobx";
import { min } from "lodash";

import { NameProvider } from "@gp/utility";

import { logger } from "@state";
import BetSlipStore from "@v2/state/bet-slip/BetSlipStore";
import { MyBetsViewStore } from "./components/my-bets";
import { SearchBarViewStore } from "./components/search";
import { EventSwitcherViewStore } from "./components/event";
import PromoOfferViewStore from "./pages/home/components/PromoOfferViewStore";

import ResizeStore from "./ResizeStore";
import FavoritesStore from "./FavoritesStore";

class RootStore {
	resizeStore: ResizeStore;
	nameProvider: NameProvider;
	searchBarViewStore: SearchBarViewStore;
	myBetsViewStore: MyBetsViewStore;
	betSlipStore: BetSlipStore;
	promoOfferViewStore: PromoOfferViewStore;
	eventSwitcherViewStore: EventSwitcherViewStore;
	@observable isAdditionalOfferVisible = false;
	@observable isSideMenuExpanded = true;

	@computed get calculatedOfferColumnsNumber() {
		return min([
			this.isAdditionalOfferVisible
				? Math.floor(this.resizeStore.availableColumns / 2)
				: this.resizeStore.availableColumns,
			this.isAdditionalOfferVisible ? 2 : 4,
		]);
	}

	get hub() {
		return App.state.rootStore.offerHub.hub;
	}

	constructor() {
		this.nameProvider = new NameProvider();
		this.resizeStore = new ResizeStore();
		this.searchBarViewStore = new SearchBarViewStore();
		// must be before betSlipStore
		this.myBetsViewStore = new MyBetsViewStore(this);
		this.betSlipStore = new BetSlipStore({
			offerHub: App.state.rootStore.offerHub.hub,
			userStore: App.state.rootStore.userAuthStore,
			notificationStore: App.state.rootStore.notificationStore,
			rootOfferStore: this,
			balanceResolver: () =>
				App.state.rootStore.userAuthStore.resolveBalance(),
			logger: logger,
		});

		this.promoOfferViewStore = new PromoOfferViewStore(this);

		this.eventSwitcherViewStore = new EventSwitcherViewStore();
	}

	@action.bound
	onInitialize() {
		// fix for infinite redirects on ip restriction #116314
		// we don't need this here
		// this.promoOfferViewStore.onInitialize();
	}

	@action.bound
	onDispose() {}

	@action.bound
	setAdditionalOfferVisible(value: boolean) {
		this.isAdditionalOfferVisible = value;
	}

	@action.bound
	openSideMenu() {
		this.isSideMenuExpanded = true;
	}

	@action.bound
	closeSideMenu() {
		this.isSideMenuExpanded = false;
	}

	@action.bound
	toggleSideMenu() {
		if (this.isSideMenuExpanded) {
			this.closeSideMenu();
		} else {
			this.openSideMenu();
		}
	}
}

export default RootStore;
