import { useState, useEffect, useContext } from "react";
import { OfferOptionsContext } from "@gp/components";
import { Event, Sport } from "@gp/models";

const sportsWithIndicatedScore = ["soccer", "handball", "basketball"];
const notStarted = "not-started";

export function useLiveMatchResult(event: Event, sport: Sport) {
	const offerOptions = useContext(OfferOptionsContext);
	const [isScore, setIsScore] = useState(false);
	const [timeOutId, setTimeoutId] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (
			!sportsWithIndicatedScore.includes(sport.abrv) ||
			event.currentScore == null
		) {
			return;
		}

		setIsScore(true);

		timeOutId != null && clearTimeout(timeOutId);

		const newTimeOutId = window.setTimeout(() => {
			if (timeOutId == null) return;
			setIsScore(false);
			setTimeoutId(undefined);
		}, offerOptions.scoreChangeDuration);

		setTimeoutId(newTimeOutId);

		return () => {
			if (timeOutId != null) {
				clearTimeout(timeOutId);
				setTimeoutId(undefined);
			}
		};
	}, [event.currentScore?.home, event.currentScore?.away]);

	const getScore = (team: "home" | "away") => {
		if (event.matchStatus === notStarted) {
			return "-";
		}

		if (event.currentScore != null) {
			return event.currentScore[team] || 0;
		}

		return 0;
	};

	return {
		isScore,
		home: getScore("home"),
		away: getScore("away"),
	};
}
