import React, { useEffect } from "react";
import { useUser } from "@v2/hooks/shared";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputField, PasswordInput } from "../../shared/inputs";
import { ActivationResponse } from "@data-types/common/ActivationResponse";
import { passwordValidationPattern } from "@gp/common";

export default function AccountActivationPasswordSection(props: PropTypes) {
	const { trigger, getFieldState, getValues } = useFormContext();
	const { t } = useTranslation();
	const { accountActivationData } = props;

	useEffect(() => {
		if (
			getFieldState("password").isTouched &&
			getFieldState("confirmPassword").isTouched
		) {
			trigger("confirmPassword");
		}
	}, [useWatch({ name: "password" })]);

	if (accountActivationData == null) {
		return null;
	}

	const isPasswordRequired =
		accountActivationData?.companyInfo.isOfflineUserPasswordChangeMandatory;

	return (
		<>
			{isPasswordRequired == true && (
				<div className="message--base message--note u-mar--bottom--xsml">
					{t<string>("MEMBERSHIP.ACCOUNT_ACTIVATION.PWD_HEADER")}
				</div>
			)}

			<div className="row u-mar--bottom--xsml">
				<FormInputField
					className="col col-sml-12 form__field"
					name="userCardNumber"
					label="MEMBERSHIP.ACCOUNT_ACTIVATION.USER_CARD_NUMBER"
					readOnly
					defaultValue={accountActivationData?.userInfo?.userName}
				/>
			</div>

			<div className="row u-mar--bottom--xsml">
				<PasswordInput
					name="password"
					className="col col-sml-12 form__field"
					label="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_PASSWORD_LABEL"
					placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_PASSWORD_PLACEHOLDER"
					pattern={{
						value: passwordValidationPattern,
						message:
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
					}}
					required={isPasswordRequired}
					autoComplete="new-password"
				/>
			</div>

			<div className="row u-mar--bottom--big">
				<PasswordInput
					name="confirmPassword"
					className="col col-sml-12 form__field"
					label="MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRM_NEW_PASSWORD_LABEL"
					placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRM_NEW_PASSWORD_PLACEHOLDER"
					pattern={{
						value: passwordValidationPattern,
						message:
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
					}}
					validate={{
						isEqual: (value: string) =>
							value === getValues("password") ||
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.DOES_NOT_MATCH_PWD",
					}}
					required={isPasswordRequired}
				/>
			</div>
		</>
	);
}

type PropTypes = {
	accountActivationData: ActivationResponse | null;
};
