import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { EventType } from "@gp/models";

import { MyBetsStoreContext, MyBetsBetContext } from "../../../../context";
import { getCurrentCulture } from "@utils";
import { BetOffer } from "@administration/pages/my-bets/model/BetOffer";

export default observer(function BetOfferItem(props: { offer: BetOffer }) {
	const { isSystem: isBetSystem, totalNumberOfCombinations } =
		useContext(MyBetsBetContext);

	const isSystem = isBetSystem == null || isBetSystem == "";

	const systemIdStr =
		isSystem == true && totalNumberOfCombinations > 1
			? props.offer.systemIdentifier
			: "";

	return (
		<tr className="table__tr">
			<BetOfferItem_Time offer={props.offer} />
			<td className="table--bets__td table--bets__td--combi">
				{systemIdStr}
			</td>
			<EventDataWrapper offer={props.offer} />
			<td className="table--bets__td table--bets__td--bank">
				{props.offer.isBank === true && (
					<span className="tag tag--sml tag--success u-padd--x--micro">
						B
					</span>
				)}
			</td>
			<BetOfferItem_Tip offer={props.offer} />
			<td
				className={`table--bets__td table--bets__td--odds u-color--offer--${props.offer.formattedBettingOfferStatus}`}
			>
				{props.offer.value.toFixed(2)}
			</td>
			<td className="table--bets__td table--bets__td--results">
				<BetOfferItem_OfferResults offer={props.offer} />
			</td>
			<td className="table--bets__td table--bets__td--icon">
				<i
					className={`u-icon u-icon--xsml u-icon--offer u-icon--offer--${props.offer.formattedBettingOfferStatus}`}
				/>
			</td>
		</tr>
	);
});

const BetOfferItem_Time = observer(function BetOfferItem_Time(props: {
	offer: BetOffer;
}) {
	const myBetsStore = useContext(MyBetsStoreContext);

	function checkIfIsMulti() {
		if (props.offer.isMulti) {
			return { time: "", cssClass: "" };
		}

		if (myBetsStore?.activeEventsStore.eventsMap.has(props.offer.eventId)) {
			if (
				myBetsStore.activeEventsStore.eventsMap.get(props.offer.eventId)
					?.matchTime?.displayTime
			) {
				return {
					time: myBetsStore.activeEventsStore.eventsMap.get(
						props.offer.eventId
					)?.matchTime?.displayTime,
					cssClass: " u-color--ui--danger",
				};
			}

			return { time: props.offer.timeString, cssClass: "" };
		}

		return { time: props.offer.timeString, cssClass: "" };
	}
	const data = checkIfIsMulti();

	return (
		<td className={"table--bets__td table--bets__td--time" + data.cssClass}>
			{data.time}
		</td>
	);
});

function BetOfferItem_Tip(props: { offer: BetOffer }) {
	let str = "";
	if (props.offer.sportData.eventType === EventType.NORMAL) {
		str = `${props.offer.formattedBettingType} (${props.offer.formattedBettingTypeTip})`;
	} else {
		str = `${props.offer.formattedBettingTypeTip}`;
	}

	return (
		<td
			className={
				"table--bets__td table--bets__td--bet-type u-color--offer--" +
				props.offer.formattedBettingOfferStatus
			}
		>
			{str}
		</td>
	);
}

function EventDataWrapper(props: { offer: BetOffer }) {
	if (props.offer.sportData.eventType != 0) {
		return <NormalEventData offer={props.offer} />;
	}

	return <EventData offer={props.offer} />;
}

function NormalEventData(props: { offer: BetOffer }) {
	const eventNameStr =
		props.offer.hasOwnProperty("isMulti") && props.offer.isMulti == true
			? props.offer.multiEventName
			: props.offer.sportData.eventName;

	return (
		<td className="table--bets__td table--bets__td--event">
			<LinkActiveEventsHOC
				eventId={props.offer.eventId}
				className="table--bets__td--link"
				isLive={props.offer.isLive}
			>
				{eventNameStr}
			</LinkActiveEventsHOC>
		</td>
	);
}

function EventData(props: { offer: BetOffer }) {
	const { t } = useTranslation();

	const { isSystem: isSystemBet, isLive: isBetLive } =
		useContext(MyBetsBetContext);

	return (
		<td className="table--bets__td table--bets__td--event">
			<LinkActiveEventsHOC
				eventId={props.offer.eventId}
				className="u-anchor"
				isLive={props.offer.isLive}
			>
				{props.offer.isMulti ? (
					<div className="table--bets__event">
						{props.offer.multiEventName}
					</div>
				) : (
					<div className="table--bets__event">{`${props.offer.teamOneString} - ${props.offer.teamTwoString}`}</div>
				)}

				{((isSystemBet && isBetLive) ||
					(!isSystemBet && props.offer.isLive)) &&
					props.offer.formattedResultAtBetTime != "" &&
					!props.offer.isMulti && (
						<div className="table--bets__live-score">
							*{t("MY_BETS.ITEM.LIVE_SCORE").toUpperCase()}*{" "}
							{props.offer.formattedResultAtBetTime}{" "}
							{props.offer.scorePerPeriodAtBetTimeString}
						</div>
					)}
			</LinkActiveEventsHOC>
		</td>
	);
}

const BetOfferItem_OfferResults = observer(
	function BetOfferItem_OfferResults(props: { offer: BetOffer }) {
		const { offer } = props;
		const myBetStore = useContext(MyBetsStoreContext);

		if (props.offer.sportData.result == null || props.offer.isMulti) {
			return null;
		}
		const getLiveEvent =
			myBetStore?.activeEventsStore.eventsMap.get(props.offer.eventId) ||
			false;

		return (
			<div className="table--bets__result">
				<div className="table--bets__result__ft">
					{props.offer.formattedCurrentResult(getLiveEvent)}
				</div>

				{props.offer.scorePerPeriodString() != "" && (
					<div className="table--bets__result__ht">
						{props.offer.scorePerPeriodString(getLiveEvent)}
					</div>
				)}
			</div>
		);
	}
);

const LinkActiveEventsHOC = observer(function LinkActiveEventsHOC(props: {
	eventId: string;
	className?: string;
	isLive?: boolean;
	children: JSX.Element | JSX.Element[];
}) {
	const { eventId, className, isLive, children } = props;
	const myBetStore = useContext(MyBetsStoreContext);

	if (myBetStore?.activeEventsStore.eventsMap.has(eventId)) {
		const page = isLive ? "live/events" : "sports/full/event";
		const url = `/${getCurrentCulture()}/${page}/${eventId}`;

		return (
			<Link className={className} to={url}>
				{children}
			</Link>
		);
	}

	return <>{children}</>;
});
