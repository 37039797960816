import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { RefreshBalanceBtn } from "../buttons";
import { LoginHistory, UserBalanceDisplay } from "./";
import { useAppContext, useUser } from "../../../../../ui/common/hooks";
import { isCasinoEnabled, isLiveCasinoEnabled } from "@v2/helpers";
import { useRootAppStore } from "@hooks";
import classNames from "classnames";
import { ChatEntryPoint } from "@data-types/chat";

export const UserMenu = observer(function UserMenu(props: {
	isUserMenuOpen: boolean;
	setIsUserMenuOpen: (value: boolean) => void;
}) {
	const { isUserMenuOpen, setIsUserMenuOpen } = props;

	const user = useUser();

	const { t } = useTranslation();

	const { culture } = useAppContext();

	const rootStore = useRootAppStore();

	const requiredBtnClasses = classNames("btn btn--sml btn--block btn--icon", {
		"btn--danger":
			rootStore.userAuthStore.isUserCritical ||
			(rootStore.userAuthStore.isUserCritical &&
				rootStore.userAuthStore.isUserWarning),
		"btn--warning":
			rootStore.userAuthStore.isUserWarning &&
			!rootStore.userAuthStore.isUserCritical,
	});

	const requiredIconClasses = classNames(
		"u-icon u-icon--med u-color--core--snow",
		{
			"u-icon--alert-triangle":
				rootStore.userAuthStore.isUserCritical ||
				(rootStore.userAuthStore.isUserCritical &&
					rootStore.userAuthStore.isUserWarning),
			"u-icon--alert-circle":
				rootStore.userAuthStore.isUserWarning &&
				!rootStore.userAuthStore.isUserCritical,
		}
	);

	if (!isUserMenuOpen) {
		return null;
	}

	const logout = async () => {
		await user.logoutUser();
		App.state.history.push(`/${culture}/home/full/highlights`);
	};

	return (
		<div className="dropdown--user">
			<div className="dropdown--user__head">
				<RefreshBalanceBtn
					className="dropdown--user__refresh btn--square btn--square--tny btn--secondary--inverted btn--animate-rotate"
					type="button"
					title="Reload balance"
					btnContent={
						<i className="u-icon u-icon--sml u-icon--refresh" />
					}
				/>
				<div className="dropdown--user__name">
					{user.user?.username}
				</div>
				<div className="dropdown--user__accounts">
					<ul className="wallet">
						<UserBalanceDisplay
							isInMenu={true}
							activeAccountTypeId={user.activeAccountTypeId}
							setActiveUserAccountTypeId={
								user.setActiveUserAccountTypeId
							}
						/>
					</ul>
				</div>
			</div>

			<div className="dropdown--user__history">
				<LoginHistory />
			</div>

			<div className="dropdown--user__menu">
				{rootStore.requiredUserActionStore.userActionStatus && (
					<div className="dropdown--user__required">
						<button
							type="button"
							className={requiredBtnClasses}
							onClick={() => {
								rootStore.requiredUserActionStore.showPopup();
								setIsUserMenuOpen(false);
							}}
						>
							<i className={requiredIconClasses}></i>
							<span className="btn__label">
								{t("WELCOME_POPUP.REQUIRED_ACTIONS_TITLE")}
							</span>
						</button>
					</div>
				)}

				<div className="dropdown--user__title">
					{t<string>("HEADER.MY_ACCOUNT")}
				</div>

				<ul className="dropdown--user__list">
					<li
						className="dropdown--user__item"
						onClick={() => setIsUserMenuOpen(false)}
					>
						<NavLink
							to={`/${culture}/my-bets`}
							className="dropdown--user__link"
							activeClassName="active"
							isActive={(match, location) =>
								location.pathname == `/${culture}/my-bets` ||
								match
							}
						>
							<span className="dropdown--user__label">
								{t("HEADER.MY_BETS")}
							</span>
							<i className="u-icon u-icon--tny u-icon--caret--right" />
						</NavLink>
					</li>

					<li
						className="dropdown--user__item"
						onClick={() => setIsUserMenuOpen(false)}
					>
						<NavLink
							to={`/${culture}/account-statement`}
							className="dropdown--user__link"
							activeClassName="active"
							isActive={(match, location) =>
								location.pathname ==
									`/${culture}/account-statement` || match
							}
						>
							<span className="dropdown--user__label">
								{t("HEADER.ACCOUNT_STATEMENT")}
							</span>
							<i className="u-icon u-icon--tny u-icon--caret--right" />
						</NavLink>
					</li>

					{user.isInternetUser ? (
						<>
							<li
								className="dropdown--user__item"
								onClick={() => setIsUserMenuOpen(false)}
							>
								<NavLink
									to={`/${culture}/deposit`}
									className="dropdown--user__link"
									activeClassName="active"
									isActive={(match, location) =>
										location.pathname ==
											`/${culture}/deposit` || match
									}
								>
									<span className="dropdown--user__label">
										{t("DEPOSIT.TITLE")}
									</span>
									<i className="u-icon u-icon--tny u-icon--caret--right" />
								</NavLink>
							</li>
							<li
								className="dropdown--user__item"
								onClick={() => setIsUserMenuOpen(false)}
							>
								<NavLink
									to={`/${culture}/withdrawal`}
									className="dropdown--user__link"
									activeClassName="active"
									isActive={(match, location) =>
										location.pathname ==
											`/${culture}/withdrawal` || match
									}
								>
									<span className="dropdown--user__label">
										{t("WITHDRAWAL.TITLE")}
									</span>
									<i className="u-icon u-icon--tny u-icon--caret--right" />
								</NavLink>
							</li>
						</>
					) : null}

					{isCasinoEnabled || isLiveCasinoEnabled ? (
						<li
							className="dropdown--user__item"
							onClick={() => setIsUserMenuOpen(false)}
						>
							<NavLink
								to={`/${culture}/gaming-history`}
								className="dropdown--user__link"
								activeClassName="active"
								isActive={(match, location) =>
									location.pathname ==
										`/${culture}/gaming-history` || match
								}
							>
								<span className="dropdown--user__label">
									{t("FOOTER.GH_HEAD")}
								</span>
								<i className="u-icon u-icon--tny u-icon--caret--right" />
							</NavLink>
						</li>
					) : null}
					<li
						className="dropdown--user__item"
						onClick={() => setIsUserMenuOpen(false)}
					>
						<NavLink
							to={`/${culture}/account-settings/personal-data`}
							className="dropdown--user__link"
							activeClassName="active"
							isActive={(match, location) =>
								location.pathname ==
									`/${culture}/account-settings/personal-data` ||
								match
							}
						>
							<span className="dropdown--user__label">
								{t("HEADER.ACCOUNT_SETTINGS")}
							</span>
							<i className="u-icon u-icon--tny u-icon--caret--right" />
						</NavLink>
					</li>
					<ChatNavigation setIsUserMenuOpen={setIsUserMenuOpen} />
				</ul>
			</div>
			<div className="dropdown--user__footer">
				<button
					disabled={user.isLogoutDisabled}
					className="btn btn--base btn--block btn--secondary--inverted btn--icon"
					onClick={logout}
				>
					<i className="u-icon u-icon--sml u-icon--logout" />
					<span>{t("HEADER.LOGOUT")}</span>
				</button>
			</div>
		</div>
	);
});

const ChatNavigation = observer(function ChatNavigation(props: {
	setIsUserMenuOpen: Function;
}) {
	const { t } = useTranslation();
	const { chatViewStore } = useRootAppStore();

	if (!WEBPACK_IS_CHAT_AVAILABLE) {
		return null;
	}

	return (
		<li
			style={{
				cursor: "pointer",
			}}
			className="dropdown--user__link"
			onClick={() => {
				props.setIsUserMenuOpen(false);
				chatViewStore.onInitialize(ChatEntryPoint.USER_MENU, null);
			}}
		>
			<span className="dropdown--user__label">
				{t("HEADER.OPEN_CHAT")}
			</span>
			{chatViewStore.isDisposing ? (
				<span className="btn--load btn--load--primary u-mar--auto"></span>
			) : (
				<>
					<span className="tag tag--tny tag--overflow u-type--case--uppercase u-mar--right--xtny">
						{t("CASINO.NEW_GAME_LABEL")}
					</span>
					<i className="u-icon u-icon--tny u-icon--caret--right" />
				</>
			)}
		</li>
	);
});
