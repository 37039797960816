import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MyLimitsTabBody, MyLimitsTabs } from "./components";
import MyLimitsStore from "@v2/state/myLimits/MyLimitsStore";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import { RootAppStoreContext } from "@context";
import { observer } from "mobx-react";
import { setTabTitle } from "@utils";
import { useLoading } from "@hooks";

export default observer(function MyLimitsPage() {
	const rootStore = useContext(RootAppStoreContext);
	const myLimitsStore = useMemo(() => new MyLimitsStore(rootStore), []);
	const { setIsLoading } = useLoading();

	const { t } = useTranslation();

	useEffect(() => {
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.MY_LIMITS"));

		window.scrollTo(0, 0);

		myLimitsStore.onInitialize();
	}, []);

	useEffect(() => {
		setIsLoading(myLimitsStore.isRequestPending);
	}, [myLimitsStore.isRequestPending]);

	return (
		<MyLimitsStoreContext.Provider value={myLimitsStore}>
			<div className="l--content__main">
				<div className="container">
					<div className="card--primary">
						<div className="card--primary__header">
							<div className="card--primary__title title--primary">
								{t<string>(
									"USER.ACCOUNT_SETTINGS.MY_LIMITS.TITLE"
								)}
							</div>
						</div>
						<div className="card--primary__tabs">
							<nav className="tabs--primary">
								<MyLimitsTabs />
							</nav>
						</div>

						{myLimitsStore.tabs !== null && <MyLimitsTabBody />}
					</div>
				</div>
			</div>
		</MyLimitsStoreContext.Provider>
	);
});
