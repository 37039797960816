import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { constants, SportOffer } from "@gp/offer";
import {
	SportOfferContext,
	OfferStoreContext,
	OfferOptionsContext,
} from "@gp/components";

export const TournamentHeaderBettingTypeColumns = observer(
	function TournamentHeaderBettingTypeColumns() {
		const {
			configuration: { bettingTypeSelectorsStore: btStore },
		} = useContext(OfferStoreContext);

		const { numberOfColumns } = useContext(OfferOptionsContext);

		const sport = useContext(SportOfferContext) as SportOffer;

		const selectors = btStore.getSportSelector(sport);

		if (sport.isOutright) {
			return null;
		}

		const columns = Object.values(selectors || {}).slice(
			0,
			numberOfColumns
		);

		return (
			<>
				{columns.map((column, index) => (
					<Column
						key={column != null ? column.name : index}
						column={column}
					/>
				))}
			</>
		);
	}
);

function Column(props: { column: { displayTips: string[] } }) {
	const { column } = props;

	if (column == null) {
		return <div className="offer__head__data offer__type">&nbsp;</div>;
	}

	return (
		<div className="offer__head__data offer__type">
			<div className="offer__type__row">
				<Tips tips={column.displayTips} />
			</div>
		</div>
	);
}

function Tips(props: { tips: string[] }) {
	const { tips } = props;

	return (
		<>
			{tips.map((tip) => (
				<Tip key={tip} tip={tip} />
			))}
		</>
	);
}

function Tip(props: { tip: string }) {
	const { t } = useTranslation();

	const { tip } = props;

	// Even tho configuration contains ONLY empty placeholder we added an option for other elements
	if (
		[
			constants.setnrPlaceholder,
			constants.gamenrPlaceholder,
			constants.marginPlaceholder,
			constants.emptyPlaceholder,
		].includes(tip)
	) {
		return <div className="offer__type__col">&nbsp;</div>;
	}

	return (
		<div className="offer__type__col">
			{t([`BETTING_TYPE.TIPS.${tip}`, tip])}
		</div>
	);
}
