import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { useRootOfferStore } from "@hooks";

import { BetDisplay, BetListDisplay } from "./components";

export default observer(function MyBets() {
	const myBetsViewStore = useRootOfferStore().myBetsViewStore;
	useEffect(() => {
		myBetsViewStore.onInitialize();
		return () => myBetsViewStore.onDispose();
	}, []);

	if (myBetsViewStore.isBetOpen) {
		return <BetDisplay myBetsViewStore={myBetsViewStore} />;
	}

	return <BetListDisplay myBetsViewStore={myBetsViewStore} />;
});
