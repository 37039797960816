import { EventOffer, SportOffer } from "@gp/offer";
import { formatTennisGameScore } from "@offer/common";

/**
 * @param event event for which to format tennis score
 * @param sport events sport
 * @returns If sport is not tennis returns null; If event has tiebreak returns tiebreak score; Finally returns currentGameScore
 */
export function useFormatTennisGameScore(
	event: EventOffer,
	sport: SportOffer
): TennisGameScoreType {
	if (sport.abrv !== "tennis" || event.sportId !== sport.id) {
		return;
	}

	const defaultReturn = { home: "0", away: "0" };

	if (event.result?.tiebreak === true) {
		if (event.result?.tiebreakScore != null) {
			return {
				home: String(event.result?.tiebreakScore?.home || 0),
				away: String(event.result?.tiebreakScore?.away || 0),
			};
		} else {
			return defaultReturn;
		}
	}

	if (event.result?.currentGameScore) {
		return {
			home: formatTennisGameScore(event.result?.currentGameScore.home),
			away: formatTennisGameScore(event.result?.currentGameScore.away),
		};
	}

	return defaultReturn;
}

type TennisGameScoreType =
	| {
			home: string;
			away: string;
	  }
	| undefined;
