import React from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { useAppContext } from "@v2/hooks";
import { RequiredUserAction } from "@v2/state/shared";
import { StorageStateKeysEnum } from "@utils/storage";
import { useRootAppStore } from "@hooks";
import { isCapacitorPlatform } from "@utils/specific/capacitor";

export const PrivateRoute = observer(function PrivateRoute(props: any) {
	const { culture } = useAppContext();
	const { children } = props;

	const { userAuthStore, isLocalStorageLoaded } = useRootAppStore();

	if (isCapacitorPlatform() && !isLocalStorageLoaded) {
		return null;
	}

	if (
		userAuthStore.isLoggedIn ||
		userAuthStore.loginStore.nextRequiredUserAction !=
			RequiredUserAction.None ||
		App.state.rootStore.localStorageProvider.get(
			StorageStateKeysEnum.USER_TOKEN
		) != null
	) {
		return children;
	}

	return <Redirect exact to={`/${culture}/auth/login`} />;
});
