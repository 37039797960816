import { observable, action, runInAction, computed } from "mobx";
import { LoaderStore } from "../../../../../state/stores/common";
import RootOfferStore from "@offer/stores/RootStore";
import { TopTournament } from "@api-types/offer/TopTournament";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const  PromoOfferService =lazy(loadFailPath, ()=>import("@api-services/offer/PromoOfferService"));

class PromoOfferViewStore {
	@observable topTournaments: TopTournament[] = [];
	@observable isInitialized = false;
	@observable isFetchingData = false;
	private loader: LoaderStore;

	@computed get isLoading() {
		return this.loader.isLoading;
	}

	constructor(rootStore: RootOfferStore) {
		this.loader = new LoaderStore();
		this.loader.isLoading = true;
		this.loader.suspend();
	}

	@action.bound
	public async onInitialize() {
		this.loader.suspend();
		this.isFetchingData = true;

		try {
			const response = await (
				await PromoOfferService
			).default.findTopTournaments();
			response.sort((a, b) => {
				const aOrder = a.sortOrder;
				const bOrder = b.sortOrder;

				return aOrder - bOrder;
			});
			runInAction(() => {
				this.topTournaments = response;
			});
		} catch (err) {
			console.error(err);
			runInAction(() => {
				this.topTournaments = [];
			});
		}
		runInAction(() => {
			this.isInitialized = true;
			this.isFetchingData = false;
			this.loader.resume();
		});
	}

	/** Returns array that contains tournament ids for a given top tournament. */
	@action.bound
	public async getTopTournamentIds(topTournamentId: string) {
		await this.onInitialize();

		const tt = this.topTournaments.find(
			(tournament) => topTournamentId === tournament.id
		);

		if (tt?.tournamentIds == null) {
			return [];
		}

		return tt.tournamentIds;
	}

	@action.bound
	public onDispose() {
		this.loader.suspend();
		this.isInitialized = false;
		this.isFetchingData = false;
		this.topTournaments = [];
	}
}

export default PromoOfferViewStore;
