import { RegulationDefinitionsApiService } from "@api-services/regulation-definitions";
import { RegulationDefinitionItem } from "@api-types/regulation-definitions";
import { useLoading } from "@hooks";
import { useEffect, useState } from "react";

type TStoreItems =
	| "aboutUsWeb"
	| "termsAndConditionsWeb"
	| "responsibleGamingWeb"
	| "bettingRulesWeb"
	| "bonusTermsWeb"
	| "liveCasinoGameRulesWeb"
	| "privacyWeb"
	| "liveCasinoTermsOfUseWeb"
	| "helpFaqWeb";

type TStoreRegulationItems = {
	[key in TStoreItems]?: RegulationDefinitionItem | null;
};

export function useRegulationAgency() {
	const [regulationAgency, setRegulationAgency] =
		useState<TStoreRegulationItems>({});

	const loading = useLoading();
	async function fetchRegulations() {
		loading.setIsLoading(true);
		try {
			const response =
				await RegulationDefinitionsApiService.getRegulationDefinitions();

			if (response.length > 0) {
				const items: TStoreItems[] = [
					"aboutUsWeb",
					"termsAndConditionsWeb",
					"responsibleGamingWeb",
					"bettingRulesWeb",
					"bonusTermsWeb",
					"privacyWeb",
					"liveCasinoGameRulesWeb",
					"liveCasinoTermsOfUseWeb",
					"helpFaqWeb",
				];
				const data: TStoreRegulationItems = {
					aboutUsWeb: null,
					termsAndConditionsWeb: null,
					responsibleGamingWeb: null,
					bettingRulesWeb: null,
					bonusTermsWeb: null,
					privacyWeb: null,
					liveCasinoGameRulesWeb: null,
					liveCasinoTermsOfUseWeb: null,
					helpFaqWeb: null,
				};

				items.forEach((regulation: TStoreItems) => {
					data[regulation] = response.find(
						(data: RegulationDefinitionItem) =>
							data.regulationType.abrv.replaceAll("-", "") ===
							regulation.toLowerCase()
					);
				});
				setRegulationAgency(data);
			}
		} catch (e) {
			console.error(e);
		} finally {
			loading.setIsLoading(false);
		}
	}
	useEffect(() => {
		fetchRegulations();
	}, []);

	return regulationAgency;
}
