import { LiveStreamResponse } from "@api-types/event/LiveStreamResponse";
import { httpClient } from "@utils";
import { getApplicationHeaders } from "@utils";

export class EventLiveStreamApiService {
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}platform/live-stream`;
	}

	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getStreamUrl(
		eventId: string,
		streamType: string = "hls"
	): Promise<LiveStreamResponse> {
		// this is real request - uncomment me after dev phase
		const url = `${this.baseUrl}/${eventId}/${streamType}`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
		// this is real request - uncomment me after dev phase

		// dummy
		// REMOVE IT

		// await new Promise((resolve) => setTimeout(resolve, 400));

		// let response: LiveStreamResponse;

		// let successResponse: LiveStreamResponse = {
		// 	statusCode: 2000000000,
		// 	response:
		// 		"https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8",
		// };

		// let errorResponse: LiveStreamResponse = {
		// 	statusCode: 5000000000,
		// 	errorCode: 400801,
		// 	message: "Stream is unavailable",
		// };

		// // change this to errorResponse to test error response
		// response = successResponse;
		// console.log("LS fake API response: ", response);

		// return new Promise<LiveStreamResponse>((resolve) => resolve(response));
	}
}
