import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PersonalDataContent } from "./";

export default function PersonalDataPage() {
	const methods = useForm({ mode: "onChange" });

	return (
		<FormProvider {...methods}>
			<PersonalDataContent />
		</FormProvider>
	);
}
