import { observable, action, runInAction, computed } from "mobx";
import { Subscription } from "rxjs";

import { ICountSubscription } from "@gp/hub";
import { EventType, LiveStatus } from "@gp/models";
import RootOfferStore from "@offer/stores/RootStore";

import BaseLiveEventMenuViewStore from "./BaseLiveEventMenuViewStore";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";
import FavoritesStore from "@offer/stores/FavoritesStore";

class LiveEventMenuViewStore extends BaseLiveEventMenuViewStore {
	@observable totalLiveEventsCount = 0;
	@observable totalHighlightsCount = 0;
	@observable upcomingEventsCount = 0;
	@observable liveStreamEventCount = 0;

	private menuCountSubscription: Subscription | null;
	private favoritesStore: FavoritesStore;

	@computed get liveCount() {
		return this.totalLiveEventsCount;
	}

	@computed get highlightsCount() {
		return this.totalHighlightsCount;
	}

	@computed get liveEventsFromMyBets() {
		return this.liveOfferMenuStore.liveEventsFromMyBets;
	}

	@computed get liveFavoritesCounter() {
		return this.favoritesStore.liveFavoritesCounter;
	}

	constructor(
		rootStore: RootOfferStore,
		liveOfferMenuStore: LiveOfferMenuStore,
		favoriteStore: FavoritesStore
	) {
		super(rootStore, liveOfferMenuStore);
		this.favoritesStore = favoriteStore;
	}

	@action.bound
	async onLoad() {
		super.onLoad();

		let filter: ICountSubscription = {
			subscriptionId: "live-event-menu-counter",
			count: {
				live: {
					liveStatus: LiveStatus.LIVE,
				},
				upcoming: {
					liveStatus: LiveStatus.PREMATCH,
					isUpcoming: true,
				},
				highlights: {
					liveStatus: LiveStatus.LIVE,
					isTopEvent: true,
				},
				liveStream: {
					liveStreamStatus: { eq: ["live", "upcoming"] },
					liveStatus: LiveStatus.LIVE,
				},
			},
		};
		this.disposeMenuCountSubscription();

		this.menuCountSubscription = this.rootStore.hub
			.getCounts(filter)
			.subscribe({
				next: (response) => {
					// prettier-ignore
					runInAction(() => {
						response.live = parseInt(response.live as string);
						response.upcoming = parseInt(response.upcoming as string);
						response.highlights = parseInt(response.highlights as string);
						response.favorites = parseInt(response.favorites as string);
						response.highlightFavorites = parseInt(response.highlightFavorites as string);
						response.liveStream = parseInt(response.liveStream as string);

						if (!isNaN(response.live)) {
							this.totalLiveEventsCount = response.live;
						}
						if (!isNaN(response.upcoming)) {
							this.upcomingEventsCount = response.upcoming;
						}
						if (!isNaN(response.highlights)) {
							this.totalHighlightsCount = response.highlights;
						}
						if (!isNaN(response.liveStream)) {
							this.liveStreamEventCount = response.liveStream;
						}
					});
				},
				error: (err) => {
					console.error(err);
				},
			});
	}

	@action.bound
	public onDispose() {
		this.disposeMenuCountSubscription();
		this.totalLiveEventsCount = 0;
		this.totalHighlightsCount = 0;
		this.upcomingEventsCount = 0;
		this.liveStreamEventCount = 0;
		super.onDispose();
	}

	@action.bound
	private disposeMenuCountSubscription() {
		if (this.menuCountSubscription) {
			this.menuCountSubscription.unsubscribe();
			this.menuCountSubscription = null;
		}
	}
}

export default LiveEventMenuViewStore;
