import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoading } from "@hooks";
import { useNotification } from "@v2/hooks/shared";
import usePasswordConfirmation from "@v2/hooks/shared/usePasswordConfirmation";
import { removeUndefinedFields } from "@v2/helpers/account-settings/personal-data/utils";
import { setTabTitle } from "@utils";
import PersonalDataService from "@services/account-settings/PersonalDataService";
import { PersonalDataResponseModel } from "@data-types/account-settings/PersonalDataDto";
import { PlayerPromoSendingMode } from "@api-types/user/AgencyUserDto";
import { logger } from "@state";

export default function usePersonalData() {
	const [fetchedData, setFetchedData] =
		useState<PersonalDataResponseModel | null>(null);
	const { setIsLoading } = useLoading();
	const { setShowPasswordConfirmation } = usePasswordConfirmation();

	const { t } = useTranslation();

	const {
		reset,
		getValues,
		setValue,
		formState: { dirtyFields },
		trigger,
	} = useFormContext();

	const { showError, showSuccess } = useNotification();

	const fetchData = async () => {
		try {
			setIsLoading(true);
			const response = await PersonalDataService.getPersonalData();
			if (response != null) {
				setFetchedData(response);
				reset(response.data);
			}
		} catch (error) {
			logger.logError(error);
			showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error?.message);
		} finally {
			setIsLoading(false);
			trigger();
		}
	};

	const onInitialize = () => {
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.PERSONAL_DATA"));
		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
		fetchData();
	};

	const onSubmitData = () => {
		setShowPasswordConfirmation(true);
	};

	const onCancel = () => {
		setShowPasswordConfirmation(false);
	};

	const onSuccess = async (password: string) => {
		try {
			if (fetchedData == null) {
				throw "Expected fetchedData, got null";
			}

			setIsLoading(true);
			const formData = removeUndefinedFields(
				getValues(),
				fetchedData.data
			);
			let receiveNewsletter;
			if ("receiveNewsletter" in formData) {
				receiveNewsletter =
					formData.receiveNewsletter == PlayerPromoSendingMode.enabled
						? 1
						: 0;
			}
			const response = await PersonalDataService.updatePersonalData({
				...formData,
				receiveNewsletter,
				password: password,
			});
			if (response != null) {
				const test = { ...fetchedData, data: { ...response } };
				setFetchedData(test);
				reset(response);
				setShowPasswordConfirmation(false);
				showSuccess(
					"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONAL_DATA_UPDATE_SUCCESS"
				);
			}
			setIsLoading(false);
			return true;
		} catch (error) {
			logger.logError(error);
			if (error?.message) {
				if (error?.isFull) {
					showError(error?.message);
				} else {
					showError(
						"USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error?.message
					);
				}
			}
			setIsLoading(false);
			return false;
		}
	};

	const handleDiscard = () => {
		if (Object.keys(dirtyFields).length != 0) {
			const payPin = getValues("payPin");
			reset();
			setValue("payPin", payPin);
		}
	};

	const updateUserPin = async (currPayPin: string) => {
		return PersonalDataService.updateUserPin(currPayPin);
	};

	const getNewPayPin = () => {
		return PersonalDataService.getNewPayPin();
	};

	const checkIsCityOrStreetFieldValid = (value: string) => {
		//this regex does something
		return RegExp("^[\\p{L}0-9 /'-.]{1,80}$").test(value);
	};

	return {
		onInitialize,
		onSubmitData,
		onCancel,
		onSuccess,
		handleDiscard,
		updateUserPin,
		getNewPayPin,
		fetchedData,
		checkIsCityOrStreetFieldValid,
	};
}
