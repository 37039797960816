import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import EventOfferGroups from "./EventOfferGroups";

export default observer(function MyBetsPageBetItemDetails() {
	const [isDetailsOpen, setIsDetailsOpen] = useState(false);
	return (
		<div className="bet--mini__footer">
			<MyBetsBetItemDetails_ToggleButton
				isDetailsOpen={isDetailsOpen}
				setIsDetailsOpen={setIsDetailsOpen}
			/>

			{isDetailsOpen && (
				<div className="bet bet--mini__inner">
					<EventOfferGroups offersHaveColor />
				</div>
			)}
		</div>
	);
});

function MyBetsBetItemDetails_ToggleButton(props: {
	isDetailsOpen: boolean;
	setIsDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { isDetailsOpen, setIsDetailsOpen } = props;

	const { t } = useTranslation();

	// TODO MY_BETS.ITEM.SHOW_DETAILS MY_BETS.ITEM.HIDE_DETAILS
	const text = !isDetailsOpen
		? t("MY_BETS.REPORTS.SHOW_DETAILS")
		: t("COMMON.CLOSE") +
		  " " +
		  t("ACC_STATEMENT.BET_DETAILS.BET_SLIP_DETAILS").toLowerCase();

	const iconClasses = classNames("u-icon u-icon--tny", {
		"u-icon--caret--right": !isDetailsOpen,
		"u-icon--caret--up": isDetailsOpen,
	});

	return (
		<button
			className="bet--mini__footer__btn btn btn--base btn--block btn--ghost btn--icon"
			onClick={() => setIsDetailsOpen(!isDetailsOpen)}
		>
			<span className="btn__label u-type--xsml u-color--text--secondary">
				{text}
			</span>
			<i className={iconClasses} />
		</button>
	);
}
