import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { MyBetsStoreContext } from "../../../../context";
import { useFixBody } from "@v2/hooks/shared";
import { logger } from "@state/providers/consoleLogger";

export default observer(function CancelBetSlipPopUp() {
	const store = useContext(MyBetsStoreContext);
	const t = useTranslation().t;
	useFixBody(true, store?.betCancelStore.isCancelPopUpOpen);

	if (store == null) {
		logger.logError("Expected store got something else.");
		return null;
	}

	if (
		!store.betCancelStore.isCancelPopUpOpen ||
		store.betCancelStore.betCancelMessage != null
	) {
		return null;
	}

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					{t<string>("MY_BETS.CASHOUT.BET_SLIP_NUMBER", {
						0:
							store.betCancelStore.betToCancel?.betSlipNumber ||
							"",
					})}
				</div>
				<div className="popup__body">
					{t<string>("MY_BETS.CANCEL_SLIP_TEXT")}
				</div>
				<div className="popup__footer">
					<button
						className="popup__footer__btn btn btn--sml btn--secondary--inverted"
						type="button"
						onClick={store.betCancelStore.onExitBetCancel}
					>
						{t<string>("BET_SLIP.NO")}
					</button>
					<button
						className="popup__footer__btn btn btn--sml btn--primary"
						type="button"
						onClick={store.betCancelStore.onBetCancelConfirm}
					>
						{t<string>("COMMON.YES")}
					</button>
				</div>
			</div>
		</div>
	);
});
