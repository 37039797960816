import { GamingHistoryRequestFilter } from "@api-types/gaming-history/GamingHistoryRequestFilter";
import { GamingHistoryResponse } from "@api-types/gaming-history/GamingHistoryResponse";
import { TransactionDto } from "@api-types/gaming-history/TransactionDto";
import { httpClient, getApplicationHeaders } from "@utils";

class GamingHistoryApiService {
	get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}platform`;
	}

	getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		appHeaders["Accept" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	async getGamingHistoryData(
		bodyData: GamingHistoryRequestFilter
	): Promise<GamingHistoryResponse> {
		return await httpClient.post(
			`${this.baseUrl}/reports/GameHistory`,
			JSON.stringify(bodyData),
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	async getAvailableLiveGames(): Promise<Array<string>> {
		return await httpClient.get(
			`${this.baseUrl}/casino-provider-games/live-game-groups`,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	async getTransactionsPerRound(
		bodyData: GamingHistoryRequestFilter
	): Promise<{ result: TransactionDto[] }> {
		return await httpClient.post(
			`${this.baseUrl}/reports/CasinoTransactions`,
			JSON.stringify(bodyData),
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}
}

const gamingHistoryApiService = new GamingHistoryApiService();

export { gamingHistoryApiService };
