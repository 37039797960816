import React from "react";
import { observer } from "mobx-react";

import { ErrorBoundary } from "@lib/error-boundary";
import { logger } from "@state";
import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import { LMTContainer } from "./LMTContainer";
import { WidgetLocation } from "./models/LiveWidgetTypes";
import { LiveStreamContainer } from "./LiveStreamContainer";
import { VideoPlayerConfig } from "./config/LiveStreamConfig";

export type LiveWidgetContainerProps = {
	eventId: string;
	location: WidgetLocation;

	/** If absent there will be no lmt */
	lmtProps?: {
		/** Props to pass to container div for lmt */
		divProps?: React.HTMLProps<HTMLDivElement>;
	};

	/** If absent there will be no ls */
	lsProps?: {
		/** Props to pass to container div for ls */
		divProps?: React.HTMLProps<HTMLDivElement>;
		config: VideoPlayerConfig;
	};
};

/** Container that will show ls or lmt if opened in state. */
export const LiveWidgetContainer = observer(function LiveWidgetContainer(
	props: LiveWidgetContainerProps
) {
	const store = useLiveWidgetStore();

	if (store.widgetsAreSuspended) {
		return null;
	}

	return (
		<ErrorBoundary logger={logger}>
			<>
				{props.lmtProps != null && (
					<LMTContainer
						divProps={props.lmtProps.divProps}
						eventId={props.eventId}
						location={props.location}
					/>
				)}

				{props.lsProps != null && (
					<div {...props.lsProps.divProps}>
						<LiveStreamContainer
							{...props.lsProps}
							eventId={props.eventId}
							location={props.location}
						/>
					</div>
				)}
			</>
		</ErrorBoundary>
	);
});
