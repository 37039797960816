import { RegulationDefinitionItem } from "@api-types/regulation-definitions";
import { getApplicationHeaders, httpClient } from "@utils";

export default class RegulationDefinitionsApiService {
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}platform/regulation-definitions/agency-regulation-definition`;
	}

	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getRegulationDefinitions(): Promise<
		RegulationDefinitionItem[]
	> {
		try {
			const response = await httpClient.get(
				this.baseUrl,
				this.getRequestHeaders(),
				{
					responseType: "json",
				}
			);

			return response.item;
		} catch (error: unknown) {
			console.error(error);

			return [];
		}
	}
}
