import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
	EventOfferGroups_BettingType,
	EventOfferGroups_EventName,
	EventOfferGroups_Time,
} from "./components";
import { TMappedBetSlip } from "@v2/state/quick-play-store/MostPlayedTicketsStore";
import classNames from "classnames";

export default observer(function MostPlayedTicketsCard(props: {
	betSlip: TMappedBetSlip;
	displayAdditionalInfo?: boolean;
	isPage?: boolean;
	isPopup?: boolean;
}) {
	const t = useTranslation().t;

	const wrapperClassNames = classNames("u-padd--top--xtny", {
		"card--most--faded":
			props.betSlip.eventCount > props.betSlip.betSlipOffers.length,
	});

	const wrapperHomeClassNames = classNames("u-padd--top--xtny", {
		"card--most--faded-home":
			props.betSlip.eventCount > props.betSlip.betSlipOffers.length,
	});

	if (props.isPage) {
		return (
			<div className="card--primary__body u-padd--xsml u-padd--top--xtny">
				<div className="card--most__table">
					<div className="row">
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--xsml">
								{t(
									"QUICK_PLAY.MOST_PLAYED_TICKETS.PLACED_BETS"
								)}
							</span>
							<span className="u-type--wgt--medium u-type--sml">
								{props.betSlip.submitCount}
							</span>
						</div>
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--xsml">
								{t(
									"QUICK_PLAY.MOST_PLAYED_TICKETS.COMBINATION"
								)}
							</span>
							<span className="u-type--wgt--medium u-type--sml">
								{props.betSlip.eventCount}x
							</span>
						</div>
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--xsml">
								{t("QUICK_PLAY.MOST_PLAYED_TICKETS.TOTAL_ODDS")}
							</span>
							<span className="u-type--wgt--medium u-type--sml">
								{props.betSlip.maxCoefficient.toFixed(2)}
							</span>
						</div>
					</div>
				</div>
				<div className={wrapperClassNames}>
					{props.betSlip.betSlipOffers.map((betSlipOffer) => (
						<div
							key={betSlipOffer.bettingOfferId}
							className="u-display--f u-display--f--col"
						>
							<EventOfferGroups_Time
								offer={betSlipOffer}
								displayTime={props.displayAdditionalInfo}
								isPage
							/>
							<EventOfferGroups_EventName
								offer={betSlipOffer}
								isDetails={true}
								isPage
							/>
							<EventOfferGroups_BettingType
								offer={betSlipOffer}
								isPage
							/>
						</div>
					))}
				</div>
			</div>
		);
	} else if (props.isPopup) {
		return (
			<div className="card--most__body">
				<div className="card--most__table borderless u-padd--bottom--xsml">
					<div className="row">
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--sml">
								{t(
									"QUICK_PLAY.MOST_PLAYED_TICKETS.PLACED_BETS"
								)}
							</span>
							<span className="u-type--wgt--medium u-type--base">
								{props.betSlip.submitCount}
							</span>
						</div>
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--sml">
								{t(
									"QUICK_PLAY.MOST_PLAYED_TICKETS.COMBINATION"
								)}
							</span>
							<span className="u-type--wgt--medium u-type--base">
								{props.betSlip.eventCount}x
							</span>
						</div>
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--sml">
								{t("QUICK_PLAY.MOST_PLAYED_TICKETS.TOTAL_ODDS")}
							</span>
							<span className="u-type--wgt--medium u-type--base">
								{props.betSlip.maxCoefficient.toFixed(2)}
							</span>
						</div>
					</div>
				</div>
				<div className="card--primary card--most">
					{props.betSlip.betSlipOffers.map((betSlipOffer) => (
						<div
							className="card--most__body border"
							key={betSlipOffer.bettingOfferId}
						>
							<EventOfferGroups_EventName
								offer={betSlipOffer}
								isDetails={true}
							/>
							<EventOfferGroups_Time
								offer={betSlipOffer}
								displayTime={props.displayAdditionalInfo}
								isPopup
							/>
							<EventOfferGroups_BettingType
								offer={betSlipOffer}
								withCoefficient={props.displayAdditionalInfo}
								isPopup
							/>
						</div>
					))}
					{props.betSlip.eventCount >
						props.betSlip.betSlipOffers.length && "..."}
				</div>
			</div>
		);
	} else {
		return (
			<div className="card--most__body">
				<div className="card--most__table">
					<div className="row">
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--xsml">
								{t(
									"QUICK_PLAY.MOST_PLAYED_TICKETS.PLACED_BETS"
								)}
							</span>
							<span className="u-type--wgt--medium u-type--sml">
								{props.betSlip.submitCount}
							</span>
						</div>
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--xsml">
								{t(
									"QUICK_PLAY.MOST_PLAYED_TICKETS.COMBINATION"
								)}
							</span>
							<span className="u-type--wgt--medium u-type--sml">
								{props.betSlip.eventCount}x
							</span>
						</div>
						<div className="col u-display--f u-display--f--col">
							<span className="u-color--text--tertiary u-type--xsml">
								{t("QUICK_PLAY.MOST_PLAYED_TICKETS.TOTAL_ODDS")}
							</span>
							<span className="u-type--wgt--medium u-type--sml">
								{props.betSlip.maxCoefficient.toFixed(2)}
							</span>
						</div>
					</div>
				</div>
				<div>
					<div className={wrapperHomeClassNames}>
						{props.betSlip.betSlipOffers.map((betSlipOffer) => (
							<div
								className="u-mar--bottom--xtny"
								key={betSlipOffer.bettingOfferId}
							>
								<EventOfferGroups_Time
									offer={betSlipOffer}
									displayTime={props.displayAdditionalInfo}
								/>
								<EventOfferGroups_EventName
									offer={betSlipOffer}
									isDetails={true}
								/>
								<EventOfferGroups_BettingType
									offer={betSlipOffer}
									withCoefficient={
										props.displayAdditionalInfo
									}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
});
