import React, { useContext, useMemo, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import {
	EventContext,
	OfferStoreContext,
	SportOfferContext,
} from "@gp/components";
import { useLiveWidgetStore } from "@state/hooks/live-widgets";
import {
	BrWidgetOtherConfig,
	LsOtherConfig,
	WidgetLocation,
} from "@features/live-widgets/shared";
import { ProviderNameEnum } from "@gp/models";

export default observer(function WidgetToggleButton(props: {
	/** Will widget button control side widget */
	isForSideWidget?: boolean;
	/** Is button on event or on event row */
	isOfferButton?: boolean;
	/** @deprecated */
	isOnHomepage?: boolean;
}) {
	const location: WidgetLocation = props.isForSideWidget
		? "SIDE"
		: "EVENT_OFFER";

	const widgetStore = useLiveWidgetStore();
	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);
	const store = useContext(OfferStoreContext);

	const teamOneName =
		store.lookupsStore.teams.get(event.teamOneId || "")?.name || "";
	const teamTwoName =
		store.lookupsStore.teams.get(event.teamTwoId || "")?.name || "";

	const matchId = event.source?.matchId ? event.source.matchId : null;

	const isActive = widgetStore.findActiveLmtWidget(event.id, location);

	const [isHover, setIsHover] = useState(false);

	//#region css classes

	// class order matters here

	const isDisabled = useMemo(() => {
		if (!matchId) {
			return true;
		}
		const lsSupportedSports = LsOtherConfig.supportedSports;
		const brSupportedSports = BrWidgetOtherConfig.supportSports;

		if (event.isLive || matchId !== null) {
			if (event.source?.providerName == ProviderNameEnum.LSports) {
				return (
					!WEBPACK_IS_LS_WIDGET_ENABLED ||
					!lsSupportedSports.includes(sport.abrv)
				);
			} else {
				return !brSupportedSports.includes(sport.abrv);
			}
		}
		return false;
	}, []);

	const buttonClasses = classNames(
		"at-actionStatsWidget",
		{
			offer__actions__btn: props.isOfferButton,
			event__actions__btn: !props.isOfferButton,
		},
		"btn",
		{
			"btn--square--reset btn--ghost": props.isOfferButton,
			"btn--square btn--square--xtny btn--ghost": !props.isOfferButton,
			"is-disabled": isDisabled,
		}
	);

	const iconClasses = classNames(
		{
			offer__actions__icon: props.isOfferButton,
			event__actions__icon: !props.isOfferButton,
		},
		"u-icon",
		{
			"u-icon--med u-icon--med--responsive": props.isOfferButton,
			"u-icon--med": !props.isOfferButton,
			[`u-icon--field--active u-icon--field--${sport.abrv}--active`]:
				!isHover && !isActive && !isDisabled,
			[`u-icon--field--selected u-icon--field--${sport.abrv}--selected`]:
				(isHover || isActive) && !isDisabled,
			[`u-icon--field--disabled u-icon--field--${sport.abrv}--disabled`]:
				isDisabled,
		}
	);

	return (
		<button
			disabled={isDisabled}
			className={buttonClasses}
			type="button"
			onClick={() => {
				if (matchId == null) {
					return null;
				}
				widgetStore.toggleLmtWidget(
					sport.abrv,
					event.source!.providerName,
					matchId,
					event.id,
					teamOneName,
					teamTwoName,
					location,
					// @ts-expect-error
					store.isMultiEvent
				);
			}}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<i className={iconClasses} />
		</button>
	);
});
