import { observable, computed, action, runInAction } from "mobx";
import { merge } from "lodash";
import { Subscription } from "rxjs";

import { EventType, LiveStatus, PageableOfferResponse } from "@gp/models";
import { PrematchOfferStore } from "@gp/offer";

import { ConsoleLogger } from "@gp/utility";
import { TOfferRequest, ISubscriptionRequest } from "@gp/hub";

import { parsePageNumberToValid } from "@utils";
import betRules from "@betting-rules";
import { PrematchOfferApiService } from "@api-services/offer/PrematchOfferApiService";

import { BettingTypeSelectorsStore } from "../components/BettingTypeSelectorsStore";
import { LoaderStore } from "@state/stores/common";
import { logger } from "@state";
import RootOfferStore from "@offer/stores/RootStore";

const DefaultConfig = {
	columns: [],
};

export default class SearchViewStore extends PrematchOfferStore {
	config = DefaultConfig;
	loader: LoaderStore;
	rootStore: RootOfferStore;

	//#region observables

	@observable public isFetchingData = false;
	@observable isInitialize = true;
	private subscription: Subscription | null;

	//#endregion observables

	//#region computed

	@computed get isLoading() {
		return this.loader.isLoading;
	}

	@computed get isEmpty() {
		/** Override is empty */
		return this.eventsMap.size === 0 && !this.isInitialize;
	}

	@computed get searchElementsCount() {
		return this.eventsMap.size;
	}

	//#endregion computed

	//#region  constructor

	constructor(
		rootStore: RootOfferStore,
		config = undefined,
		bettingTypeSelectorsStore?: BettingTypeSelectorsStore
	) {
		super({
			pageSize: 1000,
			logger: new ConsoleLogger(false),
			removeDelay: 10,
			throttle: 4,
			enableThrottling: true,
			bettingTypeSelectorsStore:
				bettingTypeSelectorsStore || new BettingTypeSelectorsStore(),
			customConfiguration: betRules,
		});

		if (config != null) {
			this.config = merge({}, DefaultConfig, config);
		}

		this.rootStore = rootStore;
		this.loader = new LoaderStore();

		this.pageNumber = 1;
		this.pageSize = 1000;
	}

	//#endregion constructor

	//#region data fetching

	@action.bound
	onInitialize(pageNumber: number) {
		this.fetchData(pageNumber);
	}

	@action.bound
	async fetchData(pageNumber: number) {
		this.loader.suspend();
		this.isFetchingData = true;

		this.pageNumber = parsePageNumberToValid(pageNumber);
		App.state.updateUrlParams({ page: this.pageNumber }, true);

		this.disposeSubscription();

		let offerResponse: PageableOfferResponse | null = null;
		try {
			offerResponse = await PrematchOfferApiService.GetOffer(
				this.getOfferRequest()
			);
		} catch (err) {
			logger.logError(err);
			this.onDoneFetching();
			return;
		}

		runInAction(() => {
			if (offerResponse != null) {
				this.handleOfferAssign(offerResponse);
			}

			if (this.lookupsStore.tournaments.size > 0) {
				// If we are creating live subscription then live subscription will call this.onDoneFetching
				this.createLiveSubscription();
			} else {
				this.onDoneFetching();
			}
		});
	}

	@action.bound
	handleOfferAssign(offer: PageableOfferResponse) {
		this.assignOfferData(offer);
	}

	@action.bound
	createLiveSubscription() {
		// add live subscription
		this.subscription = this.rootStore.hub
			.getOfferSubscription(this.getLiveSubscriptionRequest())
			.subscribe(
				(response) => {
					runInAction(() => {
						this.assignLiveData(response, true);
						this.onDoneFetching();
					});
				},
				(err) => {
					console.error(err);
					this.onDoneFetching();
				}
			);
	}

	@action.bound
	onDoneFetching() {
		this.loader.resume();
		this.isInitialize = false;
		this.isFetchingData = false;
	}

	//#endregion data fetching

	//#region filter

	/** Pageable request. */
	getOfferRequest(): TOfferRequest {
		return {
			paging: {
				pageNumber: this.pageNumber,
				pageSize: this.pageSize,
			},
			filter: this.getOfferFilter(),
			grouping: "tournamentGroup",
		};
	}

	getOfferFilter(): TOfferRequest["filter"] {
		const searchFilter =
			this.rootStore.searchBarViewStore.searchFilterArray;

		let filters: TOfferRequest["filter"] = [
			...this.getFilterForTeam(0), // Team one
			...this.getFilterForTeam(1), // Team two

			{
				...searchFilter[2], // Event name
				liveStatus: LiveStatus.PREMATCH,
				eventType: {
					eq: [EventType.SHORT_TERM, EventType.LONG_TERM],
				},
			},
		];

		return filters;
	}

	getFilterForTeam(teamIdx: number) {
		const prematchBettingTypes = this.OfferMapper.getBettingTypes(
			"prematch",
			betRules
		);
		const liveBettingTypes = this.OfferMapper.getBettingTypes(
			"live",
			betRules
		);

		const searchFilter =
			this.rootStore.searchBarViewStore.searchFilterArray;

		return [
			/** Normal offer filter for one team. */
			{
				...searchFilter[teamIdx], // Team name filter
				liveStatus: LiveStatus.PREMATCH,
				eventType: EventType.NORMAL,
				offers: [
					{
						bettingType: {
							abrv: {
								eq: prematchBettingTypes.normal,
							},
						},
					},
					{
						bettingType: {
							abrv: {
								eq: prematchBettingTypes.marginal,
							},
						},
						isFavorite: true,
					},
				],
			},
			/** Live offer filter for one team. */
			{
				...searchFilter[teamIdx], // Team name filter
				liveStatus: LiveStatus.LIVE,
				eventType: EventType.NORMAL,
				offers: [
					{
						bettingType: {
							abrv: {
								eq: liveBettingTypes.normal,
							},
						},
					},
					{
						bettingType: {
							abrv: {
								eq: liveBettingTypes.marginal,
							},
						},
						isFavorite: true,
					},
				],
			},
		];
	}

	/** Live request. */
	getLiveSubscriptionRequest(): ISubscriptionRequest {
		const liveBettingTypes = this.OfferMapper.getBettingTypes(
			"live",
			betRules
		);
		const searchFilter =
			this.rootStore.searchBarViewStore.searchFilterArray;

		return {
			subscriptionId:
				"search-" + this.rootStore.searchBarViewStore.searchTerm,
			compress: WEBPACK_OFFER_COMPRESS,
			channels: [
				{
					name: "event",
					filter: searchFilter,
				},
				{
					name: "betOffer",
					filter: [
						{
							bettingType: {
								abrv: {
									eq: liveBettingTypes.normal,
								},
							},
						},
						{
							bettingType: {
								abrv: {
									eq: liveBettingTypes.marginal,
								},
							},
							isFavorite: true,
						},
					],
				},
			],
		};
	}

	//#endregion filter

	//#region disposers

	@action.bound
	onDispose() {
		this.disposeSubscription();
		this.onReset();
	}

	@action.bound
	disposeSubscription() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	@action.bound
	onReset() {
		this.loader.suspend();
		this.isInitialize = true;

		/* this.configuration.bettingTypeSelectorsStore.reset(); */

		this.reset();
	}

	//#endregion disposers
}
