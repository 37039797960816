import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import Button from "@components/buttons/Button";
import { formatDateTime } from "@v2/helpers";
import { LimitRequest } from "@data-types";
import { getDisplayedAmount } from "@utils";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import {
	LimitRequestAction,
	MyLimitsStoreCategoriesNullChecked,
} from "@v2/state/myLimits/MyLimitsStore";
import { observer } from "mobx-react";

const MyLimitsRequest = observer(function MyLimitsRequest(props: LimitRequest) {
	const {
		requestType,
		limitType,
		currentAmount,
		requestedAmount,
		requestDate,
		expirationDate,
	} = props;

	const { t } = useTranslation();
	const myLimitsStore = useContext(
		MyLimitsStoreContext
	) as MyLimitsStoreCategoriesNullChecked;

	async function submitForm(action: number) {
		const model = {
			action: action,
			categoryId: props.categoryId,
			userLimitId: props.limitId,
			userLimitRequestId: props.requestId,
		};
		await myLimitsStore.submitRequestLimit(model);
	}

	const canApply = DateTime.fromISO(expirationDate) < DateTime.now();

	return (
		<form className="card--primary card--primary--border-reset col">
			<div className="card--primary__body card--primary__inner">
				<ul className="card--primary__inner__grow list--secondary u-mar--bottom--xsml u-type--xsml">
					<li className="list--secondary__item">
						{t("USER.ACCOUNT_SETTINGS.MY_LIMITS.REQUEST_TYPE")}:{" "}
						{t(requestType)}
					</li>
					<li className="list--secondary__item">
						{t("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_TYPE")}:{" "}
						<b>{limitType}</b>
					</li>
					{requestType !== "Deactivation" ? (
						<>
							<li className="list--secondary__item">
								{t(
									"USER.ACCOUNT_SETTINGS.MY_LIMITS.CURRENT_AMOUNT"
								)}{" "}
								:{" "}
								{getDisplayedAmount(
									currentAmount,
									myLimitsStore.category.currency,
									0
								)}
							</li>
							<li className="list--secondary__item">
								{t(
									"USER.ACCOUNT_SETTINGS.MY_LIMITS.REQUESTED_AMOUNT"
								)}{" "}
								:{" "}
								{getDisplayedAmount(
									requestedAmount,
									myLimitsStore.category.currency,
									0
								)}
							</li>
						</>
					) : null}
					<li className="list--secondary__item">
						{t("USER.ACCOUNT_SETTINGS.MY_LIMITS.REQUEST_DATE")}:{" "}
						{formatDateTime(requestDate)}
					</li>
					<li className="list--secondary__item">
						{t("USER.ACCOUNT_SETTINGS.MY_LIMITS.APPLICABLE_AFTER")}:{" "}
						{formatDateTime(expirationDate)}
					</li>
				</ul>
				<div className="btn-group u-mar--top--xsml">
					<Button
						className="btn btn--sml btn--primary"
						btnType="button"
						isDisabled={myLimitsStore.isRequestPending || !canApply}
						title={`${
							!canApply
								? t(
										"USER.ACCOUNT_SETTINGS.MY_LIMITS.DESC_FOURTH"
								  )
								: ""
						}`}
						onClick={() => submitForm(LimitRequestAction.APPLY)}
						btnText="COMMON.APPLY_BUTTON"
					/>
					<Button
						isDisabled={myLimitsStore.isRequestPending}
						className="btn btn--sml btn--secondary--inverted"
						btnType="button"
						onClick={() => submitForm(LimitRequestAction.REMOVE)}
						btnText="COMMON.CANCEL"
					/>
				</div>
			</div>
		</form>
	);
});

export default MyLimitsRequest;
