import React, { useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import OfferIcon from "@v2/components/shared/icons/Offer";
import { EventOffer } from "@gp/offer";
import {
	EventContext,
	OfferStoreContext,
	SportOfferContext,
} from "@gp/components";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import { TSideWidgetView } from "./TSideWidgetView";
import LmtLsEventStore from "./LmtLsEventStore";
import TeamName from "../../offer/details/TeamName";
import WidgetToggleButton from "../../offer/buttons/WidgetToggleButton";
import LiveStreamToggleButton from "../../offer/buttons/LiveStreamToggleButton";
import {
	LiveWidgetContainer,
	LiveStreamConfigWeb,
	LMTWidgetElement,
} from "@features/live-widgets/shared";
import { ProviderNameEnum } from "@gp/models";

export default observer(function SideWidgetBody(props: {
	view: TSideWidgetView;
	setView: (newView: TSideWidgetView) => void;
	lmtLsEventStore: LmtLsEventStore;
}) {
	const store = useLiveWidgetStore();
	const t = useTranslation().t;

	if (store.isSideWidgetCollapsed) {
		return null;
	}

	if (store.activeSideWidget == null && props.view !== "EVENT_LIST") {
		props.setView("EVENT_LIST");
	}

	switch (props.view) {
		case "LIVE_MATCH_TRACKER":
		case "LIVE_STREAM":
			return (
				<LiveWidgetContainer
					eventId={store.activeSideWidget?.eventId || ""}
					location="SIDE"
					lmtProps={{
						divProps: {
							className:
								"widget--stream widget--stream--borderless",
						},
					}}
					lsProps={{
						divProps: {
							className:
								"widget--stream widget--stream--borderless is-live",
						},
						config: LiveStreamConfigWeb,
					}}
				/>
			);
		case "EVENT_LIST":
		default:
			return <EventSelectorView lmtLsStore={props.lmtLsEventStore} />;
	}
});

type TEventListTabs = "LMT" | "LS";

const EventSelectorView = observer(function EventSelectorView(props: {
	lmtLsStore: LmtLsEventStore;
}) {
	const t = useTranslation().t;
	const [tab, setTab] = useState<TEventListTabs>("LMT");

	return (
		<OfferStoreContext.Provider value={props.lmtLsStore}>
			<div className="widget__list__tab">
				<button
					onClick={() => setTab("LMT")}
					className={classNames(
						"tabs--base__item tabs--primary__item",
						{
							"is-active": tab === "LMT",
							"is-disabled":
								props.lmtLsStore.lmtEvents.length <= 0,
						}
					)}
					disabled={props.lmtLsStore.lmtEvents.length <= 0}
				>
					{t("SIDE_WIDGET.LMT_TAB")}
				</button>
				<button
					onClick={() => setTab("LS")}
					className={classNames(
						"tabs--base__item tabs--primary__item",
						{
							"is-active": tab === "LS",
							"is-disabled":
								props.lmtLsStore.lsEvents.length <= 0,
						}
					)}
					disabled={props.lmtLsStore.lsEvents.length <= 0}
				>
					{t("SIDE_WIDGET.LS_TAB")}
				</button>
			</div>
			<EventList lmtLsStore={props.lmtLsStore} tab={tab} />
		</OfferStoreContext.Provider>
	);
});

const EventList = observer(function EventList(props: {
	lmtLsStore: LmtLsEventStore;
	tab: TEventListTabs;
}) {
	let events: EventOffer[] = [];
	if (props.tab === "LMT") {
		events = props.lmtLsStore.lmtEvents;
	} else if (props.tab === "LS") {
		events = props.lmtLsStore.lsEvents;
	}

	return (
		<div className="widget__list">
			{events.map((e) => (
				<EventRow
					key={e.id}
					lmtLsStore={props.lmtLsStore}
					event={e as EventOffer}
					tab={props.tab}
				/>
			))}
		</div>
	);
});

const EventRow = observer(function EventRow(props: {
	lmtLsStore: LmtLsEventStore;
	event: EventOffer;
	tab: TEventListTabs;
}) {
	const sport = props.lmtLsStore.lookupsStore.sports.get(props.event.sportId);

	const teamA = props.lmtLsStore.lookupsStore.teams.get(
		props.event.teamOneId || ""
	);
	const teamB = props.lmtLsStore.lookupsStore.teams.get(
		props.event.teamTwoId || ""
	);

	if (teamA == null || teamB == null) {
		return null;
	}

	return (
		//@ts-expect-error
		<SportOfferContext.Provider value={sport}>
			<EventContext.Provider value={props.event}>
				<div className="widget__list__item">
					<div className="u-display--f u-gap--micro">
						<OfferIcon
							type="sport"
							sport={sport?.abrv || ""}
							className="event__icon event__icon--sport u-icon u-icon--med u-icon--sport"
						/>
						<div className="widget__list__match">
							<TeamName team={teamA} />
							<p>-</p>
							<TeamName team={teamB} />
						</div>
					</div>
					{props.tab === "LMT" && (
						<WidgetToggleButton isForSideWidget isOfferButton />
					)}
					{props.tab === "LS" && (
						<LiveStreamToggleButton
							isForSideWidget
							isOnlyOpen
							isOfferButton
						/>
					)}
				</div>
			</EventContext.Provider>
		</SportOfferContext.Provider>
	);
});
