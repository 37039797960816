import React, { useMemo, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import {
	OfferStoreContext,
	OfferOptionsContext,
	LookupContext,
} from "@gp/components";

import LiveSports from "../../views/LiveVirtualizedOffer";
import LiveEmptyOfferMessage from "../../views/live-virtualized-offer-components/LiveEmptyOfferMessage";
import { AdditionalOffer } from "../../additional-offer";
import { ErrorPage } from "../../../shared/error";

import LiveViewStore from "../../../../../state/offer/pages/LiveViewStore";
import { useRootOfferStore } from "@hooks";
import { setTabTitle } from "@utils";
import {
	additionalOfferToggle,
	useNumberOfAvailableColums,
} from "@offer/common";

import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";
import { LiveStatus } from "@gp/models";
import RenderSkeletonLoader from "../../views/live-virtualized-offer-components/LiveSkeletonLoader";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";

export default observer(function LivePage(props: {
	liveOfferMenuStore: LiveOfferMenuStore;
}) {
	const t = useTranslation().t;
	const rootStore = useRootOfferStore();

	const liveViewStore = useMemo(
		() => new LiveViewStore(rootStore, props.liveOfferMenuStore),
		[]
	);
	const numberOfColumns = useNumberOfAvailableColums(
		rootStore.resizeStore.availableColumns
	);
	liveViewStore.setNumberOfColumns(numberOfColumns);

	useEffect(() => {
		setTabTitle(t("HOME.LIVE_BETTING"));
		document.querySelector("html, body")?.scrollTo(0, 0);
		//call this method to trigger check for popups and update popup timeouts
		App.state.rootStore.initClick();

		liveViewStore.onInitialize();
		return liveViewStore.onDispose;
	}, []);

	const options = {
		numberOfColumns,
		scoreChangeDuration: 3500,
		enableInlineAdditionalOffer: false,
		isLive: liveViewStore.isLive,
		additionalOfferToggle,
		bettingTypeSelectorsStore:
			liveViewStore.configuration.bettingTypeSelectorsStore,
	};

	return (
		// TODO: review this, might not be good to kill entire store!
		// eslint-disable-next-line react/jsx-handler-names
		<ErrorPage onError={liveViewStore.onDispose}>
			<OfferStoreContext.Provider value={liveViewStore}>
				<LookupContext.Provider value={liveViewStore.lookupsStore}>
					<OfferOptionsContext.Provider value={options}>
						<TemplateCore store={liveViewStore} />
					</OfferOptionsContext.Provider>
				</LookupContext.Provider>
			</OfferStoreContext.Provider>
			<AdditionalOffer />

			<Tooltip
				id="bt-description"
				className="at-tooltipBettingType react-tooltip--primary"
				place="bottom"
				closeOnResize={true}
			/>
		</ErrorPage>
	);
});

const TemplateCore = observer(function TemplateCore(props: {
	store: Parameters<typeof LiveSports>[0]["store"];
}) {
	const t = useTranslation().t;

	useEffect(() => {
		//on mount scroll to top
		window.scrollTo(0, 0);
	}, []);

	if (props.store.isLoading) {
		return <RenderSkeletonLoader />;
	}

	if (props.store.isEmpty) {
		return <LiveEmptyOfferMessage period={props.store.period} />;
	}

	return (
		<>
			<div className="l--content__main u-padd--top--reset">
				<h1 className="title--primary u-display--none">
					{t<string>("COMMON.LIVE_BETTING")}
				</h1>
				<LiveSports store={props.store} />
				<RemoveAllFavoritesButton />
			</div>
		</>
	);
});

const RemoveAllFavoritesButton = observer(function RemoveAllFavoritesButton() {
	const favoritesStore = useContext(FavoritesStoreContext);
	const offerStore = useContext(OfferStoreContext);
	const { t } = useTranslation();

	if (
		//@ts-expect-error
		offerStore.period != "my-favorites" ||
		favoritesStore.liveFavoritesCounter == 0
	) {
		return null;
	}
	return (
		<div className="pagination">
			<div className="pagination__remove">
				<button
					className="btn btn--sml btn--negative btn--icon u-type--xsml u-type--color--anchor"
					type="button"
					onClick={() =>
						favoritesStore.deselectFavoritesEvents(LiveStatus.LIVE)
					}
				>
					{t("FAVORITES.REMOVE_ALL_FAVORITES")}
				</button>
			</div>
		</div>
	);
});
