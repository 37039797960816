import React from "react";
import { useTranslation } from "react-i18next";
import { AccountStatementBetContext } from "../../../../context/AccountStatementBetContext";
import { useContext } from "react";
import { getDisplayedAmount } from "@utils";
import classNames from "classnames";

export default function AccountStatementBettingTable() {
	const { t } = useTranslation();

	const contextValue = useContext(AccountStatementBetContext);

	if (contextValue === null) {
		return null;
	}

	const { bettingAndPaymentTableData } = contextValue;

	if (
		!bettingAndPaymentTableData ||
		!bettingAndPaymentTableData.bettingActivity
	) {
		return null;
	}

	const { NumberOfBets, Payment, Gain, NetGain, Loss, CurrencyDisplayCode } =
		bettingAndPaymentTableData.bettingActivity;

	const paymentClass = classNames(
		"table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium",
		{
			"u-color--ui--danger": Payment < 0,
		}
	);

	const gainClass = classNames(
		"table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium",
		{
			"u-color--ui--success": Gain > 0,
		}
	);

	const netGainClass = classNames(
		"table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium",
		{
			"u-color--ui--success": NetGain > 0,
			"u-color--ui--danger": NetGain < 0,
		}
	);

	const lossClass = classNames(
		"table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium",
		{
			"u-color--ui--danger": Loss < 0,
		}
	);

	return (
		<>
			<thead className="table__head table__head--accent">
				<tr className="table__head__row table__row">
					<th
						className="table__head__cell table__cell table__cell--sml u-type--center"
						colSpan={5}
					>
						{t<string>("ACC_STATEMENT.TABLES.TABLE_BETTING")}
					</th>
				</tr>
			</thead>
			<tbody className="table__body">
				<tr className="table__body__row table__row">
					<td className="table_body__cell table__cell table__cell--sml u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_BETS_NMBR")}
					</td>
					<td className="table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_STAKE")}
					</td>
					<td className="table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_GAIN")}
					</td>
					<td className="table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_NETGAIN")}
					</td>
					<td className="table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_LOSS")}
					</td>
				</tr>
				<tr className="table__body__tr table__row">
					<td className="table_body__cell table__cell table__cell--sml u-color--text--primary">
						{NumberOfBets}
					</td>
					<td className={paymentClass}>
						{getDisplayedAmount(Payment, CurrencyDisplayCode)}
					</td>
					<td className={gainClass}>
						{getDisplayedAmount(Gain, CurrencyDisplayCode)}
					</td>
					<td className={netGainClass}>
						{getDisplayedAmount(NetGain, CurrencyDisplayCode)}
					</td>
					<td className={lossClass}>
						{getDisplayedAmount(Loss, CurrencyDisplayCode)}
					</td>
				</tr>
			</tbody>
		</>
	);
}
