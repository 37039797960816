import { useAccountActivationEmailSection } from "@v2/hooks/membership";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputField } from "../../shared/inputs";
import { ActivationResponse } from "@data-types";
import { useValueAvailabilityDebounce } from "@v2/hooks";
import { get } from "lodash";
import { useFieldValidation } from "@v2/helpers/shared";
import { EmailPattern } from "@common/constants";

export default function AccountActivationEmailSectionTemplate(
	props: PropTypes
) {
	const { t } = useTranslation();
	const { accountActivationData } = props;
	const {
		formState: { errors },
		clearErrors,
		getValues,
	} = useFormContext();

	const { checkForEmailAvailiability } = useAccountActivationEmailSection();

	const debouncer = useValueAvailabilityDebounce();

	useFieldValidation("email", "confirmEmail");

	const isEmailRequired =
		accountActivationData?.companyInfo.isOfflineUserEmailChangeMandatory;

	if (accountActivationData == null) {
		return null;
	}

	return (
		<>
			{!isEmailRequired == true && (
				<div className="message--base message--muted u-mar--bottom--xsml">
					{t<string>("MEMBERSHIP.ACCOUNT_ACTIVATION.EMAIL_HEADER")}
				</div>
			)}
			<div className="row u-mar--bottom--xsml">
				<FormInputField
					name="email"
					className="col col-sml-12 form__field"
					label="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_EMAIL_LABEL"
					placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_EMAIL_PLACEHOLDER"
					pattern={{
						value: EmailPattern,
						message:
							"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.INVALID_EMAIL",
					}}
					validate={{
						isAvailable: async (value: string) => {
							if (!getValues("email") && !isEmailRequired) {
								return true;
							}

							if (get(errors, "email")) {
								clearErrors("email");
							}
							const isAvailable = await debouncer(
								value,
								checkForEmailAvailiability
							);

							return (
								isAvailable ||
								"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_EXISTS"
							);
						},
					}}
					required={isEmailRequired}
				/>
			</div>

			<div className="row u-mar--bottom--big">
				<FormInputField
					name="confirmEmail"
					className="col col-sml-12 form__field"
					label="MEMBERSHIP.ACCOUNT_ACTIVATION.REPEAT_NEW_EMAIL"
					placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.REPEAT_NEW_EMAIL_PLACEHOLDER"
					pattern={{
						value: EmailPattern,
						message:
							"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.INVALID_EMAIL",
					}}
					validate={{
						isNotEqual: (value: string) =>
							value === getValues("email") ||
							"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.DOES_NOT_MATCH_EMAIL",
					}}
					required={isEmailRequired}
				/>
			</div>
		</>
	);
}

type PropTypes = {
	accountActivationData: ActivationResponse | null;
};
