export default class SessionStorageProvider {
	sessionStorage: Storage;

	static init(key: string, initValue: any) {
		const item = sessionStorage.getItem(key);

		if (!item) {
			SessionStorageProvider.set(key, initValue);

			return initValue;
		}

		return JSON.parse(item);
	}

	static get(key: string): string | null {
		return sessionStorage.getItem(key);
	}

	static set(key: string, value?: null | string | ""): void {
		if (value == null || value == "") {
			sessionStorage.removeItem(key);
			return;
		}
		sessionStorage.setItem(key, value);
	}

	static remove(key: string): void {
		sessionStorage.removeItem(key);
	}

	static flush(): void {
		sessionStorage.clear();
	}
}
