import { observable, action, computed } from "mobx";
import { IStoreLifeCycle } from "@interface";
// in px
const LIMIT_1 = 1670;
const LIMIT_2 = 1515;
const LIMIT_3 = 1130;

/**
 * Resize is triggered in MainLayout.jsx
 * Resize is only applied to desktop versions of the application
 */
class ResizeStore implements IStoreLifeCycle {
	private resizeTimeoutId: ReturnType<typeof setTimeout> | undefined;
	@observable width = window.innerWidth;
	@observable height = window.innerHeight;
	@observable public isStoreInitialized = false;

	@computed get availableColumns() {
		if (this.width >= LIMIT_1) {
			return 4;
		} else if (this.width >= LIMIT_2) {
			return 3;
		} else if (this.width >= LIMIT_3) {
			return 1;
		}

		return 2;
	}

	@computed get visibleColumns() {
		return Array(this.availableColumns)
			.fill(undefined)
			.map((_, idx) => `${idx + 1}`);
	}

	@action.bound
	onInitialize() {
		this.handleResize();
		window.addEventListener("resize", this.handleResize);
		this.isStoreInitialized = true;
	}

	private handleResize = () => {
		clearTimeout(this.resizeTimeoutId);
		this.resizeTimeoutId = setTimeout(() => {
			this.updateWidthAndHeight(window.innerWidth, window.innerHeight);
		}, 50);
	};

	@action.bound
	private updateWidthAndHeight(width: number, height: number) {
		if (width !== this.width) {
			this.width = width;
		}
		if (height !== this.height) {
			this.height = height;
		}
	}

	@action.bound
	onDispose() {
		window.removeEventListener("resize", this.handleResize);
		this.isStoreInitialized = false;
	}
}

export default ResizeStore;
