import { AccountVerificationStatus } from "@api-types/user/LoginResponseDto";
import { useRootAppStore } from "@hooks";
import { UserTypes, getUserFromLocalStorage } from "@utils";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export function useAccountVerification() {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();

	if (rootStore == null) {
		throw Error("should be used in components");
	}

	if (!rootStore.userAuthStore.isInternetUser) {
		return null;
	}

	const onlineUser = getUserFromLocalStorage(
		rootStore.userAuthStore.isPrimaryUserOnline == true
			? UserTypes.PRIMARY
			: UserTypes.SECONDARY
	);

	if (
		onlineUser?.accountVerificationStatus ==
		AccountVerificationStatus.FullyVerified
	) {
		return null;
	}

	let message;
	let title;
	let link;
	let cssClass = "red";

	if (onlineUser?.gracePeriodExpirationDate != null) {
		const isExpired =
			DateTime.fromISO(onlineUser.gracePeriodExpirationDate) <
			DateTime.now();
		if (isExpired) {
			message = t("ACCOUNT_VERIFICATION.GRACE_PERIOD_EXPIRED");
		} else {
			message = t("ACCOUNT_VERIFICATION.GRACE_PERIOD_ACTIVE", {
				0: DateTime.fromISO(
					onlineUser.gracePeriodExpirationDate
				).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
			});
		}
		cssClass = "orange";
	} else if (onlineUser?.recheckDueDate) {
		const now = new Date();
		// @ts-expect-error
		const recheckDueDateUTC = new Date(user.recheckDueDate);
		// @ts-expect-error
		const recheckDiffTimeStamp = recheckDueDateUTC - now;
		const recheckDiffDays = Math.floor(
			recheckDiffTimeStamp / (24 * 60 * 60 * 1000)
		);

		if (recheckDiffTimeStamp < 0) {
			message = t("ACCOUNT_RECHECK.ANNUAL_RECHECK_REQUIRED");
			title = "ACCOUNT_RECHECK.TITLE";
			link = "user-profile-recheck";
		} else if (
			recheckDiffDays >= 0 &&
			recheckDiffDays <= WEBPACK_ACCOUNT_RECHECK_EXPIRING_INTERVAL
		) {
			title = "ACCOUNT_RECHECK.TITLE";
			message = t("ACCOUNT_RECHECK.ANNUAL_RECHECK_EXPIRING", {
				0: DateTime.fromISO(onlineUser.recheckDueDate).toLocaleString(
					DateTime.DATETIME_SHORT
				),
			});
			link = "user-profile-recheck";
		}
	} else {
		message = t("ACCOUNT_VERIFICATION.POPUP_MESSAGE");
	}

	return {
		message,
		title,
		link,
		cssClass,
	};
}
