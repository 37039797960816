import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";

export const SearchEmptyState = observer(function SearchEmptyState(props: {
	searchTerm: string;
}) {
	const { t } = useTranslation();

	return (
		<div className="l--content__main">
			<div className="container">
				<div className="card--empty">
					<div className="card--empty__content">
						<i className="card--empty__icon u-icon u-icon--huge u-icon--empty u-icon--empty--search" />
						<div className="card--empty__title">
							{t("SEARCH.EMPTY_STATE.TITLE")}
						</div>
						<div className="card--empty__message">
							{t("SEARCH.EMPTY_STATE.DESCRIPTION", {
								searchTerm: props.searchTerm,
							})}
						</div>
						<Link
							to={`/${getCurrentCulture()}/sports/full`}
							className="card--empty__btn btn btn--sml btn--secondary--inverted btn--icon btn--animate-forward"
						>
							<span>
								{t("SEARCH.EMPTY_STATE.GO_SPORTS_BETTING")}
							</span>
							<i className="u-icon u-icon--xsml u-icon--arrow--right" />
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
});
