import React from "react";
import { Button } from ".";
import { useFormContext } from "react-hook-form";

export default function (props) {
	const {
		formState: { dirtyFields },
	} = useFormContext();
	const isButtonDisabled = Object.keys(dirtyFields).length == 0;

	return <Button {...props} disabled={isButtonDisabled} />;
}
