import React from "react";
import { observer } from "mobx-react";

import { SportOfferContext } from "@gp/components";
import { SportOffer } from "@gp/offer";

import { SportHeader as LiveSportHeader } from "../../sport-header/SportHeader";
import { getSportOfferKey } from "@offer/common";
import { TFlatVirtualizedSportItem } from "@v2/state/offer/pages/TFlatVirtualizedSportItem";
import VirtualizedSportOffer from "./LiveVirtualizedOfferList";

export default observer(function SportsList(props: {
	isScrollRestoreWorking: boolean;
	store: Parameters<typeof SportItem>[0]["store"];
	listHeight: number;
}) {
	return (
		<>
			{props.store.virtualizedLiveSportsList.map((spList) => (
				<SportItem
					spList={spList}
					isScrollRestoreWorking={props.isScrollRestoreWorking}
					store={props.store}
					listHeight={props.listHeight}
				/>
			))}
		</>
	);
});

const SportItem = observer(function SportItem(props: {
	spList: TFlatVirtualizedSportItem[];
	isScrollRestoreWorking: boolean;
	store: {
		eventsInSports: {
			sports: SportOffer[];
		};
		virtualizedLiveSportsList: TFlatVirtualizedSportItem[][];
		collapsedSports: string[];
	} & Parameters<typeof VirtualizedSportOffer>[0]["store"];
	listHeight: number;
}) {
	const [wrapperDivRef, setWrapperDivRef] =
		React.useState<HTMLDivElement | null>(null);
	const sp = props.spList[0].sport;

	if (sp == null) {
		console.error("Expected sport, got null.");
		return null;
	}

	const isSpCollapsed = props.store.collapsedSports.includes(sp.id);

	return (
		<SportOfferContext.Provider value={sp} key={getSportOfferKey(sp)}>
			<div ref={setWrapperDivRef}>
				<LiveSportHeader
					visible={!isSpCollapsed}
					eventCount={props.spList[0].eventCount}
					isLive
					sport={sp}
					onShowHide={props.store.toggleSportCollapse}
				/>
				{!isSpCollapsed && (
					<VirtualizedSportOffer
						isScrollRestoreWorking={props.isScrollRestoreWorking}
						flatSport={props.spList}
						store={props.store}
						listHeight={props.listHeight}
						isSpCollapsed={isSpCollapsed}
						parentWrapperDiv={wrapperDivRef}
					/>
				)}
			</div>
		</SportOfferContext.Provider>
	);
});
