import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "../inputs";
import Button from "@components/buttons/Button";
import FormButton from "@components/buttons/FormButton";

export default function PasswordConfirmation(props) {
	const { data, onSuccess, onCancel } = props;

	const methods = useForm({ mode: "onChange" });
	const { handleSubmit, reset } = methods;

	const { t } = useTranslation();

	const onSubmitData = async (formData) => {
		await onSuccess(formData.password);
		reset();
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmitData)}>
				<div className="card--primary__body">
					<div className="message message--base message--muted u-mar--bottom--xsml">
						<div className="message__content">
							<div>
								{t(
									"COMMON.PASSWORD_CONFIRMATION.SELECTED_DATA"
								)}
							</div>
							<List data={data} />
						</div>
					</div>
					<PasswordInput
						className="form__field"
						label="COMMON.PASSWORD_CONFIRMATION.PASSWORD_TITLE"
						name="password"
						placeholder="COMMON.PASSWORD_CONFIRMATION.PASSWORD_PLACEHOLDER"
						errorMessage="COMMON.PASSWORD_CONFIRMATION.ENTER_PASSWORD_CONFIRMATION"
						required
					/>
				</div>
				<div className="card--primary__footer">
					<Button
						className="card--primary__footer__btn btn btn--lrg btn--secondary--inverted"
						onClick={onCancel}
						btnType="button"
						btnText="COMMON.CANCEL_BUTTON"
					/>

					<FormButton
						className="card--primary__footer__btn btn btn--lrg btn--primary"
						btnText="COMMON.SAVE_BUTTON"
					/>
				</div>
			</form>
		</FormProvider>
	);
}

function List(props) {
	const { data } = props;
	const { t } = useTranslation();

	return data.map((x, index) => (
		<p key={index}>
			{t(x.name)} : {x.value}
		</p>
	));
}
