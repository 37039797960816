import React, { useContext } from "react";

import { observer } from "mobx-react";

import { getSortedEventKeysBySpecifierValue } from "@gp/offer";
import { BettingType as GPBettingType, EventKey } from "@gp/models";
import { OfferStoreContext, EventContext } from "@gp/components";

import { BettingTypeRow } from "./";
import { Sorter, NBATeamNameComparer } from "@gp/utility";
import { FavoritesStoreContext } from "../../../../../context/FavoritesStoreContext";

export const BettingType = observer(function BettingType(props: {
	bettingType: GPBettingType;
	isFavorite?: boolean;
}) {
	const { bettingType, isFavorite } = props;
	const favoritesStore = useContext(FavoritesStoreContext);
	const event = useContext(EventContext);
	const {
		eventKeysMap,
		lookupsStore: { teams },
	} = useContext(OfferStoreContext);

	if (eventKeysMap == null || eventKeysMap.size === 0) {
		return null;
	}

	const eventKeys = eventKeysMap.get(event.id);

	if (eventKeys == null || eventKeys.size === 0) {
		return null;
	}
	let bettingTypeKeys: EventKey[] = Array.from(eventKeys.values()).filter(
		(key) => key.bettingType.abrv === bettingType.abrv
	);

	bettingTypeKeys = getSortedEventKeysBySpecifierValue(bettingTypeKeys);
	if (isFavorite && bettingTypeKeys[0]?.specifier) {
		bettingTypeKeys = bettingTypeKeys.filter((marginal) => {
			return favoritesStore.isUserFavoriteBettingType(
				marginal.bettingTypeId,
				marginal.specifier || null
			);
		});
	}

	const teamOneName = teams.get(event.teamOneId || "")?.name || "";
	// Knowing whose name will be on this commit makes me wonder if this is how Luke felt when he found out who is his father?
	if (
		teamOneName != null &&
		[
			"player-total-assists",
			"player-total-rebounds",
			"player-total-3-point-field-goals",
			"player-total-points",
		].includes(bettingType.abrv || "")
	) {
		bettingTypeKeys.sort(
			Sorter.sort((a, b) => NBATeamNameComparer(a, b, event, teams))
		);
	}

	return (
		<>
			{[
				"player-total-assists",
				"player-total-rebounds",
				"player-total-3-point-field-goals",
				"player-total-points",
			].includes(bettingType.abrv || "") && (
				<div className="widget__header widget__header--en">
					<div className="widget__header__title">
						{bettingType.simpleName}
					</div>
				</div>
			)}
			{bettingTypeKeys.map((key) => (
				<BettingTypeRow
					key={key.id}
					bettingType={bettingType}
					offerKey={key}
				/>
			))}
		</>
	);
});
