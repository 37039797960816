import React from "react";
import {
	ChatLoader,
	ChatMessagesWrapper,
	ChatErrorMessage,
} from "@features/chat/desktop";

export default function ChatContent() {
	return (
		<>
			<ChatLoader />
			{/* <ChatErrorMessage /> */}
			<div className="chat__box">
				<ChatMessagesWrapper />
			</div>
		</>
	);
}
