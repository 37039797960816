import RootStore from "@state/stores/RootStore";
import { StorageStateKeysEnum } from "@utils/storage";
import { observable, action } from "mobx";
import OnboardingRegisterStore from "./OnboardingRegisterStore";
import OnboardingNewsletterStore from "./OnboardingNewsletterStore";
import { isCapacitorPlatform } from "@utils/specific/capacitor";

type TRootStore = {
	localStorageProvider: RootStore["localStorageProvider"];
	userAuthStore: RootStore["userAuthStore"];
};

class OnboardingWelcomePopupStore {
	@observable isActive: boolean = false;
	@observable shouldDisplayOnboardingRegisterPopup: boolean = false;
	@observable shouldDisplayOnBoardingNewsletterPopup: boolean = false;

	rootStore: TRootStore;
	onboardingRegistrationStore: OnboardingRegisterStore;
	onboardingNewsletterStore: OnboardingNewsletterStore;

	constructor(rootStore: TRootStore) {
		this.rootStore = rootStore;
		this.onboardingRegistrationStore = new OnboardingRegisterStore(
			this.rootStore
		);
		this.onboardingNewsletterStore = new OnboardingNewsletterStore(
			this.rootStore
		);
	}

	@action.bound
	onInitialize() {
		const welcomePopupsDelay = WEBPACK_WELCOME_POPUP_DELAY;

		if (
			this.rootStore.localStorageProvider.get(
				StorageStateKeysEnum.ONBOARDING_REGISTER_POPUP
			) &&
			this.rootStore.localStorageProvider.get(
				StorageStateKeysEnum.ONBOARDING_NEWSLETTER_POPUP
			)
		) {
			return;
		}

		if (WEBPACK_WELCOME_POPUP_ENABLED) {
			setTimeout(() => {
				if (
					!this.rootStore.userAuthStore.isLoggedIn &&
					!isCapacitorPlatform()
				) {
					if (
						!JSON.parse(
							this.rootStore.localStorageProvider.get(
								StorageStateKeysEnum.ONBOARDING_REGISTER_POPUP
							) as string
						)
					) {
						this.shouldDisplayOnboardingRegisterPopup = true;
					}
					if (
						!JSON.parse(
							this.rootStore.localStorageProvider.get(
								StorageStateKeysEnum.ONBOARDING_NEWSLETTER_POPUP
							) as string
						) &&
						JSON.parse(
							this.rootStore.localStorageProvider.get(
								StorageStateKeysEnum.ONBOARDING_REGISTER_POPUP
							) as string
						)
					) {
						this.shouldDisplayOnBoardingNewsletterPopup = true;
					}
					this.triggerWelcomePopups();
				}
			}, welcomePopupsDelay);
		}
	}

	triggerWelcomePopups = () => {
		if (
			window.location.pathname.includes("home") ||
			window.location.pathname.includes("sports") ||
			window.location.pathname.includes("live")
		) {
			if (
				this.shouldDisplayOnBoardingNewsletterPopup &&
				!this.rootStore.userAuthStore.isLoggedIn
			) {
				this.onboardingNewsletterStore.showPopup();
				this.shouldDisplayOnBoardingNewsletterPopup = false;
			} else if (
				this.shouldDisplayOnboardingRegisterPopup &&
				!this.rootStore.userAuthStore.isLoggedIn
			) {
				this.onboardingRegistrationStore.showPopup();
				this.shouldDisplayOnboardingRegisterPopup = false;
			}
		}
	};

	@action.bound
	showPopup() {
		this.isActive = true;
	}

	@action.bound
	onCancel() {
		this.shouldDisplayOnboardingRegisterPopup = false;
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.ONBOARDING_REGISTER_POPUP,
			JSON.stringify(this.shouldDisplayOnboardingRegisterPopup)
		);
	}

	@action.bound
	onOnboardingRedirection() {
		this.onboardingRegistrationStore.onOnboardingRedirection();
	}
}

export default OnboardingWelcomePopupStore;
