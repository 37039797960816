import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { isNullOrWhitespace, setTabTitle } from "@utils";
import {
	FormProvider,
	useForm,
	useFormContext,
	useWatch,
} from "react-hook-form";
import { useLoading } from "@hooks";
import {
	FormInputField,
	PasswordInput,
	RequiredMark,
} from "@v2/components/desktop/shared/inputs";
import { Button } from "@v2/components/desktop/shared/buttons";

export default observer(function UserMatchingPage() {
	const methods = useForm();
	const { handleSubmit, resetField } = methods;
	const { setIsLoading } = useLoading();
	const [isManualMatchingStarted, setIsManualMatchingStarted] =
		useState(false);
	const { t } = useTranslation();
	const loginStore = App.state.rootStore.userAuthStore.loginStore;
	const {
		onUserMatchingConfirm,
		loginError,
		onUserMatchingCancel,
		userMatchingDataList,
	} = loginStore;

	const onSuccess = async (data: {
		username: string;
		password: string;
		usernameManual: string;
	}) => {
		setIsLoading(true);
		await onUserMatchingConfirm({
			username:
				data.usernameManual != null && data.usernameManual !== ""
					? data.usernameManual
					: data.username,
			password: data.password,
		});

		if (
			loginError != null &&
			typeof loginError.userData !== "string" &&
			loginError.userData?.activationToken == null
		) {
			resetField("password");
		}
		setIsLoading(false);
	};

	useEffect(() => {
		setTabTitle(t("USER_MATCHING.TITLE"));
	}, []);

	if (!loginStore.isUserMatchingInProgress) {
		return null;
	}

	const message =
		userMatchingDataList != null && userMatchingDataList.length !== 0
			? "USER_MATCHING.MANUAL_MATCH_DATA_AVAILABLE"
			: "USER_MATCHING.MANUAL_MATCH_NO_DATA";

	return (
		<>
			<div className="card--primary">
				<div className="card--primary__header">
					<h1 className="card--primary__title title--primary">
						{t<string>("USER_MATCHING.TITLE")}
					</h1>
				</div>

				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSuccess)}>
						<div className="card--primary__body">
							<div className="message message message--base message--note u-mar--bottom--big">
								<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
								<div className="message__content">
									<AutoMatchMessage
										userMatchingDataList={
											userMatchingDataList
										}
									/>
									<div className="message__text">
										{t<string>(message)}
									</div>
								</div>
							</div>

							<UserMatchingList
								userMatchingDataList={userMatchingDataList}
								isManualMatchingStarted={
									isManualMatchingStarted
								}
							/>

							{isManualMatchingStarted ? (
								<>
									<FormInputField
										id="loginUsername"
										name="usernameManual"
										className="form__group"
										label="MEMBERSHIP.LOGIN.EMAIL_OR_USERNAME_LABEL"
										placeholder="MEMBERSHIP.LOGIN.EMAIL_OR_USERNAME_PLACEHOLDER"
									/>

									<PasswordInput
										id="loginPassword"
										className="form__group"
										label="USER_MATCHING.PASSWORD_LABEL"
										name="password"
										placeholder="MEMBERSHIP.LOGIN.PASSWORD_PLACEHOLDER"
										validate={{
											isNullOrWhitespace: (
												value: string
											) =>
												isNullOrWhitespace(value) !==
													true ||
												"COMMON.REQUIRED_FIELD",
										}}
										required
									/>
								</>
							) : (
								<>
									{userMatchingDataList.length > 0 ? (
										<PasswordInput
											id="loginPassword"
											className="form__group"
											label="USER_MATCHING.PASSWORD_LABEL"
											name="password"
											placeholder="MEMBERSHIP.LOGIN.PASSWORD_PLACEHOLDER"
											validate={{
												isNullOrWhitespace: (
													value: string
												) =>
													isNullOrWhitespace(
														value
													) !== true ||
													"COMMON.REQUIRED_FIELD",
											}}
											required
										/>
									) : null}
									<Button
										btnType="button"
										atClassName="userMatchConfirm"
										className="card--primary__footer__btn btn--lrg btn--primary"
										btnText="USER_MATCHING.START_MANUAL"
										onClick={() =>
											setIsManualMatchingStarted(true)
										}
									/>
								</>
							)}
						</div>

						<div className="card--primary__footer">
							{(isManualMatchingStarted ||
								userMatchingDataList.length > 0) && (
								<Button
									btnType="submit"
									atClassName="userMatchConfirm"
									className="card--primary__footer__btn btn--lrg btn--primary"
									btnText="USER_MATCHING.CONFIRM"
								/>
							)}

							<Button
								btnType="button"
								atClassName="userMatchCancel"
								className="card--primary__footer__btn btn--lrg btn--secondary--inverted u-color--ui--danger"
								btnText="USER_MATCHING.DENY"
								onClick={onUserMatchingCancel}
							/>
						</div>
					</form>
				</FormProvider>
			</div>
		</>
	);
});

const AutoMatchMessage: FC<{
	userMatchingDataList: string[];
}> = observer(function AutoMatchMessage(props: {
	userMatchingDataList: string[];
}) {
	const { t } = useTranslation();

	if (
		props.userMatchingDataList != null &&
		props.userMatchingDataList.length === 0
	) {
		return null;
	}

	return (
		<div className="message__text u-mar--bottom--xsml">
			{t<string>("USER_MATCHING.AUTO_MATCH_MESSAGE")}
		</div>
	);
});

const UserMatchingList: FC<{
	userMatchingDataList: string[];
	isManualMatchingStarted: boolean;
}> = observer(function UserMatchingList(props: {
	userMatchingDataList: string[];
	isManualMatchingStarted: boolean;
}) {
	const { t } = useTranslation();
	const { register, resetField, getValues } = useFormContext();

	useEffect(() => {
		if (props.userMatchingDataList.length > 0) {
			resetField("username", {
				defaultValue: props.userMatchingDataList[0],
			});
			resetField("usernameManual", {
				defaultValue: "",
			});
		}
	}, []);

	useEffect(() => {
		if (getValues("usernameManual") != "") {
			resetField("username", {
				defaultValue: null,
			});
		} else if (props.userMatchingDataList.length > 0) {
			resetField("username", {
				defaultValue: props.userMatchingDataList[0],
			});
		}
	}, [useWatch({ name: "usernameManual" })]);

	useEffect(() => {
		if (
			getValues("username") != null &&
			props.userMatchingDataList.length > 0
		) {
			resetField("usernameManual", {
				defaultValue: "",
			});
		}
	}, [useWatch({ name: "username" })]);

	if (
		props.userMatchingDataList.length === 0 ||
		props.isManualMatchingStarted
	) {
		return null;
	}

	return (
		<div className="form__group">
			<div className="label">
				{t<string>("USER.ACCOUNT_SETTINGS.NAVIGATION.USER")}{" "}
				<RequiredMark required={true} />
			</div>
			{props.userMatchingDataList.map((userName) => {
				return (
					<div key={`matching-${userName}`}>
						<input
							id={`matching-${userName}`}
							className="input--radio"
							value={userName}
							type="radio"
							{...register("username")}
						/>
						<label htmlFor={`matching-${userName}`}>
							{userName}
						</label>
					</div>
				);
			})}
		</div>
	);
});
