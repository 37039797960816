import { DateTime } from "luxon";

export function fromToDates(period: string): Dates{
    const now = DateTime.now();
    const to = now.toJSDate().toISOString();
    let from = "";
    switch(period){
        case "last3months":
            from = now.minus({months: 3}).toJSDate().toISOString();
            break;
        case "last6months":
            from = now.minus({ months: 6 }).toJSDate().toISOString();
            break;
        case "last9months":
            from = now.minus({ months: 9 }).toJSDate().toISOString();
            break;
        case "last1year":
            from = now.minus({ year: 1 }).toJSDate().toISOString();
            break;
    }
    return {
        from : from,
        to: to
    }
}

type Dates = {
    from : string;
    to: string;
}