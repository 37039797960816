import { observable, action } from "mobx";

import { MainOfferStore } from "@gp/offer";
import bettingRules from "@betting-rules";
import RootOfferStore from "@offer/stores/RootStore";

export type Options = {
	type: string | "prematch";
	isLive: boolean;
	onClose?: Function;
} | null;

const DEFAULT_OPTIONS: Options = {
	type: "prematch",
	isLive: false,
	onClose: undefined,
};

export class GroupOfferStore extends MainOfferStore {
	rootStore: RootOfferStore;
	@observable displayBettingGroup = "all";
	type: any;
	isLive: boolean;
	protected onClose: Function | undefined;

	constructor(rootStore: RootOfferStore, options: Options = null) {
		options = Object.assign({}, DEFAULT_OPTIONS, options);
		// @ts-expect-error some of these options have default values
		super({
			customConfiguration: bettingRules,
		});

		this.rootStore = rootStore;
		this.type = options?.type || "prematch";
		this.isLive = options?.isLive || false;
		this.onClose = options?.onClose;
	}

	@action.bound
	setDisplayBettingGroup(value: string) {
		if (value !== this.displayBettingGroup) {
			this.displayBettingGroup = value;
		}
	}

	@action.bound
	resetDisplayBettingGroup() {
		this.displayBettingGroup = "all";
	}
}
