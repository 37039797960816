import React from "react";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import OfferIcon from "@v2/components/shared/icons/Offer";
import { Button } from "@components/buttons";
import { useTranslation } from "react-i18next";

export default observer(function ChannelInfoContainer() {
	const { chatViewStore } = useRootAppStore();
	const { t } = useTranslation();

	const channelInfo = chatViewStore.chatChannelHandler.iconProps;

	if (!chatViewStore.chatChannelHandler.activeChannelInfo) {
		//if no info of channel return empty div
		return <div></div>;
	}

	const { displayName, memberCount } =
		chatViewStore.chatChannelHandler.activeChannelInfo;

	return (
		<div className="chat__info">
			<Button
				className="btn--square btn--square--tny btn--secondary--inverted"
				iconOnly
				iconClassName="u-icon--sml u-icon--list u-color--text--primary"
				// btnText="Channel list"
				onClick={() => chatViewStore.toggleChatList(true)}
				// isLoading={chatViewStore.channelListLoader}
			/>

			<div className="chat__icon">
				{channelInfo?.sportAbrv &&
					!channelInfo?.categoryAbrv &&
					!channelInfo?.tournamentAbrv && (
						<OfferIcon
							type="sport"
							sport={channelInfo?.sportAbrv || ""}
							className="chat__icon__primary u-icon u-icon--lrg"
						/>
					)}

				{channelInfo?.sportAbrv &&
					channelInfo?.categoryAbrv &&
					!channelInfo?.tournamentAbrv && (
						<OfferIcon
							type="category"
							sport={channelInfo?.sportAbrv || ""}
							category={channelInfo?.categoryAbrv || ""}
							className="chat__icon__primary u-icon u-icon--lrg"
						/>
					)}

				{channelInfo?.categoryAbrv &&
					channelInfo?.tournamentAbrv &&
					channelInfo?.sportAbrv && (
						<OfferIcon
							type="tournament"
							sport={channelInfo?.sportAbrv || ""}
							category={channelInfo.categoryAbrv || ""}
							tournament={channelInfo?.tournamentAbrv || ""}
							className="chat__icon__primary u-icon u-icon--lrg"
							catOverSportClass="chat__icon__secondary u-icon u-icon--med"
						/>
					)}
			</div>

			<div className="chat__title">
				<div className="chat__name">{displayName}</div>
				{memberCount && (
					<span className="chat__subtitle">
						{t("CHAT.MEMBERS")}: {memberCount}
					</span>
				)}
			</div>
		</div>
	);
});
