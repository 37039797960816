import { useRootAppStore, useRootOfferStore } from "@hooks";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
	EventOfferGroups_BettingType,
	EventOfferGroups_EventName,
} from "../most-played-tickets/components";
import { BetOffer } from "@administration/pages/my-bets/model";
import { Button } from "@v2/components/desktop/shared/buttons";
import {
	CheckboxField,
	FormInputField,
	FormInputFieldCurrency,
} from "@v2/components/desktop/shared/inputs";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { observer } from "mobx-react";
import QuickBetStore, {
	TFormFilter,
} from "@v2/state/quick-play-store/QuickBetStore";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { getCurrentCulture } from "@utils";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { QuickBetActionsTypeEnum } from "@data-types/analytics/QuickPlayInteractionTypeEnum";
import QuickBetFilterBar from "./QuickBetFilterBar";

export default observer(function QuickPlayPage(props: { isWidget?: boolean }) {
	const rootStateStore = useRootAppStore();
	const rootOfferStore = useRootOfferStore();

	const mappedBetSlip = rootStateStore.quickPlayStore.quickBetStore.betSlip;
	const { t } = useTranslation();

	const methods = useForm<TFormFilter>({
		mode: "onChange",
		defaultValues: rootStateStore.quickPlayStore.quickBetStore
			.getOnloadFilter || {
			eventCount:
				rootStateStore.quickPlayStore.quickBetStore.EVENTS_COUNT,
			maxOddValue:
				rootStateStore.quickPlayStore.quickBetStore
					.MAX_COEFFICIENT_VALUE,
			minOddValue:
				rootStateStore.quickPlayStore.quickBetStore
					.MIN_COEFFICIENT_VALUE,
			stake:
				rootStateStore.userAuthStore.user?.stakeAmount?.toFixed(2) ||
				WEBPACK_DEFAULT_STAKE?.toFixed(2),
			useUserFavorites: false,
			quickBetType:
				rootStateStore.quickPlayStore.quickBetStore.QUICK_TYPE,
			includeMenu: false,
		},
	});

	const debounceRef = useRef<ReturnType<typeof setTimeout> | undefined>(
		undefined
	);
	const currentValueRef = useRef<string>(methods.getValues("stake"));
	useEffect(() => {
		if (rootStateStore.quickPlayStore.quickBetStore.onLoadFilter) {
			if (!props.isWidget) {
				methods.reset({
					...rootStateStore.quickPlayStore.quickBetStore.onLoadFilter,
					includeMenu: false,
				});
			} else {
				methods.reset(
					rootStateStore.quickPlayStore.quickBetStore.onLoadFilter
				);
			}
		}

		const fetchData = async () => {
			if (location.pathname.includes("/quick-play/quick-bet")) {
				await rootStateStore.quickPlayStore.quickBetStore.fetchMenuData();
			}
			if (!rootStateStore.quickPlayStore.quickBetStore.betSlip) {
				rootStateStore.quickPlayStore.quickBetStore.fetchData(
					methods.getValues()
				);
			}
		};

		fetchData();

		return () => {
			rootStateStore.quickPlayStore.quickBetStore.setSelectedSportId(
				"any"
			);
			rootStateStore.quickPlayStore.quickBetStore.saveFilter({
				...methods.getValues(),
				eventCount:
					methods.getValues("eventCount") > 0 &&
					methods.getValues("eventCount") < 11
						? methods.getValues("eventCount")
						: methods.formState.defaultValues?.eventCount ||
						  rootStateStore.quickPlayStore.quickBetStore
								.EVENTS_COUNT,
			});
		};
	}, []);
	const submitData = (e: TFormFilter) => {
		AnalyticsService.logQuickPlayInteraction({
			category: QuickBetActionsTypeEnum.TicketGen,
		});
		rootStateStore.quickPlayStore.quickBetStore.fetchData(e);
	};

	function onStakeChange(e: React.ChangeEvent<HTMLInputElement>) {
		{
			const value = parseFloat(e.target.value);
			methods.setValue("stake", e.target.value);

			if (!isNaN(value) && value > 0) {
				currentValueRef.current = e.target.value;

				methods.setValue("stake", e.target.value);

				if (value > 0) {
					currentValueRef.current = value.toFixed(2);

					clearTimeout(debounceRef.current);
				}
			}
		}
	}

	function onStakeBlur() {
		methods.setValue("stake", currentValueRef.current);
		if (debounceRef.current != null) {
			clearInterval(debounceRef.current);
			debounceRef.current = undefined;
		}
	}

	useEffect(() => {
		if (location.pathname.includes("live")) {
			if (location.pathname.includes("events")) {
				methods.setValue("includeMenu", false);
			}
			if (methods.getValues("quickBetType") !== "live") {
				methods.setValue("includeMenu", false);
			}
		} else if (
			!location.pathname.includes("live") &&
			methods.getValues("quickBetType") == "live"
		) {
			methods.setValue("includeMenu", false);
		}
	}, [methods.getValues("quickBetType")]);

	if (props.isWidget) {
		return (
			<div className="widget">
				<div className="card--primary__header u-padd--xsml">
					<div className="card--primary__title">
						<span className="tag tag--med u-type--case--uppercase u-mar--right--tny">
							{t("CASINO.NEW_GAME_LABEL")}
						</span>
						<span className="u-type--base u-type--wgt--medium">
							{t("QUICK_PLAY.TITLE")}
						</span>
					</div>
				</div>
				{mappedBetSlip && mappedBetSlip.betSlipOffers?.length > 0 && (
					<div className="slip__body__inner">
						{mappedBetSlip?.betSlipOffers.map(
							(props: BetOffer, index) => {
								return (
									<div
										className="slip__match"
										key={props.bettingOfferId}
									>
										<div className="slip__match__pair">
											<div className="slip__match__wrapper">
												<div className="slip__match__details">
													<EventOfferGroups_EventName
														offer={props}
													/>
												</div>
											</div>
										</div>
										<div className="slip__match__pick slip--quick__pick">
											<div className="slip__match__pick__row">
												<EventOfferGroups_BettingType
													offer={props}
													isWidget
													withCoefficient
												/>
												<div className="slip--quick__inner slip--quick__inner--tip">
													<Button
														type="button"
														onClick={() =>
															rootStateStore.quickPlayStore.quickBetStore.removeFromBetSlip(
																index
															)
														}
														className="slip__match__btn btn btn--square btn--square--tny btn--secondary--inverted"
														iconClassName="u-icon u-icon--sml u-icon--remove u-color--ui--danger"
														iconOnly
													/>
												</div>
											</div>
										</div>
									</div>
								);
							}
						)}
					</div>
				)}

				<div className="u-padd--xsml">
					<form onSubmit={methods.handleSubmit(submitData)}>
						<FormProvider {...methods}>
							<div className="u-mar--bottom--xsml">
								<Button
									btnText="QUICK_PLAY.QUICK_BET.CREATE_NEW_BET_SLIP"
									className="btn--wrap btn--wrap--base btn--block btn--secondary--inverted btn--animate-rotate"
									iconClassName="u-icon--sml u-icon--refresh"
									iconLeft
									disabled={
										Object.keys(methods.formState.errors)
											.length != 0 ||
										methods.formState.isSubmitting ||
										!methods.formState.isValid ||
										rootStateStore.quickPlayStore
											.loaderStore.isLoading ||
										rootStateStore.quickPlayStore
											.loaderStore.isLoadingProcess
									}
								/>
								<div
									className={`card--quick__inner card--quick__inner--${getCurrentCulture()}`}
								>
									{rootStateStore.quickPlayStore.quickBetStore.QUICK_BET_TYPES_ARRAY.map(
										(type) => (
											<QuickBetButtonType
												key={type.quickBetType}
												quickBetType={type.quickBetType}
												text={type.text}
												isWidget
											/>
										)
									)}
								</div>
							</div>
							<div className="card--quick">
								{/* <span className="card--quick__placeholder">Stake</span> */}

								<FormInputFieldCurrency
									label="SESSION.REALITY_CHECK.STAKE"
									name="stake"
									className="form__field card--quick__input"
									labelClassName="input__label"
									validate={{
										isNotNegative: (value: number) =>
											value > 0 ||
											t(
												"QUICK_PLAY.QUICK_BET.GREATER_THAN_0",
												{ 0: 0 }
											),
									}}
									maxLength={10}
									required
									onChange={onStakeChange}
									onBlur={onStakeBlur}
								/>

								<div className="card--quick__body">
									<div className="card--quick__row">
										<QuickBetOddsGroup
											text="BET_SLIP.MIN_ODDS"
											type="minOddValue"
										/>
									</div>

									<div className="card--quick__row">
										<QuickBetOddsGroup
											text="BET_SLIP.MAX_ODDS"
											type="maxOddValue"
										/>
									</div>
									<div className="card--quick__row">
										<FormInputField
											label="QUICK_PLAY.QUICK_BET.NUMBER_OF_EVENTS"
											name="eventCount"
											className="form__field card--quick__input"
											labelClassName="input__label"
											validate={{
												isNotNegative: (
													value: number
												) =>
													value > 0 ||
													t(
														"QUICK_PLAY.QUICK_BET.GREATER_THAN_0",
														{ 0: 0 }
													),
												lessThanOrEqualTo10: (
													value: number
												) =>
													Number(value) < 11 ||
													t(
														"QUICK_PLAY.QUICK_BET.LESS_THAN_OR_EQUAL_TO_NUMBER",
														{ 0: 10 }
													),
											}}
											required
										/>
									</div>
									<FavoritesCheckBox />
									<SportsMenuCheckBox
										isWidget={props.isWidget}
									/>
								</div>
							</div>
						</FormProvider>
					</form>
					<Button
						type="button"
						btnText={t("MY_BETS.SHARE.ADD_TO_SLIP")}
						className="btn--base btn--block btn--primary"
						disabled={
							!rootStateStore.quickPlayStore.quickBetStore.betSlip
						}
						onClick={() => {
							if (
								!rootStateStore.quickPlayStore.quickBetStore
									.betSlip
							) {
								console.error("Bet Slip should not be null");
								return;
							}
							AnalyticsService.logQuickPlayInteraction({
								category: QuickBetActionsTypeEnum.TicketUse,
							});
							rootOfferStore.betSlipStore.sharedSlipAndMap(
								{
									...rootStateStore.quickPlayStore
										.quickBetStore.betSlip,
								},
								parseFloat(methods.getValues("stake"))
							);
						}}
					/>
				</div>
			</div>
		);
	} else {
		return (
			<FormProvider {...methods}>
				<QuickBetFilterBar />
				{mappedBetSlip && mappedBetSlip.betSlipOffers.length > 0 && (
					<div className={`slip__body__inner`}>
						{mappedBetSlip?.betSlipOffers.map(
							(props: BetOffer, index) => {
								return (
									<div
										className="slip__match"
										key={props.bettingOfferId}
									>
										<div className="slip__match__pair">
											<div className="slip__match__wrapper">
												<div className="slip__match__details">
													<EventOfferGroups_EventName
														offer={props}
													/>
												</div>
											</div>
										</div>
										<div className="slip__match__pick slip--quick__pick">
											<div className="slip__match__pick__row">
												<EventOfferGroups_BettingType
													offer={props}
													/* bettingTypeClass={
																"slip__pick__col slip__pick__col--offer"
															} */
													withCoefficient
												/>
												<div className="slip--quick__inner slip--quick__inner--tip">
													<Button
														type="button"
														onClick={() =>
															rootStateStore.quickPlayStore.quickBetStore.removeFromBetSlip(
																index
															)
														}
														className="slip__match__btn btn btn--square btn--square--tny btn--secondary--inverted"
														iconClassName="u-icon u-icon--sml u-icon--remove u-color--ui--danger"
														iconOnly
													/>
												</div>
											</div>
										</div>
									</div>
								);
							}
						)}
					</div>
				)}

				<div className="u-padd--xsml">
					<form onSubmit={methods.handleSubmit(submitData)}>
						<div className="card--quick__wrapper">
							<Button
								btnText="QUICK_PLAY.QUICK_BET.CREATE_NEW_BET_SLIP"
								className="btn--block btn--secondary--inverted btn--animate-rotate "
								iconClassName="u-icon u-icon--sml u-icon--refresh"
								iconLeft
								disabled={
									Object.keys(methods.formState.errors)
										.length != 0 ||
									methods.formState.isSubmitting ||
									!methods.formState.isValid ||
									rootStateStore.quickPlayStore.loaderStore
										.isLoading
								}
							/>
							<div className="card--quick__actions">
								{rootStateStore.quickPlayStore.quickBetStore.QUICK_BET_TYPES_ARRAY.map(
									(type) => (
										<QuickBetButtonType
											key={type.quickBetType}
											quickBetType={type.quickBetType}
											text={type.text}
											isWidget={props.isWidget}
										/>
									)
								)}
							</div>
						</div>
						<div className="card--quick">
							{/* <span className="card--quick__placeholder">Stake</span> */}
							<FormInputFieldCurrency
								label="SESSION.REALITY_CHECK.STAKE"
								name="stake"
								className="form__field card--quick__input"
								labelClassName="input__label"
								validate={{
									isNotNegative: (value: number) =>
										value > 0 ||
										t(
											"QUICK_PLAY.QUICK_BET.GREATER_THAN_0",
											{ 0: 0 }
										),
								}}
								maxLength={10}
								required
								onChange={onStakeChange}
								onBlur={onStakeBlur}
							/>

							<div className="card--quick__body">
								<div className="card--quick__row">
									<QuickBetOddsGroup
										text="BET_SLIP.MIN_ODDS"
										type="minOddValue"
									/>
								</div>

								<div className="card--quick__row">
									<QuickBetOddsGroup
										text="BET_SLIP.MAX_ODDS"
										type="maxOddValue"
									/>
								</div>
								<div
									className={`card--quick__row ${
										!rootStateStore.userAuthStore.isLoggedIn
											? "borderless"
											: ""
									}`}
								>
									<FormInputField
										label="QUICK_PLAY.QUICK_BET.NUMBER_OF_EVENTS"
										name="eventCount"
										className="form__field card--quick__input"
										labelClassName="input__label"
										validate={{
											isNotNegative: (value: number) =>
												value > 0 ||
												t(
													"QUICK_PLAY.QUICK_BET.GREATER_THAN_0",
													{ 0: 0 }
												),
											lessThanOrEqualTo10: (
												value: number
											) =>
												value < 11 ||
												t(
													"QUICK_PLAY.QUICK_BET.LESS_THAN_OR_EQUAL_TO_NUMBER",
													{ 0: 10 }
												),
										}}
										required
									/>
								</div>
								<FavoritesCheckBox />
								<SportsMenuCheckBox isWidget={props.isWidget} />
							</div>
						</div>
					</form>
					<div>
						<Button
							type="button"
							btnText="MY_BETS.SHARE.ADD_TO_SLIP"
							className="btn--lrg btn--primary"
							disabled={
								!rootStateStore.quickPlayStore.quickBetStore
									.betSlip
							}
							onClick={() => {
								if (
									!rootStateStore.quickPlayStore.quickBetStore
										.betSlip
								) {
									console.error(
										"Bet Slip should not be null"
									);
									return;
								}
								AnalyticsService.logQuickPlayInteraction({
									category: QuickBetActionsTypeEnum.TicketUse,
								});
								rootOfferStore.betSlipStore.sharedSlipAndMap(
									{
										...rootStateStore.quickPlayStore
											.quickBetStore.betSlip,
									},
									parseFloat(methods.getValues("stake"))
								);
							}}
						/>
					</div>
				</div>
			</FormProvider>
		);
	}
});

function QuickBetButtonType(
	props: QuickBetStore["QUICK_BET_TYPES_ARRAY"][0] & { isWidget?: boolean }
) {
	const formContext = useFormContext<TFormFilter>();

	formContext.watch("quickBetType");

	const buttonClasses = classNames(
		"slip--quick__btn btn--block",
		{
			"btn--secondary--inverted":
				formContext.getValues().quickBetType != props.quickBetType,
			"btn--secondary is-disabled":
				formContext.getValues().quickBetType == props.quickBetType,
			"btn--wrap btn--wrap--base": props.isWidget,
		},
		"u-padd--xtny u-type--xsml"
	);

	return (
		<Button
			type="button"
			btnText={props.text}
			disabled={
				formContext.getValues().quickBetType == props.quickBetType
			}
			className={buttonClasses}
			onClick={() => {
				formContext.setValue("quickBetType", props.quickBetType);
			}}
		/>
	);
}

function QuickBetOddsGroup(props: {
	type: "maxOddValue" | "minOddValue";
	text: string;
}) {
	const { t } = useTranslation();

	const formContext = useFormContext<TFormFilter>();

	const values = formContext.getValues();
	const rootStateStore = useRootAppStore();

	const minOddValue = values["minOddValue"];
	const maxOddValue = values["maxOddValue"];

	const decreaseCheck =
		props.type == "minOddValue" &&
		values[props.type] -
			rootStateStore.quickPlayStore.quickBetStore
				.COEFFICIENT_CHANGE_VALUE <
			1.0;

	const minMaxCheck =
		props.type === "maxOddValue" &&
		values[props.type] -
			rootStateStore.quickPlayStore.quickBetStore
				.COEFFICIENT_CHANGE_VALUE <=
			minOddValue;

	const lessThanOrEqual100 =
		props.type === "maxOddValue" &&
		values[props.type] +
			rootStateStore.quickPlayStore.quickBetStore
				.COEFFICIENT_CHANGE_VALUE >=
			100;

	const isMinButtonDisabled =
		props.type === "minOddValue" &&
		values[props.type] +
			rootStateStore.quickPlayStore.quickBetStore
				.COEFFICIENT_CHANGE_VALUE >=
			maxOddValue;

	function changeOddValue(
		key: "maxOddValue" | "minOddValue",
		num: "increase" | "decrease"
	) {
		switch (num) {
			case "increase":
				formContext.setValue(
					key,
					values[key] +
						rootStateStore.quickPlayStore.quickBetStore
							.COEFFICIENT_CHANGE_VALUE
				);
				break;
			case "decrease":
				formContext.setValue(
					key,
					values[key] -
						rootStateStore.quickPlayStore.quickBetStore
							.COEFFICIENT_CHANGE_VALUE
				);
				break;
			default:
				console.error("missing switch value");
		}
	}

	formContext.watch(props.type);
	return (
		<>
			{formContext.formState.errors[props.type] && (
				<span>{formContext.formState.errors[props.type]?.message}</span>
			)}
			<div className="u-display--f u-display--f--col">
				<span className="bet--odd__title">{t(props.text)} :</span>
				<span className="bet--odd">
					{formContext.getValues()[props.type].toFixed(2)}
				</span>
			</div>
			<div className="u-display--f u-gap--sml">
				<Button
					type="button"
					className="btn--square btn--square--sml btn--secondary--inverted"
					onClick={() => {
						changeOddValue(props.type, "decrease");
					}}
					disabled={decreaseCheck || minMaxCheck}
					iconOnly
					iconClassName="u-icon u-icon--med u-icon--subtract"
				/>
				<Button
					type="button"
					className="btn--square btn--square--sml btn--secondary--inverted"
					onClick={() => {
						changeOddValue(props.type, "increase");
					}}
					disabled={isMinButtonDisabled || lessThanOrEqual100}
					iconOnly
					iconClassName="u-icon u-icon--med u-icon--add"
				/>
			</div>
		</>
	);
}

const FavoritesCheckBox = observer(function FavoritesCheckBox() {
	const rootStateStore = useRootAppStore();

	if (!rootStateStore.userAuthStore.isLoggedIn) {
		return null;
	}

	return (
		<CheckboxField
			checkboxClassname="form__field"
			id="useUserFavorites"
			name="useUserFavorites"
			label="QUICK_PLAY.QUICK_BET.FAVORITES_OFFER"
		/>
	);
});

const SportsMenuCheckBox = observer(function FavoritesCheckBox(props: {
	isWidget?: boolean;
}) {
	const rootStateStore = useRootAppStore();
	const location = useLocation();

	const formContext = useFormContext<TFormFilter>();

	formContext.watch("quickBetType");
	if (!props.isWidget) {
		return null;
	}
	if (
		rootStateStore.quickPlayStore.quickBetStore.menu?.menu
			.nodesBySelectionOrder.length == 0
	) {
		return null;
	}

	if (location.pathname.includes("live")) {
		if (location.pathname.includes("events/")) {
			return null;
		} else if (formContext.getValues("quickBetType") !== "live") {
			return null;
		}
	} else if (
		!location.pathname.includes("live") &&
		formContext.getValues("quickBetType") == "live"
	) {
		return null;
	}

	return (
		<CheckboxField
			checkboxClassname="form__field"
			id="includeMenu"
			name="includeMenu"
			label="QUICK_PLAY.QUICK_BET.MENU_OFFER"
		/>
	);
});
