import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { FormButton } from "@components/buttons";
import { FormDataType } from "@data-types/membership/registration";
import AgencyUserApiService from "@api-services/user/AgencyUserApiService";
import { useRootAppStore } from "@hooks";

import { ChatCloseButton, ChatMinimizeButton } from "@features/chat/desktop";

export default function ChatNameUpdate() {
	const { t } = useTranslation();

	const userAuthStore = App.state.rootStore.userAuthStore;

	const { chatViewStore } = useRootAppStore();

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			chatName: "",
		},
	});

	const { handleSubmit } = methods;
	const user = App.state.rootStore.userAuthStore.user;

	const submitData = async (chatName: string) => {
		if (user) {
			await chatViewStore.updateChatName(
				chatName,
				user?.agencyId,
				user?.id
			);
		}
	};

	return (
		<>
			<div className="chat__card">
				<div className="chat__header u-padd--xsml">
					<span className="chat__header__title">
						{t("CHAT.TITLE")}
					</span>
					<div className="chat__header__actions u-mar--reset">
						<ChatMinimizeButton />
						<ChatCloseButton />
					</div>
				</div>

				<div className="chat__box">
					<div className="chat__notice">
						<div className="chat__box__icon-wrapper">
							<i className="u-icon u-icon--xlrg u-icon--user--alt" />
						</div>

						<div className="u-type--center">
							<div className="u-mar--bottom--xtny u-type--med u-type--wgt--medium">
								{t("CHAT.TITLE")}
							</div>
							<div className="u-type--sml">
								{t("CHAT.ENTER_CHAT_NAME_DESCRIPTION")}
							</div>
						</div>
					</div>

					<FormProvider {...methods}>
						<form
							className="chat__footer"
							onSubmit={handleSubmit(
								(formData: { chatName: string }) =>
									submitData(formData.chatName)
							)}
						>
							<div className="chat__textbox">
								<FormInputField
									name="chatName"
									type="string"
									placeholder={t(
										"CHAT.ENTER_CHAT_NAME_PLACEHOLDER"
									)}
									inputClassName="chat__input input input--sml"
									maxLength={27}
									validate={{
										nameContainsUnderscore: (
											value: string
										) =>
											!value.includes("_") ||
											"CHAT.CANNOT_CONTAIN_UNDERSCORE",
									}}
									required
								/>

								<FormButton
									btnText="COMMON.SAVE"
									className="chat__send btn btn--sml btn--primary"
								/>
							</div>
						</form>
					</FormProvider>
				</div>
			</div>
		</>
	);
}
