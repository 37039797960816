import { action, computed, observable } from "mobx";
import { UserAuthStore } from "./UserAuthStore";
import { UserTypes, getUserFromLocalStorage } from "@utils";
import { AccountVerificationStatus } from "@api-types/user/LoginResponseDto";
import { isAccountVerificationSectionEnabled } from "@v2/helpers";

class RequiredUserActionsStore {
	private userAuthStore: UserAuthStore;
	@observable isActive: boolean = false;

	constructor(userAuthStore: UserAuthStore) {
		this.userAuthStore = userAuthStore;
	}

	@computed get userActionStatus() {
		if (!this.userAuthStore.isLoggedIn) {
			return false;
		}

		const onlineUser = getUserFromLocalStorage(
			this.userAuthStore.isPrimaryUserOnline == true
				? UserTypes.PRIMARY
				: UserTypes.SECONDARY
		);

		if (
			this.userAuthStore.isInternetUser &&
			isAccountVerificationSectionEnabled
		) {
			if (
				onlineUser?.accountVerificationStatus !==
				AccountVerificationStatus.FullyVerified
			) {
				return true;
			}
		}
		const primaryUser = this.userAuthStore.user;

		if (
			(primaryUser && primaryUser?.userDocumentDateExpired) ||
			primaryUser?.userDocumentDateExpiresSoon ||
			primaryUser?.userDocumentDateNotSet
		) {
			return true;
		}

		const secondaryUser = this.userAuthStore.secondaryUser;

		if (secondaryUser) {
			if (onlineUser?.depositLimitUpdate) {
				return true;
			}
		} else if (primaryUser && primaryUser?.depositLimitUpdate) {
			return true;
		}

		if (
			(secondaryUser && secondaryUser?.userDocumentDateExpired) ||
			secondaryUser?.userDocumentDateExpiresSoon ||
			secondaryUser?.userDocumentDateNotSet
		) {
			return true;
		}

		const userType = this.userAuthStore.getWalletOwner(
			this.userAuthStore.activeAccountTypeId
		);

		const user =
			UserTypes.PRIMARY == userType
				? this.userAuthStore.user
				: this.userAuthStore.secondaryUser;

		if (user?.isBettingDisabled) {
			return true;
		}

		return false;
	}

	@action.bound
	showPopup() {
		this.isActive = true;
	}

	@action.bound
	hidePopup() {
		this.isActive = false;
	}
}

export default RequiredUserActionsStore;
