import React from "react";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { useFormContext } from "react-hook-form";

export default function RegistrationLastName(props: {
	labelClassName?: string;
}) {
	const { labelClassName } = props;

	const { getValues, trigger, clearErrors } = useFormContext();

	return (
		<FormInputField
			className="form__field"
			name="lastName"
			label="MEMBERSHIP.REGISTRATION.LAST_NAME_LABEL"
			labelClassName={labelClassName}
			placeholder="MEMBERSHIP.REGISTRATION.LAST_NAME_PLACEHOLDER"
			// do not delete, maybe will be used in the future
			// popoverMsg={"MEMBERSHIP.REGISTRATION.LAST_NAME_DESCRIPTION"}
			pattern={{
				value: /^[\p{L}\p{M}\d \.'\-]{1,80}$/u,
				message: "MEMBERSHIP.REGISTRATION.INVALID_LAST_NAME",
			}}
			validate={{
				passValidate: () => {
					if (getValues("password")) {
						trigger("password");
					} else {
						clearErrors("password");
					}
				},
			}}
			required
		/>
	);
}
