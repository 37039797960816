import { observable, action, runInAction, computed } from "mobx";

import { LiveStatus } from "@gp/models";
import { Subscription } from "rxjs";
import { TRootStore } from "../BaseOfferMenuStore";
import FavoritesStore from "@offer/stores/FavoritesStore";

/**
 * @classdesc Store that manges count subscription for live favorite events
 * @param {object} rootStore Constructor takes rootStore, it is required for hub connection
 * @property {array} eventIds list of event ids for which this store creates count subscription
 *
 * @function updateEventIds updates eventIds for which this store gets count
 * @function onDispose
 * @function {action} initSubscription internally used to create count subscription for eventIds array, don't call this directly
 */
export class FavoriteEventsCounterStore {
	//#region observable
	rootStore: TRootStore;
	favoritesStore: FavoritesStore;

	@observable liveFavoritesCount = 0;
	@observable prematchFavoritesCount = 0;

	@observable favSubscription: Subscription | null;

	//#endregion observable

	//#region constructor

	constructor(rootStore: TRootStore, favoritesStore: FavoritesStore) {
		this.rootStore = rootStore;
		this.favoritesStore = favoritesStore;
	}

	//#endregion constructor

	//#region fetching data

	@action.bound
	fetchLiveFavoritesCounter() {
		this.disposeSubscription();

		if (this.favoritesStore.userFavoriteEventsSet.size === 0) {
			this.liveFavoritesCount = 0;
			return;
		}

		const favFilter = {
			subscriptionId: "live-favorites-counter",
			count: {
				favorites: {
					liveStatus: LiveStatus.LIVE,
					id: {
						eq: [...this.favoritesStore.userFavoriteEventsSet],
					},
				},
			},
		};

		this.favSubscription = this.rootStore.hub
			.getCounts(favFilter)
			.subscribe(
				(response) => {
					runInAction(() => {
						if (!isNaN(Number(response.favorites))) {
							this.liveFavoritesCount = Number(
								response.favorites
							);
						}
					});
				},
				(err) => {
					console.error("favorites count error", err);
					runInAction(() => {
						this.liveFavoritesCount = 0;
					});
				}
			);
	}

	@action.bound
	fetchPrematchFavoritesCounter() {
		if (this.favoritesStore.userFavoriteEventsSet.size === 0) {
			this.prematchFavoritesCount = 0;
			return;
		}
		this.disposeSubscription();

		const favFilter = {
			subscriptionId: "favorites-counter",
			count: {
				prematchFavorites: {
					...this.favoritesStore.getTime(),
					id: {
						eq: [...this.favoritesStore.userFavoriteEventsSet],
					},
					liveStatus: LiveStatus.PREMATCH,
				},
			},
		};

		this.favSubscription = this.rootStore.hub
			.getCounts(favFilter)
			.subscribe(
				(response) => {
					runInAction(() => {
						if (!isNaN(Number(response.prematchFavorites))) {
							this.prematchFavoritesCount = Number(
								response.prematchFavorites
							);
						}
						this.disposeSubscription();
					});
				},
				(err) => {
					console.error("favorites count error", err);
					runInAction(() => {
						this.prematchFavoritesCount = 0;
					});
					this.disposeSubscription();
				}
			);
	}
	//#endregion fetching data

	//#region actions

	//#endregion actions

	//#region disposers

	@action.bound
	onDispose() {
		this.prematchFavoritesCount = 0;
		this.liveFavoritesCount = 0;
		this.disposeSubscription();
	}

	@action.bound
	disposeSubscription() {
		if (this.favSubscription) {
			this.favSubscription.unsubscribe();
			this.favSubscription = null;
		}
	}

	//#endregion disposers

	//#region utility

	//#endregion utility
}
