import React, { useState } from "react";
import { observer } from "mobx-react";

import { SportOfferContext } from "@gp/components";
import { SportOffer } from "@gp/offer";

import { getSportOfferKey } from "@offer/common";

import SportHeader from "./components/SportHeader";
import { TournamentEvents } from "./components/TournamentEvents";

export const PrematchSportsList = observer(function PrematchSportsList(props: {
	sports: SportOffer[];
}) {
	return (
		<>
			{props.sports
				.sort((a, b) => (a.isLive === b.isLive ? 0 : a.isLive ? -1 : 1))
				.map((sport) => (
					<PrematchSport
						key={getSportOfferKey(sport)}
						sport={sport}
					/>
				))}
		</>
	);
});

const PrematchSport = observer(function PrematchSport(props: {
	sport: SportOffer;
}) {
	const [isSportCollapsed, setIsSportCollapsed] = useState(false);

	return (
		<SportOfferContext.Provider value={props.sport}>
			<SportHeader
				isSportCollapsed={isSportCollapsed}
				setIsSportCollapsed={setIsSportCollapsed}
				sport={props.sport}
			/>
			{!isSportCollapsed &&
				props.sport.categories?.map((cat) =>
					cat.tournaments.map((tournament) => (
						<TournamentEvents
							key={tournament.id}
							sport={props.sport}
							category={cat}
							tournament={tournament}
						/>
					))
				)}
		</SportOfferContext.Provider>
	);
});
