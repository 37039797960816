import React, { useState } from "react";
import {
	Validate,
	ValidationRule,
	useFormContext,
	useFormState,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RequiredMark, PopoverMessage, ErrorMessage } from "./";
import { get } from "lodash";

import classNames from "classnames";

export default function FormInputField(props: FormInputFieldProps) {
	const {
		id = undefined,
		label,
		labelClassName,
		inputClassName = undefined,
		name,
		className,
		errorMessage = undefined,
		type = "text",
		required = false,
		placeholder = undefined,
		popoverMsg = null,
		validate,
		astersisksClass = null,
		pattern,
		...rest
	} = props;

	const [mouseOverState, setMouseOverState] = useState(false);

	const { t } = useTranslation();
	const { register } = useFormContext();

	const { errors } = useFormState();
	const error = get(errors, name);

	const field = register(name, {
		required: {
			value: required,
			message: errorMessage || "COMMON.REQUIRED_FIELD",
		},
		pattern,
		validate,
	});

	const labelClasses = classNames("label", labelClassName);

	const inputClass = classNames(inputClassName, {
		"input input--base":
			inputClassName == undefined || inputClassName == null,
		"input-validation-error": error != undefined,
	});

	if (label == undefined || className == undefined) {
		return (
			<input
				type={type}
				{...field}
				{...rest}
				id={id}
				className={inputClassName}
				placeholder={t(placeholder || "")}
			/>
		);
	}

	return (
		<div className={className}>
			<label
				htmlFor={id}
				className={labelClasses}
				onMouseEnter={() => setMouseOverState(true)}
				onMouseLeave={() => setMouseOverState(false)}
			>
				{t(label)}
				<RequiredMark required={required} />
				<PopoverMessage
					popoverMsg={popoverMsg}
					mouseOverState={mouseOverState}
				/>
			</label>
			<ErrorMessage name={name} />
			<input
				type={type}
				{...field}
				{...rest}
				id={id}
				className={inputClass}
				placeholder={t(placeholder || "")}
			/>
		</div>
	);
}

type FormInputFieldProps = Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	"pattern"
> & {
	name: string;
	label?: string;
	labelClassName?: string;
	inputClassName?: string;
	placeholder?: string;
	popoverMsg?: React.ReactNode | string;
	errorMessage?: string;
	pattern?: ValidationRule<RegExp>;
	validate?: Validate<any> | Record<string, Validate<any>>;
	astersisksClass?: string | null;
};
