import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorMessage, RequiredMark } from ".";

const CheckboxFieldArray = (props) => {
	const { t } = useTranslation();
	const { register, getValues, watch, clearErrors } = useFormContext();
	const {
		arrayList,
		name,
		disabledOption,
		checkboxFieldsLabel,
		required = false,
	} = props;
	const [disabled, setDisabled] = useState(false);
	const fieldResponse = getValues(name);
	watch(name);

	const handleDisabledCheckbox = () => {
		setDisabled(!disabled);
	};

	const checkboxFieldFormValidation = () => {
		if (fieldResponse == null) {
			return;
		}
		if (
			fieldResponse.fieldData != false ||
			fieldResponse.disableOption != false
		) {
			clearErrors(name);
		} else {
			return false;
		}
	};

	return (
		<div className="col col-sml-12 form__field">
			<label className="label">
				{t(checkboxFieldsLabel)}
				<RequiredMark required={required} />
			</label>

			<ErrorMessage
				name={name}
				customErrorMessage="COMMON.REQUIRED_FIELD"
			/>

			<div className="card--primary message--validation--card">
				<div className="card--primary__body u-padd--xsml">
					{arrayList.map((item) => (
						<div
							className="col col-sml-12 form__field u-mar--top--xsml"
							key={item.id}
						>
							<input
								id={item.name}
								type="checkbox"
								className="input--check"
								value={item.code}
								disabled={
									fieldResponse != null &&
									fieldResponse.disableOption != false
								}
								{...register(`${name}.fieldData`, {
									validate: () =>
										checkboxFieldFormValidation(),
								})}
							/>
							<label htmlFor={item.name}>{item.name}</label>
						</div>
					))}

					{disabledOption != null && (
						<div className="col col-sml-12 form__field u-mar--top--xsml">
							<input
								id={disabledOption.name}
								type="checkbox"
								{...register(`${name}.disableOption`, {
									validate: () =>
										checkboxFieldFormValidation(),
								})}
								className="input--check"
								value={disabledOption.code}
								onClick={handleDisabledCheckbox}
							/>
							<label htmlFor={disabledOption.name}>
								{disabledOption.name}
							</label>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default CheckboxFieldArray;
