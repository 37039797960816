import React, { useEffect } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { localizationService } from "@state";
import { setTabTitle } from "@utils";

export default function OfferPrintTableSection(props) {
	const { data } = props;
	const { t } = useTranslation();

	useEffect(() => {
		setTabTitle(localizationService.t("OFFER_PRINT.TITLE"));
	});

	return (
		<div className="offer--download">
			<div className="row">
				<div className="col col-sml-12">
					<div className="offer--download__header">
						<div className="offer--download__title">
							<span className="u-align--v--middle">
								{data?.isHex
									? t("OFFER_PRINT.HEX_TITLE").toUpperCase()
									: t("OFFER_PRINT.DEC_TITLE").toUpperCase()}
							</span>
						</div>
						<div className="offer--download__date">
							{t("OFFER_PRINT.LAST_UPDATE_LABEL").toUpperCase()}
						</div>
						<div className="offer--download__action">
							{t("OFFER_PRINT.DOWNLOAD_LABEL").toUpperCase()}
						</div>
					</div>
					<List data={data} items={data?.offerPrintCategories} />
				</div>
			</div>
		</div>
	);
}

function List(props) {
	const { items, data } = props;

	const { t } = useTranslation();

	if (!items || !items.length) return null;

	return items.map((category, idx) => (
		<div
			key={`${category.name}-c-${idx}${data.isHex ? "-hex" : ""}`}
			className="offer--download__body"
		>
			<div className="offer--download__subheader">
				{t(`OFFER_PRINT.CATEGORIES.${category.name}`).toUpperCase()}
			</div>

			<Categories
				data={data}
				category={category}
				idx={idx}
				items={category.offerPrintFiles}
			/>
		</div>
	));
}

function Categories(props) {
	const { data, category, idx, items } = props;

	const { t } = useTranslation();

	return items.map((file, fIdx) => {
		const hasUrl = file.url != null && file.url !== "";

		return (
			<div
				key={`${category.name}-c-${idx}-${file.name}-f-${fIdx}${data.isHex ? "-hex" : ""}`}
				className="offer--download__item"
			>
				<div className="offer--download__title">
					<a
						className="u-anchor u-anchor--secondary"
						disabled={!hasUrl}
						href={file.url}
						target="_blank"
						rel="noreferrer"
					>
						{t(`OFFER_PRINT.FILES.${file.name}`)}
					</a>
				</div>
				{hasUrl ? (
					<>
						<div className="offer--download__date">
							{DateTime.fromISO(file.dateModified).toFormat("EEE, dd.LL, T")}
						</div>
						<div className="offer--download__action">
							<a
								className="btn btn--square--reset btn--ghost"
								disabled={!hasUrl}
								href={file.url}
								target="_blank"
								rel="noreferrer"
							>
								<i className="u-icon u-icon--sml u-icon--download" />
							</a>
						</div>
					</>
				) : null}
			</div>
		);
	});
}