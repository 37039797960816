import React, { useEffect, useRef } from "react";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { MessageContainer, SendMessage } from "@features/chat/desktop";
import classNames from "classnames";
import { AmityMessageMutated } from "@state/stores/chat/ChatChannelHandler";
import { useTranslation } from "react-i18next";

export default observer(function ChatMessagesWrapper() {
	const chatContainerRef = useRef<HTMLDivElement>(null);
	const messageRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const {
		chatViewStore: { chatChannelHandler },
	} = useRootAppStore();

	useEffect(() => {
		if (
			chatContainerRef.current &&
			messageRef.current &&
			chatContainerRef.current.scrollTop != 0
		) {
			const messageHeight = messageRef.current.clientHeight;
			chatContainerRef.current.scrollBy({
				top: -messageHeight,
				behavior: "auto",
			});
		}
	}, [chatChannelHandler.messageList.length]);

	if (!chatChannelHandler.messagesPaginationObject) {
		return null;
	}

	const { onNextPage, hasNextPage } =
		chatChannelHandler.messagesPaginationObject;

	if (onNextPage === undefined || hasNextPage === undefined) {
		return null;
	}

	const btnClasses = classNames(
		"chat__btn--scroll btn btn--round btn--round--sml btn--icon btn--tertiary"
	);

	return (
		<>
			<div
				className="chat__box__container"
				id="scrollableDiv"
				ref={chatContainerRef}
			>
				<InfiniteScroll
					className="chat__scroll"
					dataLength={chatChannelHandler.messageList.length}
					next={onNextPage}
					hasMore={hasNextPage}
					inverse={true}
					loader={
						<div className="chat__box__loader">
							<div className="loader__spinner">
								<div className="loader__spinner__wrapper"></div>
							</div>
						</div>
					}
					scrollableTarget="scrollableDiv"
					endMessage={
						<span className="u-type--center u-color--text--tertiary">
							{t("CHAT.NO_MORE_MESSAGES_AVAILABLE")}
						</span>
					}
				>
					{chatChannelHandler.messageList.reverse().map(
						(
							message: AmityMessageMutated & {
								data: {
									text?: string;
								};
								metadata: {
									isLink: boolean;
								};
							},
							index: number
						) => {
							return (
								<div key={index} ref={messageRef}>
									<MessageContainer message={message} />
								</div>
							);
						}
					)}
				</InfiniteScroll>
			</div>
			<SendMessage />
		</>
	);
});
