import PartnerRegistrationStore from "@v2/state/partner-registration/PartnerRegistrationStore";
import { useContext, createContext } from "react";

export const PartnerRegistrationStoreContext = createContext(
	{} as PartnerRegistrationStore
);

export const usePartnerRegistrationStore = () => {
	return useContext(PartnerRegistrationStoreContext);
};
