import { Bet } from "@administration/pages/my-bets/model";
import { MyBetsBetContext, MyBetsStoreContext } from "@v2/context";
import classnames from "classnames";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import BetOfferItem from "./BetOfferItem";
import { DateTime } from "luxon";
import { useRootAppStore } from "@hooks";

export default observer(function SharedBetSlipItem(props: { bet: Bet }) {
	const { bet } = props;
	const { t } = useTranslation();

	return (
		<MyBetsBetContext.Provider value={props.bet}>
			<div id={`bet-item-${props.bet.id}`} className="bet">
				<div className="logo"></div>
				<div className="bet__header--shared">
					<i className="u-icon u-icon--xxxlrg u-icon--empty u-icon--empty--slip" />
					<div className="bet__title u-mar--bottom--xtny">
						<h3>{t("MY_BETS.SHARE.TITLE")}</h3>
					</div>
					<div className="bet__header__inner">
						<div className="bet__header__col">
							<span className="u-type--xxsml u-color--text--tertiary">
								{t("BET_SLIP.DATE")}:{" "}
							</span>

							<span className="u-type--sml u-color--text--primary">
								{DateTime.fromISO(
									bet.dateCreated
								).toLocaleString(DateTime.DATETIME_SHORT)}
							</span>
						</div>

						<div className="bet__header__col">
							<span className="u-type--xxsml u-color--text--tertiary">
								{t("BET_SLIP.MAX_COEF")}:{" "}
							</span>

							<span className="u-type--sml u-color--text--primary">
								{bet.maxCoefficient.toFixed(2)}
							</span>
						</div>
					</div>
				</div>
				<SharedBetItemTable bet={bet} />
			</div>
		</MyBetsBetContext.Provider>
	);
});

function SharedBetItemTable(props: { bet: Bet }) {
	const { bet } = props;
	return (
		<div className="card--primary__body">
			<div className="card--bet">
				<table className="table--bets" cellSpacing={0} cellPadding={0}>
					<SharedBetItemTableHead bet={bet} />
					<tbody className="table--bets__body">
						<SharedBetOffers bet={bet} />
					</tbody>
				</table>
			</div>
			<AddToBetSlipButton />
		</div>
	);
}

const SharedBetOffers = observer(function SharedBetOffers(props: { bet: Bet }) {
	if (props.bet.betSlipOffers == null) {
		return null;
	}

	return (
		<>
			{props.bet.betSlipOffers.map((offer) => (
				<BetOfferItem key={offer.id} offer={offer} />
			))}
		</>
	);
});

function SharedBetItemTableHead(props: { bet: Bet }) {
	const { bet } = props;
	const { t } = useTranslation();
	return (
		<thead className="table--bets__head">
			<tr className="table--bets__subhead">
				<th className="table--bets__ts table--bets__ts--time">
					{t<string>("MY_BETS.ITEM.TIME_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--combi">
					&nbsp;
				</th>
				<th className="table--bets__ts table--bets__ts--event">
					{t<string>("MY_BETS.ITEM.EVENT_NAME_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--bank">
					&nbsp;
				</th>
				<th className="table--bets__ts table--bets__ts--bet-type">
					{t<string>("MY_BETS.ITEM.TIP_NAME_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--odds">
					{t<string>("MY_BETS.ITEM.QUOTA_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--results">
					{t<string>("MY_BETS.ITEM.RESULT_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--icon">
					&nbsp;
				</th>
			</tr>
		</thead>
	);
}

const AddToBetSlipButton = observer(function AddToBetSlipButton() {
	const { t } = useTranslation();
	const { betSlipOffers, id, betSlipNumber, isLive, userId } =
		useContext(MyBetsBetContext);

	const store = useContext(MyBetsStoreContext);

	const {
		userAuthStore: { isLoggedIn },
	} = useRootAppStore();

	if (store == null) {
		console.error("Expected store, got null.");
		return null;
	}

	const {
		activeEventsStore,
		betSlipShareStore: { openReuseBetSlipModal },
	} = store;

	const hasActiveOffer = betSlipOffers.some((offer) =>
		activeEventsStore.eventsMap.has(offer.eventId)
	);

	if (!hasActiveOffer) {
		return null;
	}

	return (
		<button
			type="button"
			className="btn btn--icon btn--wrap btn--wrap--base btn--primary u-mar--top--big"
			onClick={() => {
				openReuseBetSlipModal({ id, betSlipNumber, isLive, userId });
			}}
		>
			<i className="u-icon u-icon--big u-icon--empty--add-slip u-color--core--snow" />
			<span>{t("MY_BETS.SHARE.ADD_TO_SLIP")}</span>
		</button>
	);
});
