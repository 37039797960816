import { observer } from "mobx-react";
import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useUser } from "@v2/hooks/shared";
import { UserAccountType } from "@common/constants";
import { UserAccount } from "@data-types";
import { addCanBeSelected, SelectableWallets } from "@v2/helpers/shared";

export const UserBalanceDisplay = observer(function UserBalanceDisplay(
	props: UserBalanceDisplayProps
): JSX.Element | null {
	const { isInMenu } = props;
	const user = useUser();

	if (user.userAccounts == null || user.userAccounts.length == 0) {
		return null;
	}

	return <AccountBalance isInMenu={isInMenu} />;
});

const AccountBalance = observer(function SingleWallet(
	props: UserBalanceDisplayProps
): JSX.Element | null {
	const { isInMenu } = props;
	const user = useUser();

	const numberOfWallets = user.userAccounts?.length;

	if (!isInMenu) {
		const wallet =
			user.userAccounts?.find(
				(account) => account.accountTypeId === user.activeAccountTypeId
			) || user.userAccounts?.[0];

		return (
			<WalletItem
				isInMenu={isInMenu}
				wallet={wallet}
				numberOfWallets={numberOfWallets}
			/>
		);
	}
	const userWalletsMapped = addCanBeSelected(user.userAccounts, user);
	return (
		<>
			{userWalletsMapped.map((wallet) => {
				return (
					<WalletItem
						key={wallet.id}
						isInMenu={isInMenu}
						wallet={wallet}
						numberOfWallets={numberOfWallets}
					/>
				);
			})}
		</>
	);
});

const WalletItem = observer(function WalletItem(props: WalletItemProps) {
	const { wallet, isInMenu, numberOfWallets } = props;

	if (wallet.abrv === UserAccountType.SportBettingAccountShop && !isInMenu) {
		return null;
	}

	if (
		WEBPACK_IS_DK_MW &&
		wallet.abrv === UserAccountType.SportBettingAccountShopOnline &&
		isInMenu
	) {
		return null;
	}

	const itemClasses = classNames("wallet__item", {
		"wallet__item--sml": !isInMenu,
	});

	const labelClasses = classNames(
		[`wallet__account wallet__account--${wallet.abrv}`],
		{
			"wallet__account--sml": !isInMenu,
		}
	);

	const typeClasses = classNames("wallet__type", {
		"wallet__type--sml": !isInMenu,
	});

	const iconClasses = classNames(
		"wallet__icon",
		{
			"wallet__icon--header": !isInMenu,
			"wallet__icon--menu": isInMenu,
		},
		"u-icon",
		{
			"u-icon--xsml": !isInMenu,
			"u-icon--med": isInMenu,
		},
		[`u-icon--wallet u-icon--wallet--${wallet.abrv} u-mar--left--reset`]
	);

	const balanceClasses = classNames("wallet__balance", {
		"wallet__balance--sml": !isInMenu,
	});

	return (
		<li className={itemClasses}>
			<AccountTypeSelector
				isInMenu={isInMenu}
				wallet={wallet}
				numberOfWallets={numberOfWallets}
			/>

			<label
				htmlFor={!isInMenu ? `${wallet.id}` : `${wallet.id}-menu`}
				className={labelClasses}
			>
				<span className={typeClasses}>
					{numberOfWallets != 1 && <i className={iconClasses} />}
					<span
						className={classNames("wallet__type__title", {
							"wallet__type__title--sml": !isInMenu,
						})}
					>
						<WalletName
							isInMenu={isInMenu}
							wallet={wallet}
							numberOfWallets={numberOfWallets}
						/>
						:
					</span>
				</span>
				<span className={balanceClasses}>
					<span className="wallet__amount" data-id={wallet.id}>
						{wallet.balance.toFixed(2)}
					</span>
					<span className="wallet__currency">
						{wallet.currency.displayCode}
					</span>
				</span>
			</label>
		</li>
	);
});

function WalletName(props: WalletItemProps) {
	const { isInMenu, numberOfWallets, wallet } = props;
	const { t } = useTranslation();

	//todo shorter translation for !isInMenu wallets
	if (numberOfWallets == 1) {
		return <>{t(`USER.ACCOUNT_SETTINGS.NAVIGATION.ACCOUNT_BALANCE`)}</>;
	}
	return <>{t(`HEADER.${wallet.abrv.toUpperCase()}`)}</>;
}

const AccountTypeSelector = observer(function AccountTypeSelector(
	props: WalletItemProps
) {
	const { isInMenu, wallet, numberOfWallets } = props;
	const user = useUser();

	if (
		numberOfWallets == 1 ||
		("canBeSelected" in wallet && wallet.canBeSelected == false) ||
		!isInMenu
	) {
		return (
			<input
				id={`${wallet.id}${isInMenu ? "-menu" : ""}`}
				name={isInMenu ? "accountSelector-menu" : "accountSelector"}
				className={classNames("input--radio", {
					"is-hidden": isInMenu,
					"is-disabled": !isInMenu,
				})}
				type="radio"
				disabled={true}
			/>
		);
	}

	return (
		<input
			id={`${wallet.id}${isInMenu ? "-menu" : ""}`}
			name={isInMenu ? "accountSelector-menu" : "accountSelector"}
			className="input--radio"
			type="radio"
			value={wallet.accountTypeId}
			data-id={wallet.id}
			onChange={() =>
				user.setActiveUserAccountTypeId(wallet.accountTypeId)
			}
			checked={user.activeAccountTypeId === wallet.accountTypeId}
		/>
	);
});

type UserBalanceDisplayProps = {
	isInMenu: boolean;
};

type WalletItemProps = {
	wallet: SelectableWallets | UserAccount;
	isInMenu: boolean;
	numberOfWallets?: number;
};
