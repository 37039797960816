import React, { useContext } from "react";
import { OfferOptionsContext } from "@gp/components";

export default function EmptyOfferColumns() {
    const { numberOfColumns } = useContext(OfferOptionsContext);

    return (
        <>
            {(new Array(numberOfColumns)).fill(1).map((x, idx) => (
                <EmptyOfferColumn key={idx} />
            ))}
        </>
    );
}

function EmptyOfferColumn() {
    return (
        <div className="offer__body__data offer__quotes">
            <div className="quote quote--placeholder" />
            <div className="quote quote--placeholder" />
            <div className="quote quote--placeholder" />
        </div>
    );
}