import { useCallback } from "react";
import { useLoading } from "@hooks";
import {
	useNotification,
	usePasswordConfirmation,
} from "../../../../ui/common/hooks";
import { useTranslation } from "react-i18next";
import { EmailChangeService } from "@services/account-settings/EmailChangeService";
import { isNullOrWhitespace } from "@utils";
import { setTabTitle } from "@utils";
import { useFormContext } from "react-hook-form";
import { EmailChangeDto } from "@data-types";

let timeoutId: ReturnType<typeof setTimeout> | null = null;

export default function useEmailChange() {
	const { isLoading, setIsLoading } = useLoading();
	const { showError, showSuccess } = useNotification();

	const { showPasswordConfirmation, setShowPasswordConfirmation } =
		usePasswordConfirmation();

	const { getValues, reset, setError, clearErrors, getFieldState } =
		useFormContext();

	const { t } = useTranslation();

	const onSubmitData = useCallback(() => {
		setShowPasswordConfirmation(true);
	}, []);

	const onCancel = () => {
		reset();
		setShowPasswordConfirmation(false);
	};

	const checkIsEmailAvailable = useCallback((newEmail: string) => {
		return EmailChangeService.checkIsEmailAvailable(newEmail);
	}, []);

	const checkForEmailAvailiability = async (value: string) => {
		const response = await checkIsEmailAvailable(value);
		return response;
	};

	const checkEmail = (newEmail: string) => {
		if (isNullOrWhitespace(newEmail) || getFieldState("newEmail").invalid)
			return;

		if (timeoutId != null) {
			clearTimeout(timeoutId);
		}
		//this error is set so user can't submit the form until request has finished
		setError("newEmail", { type: "wait" });

		timeoutId = setTimeout(async () => {
			const isMailAvailable = await checkForEmailAvailiability(newEmail);
			if (!isMailAvailable) {
				setError("newEmail", {
					type: "isAvailable",
					message: "USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_EXISTS",
				});
			} else {
				clearErrors("newEmail");
			}
		}, 500);
	};

	const onSuccess = useCallback(async (password: string) => {
		try {
			setIsLoading(true);
			const emailChangeModel: EmailChangeDto = {
				newEmailAddress: getValues("newEmail"),
				password: password,
			};

			const response = await EmailChangeService.changeUserEmail(
				emailChangeModel
			);
			if (response) {
				reset();
				setShowPasswordConfirmation(false);
				showSuccess([
					{
						message:
							"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.SUCCESS_EMAIL_CHANGE_ONE",
					},
					{ message: emailChangeModel.newEmailAddress },
					{
						message:
							"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.SUCCESS_EMAIL_CHANGE_TWO",
					},
				]);
			}
		} catch (error) {
			if (error?.message) {
				showError(
					"USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error?.message
				);
			}
		} finally {
			setIsLoading(false);
		}
	}, []);

	const fetchOrActivateUserEmail = async (mailkey: string) => {
		setIsLoading(true);
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_EC"));
		document.querySelector("html, body")?.scrollTo(0, 0);

		try {
			const response = await EmailChangeService.getUser(mailkey);
			if (typeof response === "string") {
				reset({ CurrentEmail: response });
				if (mailkey) {
					showSuccess(
						"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_ACTIVATE_SUCCESS"
					);
				}
			}
		} catch (error) {
			reset(error.data);

			showError("USER.ACCOUNT_SETTINGS.EMAIL_CHANGE." + error?.message);
		} finally {
			setIsLoading(false);
		}
	};
	return {
		onSubmitData,
		onSuccess,
		onCancel,
		checkIsEmailAvailable,
		fetchOrActivateUserEmail,
		checkForEmailAvailiability,
		checkEmail,
		isLoading,
		setIsLoading,
		showPasswordConfirmation,
		setShowPasswordConfirmation,
	};
}
