import React from "react";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { useValueAvailabilityDebounce } from "@v2/hooks";
import { EmailPattern } from "@common/constants";

export default function RegistrationEmail(props: {
	checkIsEmailAvailable: (data: string) => Promise<void>;
	labelClassName?: string;
}) {
	const { checkIsEmailAvailable, labelClassName } = props;
	const {
		formState: { errors },
		clearErrors,
	} = useFormContext();

	const debouncer = useValueAvailabilityDebounce();

	return (
		<FormInputField
			className="form__field"
			name="email"
			popoverMsg={"MEMBERSHIP.REGISTRATION.EMAIL_DESCRIPTION"}
			label="MEMBERSHIP.REGISTRATION.EMAIL_LABEL"
			labelClassName={labelClassName}
			placeholder="MEMBERSHIP.REGISTRATION.EMAIL_PLACEHOLDER"
			maxLength={100}
			pattern={{
				value: EmailPattern,
				message: "MEMBERSHIP.REGISTRATION.INVALID_EMAIL",
			}}
			validate={{
				isAvailable: async (value: string) => {
					if (get(errors, "email")) {
						clearErrors("email");
					}
					const isAvailable = await debouncer(
						value,
						checkIsEmailAvailable
					);

					return (
						isAvailable || "MEMBERSHIP.REGISTRATION.EMAIL_EXISTS"
					);
				},
			}}
			required
		/>
	);
}
