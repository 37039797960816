import { useContext } from "react";
import { SetIsAdditionalOfferOpenCtxProviderForParentLayout } from "@context/SetIsAdditionalOfferOpenCtxProviderForParentLayout";
import { EventContext } from "@gp/components";
import { useGetAdditionalOfferEvent } from "@offer/common";

export default function useGetIsEventFeatured(): boolean {
	const additionalOfferEventId = useGetAdditionalOfferEvent()?.eventId;
	const addOffer = useContext(
		SetIsAdditionalOfferOpenCtxProviderForParentLayout
	);
	const event = useContext(EventContext);

	// if (!addOffer.isAdditionalOpen) {
	//     return false;
	// }

	return additionalOfferEventId === event.id;
}
