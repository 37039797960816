import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import LmtLsEventsStore from "./LmtLsEventStore";

import { useRootAppStore } from "@hooks";

export default observer(function SideWidgetHeaderTitle(props: {
	lmtLsStore: LmtLsEventsStore;
	isMainTitle?: boolean;
}) {
	const t = useTranslation().t;
	const widgetStore = useLiveWidgetStore();
	const activeWidget = widgetStore.activeSideWidget;
	const event = props.lmtLsStore.eventsMap.get(activeWidget?.eventId || "");

	const titleClasses = classNames("widget__title", {
		// "widget__title--single": activeWidget != null && (event?.source != null || event?.liveStreamStatus === "live" || event?.liveStreamStatus === "upcoming"),
		// "widget__title--both": activeWidget != null && event?.source != null && (event?.liveStreamStatus === "live" || event?.liveStreamStatus === "upcoming"),
		"widget--draggable__title": widgetStore.isWidgetFloating,
		"widget__title--sub": !props.isMainTitle,
	});

	// const widgetTitle = activeWidget != null ? `${activeWidget.teamOneName} - ${activeWidget.teamTwoName}` : t<string>("COMMON.LMT_AND_LS");
	const widgetTitle =
		activeWidget != null
			? `${activeWidget.teamOneName} - ${activeWidget.teamTwoName}`
			: t<string>("COMMON.MEDIA");

	if (props.isMainTitle && activeWidget != null) {
		return (
			<div className={titleClasses} title={t<string>("COMMON.MEDIA")}>
				{t<string>("COMMON.MEDIA")}
			</div>
		);
	}

	return (
		<div className={titleClasses} title={widgetTitle}>
			{widgetTitle}
		</div>
	);
});
