import React, { FC, useContext } from "react";
import { HeaderWithSideMenu } from "../shared/header";
import { AdministrationSideMenu } from "../shared/side-menu";
import { Footer } from "../shared/footer";
import { Link } from "react-router-dom";
import { getCurrentCulture } from "@utils";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { DepositContext } from "@context";

export function DepositInfoPage() {
	const { t } = useTranslation();

	return (
		<>
			<HeaderWithSideMenu />
			<aside className="l--aside--left">
				<AdministrationSideMenu />
			</aside>
			<main className="l--main l--main--med">
				<section className="l--content l--content--full">
					<div className="l--content__main">
						<div className="container">
							<div className="card--primary">
								<div className="card--primary__body">
									<div className="card--primary__header card--primary__header--icon">
										<i className="u-icon u-icon--huge u-icon--pending"></i>
										<div className="u-mar--top--xsml u-type--lrg">
											{t("PAYMENT_INFO.PAYMENT_DETAILS")}
										</div>
									</div>

									<div className="u-type--base u-type--center u-mar--bottom--big">
										{t(
											"PAYMENT_INFO.PAYLADO_TRANSACTION_MESSAGE"
										)}
									</div>

									<Link
										to={`/${getCurrentCulture()}/home/full/highlights`}
										className="at-accountActivationOk card--message__cta btn btn--lrg btn--primary u-mar--top--big"
									>
										{t("PAYMENT_INFO.HOME_PAGE")}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</>
	);
}
