import React from "react";
import { observer } from "mobx-react";
import { MessageContainer, VideoPlayer, useLiveStreamURLFetch } from ".";
import { WidgetLocation } from "./models/LiveWidgetTypes";
import { VideoPlayerConfig } from "./config/LiveStreamConfig";
import { useTranslation } from "react-i18next";

export const LiveStreamContainer = observer(
	function LiveStreamContainer(props: {
		eventId: string;
		location: WidgetLocation;
		config: VideoPlayerConfig;
	}) {
		const { eventId, location, config } = props;

		const { myWidget, url, setErrorMessage, user } = useLiveStreamURLFetch(
			eventId,
			location
		);

		const { t } = useTranslation();

		if (!myWidget) {
			return null;
		}

		if (
			!user.isLoggedIn ||
			myWidget.message?.infoMessage ||
			myWidget.message?.errorMessage
		) {
			let msg = "";
			if (myWidget.message?.infoMessage != null) {
				msg = myWidget.message.infoMessage;
			}

			if (myWidget.message?.errorMessage != null) {
				msg = myWidget.message.errorMessage;
			}

			if (!user.isLoggedIn) {
				msg = t<string>("LIVE_WIDGETS.LIVE_STREAM.NOT_LOGGED_IN");
			}

			return (
				<MessageContainer
					message={msg}
					isError={myWidget.message?.errorMessage != null}
					location={location}
				/>
			);
		}

		if (!url) {
			return (
				<div className="loader loader--stream">
					<div className="loader__spinner loader__spinner--primary loader--stream__spinner" />
				</div>
			);
		}

		return (
			<VideoPlayer
				url={url}
				eventId={myWidget.eventId}
				config={config}
				setErrorMessage={setErrorMessage}
			/>
		);
	}
);
