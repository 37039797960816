import {
	observable,
	computed,
	action,
	runInAction,
	reaction,
	IReactionDisposer,
} from "mobx";
import GamingHistoryFilterStore from "./GamingHistoryFilterStore";
import { LoaderStore } from "@state/stores/common";
import { betPeriods } from "@administration/pages/my-bets/BetPeriods";
import { gamingHistoryService } from "@v2/data-access/gaming-history/GamingHistoryService";
import { getAgencyId, getCurrentCulture, getShopId } from "@utils";
import RootStore from "@state/stores/RootStore";
import { AccessChannelsModel } from "@api-types/gaming-history/AccessChannelsModel";
import { CoreGamingHistoryFilter } from "@api-types/gaming-history/CoreGamingHistoryFilter";
import { GamingHistoryDataDto } from "@api-types/gaming-history/GamingHistoryDataDto";
import { GamingHistoryFilterParamItem } from "@api-types/gaming-history/GamingHistoryFilterParamItem";
import { GamingHistoryRequestFilter } from "@api-types/gaming-history/GamingHistoryRequestFilter";
import { ShopDefinition } from "@api-types/gaming-history/ShopDefinition";

import { PagingData } from "@data-types/common";
import {
	GamingHistoryFilter,
	GamingHistoryRawFilterData,
	RoundTransactionData,
} from "@data-types/gaming-history";
import { localizationService } from "@state";
import { GamingHistoryReportStore } from "./GamingHistoryReportStore";
import { isGamingHistoryReportEnabled } from "@v2/helpers";

export default class GamingHistoryStore {
	gamingHistoryReport: GamingHistoryReportStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		// Helper stores
		this.gamingHistoryFilterStore = new GamingHistoryFilterStore(
			rootStore,
			this
		);
		this.gamingHistoryReport = new GamingHistoryReportStore(
			rootStore,
			this
		);
		this.loader = new LoaderStore();

		// Method bindings
		this.getDateRange = this.getDateRange.bind(this);
	}

	//#region observables

	@observable isStoreInitialized: boolean = false;
	@observable isFetchingData: boolean = true; // When store first starts it will always start fetching

	@observable gameHistoryList: Array<GamingHistoryDataDto> = [];
	@computed get isEmpty(): boolean {
		return (
			this.gameHistoryList.length === 0 &&
			!this.isFetchingData &&
			this.isStoreInitialized
		);
	}

	@computed get betPeriods() {
		return betPeriods;
	}

	@computed get pageNumber(): number {
		return this.gamingHistoryFilterStore.pageNumber;
	}

	pageSize: number = 10;
	loader: LoaderStore;
	gamingHistoryFilterStore: GamingHistoryFilterStore;
	filterReaction: IReactionDisposer | null;
	rootStore: RootStore;
	@observable totalItems: number = 0;
	@observable initialLoad: boolean = true;

	@observable games: Array<string> = [];
	@observable transactionsPerRound: RoundTransactionData[] = [];

	//#endregion observables

	//#region computed

	@computed get isLoading(): boolean {
		return this.loader.isLoading;
	}

	//#endregion computed

	//#region actions

	@action.bound
	async onInitialize(): Promise<void> {
		this.loader.suspend();

		this.disposeFilterReaction();
		this.filterReaction = reaction(
			() => ({
				filter: this.gamingHistoryFilterStore.filter,
			}),
			() => {
				if (
					this.isStoreInitialized && // Wait for lookups to load
					!this.gamingHistoryFilterStore.isDefaultFilter // Don't fetch for default filter
				) {
					this.fetchGamingHistory(
						this.gamingHistoryFilterStore.filter
					);
				}
			},
			{
				fireImmediately: true,
			}
		);
		this.gamingHistoryFilterStore.onInitialize(); // Initialize filter after this store, we need to know if search params are empty

		runInAction(() => {
			this.isStoreInitialized = true;
			this.loader.resume();
		});
	}

	@action.bound
	async onLiveCasinoInitialize(): Promise<void> {
		this.loader.suspend();
		const gamesResponse =
			await gamingHistoryService.getAvailableLiveGames();

		runInAction(() => {
			this.loader.resume();
			this.games = gamesResponse;
		});
	}

	@action.bound
	async fetchGamingHistory(filter: GamingHistoryFilter): Promise<void> {
		this.loader.suspend();
		this.isFetchingData = true;

		try {
			let requestFilter = new GamingHistoryRequestFilter();

			let shopsArray = new Array<ShopDefinition>();
			shopsArray.push(new ShopDefinition(getAgencyId(), getShopId()));

			let accessChannelParams = new AccessChannelsModel(shopsArray);

			let params = new Array<GamingHistoryFilterParamItem>();
			params = params.concat([
				new GamingHistoryFilterParamItem(
					"DateFrom",
					filter.dateFrom || ""
				),
				new GamingHistoryFilterParamItem("DateTo", filter.dateTo || ""),
				new GamingHistoryFilterParamItem(
					"CasinoProviderGameTypeAbrv",
					location.pathname ===
					`/${getCurrentCulture()}/gaming-history/games`
						? "casino-game"
						: "live-casino-game"
				),
			]);

			if (
				filter.casinoGameCategoryId !== "all" &&
				filter.casinoGameCategoryId != null
			) {
				params.push(
					new GamingHistoryFilterParamItem(
						"CasinoGameCategoryId",
						filter.casinoGameCategoryId
					)
				);
			}

			if (
				filter.liveGameTableGroup !== "all" &&
				filter.liveGameTableGroup != null
			) {
				params.push(
					new GamingHistoryFilterParamItem(
						"LiveGameTableGroup",
						filter.liveGameTableGroup
					)
				);
			}

			if (
				filter.roundStatus != null &&
				filter.roundStatus !== "all" &&
				!filter.onlyWin
			) {
				params.push(
					new GamingHistoryFilterParamItem(
						"RoundStatus",
						filter.roundStatus
					)
				);
			}

			if (
				location.pathname ===
					`/${getCurrentCulture()}/gaming-history/games` &&
				filter.onlyWin
			) {
				params.push(
					new GamingHistoryFilterParamItem("RoundStatus", "1")
				);
			}

			let coreFilter = new CoreGamingHistoryFilter(
				accessChannelParams,
				params,
				getCurrentCulture()
			);

			requestFilter.filter = coreFilter;
			requestFilter.paging = new PagingData(
				this.pageNumber,
				this.pageSize
			);

			if (isGamingHistoryReportEnabled) {
				const reportsFilter = this.getDateRange(
					this.gamingHistoryFilterStore.rawData
				);
				if (reportsFilter != null) {
					this.gamingHistoryReport.fetchReports({
						...reportsFilter,
					});
				}
			}

			const response = await gamingHistoryService.getGamingHistoryData(
				requestFilter
			);

			runInAction(() => {
				this.gameHistoryList = response.result;

				if (
					response != null &&
					response.result != null &&
					response.result.length > 0
				) {
					this.totalItems = response.result[0].totalcnt;
				}
			});
		} catch (error) {
			runInAction(() => {
				console.error(error);
				const errorMessage = localizationService.t("ERROR.TITLE");
				App.state.rootStore.notificationStore.error(errorMessage);

				this.gameHistoryList = [];
			});
		} finally {
			this.loader.resume();
			this.isFetchingData = false;
			this.initialLoad = false;
		}
	}

	@action.bound
	async getRoundTransactions(roundId: string): Promise<void> {
		const transactionsAlreadyFetched = this.transactionsPerRound.some(
			(item) => item.roundId === roundId
		);

		if (!transactionsAlreadyFetched) {
			let requestFilter = new GamingHistoryRequestFilter();

			let shopsArray = new Array<ShopDefinition>();
			shopsArray.push(new ShopDefinition(getAgencyId(), getShopId()));

			let accessChannelParams = new AccessChannelsModel(shopsArray);

			let params = new Array<GamingHistoryFilterParamItem>();
			params.push(
				new GamingHistoryFilterParamItem("CasinoRoundId", roundId)
			);

			let coreFilter = new CoreGamingHistoryFilter(
				accessChannelParams,
				params,
				getCurrentCulture()
			);

			requestFilter.filter = coreFilter;

			const response = await gamingHistoryService.getTransactionsPerRound(
				requestFilter
			);

			if (response != null) {
				const transactionItem = new RoundTransactionData();
				transactionItem.roundId = roundId;
				transactionItem.transactions = response;

				runInAction(() => {
					this.transactionsPerRound.push(transactionItem);
				});
			}
		}
	}

	@action.bound
	refresh(): void {
		this.fetchGamingHistory(this.gamingHistoryFilterStore.filter);
	}

	@action.bound
	submitFilter(data: GamingHistoryRawFilterData): void {
		this.gamingHistoryFilterStore.setRawFilterData(data);
	}

	@action.bound
	onPageChange(newPageNumber: number): void {
		window.scrollTo(0, 0);
		this.gamingHistoryFilterStore.setPageNumber(newPageNumber);
	}

	//#endregion actions

	//#region helpers

	getDateRange(data: GamingHistoryRawFilterData): {
		from: string | null | undefined;
		to: string | null | undefined;
	} | null {
		const { period, from, to } = data;

		if (
			(period == null && from == null && to == null) ||
			period === "all"
		) {
			return null;
		}

		if (period !== "custom") {
			const timeInterval = this.betPeriods
				.find((p) => p.value === period)
				?.getTimeInterval();
			if (timeInterval == null) {
				// Return default
				return this.betPeriods[0].getTimeInterval();
			}
			return timeInterval;
		}

		return { from, to };
	}

	//#endregion helpers

	//#region disposers

	@action.bound
	onDispose(): void {
		this.gamingHistoryFilterStore.onDispose();
		this.disposeFilterReaction();

		this.isStoreInitialized = false;
		this.isFetchingData = true;

		this.disposeCurrentGamingHistoryState();
	}

	@action.bound
	disposeCurrentGamingHistoryState(): void {
		this.gameHistoryList = [];
		this.games = [];
		this.initialLoad = true;
		this.pageSize = 10;
		this.totalItems = 0;
	}

	@action.bound
	disposeFilterReaction(): void {
		if (this.filterReaction != null) {
			this.filterReaction();
			this.filterReaction = null;
		}
	}

	//#endregion disposers
}
