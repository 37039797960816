import { SportOffer } from "@gp/offer";

/**
 * Format tennis game score. It can be g0, g15, g30, g40, a
 * @todo This should be computed on event object 
 * @returns string that has only number if it starts with 'g' or 'A' if it is 'a', or "0" for nullish
 */
export function formatTennisGameScore(input?: string | number | null): string {
	input = String(input || 0);
	return input.startsWith("g") ? input.substring(1) : input.toUpperCase();
}

/**
 * @param id sport id
 * @param isLive is sport live
 * @param isOutright is sport outright
 * @returns unique sport key based on params
 */
export function getSportKeyId(
	id: string,
	isLive: boolean,
	isOutright: boolean
) {
	if (isLive) {
		return `${id}-live`;
	}

	if (isOutright) {
		return `${id}-outright`;
	}

	return id;
}
/**
 * @param sport sport for which to create unique key
 * @returns unique sport key based on sport 
 */
export function getSportOfferKey(sport: SportOffer) {
	return getSportKeyId(sport.id, sport.isLive, sport.isOutright);
}
