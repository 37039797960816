import React from "react";
import { useFormContext } from "react-hook-form";
import Button from "@components/buttons/Button";

export default function FormButton(props: Parameters<typeof Button>["0"]) {
	const {
		formState: { dirtyFields, isSubmitting, errors, isValid },
	} = useFormContext();

	const isButtonDisabled =
		Object.keys(dirtyFields).length == 0 ||
		Object.keys(errors).length != 0 ||
		isSubmitting ||
		!isValid;

	return (
		<Button {...props} isDisabled={isButtonDisabled || props.disabled} />
	);
}
