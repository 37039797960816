import React, { useEffect } from "react";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import { ChatToggleButton, ChatBox } from "@features/chat/desktop";

export default observer(function ChatContainer() {
	const { chatViewStore } = useRootAppStore();

	useEffect(() => {
		return () => chatViewStore.onDispose();
	}, []);

	return (
		<div className="chat">
			<ChatBox />
			<ChatToggleButton />
		</div>
	);
});
