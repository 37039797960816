import React, { useContext } from "react";
import { observer } from "mobx-react";

import { constants } from "@gp/offer";
import { OfferStoreContext, OfferColumnComponentProps } from "@gp/components";

import { OfferTip } from "../../buttons";
import EmptyTips from "./EmptyTips";
import { useGetColumnTipProps } from "./useGetColumnTipProps";

export const OfferColumn = observer(function OfferColumn(
	props: {
		isEmpty: boolean;
	} & OfferColumnComponentProps
) {
	const tips = props.tips;
	const offerStore = useContext(OfferStoreContext);

	if (props.isEmpty || tips == null) {
		return <EmptyTips hasFourthTip={(props.tips?.length || 0) === 4} />;
	}
	let hasFourthTip = tips.length === 4;
	const isMarginal = tips[0] === constants.marginPlaceholder;

	if (props.offerKeyId === undefined) {
		return (
			<EmptyTips isMarginal={isMarginal} hasFourthTip={hasFourthTip} />
		);
	}

	const offerMap = offerStore.keyOffersMap.get(props.offerKeyId);

	if (offerMap == undefined || offerMap.size === 0) {
		return (
			<EmptyTips isMarginal={isMarginal} hasFourthTip={hasFourthTip} />
		);
	}

	const formattedTips = useGetColumnTipProps(
		offerStore,
		offerMap,
		tips,
		isMarginal,
		hasFourthTip
	);

	return (
		<div className="offer__body__data offer__quotes">
			<OfferTip {...formattedTips.leftTip} />
			<OfferTip {...formattedTips.middleTip} />
			<OfferTip {...formattedTips.rightTip} />
			{hasFourthTip && <OfferTip tip={formattedTips.farRightTip} />}
		</div>
	);
});
