import { getTopLevelDomain } from "@utils";
import { isCapacitorPlatform } from "@utils/specific/capacitor";
import { useTranslation } from "react-i18next";

export const useGenerateNecessaryCookiesList = () => {
	const { t } = useTranslation();
	const getGigCookieUrl = getTopLevelDomain(window.location.href);

	const cookies: { [key: string]: string }[] = [];
	if (WEBPACK_IS_GIG_ENABLED && !isCapacitorPlatform()) {
		cookies.push({
			name: "cgp-token",
			domain: getGigCookieUrl,
			expiration: t("COOKIE.PREFERENCES.NECESSARY_COOKIES.SESSION"),
			desc: t(
				"COOKIE.PREFERENCES.NECESSARY_COOKIES.TABLE_BODY_GIG.DESCRIPTION"
			),
		});
	}
	cookies.push({
		name: "cc_cookie",
		domain: location.hostname,
		expiration: "182 " + t("COOKIE.TABLE_BODY.EXPIRATION"),
		desc: t(
			"COOKIE.PREFERENCES.NECESSARY_COOKIES.TABLE_BODY_CC.DESCRIPTION"
		),
	});
	cookies.push({
		name: "lng",
		domain: location.hostname,
		expiration: "365 " + t("COOKIE.TABLE_BODY.EXPIRATION"),
		desc: t(
			"COOKIE.PREFERENCES.NECESSARY_COOKIES.TABLE_BODY_LNG.DESCRIPTION"
		),
	});

	return cookies;
};
