import React, { useRef, useMemo, useEffect } from "react";
import { IConsoleLogger } from "@interface";
import { Modal } from "@lib/modal";

/** Floating container that can be moved on the screen and resized. */
export function DraggableContainer(props: {
	children: JSX.Element;
	isDraggable?: boolean;
	resize?: "none" | "both" | "horizontal" | "vertical" | "block" | "inline";
	/** If provided drag works only on this element. If it is not child drag will not work correctly. */
	draggableElement?: HTMLElement | null;
	/** Initial css styles. */
	initialCss?: React.CSSProperties;
	onPositionUpdate?: (
		draggableContainer: HTMLElement | null | undefined
	) => void;
	logger: IConsoleLogger;
}) {
	const divRef = useRef<HTMLDivElement | null>(null);
	const mouseDownEvent = useRef<{
		x: number;
		y: number;
		target: HTMLElement;
	} | null>(null);

	const styles = {
		resize: props.resize || "none",
		...props.initialCss,
	};

	const handleMouseMove = useMemo(
		() =>
			(e: MouseEvent): void => {
				if (
					e.buttons !== 1 ||
					e.type !== "mousemove" ||
					divRef.current == null ||
					mouseDownEvent.current == null
				) {
					return;
				}

				window.requestAnimationFrame(() => {
					if (
						divRef.current == null ||
						mouseDownEvent.current == null
					) {
						return;
					}

					divRef.current.style.left =
						e.clientX - mouseDownEvent.current.x + "px";

					divRef.current.style.top =
						e.clientY - mouseDownEvent.current.y + "px";

					props.onPositionUpdate?.(divRef.current);
				});
			},
		[]
	);

	const handleMouseUp = useMemo(
		() =>
			(e: MouseEvent): void => {
				mouseDownEvent.current = null;
				props.onPositionUpdate?.(divRef.current);
			},
		[]
	);

	useEffect(() => {
		window.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("mouseup", handleMouseUp);
		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
		};
	});

	if (
		!props.isDraggable &&
		(props.resize != null || props.resize == "none")
	) {
		return <>{props.children}</>;
	}

	return (
		<Modal logger={props.logger}>
			<div
				ref={divRef}
				className="popup--player"
				style={styles as React.CSSProperties}
				onMouseDown={(e) => {
					if (
						!(e.target instanceof Element) ||
						!divRef.current?.contains(e.target) ||
						(props.draggableElement != null &&
							e.target !== props.draggableElement)
					) {
						return;
					}

					e.preventDefault();
					const rect = e.target.getBoundingClientRect();
					const x = e.clientX - rect.left;
					const y = e.clientY - rect.top;
					mouseDownEvent.current = {
						x,
						y,
						target: e.target as HTMLElement,
					};
				}}
			>
				{props.children}
			</div>
		</Modal>
	);
}
