import React from "react";
import { observer } from "mobx-react";
import { EventKeyOffer, MainOfferStore } from "@gp/offer";
import { EventOutrightOfferRow } from "./";
import { EventKeyBettingOffer } from "@gp/models";

export const EventOutrightOfferBettingTypeRow = observer(
	function EventOutrightOfferBettingTypeRow(props: {
		bettingType: EventKeyOffer;
		offerStore: MainOfferStore;
	}) {
		const offers = Array.from(
			props.offerStore.keyOffersMap.get(props.bettingType.id)?.values() ||
				[]
		).sort(function (a: EventKeyBettingOffer, b: EventKeyBettingOffer) {
			if (a.value === b.value) {
				return 0;
			}

			return a.value - b.value;
		}) as EventKeyBettingOffer[];

		return (
			<EventOutrightOfferRow
				eventKey={props.bettingType}
				offers={offers}
			/>
		);
	}
);
