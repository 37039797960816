import React from "react";
import { useLoading } from "@hooks";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isLoginFlow } from "@v2/helpers/membership/utils";
import { useLocation } from "react-router-dom";
import { bottom, top } from "safe-area-insets";
import { isCapacitorPlatform } from "@utils/specific/capacitor";

export default observer(function Loader(props: {
	className?: string;
	isLoading?: boolean;
}) {
	const { isLoading } = useLoading();
	const { pathname } = useLocation();

	// loader loader--menu
	const loaderClasses = classNames("loader", props.className, {
		"loader--full": isLoginFlow(pathname),
	});

	if (!isLoading && !props?.isLoading) {
		return null;
	}
	
	const loaderPadding = (isCapacitorPlatform() && !isLoginFlow(pathname)) ? { top: top + 48, bottom: bottom + 54 } : {};

	return (
		<div className={loaderClasses} style={loaderPadding}>
			<div className="loader__spinner">
				<div className="loader__spinner__wrapper">
					<div />
					<div />
					<div />
				</div>
			</div>
		</div>
	);
});
