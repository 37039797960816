export class DeviceDetector {
	static get isMobileTheme() {
		return WEBPACK_ENVIRONMENT_KEY === "MobileWeb";
	}

	static get isDesktopTheme() {
		return WEBPACK_ENVIRONMENT_KEY === "Web";
	}

	static get isMobileApp() {
		return navigator.userAgent.includes(AndroidAppUserAgent);
	}

	static get isIOS() {
		return (
			navigator.userAgent.includes("iPhone") ||
			navigator.userAgent.includes("iPad") ||
			navigator.userAgent.includes("iPod")
		);
	}

	static get isAndroid() {
		return navigator.userAgent.includes("Android");
	}

	static get isDesktopChrome() {
		return (
			navigator.userAgent.indexOf("Safari") > -1 &&
			navigator.userAgent.includes("Chrome")
		);
	}

	static get isDesktopSafari() {
		return (
			navigator.userAgent.indexOf("Safari") > -1 &&
			!navigator.userAgent.includes("Chrome")
		);
	}

	static get isDesktopFirefox() {
		return navigator.userAgent.indexOf("Firefox") > -1;
	}

	static get isDesktopEdge() {
		return navigator.userAgent.indexOf("Edg") > -1;
	}

	static get isDesktopOpera() {
		return (
			navigator.userAgent.indexOf("OPR") > -1 ||
			navigator.userAgent.indexOf("Opera") > -1
		);
	}
}

{
	/*

if (this.ua.includes("Firefox")) {
	// "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
	return "Mozilla Firefox";
} else if (this.ua.includes("SamsungBrowser")) {
	// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
	return "Samsung Internet";
} else if (this.ua.includes("Opera") || this.ua.includes("OPR")) {
	// "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
	return "Opera";
} else if (this.ua.includes("Edge")) {
	// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
	return "Microsoft Edge (Legacy)";
} else if (this.ua.includes("Edg")) {
	// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
	return "Microsoft Edge (Chromium)";
} else if (this.ua.includes("Chrome")) {
	// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
	return "Google Chrome or Chromium";
} else if (this.ua.includes("Safari")) {
	// "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
	return "Apple Safari";
} else {
	return "unknown";
}

*/
}
