import { useEffect, useRef } from "react";
import { getCurrentCulture } from "@utils";
import {
	LMTWidgetElement,
	WidgetLocation,
} from "@features/live-widgets/shared/models/LiveWidgetTypes";
import { BrWidgetDefaultConfig } from "../config";
import { useLiveWidgetStore } from "@state/hooks/live-widgets";

let language = "";
/** @returns  language that is */
function getCurrentLanguageForSIR(): string {
	let lang = getCurrentCulture();

	if (lang === "sq") {
		lang = "sqi";
	} else if (lang === "sv") {
		lang = "se";
	}
	language = lang;
	return lang;
}

export function useBRContainerCycles(
	ref: HTMLDivElement | null,
	eventId: string,
	location: WidgetLocation
): boolean {
	const store = useLiveWidgetStore();

	// On component will unmount
	useEffect(() => {
		return () => {
			if (window.SIR == undefined) {
				store.logger.logInfo(
					"LmtContainerUnmount without SIR existing."
				);
				return;
			}
			if (ref != null) {
				const sirResult = SIR("removeWidget", ref);
				store.logger.logInfo(
					`LmtWidgetRemoveOnComponentUnmount: ${sirResult}; eventId: ${eventId}; location: ${location}`
				);
				return;
			}

			store.logger.logWarn(
				"Remove widget on LMTContainer unmount failed."
			);
		};
	}, [ref]);

	const mountedWidget = useRef<LMTWidgetElement | null>();

	if (window.SIR == undefined) {
		// We are not mounted if it is undefined
		return false;
	}

	const myWidget = store.activeLmtWidgets?.find(
		(w) =>
			(eventId == "" && location === "SIDE" && w.location === "SIDE") ||
			(w.eventId === eventId && w.location === location)
	);

	if (myWidget?.isError) {
		// an error occurred on widget initialization; execute fallback
		return false;
	}

	// This is initial add.
	if (ref != null && myWidget != null && mountedWidget.current == null) {
		if (language !== getCurrentLanguageForSIR()) {
			SIR?.("changeLanguage", getCurrentLanguageForSIR());
		}

		const lmtOptions = Object.assign(
			{},
			BrWidgetDefaultConfig,
			myWidget.BRWidgetOptions,
			{ matchId: myWidget.matchId }
		);

		const sirResult = SIR(
			"addWidget",
			ref,
			"match.lmtPlus",
			lmtOptions,
			() => {
				store.logger.logInfo("LMT Loaded");
			}
		);

		store.logger.logInfo(
			`LmtWidgetAdd: ${sirResult}; eventId: ${eventId}; location: ${location}`
		);

		mountedWidget.current = { ...myWidget };
		// We are mounted
		return true;
	}

	// This is widget update.
	if (ref != null && myWidget != null && mountedWidget.current != null) {
		const lmtOptions = Object.assign(
			{},
			BrWidgetDefaultConfig,
			myWidget.BRWidgetOptions,
			{ matchId: myWidget.matchId }
		);

		const sirResult = SIR("updateWidget", ref, lmtOptions, () => {});
		store.logger.logInfo(
			`LmtWidgetUpdate: ${sirResult}; eventId: ${eventId}; location: ${location}`
		);

		mountedWidget.current = { ...myWidget };
		// We are mounted
		return true;
	}

	// This is widget removed.
	if (ref != null && myWidget == null && mountedWidget.current != null) {
		const sirResult = SIR("removeWidget", ref);
		store.logger.logInfo(
			`LmtWidgetRemove: ${sirResult}; eventId: ${eventId}; location: ${location}`
		);
		mountedWidget.current = null;
		// We are not mounted
		return false;
	}

	return mountedWidget.current != null || myWidget != null;
}
