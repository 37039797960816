import { action, observable, runInAction } from "mobx";
import { BasePopupStore } from ".";
import { isUserAuthenticated, getCurrentCulture } from "@utils";
import { StorageStateKeysEnum } from "@utils/storage";

class SessionExpireStore extends BasePopupStore {
	@observable isGameOpen: boolean = false;

	constructor(rootStore: typeof App.state.rootStore) {
		super(rootStore);
		window.addEventListener("storage", (e) => {
			if (e.key === "session-expiration-time") {
				// @ts-expect-error
				this.startTimer(DefaultSessionDuration, true);
			}
		});

		document.addEventListener("click", this.onClick);
	}

    shouldStartTimer() {
        return (
            this.rootStore.userAuthStore.user != null &&
            // @ts-expect-error
            DefaultSessionDuration != null &&
            // @ts-expect-error
            !isNaN(DefaultSessionDuration) &&
            !this.rootStore.offerHub.isSessionExpired &&
            !this.rootStore.realityCheckStore.isActive &&
            !this.rootStore.automaticLogoutStore.isActive &&
            !this.rootStore.isDepositLimitUpdatePopupActive
        );
    }

	onInitialize() {
		this.isGameOpen =
			this.rootStore.localStorageProvider.get(StorageStateKeysEnum.GS) ===
			"true";
		if (this.shouldStartTimer()) {
			// @ts-expect-error
			this.startTimer(DefaultSessionDuration);
		}
	}

    @action.bound
    startTimer(expiresIn: number, receivedMessage = false) {
        this.clearTimer();
        const currentDate = new Date();

		const sessionExpirationTime = currentDate.getTime() + expiresIn * 1000;
		if (
			this.rootStore.localStorageProvider.get(
				StorageStateKeysEnum.SESSION_EXPIRATION_TIME
			) == null ||
			!receivedMessage
		) {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.SESSION_EXPIRATION_TIME,
				sessionExpirationTime
			);
		}

		this.timerId = setTimeout(() => {
			runInAction(async () => {
				if (
					this.rootStore.userAuthStore.isLoggedIn &&
					this.rootStore.localStorageProvider.get(
						StorageStateKeysEnum.SLN
					) !== "true" &&
					!this.rootStore.automaticLogoutStore.isActive
				) {
					if (this.isGameOpen) {
						return;
					}

					const newCurrentDate = new Date();
					const localStorageSessionExpiryTime =
						this.rootStore.localStorageProvider.get(
							StorageStateKeysEnum.SESSION_EXPIRATION_TIME
						) as unknown as number;

                    if (newCurrentDate.getTime() <= localStorageSessionExpiryTime) {
                        return;
                    }

                    // redirect after session expire, don't show popup
                    // logout user
                    const url = window.location.pathname;
                    const returnUrl = url.substring(3);
                    await App.state.rootStore.userAuthStore.logoutUser();
                    if (!App.state.rootStore.automaticLogoutStore.isActive) {
                        App.state.rootStore.closePopups();
                        App.state.rootStore.resetPopups();
                        App.state.redirect(
                            `/${getCurrentCulture()}/auth/login?isSessionExpired=true&returnUrl=${returnUrl}`
                        );
                    }
                }
            });
        }, expiresIn * 1000);
    }

    onClick = () => {
        const isLiveOfferPathnameActive = App.state.history.location.pathname.includes(`/${getCurrentCulture()}/live`);

        if (
            this.rootStore.userAuthStore.user != null &&
            !isLiveOfferPathnameActive &&
            !App.state.rootStore.offerHub.isSessionExpired &&
            !App.state.rootStore.realityCheckStore.isActive &&
            !App.state.rootStore.automaticLogoutStore.isActive
        ) {
            // @ts-expect-error
            this.startTimer(DefaultSessionDuration);
        }
    };

	setIsGameOpened(state: boolean): void {
		this.isGameOpen = state;
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.GS,
			state ? "true" : "false"
		);
	}
}

export default SessionExpireStore;
