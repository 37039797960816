import {
	isAccountVerificationSectionEnabled,
	isAutomaticLogoutEnabled,
} from "@v2/helpers";
import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../../../../ui/common/hooks";
import classNames from "classnames";
import { RootAppStoreContext } from "@context";
import { UserTypes, getUserFromLocalStorage } from "@utils";
import { AccountVerificationStatus } from "@api-types/user/LoginResponseDto";

export default observer(function AccountSettingsSideMenu() {
	const { culture } = useAppContext();
	const { t } = useTranslation();
	const { userAuthStore } = useContext(RootAppStoreContext);
	const IsOasisLockEnabled = userAuthStore.user?.isOasisEnabled;

	const getOnlineUserIfExists = userAuthStore.isInternetUser
		? getUserFromLocalStorage(
				userAuthStore.isPrimaryUserOnline == true
					? UserTypes.PRIMARY
					: UserTypes.SECONDARY
		  )
		: null;
	return (
		<nav className="nav--tertiary">
			<ul className="nav--tertiary__menu">
				<li className="nav--tertiary__item">
					<NavLink
						to={`/${culture}/account-settings/personal-data`}
						className="nav--tertiary__link"
						activeClassName="is-active"
						isActive={(match, location) => {
							if (
								location.pathname ==
									`/${culture}/account-settings/personal-data` ||
								location.pathname ==
									`/${culture}/account-settings`
							) {
								return true;
							} else if (match) {
								return true;
							}
							return false;
						}}
					>
						<span className="nav--tertiary__title">
							{t(
								"USER.ACCOUNT_SETTINGS.NAVIGATION.PERSONAL_DATA"
							)}
						</span>
					</NavLink>
				</li>
				{App.state.rootStore.userAuthStore.isInternetUser && (
					<li className="nav--tertiary__item">
						<NavLink
							to={`/${culture}/account-settings/account-verification`}
							className="nav--tertiary__link"
							activeClassName="is-active"
							isActive={(match, location) =>
								location.pathname.includes(
									"account-verification"
								)
							}
						>
							<span className="nav--tertiary__title">
								{t("HEADER.ACCOUNT_VERIFICATION")}
							</span>
							{isAccountVerificationSectionEnabled && (
								<i
									className={classNames(
										"u-icon u-icon--sml",
										{
											"u-icon--alert-circle--bold u-color--ui--warning":
												getOnlineUserIfExists?.accountVerificationStatus !==
												AccountVerificationStatus.FullyVerified,
											"u-icon--check-circle--bold u-color--ui--success":
												getOnlineUserIfExists?.accountVerificationStatus ==
												AccountVerificationStatus.FullyVerified,
										}
									)}
								/>
							)}
						</NavLink>
					</li>
				)}
				<li className="nav--tertiary__item">
					<NavLink
						to={`/${culture}/account-settings/password-change`}
						className="nav--tertiary__link"
						activeClassName="is-active"
						isActive={(_, location) =>
							location.pathname.includes(
								`/${culture}/account-settings/password-change`
							)
						}
					>
						<span className="nav--tertiary__title">
							{t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_PC")}
						</span>
					</NavLink>
				</li>
				<li className="nav--tertiary__item">
					<NavLink
						to={`/${culture}/account-settings/email-change`}
						className="nav--tertiary__link"
						activeClassName="is-active"
						isActive={(_, location) =>
							location.pathname.includes(
								`/${culture}/account-settings/email-change`
							)
						}
					>
						<span className="nav--tertiary__title">
							{t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_EC")}
						</span>
					</NavLink>
				</li>

				<li className="nav--tertiary__item">
					<NavLink
						to={`/${culture}/account-settings/my-limits`}
						className="nav--tertiary__link"
						activeClassName="is-active"
						isActive={(_, location) =>
							location.pathname.includes(
								`/${culture}/account-settings/my-limits`
							)
						}
					>
						<span className="nav--tertiary__title">
							{t("USER.ACCOUNT_SETTINGS.NAVIGATION.MY_LIMITS")}
						</span>
					</NavLink>
				</li>

				<li className="nav--tertiary__item">
					<NavLink
						to={`/${culture}/account-settings/limits-history`}
						className="nav--tertiary__link"
						activeClassName="is-active"
						isActive={(match, location) =>
							location.pathname.includes(
								`/${culture}/account-settings/limits-history`
							)
						}
					>
						<span className="nav--tertiary__title">
							{t(
								"USER.ACCOUNT_SETTINGS.NAVIGATION.LIMITS_HISTORY"
							)}
						</span>
					</NavLink>
				</li>

				{userAuthStore.isInternetUser &&
				userAuthStore.isAutoWithdrawalEnabled ? (
					<li className="nav--tertiary__item">
						<NavLink
							to={`/${culture}/account-settings/automatic-withdrawal`}
							className="nav--tertiary__link"
							activeClassName="is-active"
							isActive={(_, location) =>
								location.pathname.includes(
									`/${culture}/account-settings/automatic-withdrawal`
								)
							}
						>
							<span className="nav--tertiary__title">
								{t(
									"USER.ACCOUNT_SETTINGS.NAVIGATION.AUTOMATIC_WITHDRAWAL"
								)}
							</span>
						</NavLink>
					</li>
				) : null}

				{IsOasisLockEnabled ? (
					<li className="nav--tertiary__item">
						<NavLink
							to={`/${culture}/account-settings/account-lock`}
							className="nav--tertiary__link"
							activeClassName="is-active"
							isActive={(_, location) =>
								location.pathname.includes(
									`/${culture}/account-settings/account-lock`
								)
							}
						>
							<span className="nav--tertiary__title">
								{t(
									"USER.ACCOUNT_SETTINGS.NAVIGATION.ACCOUNT_CLOSURE_OASIS"
								)}
							</span>
						</NavLink>
					</li>
				) : (
					<li className="nav--tertiary__item">
						<NavLink
							to={`/${culture}/account-settings/account-lock`}
							className="nav--tertiary__link"
							activeClassName="is-active"
							isActive={(_, location) =>
								location.pathname.includes(
									`/${culture}/account-settings/account-lock`
								)
							}
						>
							<span className="nav--tertiary__title">
								{t(
									"USER.ACCOUNT_SETTINGS.NAVIGATION.ACCOUNT_LOCK"
								)}
							</span>
						</NavLink>
					</li>
				)}

				{isAutomaticLogoutEnabled ? (
					<li className="nav--tertiary__item">
						<NavLink
							to={`/${culture}/account-settings/automatic-logout`}
							className="nav--tertiary__link"
							activeClassName="is-active"
							isActive={(_, location) =>
								location.pathname.includes(
									`/${culture}/account-settings/automatic-logout`
								)
							}
						>
							<span className="nav--tertiary__title">
								{t(
									"USER.ACCOUNT_SETTINGS.NAVIGATION.AUTOMATIC_LOGOUT"
								)}
							</span>
						</NavLink>
					</li>
				) : null}

				<li className="nav--tertiary__item">
					<NavLink
						to={`/${culture}/account-settings/my-bonuses`}
						className="nav--tertiary__link"
						activeClassName="is-active"
						isActive={(_, location) =>
							location.pathname.includes(
								`/${culture}/account-settings/my-bonuses`
							)
						}
					>
						<span className="nav--tertiary__title">
							{t("USER.ACCOUNT_SETTINGS.NAVIGATION.MY_BONUSES")}
						</span>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
});
