import { localizationService } from "@state";
import { runInAction } from "mobx";

export function registrationValidationSingle(errorObject: any) {
	let errorMessage: string;

	switch (errorObject?.errorCode) {
		case 400101:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.ACCOUNT_ALREADY_EXISTS"
			);
			break;
		case 400165:
			errorMessage = localizationService.t(
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING.ERROR_PWD_CHANGE_SIMILAR"
			);
			break;
		case 400198:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.LIMIT_OUT_OF_RANGE"
			);
			break;
		case 400216:
			errorMessage = localizationService.t(
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING.USER_REGISTRATION_BLOCKED"
			);
			break;
		case 400901:
			errorMessage = localizationService.t(
				"MEMBERSHIP.ERR_HANDLING.CPR_NUMBER_NOT_REGISTERED"
			);
			break;
		case 400902:
			errorMessage = localizationService.t(
				"MEMBERSHIP.ERR_HANDLING.CPR_NUMBER_VERIFICATION_ERROR"
			);
			break;
		case 400907:
			errorMessage = localizationService.t(
				"MEMBERSHIP.ERR_HANDLING.PLAYER_TOO_YOUNG"
			);
			break;
		case 400908:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.REGISTER_EXCLUSION_CHECK_ERROR"
			);
			break;
		case 400909:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.REGISTER_INDEFINITE_EXCLUSION"
			);
			break;
		case 400910:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.REGISTER_TEMPORARY_EXCLUSION"
			);
			break;
		case 400912:
			errorMessage = localizationService.t(
				"MEMBERSHIP.ERR_HANDLING.INVALID_CPR_NUMBER"
			);
			break;
		case 400229:
			errorMessage = localizationService.t(
				"MEMBERSHIP.ERR_HANDLING.SPELPAUS_MARKETING_EXCLUSION_ACTIVE"
			);
			break;
		case 400230:
			errorMessage = localizationService.t(
				"MEMBERSHIP.ERR_HANDLING.SPELPAUS_USER_BLOCKED_FROM_PLAYING"
			);
			break;
		case 400242:
			errorMessage = localizationService.t(
				"MEMBERSHIP.ERR_HANDLING.LUGAS_VALIDATION_ERROR"
			);
			break;
		case 400256:
			errorMessage = localizationService.t(
				"MEMBERSHIP.ERR_HANDLING.LUGAS_UNSPECIFIED"
			);
			break;
		case 400247:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.LUGAS_PLAYER_ALREADY_REGISTERED"
			);
			break;
		case 400248:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.LUGAS_DUPLICATE_ID"
			);
			break;
		case 400249:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.LUGAS_LIMIT_NOT_SPECIFIED"
			);
			break;
		case 400124:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.ACCOUNT_ALREADY_EXISTS"
			);
			break;
		case 400197:
			errorMessage = localizationService.t(
				"MEMBERSHIP.LOGIN.OASIS_GAMING_EXCLUSION"
			);
			break;
		default:
			errorMessage = localizationService.t(
				"MEMBERSHIP.REGISTRATION.GENERIC_ERROR"
			);
			break;
	}

	App.state.rootStore.notificationStore.error(errorMessage);
}

export function registrationValidationMultiple(
	errorObject: any,
	setFn: (value: string) => void
) {
	let errorInfoMessage: string;
	let registrationErrorMessage: string;

	switch (errorObject?.errorCode) {
		//check what error messages should display in client
		case 400101:
			registrationErrorMessage =
				"MEMBERSHIP.REGISTRATION.ACCOUNT_ALREADY_EXISTS";
			break;
		case 400165:
			errorInfoMessage =
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING.ERROR_PWD_CHANGE_SIMILAR";
			break;
		case 400198:
			errorInfoMessage = "MEMBERSHIP.REGISTRATION.LIMIT_OUT_OF_RANGE";
			break;
		case 400216:
			registrationErrorMessage =
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING.USER_REGISTRATION_BLOCKED";
			break;
		case 400901:
			registrationErrorMessage =
				"MEMBERSHIP.ERR_HANDLING.CPR_NUMBER_NOT_REGISTERED";
			break;
		case 400902:
			registrationErrorMessage =
				"MEMBERSHIP.ERR_HANDLING.CPR_NUMBER_VERIFICATION_ERROR";
			break;
		case 400907:
			errorInfoMessage = "MEMBERSHIP.ERR_HANDLING.PLAYER_TOO_YOUNG";
			break;
		case 400908:
			registrationErrorMessage =
				"MEMBERSHIP.REGISTRATION.REGISTER_EXCLUSION_CHECK_ERROR";
			break;
		case 400909:
			registrationErrorMessage =
				"MEMBERSHIP.REGISTRATION.REGISTER_INDEFINITE_EXCLUSION";
			break;
		case 400910:
			registrationErrorMessage =
				"MEMBERSHIP.REGISTRATION.REGISTER_TEMPORARY_EXCLUSION";
			break;
		case 400911:
			registrationErrorMessage =
				"specific:MEMBERSHIP.MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.NO_CPR_NR_PROVIDED";
			break;
		case 400912:
			registrationErrorMessage =
				"MEMBERSHIP.ERR_HANDLING.INVALID_CPR_NUMBER";
			break;
		//registration_with_mitId_error_codes
		case 400136:
			registrationErrorMessage =
				"MEMBERSHIP.REGISTRATION.ACCOUNT_ALREADY_EXISTS";
			break;
		case 400125:
			registrationErrorMessage =
				"MEMBERSHIP.REGISTRATION.ACCOUNT_ALREADY_EXISTS";
			break;
		case 400182:
			//TODO:
			registrationErrorMessage =
				"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.NOT_A_NON_DEPO_CARD";
			break;
		case 400124:
			registrationErrorMessage =
				"MEMBERSHIP.REGISTRATION.ACCOUNT_ALREADY_EXISTS";
			break;
		default:
			registrationErrorMessage = "MEMBERSHIP.REGISTRATION.GENERIC_ERROR";
			break;
		//rest
		/*
					401 redirects user to login page
					unauth - ne pošalješ mi personal identificator
					unauth - matchaš usera s postojećim onlineom koji je u drugoj kompaniji
					unauth - gaming limiti ti nisu u rangeu
				*/
	}
	runInAction(() => {
		if (registrationErrorMessage) {
			//error should stop registration process (e.g. cpr already used, username taken, card invalid, ...)
			setFn(registrationErrorMessage);
		} else {
			//user can go back to reg form and change values(e.g. password, birthdate, ...)
			App.state.rootStore.notificationStore.error(
				localizationService.t(errorInfoMessage)
			);
		}
	});
}
