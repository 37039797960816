import React, { useContext } from "react";
import { observer } from "mobx-react";
import { EventContext, OfferStoreContext } from "@gp/components";
import { BettingTypeGroup } from "./";

export const BettingTypeGroups = observer(function BettingTypeGroups(props: {
	selectedGroup: string;
}) {
	const { selectedGroup } = props;
	// @ts-expect-error
	const { bettingTypeGroups, id } = useContext(EventContext);
	// @ts-expect-error
	const { favoritesBettingTypeGroups } = useContext(OfferStoreContext);
	if (selectedGroup == "favorites") {
		return (
			<>
				{
					// @ts-expect-error
					favoritesBettingTypeGroups[id].map((group) => (
						<BettingTypeGroup
							key={group.abrv}
							group={group}
							isFavorite
						/>
					))
				}
			</>
		);
	}
	if (selectedGroup !== "all") {
		// @ts-expect-error
		const group = bettingTypeGroups.find((g) => g.abrv === selectedGroup);
		return <BettingTypeGroup key={group.abrv} group={group} />;
	}

	// @ts-expect-error
	return bettingTypeGroups.map((group) => (
		<BettingTypeGroup key={group.abrv} group={group} />
	));
});
