import { LookupDto } from "@api-types";
import {
	RegionDataType,
	LimitDurationDataType,
} from "@data-types/membership/registration";
import { httpClient, getApplicationHeaders } from "@utils";

class RegistrationLookupService {
	getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type"] = "application/json";
		return appHeaders;
	};

	findTitles() {
		const url = `${WEBPACK_BASE_ADDRESS}platform/user-titles?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	findCountries() {
		const url = `${WEBPACK_BASE_ADDRESS}platform/countries?sort=name|asc&fields=id,name,nationality,abrv&rpp=1000&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	findOccupations() {
		//sort=name|asc&fields=id,name&
		const url = `${WEBPACK_BASE_ADDRESS}platform/occupations?rpp=1000&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	findOccupationGroups() {
		//sort=name|asc&fields=id,name&
		const url = `${WEBPACK_BASE_ADDRESS}platform/occupation-groups?rpp=1000&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	//filter languages by supported
	findLanguages(isos: string) {
		const url = `${WEBPACK_BASE_ADDRESS}platform/languages?sort=name|asc&rpp=1000&translate=true&isos=${isos}`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	findCountryRegions(): LookupDto<RegionDataType> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/country-regions?sort=name|asc&rpp=1000&translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	findLimitDurations(): Promise<{ item: LimitDurationDataType[] }> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/platform-gaming-limit-durations?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}
}

export default RegistrationLookupService;
