import React from "react";
import { useTranslation } from "react-i18next";
import { DeviceDetector, getCurrentCulture } from "@utils";

export function WorldCup(props: { isSlider?: boolean }) {
	const { t } = useTranslation();

	const { isSlider } = props;

	// eslint-disable-next-line no-undef
	if (WEBPACK_WORLD_CUP_LINK == null || WEBPACK_WORLD_CUP_LINK === "") {
		return null;
	}

	const year = new Date().getFullYear();
	const currentCulture = getCurrentCulture();
	// eslint-disable-next-line no-undef
	const link = `${WEBPACK_WORLD_CUP_LINK}${
		currentCulture === "sq"
			? "sqi"
			: currentCulture === "sv"
			? "se"
			: currentCulture
	}`;

	if (DeviceDetector.isMobileTheme && isSlider) {
		return (
			<div className="carousel__item">
				<div
					onClick={() =>
						App.state.addToUrlParams({ isWorldCupOpen: true })
					}
					className="carousel__slide"
				>
					<CarouselMedia imgClassExtension="fifa2022" />

					<div className="carousel__content">
						<div className="carousel__subtitle">{year}</div>
						<div className="carousel__title">
							{t("WORLD_CUP.TITLE")}
						</div>
						<CarouselItemButton
							buttonText={t("STATISTICS.TITLE")}
						/>
					</div>
				</div>
			</div>
		);
	}

	if (isSlider) {
		return (
			<a
				href={link}
				target="_blank"
				className="carousel__slide"
				title={t<string>("WORLD_CUP.TITLE")}
			>
				<img
					className="carousel__img "
					src={`../assets/images/carousel/carousel--fifa.png`}
				/>
				<div className="carousel__content">
					<div className="carousel__subtitle">{year}</div>
					<div className="carousel__title">
						{t("WORLD_CUP.TITLE")}
					</div>
					<CarouselItemButton buttonText={t("STATISTICS.TITLE")} />
				</div>
			</a>
		);
	}

	return (
		<div className="widget widget--stats">
			<div className="widget__banner">
				<a
					href={link}
					className="widget__banner__link"
					target="_blank"
					title="FIFA World Cup 2022"
				>
					<i className="widget__banner__icon u-icon u-icon--huge u-icon--soccer--international-clubs--fifa-world-cup" />
					<div className="widget__banner__content">
						<div className="widget__banner__tag">
							<div className="tag tag--med tag--sunny">{`${new Date().getFullYear()}`}</div>
						</div>
						<div className="widget__banner__title">{`${t(
							"WORLD_CUP.TITLE"
						)}`}</div>
					</div>
				</a>
			</div>
		</div>
	);
}

function CarouselMedia(props: { imgClassExtension: string }) {
	const { imgClassExtension } = props;

	const imgClass = "carousel__img  carousel__img--" + imgClassExtension;

	return (
		<div className="carousel__media">
			<i className={imgClass} />
		</div>
	);
}

function CarouselItemButton(props: { buttonText: string }) {
	return (
		<div
			className={
				DeviceDetector.isMobileTheme
					? "carousel__btn btn btn--micro btn--neutral--inverted u-type--xxsml"
					: "carousel__btn btn btn--tny btn--neutral--inverted u-type--xsml"
			}
		>
			{props.buttonText}
			<i className="u-icon u-icon--tny u-icon--arrow--right u-color--core--snow" />
		</div>
	);
}
