import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

interface ThemeToggleButtonProps {
	rounded?: boolean;
}

function ThemeToggleButton({ rounded }: ThemeToggleButtonProps) {
    const { t } = useTranslation();

	const { isLightTheme, toggleTheme } = App.state.rootStore.themeStore;

	const toggleThemeStatus = () => {
		toggleTheme();
	};

	const toggleClassName = classnames(
		"toggle__btn"
	);

	return (
		<div className="toggle">
			<input
				id="theme"
				type="checkbox"
				className="toggle__input"
				onChange={toggleThemeStatus}
				checked={!isLightTheme}
			/>
			<label
				htmlFor="theme"
				className={toggleClassName}
                data-label-light={t<string>("HEADER.THEME_LABEL_LIGHT")}
                data-label-dark={t<string>("HEADER.THEME_LABEL_DARK")}
			/>
		</div>
	);
}

export default observer(ThemeToggleButton);