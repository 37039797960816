import React, { useEffect, useState } from "react";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";
import { getCurrentCulture } from "@utils";

import { WidgetLocation } from "../models";
import { LsWidgetConfigurationIds } from "../config";

export function useLSContainerCycles(
	widgetRef: React.RefObject<HTMLDivElement>,
	eventId: string,
	location: WidgetLocation
) {
	const store = useLiveWidgetStore();
	const [isError, setError] = useState<boolean>();

	const activeWidget = store.activeLmtWidgets?.find(
		(w) =>
			(eventId == "" && location === "SIDE" && w.location === "SIDE") ||
			(w.eventId === eventId && w.location === location)
	);

	let widget: STATSCOREWidgets.Widget | undefined;

	useEffect(() => {
		// on widget script reference load
		window.STATSCOREWidgets.onLoad((err) => {
			setError(false);
			if (!activeWidget) {
				return;
			}

			if (err) {
				store.logger.logError(
					`STATSCORE: Error on init; type: ${err.type}`
				);
				setError(true);
				return;
			}

			let language: string = getCurrentCulture();

			if (language === "sq") {
				language = WEBPACK_DEFAULT_CULTURE;
			}

			const inputData: LSParams = {
				eventId: `m:${activeWidget?.matchId}`,
				language,
			};

			// on widget init
			if (activeWidget.sport) {
				const initializedWidget = new window.STATSCOREWidgets.Widget(
					widgetRef.current,
					LsWidgetConfigurationIds[activeWidget.sport],
					inputData,
					activeWidget?.LSWidgetOptions
				);

				// on widget init error; fallback will be rendered
				initializedWidget.on("error", (e: any) => {
					if (widgetRef.current) widgetRef.current.innerText = "";
					store.logger.logError(
						"STATSCORE: Widget failed to load:",
						e
					);
					setError(true);
					return;
				});

				// on widget mount
				initializedWidget.on("mount", () => {
					widget = initializedWidget;
				});
			}
		});

		return () => {
			if (widget === undefined) {
				store.logger.logWarn(
					"STATSCORE: unmount failed; widget not existing"
				);
				return;
			}

			// on widget onmount
			widget.destroy();
			store.logger.logInfo(`STATSCORE: unmounted`);
		};
	}, [activeWidget]);

	return isError;
}
