import React, { FC } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { UserAccount } from "@data-types";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

export const WalletItem: FC<{
	wallet: UserAccount | undefined;
	isDisabled?: boolean;
	isList?: boolean;
	isDefaultChecked?: boolean;
	shouldRegister?: boolean;
	hidden?: boolean;
	showRadioSelectIfDisabled?: boolean;
}> = observer(function WalletItem({
	wallet,
	isDisabled = false,
	isList = false,
	isDefaultChecked = false,
	shouldRegister = true,
	hidden = false,
	showRadioSelectIfDisabled = false,
}) {
	const { t } = useTranslation();
	const { register } = useFormContext();

	if (
		wallet == null ||
		App.state.rootStore.userAuthStore.availableUserWallets?.includes(
			wallet.abrv
		)
	) {
		return null;
	}

	const field =
		shouldRegister && register("bettingAccountTypeId", { required: true });

	if (hidden) {
		return (
			<input
				type="radio"
				id={wallet.accountTypeId}
				value={wallet.accountTypeId}
				className={classNames("input--radio", {
					"is-disabled": isDisabled,
				})}
				defaultChecked={isDefaultChecked}
				disabled={isDisabled}
				hidden={hidden}
				{...field}
			/>
		);
	}

	return (
		<li className="wallet__item">
			<input
				type="radio"
				id={wallet.accountTypeId}
				value={wallet.accountTypeId}
				className={classNames("input--radio", {
					"is-disabled": isDisabled && !showRadioSelectIfDisabled,
				})}
				defaultChecked={isDefaultChecked}
				disabled={isDisabled}
				{...field}
			/>
			<label
				htmlFor={wallet.accountTypeId}
				className={`wallet__account wallet__account--${wallet.abrv}`}
			>
				<span
					className={classNames("wallet__type", {
						"wallet__type--grow": isList,
					})}
				>
					<i
						className={`wallet__icon wallet__icon--menu u-icon u-icon--med u-icon--wallet u-icon--wallet--${wallet.abrv} u-mar--left--reset`}
					/>
					<span className="wallet__type__title">
						{t<string>(`HEADER.${wallet.abrv.toUpperCase()}`)}:
					</span>
				</span>
				<span
					className={classNames("wallet__balance", {
						"wallet__balance--sml": isList,
					})}
				>
					<span className="wallet__amount">{wallet.balance}</span>
					<span className="wallet__currency">
						{wallet.currency.displayCode}
					</span>
				</span>
			</label>
		</li>
	);
});
