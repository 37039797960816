import { maxBy } from "lodash";

import { EventOffer, SportOffer } from "@gp/offer";
import { useFormatTennisGameScore } from "@hooks";

const pauseStatuses = [
	"not-started",
	"first-break",
	"second-break",
	"third-break",
	"fourth-break",
	"fifth-break",
	"sixth-break",
	"ended",
];

const pauseSkipSports = ["volleyball", "beach-volley"];

const notRoundedSports = ["tennis"];

export function useOfferColumnResult(event: EventOffer, sport: SportOffer) {
	const isRounded = !notRoundedSports.includes(sport.abrv);

	let gameScore = useFormatTennisGameScore(event, sport);

	let lastPeriodScore;

	if (
		pauseSkipSports.includes(sport.abrv) &&
		pauseStatuses.includes(event.matchStatus)
	) {
		lastPeriodScore = {
			home: "-",
			away: "-",
		};
	} else {
		lastPeriodScore = maxBy(event.result?.scorePerPeriod, "periodNumber");
	}

	return {
		serve: event.result?.serve,
		currentScore: event.currentScore,
		/**
		 * @deprecated use useFormatTennisGameScore instead
		 */
		gameScore: gameScore,
		lastPeriodScore: lastPeriodScore,
		isRounded: isRounded,
	};
}
