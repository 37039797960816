import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Period } from "@state/common/PeriodConverter";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { useContext } from "react";

export function ExpandedPeriodMenu(props: {
	store: {
		toggleUpdateProcessing: () => void;
		periodSubMenu: {
			setPeriodKey: (p: Period) => void;
			periodMenu: { period: Period; name: string }[];
		};
	};
}) {
	return (
		<nav className="nav--tertiary">
			<ul className="nav--tertiary__menu">
				<PeriodMenuItems store={props.store} />
			</ul>
		</nav>
	);
}

function PeriodMenuItems(props: {
	store: {
		toggleUpdateProcessing: () => void;
		periodSubMenu: {
			setPeriodKey: (p: Period) => void;
			periodMenu: { period: Period; name: string }[];
		};
	};
}) {
	return (
		<>
			{props.store.periodSubMenu.periodMenu.map((period) => (
				<PeriodMenuItem
					key={period.period}
					period={period.period}
					name={period.name}
					store={props.store}
				/>
			))}
		</>
	);
}

function PeriodMenuItem(props: {
	period: Period;
	name: string;
	store: {
		toggleUpdateProcessing: () => void;
		periodSubMenu: {
			setPeriodKey: (p: Period, shouldOpenOfferMenu: boolean) => void;
		};
	};
}) {
	const params = useParams<{ period: string }>();
	const location = useLocation();
	const shouldOpenOfferMenu = !location.pathname.includes("/my-favorites");
	const { t } = useTranslation();
	const favoriteStore = useContext(FavoritesStoreContext);

	return (
		<button
			className={classnames("nav--tertiary__link", {
				"is-active": params.period === props.period,
			})}
			onClick={() => {
				props.store.toggleUpdateProcessing();
				props.store.periodSubMenu.setPeriodKey(
					props.period,
					shouldOpenOfferMenu
				);
				favoriteStore.setPeriod(props.period);
			}}
		>
			<span className="nav--tertiary__title nav--tertiary__title--center">
				<i
					className={`nav--tertiary__icon u-icon u-icon--med u-icon--period--${props.period}`}
				/>
				<span>{t(props.name)}</span>
			</span>
		</button>
	);
}
