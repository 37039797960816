import React, { useContext } from "react";
import { observer } from "mobx-react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { MyBetsBetContext, MyBetsStoreContext } from "../../../../context";
import FiskalySection from "./FiskalySection";

export default observer(function BetDetails(props: {
	setShowCombinations: (v: boolean) => void;
}) {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);
	const betStore = useContext(MyBetsStoreContext);

	if (betStore == null) {
		console.log("Expected bet store context got null");
		return null;
	}

	const {
		betCancelStore: { onBetCancel },
	} = betStore;

	const fiscalizationItem = bet.firstFinishedTransactionItem;

	return (
		<tr className="table--bets__details">
			<td className="u-padd--reset" colSpan={8}>
				<table
					className="table--bets--reset"
					cellSpacing={0}
					cellPadding={0}
				>
					<tbody>
						<tr className="table--bets__tr">
							<td className="table--bets__dd">
								{t(
									"MY_BETS.ITEM.ADDITIONAL_NUM_SELECTED_EVENTS"
								)}
							</td>
							<td className="table--bets__dd">
								{bet.eventCount}
							</td>
						</tr>
						<tr className="table--bets__tr">
							<BetDetails_ShowCombinations
								setShowCombinations={props.setShowCombinations}
							/>
						</tr>
						<tr className="table--bets__tr">
							<td className="table--bets__dd">
								{t("MY_BETS.ITEM.ADDITIONAL_STAKE_PER_BET")}
							</td>
							<td className="table--bets__dd">
								{bet.displayPaymentPerCombination}
							</td>
						</tr>
						<tr className="table--bets__tr">
							<td className="table--bets__dd">
								{t("MY_BETS.ITEM.ADDITIONAL_TOTAL_STAKE")}
							</td>
							<td className="table--bets__dd">
								{bet.displayPayment}
							</td>
						</tr>
						{bet.handlingFeePercentage != null &&
							bet.handlingFeePercentage > 0 && (
								<tr className="table--bets__tr">
									<td className="table--bets__dd">
										{t(
											"MY_BETS.ITEM.ADDITIONAL_HANDLING_FEE",
											{
												0: bet.handlingFeePercentage.toFixed(),
											}
										)}
									</td>
									<td className="table--bets__dd">
										{bet.displayHandlingFee}
									</td>
								</tr>
							)}
						<tr className="table--bets__tr">
							<td className="table--bets__dd">
								{t("MY_BETS.ITEM.ADDITIONAL_MAX_COEFFICIENT")}
							</td>
							<td className="table--bets__dd">
								{bet.maxCoefficient}
							</td>
						</tr>
						{bet.gainBonusPercentage != null &&
							bet.gainBonusPercentage > 0 && (
								<tr className="table--bets__tr">
									<td className="table--bets__dd">
										{t(
											"MY_BETS.ITEM.ADDITIONAL_GAIN_BONUS",
											{
												0: bet.gainBonusPercentage.toFixed(
													2
												),
											}
										)}
									</td>
									<td className="table--bets__dd">
										+ {bet.displayGainBonus}
									</td>
								</tr>
							)}
						{bet.gainTaxPercentage != null &&
							bet.gainTaxPercentage > 0 && (
								<tr className="table--bets__tr">
									<td className="table--bets__dd">
										{t("MY_BETS.ITEM.ADDITIONAL_GAIN_TAX", {
											0: bet.gainTaxPercentage.toFixed(2),
										})}
									</td>
									<td className="table--bets__dd">
										- {bet.displayGainTax}
									</td>
								</tr>
							)}
						<MaxGain />
						<Payment />
						<Gain />

						<Payout />
						{bet.metadata != null && fiscalizationItem != null && (
							<>
								<tr className="table--bets__tr table--bets__fiscalization table--bets__fiscalization--dashed">
									<td className="table--bets__tf" colSpan={2}>
										<span className="u-mar--right--xtny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_START_TIME"
											)}
											:
										</span>
										<span>
											{DateTime.fromISO(
												fiscalizationItem.transactionTimestamp
											).toLocaleString(
												DateTime.DATETIME_SHORT
											)}
										</span>
									</td>
								</tr>
								<tr className="table--bets__tr table--bets__fiscalization">
									<td className="table--bets__tf" colSpan={2}>
										<span className="u-mar--right--xtny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_END_TIME"
											)}
											:
										</span>
										<span>
											{DateTime.fromISO(
												fiscalizationItem.transactionTimestamp
											).toLocaleString(
												DateTime.DATETIME_SHORT
											)}
										</span>
									</td>
								</tr>
								<tr className="table--bets__tr table--bets__fiscalization">
									<td className="table--bets__tf" colSpan={2}>
										<span className="u-mar--right--xtny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_TRANSACTION_NUMBER"
											)}
											:
										</span>
										<span>
											{
												fiscalizationItem.transactionNumber
											}
										</span>
									</td>
								</tr>
								<tr className="table--bets__tr table--bets__fiscalization">
									<td className="table--bets__tf" colSpan={2}>
										<span className="u-mar--right--xtny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_TSE_SERIAL_NUMBER"
											)}
											:
										</span>
										<span>
											{bet.metadata.deviceSerialNumber}
										</span>
									</td>
								</tr>
								<tr className="table--bets__tr table--bets__fiscalization">
									<td className="table--bets__tf" colSpan={2}>
										<span className="u-mar--right--xtny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_SIGNATURE"
											)}
											:
										</span>
										<span>
											{fiscalizationItem.signature}
										</span>
									</td>
								</tr>
							</>
						)}
						<FiskalySection />
						{bet.isCancellationAvailable == true && (
							<tr className="table--bets__tr table--bets__tr--cancel">
								<td
									className="table--bets__dd u-padd--x--xtny"
									colSpan={2}
								>
									<button
										className="btn btn--tny btn--danger"
										onClick={() => onBetCancel(bet)}
									>
										{t("MY_BETS.ITEM.CANCEL_BET")}
									</button>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</td>
		</tr>
	);
});

function BetDetails_ShowCombinations(props: {
	setShowCombinations: (v: boolean) => void;
}) {
	const { t } = useTranslation();
	const myBetsStore = useContext(MyBetsStoreContext);
	const bet = useContext(MyBetsBetContext);

	return (
		<>
			<td className="table--bets__dd">
				<div>
					{t("MY_BETS.ITEM.ADDITIONAL_NUM_BETS")}
					{bet.totalNumberOfCombinations > 1 && (
						<span
							className="u-mar--left--xtny no-print"
							onClick={() => {
								props.setShowCombinations(true);
								myBetsStore?.initBetCombinationForBetSlip(
									bet.id
								);
							}}
						>
							(
							<span className="u-anchor u-anchor--secondary u-anchor--underline u-type--wgt--medium">
								{t<string>("MY_BETS.ITEM.SHOW_COMBINATIONS")}
							</span>
							)
						</span>
					)}
				</div>
			</td>
			<td className="table--bets__dd">{bet.totalNumberOfCombinations}</td>
		</>
	);
}

const MaxGain = observer(function MaxGain() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);

	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd">
				{t("MY_BETS.ITEM.ADDITIONAL_MAX_GAIN")}
			</td>
			<td className="table--bets__dd">{bet.displayMaxGain}</td>
		</tr>
	);
});

const Gain = observer(function Gain() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);

	if (bet.betStatus.abrv !== "won") {
		return null;
	}

	if (bet.gain == null || bet.gain == 0) {
		return null;
	}
	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd">
				{t("MY_BETS.ITEM.ADDITIONAL_GAIN")}
			</td>
			<td className="table--bets__dd u-type--color--won">
				{bet.displayGain}
			</td>
		</tr>
	);
});

const Payment = observer(function Payment() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);

	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd">{t("MY_BETS.REPORTS.PAYMENT")}</td>
			<td className="table--bets__dd u-type--color--lost">
				{bet.displayPayment}
			</td>
		</tr>
	);
});

const Payout = observer(function Payout() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);

	if (
		!bet.payout ||
		(bet.betSlipStatus.abrv !== "paid-out" &&
			bet.betSlipStatus.abrv !== "cashout")
	) {
		return null;
	}
	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd">
				{t<string>("MY_BETS.ITEM.INFO_PAYOUT_LABEL")}
			</td>

			<td className="table--bets__dd u-type--color--won">
				{bet.displayPayout}{" "}
			</td>
		</tr>
	);
});
