import React, { FC, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import GamingHistoryStoreContext from "../../../context/GamingHistoryStoreContext";
import { Pager } from "@v2/components/desktop/shared/inputs";
import { DateTime } from "luxon";
import useCurrency from "@v2/hooks/shared/useCurrency";
import { GamingHistoryDataDto } from "@api-types/gaming-history/GamingHistoryDataDto";
import { TransactionDto } from "@api-types/gaming-history/TransactionDto";
import GamingHistoryReport from "./GamingHistoryReport";
import classnames from "classnames";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

type GamingHistoryListItemProps = {
	key: string;
	gameHistoryItem: GamingHistoryDataDto;
};

const Body: FC = observer(function Body() {
	const {
		gameHistoryList,
		initialLoad,
		pageNumber,
		pageSize,
		totalItems,
		onPageChange,
	} = useContext(GamingHistoryStoreContext);

	const { t } = useTranslation();

	if (initialLoad) {
		return null;
	}

	if (gameHistoryList == null || gameHistoryList.length === 0) {
		return (
			<InfoMessageComponent
				type="note"
				message={t<string>("specific:GAMING_HISTORY.NO_DATA")}
				addClassName="u-mar--top--sml"
			/>
		);
	}

	return (
		<div className="u-mar--top--big">
			<GamingHistoryReport />
			<GamingHistoryTable />
			<Pager
				pageNumber={pageNumber}
				pageSize={pageSize}
				totalItems={totalItems}
				onPageChange={onPageChange}
				withNumberInput
			/>
		</div>
	);
});

const GamingHistoryTable: FC = observer(function GamingHistoryTable() {
	const { gameHistoryList } = useContext(GamingHistoryStoreContext);

	const { t } = useTranslation();
	const { currency } = useCurrency();

	return (
		<table className="table--primary">
			<thead className="table__head">
				<tr className="table__head__row table__row">
					<th className="table__head__cell table__cell">
						{t<string>("GAMING_HISTORY.TABLE.TABLE_GAME_ROUND")}
					</th>
					<th className="table__head__cell table__cell">
						{t<string>("GAMING_HISTORY.TABLE.TABLE_DATE")}
					</th>
					<th className="table__head__cell table__cell">
						{t<string>(
							"specific:GAMING_HISTORY.TABLE.TABLE_CASINO_GAME"
						)}
					</th>
					<th className="table__head__cell table__cell u-type--right">
						{t<string>("GAMING_HISTORY.TABLE.STAKE")} ({currency})
					</th>
					<th className="table__head__cell table__cell u-type--right">
						{t<string>("GAMING_HISTORY.TABLE.GAIN")} ({currency})
					</th>
					<th className="table__head__cell table__cell u-type--right">
						&nbsp;
					</th>
				</tr>
			</thead>
			<tbody className="table__body">
				{gameHistoryList.map((gameHistoryItem) => (
					<GamingHistoryListItem
						key={gameHistoryItem.GameRoundId}
						gameHistoryItem={gameHistoryItem}
					/>
				))}
			</tbody>
		</table>
	);
});

const GamingHistoryListItem: FC<GamingHistoryListItemProps> = observer(
	function GamingHistoryListItem(props: GamingHistoryListItemProps) {
		const { gameHistoryItem } = props;
		const { t } = useTranslation();
		const [isToggleTrue, setToggle] = useState(false);

		const gain =
			gameHistoryItem.TotalGain > 0
				? gameHistoryItem.TotalGain.toString()
				: "-";
		var stakeOrGain =
			gameHistoryItem.CountStake > 1 || gameHistoryItem.CountGain > 1;

		let roundStatus = "";
		switch (gameHistoryItem.RoundStatus) {
			case 0:
				roundStatus = t<string>("COMMON.STATUS.IN_PROGRESS");
				break;
			case 1:
				roundStatus = t<string>("COMMON.STATUS.WON");
				break;
			case 2:
				roundStatus = t<string>("COMMON.STATUS.LOST");
				break;
			case 3:
				roundStatus = t<string>("COMMON.STATUS.CANCELLED");
				break;
			default:
				roundStatus = t<string>("COMMON.STATUS.IN_PROGRESS");
				break;
		}

		const roundStatusColor = classnames(
			"tag tag--sml",
			{
				"tag--bet--in-progress": gameHistoryItem.RoundStatus === 0,
				"tag--bet--won": gameHistoryItem.RoundStatus === 1,
				"tag--bet--lost": gameHistoryItem.RoundStatus === 2,
				"tag--bet--cancelled": gameHistoryItem.RoundStatus === 3,
			},
			"u-align--v--middle u-mar--right--xtny"
		);

		return (
			<>
				<tr className="table__body__row table__row">
					<td className="table__body__cell table__cell">
						<span className={roundStatusColor}>{roundStatus}</span>
						<span className="u-align--v--middle u-type--wgt--medium">
							#{gameHistoryItem.GameRound}
						</span>
					</td>
					<td className="table__body__cell table__cell">
						{DateTime.fromISO(gameHistoryItem.Date)
							.toLocaleString(
								DateTime.DATETIME_SHORT_WITH_SECONDS
							)
							.replace(",", "")}
					</td>
					<td className="table__body__cell table__cell">
						{gameHistoryItem.GameName}
					</td>
					<td className="table__body__cell table__cell u-type--wgt--medium u-type--right u-color--ui--danger">
						{gameHistoryItem.TotalStake}
					</td>
					<td className="table__body__cell table__cell u-type--wgt--medium u-type--right u-color--ui--success">
						{gain}
					</td>
					<td className="table__body__cell table__cell u-type--right">
						<StakeOrGainButton
							item={gameHistoryItem}
							stakeOrGain={stakeOrGain}
							isVisible={isToggleTrue}
							toggleVisibility={setToggle}
						/>
					</td>
				</tr>
				<StakeOrGainRow
					stakeOrGain={stakeOrGain}
					isVisible={isToggleTrue}
					roundId={gameHistoryItem.GameRoundId}
				/>
			</>
		);
	}
);

const StakeOrGainButton: FC<{
	item: GamingHistoryDataDto;
	stakeOrGain: boolean;
	isVisible: boolean;
	toggleVisibility: (param: boolean) => void;
}> = function StakeOrGainButton(props: {
	item: GamingHistoryDataDto;
	stakeOrGain: boolean;
	isVisible: boolean;
	toggleVisibility: (param: boolean) => void;
}) {
	const { item, stakeOrGain, toggleVisibility, isVisible } = props;
	const { getRoundTransactions } = useContext(GamingHistoryStoreContext);

	if (!stakeOrGain) {
		return null;
	}

	return (
		<button
			type="button"
			className="btn btn--square btn--square--tny"
			value={item.GameRoundId}
			onClick={() => {
				getRoundTransactions(item.GameRoundId);
				toggleVisibility(!isVisible);
			}}
		>
			<i
				className={
					"u-icon u-icon--xsml" +
					(isVisible
						? " u-icon--caret--down"
						: " u-icon--caret--right")
				}
			/>
		</button>
	);
};

const StakeOrGainRow: FC<{
	stakeOrGain: boolean;
	roundId: string;
	isVisible: boolean;
}> = observer(function StakeOrGainRow(props: {
	stakeOrGain: boolean;
	roundId: string;
	isVisible: boolean;
}) {
	const { transactionsPerRound } = useContext(GamingHistoryStoreContext);
	const { stakeOrGain, isVisible, roundId } = props;

	if (!stakeOrGain || !isVisible) {
		return null;
	}

	const foundTransaction = transactionsPerRound.find(
		(item) => item.roundId === roundId
	);

	return (
		<tr className="table__body__row table__row u-type--xsml">
			<td
				className="table__body__cell table__cell table__cell--sml"
				colSpan={3}
			/>
			<td
				className="table__body__cell table__cell table__cell--sml"
				colSpan={2}
			>
				{foundTransaction?.transactions.map((item) => {
					return (
						<div key={item.TransactionId} className="row">
							<TransactionRow
								key={item.TransactionId}
								item={item}
							/>
						</div>
					);
				})}
			</td>
			<td className="table__body__cell table__cell table__cell--sml" />
		</tr>
	);
});

const TransactionRow: FC<{ item: TransactionDto }> =
	function TransactionRow(props: { item: TransactionDto }) {
		const { t } = useTranslation();

		return (
			<>
				{props.item.Amount < 0 ? (
					<>
						<div className="col col-sml-6 u-type--right">
							{t<string>("SESSION.REALITY_CHECK.STAKE")}:
						</div>
						<div className="col col-sml-6 u-type--right u-color--ui--danger">
							{props.item.Amount}
						</div>
					</>
				) : (
					<>
						<div className="col col-sml-6 u-type--right">
							{t<string>("SESSION.REALITY_CHECK.GAIN")}:
						</div>
						<div className="col col-sml-6 u-type--right u-color--ui--success">
							{props.item.Amount}
						</div>
					</>
				)}
			</>
		);
	};

export default Body;
