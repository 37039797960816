import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

export default observer(function NumericInput(props) {
	const [value, setValue] = useState(props.value.toFixed(2) || "");
	const [internalChange, setInternalChange] = useState(false);

	const {
		isReadonly,
		onChange,
		onBlur,
		onInput,
		onFocus,
		disabled,
		className,
		name,
		value: propValue,
	} = props;

	useEffect(() => {
		if (!internalChange && value !== propValue) {
			setValue(propValue.toFixed(2));
			setInternalChange(false);
		}
	}, [value, propValue]);

	const handleChange = (event) => {
		setValue(event.target.value);
		setInternalChange(true);
	};

	const handleBlur = (event) => {
		if (isReadonly) {
			return;
		}

		const parsedValue = parseFloat(value);

		onChange(parsedValue);

		if (onBlur != null) {
			onBlur(event);
		}

		setValue(parsedValue.toFixed(2));
		setInternalChange(false);
	};

	return (
		<input
			disabled={disabled}
			className={className}
			name={name}
			value={value}
			onChange={handleChange}
			onBlur={handleBlur}
			onInput={onInput}
			onFocus={onFocus}
			type="number"
		/>
	);
});
