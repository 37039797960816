import { BetOffer } from "@administration/pages/my-bets/model";
import {
	OfferCountLimit,
	TMostPlayTicketResponse,
	TMostPlayTicketsFilter,
} from "@api-types/quick-play";
import { action, computed, observable, runInAction } from "mobx";
import QuickPlayStore from "./QuickPlayStore";
import { QuickPlayApiService } from "@api-services/quick-play";
import { localizationService } from "@state";
import { LoaderStore } from "@state/stores/common";

export default class MostPlayTicketsStore {
	quickPlayStore: QuickPlayStore;
	loader: LoaderStore;

	@observable public isPopupDisplayed: boolean = false;
	@observable public blockMainLoader: boolean = false;
	@observable public showLoader: ChangePageButton | null = null;
	@observable public popupBetSlip: TMappedBetSlip | null = null;
	@observable public filteredBetSlips: TMostPlayTicketResponse[] =
		observable.array([]);
	@observable public selectedSportCategory: TsportCategories | null = null;

	@observable indexOfBetslip: number = 0;

	@observable betSlips: TMostPlayTicketResponse[] = [];
	@observable sportCategories: TsportCategories[] = observable.array([]);

	@observable isStoreInitialized: boolean = false;
	@observable isFilteredList: boolean = false;
	constructor(quickPlayStore: QuickPlayStore) {
		this.loader = new LoaderStore();
		this.quickPlayStore = quickPlayStore;
	}

	@computed get isLoading() {
		return this.loader.isLoading;
	}

	//#region  popup
	@action.bound
	activatePopup(index: number, isFilteredList: boolean) {
		this.indexOfBetslip = index;
		this.isFilteredList = isFilteredList;
		this.changeBetSlip();
		this.isPopupDisplayed = true;
	}

	@action.bound
	deactivatePopup() {
		this.blockMainLoader = false;
		this.indexOfBetslip = 0;
		this.isPopupDisplayed = false;
		this.popupBetSlip = null;
		this.isFilteredList = false;
	}

	@action.bound
	async changePage(num: ChangePageButton) {
		this.showLoader = num;
		if (!this.blockMainLoader) this.blockMainLoader = true;
		const betslips = this.isFilteredList
			? this.filteredBetSlips
			: this.betSlips;
		switch (num) {
			case ChangePageButton.PREVIOUS:
				if (this.indexOfBetslip > 0) {
					this.indexOfBetslip -= 1;
				} else {
					this.indexOfBetslip = betslips.length - 1;
				}
				break;
			case ChangePageButton.NEXT:
				if (this.indexOfBetslip < betslips.length - 1) {
					this.indexOfBetslip += 1;
				} else {
					this.indexOfBetslip = 0;
				}
				break;
			default:
				this.indexOfBetslip = num;
				break;
		}
		await this.changeBetSlip();
	}

	//#endregion popup

	@action.bound
	public async onInitializeMostPlayTickets(location: OfferCountLimit) {
		await this.fetchMostPlayedTickets({
			offerCount: location,
		});
		this.isStoreInitialized = true;
	}

	@action.bound
	private createBetlipWithMappedBettingOffers(
		betslips: TMostPlayTicketResponse[]
	) {
		return betslips.reduce((acc: TMappedBetSlip[], bet) => {
			const mappedBet = bet.betSlipOffers.reduce(
				(acc: BetOffer[], betSlipOffer) => {
					acc.push(new BetOffer(betSlipOffer));
					return acc;
				},
				[]
			);
			acc.push({ ...bet, betSlipOffers: mappedBet });

			return acc;
		}, []);
	}

	@action.bound
	private createBetSlipsSportsCategories() {
		const sportCategoriesSet = new Map<string, TsportCategories>();
		this.betSlips.forEach((betslip) => {
			if (!sportCategoriesSet.has(betslip.sportAbrv)) {
				sportCategoriesSet.set(betslip.sportAbrv, {
					sportAbrv: betslip.sportAbrv,
					sortOrder: betslip.sportSortOrder,
					sportName: betslip.sportName,
				});
			}
		});

		const sortedSportCategories = [...sportCategoriesSet.values()].sort(
			(a, b) => a.sortOrder - b.sortOrder
		);

		this.sportCategories = sortedSportCategories;
	}

	@action.bound
	public filterByCategory(sportAbrv?: string) {
		if (!sportAbrv) {
			this.filteredBetSlips = this.betSlips;
			return;
		}
		this.filteredBetSlips = this.betSlips.filter(
			(bet) => bet.sportAbrv == sportAbrv
		);
		this.selectedSportCategory = {
			sportAbrv: this.filteredBetSlips[0].sportAbrv,
			sortOrder: this.filteredBetSlips[0].sportSortOrder,
			sportName: this.filteredBetSlips[0].sportName,
		};
	}

	@action.bound
	private async fetchMostPlayedTickets(settings: TMostPlayTicketsFilter) {
		this.quickPlayStore.loaderStore.suspend();
		try {
			const mostplayedTicketResponse =
				await QuickPlayApiService.fetchMostPlayBetSlips(settings);
			if (!mostplayedTicketResponse.response) {
				return;
			}
			const betSlips = mostplayedTicketResponse.response;
			betSlips.forEach((betSlip) => {
				betSlip?.betSlipOffers?.forEach((offer) => {
					if (
						!offer.sportData ||
						!offer.sportData.bettingTypeAbrv ||
						offer?.specifier?.player == null ||
						![
							"player-total-assists",
							"player-total-rebounds",
							"player-total-3-point-field-goals",
							"player-total-points",
							"player-total-rushing-yards",
							"player-total-passing-touchdowns",
							"player-total-passing-yards",
							"player-total-receiving-yards",
						].includes(offer.sportData.bettingTypeAbrv)
					) {
						return;
					}

					offer.specifier.player =
						offer.playerFirstName +
						" " +
						offer.playerLastName +
						` (${
							offer.sportData.teamOneId === offer.teamId
								? offer.sportData.teamOneName
								: offer.sportData.teamTwoName
						})`;
					offer.playerId = undefined;
					offer.teamId = undefined;
				});
			});
			runInAction(() => {
				this.betSlips =
					this.createBetlipWithMappedBettingOffers(betSlips);
				this.createBetSlipsSportsCategories();
				[this.selectedSportCategory] = this.sportCategories;
				this.filterByCategory(this.sportCategories[0].sportAbrv);
			});
		} catch (e) {
			console.error(e);
		} finally {
			this.quickPlayStore.loaderStore.resume();
		}
	}

	@action.bound
	private async fetchMostPlayedTicketById(betSlipId: string) {
		this.quickPlayStore.loaderStore.suspend();
		try {
			return await QuickPlayApiService.fetchBetSlipById(betSlipId);
		} catch (e) {
			console.error(e);
			if (e?.data.errorCode == 400011) {
				throw e?.data;
			}
			return null;
		} finally {
			this.quickPlayStore.loaderStore.resume();
		}
	}

	@action.bound
	public async changeBetSlip() {
		if (this.quickPlayStore.loaderStore.isLoadingProcess) {
			return;
		}
		this.loader.suspend();
		const betSlips = this.isFilteredList
			? this.filteredBetSlips
			: this.betSlips;
		try {
			const betSlip = await this.fetchMostPlayedTicketById(
				betSlips[this.indexOfBetslip].betSlipId
			);

			if (!betSlip?.response || betSlip.response?.length == 0) {
				this.quickPlayStore.rootStore.notificationStore.error(
					localizationService.t(
						"QUICK_PLAY.MOST_PLAYED_TICKETS.BET_SLIP_ERROR"
					)
				);
				console.error("There should be a bet slip");
				return;
			}
			this.popupBetSlip = this.createBetlipWithMappedBettingOffers(
				betSlip.response
			)[0];
		} catch (e) {
			this.quickPlayStore.rootStore.notificationStore.error(
				localizationService.t(
					"QUICK_PLAY.MOST_PLAYED_TICKETS.BET_SLIP_EXPIRED"
				)
			);

			this.fetchMostPlayedTickets({
				offerCount: this.isFilteredList
					? OfferCountLimit.MostPlayedTicketsPage
					: OfferCountLimit.HomePage,
			});
			this.isPopupDisplayed = false;
		} finally {
			this.loader.resume();
		}
	}

	@action.bound
	onDispose() {
		this.blockMainLoader = false;
		this.isStoreInitialized = false;
		this.betSlips = [];
		this.sportCategories = [];
		this.popupBetSlip = null;
		this.filteredBetSlips = [];
		this.isFilteredList = false;
		this.isPopupDisplayed = false;
	}
}
export type TMappedBetSlip = Omit<TMostPlayTicketResponse, "betSlipOffers"> & {
	betSlipOffers: BetOffer[];
};

export enum ChangePageButton {
	"PREVIOUS",
	"NEXT",
}

type TsportCategories = {
	sportAbrv: string;
	sortOrder: number;
	sportName: string;
};
