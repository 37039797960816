import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "@v2/components/desktop/shared/header";
import { Footer } from "@v2/components/desktop/shared/footer";
import { OfferPrintTableSection } from "./";
import useOfferPrint from "@v2/hooks/offer-print/useOfferPrint";

type OfferPrintData = Parameters<
	Parameters<ReturnType<typeof useOfferPrint>["onInitializePage"]>[0]
>[0];

export default function OfferPrintPage() {
	const { onInitializePage } = useOfferPrint();
	const { t } = useTranslation();

	const [offerPrintData, setOfferPrintData] = useState<OfferPrintData>();

	useEffect(() => {
		onInitializePage(setOfferPrintData);
	}, []);

	return (
		<div className="l--page">
			<Header />
			<main role="main" className="l--main l--main--med">
				<section className="l--content l--content--full">
					<div className="l--content__main">
						<div className="container container--lrg">
							<div className="card--primary">
								<div className="card--primary__header">
									<h1 className="card--primary__title title--primary">
										{t("OFFER_PRINT.TITLE")}
									</h1>
								</div>

								<div className="card--primary__body">
									<div className="row">
										<div className="col col-sml-12 col-ssvga-6">
											<OfferPrintTableSection
												data={
													offerPrintData?.decimalOfferPrint
												}
											/>
										</div>
										<div className="col col-sml-12 col-ssvga-6">
											<OfferPrintTableSection
												data={
													offerPrintData?.hexadecimalOfferPrint
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</div>
	);
}
