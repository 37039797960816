import { useNotification } from "../../../ui/common/hooks";

import { offerPrintService } from "@v2/data-access/offer-print";
import { useLoading } from "@hooks";

type OfferPrintDataResponse = Parameters<
	NonNullable<
		Parameters<
			ReturnType<typeof offerPrintService.getOfferPrintData>["then"]
		>[0]
	>
>[0];

export default function useOfferPrint() {
	const { setIsLoading } = useLoading();
	const { showError } = useNotification();

	const onInitializePage = async (
		setOfferPrintData: (r: OfferPrintDataResponse) => void
	) => {
		try {
			setIsLoading(true);
			const response = await offerPrintService.getOfferPrintData();

			setOfferPrintData(response);
		} catch (error) {
			showError(error);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		onInitializePage,
	};
}
