import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { PasswordInput } from "@v2/components/desktop/shared/inputs";

export const WithdrawalPasswordConfirmation: FC = observer(
	function WithdrawalPasswordConfirmation() {
		return (
			<PasswordInput
				name="password"
				className="form__group"
				label={"WITHDRAWAL.PASSWORD_CONFIRM_MESSAGE"}
				placeholder={"COMMON.PASSWORD_CONFIRMATION.PASSWORD_TITLE"}
				required
			/>
		);
	}
);
