import { merge } from "lodash";
import { getCurrentCulture } from "@utils";

type HttpClientRequestInit = RequestInit & { responseType?: string };

class HttpClient {
	defaultHeaders: { "Caller-Environment": string };

	constructor() {
		this.defaultHeaders = {
			"Caller-Environment": WEBPACK_ENVIRONMENT_KEY,
		};
		this._internalRequest = this._internalRequest.bind(this);
		this.request = this.request.bind(this);
		this.get = this.get.bind(this);
		this.post = this.post.bind(this);
		this.put = this.put.bind(this);
		this.delete = this.delete.bind(this);
	}

	_internalRequest(
		url: RequestInfo | URL,
		options?: HttpClientRequestInit
	): Promise<Response> {
		return fetch(url, options);
	}

	addAdditionalQueryParamsToRequest(url: URL | RequestInfo) {
		if (!WEBPACK_SHOULD_SET_REQUESTID) {
			return url;
		}

		if (!url || App?.state?.rootStore == null) {
			return url;
		}

		const requestIdValue = `${WEBPACK_ENVIRONMENT_KEY}-${
			App.state.rootStore.uniqueId
		}-${Date.now()}`;

		if (typeof url == "object") {
			(url as URL).searchParams.append("reqId", requestIdValue);
			return url;
		}

		if (url.includes("?")) {
			url = url += "&" + "reqId=" + requestIdValue;
		} else {
			url = url += "/?" + "reqId=" + requestIdValue;
		}
		return url;
	}

	async request(url: RequestInfo | URL, options?: HttpClientRequestInit) {
		const rawResponse = await this._internalRequest(
			this.addAdditionalQueryParamsToRequest(url),
			options
		);
		if (rawResponse.redirected) {
			window.location.href = rawResponse.url;
			return;
		}

		if (rawResponse.status === 308) {
			const body = await rawResponse.json();

			window.location.href = window.location.origin + body.url;
			return;
		}

		if (rawResponse.status === 204) {
			return null;
		}

		if (rawResponse.status === 401 || rawResponse.status === 403) {
			if (window.location !== window.parent.location) {
				//used for gig in case of 401 do not direct user to login page due to gig page being outside of main layout
				throw new Error("ERROR.GIG_USER_ERROR");
			}

			App.state.rootStore.userAuthStore.removeUserFromLocalStorage();

			const response = await rawResponse.json();
			if (response?.errorCode != null && response.errorCode === 400239) {
				App.state.redirect(
					`/${getCurrentCulture()}/auth/login?isAutomaticLogout=true`
				);
			} else {
				App.state.redirect(
					`/${getCurrentCulture()}/auth/login?isSessionExpired=true`
				);
			}
			throw new Error("Unauthorized");
		}

		let response;
		switch (options?.responseType) {
			case "text":
				response = await rawResponse.text();
				break;
			case "blob":
				response = await rawResponse.blob();
				break;
			case "arrayBuffer":
				response = await rawResponse.arrayBuffer();
				break;
			case "formData":
				response = await rawResponse.formData();
				break;
			case "json":
				response = await rawResponse.json();
				break;
			default:
				response = rawResponse;
				break;
		}

		if (rawResponse.ok) {
			return response;
		}

		// if we got here, error happened
		// throw what we got from the response
		throw {
			statusCode: rawResponse.status,
			data: response,
			rawResponse: rawResponse,
		};
	}

	get(
		url: RequestInfo | URL,
		headers?: HeadersInit,
		options: HttpClientRequestInit = {}
	): Promise<any> {
		let innerHeaders = {
			...this.defaultHeaders,
		};
		if (headers) {
			merge(innerHeaders, headers);
		}

		return this.request(url, {
			...options,
			method: "GET",
			headers: innerHeaders,
		});
	}

	post(
		url: RequestInfo | URL,
		data: BodyInit | null = null,
		headers?: HeadersInit,
		options: HttpClientRequestInit = {}
	) {
		let innerHeaders = {
			...this.defaultHeaders,
		};
		if (headers) {
			merge(innerHeaders, headers);
		}

		return this.request(url, {
			...options,
			// credentials: 'same-origin',
			method: "POST",
			body: data,
			headers: innerHeaders,
		});
	}

	put(
		url: RequestInfo | URL,
		data: BodyInit | null = null,
		headers?: HeadersInit,
		options: HttpClientRequestInit = {}
	) {
		let innerHeaders = {
			...this.defaultHeaders,
		};

		if (headers) {
			merge(innerHeaders, headers);
		}

		return this.request(url, {
			...options,
			method: "PUT",
			body: data,
			headers: innerHeaders,
		});
	}

	delete(
		url: RequestInfo | URL,
		headers: HeadersInit,
		options: HttpClientRequestInit = {}
	) {
		let innerHeaders = {
			...this.defaultHeaders,
		};

		if (headers) {
			merge(innerHeaders, headers);
		}

		return this.request(url, {
			...options,
			method: "DELETE",
			headers: innerHeaders,
		});
	}
}

const httpClient = new HttpClient();
export { httpClient };
