import { useRootAppStore } from "@hooks";
import { UserTypes, getCurrentCulture, getUserFromLocalStorage } from "@utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function DepositLimitInfo() {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();
	const onlineUser = getUserFromLocalStorage(
		rootStore.userAuthStore.isPrimaryUserOnline == true
			? UserTypes.PRIMARY
			: UserTypes.SECONDARY
	);

	if (!rootStore.userAuthStore.secondaryUser) {
		if (!rootStore.userAuthStore.user?.depositLimitUpdate) return null;
	} else if (onlineUser && !onlineUser?.depositLimitUpdate) {
		return null;
	}

	return (
		<div className="popup__col col col-sml-12">
			<div className="message message--base">
				<div className="message__content u-padd--reset">
					<div className="message__title u-type--xmed">
						<i className="message__icon u-icon u-icon--lrg u-icon--limits u-color--ui--danger u-mar--right--tny" />
						<span className="u-align--v--middle">
							{t<string>(
								"SESSION.DEPOSIT_LIMIT.DEPOSIT_LIMIT_TITLE"
							)}
						</span>
					</div>
					<div className="message__text">
						{t<string>(
							"SESSION.DEPOSIT_LIMIT.DEPOSIT_LIMIT_DESCRIPTION"
						)}
					</div>
					<Link
						to={`/${getCurrentCulture()}/account-settings/my-limits`}
						className="at-welcomeModalBtnMyLimits u-anchor u-anchor--primary u-anchor--underline has-arrow"
						onClick={() => {
							rootStore.welcomeStore.hidePopup(),
								rootStore.requiredUserActionStore.hidePopup();
						}}
					>
						<span>
							{t<string>("SESSION.DEPOSIT_LIMIT.MY_LIMITS_LINK")}
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
}
