import React from "react";
import { observer } from "mobx-react";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";
import { ProviderNameEnum } from "@gp/models";
import { ReactScriptLoad } from "@lib/react-script-load";

import { WidgetLocation } from "./models/LiveWidgetTypes";
import { BrWidgetScriptUrl, LsWidgetScriptUrl } from "./config";
import { BRWidgetContainer } from "./widgets/BRWidgetContainer";
import { LSWidgetContainter } from "./widgets/LSWidgetContainter";

/** Container that does mounting of SIR LMT widget. */
export const LMTContainer = observer(function LMTContainer(props: {
	eventId: string;
	location: WidgetLocation;
	/** Props passes to wrapping div element. */
	divProps?: React.HTMLProps<HTMLDivElement>;
}) {
	const widgetStore = useLiveWidgetStore();
	const activeLmtWidget = widgetStore.findActiveLmtWidget(
		props.eventId,
		props.location
	);

	return (
		<>
			<ReactScriptLoad
				src={BrWidgetScriptUrl}
				onError={(er: ErrorEvent) => console.error(er)}
			>
				{activeLmtWidget?.provider === ProviderNameEnum.BetRadar ? (
					<BRWidgetContainer
						{...props}
						eventId={props.eventId}
						location={props.location}
					/>
				) : null}
			</ReactScriptLoad>
			<ReactScriptLoad
				src={LsWidgetScriptUrl}
				onError={(er: ErrorEvent) => console.error(er)}
			>
				{activeLmtWidget?.provider === ProviderNameEnum.LSports ? (
					<LSWidgetContainter
						{...props}
						eventId={props.eventId}
						location={props.location}
					/>
				) : null}
			</ReactScriptLoad>
		</>
	);
});
