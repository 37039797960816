import { DateTime } from "luxon";

export const lookupsInitialData = {
	durationAbrvs: WEBPACK_ACCOUNT_LOCK_DURATIONS,
	reasonAbrvs: [
		"other-reasons",
		"no-longer-interested-in-sports-betting",
		"gambling-addiction-prevention",
		"not-satisfied-with-sports-betting-services",
	],
};

export const lookupsInitialDataOasisEnabled = {
	durationAbrvs: [
		"30-days",
		"3-months",
		"4-months",
		"6-months",
		"8-months",
		"12-months",
		"permanent",
	],
	reasonAbrvs: [
		"other-reasons",
		"not-satisfied-with-sports-betting-services",
		"no-longer-interested-in-sports-betting",
	],
};

export const oasisLockLookupsInitialData = {
	oasisDurationAbrvs: [
		"permanent",
		"3-months",
		"4-months",
		"6-months",
		"8-months",
		"1-year",
	],
	oasisReasonCodes: ["01", "03", "04", "02"],
	oasisReasonCodesDisableCode: "99",
};

export const mapArray = (dataArray) => {
	if (!dataArray) return;
	const mapThroughDataArray = dataArray.map((data) => {
		return {
			id: data.id,
			name: data.name,
		};
	});
	return mapThroughDataArray;
};

export function sortArray(array) {
	if (array.length == 0) {
		return;
	}
	const sorted = array.sort(function (a, b) {
		if (a.units == -1 || b.units == -1) {
			return b.units - a.units;
		}
	});
	return sorted;
}

export const filterArray = (arr1, arr2) => {
	if (arr1.length == 0 || arr1.length == 0) {
		return;
	}
	const filtered = arr1.filter((el) => {
		return arr2.indexOf(el) === -1;
	});
	return filtered;
};

export const changeIdToName = (data, reasonTypes, sortDurationTypes) => {
	const changedData = data.map((x) => {
		const getName =
			reasonTypes.find((item) => item.id === x.value) ||
			sortDurationTypes.find((duration) => duration.id === x.value);
		if (getName && getName.name) {
			x.value = getName.name;
		}
		return x;
	});

	return changedData;
};

export const formatDateTime = (date) => {
	return DateTime.fromISO(date).toLocaleString(
		DateTime.DATETIME_SHORT_WITH_SECONDS
	);
};

export const processDirtyFields = (data, dirtyFields) => {
	const touchedFields = {};
	Object.keys(dirtyFields).map((fieldName) => {
		touchedFields[fieldName] = data[fieldName];
	});
	return touchedFields;
};

export function sortCheckboxArray(array) {
	if (array.length == 0) {
		return;
	}
	const sortedCheckboxList = array.sort(function (a, b) {
		return a.code - b.code;
	});
	return sortedCheckboxList;
}

export const removeUndefinedFields = (data) => {
	const formData = {};

	Object.keys(data).forEach((fieldName) => {
		if (data[fieldName] !== undefined) {
			formData[fieldName] = data[fieldName];
		}
	});

	return formData;
};

export const mapDataForPasswordConfirmation = (
	data,
	dirtyFields,
	reasonTypes,
	sortDurationTypes
) => {
	const processedData = processDirtyFields(data, dirtyFields);
	const dataEntries = Object.entries(processedData);

	let modifiedEntry;
	const mappedData = dataEntries.map((entry) => {
		const modifiedEntryStart = entry[0].slice(0, 4);
		const modifiedEntryEnd = entry[0].slice(4);
		if (modifiedEntryStart.toLowerCase() == "lock") {
			modifiedEntry =
				modifiedEntryStart + "_" + modifiedEntryEnd + "_LABEL";
		} else if (entry[0] === "isPromoApproved") {
			return null;
		} else {
			modifiedEntry = entry[0].toUpperCase() + "_LABEl";
		}
		return {
			name:
				"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK." +
				modifiedEntry.toUpperCase(),
			value: entry[1],
		};
	});
	const filteredData = mappedData.filter((x) => x != null);
	const getIDValues = changeIdToName(
		filteredData,
		reasonTypes,
		sortDurationTypes
	);
	return getIDValues;
};

export const legalLockTranslationKeys = [
	"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ACCOUNT_LOCK_INFO_MSG_FIRST",
	"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ACCOUNT_LOCK_INFO_MSG_SECOND",
	"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ACCOUNT_LOCK_INFO_MSG_THIRD",
	"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ACCOUNT_LOCK_INFO_MSG_FOURTH",
	"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ACCOUNT_LOCK_INFO_MSG_FIFTH",
];
