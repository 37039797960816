import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ForgotPasswordFormSection, ForgotPasswordSuccess } from ".";
import { DateTime } from "luxon";
import { setTabTitle } from "@utils";
import GoogleRecaptchaWrapper from "@v2/components/shared/GoogleRecaptchaWrapper";

export default function ForgotPasswordPage() {
	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			userName: "",
			dateOfBirth: DateTime.now().minus({ years: 18 }).toString(),
		},
	});

	const { t } = useTranslation();

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.FORGOT_PASSWORD.TITLE"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
	}, []);

	const [isForgotPasswordSuccess, setIsForgotPasswordSuccess] =
		useState(false);

	return (
		<FormProvider {...methods}>
			<div className="container">
				<ForgotPasswordFormSection
					isForgotPasswordSuccess={isForgotPasswordSuccess}
					setIsForgotPasswordSuccess={setIsForgotPasswordSuccess}
				/>

				<ForgotPasswordSuccess
					isForgotPasswordSuccess={isForgotPasswordSuccess}
				/>
			</div>
		</FormProvider>
	);
}
