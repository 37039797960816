import { ModalInfoType, ModalStatus, ModalType } from "@data-types";
import { computed, observable, action } from "mobx";

export default class CookieConsentStore {
	@observable isConsentModalShown = false;
	@observable isPreferencesModalShown = false;

	@computed get shouldBlockOtherPopups() {
		return this.isConsentModalShown || this.isPreferencesModalShown;
	}

	@action.bound
	updateModalStatus(modalInfo: ModalInfoType, visibility: ModalStatus) {
		if (modalInfo.modalName === ModalType.CONSENT_MODAL) {
			this.isConsentModalShown = visibility === ModalStatus.SHOW;
		} else if (modalInfo.modalName === ModalType.PREFERENCES_MODAL) {
			this.isPreferencesModalShown = visibility === ModalStatus.SHOW;
		}
	}
}
