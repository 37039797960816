import React from "react";
import { useTranslation } from "react-i18next";

import { defaultTemplate } from "@v2/hoc";
import { Currency } from "@v2/components/shared";
import BetSlipStore from "@v2/state/bet-slip/BetSlipStore";
import NumberInput from "./NumberInput";

export default defaultTemplate(function OneClickBet(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	return (
		<div className="oneclick">
			{props.store.isLoading || props.store.isCountdownVisible ? (
				<div className="oneclick__loader">
					<div className="loader--countdown__inner">
						{props.store.isCountdownVisible && (
							<div className="loader--countdown__box loader--countdown__box--sml">
								{props.store.countdownCounter}
							</div>
						)}
						<div className="loader--countdown__title">
							{t<string>("HOME.LOADING")}...
						</div>
					</div>
				</div>
			) : null}
			<div className="oneclick__body">
				<div className="oneclick__amount">
					<label className="oneclick__label label">
						{t<string>("BET_SLIP.ONE_CLICK_BET.CHOOSE_AMOUNT")}
					</label>

					<input
						id="1"
						type="radio"
						name="amount"
						onClick={(e) =>
							props.store.onOneClickBetAmountChange(5)
						}
						className="oneclick__radio"
					/>
					<label htmlFor="1" className="u-mar--right--xtny">
						{t("BET_SLIP.ONE_CLICK_BET.AMOUNT_5")}
						<Currency />
					</label>

					<input
						id="2"
						type="radio"
						name="amount"
						onClick={(e) =>
							props.store.onOneClickBetAmountChange(10)
						}
						className="oneclick__radio"
					/>
					<label htmlFor="2" className="u-mar--right--xtny">
						{t("BET_SLIP.ONE_CLICK_BET.AMOUNT_10")}
						<Currency />
					</label>

					<input
						id="3"
						type="radio"
						name="amount"
						onClick={(e) =>
							props.store.onOneClickBetAmountChange(15)
						}
						className="oneclick__radio"
					/>
					<label htmlFor="3">
						{t<string>("BET_SLIP.ONE_CLICK_BET.AMOUNT_15")}
						<Currency />
					</label>
				</div>
				<div className="oneclick__custom">
					<label
						htmlFor="customValue"
						className="oneclick__label label"
					>
						{t<string>("BET_SLIP.ONE_CLICK_BET.CUSTOM_AMOUNT")}
					</label>
					<div className="oneclick__custom__field">
						<NumberInput
							id="customValue"
							className="oneclick__custom__input input input--base"
							step=".01"
							min={0}
							type="number"
							onChange={(value) =>
								props.store.onOneClickBetAmountChange(value)
							}
							value={props.store.oneClickBetAmount}
						/>
						<span className="oneclick__custom__currency">
							<Currency />
						</span>
					</div>
				</div>
			</div>
		</div>
	);
});
