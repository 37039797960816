import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, FormButton } from "../buttons";
import { SearchInputField } from "../inputs";
import { useRootOfferStore } from "@hooks";
import { useAppContext } from "@common/hooks";
import { useLocation } from "react-router-dom";
import { getCurrentCulture } from "@utils";

export default observer(function Search() {
	const [isSearchBarActive, setIsSearchBarActive] = useState(false);
	const [isHover, setIsHover] = useState(false);

	const { pathname } = useLocation();

	const rootOfferStore = useRootOfferStore();

	const showSearchIcon =
		pathname.includes(`/${getCurrentCulture()}/home`) ||
		pathname.includes(`/${getCurrentCulture()}/live`) ||
		pathname.includes(`/${getCurrentCulture()}/sports`) ||
		pathname.includes(`/${getCurrentCulture()}/search`) ||
		pathname.includes(`/${getCurrentCulture()}/results`);

	const isResultsPage = pathname.includes(`/${getCurrentCulture()}/results`);

	if (!showSearchIcon) return null;

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			search: rootOfferStore.searchBarViewStore.searchTerm,
		},
	});

	useEffect(() => {
		methods.reset({ search: rootOfferStore.searchBarViewStore.searchTerm });
	}, [rootOfferStore.searchBarViewStore.searchTerm]);

	const onSuccess = (data: { search: string }, event: any) => {
		const { search } = data;

		rootOfferStore.searchBarViewStore.onTermChange(search);
		rootOfferStore.searchBarViewStore.onSubmit(
			{ event: event, period: "full" },
			isResultsPage ? () => {} : null
		);

		methods.resetField("search", {
			defaultValue: rootOfferStore.searchBarViewStore.searchTerm,
		});
	};

	return isSearchBarActive ? (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSuccess)}
				className="searchbar is-active"
			>
				<SearchInputField
					inputClassName="searchbar__input input"
					customErrorClassName="searchbar__validation message--validation field-validation-error"
					name="search"
					validate={(value: string) =>
						value.trim().length >= 4 || "SEARCH.INFO"
					}
					placeholder="HEADER.SEARCH_PLACEHOLDER"
					autoFocus
				/>
				<FormButton
					className="searchbar__btn btn--reset btn--ghost"
					iconOnly={true}
					iconClassName="u-icon--med u-icon--search--alt u-color--text--primary"
				/>
				<Button
					btnType="button"
					className="searchbar__close btn--reset btn--ghost"
					iconOnly={true}
					iconClassName="u-icon--xsml u-icon--close"
					onClick={() => {
						setIsSearchBarActive(false);
						setIsHover(false);
					}}
				/>
			</form>
		</FormProvider>
	) : (
		<Button
			className="header__search btn--reset btn--ghost"
			iconOnly={true}
			iconClassName={
				"header__search__icon u-icon u-icon--med u-icon--search--alt" +
				(isHover
					? " u-color--brand--highlight"
					: " u-color--core--snow")
			}
			onClick={() => setIsSearchBarActive(true)}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		/>
	);
});
