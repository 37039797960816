import React, { useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import MessageWrapper from "./MessageWrapper";
import CustomMessage from "./CustomMessage";
import { ActiveSharedBetSlip } from "@data-types/chat";
import { LoginResponseUser } from "@api-types/user/LoginResponseDto";
import OutsideClickDetector from "@v2/components/shared/utility/OutsideClickDetector";
import { useRootAppStore } from "@hooks";

export default observer(function MessageContainer(props: {
	message: Amity.Message & {
		data: {
			text?: string;
			sharedSlip?: ActiveSharedBetSlip;
		};
		metadata: {
			isLink?: boolean;
		};
	};
}) {
	const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);

	const { message } = props;
	const messageObj = message.data;
	const user = App.state.rootStore.userAuthStore.user;

	return (
		<>
			<div
				className={classNames("chat__row", {
					"chat__row--highlighted": isUserInfoOpen,
				})}
			>
				<PreMessageContent
					isUserInfoOpen={isUserInfoOpen}
					setIsUserInfoOpen={setIsUserInfoOpen}
					message={message}
					user={user}
				/>

				{message.data.sharedSlip == null ? (
					<MessageWrapper
						isLink={message.metadata?.isLink}
						anchor={message.data.text}
					>
						<span>{message.data.text}</span>
					</MessageWrapper>
				) : (
					<CustomMessage
						customMessageData={messageObj}
						isLink={message.metadata?.isLink}
					/>
				)}
			</div>
		</>
	);
});

function PreMessageContent(props: {
	isUserInfoOpen: boolean;
	setIsUserInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
	message: Amity.Message & {
		data: {
			text?: string;
			sharedSlip?: ActiveSharedBetSlip;
		};
		metadata: {
			isLink?: boolean;
		};
	};
	user: LoginResponseUser | null;
}) {
	const { t } = useTranslation();
	const { chatViewStore } = useRootAppStore();

	const isUserCreator = props.message.creatorId === props.user?.chatName;

	const openUserInfo = () => {
		if (!isUserCreator) {
			props.setIsUserInfoOpen(true);
		}
	};

	return (
		<>
			{props.isUserInfoOpen && (
				<OutsideClickDetector
					onOutsideClick={() => props.setIsUserInfoOpen(false)}
					wrapperClasses="chat__flag"
				>
					<div
						className="chat__flag__inner btn btn--base btn--secondary--inverted btn--icon u-color--ui--danger"
						onClick={async () => {
							props.setIsUserInfoOpen(false);
							await chatViewStore.chatChannelHandler.checkIfMessageShouldBeFlagged(
								props.message.uniqueId,
								props.message.creatorId
							);
						}}
					>
						<i className="u-icon u-icon--med u-icon--alert-circle--bold u-color--ui--danger" />
						<span className="u-type--sml">
							{t("CHAT.REPORT_MESSAGE")}
						</span>
					</div>
				</OutsideClickDetector>
			)}
			<span
				className={classNames("chat__pointer", {
					"is-visible": !isUserCreator,
				})}
				onClick={openUserInfo}
			>
				<span
					className="u-color--text--tertiary"
					id={props.message.uniqueId}
				>
					{new Date(props.message.createdAt)
						.getHours()
						.toString()
						.padStart(2, "0") +
						":" +
						new Date(props.message.createdAt)
							.getMinutes()
							.toString()
							.padStart(2, "0")}
				</span>
				<span
					className={`u-type--wgt--bold u-mar--right--xtny u-mar--left--nano ${
						isUserCreator ? "u-color--core--vibrant" : ""
					}`}
				>
					{props.message.creatorId}:
				</span>
			</span>
		</>
	);
}
