import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import classNames from "classnames";
import { ErrorMessage, RequiredMark, PopoverMessage } from ".";
import useLocaleDateFormatForDatePicker from "@v2/hooks/shared/useLocaleDateFormatForDatePicker";
import { getCurrentCulture } from "@utils";

//#region lazy

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
const loadFailPath = `/${getCurrentCulture()}/app-update`;
const ReactDatePicker = lazy(loadFailPath, () =>
	import("react-datetime-picker/dist/entry.nostyle")
);

//#endregion lazy

const DatePicker = (props) => {
	const {
		label,
		labelClassName,
		name,
		className,
		errorMessage = undefined,
		required = false,
		disabled = false,
		calendarDisabled = false,
		readOnly,
		validate,
		format,
		disableClearIcon,
		popoverMsg = null,
		...dateTimePickerProps
	} = props;

	const { t } = useTranslation();
	const { control } = useFormContext();
	const currentDateFormat = useLocaleDateFormatForDatePicker();

	return (
		<div className={className}>
			<Label data={props} />
			<ErrorMessage name={name} />
			<Controller
				name={name}
				control={control}
				rules={{
					required: {
						value: required,
						message: errorMessage || "COMMON.REQUIRED_FIELD",
					},
					validate: validate,
				}}
				render={({ field }) => (
					<ReactDatePicker
						className={
							readOnly ? "react-datetime-picker--readonly" : ""
						}
						value={
							field.value
								? DateTime.fromISO(field.value).toJSDate()
								: undefined
						}
						onChange={(date) =>
							field.onChange(date ? date.toISOString() : "")
						}
						locale={getCurrentCulture()}
						dayPlaceholder={t("COMMON.DAY_PLACEHOLDER")}
						monthPlaceholder={t("COMMON.MONTH_PLACEHOLDER")}
						yearPlaceholder={t("COMMON.YEAR_PLACEHOLDER")}
						format={format || currentDateFormat}
						disableCalendar={calendarDisabled}
						clearIcon={
							disabled || disableClearIcon ? null : undefined
						}
						disabled={disabled}
						{...dateTimePickerProps}
					/>
				)}
			/>
		</div>
	);
};
export default DatePicker;

function Label(props) {
	const { t } = useTranslation();
	const [showPopoverMsg, setShowPopoverMsg] = useState(false);

	const labelClasses = classNames("label", props.data.labelClassName);

	if (!props.data.label) {
		return null;
	}

	return (
		<label
			className={labelClasses}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				setShowPopoverMsg(true);
			}}
		>
			{t(props.data.label)}
			<RequiredMark required={props.data.required} />
			<PopoverMessage
				showPopoverMsg={showPopoverMsg}
				setShowPopoverMsg={setShowPopoverMsg}
				popoverMsg={props.data.popoverMsg}
			/>
		</label>
	);
}
