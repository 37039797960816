import { EmailChangeService } from "@services/account-settings/EmailChangeService";

export default function useAccountActivationEmailSection() {
	const checkForEmailAvailiability = async (value: string) => {
		return await EmailChangeService.checkIsEmailAvailable(value);
	};

	return {
		checkForEmailAvailiability,
	};
}
