import React from "react";
import {
	CashoutHistoryModal,
	CancelBetSlipPopUp,
	CashoutCounter,
	ReuseBetConfirmationPopUp,
	CashoutConfirmationPopUp,
	CancelBetSlipMessagePopUp,
	ReuseSharedBetSlipPopUp,
	ShareBetSlipModal,
} from "./";

export default function Modals(props: { isOnOffer?: boolean }) {
	return (
		<>
			<CashoutHistoryModal />
			<CancelBetSlipPopUp />
			<CashoutCounter />
			<ReuseBetConfirmationPopUp isOnOffer={props.isOnOffer} />
			<ReuseSharedBetSlipPopUp isOnOffer={props.isOnOffer} />
			<ShareBetSlipModal />
			<CashoutConfirmationPopUp />
			<CancelBetSlipMessagePopUp />
		</>
	);
}
