import React, { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { WithdrawalContext } from "@context";
import { WalletItem } from "@v2/components/desktop/shared/inputs";
import { isCasinoEnabled } from "@v2/helpers";

export const WithdrawalWalletSelector: FC = observer(
	function WithdrawalWalletSelector() {
		const {
			onlineWallet,
			casinoWallet,
			liveCasinoWallet,
			gigWallet,
			shouldShowWalletSelectors,
		} = useContext(WithdrawalContext);
		return (
			<ul className="wallet">
				<WalletItem
					wallet={onlineWallet}
					isList
					hidden={!shouldShowWalletSelectors}
				/>
				{isCasinoEnabled ? (
					<WalletItem
						wallet={casinoWallet}
						isList={true}
						hidden={!shouldShowWalletSelectors}
					/>
				) : null}
				{WEBPACK_SHOW_LIVE_CASINO_WALLET_PAYMENT ? (
					<WalletItem
						wallet={liveCasinoWallet}
						isList={true}
						hidden={!shouldShowWalletSelectors}
					/>
				) : null}
				<WalletItem
					wallet={gigWallet}
					isList
					showRadioSelectIfDisabled
					isDisabled
					shouldRegister={false}
				/>
			</ul>
		);
	}
);
