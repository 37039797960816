import { ReportOptionsDto } from "@api-types/reports/ReportOptionsDto";
import { ReportResultDto } from "@api-types/reports/ReportResultDto";
import { httpClient, getAgencyId, getApplicationHeaders } from "@utils";
import { UserTypes } from "@utils";

export class ReportApiService {
	static baseUrl(): string {
		return `${WEBPACK_BASE_ADDRESS}platform/reports`;
	}

	static getRequestHeaders = (owner = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(owner);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static getMyBetsReport(
		startDate: string,
		endDate: string,
		bettingAccountTypeAbrv: string,
		accountOwner = UserTypes.PRIMARY
	) {
		const requestHeaders = this.getRequestHeaders(accountOwner);
		// @ts-expect-error
		requestHeaders["Accept"] = "application/json";
		var filter = {
			filter: {
				accessChannels: {
					agencies: [
						{
							id: getAgencyId(accountOwner),
						},
					],
				},
				parameters: [
					{
						column: "StartDate",
						value: startDate,
					},
					{
						column: "EndDate",
						value: endDate,
					},
					{
						column: "BettingAccountTypeAbrv",
						value: bettingAccountTypeAbrv,
					},
				],
			},
		};
		return httpClient.post(
			ReportApiService.baseUrl() + "/mybets",
			JSON.stringify(filter),
			requestHeaders,
			{ responseType: "json" }
		);
	}

	static async fetchUserAccountActivity(
		filter: ReportOptionsDto,
		owner = UserTypes.PRIMARY
	): Promise<ReportResultDto> {
		const requestHeaders = this.getRequestHeaders(owner);
		// @ts-expect-error
		requestHeaders["Accept"] = "application/json";
		return await httpClient.post(
			`${this.baseUrl()}/AccountActivityReview`,
			JSON.stringify(filter),
			requestHeaders,
			{ responseType: "json" }
		);
	}
	static async fetchUserBettingActivity(
		filter: ReportOptionsDto,
		owner = UserTypes.PRIMARY
	): Promise<ReportResultDto> {
		const requestHeaders = this.getRequestHeaders(owner);
		// @ts-expect-error
		requestHeaders["Accept"] = "application/json";

		return await httpClient.post(
			`${this.baseUrl()}/BettingActivityReview`,
			JSON.stringify(filter),
			requestHeaders,
			{ responseType: "json" }
		);
	}

	static getGamingHistoryReport(
		startDate: string,
		endDate: string,
		accountOwner = UserTypes.PRIMARY
	): Promise<ReportResultDto> {
		const requestHeaders = this.getRequestHeaders(accountOwner);
		// @ts-expect-error
		requestHeaders["Accept"] = "application/json";
		var filter = {
			filter: {
				accessChannels: {
					shops: [
						{
							agencyId: getAgencyId(accountOwner),
						},
					],
				},
				parameters: [
					{
						column: "DateFrom",
						value: startDate,
					},
					{
						column: "DateTo",
						value: endDate,
					},
					{
						column: "CasinoProviderGameTypeAbrv",
						value: "casino-game",
					},
				],
			},
		};
		return httpClient.post(
			ReportApiService.baseUrl() + "/GamingActivityReview",
			JSON.stringify(filter),
			requestHeaders,
			{ responseType: "json" }
		);
	}
}

export default ReportApiService;
