/*
    Here’s an example of how the links might look:

    For iOS: https://apps.apple.com/app/[Your-App-Name]/[App-ID]
    For Android: https://play.google.com/store/apps/details?id=[Your-Package-Name]

    Replace [Your-App-Name] with your app’s name, 
    [App-ID] with your iOS app’s unique identifier, 
    and [Your-Package-Name] with your Android app’s package name.
*/

export const iOSAppStoreURL = `https://apps.apple.com/us/app/${WEBPACK_CAPACITOR_APP_DISPLAY_NAME}/id${WEBPACK_CAPACITOR_APPLE_STORE_NAVIGATION_ID}`;

export const androidAppStoreUrl = `https://play.google.com/store/apps/details?id=${WEBPACK_CAPACITOR_APP_IDENTIFIER}`;
