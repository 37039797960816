import React from "react";
import { observer } from "mobx-react";
import { useRootAppStore } from "@hooks";

export default observer(function ChatLoader() {
	const { chatViewStore } = useRootAppStore();

	if (!chatViewStore?.shouldRenderMainLoader) {
		return null;
	}

	return (
		<div className="loader chat__loader">
			<div className="loader__spinner">
				<div className="loader__spinner__wrapper">
					<div />
					<div />
					<div />
				</div>
			</div>
		</div>
	);
});
