import { ActivationUserData } from "./UserData";

export class ActivationErrorResponse {
    message: string | null;
    errorCode: string;
    userData : ActivationUserData;

    constructor(message: string, errorCode: string, userData: ActivationUserData){
        this.message = message;
        this.errorCode = errorCode;
        this.userData = userData;
    }
}