import { isCapacitorPlatform } from "@utils/specific/capacitor";
import { LoginResponseUser } from "./../../data/api-types/user/LoginResponseDto";
import { DeviceDetector, getCurrentLanguage, UserTypes } from "@utils";

export function isUserAuthenticated(
	activeAccount: UserTypes = UserTypes.PRIMARY
): boolean {
	let userToken = getUserToken(activeAccount);
	if (userToken) {
		return true;
	}
	return false;
}

export function getShopId(
	activeAccount: UserTypes = UserTypes.PRIMARY
): string {
	if (activeAccount === UserTypes.PRIMARY) {
		return App.state.rootStore.userAuthStore.shopId;
	}
	return App.state.rootStore.userAuthStore.secondaryShopId;
}

export function getAgencyKey(
	activeAccount: UserTypes = UserTypes.PRIMARY
): string {
	if (activeAccount == UserTypes.PRIMARY) {
		return App.state.rootStore.userAuthStore.agencyKey;
	}
	return App.state.rootStore.userAuthStore.secondaryAgencyKey;
}

export function getAgencyId(
	activeAccount: UserTypes = UserTypes.PRIMARY
): string {
	if (activeAccount == UserTypes.PRIMARY) {
		return App.state.rootStore.userAuthStore.agencyId;
	}
	return App.state.rootStore.userAuthStore.secondaryAgencyId;
}

export function getUserId(
	activeAccount: UserTypes = UserTypes.PRIMARY
): string {
	if (activeAccount == UserTypes.PRIMARY) {
		return App.state.rootStore.userAuthStore.user?.id || "";
	}
	return App.state.rootStore.userAuthStore.secondaryUser?.id || "";
}

export function getUsername(
	activeAccount: UserTypes = UserTypes.PRIMARY
): string {
	if (activeAccount == UserTypes.PRIMARY) {
		return App.state.rootStore.userAuthStore.user?.username || "";
	}
	return App.state.rootStore.userAuthStore.secondaryUser?.username || "";
}

export function getApplicationHeaders(
	activeAccount: UserTypes = UserTypes.PRIMARY,
	shouldSetToken: boolean = true
): HeadersInit {
	return getApplicationHeadersBase(activeAccount, shouldSetToken);
}

export function getMvcApplicationHeaders(
	activeAccount: UserTypes = UserTypes.PRIMARY,
	shouldSetToken: boolean = true
): HeadersInit {
	var headers = getApplicationHeadersBase(activeAccount, shouldSetToken);

	// only MVC (our web api) requires agency and web device headers
	headers["Agency-Key" as keyof HeadersInit] = getAgencyKey(activeAccount);
	headers["Agency-Id" as keyof HeadersInit] = getAgencyId(activeAccount);

	let webDeviceValue = WEBPACK_ENVIRONMENT_KEY;
	if (isCapacitorPlatform()) {
		webDeviceValue = "CapacitorApp";
	} else if (DeviceDetector.isMobileApp) {
		webDeviceValue = "Mobile";
	}
	headers["Web-Device" as keyof HeadersInit] = webDeviceValue;

	return headers;
}

// base function to get headers, should never be exported
function getApplicationHeadersBase(
	activeAccount: UserTypes = UserTypes.PRIMARY,
	shouldSetToken: boolean = true
) {
	let headers: HeadersInit = {
		"Accept-Language": getCurrentLanguage(),
		"Shop-Id": getShopId(activeAccount),
		"Caller-Environment": WEBPACK_ENVIRONMENT_KEY,
	};

	let token = getUserToken(activeAccount);
	if (token && token.token && shouldSetToken) {
		headers["Authorization"] = "Bearer " + token.token;
	}
	return headers;
}

export function getUserToken(
	activeAccount: UserTypes = UserTypes.PRIMARY
): { token: string } | null {
	if (activeAccount === UserTypes.PRIMARY) {
		return App.state.rootStore.userAuthStore.token;
	}
	return App.state.rootStore.userAuthStore.secondaryToken;
}

// TODO user obj
export function getUserFromLocalStorage(
	activeAccount: UserTypes = UserTypes.PRIMARY
): LoginResponseUser | null {
	if (activeAccount == UserTypes.PRIMARY) {
		return App.state.rootStore.userAuthStore.user;
	}

	return App.state.rootStore.userAuthStore.secondaryUser;
}

export function getSecondaryUser(): LoginResponseUser | null {
	return App.state.rootStore.userAuthStore.secondaryUser;
}

export function getAccountActivationToken(): string | null {
	return (
		App.state.rootStore.userAuthStore.accountActivation?.activationToken ||
		null
	);
}
