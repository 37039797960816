import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useRegistrationStore } from "@v2/context/RegistrationStoreContext";
import { Button } from "@components/buttons";
import { Link } from "react-router-dom";
import { getCurrentCulture } from "@utils";
import {
	RegistrationCountry,
	RegistrationDOB,
	RegistrationEmail,
	RegistrationFirstName,
	RegistrationLastName,
	RegistrationPassword,
	RegistrationPhone,
	RegistrationTitle,
	RegistrationUsername,
} from "@components-desktop/membership/registration";
import RegistrationPersonalNumber from "@components-desktop/membership/registration/inputs/RegistrationPersonalNumber";
import { useFormContext } from "react-hook-form";
import { FormSelectField } from "@v2/components/desktop/shared/inputs";
import { validateCountry } from "@v2/helpers/membership/registration/utils";
import { useRootAppStore } from "@hooks";

export const RegistrationAccountDetails = observer(
	function RegistrationAccountDetails() {
		const { t } = useTranslation();
		const { trigger, setValue, getValues } = useFormContext();

		const { isIpRestricted } = useRootAppStore();

		const [isNextButtonDisabled, setIsNextButtonDisabled] =
			useState<boolean>(false);

		const {
			titleData,
			countryData,
			checkUserNameAvailability,
			checkIsEmailAvailable,
			checkPasswordSimilarity,
			setCurrentStep,
			toggleStepSwitchFlag,
			isSwitchingSteps,
		} = useRegistrationStore();

		const selectedCountry = countryData?.find(
			(country) => country.abrv === "sweden"
		);

		useEffect(() => {
			setValue("countryId", selectedCountry?.id);
		}, [countryData]);

		useEffect(() => {
			document.querySelector("html, body")?.scrollTo(0, 0);
		}, []);

		return (
			<>
				<div className="card--onboarding__content">
					<RegistrationTitle titleData={titleData} />

					<div className="form__field">
						<div className="row">
							<div className="col col-sml-6">
								<RegistrationFirstName labelClassName="label--strong" />
							</div>
							<div className="col col-sml-6">
								<RegistrationLastName labelClassName="label--strong" />
							</div>
						</div>
					</div>

					<RegistrationDOB
						countryData={countryData}
						labelClassName="label--strong"
					/>

					<RegistrationPersonalNumber labelClassName="label--strong" />

					<FormSelectField
						className="form__field"
						name="countryId"
						label="MEMBERSHIP.REGISTRATION.COUNTRY_LABEL"
						labelClassName="label--strong"
						placeholder="MEMBERSHIP.REGISTRATION.COUNTRY_PLACEHOLDER"
						// do not delete, maybe will be used in the future
						// popoverMsg={"MEMBERSHIP.REGISTRATION.COUNTRY_DESCRIPTION"}
						dropdownData={countryData}
						selected={selectedCountry}
						validate={{
							isCountryValidDependantOnDoB: (value: string) =>
								validateCountry(
									getValues("dob"),
									countryData,
									value
								),
						}}
						required
					/>

					<RegistrationUsername
						checkUserNameAvailability={checkUserNameAvailability}
						labelClassName="label--strong"
					/>

					<RegistrationPassword
						checkPasswordSimilarity={checkPasswordSimilarity}
						labelClassName="label--strong"
					/>

					<RegistrationEmail
						checkIsEmailAvailable={checkIsEmailAvailable}
						labelClassName="label--strong"
					/>

					<RegistrationPhone labelClassName="label--strong" />
				</div>

				<Button
					atClassName="regDetailsBtnNext"
					className="btn--base btn--block btn--primary"
					iconRight={isSwitchingSteps ? false : true}
					iconClassName={
						isSwitchingSteps
							? ""
							: "u-icon--xsml u-icon--arrow--right u-color--core--snow"
					}
					btnText="COMMON.NEXT_BUTTON"
					btnType="button"
					isLoading={isSwitchingSteps}
					isDisabled={isIpRestricted || isNextButtonDisabled}
					onClick={async () => {
						toggleStepSwitchFlag(true);
						setIsNextButtonDisabled(true);
						const isValid = await trigger();
						setIsNextButtonDisabled(false);

						if (isValid) {
							setCurrentStep(2, true);
						}
						toggleStepSwitchFlag(false);
					}}
				/>

				<div className="card--onboarding__footer u-type--sml u-color--text--secondary">
					{t("MEMBERSHIP.REGISTRATION.EXISTING_ACCOUNT")}
					<Link
						to={`/${getCurrentCulture()}/auth/login`}
						className="u-anchor u-anchor--primary u-mar--left--xtny"
					>
						{t("MEMBERSHIP.LOGIN.LOGIN_BUTTON")}
					</Link>
				</div>
			</>
		);
	}
);
