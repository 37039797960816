import { useEffect } from "react";
import * as Cookie from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import { ModalInfoType, ModalStatus } from "@data-types";
import { useGenerateNecessaryCookiesList } from "@hooks/useGenerateNecessaryCookiesList";
import { isCapacitorPlatform } from "@utils/specific/capacitor";

export default observer(function CookieConsent() {
	const culture = getCurrentCulture();
	const { t } = useTranslation();

	const {
		cookieConsentStore: { updateModalStatus },
	} = useRootAppStore();

	const generatedCookies = useGenerateNecessaryCookiesList();

	useEffect(() => {
		Cookie.run({
			root: isCapacitorPlatform() ? ".l__app" : document.body,
			onModalShow: (modalInfo: ModalInfoType) => {
				updateModalStatus(modalInfo, ModalStatus.SHOW);
			},

			onModalHide: (modalInfo: ModalInfoType) => {
				updateModalStatus(modalInfo, ModalStatus.HIDE);
			},

			//bump number up if we change cookie usage to show users cookie consent again
			revision: 1,

			autoShow: true,

			manageScriptTags: true,

			guiOptions: {
				consentModal: {
					layout: "box wide",
					position: "bottom right",
					equalWeightButtons: true,
					flipButtons: false,
				},
				preferencesModal: {
					layout: "box",
					equalWeightButtons: true,
					flipButtons: false,
				},
			},

			categories: {
				necessary: {
					enabled: true,
					readOnly: true,
					autoClear: {
						cookies: [
							{
								name: "cc_cookie",
							},
							{
								name: "lng",
							},
							{
								name: "cgp-token",
							},
						],
					},
				},
				analytics: {
					enabled: false,
					readOnly: false,
					autoClear: {
						reloadPage: true,
						cookies: [
							{
								name: /^(_ga)/,
							},
						],
					},
				},
				ads: {
					enabled: false,
					readOnly: false,
					autoClear: {
						reloadPage: true,
						cookies: [
							{
								name: "_fbp",
							},
						],
					},
				},
			},

			language: {
				default: "en",
				translations: {
					en: {
						consentModal: {
							title: t("COOKIE.CONSENT.MODAL_TITLE"),
							description: t("COOKIE.CONSENT.MODAL_DESCRIPTION"),
							acceptAllBtn: t("COOKIE.CONSENT.ACCEPT_ALL_BTN"),
							acceptNecessaryBtn: t(
								"COOKIE.CONSENT.REJECT_ALL_BTN"
							),
							showPreferencesBtn: t(
								"COOKIE.CONSENT.SHOW_PREFERENCES_BTN"
							),
						},
						preferencesModal: {
							title: t("COOKIE.PREFERENCES.MODAL_TITLE"),
							acceptAllBtn: t(
								"COOKIE.PREFERENCES.ACCEPT_ALL_BTN"
							),
							acceptNecessaryBtn: t(
								"COOKIE.PREFERENCES.REJECT_ALL_BTN"
							),
							savePreferencesBtn: t(
								"COOKIE.PREFERENCES.SAVE_PREFERENCES_BTN"
							),
							closeIconLabel: t(
								"COOKIE.PREFERENCES.CLOSE_MODAL_BTN"
							),
							sections: [
								{
									title:
										t("COOKIE.PREFERENCES.SECTIONS.TITLE") +
										" 📢",
									description:
										t(
											"COOKIE.PREFERENCES.SECTIONS.DESCRIPTION"
										) +
										`<a href='/${culture}/info/privacy'> ${t(
											"COOKIE.PREFERENCES.SECTIONS.PRIVACY_POLICY"
										)}.</a>`,
								},
								{
									title: t(
										"COOKIE.PREFERENCES.NECESSARY_COOKIES.TITLE"
									),
									description: t(
										"COOKIE.PREFERENCES.NECESSARY_COOKIES.DESCRIPTION"
									),
									linkedCategory: "necessary",
									cookieTable: {
										headers: {
											name: t(
												"COOKIE.TABLE_HEADERS.NAME"
											),
											domain: t(
												"COOKIE.TABLE_HEADERS.DOMAIN"
											),
											expiration: t(
												"COOKIE.TABLE_HEADERS.EXPIRATION"
											),
											desc: t(
												"COOKIE.TABLE_HEADERS.DESCRIPTION"
											),
										},
										body: generatedCookies,
									},
								},
								{
									title: t(
										"COOKIE.PREFERENCES.PERFORMANCE_AND_ANALYTICS_COOKIES.TITLE"
									),
									description: t(
										"COOKIE.PREFERENCES.PERFORMANCE_AND_ANALYTICS_COOKIES.DESCRIPTION"
									),
									linkedCategory: "analytics",
									cookieTable: {
										headers: {
											name: t(
												"COOKIE.TABLE_HEADERS.NAME"
											),
											domain: t(
												"COOKIE.TABLE_HEADERS.DOMAIN"
											),
											expiration: t(
												"COOKIE.TABLE_HEADERS.EXPIRATION"
											),
											desc: t(
												"COOKIE.TABLE_HEADERS.DESCRIPTION"
											),
										},
										body: [
											{
												name: "^_ga",
												domain: location.hostname,
												expiration:
													"400 " +
													t(
														"COOKIE.TABLE_BODY.EXPIRATION"
													),
												desc: t(
													"COOKIE.PREFERENCES.PERFORMANCE_AND_ANALYTICS_COOKIES.TABLE_BODY_GA.DESCRIPTION"
												),
											},
											{
												name: "_ga_",
												domain: location.hostname,
												expiration:
													"400 " +
													t(
														"COOKIE.TABLE_BODY.EXPIRATION"
													),
												desc: t(
													"COOKIE.PREFERENCES.PERFORMANCE_AND_ANALYTICS_COOKIES.TABLE_BODY_GA_.DESCRIPTION"
												),
											},
										],
									},
								},
								{
									title: t(
										"COOKIE.PREFERENCES.ADVERTISEMENT_AND_TARGETING_COOKIES.TITLE"
									),
									description: t(
										"COOKIE.PREFERENCES.ADVERTISEMENT_AND_TARGETING_COOKIES.DESCRIPTION"
									),
									linkedCategory: "ads",
									cookieTable: {
										headers: {
											name: t(
												"COOKIE.TABLE_HEADERS.NAME"
											),
											domain: t(
												"COOKIE.TABLE_HEADERS.DOMAIN"
											),
											expiration: t(
												"COOKIE.TABLE_HEADERS.EXPIRATION"
											),
											desc: t(
												"COOKIE.TABLE_HEADERS.DESCRIPTION"
											),
										},
										body: [
											{
												name: "_fbp",
												domain: location.hostname,
												expiration:
													"90 " +
													t(
														"COOKIE.TABLE_BODY.EXPIRATION"
													),
												desc: t(
													"COOKIE.PREFERENCES.ADVERTISEMENT_AND_TARGETING_COOKIES.TABLE_BODY_FBP.DESCRIPTION"
												),
											},
										],
									},
								},
								{
									title: t(
										"COOKIE.PREFERENCES.MORE_INFORMATION.TITLE"
									),
									description:
										t(
											"COOKIE.PREFERENCES.MORE_INFORMATION.DESCRIPTION"
										) +
										`<a href='/${culture}/info/contact'>${t(
											"COOKIE.PREFERENCES.MORE_INFORMATION.CONTACT_US"
										)}.</a>`,
								},
							],
						},
					},
				},
			},
		});

		//if category not accepted and cookie exists
		const gaCookies = document.cookie
			.split(";")
			.filter((e) => e.includes("_ga"));
		const fbpCookies = document.cookie
			.split(";")
			.filter((e) => e.includes("_fbp"));
		if (!Cookie.acceptedCategory("analytics") && gaCookies) {
			gaCookies.map((gaCookie) =>
				Cookie.eraseCookies(gaCookie.split("=")[0])
			);
		}
		if (!Cookie.acceptedCategory("ads") && fbpCookies) {
			fbpCookies.map((fbCookie) =>
				Cookie.eraseCookies(fbCookie.split("=")[0])
			);
		}
	}, []);

	return null;
});
