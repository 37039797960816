import React, { useRef, useEffect, useContext, useState, useMemo } from "react";
import { WindowScroller, WindowScrollerChildProps } from "react-virtualized";
import {
	SportOffer,
	TournamentOffer,
	EventOffer,
	getEventSortByFavorite,
	FavoritesStore,
} from "@gp/offer";
import ResizeObserver from "resize-observer-polyfill";
import { Sorter } from "@gp/utility";

export type FlatList = {
	tournament?: TournamentOffer;
	event?: EventOffer;
}[];

/**
 * Formats sport offer into list that can be used by ReactVirtualized list
 */
export function useFlatListSport(
	sport: SportOffer,
	favorites?: FavoritesStore
): FlatList {
	return (
		sport.categories?.reduce((acc: FlatList, cat) => {
			cat.tournaments.forEach((t) => {
				acc.push({
					tournament: t,
				});
				if (favorites && favorites.userFavoriteEventsSet?.size > 0) {
					t.events
						.sort(
							Sorter.sort((a, b) => {
								return Sorter.sort(
									(a1: EventOffer, b1: EventOffer) =>
										getEventSortByFavorite(
											a1,
											b1,
											favorites
										),

									"startTime"
								)(a, b);
							})
						)
						.forEach((e) => {
							acc.push({
								event: e,
							});
						});
				} else {
					t.events.forEach((e) => {
						acc.push({
							event: e,
						});
					});
				}
			});
			return acc;
		}, []) || []
	);
}

export const WsPropsContext = React.createContext<WindowScrollerChildProps>({
	onChildScroll: ({ scrollTop }) => {},
	registerChild: (element) => {},
	height: 0,
	isScrolling: false,
	scrollLeft: 0,
	scrollTop: 0,
	width: 0,
});

export const useWsProps = () => useContext(WsPropsContext);

const ScrollerDivContext = React.createContext<HTMLElement | null>(null);
const useScrollerDiv = () => useContext(ScrollerDivContext);

/**
 * @param listWrapperElRef Html element that wraps ReactVirtualized List component
 * @param isFirstItem Result is different if it is first virtualized list or n > 1
 * @returns calculated scroll top for virtualized list when there are multiple virtualized lists
 */
export const useMultiListScrollOffset = (
	listWrapperElRef: HTMLElement | null,
	isFirstItem: boolean,
	scrollElement: Element | typeof window = window,
	listHeight?: number
): number => {
	const scrollerDiv = useScrollerDiv();

	const scrollerHeight = listHeight || scrollerDiv?.clientHeight || 0;
	const scrollerOffset = scrollerDiv?.offsetTop || 0;

	const currentElementTop =
		((scrollElement as typeof window).scrollY ||
			(scrollElement as Element).scrollTop ||
			0) +
		scrollerOffset -
		(listWrapperElRef?.offsetTop || 0);

	const isUnderScrollPosition =
		currentElementTop < -1 * window.innerHeight && !isFirstItem;
	const currentScrollTopForList = isUnderScrollPosition
		? scrollerHeight
		: Math.max(currentElementTop, 0);

	return currentScrollTopForList;
};

/**
 * Overrides windowScroller width with  scrollerElement.clientWidth if provided.
 */
export function WindowsScrollerWrapper(props: {
	/** Parent HTMLElement used to set current width, if not provided window width will be used. */
	scrollElement: HTMLElement | null;
	children: JSX.Element;
}) {
	const [width, setWidth] = useState<number>(0);

	//#region observe element width

	const setWidthRef = useRef(() => {});
	setWidthRef.current = () => setWidth(props.scrollElement?.clientWidth || 0);

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver(() => {
				setWidthRef.current();
			}),
		[]
	);

	useEffect(() => {
		if (props.scrollElement != null) {
			resizeObserver.observe(props.scrollElement);
		}
	}, [props.scrollElement]);

	useEffect(() => {
		return () => resizeObserver.disconnect();
	}, []);

	//#endregion observe element width

	return (
		<WindowScroller scrollElement={props.scrollElement || window}>
			{(wsProps: WindowScrollerChildProps) => {
				// Override ws width with scrollElement width since ws is attached to window
				wsProps.width = width > 0 ? width : wsProps.width;

				return (
					<WsPropsContext.Provider value={wsProps}>
						<ScrollerDivContext.Provider
							value={props.scrollElement}
						>
							{props.children}
						</ScrollerDivContext.Provider>
					</WsPropsContext.Provider>
				);
			}}
		</WindowScroller>
	);
}
