import React from "react";
import classnames from "classnames";
import { range } from "lodash";
import { observer } from "mobx-react";

export default observer(function SkeletonLoader(props: {
	rowCount?: number;
	quotasCount?: number;
	isFullLoader?: boolean;
	isLoading?: boolean;
	name?: string;
}) {
	const { rowCount = 16, quotasCount = 2, isFullLoader = true } = props;

	const loaderClasses = classnames(
		"loader--skeleton offer offer--sport offer--highlights offer--skeleton",
		{
			"offer--sml": !isFullLoader,
			"offer--full": isFullLoader,
		}
	);

	if (!props?.isLoading) {
		return null;
	}

	return (
		<div className={loaderClasses}>
			<div className="offer__head">
				<div className="offer__head__row" />
			</div>
			<div className="offer__body">
				<SkeletonLoaderRows
					rowCount={rowCount}
					quotasCount={quotasCount}
				/>
			</div>
		</div>
	);
});

function SkeletonLoaderRows(props: { rowCount: number; quotasCount: number }) {
	return (
		<>
			{range(0, props.rowCount - 1).map((k) => (
				<SkeletonLoaderRow key={k} quotasCount={props.quotasCount} />
			))}
		</>
	);
}

function SkeletonLoaderRow(props: { quotasCount: number }) {
	return (
		<div className="offer__body__row">
			<div className="offer__body__data offer__day">
				<div className="offer__day__date" />
				<div className="offer__day__time" />
			</div>
			<div className="offer__body__data offer__match">
				<div className="offer__match__icon u-icon u-icon--med" />
				<div className="offer__match__competitors offer__match__competitors--sml">
					<div className="offer__team" />
					<div className="offer__team" />
				</div>
			</div>
			<div className="offer__body__data offer__actions">
				<div className="offer__actions__btn btn btn--square--reset btn--link btn--icon">
					<div className="offer__actions__icon u-icon u-icon--med" />
				</div>
				<div className="offer__actions__btn btn btn--square--reset btn--link btn--icon">
					<div className="offer__actions__icon u-icon u-icon--med" />
				</div>
			</div>
			<SkeletonLoaderQuotaColumns quotasCount={props.quotasCount} />
			<div className="offer__body__data offer__more">
				<div className="offer__more__icon u-icon u-icon--tny" />
			</div>
		</div>
	);
}

function SkeletonLoaderQuotaColumns(props: { quotasCount: number }) {
	return (
		<>
			{range(0, props.quotasCount).map((k) => (
				<SkeletonLoaderQuota key={k} />
			))}
		</>
	);
}

function SkeletonLoaderQuota() {
	return (
		<div className="offer__body__data offer__quotes">
			<div className="quote quote--button" />
			<div className="quote quote--button" />
			<div className="quote quote--button" />
		</div>
	);
}
