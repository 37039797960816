import React, { useContext, useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useParams, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SportOffer, EventOffer, LookupsStore } from "@gp/offer";
import { LiveEventSideMenuContext } from "@v2/context";
import OfferIcon from "@v2/components/shared/icons/Offer";
import { useRootOfferStore } from "@hooks";

export const LiveEventSideMenu = observer(function LiveEventSideMenu() {
	const { culture, eventIds } = useParams<{
		culture: string;
		eventIds: string;
	}>();
	const liveEventMenuViewStore = useContext(LiveEventSideMenuContext);

	useEffect(() => {
		liveEventMenuViewStore.onInitialize();
		return () => liveEventMenuViewStore.onDispose();
	}, []);

	useEffect(() => {
		liveEventMenuViewStore.setSelectedEventIds(eventIds);
	}, [eventIds]);

	return (
		<>
			<nav className="nav--secondary">
				<ul className="nav--secondary__menu">
					{/* <div className="l--header__toggle">
						<button
							id="burger"
							className="l--header__toggle__btn"
							type="button"
							onClick={() =>
								liveEventMenuViewStore.expandCollapseMenu()
							}
						>
							<span />
							<span />
							<span />
						</button>
					</div> */}
					<LiveFavoritesItem culture={culture} />
					<SportsList
						sportsList={
							liveEventMenuViewStore.eventsInSports.sports
						}
					/>
					<NavigationItemTwo culture={culture} />
				</ul>
			</nav>
			{liveEventMenuViewStore.isMenuExpanded && (
				<nav id="side-menu" className="nav--tertiary">
					<ul className="nav--tertiary__menu">
						<li className="nav--tertiary__item">
							<ul className="nav--quaternary">
								<SubMenuList
									culture={culture}
									eventOfferList={
										liveEventMenuViewStore.elementSubMenu
									}
									lookupsStore={
										liveEventMenuViewStore.lookupsStore
									}
								/>
							</ul>
						</li>
					</ul>
				</nav>
			)}
		</>
	);
});

const LiveFavoritesItem = observer(function LiveFavoritesItem(props: {
	culture: string;
}) {
	const { culture } = props;

	const liveEventMenuViewStore = useContext(LiveEventSideMenuContext);

	const {
		liveCount,
		liveEventsFromMyBets: {
			count: playedLiveEventsCount,
			isEnabled: isPlayedLiveEventsEnabled,
		},
		liveFavoritesCounter,
	} = liveEventMenuViewStore;

	const { t } = useTranslation();

	return (
		<li className="nav--secondary__item">
			<NavLink
				className="nav--secondary__link"
				activeClassName="is-expanded"
				exact
				to={`/${culture}/live/events`}
			>
				<i className="nav--secondary__icon u-icon u-icon--lrg u-icon--all" />

				<div className="nav--secondary__title">
					{t<string>("LIVE.SIDEMENU.ALL")}
				</div>
				{liveCount > 0 && (
					<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
						{liveCount}
					</span>
				)}
			</NavLink>
			{isPlayedLiveEventsEnabled && playedLiveEventsCount > 0 && (
				<NavLink
					className="nav--secondary__link"
					activeClassName="is-expanded"
					to={`/${culture}/live/played-events`}
				>
					<i className="nav--secondary__icon u-icon u-icon--lrg u-icon--played-events" />
					<div className="nav--secondary__title">
						{t<string>("LIVE.SIDEMENU.PLAYED_EVENTS")}
					</div>
					{playedLiveEventsCount > 0 && (
						<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
							{playedLiveEventsCount}
						</span>
					)}
				</NavLink>
			)}
			{liveFavoritesCounter > 0 && (
				<NavLink
					className="nav--secondary__link"
					activeClassName="is-expanded"
					to={`/${culture}/live/my-favorites`}
				>
					<i className="nav--secondary__icon u-icon u-icon--lrg u-icon--favorites" />
					<div className="nav--secondary__title">
						{t<string>("LIVE.SIDEMENU.FAVORITES")}
					</div>
					{liveFavoritesCounter > 0 && (
						<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
							{liveFavoritesCounter}
						</span>
					)}
				</NavLink>
			)}
		</li>
	);
});

const NavigationItemTwo = observer(function NavigationItemTwo(props: {
	culture: string;
}) {
	const liveEventMenuStore = useContext(LiveEventSideMenuContext);

	const { t } = useTranslation();

	return (
		<li className="nav--secondary__item">
			<NavLink
				className="nav--secondary__link"
				activeClassName="is-expanded"
				to={`/${props.culture}/live/upcoming`}
			>
				<i className="nav--secondary__icon u-icon u-icon--lrg u-icon--upcoming" />
				<div className="nav--secondary__title">
					{t<string>("LIVE.SIDEMENU.UPCOMING")}
				</div>
				{liveEventMenuStore.upcomingEventsCount > 0 && (
					<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
						{liveEventMenuStore.upcomingEventsCount}
					</span>
				)}
			</NavLink>
			<NavLink
				className="nav--secondary__link"
				activeClassName="is-expanded"
				to={`/${props.culture}/live/highlights`}
			>
				<i className="nav--secondary__icon u-icon u-icon--lrg u-icon--live-highlights" />
				<div className="nav--secondary__title">
					{t<string>("LIVE.SIDEMENU.HIGHLIGHTS")}
				</div>
				{liveEventMenuStore.highlightsCount > 0 && (
					<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
						{liveEventMenuStore.highlightsCount}
					</span>
				)}
			</NavLink>
			{liveEventMenuStore.liveStreamEventCount > 0 ? (
				<NavLink
					className="nav--secondary__link"
					activeClassName="is-expanded"
					to={`/${props.culture}/live/live-stream`}
				>
					<i className="nav--secondary__icon u-icon u-icon--lrg u-icon--live-stream" />
					<div className="nav--secondary__title">
						{t<string>("SIDE_MENU.EVENTS_WITH_LIVE_STREAM")}
					</div>
					<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
						{liveEventMenuStore.liveStreamEventCount}
					</span>
				</NavLink>
			) : null}
		</li>
	);
});

const SportsList = observer(function SportsList(props: {
	sportsList: SportOffer[];
}) {
	const menuStore = useContext(LiveEventSideMenuContext);

	return (
		<>
			{props.sportsList.map((sport) => (
				<li key={sport.id} className="nav--secondary__item">
					<button
						className={`nav--secondary__link ${
							sport.id == menuStore.expandedElementId
								? "is-selected"
								: ""
						}`}
						type="button"
						onClick={() =>
							menuStore.expandCollapseElement(sport.id)
						}
					>
						<OfferIcon
							type="sport"
							sport={sport.abrv}
							className="nav--secondary__icon u-icon u-icon--lrg"
							treeIcon={true}
						/>
						<div className="nav--secondary__title">
							{sport.name}
						</div>
						<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
							{sport.eventCount}
						</span>
					</button>
				</li>
			))}
		</>
	);
});

const SubMenuList = observer(function SubMenuList(props: {
	culture: string;
	eventOfferList?: EventOffer[];
	lookupsStore: LookupsStore;
}) {
	const menuStore = useContext(LiveEventSideMenuContext);
	const eventIds = menuStore.eventIds.map((eId) => eId.split("-")[0]);
	return (
		<>
			{props.eventOfferList?.map((event) => {
				const isEventActive = eventIds.includes(event.id);
				const t1 = props.lookupsStore.teams.get(event.teamOneId || "");
				const t2 = props.lookupsStore.teams.get(event.teamTwoId || "");

				return (
					<li
						key={event.id}
						className="nav--quaternary__item nav--multi__item"
					>
						<ToggleIsEventInOfferButton
							event={event}
							isEventActive={isEventActive}
						/>
						<NavLink
							className="nav--quaternary__link nav--multi__link"
							activeClassName="is-selected"
							isActive={() => isEventActive}
							to={{
								pathname: `/${props.culture}/live/events/${event.id}-myBets=false`,
								state: {
									prevUrl:
										App.state.history.location.pathname +
										App.state.history.location.search,
									eventIdsForEventSwitcher:
										App.state.history.location.state
											?.eventIdsForEventSwitcher,
								},
							}}
						>
							<span>
								{t1?.name} - {t2?.name}
							</span>
						</NavLink>
					</li>
				);
			})}
		</>
	);
});

const ToggleIsEventInOfferButton = observer(
	function ToggleIsEventInOfferButton(props: {
		event: EventOffer;
		isEventActive: boolean;
	}) {
		const { t } = useTranslation();
		const eventMenuStore = useContext(LiveEventSideMenuContext);
		const rootOfferStore = useRootOfferStore();

		const [isHover, setIsHover] = useState(false);

		const btnClasses = classNames(
			"nav--multi__btn btn btn--square btn--square--micro btn--secondary--inverted"
		);

		const iconClasses = classNames("u-icon u-icon--xsml", {
			"u-icon--close": props.isEventActive && !isHover,
			"u-icon--close u-color--brand--highlight":
				props.isEventActive && isHover,
			"u-icon--add": !props.isEventActive && !isHover,
			"u-icon--add u-color--brand--highlight":
				!props.isEventActive && isHover,
		});

		if (props.isEventActive) {
			return (
				<button
					className={btnClasses}
					type="button"
					title={t("LIVE.SIDEMENU.REMOVE_EVENT_FROM_VIEW")}
					onClick={() => {
						eventMenuStore.removeEvent(props.event.id);
						rootOfferStore.myBetsViewStore.cashoutStore.disposeSubscription(
							props.event.id
						);
					}}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
				>
					<i className={iconClasses} />
				</button>
			);
		}

		return (
			<button
				className={btnClasses}
				type="button"
				title={t("LIVE.SIDEMENU.ADD_EVENT_TO_VIEW")}
				onClick={() => eventMenuStore.addEvent(props.event.id)}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				<i className={iconClasses} />
			</button>
		);
	}
);
