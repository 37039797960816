import React from "react";

export const UserContext = React.createContext({
    user: null,
    token: null,
    accountActivation: null,
    loginHistory: null
});

export const UserContextActions = React.createContext({
    setLoginHistory: () => { },
    setUser: () => { },
    setAccountActivation: () => { },
    setToken: () => { }
});

export const UserBalanceActionsContext = React.createContext({
    setUserBalance: () => { },
    refreshUserBalance: () => { },
});

export const UserBalanceContext = React.createContext({
    userBalance: null
})