import { createContext } from "react";

export const AppContext = createContext<{
	theme?: string;
	culture: string;
	isNavMenuOpen: boolean;
	setIsNavMenuOpen: (newValue: boolean) => void;
}>({
	theme: "light",
	culture: "en",
	isNavMenuOpen: false,
	setIsNavMenuOpen: (newValue: boolean) => {},
});

export const AppActionsContext = createContext({
	setTheme: (newTheme: string) => {},
	setCulture: (newCulture: string) => {},
});
