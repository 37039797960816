import React from "react";

export default function GlobalLoader() {

	return (
        <div className="loader loader--full">
            <div className="loader__card">
                <div className="loader__spinner u-display--b"></div>
                {/* <div className="loader--countdown__title">
                    {t<string>("HOME.LOADING")}...
                </div> */}
            </div>
		</div>
	);
}