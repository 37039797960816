import React, { useEffect, useContext, useMemo, FC } from "react";
import { observer } from "mobx-react";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@v2/hooks";
import GamingHistoryStoreContext from "@v2/context/GamingHistoryStoreContext";

import GamingHistoryStore from "@v2/state/gaming-history/GamingHistoryStore";

import { HeaderWithSideMenu } from "@v2/components/desktop/shared/header";
import { Footer } from "@v2/components/desktop/shared/footer";
import { CasinoFilterForm, LiveCasinoFilterForm } from "./tabs";

import { getCurrentCulture, setTabTitle } from "@utils";
import { isCasinoEnabled, isLiveCasinoEnabled } from "@v2/helpers";
import Body from "./Body";
import { AdministrationSideMenu } from "../shared/side-menu";
import { LoaderSpinner } from "@v2/components/shared";

export const GamingHistoryPage = observer(function GamingHistoryPage() {
	const { t } = useTranslation();
	const gamingHistoryStore = useMemo(
		() => new GamingHistoryStore(App.state.rootStore),
		[]
	);

	useEffect(() => {
		setTabTitle(t("GAMING_HISTORY.TITLE"));
		gamingHistoryStore.onInitialize();
		return () => {
			gamingHistoryStore.onDispose();
		};
	}, []);
	const {
		isStoreInitialized,
		isLoading: isLiveCasinoFormLoading,
		gamingHistoryFilterStore: { isLoading: isCasinoFormLoading },
	} = gamingHistoryStore;

	if (
		!isStoreInitialized &&
		!isCasinoFormLoading &&
		!isLiveCasinoFormLoading
	) {
		return null;
	}

	return (
		<GamingHistoryStoreContext.Provider value={gamingHistoryStore}>
			<LoaderSpinner
				isLoading={isCasinoFormLoading || isLiveCasinoFormLoading}
			/>

			<HeaderWithSideMenu />
			<main className="l--main l--main--med">
				<aside className="l--aside--left">
					<AdministrationSideMenu />
				</aside>
				<section className="l--content l--content--full">
					<div className="l--content__main">
						<div className="container">
							<div id="betsDiv" className="container">
								<div className="card--primary">
									<GamingHistoryHeader />

									<GamingHistoryTabSwitcher />

									<GamingHistoryTabs />
								</div>
								<Body />
							</div>
						</div>
					</div>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</GamingHistoryStoreContext.Provider>
	);
});

const GamingHistoryHeader: FC = function GamingHistoryHeader() {
	const { t } = useTranslation();

	return (
		<div className="card--primary__header">
			<div className="card--primary__title title--primary">
				{t<string>("GAMING_HISTORY.TITLE")}
			</div>
		</div>
	);
};

const GamingHistoryTabSwitcher: FC = observer(
	function GamingHistoryTabSwitcher() {
		const { t } = useTranslation();
		const { culture } = useAppContext();

		return (
			<div className="card--primary__tabs">
				<nav className="tabs--primary">
					{isCasinoEnabled ? (
						<NavLink
							className="tabs--primary__item"
							activeClassName="is-active"
							replace={true}
							to={`/${culture}/gaming-history/games`}
						>
							{t<string>("specific:GAMING_HISTORY.CASINO")}
						</NavLink>
					) : null}
					{isLiveCasinoEnabled ? (
						<NavLink
							to={`/${culture}/gaming-history/live-games`}
							className="tabs--primary__item"
							replace={true}
							activeClassName="is-active"
						>
							{t<string>("specific:GAMING_HISTORY.LIVE_CASINO")}
						</NavLink>
					) : null}
				</nav>
			</div>
		);
	}
);

const GamingHistoryTabs: FC = function GamingHistoryTabs() {
	let url = "";

	if (isCasinoEnabled) {
		url = `/${getCurrentCulture()}/gaming-history/games`;
	} else if (isLiveCasinoEnabled) {
		url = `/${getCurrentCulture()}/gaming-history/live-games`;
	} else {
		url = `/${getCurrentCulture()}/home/full/highlights`;
	}

	return (
		<Switch>
			{isCasinoEnabled ? (
				<Route exact path={["/:culture/gaming-history/games"]}>
					<CasinoFilterForm />
				</Route>
			) : null}

			{isLiveCasinoEnabled ? (
				<Route exact path={["/:culture/gaming-history/live-games"]}>
					<LiveCasinoFilterForm />
				</Route>
			) : null}

			<Redirect to={url} />
		</Switch>
	);
};
