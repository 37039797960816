import { DateTime } from "luxon";

const periods = {
	full: () => DateTime.now().endOf("day").plus({ years: 200 }),
	"3d": () => DateTime.now().endOf("day").plus({ days: 3 }),
	today: () => DateTime.now().endOf("day"),
	"48h": () => DateTime.now().endOf("hour").plus({ hours: 48 }),
	"24h": () => DateTime.now().endOf("hour").plus({ hours: 24 }),
	"3h": () => DateTime.now().endOf("hour").plus({ hours: 3 }),
};
export type Period = keyof typeof periods;

export const validPeriods: Period[] = Object.keys(periods) as Period[];

/** Round time to start of next hour. */
export function roundToStartOfNextHour(dateTime: DateTime) {
	if (dateTime.second !== 0 || dateTime.minute !== 0) {
		return dateTime.startOf("hour").plus({ hours: 1 });
	}
	return dateTime;
}

class PeriodConverter {
	/** Calculates offer interval based on period. */
	static CalculateOfferInterval(period: Period) {
		const validPeriod = PeriodConverter.IsValidPeriod(period)
			? period
			: PeriodConverter.GetDefaultPeriod();
		let momentTime = periods[validPeriod]();

		// If there are any seconds or minutes in current hour then round up to the next hour.
		if (momentTime.second !== 0 || momentTime.minute !== 0) {
			momentTime = momentTime.startOf("hour").plus({ hours: 1 });
		}

		return momentTime.toUTC().toJSDate();
	}

	/** Calculates offer interval based on period. */
	static IsValidPeriod(period: Period): boolean {
		return validPeriods.includes(period);
	}

	/** Returns default period. */
	static GetDefaultPeriod(): Period {
		return "full";
	}
}

export default PeriodConverter;
