import {
	observable,
	computed,
	action,
	runInAction,
	reaction,
	IReactionDisposer,
} from "mobx";
import WithdrawalFilterStore from "./WithdrawalFilterStore";

import { LoaderStore } from "@state/stores/common";
import { betPeriods } from "@administration/pages/my-bets/BetPeriods";
import { WithdrawalService } from "@services/withdrawal/WithdrawalService";
import RootStore from "@state/stores/RootStore";
import {
	WithdrawalResponseDto,
	PaymentOption,
	UsedPaymentMethodDto,
} from "@api-types/withdrawal";

import {
	PaymentRequestFilter,
	RawFilterData,
	WithdrawalSubmitData,
} from "@data-types/withdrawal";
import { localizationService } from "@state";
import { BaseLookupDto } from "@api-types/lookups/BaseLookupDto";
import { AccountTypes, getUserId } from "@utils";
import { BonusActivityInfo } from "@api-types/withdrawal/WithdrawalDataDto";
import { UserAccountType } from "@common/constants";
import { Capacitor } from "@capacitor/core";
import { isCapacitorPlatform } from "@utils/specific/capacitor";

export default class WithdrawalStore {
	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		// Helper stores
		this.withdrawalFilterStore = new WithdrawalFilterStore(rootStore, this);
		this.loader = new LoaderStore();

		// Method bindings
		this.getDateRange = this.getDateRange.bind(this);
	}

	//#region observables

	@observable isStoreInitialized: boolean = false;
	@observable isFetchingData: boolean = true; // When store first starts it will always start fetching

	@observable withdrawalList: WithdrawalResponseDto | null = null;
	@observable paymentMethods: PaymentOption[] | null;
	@observable userPaymentMethods: UsedPaymentMethodDto[] | null;
	@observable transactionStatuses: BaseLookupDto[] | null;
	@observable paymentOption: PaymentOption | null = null;
	@observable isAmountInserted: boolean = false;
	@observable insertedAmount: number = 0;
	@observable submittedWallet: string | null = null;
	@observable isRedirectingToPaymentPage: boolean = false;

	@observable currentFormData: WithdrawalSubmitData | null = null;

	@observable isPasswordConfirmationRequired: boolean = false;

	@observable withdrawalResult: boolean | null = null;
	@observable withdrawalResultError: boolean = false;

	@observable isFetchingPaymentMethods: boolean = true;

	@observable userBonusInfo: BonusActivityInfo | null = null;

	@observable disableCancelConfirmationTransaction: boolean = false;

	@computed get isEmpty(): boolean {
		return (
			this.withdrawalList != null &&
			this.withdrawalList.item.length === 0 &&
			!this.isFetchingData &&
			this.isStoreInitialized
		);
	}

	@computed get betPeriods() {
		return betPeriods;
	}

	@computed get pageNumber(): number {
		return this.withdrawalFilterStore.pageNumber;
	}

	@computed get userPaymentSavedMethods() {
		return this.paymentMethods?.filter(
			(method) => method.hasPaymentReference === true
		);
	}

	@computed get otherPaymentMethods() {
		return this.paymentMethods?.filter(
			(method) => method.hasPaymentReference === false
		);
	}

	@computed get onlineWallet() {
		return App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) =>
				wallet.abrv === UserAccountType.SportBettingAccountOnline
		);
	}

	@computed get casinoWallet() {
		return App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) => wallet.abrv === UserAccountType.CasinoAccount
		);
	}

	@computed get liveCasinoWallet() {
		return App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) => wallet.abrv === UserAccountType.LiveCasinoAccount
		);
	}

	@computed get gigWallet() {
		return App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) => wallet.abrv === UserAccountType.GigAccount
		);
	}

	@computed get shouldShowWalletSelectors(): boolean {
		if (App.state.rootStore.userAuthStore.userAccounts == null) {
			return false;
		}
		return App.state.rootStore.userAuthStore.userAccounts.length > 1;
	}

	pageSize: number = 10;
	loader: LoaderStore;
	withdrawalFilterStore: WithdrawalFilterStore;
	filterReaction: IReactionDisposer | null;
	rootStore: RootStore;
	paymentProcessingMode: number | null;
	withdrawalMethodSelectionMode: number | null;

	@observable totalItems: number = 0;
	@observable initialLoad: boolean = true;
	@observable isMultiStepWithdrawal: boolean = false;
	@observable showConfirmationModal: boolean = false;
	@observable currentWithdrawalTransactionId: string | null;
	@observable includePayPalWithdrawalAmountValidation: boolean = false;
	@observable hasWalletChangedFromDefault: boolean = false;

	@observable paymentMethodError: {
		message: string;
		errorCode: number;
	} | null = null;

	@observable paymentModeHash: string | null;

	//#endregion observables

	//#region computed

	@computed get isLoading(): boolean {
		return this.loader.isLoading;
	}

	@computed get isLoadingProcess(): boolean {
		return this.loader.isLoadingProcess;
	}

	//#endregion computed

	//#region actions

	@action.bound
	changeWalletFromDefault() {
		this.hasWalletChangedFromDefault = true;
	}

	@action.bound
	async onInitialize(): Promise<void> {
		this.loader.suspend();
		if (this.onlineWallet == null) {
			console.error("Expected online wallet, got null");
			return;
		}
		await this.fetchPaymentMethods(this.onlineWallet?.accountTypeId);

		await this.fetchUserPaymentMethods();
		await this.fetchTransactionStatuses();

		this.disposeFilterReaction();
		this.filterReaction = reaction(
			() => ({
				filter: this.withdrawalFilterStore.filter,
			}),
			() => {
				if (
					this.isStoreInitialized && // Wait for lookups to load
					!this.withdrawalFilterStore.isDefaultFilter // Don't fetch for default filter
				) {
					this.fetchWithdrawal(this.withdrawalFilterStore.filter);
				}
			},
			{
				fireImmediately: false,
			}
		);
		this.withdrawalFilterStore.onInitialize(); // Initialize filter after this store, we need to know if search params are empty

		runInAction(() => {
			this.isStoreInitialized = true;
			this.loader.resume();
		});
	}

	@action.bound
	async fetchUserPaymentMethods(): Promise<void> {
		try {
			const userPaymentMethods =
				await WithdrawalService.getUserPaymentMethods();
			runInAction(() => {
				this.userPaymentMethods = userPaymentMethods;
			});
		} catch (error) {
			console.error(error);
			runInAction(() => {
				this.userPaymentMethods = null;
			});
			const errorMessage =
				error?.data?.errorMessage[0] ||
				localizationService.t("ERROR.TITLE");
			App.state.rootStore.notificationStore.error(errorMessage);
		}
	}

	@action.bound
	async fetchTransactionStatuses(): Promise<void> {
		try {
			const transactionStatuses =
				await WithdrawalService.getTransactionStatuses();
			runInAction(() => {
				this.transactionStatuses = transactionStatuses.item;
			});
		} catch (error) {
			const errorMessage =
				error.data?.errorMessage?.shift() ||
				localizationService.t("ERROR.TITLE");
			runInAction(() => {
				this.transactionStatuses = null;
			});
			App.state.rootStore.notificationStore.error(errorMessage);
		}
	}

	@action.bound
	async fetchPaymentMethods(bettingAccountTypeId: string): Promise<void> {
		this.loader.suspend();
		this.isFetchingPaymentMethods = true;
		if (this.paymentMethodError != null) {
			this.paymentMethodError = null;
		}

		try {
			const paymentMethods = await WithdrawalService.getAvailableMethods(
				bettingAccountTypeId
			);

			runInAction(() => {
				this.userBonusInfo = {
					isBonusActive:
						paymentMethods.bonusActivityInfo.isBonusActive,
					rolloverInfo: paymentMethods.bonusActivityInfo.rolloverInfo,
					bonusType: paymentMethods.bonusActivityInfo.bonusType,
				};
				this.paymentMethods = paymentMethods.availablePaymentMethods;
				this.isPasswordConfirmationRequired =
					paymentMethods.isPasswordConfirmationRequired;
				this.paymentModeHash = paymentMethods.paymentModeHash;

				this.paymentProcessingMode =
					paymentMethods.paymentProcessingMode;
				this.withdrawalMethodSelectionMode =
					paymentMethods.withdrawalMethodSelectionMode;
				this.includePayPalWithdrawalAmountValidation =
					paymentMethods.includePayPalWithdrawalAmountValidation;

				this.isFetchingPaymentMethods = false;
			});
		} catch (error) {
			console.error(error);
			//nice
			if (error?.data?.error === 69) {
				App.state.rootStore.AccountVerificationStore.showPopup(
					"user-profile-recheck",
					"ACCOUNT_RECHECK.TITLE",
					error?.data?.errorMessage?.shift()
				);
				return;
			}
			this.paymentMethodError = {
				message:
					error?.data?.errorMessage?.shift() ||
					localizationService.t("ERROR.TITLE"),
				errorCode: error.data?.error,
			};

			runInAction(() => {
				this.paymentMethods = null;
				this.isPasswordConfirmationRequired = false;
				this.paymentModeHash = null;

				this.paymentProcessingMode = null;
				this.withdrawalMethodSelectionMode = null;
			});
		} finally {
			runInAction(() => {
				this.isFetchingPaymentMethods = false;
				this.loader.resume();
			});
		}
	}

	@action.bound
	async initializeWithdrawalProcess(
		data: WithdrawalSubmitData
	): Promise<void> {
		if (this.isPasswordConfirmationRequired == false) {
			this.finalizeWithdrawal(data);
			return;
		}

		runInAction(() => {
			this.currentFormData = data;
			this.isMultiStepWithdrawal = true;
		});
	}

	@action.bound
	async finalizeWithdrawal(data: WithdrawalSubmitData): Promise<void> {
		if (this.paymentModeHash == null) {
			console.error("Expected payment mode hash, got null");
			return;
		}

		if (this.paymentProcessingMode == null) {
			console.error("Expected payment mode hash, got null");
			return;
		}

		if (this.withdrawalMethodSelectionMode == null) {
			console.error("Expected withdrawal method selection mode got null");
			return;
		}

		if (this.includePayPalWithdrawalAmountValidation == null) {
			console.error(
				"Expected include payPal withdrawal amount validation, got null"
			);
			return;
		}

		try {
			this.loader.suspend();

			const paymentMethodObject = this.paymentMethods?.find(
				(method) =>
					method.paymentMethodId ===
						this.currentFormData?.paymentMethodId ||
					method.paymentMethodId === data?.paymentMethodId
			);
			const response =
				await WithdrawalService.initializeNewWithdrawalProcess({
					amount: data?.amount || this.currentFormData?.amount,
					paymentMethodId:
						data?.paymentMethodId ||
						this.currentFormData?.paymentMethodId,
					bettingAccountTypeId:
						data?.bettingAccountTypeId ||
						this.currentFormData?.bettingAccountTypeId,
					userPassword: data.password,
					paymentModeHash: this.paymentModeHash,
					paymentProviderId: paymentMethodObject?.paymentProviderId,
					paymentProcessingMode: this.paymentProcessingMode,
					withdrawalMethodSelectionMode:
						this.withdrawalMethodSelectionMode,
					isPasswordConfirmationRequired:
						this.isPasswordConfirmationRequired,
					includePayPalWithdrawalAmountValidation:
						this.includePayPalWithdrawalAmountValidation,
				});

			if (response.redirectUrl) {
				this.isRedirectingToPaymentPage = true;
				//if user comes back from payment page to our url, refresh user balance on status success??
			} else {
				this.withdrawalResult = true;
				this.resetMultiStepFlags();
				this.disposeNewWithdrawalProcess();
				//refresh user balance on notification success
				App.state.rootStore.userAuthStore.refreshUserBalance();
				App.state.rootStore.notificationStore.success(
					localizationService.t("WITHDRAWAL.SUCCESS_MESSAGE", {
						0: data?.amount || this.currentFormData?.amount,
						1: paymentMethodObject?.currencyDisplayCode,
						2: paymentMethodObject?.displayName,
					})
				);
			}
			if (this.isRedirectingToPaymentPage) {
				window.location.href = response.redirectUrl;
				if (isCapacitorPlatform()) {
					//only on native
					//reset state so user can submit again if we comes back from browser into app
					runInAction(() => {
						this.isRedirectingToPaymentPage = false;
					});
					this.loader.resume();
				}
			}
		} catch (error) {
			console.error(error);
			if (error?.data?.error === 70) {
				App.state.rootStore.AccountVerificationStore.showPopup(
					"user-profile-recheck",
					"ACCOUNT_RECHECK.TITLE",
					error?.data?.errorMessage?.shift()
				);
				return;
			}
			//if password is incorrect, do not reset the form
			if (error.data?.errorCode !== 400176) {
				runInAction(() => {
					this.withdrawalResult = error.data.isValid;
				});
				this.resetMultiStepFlags();
				this.disposeNewWithdrawalProcess();
			}
			const errorMessage =
				error?.data?.errorMessage?.shift() ||
				error.data?.details ||
				localizationService.t("ERROR.TITLE");
			App.state.rootStore.notificationStore.error(errorMessage);
		} finally {
			this.loader.resume();
		}
	}

	@action.bound
	async fetchWithdrawal(filter: PaymentRequestFilter): Promise<void> {
		this.loader.suspend();
		this.isFetchingData = true;

		try {
			const requestFilter: PaymentRequestFilter = {
				from: filter.from,
				to: filter.to,
				pageNumber: filter.pageNumber,
				pageSize: this.pageSize,
				userId: getUserId(
					App.state.rootStore.userAuthStore.getUserAccountType(
						AccountTypes.ONLINE
					)
				),
				isDeposit: false,
			};

			if (filter.paymentMethodId !== "all") {
				requestFilter.paymentMethodId = filter.paymentMethodId;
			}

			if (filter.transactionStatusId !== "all") {
				requestFilter.transactionStatusId = filter.transactionStatusId;
			}

			const response = await WithdrawalService.getWithdrawalData(
				requestFilter
			);

			const data = {
				...response,
				item: response.item.map((item) => ({
					...item,
					status: this.transactionStatuses?.find(
						(status) => status.id === item?.transactionStatusId
					),
				})),
			};

			runInAction(() => {
				this.withdrawalList = data;
				this.totalItems = data.totalRecords;
			});
		} catch (error) {
			runInAction(() => {
				console.error(error);
				const errorMessage = localizationService.t("ERROR.TITLE");
				App.state.rootStore.notificationStore.error(errorMessage);

				this.withdrawalList = null;
			});
		} finally {
			this.loader.resume();
			this.isFetchingData = false;
			this.initialLoad = false;
		}
	}

	@action.bound
	async cancelWithdrawalTransaction(): Promise<void> {
		this.disableCancelConfirmationTransaction = true;
		try {
			this.loader.suspend();
			if (this.currentWithdrawalTransactionId == null) {
				return;
			}
			await WithdrawalService.cancelWithdrawalTransaction(
				this.currentWithdrawalTransactionId
			);
			await this.fetchWithdrawal(this.withdrawalFilterStore.filter);
			//refresh user balance on successful transaction cancel
			App.state.rootStore.userAuthStore.refreshUserBalance();
			App.state.rootStore.notificationStore.success(
				localizationService.t("WITHDRAWAL.SUCCESSFULLY_CANCELLED")
			);
		} catch (error) {
			//cannot expect json in api service due to 200 status code doesn't return response
			const errorObject = await error.rawResponse.json();
			console.error(errorObject);
			const errorMessage =
				errorObject?.errors?.shift() ||
				localizationService.t("ERROR.TITLE");
			App.state.rootStore.notificationStore.error(errorMessage);
		} finally {
			runInAction(() => {
				this.currentWithdrawalTransactionId = null;
				this.showConfirmationModal = false;
			});
			this.loader.resume();
		}
	}

	@action.bound
	refresh(): void {
		this.fetchWithdrawal(this.withdrawalFilterStore.filter);
	}

	@action.bound
	submitFilter(data: RawFilterData): void {
		this.withdrawalFilterStore.setRawFilterData(data);
	}

	@action.bound
	onPageChange(newPageNumber: number): void {
		this.withdrawalFilterStore.setPageNumber(newPageNumber);
	}

	@action.bound
	setPaymentOption(paymentOption: PaymentOption | null): void {
		this.paymentOption = paymentOption;
	}

	@action.bound
	setSubmittedWallet(wallet: string): void {
		this.submittedWallet = wallet;
	}

	@action.bound
	setAmountFlag(state: boolean): void {
		this.isAmountInserted = state;
	}

	@action.bound
	setInsertedAmount(amount: number): void {
		this.insertedAmount = amount;
	}

	@action.bound
	resetMultiStepFlags(): void {
		this.currentFormData = null;
		this.isMultiStepWithdrawal = false;
		this.withdrawalResult = null;
		this.withdrawalResultError = false;
	}

	@action.bound
	setCurrentFormData(data: any): void {
		this.currentFormData = data;
	}

	@action.bound
	setShowConfirmationModal(state: boolean, transactionId?: string) {
		this.showConfirmationModal = state;
		if (transactionId) {
			this.currentWithdrawalTransactionId = transactionId;
		}
		// disable confirmation modal buttons in case hide
		if (!state) {
			this.disableCancelConfirmationTransaction = true;
		} else {
			this.disableCancelConfirmationTransaction = false;
		}
	}

	//#endregion actions

	//#region helpers

	getDateRange(data: RawFilterData): {
		from: string | null | undefined;
		to: string | null | undefined;
	} | null {
		const { period, from, to } = data;

		if (
			(period == null && from == null && to == null) ||
			period === "all"
		) {
			return null;
		}

		if (period !== "custom") {
			const timeInterval = this.betPeriods
				.find((p) => p.value === period)
				?.getTimeInterval();
			if (timeInterval == null) {
				// Return default
				return this.betPeriods[0].getTimeInterval();
			}
			return timeInterval;
		}

		return { from, to };
	}

	//#endregion helpers

	//#region disposers

	@action.bound
	onDispose(): void {
		this.withdrawalFilterStore.onDispose();
		this.disposeFilterReaction();

		this.isStoreInitialized = false;
		this.isFetchingData = true;
		this.paymentMethodError = null;

		this.disposeCurrentWithdrawalState();
	}

	@action.bound
	disposeCurrentWithdrawalState(): void {
		this.withdrawalList = null;
		this.initialLoad = true;
		this.pageSize = 10;
		this.totalItems = 0;
	}

	@action.bound
	disposeFilterReaction(): void {
		if (this.filterReaction != null) {
			this.filterReaction();
			this.filterReaction = null;
		}
	}

	@action.bound
	disposeNewWithdrawalProcess(): void {
		this.paymentOption = null;
		this.isAmountInserted = false;
		this.insertedAmount = 0;
		this.withdrawalResult = null;
		this.submittedWallet = null;
		this.isPasswordConfirmationRequired = false;
	}

	//#endregion disposers
}
