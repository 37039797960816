import { httpClient, getApplicationHeaders } from "@utils";

class EventChangeTypeLookupService {

	getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type"] = "application/json";
		return appHeaders;
	}

	findLookup() {
		const url = `${WEBPACK_BASE_ADDRESS}platform/event-change-types?translate=true`;
		return httpClient.get(url, this.getRequestHeaders(), { responseType: "json" });
	}
}

const eventChangeTypeLookupService = new EventChangeTypeLookupService();

export { eventChangeTypeLookupService };
