export const LazyImportWithLoadFailHandle = function <T>(
	importFailRoute: string,
	factory: () => Promise<{
		default: T;
	}>
): Promise<{ default: T }> {
	return new Promise((resolve, reject) => {
		factory()
			.then((component) => resolve(component))
			.catch((error) => {
				if (error.message.startsWith("Loading chunk ")) {
					App.state.redirect(importFailRoute);
				}
				reject(error);
			});
	});
};
