import React from "react";

export class ErrorHandler extends React.PureComponent {
	componentDidMount() {
		/* this.initialize(); */
	}

	initialize() {
		window.addEventListener("unhandledrejection", (event) => {
			event.stopPropagation();
			event.preventDefault();

			const { reason } = event;

			if (typeof reason === "object") {
				/*    if (reason.request !== undefined && reason.statusCode !== undefined) {
                       return handleApiError(reason);
                   } */
			}

			console.error(reason);
			// App.state.rootStore.notificationStore.error("We apologize for the inconvenience, but an unexpected error has occurred while processing your request.")
		});

		window.addEventListener("error", (event) => {
			event.stopPropagation();
			event.preventDefault();
			// eslint-disable-next-line
			/* 
			if (
				event.message != null &&
				event.message.includes("ResizeObserver")
			) {
				return;
			} */
			console.error("ERROR", event);
			// App.state.rootStore.notificationStore.error("We apologize for the inconvenience, but an unexpected error has occurred while processing your request.")
			return true;
			// App.state.history.push("/en/sports")
		});
	}

	render() {
		return null;
	}
}
