import { useTranslation } from "react-i18next";

export const mapPeriods = (data) => {
	const { t } = useTranslation();

	if (!data) {
		return;
	}

	const mappedData = data.map((item) => {
		return {
			id: item.id,
			name: t(`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.${item.name}`),
			abrv: item.id,
		};
	});
	return mappedData;
};
export const mapCategories = (data) => {
	const { t } = useTranslation();

	if (!data) {
		return;
	}

	const mappedData = data.map((item) => {
		return {
			id: item.id,
			name:
				item.name == "SHOW_ALL"
					? t(`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.${item.name}`)
					: item.name,
		};
	});
	return mappedData;
};
