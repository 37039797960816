import React from "react";
import ThemeToggleButton from "@v2/components/shared/utility/ThemeToggleButton";

interface ThemeToggleProps {
	rounded?: boolean;
}

export default function ThemeToggle({rounded}: ThemeToggleProps) {
	return (
			<ThemeToggleButton rounded={rounded} />
	);
}