import { MappedCategoryModel } from "@api-types";
import {
	LimitCategoriesViewModel,
	LimitTypeItem,
	ResolveLimit,
	UserLimit,
} from "@data-types";
import { LimitDurationDataType } from "@data-types/membership/registration";
import { MyLimitsService } from "@services/account-settings/MyLimitsService";
import { localizationService } from "@state";
import { LoaderStore } from "@state/stores/common";
import { action, computed, observable, runInAction } from "mobx";

import { AccountTypes } from "@utils";
import RootStore from "@state/stores/RootStore";

export default class MyLimitsStore {
	loader: LoaderStore;
	rootStore: RootStore;
	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.loader = new LoaderStore();
	}
	//#region  observable

	@observable lookups: {
		limitDurations: LimitDurationDataType[];
		limitTypes: LimitTypeItem[];
	} | null = null;

	@observable activeAccount: AccountTypes = App.state.rootStore.userAuthStore
		.user
		? AccountTypes.ONLINE
		: AccountTypes.OFFLINE;

	@observable tabs: MappedCategoryModel[] | null = null;
	@observable category: LimitCategoriesViewModel | null = null;

	//#endregion  observable

	@computed get getSingleLimitDurations(): UserLimit {
		const placeholder: UserLimit = {
			limitId: "",
			name: "",
			canBeUpdated: false,
			canBeDeleted: false,
			categoryId: "",
			durationId: "",
			typeId: "",
			units: 0,
			amount: null,
		};
		if (!this.category) return placeholder;
		if (!this.category.durations) {
			return placeholder;
		}
		if (this.category.durations.length < 1) {
			return placeholder;
		}
		return this.category.durations[0];
	}

	@computed get isMultiModeEnabled() {
		return this.category?.isMultiTypeMode || false;
	}

	@computed get isRequestPending() {
		return this.loader.isLoadingProcess;
	}

	@action.bound
	setActiveAccount(account: AccountTypes): void {
		this.activeAccount = account;
	}

	@action.bound
	setCategory(category: LimitCategoriesViewModel): void {
		this.category = category;
	}

	@action.bound
	async onInitialize() {
		this.loader.suspend();
		try {
			const response = await MyLimitsService.getTabs();
			runInAction(() => {
				this.tabs = response.tabs;
			});
		} catch (error) {
			console.error(error);
			const errorMessage = localizationService.t(
				error?.data?.message ||
					"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
			);
			this.rootStore.notificationStore.error(errorMessage);
		}
		this.loader.resume();
	}

	@action.bound
	async fetchLimitInfo(limitId: string) {
		this.loader.suspend();

		try {
			const response = await MyLimitsService.getTabData(
				this.activeAccount,
				limitId
			);

			if (!response.isMultiLimitModeEnabled) {
				const lookup = await MyLimitsService.fetchLimitLookups();
				runInAction(() => {
					this.lookups = lookup;
				});
			}
			runInAction(() => {
				this.setCategory(response);
			});
		} catch (error) {
			console.error(error);
			const errorMessage = localizationService.t(
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error?.error?.message ||
					"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
			);
			this.rootStore.notificationStore.error(errorMessage);
		}
		this.loader.resume();
	}

	@action.bound
	async submitRequestLimit(model: {
		action: LimitRequestAction;
		categoryId: string;
		userLimitId: string;
		userLimitRequestId: string;
	}) {
		this.loader.suspend();
		try {
			const response = await MyLimitsService.resolveRequest(
				this.activeAccount,
				model
			);
			if (response !== undefined) {
				runInAction(() => {
					this.setCategory(response);
					this.rootStore.notificationStore.success(
						response?.infoMessage ||
							"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
					);
				});
			}
		} catch (error) {
			console.error(error);
			const errorMessage = localizationService.t(
				error?.message || "USER.ACCOUNT_SETTINGS.MY_LIMITS." + error
			);
			this.rootStore.notificationStore.error(errorMessage);
		}
		this.loader.resume();
	}

	@action.bound
	async submitLimit(model: ResolveLimit) {
		this.loader.suspend();
		try {
			const response = await MyLimitsService.resolveLimit(
				this.activeAccount,
				model
			);
			if (response) {
				runInAction(() => {
					this.setCategory(response);
					this.rootStore.notificationStore.success(
						response?.infoMessage ||
							"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
					);
				});
			}
		} catch (error) {
			console.error(error);
			if (error?.errorCode === 20) {
				this.rootStore.AccountVerificationStore.showPopup(
					"account-settings/account-verification",
					"ACCOUNT_VERIFICATION.TITLE",
					error.message
				);
			} else if (error?.errorCode === 21) {
				this.rootStore.AccountVerificationStore.showPopup(
					"limit-increase",
					"LIMIT_INCREASE.TITLE",
					error.message
				);
			} else if (error?.errorCode === 400242) {
				const errorMessage = localizationService.t(
					"MEMBERSHIP.ERR_HANDLING.LUGAS_VALIDATION_ERROR"
				);
				this.rootStore.notificationStore.error(errorMessage);
			} else if (error?.errorCode === 400244) {
				const errorMessage = localizationService.t(
					"USER.ACCOUNT_SETTINGS.MY_LIMITS.LUGAS_LIMIT_EXCEEDS_MAX"
				);
				this.rootStore.notificationStore.error(errorMessage);
			} else if (error?.errorCode === 400256) {
				const errorMessage = localizationService.t(
					"MEMBERSHIP.ERR_HANDLING.LUGAS_UNSPECIFIED"
				);
				this.rootStore.notificationStore.error(errorMessage);
			} else {
				const errorMessage = localizationService.t(
					error?.message || "USER.ACCOUNT_SETTINGS.MY_LIMITS." + error
				);
				this.rootStore.notificationStore.error(errorMessage);
			}
		}
		this.loader.resume();
	}
}

export enum LimitRequestAction {
	REMOVE = 1,
	APPLY = 2,
}

export type SubmitLimit = {
	amount: number;
	action: number;
	categoryId: string;
	durationId: string;
	typeId: string;
	limitId: string;
};

export enum SubmitLimitAction {
	ADD = 1,
	REMOVE = 2,
	UPDATE = 3,
}

export type MyLimitsStoreCategoriesNullChecked = Omit<
	MyLimitsStore,
	"category"
> & { category: LimitCategoriesViewModel };

export type MyLimitsStoreTabsNullChecked = Omit<MyLimitsStore, "tabs"> & {
	tabs: MappedCategoryModel[];
};

export const timeLimitsDropDown = [
	{
		id: 30,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_30_MINUTES",
	},
	{
		id: 60,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_1_HOUR",
	},
	{
		id: 120,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_2_HOURS",
	},
	{
		id: 180,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_3_HOURS",
	},
	{
		id: 240,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_4_HOURS",
	},
	{
		id: 300,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_5_HOURS",
	},
	{
		id: 360,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_6_HOURS",
	},
	{
		id: 480,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_8_HOURS",
	},
	{
		id: 600,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_10_HOURS",
	},
	{
		id: 720,
		name: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_12_HOURS",
	},
];
