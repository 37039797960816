import { observable } from "mobx";
import { DateTime } from "luxon";
import { localizationService } from "@state";
import { BetOffer } from "./BetOffer";
import { ISOToDisplayCode, getDisplayedAmount } from "@utils";
import { CashoutType } from "@gp/hub";
import { BetSlipSecureWinSetting } from "./BetSlipSecureWinSetting";
import { Fiskaly } from "@data-types";
import { ShopAddress } from "@api-types/bet-slip/ShopAddress";

export class Bet {
	id: string;
	system: string;
	eventCount: number;
	combinationBettingOffersBankCount: number;
	combinationBettingOffersCount: number;
	totalNumberOfCombinations: number;
	metadata: any; // TODO
	betSlipStatus: any; // TODO
	betSlipCombinations: any[]; // TODO
	groupedOffersByEvent: BetOffer[][];
	betSlipType: any; // TODO
	betStatusId: string;
	player: any; // TODO
	cashoutTimeStamp?: DateTime; // TODO
	betSlipNumber: string;
	betSlipSubmitEnvironmentTypeAbrv: string;
	bettingAccountType: any; // TODO
	bettingAccountTypeId: string;
	cashoutGain: any; // TODO
	code: string;
	fiscalizationId: string;
	fiscalizationSentDate?: any; // TODO
	gain: number;
	gainBonus: number;
	gainBonusPercentage: number;
	gainTax: number;
	gainTaxPercentage: number;
	handlingFee: number;
	handlingFeePercentage: number;
	ipAddress: string;
	isBonus: boolean;
	isLive: boolean;
	isWege: boolean;
	maxCoefficient: number;
	minGain: number;
	payment: number;
	paymentBonus: number;
	paymentPerCombination: number;
	payout: any; // TODO
	payoutDate: any; // TODO
	slipStatusId: string;
	stake: number;
	stakePerCombination: number;
	stakeTaxPercentage: number;
	taxFreePayoutAmount: any; // TODO
	totalNumberOfOffersPerBetSlip: number;
	userId: string;
	dateCreated: string;
	dateUpdated: string;
	currency: string;
	displayPaymentPerCombination: string;
	displayPayment: string;
	displayHandlingFee: string;
	displayGainTax: string;
	displayGainBonus: string;
	displayMaxGain: string;
	displayPayout: string;
	displayGain: string;
	isChangeAccepted?: boolean;
	shopAddress: ShopAddress;

	constructor(bet: Bet) {
		this.assignValues = this.assignValues.bind(this);
		this.injectIsMultiToOffers = this.injectIsMultiToOffers.bind(this);
		this.groupOffersByEvent = this.groupOffersByEvent.bind(this);

		this.assignValues(bet);

		this.isAutoCashoutSet =
			bet && bet.betSlipSecureWinSetting ? true : false;
	}

	//#region observable

	@observable cType?: CashoutType;
	@observable isPaidOut: boolean;
	@observable maxGain: number;
	@observable displayedAmount: string | null;
	@observable calculatedCashoutAmount: number;
	@observable indicator: number;
	@observable isRemoved: boolean;
	@observable betStatus?: any; // TODO
	@observable betSlipOffers: BetOffer[];
	@observable isCancellationAvailable: boolean;
	@observable isAutoCashoutSet: boolean;
	@observable betSlipSecureWinSetting?: BetSlipSecureWinSetting | null;

	//#endregion observable

	//#region getters

	get formattedBetSystemString() {
		const interpolation = { escapeValue: false };
		if (this.system) {
			let systemString = `${this.system} / ${
				this.eventCount - this.combinationBettingOffersBankCount
			} = ${this.combinationBettingOffersCount}`;

			if (this.combinationBettingOffersBankCount > 0) {
				systemString = `B x ${this.combinationBettingOffersBankCount}, 
					${systemString}`;
			}

			return localizationService.t("MY_BETS.ITEM.INFO_SYSTEM", {
				interpolation,
				0: `(${systemString})`,
			});
		}

		if (this.totalNumberOfCombinations > 1) {
			return localizationService.t(
				"MY_BETS.ITEM.INFO_COMBINATION_MULTI",
				{ interpolation, 0: this.totalNumberOfCombinations }
			);
		}

		return localizationService.t("MY_BETS.ITEM.INFO_COMBINATION", {
			interpolation,
			0: this.totalNumberOfCombinations,
		});
	}

	get firstFinishedTransactionItem() {
		if (
			this.metadata?.fiscalizationItems == null ||
			this.metadata.fiscalizationItems.length < 0
		) {
			return null;
		}

		return this.metadata.fiscalizationItems.find(
			// TODO
			(i: any) => i.isFinishTransaction == true
		);
	}

	get metaDataTransactionAT() {
		if (this.metadata == null) {
			return null;
		}

		if (this.metadata?.fiscalizationItems) {
			return null;
		}

		if ("fiskalyReceiptNumber" in this.metadata) {
			const metdaData: Fiskaly = this.metadata;
			if (metdaData.fiskalyReceiptNumber == null) {
				return {
					...metdaData,
					hints: ["Sicherheitseinrichtung ausgefallen"],
				};
			}
		}
		return this.metadata;
	}
	get shouldShowBetSlipStatus() {
		return (
			this.betSlipStatus.abrv.includes("cashout") ||
			this.betSlipStatus.abrv === "paid-out"
		);
	}

	//#endregion getters

	//#region object assign

	assignValues(bet: Bet) {
		// Array of bet slip combinations
		this.betSlipCombinations = bet.betSlipCombinations;

		// Array of bet slip offers
		this.betSlipOffers = bet.betSlipOffers.map(
			(offer) => new BetOffer(offer)
		);
		this.groupedOffersByEvent = this.groupOffersByEvent(this.betSlipOffers);
		this.injectIsMultiToOffers(this.groupedOffersByEvent);

		this.betSlipSecureWinSetting = bet.betSlipSecureWinSetting;
		this.betSlipStatus = new BetSlipStatus(bet.betSlipStatus);
		this.betSlipType = bet.betSlipType;
		this.betStatusId = bet.betStatusId;
		this.player = bet.player;

		this.cashoutTimeStamp = bet.cashoutTimeStamp;
		this.betSlipNumber = bet.betSlipNumber;
		this.betSlipSubmitEnvironmentTypeAbrv =
			bet.betSlipSubmitEnvironmentTypeAbrv;
		this.betStatus = bet.betStatus;
		this.bettingAccountType = bet.bettingAccountType;
		this.bettingAccountTypeId = bet.bettingAccountTypeId;
		this.calculatedCashoutAmount = bet.calculatedCashoutAmount;
		this.cashoutGain = bet.cashoutGain;
		this.code = bet.code;
		this.combinationBettingOffersBankCount =
			bet.combinationBettingOffersBankCount;
		this.combinationBettingOffersCount = bet.combinationBettingOffersCount;
		this.cType = bet.cType;
		this.eventCount = bet.eventCount;
		this.fiscalizationId = bet.fiscalizationId;
		this.fiscalizationSentDate = bet.fiscalizationSentDate;
		this.gain = bet.gain;
		this.gainBonus = bet.gainBonus;
		this.gainBonusPercentage = bet.gainBonusPercentage;
		this.gainTax = bet.gainTax;
		this.gainTaxPercentage = bet.gainTaxPercentage;
		this.handlingFee = bet.handlingFee;
		this.handlingFeePercentage = bet.handlingFeePercentage;
		this.ipAddress = bet.ipAddress;
		this.isBonus = bet.isBonus;
		this.isCancellationAvailable = bet.isCancellationAvailable;
		this.isLive = bet.isLive;
		this.isWege = bet.isWege;
		this.maxCoefficient = bet.maxCoefficient;
		this.maxGain = bet.maxGain;
		this.metadata = bet.metadata;
		this.minGain = bet.minGain;
		this.payment = bet.payment;
		this.paymentBonus = bet.paymentBonus;
		this.paymentPerCombination = bet.paymentPerCombination;
		this.payout = bet.payout;
		this.payoutDate = bet.payoutDate;
		this.slipStatusId = bet.slipStatusId;
		this.stake = bet.stake;
		this.stakePerCombination = bet.stakePerCombination;
		this.stakeTaxPercentage = bet.stakeTaxPercentage;
		this.system = bet.system;
		this.taxFreePayoutAmount = bet.taxFreePayoutAmount;
		this.totalNumberOfCombinations = bet.totalNumberOfCombinations;
		this.totalNumberOfOffersPerBetSlip = bet.totalNumberOfOffersPerBetSlip;
		this.userId = bet.userId;
		this.dateCreated = bet.dateCreated;
		this.dateUpdated = bet.dateUpdated;
		this.id = bet.id;
		this.shopAddress = bet.shopAddress;

		// These are missing from response
		this.displayedAmount = this.calculatedCashoutAmount?.toFixed(2);
		this.indicator = 0;
		this.isRemoved = false;
		this.currency =
			App.state.rootStore.userAuthStore.userAccounts?.find(
				(acc) => acc.accountTypeId === this.bettingAccountTypeId
			)?.currency.displayCode ||
			ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY);
		this.displayPaymentPerCombination = getDisplayedAmount(
			this.paymentPerCombination,
			this.currency
		);
		this.displayPayment = getDisplayedAmount(this.payment, this.currency);
		this.displayHandlingFee = getDisplayedAmount(
			this.handlingFee,
			this.currency
		);
		this.displayGainTax = getDisplayedAmount(this.gainTax, this.currency);
		this.displayGainBonus = getDisplayedAmount(
			this.gainBonus,
			this.currency
		);
		this.displayMaxGain = getDisplayedAmount(this.maxGain, this.currency);
		this.displayGain = getDisplayedAmount(this.gain, this.currency);
		this.displayPayout = getDisplayedAmount(this.payout, this.currency);
	}

	/**
	 * @param {array} offerGroupsByEvent array of betSlipOffers grouped by event
	 */
	injectIsMultiToOffers(offerGroupsByEvent: BetOffer[][]) {
		for (const eventOffers of offerGroupsByEvent) {
			if (eventOffers.length > 1) {
				for (let i = 1; i < eventOffers.length; i++) {
					eventOffers[i].isMulti = true;
					eventOffers[i].multiEventName = `${i + 1} Multi`;
				}
			}
		}
	}

	groupOffersByEvent(offers: BetOffer[]): BetOffer[][] {
		const offerGroupsByEvent = new Map();

		for (const offer of offers) {
			const eventId = offer.eventId;

			if (!offerGroupsByEvent.has(eventId)) {
				offerGroupsByEvent.set(eventId, []);
			}

			offerGroupsByEvent.get(eventId).push(offer);
		}

		return Array.from(offerGroupsByEvent.values());
	}

	//#endregion object assign
}

export class BetSlipStatus {
	abrv?: string;
	dateCreated: DateTime;
	dateUpdated: DateTime;
	id: string;
	name?: string;

	constructor(betSlipStatus: BetSlipStatus) {
		this.abrv = betSlipStatus.abrv;
		this.dateCreated = betSlipStatus.dateCreated;
		this.dateUpdated = betSlipStatus.dateUpdated;
		this.id = betSlipStatus.id;
		this.name = betSlipStatus.name;
	}
}
