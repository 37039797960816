import { UserLimit } from "@data-types/account-settings/MyLimits";
import { LimitDurationDataType } from "@data-types/membership/registration";

export function sortDurations<T extends UserLimit | LimitDurationDataType>(
	durations: T[] | null
): T[] | [] {
	if (durations == null) {
		return [];
	}
	return durations?.sort((a, b) => a.units - b.units);
}
