import { getCurrentCulture, isNullOrWhitespace, AccountTypes } from "@utils";
import { VerifyAndChangePasswordParamsDto } from "@api-types/user/VerifyAndChangePasswordParamsDto";
import { PasswordChangeDto } from "@data-types";
import { getUsername } from "@utils";

// import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { getRedirectOriginUrl } from "@utils/specific/capacitor";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));
export class PasswordChangeService {
	static getUserEmailAndUserName(
		activeAccount: AccountTypes = AccountTypes.OFFLINE
	): { Email: string; Username: string } {
		const getUserType =
			App.state.rootStore.userAuthStore.getUserAccountType(activeAccount);

		if (App.state.rootStore.userAuthStore.user == null) {
			throw "Expected user, got null";
		}

		const email: string = App.state.rootStore.userAuthStore.user?.email;
		const userName = getUsername(getUserType);

		return { Email: email, Username: userName };
	}

	static async changeUserPassword(
		model: PasswordChangeDto,
		activeAccount: AccountTypes = AccountTypes.OFFLINE
	): Promise<boolean> {
		if (
			isNullOrWhitespace(model.oldPassword) ||
			isNullOrWhitespace(model.newPassword)
		) {
			throw { message: "GENERIC_ERROR" };
		}

		try {
			const passwordModel: VerifyAndChangePasswordParamsDto = {
				oldPassword: model.oldPassword,
				newPassword: model.newPassword,
				sendMailNotification: true,
				siteUrl: `${getRedirectOriginUrl("home")}`,
			};
			const getUserType =
				App.state.rootStore.userAuthStore.getUserAccountType(
					activeAccount
				);
			await (
				await AgencyUserApiService
			).default.changeUserPassword(passwordModel, getUserType);
			return true;
		} catch (error) {
			if (error.message == "Unauthorized") {
				return false;
			}

			let errorMessage = "";

			if (error.data.errorCode == 400176) {
				errorMessage = "INVALID_PASSWORD";
			} else if (
				error.data.errorCode == 400170 ||
				error.data.errorCode == 400171
			) {
				errorMessage = "ERROR_PWD_CHANGE_OLD_SAME";
			} else if (error.data.errorCode == 400165) {
				errorMessage = "ERROR_PWD_CHANGE_SIMILAR";
			} else if (error.data.errorCode == 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isChangePasswordFailed=true`
				);
				throw false;
			} else {
				errorMessage = "ERROR_PWD_CHANGE";
			}
			throw { message: errorMessage };
		}
	}
}
