import { useContext } from "react";
import { EventOffer } from "@gp/offer";
import { useLocation } from "react-router";
import { min } from "lodash";
import { SetIsAdditionalOfferOpenCtxProviderForParentLayout } from "@context/SetIsAdditionalOfferOpenCtxProviderForParentLayout";

export function additionalOfferToggle(event: EventOffer) {
	if (
		App.state.getUrlParamsAsObj<{ eventId: string }>().eventId !== event.id
	) {
		additionalOfferOpen(event);
	} else {
		additionalOfferClose();
	}
}

export function additionalOfferOpen(event: EventOffer) {
	App.state.addToUrlParams(
		{
			eventId: event.id,
			sportId: event.sportId,
			isAdditionalLive: event.isLive,
			mybets:
				App.state.getUrlParamsAsObj<{ mybets: string }>().mybets ===
				"true"
					? true
					: false,
		},
		true
	);
}

export function additionalOfferClose() {
	App.state.removeFromUrlParams(
		["eventId", "sportId", "isAdditionalLive", "mybets"],
		true
	);
}

export function useNumberOfAvailableColums(availableColumns: number): number {
	const isAdditionalOfferVisible = useContext(
		SetIsAdditionalOfferOpenCtxProviderForParentLayout
	).isAdditionalOpen;
	return min([
		isAdditionalOfferVisible
			? Math.floor(availableColumns / 2)
			: availableColumns,
		isAdditionalOfferVisible ? 2 : 4,
	]) as number;
}

export function useGetAdditionalOfferEvent():
	| { eventId: string; sportId: string }
	| undefined {
	// This is required to trigger rerender
	useLocation();
	const isAdditionalOfferVisible = useContext(
		SetIsAdditionalOfferOpenCtxProviderForParentLayout
	).isAdditionalOpen;
	const additionalOffer = App.state.getUrlParamsAsObj<{
		eventId?: string;
		sportId?: string;
	}>();
	if (
		// !isAdditionalOfferVisible ||
		additionalOffer.eventId == null ||
		additionalOffer.eventId == undefined ||
		additionalOffer.sportId == null
	) {
		return undefined;
	}

	return {
		eventId: additionalOffer.eventId,
		sportId: additionalOffer.sportId,
	};
}

export function useIsAdditionalOfferVisible(): boolean {
	return useContext(SetIsAdditionalOfferOpenCtxProviderForParentLayout)
		.isAdditionalOpen;
}

export function useGetIsAdditionalLive(): boolean {
	// This is required to trigger rerender
	useLocation();
	return (
		App.state.getUrlParamsAsObj<{ isAdditionalLive?: boolean }>()
			.isAdditionalLive === true
	);
}
