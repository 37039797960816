import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
	MyBetsCashoutButton,
	MyBetsAutosellButton,
	ShareBetSlipButton,
} from "../../../my-bets/components";
import { MyBetsViewStore } from "@offer/stores/components/my-bets";

import { Bet } from "@administration/pages/my-bets/model";
import {
	getTip,
	getType,
	shouldShowBetSlipStatus,
} from "../../../../../helpers";
import { getCurrentCulture } from "@utils";
import { WalletIcon } from "@v2/components/shared/utility";

import { DisplayDate } from "./";
import { EventType } from "@gp/models";
import BetOfferEventTime from "./BetOfferEventTime";
import BetItemPaymentDetail from "./BetItemPaymentDetail";
import { MyBetsStoreContext } from "@v2/context";
import { ShareBetSlipModal } from "@v2/components/desktop/my-bets/modals";

/* No ticket type in header */
const SingleItem = observer(function SingleItem(props: {
	bet: Bet;
	isFull: boolean;
	myBetsViewStore: MyBetsViewStore;
}) {
	const { t } = useTranslation();
	const offer = props.bet.betSlipOffers[0];
	const hasActiveOffers = props.bet.betSlipOffers.some(
		(offer) => offer.bettingOfferStatus == 1
	);

	const reuseLabelLength = t<string>("MY_BETS.REUSE.BUTTON_LABEL").length;

	const headerClasses = classNames(
		"widget--bets__header",
		[`widget--bets__header--${getCurrentCulture()}`],
		{
			// "widget--bets__header--col": props.isFull,
			"widget--bets__header--col": reuseLabelLength > 14,
		}
	);

	const statusClasses = classNames("widget--bets__status");

	const reuseBtnClasses = classNames(
		"btn btn--wrap btn--wrap--xtny btn--secondary--inverted btn--icon btn--animate-rotate",
		{
			"btn--block": reuseLabelLength > 14,
		}
	);

	if (props.bet.isRemoved) {
		return null;
	}

	return (
		<div key={props.bet.id} className="widget--bets__item">
			<div className={headerClasses}>
				<div className={statusClasses}>
					<span
						className={
							"card--bet__status tag tag--med tag--bet tag--bet--" +
							props.bet.betStatus.abrv
						}
					>
						{props.bet.betStatus.name}
						{props.bet.isBonus && (
							<> ({t<string>("COMMON.BONUS")})</>
						)}
					</span>

					{shouldShowBetSlipStatus(props.bet) && (
						<span
							className={
								"tag tag--med tag--slip tag--slip--" +
								props.bet.betSlipStatus.abrv +
								"--inverted"
							}
						>
							{t<string>(
								`MY_BETS.STATUS.${props.bet.betSlipStatus.abrv.toUpperCase()}`
							)}
						</span>
					)}

					<WalletIcon
						bettingAccountTypeAbrv={
							props.bet.bettingAccountType?.abrv
						}
						classList="u-mar--left--reset"
					/>
				</div>

				<ShareBetSlipButton bet={props.bet} isMini={true} btnTiny />

				{hasActiveOffers ? (
					<>
						<button
							className={reuseBtnClasses}
							type="button"
							onClick={() =>
								props.myBetsViewStore.openReuseBetSlipModal({
									id: props.bet.id,
									betSlipNumber: props.bet.betSlipNumber,
									isLive: props.bet.isLive,
								})
							}
							title={t<string>("MY_BETS.REUSE.BUTTON_LABEL")}
						>
							<i className="u-icon u-icon--sml u-icon--refresh" />
						</button>
					</>
				) : null}
			</div>

			<div className="widget--bets__info">
				{offer.sportData.eventType &&
				offer.sportData.eventType != EventType.NORMAL ? (
					<div className="widget--bets__title">
						{offer.sportData.eventName}
					</div>
				) : (
					<div className="widget--bets__title">
						{offer.sportData.teamOneName} -{" "}
						{offer.sportData.teamTwoName}
					</div>
				)}

				<BetOfferEventTime
					myBetsViewStore={props.myBetsViewStore}
					bet={props.bet.betSlipOffers[0]}
				/>

				<div className="widget--bets__pick">
					<div className="widget--bets__offer">
						{offer.sportData.eventType &&
						offer.sportData.eventType != EventType.NORMAL ? null : (
							<div className="widget--bets__type">
								{getType(offer)}:
							</div>
						)}
						<div className="widget--bets__tip">{getTip(offer)}</div>
					</div>
					<div className="widget--bets__quote">{offer.value}</div>
				</div>
			</div>

			<div className="widget--bets__row">
				<div className="widget--bets__col">
					<div className="widget--bets__label">
						{t<string>("MY_BETS.ITEM.INFO_PAYMENT_LABEL")}:
					</div>
					<div className="widget--bets__amount">
						{props.bet.displayPayment}
					</div>
				</div>
				<div className="widget--bets__col">
					<BetItemPaymentDetail
						betStatusAbrv={props.bet.betStatus.abrv}
						betSlipStatusAbrv={props.bet.betSlipStatus.abrv}
						payout={props.bet.displayPayout}
						maxGain={props.bet.displayMaxGain}
					/>
				</div>
				<div className="widget--bets__col">
					<div className="widget--bets__label">
						{t<string>("MY_BETS.ITEM.ADDITIONAL_MAX_COEFFICIENT")}:
					</div>
					<div className="widget--bets__amount">
						{props.bet.maxCoefficient}
					</div>
				</div>
			</div>

			{props.bet.displayedAmount != null && (
				<div className="widget--bets__actions">
					<MyBetsCashoutButton
						bet={props.bet}
						openCashoutModal={
							props.myBetsViewStore.cashoutStore.openCashoutModal
						}
					/>
					<MyBetsAutosellButton
						bet={props.bet}
						openAutosellModal={
							props.myBetsViewStore.cashoutStore.openAutosellModal
						}
						isAutoCashoutEnabled={
							props.myBetsViewStore.cashoutStore
								.isAutoCashoutEnabled
						}
					/>
				</div>
			)}

			<div className="widget--bets__footer">
				<div className="widget--bets__date">
					<DisplayDate betDateTimeStamp={props.bet.dateCreated} />
				</div>
			</div>
		</div>
	);
});

export default SingleItem;
