import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";

export default observer(function ToggleWidgetFloatButton(props: {
	headerRef: HTMLDivElement | null;
	tooltipId: string;
}) {
	const store = useLiveWidgetStore();
	const t = useTranslation().t;

	return (
		<button
			type="button"
			className="widget__actions__btn btn btn--square btn--square--sml btn--ghost"
			onClick={() => {
				if (
					props.headerRef == null ||
					!(props.headerRef instanceof Element) ||
					props.headerRef.parentElement == null
				) {
					return;
				}

				const container = props.headerRef.parentElement;
				const boundRect = container.getBoundingClientRect();

				store.updateWidgetFloat(!store.isWidgetFloating, {
					top: window.innerHeight - window.innerHeight * 0.33 - 380,
					left: window.innerWidth - window.innerWidth * 0.33 - 50,
					maxWidth: "33vw",
					minWidth: boundRect.width,
					width: "33vw",
					height: "unset",
				});
			}}
			data-tooltip-id={props.tooltipId}
			data-tooltip-content={
				store.isWidgetFloating
					? t<string>("LIVE_WIDGET.TOOLTIP.COLLAPSE")
					: t<string>("LIVE_WIDGET.TOOLTIP.EXPAND")
			}
			data-tooltip-position-strategy="fixed"
		>
			<i
				className={classNames("u-icon", {
					"u-icon--sml u-icon--video-float u-color--text--primary":
						!store.isWidgetFloating,
					"u-icon--xmed u-icon--video-unfloat u-color--text--primary":
						store.isWidgetFloating,
				})}
			/>
		</button>
	);
});
