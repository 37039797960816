import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { PasswordInput } from "@v2/components/desktop/shared/inputs";

export const DepositPasswordConfirmation: FC = observer(
	function DepositPasswordConfirmation() {
		const { t } = useTranslation();

		return (
			<PasswordInput
				name="password"
				className="form__group"
				label={t<string>("DEPOSIT.PASSWORD_CONFIRM_MESSAGE")}
				placeholder={"COMMON.PASSWORD_CONFIRMATION.PASSWORD_TITLE"}
				required
			/>
		);
	}
);
