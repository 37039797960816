import React from "react";
import { DeviceDetector } from "@utils";

type Modifiers = {
	mono: boolean;
};

const modifierOrder: (keyof Modifiers)[] = ["mono"];

type Props = (
	| {
			type: "sport";
			sport: string;
			treeIcon?: Boolean;
	  }
	| {
			type: "category";
			sport: string;
			category: string;
			treeIcon?: Boolean;
	  }
	| {
			type: "tournament";
			catOverSportClass?: string;
			sport: string;
			category: string;
			tournament: string;
			treeIcon?: Boolean;
			mobile?: boolean;
	  }
	| {
			type: "topTournament";
			topTournament: string;
	  }
) & {
	className?: string;
	wrapperClassName?: string;
	modifiers?: Partial<Modifiers>;
	countryCode?: boolean;
	mainMobileMenu?: Boolean;
	treeIcon?: Boolean;
};

const OfferIcons = React.memo(function OfferIcons(props: Props) {
	const [errorSrcCount, setErrorSrcCount] = React.useState(-1);
	const [isLoading, setIsLoading] = React.useState(true);

	React.useEffect(() => {
		setErrorSrcCount(-1);
	}, [props]);

	const sources = useGetSources(props);

	if (
		props.type === "tournament" &&
		errorSrcCount != -1 &&
		props.catOverSportClass != null
	) {
		///CASE FOR TOURNAMENTS THAT ARE MISSING THEIR OWN ICON
		//SET SPORT + CATEGORY ICONS
		const { sport, category, mobile } = props;
		const src = `${WEBPACK_CDN_ROOT_URL}/icons/sport/${sport}`;
		const sportSrc = src + `/${sport}.svg`;
		const categorySrc = src + `/${category}/${category}.svg`;
		/* 		const tournamentSrc = src + `/${category}/${category}.svg`; */

		return (
			<>
				<picture className="picture--primary">
					<img
						// @ts-expect-error
						loading="lazy"
						className={props.className || ""}
						src={sportSrc}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src =
								"/assets/images/sportFallback.svg";
						}}
						onLoad={() => setIsLoading(false)}
					/>
				</picture>
				{/* Mobile should display only sport OR specific icon */}
				{!mobile && (
					<picture className="picture--secondary">
						<img
							// @ts-expect-error
							loading="lazy"
							className={props.catOverSportClass || ""}
							src={categorySrc}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src =
									"/assets/images/international.svg";
							}}
							style={isLoading ? { opacity: "0" } : {}}
						/>
					</picture>
				)}
			</>
		);
	}

	if (props.treeIcon) {
		//This case is used to optimize tree rendering, esp on slow networks
		//this will prevent flashes,flashes of broken images, or displaying fallback and then loaded SVG
		//it's used for displaying category and tournament tree icons

		const [display, setDisplay] = React.useState(false);

		let imgSrc: string;
		if (props.mainMobileMenu) {
			imgSrc = `${WEBPACK_CDN_ROOT_URL}/icons/sport/${
				// @ts-expect-error
				props.sport
				// @ts-expect-error
			}/${trimAmateur(props.category)}/${trimAmateur(
				// @ts-expect-error
				props.category
			)}.svg`;
		} else {
			imgSrc =
				sources[
					props.type === "tournament"
						? 0
						: sources.length - (props.type === "category" ? 3 : 1)
				];
		}

		return (
			<img
				// @ts-expect-error
				loading="lazy"
				src={imgSrc}
				key={imgSrc}
				alt=""
				style={
					display || props.type !== "tournament"
						? {}
						: { opacity: "0" }
				}
				className={props.className || ""}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null;
					if (props.type === "category" || props.mainMobileMenu) {
						currentTarget.src = "/assets/images/international.svg";
					} else {
						currentTarget.style.display = "none";
					}
				}}
				onLoad={() => {
					setDisplay(true);
				}}
			/>
		);
	}

	return (
		<picture className={props.wrapperClassName || ""}>
			{sources
				// Error count counts how many urls returned error from start of the list
				.filter((src, idx) => idx > errorSrcCount)
				.map((src) => (
					<source key={src} srcSet={src} />
				))}
			<img
				// @ts-expect-error
				loading="lazy"
				className={props.className}
				style={isLoading ? { opacity: "0" } : {}}
				src={"/assets/images/sportFallback.svg"}
				onError={() => setErrorSrcCount(1 + errorSrcCount)}
				onLoad={() => setIsLoading(false)}
			/>
		</picture>
	);
});

function useGetSources(params: Props): string[] {
	const modifiers = React.useMemo(
		() => getModifiers(params.modifiers),
		[params.modifiers]
	);
	return React.useMemo(
		() => getBaseSources(params, modifiers),
		[params, modifiers]
	);
}

function trimAmateur(val: string): string {
	//remove amateur from name to avoid uploading separate icons for every "-amateur" category
	return val.includes("amateur") ? val.replace("-amateur", "") : val;
}

function getModifiers(modifiers: Partial<Modifiers> | undefined): string {
	if (modifiers == null) {
		return "";
	}

	return modifierOrder.reduce((acc, x) => {
		if (modifiers == null) {
			return acc;
		}
		if (modifiers[x]) {
			return acc + "--" + x;
		}
		return acc;
	}, "");
}

function getBaseSources(params: Props, modifiers: string): string[] {
	const iconUrls = [];

	if (params.countryCode && params.type === "category") {
		iconUrls.push(
			`${WEBPACK_CDN_ROOT_URL}/icons/countries/${params.category.toLowerCase()}.svg`
		);
	} else {
		if (params.type === "tournament") {
			if (modifiers != "") {
				iconUrls.push(
					`${WEBPACK_CDN_ROOT_URL}/icons/sport/${
						params.sport
					}/${params.category.toLowerCase()}/${params.tournament.toLowerCase()}/${params.tournament.toLowerCase()}${modifiers.toLowerCase()}.svg`
				);
			}

			iconUrls.push(
				`${WEBPACK_CDN_ROOT_URL}/icons/sport/${
					params.sport
				}/${params.category.toLowerCase()}/${params.tournament.toLowerCase()}/${params.tournament.toLowerCase()}.svg`
			);

			if (params.treeIcon) return iconUrls;
		}

		if (params.type === "category" || params.type === "tournament") {
			if (modifiers != "") {
				iconUrls.push(
					`${WEBPACK_CDN_ROOT_URL}/icons/sport/${
						params.sport
					}/${params.category.toLowerCase()}/${params.category.toLowerCase()}${modifiers.toLowerCase()}.svg`
				);
			}

			iconUrls.push(
				`${WEBPACK_CDN_ROOT_URL}/icons/sport/${
					params.sport
				}/${trimAmateur(params.category.toLowerCase())}/${trimAmateur(
					params.category.toLowerCase()
				)}.svg`
			);

			iconUrls.push("/assets/images/international.svg");
		}

		if (
			params.type === "sport" ||
			params.type === "category" ||
			params.type === "tournament"
		) {
			if (modifiers != "") {
				iconUrls.push(
					`${WEBPACK_CDN_ROOT_URL}/icons/sport/${params.sport}/${params.sport}${modifiers}.svg`
				);
			}

			iconUrls.push(
				`${WEBPACK_CDN_ROOT_URL}/icons/sport/${params.sport}/${params.sport}.svg`
			);
		}
	}
	return iconUrls;
}

export default OfferIcons;
