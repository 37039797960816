import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useRootOfferStore } from "@hooks";
import { ResultsMenuStoreContext } from "@context/results";
import classnames from "classnames";

export const ResultDataTabs = observer(function ResultDataTabs() {
	const offerStore = useRootOfferStore();
	const {
		dayParam: selectedDay,
		changeDayParam: changeDay,
		isSearch,
		resetResultsData,
	} = useContext(ResultsMenuStoreContext).resultsDataStore;

	const { t } = useTranslation();

	const todayTabClasses = classnames("tabs--primary__item", {
		"is-active": selectedDay === 0,
	});
	const yesterdayTabClasses = classnames("tabs--primary__item", {
		"is-active": selectedDay === -1,
	});

	if (isSearch) {
		return (
			<div className="card--primary__header">
				<div className="card--primary__title">
					<i className="u-icon u-icon--med u-icon--search u-align--v--middle u-mar--right--xtny"></i>
					<span className="u-align--v--middle">
						{t("RESULTS.RESULT_CONTENT.SEARCH.SEARCH_RESULT_FOR")}
						<i className="u-mar--left--xtny">
							{offerStore.searchBarViewStore.searchTerm}
						</i>
					</span>
				</div>

				<div className="card--primary__actions">
					<button
						className="card--primary__actions__btn btn btn--tny btn--square--tny btn--secondary--inverted btn--icon"
						type="button"
						onClick={resetResultsData}
					>
						<i className="u-icon u-icon--sml u-icon--close"></i>
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="card--primary__tabs">
			<nav className="tabs--primary">
				<div className={todayTabClasses} onClick={() => changeDay(0)}>
					{t("RESULTS.RESULT_CONTENT.TODAY")}
				</div>
				<div
					className={yesterdayTabClasses}
					onClick={() => changeDay(-1)}
				>
					{t("RESULTS.RESULT_CONTENT.YESTERDAY")}
				</div>
			</nav>
		</div>
	);
});
