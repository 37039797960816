import React, { useContext } from "react";
import { observer } from "mobx-react";

import {
	ResultsMenuCategoryContext,
	ResultsMenuStoreContext,
} from "@context/results";
import {
	ResultMenuCategory,
	ResultMenuTournament,
} from "@data-types/results/ResultMenuData";
import classnames from "classnames";
import OfferIcon from "@v2/components/shared/icons/Offer";

export const SelectedSportWrapper = observer(function SelectedSportWrapper() {
	const store = useContext(ResultsMenuStoreContext);

	if (store.selectedSport == null) {
		return null;
	}

	return (
		<nav className="nav--tertiary">
			<ul className="nav--tertiary__menu">
				<SelectedSportCategoriesList />
			</ul>
		</nav>
	);
});

const SelectedSportCategoriesList = function SelectedSportCategoriesList() {
	const store = useContext(ResultsMenuStoreContext);

	if (store.selectedSportObj == null) {
		return null;
	}

	return (
		<>
			{store.selectedSportObj.children.map(
				(category: ResultMenuCategory) => (
					<ResultsMenuCategoryContext.Provider
						key={category.id}
						value={category}
					>
						<SelectedSportCategory />
					</ResultsMenuCategoryContext.Provider>
				)
			)}
		</>
	);
};

const SelectedSportCategory = observer(function SelectedSportCategory() {
	const store = useContext(ResultsMenuStoreContext);
	const category = useContext(ResultsMenuCategoryContext);

	const categoryButtonClasses = classnames("nav--tertiary__link", {
		"is-expanded": store.expandedCategories.includes(category.id),
	});

	if (store.selectedSportObj == null) {
		return null;
	}

	return (
		<li
			className="nav--tertiary__item"
			onClick={(e) => {
				e.stopPropagation();
				store.setExpandedCategories(category.id);
			}}
		>
			<button className={categoryButtonClasses} type="button">
				<span className="nav--tertiary__title">
					<OfferIcon
						type="category"
						sport={store.selectedSportObj.abrv}
						category={category.abrv}
						className="nav--tertiary__icon u-icon u-icon--med u-mar--right--micro"
					/>
					<span>{category.name}</span>
				</span>
				<span className="nav--tertiary__counter counter counter--base counter--simple">
					{category.count}
				</span>
			</button>

			<ul className="nav--quaternary">
				<ResultsMenuTournamentsList />
			</ul>
		</li>
	);
});

const ResultsMenuTournamentsList = function ResultsMenuTournamentsList() {
	const store = useContext(ResultsMenuStoreContext);
	const category = useContext(ResultsMenuCategoryContext);

	if (!store.expandedCategories.includes(category.id)) {
		return null;
	}

	return (
		<>
			{category.children.map((tournament: ResultMenuTournament) => {
				return (
					<ResultsMenuTournament
						key={tournament.id}
						tournament={tournament}
					/>
				);
			})}
		</>
	);
};

const ResultsMenuTournament = observer(function ResultsMenuTournament(props: {
	tournament: ResultMenuTournament;
}) {
	const store = useContext(ResultsMenuStoreContext);

	const anchorClasses = classnames("nav--quaternary__link", {
		"is-active": store.selectedTournaments.some(
			(t) => t.id === props.tournament.id
		),
	});

	return (
		<li
			className="nav--quaternary__item"
			onClick={(e) => {
				e.stopPropagation();
				store.setSelectedTournaments(props.tournament);
			}}
		>
			<a className={anchorClasses}>
				<span>{props.tournament.name}</span>
				<span className="nav--tertiary__counter counter counter--base counter--simple">
					{props.tournament.count}
				</span>
			</a>
		</li>
	);
});
