import { useRootAppStore } from "@hooks";
import { getUserFromLocalStorage } from "@utils";

import React from "react";
import { useTranslation } from "react-i18next";

export default function BettingDisabledInfo() {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();

	if (rootStore.userAuthStore.activeUserWallet === false) {
		return null;
	}
	let user = getUserFromLocalStorage(
		rootStore.userAuthStore.activeUserWallet
	);

	if (!user || !user.isBettingDisabled) {
		return null;
	}

	return (
		<div className="popup__col col col-sml-12">
			<div className="message message--base">
				<div className="message__content u-padd--reset">
					<div className="message__title u-type--xmed">
						<i className="message__icon u-icon u-icon--lrg u-icon--limits u-color--ui--danger u-mar--right--tny" />
						<span className="u-align--v--middle">
							{t<string>("WELCOME_POPUP.BETTING_DISABLE_TITLE")}
						</span>
					</div>
					<div className="message__text">
						{t<string>("WELCOME_POPUP.BETTING_DISABLE_HINT")}
					</div>
				</div>
			</div>
		</div>
	);
}
