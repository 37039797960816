import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLoading } from "@hooks";
import { useNotification } from "../../../../ui/common/hooks";
import { useAccountLock } from ".";
import { sortArray } from "@v2/helpers/account-settings/account-lock/utils";
import {
	UserAccountLockDurationTypesModel,
	UserAccountLockReasonTypesModel,
} from "@api-types";

import { PlayerPromoSendingMode } from "@api-types/user/AgencyUserDto";
import { AccountClosureUpdateModelData } from "@data-types/account-settings/AccountLockDto";
import { logger } from "@state";
class LookupsStateModel {
	reasonTypes: UserAccountLockReasonTypesModel[];
	durationTypes: UserAccountLockDurationTypesModel[];
}

export default function useAccountClosure() {
	const { getAccountClosureViewData, createAccountLock } = useAccountLock();

	const { reset } = useFormContext();

	const { showError } = useNotification();
	const { setIsLoading } = useLoading();

	const [lookups, setLookups] = useState<LookupsStateModel>({
		reasonTypes: [],
		durationTypes: [],
	});

	const onInitialize = async () => {
		try {
			setIsLoading(true);
			const response = await getAccountClosureViewData();
			if (response != null) {
				setLookups({
					reasonTypes: response.reasonTypes,
					durationTypes: sortArray(response.durationTypes),
				});
				reset({
					isPromoApproved:
						response?.playerPromoSendingMode ==
						PlayerPromoSendingMode.enabled
							? true
							: false,
				});
			}
		} catch (error) {
			logger.logError(error);
			showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.message}`);
		} finally {
			setIsLoading(false);
		}
	};

	const onSuccess = async (data: AccountClosureUpdateModelData) => {
		try {
			if (App.state.rootStore.userAuthStore.user == null) {
				throw "Expected user, got null";
			}

			const modifiedData = {
				...data,
				agencyUserIds: {
					userId: App.state.rootStore.userAuthStore.user.id,
					agencyId: App.state.rootStore.userAuthStore.user.agencyId,
				},
			};
			setIsLoading(true);
			await createAccountLock(modifiedData);
		} catch (error) {
			logger.logError(error);
			reset();
			showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.message}`);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		onInitialize,
		onSuccess,
		lookups,
	};
}
