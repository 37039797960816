import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { MyBetsStoreContext } from "../../../../context";
import { Modal } from "@v2/components/shared";
import { useFixBody } from "@v2/hooks/shared";
import Button from "@components/buttons/Button";
import { Bet } from "@administration/pages/my-bets/model";

export default observer(function ReuseSharedBetSlipPopUp(props: {
	isOnOffer?: boolean;
}) {
	const store = useContext(MyBetsStoreContext);

	if (store == null || !store.betSlipShareStore.isReuseShareModalOpen) {
		return null;
	}

	return (
		<ReuseSharedBetSlipConfirmation
			isOpen={store.betSlipShareStore.isReuseShareModalOpen}
			{...store.betSlipShareStore.reuseShareModalData}
			onAccept={store.betSlipShareStore.reuseSharedBetSlip}
			onClose={store.betSlipShareStore.closeReuseBetSlipModal}
			isOnOffer={props.isOnOffer}
		/>
	);
});

export const ReuseSharedBetSlipConfirmation = observer(
	function ReuseSharedBetSlipConfirmation(props: {
		id?: string;
		betSlipNumber?: string;
		isLive?: boolean;
		onClose: () => void;
		onAccept: (isLive: boolean, bet: Bet) => void;
		isOpen?: boolean;
		isOnOffer?: boolean;
		userId?: string;
	}) {
		const t = useTranslation().t;
		useFixBody(true, props.isOpen);
		const store = useContext(MyBetsStoreContext);
		const [isYesButtonDisabled, setIsYesButtonDisabled] =
			useState<boolean>(false);
		if (!store || store?.type != "BetDetailsStore") {
			return null;
		}
		const betDetailsStore = store;
		function onClick() {
			setIsYesButtonDisabled(true);
			if (!betDetailsStore.bet) {
				console.error("There should be bet slip offers");
				return;
			}
			props.onAccept(false, betDetailsStore.bet);
		}

		return (
			<Modal>
				<div className="popup">
					<div className="popup__card">
						<div className="popup__header">
							{t("MY_BETS.SHARE.SHARE_POPUP.TITLE")}
						</div>
						<div className="popup__body">
							<div className="u-mar--bottom--xtny">
								{t("MY_BETS.SHARE.SHARE_POPUP.DESCRIPTION")}
							</div>
						</div>
						<div className="popup__footer">
							<button
								className="popup__footer__btn btn btn--sml btn--secondary--inverted"
								onClick={props.onClose}
								type="button"
							>
								{t("COMMON.CANCEL")}
							</button>
							<Button
								className="popup__footer__btn btn btn--sml btn--primary"
								onClick={onClick}
								type="submit"
								btnText={t("COMMON.YES")}
								isDisabled={isYesButtonDisabled}
							></Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
);
