import { useLoading } from "@hooks";
import { emailActivationService } from "@v2/data-access/membership";
import { useState } from "react";

export default function useEmailActivation() {
	const [activationState, setActivationState] = useState<boolean | null>(
		null
	);
	const [errorMessage, setErrorMessage] = useState(null);

	const { setIsLoading } = useLoading();

	const urlParams = new URLSearchParams(App.state.history.location.search);

	const activateUserEmail = async () => {
		const mailkey = urlParams.get("mailkey");
		const username = urlParams.get("username");

		try {
			setIsLoading(true);
			const response = await emailActivationService.emailActivation(
				mailkey,
				username
			);
			if (response.success) {
				setActivationState(true);
			}
		} catch (error) {
			setActivationState(false);
			setErrorMessage(error?.error?.message);
		} finally {
			setIsLoading(false);
		}
	};

	return { activateUserEmail, activationState, errorMessage };
}
