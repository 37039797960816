import React, { useContext, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EventContext } from "@gp/components";
import { openEventStatistics } from "@utils";
import { ProviderNameEnum } from "@gp/models";

export default observer(function StatisticsButton(props: {
	isOfferButton?: boolean;
}) {
	const [isHover, setIsHover] = useState(false);

	const event = useContext(EventContext);

	// only BR events may have statistics
	const isBREvent =
		event?.source?.providerName == ProviderNameEnum.BetRadar ? true : false;
	const betRadarId = isBREvent ? event.source?.matchId : null;

	// class order matters here
	const buttonClasses = classNames(
		"at-actionStats",
		{
			offer__actions__btn: props.isOfferButton,
			event__actions__btn: !props.isOfferButton,
		},
		"btn",
		{
			"btn--square--reset btn--ghost": props.isOfferButton,
			"btn--square btn--square--xtny btn--ghost": !props.isOfferButton,
			"is-disabled": !betRadarId,
		}
	);

	const iconClasses = classNames(
		{
			offer__actions__icon: props.isOfferButton,
			event__actions__icon: !props.isOfferButton,
		},
		"u-icon",
		{
			"u-icon--med u-icon--med--responsive": props.isOfferButton,
			"u-icon--med": !props.isOfferButton,
			"u-icon--action--stats--active": !isHover && betRadarId,
			"u-icon--action--stats--selected": isHover && betRadarId,
			"u-icon--action--stats--disabled": !betRadarId,
		}
	);

	return (
		<button
			className={buttonClasses}
			type="button"
			disabled={!betRadarId}
			onClick={() => {
				if (betRadarId != null) {
					openEventStatistics(betRadarId);
				}
			}}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<i className={iconClasses} />
		</button>
	);
});
