import React, { useContext, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { UserAccountType } from "@common/constants";
import { MyBetsStoreContext } from "../../../../context";
import { useUser } from "@v2/hooks";

export default observer(function AccountTypeSelector() {
	const { t } = useTranslation();

	const user = useUser();

	const betStore = useContext(MyBetsStoreContext);

	if (betStore == null) {
		console.log("Expected bet store context got null");
		return null;
	}

	if (betStore.type !== "MyBetsStore") {
		console.error("Expected my bets store got something else.");
		return null;
	}

	const {
		onlineAccountTypeId,
		onlineShopAccountTypeId,
		offlineAccountTypeId,
		myBetsFilterStore,
		myBetsFilterStore: {
			setAccountTypeIds,
			bettingAccountTypeIds,
			setRawFilterData,
		},
	} = betStore;

	useEffect(() => {
		let betStatusIds = "open";
		if (!window.location.pathname.includes("open")) {
			betStatusIds = "closed";
		}

		if (
			App.state.history.location.search != null &&
			App.state.history.location.search !== ""
		) {
			return;
		}

		setRawFilterData({
			...(myBetsFilterStore.rawData || {}),
			betStatusIds: betStatusIds,
			bettingAccountTypeIds: [user.availableUserWallets![0]],
		});
	}, []);
	const availableWallets = useMemo(
		() =>
			App.state.rootStore.userAuthStore.userAccounts
				?.filter(
					(wallet) =>
						wallet.userId ===
							App.state.rootStore.userAuthStore.user?.id ||
						wallet.userId ===
							App.state.rootStore.userAuthStore.secondaryUser?.id
				)
				.filter(
					(wallet) =>
						wallet.abrv ===
							UserAccountType.SportBettingAccountOnline ||
						wallet.abrv ===
							UserAccountType.SportBettingAccountShopOnline ||
						wallet.abrv === UserAccountType.SportBettingAccountShop
				),
		[]
	);

	if (availableWallets?.length == 1) {
		return null;
	}

	const onlineWallet = user.userAccounts?.find(
		(wallet) => wallet.abrv === UserAccountType.SportBettingAccountOnline
	)?.accountTypeId;

	return (
		<form className="card--primary__filter">
			<div
				id="find-bets"
				className="card--primary__filter__list no-print"
			>
				{/* render only if  sport-betting-account-online exists*/}
				{onlineWallet != null &&
					user.availableUserWallets?.includes(onlineWallet) && (
						<div className="card--primary__filter__item">
							<input
								id="OnlineWalletId"
								type="radio"
								name="walletId"
								value={onlineAccountTypeId}
								checked={
									bettingAccountTypeIds?.includes(
										onlineAccountTypeId
									) || false
								}
								className="at-OnlineWalletId input--radio"
								onChange={() => {
									setAccountTypeIds([onlineAccountTypeId]);
								}}
							/>
							<label
								htmlFor="OnlineWalletId"
								className="u-display--ib"
							>
								<i className="u-icon u-icon--med u-icon--wallet--online u-align--v--middle" />
								&nbsp;
								<span className="u-align--v--middle">
									{t<string>(
										"MY_BETS.PAGE.FILTER_FORM.FILTER_ONLINE_WALLET"
									)}
								</span>
							</label>
						</div>
					)}

				{onlineShopAccountTypeId != null &&
					user.availableUserWallets?.includes(
						onlineShopAccountTypeId
					) && (
						<div className="card--primary__filter__item">
							<input
								id="OnlineShopWalletId"
								type="radio"
								name="walletId"
								value={onlineShopAccountTypeId}
								checked={
									bettingAccountTypeIds?.includes(
										onlineShopAccountTypeId
									) || false
								}
								className="at-OnlineWalletId input--radio"
								onChange={() => {
									setAccountTypeIds([
										onlineShopAccountTypeId,
									]);
								}}
							/>
							<label
								htmlFor="OnlineShopWalletId"
								className="u-display--ib"
							>
								<i className="u-icon u-icon--med u-icon--wallet--sport-betting-account-shop-online u-align--v--middle" />
								&nbsp;
								<span className="u-align--v--middle">
									{t<string>(
										"MY_BETS.PAGE.FILTER_FORM.FILTER_SHOP_ONLINE_WALLET"
									)}
								</span>
							</label>
						</div>
					)}

				{offlineAccountTypeId != null &&
					user.availableUserWallets?.includes(
						offlineAccountTypeId
					) && (
						<div className="card--primary__filter__item">
							<input
								id="OfflineWalletId"
								type="radio"
								name="walletId"
								value={offlineAccountTypeId}
								checked={
									bettingAccountTypeIds?.includes(
										offlineAccountTypeId
									) || false
								}
								className="at-OfflineWalletId input--radio"
								onChange={() => {
									setAccountTypeIds([offlineAccountTypeId]);
								}}
							/>
							<label
								htmlFor="OfflineWalletId"
								className="u-display--ib"
							>
								<i className="u-icon u-icon--med u-icon--wallet--offline u-align--v--middle" />
								&nbsp;
								<span className="u-align--v--middle">
									{t<string>(
										"MY_BETS.PAGE.FILTER_FORM.FILTER_OFFLINE_WALLET"
									)}
								</span>
							</label>
						</div>
					)}
			</div>
		</form>
	);
});
