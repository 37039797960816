import React, { useState } from "react";
import { LoaderGetterContext, LoaderSetterContext } from "@context";
import DesktopLoader from "../components/desktop/shared/loader/GlobalLoader";
import { Loader as MobileLoader } from "../components/mobile/shared/loader";
import { DeviceDetector } from "@utils";

export function LoadingContainer(props: { children: React.ReactNode }) {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<LoaderGetterContext.Provider value={{ isLoading }}>
			<LoaderSetterContext.Provider value={{ setIsLoading }}>
				{isLoading && <Loader />}
				{props.children}
			</LoaderSetterContext.Provider>
		</LoaderGetterContext.Provider>
	);
}

function Loader() {
	if (DeviceDetector.isDesktopTheme) {
		return <DesktopLoader />;
	}

	return <MobileLoader />;
}
