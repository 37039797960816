import { observer } from "mobx-react";
import React, { useContext } from "react";
import {
	Logo,
	Search,
	Language,
	HeaderUserDetails,
	Clock,
	ThemeToggle,
	HeaderIconActions,
} from "./";
import HeaderNavigation from "./HeaderNavigation";
import { ScrollDirectionContext } from "@v2/context";

export default observer(function HeaderWithSideMenu(props: {
	onNavTargetClick?: Parameters<typeof HeaderNavigation>[0]["onTargetClick"];
}) {
	const scrollDirection = useContext(ScrollDirectionContext);

	return (
		<header className={"l--header is-" + scrollDirection}>
			<div className={"header is-" + scrollDirection}>
				<div className="header__top">
					<Clock />

					<div className="header__settings">
						<Search />
						<Language rounded />
						<ThemeToggle rounded />
					</div>
				</div>

				<div className="header__main">
					<div className="header__col header__col--left">
						<figure className="header__logo">
							<Logo />
						</figure>

						<HeaderIconActions />
					</div>

					<div className="header__col header__col--grow">
						<HeaderNavigation
							onTargetClick={props.onNavTargetClick}
						/>
					</div>

					<div className="header__col header__col--right">
						<HeaderUserDetails />
					</div>
				</div>
			</div>
		</header>
	);
});
