import React, { useEffect } from "react";
import { usePersonalData } from "@v2/hooks/account-settings/personal-data";
import usePasswordConfirmation from "@v2/hooks/shared/usePasswordConfirmation";
import { PersonalDataFormSection } from ".";
import { PasswordConfirmation } from "../../shared/password-confirmation";
import { useFormContext } from "react-hook-form";
import { mapDataForPasswordConfirmation } from "@v2/helpers/account-settings/personal-data/utils";

export default function PersonalDataContent() {
	const { showPasswordConfirmation, setShowPasswordConfirmation } =
		usePasswordConfirmation();

	const { onInitialize, fetchedData, onSuccess, onCancel } =
		usePersonalData();

	const {
		formState: { dirtyFields },
		getValues,
	} = useFormContext();

	useEffect(() => {
		onInitialize();
		return () => setShowPasswordConfirmation(false);
	}, []);

	return (
		<div className="l--content__main">
			<div className="container">
				{!showPasswordConfirmation ? (
					<PersonalDataFormSection fetchedData={fetchedData} />
				) : (
					<div className="form form--grid card--primary">
						<PasswordConfirmation
							data={mapDataForPasswordConfirmation(
								getValues(),
								dirtyFields,
								fetchedData
							)}
							onSuccess={onSuccess}
							onCancel={onCancel}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
