import React, { FC, useContext, useEffect } from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PaymentOption } from "@api-types/deposit";
import { DepositContext } from "@context";
import {
	DepositPaymentMethodMaskedPan,
	DepositPaymentMethodMultipleReferencesCounter,
} from ".";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";
import PaymentOptionImage from "@v2/components/shared/icons/PaymentOptionImage";
import { getSVGExtension } from "@utils/common/helpers";

export const DepositPaymentMethodSelection: FC = observer(
	function DepositPaymentMethodSelection() {
		const { t } = useTranslation();
		const { paymentMethods, notVerifiedMessage } =
			useContext(DepositContext);

		if (notVerifiedMessage) {
			return (
				<InfoMessageComponent
					type="note"
					message={notVerifiedMessage}
					textClass="message__text"
				/>
			);
		} else if (paymentMethods == null || paymentMethods.length == 0) {
			//return view of no payment methods
			return (
				<InfoMessageComponent
					type="note"
					message={t("DEPOSIT.NO_PAYMENT_OPTIONS")}
					textClass="message__text"
				/>
			);
		}

		return <PaymentMethodList />;
	}
);

const PaymentMethodList: FC = observer(function PaymentMethodList() {
	const { otherPaymentMethods, userPaymentSavedMethods } =
		useContext(DepositContext);
	const { t } = useTranslation();
	const { resetField, getValues } = useFormContext();

	useEffect(() => {
		if (getValues("paymentMethodId") == "") {
			resetField("paymentMethodId", {
				defaultValue:
					userPaymentSavedMethods?.length != 0
						? userPaymentSavedMethods[0]?.paymentMethodId
						: otherPaymentMethods[0]?.paymentMethodId,
			});
		}
	}, []);

	return (
		<>
			{userPaymentSavedMethods?.length !== 0 && (
				<div className="form__group">
					<div className="label u-mar--bottom--xsml">
						{t("DEPOSIT.USER_PAYMENT_METHODS")}
					</div>

					<div className="row">
						{userPaymentSavedMethods.map(
							(userPaymentMethod: PaymentOption) => {
								return (
									<div
										key={userPaymentMethod.paymentMethodId}
										className="col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col-ssvga-12 col-hdlow-12 col-xxlrg-12 col-huge-12 col-xxxlrg-6"
									>
										<PaymentMethodOption
											paymentMethod={userPaymentMethod}
										/>
									</div>
								);
							}
						)}
					</div>
				</div>
			)}

			{otherPaymentMethods?.length !== 0 && (
				<>
					<div className="form__group">
						{userPaymentSavedMethods?.length !== 0 && (
							<div className="label u-mar--bottom--xsml">
								{t("DEPOSIT.OTHER_PAYMENT_METHODS")}
							</div>
						)}
					</div>

					<div className="row">
						{otherPaymentMethods.map(
							(paymentMethod: PaymentOption) => {
								return (
									<div
										key={paymentMethod.paymentMethodId}
										className="col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col-ssvga-12 col-hdlow-12 col-xxlrg-12 col-huge-12 col-xxxlrg-6"
									>
										<PaymentMethodOption
											paymentMethod={paymentMethod}
										/>
									</div>
								);
							}
						)}
					</div>
				</>
			)}
		</>
	);
});

const PaymentMethodOption: FC<{
	paymentMethod: PaymentOption;
}> = observer(function PaymentMethodOption(props: {
	paymentMethod: PaymentOption;
}) {
	const { paymentMethod } = props;

	const { register } = useFormContext();

	const { t } = useTranslation();

	const providerLogoClasses = classNames(
		"card--payment__logo u-icon u-icon--payment u-icon--payment--med",
		[`u-icon--payment--${paymentMethod.paymentMethodAbrv.toLowerCase()}`]
	);

	return (
		<div key={paymentMethod.paymentMethodId} className="card--payment">
			<input
				id={`${paymentMethod.paymentMethodId}`}
				className="input--radio"
				value={paymentMethod.paymentMethodId}
				type="radio"
				{...register("paymentMethodId")}
				required
			/>
			<label
				htmlFor={`${paymentMethod.paymentMethodId}`}
				className="card--payment__area"
			>
				<span className="card--payment__icon">
					<DepositPaymentMethodMultipleReferencesCounter
						paymentMethod={paymentMethod}
					/>
					<PaymentOptionImage
						className={providerLogoClasses}
						svgName={
							paymentMethod.paymentMethodAbrv.toLowerCase() +
							getSVGExtension()
						}
					/>
					<DepositPaymentMethodMaskedPan
						paymentMethod={paymentMethod}
					/>
				</span>
				<span className="card--payment__details">
					<span className="card--payment__title">
						{paymentMethod.displayName}
					</span>
					<span className="card--payment__row">
						<span className="card--payment__col">
							{t<string>("DEPOSIT.MIN_AMOUNT")}:
						</span>
						<span className="card--payment__col">
							{paymentMethod.minDeposit}
							{paymentMethod.currencyDisplayCode}
						</span>
					</span>
					<span className="card--payment__row">
						<span className="card--payment__col">
							{t<string>("DEPOSIT.MAX_AMOUNT")}:
						</span>
						<span className="card--payment__col">
							{paymentMethod.maxDeposit}
							{paymentMethod.currencyDisplayCode}
						</span>
					</span>
				</span>
			</label>
		</div>
	);
});
