import React, { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { DepositContext } from "@context";
import { getCurrentCulture } from "@utils";

export const DepositBonusConfirmation: FC = observer(
	function DepositBonusConfirmation() {
		const { currentFormData, selectedPaymentMethod, depositBonusInfo } =
			useContext(DepositContext);
		const { t } = useTranslation();

		const { register } = useFormContext();

		return (
			<>
				<div className="message message--base message--note u-mar--bottom--xsml">
					<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
					<div className="message__content">
						<div className="message__title">
							{t("DEPOSIT.BONUS_MAX_INFO", {
								0: depositBonusInfo?.bonusInfo.bonusPercentage,
								1: depositBonusInfo?.bonusInfo.maxBonusAmount,
								2: selectedPaymentMethod?.currencyDisplayCode,
							})}
						</div>
						<div className="message__text">
							{t("DEPOSIT.BONUS_AMOUNT_INFO", {
								0: currentFormData?.amount,
								1: selectedPaymentMethod?.currencyDisplayCode,
								2: depositBonusInfo?.bonusInfo.bonusAmount,
							})}
						</div>
						<Link
							to={`/${getCurrentCulture()}/info/bonus-terms`}
							target="_blank"
							className="message__btn btn btn--tny btn--secondary--inverted"
						>
							{t<string>("FOOTER.BONUS_TERMS")}
						</Link>
					</div>
				</div>
				<div className="form__group">
					<input
						className="input--check"
						id="bonus"
						type="checkbox"
						{...register("isBonusAccepted")}
					/>
					<label htmlFor="bonus" className="u-color--text--primary">
						{t("DEPOSIT.BONUS_ACCEPTANCE")}
					</label>
				</div>
			</>
		);
	}
);
