import React from "react";
import { useFixBody } from "@v2/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons";
import { DateTime } from "luxon";

export default function User24hLockPopup() {
	const { t } = useTranslation();

	useFixBody(true, App.state.rootStore.user24hLockStore.isActive);
	return (
		<div className="at-userLockPopUp popup">
			<div className="popup__card popup__card--reset-all">
				<div className="popup__body u-padd--reset">
					<div className="card--message">
						<div className="card--message__thumb" />
						<div className="card--message__body">
							<div className="card--message__content">
								<HeaderLockPopupContent />
								<div className="card--message__footer">
									<Button
										className="card--message__cta btn--lrg btn--block btn--primary"
										btnText={t("COMMON.OK_BTN")}
										onClick={App.state.rootStore.user24hLockStore.deactivatePopup}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function HeaderLockPopupContent() {
	const { responseData } = App.state.rootStore.user24hLockStore;
	const { t } = useTranslation();

	return responseData && responseData.isSuccess ? (
		<>
			<div className="card--icon">
				<i className="card--icon__icon u-icon u-icon--lrg u-icon--rounded u-icon--lock u-color--core--snow u-icon--animated--bounce-in"></i>
				<i className="card--icon__icon u-icon u-icon--huge u-icon--rounded u-icon--user"></i>
			</div>
			<div className="card--message__title u-type--left">
				{t<string>("HEADER.24H_LOCK.POPUP_TITLE")}
			</div>
			<div className="u-type--left">
				{responseData.expirationDate
					? t<string>(
							"HEADER.24H_LOCK.24_HOURS_LOCK_SUCCESS_MESSAGE",
							{
								0: DateTime.fromISO(
									responseData.expirationDate
								).toLocaleString(
									DateTime.DATETIME_SHORT_WITH_SECONDS
								),
								interpolation: {
									escapeValue: false,
								},
							}
					  )
					: t<string>(
							"HEADER.24H_LOCK.24_HOURS_LOCK_SUCCESS_MESSAGE_WITHOUT_DATE"
					  )}
			</div>
		</>
	) : (
		<>
			<div className="card--icon">
				<i className="card--icon__icon u-icon u-icon--lrg u-icon--rounded u-icon--alert-circle u-color--core--snow u-icon--animated--bounce-in"></i>
				<i className="card--icon__icon u-icon u-icon--huge u-icon--rounded u-icon--user"></i>
			</div>
			<div className="card--message__title">
				{t<string>("HEADER.24H_LOCK.POPUP_TITLE")}
			</div>
			<div>{t<string>("HEADER.24H_LOCK.LOCK_ERROR")}</div>
		</>
	);
}