import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Sorter } from "@gp/utility";
import { EventKeyOffer } from "@gp/offer";

import { OfferStoreContext, EventContext } from "@gp/components";

import { BettingType } from "./";

export const NotGroupedBettingTypes = observer(
	function NotGroupedBettingTypes() {
		const event = useContext(EventContext);
		const { eventKeysMap } = useContext(OfferStoreContext);

		const eventKeys = eventKeysMap.get(event.id);
		const eventKeysForGroup = Array.from(eventKeys?.values() || []);

		const bettingTypes = eventKeysForGroup
			.reduce<EventKeyOffer["bettingType"][]>((acc, key) => {
				if (
					acc.find((bt) => bt.abrv === key.bettingType.abrv) == null
				) {
					acc.push(key.bettingType);
				}
				return acc;
			}, [])
			.sort(
				Sorter.sort((a, b) => {
					const aSort =
						a?.settingsPerSport?.[event.sportId]?.sortOrder;
					const bSort =
						b?.settingsPerSport?.[event.sportId]?.sortOrder;

					const aSortOrder = aSort == null ? 99999 : aSort;
					const bSortOrder = bSort == null ? 99999 : bSort;

					return Math.sign(aSortOrder - bSortOrder);
				}, "id")
			);

		return (
			<div className="offer__body offer--additional__body">
				{bettingTypes.map((bt) => (
					<BettingType key={bt.abrv} bettingType={bt} />
				))}
			</div>
		);
	}
);
