import { MitAuthDto } from "@api-types/integrations/MitAuthDto";
import {
	MitIdWidgetDto,
	MitIdWidgetRequest,
} from "@api-types/integrations/MitIdWidgetDto";
import { MidIdUserInfoDto } from "@api-types/integrations/MidItUserInfoDto";
import {
	DeviceDetector,
	getApplicationHeaders,
	getCurrentCulture,
	httpClient,
} from "@utils";
import qs from "qs";

export class MitIdApiService {
	static get baseUrl(): string {
		return `${WEBPACK_BASE_ADDRESS}platform/integrations/mitid`;
	}

	private static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();

		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	/*
		auth_config_init => WidgetConfigLoadFailed 400955
		auth_request_failed => WidgetInitFailed 400956
		auth_unhandled_error => WidgetInitUnhandledError 400957
		auth_invalid_login_url => WidgetUrlNotValid 400958
	*/
	static async getMitIdWidgetInfo(
		redirectUrl: string
	): Promise<MitIdWidgetDto> {
		const lng = getCurrentCulture() === "da" ? "da" : "en";
		let body = {
			language: lng,
			redirectUrl: redirectUrl,
		} as MitIdWidgetRequest;
		if (DeviceDetector.isMobileApp) {
			body.enableAppSwitch = true;
			body.appSwitchOs = "android";
			body.appSwitchUrl = redirectUrl;
		}
		return await httpClient.post(
			`${this.baseUrl}/auth/widget`,
			JSON.stringify(body),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async getUserAdditionalInfo(
		formData: MitAuthDto
	): Promise<MidIdUserInfoDto> {
		const headers = getApplicationHeaders();
		headers["Content-Type" as keyof HeadersInit] =
			"application/x-www-form-urlencoded";
		return await httpClient.post(
			`${this.baseUrl}/auth/resolve-user-info`,
			qs.stringify(formData),
			headers,
			{ responseType: "json" }
		);
	}
}
