import React from "react";
import { get } from "lodash";
import { useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ErrorMessage(props) {
	const { t } = useTranslation();
	const { errors } = useFormState();

	const { customErrorMessage = null, customErrorClassName = null } = props;

	let error = get(errors, props.name);

	if (error === undefined || error.message == undefined) {
		return null;
	}

	const customClass = customErrorClassName ? customErrorClassName : "message--validation field-validation-error";

	return (
		<span className={customClass}>
			<span>
				{customErrorMessage != null ? t(customErrorMessage) : t(error.message)}
			</span>
		</span>
	);
}