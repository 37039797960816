import React from "react";
import classNames from "classnames";
import { OfferTip } from "../../buttons";

export default function EmptyTips(props: {
	hasFourthTip?: boolean;
	isMarginal?: boolean;
}) {
	return (
		<div
			className={classNames("offer__body__data offer__quotes", {
				offer__quotes__special: props.hasFourthTip,
			})}
		>
			{props.isMarginal || props.hasFourthTip ? (
				<OfferTip className="quote quote--margin is-empty" />
			) : (
				<OfferTip className="quote quote--placeholder" />
			)}
			<OfferTip className="quote quote--placeholder" />
			<OfferTip className="quote quote--placeholder" />
			{props.hasFourthTip ? (
				<OfferTip className="quote quote--placeholder" />
			) : null}
		</div>
	);
}
