import { observable, action } from "mobx";
import { AccountTypes, UserTypes, getUserFromLocalStorage } from "@utils";

export default class AccountVerificationStore {
	@observable isActive: boolean = false;
	@observable message: string | null = "";
	@observable url: string = "";
	@observable buttonText: string = "";

	@action.bound
	closePopup() {
		this.isActive = false;
		this.message = "";
		this.url = "";
		this.buttonText = "";
	}

	@action.bound
	showPopup(
		url = "account-settings/account-verification",
		buttonText: string = "ACCOUNT_VERIFICATION.TITLE",
		errorMessage?: string
	) {
		if (errorMessage) {
			this.message = errorMessage;
		}
		this.url = url;
		this.buttonText = buttonText;
		this.isActive = true;
	}
}
