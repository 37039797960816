import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoading } from "@hooks";
import { useNotification } from "../../../../ui/common/hooks";
import { sortArray } from "@v2/helpers/account-settings/account-lock/utils";
import { useAccountLock } from ".";
import { AccountClosureViewModel } from "@data-types";
import {
	UserAccountLockDurationTypesModel,
	UserAccountLockReasonTypesModel,
} from "@api-types";

class LookupsStateModel {
	reasonTypes: UserAccountLockReasonTypesModel[];
	durationTypes: UserAccountLockDurationTypesModel[];
}

export default function useAccountLockWithPasswordConfirmation() {
	const { t } = useTranslation();
	const {
		getAccountClosureViewData,
		createAccountLock,
		onSubmitData,
		onCancel,
	} = useAccountLock();
	const { showError } = useNotification();
	const { setIsLoading } = useLoading();
	const [fetchedData, setFetchedData] = useState<
		AccountClosureViewModel | []
	>([]);
	const [lookups, setLookups] = useState<LookupsStateModel>({
		durationTypes: [],
		reasonTypes: [],
	});

	const { getValues, reset } = useFormContext();

	const onInitialize = async () => {
		try {
			setIsLoading(true);
			const response = await getAccountClosureViewData();
			if (response) {
				reset({ lockDuration: "", lockReason: "" });
				setFetchedData(response);
				setLookups({
					reasonTypes: response.reasonTypes,
					durationTypes: sortArray(response.durationTypes),
				});
			}
		} catch (error) {
			showError(
				t(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`)
			);
		} finally {
			setIsLoading(false);
		}
	};

	const onSuccess = async (password: string) => {
		const formData = getValues() as Omit<
			Parameters<typeof createAccountLock>[0],
			"password" | "agencyUserIds"
		>;

		if (formData == null) {
			return;
		}

		try {
			if (App.state.rootStore.userAuthStore.user == null) {
				throw "Expected user, got null";
			}

			const modifiedData = {
				...formData,
				password: password,
				agencyUserIds: {
					userId: App.state.rootStore.userAuthStore.user.id,
					agencyId: App.state.rootStore.userAuthStore.user.agencyId,
				},
			};
			setIsLoading(true);
			await createAccountLock(modifiedData);
		} catch (error) {
			console.error(error);
			showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.message}`);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		onSubmitData,
		fetchedData,
		lookups,
		onInitialize,
		onSuccess,
		onCancel,
	};
}
