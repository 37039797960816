import { action, computed, observable, runInAction } from "mobx";
import QuickPlayStore from "./QuickPlayStore";
import { BetOffer } from "@administration/pages/my-bets/model";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";
import {
	TMainBettingTypesPerSport,
	TQuickBetFormFilter,
	TQuickBetFormRequest,
	TQuickBet,
} from "@api-types/quick-play";
import NodeStore from "@offer/stores/components/offer-menu/node/NodeStore";
import { SportMenuNodeType } from "@offer/stores/components/offer-menu/SportNodeType";
import { QuickPlayApiService } from "@api-services/quick-play";
import betRules from "@betting-rules";
import { localizationService } from "@state";
import SportsMenuApiService from "@api-services/offer/SportsMenuApiService";
import { LiveStatus } from "@gp/models";

const BETTING_TYPES_ABRV = [
	"3way",
	"winner",
	"winner-incl-overtime",
	"winner-incl-extra-innings",
	"winner-incl-super-over",
];
export default class QuickBetStore {
	quickPlayStore: QuickPlayStore;
	@observable betSlip: TQuickBetBetSlip | null = null;
	@observable menu: PrematchOfferMenuStore | LiveOfferMenuStore | null;
	@observable menuSportsList:
		| {
				name: string;
				abrv: string;
				id: string;
				sortOrder: number;
		  }[]
		| null = null;
	@observable selectedSportId: string = "any";

	public COEFFICIENT_CHANGE_VALUE: 0.1 = 0.1;
	public MIN_COEFFICIENT_VALUE: 1.1 = 1.1;
	public MAX_COEFFICIENT_VALUE: 3 = 3;
	public EVENTS_COUNT: 5 = 5;
	public QUICK_TYPE: TFormFilter["quickBetType"] = "weekly";
	public QUICK_BET_TYPES_ARRAY: {
		text: string;
		quickBetType: TFormFilter["quickBetType"];
	}[] = [
		{ text: "OFFER_PRINT.CATEGORIES.DAILY_OFFER", quickBetType: "daily" },
		{ text: "OFFER_PRINT.CATEGORIES.WEEKLY_OFFER", quickBetType: "weekly" },
		{ text: "OFFER_PRINT.CATEGORIES.LIVE_OFFER", quickBetType: "live" },
	];

	/* private offerConfigurationStore: OfferConfigurationStore; */
	public onLoadFilter: TFormFilter | null = null;

	constructor(quickPlayStore: QuickPlayStore) {
		/* 	this.offerConfigurationStore = new OfferConfigurationStore("european"); */
		this.quickPlayStore = quickPlayStore;
	}

	@computed
	public get getOnloadFilter() {
		return this.onLoadFilter;
	}

	@action.bound
	public mappedQuickBet(bet: TQuickBet): TQuickBetBetSlip | undefined {
		if (!bet.betSlip) {
			console.error("betslip should exists");
			return;
		}
		const mappedBet = bet.betSlip.betSlipOffers.reduce(
			(acc: BetOffer[], betSlipOffer) => {
				acc.push(new BetOffer(betSlipOffer));
				return acc;
			},
			[]
		);

		return { ...bet.betSlip, betSlipOffers: mappedBet };
	}

	@action.bound
	public async fetchQuickBet(filter: TQuickBetFormRequest) {
		this.quickPlayStore.loaderStore.suspend();
		try {
			const quickBetRespone = await QuickPlayApiService.fetchQuickBet(
				filter
			);

			if (!quickBetRespone.response) {
				this.quickPlayStore.rootStore.notificationStore.error(
					localizationService.t("QUICK_PLAY.QUICK_BET.BET_SLIP_ERROR")
				);
				return;
			}

			quickBetRespone.response.betSlip?.betSlipOffers?.forEach(
				(offer) => {
					if (
						offer?.specifier?.player == null ||
						!offer.sportData?.bettingTypeAbrv ||
						![
							"player-total-assists",
							"player-total-rebounds",
							"player-total-3-point-field-goals",
							"player-total-points",
							"player-total-rushing-yards",
							"player-total-passing-touchdowns",
							"player-total-passing-yards",
							"player-total-receiving-yards",
						].includes(offer.sportData?.bettingTypeAbrv)
					) {
						return;
					}

					offer.specifier.player =
						offer.playerFirstName +
						" " +
						offer.playerLastName +
						` (${
							offer.sportData.teamOneId === offer.teamId
								? offer.sportData.teamOneName
								: offer.sportData.teamTwoName
						})`;
					offer.playerId = undefined;
					offer.teamId = undefined;
				}
			);

			const mappedBet = this.mappedQuickBet(quickBetRespone.response);
			if (!mappedBet) {
				console.error("Betslip is null");
				this.quickPlayStore.rootStore.notificationStore.error(
					localizationService.t("QUICK_PLAY.QUICK_BET.BET_SLIP_ERROR")
				);
				return;
			}
			this.betSlip = observable.object(mappedBet);
		} catch (e) {
			console.error(e);
			this.quickPlayStore.rootStore.notificationStore.error(
				localizationService.t("QUICK_PLAY.QUICK_BET.BET_SLIP_ERROR")
			);
		} finally {
			this.quickPlayStore.loaderStore.resume();
		}
	}

	@action.bound
	public removeFromBetSlip(betIndex: number) {
		this.betSlip?.betSlipOffers.splice(betIndex, 1);
	}

	@action.bound
	public async fetchMenuData() {
		let menuResponse = await SportsMenuApiService.getPrematchMenu({
			liveStatus: LiveStatus.PREMATCH,
		});

		if (menuResponse != null) {
			runInAction(() => {
				this.menuSportsList = menuResponse
					.map((sportItem) => sportItem.sport)
					.sort((a, b) => a.sortOrder - b.sortOrder);
			});
		}
	}

	@action.bound
	public async setSelectedSportId(sportId: string) {
		this.selectedSportId = sportId;
	}

	@action.bound
	public async fetchData(filter: TFormFilter) {
		const mappedSportMenuSelection = {
			sportIds: this.menu?.menu.selectedItems.sports || [],
			tournamentIds: this.menu?.menu.selectedItems.tournaments || [],
			categoryIds: this.menu?.menu.selectedItems.categories || [],
		};

		const requestFilter: TQuickBetFormRequest = {
			eventCount: filter.eventCount,
			maxOddValue: filter.maxOddValue.toFixed(2),
			minOddValue: filter.minOddValue.toFixed(2),
			stake: filter.stake,
			quickBetType: filter.quickBetType,
			useUserFavorites: filter.useUserFavorites,
			mainBettingTypesPerSport: this.getMainBettingType(filter),
			userSportTreeFilter: null,
		};

		const isSportSelectionOn =
			location.pathname.includes("/quick-play/quick-bet") &&
			this.menuSportsList != null;

		if (isSportSelectionOn) {
			if (this.selectedSportId !== "any") {
				requestFilter.userSportTreeFilter = {
					sportIds: [this.selectedSportId],
					categoryIds: [],
					tournamentIds: [],
				};
			} else {
				requestFilter.userSportTreeFilter = {
					sportIds: this.menuSportsList?.map((sport) => sport.id),
					categoryIds: [],
					tournamentIds: [],
				};
			}
		}

		switch (filter.includeMenu) {
			case true: {
				requestFilter.userSportTreeFilter = mappedSportMenuSelection;
				await this.fetchQuickBet(requestFilter);
				break;
			}
			case false: {
				await this.fetchQuickBet(requestFilter);
				break;
			}
			default: {
				console.error("Menu should either be selected or not");
			}
		}
	}

	getMainBettingType(filter: TFormFilter) {
		/* ODKOMENTIRAJ AKO IKAD BUDE TREBALO PO SPORTU BETTING TYPOVI ^^ */
		if (filter.quickBetType == "live") {
			if (
				this.menu?.menu.nodesBySelectionOrder.length &&
				filter.includeMenu
			) {
				const sportsAbrvSet = new Set<string>();

				this.menu.menu.nodesBySelectionOrder.forEach((node) => {
					const getNode = this.getSportAbrvFromNodes(node);
					if (getNode) {
						sportsAbrvSet.add(getNode);
					}
				});

				return [...sportsAbrvSet].reduce(
					(acc: TMainBettingTypesPerSport[], sportAbrv) => {
						/* const mainSportHeaders =
							this.offerConfigurationStore.OfferMapper.getMainSportHeaders(
								sportAbrv,
								false,
								betRules
							); */

						acc.push({
							sportAbrv: sportAbrv,
							bettingTypeAbrvs: BETTING_TYPES_ABRV,
						});

						return acc;
					},
					[]
				);
			} else {
				const liveSports = betRules.live
					.map((rule) => [...rule.sports.values()])
					.flat(1);

				return liveSports.reduce(
					(acc: TMainBettingTypesPerSport[], sportAbrv) => {
						/* 	const mainSportHeaders =
							this.offerConfigurationStore.OfferMapper.getMainSportHeaders(
								sportAbrv,
								false,
								betRules
							); */

						acc.push({
							sportAbrv: sportAbrv,
							bettingTypeAbrvs: BETTING_TYPES_ABRV,
						});

						return acc;
					},
					[]
				);
			}
		} else {
			if (
				this.menu?.menu.nodesBySelectionOrder.length &&
				filter.includeMenu
			) {
				const sportsAbrvSet = new Set<string>();

				this.menu.menu.nodesBySelectionOrder.forEach((node) => {
					const getNode = this.getSportAbrvFromNodes(node);
					if (getNode) {
						sportsAbrvSet.add(getNode);
					}
				});

				return [...sportsAbrvSet].reduce(
					(acc: TMainBettingTypesPerSport[], sportAbrv) => {
						/* 		const mainSportHeaders =
							this.offerConfigurationStore.OfferMapper.getMainSportHeaders(
								sportAbrv,
								false,
								betRules
							); */

						acc.push({
							sportAbrv: sportAbrv,
							bettingTypeAbrvs: BETTING_TYPES_ABRV,
						});

						return acc;
					},
					[]
				);
			} else {
				const prematchSports = betRules.prematch
					.map((rule) => [...rule.sports.values()])
					.flat(1);

				return prematchSports.reduce(
					(acc: TMainBettingTypesPerSport[], sportAbrv) => {
						/* const mainSportHeaders =
							this.offerConfigurationStore.OfferMapper.getMainSportHeaders(
								sportAbrv,
								false,
								betRules
							); */

						acc.push({
							sportAbrv: sportAbrv,
							bettingTypeAbrvs: BETTING_TYPES_ABRV,
						});

						return acc;
					},
					[]
				);
			}
		}
	}

	@action.bound
	public saveFilter(filter: TFormFilter) {
		this.onLoadFilter = filter;
	}

	@action.bound
	public initializeMenuStore(
		menu: LiveOfferMenuStore | PrematchOfferMenuStore
	) {
		this.menu = menu;
	}

	@action.bound
	public removeMenuStore() {
		this.menu = null;
	}

	getSportAbrvFromNodes(node: NodeStore<SportMenuNodeType>) {
		if (node.node.type == "root") {
			return;
		}
		if (node.node.type == "sport") {
			return node.node.abrv;
		}

		let currNode = node.parent;
		while (currNode && !currNode.isRoot) {
			if (currNode.node.type == "sport") {
				return currNode.node.abrv;
			}

			currNode = currNode.parent;
		}
	}
}

export type TFormFilter = Omit<
	TQuickBetFormFilter,
	"userSportTreeFilter" | "mainBettingTypesPerSport"
> & { includeMenu: boolean; selectedSportId: string };

export type TQuickBetBetSlip = TQuickBet["betSlip"] & {
	betSlipOffers: BetOffer[];
};
