import { useContext } from "react";
import { SportOffer, EventOffer } from "@gp/offer";
import {
	getOfferColumns,
	getSecondaryOfferColumns,
	OfferStoreContext,
	OfferColumnConfigurationResult,
	OfferOptionsContext,
} from "@gp/components";

export default function useGetOffer(
	params: {
		hasSecondaryOffer?: boolean;
		noSecondaryOffer?: boolean;
		secondaryOfferCount?: number;
	},
	sport: SportOffer,
	event: EventOffer
) {
	const offerStore = useContext(OfferStoreContext);
	const offerOptions = useContext(OfferOptionsContext);

	const eventOffer = offerStore.eventKeysMap.get(event.id);

	let mainColumns: OfferColumnConfigurationResult = {
		nonEmptyColumns: 0,
		isEmpty: true,
		columns: [],
	};
	let secondaryColumns: OfferColumnConfigurationResult = {
		nonEmptyColumns: 0,
		isEmpty: true,
		columns: [],
	};

	const sportSelectors =
		offerStore.configuration.bettingTypeSelectorsStore.getSportSelector(
			sport
		);

	if (sportSelectors == null) {
		console.error("Expected sport selectors, got null.");
	}

	if (
		sportSelectors != null &&
		eventOffer != undefined &&
		eventOffer?.size != 0
	) {
		const eventKeys = Array.from(eventOffer.values());

		mainColumns = getOfferColumns(
			eventKeys,
			sportSelectors,
			offerOptions.numberOfColumns
		);

		secondaryColumns = {
			nonEmptyColumns: 0,
			isEmpty: !params.hasSecondaryOffer,
			columns: [],
		};
		if (!params.noSecondaryOffer) {
			secondaryColumns = getSecondaryOfferColumns(
				eventKeys,
				sportSelectors,
				offerOptions.numberOfColumns
			);
		} else {
			secondaryColumns.nonEmptyColumns = params.secondaryOfferCount || 0;
		}
	}

	const additionalOfferCount =
		event.totalOfferCount -
		(mainColumns.nonEmptyColumns + secondaryColumns.nonEmptyColumns);

	return {
		mainColumns,
		secondaryColumns,
		additionalOfferCount,
	};
}
