import { useState, useCallback } from "react";
import { useLoading } from "@hooks";
import { useAppContext, useNotification, useUser } from "@v2/hooks/shared";
import { AcceptNewTermsService } from "@v2/data-access/membership";
import { DateTime } from "luxon";

const acceptNewTermsService = new AcceptNewTermsService();

export default function useAcceptNewTerms() {
	const { culture } = useAppContext();
	const [acceptNewTermsError, setAcceptNewTermsError] = useState<
		boolean | null
	>(null);
	const { showError } = useNotification();
	const { setIsLoading } = useLoading();
	const user = useUser();

	const onSuccess = async () => {
		try {
			setIsLoading(true);
			const response = await acceptNewTermsService.acceptRegulation();
			if (response.success) {
				user.resolveAcceptNewTerms();
				App.state.history.push(`/${culture}/home/full/highlights`);
			}
		} catch (error) {
			setAcceptNewTermsError(error);
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const logoutUser = async () => {
		try {
			setIsLoading(true);
			await user.logoutUser();
			App.state.history.push(`/${culture}/home/full/highlights`);
		} catch (error) {
			console.error(error);
			showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR");
		} finally {
			setIsLoading(false);
		}
	};

	const getTermsEffectiveDate = useCallback(() => {
		//Terms effective date is currently hardcoded
		const effectiveDate = DateTime.fromISO("2023-01-01").toLocaleString(
			DateTime.DATE_SHORT
		);
		return effectiveDate;
	}, []);

	return {
		acceptNewTermsError,
		onSuccess,
		logoutUser,
		getTermsEffectiveDate,
		setAcceptNewTermsError,
	};
}
