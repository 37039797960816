import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";

import {
	EventContext,
	LookupContext,
	SportOfferContext,
	OfferOptionsContext,
	OfferStoreContext,
} from "@gp/components";

import {
	LiveMatchTime,
	LiveMatchResult,
	Score,
	RedCards,
	StartTime,
} from "./common";

import { TeamName } from "../details";
import {
	ChatButton,
	LiveStreamToggleButton,
	StatisticsButton,
	TvCoverageButton,
	WidgetToggleButton,
} from "../buttons";

import { useIsAdditionalOfferVisible } from "@offer/common";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { useRootAppStore } from "@hooks";
import { useTranslation } from "react-i18next";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { AdditionalOfferInteractionTypeEnum } from "@data-types/analytics/AdditionalOfferInteractionTypeEnum";
import { ChannelResourceTypeDto } from "@api-types/chat";
import { Event, Team } from "@gp/models";

/**
 * Requires EventContext, LookupContext, SportOfferContext, OfferOptionsContext, EventContext
 */
export const EventDetails = observer(function EventDetails(props: {
	/** This is a flag not sport. */
	displaySport?: boolean;
	hasSecondaryOffer?: boolean;
	/** Is that specific event featured? Opened as additional offer. */
	isEventFeatured?: boolean;
	offerCategory?: string;
	isAdditionalOfferVisible?: boolean;
}) {
	const isAdditionalOfferVisible = useIsAdditionalOfferVisible(); // is ANY event selected/expanded

	return (
		<>
			<MatchTimeWrapper
				shouldDisplaySportIcon={props.displaySport}
				hasSecondaryOffer={props.hasSecondaryOffer}
				isEventFeatured={props.isEventFeatured}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
			/>
			<FavoritesIcon />
			<EventNamesWrapper
				shouldDisplaySportIcon={props.displaySport}
				offerCategory={props.offerCategory}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
			/>
			<MatchResultWrapper
				isOnHomepage={props.displaySport}
				isEventFeatured={props.isEventFeatured}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
			/>
			<ActionButtons
				isOnHomepage={props.displaySport}
				isEventFeatured={props.isEventFeatured}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
			/>
		</>
	);
});

const EventNamesWrapper = observer(function EventNamesWrapper(props: {
	shouldDisplaySportIcon?: boolean;
	offerCategory?: string;
	isAdditionalOfferVisible?: boolean;
}) {
	const { shouldDisplaySportIcon, offerCategory, isAdditionalOfferVisible } =
		props;

	return (
		<div className="offer__body__data offer__match">
			{/* <SportIcon shouldDisplaySportIcon={shouldDisplaySportIcon} /> */}
			<TeamNames
				offerCategory={offerCategory}
				shouldDisplaySportIcon={shouldDisplaySportIcon}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
			/>
		</div>
	);
});

/**
 * Requires EventContext, LookupContext, SportOfferContext, OfferOptionsContext
 */
const TeamNames = observer(function TeamNames(props: {
	offerCategory?: string;
	shouldDisplaySportIcon?: boolean;
	isAdditionalOfferVisible?: boolean;
}) {
	const event = useContext(EventContext);
	const lookupContext = useContext(LookupContext);
	const sportContext = useContext(SportOfferContext);
	const favoriteStore = useContext(FavoritesStoreContext);

	// @ts-expect-error
	const { eventIdsForEventSwitcher } = useContext(OfferStoreContext);

	const params = useParams<{
		culture: string;
		period: string;
		segments: string;
	}>();

	let t1: Team | undefined, t2: Team | undefined;
	if (!event.isOutright) {
		t1 = lookupContext.teams.get(event.teamOneId || "");
		t2 = lookupContext.teams.get(event.teamTwoId || "");
	}

	let category = lookupContext.categories.get(event.sportCategoryId);
	let tournament = lookupContext.tournaments.get(event.tournamentId);

	const isFavorite = favoriteStore.userFavoriteEventsSet.has(event.id);
	if (
		(!event.isOutright && (t1 == null || t2 == null)) ||
		(event.isOutright && event.name == null)
	) {
		return null;
	}

	let link = `/${params.culture}/sports/${params.period}/event/${event.id}${
		params.segments == null ? "" : "/" + params.segments
	}`;
	if (event.isLive) {
		if (isFavorite) {
			link = `/${params.culture}/live/my-favorites/${event.id}`;
		} else {
			link = `/${params.culture}/live/events/${event.id}`;
		}
	}

	const linkDataTip = `${category?.name} - ${tournament?.name}: ${
		event.isOutright ? `${event.name}` : `${t1?.name} - ${t2?.name}`
	}`;

	const linkToObject = {
		pathname: link,
		state: {
			prevUrl:
				App.state.history.location.pathname +
				App.state.history.location.search,
			eventIdsForEventSwitcher: eventIdsForEventSwitcher,
		},
	};

	let tooltipId = `tooltipTeams-${event.id}${
		props.offerCategory ? `-${props.offerCategory}` : ""
	}`;

	if (event.isLive) {
		tooltipId = "tooltipTeams-" + event.id + "-live";
	}

	const { shouldDisplaySportIcon, isAdditionalOfferVisible } = props;

	return (
		<>
			<Link
				className={classNames("offer__competitors", {
					"offer__competitors--sml": isAdditionalOfferVisible,
					// "offer__competitors--sml": shouldDisplaySportIcon,
					// "offer__competitors--full": !shouldDisplaySportIcon,
				})}
				to={linkToObject}
				onClick={() => {
					AnalyticsService.logAdditionalOfferInteraction({
						category: link.includes("live")
							? AdditionalOfferInteractionTypeEnum.LiveAdditionalOffer
							: AdditionalOfferInteractionTypeEnum.PrematchAdditionalOffer,
						label: `${sportContext.abrv}-${t1?.name}-${t2?.name}`,
					});
				}}
				data-tooltip-id={tooltipId}
				data-tooltip-content={linkDataTip}
				data-tooltip-position-strategy="fixed"
			>
				{event.isOutright ? (
					<div className="offer__outright__name">{event.name}</div>
				) : (
					<>
						<div className="offer__team">
							<TeamName team={t1} />
							<RedCards
								sport={sportContext}
								statistics={
									event.result?.homeTeamTotalStatistics
								}
							/>
						</div>
						<div className="offer__team">
							<TeamName team={t2} />
							<RedCards
								sport={sportContext}
								statistics={
									event.result?.awayTeamTotalStatistics
								}
							/>
						</div>
					</>
				)}
			</Link>

			<Tooltip
				id={tooltipId}
				className="at-tooltipTeams react-tooltip--primary"
				place="right-start"
				closeOnResize={true}
				offset={-100}
				style={{ maxWidth: "30%" }}
			/>
		</>
	);
});

const MatchTimeWrapper = observer(function MatchTimeWrapper(props: {
	hasSecondaryOffer?: boolean;
	isEventFeatured?: boolean;
	shouldDisplaySportIcon?: boolean;
	isAdditionalOfferVisible?: boolean;
}) {
	const {
		hasSecondaryOffer,
		isEventFeatured,
		shouldDisplaySportIcon,
		isAdditionalOfferVisible,
	} = props;

	const event = useContext(EventContext);

	if (event.isLive) {
		return (
			<LiveMatchTime
				event={event}
				className={classNames("offer__body__data offer__time", {
					// "offer__time--sml": shouldDisplaySportIcon || isAdditionalOfferVisible,
					"offer__time--sml": isAdditionalOfferVisible,
					"offer__time--stretch": hasSecondaryOffer,
					"is-selected": isEventFeatured,
				})}
			/>
		);
	}

	return (
		<StartTime
			event={event}
			className={classNames(
				{
					offer__body__data: !event.isOutright,
				},
				"offer__day",
				{
					// "offer__day--sml": shouldDisplaySportIcon || isAdditionalOfferVisible,
					"offer__day--sml": isAdditionalOfferVisible,
					"offer__day--stretch": hasSecondaryOffer,
					offer__outright__date: event.isOutright,
					"is-selected": isEventFeatured,
				}
			)}
		/>
	);
});

export const MatchResultWrapper = observer(function MatchResultWrapper(props: {
	isOnHomepage?: boolean;
	/** Is this for the same event as is in additional offer. */
	isEventFeatured?: boolean;
	isAdditionalOfferVisible?: boolean;
}) {
	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);

	const { isOnHomepage, isEventFeatured, isAdditionalOfferVisible } = props;

	if (!event.isLive) {
		return null;
	}

	return (
		<>
			{!isAdditionalOfferVisible && <Score />}
			<LiveMatchResult
				event={event}
				sport={sport}
				isEventFeatured={isEventFeatured}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
				isOnHomepage={isOnHomepage}
			/>
		</>
	);
});

const ActionButtons = observer(function ActionButtons(props: {
	isOnHomepage?: boolean;
	isEventFeatured?: boolean;
	isAdditionalOfferVisible?: boolean;
}) {
	const event = useContext(EventContext);

	// const { isLive } = useContext(OfferOptionsContext);

	const { isOnHomepage, isEventFeatured, isAdditionalOfferVisible } = props;

	if (event.isOutright || isAdditionalOfferVisible) {
		return null;
	}

	return (
		<div
			className={classNames("offer__body__data offer__actions", {
				// "offer__actions--live": isLive,
				// "offer__actions--col": isOnHomepage,
				"is-selected": isEventFeatured,
				"is-hidden": isAdditionalOfferVisible,
			})}
		>
			<StatisticsButton isOfferButton />
			<TvCoverageButton isOfferButton isOnHomepage={isOnHomepage} />

			{/* IF STREAM IS AVAILABLE ON HOMEPAGE THEN RENDER STREAM INSTEAD OF LMT */}
			{isOnHomepage ? (
				event?.liveStreamStatus &&
				event.liveStreamStatus !== "cancelled" ? (
					<LiveStreamToggleButton
						isForSideWidget
						isOfferButton
						isOnHomepage={isOnHomepage}
					/>
				) : (
					<WidgetToggleButton
						isForSideWidget
						isOfferButton
						isOnHomepage={isOnHomepage}
					/>
				)
			) : (
				<>
					<WidgetToggleButton
						isForSideWidget
						isOfferButton
						isOnHomepage={isOnHomepage}
					/>
					<LiveStreamToggleButton
						isForSideWidget
						isOfferButton
						isOnHomepage={isOnHomepage}
					/>
				</>
			)}

			<ChatButton
				channelType={ChannelResourceTypeDto.EVENT}
				resourceId={event.id}
				isOfferButton
			/>
		</div>
	);
});

const FavoritesIcon = observer(function FavoritesIcon() {
	const sportOfferContext = useContext(SportOfferContext);
	const rootStateStore = useRootAppStore();
	const event = useContext(EventContext);
	const favoriteStore = useContext(FavoritesStoreContext);
	const { t } = useTranslation();
	const [isHover, setIsHover] = useState(false);

	if (sportOfferContext.isOutright) {
		return null;
	}
	const tooltipId = `favorites-${event.id}`;

	const isFavorite = favoriteStore.isUserFavoriteEvent(event.id);

	function onButtonClick() {
		if (isFavorite) {
			favoriteStore.removeUserFavoriteEvent(event.id);
		} else {
			favoriteStore.addUserFavoriteEvent(event.id);
		}
	}
	const tooltipMessage = !rootStateStore.userAuthStore.isLoggedIn
		? t("FAVORITES.NONAUTH_TOOLTIP")
		: "";

	return (
		<>
			<div className="offer__body__data offer__favorites">
				<button
					data-tooltip-id={tooltipId}
					data-tooltip-content={tooltipMessage}
					data-tooltip-position-strategy="fixed"
					type="button"
					className="offer__favorites__btn btn btn--square btn--square--reset btn--ghost"
					onClick={onButtonClick}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					disabled={!rootStateStore.userAuthStore.isLoggedIn}
				>
					<i
						className={classNames(
							"offer__favorites__icon u-icon u-icon--med",
							{
								"u-icon--favorite--bold u-color--secondary--sunny":
									isFavorite || (!isFavorite && isHover),
								"u-icon--favorite--alt u-color--ui--muted":
									!isFavorite && !isHover,
							}
						)}
					/>
				</button>
			</div>

			<Tooltip
				id={tooltipId}
				className="at-tooltipTeams react-tooltip--primary"
				place="right-start"
				closeOnResize={true}
			/>
		</>
	);
});
