import { MitIdApiService } from "@api-services/integrations/MitIdApiService";
import { useLoading, useRootAppStore } from "@hooks";
import {
	getRedirectOriginUrl,
	isCapacitorPlatform,
} from "@utils/specific/capacitor";
import { StorageStateKeysEnum } from "@utils/storage";
import { isMitIdLoginEnabled } from "@v2/helpers";
import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
interface LogonMitIdButtonProps {
	btnSize?: string;
}

export default observer(function LogonMitIdButton(
	props: LogonMitIdButtonProps
) {
	const { setIsLoading } = useLoading();
	const { t } = useTranslation();
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const { isIpRestricted } = useRootAppStore();

	async function redirectToMit() {
		setIsLoading(true);
		setIsButtonDisabled(true);

		const redirectUrl = getRedirectOriginUrl(
			window.location.pathname,
			true
		);
		try {
			const mitAuthResponse = await MitIdApiService.getMitIdWidgetInfo(
				redirectUrl
			);
			if (mitAuthResponse.loginHash) {
				App.state.rootStore.localStorageProvider.set(
					StorageStateKeysEnum.MITID_LOGIN_HASH,
					JSON.stringify(mitAuthResponse.loginHash)
				);
			}
			if (mitAuthResponse.loginUrl) {
				window.location.href = mitAuthResponse.loginUrl;
			}
			if (isCapacitorPlatform()) {
				setIsLoading(false);
				setIsButtonDisabled(false);
			}
		} catch (e) {
			console.error(e);
			// reset original loader and button in case of an error
			setIsButtonDisabled(false);
			setIsLoading(false);
		} finally {
			// fix for: #114888
			// do not turn off loader (there is a moment where loader is off and user can double click mitId button causing error with original state)
			// since we are doing redirect on button click we should not have hanging loader
			// setIsLoading(false);
		}
	}

	if (!isMitIdLoginEnabled) {
		return null;
	}

	return (
		<div>
			<button
				disabled={isButtonDisabled || isIpRestricted}
				className={classnames(
					"btn",
					props.btnSize ? `btn--${props.btnSize}` : "btn--lrg",
					"btn--block btn--secondary--inverted"
				)}
				onClick={redirectToMit}
			>
				{t<string>("MEMBERSHIP.LOGIN.LOGON_WITH")}
				<i className="u-icon u-icon--mitid u-mar--left--tny" />
			</button>
			<div className="u-mar--top--tny u-type--sml u-type--center u-color--text--secondary">
				{t<string>("MEMBERSHIP.LOGIN.FOR_MORE_INFORMATION_VISIT")}{" "}
				<a
					className="u-anchor u-anchor--primary"
					href="https://www.mitid.dk/"
				>
					MitID
				</a>{" "}
				{t<string>("MEMBERSHIP.LOGIN.WEBSITE")}.
			</div>
		</div>
	);
});
