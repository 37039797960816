import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { DatePicker, FormInputField } from "../../shared/inputs";
import { FormButton } from "../../shared/buttons";
import { useForgotPasswordForm } from "@v2/hooks/membership";

export default function ForgotPasswordFormSection(props: {
	setIsForgotPasswordSuccess: (value: boolean) => void;
	isForgotPasswordSuccess: boolean;
}) {
	const { handleSubmit } = useFormContext();
	const { t } = useTranslation();

	const { onFormSubmit, onInitialize } = useForgotPasswordForm({
		setIsForgotPasswordSuccess: props.setIsForgotPasswordSuccess,
	});

	useEffect(() => {
		onInitialize();
	}, []);

	const maxDate = DateTime.now().minus({ years: 18 }).toJSDate();
	const minDate = DateTime.now().minus({ years: 100 }).toJSDate();

	if (props.isForgotPasswordSuccess) {
		return null;
	}

	return (
		<form
			className="at-forgotPassword form card--primary"
			onSubmit={handleSubmit(onFormSubmit)}
		>
			<div className="card--primary__header">
				<div className="card--primary__title title--primary">
					{t<string>("MEMBERSHIP.FORGOT_PASSWORD.FORGOT_PASS_TITLE")}
				</div>
			</div>
			<div className="card--primary__body">
				<div className="u-mar--bottom--big">
					{t<string>("MEMBERSHIP.FORGOT_PASSWORD.FORGOT_PASS")}
				</div>

				<div className="form__group">
					<div className="row">
						<FormInputField
							id="userName"
							className="col col-sml-12 form__field"
							label="MEMBERSHIP.FORGOT_PASSWORD.FP_USERNAME"
							required
							name="userName"
							placeholder="MEMBERSHIP.FORGOT_PASSWORD.FP_USERNAME_PLACEHOLDER"
							validate={{
								isEmail: (value: string) =>
									!value.includes("@") ||
									"MEMBERSHIP.FORGOT_PASSWORD.USERNAME_EMAIL_ERROR_MESSAGE",
							}}
						/>
					</div>
				</div>

				<div className="form__group">
					<div className="row">
						<DatePicker
							name="dateOfBirth"
							label="MEMBERSHIP.FORGOT_PASSWORD.DOB_LABEL"
							className="col col-sml-12 form__field"
							required
							maxDate={maxDate}
							minDate={minDate}
						/>
					</div>
				</div>

				<div className="form__group">
					<div className="row">
						<div className="col col-sml-12">
							<div className="u-type--muted">
								{t<string>(
									"MEMBERSHIP.FORGOT_PASSWORD.CONTACT_ASTERISK"
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card--primary__footer">
				<FormButton
					className="card--primary__footer__btn btn btn--lrg btn--primary"
					btnText="MEMBERSHIP.FORGOT_PASSWORD.FP_SUBMIT_BTN"
				/>
			</div>
		</form>
	);
}
