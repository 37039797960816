import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { isNullOrWhitespace } from "@utils";
import { useWatch } from "react-hook-form";

export const useFieldValidation = (field: string, triggerField: string) => {
	const newPassword = useWatch({ name: field });
	const { getFieldState, getValues, trigger } = useFormContext();
	useEffect(() => {
		if (
			!isNullOrWhitespace(getValues(field)) &&
			getFieldState(field).isTouched &&
			getFieldState(triggerField).isTouched
		) {
			trigger(triggerField);
		}
	}, [newPassword]);
};
