import React from "react";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { isNullOrWhitespace } from "@v2/helpers/utility";
import { Button } from "@v2/components/desktop/shared/buttons";
import { getSystemString } from "@v2/helpers/account-statement/utility";
import {
	AccountStatementBetDetailsError,
	AccountStatementBetDetailsOffer,
} from "./";
import { prepareOfferData } from "../../../../helpers/account-statement/utility";
import { WalletIcon } from "../../../shared/utility";
import { AccountStatementBetContext } from "../../../../context/AccountStatementBetContext";
import { useContext } from "react";
import { getDisplayedAmount } from "@utils";
import AccountStatementFiskalySection from "./AccountStatementFiskalySection";

export default function AccountStatementBetDetails() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);

	const { slipDetails, setDetailsType } = contextValue!;

	if (slipDetails == null || !("betSlipNumber" in slipDetails)) {
		return (
			<AccountStatementBetDetailsError setDetailsType={setDetailsType} />
		);
	}

	const {
		isLive,
		system,
		totalNumberOfCombinations,
		dateCreated,
		payment,
		maxGain,
		/* status, */
		eventCount,
		paymentPerCombination,
		maxCoefficient,
		currency,
	} = slipDetails;
	const isSystem = !isNullOrWhitespace(system);

	const finalOffers = prepareOfferData(slipDetails);
	return (
		<div className="popup">
			<div className="popup__card popup__card--reset-width">
				<Header />

				<div className="popup__body">
					<div className="card--bet">
						<table className="table--bets">
							<thead className="table--bets__head">
								<tr className="table--bets__head__row">
									<th
										className="u-padd--reset u-type--wgt--medium"
										colSpan={8}
									>
										<table
											className="table--bets--reset"
											cellSpacing="0"
											cellPadding="0"
										>
											<tbody>
												<tr>
													<th className="table--bets__th table--bets__th--time">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.INFO_DATE",
															{
																interpolation: {
																	escapeValue:
																		false,
																},
																0: DateTime.fromISO(
																	dateCreated
																).toLocaleString(
																	DateTime.DATETIME_SHORT_WITH_SECONDS
																),
															}
														)}
													</th>
													<th className="table--bets__th table--bets__th--type">
														<AdditionalDescriptionSystem />

														<AdditionalDescriptionNotSystem />
													</th>
													<th className="table--bets__th table--bets__th--price">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.INFO_PAYMENT",
															{
																0: getDisplayedAmount(
																	payment,
																	currency
																),
															}
														)}
													</th>
													<th className="table--bets__th table--bets__th--price">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.INFO_MAX_GAIN",
															{
																0: getDisplayedAmount(
																	maxGain,
																	currency
																),
															}
														)}
													</th>
												</tr>
											</tbody>
										</table>
									</th>
								</tr>
								<tr className="table--bets__subhead">
									<th className="table--bets__ts table--bets__ts--time">
										{t<string>(
											"ACC_STATEMENT.BET_DETAILS.T_TIME"
										)}
									</th>
									<th className="table--bets__ts table--bets__ts--combi">
										&nbsp;
									</th>
									<th className="table--bets__ts table--bets__ts--event">
										{t<string>(
											"ACC_STATEMENT.BET_DETAILS.T_EVENT"
										)}
									</th>
									<th className="table--bets__ts table--bets__ts--bank">
										&nbsp;
									</th>
									<th className="table--bets__ts table--bets__ts--bet-type">
										{t<string>(
											"ACC_STATEMENT.BET_DETAILS.T_TIP"
										)}
									</th>
									<th className="table--bets__ts table--bets__ts--odds">
										{t<string>(
											"ACC_STATEMENT.BET_DETAILS.T_QUOTA"
										)}
									</th>
									<th className="table--bets__ts table--bets__ts--results">
										{t<string>(
											"ACC_STATEMENT.BET_DETAILS.T_RESULT"
										)}
									</th>
									<th className="table--bets__ts table--bets__ts--icon">
										&nbsp;
									</th>
								</tr>
							</thead>
							<tbody className="table--bets__body">
								{finalOffers &&
									finalOffers.map((offer) => (
										<AccountStatementBetDetailsOffer
											offer={offer}
											isSystem={isSystem}
											totalCombinations={
												totalNumberOfCombinations
											}
											isLive={isLive}
											key={offer.bettingOfferId}
											status={status}
										/>
									))}

								<tr className="table--bets__details">
									<td className="u-padd--reset" colSpan={8}>
										<table className="table--bets--reset">
											<tbody>
												<tr className="table--bets__tr">
													<td className="table--bets__dd">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_NUM_SELECTED_EVENTS"
														)}
													</td>
													<td className="table--bets__dd">
														{eventCount}
													</td>
												</tr>
												<tr className="table--bets__tr">
													<td className="table--bets__dd">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_NUM_BETS"
														)}
													</td>
													<td className="table--bets__dd">
														{
															totalNumberOfCombinations
														}
													</td>
												</tr>
												<tr className="table--bets__tr">
													<td className="table--bets__dd">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_STAKE_PER_BET"
														)}
													</td>
													<td className="table--bets__dd">
														{getDisplayedAmount(
															paymentPerCombination,
															currency
														)}
													</td>
												</tr>

												<tr className="table--bets__tr">
													<td className="table--bets__dd">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_TOTAL_STAKE"
														)}
													</td>
													<td className="table--bets__dd">
														{getDisplayedAmount(
															payment,
															currency
														)}
													</td>
												</tr>

												<HandlingFeePercentage />

												<tr className="table--bets__tr">
													<td className="table--bets__dd">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_MAX_COEFFICIENT"
														)}
													</td>
													<td className="table--bets__dd">
														{maxCoefficient}
													</td>
												</tr>

												<AdditionalGainBonus />

												<AdditionalGainTax />

												<tr className="table--bets__tr">
													<td className="table--bets__dd">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_MAX_GAIN"
														)}
													</td>
													<td className="table--bets__dd">
														{getDisplayedAmount(
															maxGain,
															currency
														)}
													</td>
												</tr>
												<tr className="table--bets__tr">
													<td className="table--bets__dd u-color--ui--danger">
														{t<string>(
															"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_PAYMENT"
														)}
													</td>
													<td className="table--bets__dd u-color--ui--danger">
														{getDisplayedAmount(
															payment,
															currency
														)}
													</td>
												</tr>
												<AdditionalGain />
												<AdditionalPayout />
												<MetadataSection />
												<AccountStatementFiskalySection />
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

function Header() {
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails, setDetailsType } = contextValue!;

	if (!("isLive" in slipDetails!)) {
		return null;
	}
	const {
		isLive,
		betSlipNumber,
		betStatus,
		betSlipStatus,
		bettingAccountType,
		isBonus,
	} = slipDetails;

	const { t } = useTranslation();

	const betStatusClassname = classNames(
		"tag tag--med",
		{
			"tag--bet tag--bet--won": betSlipStatus.abrv == "cashout",
			[`tag--slip tag--slip--${betSlipStatus.abrv}`]:
				betSlipStatus.abrv != "cashout",
		},
		"u-mar--right--xtny"
	);

	const shouldShowBetSlipStatus =
		betSlipStatus.abrv.includes("cashout") ||
		betSlipStatus.abrv === "paid-out";

	return (
		<div className="popup__header">
			<div className="popup__header__title u-type--base">
				<div className={betStatusClassname}>
					{t<string>(
						isLive
							? "ACC_STATEMENT.BET_DETAILS.BET_SLIP_NUMBER_LIVE"
							: "ACC_STATEMENT.BET_DETAILS.BET_SLIP_NUMBER",
						{
							0: betSlipNumber,
							1: t<string>(
								"ACC_STATEMENT.BET_DETAILS." +
									betStatus.abrv.toUpperCase()
							).toUpperCase(),
						}
					)}{" "}
					{isBonus && <> ({t<string>("COMMON.BONUS")})</>}
				</div>
				{shouldShowBetSlipStatus && (
					<div
						className={
							"card--bet__status tag tag--med tag--slip tag--slip--" +
							betSlipStatus.abrv +
							"--inverted"
						}
					>
						{betSlipStatus.name}
					</div>
				)}
				<WalletIcon
					bettingAccountTypeAbrv={
						bettingAccountType != null
							? bettingAccountType.abrv
							: ""
					}
				/>
			</div>

			<Button
				className="btn--tny btn--square--tny btn--secondary--inverted btn--animate-grow"
				btnType="button"
				iconClassName="u-icon--sml u-icon--close"
				onClick={() => setDetailsType(0)}
				iconOnly={true}
			/>
		</div>
	);
}

function AdditionalDescriptionSystem() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails } = contextValue!;
	//@ts-ignore
	if (!("system" in slipDetails!) || isNullOrWhitespace(slipDetails.system)) {
		return <></>;
	}
	const {
		system,
		combinationBettingOffersBankCount,
		eventCount,
		combinationBettingOffersCount,
	} = slipDetails;

	const systemString = getSystemString({
		system,
		combinationBettingOffersBankCount,
		eventCount,
		combinationBettingOffersCount,
	});

	return (
		<>
			{t<string>("ACC_STATEMENT.BET_DETAILS.INFO_SYSTEM", {
				interpolation: { escapeValue: false },
				0: systemString,
			})}
		</>
	);
}

function AdditionalDescriptionNotSystem() {
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails } = contextValue!;

	if ("system" in slipDetails! && slipDetails.system) {
		return null;
	}
	if (!("totalNumberOfCombinations" in slipDetails!)) {
		return null;
	}
	const { totalNumberOfCombinations } = slipDetails;
	return (
		<>
			<AdditionalDescriptionCombination
				totalNumberOfCombinations={totalNumberOfCombinations}
			/>
			<AdditionalDescriptionCombinations
				totalNumberOfCombinations={totalNumberOfCombinations}
			/>
		</>
	);
}

function AdditionalDescriptionCombinations(props: {
	totalNumberOfCombinations: number;
}) {
	const { t } = useTranslation();

	const { totalNumberOfCombinations } = props;

	if (totalNumberOfCombinations <= 1) {
		return null;
	}

	return (
		<>
			{t<string>("ACC_STATEMENT.BET_DETAILS.INFO_COMBINATION_MULTI", {
				0: totalNumberOfCombinations,
			})}
		</>
	);
}

function AdditionalDescriptionCombination(props: {
	totalNumberOfCombinations: number;
}) {
	const { t } = useTranslation();

	const { totalNumberOfCombinations } = props;

	if (totalNumberOfCombinations > 1) {
		return null;
	}

	return (
		<>
			{t<string>("ACC_STATEMENT.BET_DETAILS.INFO_COMBINATION", {
				0: totalNumberOfCombinations,
			})}
		</>
	);
}

function HandlingFeePercentage() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails } = contextValue!;

	if (!("handlingFee" in slipDetails!)) {
		return null;
	}

	const { handlingFeePercentage, handlingFee, currency } = slipDetails;

	if (
		!handlingFeePercentage ||
		handlingFeePercentage == 0 ||
		handlingFee == null
	) {
		return null;
	}

	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd">
				{t<string>(
					"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_HANDLING_FEE",
					{
						0: handlingFeePercentage.toFixed(),
					}
				)}
			</td>
			<td className="table--bets__dd">
				{getDisplayedAmount(handlingFee, currency)}
			</td>
		</tr>
	);
}

function MetadataSection() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails } = contextValue!;

	if (!("metadata" in slipDetails!)) {
		return null;
	}

	const { metadata } = slipDetails;

	if (metadata == null || metadata.fiscalizationItems == null) {
		return null;
	}

	const finishtransaction = metadata.fiscalizationItems.find(
		(item: any) => item.isFinishTransaction
	);

	return (
		<>
			<tr className="table--bets__tr table--bets__fiscalization table--bets__fiscalization--dashed">
				<td className="table--bets__tf" colSpan={2}>
					<span className="u-mar--right--xtny">
						{t<string>(
							"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_START_TIME"
						)}
					</span>
					<span>
						{DateTime.fromISO(
							finishtransaction.transactionTimestamp
						).toLocaleString(DateTime.DATETIME_SHORT)}
					</span>
				</td>
			</tr>

			<tr className="table--bets__tr table--bets__fiscalization">
				<td className="table--bets__tf" colSpan={2}>
					<span className="u-mar--right--xtny">
						{t<string>(
							"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_END_TIME"
						)}
					</span>
					<span>
						{DateTime.fromISO(
							finishtransaction.transactionTimestamp
						).toLocaleString(DateTime.DATETIME_SHORT)}
					</span>
				</td>
			</tr>

			<tr className="table--bets__tr table--bets__fiscalization">
				<td className="table--bets__tf" colSpan={2}>
					<span className="u-mar--right--xtny">
						{t<string>(
							"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_TRANSACTION_NUMBER"
						)}
					</span>
					<span>{finishtransaction.transactionNumber}</span>
				</td>
			</tr>

			<tr className="table--bets__tr table--bets__fiscalization">
				<td className="table--bets__tf" colSpan={2}>
					<span className="u-mar--right--xtny">
						{t<string>(
							"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_SIGNATURE_COUNTER"
						)}
					</span>
					<span>{finishtransaction.signatureCounter}</span>
				</td>
			</tr>

			<tr className="table--bets__tr table--bets__fiscalization">
				<td className="table--bets__tf" colSpan={2}>
					<span className="u-mar--right--xtny">
						{t<string>(
							"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_TSE_SERIAL_NUMBER"
						)}
					</span>
					<span>{metadata.deviceSerialNumber}</span>
				</td>
			</tr>

			<tr className="table--bets__tr table--bets__fiscalization">
				<td className="table--bets__tf" colSpan={2}>
					<span className="u-mar--right--xtny">
						{t<string>(
							"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_SIGNATURE"
						)}
					</span>
					<span>{finishtransaction.signature}</span>
				</td>
			</tr>
		</>
	);
}

function AdditionalGain() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails } = contextValue!;

	if (!slipDetails || !("gain" in slipDetails)) {
		return null;
	}
	if (slipDetails.betStatus.abrv != "won") {
		return null;
	}
	const { gain, currency } = slipDetails;

	if (!gain || isNullOrWhitespace(gain.toString())) {
		return null;
	}

	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd u-color--ui--success">
				{t<string>("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_GAIN")}
			</td>
			<td className="table--bets__dd u-color--ui--success">
				{getDisplayedAmount(gain, currency)}
			</td>
		</tr>
	);
}

function AdditionalGainBonus() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails } = contextValue!;

	if (!slipDetails || !("gainBonusPercentage" in slipDetails)) {
		return null;
	}

	const { gainBonusPercentage, gainBonus, currency } = slipDetails;

	if (gainBonusPercentage == null || gainBonus == 0) {
		return null;
	}

	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd">
				{t<string>("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_GAIN_BONUS", {
					0: gainBonusPercentage.toFixed(2),
				})}
			</td>
			<td className="table--bets__dd">
				+ {getDisplayedAmount(gainBonus || 0, currency, 0)}
			</td>
		</tr>
	);
}

function AdditionalGainTax() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails } = contextValue!;

	if (!slipDetails || !("gainTaxPercentage" in slipDetails)) {
		return null;
	}

	const { gainTaxPercentage, gainTax, currency } = slipDetails;

	if (gainTaxPercentage == null || gainTaxPercentage == 0) {
		return null;
	}

	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd">
				{t<string>("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_GAIN_TAX", {
					0: gainTaxPercentage.toFixed(),
				})}
			</td>
			<td className="table--bets__dd">
				- {getDisplayedAmount(gainTax || 0, currency, 0)}
			</td>
		</tr>
	);
}

function AdditionalPayout() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);
	const { slipDetails } = contextValue!;

	if (!slipDetails || !("payout" in slipDetails)) {
		return null;
	}

	const { payout, currency } = slipDetails;

	if (!payout || isNullOrWhitespace(payout.toString())) {
		return null;
	}

	if (
		slipDetails.betSlipStatus.abrv !== "paid-out" &&
		slipDetails.betSlipStatus.abrv !== "cashout"
	) {
		return null;
	}

	return (
		<tr className="table--bets__tr">
			<td className="table--bets__dd u-color--ui--success">
				{t<string>("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_PAYOUT")}
			</td>
			<td className="table--bets__dd u-color--ui--success">
				{getDisplayedAmount(payout, currency)}
			</td>
		</tr>
	);
}
