import React, { useContext } from "react";
import { observer } from "mobx-react";
import { GroupedBettingTypes } from "./components";
import { EventContext, OfferStoreContext } from "@gp/components";
import { AdditionalOfferViewStore } from "@v2/state/offer/components/AdditionalOfferViewStore";

export default observer(function AddOfferGroupedEventOfferTemplate() {
	const store = useContext(OfferStoreContext) as AdditionalOfferViewStore;

	if (store.isButtonToggled) {
		return null;
	}
	return <GroupedBettingTypes />;
});
