import { AccountVerificationStatus } from "@api-types/account-verification/AccountVerificationType";
import {
	AccountTypes,
	getAgencyKey,
	getApplicationHeaders,
	httpClient,
} from "@utils";

export default class AccountVerificationStatusApiService {
	static get baseUrl(): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}/gp-users/account-verification-status`;
	}

	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getAccountVerificationStatus(): Promise<AccountVerificationStatus> {
		return await httpClient.get(this.baseUrl, this.getRequestHeaders(), {
			responseType: "json",
		});
	}
}
