import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons";
import { useCreate24hLock } from "@v2/hooks/header/useCreate24hLock";
import { is24hLockEnabled } from "@v2/helpers";
import { legalLockTranslationKeys } from "@v2/helpers/account-settings/account-lock/utils";

export default function HeaderLockButton() {
	const [pressAndHoldTimeout, setPressAndHoldTimeout] = useState<
		ReturnType<typeof setTimeout> | undefined
	>(undefined);
	const [showInstructionsPopup, setShowInstructionsPopup] =
		useState<boolean>(false);

	const { t } = useTranslation();

	const { create24hLock } = useCreate24hLock();

	useEffect(() => {
		return () => clearTimeout(pressAndHoldTimeout);
	}, []);

	const startClickTimer = () => {
		setPressAndHoldTimeout(setTimeout(create24hLock, 3000));
	};

	const stopClickTimer = () => {
		clearTimeout(pressAndHoldTimeout);
		setPressAndHoldTimeout(undefined);
	};

	const shouldShowLockButton = WEBPACK_IS_OASIS_ENABLED || is24hLockEnabled;

	if (!shouldShowLockButton) {
		return null;
	}

	return (
		<div className="header__settings__item header__lock u-mar--right--tny">
			<Button
				className="header__lock__btn btn btn--sml btn--danger btn--icon btn--animate-grow u-type--sml popover popover--bottom"
				onMouseEnter={() => setShowInstructionsPopup(true)}
				onMouseLeave={() => setShowInstructionsPopup(false)}
				onMouseDown={() => startClickTimer()}
				onMouseUp={() => stopClickTimer()}
				btnContent={
					<>
						<i className="header__lock__icon u-icon u-icon--med u-icon--lock u-color--core--snow"></i>
						<span className="header__lock__label btn__label">
							{t<string>("HEADER.LOCK_BUTTON_LABEL")}
						</span>
					</>
				}
			/>
			<ShowLockInstructions
				showInstructionsPopup={showInstructionsPopup}
			/>
		</div>
	);
}

function ShowLockInstructions(props: { showInstructionsPopup: boolean }) {
	const { showInstructionsPopup } = props;
	const { t } = useTranslation();

	if (!showInstructionsPopup) {
		return null;
	}

	return (
		<div className="popover__wrapper popover__wrapper--danger popover--bottom__wrapper">
			<div className="popover__content popover__content--wrap">
				<ul>
					<li>{t<string>("HEADER.LOCK_INSTRUCTIONS")}</li>
					<li>
						{legalLockTranslationKeys
							.map((key) => t(key))
							.join(" ")}
					</li>
				</ul>
			</div>
			<span className="popover__arrow popover--bottom__arrow popover--bottom__arrow--danger" />
		</div>
	);
}
