import React, { useState } from "react";
import { useFixBody } from "@v2/hooks/shared";
import { useTranslation } from "react-i18next";
import { useRootAppStore } from "@hooks";
import WelcomeInfo from "./welcomePopups/WelcomeInfo";
import AccountVerificationInfo from "./welcomePopups/AccountVerificationInfo";

import DocumentExpirationInfo from "./welcomePopups/DocumentExpirationInfo";
import BettingDisabledInfo from "./welcomePopups/BettingDisabledInfo";
import DepositLimitInfo from "./welcomePopups/DepositLimitInfo";
import UseMarketplaceBonus from "./welcomePopups/UseMarketplaceBonus";

export default function WelcomeLoginPopup() {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();

	useFixBody(true, rootStore.welcomeStore.isActive);

	const componentsList = [
		WelcomeInfo(),
		DepositLimitInfo(),
		AccountVerificationInfo(),
		BettingDisabledInfo(),
		DocumentExpirationInfo(),
		UseMarketplaceBonus(),
		//content that is not error nor warning goes below
	];

	if (componentsList.every((component) => component == null)) {
		rootStore.welcomeStore.hidePopup();
		return null;
	}

	return (
		<div className="at-welcomeModal popup popup--mega">
			<div className="popup__card popup--mega__card">
				<div className="popup__header popup__header--top u-padd--lrg">
					<div className="popup__title popup__title--vertical">
						<div className="u-mar--bottom--xtny u-type--xxlrg u-type--wgt--bold">
							{t("HOME.PROMO_OFFER.WELCOME_TITLE", {
								gp_company_placeholder: "TipWin",
							})}
							!
						</div>
						<div className="u-type--xmed u-type--wgt--regular u-color--text--secondary">
							{t("WELCOME_POPUP.INFO_TEXT_PART_1")}{" "}
							<span className="u-type--wgt--medium u-color--text--primary">
								{t("WELCOME_POPUP.INFO_TEXT_PART_2")}
							</span>
							.
						</div>
					</div>

					<button
						type="button"
						className="popup__close btn btn--square btn--square--tny btn--secondary--inverted"
						title={t("COMMON.CLOSE")}
						onClick={async () => {
							await rootStore.userAuthStore.sendWelcomeUseBonusConfirmation(
								true
							);
							rootStore.welcomeStore.hidePopup();
						}}
					>
						<i className="u-icon u-icon--xsml u-icon--close" />
					</button>
				</div>
				<div className="popup__body u-padd--lrg">
					<div className="popup__section">
						<div className="popup__row row">
							{componentsList.map((component, id) => {
								return (
									<React.Fragment key={id}>
										{component}
									</React.Fragment>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
