import { getCurrentCulture } from "@utils";

export function openTournamentStatistics(betRadarId: string) {
	window.open(
		"https://s5.sir.sportradar.com/tipwin/" +
			getCurrentCulture() +
			"/season/" +
			betRadarId,
		"newwindow",
		"width=1200, height=800"
	);
}

export function openEventStatistics(betRadarId: string) {
	window.open(
		"https://s5.sir.sportradar.com/tipwin/" +
			getCurrentCulture() +
			"/match/" +
			betRadarId,
		"newwindow",
		"width=1200, height=800"
	);
}

export function openMobileTournamentStatisticsPopUp(betRadarId: string) {
	App.state.addToUrlParams({ tStatId: Number(betRadarId) || "full" });
}

export function openMobileEventStatisticsPopUp(betRadarId: string) {
	App.state.addToUrlParams({ eStatId: Number(betRadarId) || "full" });
}

export function closeMobileStatisticsPopUp() {
	App.state.removeFromUrlParams(["tStatId", "eStatId"]);
}
