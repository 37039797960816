import React, { useEffect } from "react";
import { HeaderWithSideMenu } from "../shared/header";
import { AdministrationSideMenu } from "../shared/side-menu";
import { Footer } from "../shared/footer";
import { AccountStatementFilter } from "./filter";
import {
	AccountStatementBettingTable,
	AccountStatementPaymentTable,
	AccountStatementTransactionsTable,
} from "./table";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { setTabTitle } from "@utils";
import { AccountStatementContextProvider } from "@v2/containers/AccountStatementContextProvider";

export default function AccountStatementPage() {
	const methods = useForm({
		mode: "onSubmit",
		defaultValues: {
			bettingAccountTypeIds:
				App.state.rootStore.userAuthStore.availableUserWallets![0],
		},
		shouldUnregister: true,
	});
	const { t } = useTranslation();
	useEffect(() => {
		setTabTitle(t("HEADER.ACCOUNT_STATEMENT"));
		if (App.state.rootStore?.isSideMenuExpanded != null) {
			App.state.rootStore.closeSideMenu();
		}
		//on mount scroll to top
		document?.querySelector("html, body")?.scrollTo(0, 0);
		return () => {
			if (App.state.rootStore?.isSideMenuExpanded != null) {
				App.state.rootStore.openSideMenu();
			}
		};
	}, []);

	return (
		<>
			<HeaderWithSideMenu />
			<aside className="l--aside--left">
				<AdministrationSideMenu />
			</aside>
			<AccountStatementContextProvider>
				<main className="l--main l--main--med">
					<section className=" l--content l--content--full">
						<div className="l--content__main">
							<div className="container">
								<FormProvider {...methods}>
									<AccountStatementFilter />
								</FormProvider>

								<table className="table table--fixed table--bordered u-mar--top--xsml">
									<AccountStatementPaymentTable />
									<AccountStatementBettingTable />
								</table>

								<AccountStatementTransactionsTable />
							</div>
						</div>
					</section>
				</main>
			</AccountStatementContextProvider>
			<footer className="l--footer">
				<Footer />
			</footer>
		</>
	);
}
