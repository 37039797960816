import {
	CountriesLookupModel,
	UserAccountLockDurationTypesModel,
	UserOasisLockBlockReasonsTypesModel,
} from "@api-types";
import { OasisMissingUserInfo } from "@api-types/locks/SelfExclusionInfoDto";

export class OasisLockGetViewModel {
	data: OasisLockGetViewDataModel;
	blockReasons: UserOasisLockBlockReasonsTypesModel[];
	blockReasonsDisableOption: UserOasisLockBlockReasonsTypesModel;
	durationTypes: UserAccountLockDurationTypesModel[];
	countries: CountriesLookupModel[];
	personalDetails?: OasisPersonalDetailsListModel[] | [];
	isPersonalDetailsMissing?: boolean;
}

export class OasisLockGetViewDataModel {
	personalDetails: OasisPersonalDetailsListModel[];
	isPersonalDetailsMissing: boolean;
	lockDurationId: string;
	reasonCodes: string[];
	password: string;
	isLocked: boolean;
}

export class OasisPersonalDetailsListModel {
	fieldName: string;
	value: string;
}

export class OasisLockLookupsInitialDataModel {
	oasisDurationAbrvs: string[];
	oasisReasonCodes: string[];
	oasisReasonCodesDisableCode: string;
}

export class OasisLockUpdateModel {
	password: string;
	isPersonalDataMissing: boolean;
	lockDuration: string;
	personalDetails: OasisMissingUserInfo;
	reasonCodes: ReasonCodes;
}

export class ReasonCodes {
	disableOption: boolean | string;
	fieldData: string[] | boolean;
}
