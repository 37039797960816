import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "@v2/components/desktop/shared/footer";
import { Header } from "@v2/components/desktop/shared/header";
import { setTabTitle } from "@utils";

export default function RestrictedPage() {
	const { t } = useTranslation();

	useEffect(() => {
		setTabTitle(t("RESTRICTION.RESTRICT_TITLE"));
		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Header />
			<main className="at-restricted l--main l--main--full">
				<section className="l--content l--content--full">
					<div className="l--content__main">
						<div className="container">
							<div className="card--message">
								<div className="card--message__thumb" />
								<div className="card--message__body">
									<div className="card--message__content">
										<i className="card--message__icon u-icon u-icon--xlrg u-icon--restricted u-icon--animated--bounce-in u-color--ui--danger" />
										<div>
											{t<string>(
												"RESTRICTION.COUNTRY_ACCESS_RESTRICTION_INFO",
												{
													0: t(
														"RESTRICTION.COMPANY_LABEL"
													),
												}
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</>
	);
}
