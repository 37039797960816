import { observable, action, runInAction, computed } from "mobx";
import { Subscription } from "rxjs";

import { MainOfferStore, BettingTypeSelectorsStore } from "@gp/offer";
import { ISubscriptionRequest } from "@gp/hub";
import { EventType, LiveStatus } from "@gp/models";
import { ConsoleLogger } from "@gp/utility";

import { getCurrentCulture } from "@utils";
import bettingRules from "@betting-rules";
import RootOfferStore from "@offer/stores/RootStore";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";

class LiveEventMenuViewStore extends MainOfferStore {
	@observable public expandedElementId: string | null;
	@observable public isLoading = true;

	public rootStore: RootOfferStore;
	firstLoad: boolean = true;
	protected liveOfferMenuStore: LiveOfferMenuStore;
	@observable private rawSelectedEventIds = "";

	private subscription: Subscription | null;

	@computed public get expandedElement() {
		if (this.expandedElementId) {
			return this.eventsInSports.sports.find(
				(s) => s.id === this.expandedElementId
			);
		}

		return null;
	}

	@computed public get elementSubMenu() {
		if (this.expandedElement) {
			return this.expandedElement.events;
		}

		return [];
	}

	@computed public get isMenuExpanded() {
		return this.rootStore.isSideMenuExpanded;
	}

	@computed public get eventIds() {
		// slice ensures to remove first empty element because of leading '/'
		return this.rawSelectedEventIds.split("/").slice(1);
	}

	constructor(
		rootStore: RootOfferStore,
		liveOfferMenuStore: LiveOfferMenuStore
	) {
		super({
			logger: new ConsoleLogger(false),
			removeDelay: 10,
			throttle: 4,
			enableThrottling: true,
			customConfiguration: bettingRules,
			bettingTypeSelectorsStore: new BettingTypeSelectorsStore(),
		});

		this.rootStore = rootStore;
		this.liveOfferMenuStore = liveOfferMenuStore;
	}

	@action.bound
	public onInitialize() {
		this.onLoad();
	}

	@action
	protected async onLoad(subsReq: ISubscriptionRequest | null = null) {
		const subscriptionRequest: ISubscriptionRequest = subsReq || {
			subscriptionId: "live-events-menu",
			compress: WEBPACK_OFFER_COMPRESS,
			channels: [
				{
					name: "event",
					filter: {
						eventType: EventType.NORMAL,
						liveStatus: LiveStatus.LIVE,
					},
				},
			],
		};

		this.subscription = this.rootStore.hub
			.getOfferSubscription(subscriptionRequest)
			.subscribe({
				next: (response) => {
					runInAction(() => {
						this.assignOfferData(response);

						// Used to set initial open sport in the menu
						if (
							this.eventsInSports.sports.length > 0 &&
							response.startingVersion === 0
						) {
							this.initialSportExpandOnResponse();
						}
						this.isLoading = false;
					});
				},
				error: (err) => {
					console.error(err);
					runInAction(() => {
						this.isLoading = false;
					});
				},
			});
	}

	@action.bound
	private initialSportExpandOnResponse() {
		const availableEvents = this.eventIds.filter((id) =>
			this.eventsMap.has(id)
		);

		const lastEventId =
			availableEvents.length >= 1
				? availableEvents[availableEvents.length - 1]
				: "";

		const lastEvent = this.eventsMap.get(lastEventId);

		if (lastEvent != null) {
			this.expandElement(lastEvent.sportId || "");
		} else {
			// this is failsafe
			if (this.firstLoad && this.liveOfferMenuStore.selectedSport) {
				this.expandElement(
					this.liveOfferMenuStore.selectedSport.node.id
				);
				this.firstLoad = false;
				return;
			}
			this.expandElement(this.eventsInSports.sports[0].id);
		}
	}

	//#region actions

	@action.bound
	public expandCollapseElement(elId: string) {
		if (this.expandedElementId !== elId || !this.isMenuExpanded) {
			this.expandElement(elId);
		} else {
			this.expandedElementId = null;
			this.rootStore.closeSideMenu();
		}
	}

	@action.bound
	public expandElement(elId: string) {
		this.expandedElementId = elId;
		this.rootStore.openSideMenu();
	}

	@action.bound
	public expandCollapseMenu() {
		this.rootStore.toggleSideMenu();
	}

	@action.bound
	public setSelectedEventIds(rawEventIds: string) {
		if (this.rawSelectedEventIds === rawEventIds) {
			return;
		}

		this.rawSelectedEventIds = "/" + rawEventIds;
	}

	@action.bound
	public addEvent(eventId: string): void {
		if (this.rawSelectedEventIds.indexOf(eventId) === -1) {
			this.rawSelectedEventIds += `/${eventId}`;
			this._updateUrl();
		}
	}

	@action.bound
	public removeEvent(eventId: string): void {
		if (this.rawSelectedEventIds.indexOf(eventId) > -1) {
			let currentIds = this.rawSelectedEventIds.split("/");
			let eventInUrl = currentIds.find((item) => item.includes(eventId));
			if (eventInUrl != null) {
				const eventInUrlSplitted = eventInUrl.split("-");
				const toggleValue = eventInUrlSplitted[1].split("=")[1];

				this.rawSelectedEventIds = this.rawSelectedEventIds.replace(
					`/${eventInUrlSplitted[0]}-myBets=${toggleValue}`,
					""
				);

				if (
					App.offer.rootStore.myBetsViewStore != null &&
					App.offer.rootStore.myBetsViewStore.betsPerEvent != null &&
					App.offer.rootStore.myBetsViewStore.betsPerEvent.length !==
						0
				) {
					const eventIdx =
						App.offer.rootStore.myBetsViewStore.betsPerEvent.findIndex(
							(item) => item.eventId === eventId
						);
					App.offer.rootStore.myBetsViewStore.betsPerEvent.splice(
						eventIdx,
						1
					);
				}
			}
			this._updateUrl();
		}
	}

	//#endregion actions

	//#region disposers

	@action.bound
	public onDispose() {
		this.disposeSubscription();
		this.firstLoad = true;
		this.rawSelectedEventIds = "";
		this.reset();
		this.expandedElementId = null;
		this.rootStore.closeSideMenu();
	}

	@action.bound
	private disposeSubscription() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	//#endregion disposers

	//#region helpers

	@action.bound
	private _updateUrl(): void {
		const culture = getCurrentCulture();
		const period = location.pathname.includes("my-favorites")
			? "my-favorites"
			: "events";

		App.state.redirect(
			`/${culture}/live/${period}${
				this.eventIds.length > 0 ? "/" : ""
			}${this.eventIds.join("/")}`,
			true
		);
	}

	//#endregion helpers
}

export default LiveEventMenuViewStore;
