import React, { useState } from "react";
import {
	useFormContext,
	useFormState,
	Validate,
	ValidationRule,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import classNames from "classnames";
import { RequiredMark, PopoverMessage, ErrorMessage } from "./";

export default function PasswordInput(
	props: {
		id?: string;
		errorMessage?: string;
		label: string;
		labelClassName?: string;
		inputClassName?: string;
		popoverMsg?: React.ReactNode | string;
		validate?: Validate<any> | Record<string, Validate<any>>;
		pattern?: ValidationRule<RegExp>;
		name: string;
	} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "pattern">
) {
	const {
		id = undefined,
		name,
		required = false,
		disabled,
		errorMessage = undefined,
		inputClassName = undefined,
		onChange,
		validate,
		pattern,
		label,
		labelClassName,
		className,
		placeholder = undefined,
		popoverMsg = null,
		...rest
	} = props;

	const [mouseOverState, setMouseOverState] = useState(false);
	const [showIcon, setShowIcon] = useState(true);
	const [pwdIcon, setPwdIcon] = useState(false);
	const [pwdType, setPwdType] = useState("password");

	const { t } = useTranslation();
	const { register } = useFormContext();

	const field = register(name, {
		required: {
			value: required,
			message: errorMessage || "COMMON.REQUIRED_FIELD",
		},
		disabled: disabled,
		onChange,
		validate,
		pattern,
	});

	const { errors } = useFormState();
	const error = get(errors, name);

	const inputClass = classNames("form__field--icon__input", inputClassName, {
		"input input--base":
			inputClassName == undefined || inputClassName == null,
		"input-validation-error": error != undefined,
	});

	const iconClass = classNames("u-icon u-icon--med", {
		"u-icon--view--off": !pwdIcon,
		"u-icon--view--on": pwdIcon,
	});

	const togglePwdType = () => {
		setPwdIcon(!pwdIcon);
		if (pwdType == "password") {
			setPwdType("text");
		} else {
			setPwdType("password");
		}
	};

	const handleInput: React.FormEventHandler<HTMLInputElement> = (e) => {
		e.persist();
		// @ts-expect-error
		if (e.target.value.length == 0) {
			setShowIcon(false);
		} else if (!showIcon) {
			setShowIcon(true);
		}
	};

	return (
		<div className={className}>
			<label
				htmlFor={id}
				className={classNames("label", labelClassName)}
				onMouseEnter={() => setMouseOverState(true)}
				onMouseLeave={() => setMouseOverState(false)}
			>
				{t<string>(label)} <RequiredMark required={required} />
				<PopoverMessage
					popoverMsg={popoverMsg}
					mouseOverState={mouseOverState}
				/>
			</label>
			<ErrorMessage name={name} />
			<div className="form__field--icon">
				<input
					{...field}
					{...rest}
					type={pwdType}
					id={id}
					className={inputClass}
					placeholder={t<string>(placeholder as string)}
					onInput={handleInput}
				/>
				<div
					className="form__field--icon__symbol"
					onClick={togglePwdType}
				>
					{showIcon ? <i className={iconClass} /> : null}
				</div>
			</div>
		</div>
	);
}
