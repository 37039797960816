import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { getCurrentCulture } from "@utils";

export const Results = observer(function Results() {
	const { t } = useTranslation();

	return (
		<div className="widget widget--results">
			<div className="widget__banner">
				<Link
					to={`/${getCurrentCulture()}/results`}
					className="widget__banner__link"
					target="_blank"
				>
					<i className="widget__banner__icon u-icon u-icon--huge u-icon--bet-results" />
					<div className="widget__banner__content">
						<div className="widget__banner__tag">
							<div className="tag tag--med tag--sunny">Bet</div>
						</div>
						<div className="widget__banner__title">
							{t<string>("RESULTS.TITLE")}
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
});
