import React, { useContext, useState } from "react";
import { observer } from "mobx-react";

import { EventContext, OfferStoreContext } from "@gp/components";

import {
	EmptyBettingTypes,
	BettingTypeGroups,
	BettingTypeGroupHeader,
} from "./";

export const GroupedBettingTypes = observer(function GroupedBettingTypes() {
	const event = useContext(EventContext);
	const store = useContext(OfferStoreContext);
	const [selectedGroup, setSelectedGroup] = useState("all");

	// @ts-expect-error
	const bettingTypeGroups = event.bettingTypeGroups;
	if (
		selectedGroup !== "favorites" &&
		bettingTypeGroups.find(
			(g: { abrv: string }) => g.abrv === selectedGroup
		) == null &&
		selectedGroup != "all"
	) {
		setSelectedGroup("all");
	}

	if (
		selectedGroup == "favorites" &&
		// @ts-expect-error
		store.favoritesBettingTypeGroups?.[event.id] &&
		// @ts-expect-error
		event?.favoritesBettingTypesIds?.length == 0
	) {
		setSelectedGroup("all");
	}
	const eventOfferKeys = store.eventKeysMap.get(event.id);

	if (
		bettingTypeGroups.length === 0 ||
		eventOfferKeys == null ||
		eventOfferKeys.size === 0
	) {
		return <EmptyBettingTypes />;
	}

	return (
		<>
			<div className="event__filter">
				<nav className="tabs--primary">
					<BettingTypeGroupHeader
						setSelectedGroup={setSelectedGroup}
						selectedGroup={selectedGroup}
					/>
				</nav>
			</div>
			<div className="event__offer">
				<BettingTypeGroups selectedGroup={selectedGroup} />
			</div>
		</>
	);
});
