import React, { useState, useEffect } from "react";

export default function ScrollToTop() {
	const [isButtonVisible, setIsButtonVisible] = useState(false);

	const checkScrollPosition = () => {
		if (window.pageYOffset > 100) {
			setIsButtonVisible(true);
		} else {
			setIsButtonVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScrollPosition);

		return () => window.removeEventListener("scroll", checkScrollPosition);
	}, []);

	if (!isButtonVisible) {
		return null;
	}

	return (
		<button
			className="footer__btn btn btn--square btn--square--lrg btn--animate-up"
			type="button"
			onClick={() => window.scrollTo(0, 0)}
		>
			<i className="u-icon u-icon--sml u-icon--arrow--up u-color--core--snow" />
		</button>
	);
}
