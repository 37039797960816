export class Response<T> {
	success: boolean;
	data: T | null;

	constructor(data: T | null = null) {
		this.success = true;
		this.data = data;
	}
}

export class ErrorResponse<E> {
	error: E | null;
	success: boolean;
	constructor(error: E | null = null) {
		this.error = error;
		this.success = false;
	}
}
