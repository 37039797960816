import { AccountStatementFilter } from "@api-types/account-settings/AccountStatementFilter";
import { UserPaymentTransactionLimited } from "@api-types/account-settings/UserPaymentTransactionLimited";

import {
	UserTypes,
	getAgencyKey,
	getUserFromLocalStorage,
	httpClient,
} from "@utils";
import { FindResultDto } from "@api-types/FindResultDto";
import { getApplicationHeaders } from "../../../utils/membership/applicationScope";
export class AccountStatementApiService {
	static get baseUrl(): string {
		return `${WEBPACK_BASE_ADDRESS}`;
	}

	static getRequestHeaders = (owner = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(owner);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};
	static async findAccountStatements(
		filterModel: AccountStatementFilter,
		owner = UserTypes.PRIMARY
	): Promise<FindResultDto<UserPaymentTransactionLimited>> {
		const userId = getUserFromLocalStorage(owner)?.id;

		const filter = new URLSearchParams();
		// TODO: do this with all filter properties
		if (filterModel.to) {
			filter.append("to", filterModel.to);
		}
		if (filterModel.from) {
			filter.append("from", filterModel.from);
		}
		if (filterModel.transactionTypeIds) {
			filter.append("transactionTypeIds", filterModel.transactionTypeIds);
		}
		if (filterModel.transactionGroupIds) {
			filter.append(
				"transactionGroupIds",
				filterModel.transactionGroupIds
			);
		}
		if (filterModel.currencyIds) {
			filter.append("currencyIds", filterModel.currencyIds);
		}
		if (filterModel.isOnline) {
			filter.append("isOnline", filterModel.isOnline + "");
		}
		if (filterModel.page) {
			filter.append("page", filterModel.page + "");
		}
		if (filterModel.rpp) {
			filter.append("rpp", filterModel.rpp + "");
		}
		if (filterModel.embed) {
			filter.append("embed", filterModel.embed);
		}
		if (filterModel.fields) {
			filter.append("fields", filterModel.fields);
		}
		if (filterModel.bettingAccountTypeIds) {
			filter.append(
				"bettingAccountTypeIds",
				// @ts-expect-error url search params will automatically apply join(",") to array of strings
				filterModel.bettingAccountTypeIds
			);
		}

		return await httpClient.get(
			`${this.baseUrl}${getAgencyKey(
				owner
			)}/account-statements/${userId}?${filter.toString()}&translate=true`,
			this.getRequestHeaders(owner),
			{ responseType: "json" }
		);
	}
}
