import { observable, computed, action, runInAction } from "mobx";
import {
	RegistrationLookupService,
	RegistrationService,
} from "../../data-access/membership/registration";
import { LoaderStore } from "@state/stores/common";
import {
	CountryDataType,
	FormDataType,
	GamingLimitDataType,
	LanguageDataType,
	LimitDurationDataType,
	OccupationDataType,
	OccupationGroupDataType,
	PasswordSimilarityRequestModel,
	RegionDataType,
	TitleDataType,
} from "@data-types/membership/registration";
import { SUPPORTED_LANGUAGES } from "@common/constants/SupportedLanguages";
import { MitIdApiService } from "@api-services/integrations/MitIdApiService";
import { MitIdFormData } from "@data-types/integrations/MitIdFormData";
import { DateTime } from "luxon";
import {
	ETermRegErrorDescParam,
	ETermRegErrorParam,
	ETermRegParam,
} from "@data-types/integrations/MitIdRegistrationParams";
import { DeviceDetector, getCurrentCulture } from "@utils";
import { registrationValidationMultiple } from "@utils/membership/registrationValidation";
import {
	getRedirectOriginUrl,
	isCapacitorPlatform,
} from "@utils/specific/capacitor";

export default class MitIdRegistrationStore {
	@observable currentStep = 1;
	@observable totalSteps = 2;
	@observable isFirstStepDone: boolean = false;
	@observable isSwitchingSteps: boolean = false;

	//lookup data
	@observable titleData: TitleDataType[] | null;
	@observable countryData: CountryDataType[] | null;
	@observable occupationData: OccupationDataType[] | null;
	@observable languageData: LanguageDataType[] | null;
	@observable countryRegionsData: RegionDataType[] | null;
	@observable occupationGroupData: OccupationGroupDataType[] | null;
	@observable gamingLimitsData: GamingLimitDataType | null;
	@observable limitDurationData: LimitDurationDataType[] | null;
	@observable formData: MitIdFormData | null;
	lookupService: RegistrationLookupService;
	registrationService: RegistrationService;
	loader: LoaderStore;
	lookups: [
		TitleDataType[],
		CountryDataType[],
		OccupationDataType[],
		LanguageDataType[],
		RegionDataType[],
		OccupationGroupDataType[],
		GamingLimitDataType
	];

	constructor() {
		this.lookupService = new RegistrationLookupService();
		this.registrationService = new RegistrationService();
		this.loader = new LoaderStore();
	}

	@computed get isLoading() {
		return this.loader.isLoadingProcess;
	}

	@action.bound
	async initializeMitIdProcess(): Promise<void> {
		this.loader.suspend();

		const redirectUrl = getRedirectOriginUrl(
			window.location.pathname,
			true
		);

		try {
			const response = await MitIdApiService.getMitIdWidgetInfo(
				redirectUrl
			);
			if (!response.loginHash || !response.loginUrl) {
				//invalid_token
				this.setMidIdErrorMessage(
					"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.TOKEN_EXCHANGE_RESPONSE_VALIDATION_FAILED"
				);
				//resume loader only on error
				this.loader.resume();
				return;
			}
			sessionStorage.setItem(
				"loginHash",
				JSON.stringify(response.loginHash)
			);
			//if we get here we need loader to still be on cause of redirect
			window.location.replace(response.loginUrl);
			if (isCapacitorPlatform()) {
				this.loader.resume();
			}
			//redirection - done
		} catch (err) {
			console.error(err);
			let errorMessage: string;
			//check what error messages should display in client
			switch (err.data.errorCode) {
				case 400955:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.AUTH_CONFIG_INIT";
					break;
				case 400956:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.AUTH_REQUEST_FAILED";
					break;
				case 400957:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.AUTH_UNHANDLED_ERROR";
					break;
				case 400958:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.AUTH_INVALID_LOGIN_URL";
					break;
				default:
					errorMessage = "MEMBERSHIP.REGISTRATION.GENERIC_ERROR";
					break;
			}
			this.setMidIdErrorMessage(errorMessage);
			//resume loader only on error
			this.loader.resume();
		}
	}

	@action.bound
	async getUserAdditionalInfo(
		scope: string,
		code: string,
		state: string,
		iss: string
	): Promise<void> {
		this.loader.suspend();
		const loginHashValues = sessionStorage.getItem("loginHash") as
			| string
			| null;
		if (!loginHashValues) {
			//invalid_token
			this.setMidIdErrorMessage(
				"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.TOKEN_EXCHANGE_RESPONSE_VALIDATION_FAILED"
			);
			return;
		}
		const hashObject: { state: string; nonce: string } =
			JSON.parse(loginHashValues);

		const data = {
			scope,
			code,
			state,
			iss,
			originalState: hashObject.state,
			originalNonce: hashObject.nonce,
			redirectUrl: getRedirectOriginUrl(window.location.pathname, true),
		};
		//redirectUrl: window.location.origin + window.location.pathname,

		try {
			const response = await MitIdApiService.getUserAdditionalInfo(data);

			let formData: MitIdFormData = {};

			if (response.mitIdName) {
				const [firstName, ...lastNames] = response.mitIdName.split(" ");

				//if firstname does not exist, first name field will be empty and editable
				if (firstName) {
					formData.firstName = firstName;
				}
				//if lastnames do not exist, last name field will be empty and editable
				formData.lastName = lastNames ? lastNames.join(" ") : undefined;
			}
			if (response.cprNumber) {
				formData.personalIdentificator = response.cprNumber;
			}
			if (response.birthDate) {
				const datetime = DateTime.fromISO(response.birthDate);

				if (DeviceDetector.isMobileTheme) {
					formData.dob = {
						day: datetime.get("day").toString(),
						month: datetime.get("month").toString(),
						year: datetime.get("year").toString(),
					};
				} else {
					formData.dob = datetime.toFormat("yyyy-MM-dd");
				}
			}
			runInAction(() => {
				//used observable for setting field values and disabling fields
				this.formData = formData;
				sessionStorage.setItem(
					"additionalUserInfo",
					JSON.stringify(formData)
				);
			});
		} catch (err) {
			console.error(err);
			//check what error messages should display in client
			let errorMessage: string;
			switch (err.data.errorCode) {
				case 400959:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.TOKEN_EXCHANGE_CONFIG_INIT";
					break;
				case 400960:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.AUTH_MITID_FLOW_ERROR";
					break;
				case 400961:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.AUTH_RESPONSE_VALIDATION_FAILED";
					break;
				case 400962:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.TOKEN_EXCHANGE_REQUEST_FAILED";
					break;
				case 400963:
					errorMessage =
						"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.TOKEN_EXCHANGE_RESPONSE_VALIDATION_FAILED";
					break;
				default:
					errorMessage = "MEMBERSHIP.REGISTRATION.GENERIC_ERROR";
					break;
			}
			this.setMidIdErrorMessage(errorMessage);
		} finally {
			this.loader.resume();
		}
	}

	@action.bound
	setMidIdErrorMessage(message: string) {
		//every time error message is set, remove local storage keys
		this.removeMitIdProcessLocalStorageKeys();
		App.state.redirect(
			`/${getCurrentCulture()}/auth/registration/error?key=${message}`
		);
		//TODO: handle errors
		console.error(message);
	}

	@action.bound
	async onInitialize(): Promise<void> {
		const mitIdRegistrationInProgress = await this.mitIdUrlParamProcess();
		if (mitIdRegistrationInProgress) {
			//dont fetch lookups if full registration is not yet accessible
			return;
		}
		this.loader.suspend();
		try {
			this.lookups = await Promise.all([
				this.fetchTitleLookupData(),
				this.fetchCountryLookupData(),
				this.fetchOccupationLookupData(),
				this.fetchLanguageLookupData(),
				this.fetchCountryRegionsLookupData(),
				this.fetchOccupationGroupLookupData(),
				this.fetchGamingLimits(),
			]);
		} catch (error) {
			this.setMidIdErrorMessage("MEMBERSHIP.REGISTRATION.GENERIC_ERROR");
		}

		runInAction(() => {
			if (this.lookups != null) {
				this.titleData = this.lookups[0];
				this.countryData = this.lookups[1].sort((a, b) =>
					a.name.localeCompare(b.name)
				);
				this.occupationData = this.lookups[2].sort((a, b) =>
					a.name.localeCompare(b.name)
				);
				this.languageData = this.lookups[3].sort((a, b) =>
					a.name.localeCompare(b.name)
				);
				this.countryRegionsData = this.lookups[4].sort((a, b) =>
					a.name.localeCompare(b.name)
				);
				this.occupationGroupData = this.lookups[5].sort((a, b) =>
					a.name.localeCompare(b.name)
				);
				this.gamingLimitsData = this.lookups[6];
			}
			this.loader.resume();
		});
	}

	@action.bound
	setCurrentStep(step: number, isValid?: boolean) {
		if (isValid || this.currentStep > step) {
			if (step === 2 && !this.isFirstStepDone) {
				this.isFirstStepDone = true;
			}
			this.currentStep = step;
			document.querySelector("main")?.scrollTo(0, 0);
		}
	}

	@action.bound
	previousStep() {
		if (this.currentStep > 1) {
			this.currentStep--;
			document.querySelector("main")?.scrollTo(0, 0);
		}
	}

	@action.bound
	nextStep() {
		if (this.currentStep < this.totalSteps) {
			this.currentStep++;
			document.querySelector("main")?.scrollTo(0, 0);
		}
	}

	@action.bound
	async register(
		formData: FormDataType,
		recaptcha: (value: string) => Promise<string>,
		affiliateCode: string | null
	) {
		try {
			this.loader.suspend();

			const recaptchaToken = await recaptcha?.("registerUser");

			const newFormData = formData;

			if (newFormData.day && newFormData.month && newFormData.year) {
				newFormData.dob = DateTime.fromObject({
					day: parseInt(newFormData.day),
					month: parseInt(newFormData.month),
					year: parseInt(newFormData.year),
				}).toISO() as string;
			}

			if (
				newFormData.limitDurationId == "" &&
				newFormData.limitAmount == ""
			) {
				delete newFormData!.limitDurationId;
				delete newFormData!.limitAmount;
			}

			if (
				newFormData.mobilePhone?.startsWith("+") &&
				newFormData.mobilePhone?.length <= 5
			) {
				delete newFormData!.mobilePhone;
			}

			newFormData.playerPromoSendingMode = JSON.parse(
				newFormData.playerPromoSendingMode
			);

			const registrationFormData = Object.assign(newFormData, {
				challengeResponse: recaptchaToken,
			});

			const response = await this.registrationService.registerUser(
				registrationFormData,
				affiliateCode
			);
			this.removeMitIdProcessLocalStorageKeys();
			App.state.redirect(
				`/${getCurrentCulture()}/auth/registration/success`
			);
		} catch (error) {
			console.error(error);
			if (error.statusCode === 404) {
				//user card not found/not valid
				this.setMidIdErrorMessage(
					"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.CARD_NOT_FOUND"
				);
				return;
			}

			const errorObject = await error.rawResponse.json();

			registrationValidationMultiple(
				errorObject,
				this.setMidIdErrorMessage
			);
		} finally {
			this.loader.resume();
		}
	}

	@action.bound
	async checkUserNameAvailability(username: string) {
		try {
			const response =
				await this.registrationService.checkUserNameAvailability(
					username
				);
			return response;
		} catch (err) {
			console.error(err);
			return false;
		}
	}

	@action.bound
	async checkIsEmailAvailable(email: string) {
		try {
			const response =
				await this.registrationService.checkIsEmailAvailable(email);
			return response;
		} catch (err) {
			console.error(err);
			return false;
		}
	}

	@action.bound
	async checkPasswordSimilarity(
		dataset: PasswordSimilarityRequestModel
	): Promise<boolean> {
		let response = false;
		try {
			response = await this.registrationService.checkPasswordSimilarity(
				dataset
			);
		} catch (err) {
			console.error(err);
		}

		return response;
	}

	@action.bound
	toggleStepSwitchFlag(value: boolean) {
		if (this.isSwitchingSteps != value) {
			this.isSwitchingSteps = value;
		}
	}

	@action.bound
	async fetchGamingLimits() {
		const response = await this.registrationService.fetchGamingLimits();
		return response;
	}

	@action.bound
	async fetchOccupationGroupLookupData() {
		const response = await this.lookupService.findOccupationGroups();
		return response.item;
	}

	@action.bound
	async fetchTitleLookupData() {
		const response = await this.lookupService.findTitles();
		return response.item;
	}

	@action.bound
	async fetchCountryLookupData() {
		const response = await this.lookupService.findCountries();
		return response.item;
	}

	@action.bound
	async fetchOccupationLookupData() {
		const response = await this.lookupService.findOccupations();
		return response.item;
	}

	@action.bound
	async fetchLanguageLookupData() {
		const getIsoFromSupportedLanguages = SUPPORTED_LANGUAGES.map((lang) => {
			return lang.iso;
		}).join(",");
		const response = await this.lookupService.findLanguages(
			getIsoFromSupportedLanguages
		);
		return response.item;
	}

	@action.bound
	async fetchCountryRegionsLookupData() {
		const response = await this.lookupService.findCountryRegions();
		return response.item;
	}

	@action.bound
	async fetchLimitDuration() {
		const response = await this.lookupService.findLimitDurations();

		runInAction(() => {
			this.limitDurationData = response.item.sort(
				(a, b) => a.units - b.units
			);
		});
	}

	@action.bound
	onDispose() {
		this.currentStep = 1;

		//lookups
		this.titleData = [];
		this.countryData = [];
		this.occupationData = [];
		this.languageData = [];
		this.countryRegionsData = [];
		this.occupationGroupData = [];
		this.gamingLimitsData = null;

		this.formData = null;
		//on user leave from Terminal Registration page
		this.removeMitIdProcessLocalStorageKeys();
	}

	/** @returns true if mitId login process in progress. */
	private mitIdUrlParamProcess = async (): Promise<boolean> => {
		const params = new URLSearchParams(window.location.search);

		//if no username and shop id params, continue with registration as normal?
		// if (
		// 	params.get(ETermRegParam.USERNAME) &&
		// 	params.get(ETermRegParam.SHOP_ID)
		// ) {
		// 	sessionStorage.setItem(
		// 		"QRTermData",
		// 		JSON.stringify({
		// 			username: params.get(ETermRegParam.USERNAME),
		// 			shopId: params.get(ETermRegParam.SHOP_ID),
		// 		})
		// 	);
		// 	this.initializeMitIdProcess();
		// 	return true;
		// }

		if (
			params.get("scope") &&
			params.get("code") &&
			params.get("state") &&
			params.get("iss")
		) {
			const paramsObject = {
				scope: params.get("scope"),
				code: params.get("code"),
				state: params.get("state"),
				iss: params.get("iss"),
			};
			if (sessionStorage.getItem("additionalUserInfo")) {
				//do not fetch user additional info, use session storage values for prefill
				runInAction(() => {
					//used observable for setting field values and disabling fields
					this.formData = JSON.parse(
						sessionStorage.getItem("additionalUserInfo") as string
					);
				});
			} else if (
				paramsObject.scope &&
				paramsObject.code &&
				paramsObject.state &&
				paramsObject.iss
			) {
				await this.getUserAdditionalInfo(
					paramsObject.scope,
					paramsObject.code,
					paramsObject.state,
					paramsObject.iss
				);
			}
		}

		// prettier-ignore
		if (
			params.get(ETermRegParam.ERROR) === ETermRegErrorParam.ACCESS_DENIED &&
			(
				params.get(ETermRegParam.ERROR_DESCRIPTION) === ETermRegErrorDescParam.USER_ABORT ||
				params.get(ETermRegParam.ERROR_DESCRIPTION) === ETermRegErrorDescParam.P_TO_B_ABORT
			)
		) {
			//user aborted on mitid ( cancel )
			this.removeMitIdProcessLocalStorageKeys();
			this.setMidIdErrorMessage(
					"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.MITID_USER_ABORTED"	
			);
			return true;
		}

		// prettier-ignore
		if (
			params.get(ETermRegParam.ERROR) === ETermRegErrorParam.ACCESS_DENIED &&
			(
				params.get(ETermRegParam.ERROR_DESCRIPTION) !== ETermRegErrorDescParam.USER_ABORT ||
				params.get(ETermRegParam.ERROR_DESCRIPTION) !== ETermRegErrorDescParam.P_TO_B_ABORT
			)
		) {
			//something went wrong with mit id
			/*
				possible errors:
				internal_error,
				no_ctx
			*/
			this.setMidIdErrorMessage(
					"specific:MEMBERSHIP.TERM_REGISTRATION.ERR_MSGS.MITID_GENERIC_ERROR"	
			);
			return true;
		}

		// if (
		// 	(!params.get(ETermRegParam.USERNAME) ||
		// 		!params.get(ETermRegParam.SHOP_ID)) &&
		// 	!sessionStorage.getItem("QRTermData") &&
		// 	!sessionStorage.getItem("additionalUserInfo")
		// ) {
		// 	//user got to page without username and shop id params and without session storage data
		// 	this.setMidIdErrorMessage("MEMBERSHIP.REGISTRATION.GENERIC_ERROR");
		// 	return true;
		// }

		//user first landed on registration page
		if (
			!sessionStorage.getItem("loginHash") &&
			!sessionStorage.getItem("additionalUserInfo")
		) {
			this.initializeMitIdProcess();
			return true;
		}
		if (isCapacitorPlatform()) {
			this.loader.resume();
		}

		//fetch registration lookups if get here
		return false;
	};

	removeMitIdProcessLocalStorageKeys() {
		//clear session storage keys after successful registration/errors
		sessionStorage.removeItem("loginHash");
		sessionStorage.removeItem("additionalUserInfo");
	}
}
