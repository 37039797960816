import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { Modal } from "@v2/components/shared";
import { useFixBody } from "@v2/hooks/shared";
import Button from "@components/buttons/Button";
import { useRootOfferStore } from "@hooks";
import { UserTypes, getUserFromLocalStorage } from "@utils";

export default observer(function ReuseBetConfirmationPopUp(props: {
	isOnOffer?: boolean;
}) {
	const store = useRootOfferStore().myBetsViewStore;

	if (store == null || !store.isReuseModalOpen) {
		return null;
	}

	return (
		<MyBetsReuseConfirmation
			isOpen={store.isReuseModalOpen}
			{...store.reuseModalData}
			onAccept={store.reuseBetSlip}
			onClose={store.closeReuseBetSlipModal}
			isOnOffer={props.isOnOffer}
		/>
	);
});

export const MyBetsReuseConfirmation = observer(
	function MyBetsReuseConfirmation(props: {
		id?: string;
		betSlipNumber?: string;
		isLive?: boolean;
		onClose: () => void;
		onAccept: (arg: { id?: string; isLive?: boolean }) => void;
		isOpen?: boolean;
		isOnOffer?: boolean;
		userId?: string;
	}) {
		const t = useTranslation().t;
		useFixBody(true, props.isOpen);
		const betSlipStore = useRootOfferStore().betSlipStore;
		const [isYesButtonDisabled, setIsYesButtonDisabled] =
			useState<boolean>(false);
		function onClick() {
			setIsYesButtonDisabled(true);
			if (props.isOnOffer) {
				if (!props.id) {
					console.error("No id of a betslip");
					return;
				}
				const user = getUserFromLocalStorage();
				if (!user) {
					console.log("no user");
					return;
				}
				const isPrimaryUser =
					props.userId == user.id
						? UserTypes.PRIMARY
						: UserTypes.SECONDARY;
				props.onClose();

				betSlipStore.getSlipAndMap(props.id, isPrimaryUser);
			} else {
				props.onAccept({
					id: props.id,
					isLive: props.isLive,
				});
			}
		}

		return (
			<Modal>
				<div className="popup">
					<div className="popup__card">
						<div className="popup__header">
							{t("MY_BETS.REUSE.TITLE")}
						</div>
						<div className="popup__body">
							<div className="u-mar--bottom--xtny">
								{t("MY_BETS.REUSE.MESSAGE", {
									bsn: props.betSlipNumber,
								})}
							</div>
						</div>
						<div className="popup__footer">
							<button
								className="popup__footer__btn btn btn--sml btn--secondary--inverted"
								onClick={props.onClose}
								type="button"
							>
								{t("COMMON.CANCEL")}
							</button>
							<Button
								className="popup__footer__btn btn btn--sml btn--primary"
								onClick={onClick}
								type="submit"
								btnText={t("COMMON.YES")}
								isDisabled={isYesButtonDisabled}
							></Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
);
