import { BetOffer } from "@administration/pages/my-bets/model";
import { MyBetsViewStore } from "@offer/stores/components/my-bets";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import React from "react";

export default observer(function BetOfferEventTime(props: {
	bet: BetOffer;
	myBetsViewStore: MyBetsViewStore;
}) {
	function checkIfIsMulti() {
		if (props.bet.isMulti) {
			return { time: "", cssClass: "" };
		}

		if (
			props.myBetsViewStore?.activeEventsStore.eventsMap.has(
				props.bet.eventId
			)
		) {
			if (
				props.myBetsViewStore.activeEventsStore.eventsMap.get(
					props.bet.eventId
				)?.matchTime?.displayTime
			) {
				return {
					time: props.myBetsViewStore.activeEventsStore.eventsMap.get(
						props.bet.eventId
					)?.matchTime?.displayTime,
					cssClass: " u-color--ui--danger",
				};
			}

			return {
				time: DateTime.fromISO(
					props.bet.sportData.eventStartTime
				).toFormat("dd.MM. HH:mm"),
				cssClass: " u-color--text--primary",
			};
		}
		return {
			time: DateTime.fromISO(props.bet.sportData.eventStartTime).toFormat(
				"dd.MM. HH:mm"
			),
			cssClass: " u-color--text--primary",
		};
	}

	const data = checkIfIsMulti();
	return (
		<div className="widget--bets__time" title={"Match time"}>
			<div
				className={
					"u-mar--bottom--micro u-type--xxsml" + data?.cssClass
				}
			>
				{data?.time}
			</div>
		</div>
	);
});
