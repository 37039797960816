import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useRootAppStore, useRootOfferStore } from "@hooks";
import { useFixBody } from "@v2/hooks";
import MostPlayedTicketsCard from "./MostPlayedTicketsCard";
import { Button } from "@components/buttons";
import { useLocation } from "react-router-dom";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { MostPlayTicketActionsTypeEnum } from "@data-types/analytics/QuickPlayInteractionTypeEnum";
import { LoaderSpinner } from "@v2/components/shared";
import { ChangePageButton } from "@v2/state/quick-play-store/MostPlayedTicketsStore";

export default observer(function MostPlayTicketsPopup() {
	const { t } = useTranslation();

	const rootAppStore = useRootAppStore();
	const rootOfferStore = useRootOfferStore();

	const popupElement = useRef<null | HTMLDivElement>(null);
	const location = useLocation();

	useFixBody(
		true,
		rootAppStore.quickPlayStore.mostPlayedTicketsStore.isPopupDisplayed
	);

	if (
		!location.pathname.includes("home") &&
		!location.pathname.includes("most-played-tickets")
	) {
		rootAppStore.quickPlayStore.mostPlayedTicketsStore.deactivatePopup();
	}
	useEffect(() => {
		popupElement.current?.scrollIntoView({ block: "start" });
	}, [rootAppStore.quickPlayStore.mostPlayedTicketsStore.popupBetSlip?.betSlipId]);

	const isPrevAndNextDisabled = rootAppStore.quickPlayStore
		.mostPlayedTicketsStore.filteredBetSlips
		? rootAppStore.quickPlayStore.mostPlayedTicketsStore.filteredBetSlips
				.length == 1
		: rootAppStore.quickPlayStore.mostPlayedTicketsStore.betSlips.length ==
		  1;

	if (
		rootAppStore.quickPlayStore.loaderStore.isLoadingProcess &&
		!rootAppStore.quickPlayStore.mostPlayedTicketsStore.blockMainLoader
	)
		return <LoaderSpinner isLoading />;

	if (!rootAppStore.quickPlayStore.mostPlayedTicketsStore.popupBetSlip)
		return null;

	return (
		<div className="popup popup--full">
			<div className="popup__card popup--most__card">
				<div className="popup__header">
					<span>
						{t("QUICK_PLAY.MOST_PLAYED_TICKETS.TICKET")} #
						{rootAppStore.quickPlayStore.mostPlayedTicketsStore
							.indexOfBetslip + 1}
					</span>
					{/* <Button
						btnType="button"
						className="btn--square btn--square--sml btn--secondary--inverted"
						iconOnly={true}
						iconClassName="u-icon u-icon--sml u-icon--close"
						onClick={rootAppStore.quickPlayStore.mostPlayedTicketsStore.deactivatePopup}
					/> */}
					<button
						type="button"
						className="btn btn--icon btn--square btn--square--sml btn--secondary--inverted"
						onClick={
							rootAppStore.quickPlayStore.mostPlayedTicketsStore
								.deactivatePopup
						}
					>
						<i className="u-icon u-icon--sml u-icon--close" />
					</button>
				</div>
				<div className="popup__body u-padd--reset">
					<MostPlayedTicketsCard
						key={
							rootAppStore.quickPlayStore.mostPlayedTicketsStore
								.popupBetSlip.betSlipId
						}
						betSlip={
							rootAppStore.quickPlayStore.mostPlayedTicketsStore
								.popupBetSlip
						}
						isPopup
						displayAdditionalInfo
					/>
				</div>
				<div className="popup__footer">
					<div className="popup__footer__inner">
						<Button
							isDisabled={
								rootAppStore.quickPlayStore.loaderStore
									.isLoadingProcess || isPrevAndNextDisabled
							}
							isLoading={
								rootAppStore.quickPlayStore.loaderStore
									.isLoadingProcess &&
								rootAppStore.quickPlayStore
									.mostPlayedTicketsStore.showLoader ==
									ChangePageButton.PREVIOUS
							}
							isMostPlayed
							switchIcon
							iconLeft
							iconClassName="u-icon--sml u-icon--arrow--left"
							className="btn--base btn--secondary--inverted"
							onClick={() => {
								rootAppStore.quickPlayStore.mostPlayedTicketsStore.changePage(
									ChangePageButton.PREVIOUS
								);
							}}
							type="button"
							btnText={t("COMMON.PREVIOUS_BUTTON")}
						/>
						<Button
							isDisabled={
								rootAppStore.quickPlayStore.loaderStore
									.isLoadingProcess || isPrevAndNextDisabled
							}
							isLoading={
								rootAppStore.quickPlayStore.loaderStore
									.isLoadingProcess &&
								rootAppStore.quickPlayStore
									.mostPlayedTicketsStore.showLoader ==
									ChangePageButton.NEXT
							}
							isMostPlayed
							switchIcon
							iconRight
							iconClassName="u-icon--sml u-icon--arrow--right"
							className="btn--base btn--secondary--inverted"
							onClick={() =>
								rootAppStore.quickPlayStore.mostPlayedTicketsStore.changePage(
									ChangePageButton.NEXT
								)
							}
							type="button"
							btnText={t("COMMON.NEXT_BUTTON")}
						/>
					</div>
					<Button
						isDisabled={
							rootAppStore.quickPlayStore.loaderStore
								.isLoadingProcess
						}
						className="btn btn--base btn--primary"
						onClick={() => {
							if (
								!rootAppStore.quickPlayStore
									.mostPlayedTicketsStore.popupBetSlip
							) {
								return;
							}
							AnalyticsService.logQuickPlayInteraction({
								category:
									MostPlayTicketActionsTypeEnum.TicketUse,
							});
							rootOfferStore.betSlipStore.sharedSlipAndMap({
								...rootAppStore.quickPlayStore
									.mostPlayedTicketsStore.popupBetSlip,
							});
							rootAppStore.quickPlayStore.mostPlayedTicketsStore.deactivatePopup();
						}}
						type="button"
						iconClassName="u-icon u-icon--sml u-icon--refresh u-color--core--snow"
						btnText={t("MY_BETS.SHARE.ADD_TO_SLIP")}
					></Button>
				</div>
			</div>
		</div>
	);
});
