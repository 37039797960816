import React from "react";
import classNames from "classnames";
import { useRegistrationStore } from "@v2/context";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react";
import { useRootAppStore } from "@hooks";

export default observer(function RegistrationTabs() {
	const { trigger } = useFormContext();

	const { isIpRestricted } = useRootAppStore();

	const { currentStep, setCurrentStep, toggleStepSwitchFlag } =
		useRegistrationStore();

	const stepOneClasses = classNames(
		"card--onboarding__no counter counter--rounded counter--rounded--base counter--rounded counter--nero"
	);

	const stepTwoClasses = classNames(
		"card--onboarding__no counter counter--rounded counter--rounded--base counter--rounded",
		{
			"counter--medium": currentStep === 1,
			"counter--nero": currentStep === 2,
		}
	);

	return (
		<div className="card--onboarding__steps">
			<div className={stepOneClasses} onClick={() => setCurrentStep(1)}>
				{currentStep === 1 ? (
					<>1</>
				) : (
					<i className="u-icon u-icon--xsml u-icon--check u-color--core--snow"></i>
				)}
			</div>
			<button
				type="button"
				className={stepTwoClasses}
				disabled={currentStep === 2 || isIpRestricted}
				onClick={async () => {
					toggleStepSwitchFlag(true);
					const isValid = await trigger();

					if (isValid) {
						setCurrentStep(2, true);
					}
					toggleStepSwitchFlag(false);
				}}
			>
				2
			</button>
		</div>
	);
});
