import React from "react";
import { useTranslation } from "react-i18next";

export function EmptyBettingTypes() {
	const t = useTranslation().t;

	return (
		<div className="u-padd--xsml u-padd--top--reset">
			<div className="card--empty u-mar--bottom--reset">
				<div className="card--empty__content">
					<i className="card--empty__icon u-icon u-icon u-icon--huge u-icon--empty u-icon--empty--search" />
					<div className="card--empty__message u-mar--bottom--reset">
						{t("BETTING_TYPE.NO_OFFER")}
					</div>
				</div>
			</div>
		</div>
	);
}
