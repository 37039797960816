import usePasswordConfirmation from "@v2/hooks/shared/usePasswordConfirmation";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { setTabTitle } from "@utils";
import { AccountLockService } from "@services/account-settings/AccountLockService";
import { AccountClosureUpdateRequestDataModel } from "@data-types/account-settings/AccountLockDto";
import {
	lookupsInitialData,
	lookupsInitialDataOasisEnabled,
	oasisLockLookupsInitialData,
} from "@v2/helpers/account-settings/account-lock/utils";
import { SelfExclusionInfoDto } from "@api-types/locks/SelfExclusionInfoDto";
import { useUser } from "@common/hooks";

export default function useAccountLock() {
	const { t } = useTranslation();

	const {
		formState: { dirtyFields },
		reset,
	} = useFormContext();

	const onInitialize = () => {
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_ACC_LOCK"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
		reset();
	};

	const { setShowPasswordConfirmation } = usePasswordConfirmation();

	const onCancel = () => {
		setShowPasswordConfirmation(false);
	};

	const onSubmitData = () => {
		if (Object.keys(dirtyFields).length != 0) {
			setShowPasswordConfirmation(true);
		}
	};

	function getAccountClosureViewData() {
		if (App.state.rootStore.userAuthStore.user?.isOasisEnabled) {
			return AccountLockService.getAccountClosureViewData(
				lookupsInitialDataOasisEnabled
			);
		}
		return AccountLockService.getAccountClosureViewData(lookupsInitialData);
	}

	function createAccountLock(data: AccountClosureUpdateRequestDataModel) {
		return AccountLockService.createAccountLock(data);
	}

	function getOasisBlockData() {
		return AccountLockService.getOasisBlockData(
			oasisLockLookupsInitialData
		);
	}

	function createOasisBlock(data: SelfExclusionInfoDto) {
		return AccountLockService.createOasisBlock(data);
	}

	return {
		onInitialize,
		onCancel,
		onSubmitData,
		getAccountClosureViewData,
		createAccountLock,
		getOasisBlockData,
		createOasisBlock,
	};
}
