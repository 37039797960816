import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { setTabTitle } from "@utils";
import { Loader } from "@v2/components/desktop/shared/loader";
import { RegistrationStore } from "@v2/state/registration";
import {
	useRegistrationStore,
	RegistrationStoreContext,
} from "@v2/context/RegistrationStoreContext";
import {
	RegistrationError,
	RegistrationSuccess,
	RegistrationTabs,
} from "@components-desktop/membership/registration";
import { RegistrationAccountDetails, RegistrationContactDetails } from ".";
import { FormDataType } from "@data-types/membership/registration";
import {
	OnBoardingSlider,
	OnBoardingFooter,
} from "@components-desktop/membership/shared";
import GoogleRecaptchaWrapper from "@v2/components/shared/GoogleRecaptchaWrapper";
import RestrictedSection from "@components-desktop/membership/shared/RestrictedSection";
import { useRootAppStore } from "@hooks";

export const RegistrationPage = observer(function RegistrationPage() {
	const registrationStore = useMemo(() => new RegistrationStore(), []);

	const { t } = useTranslation();

	const { isIpRestricted } = useRootAppStore();

	const { onInitialize, onDispose } = registrationStore;

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.REGISTRATION.REGISTRATION_TITLE"));

		onInitialize();

		return () => onDispose();
	}, []);

	return (
		<RegistrationStoreContext.Provider value={registrationStore}>
			<RegistrationPageContent />
		</RegistrationStoreContext.Provider>
	);
});

const RegistrationPageContent = observer(function RegistrationPageContent() {
	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			cultureInfoName: "en-US",
		},
	});
	const { handleSubmit } = methods;

	const { t } = useTranslation();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const { isIpRestricted } = useRootAppStore();

	const { isRegistrationSuccessful, isLoading, currentStep, register } =
		useRegistrationStore();

	const params = new URLSearchParams(window.location.search);
	const affiliateCode = params.get("afc");

	if (isRegistrationSuccessful === true) {
		return <RegistrationSuccess />;
	}

	if (isRegistrationSuccessful === false) {
		return <RegistrationError />;
	}

	return (
		<main role="main" className="at-registration l--main l--main--grow">
			<Loader isLoading={isLoading} />

			<section className="l--content l--content--full">
				<div className="l--content__main u-padd--reset">
					<div className="container container--med">
						<div className="card--onboarding">
							<OnBoardingSlider />
							<FormProvider {...methods}>
								<form
									className="at-regDetails card--onboarding__form"
									onSubmit={handleSubmit(
										(formData: FormDataType) =>
											register(
												formData,
												executeRecaptcha!,
												affiliateCode
											)
									)}
								>
									{isIpRestricted ? (
										<RestrictedSection />
									) : null}
									<div className="card--onboarding__header">
										<div className="card--onboarding__title">
											{currentStep === 1
												? t(
														"MEMBERSHIP.REGISTRATION.ACCOUNT_DETAILS_TAB"
												  )
												: t(
														"MEMBERSHIP.REGISTRATION.CONTACT_DETAILS_TAB"
												  )}
										</div>
										<RegistrationTabs />
									</div>

									<Steps />
								</form>
							</FormProvider>
						</div>

						<OnBoardingFooter isRegistration />
					</div>
				</div>
			</section>
		</main>
	);
});

const Steps = observer(function Steps() {
	const { currentStep } = useRegistrationStore();

	switch (currentStep) {
		case 1:
			return <RegistrationAccountDetails />;

		case 2:
			return <RegistrationContactDetails />;

		default:
			return null;
	}
});
