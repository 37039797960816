import React from "react";

class OutsideClickDetector extends React.Component {
	constructor(props) {
		super(props);

		this.wrapperRef = React.createRef();
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.rootAppNode = document.getElementById("app");
	}

	componentDidMount() {
		this.rootAppNode.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		this.rootAppNode.removeEventListener(
			"mousedown",
			this.handleClickOutside
		);
	}

	handleClickOutside(event) {
		if (
			this.wrapperRef &&
			!this.wrapperRef.current.contains(event.target) &&
			(event.target !== "navItemMenu" ||
				event.target.parentElement.id !== "navItemMenu")
		) {
			if (this.props.onOutsideClick) {
				this.props.onOutsideClick(event);
			}
		}
	}

	render() {
		return (
			<div ref={this.wrapperRef} className={this.props.wrapperClasses} style={this.props.style}>
				{this.props.children}
			</div>
		);
	}
}

// OutsideClickDetector.propTypes = {
// 	onOutsideClick: PropTypes.func,
// 	wrapperClasses: PropTypes.string,
// 	children: PropTypes.element.isRequired,
// };

export default OutsideClickDetector;
