export const UserAccountType = {
	All: "all",
	BonusAccount: "bonus-account",
	CasinoAccount: "casino",
	LiveCasinoAccount: "casino-live",
	VirtualGameAccount: "virtual-game",
	SportBettingAccountOnline: "sport-betting-account-online",
	SportBettingAccountShop: "sport-betting-account-shop",
	SportBettingAccountShopOnline: "sport-betting-account-shop-online",
	CashToDigitalAccount: "cash-to-digital",
	GigAccount: "gig-account",
};
