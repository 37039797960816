import { httpClient, getApplicationHeaders } from "@utils";

export class TvInfoService {
	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getTvInfo(eventId: string) {
		const url = `${WEBPACK_BASE_ADDRESS}platform/events/tv-information`;
		const query = new URLSearchParams();
		query.set("rpp", "1");
		query.set("page", "1");
		query.set("translate", "true");
		query.set("ids", eventId);
		const result = await httpClient.get(
			`${url}?${query}`,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);

		if (result && result.item != null && result.item[0] != null) {
			return result.item[0].info;
		}

		return "";
	}
}
