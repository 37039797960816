import React, { FC } from "react";

import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";

import "katex/dist/katex.min.css";

type MarkdownProps = {
	/**
	 * markdown to parse
	 */
	content?: string;
	/**
	 * creates a wrapping `<div>` with className
	 */
	className?: string;
};

const Markdown: FC<MarkdownProps> = ({ content = "", className = "" }) => {
	return (
		<ReactMarkdown
			children={content}
			className={className}
			remarkPlugins={[remarkMath, remarkGfm]}
			rehypePlugins={[rehypeKatex, rehypeRaw]}
		/>
	);
};

export default Markdown;
