import { AccessChannelsModel } from "./AccessChannelsModel";
import { GamingHistoryFilterParamItem } from "./GamingHistoryFilterParamItem";

export class CoreGamingHistoryFilter {
	accessChannels: AccessChannelsModel;
	parameters: GamingHistoryFilterParamItem[];
	language: string;

	constructor(
		accessChannels: AccessChannelsModel,
		parameters: GamingHistoryFilterParamItem[],
		language: string
	) {
		this.accessChannels = accessChannels;
		this.parameters = parameters;
		this.language = language;
	}
}
