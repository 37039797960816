import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useAppContext } from "@v2/hooks/shared";

export default observer(function Logo() {
	const { culture } = useAppContext();
	const isLightTheme = App.state.rootStore.themeStore.isLightTheme;
	return (
		<Link to={`/${culture}/home`} className="header__logo__link">
			{isLightTheme ? (
				<>
					<img
						className="header__logo__img header__default"
						src="/assets/images/logo-dark.svg"
						alt=""
					/>
					<div
						className="header__logo__img header__default header__logo__img--sml"
					/>

                    <img
						className="header__logo__img header__vibrant"
						src="/assets/images/logo-vibrant.svg"
						alt=""
					/>
					<div
						className="header__logo__img header__vibrant header__vibrant--sml"
					/>
				</>
			) : (
				<>
					<img
						className="header__logo__img header__default"
						src="/assets/images/logo.svg"
						alt=""
					/>
					<div
						className="header__logo__img header__default header__logo__img--sml"
					/>

                    <img
						className="header__logo__img header__vibrant"
						src="/assets/images/logo-vibrant.svg"
						alt=""
					/>
					<div
						className="header__logo__img header__vibrant header__vibrant--sml"
					/>
				</>
			)}
		</Link>
	);
});
