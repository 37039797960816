import React from "react";

function SkeletonLoader() {
	return (
		<div className="widget__body">
			<div className="loader--skeleton loader--skeleton--content loader--skeleton--mybets">
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__btn loader--skeleton__btn--status" />
				</div>
				<div className="loader--skeleton__title loader--skeleton__title--med u-mar--y--tny" />
				<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--y--tny" />
				<div className="loader--skeleton--mybets__item u-mar--y--tny">
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
				</div>
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__title loader--skeleton__title--xsml" />
				</div>
			</div>
			<div className="loader--skeleton loader--skeleton--content loader--skeleton--mybets">
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__btn loader--skeleton__btn--status" />
				</div>
				<div className="loader--skeleton--mybets__item u-mar--y--tny">
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
				</div>
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__title loader--skeleton__title--xsml" />
				</div>
			</div>
			<div className="loader--skeleton loader--skeleton--content loader--skeleton--mybets">
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__btn loader--skeleton__btn--status" />
				</div>
				<div className="loader--skeleton--mybets__item u-mar--y--tny">
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
				</div>
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__title loader--skeleton__title--xsml" />
				</div>
			</div>
			<div className="loader--skeleton loader--skeleton--content loader--skeleton--mybets">
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__btn loader--skeleton__btn--status" />
				</div>
				<div className="loader--skeleton--mybets__item u-mar--y--tny">
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
				</div>
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__title loader--skeleton__title--xsml" />
				</div>
			</div>
			<div className="loader--skeleton loader--skeleton--content loader--skeleton--mybets">
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__btn loader--skeleton__btn--status" />
				</div>
				<div className="loader--skeleton--mybets__item u-mar--y--tny">
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
					<div className="loader--skeleton--mybets__col">
						<div className="loader--skeleton__title loader--skeleton__title--sml u-mar--bottom--nano" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
				</div>
				<div className="loader--skeleton--mybets__item">
					<div className="loader--skeleton__title loader--skeleton__title--xsml" />
				</div>
			</div>
		</div>
	);
}

export default SkeletonLoader;
