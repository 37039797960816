import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	FormSelectField,
	PasswordInput,
	TextareaField,
	CheckboxField,
} from "../../shared/inputs";
import FormButton from "@components/buttons/FormButton";
import { AccountLockTitleDisplay } from ".";
import { mapArray } from "@v2/helpers/account-settings/account-lock/utils";
import { isNullOrWhitespace } from "@v2/helpers/utility";
import { useAccountClosure } from "@v2/hooks/account-settings/account-lock";
import { setTabTitle } from "@utils";

export default function AccountClosureView() {
	const { onInitialize, onSuccess, lookups } = useAccountClosure();

	useEffect(() => {
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_ACC_LOCK"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
		onInitialize();
	}, []);
	const { handleSubmit } = useFormContext();
	const { t } = useTranslation();

	return (
		<form className="form" method="post" onSubmit={handleSubmit(onSuccess)}>
			<div>
				<div className="card--primary__body">
					<AccountLockTitleDisplay />

					<div className="row">
						<FormSelectField
							className="col col-sml-12 form__field"
							name="lockDuration"
							placeholder={t(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_DURATION_PLACEHOLDER"
							)}
							label={t(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_DURATION_LABEL"
							)}
							dropdownData={mapArray(lookups.durationTypes)}
							required
						/>

						<FormSelectField
							className="col col-sml-12 form__field"
							name="lockReason"
							placeholder={t(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_REASON_PLACEHOLDER"
							)}
							label={t(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_REASON_LABEL"
							)}
							dropdownData={mapArray(lookups.reasonTypes)}
							required
						/>

						<TextareaField
							label="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.EXPLANATION_LABEL"
							name="explanation"
							placeholder={t(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.EXPLANATION_PLACEHOLDER"
							)}
							rows="2"
							cols="20"
						/>

						<CheckboxField
							checkboxClassname="col col-sml-12 form__field u-mar--top--xsml"
							id="isPromoApproved"
							className="input--check"
							name="isPromoApproved"
							label={t(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.NEWSLETTER_LABEL"
							)}
						/>

						<PasswordInput
							name="password"
							className="col col-sml-12 form__field"
							label={t(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.PASSWORD_CONF_LABEL"
							)}
							placeholder={t(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.PASSWORD_CONF_PLACEHOLDER"
							)}
							validate={{
								isNullOrWhitespace: (value: string) =>
									isNullOrWhitespace(value) !== true ||
									"COMMON.REQUIRED_FIELD",
							}}
							required
						/>
					</div>
				</div>
				<div className="card--primary__footer">
					<FormButton
						className="card--primary__footer__btn btn btn--lrg btn--primary"
						btnText="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ACCOUNT_CLOSURE_SAVE_BUTTON"
					/>
				</div>
			</div>
		</form>
	);
}
