import { setTabTitle } from "@utils";
import { Button } from "@v2/components/desktop/shared/buttons";
import { FormInputFieldCurrency } from "@v2/components/mobile/shared/inputs";
import { useAutomaticWithdrawal } from "@v2/hooks/account-settings/automatic-withdrawal";
import React, { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AutomaticWithdrawalPage = function AutomaticWithdrawalPage() {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			payoutThreshold: "",
			payoutAmount: "",
		},
	});

	return (
		<div className="l--content__main">
			<div className="container">
				<div className="at-automaticWithdrawalCard card--primary">
					<div className="card--primary__header">
						<div className="card--primary__title title--primary">
							{t<string>(
								`USER.ACCOUNT_SETTINGS.AUTOMATIC_WITHDRAWAL.TITLE`
							)}
						</div>
					</div>
					<FormProvider {...methods}>
						<AutomaticWithdrawalForm />
					</FormProvider>
				</div>
			</div>
		</div>
	);
};

const AutomaticWithdrawalForm = () => {
	const { t } = useTranslation();

	const {
		handleSubmit,
		formState: { isDirty, isValid },
	} = useFormContext();

	const {
		validateMinAmount,
		validateThreshold,
		handleDeactivate,
		onSubmit,
		withdrawalData,
		onInitialize,
		showPopup,
		isVisible,
		hidePopup,
	} = useAutomaticWithdrawal();

	useEffect(() => {
		setTabTitle(t("HEADER.AUTOMATIC_WITHDRAWAL"));
		onInitialize();
	}, []);

	if (withdrawalData == null) {
		return null;
	}

	return (
		<form
			id="automaticWithdrawalForm"
			className="form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="at-automaticWithdrawalCard card--primary__body">
				<div className="message message message--base message--note u-mar--bottom--big">
					<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
					<div className="message__content">
						<div className="message__text u-mar--bottom--xsml">
							{t<string>(
								"USER.ACCOUNT_SETTINGS.AUTOMATIC_WITHDRAWAL.INFO_MESSAGE"
							)}
						</div>
						<div className="message__text u-mar--bottom--xsml">
							{t<string>(
								"USER.ACCOUNT_SETTINGS.AUTOMATIC_WITHDRAWAL.EXAMPLE"
							)}
						</div>
						<div className="message__text">
							<div className="row">
								<div className="col col-sml-6">
									<span className="u-mar--right--micro u-type--wgt--medium">
										{t<string>("WITHDRAWAL.THRESHOLD")}:
									</span>
									<span className="u-mar--right--micro">
										min.{" "}
										{
											withdrawalData.autoPayoutSetting
												.minThreshold
										}{" "}
										{withdrawalData.currencyDisplayCode},
									</span>
									<span>
										max.{" "}
										{
											withdrawalData.autoPayoutSetting
												.maxThreshold
										}{" "}
										{withdrawalData.currencyDisplayCode}
									</span>
								</div>
								<div className="col col-sml-6">
									<span className="u-mar--right--micro u-type--wgt--medium">
										Min.amount:
									</span>
									<span className="u-mar--right--micro">
										min.{" "}
										{
											withdrawalData.autoPayoutSetting
												.minPayout
										}{" "}
										{withdrawalData.currencyDisplayCode},
									</span>
									<span>
										max.{" "}
										{
											withdrawalData.autoPayoutSetting
												.maxPayout
										}{" "}
										{withdrawalData.currencyDisplayCode}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Popup
					isVisible={isVisible}
					hidePopup={hidePopup}
					handleDeactivate={handleDeactivate}
				/>
				<FormInputFieldCurrency
					label="WITHDRAWAL.THRESHOLD"
					name="payoutThreshold"
					className="form__group"
					type="number"
					placeholder="WITHDRAWAL.THRESHOLD"
					validate={validateThreshold}
				/>
				<FormInputFieldCurrency
					label="WITHDRAWAL.MIN_AMOUNT"
					name="payoutAmount"
					className="form__group"
					type="number"
					placeholder="WITHDRAWAL.MIN_AMOUNT"
					validate={validateMinAmount}
				/>
			</div>
			<div className="card--primary__footer">
				<div className="card--primary__footer__inner">
					{withdrawalData.autoPayoutSetting.payoutAmount === 0 &&
						withdrawalData.autoPayoutSetting.payoutThreshold ===
							0 && (
							<Button
								btnText="COMMON.SAVE_BUTTON"
								className="card--primary__footer__btn btn--lrg btn--primary"
								btnType="submit"
								disabled={!isDirty || !isValid}
							/>
						)}
					{withdrawalData.autoPayoutSetting.payoutAmount !== 0 &&
						withdrawalData.autoPayoutSetting.payoutThreshold !==
							0 && (
							<Button
								btnText="COMMON.UPDATE_BUTTON"
								className="card--primary__footer__btn btn--lrg btn--primary"
								btnType="submit"
								disabled={!isDirty || !isValid}
							/>
						)}
					{withdrawalData.autoPayoutSetting.payoutAmount !== 0 &&
						withdrawalData.autoPayoutSetting.payoutThreshold !==
							0 && (
							<Button
								btnText="COMMON.DEACTIVATE_BUTTON"
								className="card--primary__footer__btn btn--lrg btn--secondary--inverted u-color--ui--danger"
								btnType="button"
								onClick={showPopup}
							/>
						)}
				</div>
			</div>
		</form>
	);
};
export default AutomaticWithdrawalPage;

const Popup = ({
	isVisible,
	hidePopup,
	handleDeactivate,
}: {
	isVisible: boolean;
	hidePopup: () => void;
	handleDeactivate: () => void;
}) => {
	const { t } = useTranslation();
	if (isVisible == false) {
		return null;
	}

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					{t(
						"USER.ACCOUNT_SETTINGS.AUTOMATIC_WITHDRAWAL.CONFIRMATION_LABEL"
					)}
				</div>
				<div className="popup__body">
					{t(
						"USER.ACCOUNT_SETTINGS.AUTOMATIC_WITHDRAWAL.CANCEL_CONFIRM"
					)}
				</div>
				<div className="popup__footer">
					<button
						className="popup__footer__btn btn btn--sml btn--secondary--inverted"
						type="button"
						onClick={hidePopup}
					>
						{t("COMMON.CANCEL")}
					</button>
					<button
						className="popup__footer__btn btn btn--sml btn--primary"
						type="button"
						onClick={handleDeactivate}
					>
						{t("COMMON.CONFIRM")}
					</button>
				</div>
			</div>
		</div>
	);
};
