import { action } from "mobx";
import { BasePopupStore } from ".";
import { isUserAuthenticated } from "@utils";

class RealityCheckStore extends BasePopupStore {
    constructor(rootStore: typeof App.state.rootStore) {
        super(rootStore);

        document.addEventListener("click", this.onClick);
    }

    onInitialize() {
        const currentDate = new Date();
        const realityCheckDurationLeft = App.state.rootStore.userAuthStore.realityCheckTime || new Date().getTime();
        const expiresIn = realityCheckDurationLeft - currentDate.getTime();
        const isRealityCheckExpired = currentDate.getTime() >= realityCheckDurationLeft;

        if (isUserAuthenticated() && !isRealityCheckExpired && !this.rootStore.automaticLogoutStore.isActive) {
            this.startTimer(expiresIn);
        }
    }

    @action.bound
    startTimer(expiresIn: number) {
        if (!WEBPACK_IS_REALITY_CHECK_ENABLED || !isUserAuthenticated()) {
            return;
        }
        this.clearTimer();

        const shouldDisplayRealityCheck = () => {
            return isUserAuthenticated() && !App.state.rootStore.automaticLogoutStore.isActive;
        };

        this.timerId = setTimeout(() => {
            if (shouldDisplayRealityCheck()) {
                this.activatePopup();
            }
        }, expiresIn);
    }

    onClick = () => {
        const realityCheckDurationLeft = App.state.rootStore.userAuthStore.realityCheckTime || new Date().getTime();
        const currentDate = new Date();
        const expiresIn = realityCheckDurationLeft - currentDate.getTime();

        const isRealityCheckExpired = currentDate.getTime() >= realityCheckDurationLeft;

        if (WEBPACK_IS_REALITY_CHECK_ENABLED && isUserAuthenticated() && isRealityCheckExpired && !this.isActive) {
            this.activatePopup();
        }

        if (
            WEBPACK_IS_REALITY_CHECK_ENABLED &&
            isUserAuthenticated() &&
            !this.isActive &&
            !isRealityCheckExpired &&
            !App.state.rootStore.automaticLogoutStore.isActive
        ) {
            this.startTimer(expiresIn);
        } else {
            if (!isUserAuthenticated()) {
                this.clearTimer();
            }
        }
    };
}

export default RealityCheckStore;
