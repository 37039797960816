import React, { useState } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useAppContext, useAppActionsContext } from "@v2/hooks/shared";
import  OutsideClickDetector from "@v2/components/shared/utility/OutsideClickDetector";

const availableLanguages = [
	// { value: 'es', name: 'Español' },
	// { value: 'nl', name: 'Dutch' },
	{ value: "en", name: "English" },
	{ value: "de", name: "Deutsch" },
	{ value: "fr", name: "Français" },
	{ value: "hr", name: "Hrvatski" },
	{ value: "it", name: "Italiano" },
	{ value: "tr", name: "Türkçe" },
	{ value: "sq", name: "Shqip" },
	{ value: "sv", name: "Svenska" },
	{ value: "da", name: "Dansk" },
];

interface LanguageProps {
	rounded?: boolean;
	hasOnboardingStyling?: boolean;
}

export default function Language(props: LanguageProps) {
	const { culture } = useAppContext();
	const { setCulture } = useAppActionsContext();

	const [isOpen, setIsOpen] = useState<boolean>();
	const { pathname, search } = useLocation();

	// current language
	const currentLanguage = availableLanguages.find((x) => x.value === culture);

	// filter available languages and remove current language
	const filteredLanguages = availableLanguages.filter(
		(x) => x.value !== culture
	);

	const onCultureChange = (newCulture: string): void => {
		// i18n.changeLanguage(newCulture);
		// close menu
		setIsOpen(false);

		// navigate
		let segments: string[] | string = pathname.split("/");
		segments[1] = newCulture;
		segments = segments.join("/");
		App.state.history.replace(
			segments + search,
			App.state.history.location?.state
		);
		window.location.reload();
		setCulture();
	};

	if (availableLanguages == null) {
		return null;
	}

	const dropBtnClasses = classNames(
		"dropdown__toggle btn",
		{
			"btn--reset": props.rounded,
			"btn--sml btn--neutral--inverted": !props.rounded && !props.hasOnboardingStyling,
            "btn--sml btn--secondary--inverted": !props.rounded && props.hasOnboardingStyling,
			"btn--icon btn--icon--left": !props.rounded || props.hasOnboardingStyling,
			"u-padd--x--xtny": !props.rounded && !props.hasOnboardingStyling,
			"u-type--xsml": props.hasOnboardingStyling
		}
	);

	const dropFlagClasses = classNames(
		[`u-icon u-icon--big u-icon--lang u-icon--lang--${currentLanguage?.value} u-icon--lang--rounded`]
	);

	const dropLabelClasses = classNames(
		"btn__label u-type--left",
		{
			"dropdown__toggle__caption u-color--core--snow": !props.rounded && !props.hasOnboardingStyling
		}
	);

	const dropCaretClasses = classNames(
		"u-icon u-icon--tny u-icon--caret--down",
		{
			"u-color--core--snow": !props.rounded && !props.hasOnboardingStyling
		}
	);

	return (
		<div className="header__preferences">
			<OutsideClickDetector
				onOutsideClick={() => {
					setIsOpen(false);
				}}
				wrapperClasses="dropdown dropdown--language"
			>
				<button
					type="button"
					onClick={() => setIsOpen((value) => !value)}
					className={dropBtnClasses}
				>
					{!props.hasOnboardingStyling && (
						<i className={dropFlagClasses} />
					)}

					{!props.rounded && (
						<>
							<span className={dropLabelClasses}>{currentLanguage?.name}</span>
							<i className={dropCaretClasses} />
						</>
					)}
				</button>

				<ul className={classNames(
					"dropdown__menu dropdown--language__menu",
					{
						"is-expanded": isOpen,
						"is-collapsed": !isOpen,
					}
				)}>
					{/* TODO: add styling */}
					{filteredLanguages.map((lang) => {
						return (
							<li
								key={lang.value}
								className="dropdown__item"
								onClick={() => onCultureChange(lang.value)}
							>
								<button
									type="button"
									className="dropdown__item__btn btn btn--sml btn--block btn--ghost btn--icon"
								>
									<i className={`u-icon u-icon--big u-icon--lang u-icon--lang--${lang.value}`} />
									<span>{lang.name}</span>
								</button>
							</li>
						);
					})}
				</ul>
			</OutsideClickDetector>
		</div>
	);
}