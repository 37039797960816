import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { BetSlipBetType } from "@gp/slip";
import { useTranslation } from "react-i18next";
import BetSlipStore from "@v2/state/bet-slip/BetSlipStore";

export default observer(function BetSlipBetTypeSelector(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	return (
		<ul className="tabs--slip">
			<li className="tabs--slip__item">
				<button
					onClick={() =>
						props.store.changeBetSlipBetType(BetSlipBetType.Normal)
					}
					className={classNames(
						"tabs--slip__link tabs--slip__link--normal",
						{
							"is-active":
								props.store.betSlipBetType ===
								BetSlipBetType.Normal,
						}
					)}
				>
					{t<string>("BET_SLIP.TITLE")}
				</button>
			</li>
			<li className="tabs--slip__item">
				<button
					onClick={(e) =>
						props.store.changeBetSlipBetType(
							BetSlipBetType.OneClickBet
						)
					}
					className={classNames(
						"tabs--slip__link tabs--slip__link--oneclick",
						{
							"is-active":
								props.store.betSlipBetType ===
								BetSlipBetType.OneClickBet,
						}
					)}
					disabled={props.store.isBettingDisabled}
				>
					{t<string>("BET_SLIP.ONE_CLICK_BET.LABEL")}
				</button>
			</li>
		</ul>
	);
});
