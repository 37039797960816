import { getCurrentLanguage } from "@utils";
import { isCapacitorPlatform } from "@utils/specific/capacitor";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function GoogleRecaptchaWrapper(props: {
	children: React.ReactNode;
}) {
	if (isCapacitorPlatform()) {
		return <>{props.children}</>;
	}

	return (
		<GoogleReCaptchaProvider
			language={getCurrentLanguage()}
			reCaptchaKey={ReCaptchaChallengeKey}
		>
			{props.children}
		</GoogleReCaptchaProvider>
	);
}
