import React, { useContext } from "react";
import { observer } from "mobx-react";
import {
	ResultsMenuSportContext,
	ResultsMenuStoreContext,
} from "@context/results";
import classnames from "classnames";
import OfferIcon from "@v2/components/shared/icons/Offer";

export const ResultsMenuSport = observer(function ResultsMenuSport() {
	const store = useContext(ResultsMenuStoreContext);
	const sport = useContext(ResultsMenuSportContext);

	const anchorClasses = classnames("nav--secondary__link", {
		"is-expanded": store.selectedSport === sport.id,
		"is-selected": store.selectedTournaments.some(
			(t) => t.sportId === sport.id
		),
	});

	return (
		<li className="nav--secondary__item">
			<a
				className={anchorClasses}
				onClick={() => {
					if (store.selectedSport !== sport.id) {
						store.setSelectedSport(sport.id);
						if (!store.isMenuExpanded) {
							store.toggleMenuExpanded();
						}
					} else {
						store.setSelectedSport(null);
						store.toggleMenuExpanded();
					}
				}}
			>
				<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
					{sport.count}
				</span>
				<OfferIcon
					type="sport"
					sport={sport.abrv}
					className="nav--secondary__icon u-icon u-icon--lrg"
				/>
				<div className="nav--secondary__title">{sport.name}</div>
			</a>
		</li>
	);
});
