import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { NameProvider } from "@gp/utility";
import { BetSlipBetType, EventType } from "@gp/slip";
import { BetSlipTypes } from "@gp/models";

import { getTip } from "@v2/helpers";
import { useFixBody } from "@v2/hooks/shared";
import {
	BetSlipTypeSelector,
	BetSlipConfirmationModal,
	BetSlipBetTypeSelector,
	OneClickBet,
} from ".";
import {
	Number as NumberComponent,
	Currency,
	EmptyState,
	LoaderSpinner,
} from "@v2/components/shared";

import { getCurrentCulture } from "@utils";
import { useRootOfferStore } from "@hooks";
import BetSlipStore from "@v2/state/bet-slip/BetSlipStore";
import NumberInput from "./NumberInput";
import Button from "@components/buttons/Button";
import { MyBetsStoreContext } from "@v2/context";

const nameProvider = new NameProvider();

function TeamName(props: { name: string; gender?: 1 }) {
	const t = useTranslation().t;
	return (
		<>
			{props.name}{" "}
			{props.gender === 1 ? <>({t("OFFER.GENDER.WOMEN")})</> : null}
		</>
	);
}

// @ts-expect-error TODO
function EventInfo(props) {
	const { resultData, matchTime, sportData } = props;
	const { t } = useTranslation();

	if (!resultData || !matchTime) {
		return null;
	}

	const homeHalfTimeScore =
		resultData.halfTimeScore && resultData.halfTimeScore.home
			? resultData.halfTimeScore.home
			: 0;
	const awayHalfTimeScore =
		resultData.halfTimeScore && resultData.halfTimeScore.away
			? resultData.halfTimeScore.away
			: 0;

	let periods = {
		periodNumber: [],
		home: [],
		away: [],
	};
	if (resultData.scorePerPeriod) {
		// @ts-expect-error
		periods = resultData.scorePerPeriod.reduce((acc, item) => {
			var periodItem = {
				periodNumber: item.periodNumber,
				home: item.home || 0,
				away: item.away || 0,
			};

			acc.push(periodItem);

			return acc;
		}, []);
	}

	/**
	 * @deprecated use from @offer/helpers
	 */
	const formatGameScore = (input: string) =>
		input.startsWith("g") ? input.substr(1) : input.toUpperCase();

	return (
		<>
			<div className="slip__match__info">
				<div className="slip__match__time">{matchTime.displayTime}</div>

				{/* CURRENT SCORE */}
				{resultData.currentScore ? (
					<div className="slip__match__stats slip__match__score u-type--wgt--bold">
						{resultData.currentScore.home || 0} :{" "}
						{resultData.currentScore.away || 0}
					</div>
				) : null}

				{/* HT - HALF TIME SCORE */}
				{resultData.halfTimeScore && (
					<div
						className="slip__match__stats slip__match__ht"
						title={t("EVENT.DETAILS.HT")}
					>
						({homeHalfTimeScore || 0} : {awayHalfTimeScore || 0})
					</div>
				)}

				{/* SCORE PER PERIOD */}
				{resultData.scorePerPeriod &&
				!resultData.halfTimeScore &&
				sportData.sportAbrv != "snooker" ? (
					<div className="slip__match__stats slip__match__periods">
						{/* @ts-expect-error */}
						{periods.map((period, idx) => {
							return (
								<div key={idx}>
									(<strong>P{period.periodNumber}</strong>{" "}
									{period.home}:{period.away})
								</div>
							);
						})}
					</div>
				) : null}

				{/* OT - OVERTIME SCORE */}
				{resultData.overtimeScore && (
					<div
						className="slip__match__stats slip__match__ot"
						title={t("EVENT.DETAILS.OT_TITLE")}
					>
						({resultData.overtimeScore.home || 0} :{" "}
						{resultData.overtimeScore.away || 0})
					</div>
				)}

				{/* TBS - TIE BREAKER SCORE */}
				{resultData.tiebreakScore && (
					<div
						className="slip__match__stats slip__match__tbs"
						title={t("EVENT.DETAILS.TBS_TITLE")}
					>
						({resultData.tiebreakScore.home || 0} :{" "}
						{resultData.tiebreakScore.away || 0})
					</div>
				)}

				{/* GAME SCORE - TENNIS */}
				{resultData.currentGameScore && !resultData.tiebreakScore && (
					<div
						className="slip__match__stats slip__match__game"
						title={t("EVENT.DETAILS.GAME_TITLE")}
					>
						(
						{formatGameScore(
							resultData.currentGameScore.home || "0"
						)}{" "}
						:{" "}
						{formatGameScore(
							resultData.currentGameScore.away || "0"
						)}
						)
					</div>
				)}
			</div>
		</>
	);
}

export default observer(function BetSlip() {
	const betSlipStore = useRootOfferStore().betSlipStore as BetSlipStore;
	const location = useLocation();

	useEffect(() => {
		const betSlipInit = async () => {
			const urlParams = new URLSearchParams(location.search);

			if (urlParams.has("bid")) {
				const bid = urlParams.get("bid");
				const activeAccountOwner = Number(urlParams.get("accType"));
				await betSlipStore.getSlipAndMap(
					bid as string,
					activeAccountOwner
				);
			}

			await betSlipStore.onInitialize();
		};

		betSlipInit();
	}, []);

	return (
		<>
			<div className="widget">
				<BetSlipBetTypeSelector store={betSlipStore} />
				<LoaderSpinner
					contained
					isLoading={
						betSlipStore.isLoading &&
						!betSlipStore.isCountdownVisible &&
						!betSlipStore.isOneClickBetActive
					}
				/>
				<SlipBody store={betSlipStore} />
			</div>
			<BetSlipOneClickBetClear store={betSlipStore} />
			<BetSlipReset store={betSlipStore} />
		</>
	);
});

const BetSlipOneClickBetClear = observer(
	function BetSlipOneClickBetClear(props: { store: BetSlipStore }) {
		const { showOneClickBetClearSlipModal } = props.store;

		useFixBody(true, showOneClickBetClearSlipModal);

		if (!showOneClickBetClearSlipModal) {
			return null;
		}

		return (
			<BetSlipConfirmationModal
				headerText="LIVE.INFO"
				bodyText="BET_SLIP.ONE_CLICK_BET_CLEAR_CONFIRMATION"
				onAccept={props.store.acceptSlipClearAndSetToOneClick}
				onReject={props.store.onCloseOneClickBetChangeModal}
			/>
		);
	}
);

const BetSlipReset = observer(function BetSlipReset(props: {
	store: BetSlipStore;
}) {
	useFixBody(true, props.store.showResetModal);

	if (!props.store.showResetModal) {
		return null;
	}

	return (
		<BetSlipConfirmationModal
			headerText="LIVE.INFO"
			bodyText="BET_SLIP.CLEAR_BET_SLIP_CONFIRMATION"
			onAccept={props.store.acceptBetSlipReset}
			onReject={props.store.cancelBetSlipReset}
		/>
	);
});

const SlipBody = observer(function SlipBody(props: { store: BetSlipStore }) {
	const { t } = useTranslation();

	const betDisabledStateStyle = {
		wrapperStyle: "slip__body is-empty u-type--center",
		iconStyle:
			"u-icon u-icon--xxxlrg u-icon--exclusion u-color--ui--danger u-align--h--center u-mar--top--xsml u-mar--bottom--xsml",
	};

	const emptyStateStyle = {
		wrapperStyle: "slip__body is-empty",
		iconStyle:
			"u-icon u-icon--huge u-icon--empty--slip u-align--h--center u-mar--top--xsml u-mar--bottom--big",
	};

	if (props.store.betSlipBetType === BetSlipBetType.OneClickBet) {
		return <OneClickBet {...props} />;
	}

	if (props.store.isSlipEmpty && props.store.isBettingDisabled) {
		return (
			<EmptyState styles={betDisabledStateStyle}>
				{t("WELCOME_POPUP.BETTING_DISABLE_HINT")}
			</EmptyState>
		);
	}

	if (props.store.isSlipEmpty && !props.store.isBettingDisabled) {
		return (
			<EmptyState styles={emptyStateStyle}>
				{t("BET_SLIP.CHOOSE_QUOTES")}
			</EmptyState>
		);
	}
	return (
		<>
			<LoaderSpinner
				isLoading={props.store.isCountdownVisible}
				isCountdown={{
					countdownValue: props.store.countdownCounter,
					isDesktop: true,
				}}
			/>
			<BetSlipTypeSelector store={props.store} />
			<div className="slip__body">
				<SlipEvents store={props.store} />
				<RemoveAllButton store={props.store} />

				<SystemSection store={props.store} />
				<div className="slip__info">
					<CheckOdds store={props.store} />
					<div className="slip__item">
						<EventCount store={props.store} />
					</div>
					<Combinations store={props.store} />
					{/* STAKES */}
					<Stakes store={props.store} />
					{/* EO STAKES */}
					<HandlingFee store={props.store} />
					<SlipMaxCoefficient store={props.store} />
					<div className="slip__item">
						<div className="slip__item__row">
							<GainLabel store={props.store} />
							<Gain store={props.store} />
						</div>
					</div>
					<GainBonusPercentage store={props.store} />
					<GainTaxPercentage store={props.store} />
					<MaxGain store={props.store} />
					<Payment store={props.store} />
				</div>
				<SlipFooter store={props.store} />
			</div>
		</>
	);
});

const SlipEvents = observer(function SlipEvents(props: {
	store: BetSlipStore;
}) {
	return (
		<>
			{Object.keys(props.store.displaySlipEventEntries).map(
				(eventId: string, idx) => {
					const eventOffer =
						props.store.displaySlipEventEntries[eventId]; // array of offers for the same event
					const eventDetails = eventOffer[0].sportData;

					const eventHasErrors = eventOffer.some(
						(offer) => offer.errors && offer.errors.length > 0
					);

					const isLive =
						//@ts-expect-error missing property in model in GP packages
						props.store.displaySlipEventEntries[eventId][0].isLive;

					return (
						<div key={idx} className="slip__match">
							<div className={`slip__match__pair`}>
								<div className="slip__match__wrapper">
									<EventDetails
										eventId={eventId}
										isLive={isLive}
										eventDetails={eventDetails}
										eventHasErrors={eventHasErrors}
									/>
									<EventInfo
										resultData={
											eventOffer[0].eventResultData
										}
										matchTime={eventOffer[0].matchTime}
										sportData={eventOffer[0].sportData}
									/>
								</div>
								<BankButton
									eventId={eventId}
									store={props.store}
								/>
							</div>

							{eventOffer.map((offer, oIdx) => {
								const slipMatchPickClasses = classNames(
									"slip__match__pick",
									{
										"is-excluded": offer.isExcluded,
										"bet-lock":
											(offer.isEventLocked ||
												offer.isKeyLocked ||
												offer.isOfferLocked) &&
											offer.errors != null,
										"bet-stop": offer.isDeleted,
									}
								);

								const quoteClasses = classNames("quote--tny", {
									desc: offer.indicator === -1,
									inc: offer.indicator === 1,
								});

								const specifiers = {
									...offer.specifier,
									competitor1: eventDetails.teamOneName,
									competitor2: eventDetails.teamTwoName,
								};

								const name = nameProvider.getName(
									offer.sportData
										.bettingTypeNameForBettingSlip,
									specifiers
								);

								return (
									<div
										key={oIdx}
										className={slipMatchPickClasses}
									>
										<div className="slip__match__row">
											{props.store.currentSlipOffers
												.length > 1 ||
											offer.isExcluded ? (
												<button
													className="btn btn--square btn--square--xtny btn--secondary--inverted btn--animate-grow"
													type="button"
													onClick={() =>
														props.store.toggleTipExclude(
															offer.bettingOfferId
														)
													}
												>
													{offer.isExcluded ? (
														<i className="u-icon u-icon--xsml u-icon--check u-color--ui--muted" />
													) : (
														<i className="u-icon u-icon--xsml u-icon--check u-color--ui--success" />
													)}
												</button>
											) : null}

											<div className="slip__match__col slip__match__col--tip">
												{offer.sportData.eventType ===
												EventType.LongTerm ? null : (
													<React.Fragment>
														{name}:
													</React.Fragment>
												)}

												<strong>
													{/* {offer.displayTip} */}
													{getTip(offer)}
												</strong>
											</div>
											<div className="slip__match__col slip__match__col--offer">
												<div
													className={classNames(
														quoteClasses,
														{
															"u-color--ui--muted":
																offer.isExcluded,
														}
													)}
												>
													{offer.displayValue}
												</div>
											</div>
											<RemoveEventOfferButton
												bettingOfferId={
													offer.bettingOfferId
												}
												store={props.store}
												isExcluded={offer.isExcluded}
											/>
										</div>
									</div>
								);
							})}
						</div>
					);
				}
			)}
		</>
	);
});

// @ts-expect-error TODO
const EventDetails = observer(function EventDetails(props) {
	const { eventDetails, eventHasErrors, eventId, isLive } = props;
	const location = useLocation();

	const teamClasses = classNames("slip__match__teams", {
		"u-type--color--danger": eventHasErrors,
	});

	if (eventHasErrors || location.pathname.includes(eventId)) {
		if (eventDetails.eventType !== EventType.Normal) {
			return (
				<div className="slip__match__teams">
					<div>{eventDetails.eventName}</div>
				</div>
			);
		}

		return (
			<div className={teamClasses}>
				<div>
					<TeamName
						name={eventDetails.teamOneName}
						gender={eventDetails.teamOneGender}
					/>
				</div>
				<div>
					<TeamName
						name={eventDetails.teamTwoName}
						gender={eventDetails.teamTwoGender}
					/>
				</div>
			</div>
		);
	}

	if (eventDetails.eventType !== EventType.Normal) {
		return (
			<LinkEventHOC
				eventId={eventId}
				isLive={isLive}
				className="u-anchor"
			>
				<div className="slip__match__teams">
					<div>{eventDetails.eventName}</div>
				</div>
			</LinkEventHOC>
		);
	}

	return (
		<LinkEventHOC eventId={eventId} isLive={isLive} className="u-anchor">
			<div className={teamClasses}>
				<div>
					<TeamName
						name={eventDetails.teamOneName}
						gender={eventDetails.teamOneGender}
					/>
				</div>
				<div>
					<TeamName
						name={eventDetails.teamTwoName}
						gender={eventDetails.teamTwoGender}
					/>
				</div>
			</div>
		</LinkEventHOC>
	);
});

const LinkEventHOC = observer(function LinkEventHOC(props: {
	eventId: string;
	className?: string;
	isLive?: boolean;
	children: JSX.Element | JSX.Element[];
}) {
	const { eventId, className, isLive, children } = props;

	const page = isLive ? "live/events" : "sports/full/event";
	const url = `/${getCurrentCulture()}/${page}/${eventId}`;

	return (
		<Link className={className} to={url}>
			{children}
		</Link>
	);
});

const BankButton = observer(function BankButton(props: {
	store: BetSlipStore;
	eventId: string;
}) {
	if (props.store.betSlipType !== BetSlipTypes.SYSTEM) {
		return null;
	}

	return (
		<div
			className={classNames("slip__match__actions", {
				"slip__match__actions--wide":
					props.store.betSlipType === BetSlipTypes.SYSTEM,
			})}
		>
			<button
				className={classNames(
					"slip__match__actions__btn btn btn--square btn--square--xtny",
					{
						"btn--success": props.store.isEventBank(props.eventId),
						"btn--secondary--inverted": !props.store.isEventBank(
							props.eventId
						),
					},
					"btn--animate-grow u-type--sml"
				)}
				type="button"
				onClick={() => props.store.addRemoveBank(props.eventId)}
			>
				B
			</button>
		</div>
	);
});

const RemoveEventOfferButton = function RemoveEventOfferButton(props: {
	store: BetSlipStore;
	bettingOfferId: string;
	isExcluded?: boolean;
}) {
	return (
		<div className="slip__match__col slip__match__col--actions">
			<button
				className="btn btn--square btn--square--xtny btn--secondary--inverted btn--animate-grow"
				type="button"
				onClick={() =>
					props.store.removeFromBetSlip({ id: props.bettingOfferId })
				}
			>
				<i
					className={
						"u-icon u-icon--xsml u-icon--remove" +
						(props.isExcluded
							? " u-color--ui--muted"
							: " u-color--ui--danger")
					}
				/>
			</button>
		</div>
	);
};

const RemoveAllButton = observer(function RemoveAllButton(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	if (props.store.slipSize <= 1) {
		return null;
	}

	return (
		<div className="slip__item slip__item--remove-all">
			<button
				className="btn btn--tny btn--ghost u-color--brand--highlight u-type--decoration--underline"
				type="button"
				onClick={props.store.startBetSlipReset}
			>
				{t<string>("BET_SLIP.REMOVE_ALL_BETS")}
			</button>
		</div>
	);
});

const Stakes = observer(function Stakes(props: { store: BetSlipStore }) {
	if (props.store.combinations > 1) {
		return (
			<React.Fragment>
				<div className="slip__item">
					<div className="slip__item__row">
						<StakePerCombination store={props.store} />
					</div>
				</div>
				<div className="slip__item">
					<div className="slip__item__row">
						<TotalStake store={props.store} />
					</div>
				</div>
			</React.Fragment>
		);
	}

	return (
		<div className="slip__item">
			<div className="slip__item__row">
				<TotalStake store={props.store} />
			</div>
		</div>
	);
});

const StakePerCombination = observer(function StakePerCombination(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	return (
		<>
			<div className="slip__item__col">
				{t<string>("BET_SLIP.STAKE_PER_COMBINATION")}
			</div>
			<div className="slip__item__col">
				<div className="slip__field">
					<NumberInput
						disabled={props.store.isProcessingSlipEmpty}
						className="slip__field__input input input--sml"
						name="paymentPerCombination"
						value={props.store.paymentPerCombination}
						onChange={(newValue: number) => {
							props.store.onPaymentPerCombinationChange(newValue);
							props.store.onPaymentPerCombinationApply();
						}}
					/>
					<span className="slip__field__currency">
						<Currency />
					</span>
				</div>
			</div>
		</>
	);
});

const TotalStake = observer(function TotalStake(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	return (
		<>
			<div className="slip__item__col">
				{t<string>("BET_SLIP.TOTAL_STAKE")}
			</div>
			<div className="slip__item__col">
				<DefaultStakeButton store={props.store} />
				<div className="slip__field">
					<NumberInput
						disabled={props.store.isProcessingSlipEmpty}
						className="slip__field__input input input--sml"
						name="totalStake"
						value={props.store.payment}
						onChange={(newValue: number) => {
							props.store.onTotalStakeChange(newValue);
							props.store.onTotalStakeApply();
						}}
					/>
					<span className="slip__field__currency">
						<Currency />
					</span>
				</div>
			</div>
		</>
	);
});

const DefaultStakeButton = observer(function DefaultStakeButton(props: {
	store: BetSlipStore;
}) {
	const [isHover, setIsHover] = useState(false);

	if (App.state.rootStore.userAuthStore.user == null) {
		return null;
	}

	const iconClasses = classNames("u-icon u-icon--sml u-icon--set-stake", {
		"is-hovered": isHover,
	});

	return (
		<button
			className="btn btn--square btn--square--tny btn--secondary--inverted u-mar--right--xtny"
			type="button"
			onClick={() => props.store.setDefaultBetSlipStake()}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<i className={iconClasses} />
		</button>
	);
});

const SystemSection = observer(function SystemSection(props: {
	store: BetSlipStore;
}) {
	if (props.store.betSlipType !== BetSlipTypes.SYSTEM) {
		return null;
	}

	return (
		<div className="slip__system">
			<AddRemoveCombinations store={props.store} />
		</div>
	);
});

const AddRemoveCombinations = observer(function AddRemoveCombinations(props: {
	store: BetSlipStore;
}) {
	if (props.store.availableCombinations == null) {
		return null;
	}

	return (
		<>
			{props.store.availableCombinations.map((combo, i) => (
				<AvailableCombinationItem
					combo={combo}
					key={i}
					store={props.store}
				/>
			))}
		</>
	);
});

const AvailableCombinationItem = observer(
	function AvailableCombinationItem(props: {
		store: BetSlipStore;
		combo: any; // TODO
	}) {
		return (
			<button
				className={`slip__system__btn btn btn--xtny ${
					props.store.isCombinationSelected(props.combo.key)
						? "is-selected"
						: ""
				}`}
				onClick={(e) =>
					props.store.addRemoveCombination(props.combo.key)
				}
			>
				{props.store.bankOfferCount > 0 ? (
					<span className="u-type--wgt--medium">
						B{props.store.bankOfferCount} +{" "}
					</span>
				) : null}
				{props.combo.key}/{props.combo.total} (
				{props.combo.numberOfCombinations})
			</button>
		);
	}
);

const EventCount = observer(function EventCount(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	return (
		<>
			<div className="slip__item__row">
				<div className="slip__item__col">
					{t<string>("BET_SLIP.NO_PLAYED_EVENTS")}
				</div>
				<div className="slip__item__col">{props.store.eventCount}</div>
			</div>
		</>
	);
});

const Combinations = observer(function Combinations(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	if (props.store.combinations <= 1) {
		return null;
	}

	return (
		<div className="slip__item">
			<div className="slip__item__row">
				<div className="slip__item__col">
					{t<string>("BET_SLIP.NO_BETS")}
				</div>
				<div className="slip__item__col">
					{props.store.combinations}
				</div>
			</div>
		</div>
	);
});

const HandlingFee = observer(function HandlingFee(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	if (!props.store.isHandlingFeeActive) {
		return null;
	}

	return (
		<div className="slip__item">
			<div className="slip__item__row">
				<div className="slip__item__col">
					{t<string>("BET_SLIP.HANDLING_FEE", {
						feePercentage: props.store.handlingFeePercentage,
					})}
				</div>
				<div className="slip__item__col">
					-<NumberComponent value={props.store.handlingFeeAmount} />
					<span className="u-mar--left--xtny">
						<Currency />
					</span>
				</div>
			</div>
		</div>
	);
});

const SlipMaxCoefficient = observer(function SlipMaxCoefficient(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	return (
		<div className="slip__item">
			<div className="slip__item__row">
				<div className="slip__item__col">
					{t<string>("BET_SLIP.MAX_COEF")}
				</div>
				<div className="slip__item__col">
					<NumberComponent value={props.store.slipMaxCoefficient} />
				</div>
			</div>
		</div>
	);
});

const GainLabel = observer(function GainLabel(props: { store: BetSlipStore }) {
	const { t } = useTranslation();

	if (
		props.store.gainBonusPercentage > 0 ||
		props.store.gainTaxPercentage > 0
	) {
		return (
			<div className="slip__item__col">{t<string>("BET_SLIP.GAIN")}</div>
		);
	}

	return (
		<div className="slip__item__col">{t<string>("BET_SLIP.MAX_GAIN")}</div>
	);
});

const Gain = observer(function Gain(props: { store: BetSlipStore }) {
	return (
		<div className="slip__item__col">
			<div className="slip__field">
				<NumberInput
					disabled={props.store.isProcessingSlipEmpty}
					className="slip__field__input input input--sml"
					name="maxGain"
					value={props.store.gain}
					onChange={(newValue: number) => {
						props.store.onGainChange(newValue);
						props.store.onGainApply();
					}}
				/>
				<span className="slip__field__currency">
					<span className="u-mar--left--xtny">
						<Currency />
					</span>
				</span>
			</div>
		</div>
	);
});

const GainBonusPercentage = observer(function GainBonusPercentage(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	if (props.store.gainBonusPercentage <= 0) {
		return null;
	}

	return (
		<div className="slip__item">
			<div className="slip__item__row">
				<div className="slip__item__col">
					{" "}
					{t<string>("BET_SLIP.GAIN_BONUS")} (
					{props.store.gainBonusPercentage} %)
				</div>
				<div className="slip__item__col">
					+<NumberComponent value={props.store.gainBonusAmount} />
					<span className="u-mar--left--xtny">
						<Currency />
					</span>
				</div>
			</div>
		</div>
	);
});

const GainTaxPercentage = observer(function GainTaxPercentage(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	if (props.store.gainTaxPercentage <= 0) {
		return null;
	}

	return (
		<div className="slip__item">
			<div className="slip__item__row">
				<div className="slip__item__col">
					{" "}
					{t<string>("BET_SLIP.GAIN_TAX")} (
					{props.store.gainTaxPercentage} %)
				</div>
				<div className="slip__item__col">
					-<NumberComponent value={props.store.gainTaxAmount} />
					<span className="u-mar--left--xtny">
						<Currency />
					</span>
				</div>
			</div>
		</div>
	);
});

const MaxGain = observer(function MaxGain(props: { store: BetSlipStore }) {
	const { t } = useTranslation();

	if (
		props.store.gainBonusPercentage <= 0 &&
		props.store.gainTaxPercentage <= 0
	) {
		return null;
	}

	return (
		<div className="slip__item">
			<div className="slip__item__row">
				<div className="slip__item__col">
					{t<string>("BET_SLIP.MAX_GAIN")}
				</div>
				<div className="slip__item__col">
					<NumberComponent value={props.store.maxGain} />
					<span className="u-mar--left--xtny">
						<Currency />
					</span>
				</div>
			</div>
		</div>
	);
});

const Payment = observer(function Payment(props: { store: BetSlipStore }) {
	const { t } = useTranslation();

	return (
		<div className="slip__item">
			<div className="slip__item__row u-padd--y--xtny u-type--wgt--medium">
				<div className="slip__item__col">
					{t<string>("BET_SLIP.PAYMENT")}
				</div>
				<div className="slip__item__col">
					<NumberComponent value={props.store.payment} />
					<span className="u-mar--left--xtny">
						<Currency />
					</span>
				</div>
			</div>
		</div>
	);
});

const CheckOdds = observer(function CheckOdds(props: { store: BetSlipStore }) {
	const { t } = useTranslation();

	return (
		<div className="slip__item">
			<div className="slip__item__row slip__item__row--bordered">
				<input
					id="acceptOdds"
					className="input--check"
					type="checkbox"
					onChange={() =>
						props.store.onToggleAcceptOddsChangesChange()
					}
					checked={props.store.acceptOddsChanges}
				/>
				<label htmlFor="acceptOdds">
					{t<string>("BET_SLIP.TYPE.CHECK_ODDS")}
				</label>
			</div>
		</div>
	);
});

const SlipFooter = observer(function SlipFooter(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	if (props.store.hasInvalidBets) {
		return (
			<button
				className="slip__button btn btn--lrg btn--block btn--danger btn--icon btn--animate-grow"
				type="button"
				onClick={props.store.removeInactiveBets}
			>
				<i className="u-icon u-icon--sml u-icon--remove u-color--core--snow" />
				<span className="slip__button__text">
					{t<string>("BET_SLIP.REMOVE_INACTIVE")}
				</span>
			</button>
		);
	}

	if (App.state.rootStore.userAuthStore.isLoggedIn) {
		return <SlipFooterAuthenticated store={props.store} />;
	}

	return <SlipFooterNotAuthenticated />;
});

const SlipFooterAuthenticated = observer(
	function SlipFooterAuthenticated(props: { store: BetSlipStore }) {
		const { t } = useTranslation();

		if (props.store.isBettingDisabled) {
			return (
				<div className="slip__footer">
					<div className="slip__notice message message--base">
						<i className="message__icon u-icon u-icon--lrg u-icon--exclusion u-color--ui--danger" />
						<div className="message__content">
							<div className="message__text u-type--sml">
								{t<string>(
									"WELCOME_POPUP.BETTING_DISABLE_HINT"
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}

		const submitBtnClasses = classNames(
			"btn btn--lrg btn--block btn--success slip__button",
			{
				"slip__button--sharp": props.store.showConfirmBetSlipSubmit,
				"btn--animate-forward": !props.store.showConfirmBetSlipSubmit,
				"btn--animate-grow": props.store.showConfirmBetSlipSubmit,
			}
		);

		const submitIconClasses = classNames(
			"u-icon--sml",
			{
				"u-icon--arrow--right": !props.store.showConfirmBetSlipSubmit,
				"u-icon--check": props.store.showConfirmBetSlipSubmit,
			},
			"u-color--core--snow"
		);

		return (
			<>
				<Button
					btnType="button"
					className={submitBtnClasses}
					btnText={t<string>("BET_SLIP.SUBMIT_BET")}
					iconRight={true}
					iconClassName={submitIconClasses}
					onClick={(e) => {
						if (props.store.showConfirmBetSlipSubmit) {
							props.store.onSubmitSlip();
						} else {
							props.store.startBetSlipSubmitProcess();
						}
					}}
					isDisabled={props.store.isSubmitDisabled}
				/>
				<BetSlipSubmitConfirmation store={props.store} />
			</>
		);
	}
);

const BetSlipSubmitConfirmation = observer(
	function BetSlipSubmitConfirmation(props: { store: BetSlipStore }) {
		const { t } = useTranslation();

		if (!props.store.showConfirmBetSlipSubmit) {
			return null;
		}

		return (
			<button
				className="slip__button btn btn--lrg btn--block btn--ghost btn--icon btn--animate-grow"
				type="button"
				onClick={() => props.store.cancelBetSlipSubmit()}
			>
				<span className="slip__button__text">
					{t<string>("COMMON.CANCEL")}
				</span>
				<i className="u-icon u-icon--sml u-icon--remove u-color--ui--danger" />
			</button>
		);
	}
);

const SlipFooterNotAuthenticated = function SlipFooterNotAuthenticated() {
	const { t } = useTranslation();

	return (
		<Link
			to={`/${getCurrentCulture()}/auth/login`}
			className="slip__button btn btn--lrg btn--block btn--primary btn--icon btn--animate-forward"
		>
			<span className="slip__button__text">
				{t<string>("BET_SLIP.LOGIN_TO_SUBMIT")}
			</span>
			<i className="u-icon u-icon--med u-icon--login u-color--core--snow" />
		</Link>
	);
};
