export const supportEmail = "support@tipwin.com";

const onboardingRoutes = [
	"register-activation",
	"login",
	"register",
	"registration",
];
const loginFlowRoutes = [
	"register-activation",
	"login",
	"register",
	"registration",
	"force-password-change",
	"betting-activity",
	"match-user",
	"confirm-user-data",
	"accept-new-terms",
	"account-activation",
	"accept-gdpr",
];

export const isOnboardingPage = (currentRoute: string) =>
	onboardingRoutes.some((route) => currentRoute.includes(route)) &&
	!location.search.includes("isErrorPage=true");

export const isLoginFlow = (currentRoute: string) =>
	loginFlowRoutes.some((route) => currentRoute.includes(route)) &&
	!location.search.includes("isErrorPage=true");

export const isRegistrationDomain = () => {
	return window.location.host.includes("registration.");
};
