import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import { OfferConfigurationStore } from "@gp/offer";

import { SpecifierType, EventType, EventKeyBettingOffer } from "@gp/models";
import { NameProvider, Sorter } from "@gp/utility";

import { OfferStoreContext, LookupContext, EventContext } from "@gp/components";

import { OfferTip } from "../../buttons";
import { EventOutrightOffer } from "../../event-row";
import { FavoritesStoreContext } from "../../../../../context/FavoritesStoreContext";
import { useRootAppStore } from "../../../../../../hooks";

const offerConfigurationStore = new OfferConfigurationStore("european");
const TypeConfiguration = offerConfigurationStore.typeConfiguration;

const nameProvider = new NameProvider();

const bettingTypesVertical = [
	"1st-2nd-half-both-teams-to-score",
	"1st-half-correct-score",
	"1st-half-correct-score-score-max-3",
	"1st-half-exact-number-of-goals",
	"1st-half-exact-number-of-goals-of-away-team",
	"1st-half-exact-number-of-goals-of-home-team",
	"1st-half-tip-1x2-both-teams-to-score",
	"1st-half-tip1x2-over-under",
	"3-way-both-teams-to-score",
	"both-teams-to-score-over-under",
	"correct-score",
	"correct-score-max-4",
	"correct-score-score-max-6",
	"double-chance-total",
	"double-result-1st-set-match",
	"exact-number-of-goals",
	"goalnr-goal-1x2",
	"goals-away-team",
	"goals-home-team",
	"halftime-fulltime",
	"highest-scoring-quarter",
	"quarternr-quarter-team-winning-margin",
	"setnr-set-correct-score",
	"tip-1x2-and-over-under",
	"total-goals-aggregated",
	"winner-total",
	"winner-total-incl-overtime",
	"winning-margin",
	"winning-margin-incl-overtime",
	"which-team-to-score",
	"framenr-frame-highest-break",
	"frames-1-to-framenr-correct-score",
	"setnr-set-correct-score-in-legs",
	"correct-score-in-sets",
	"double-chance-both-teams-to-score",
	"highest-scoring-period",
];

const bettingTypesTwoCols = [
	"1st-2nd-half-both-teams-to-score",
	"double-chance-total",
	"touchdownnr-touchdown-scorer-incl-overtime",
	"quarternr-quarter-team-winning-margin",
	"winner-total-incl-overtime",
];

const offerInline = ["winning-margin-incl-overtime"];

export const BettingTypeRow = observer(function BettingTypeRow(props: {
	bettingType: Parameters<typeof BettingTypeName>[0]["bettingType"] &
		Parameters<typeof BettingTypeOffer>[0]["bettingType"] & {
			nameForOfferList: string;
			description: string;
			abrv: string;
		};
	offerKey: Parameters<typeof BettingTypeOffer>[0]["offerKey"] & {
		specifier?: { [key: string]: string };
	};
}) {
	const { bettingType, offerKey } = props;

	const event = useContext(EventContext);
	const { teams } = useContext(LookupContext);

	const homeTeam = teams.get(event.teamOneId || "");
	const awayTeam = teams.get(event.teamTwoId || "");

	const specifiers = {
		...offerKey.specifier,
		competitor1: homeTeam?.name || "",
		competitor2: awayTeam?.name || "",
	};

	const name = nameProvider.getName(bettingType.nameForOfferList, specifiers);
	const description = nameProvider.getName(
		bettingType.description,
		specifiers
	);

	const offerItemClasses = classNames(
		"offer__body__row offer--additional__type",
		[`offer--additional__${bettingType.abrv}`],
		{
			"offer--additional__vertical": bettingTypesVertical.includes(
				bettingType.abrv
			),
		}
	);

	return (
		<div className={offerItemClasses} data-type={bettingType.abrv}>
			<BettingTypeName
				name={name}
				bettingType={bettingType}
				offerKey={props.offerKey}
			/>
			<BettingTypeOffer bettingType={bettingType} offerKey={offerKey} />
			<BettingTypeToolTip name={name} description={description} />
		</div>
	);
});

export const BettingTypeName = observer(function FavoritesIcon(props: {
	bettingType: { id: string };
	offerKey: { specifier?: { [key: string]: any } };
	name: string;
}) {
	const [isHover, setIsHover] = useState(false);
	const favoritesStore = useContext(FavoritesStoreContext);
	const isFavorite = favoritesStore.isUserFavoriteBettingType(
		props.bettingType.id,
		props.offerKey.specifier || null
	);
	const rootStateStore = useRootAppStore();
	const { t } = useTranslation();

	const onButtonClick = () => {
		if (isFavorite) {
			favoritesStore.removeUserFavoriteBettingType(
				props.bettingType.id,
				props.offerKey.specifier || null
			);
		} else {
			favoritesStore.addUserFavoriteBettingType(
				props.bettingType.id,
				props.offerKey.specifier || null
			);
		}
	};

	const typeWrapperClasses = classNames(
		"offer__body__data offer--additional__name"
	);

	const typeInnerClasses = classNames("offer--additional__btype", {
		"is-disabled": !rootStateStore.userAuthStore.isLoggedIn,
	});

	const favIconClasses = classNames(
		"offer--additional__fav u-icon u-icon--med",
		{
			"u-icon--favorite--bold u-color--secondary--sunny":
				isFavorite ||
				(!isFavorite &&
					isHover &&
					rootStateStore.userAuthStore.isLoggedIn),
			"u-icon--favorite--alt u-color--ui--muted":
				(!isFavorite && !isHover) ||
				!rootStateStore.userAuthStore.isLoggedIn,
			"is-disabled": !rootStateStore.userAuthStore.isLoggedIn,
		}
	);

	const tooltipMessage = !rootStateStore.userAuthStore.isLoggedIn
		? t("FAVORITES.NONAUTH_TOOLTIP")
		: "";

	return (
		<div className={typeWrapperClasses}>
			<div
				className={typeInnerClasses}
				onClick={onButtonClick}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
				data-tooltip-id={
					"offerRowTooltip-" + props.name.replace(" ", "")
				}
				data-tooltip-position-strategy="fixed"
				data-tooltip-content={tooltipMessage}
			>
				<i className={favIconClasses} />
				{props.name}
			</div>

			<i
				className="offer--additional__info u-icon u-icon--sml u-icon--info-circle"
				data-tooltip-id={
					"offerRowTooltip-" + props.name.replace(" ", "")
				}
				data-tooltip-position-strategy="fixed"
			/>
		</div>
	);
});

function BettingTypeToolTip(props: { name: string; description: string }) {
	const { name, description } = props;

	return (
		<Tooltip
			id={"offerRowTooltip-" + name.replace(" ", "")}
			className="at-bettingTypeToolTip react-tooltip--primary react-tooltip--table"
			place="bottom"
		>
			<div className="react-tooltip__header">{name}</div>
			<div className="react-tooltip__body">{description}</div>
		</Tooltip>
	);
}

const BettingTypeOffer = observer(function BettingTypeOffer(props: {
	bettingType: { abrv: string; specifierType: SpecifierType };
	offerKey: { id: string };
}) {
	const { bettingType, offerKey } = props;

	//#region  hooks

	const event = useContext(EventContext);
	const offerStore = useContext(OfferStoreContext);
	const keyOffersMap = offerStore.keyOffersMap;
	const { teams } = useContext(LookupContext);
	const { t } = useTranslation();

	//#endregion hooks

	const keyOffers = keyOffersMap.get(offerKey.id);
	let offers: EventKeyBettingOffer[] = [];

	if (keyOffers != null) {
		offers = Array.from(keyOffers.values());
	}

	const btConfig = new TypeConfiguration(
		bettingType.abrv,
		offers.map((o) => o.tip)
	);

	if (bettingType.specifierType === SpecifierType.PLAYER) {
		const sortTips = (a: EventKeyBettingOffer, b: EventKeyBettingOffer) => {
			// @ts-expect-error is this really number?
			const aVal = parseFloat(a.value);
			// @ts-expect-error is this really number?
			const bVal = parseFloat(b.value);

			if (isNaN(aVal) || isNaN(bVal)) {
				return 0;
			}

			return Math.sign(aVal - bVal);
		};
		const homeTeamOffers = offers
			.filter((o) => o.teamId === event.teamOneId && o.value > 1)
			.sort(Sorter.sort(sortTips));
		const awayTeamOffers = offers
			.filter((o) => o.teamId === event.teamTwoId && o.value > 1)
			.sort(Sorter.sort(sortTips));

		const homeTeam = teams.get(event.teamOneId || "");
		const awayTeam = teams.get(event.teamTwoId || "");

		return (
			<div className="offer__body__data offer__player">
				<div className="row">
					{homeTeamOffers.length === 0 &&
					awayTeamOffers.length === 0 ? (
						<div className="col col-sml-6">
							{t("BETTING_TYPE.NO_OFFER")}
						</div>
					) : (
						<>
							<div className="col col-sml-6 offer__player__col">
								<div className="offer__player__team">
									{homeTeam?.name}
								</div>
								<ul className="offer__player__list">
									{homeTeamOffers.map((tip) => (
										<PlayerTipWrapper
											key={tip.id}
											tip={tip}
										/>
									))}
								</ul>
							</div>
							<div className="col col-sml-6 offer__player__col">
								<div className="offer__player__team">
									{awayTeam?.name}
								</div>
								<ul className="offer__player__list">
									{awayTeamOffers.map((tip) => (
										<PlayerTipWrapper
											key={tip.id}
											tip={tip}
										/>
									))}
								</ul>
							</div>
						</>
					)}
				</div>

				{offers
					.filter((o) => o.teamId == null)
					.map((tip) => (
						<div key={tip.id} className="row offer__player__goal">
							<div className="col col-sml-12">
								<TipWrapper tip={tip} />
							</div>
						</div>
					))}
			</div>
		);
	}

	// Due to some unexplained mystery of the universe eventType is undefined for normal events
	if (event.eventType != null && event.eventType !== EventType.NORMAL) {
		const eventOffer = offerStore.eventKeysMap.get(event.id);

		return (
			<div className="offer__body__row offer__outright">
				<EventOutrightOffer
					eventOffer={eventOffer}
					offerStore={offerStore}
				/>
			</div>
		);
	}

	if (bettingType.abrv === "winning-method") {
		return (
			<div className="offer__body__data offer__player">
				{btConfig.tips.sorted.map((tip) => {
					const tipOffer = offers.filter(
						(offerItem) =>
							offerItem.tip.toLowerCase() === tip.toLowerCase()
					)[0];

					if (tipOffer == null) {
						if (btConfig.tips.tipOptions.displayPlaceholders) {
							return (
								<PlayerTipWrapper
									key={tip}
									bettingTypeAbrv={bettingType.abrv}
								/>
							);
						}
						return null;
					}

					return (
						<PlayerTipWrapper
							key={tipOffer.id}
							tip={tipOffer}
							bettingTypeAbrv={bettingType.abrv}
						/>
					);
				})}
			</div>
		);
	}

	const quoteWrapperClasses = classNames("offer__body__data", {
		"offer--additional__inline": !bettingTypesTwoCols.includes(
			bettingType.abrv
		),
		"offer--additional__vertical": offerInline.includes(bettingType.abrv),
		"offer--additional__grid": bettingTypesTwoCols.includes(
			bettingType.abrv
		),
	});

	return (
		<div className={quoteWrapperClasses}>
			{btConfig.tips.sorted.map((tip) => {
				const tipOffer = offers.filter(
					(offerItem) =>
						offerItem.tip.toLowerCase() === tip.toLowerCase()
				)[0];

				if (tipOffer == null) {
					if (btConfig.tips.tipOptions.displayPlaceholders) {
						return (
							<TipWrapper
								key={tip}
								bettingTypeAbrv={bettingType.abrv}
							/>
						);
					}
					return null;
				}

				return (
					<TipWrapper
						key={tipOffer.id}
						tip={tipOffer}
						bettingTypeAbrv={bettingType.abrv}
					/>
				);
			})}
		</div>
	);
});

function TipWrapper(props: {
	tip?: EventKeyBettingOffer;
	bettingTypeAbrv?: string;
}) {
	const { tip, bettingTypeAbrv } = props;

	const tipWrapperClasses = classNames({
		"offer--additional__inline__item": !bettingTypesTwoCols.includes(
			bettingTypeAbrv || ""
		),
		"offer--additional__grid__half": bettingTypesTwoCols.includes(
			bettingTypeAbrv || ""
		),
	});

	return (
		<OfferTip
			tip={tip}
			displayTipName={tip?.displayTip}
			className={tipWrapperClasses}
			tipLabelHasMargin={false}
			isGroupedOfferTip
			isAdditionalOffer={true}
		/>
	);
}

function PlayerTipWrapper(props: {
	tip?: EventKeyBettingOffer;
	bettingTypeAbrv?: string;
}) {
	const { tip, bettingTypeAbrv } = props;

	const playerTipWrapperClasses = classNames("offer__player__item", {
		"offer--additional__inline__item": !bettingTypesTwoCols.includes(
			bettingTypeAbrv || ""
		),
		"offer--additional__grid__half": bettingTypesTwoCols.includes(
			bettingTypeAbrv || ""
		),
	});

	return (
		<OfferTip
			tip={tip}
			displayTipName={tip?.displayTip}
			className={playerTipWrapperClasses}
			isGroupedOfferTip
			isPlayerMarket
			isAdditionalOffer={true}
		/>
	);
}
