import React, { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { useLocation } from "react-router-dom";
import { LiveStatus } from "@gp/models";

export default function Pager(props: {
	pageNumber: number;
	pageSize: number;
	totalItems: number;
	onPageChange: (newPage: number) => void;
	isRemoveAllVisible?: boolean;
	/** Clear all selection from menu. */
	onCloseAll?: () => void;
	withDropdown?: boolean;
	withNumberInput?: boolean;
}) {
	const {
		pageNumber,
		pageSize,
		totalItems,
		onPageChange,
		isRemoveAllVisible,
		onCloseAll,
		withDropdown = false,
		withNumberInput = false,
	} = props;

	const remainder = totalItems % pageSize;
	const totalPages =
		remainder === 0
			? totalItems / pageSize
			: (totalItems - remainder) / pageSize + 1;

	if (totalPages <= 1) {
		return (
			<React.Fragment>
				<div className="pagination">
					{isRemoveAllVisible && (
						<RemoveAll onCloseAll={onCloseAll} />
					)}
					<RemoveAllFavoritesEventsButton />
					<RemoveAllFavoritesTournamentsButton />
				</div>
			</React.Fragment>
		);
	}

	let start = 1;
	let end = totalPages;

	if (totalPages > 3) {
		end = 3;
	}
	if (totalPages > 3 && pageNumber > 1) {
		start = pageNumber - 1;
		end = pageNumber + 1;
		if (end > totalPages) {
			end = totalPages;
			start = totalPages - 2;
		}
	}

	const renderInnerPager = () => {
		const ip = [];

		for (let i = start; i <= end; ++i) {
			if (i === pageNumber) {
				ip.push(
					<PageButton
						key={i}
						page={i}
						onPageChange={onPageChange}
						disabled
						active
					/>
				);
			} else {
				ip.push(
					<PageButton key={i} page={i} onPageChange={onPageChange} />
				);
			}
		}

		return ip;
	};

	const paginationTabsClasses = classNames("pagination__tabs", {
		"pagination__tabs--sml": withNumberInput,
	});

	return (
		<React.Fragment>
			<nav className="pagination">
				<div className={paginationTabsClasses}>
					{totalPages > 3 && pageNumber > 1 && start > 1 ? (
						<React.Fragment>
							<PageButton page={1} onPageChange={onPageChange} />
							<IconButton
								previous
								page={pageNumber - 1}
								onPageChange={onPageChange}
							/>
						</React.Fragment>
					) : null}
					{renderInnerPager()}
					{totalPages > 3 &&
					pageNumber < totalPages &&
					end < totalPages ? (
						<React.Fragment>
							<IconButton
								page={pageNumber + 1}
								onPageChange={onPageChange}
							/>
							<PageButton
								page={totalPages}
								onPageChange={onPageChange}
							/>
						</React.Fragment>
					) : null}
				</div>
				{withNumberInput && (
					<WithNumberInput
						totalPages={totalPages}
						onPageChange={onPageChange}
					/>
				)}

				{withDropdown && (
					<DropdownPager
						totalPages={totalPages}
						onPageChange={onPageChange}
						pageNumber={pageNumber}
					/>
				)}
				{isRemoveAllVisible && <RemoveAll onCloseAll={onCloseAll} />}
				<RemoveAllFavoritesEventsButton />
				<RemoveAllFavoritesTournamentsButton />
			</nav>
		</React.Fragment>
	);
}

const RemoveAll = observer(function RemoveAll(props: {
	onCloseAll?: () => void;
}) {
	const { t } = useTranslation();
	if (!props.onCloseAll) {
		return null;
	}
	return (
		<div className="pagination__remove">
			<button
				className="btn btn--tny btn--ghost btn--icon u-color--brand--highlight"
				type="button"
				onClick={props.onCloseAll}
			>
				{t<string>("COMMON.REMOVE_ALL")}
			</button>
		</div>
	);
});

function DropdownPager(props: {
	totalPages: number;
	onPageChange: (value: number) => void;
	pageNumber: number;
}) {
	const optionsArray = [];

	for (let i = 1; i <= props.totalPages; i++) {
		optionsArray.push(i);
	}

	return (
		<select
			className="pagination__input pagination--wrap__input input input--tny input--select"
			onChange={(e) => props.onPageChange(parseInt(e.target.value) || 1)}
			value={props.pageNumber}
		>
			{optionsArray.map((pageNr) => (
				<option key={pageNr} value={pageNr}>
					{pageNr}
				</option>
			))}
		</select>
	);
}

function WithNumberInput(props: {
	totalPages: number;
	onPageChange: (newPage: number) => void;
}) {
	const t = useTranslation().t;

	const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.keyCode !== 13) {
			return;
		}

		const newPage = parseInt((e.target as HTMLInputElement).value);
		if (isNaN(newPage) || newPage < 1 || newPage > props.totalPages) {
			return;
		}

		props.onPageChange(newPage);
	};

	return (
		<input
			className="pagination__input input input--sml"
			type="number"
			min="1"
			max={props.totalPages}
			onKeyDown={onKeyDown}
			placeholder={t("COMMON.PAGE_PLACE_HOLDER")}
		/>
	);
}

function PageButton(props: PagerButtonProps & { page: number }) {
	return <PagerButton {...props}>{props.page}</PagerButton>;
}

type PagerButtonProps = {
	disabled?: boolean;
	page: number;
	active?: boolean;
	children?: JSX.Element | JSX.Element | string | number;
	onPageChange: (newPage: number) => void;
};

function PagerButton(props: PagerButtonProps) {
	const itemClasses = classNames(
		"pagination__item btn btn--square btn--square--sml",
		{
			"btn--secondary--inverted": !props.active,
			"btn--primary is-active": props.active,
		}
	);

	return (
		<button
			type="button"
			className={itemClasses}
			disabled={props.disabled}
			onClick={() => props.onPageChange(props.page)}
		>
			{props.children}
		</button>
	);
}

function IconButton(props: PagerButtonProps & { previous?: boolean }) {
	const iconClassNames = classNames("u-icon u-icon--xsml", {
		"u-icon--caret--left": props.previous,
		"u-icon--caret--right": !props.previous,
	});

	return (
		<PagerButton {...props}>
			<i className={iconClassNames} />
		</PagerButton>
	);
}

const RemoveAllFavoritesEventsButton = observer(
	function RemoveAllFavoritesEventsButton() {
		const favoritesStore = useContext(FavoritesStoreContext);
		const { t } = useTranslation();
		const location = useLocation();

		if (!favoritesStore) {
			return null;
		}
		if (
			!location.pathname.includes("my-favorites/events") ||
			favoritesStore.prematchFavoritesCount == 0
		) {
			return null;
		}

		return (
			<div className="pagination__remove">
				<button
					className="btn btn--sml btn--negative btn--icon u-type--xsml u-type--color--anchor"
					type="button"
					onClick={() =>
						favoritesStore.deselectFavoritesEvents(
							LiveStatus.PREMATCH
						)
					}
				>
					{t("FAVORITES.REMOVE_ALL_FAVORITES")}
				</button>
			</div>
		);
	}
);

const RemoveAllFavoritesTournamentsButton = observer(
	function RemoveAllFavoritesTournamentsButton() {
		const favoritesStore = useContext(FavoritesStoreContext);
		const { t } = useTranslation();
		const location = useLocation();

		if (!favoritesStore) {
			return null;
		}
		if (
			!location.pathname.includes("my-favorites/tournaments") ||
			favoritesStore.favoriteTournamentsCount == 0
		) {
			return null;
		}

		return (
			<div className="pagination__remove">
				<button
					className="btn btn--sml btn--negative btn--icon u-type--xsml u-type--color--anchor"
					type="button"
					onClick={favoritesStore.deselectFavoritesTournaments}
				>
					{t("FAVORITES.REMOVE_ALL_FAVORITES")}
				</button>
			</div>
		);
	}
);
