import { DateTime } from "luxon";
import { eventChangeTypeLookupService as EventChangeTypeLookupService } from "../../../administration/services/search-page/lookups/EventChangeTypeLookupService";
import { bettingTypesLookupService as BettingTypesLookupService } from "../../../administration/services/search-page/lookups/BettingTypesLookupService";

import { httpClient, getApplicationHeaders } from "@utils";
import {
	ResultDataRequestFilter,
	ResultMenuResponseData,
} from "@data-types/results";
import { BaseLookupDto } from "@api-types/lookups/BaseLookupDto";
import {
	ResultEventChange,
	ResultResponseData,
} from "@data-types/results/ResultResponseData";
import { FindResultDto } from "@api-types/FindResultDto";

const baseUrl = `${WEBPACK_BASE_ADDRESS}platform`;
const bettingTypesList = [
	"3way",
	"winner",
	"head2head",
	"head2head-1x2",
	"winner-incl-extra-innings",
	"winner-incl-overtime",
	"winner-incl-super-over",
];

export default class ResultsService {
	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getMenu(filter: {
		previousDays: number;
	}): Promise<ResultMenuResponseData[]> {
		const timeFilter = getTimeSpan(filter.previousDays);

		return await httpClient.get(
			`${baseUrl}/events-with-results-per-tournaments?from=${timeFilter.startTimeFrom}&to=${timeFilter.startTimeTo}&translate=True&rpp=7000`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async getResults(
		filter: ResultDataRequestFilter
	): Promise<ResultResponseData[]> {
		const timeFilter = getTimeSpan(filter.previousDays);
		const bettingTypes = await BettingTypesLookupService.findLookup();

		return await httpClient.post(
			`${baseUrl}/events/with-results`,
			JSON.stringify({
				eventWithTips: true,
				isEndOfMatch: true,
				isAvailableOnInternet: true,
				feedTypes: [0, 1],
				tournamentIds: filter.tournamentIds,
				sportCategoryIds: filter.sportCategoryIds,
				bettingTypeIds: bettingTypes.item
					.filter((bt: BaseLookupDto) =>
						bettingTypesList.includes(bt.abrv)
					)
					.map((item: BaseLookupDto) => item.id),
				startTimeFrom: timeFilter.startTimeFrom,
				startTimeTo: timeFilter.startTimeTo,
				searchPhrase: filter.searchPhrase,
			}),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async getEventResult(
		eventId: string
	): Promise<FindResultDto<ResultEventChange>> {
		const eventChangeTypes =
			await EventChangeTypeLookupService.findLookup();

		const eventChangeTypeIds = eventChangeTypes.item
			.filter(function (item: BaseLookupDto) {
				return item.abrv == "score-change";
			})
			.map((item: BaseLookupDto) => item.id);

		return await httpClient.get(
			`${baseUrl}/event-changes?eventIds=${eventId}&eventChangeTypeIds=${eventChangeTypeIds.join(
				","
			)}&rpp=100&sort=time|asc`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}
}

const getTimeSpan = (
	day: number
): {
	startTimeFrom: DateTime;
	startTimeTo: DateTime;
} => {
	const now = DateTime.now();

	let timeSpan = null;

	switch (day) {
		case -1:
			timeSpan = {
				startTimeFrom: now.plus({ days: -1 }).startOf("day").toUTC(),
				startTimeTo: now.plus({ days: -1 }).endOf("day").toUTC(),
			};
			break;
		case 0:
			timeSpan = {
				startTimeFrom: now.startOf("day").toUTC(),
				startTimeTo: now.endOf("day").toUTC(),
			};
			break;
		default:
			timeSpan = {
				startTimeFrom: now.plus({ days: -day }).startOf("day").toUTC(),
				startTimeTo: now.toUTC(),
			};
			break;
	}

	return timeSpan;
};
