import React from "react";
import { useTranslation } from "react-i18next";

export default function Header() {
	const { t } = useTranslation();

	const isOasisLockEnabled =
		App.state.rootStore.userAuthStore.user?.isOasisEnabled;

	if (!isOasisLockEnabled) {
		return (
			<div className="card--primary__header">
				<div className="card--primary__title title--primary">
					{t("USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.TITLE_ACC_CLOSURE")}
				</div>
			</div>
		);
	}
	return (
		<div className="card--primary__header">
			<div className="card--primary__title title--primary">
				{t("USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.TITLE_ACC_LOCK")}
			</div>
		</div>
	);
}
