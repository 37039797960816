export const enum StorageStateKeysEnum {
	USER_KEY = "user",
	USER_TOKEN = "userToken",
	SECONDARY_USER = "secondaryUser",
	SECONDARY_TOKEN = "secondaryToken",
	ACCOUNT_ACTIVATION = "accountActivation",
	ACC = "acc",
	REALITY_CHECK_EXPIRATION = "reality-check-expiration",
	AUTO_LOGOUT_PERIOD = "auto-logout-period",
	DSV = "dsv",
	TOKEN_EXPIRATION_LAST_CHANGE = "token-expiration-last-change",
	SECONDARY_TOKEN_EXPIRATION_DATE = "secondary-token-expiration-date",
	LOGIN_HISTORY = "loginHistory",
	GIG_TOKEN = "gigToken",
	USER_ACCOUNTS = "userAccounts",
	USER_MATCHING = "user_matching",
	UDC = "udc",
	CONFIRMATION_DATA = "confirmation_data",
	SLN = "sln",
	SESSION_EXPIRATION_TIME = "session-expiration-time",
	GS = "gs",
	WELCOME_POPUP = "welcomePopup",
	THEME = "theme",
	USER_DATA_TO_BE_CONFIRMED = "user-data-to-be-confirmed",
	MITID_LOGIN_HASH = "mitid-login-hash",
	ONBOARDING_REGISTER_POPUP = "onboardingRegisterPopup",
	ONBOARDING_NEWSLETTER_POPUP = "onboardingNewsletterPopup",
	ONBOARDING_WELCOME_POPUPS = "onboardingWelcomePopups",
	DECLINED_CAP_UPDATE = "declineCapUpdate",
	AFC = "afc",
	CHAT_BOX_VISIBLE = "isChatBoxVisible",
	MOBILE_APP_POPUP_DISPLAY = "hideMobileAppPopup",
}

export interface ILocalStorageProvider {
	initialLoad: () => Promise<void>;
	get: (key: StorageStateKeysEnum) => unknown | null;
	set: (key: StorageStateKeysEnum, value: any) => void;
	remove: (key: StorageStateKeysEnum) => void;
	flush: () => void;
}
