import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
	RecoverPasswordFormSection,
	RecoverPasswordSuccess,
} from "./components";
import { useRecoverPassword } from "@v2/hooks/membership/recover-password";

export default function RecoverPasswordPage() {
	const [isRecoverPasswordSuccess, setIsRecoverPasswordSuccess] =
		useState(false);

	const methods = useForm({ mode: "onChange" });
	const { reset } = methods;

	const { onInitialize } = useRecoverPassword(
		reset,
		setIsRecoverPasswordSuccess
	);

	useEffect(() => {
		onInitialize();
	}, []);

	return (
		<div className="container">
			<FormProvider {...methods}>
				<RecoverPasswordFormSection
					reset={reset}
					isRecoverPasswordSuccess={isRecoverPasswordSuccess}
					setIsRecoverPasswordSuccess={setIsRecoverPasswordSuccess}
				/>
			</FormProvider>

			<RecoverPasswordSuccess
				isRecoverPasswordSuccess={isRecoverPasswordSuccess}
			/>
		</div>
	);
}
