import {
	getAgencyKey,
	httpClient,
	getApplicationHeaders,
	AccountTypes,
} from "@utils";

export class LugasCasinoService {
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}`;
	}

	static get casinoBaseUrl() {
		return `${CasinoApiAddress}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}`;
	}

	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async sendSportToCasinoAreaChange(data: {
		userId: string;
		agencyId: string;
	}) {
		const url = `${WEBPACK_BASE_ADDRESS}platform/area-change/sport-to-casino`;

		return await httpClient.post(
			url,
			JSON.stringify(data),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}
}
