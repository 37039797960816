import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Clock, Language } from "@v2/components/desktop/shared/header";
import { useAppContext } from "@v2/hooks/shared";

import Slider from "react-slick";
import { getCurrentCulture, ISOToDisplayCode } from "@utils";
import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
import PromoContentImage from "@v2/components/shared/icons/PromoContentImage";
import { PromoOfferType } from "@utils/common/promoOfferTypeEnum";
import { isRegistrationDomain } from "@v2/helpers/membership/utils";
const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const ReactMarkdown = lazy(loadFailPath, () => import("react-markdown"));

export default memo(function OnBoardingSlider(props: {
	hasOnboardingStyling?: boolean;
}) {
	const { t } = useTranslation();
	const { culture } = useAppContext();

	const settings = {
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		variableWidth: false,
		autoplay: true,
		autoplaySpeed: 8000,
	};

	const wrapperClasses = classNames({
		"card--onboarding__slider": !props.hasOnboardingStyling,
		"card--membership__aside": props.hasOnboardingStyling,
	});

	const headerClasses = classNames({
		"card--onboarding__top": !props.hasOnboardingStyling,
		"l--membership__header": props.hasOnboardingStyling,
	});

	const settingsClasses = classNames({
		"carousel--onboarding__settings": !props.hasOnboardingStyling,
		"l--membership__settings": props.hasOnboardingStyling,
	});

	const stampClasses = classNames(
		"card--membership__stamp u-icon u-icon--license",
		{
			"u-icon--license--de": getCurrentCulture() == "de",
			"u-icon--license--en": getCurrentCulture() != "de",
		}
	);

	return (
		<div className={wrapperClasses}>
			<div className={headerClasses}>
				<HomeLogo isOnboarding={props.hasOnboardingStyling} />
				<div className={settingsClasses}>
					<Clock hasOnboardingStyling={props.hasOnboardingStyling} />
					<Language
						hasOnboardingStyling={props.hasOnboardingStyling}
						rounded={false}
					/>
				</div>
			</div>

			{props.hasOnboardingStyling ? (
				<>
					<div className="card--membership__intro">
						<img
							className="card--membership__media"
							src="/assets/images/bg/athlete.png"
							alt=""
						/>
						<ReactMarkdown className="card--membership__intro__title">
							{t(
								"specific:MEMBERSHIP.REGISTRATION.ONBOARDING.WELCOME_BONUS"
							)}
						</ReactMarkdown>
						<ReactMarkdown className="card--membership__intro__desc">
							{t(
								"specific:MEMBERSHIP.REGISTRATION.ONBOARDING.WELCOME_BONUS_DESC"
							)}
						</ReactMarkdown>
					</div>

					<i className={stampClasses} />
				</>
			) : (
				<Slider {...settings} className="carousel--onboarding">
					<div className="carousel--onboarding__item">
						<div className="carousel--onboarding__media carousel--onboarding__media--bonus">
							<PromoContentImage
								page="membership"
								wrapperClassName="carousel--onboarding__picture"
								className="carousel--onboarding__img"
								image="reg1-opti.png"
								fallback="membership.png"
							/>
						</div>
						<div className="carousel--onboarding__content">
							<div className="carousel--onboarding__body">
								<ReactMarkdown className="carousel--onboarding__text">
									{t(
										"specific:MEMBERSHIP.REGISTRATION.SLIDER.WELCOME_BONUS",
										{
											currency: ISOToDisplayCode(
												WEBPACK_DEFAULT_CURRENCY
											),
										}
									)}
								</ReactMarkdown>

								<div className="carousel--onboarding__desc">
									{t(
										"MEMBERSHIP.REGISTRATION.SLIDER.WAGERING_EXCLUSION"
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="carousel--onboarding__item">
						<div className="carousel--onboarding__media carousel--onboarding__media--games">
							<PromoContentImage
								page={PromoOfferType.MEMBERSHIP}
								wrapperClassName="carousel--onboarding__picture"
								className="carousel--onboarding__img"
								image="reg2-opti.png"
								fallback="membership.png"
							/>
						</div>
						<div className="carousel--onboarding__content">
							<div className="carousel--onboarding__body">
								<ReactMarkdown className="carousel--onboarding__text">
									{t(
										"MEMBERSHIP.REGISTRATION.SLIDER.TIPWIN_SERVICES"
									)}
								</ReactMarkdown>
								<div className="carousel--onboarding__desc">
									{t(
										"MEMBERSHIP.REGISTRATION.SLIDER.WAGERING_EXCLUSION"
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="carousel--onboarding__item">
						<div className="carousel--onboarding__media carousel--onboarding__media--welcome">
							<PromoContentImage
								page="membership"
								wrapperClassName="carousel--onboarding__picture"
								className="carousel--onboarding__img"
								image="reg3-opti.png"
								fallback="membership.png"
							/>
						</div>
						<div className="carousel--onboarding__content">
							<div className="carousel--onboarding__body">
								<ReactMarkdown className="carousel--onboarding__text">
									{t(
										"MEMBERSHIP.REGISTRATION.SLIDER.WELCOME"
									)}
								</ReactMarkdown>
								<div className="carousel--onboarding__desc">
									{t(
										"MEMBERSHIP.REGISTRATION.SLIDER.WIDE_OFFER"
									)}
								</div>
							</div>
						</div>
					</div>
				</Slider>
			)}
		</div>
	);
});

function HomeLogo(props: { isOnboarding?: boolean }) {
	const { culture } = useAppContext();
	if (isRegistrationDomain()) {
		return (
			<a
				href={`${WEBPACK_WEBSITE_URL}/${culture}/home`}
				className="u-display--b"
			>
				<HomeImg isOnboarding={props.isOnboarding} />
			</a>
		);
	}
	return (
		<Link to={`/${culture}/home`} className="u-display--b">
			<HomeImg isOnboarding={props.isOnboarding} />
		</Link>
	);
}

function HomeImg(props: { isOnboarding?: boolean }) {
	let imgClassName = "card--onboarding__logo";
	let imgSrc = "/assets/images/logo-negative.svg";
	if (props.isOnboarding) {
		imgClassName = "l--membership__logo";
		imgSrc = "/assets/images/logo-vibrant.svg";
	}
	return <img className={imgClassName} src={imgSrc} alt="TipWin" />;
}
