import { useMemo, useState, useEffect, useRef } from "react";

export function useReturnToScroll(
	isLoading: boolean,
	containerRef: HTMLElement | null
): boolean {
	const timerId = useRef<ReturnType<typeof setTimeout> | undefined>(
		undefined
	);
	const counter = useRef<number>(0);
	const isWorkingRef = useRef<boolean>(true);
	const [isWorking, setIsWorking] = useState<boolean>(true);
	const lastContainerHeight = useRef<number>(containerRef?.clientHeight || 0);
	isWorkingRef.current = isWorking;

	const scrollAction = useMemo(
		() => () => {
			if (isWorkingRef.current) {
				return;
			}

			App.state.history.replace(
				App.state.history.location.pathname +
					App.state.history.location.search,
				Object.assign(App.state.history.location.state || {}, {
					scrollPositionApp: containerRef?.scrollTop,
				})
			);
		},
		[]
	);

	useEffect(() => {
		document.addEventListener("touchend", scrollAction);
		return () => {
			document.removeEventListener("touchend", scrollAction);
			if (timerId.current != null) {
				clearInterval(timerId.current);
			}
		};
	}, []);

	if (isLoading) {
		if (timerId.current != null) {
			clearInterval(timerId.current);
			timerId.current = undefined;
		}
		return isWorking;
	}

	const lastScroll: number =
		App.state.history.location.state?.scrollPositionApp || 0;

	if (timerId.current == null && isWorking) {
		timerId.current = setInterval(() => {
			containerRef?.scrollTo(0, lastScroll);
			counter.current++;

			if (
				(lastScroll === containerRef?.scrollTop &&
					lastContainerHeight.current) ||
				0 === (containerRef?.clientHeight || 0) ||
				counter.current >= 20
			) {
				setIsWorking(false);
				clearInterval(timerId.current);
				timerId.current = undefined;
			}

			lastContainerHeight.current = containerRef?.clientHeight || 0;
		}, 100);
	}

	return isWorking;
}
