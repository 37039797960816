import { observable, action, runInAction, computed } from "mobx";
import { Subscription } from "rxjs";

import { LoaderStore } from "../../../state/stores/common";

import { OpenCashoutModalType } from "./model";
import { isAutoCashoutEnabled } from "@v2/helpers/features";
import {
	getUserFromLocalStorage,
	getCurrentLanguage,
	getUserToken,
	getShopId,
	getAgencyKey,
	ISOToDisplayCode,
} from "@utils";
import {
	ICashoutSubscriptionRequest,
	IBetSlipContainer,
	CashoutType,
} from "@gp/hub";
import { BetSlip as BaseBetSlip } from "@gp/models";

import { localizationService, logger } from "@state";

import { ICashoutStoreParent } from "./ICashoutStoreParent";
import { BetSlipService } from "@v2/data-access/bet-slip";
import { Bet } from "./model/Bet";

import { getCurrentCulture } from "@utils";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { DocumentExpirationErrorCodes } from "@api-types/documentExpiration/documentExpiration";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { BetSlipInteractionTypeEnum } from "@data-types/analytics/BetSlipInteractionTypeEnum";
import { BetSlipSecureWinSetting } from "./model/BetSlipSecureWinSetting";
import { DateTime } from "luxon";
import { getUniqueObjectsByProperty } from "@utils/common/getUniqueObjectsByProperty";
import { OpenCashoutWithQrCodeModalType } from "./model/OpenCashoutWithQrCodeModalType";
import ModalService from "@services/modals/ModalService";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const MyBetsService = lazy(loadFailPath, () => import("@services/my-bets/MyBetsService"));

type BetSlip = BaseBetSlip & {
	indicator?: -1 | 0 | 1;
	displayedAmount?: string;
	cType?: CashoutType;
	isRemoved?: boolean;
};

export class CashoutStore {
	public isStoreInitialized: boolean = false;
	@computed public get isLoading() {
		return this.loader.isLoading;
	}

	private parentStore: ICashoutStoreParent;
	private betSlipService: BetSlipService;
	private loader: LoaderStore;
	private subscriptionIds: { id: string; subscription: Subscription }[] = [];
	private refreshDebounceId: ReturnType<typeof setTimeout> | undefined;
	private countdownInterval: ReturnType<typeof setTimeout> | undefined;

	constructor(parentStore: ICashoutStoreParent) {
		this.parentStore = parentStore;

		this.loader = new LoaderStore();
		this.betSlipService = new BetSlipService();
		this.updateAutoSellValues = this.updateAutoSellValues.bind(this);
		this.isCashedOut = this.isCashedOut.bind(this);
	}

	//#region  observable

	@observable isCashoutModalOpen = false;
	@observable isEventCashoutModalOpen = false;
	@observable cashoutModalData: OpenCashoutModalType | null;
	@observable eventCashoutModalData: OpenCashoutModalType | null;
	@observable isCashoutFormSubmitting = false;
	@observable isCashoutWithQrCodeModalOpen = false;
	@observable
	cashoutWithQrCodeModalData: OpenCashoutWithQrCodeModalType | null;
	@observable isAnonymousCashoutWithQrCodeSuccessModalOpen = false;

	@observable countDownDuration = 0;
	@observable countdownCounter = 0;
	@observable isCountdownVisible = false;

	@observable cashoutHistory: {
		betSlipCashoutHistoryValues: {
			timestamp: string;
			cashoutValue: number;
		}[];
	} | null = null;
	@observable cashoutHistorySlipId: string | null = null;
	@observable isCashoutHistoryOpen = false;

	@observable isAutosellModalOpen = false;
	@observable isEventAutosellModalOpen = false;
	@observable isSaveBtnDisabled = true;
	@observable isConfigurationExists = false;
	@observable secureWinAmount = "";
	@observable minThreshold = "";
	@observable maxThreshold = "";
	@observable openedAutosellModalBetSlipId: string | null = null;
	@observable isAutocashoutUnavailable = false;
	@observable isAutosellFormValid = true;
	@observable isMinThresholdValid = true;
	@observable isMaxThresholdValid = true;
	@observable isAutosellModalCashedOut = false;
	@observable autosellModalCashoutGain = null;
	@observable bet: Bet | null = null;
	@observable cashoutLocation: "my-bets" | "offer" | null = null;
	@observable cashoutEventId: string | null = null;
	//#endregion observable

	@computed public get isAutoCashoutEnabled() {
		return isAutoCashoutEnabled;
	}

	private _timeoutCache = new Map();
	private _myBetsTimeoutCache = new Map();

	private _isSecureWinAmountInputDirty = false;
	private _currentCashoutValue: number | null = null;
	private _minSecureWinThresholdIncreasePercentage = 0.15;

	//#region cashout subscription

	@action.bound
	async initializeCashoutSubscription(
		eventId: string | null = null,
		betsList: Bet[] | null = null
	) {
		this.disposeSubscription(eventId);

		const slips = (betsList || this.parentStore.betsList)
			.filter((b) => b.betSlipStatus.abrv === "submitted")
			.map((bet) => ({
				betSlipId: bet.id,
				betSlipNumericalId: bet.betSlipNumber,
			}));

		if (slips.length === 0) {
			return;
		}

		const subscriptionFilter: ICashoutSubscriptionRequest = {
			subscriptionId: `${getUserFromLocalStorage()?.id}${
				eventId != null ? `-${eventId}` : ""
			}-my-bets-cashout`,
			environment: WEBPACK_ENVIRONMENT_KEY,
			language: getCurrentLanguage(),
			token: getUserToken()?.token || "",
			shopId: getShopId(),
			agency: getAgencyKey(),
			isAgencyUser: true,
			monitoredBetSlips: slips,
		};

		const subscription = App.state.rootStore.cashoutHubStore.hub
			.getSubscription(subscriptionFilter)
			.subscribe((response) => {
				// TODO: maybe add buffer
				runInAction(() => {
					if (eventId == null) {
						this.parentStore.betsList.forEach((betSlip) => {
							const update = response.value.find(
								(u) => u.id === betSlip.id
							);

							if (update && !betSlip.isRemoved) {
								this.updateSlipCashout(betSlip, update);

								// autosell modal change
								if (
									(this.isAutosellModalOpen ||
										this.isEventAutosellModalOpen) &&
									this.openedAutosellModalBetSlipId &&
									this.openedAutosellModalBetSlipId ===
										betSlip.id
								) {
									const newCashoutValue = update.value;

									if (this.isCashedOut(update)) {
										this.isAutosellModalCashedOut = true;
										this.autosellModalCashoutGain =
											//@ts-expect-error
											update.cashoutGain;
									} else if (
										this._currentCashoutValue !==
										newCashoutValue
									) {
										this.updateAutoSellValues(
											//@ts-expect-error
											newCashoutValue,
											update.maxGain
										);
									}
								}
							}

							if (update != null && this.isCashedOut(update)) {
								this.onCashoutSync(update.id);
							}
						});
					} else {
						if (
							this.parentStore.betsPerEvent != null &&
							this.parentStore.betsPerEvent.length > 0
						) {
							const eventIdx =
								this.parentStore.betsPerEvent.findIndex(
									(e) => e.eventId === eventId
								);

							if (eventIdx > -1) {
								response.value.forEach((betSlip) => {
									if (this.parentStore.betsPerEvent != null) {
										const betPerEvent =
											this.parentStore.betsPerEvent[
												eventIdx
											].bets.find(
												(b) => b.id === betSlip.id
											);
										if (betPerEvent != null) {
											this.updateSlipCashout(
												betPerEvent,
												betSlip
											);
										}
									}
								});
							}
						}
					}
				});
			});

		this.subscriptionIds?.push({
			id: subscriptionFilter.subscriptionId,
			subscription: subscription,
		});
	}

	/**
	 * Updates betSlip cashout related properties is cashout is available.
	 */
	@action.bound
	updateSlipCashout(betSlip: Bet, update: Partial<IBetSlipContainer>) {
		this.removeBetSlipIdFromTimeOutCache(betSlip);
		this.updateBetSlipIndicator(betSlip, update);

		// when betslip is auto-cashed out and should be removed after 10 seconds
		if (
			!this._myBetsTimeoutCache.has(update.id) &&
			this.isCashedOut(update)
		) {
			betSlip.betStatus = {
				abrv: "won",
				name: "WON",
			};
			betSlip.indicator = 0;
			betSlip.displayedAmount = null; // remove displayed amount to hide button
			betSlip.isCancellationAvailable = false;

			for (let offer of betSlip.betSlipOffers) {
				offer.bettingOfferStatus = 9;
			}

			const timeoutId = setTimeout(() => {
				this._myBetsTimeoutCache.delete(update.id);
				runInAction(() => {
					betSlip.isRemoved = true;
				});
			}, 10000);

			this._myBetsTimeoutCache.set(update.id, timeoutId);

			App.state.rootStore.userAuthStore.refreshUserBalance();

			return;
		}

		betSlip.displayedAmount =
			!update.isPaidOut && update.value != null
				? update.value.toFixed(2)
				: null;
		betSlip.cType = update.cType;
		betSlip.calculatedCashoutAmount = update.value || 0;
	}

	@action.bound
	private removeBetSlipIdFromTimeOutCache(betSlip: { id: string }) {
		if (this._timeoutCache.has(betSlip.id)) {
			clearTimeout(this._timeoutCache.get(betSlip.id));
			this._timeoutCache.delete(betSlip.id);
		}
	}

	@action.bound
	updateBetSlipIndicator(betSlip: Bet, update: Partial<IBetSlipContainer>) {
		const currAmount = Number(betSlip.displayedAmount);

		// initial value for currAmount will be 0 so we must skip adding indicators
		if (currAmount <= 0 || update.value === currAmount) {
			betSlip.indicator = 0;
			return;
		}

		if (update.value != null && update.value > currAmount) {
			betSlip.indicator = 1;
		} else if (update.value != null && update.value < currAmount) {
			betSlip.indicator = -1;
		}

		const tId = setTimeout(() => {
			this._timeoutCache.delete(betSlip.id);
			runInAction(() => {
				betSlip.indicator = 0;
			});
		}, 3500);

		this._timeoutCache.set(betSlip.id, tId);
	}

	//#endregion cashout subscription

	//#region actions

	@action.bound
	async onInitialize() {
		try {
			const countDownDurationResponse = await this.betSlipService.delay();
			const countDownDuration = parseInt(countDownDurationResponse.value);
			runInAction(() => {
				this.countDownDuration = countDownDuration;
				this.isStoreInitialized = true;
			});
		} catch (error) {
			console.error(error);
		}
	}

	@action.bound
	async cashout(
		{
			id,
			calculatedCashoutAmount,
			cType,
			isChangeAccepted,
			isLive,
		}: Omit<OpenCashoutModalType, "isChangeAccepted"> & {
			isChangeAccepted: boolean;
		},
		cashoutLocation: "my-bets" | "offer" | null
	) {
		this.isCashoutFormSubmitting = true;
		this.cashoutLocation = cashoutLocation;

		if (isLive) {
			this.startCountdown();
		}

		try {
			this.closeCashoutModal();
			this.closeEventCashoutModal();
			const response = await this.betSlipService.cashout(
				id,
				calculatedCashoutAmount,
				cType,
				isChangeAccepted,
				isLive,
				this.parentStore.activeAccountOwner
			);
			const betSlip = this.parentStore.betsList.find(
				(betSlip) => betSlip.id === id
			);
			if (betSlip != null) {
				this.updateSlipCashout(betSlip, { isPaidOut: true });
			}
			if (
				this.parentStore.betsPerEvent != null &&
				this.parentStore.betsPerEvent.length !== 0
			) {
				this.parentStore.betsPerEvent.forEach((eventBets) => {
					const betPerEvent = eventBets.bets.find((b) => b.id === id);
					if (betPerEvent != null) {
						this.updateSlipCashout(betPerEvent, {
							isPaidOut: true,
						});
					}
				});
			}
			var gainValue = response.betSlip.payout ?? response.betSlip.gain;

			if (response.betSlipProcessingResponse) {
				App.state.rootStore.notificationStore.success(
					response.betSlipProcessingResponse.errorMessage
				);
			} else {
				App.state.rootStore.notificationStore.success(
					localizationService.t("MY_BETS.CASHOUT.SUCCESS_MESSAGE", {
						amount: gainValue.toFixed(2),
						currency: ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY),
					})
				);
			}

			App.state.rootStore.userAuthStore.resolveBalance();
			AnalyticsService.logBetSlipInteraction(
				BetSlipInteractionTypeEnum.Cashout
			);
		} catch (err) {
			console.log(err);
			var errorMessage = localizationService.t(
				"MY_BETS.CASHOUT.FAILURE_MESSAGE"
			);
			if (err.data == null) {
				App.state.rootStore.notificationStore.error(errorMessage);
			} else {
				var error = err.data;
				if (error.betSlipProcessingResponse != null) {
					errorMessage = error.betSlipProcessingResponse.errorMessage;
				} else if (error.validationResponse != null) {
					errorMessage = error.validationResponse.errorMessage;
				}
				errorMessage = errorMessage.replaceAll(
					"{currency}",
					ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)
				);
				const errorCode = error.betSlipProcessingResponse.errorCode;
				if (errorCode === 400082) {
					App.state.rootStore.notificationStore.error(
						error.betSlipProcessingResponse.errorMessage
					);
				} else if (errorCode === 400262) {
					error.betSlipProcessingResponse.errorMessage =
						error.betSlipProcessingResponse.errorMessage.replace(
							"{0}",
							DateTime.fromISO(
								error.betSlipProcessingResponse
									.previouslyClosedAt
							).toFormat("f")
						);
					error.betSlipProcessingResponse.errorMessage =
						error.betSlipProcessingResponse.errorMessage.replace(
							"{1}",
							DateTime.fromISO(
								error.betSlipProcessingResponse.nextOpening
							).toFormat("f")
						);

					App.state.rootStore.notificationStore.error(
						error.betSlipProcessingResponse.errorMessage
					);
				} else if (errorCode === 400083) {
					const id = error.betSlip.id;
					const calculatedCashoutAmount =
						error.betSlip.calculatedCashoutAmount;
					const displayedAmount =
						error.betSlip.calculatedCashoutAmount.toFixed(2);
					const isLive = error.betSlip.isLive;
					const message = errorMessage;
					this.openCashoutModal({
						id,
						calculatedCashoutAmount,
						displayedAmount,
						cType,
						isLive,
						message,
					});
				} else if (
					errorCode ===
						DocumentExpirationErrorCodes.UserDocumentExpirationDateExpired ||
					errorCode ===
						DocumentExpirationErrorCodes.UserDocumentExpirationDateNotSet
				) {
					App.state.rootStore.AccountVerificationStore.showPopup(
						"account-settings/document-expiration",
						"DOCUMENT_EXPIRATION.TITLE",
						errorMessage
					);
				} else {
					App.state.rootStore.notificationStore.error(
						localizationService.t("MY_BETS.CASHOUT.FAILURE_MESSAGE")
					);
				}
			}
		} finally {
			// if (isLive) {
			//     loader.resume();
			// }
			this.stopCountDown();
			//await this.refresh();
			await this.onCashoutSync(id);

			runInAction(() => {
				this.isCashoutFormSubmitting = false;
				this.cashoutEventId = null;
			});
		}
	}

	@action.bound
	async refresh() {
		clearTimeout(this.refreshDebounceId);
		this.refreshDebounceId = setTimeout(async () => {
			this.parentStore.refresh();
		}, 500);
	}

	@action.bound
	async onCashoutSync(betId: string) {
		const betsListBet = this.parentStore.betsList.find(
			(item) => item.id === betId
		);
		const offerBetsList = this.parentStore.betsPerEvent?.filter(
			(item) => item.bets.find((bet) => bet.id === betId) != null
		);

		if (betsListBet != null) {
			this.parentStore.refresh();
		}

		if (offerBetsList != null && offerBetsList.length > 0) {
			offerBetsList.forEach((item) => {
				if (this.parentStore.fetchAdditionalOffers != null) {
					if (this.parentStore.betsPerEvent != null) {
						const currentEventIdx =
							this.parentStore.betsPerEvent.findIndex(
								(eb) => eb.eventId === item.eventId
							);

						if (currentEventIdx > -1) {
							this.parentStore.betsPerEvent[
								currentEventIdx
							].pageNumber = 1;
						}

						this.parentStore.fetchAdditionalOffers(item.eventId);
					}
				}
			});
		}
	}

	@action.bound
	stopCountDown() {
		this.countdownCounter = 0;
		clearInterval(this.countdownInterval);
		this.isCountdownVisible = false;
	}

	@action.bound
	startCountdown() {
		this.countdownCounter = this.countDownDuration;
		if (this.countdownCounter !== 0) {
			this.isCountdownVisible = true;
			this.countdownInterval = setInterval(async () => {
				--this.countdownCounter;
				if (this.countdownCounter <= 0) {
					clearInterval(this.countdownInterval);
					// this.isCountdownVisible = false;
				}
			}, 1000);
		} else {
			// this.isCountdownVisible = false;
		}
	}

	/**
	 * Closes cashout modal
	 */
	@action.bound
	closeCashoutModal() {
		this.isCashoutModalOpen = false;
		this.cashoutModalData = null;
	}

	@action.bound
	closeEventCashoutModal() {
		this.isEventCashoutModalOpen = false;
		this.eventCashoutModalData = null;
	}

	/**
	 * Opens cashout modal
	 * @param {{id, amount}} modalData
	 */
	@action.bound
	openCashoutModal(modalData: OpenCashoutModalType) {
		this.isCashoutModalOpen = true;
		this.cashoutModalData = modalData;
		ModalService.onOpen();
	}

	@action.bound
	openEventCashoutModal(modalData: OpenCashoutModalType, eventId: string) {
		this.isEventCashoutModalOpen = true;
		this.cashoutEventId = eventId;
		this.eventCashoutModalData = modalData;
	}

	//#endregion actions

	//#region cashout with QR code
	@action.bound
	async cashoutWithQrCode({
		id,
		calculatedCashoutAmount,
		cType,
		isChangeAccepted,
		isLive,
		code,
		betSlipNumber,
		userId,
		owner,
	}: Omit<OpenCashoutWithQrCodeModalType, "isChangeAccepted" & "code"> & {
		isChangeAccepted: boolean;
		code: number;
	}) {
		this.isCashoutFormSubmitting = true;

		if (isLive) {
			this.startCountdown();
		}

		try {
			this.closeCashoutWithQrCodeModal();

			const response = await this.betSlipService.cashoutWithQrCode(
				id,
				calculatedCashoutAmount,
				isChangeAccepted,
				isLive,
				code,
				betSlipNumber,
				userId,
				owner
			);

			const betSlip = this.parentStore.betsList.find(
				(betSlip) => betSlip.id === id
			);
			if (betSlip != null) {
				this.updateSlipCashout(betSlip, { isPaidOut: true });
			}
			if (
				this.parentStore.betsPerEvent != null &&
				this.parentStore.betsPerEvent.length !== 0
			) {
				this.parentStore.betsPerEvent.forEach((eventBets) => {
					const betPerEvent = eventBets.bets.find((b) => b.id === id);
					if (betPerEvent != null) {
						this.updateSlipCashout(betPerEvent, {
							isPaidOut: true,
						});
					}
				});
			}

			var gainValue = response.betSlip.payout ?? response.betSlip.gain;

			if (
				response.betSlipProcessingResponse &&
				response.betSlip.shopAddress === null
			) {
				App.state.rootStore.notificationStore.success(
					response.betSlipProcessingResponse.errorMessage
				);
			} else if (
				response.betSlip !== null &&
				response.betSlip.shopAddress !== null
			) {
				const id = response.betSlip.id;
				const calculatedCashoutAmount =
					response.betSlip.calculatedCashoutAmount;
				const displayedAmount =
					response.betSlip.calculatedCashoutAmount.toFixed(2);
				const isLive = response.betSlip.isLive;
				const code = response.betSlip.code;
				const message =
					response.betSlipProcessingResponse != null
						? response.betSlipProcessingResponse.errorMessage
						: null;
				const shopAddress = response.betSlip.shopAddress;
				const betSlipNumber = response.betSlip.betSlipNumber;
				const userId = response.betSlip.userId;
				const owner =
					App.state.rootStore.userAuthStore.getUserTypeFromBettingAccountTypeId(
						response.betSlip.bettingAccountTypeId
					);
				this.openAnonymousCashoutWithQrCodeSuccessModal({
					id,
					calculatedCashoutAmount,
					displayedAmount,
					cType,
					isLive,
					code,
					message,
					shopAddress,
					betSlipNumber,
					userId,
					owner,
				});
			} else {
				App.state.rootStore.notificationStore.success(
					localizationService.t("MY_BETS.CASHOUT.SUCCESS_MESSAGE", {
						amount: gainValue.toFixed(2),
						currency: ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY),
					})
				);
			}

			if (
				getUserFromLocalStorage()?.id != undefined &&
				response.betSlip.shopAddress === null
			) {
				App.state.rootStore.userAuthStore.resolveBalance();
			}
			AnalyticsService.logBetSlipInteraction(
				BetSlipInteractionTypeEnum.Cashout
			);
		} catch (err) {
			console.log(err);
			var errorMessage = localizationService.t(
				"MY_BETS.CASHOUT.FAILURE_MESSAGE"
			);
			if (err.data == null) {
				App.state.rootStore.notificationStore.error(errorMessage);
			} else {
				var error = err.data;
				if (error.betSlipProcessingResponse != null) {
					errorMessage = error.betSlipProcessingResponse.errorMessage;
				} else if (error.validationResponse != null) {
					errorMessage = error.validationResponse.errorMessage;
				}
				errorMessage = errorMessage.replaceAll(
					"{currency}",
					ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)
				);
				const errorCode = error.betSlipProcessingResponse.errorCode;
				if (errorCode === 400082) {
					App.state.rootStore.notificationStore.error(
						error.betSlipProcessingResponse.errorMessage
					);
				} else if (errorCode === 400262) {
					error.betSlipProcessingResponse.errorMessage =
						error.betSlipProcessingResponse.errorMessage.replace(
							"{0}",
							DateTime.fromISO(
								error.betSlipProcessingResponse
									.previouslyClosedAt
							).toFormat("f")
						);
					error.betSlipProcessingResponse.errorMessage =
						error.betSlipProcessingResponse.errorMessage.replace(
							"{1}",
							DateTime.fromISO(
								error.betSlipProcessingResponse.nextOpening
							).toFormat("f")
						);
				} else if (errorCode === 400083) {
					const id = error.betSlip.id;
					const calculatedCashoutAmount =
						error.betSlip.calculatedCashoutAmount;
					const displayedAmount =
						error.betSlip.calculatedCashoutAmount.toFixed(2);
					const isLive = error.betSlip.isLive;
					const code = error.betSlip.code;
					const message = errorMessage;
					const betSlipNumber = error.betSlip.betSlipNumber;
					const userId = error.betSlip.userId;
					const owner =
						App.state.rootStore.userAuthStore.getUserTypeFromBettingAccountTypeId(
							error.betSlip.bettingAccountTypeId
						);
					this.openCashoutWithQrCodeModal({
						id,
						calculatedCashoutAmount,
						displayedAmount,
						cType,
						isLive,
						code,
						message,
						betSlipNumber,
						userId,
						owner,
					});
				} else if (
					errorCode ===
						DocumentExpirationErrorCodes.UserDocumentExpirationDateExpired ||
					errorCode ===
						DocumentExpirationErrorCodes.UserDocumentExpirationDateNotSet
				) {
					App.state.rootStore.AccountVerificationStore.showPopup(
						"account-settings/document-expiration",
						"DOCUMENT_EXPIRATION.TITLE",
						errorMessage
					);
				} else if (errorCode === 400095) {
					App.state.rootStore.notificationStore.error(
						error.betSlipProcessingResponse.errorMessage
					);
				} else if (errorCode === 400297) {
					App.state.rootStore.notificationStore.error(
						error.betSlipProcessingResponse.errorMessage
					);
				} else if (errorCode === 400080) {
					App.state.rootStore.notificationStore.error(
						error.betSlipProcessingResponse.errorMessage
					);
				} else {
					App.state.rootStore.notificationStore.error(
						localizationService.t("MY_BETS.CASHOUT.FAILURE_MESSAGE")
					);
				}
			}
		} finally {
			this.stopCountDown();
			await this.refreshWithQrCode();

			runInAction(() => {
				this.isCashoutFormSubmitting = false;
			});
		}
	}

	@action.bound
	openCashoutWithQrCodeModal(modalData: OpenCashoutWithQrCodeModalType) {
		this.isCashoutWithQrCodeModalOpen = true;
		this.cashoutWithQrCodeModalData = modalData;
	}

	@action.bound
	closeCashoutWithQrCodeModal() {
		this.isCashoutWithQrCodeModalOpen = false;
		this.cashoutWithQrCodeModalData = null;
	}

	@action.bound
	closeAnonymousCashoutWithQrCodeSuccessModal() {
		this.isAnonymousCashoutWithQrCodeSuccessModalOpen = false;
		this.cashoutWithQrCodeModalData = null;
	}

	@action.bound
	openAnonymousCashoutWithQrCodeSuccessModal(
		modalData: OpenCashoutWithQrCodeModalType
	) {
		this.isAnonymousCashoutWithQrCodeSuccessModalOpen = true;
		this.cashoutWithQrCodeModalData = modalData;
	}

	@action.bound
	async refreshWithQrCode() {
		clearTimeout(this.refreshDebounceId);
		this.refreshDebounceId = setTimeout(async () => {
			this.parentStore.refreshWithQrCode?.();
		}, 500);
	}

	public redirectToLogin = (redirectToCurrentUrl = false) => {
		let url = "";
		if (redirectToCurrentUrl) {
			const currentUrl = window.location.pathname.slice(3);
			url = `/${getCurrentCulture()}/auth/login?returnUrl=${currentUrl}`;
		} else {
			url = `/${getCurrentCulture()}/auth/login?returnUrl=/home/full`;
		}

		App.state.redirect(url);
	};

	//#endregion cashout with QR code

	//#region cashout history

	@action.bound
	async fetchCashoutHistoryForBetSlip(bet: NonNullable<CashoutStore["bet"]>) {
		ModalService.onOpen();
		this.isCashoutHistoryOpen = true;
		this.cashoutHistorySlipId = bet.betSlipNumber;
		this.bet = bet;
		try {
			const cashOutHistory = await this.betSlipService.getCashoutHistory(
				bet.id,
				this.parentStore.activeAccountOwner
			);
			runInAction(() => {
				this.cashoutHistory = cashOutHistory;
			});
		} catch (error) {
			console.error(error);
			// close in case of error
			this.closeCashoutHistory();
		}
	}

	@action.bound
	closeCashoutHistory() {
		this.isCashoutHistoryOpen = false;
		this.cashoutHistory = null;
		this.bet = null;
	}

	//#endregion cashout history

	//#region disposers

	@action.bound
	disposeSubscription(eventId: string | null = null) {
		const idToUse = eventId != null ? eventId : "my-bets";

		if (this.subscriptionIds != null) {
			const subscriptionIdx = this.subscriptionIds.findIndex((subscr) =>
				subscr.id.includes(idToUse)
			);

			if (subscriptionIdx > -1) {
				this.subscriptionIds[
					subscriptionIdx
				].subscription.unsubscribe();
				this.subscriptionIds.splice(subscriptionIdx, 1);
			}
		}
		// if (this.subscription != null) {
		// 	this.subscription.unsubscribe();
		// 	this.subscription = null;
		// }
	}

	@action.bound
	disposeAllSubscriptions() {
		if (this.subscriptionIds != null && this.subscriptionIds.length > 0) {
			this.subscriptionIds.forEach((subscr) =>
				subscr.subscription.unsubscribe()
			);
			this.subscriptionIds = [];
		}
	}

	@action.bound
	onDispose() {
		this.disposeAllSubscriptions();
		this.isStoreInitialized = false;

		this.isCashoutModalOpen = false;
		this.cashoutModalData = null;
		this.isCashoutFormSubmitting = false;

		this.countDownDuration = 0;
		this.countdownCounter = 0;
		this.isCountdownVisible = false;

		this.cashoutHistory = null;
		this.cashoutHistorySlipId = null;
		this.isCashoutHistoryOpen = false;
	}

	//#endregion disposers

	//#region  auto sell

	@action.bound
	openAutosellModal(
		bet: {
			id: CashoutStore["openedAutosellModalBetSlipId"];
			calculatedCashoutAmount: Parameters<
				CashoutStore["_setMinThreshold"]
			>[0];
			maxGain: Parameters<CashoutStore["_setMinThreshold"]>[1];
			gainBonus: Parameters<CashoutStore["_setMaxThreshold"]>[1];
			betSlipSecureWinSetting?: BetSlipSecureWinSetting | null;
		},
		isEventPage: boolean = false
	) {
		ModalService.onOpen();
		if (isEventPage) {
			this.isEventAutosellModalOpen = true;
		} else {
			this.isAutosellModalOpen = true;
		}
		this._currentCashoutValue = bet.calculatedCashoutAmount;
		this.openedAutosellModalBetSlipId = bet.id;

		this._setMinThreshold(bet.calculatedCashoutAmount, bet.maxGain);
		this._setMaxThreshold(bet.maxGain, bet.gainBonus);

		if (
			this.minThreshold !== undefined &&
			this.minThreshold !== null &&
			this.minThreshold !== "" &&
			this.maxThreshold !== undefined &&
			this.maxThreshold !== null &&
			this.maxThreshold !== "" &&
			Number(this.minThreshold) >= Number(this.maxThreshold)
		) {
			this.isAutocashoutUnavailable = true;
			this.isSaveBtnDisabled = true;
			this.isAutosellFormValid = false;
		} else {
			if (bet && bet.betSlipSecureWinSetting) {
				this.isConfigurationExists = true;
				this.secureWinAmount =
					bet.betSlipSecureWinSetting.threshold.toFixed(2);
			} else {
				this.isConfigurationExists = false;
				this.secureWinAmount = "";
			}
		}
	}

	@action.bound
	closeAutosellModal() {
		this.isAutosellModalOpen = false;
		this.isEventAutosellModalOpen = false;
		this.minThreshold = "";
		this.maxThreshold = "";
		this.secureWinAmount = "";
		this._currentCashoutValue = null;
		this.openedAutosellModalBetSlipId = null;
		this.isAutocashoutUnavailable = false;
		this.isSaveBtnDisabled = true;
		this.isAutosellFormValid = true;
		this.isMinThresholdValid = true;
		this.isMaxThresholdValid = true;
		this.isAutosellModalCashedOut = false;
		this.autosellModalCashoutGain = null;
		this._isSecureWinAmountInputDirty = false;
		this.isConfigurationExists = false;
	}

	@action.bound
	async setSecureWinConfiguration(id: string, threshold: number) {
		try {
			const response = await (
				await MyBetsService
			).default.setSecureWinConfiguration(
				id,
				threshold,
				this.parentStore.activeAccountOwner
			);

			if (
				response &&
				response.betSlipSecureWinSetting &&
				response.betSlipSecureWinSetting.threshold
			) {
				const betSlip = this.parentStore.betsList.find(
					(b) => b.id === id
				);

				if (betSlip !== null && betSlip !== undefined) {
					betSlip.betSlipSecureWinSetting =
						response.betSlipSecureWinSetting;
					betSlip.isAutoCashoutSet = true;
				}

				if (
					this.parentStore.betsPerEvent != null &&
					this.parentStore.betsPerEvent.length !== 0
				) {
					this.parentStore.betsPerEvent.forEach((eventBets) => {
						const betPerEvent = eventBets.bets.find(
							(b) => b.id === id
						);
						if (betPerEvent != null) {
							betPerEvent.betSlipSecureWinSetting =
								response.betSlipSecureWinSetting;
							betPerEvent.isAutoCashoutSet = true;
						}
					});
				}

				App.state.rootStore.notificationStore.success(
					localizationService.t(
						"MY_BETS.CASHOUT.SECURE_WIN_SET_SUCCESS_MESSAGE"
					)
				);
			}

			if (
				response &&
				!response.betSlipSecureWinSetting &&
				response.betSlipSecureWinSettingValidation
			) {
				switch (response.betSlipSecureWinSettingValidation.errorCode) {
					case 123:
						this.isAutocashoutUnavailable = true;
						break;

					// @ts-expect-error
					case (181, 144):
						this.minThreshold =
							response.betSlipSecureWinSettingValidation.minThreshold.toFixed(
								2
							);
						this.maxThreshold =
							response.betSlipSecureWinSettingValidation.maxThreshold.toFixed(
								2
							);

						this.validateThreshold();
						break;
				}
			}
		} catch (error) {
			logger.logError(
				"Failed to set auto sell secure win configuration.",
				error
			);
			this.closeAutosellModal();

			if (error.data.errorCode === 400241) {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"MY_BETS.CASHOUT.AUTO_CASHOUT_UNAVAILABLE"
					)
				);
			} else {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"MY_BETS.CASHOUT.SECURE_WIN_SET_ERROR_MESSAGE"
					)
				);
			}
		} finally {
			this.closeAutosellModal();
		}
	}

	@action.bound
	async removeSecureWinConfiguration(id: string) {
		try {
			await (
				await MyBetsService
			).default.removeSecureWinConfiguration(
				id,
				this.parentStore.activeAccountOwner
			);

			const betSlip = this.parentStore.betsList.find((b) => b.id === id);

			if (betSlip !== null && betSlip !== undefined) {
				betSlip.betSlipSecureWinSetting = null;
				betSlip.isAutoCashoutSet = false;
			}

			if (
				this.parentStore.betsPerEvent != null &&
				this.parentStore.betsPerEvent.length !== 0
			) {
				this.parentStore.betsPerEvent.forEach((eventBets) => {
					const betPerEvent = eventBets.bets.find((b) => b.id === id);
					if (betPerEvent != null) {
						betPerEvent.betSlipSecureWinSetting = null;
						betPerEvent.isAutoCashoutSet = false;
					}
				});
			}

			App.state.rootStore.notificationStore.success(
				localizationService.t(
					"MY_BETS.CASHOUT.SECURE_WIN_DELETE_SUCCESS_MESSAGE"
				)
			);
		} catch (error) {
			logger.logError(
				"Failed to remove auto sell secure win configuration.",
				error
			);

			if (error.data.errorCode === 400241) {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"MY_BETS.CASHOUT.AUTO_CASHOUT_UNAVAILABLE"
					)
				);
			} else {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"MY_BETS.CASHOUT.SECURE_WIN_DELETE_ERROR_MESSAGE"
					)
				);
			}
		} finally {
			this.closeAutosellModal();
		}
	}

	// validation: "current cashout value" <= "threshold" <= "max gain"
	@action.bound
	handleChange(e: Omit<Event, "target"> & { target: HTMLInputElement }) {
		// sanitizing threshold value: prevent more than 2 decimal places after "."
		let value = e.target?.value;
		const decimal = value.split(".")[1];

		if (decimal && decimal.length > 2) {
			// @ts-expect-error
			value = Number(e.target?.value).toFixedNoRound(2);
		}

		// @ts-expect-error
		if (value === "" || value === NaN || value === undefined) {
			this.secureWinAmount = "";
		} else {
			this.secureWinAmount = value;
		}

		this._isSecureWinAmountInputDirty = true;

		this.validateThreshold();
	}

	@action.bound
	updateAutoSellValues(newCashoutValue: number, maxGain: number) {
		this._currentCashoutValue = newCashoutValue;

		this._setMinThreshold(newCashoutValue, maxGain);

		if (this.secureWinAmount !== "") {
			this.validateThreshold();
		}
	}

	@action.bound
	validateThreshold() {
		const secureWinAmount = Number(this.secureWinAmount);
		const minThreshold = Number(this.minThreshold);
		const maxThreshold = Number(this.maxThreshold);

		if (secureWinAmount < minThreshold) {
			this.isMinThresholdValid = false;
		} else {
			this.isMinThresholdValid = true;
		}

		if (secureWinAmount > maxThreshold) {
			this.isMaxThresholdValid = false;
		} else {
			this.isMaxThresholdValid = true;
		}

		if (this._isSecureWinAmountInputDirty) {
			this.isAutosellFormValid =
				this.isMinThresholdValid && this.isMaxThresholdValid;
			this.isSaveBtnDisabled = !this.isAutosellFormValid;
		} else {
			this.isSaveBtnDisabled = true;
		}
	}

	@action.bound
	_setMinThreshold(cashoutValue: number, maxGain: number) {
		let increaseValue =
			(maxGain - cashoutValue) *
			this._minSecureWinThresholdIncreasePercentage;

		if (increaseValue > 2) {
			increaseValue = 2;

			this.minThreshold = Math.round(
				cashoutValue + increaseValue
			).toFixed();
		} else {
			this.minThreshold = (
				Math.round((cashoutValue + increaseValue) * 10) / 10
			).toFixed(1);
		}
	}

	@action.bound
	_setMaxThreshold(maxGain: number, gainBonus: number) {
		let maxGainTmp = maxGain;

		if (gainBonus !== null && gainBonus !== undefined) {
			maxGainTmp -= gainBonus;
		}

		this.maxThreshold = (
			Math.round(maxGainTmp * 1000) / 1000 -
			0.01
		).toFixed(2);
	}

	//#endregion  auto sell

	private isCashedOut(update: {
		isPaidOut?: boolean | null;
		cType?: CashoutType | null;
		cashoutGain?: number | null;
	}) {
		return (
			update.isPaidOut &&
			update.cType === CashoutType.JACKTIME &&
			update.cashoutGain !== undefined &&
			(update.cashoutGain || 0) > 0
		);
	}
}
