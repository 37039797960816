import { action, observable } from "mobx";
import RootAppStore from "@state/stores/RootStore";

class BasePopupStore {
	@observable public isActive = false;
	protected timerId: ReturnType<typeof setTimeout> | undefined = undefined;

	rootStore: RootAppStore;

	constructor(rootStore: RootAppStore) {
		this.rootStore = rootStore;
	}

	@action.bound
	activatePopup() {
		this.isActive = true;
	}

	@action.bound
	deactivatePopup() {
		this.isActive = false;
	}

	clearTimer() {
		if (this.timerId != null) {
			clearTimeout(this.timerId);
			this.timerId = undefined;
		}
	}
}

export default BasePopupStore;
