import React from "react";
import { FormInputField } from "../../shared/inputs";
import { Button } from "../../shared/buttons";
import { usePersonalDataPayPinSection } from "@v2/hooks/account-settings/personal-data";
import { isNullOrWhitespace } from "@v2/helpers/utility";

class PersonalDataPayPinSectionPropType {
	payPin: string;
	updateUserPin: (currPayPin: string) => Promise<Error | null>;
}

export default function PersonalDataPayPinSection(
	props: PersonalDataPayPinSectionPropType
) {
	const { payPin } = props;
	const {
		newPayPin,
		getNewPin,
		handleSavePin,
		handleCancelPin,
		shouldHidePayPinSection,
	} = usePersonalDataPayPinSection(payPin);

	if (shouldHidePayPinSection(payPin)) {
		return null;
	}

	return (
		<div className="form__group">
			<div className="form--grid__row row">
				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PAYOUT_CODE_LABEL"
					className="form--grid__col col col-sml-6 form__field"
					name="payPin"
					readOnly
				/>
				<div className="form--grid__col col col-sml-6 form__field">
					<div>
						{!newPayPin ? (
							<Button
								className="btn btn btn--base btn--secondary--inverted"
								btnType="button"
								onClick={getNewPin}
								btnText="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PAYOUT_CODE"
							/>
						) : (
							<div>
								<Button
									className="btn btn--base btn--primary u-mar--right--tny"
									btnType="button"
									onClick={handleSavePin}
									btnText="COMMON.SAVE_BUTTON"
								/>

								<Button
									className="btn btn--base btn--secondary--inverted"
									btnType="button"
									onClick={handleCancelPin}
									btnText="COMMON.CANCEL_BUTTON"
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
