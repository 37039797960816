import { StorageStateKeysEnum } from "./StorageTypes";
import { ILocalStorageProvider } from "./StorageTypes";
export default class LocalStorage implements ILocalStorageProvider {
	initialLoad = async () => {
		//this is not used on local storage for web
	};

	get = (key: StorageStateKeysEnum): unknown | null => {
		return localStorage.getItem(key);
	};

	set = (key: StorageStateKeysEnum, value: any): void => {
		if (value == null || value == "") {
			localStorage.removeItem(key);
			return;
		}
		localStorage.setItem(key, value);
	};

	remove(key: StorageStateKeysEnum): void {
		localStorage.removeItem(key);
	}

	flush(): void {
		localStorage.clear();
	}
}
