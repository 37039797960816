import React, { FC, useEffect, useState } from "react";
import { useRealityCheckPopup } from "@v2/helpers/shared";
import { useFixBody } from "@v2/hooks/shared";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { isCasinoEnabled, isLiveCasinoEnabled } from "@v2/helpers";

export default function RealityCheckPopup(props: { isOpened: boolean }) {
	const { t } = useTranslation();
	const { fetchedData, timePlayedData, resume, logout, onInitialize } =
		useRealityCheckPopup();

	useFixBody(true, props.isOpened);
	const acceptGameResumeRef = useRef<HTMLInputElement>(null);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	useEffect(() => {
		onInitialize();
	}, []);

	if (fetchedData == null || timePlayedData == null) {
		return null;
	}
	const setAcceptGameResume = (): void => {
		if (
			acceptGameResumeRef != null &&
			acceptGameResumeRef.current != null
		) {
			setIsSubmitting(acceptGameResumeRef.current.checked);
		}
	};

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					{t<string>("SESSION.REALITY_CHECK.REALITY_CHECK")}
				</div>
				<div className="popup__body">
					<TimePlayedData timePlayedData={timePlayedData} />

					<DataTable
						data={fetchedData.sportBettingOnlineInfo}
						isAllowed={true}
						title={"HEADER.SPORT-BETTING-ACCOUNT-ONLINE"}
					/>

					<DataTable
						data={fetchedData.sportBettingInfo}
						isAllowed={true}
						title={"HEADER.SPORT-BETTING-ACCOUNT-SHOP-ONLINE"}
					/>

					<DataTable
						data={fetchedData.casinoInfo}
						isAllowed={isCasinoEnabled}
						title={"specific:CASINO.TITLE"}
					/>

					<DataTable
						data={fetchedData.casinoLiveInfo}
						isAllowed={isLiveCasinoEnabled}
						title={"specific:LIVE_CASINO.TITLE"}
					/>

					<div>
						<input
							ref={acceptGameResumeRef}
							onChange={() => setAcceptGameResume()}
							id="acceptGameResume"
							className="input--check"
							name="acceptGameResume"
							type="checkbox"
						/>
						<label htmlFor="acceptGameResume">
							{t<string>(
								"SESSION.REALITY_CHECK.ACCEPT_GAME_RESUME"
							)}
						</label>
					</div>
				</div>
				<div className="popup__footer">
					<button
						className="popup__footer__btn btn btn--sml btn--secondary--inverted"
						onClick={logout}
					>
						{t<string>("SESSION.REALITY_CHECK.LOGOUT_BUTTON")}
					</button>
					<button
						className="popup__footer__btn btn btn--sml btn--primary"
						onClick={resume}
						disabled={!isSubmitting}
						type="submit"
					>
						{t<string>("SESSION.REALITY_CHECK.RESUME_BUTTON")}
					</button>
				</div>
			</div>
		</div>
	);
}

const TimePlayedData: FC<{
	timePlayedData: { hours: number; minutes: number };
}> = function TimePlayedData(props: {
	timePlayedData: { hours: number; minutes: number };
}) {
	const { timePlayedData } = props;
	const { t } = useTranslation();

	return (
		<>
			<div className="row u-mar--bottom--xsml">
				<div className="col col-sml-6">
					{t<string>("SESSION.REALITY_CHECK.TIME_PLAYED")}:
				</div>
				<div className="col col-sml-6 u-type--color--primary">
					<strong>
						{t<string>("SESSION.REALITY_CHECK.TIME_PLAYED_VALUE", {
							0: timePlayedData.hours,
							1: timePlayedData.minutes,
						})}
					</strong>
				</div>
			</div>
		</>
	);
};

const DataTable: FC<{ data: any; title: string; isAllowed: boolean }> =
	function DataTable(props) {
		const { data, title, isAllowed } = props;

		const { t } = useTranslation();

		if (data == null || !isAllowed) {
			return null;
		}

		return (
			<table className="table--primary u-mar--bottom--xsml u-type--base">
				<thead className="table--primary__header table--primary__header--light">
					<tr className="table--primary__header__row">
						<th
							className="table--primary__header__data"
							colSpan={2}
						>
							{t<string>(title)}
						</th>
					</tr>
				</thead>
				<tbody className="table--primary__body">
					<tr className="table--primary__body__row">
						<td className="table--primary__body__data">
							{t<string>("SESSION.REALITY_CHECK.NUM_BETS")}:
						</td>
						<td className="table--primary__body__data">
							{data.numberOfBets}
						</td>
					</tr>
					<tr className="table--primary__body__row">
						<td className="table--primary__body__data">
							{t<string>("SESSION.REALITY_CHECK.GAIN")}:
						</td>
						<td className="table--primary__body__data">
							{data.gain}
						</td>
					</tr>
					<tr className="table--primary__body__row">
						<td className="table--primary__body__data">
							{t<string>("SESSION.REALITY_CHECK.LOSS")}:
						</td>
						<td className="table--primary__body__data">
							{data.loss}
						</td>
					</tr>
					<tr className="table--primary__body__row">
						<td className="table--primary__body__data">
							{t<string>("SESSION.REALITY_CHECK.STAKE")}:
						</td>
						<td className="table--primary__body__data">
							{data.stake}
						</td>
					</tr>
				</tbody>
			</table>
		);
	};
