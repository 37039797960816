import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useRootAppStore } from "@hooks";

export default observer(function ChatToggleButton() {
	const { chatViewStore } = useRootAppStore();

	const toggleChatWindow = () => {
		chatViewStore.toggleChatBox();
	};

	return (
		<button
			className={classNames(
				"chat__toggle btn btn--round btn--round--lrg btn--icon",
				{
					"chat__toggle--active": chatViewStore.chatBoxVisible,
				}
			)}
			onClick={toggleChatWindow}
		>
			<i
				className={classNames(
					"u-icon u-icon--big u-color--core--snow",
					{
						"u-icon--chat--active": !chatViewStore.chatBoxVisible,
						"u-icon--chat--selected": chatViewStore.chatBoxVisible,
					}
				)}
			/>
		</button>
	);
});
