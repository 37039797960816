import React from "react";
import { Switch } from "react-router-dom";
import { Footer } from "@v2/components/desktop/shared/footer";
import { Header } from "@v2/components/desktop/shared/header";
import { NoMatchRoute, RouteElement } from "@v2/components/shared/navigation";

import { getCurrentCulture } from "@utils";

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
import GoogleRecaptchaWrapper from "@v2/components/shared/GoogleRecaptchaWrapper";
const loadFailPath = `/${getCurrentCulture()}/app-update`;
//#region lazy imports
// prettier-ignore
const AboutUsPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/about-us/AboutUsPage"));
// prettier-ignore
const BettingRulesPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/betting-rules/BettingRulesPage"));
// prettier-ignore
const BonusTermsPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/bonus-terms/BonusTermsPage"));
// prettier-ignore
const ContactPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/contact/ContactPage"));
// prettier-ignore
const HelpPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/help/HelpPage"));
// prettier-ignore
const OurPartnersPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/our-partners/OurPartnersPage"));
// prettier-ignore
const PrivacyPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/privacy/PrivacyPage"));
// prettier-ignore
const ResponsibleGamingPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/responsible-gaming/ResponsibleGamingPage"));
// prettier-ignore
const TermsAndConditionsPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/terms-and-conditions/TermsAndConditionsPage"));
//prettier-ignore
const PlayerMoneyProtectionPage = lazy(loadFailPath, () => import("@v2/components/desktop/info-pages/player-money-protection/PlayerMoneyProtectionPage"));
// prettier-ignore
const PaymentOptionsPage = lazy(loadFailPath, () => import("@v2/components/desktop/payment-options/PaymentOptionsPage"));
// prettier-ignore
/* const NewsPage = lazy(loadFailPath, () => import("@features/cms/news/desktop/page/NewsPage")); */
//#endregion lazy imports

export default function InfoPagesLayoutTemplate() {
	return (
		<>
			<Header />
			<main className="l--main l--main--full">
				<section className="l--content l--content--full">
					<div className="l--content__main">
						<div className="container">
							<Switch>
								<RouteElement
									exact
									path="/:culture/info/about-us"
								>
									<AboutUsPage />
								</RouteElement>

								<RouteElement exact path="/:culture/info/help">
									<HelpPage />
								</RouteElement>
								<RouteElement
									exact
									path="/:culture/info/contact"
								>
									<ContactPage />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/info/betting-rules"
								>
									<BettingRulesPage />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/info/bonus-terms"
								>
									<BonusTermsPage />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/info/our-partners"
								>
									<OurPartnersPage />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/info/privacy"
								>
									<PrivacyPage />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/info/responsible-gaming"
								>
									<ResponsibleGamingPage />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/info/terms-and-conditions"
								>
									<TermsAndConditionsPage />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/info/payment-options"
								>
									<PaymentOptionsPage />
								</RouteElement>

								<RouteElement path="/:culture/info/player-money-protection">
									<PlayerMoneyProtectionPage />
								</RouteElement>

								{/* 	<RouteElement
									exact
									path="/:culture/info/news/:newsLocalizationId"
								>
									<NewsPage />
								</RouteElement> */}
								<NoMatchRoute />
							</Switch>
						</div>
					</div>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</>
	);
}
