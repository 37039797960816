import React from "react";
import { FormSelectField } from "@v2/components/desktop/shared/inputs";
import { mapLanguagesForFormSelect } from "@v2/helpers/membership/registration/utils";
import { LanguageDataType } from "@data-types/membership/registration";

export default function NameInput(props: {
	languageData: LanguageDataType[] | null;
	labelClassName?: string;
}) {
	const { languageData, labelClassName } = props;

	return (
		<FormSelectField
			label="MEMBERSHIP.REGISTRATION.CONTACT_LANGUAGE"
			labelClassName={labelClassName}
			name="cultureInfoName"
			dropdownData={mapLanguagesForFormSelect(languageData)}
			className="form__field"
			required
		/>
	);
}
