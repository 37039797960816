import React from "react";
import { useTranslation } from "react-i18next";

export default function BettingActivityLoader() {
	const { t } = useTranslation();

	return (
		<div className="loader loader--full">
			<div className="loader__card">
				<div className="loader__spinner u-display--b" />
				<div className="loader__title u-type--color--white">
					{t<string>("HOME.LOADING")}...
				</div>
			</div>
		</div>
	);
}
