import { observable, action, runInAction } from "mobx";
import { BettingActivity } from "@services/betting-activity/BettingActivityService";
import { getCurrentCulture } from "@utils";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const BettingActivityService = lazy(loadFailPath, ()=>import("@services/betting-activity/BettingActivityService"));

class BettingActivityStore {
	@observable bettingActivityFetchedData: BettingActivity | null = null;
	@observable isLoading = false;
	@observable bettingActivityError = null;

	@action.bound
	setIsLoading(data: boolean) {
		this.isLoading = data;
	}

	@action.bound
	setBettingActivityError(error: any) {
		this.bettingActivityError = error;
	}

	@action.bound
	async fetchBettingActivityData() {
		try {
			this.setIsLoading(true);
			const response = await (
				await BettingActivityService
			).default.getBettingActivityData();
			runInAction(() => {
				this.bettingActivityFetchedData = response?.data;
			});
		} catch (error) {
			console.error(error);
			this.bettingActivityError = error;
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async updateBettingActivityReview() {
		try {
			await (
				await BettingActivityService
			).default.updateBettingActivityReview();
		} catch (error) {
			console.error(error);
		}
	}
}

export default BettingActivityStore;
