import { DateTime } from "luxon";

import { GamingLimitCategoryService } from "@api-services/account-settings/GamingLimitCategoryService";
import { GamingLimitHistoryApiService } from "@api-services/account-settings/GamingLimitHistoryApiService";
import { fromToDates, AccountTypes, UserTypes } from "@utils";
import {
	GamingLimitHistoryFilter,
	GamingLimitHistoryDto,
} from "@api-types/account-settings/GamingLimitHistoryDto";
import {
	FromToDates,
	LimitHistoryViewModel,
	GamingLimitCategoryViewModel,
	GamingLimitHistoryFormSubmit,
	LimitHistoryViewModelItem,
} from "@data-types";
import LookupApiService from "@api-services/LookupApiService";

export class LimitsHistoryService {
	static async getLimitHistoryData(
		viewModel: GamingLimitHistoryFormSubmit,
		activeAccount: AccountTypes = AccountTypes.OFFLINE
	): Promise<LimitHistoryViewModel | null> {
		try {
			const dates = fromToDates(viewModel.period);
			const filter = this.getGamingLimitFilter(
				dates,
				viewModel.gamingTypeId,
				viewModel.page
			);
			const userType =
				App.state.rootStore.userAuthStore.getUserAccountType(
					activeAccount
				);
			const response = await this.getGamingLimitHistoryWithFilter(
				filter,
				userType
			);
			return this.mapToViewModel(viewModel, response);
		} catch (error) {
			if (error.message == "Unauthorized") {
				return null;
			}
			throw "GENERIC_ERROR";
		}
	}

	static async getGamingLimitCategories(
		activeAccount: AccountTypes = AccountTypes.OFFLINE
	): Promise<GamingLimitCategoryViewModel[]> {
		const gamingLimitCategories = [];
		const userType =
			App.state.rootStore.userAuthStore.getUserAccountType(activeAccount);

		const limitCategories =
			await LookupApiService.fetchGamingLimitCategoriesLookup(userType);
		const userAvailableLimitCategories =
			await GamingLimitCategoryService.getAvailableCategories(userType);

		const categories = userAvailableLimitCategories.limitCategories
			.map((lc) => {
				const limitCategoryId = limitCategories.item.find(
					(limitLookup) =>
						lc.gamingLimitCategory.abrv === limitLookup.abrv
				)?.id;

				if (limitCategoryId == null) {
					//if abrv not found in lookup, do not render category in select
					return null;
				}

				return {
					name: lc.gamingLimitCategory.name,
					id: limitCategoryId,
				};
			})
			.filter(<T>(i: T | null): i is T => i != null);

		gamingLimitCategories.push(...categories);

		gamingLimitCategories.push({
			name: "SHOW_ALL",
			id: "default",
		});

		return gamingLimitCategories;
	}

	static getGamingLimitFilter(
		dates: FromToDates,
		gamingLimitTypeIds = "",
		page = 1,
		sort = "dateCreated|desc"
	): GamingLimitHistoryFilter {
		const filter: GamingLimitHistoryFilter = {};
		filter.embed =
			"gamingLimitDuration,gamingLimitType,currencyDisplayCode";
		filter.sort = sort;
		filter.page = page;
		filter.rpp = 10;
		if (dates != null) {
			filter.from = dates.from;
			filter.to = dates.to;
		}
		if (
			gamingLimitTypeIds != "" &&
			typeof gamingLimitTypeIds === "string"
		) {
			filter.gamingLimitTypeIds = gamingLimitTypeIds;
		}
		return filter;
	}

	static async getGamingLimitHistoryWithFilter(
		filter: GamingLimitHistoryFilter,
		activeAccount: UserTypes = UserTypes.PRIMARY
	): Promise<GamingLimitHistoryDto> {
		let filterOptions;
		let apiUrl;

		if (
			filter.gamingLimitTypeIds == undefined ||
			filter.gamingLimitTypeIds == "default"
		) {
			filterOptions = `page=${filter.page}&rpp=${filter.rpp}&sort=${filter.sort}&embed=${filter.embed}`;
		} else {
			filterOptions = `page=${filter.page}&rpp=${filter.rpp}&sort=${filter.sort}&embed=${filter.embed}&gamingLimitCategoryIds=${filter.gamingLimitTypeIds}`;
		}

		if (filter.from != null && filter.from != "" && filter.to == null) {
			apiUrl = `?from=${filter.from}&translate=True&${filterOptions}`;
		} else if (
			filter.from == null ||
			(filter.from == "" && filter.to != null)
		) {
			apiUrl = `?to=${filter.to}&translate=True&${filterOptions}`;
		} else if (
			filter.from != null &&
			filter.from != "" &&
			filter.to != null
		) {
			apiUrl = `?from=${filter.from}&to=${filter.to}&translate=True&${filterOptions}`;
		}

		return await GamingLimitHistoryApiService.getGamingLimitHistory(
			apiUrl,
			activeAccount
		);
	}

	static mapToViewModel(
		viewModel: GamingLimitHistoryFormSubmit,
		pagedHistory: GamingLimitHistoryDto
	): LimitHistoryViewModel & ViewModelAdditionalTypes {
		return {
			...viewModel,
			totalRecords: pagedHistory.totalRecords,
			page: pagedHistory.page,
			recordsPerPage: 10,
			items: pagedHistory.item.map((limitsHistoryItem) => {
				return {
					date: DateTime.fromISO(
						limitsHistoryItem.dateCreated
					).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
					limitType: limitsHistoryItem.gamingLimitType.name,
					limitInterval: limitsHistoryItem.gamingLimitDuration.name,
					limitAmount: limitsHistoryItem.amount,
					id: limitsHistoryItem.id,
					currencyDisplayCode:
						limitsHistoryItem.gamingLimitType.abrv !==
						"login-time-limit"
							? limitsHistoryItem.currencyDisplayCode
							: "",
				};
			}),
		};
	}
}

type ViewModelAdditionalTypes = {
	totalRecords: number;
	recordsPerPage: number;
	items: LimitHistoryViewModelItem[];
};
