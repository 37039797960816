import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";

//#region lazy

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
const loadFailPath = `/${getCurrentCulture()}/app-update`;
const ReactMarkdown = lazy(loadFailPath, () => import("react-markdown"));

//#endregion lazy

export default function PopoverMessage(props: {
	showPopoverMsg: boolean;
	setShowPopoverMsg: (value: boolean) => void;
	popoverMsg: string | null;
}) {
	const {
		popoverMsg = null,
		showPopoverMsg = false,
		setShowPopoverMsg,
	} = props;

	if (popoverMsg == null) {
		return null;
	}

	return (
		<>
			<i className="form__field__info u-icon u-icon--sml u-icon--info--alt popover" />
			<PopoverContainer
				showPopoverMsg={showPopoverMsg}
				setShowPopoverMsg={setShowPopoverMsg}
				popoverMsg={popoverMsg}
			/>
		</>
	);
}

function PopoverContainer(props: {
	popoverMsg: string;
	showPopoverMsg: boolean;
	setShowPopoverMsg: (value: boolean) => void;
}) {
	const { t } = useTranslation();

	const { popoverMsg, showPopoverMsg, setShowPopoverMsg } = props;

	if (showPopoverMsg == false) {
		return null;
	}

	if (typeof popoverMsg === "string") {
		return (
			<div className="popup">
				<div className="popup__card">
					<div className="popup__header">
						<div className="popup__title" />
						<button
							className="popup__close btn btn btn--square btn--square--sml btn--secondary--inverted"
							type="button"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setShowPopoverMsg(false);
							}}
						>
							<i className="u-icon u-icon--med u-icon--close" />
						</button>
					</div>
					<div className="popup__body">
						<ReactMarkdown>{t(popoverMsg)}</ReactMarkdown>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					<div className="popup__title" />
					<button
						className="popup__close btn btn btn--square btn--square--sml btn--secondary--inverted"
						type="button"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							setShowPopoverMsg(false);
						}}
					>
						<i className="u-icon u-icon--med u-icon--close" />
					</button>
				</div>
				<div className="popup__body">
					{/* {popoverMsg} */}
					<ReactMarkdown>{popoverMsg}</ReactMarkdown>
				</div>
			</div>
		</div>
	);
}
