import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { SportOffer, TournamentOffer } from "@gp/offer";
import { Category } from "@gp/models";

import { ChatButton, TournamentStatisticsButton } from "../../buttons";
import { TournamentHeaderBettingTypeColumns } from "./";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { useRootAppStore } from "@hooks";
import { useTranslation } from "react-i18next";
import OfferIcon from "@v2/components/shared/icons/Offer";
import { ChannelResourceTypeDto } from "@api-types/chat";

export const TournamentHeader = observer(function TournamentHeader(props: {
	sport: SportOffer;
	category: Category;
	tournament: TournamentOffer;
	setHidden: (value: boolean) => void;
	hidden: boolean;
	isAdditionalOfferVisible?: boolean;
}) {
	return (
		<div
			className={classNames("offer__head offer--sports__head", {
				"is-collapsed": props.hidden,
				"is-expanded": !props.hidden,
			})}
		>
			<div className="offer__head__row">
				<TournamentHeaderName
					sport={props.sport}
					category={props.category}
					tournament={props.tournament}
					isAdditionalOfferVisible={props.isAdditionalOfferVisible}
				/>
				<TournamentHeaderBettingTypeColumns />
				<TournamentHeaderCollapseIndicator
					setHidden={props.setHidden}
					hidden={props.hidden}
					isAdditionalOfferVisible={props.isAdditionalOfferVisible}
				/>
			</div>
		</div>
	);
});

function TournamentHeaderName(props: {
	sport: SportOffer;
	category: Category;
	tournament: TournamentOffer;
	isAdditionalOfferVisible?: boolean;
}) {
	let iconWrapperClasses = classNames("offer__icon", {
		"u-mar--right--xtny": props.isAdditionalOfferVisible,
	});

	return (
		<div
			className="offer__head__data offer__title"
			title={`${props.category.name} - ${props.tournament.name}`}
		>
			<FavoritesIcon tournament={props.tournament} sport={props.sport} />

			<div
				className={classNames("offer__title__wrapper", {
					"offer__title--tournament": !props.sport.isOutright,
				})}
			>
				<div className="offer__name">
					<span className={iconWrapperClasses}>
						<OfferIcon
							type="tournament"
							sport={props.sport.abrv}
							category={props.category.abrv}
							tournament={props.tournament.abrv}
							className="offer__icon__primary u-icon u-icon--lrg"
							catOverSportClass="offer__icon__secondary u-icon u-icon--xmed"
						/>
					</span>

					<span className="offer__name__clip">
						{props.category.name} - {props.tournament.name}
					</span>
				</div>

				{!props.isAdditionalOfferVisible ? (
					<>
						<TournamentStatisticsButton
							tournament={props.tournament}
							isAdditionalOfferVisible={
								props.isAdditionalOfferVisible
							}
						/>

						<ChatButton
							channelType={ChannelResourceTypeDto.TOURNAMENT}
							resourceId={props.tournament.id}
							isInTournamentHeader
						/>
					</>
				) : null}
			</div>
		</div>
	);
}

function TournamentHeaderCollapseIndicator(props: {
	setHidden: (value: boolean) => void;
	hidden: boolean;
	isAdditionalOfferVisible?: boolean;
}) {
	const { setHidden, hidden, isAdditionalOfferVisible } = props;

	const toggleClasses = classNames("offer__head__data offer__toggle", {
		"offer__toggle--sml": isAdditionalOfferVisible,
	});

	const iconIndicatorClasses = classNames(
		"offer__toggle__icon u-icon u-icon--xsml",
		{
			"u-icon--caret--down": !hidden,
			"u-icon--caret--right": hidden,
		}
	);

	return (
		<div className={toggleClasses}>
			<button
				type="button"
				className="offer__toggle__btn btn btn--square btn--square--tny btn--ghost"
				onClick={() => setHidden(!hidden)}
			>
				<i className={iconIndicatorClasses} />
			</button>
		</div>
	);
}

const FavoritesIcon = observer(function FavoritesIcon(props: {
	tournament: TournamentOffer;
	sport: SportOffer;
}) {
	const [isHover, setIsHover] = useState(false);
	const favoriteStore = useContext(FavoritesStoreContext);
	const { t } = useTranslation();
	const rootStateStore = useRootAppStore();

	if (props.sport.isOutright) {
		return null;
	}
	const isFavorite = favoriteStore.userFavoriteTournamentsSet.has(
		props.tournament.id
	);

	const onButtonClick = () => {
		if (isFavorite) {
			favoriteStore.removeUserFavoriteTournament(props.tournament.id);
		} else {
			favoriteStore.addUserFavoriteTournament(props.tournament.id);
		}
	};
	const favIconClasses = classNames("u-icon u-icon--big", {
		"u-icon--favorite--bold u-color--secondary--sunny":
			isFavorite ||
			(!isFavorite && isHover && rootStateStore.userAuthStore.isLoggedIn),
		"u-icon--favorite--alt u-color--ui--muted":
			(!isFavorite && !isHover) ||
			!rootStateStore.userAuthStore.isLoggedIn,
		"is-disabled": !rootStateStore.userAuthStore.isLoggedIn,
	});

	const tooltipMessage = !rootStateStore.userAuthStore.isLoggedIn
		? t("FAVORITES.NONAUTH_TOOLTIP")
		: "";

	return (
		<button
			data-tooltip-id="tv-description"
			data-tooltip-content={tooltipMessage}
			data-tooltip-position-strategy="fixed"
			type="button"
			className="at-addTournamentToFavouritesBtn btn btn--square btn--square--reset btn--ghost u-mar--right--xsml"
			onClick={onButtonClick}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			disabled={!rootStateStore.userAuthStore.isLoggedIn}
		>
			<i className={favIconClasses} />
		</button>
	);
});
