import { computed, action, observable } from "mobx";

import { IStoreLifeCycle, IConsoleLogger } from "@interface";
import { LiveStreamStatus, ProviderNameEnum } from "@gp/models";

import { LiveMatchTrackerState } from "./LiveMatchTrackerState";
import { LiveStreamState } from "./LiveStreamState";
import {
	LMTWidgetElement,
	LSWidgetElement,
	WidgetLocation,
} from "@features/live-widgets/shared";
import { SportAbrv } from "@utils";

export class LiveWidgetStore implements IStoreLifeCycle {
	protected lmtStore: LiveMatchTrackerState;
	protected liveStreamStore: LiveStreamState;

	public logger: IConsoleLogger;
	@observable public eventsWithLsError: string[] = [];
	@observable public isStoreInitialized: boolean = false;
	@observable public widgetsAreSuspended: boolean = false;

	//#region  constructor

	constructor(logger: IConsoleLogger) {
		this.logger = logger;
		this.lmtStore = new LiveMatchTrackerState(logger);
		this.liveStreamStore = new LiveStreamState(logger);
	}

	@action.bound
	public onInitialize(): void {
		this.lmtStore.onInitialize();
		this.liveStreamStore.onInitialize();
		this.isStoreInitialized = true;
	}

	//#endregion constructor

	//#region unmount widgets

	/**
	 * Widgets remain in memory but will not be mounted to DOM.
	 * This is to stop network traffic due to inactivity.
	 * @param suspend indicates if widgets are to be suspended.
	 */
	@action.bound
	setWidgetSuspend(suspend: boolean) {
		if (this.widgetsAreSuspended === suspend) return;
		this.widgetsAreSuspended = suspend;
	}

	//#endregion unmount widget

	//#region any widget type

	/** Returns function that returns boolean that will tell if there is any active widget on location for given event id. */
	@computed public get findActiveWidget() {
		return (eventId: string, location: WidgetLocation) =>
			this.findActiveLmtWidget(eventId, location) ||
			this.findActiveLiveStreamWidget(eventId, location);
	}

	/** Returns true if there is any active widget on location SIDE */
	@computed public get hasActiveSideWidget(): boolean {
		return this.activeSideWidget != null;
	}

	/** Returns active side widget. */
	@computed public get activeSideWidget() {
		return (
			this.liveStreamStore.activeSideWidget ||
			this.lmtStore.activeSideWidget
		);
	}

	/** Pin side widget. */
	@action.bound
	public toggleWidgetPin(eventId: string, location: WidgetLocation): void {
		this.lmtStore.toggleWidgetPin(eventId, location);
		this.liveStreamStore.toggleWidgetPin(eventId, location);
	}

	@action.bound
	public updateWidget(widget: LSWidgetElement | LMTWidgetElement) {
		if ("options" in widget && widget.type === "LMT") {
			this.lmtStore.updateItem(widget);
		}
		if (widget.type === "LS") {
			this.liveStreamStore.updateItem(widget);
		}
	}

	/**
	 * Adds ls if liveStreamStatus is not null and matchId is not null.
	 * Else if adds lmt if matchId is not null.
	 * Else it does nothing.
	 */
	@action.bound
	public addWidget(
		provider: ProviderNameEnum,
		lsStatus: LiveStreamStatus | undefined | null,
		matchId: string | undefined | null,
		eventId: string,
		teamOnename: string,
		teamTwoName: string,
		location: WidgetLocation,
		sport: string,
		reopenLmt?: boolean
	): void {
		if (lsStatus === "live" && matchId != null) {
			this.addLiveStreamWidget(
				matchId,
				eventId,
				teamOnename,
				teamTwoName,
				location
			);
			return;
		}

		if (matchId != null) {
			this.addLmtWidget(
				sport,
				provider,
				matchId,
				eventId,
				teamOnename,
				teamTwoName,
				location,
				reopenLmt
			);
			return;
		}
	}

	/** Removes any widget on location for given eventId, or if location is SIDE remove for any event id */
	@action.bound
	removeWidget(eventId: string, location: WidgetLocation) {
		this.removeLiveStreamWidget(eventId, location);
		this.removeLmtWidget(eventId, location);
	}

	//#endregion any widget type

	//#region lmt

	/** All added lmt widgets */
	@computed public get activeLmtWidgets() {
		return this.lmtStore.activeWidgets;
	}

	/** Returns function that finds active widget for eventId on location  */
	@computed public get findActiveLmtWidget() {
		return this.lmtStore.findActiveWidget;
	}

	/** Toggles lmt widget and turns of ls if it is mounted on same locations for same event. */
	@action.bound
	public toggleLmtWidget(
		sport: string,
		provider: ProviderNameEnum,
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,
		location: WidgetLocation
	): void {
		if (location === "SIDE") {
			if (this.activeSideWidget == null) {
				this.addLmtWidget(
					sport,
					provider,
					matchId,
					eventId,
					teamOneName,
					teamTwoName,
					location
				);
			} else {
				const prevEventId = this.activeSideWidget.eventId;

				const lmtWidgetExists = this.lmtStore.findActiveWidget(
					this.activeSideWidget.eventId,
					"SIDE"
				);

				if (!lmtWidgetExists) {
					this.lmtStore.addLMTWidget(
						sport,
						provider,
						matchId,
						eventId,
						teamOneName,
						teamTwoName,
						"SIDE"
					);
					this.liveStreamStore.removeLiveStream(prevEventId, "SIDE");
				} else {
					this.lmtStore.removeLMTWidget(eventId, "SIDE");
					if (prevEventId !== eventId) {
						this.lmtStore.addLMTWidget(
							sport,
							provider,
							matchId,
							eventId,
							teamOneName,
							teamTwoName,
							"SIDE"
						);
					}
				}
			}

			return;
		}

		this.removeLiveStreamWidget(eventId, location);
		this.lmtStore.toggleLMTWidget(
			sport,
			provider,
			matchId,
			eventId,
			teamOneName,
			teamTwoName,
			location
		);
		if (
			this.lmtStore.findActiveWidget(eventId, "EVENT_OFFER") &&
			this.lmtStore.findActiveWidget(eventId, "SIDE")
		) {
			this.removeLmtWidget(eventId, "SIDE");
		}
	}

	/** Adds lmt widget on location for event id and removes ls if it is present. */
	@action.bound
	public addLmtWidget(
		sport: string,
		provider: ProviderNameEnum,
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,
		location: WidgetLocation,
		reopen?: boolean
	): void {
		if (location === "SIDE" && this.activeSideWidget != null) {
			const prevEventId = this.activeSideWidget.eventId;

			const lmtWidget = this.lmtStore.findActiveWidget(
				this.activeSideWidget.eventId,
				"SIDE"
			);

			if (!lmtWidget) {
				this.lmtStore.addLMTWidget(
					sport,
					provider,
					matchId,
					eventId,
					teamOneName,
					teamTwoName,
					"SIDE"
				);
			}

			if (!reopen) {
				this.toggleLmtWidget(
					sport,
					provider,
					matchId,
					eventId,
					teamOneName,
					teamTwoName,
					location
				);
			} else {
				this.lmtStore.updateItem({
					sport,
					provider,
					matchId,
					eventId,
					teamOneName,
					teamTwoName,
					location,
					type: "LMT",
				});
			}

			this.removeLiveStreamWidget(prevEventId, location);
			return;
		}
		this.removeLiveStreamWidget(eventId, location);
		this.lmtStore.addLMTWidget(
			sport,
			provider,
			matchId,
			eventId,
			teamOneName,
			teamTwoName,
			location
		);
	}

	@action.bound
	public removeLmtWidget(eventId: string, location: WidgetLocation): void {
		this.lmtStore.removeLMTWidget(eventId, location);
	}

	//#endregion lmt

	//#region ls

	/** Returns all active ls widgets. */
	@computed public get activeLsWidgets() {
		return this.liveStreamStore.activeWidgets;
	}

	/** Returns function that finds active ls widget on location for eventId */
	@computed public get findActiveLiveStreamWidget() {
		return this.liveStreamStore.findActiveLiveStream;
	}

	/** Toggles ls widget and removes lmt widget on location for eventId */
	@action.bound
	public toggleLsWidget(
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,
		location: WidgetLocation
	): void {
		if (location === "SIDE") {
			if (this.activeSideWidget == null) {
				this.addLiveStreamWidget(
					matchId,
					eventId,
					teamOneName,
					teamTwoName,
					location
				);
			} else {
				const prevEventId = this.activeSideWidget.eventId;

				const lsWidgetExists =
					this.liveStreamStore.findActiveLiveStream(
						this.activeSideWidget.eventId,
						"SIDE"
					);

				if (!lsWidgetExists) {
					this.liveStreamStore.addLiveStream(
						matchId,
						eventId,
						teamOneName,
						teamTwoName,
						"SIDE"
					);
					this.lmtStore.removeLMTWidget(prevEventId, "SIDE");
				} else {
					this.liveStreamStore.removeLiveStream(eventId, "SIDE");
					if (prevEventId !== eventId) {
						this.liveStreamStore.addLiveStream(
							matchId,
							eventId,
							teamOneName,
							teamTwoName,
							"SIDE"
						);
					}
				}
			}

			return;
		}

		this.removeLmtWidget(eventId, location);
		this.liveStreamStore.toggleLiveStream(
			matchId,
			eventId,
			teamOneName,
			teamTwoName,
			location
		);
		if (
			this.liveStreamStore.findActiveLiveStream(eventId, "EVENT_OFFER") &&
			this.liveStreamStore.findActiveLiveStream(eventId, "SIDE")
		) {
			this.removeLiveStreamWidget(eventId, "SIDE");
		}
	}

	/** Add ls widget and remove lmt widget. */
	@action.bound
	public addLiveStreamWidget(
		matchId: string,
		eventId: string,
		teamOneName: string,
		teamTwoName: string,
		location: WidgetLocation
	): void {
		if (location === "SIDE" && this.activeSideWidget != null) {
			const prevEventId = this.activeSideWidget.eventId;

			const lsWidget = this.liveStreamStore.findActiveLiveStream(
				this.activeSideWidget.eventId,
				"SIDE"
			);

			if (!lsWidget) {
				this.liveStreamStore.addLiveStream(
					matchId,
					eventId,
					teamOneName,
					teamTwoName,
					"SIDE"
				);
			}

			this.liveStreamStore.updateSideWidget({
				type: "LS",
				eventId,
				matchId,
				location,
				teamOneName,
				teamTwoName,
			});

			this.lmtStore.removeLMTWidget(prevEventId, "SIDE");
			return;
		}
		this.removeLmtWidget(eventId, location);
		this.liveStreamStore.addLiveStream(
			matchId,
			eventId,
			teamOneName,
			teamTwoName,
			location
		);
	}

	/** Removes ls widget for event on location or any event if location is SIDE */
	@action.bound
	public removeLiveStreamWidget(
		eventId: string,
		location: WidgetLocation
	): void {
		this.liveStreamStore.removeLiveStream(eventId, location);
	}

	@action.bound
	public addEventWithLsError(eventId: string) {
		if (!this.eventsWithLsError.includes(eventId)) {
			this.eventsWithLsError.push(eventId);
		}
	}

	//#endregion ls

	//#region floating widget

	@observable public isWidgetFloating: boolean = false;
	public initialCss: React.CSSProperties = {};

	@action.bound
	public updateWidgetFloat(
		isFloat: boolean,
		newCss: LiveWidgetStore["initialCss"]
	): void {
		this.isWidgetFloating = isFloat;
		this.initialCss = newCss;
	}

	//#endregion floating widget

	//#region side widget collapse

	@observable public isSideWidgetCollapsed = true;

	@action.bound
	collapseSideWidget() {
		this.isSideWidgetCollapsed = true;
	}
	@action.bound
	expandSideWidget() {
		this.isSideWidgetCollapsed = false;
	}

	@action.bound
	toggleSideWidgetCollapse() {
		this.isSideWidgetCollapsed = !this.isSideWidgetCollapsed;
	}

	//#endregion side widget collapse

	//#region disposers

	@action.bound
	public onDispose(): void {
		this.lmtStore.onDispose();
		this.liveStreamStore.onDispose();
		this.isStoreInitialized = false;
	}

	//#endregion disposers
}
