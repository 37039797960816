import React from "react";
import * as timeago from "timeago.js";
import { TDate } from "timeago.js";
import { default as TimeAgoBase } from "timeago-react";

import de from "timeago.js/lib/lang/de";
import da from "timeago.js/lib/lang/da";
import es from "timeago.js/lib/lang/es";
import fr from "timeago.js/lib/lang/fr";
import it from "timeago.js/lib/lang/it";
import sq from "timeago.js/lib/lang/sq";
import sv from "timeago.js/lib/lang/sv";
import nl from "timeago.js/lib/lang/nl";
import { getCurrentCulture } from "@utils";

timeago.register("de", de);
timeago.register("da", da);
timeago.register("es", es);
timeago.register("fr", fr);
timeago.register("it", it);
timeago.register("sq", sq);
timeago.register("sv", sv);
timeago.register("nl", sv);

export const hr = (number, index, totalSec) => {
	// number: the timeago / timein number;
	// index: the index of array below;
	// totalSec: total seconds between date to be formatted and today's date;
	return [
		["upravo", "upravo"],
		["prije %s sekundi", "za %s sekundi"],
		["prije 1 minute", "za 1 minutu"],
		["prije %s minute", "za %s minuta"],
		["prije 1 sata", "za 1 sat"],
		["prije %s sati", "za %s sati"],
		["prije 1 dana", "za 1 dan"],
		["prije %s dana", "za %s dana"],
		["prije 1 tjedna", "za 1 tjedan"],
		["prije %s tjedana", "za %s tjedana"],
		["prije 1 mjeseca", "za 1 mjesec"],
		["prije %s mjeseci", "za %s mjeseci"],
		["prije 1 godine", "za 1 godinu"],
		["prije %s godina", "za %s godina"],
	][index];
};
timeago.register("hr", hr);

function TimeAgo(props: { datetime: TDate }) {
	const { datetime } = props;
	return (
		<TimeAgoBase
			datetime={datetime}
			locale={getCurrentCulture()}
			opts={{ minInterval: 60 }}
		/>
	);
}

export default TimeAgo;
