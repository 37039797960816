import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { CashoutType } from "@gp/hub";

import { MyBetsStoreContext } from "../../../../context";

import { CashoutStore } from "@administration/pages/my-bets/CashoutStore";

import { Modal } from "@v2/components/shared";
import { useFixBody } from "@v2/hooks/shared";
import { ISOToDisplayCode } from "@utils";
import { logger } from "@state/providers/consoleLogger";
import { Button } from "@components/buttons";

export default observer(function CashoutConfirmationPopUp() {
	const store = useContext(MyBetsStoreContext);
	if (store == null) {
		logger.logError("Expected store, got null.");
		return null;
	}

	if (!store.cashoutStore.isCashoutModalOpen) {
		return null;
	}

	if (store.cashoutStore.cashoutModalData == null) {
		console.error("Expected cashout modal, got null.");
		return null;
	}

	return (
		<MyBetsCashoutConfirmation
			isOpen={store.cashoutStore.isCashoutModalOpen}
			{...store.cashoutStore.cashoutModalData}
			onAccept={store.cashoutStore.cashout}
			onClose={store.cashoutStore.closeCashoutModal}
			isSubmitting={store.cashoutStore.isCashoutFormSubmitting}
		/>
	);
});

export const MyBetsCashoutConfirmation = observer(
	function MyBetsCashoutConfirmation(props: {
		id: string;
		calculatedCashoutAmount: number;
		displayedAmount: string;
		onClose: () => void;
		onAccept: CashoutStore["cashout"];
		isSubmitting?: boolean;
		isLive: boolean;
		message?: string | null;
		cType: CashoutType;
		isOpen?: boolean;
	}) {
		useFixBody(true, props.isOpen);
		const t = useTranslation().t;

		const [acceptChangeRef, setAcceptChangeRef] =
			useState<HTMLInputElement | null>();

		return (
			<Modal>
				<div className="popup">
					<div className="popup__card">
						<div className="popup__header">
							{t("MY_BETS.CASHOUT.TITLE")}
						</div>
						<div className="popup__body">
							{props.message != undefined ? (
								<div className="message--base message--warning">
									{props.message}
								</div>
							) : (
								<div>
									{t("MY_BETS.CASHOUT.MESSAGE", {
										amount: props.displayedAmount,
										currency: ISOToDisplayCode(
											WEBPACK_DEFAULT_CURRENCY
										),
									})}
								</div>
							)}
							<div className="u-mar--top--xsml">
								<input
									id="isChangeAccepted"
									className="input--check"
									ref={(newRef) => setAcceptChangeRef(newRef)}
									disabled={props.isSubmitting}
									type="checkbox"
								/>
								<label htmlFor="isChangeAccepted">
									{t("MY_BETS.CASHOUT.ACCEPT_CHANGED_AMOUNT")}
								</label>
							</div>
						</div>
						<div className="popup__footer">
							<button
								className="popup__footer__btn btn btn--sml btn--secondary--inverted"
								disabled={props.isSubmitting}
								onClick={props.onClose}
								type="button"
							>
								{t("COMMON.CANCEL")}
							</button>
							<Button
								className="popup__footer__btn btn btn--sml btn--cashout"
								disabled={props.isSubmitting}
								onClick={(e) =>
									props.onAccept(
										{
											id: props.id,
											calculatedCashoutAmount:
												props.calculatedCashoutAmount,
											cType: props.cType,
											isChangeAccepted:
												acceptChangeRef?.checked ||
												false,
											isLive: props.isLive,
											displayedAmount:
												props.displayedAmount,
										},
										"my-bets"
									)
								}
								type="submit"
								btnText={t("MY_BETS.CASHOUT.TITLE")}
							></Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
);
