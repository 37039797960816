import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import { EventContext, OfferStoreContext } from "@gp/components";
import { EventOffer } from "@gp/offer";
import classNames from "classnames";
import { useLiveWidgetStore } from "@state/hooks/live-widgets";
import { useTranslation } from "react-i18next";
import { WidgetLocation } from "@features/live-widgets/shared";
import { LiveStatus, ProviderNameEnum } from "@gp/models";

export default observer(function LiveStreamToggleButton(props: {
	/** Will widget button control side widget */
	isForSideWidget?: boolean;
	/** Is button on event or on event row */
	isOfferButton?: boolean;
	/** @deprecated */
	isOnHomepage?: boolean;
	/** Should button only open widget, not close if already opened. */
	isOnlyOpen?: boolean;
}) {
	const [isHover, setIsHover] = useState(false);

	const widgetStore = useLiveWidgetStore();
	const event = useContext(EventContext) as EventOffer | undefined;
	const { t } = useTranslation();
	const store = useContext(OfferStoreContext);

	const teamOneName =
		store.lookupsStore.teams.get(event?.teamOneId || "")?.name || "";
	const teamTwoName =
		store.lookupsStore.teams.get(event?.teamTwoId || "")?.name || "";

	const location: WidgetLocation = props.isForSideWidget
		? "SIDE"
		: "EVENT_OFFER";

	// only BR events may have live stream
	const isBREvent =
		event?.source?.providerName == ProviderNameEnum.BetRadar ? true : false;

	const isUpcoming = event?.liveStreamStatus === "upcoming";
	const isCancelled = event?.liveStreamStatus === "cancelled";
	const eventHasLsError = widgetStore.eventsWithLsError.includes(
		event?.id || ""
	);

	const isDisabled =
		!isBREvent ||
		event?.liveStreamStatus == null ||
		event.source?.matchId == null ||
		isUpcoming ||
		isCancelled ||
		eventHasLsError;

	let dataTip = t("LIVE_WIDGETS.LIVE_STREAM.STREAM_UNAVAILABLE");

	if (!eventHasLsError) {
		if (event?.liveStreamStatus === "upcoming") {
			dataTip = t("LIVE_WIDGETS.LIVE_STREAM.TOOLTIP.UPCOMING");
		} else if (event?.liveStreamStatus === "live") {
			dataTip = t("LIVE_WIDGETS.LIVE_STREAM.TOOLTIP.LIVE");
		} else if (event?.liveStreamStatus === "cancelled") {
			dataTip = t("LIVE_WIDGETS.LIVE_STREAM.TOOLTIP.CANCELLED");
		}
	}

	const isActive = widgetStore.findActiveLiveStreamWidget(
		event?.id || "",
		location
	);

	const buttonClasses = classNames(
		"at-actionLiveStream",
		{
			offer__actions__btn: props.isOfferButton,
			event__actions__btn: !props.isOfferButton && !props.isForSideWidget,
			"widget__actions__btn widget__actions__video":
				props.isForSideWidget,
		},
		"btn",
		{
			"btn--square--reset": props.isOfferButton,
			"btn--square btn--square--xtny btn--ghost":
				!props.isOfferButton && !props.isForSideWidget,
			"btn--square btn--square--sml btn--ghost":
				!props.isOfferButton && props.isForSideWidget,
			"is-disabled": isDisabled,
		}
	);

	const iconClasses = classNames(
		{
			offer__actions__icon: props.isOfferButton && !props.isForSideWidget,
			event__actions__icon:
				!props.isOfferButton && !props.isForSideWidget,
		},
		"u-icon",
		{
			// "u-icon--sml": isOnHomepage,
			"u-icon--med u-icon--med--responsive": props.isOfferButton,
			"u-icon--med": !props.isOfferButton,
			"u-icon--action--stream--active":
				!isHover && !isActive && !isDisabled,
			"u-icon--action--stream--selected":
				(isHover || isActive) && !isDisabled,
			"u-icon--action--stream--disabled": isDisabled,
			"u-icon--action--stream--cancelled": isCancelled,
			"u-icon--action--stream--upcoming": isUpcoming,
		}
	);

	const handleOnMouseEnter: React.MouseEventHandler<
		HTMLButtonElement
	> = (): void => {
		setIsHover(true);
	};

	const handleOnMouseLeave: React.MouseEventHandler<
		HTMLButtonElement
	> = (): void => {
		setIsHover(false);
	};

	return (
		<button
			className={buttonClasses}
			type="button"
			data-tooltip-id="tv-description"
			data-tooltip-content={dataTip}
			data-tooltip-position-strategy="fixed"
			disabled={isDisabled}
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
			onClick={() => {
				if (props.isOnlyOpen) {
					widgetStore.addLiveStreamWidget(
						event?.source?.matchId!,
						event?.id!,
						teamOneName,
						teamTwoName,
						location
					);
				} else {
					widgetStore.toggleLsWidget(
						event?.source?.matchId!,
						event?.id!,
						teamOneName,
						teamTwoName,
						location
					);
				}
			}}
		>
			<i className={iconClasses} />
		</button>
	);
});
