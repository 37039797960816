import { observable, action } from "mobx";
import { DeviceDetector } from "@utils";
import { SessionStorageProvider } from "@state";
import { getCurrentCulture } from "@utils";
import {
	androidAppStoreUrl,
	iOSAppStoreURL,
	isCapacitorPlatform,
} from "@utils/specific/capacitor";
import RootStore from "@state/stores/RootStore";
import { StorageStateKeysEnum } from "@utils/storage";

class AppPopupStore {
	@observable isAppPopupVisible = false;

	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	onInitialize() {
		if (
			!SessionStorageProvider.get("appPopup") &&
			!JSON.parse(
				this.rootStore.localStorageProvider.get(
					StorageStateKeysEnum.MOBILE_APP_POPUP_DISPLAY
				) as string
			) &&
			DeviceDetector.isMobileTheme &&
			!DeviceDetector.isMobileApp &&
			!isCapacitorPlatform()
		) {
			this.isAppPopupVisible = true;
		}
	}

	@action.bound
	hidePopupForSession() {
		SessionStorageProvider.set("appPopup", "true");
		this.isAppPopupVisible = false;
	}

	@action.bound
	dontShowAgain() {
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.MOBILE_APP_POPUP_DISPLAY,
			"true"
		);
		this.isAppPopupVisible = false;
	}

	redirectToInstallPage = () => {
		if (DeviceDetector.isAndroid && WEBPACK_CAPACITOR_USE_ANDROID_STORE) {
			window.location.href = androidAppStoreUrl;
		} else if (DeviceDetector.isIOS && WEBPACK_CAPACITOR_USE_APPLE_STORE) {
			//window.location.href = `https://apps.apple.com/us/app/${WEBPACK_CAPACITOR_APP_DISPLAY_NAME}/id${WEBPACK_CAPACITOR_APPLE_TEAM_ID}`;
			window.location.href = iOSAppStoreURL;
		} else {
			App.state.history.push(`/${getCurrentCulture()}/info/install-app`);
		}
	};
}

export default AppPopupStore;
