export class AccountActivationFormData {
	email: string;
	password: string;
	userCardNumber: string;
	depositLimit: number;
	isReceivingPromoEmailsEnabled: true | false;
}

export enum EAccountActivationEmptyFields {
	NONE = 0,
	MAIL = 1,
	PASSWORD = 2,
	MAIL_AND_PASSWORD = 3,
}
