import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useUser } from "@v2/hooks";
import { AccountTypes } from "@utils";

export function AccountTabs(props: PropTypes) {
	const user = useUser();
	const { t } = useTranslation();
	const { activeAccount, setActiveAccount } = props;

	if (WEBPACK_IS_DK_MW || user.secondaryUser == null) {
		return null;
	}

	const internetTabClassName = classNames("tabs--primary__item", {
		"is-active": activeAccount === AccountTypes.ONLINE,
	});

	const offlineTabClassName = classNames("tabs--primary__item", {
		"is-active": activeAccount == AccountTypes.OFFLINE,
	});
	return (
		<div className="card--primary__tabs">
			<nav className="tabs--primary">
				<div
					className={internetTabClassName}
					onClick={() => {
						if (activeAccount != AccountTypes.ONLINE) {
							setActiveAccount(AccountTypes.ONLINE);
						}
					}}
				>
					{t<string>("USER.ACCOUNT_TYPES.INTERNET")}
				</div>
				<div
					className={offlineTabClassName}
					onClick={() => {
						if (activeAccount != AccountTypes.OFFLINE) {
							setActiveAccount(AccountTypes.OFFLINE);
						}
					}}
				>
					{t<string>("USER.ACCOUNT_TYPES.SHOP")}
				</div>
			</nav>
		</div>
	);
}

type PropTypes = {
	activeAccount: number;
	setActiveAccount: Function;
};
