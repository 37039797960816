import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRootAppStore } from "@hooks";
import classNames from "classnames";
import { observer } from "mobx-react";
import { ChannelResourceTypeDto } from "@api-types/chat";
import { ChatEntryPoint } from "@data-types/chat";
import { isChatInOfferAvailable } from "@v2/helpers";

/*
	type: "sport" | "tournament" | "event";
	isPrimary?: boolean;
	channelInfo: {
		id: string;
		name: string | undefined;
		sportAbrv?: string;
		categoryAbrv?: string;
		tournamentAbrv?: string;
		tournamentSpecialIcon?: string;
	};
*/

export default observer(function ChatButton(props: {
	// Is button displayed in main offer or additional offer and/or event view
	isOfferButton?: boolean;

	// Is button displayed in sport (red) header
	isInSportHeader?: boolean;

	// Is button displayed in offer/tournament header
	isInTournamentHeader?: boolean;

	channelType: ChannelResourceTypeDto;
	resourceId: string;
}) {
	const { chatViewStore, userAuthStore } = useRootAppStore();
	const { t } = useTranslation();

	const [isHover, setIsHover] = useState(false);

	if (
		!isChatInOfferAvailable ||
		!WEBPACK_IS_CHAT_AVAILABLE ||
		!userAuthStore.isLoggedIn
	) {
		return null;
	}

	const buttonClasses = classNames(
		"at-chatBtn",
		{
			offer__actions__btn:
				props.isOfferButton &&
				!props.isInSportHeader &&
				!props.isInTournamentHeader,
			event__actions__btn:
				!props.isOfferButton &&
				!props.isInSportHeader &&
				!props.isInTournamentHeader,
			header__icon:
				!props.isOfferButton &&
				props.isInSportHeader &&
				!props.isInTournamentHeader,
			offer__stats:
				!props.isOfferButton &&
				!props.isInSportHeader &&
				props.isInTournamentHeader,
		},
		"btn",
		{
			"btn--square--reset btn--ghost":
				props.isOfferButton &&
				!props.isInSportHeader &&
				!props.isInTournamentHeader,
			"btn--square btn--square--xtny btn--ghost":
				!props.isOfferButton &&
				!props.isInSportHeader &&
				!props.isInTournamentHeader,
			"btn btn--square btn--square--reset btn--ghost":
				!props.isOfferButton &&
				(props.isInSportHeader || props.isInTournamentHeader),
		}
	);

	const iconClasses = classNames(
		{
			offer__actions__icon:
				props.isOfferButton &&
				!props.isInSportHeader &&
				!props.isInTournamentHeader,
			event__actions__icon:
				!props.isOfferButton &&
				!props.isInSportHeader &&
				!props.isInTournamentHeader,
			offer__chat:
				!props.isOfferButton &&
				props.isInSportHeader &&
				!props.isInTournamentHeader,
			offer__stats__icon:
				!props.isOfferButton &&
				!props.isInSportHeader &&
				props.isInTournamentHeader,
		},
		"u-icon",
		{
			"u-icon--med u-icon--med--responsive": props.isOfferButton,
			"u-icon--med": !props.isOfferButton,
			"u-icon--chat--active": !isHover,
			"u-icon--chat--selected": isHover,
			"u-color--core--snow": props.isInSportHeader,
		}
	);

	return (
		<>
			<button
				className={buttonClasses}
				onClick={() =>
					chatViewStore.onInitialize(ChatEntryPoint.OFFER, {
						channelType: props.channelType,
						resourceId: props.resourceId,
					})
				}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				<i className={iconClasses} />
			</button>
		</>
	);
});

// function createImagePath(params: {
// 	id: string;
// 	name: string | undefined;
// 	sportAbrv?: string;
// 	categoryAbrv?: string;
// 	tournamentAbrv?: string;
// 	tournamentSpecialIcon?: string;
// }): {
// 	sportIcon: string;
// 	categoryIcon: string | null;
// 	tournamentIcon: string | null;
// } {
// 	const sportIcon = `icons/sport/${params.sportAbrv}/${params.sportAbrv}.svg`;

// 	const categoryIcon = params.categoryAbrv
// 		? `icons/sport/${params.sportAbrv}/${params.categoryAbrv}/${params.categoryAbrv}.svg`
// 		: null;

// 	const specialIcon = params.tournamentSpecialIcon
// 		? params.tournamentSpecialIcon?.split("-")[0]
// 		: null;

// 	const tournamentSpecialIcon = specialIcon
// 		? `icons/sport/${params.sportAbrv}/${params.categoryAbrv}/${specialIcon}/${specialIcon}.svg`
// 		: null;

// 	const metadata = {
// 		sportIcon: sportIcon,
// 		categoryIcon: categoryIcon,
// 		tournamentIcon: tournamentSpecialIcon,
// 	};

// 	return metadata;
// }

function createIconPath(
	...parts: (string | undefined | null)[]
): string | null {
	const path = parts.filter(Boolean).join("/");
	return path === "" ? null : `${path}.svg`;
}

function createImagePath({
	sportAbrv,
	categoryAbrv,
	tournamentSpecialIcon,
}: {
	sportAbrv?: string;
	categoryAbrv?: string;
	tournamentSpecialIcon?: string;
}): {
	sportIcon: string | null;
	categoryIcon: string | null;
	tournamentIcon: string | null;
} {
	const sportIcon = createIconPath("icons/sport", sportAbrv, sportAbrv);
	const categoryIcon = createIconPath(
		"icons/sport",
		sportAbrv,
		categoryAbrv,
		categoryAbrv
	);
	const tournamentSpecialIconPath = createIconPath(
		"icons/sport",
		sportAbrv,
		categoryAbrv,
		tournamentSpecialIcon?.split("-")?.[0],
		tournamentSpecialIcon
	);

	return {
		sportIcon,
		categoryIcon,
		tournamentIcon: tournamentSpecialIconPath,
	};
}
