import React from "react";
import classNames from "classnames";
import { range } from "lodash";
import { useIsAdditionalOfferVisible } from "@offer/common";

export default function RenderSkeletonLoader() {
	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();

	return (
		<div className="l--content__main">
			<div
				className={classNames(
					"loader--skeleton offer offer--virtual offer--live offer--skeleton",
					{
						"offer--full": !isAdditionalOfferVisible,
						"offer--sml": isAdditionalOfferVisible,
					}
				)}
			>
				<div className="offer--virtual__list">
					<div className="offer--virtual__thead">
						<div className="offer__head offer--virtual__head">
							<div className="offer__head__row" />
						</div>
					</div>
					{range(0, 15).map((k) => (
						<div key={k} className="offer--virtual__tbody">
							<div className="offer__body offer--virtual__body">
								<div className="offer__body__row">
									<div className="offer__body__data offer__time">
										<div className="offer__time__inner" />
									</div>
									<div className="offer__body__data offer__favorites">
										<div className="offer__favorites__btn btn btn--xtny btn--link btn--icon">
											<div className="u-icon u-icon--sml" />
										</div>
									</div>
									<div className="offer__body__data offer__match">
										<div className="offer__match__competitors">
											<div className="offer__team" />
											<div className="offer__team" />
										</div>
									</div>
									<div className="offer__body__data offer__score set-only">
										<div className="offer__score__set">
											<div className="offer__score__home" />
											<div className="offer__score__away" />
										</div>
									</div>
									<div className="offer__body__data offer__result">
										<div className="offer__result__home" />
										<div className="offer__result__away" />
									</div>
									<div className="offer__body__data offer__actions">
										<div className="offer__actions__btn btn btn--square--reset btn--link btn--icon">
											<div className="offer__actions__icon u-icon u-icon--med" />
										</div>
										<div className="offer__actions__btn btn btn--square--reset btn--link btn--icon">
											<div className="offer__actions__icon u-icon u-icon--med" />
										</div>
										<div className="offer__actions__btn btn btn--square--reset btn--link btn--icon">
											<div className="offer__actions__icon u-icon u-icon--med" />
										</div>
									</div>
									<div className="offer__body__data offer__quotes">
										<div className="quote quote--button" />
										<div className="quote quote--button" />
										<div className="quote quote--button" />
									</div>
									<div className="offer__body__data offer__quotes">
										<div className="quote quote--button" />
										<div className="quote quote--button" />
										<div className="quote quote--button" />
									</div>
									<div className="offer__body__data offer__quotes">
										<div className="quote quote--button" />
										<div className="quote quote--button" />
										<div className="quote quote--button" />
									</div>
									<div className="offer__body__data offer__quotes">
										<div className="quote quote--button" />
										<div className="quote quote--button" />
										<div className="quote quote--button" />
									</div>
									<div className="offer__body__data offer__more">
										<div className="offer__more__icon u-icon u-icon--tny" />
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}