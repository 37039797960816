import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Period } from "@state/common/PeriodConverter";
import { getCurrentCulture } from "@utils";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import { observer } from "mobx-react";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";

type store = {
	isOfferMenuOpen: boolean;
	toggleUpdateProcessing: PrematchOfferMenuStore["toggleUpdateProcessing"];
	periodSubMenu: {
		isPeriodMenuExpanded: boolean;
		setPeriodKey: (p: Period) => void;
		periodMenu: { period: Period; name: string }[];
		selectedPeriodKey: Period;
	};
	isEmpty: boolean;
	isLoading: boolean;
	selectedSport: PrematchOfferMenuStore["selectedSport"];
	menu: PrematchOfferMenuStore["menu"];
	isFavoritesSubMenuOpen: boolean;
};

export const FavoritesMenuItems = observer(function FavoritesMenuItems(props: {
	store: store;
}) {
	return (
		<nav className="nav--tertiary">
			<ul className="nav--tertiary__menu">
				<FavoritesMenuItem store={props.store} />
			</ul>
		</nav>
	);
});

const FavoritesMenuItem = observer(function FavoritesMenuItem(props: {
	store: store;
}) {
	return (
		<>
			{[
				{ type: "tournaments", name: "FAVORITES.TOURNAMENTS" },
				{ type: "events", name: "LIVE.LIVE_SUB_MENU.EVENTS" },
			].map((period) => (
				<FavoritesMenuItemList
					key={period.type}
					type={period.type}
					name={period.name}
					store={props.store}
				/>
			))}
		</>
	);
});

const FavoritesMenuItemList = observer(function FavoritesMenuItemList(props: {
	type: string;
	name: string;
	store: store;
}) {
	const location = useLocation<{ period: string }>();

	const { t } = useTranslation();
	const favoritesStore = useContext(FavoritesStoreContext);

	const iconClasses = classNames("nav--tertiary__icon u-icon u-icon--med", {
		[`u-icon--period--${props.type}`]:
			props.type != "events" && props.type != "tournaments",
		"u-icon--favorites": props.type == "events",
		"u-icon--favorite-tournaments--filled": props.type == "tournaments",
	});

	return (
		<li className="nav--tertiary__item">
			<Link
				className={classNames("nav--tertiary__link", {
					"is-active": location.pathname.includes(props.type),
				})}
				to={`/${getCurrentCulture()}/sports/${
					props.store.periodSubMenu.selectedPeriodKey
				}/my-favorites/${props.type}`}
				onClick={() => {
					window.scroll({ top: 0 });
				}}
				replace={true}
			>
				<span className="nav--tertiary__title nav--tertiary__title--center">
					<i className={iconClasses} />
					<span>{t(props.name)}</span>
				</span>

				{props.type == "events" && (
					<span className="nav--tertiary__counter counter counter--base counter--simple">
						{favoritesStore.prematchFavoritesCount}
					</span>
				)}

				{props.type == "tournaments" && (
					<span className="nav--tertiary__counter counter counter--base counter--simple">
						{favoritesStore.favoriteTournamentsCount}
					</span>
				)}
			</Link>
		</li>
	);
});
