import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AccountStatementWalletFilter, AccountStatementCustomDate } from ".";
import { FormSelectField } from "@v2/components/desktop/shared/inputs";
import Button from "@components/buttons/Button";
import {
	Params,
	periods,
} from "@v2/data-access/account-statement/AccountStatementService";
import { AccountStatementBetContext } from "@v2/context/AccountStatementBetContext";
import { getUrlParamsAsObj, redirect } from "@state";

export default function AccountStatementFilter() {
	const contextValue = useContext(AccountStatementBetContext);
	const { t } = useTranslation();
	const { handleSubmit, reset, getValues } = useFormContext();
	useEffect(() => {
		//onchange of state set form data
		reset(contextValue?.statementFilter);
	}, [contextValue?.statementFilter]);

	useEffect(() => {
		const location = App.state.history.location;
		redirect(location.pathname + location.search, true);
		onInitialize();
		if (
			App.state.history.location.search != null &&
			App.state.history.location.search !== ""
		) {
			let urlParams: unknown = getUrlParamsAsObj();
			//@ts-expect-error
			delete urlParams.pageNumber;
			setStatementFilter(urlParams as Params);
			//@ts-expect-error
			if ("asPeriod" in urlParams && urlParams.asPeriod === "custom") {
				setShowCustomDateSection(true);
			}
			return;
		}
		if (!App.state.history.location.pathname.split("/")[3])
			setStatementFilter({
				asPeriod: "today",
				asType: "all",
				bettingAccountTypeIds:
					App.state.rootStore.userAuthStore.availableUserWallets![0],
			});
	}, []);

	if (!contextValue) {
		return null;
	}

	const {
		handleChange,
		onFilterSubmit,
		filterData,
		showCustomDateSection,
		onInitialize,
		setStatementFilter,
		setShowCustomDateSection,
	} = contextValue;

	const showAllTypes = {
		id: "all",
		name: t("ACC_STATEMENT.FILTER.SHOW_ALL_TRANSACTION_TYPES"),
	};

	if (!filterData || !filterData.transactionLookup) {
		return null;
	}
	const getTransactionTableArray = () => {
		if (
			!("transactionLookup" in filterData) ||
			!filterData.transactionLookup
		) {
			return undefined;
		}

		const transactionTypesPerWallet = filterData.transactionLookup.find(
			(lookup) => lookup.wallet === getValues("bettingAccountTypeIds")
		);
		return [showAllTypes, ...transactionTypesPerWallet.transactionTypes];
	};
	let data = getTransactionTableArray();
	return (
		<form className="form" onSubmit={handleSubmit(onFilterSubmit)}>
			<div className="card--primary">
				<div className="card--primary__header">
					<div className="card--primary__title title--primary">
						{t<string>("ACC_STATEMENT.FILTER.AS_HEAD")}
					</div>
					<AccountStatementWalletFilter
						onFilterSubmit={onFilterSubmit}
					/>
				</div>
				<div className="card--primary__body">
					<FormSelectField
						className="form__group"
						label="ACC_STATEMENT.FILTER.AS_PERIOD"
						name="asPeriod"
						dropdownData={periods}
						onChange={handleChange}
					/>

					<AccountStatementCustomDate
						showCustomDateSection={showCustomDateSection}
					/>

					<FormSelectField
						className="form__group"
						label="ACC_STATEMENT.FILTER.AS_TYPE"
						name="asType"
						dropdownData={data}
					/>
				</div>
				<div className="card--primary__footer">
					<Button
						className="btn btn--lrg btn--primary"
						btnText="ACC_STATEMENT.FILTER.AS_BUTTON"
					/>
				</div>
			</div>
		</form>
	);
}
