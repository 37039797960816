import React, { useContext, useMemo, useEffect, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import {
	OfferStoreContext,
	LookupContext,
	SportOfferContext,
	EventContext,
} from "@gp/components";

import { useRootOfferStore } from "@hooks";

import {
	EventFooter,
	EventHeader,
	EventBody,
	EmptyOfferMessage,
} from "./components";

import EventViewStore from "../../../../../state/offer/pages/EventViewStore";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";

export default observer(function EventPage(props: {
	liveOfferMenuStore?: LiveOfferMenuStore | undefined | null;
	prematchOfferMenuStore: PrematchOfferMenuStore | undefined | null;
}) {
	const params = useParams<{ eventIds: string }>();
	const offerStore = useRootOfferStore();
	const favoritesStore = useContext(FavoritesStoreContext);
	const eventViewStore = useMemo(
		() =>
			new EventViewStore(
				offerStore,
				props.liveOfferMenuStore,
				favoritesStore
			),
		[]
	);

	useEffect(() => {
		window.scrollTo(0, 0);

		return eventViewStore.onDispose;
	}, []);

	useEffect(() => {
		eventViewStore.updateEventIds(params.eventIds);
	}, [params.eventIds]);

	return (
		<OfferStoreContext.Provider value={eventViewStore}>
			<LookupContext.Provider value={eventViewStore.lookupsStore}>
				<Core store={eventViewStore} />
			</LookupContext.Provider>
		</OfferStoreContext.Provider>
	);
});

const Core = observer(function Core(props: { store: EventViewStore }) {
	if (props.store.isLoading) {
		return (
			<div className="l--content__main">
				<div className="container">
					<div className="loader">
						<div className="loader__spinner loader__spinner--primary" />
					</div>
				</div>
			</div>
		);
	}

	if (
		(props.store.orderedEvents.length <= 0 || props.store.isEmpty) &&
		props.store.isStoreInitialized
	) {
		return <EmptyOfferMessage />;
	}

	return (
		<div className="l--content__main">
			<div className="row">
				{props.store.orderedEvents.map((event) => (
					<SportOfferContext.Provider
						key={event.sport.id + event.id}
						value={event.sport}
					>
						<EventContext.Provider value={event}>
							<Event store={props.store} />
						</EventContext.Provider>
					</SportOfferContext.Provider>
				))}
			</div>
		</div>
	);
});

const Event = observer(function Event(props: { store: EventViewStore }) {
	const event = useContext(EventContext);

	const eventWrapperClasses = classNames("event event--page", {
		"event--live": event.isLive,
		"event--multi": props.store.isMultiEvent,
	});

	const multiEventWrapperDiv = classNames("col u-mar--bottom--xsml", {
		"event--multi__col": props.store.isMultiEvent,
	});

	return (
		<div className={multiEventWrapperDiv}>
			<div className={eventWrapperClasses}>
				<EventHeader />
				<EventBody />
				<EventFooter />
			</div>
		</div>
	);
});
