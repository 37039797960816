import { gamingHistoryApiService } from "@api-services/gaming-history/GamingHistoryApiService";
import { TransactionDto } from "@api-types/gaming-history/TransactionDto";
import { GamingHistoryRequestFilter } from "@api-types/gaming-history/GamingHistoryRequestFilter";
import { GamingHistoryResponse } from "@api-types/gaming-history/GamingHistoryResponse";
class GamingHistoryService {
	async getGamingHistoryData(
		bodyData: GamingHistoryRequestFilter
	): Promise<GamingHistoryResponse> {
		const response = await gamingHistoryApiService.getGamingHistoryData(
			bodyData
		);

		return response;
	}

	async getAvailableLiveGames(): Promise<string[]> {
		return await gamingHistoryApiService.getAvailableLiveGames();
	}

	async getTransactionsPerRound(
		bodyData: GamingHistoryRequestFilter
	): Promise<TransactionDto[]> {
		const response = await gamingHistoryApiService.getTransactionsPerRound(
			bodyData
		);

		return response.result;
	}
}

const gamingHistoryService = new GamingHistoryService();

export { gamingHistoryService };
