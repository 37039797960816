import React from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "../../../shared";
import {
	RealityCheckPopup,
	AutomaticLogoutPopup,
	User24hLockPopup,
	AccountVerificationPopup,
	WelcomeLoginPopup,
	FailedLoginsPopup,
	OnboardingRegisterPopup,
	RequiredUserActionsPopup,
	UseMarketplaceBonusConfirmPopup,
} from ".";

import LiveSessionExpirePopup from "./LiveSessionExpirePopup";
import OnboardingNewsletterPopup from "./OnboardingNewsletterPopup";
import MostPlayTicketsPopup from "@features/quick-play/desktop/most-played-tickets/MostPlayTicketsPopup";
import { ChatBetSlipSharedPopup } from "@features/chat/desktop";

export default observer(function PopupDisplay() {
	return (
		<Modal>
			<Popup />
		</Modal>
	);
});

const Popup = observer(function Popup() {
	const {
		automaticLogoutStore: { isActive: isAutomaticLogoutActive },
		realityCheckStore: { isActive: isRealityCheckActive },
		user24hLockStore: { isActive: isUser24hLockActive },
		AccountVerificationStore: { isActive: isAccountVerificationActive },
		welcomeStore: {
			isActive: isWelcomePopupActive,
			isUseBonusConfirmActive,
			hideUseBonusConfirmationPopup,
		},
		failedLoginsCheckPopupStore: { isActive: isFailedLoginsPopupActive },
		offerHub: { isSessionExpired: isOfferHubSessionExpire },
		cashoutHubStore: { isSessionExpired: isCashOutHubSessionExpire },
		onboardingWelcomePopupStore: {
			onboardingNewsletterStore: {
				isActive: isOnboardingNewsletterPopupActive,
			},
			onboardingRegistrationStore: {
				isActive: isOnboardingRegisterPopupActive,
			},
		},
		requiredUserActionStore: { isActive: RequiredUserActionsPopupActive },
		quickPlayStore: {
			mostPlayedTicketsStore: {
				isPopupDisplayed: isQuickPlayPopupActive,
			},
		},
		cookieConsentStore: {
			shouldBlockOtherPopups: isCookieConsentPopupActive,
		},
		chatViewStore: { chatBetSlipShareData: isChatBetSlipSharePopupActive },
		userAuthStore,
	} = App.state.rootStore;

	if (
		(isAutomaticLogoutActive ||
			isRealityCheckActive ||
			isUser24hLockActive ||
			isAccountVerificationActive ||
			isWelcomePopupActive ||
			isFailedLoginsPopupActive ||
			isOfferHubSessionExpire ||
			isCashOutHubSessionExpire ||
			isOnboardingNewsletterPopupActive ||
			isOnboardingRegisterPopupActive ||
			RequiredUserActionsPopupActive ||
			isQuickPlayPopupActive ||
			isCookieConsentPopupActive) &&
		App.state.rootStore.chatViewStore.chatBoxVisible
	) {
		App.state.rootStore.chatViewStore.toggleChatBox(false);
	}

	if (isRealityCheckActive) {
		return <RealityCheckPopup isOpened={isRealityCheckActive} />;
	}

	if (isAutomaticLogoutActive) {
		return <AutomaticLogoutPopup isOpened={isAutomaticLogoutActive} />;
	}

	if (
		(isOfferHubSessionExpire || isCashOutHubSessionExpire) &&
		!window.location.pathname.includes("games")
	) {
		return <LiveSessionExpirePopup />;
	}

	if (isQuickPlayPopupActive) {
		return <MostPlayTicketsPopup />;
	}

	if (isCookieConsentPopupActive) {
		//do not render any popups if consent is shown
		return null;
	}

	if (isAccountVerificationActive) {
		return (
			<AccountVerificationPopup isOpened={isAccountVerificationActive} />
		);
	}

	if (isUser24hLockActive) {
		return <User24hLockPopup />;
	}

	if (isWelcomePopupActive) {
		return <WelcomeLoginPopup />;
	}

	if (isUseBonusConfirmActive) {
		return (
			<UseMarketplaceBonusConfirmPopup
				hidePopup={hideUseBonusConfirmationPopup}
				sendConfirmation={(value: boolean) =>
					userAuthStore.sendWelcomeUseBonusConfirmation(value)
				}
			/>
		);
	}

	if (isFailedLoginsPopupActive) {
		return <FailedLoginsPopup />;
	}

	if (isOnboardingRegisterPopupActive) {
		return (
			<OnboardingRegisterPopup
				isOpened={isOnboardingRegisterPopupActive}
			/>
		);
	}

	if (isOnboardingNewsletterPopupActive) {
		return (
			<OnboardingNewsletterPopup
				isOpened={isOnboardingNewsletterPopupActive}
			/>
		);
	}

	if (RequiredUserActionsPopupActive) {
		return <RequiredUserActionsPopup />;
	}

	if (isChatBetSlipSharePopupActive) {
		return <ChatBetSlipSharedPopup />;
	}

	return null;
});
