import {
	observable,
	computed,
	action,
	runInAction,
	reaction,
	IReactionDisposer,
} from "mobx";
import { Subscription } from "rxjs";
import { UserTypes } from "@utils";
import { getCurrentCulture } from "@utils";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import RootOfferStore from "@offer/stores/RootStore";
const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const  MyBetsService = lazy(loadFailPath, ()=>import("@services/my-bets/MyBetsService"));

type TRootStore = {
	hub: RootOfferStore["hub"];
};

export default class PlayedLiveEventsStore {
	@observable public count = 0;

	@computed public get isEnabled() {
		return App.state.rootStore.userAuthStore.user != null;
	}

	@observable public eventIds: string[] = [];
	private playedOneClickTips: { id: string; eventId: string }[] = [];
	private rootStore: TRootStore;
	private userAuthenticatedReaction: IReactionDisposer | null = null;
	private subscription: Subscription | null = null;

	constructor(rootStore: TRootStore) {
		this.rootStore = rootStore;
	}

	//#region data fetching

	@action.bound
	async onInitialize() {
		this.disposeUserAuthenticatedReaction();

		if (!this.isEnabled) {
			this.eventIds = [];
			this.count = 0;
			return;
		}

		this.userAuthenticatedReaction = reaction(
			() => ({ isEnabled: this.isEnabled }),
			() => {
				if (!this.isEnabled) {
					this.onDispose();
				}
			},
			{
				fireImmediately: true,
			}
		);

		//fetch played events for both users if secondary user exists

		try {
			let secondaryUserResult = null;
			const primaryUserResult = await (
				await MyBetsService
			).default.getEventIdsFromLiveBets(UserTypes.PRIMARY);
			if (App.state.rootStore.userAuthStore.secondaryUser) {
				secondaryUserResult = await (
					await MyBetsService
				).default.getEventIdsFromLiveBets(UserTypes.SECONDARY);
			}
			const result = Object.assign(
				primaryUserResult,
				secondaryUserResult
			);

			runInAction(() => {
				this.eventIds = result;
			});
		} catch (e) {
			console.error(e);
		}

		this.initSubscription();
	}

	@action.bound
	private initSubscription() {
		this.disposeSubscription();

		if (this.eventIds.length === 0) {
			this.eventIds = [];
			this.count = 0;
			return;
		}

		const filter = {
			subscriptionId: "live-from-my-bets-counter",
			count: {
				playedEventsCount: {
					liveStatus: 1,
					id: {
						eq: this.eventIds.map((i) => i),
					},
				},
			},
		};

		this.subscription = this.rootStore.hub.getCounts(filter).subscribe({
			next: (response) => {
				runInAction(() => {
					response.playedEventsCount = Number(
						response.playedEventsCount
					);
					if (!isNaN(response.playedEventsCount)) {
						this.count = response.playedEventsCount;
					}
				});
			},
			error: (err) => {
				console.error("live from my bets count error", err);
				runInAction(() => {
					this.count = 0;
				});
			},
		});
	}

	//#endregion data fetching

	//#region actions

	@action.bound
	public updateEvents(eventIds: string[]) {
		let haveAddedNewEventId = false;

		for (const eventId of eventIds) {
			if (!this.eventIds.includes(eventId)) {
				this.eventIds.push(eventId);
				haveAddedNewEventId = true;
			}
		}

		if (haveAddedNewEventId) {
			this.initSubscription();
		}
	}

	@action.bound
	public addPlayedOneClickTip(
		tip: PlayedLiveEventsStore["playedOneClickTips"][0]
	) {
		if (tip == null) {
			return;
		}

		this.playedOneClickTips.push(tip);
	}

	@action.bound
	public successfulOneClickTips(tipIds: string[]) {
		const eventIds = [];
		for (const tipId of tipIds) {
			const indexOfTip = this.playedOneClickTips.findIndex(
				(tip) => tip.id === tipId
			);

			if (indexOfTip === -1) {
				return;
			}

			const tip = this.playedOneClickTips[indexOfTip];
			this.playedOneClickTips.splice(indexOfTip, 1);

			eventIds.push(tip.eventId);
		}

		this.updateEvents(eventIds);
	}

	//#endregion actions

	//#region disposers

	@action.bound
	public onDispose() {
		this.disposeUserAuthenticatedReaction();
		this.disposeSubscription();
		this.eventIds = [];
		this.count = 0;
	}

	@action.bound
	private disposeSubscription() {
		if (this.subscription != null) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	@action.bound
	private disposeUserAuthenticatedReaction() {
		if (this.userAuthenticatedReaction != null) {
			this.userAuthenticatedReaction();
			this.userAuthenticatedReaction = null;
		}
	}

	//#endregion disposers
}
