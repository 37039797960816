import React, { useContext } from "react";
import { observer } from "mobx-react";

import { EventContext } from "@gp/components";

import useTime from "@hooks/useDayAndTimeFromEventStartTime";

export default observer(function EventTimeTemplate(props: {
	isEventView?: boolean;
}) {
	const event = useContext(EventContext);
	const { day, time } = useTime(event.startTime);

	if (event.matchTime?.displayTime) {
		return (
			<div
				className={
					"event__time" + (props.isEventView ? " u-type--sml" : "")
				}
			>
				{event.matchTime.displayTime}
			</div>
		);
	}

	if (event.startTime) {
		return (
			<div
				className={
					"event__start" + (props.isEventView ? " u-type--sml" : "")
				}
			>
				<div className="event__start__day">{day}</div>
				<div className="event__start__time">{time}</div>
			</div>
		);
	}

	return null;
});
