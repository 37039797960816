import { useFormState, useFormContext } from "react-hook-form";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from ".";

const SearchInputField = (props: any) => {
	const { t } = useTranslation();
	const {
		label,
		name,
		className,
		children,
		errorMessage = undefined,
		type = "search",
		required = false,
		placeholder = undefined,
		astersisksClass = null,
		inputClassName,
		customErrorClassName = null,
		pattern,
		validate,
		...rest
	} = props;

	const { errors } = useFormState();
	let error = get(errors, name);
	const form = useFormContext();
	const field = form.register(props.name, {
		required: {
			value: props.required,
			message: props.errorMessage || "COMMON.REQUIRED_FIELD",
		},
		disabled: props.disabled,
		pattern,
		validate,
	});

	return (
		<>
			<ErrorMessage
				name={name}
				customErrorClassName={customErrorClassName}
			/>
			<input
				type={type}
				{...field}
				{...rest}
				name={name}
				className={inputClassName}
				placeholder={t(placeholder)}
			/>
		</>
	);
};
export default SearchInputField;
