import { chain } from "lodash";
import { isNullOrWhitespace } from "../utility";

const SGUID_empty = "0000000000000000000000";

export const formattedStatus = (bettingOfferStatus: number) => {
	switch (bettingOfferStatus) {
		case 0:
			return "inactive";
		case 1:
			return "active";
		case 2:
			return "win";
		case 3:
			return "win-with-reduced-odds";
		case 4:
			return "lose";
		case 5:
			return "refund";
		case 6:
			return "refund-half-bet-and-win-other-half";
		case 7:
			return "refund-half-bet-and-lose-other-half";
		case 8:
			return "cancelled";
		case 9:
			return "cashout";
		default:
			return "";
	}
};

export const prepareOfferData = (bet: {
	betSlipOffers: {
		eventId: string;
		isMulti: boolean;
		multiEventName: string;
	}[];
}) => {
	const groupedOffers = chain(bet.betSlipOffers)
		.groupBy((o) => o.eventId)
		.value();
	var result = null;
	for (var key in groupedOffers) {
		if (groupedOffers[key].length > 1) {
			result = chain(groupedOffers[key]).value();
			for (var i = 1; i < result.length; i++) {
				result[i].isMulti = true;
				result[i].multiEventName = i + 1 + " Multi";
			}
			groupedOffers[key] = result;
		}
	}
	return Object.values(groupedOffers).flatMap((x) => x);
};

export const getTeamNames = (offer: {
	playerId: string | null;
	playerFirstName: string | null;
	playerLastName: string | null;
	teamId: string | null;
	teamName: string | null;
	bettingTypeTip: string | null;
}) => {
	if (!isNullOrWhitespace(offer.playerId) && offer.playerId != SGUID_empty) {
		return `${offer.playerFirstName}, ${offer.playerLastName}`;
	} else if (
		!isNullOrWhitespace(offer.teamId) &&
		offer.teamId != SGUID_empty
	) {
		return offer.teamName;
	}
	return offer.bettingTypeTip;
};

export const getParsedCurrentResult = (offer: {
	currentResult: string | null;
}):
	| (Parameters<typeof getCurrentResult>[0] &
			Parameters<typeof getCurrentScorePerPeriod>[1])
	| null => {
	if (
		offer.currentResult != null &&
		!isNullOrWhitespace(offer.currentResult)
	) {
		return JSON.parse(offer.currentResult);
	}
	return null;
};

export const getParsedResult = (
	offer: { sportData?: { result?: string | null } } | null | undefined
): object | null => {
	if (
		offer?.sportData?.result != null &&
		!isNullOrWhitespace(offer.sportData.result)
	) {
		return JSON.parse(offer.sportData.result);
	}
	return null;
};

export const getScorePerPeriod = (
	parsedResult: { scorePerPeriod?: { home?: number; away: number }[] } | null
) => {
	if (parsedResult != null && parsedResult.scorePerPeriod != null) {
		return parsedResult.scorePerPeriod
			.map((period) => `${period.home || 0}:${period.away || 0}`)
			.join("; ");
	}
	return "";
};

export const getCurrentResult = (
	parsedCurrentResult:
		| {
				currentScore?: { home?: number; away?: number };
				home?: null | undefined;
				away?: null | undefined;
		  }
		| { home?: number; away?: number; currentScore?: null | undefined }
		| null
		| undefined
): string => {
	if (
		parsedCurrentResult != null &&
		parsedCurrentResult.currentScore != null
	) {
		return `${parsedCurrentResult.currentScore.home || 0}:${
			parsedCurrentResult.currentScore.away || 0
		}`;
	} else if (parsedCurrentResult != null) {
		return `${parsedCurrentResult.home || 0}:${
			parsedCurrentResult.away || 0
		}`;
	}
	return "";
};

export const getCurrentScorePerPeriod = (
	currentResult: unknown | null | unknown,
	parsedCurrentResult: {
		scorePerPeriod?: { home?: number; away?: number }[] | null | undefined;
	} | null
) => {
	let currentScorePerPeriod = "";
	if (currentResult != null && parsedCurrentResult?.scorePerPeriod != null) {
		for (let i = 0; i < parsedCurrentResult.scorePerPeriod.length; i++) {
			currentScorePerPeriod += `${i != 0 ? " " : ""}(${
				parsedCurrentResult.scorePerPeriod[i].home || 0
			}:${parsedCurrentResult.scorePerPeriod[i].away || 0})`;
		}
	}
	return currentScorePerPeriod;
};

export const getSystemString = ({
	system,
	combinationBettingOffersBankCount,
	eventCount,
	combinationBettingOffersCount,
}: {
	system: string;
	combinationBettingOffersBankCount: number;
	eventCount: number;
	combinationBettingOffersCount: number;
}) => {
	if (combinationBettingOffersBankCount > 0) {
		return `(B x ${combinationBettingOffersBankCount}, ${system} / ${
			eventCount - combinationBettingOffersBankCount
		} = ${combinationBettingOffersCount})`;
	}
	return `(${system} / ${
		eventCount - combinationBettingOffersBankCount
	} = ${combinationBettingOffersCount})`;
};
