import { httpClient, getAgencyKey, getApplicationHeaders } from "@utils";
import { UserTypes } from "@utils";
import { GamingLimitHistoryDto } from "@api-types/account-settings/GamingLimitHistoryDto";

export class GamingLimitHistoryApiService {
	static get baseUrl(): string {
		return "/gaming-limit-histories";
	}

	static getRequestHeaders = (
		activeAccount: UserTypes = UserTypes.PRIMARY
	) => {
		const appHeaders = getApplicationHeaders(activeAccount);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getGamingLimitHistory(
		url?: string,
		activeAccount: UserTypes = UserTypes.PRIMARY
	): Promise<GamingLimitHistoryDto> {
		return httpClient.get(
			`${WEBPACK_BASE_ADDRESS}${getAgencyKey(activeAccount)}${
				this.baseUrl
			}/per-user${url}`,
			this.getRequestHeaders(activeAccount),
			{ responseType: "json" }
		);
	}
}
