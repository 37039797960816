import {
	observable,
	action,
	runInAction,
	computed,
	reaction,
	IReactionDisposer,
} from "mobx";
import { Subscription } from "rxjs";

import { EventOffer, SportOffer } from "@gp/offer";
import { ISubscriptionRequest } from "@gp/hub";
import { Sorter, insert } from "@gp/utility";

import { PrematchOfferApiService } from "@api-services/offer/PrematchOfferApiService";

import { GroupOfferStore, Options } from "../GroupOfferStore";
import { LoaderStore } from "@state/stores/common";
import FavoritesStore from "@offer/stores/FavoritesStore";
import { updateUrlParams } from "@state";

export class AdditionalOfferViewStore extends GroupOfferStore {
	//#region members

	private loader: LoaderStore;
	private subscription: Subscription | null;
	private favoritesStore: FavoritesStore;
	@observable favoritesBettingTypeGroups = {};
	//#endregion

	//#region observable

	@observable eventId: string | null;
	@observable isFetchingData = false;
	@observable isStoreInitialized = false;
	@observable isButtonToggled = false;
	favoritesBettingTypeReaction: IReactionDisposer | null;

	//#endregion observable

	//#region computed

	@computed get orderedEvents(): (EventOffer & { sport: SportOffer })[] {
		return this.events as any;
	}

	@computed get isEmpty() {
		return (
			this.events.length === 0 &&
			!this.isFetchingData &&
			this.isStoreInitialized
		);
	}

	@computed get isLoading() {
		return this.loader.isLoading;
	}

	//#endregion computed

	//#region  constructor

	constructor(
		rootStore: typeof App.offer.rootStore,
		favoritesStore: FavoritesStore,
		options: Options = null
	) {
		super(rootStore, options);

		this.loader = new LoaderStore();
		this.favoritesStore = favoritesStore;
	}

	//#endregion constructor

	//#region actions

	@action.bound
	setToggleButton(isToggled: boolean) {
		this.isButtonToggled = isToggled;
	}

	@action.bound
	handleUrls(value: boolean, shouldReplace: boolean = true) {
		if (value) {
			updateUrlParams({ mybets: true }, shouldReplace);
		} else {
			updateUrlParams({ mybets: false }, shouldReplace);
		}
		this.isButtonToggled = value;
	}

	@action.bound
	updateEventId(eventId: string | null, isLive: boolean = false) {
		if (eventId == this.eventId && this.isLive == isLive) {
			return;
		}

		this.eventId = eventId;
		this.isLive = isLive;
		if (this.eventId == null) {
			this.onDispose();
		} else {
			this.resetDisplayBettingGroup();
			this.initializeAdditionalOfferStore();

			const urlParams = new URLSearchParams(window.location.search);
			const parsedJSON =
				// @ts-expect-error
				JSON.parse(urlParams.get("mybets") || null) || false;
			this.setToggleButton(parsedJSON);
		}
	}

	//#endregion actions

	//#region data fetching

	initializeAdditionalOfferStore() {
		this.disposeSubscription();
		this.disposeFavoritesBettingTypeReaction();

		if (this.isLive) {
			this._initializeLiveAdditionalOffer();
		} else {
			this._initializePrematchAdditionalOffer();
		}
		this.favoritesBettingTypeReaction = reaction(
			() =>
				JSON.stringify(this.favoritesStore.userFavoriteBettingTypesMap),

			() => {
				this.updateSorting();
			}
		);
	}

	@action.bound
	_initializeLiveAdditionalOffer() {
		this.disposeSubscription();
		this.disposeFavoritesBettingTypeReaction();

		const eventId = this.eventId;
		if (eventId == null) {
			return;
		}

		this.onStartFetching();

		//#region  subscription request obj

		const subscriptionId = `live-additional-offer-${eventId}`;

		let subscriptionRequest: ISubscriptionRequest = {
			subscriptionId: subscriptionId,
			compress: WEBPACK_OFFER_COMPRESS,
			channels: [
				{
					name: "event",
					filter: {
						id: eventId,
					},
				},
				{
					name: "betOffer",
				},
			],
			throttle: 3,
		};

		//#endregion  subscription request obj

		// call unsubscribe() in order to dispose subscription on the application hub
		this.subscription = this.rootStore.hub
			.getOfferSubscription(subscriptionRequest)
			.subscribe({
				next: (response) => {
					if (
						response.startingVersion === 0 &&
						response.lookups == null &&
						response.offerChanges == null
					) {
						this.onDispose();

						return;
					}

					runInAction(() => {
						this.assignOfferData(response);
						this.onDoneFetching();
					});
				},
				error: (err: any) => {
					console.error(err);
					this.onDoneFetching();
				},
			});

		this.favoritesBettingTypeReaction = reaction(
			() =>
				JSON.stringify(this.favoritesStore.userFavoriteBettingTypesMap),

			() => {
				this.updateSorting();
			}
		);
	}

	@action.bound
	async _initializePrematchAdditionalOffer() {
		this.onStartFetching();

		if (!this.eventId) {
			console.error("AdditionalOfferViewStore, eventId can not be null");
			this.onDoneFetching();
			return;
		}

		try {
			const offer = await PrematchOfferApiService.GetEventAdditionalOffer(
				{
					eventIds: [this.eventId],
				}
			);

			this.assignOfferData(offer);
		} catch (err) {
			console.error(err);
		} finally {
			this.onDoneFetching();
		}
	}

	@action.bound
	onStartFetching() {
		this.isFetchingData = true;
		this.loader.suspend();
	}

	@action.bound
	private onDoneFetching() {
		/** If There are no events or there are no betting types ( no offer ).
		 *  Close additional offer store. ( this currently only affects twd desktop as mill-v1 does not use url params) */
		this.isFetchingData = false;
		this.loader.resume();
		this.isStoreInitialized = true;
	}

	//#endregion data fetching

	//$region sorting
	@action.bound
	public updateSorting() {
		this.events.forEach((event) => {
			// Add sport to event
			// @ts-expect-error
			if (event.sport == null) {
				// @ts-expect-error
				event.sport = this.getMappedSport(event);
			}

			// Add default betting type groups to event
			// @ts-expect-error
			event.bettingTypeGroups = [];
			// @ts-expect-error
			const favoritesBettingTypesIds = [];
			const eventKeys = this.eventKeysMap.get(event.id);

			// @ts-expect-error
			if (eventKeys?.size > 0) {
				// Loop over all event keys and add there betting type group to event
				// Also add each unique betting type into betting type group
				// @ts-expect-error
				const btGroups = Array.from(eventKeys.values())
					.reduce((acc, eventKey) => {
						// if it's a favorite
						const isFavoriteBettingGroup =
							this.favoritesStore.isUserFavoriteBettingType(
								eventKey.bettingTypeId,
								eventKey.specifier || null
							);
						if (isFavoriteBettingGroup) {
							if (
								// @ts-expect-error
								favoritesBettingTypesIds.find(
									(btGroup) =>
										btGroup.abrv ===
										eventKey.bettingType.groupId
								) == null
							) {
								favoritesBettingTypesIds.push({
									...this.lookupsStore.bettingTypeGroups.get(
										// @ts-expect-error
										eventKey.bettingType.groupId
									),
									bettingTypes: [],
								});
							}

							//#endregion insert betting type group

							//#region  insert betting type into betting type group
							// @ts-expect-error
							const bettingGroup = favoritesBettingTypesIds.find(
								(btGroup) =>
									btGroup.abrv ===
									eventKey.bettingType.groupId
							);

							if (bettingGroup.bettingTypes == null) {
								bettingGroup.bettingTypes = [];
							}

							if (
								bettingGroup.bettingTypes.find(
									// @ts-expect-error
									(bt) => bt.id === eventKey.bettingTypeId
								) == null
							) {
								insert(
									bettingGroup.bettingTypes,
									eventKey.bettingType,
									Sorter.sort((a, b) => {
										const aSort =
											a?.settingsPerSport?.[event.sportId]
												?.sortOrder;
										const bSort =
											b?.settingsPerSport?.[event.sportId]
												?.sortOrder;

										const aSortOrder =
											aSort == null ? 99999 : aSort;
										const bSortOrder =
											bSort == null ? 99999 : bSort;

										return Math.sign(
											aSortOrder - bSortOrder
										);
									}, "id")
								);
							}
						}

						//#region insert betting type group
						if (
							acc.find(
								(btGroup) =>
									// @ts-expect-error
									btGroup.abrv ===
									eventKey.bettingType.groupId
							) == null
						) {
							acc.push(
								// @ts-expect-error
								this.lookupsStore.bettingTypeGroups.get(
									eventKey.bettingType.groupId || ""
								)
							);
						}

						//#endregion insert betting type group

						//#region  insert betting type into betting type group

						const bettingGroup = acc.find(
							(btGroup) =>
								// @ts-expect-error
								btGroup.abrv === eventKey.bettingType.groupId
						);

						// @ts-expect-error
						if (bettingGroup.bettingTypes == null) {
							// @ts-expect-error
							bettingGroup.bettingTypes = [];
						}

						if (
							// @ts-expect-error
							bettingGroup.bettingTypes.find(
								// @ts-expect-error
								(bt) => bt.id === eventKey.bettingTypeId
							) == null
						) {
							insert(
								// @ts-expect-error
								bettingGroup.bettingTypes,
								eventKey.bettingType,
								Sorter.sort((a, b) => {
									const aSort =
										a?.settingsPerSport?.[event.sportId]
											?.sortOrder;
									const bSort =
										b?.settingsPerSport?.[event.sportId]
											?.sortOrder;

									const aSortOrder =
										aSort == null ? 99999 : aSort;
									const bSortOrder =
										bSort == null ? 99999 : bSort;

									return Math.sign(aSortOrder - bSortOrder);
								}, "id")
							);
						}

						//#endregion insert betting type into betting type group

						return acc;
					}, [])
					.sort(Sorter.sort("sortOrder", "id"));

				// @ts-expect-error
				event.bettingTypeGroups = btGroups;
			}
			// @ts-expect-error
			event.favoritesBettingTypesIds = favoritesBettingTypesIds.sort(
				Sorter.sort("sortOrder", "id")
			);
			this.favoritesBettingTypeGroups = {
				...this.favoritesBettingTypeGroups,
				// @ts-expect-error
				[event.id]: event.favoritesBettingTypesIds,
			};
		});
	}
	//#endregion sorting

	//#region  disposers

	@action.bound
	public onDispose() {
		this.eventId = null;
		this.isStoreInitialized = false;
		this.isFetchingData = false;
		this.favoritesBettingTypeGroups = {};
		this.resetDisplayBettingGroup();

		this.disposeSubscription();
		this.disposeFavoritesBettingTypeReaction();

		this.rootStore.setAdditionalOfferVisible(false);
		this.reset();
	}

	@action.bound
	private disposeSubscription() {
		if (this.subscription != null) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	@action.bound
	disposeFavoritesBettingTypeReaction() {
		//maybe it works
		if (this.favoritesBettingTypeReaction != null) {
			this.favoritesBettingTypeReaction();
			this.favoritesBettingTypeReaction = null;
		}
	}

	//#endregion disposers
}
