import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { FormSelectField } from "../../shared/inputs";
import {
	mapPeriods,
	mapCategories,
} from "@v2/helpers/account-settings/limits-history/utils";
import {
	GamingLimitCategoryViewModel,
	GamingLimitHistoryFormSubmit,
} from "@data-types";
import Button from "@components/buttons/Button";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import { observer } from "mobx-react";

const LimitsHistoryFilterSection = observer(function LimitsHistoryFilterSection(
	props: PropTypes
) {
	const { setFilter, periods, categories } = props;
	const myLimitsStore = useContext(MyLimitsStoreContext);

	const { handleSubmit } = useFormContext();

	const onSuccess = async (data: GamingLimitHistoryFormSubmit) => {
		const modelData = { ...data, page: 1 };
		setFilter(modelData);
	};

	return (
		<form onSubmit={handleSubmit(onSuccess)}>
			<div className="card--limits__body">
				<div className="row u-mar--bottom--xsml">
					<FormSelectField
						dropdownData={mapPeriods(periods)}
						label={`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.PERIOD_LABEL`}
						name="period"
						className="col col-sml-12 form__field"
					/>
				</div>
				<div className="row u-mar--bottom--xsml">
					<FormSelectField
						dropdownData={mapCategories(categories)}
						label={`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.LIMIT_TYPE`}
						name="gamingTypeId"
						className="col col-sml-12 form__field"
					/>
				</div>
			</div>

			<div className="card--limits__footer">
				<Button
					className="btn btn--lrg btn--primary"
					btnText="USER.ACCOUNT_SETTINGS.GAMING_HISTORY.SHOW"
					isDisabled={myLimitsStore.isRequestPending}
				/>
			</div>
		</form>
	);
});

type PropTypes = {
	setFilter: Function;
	periods: GamingLimitCategoryViewModel[];
	categories: GamingLimitCategoryViewModel[];
};

export default LimitsHistoryFilterSection;
