import { Bet } from "@administration/pages/my-bets/model";
import { action, observable } from "mobx";
import { ChatViewStore } from "./ChatViewStore";
import BetDetailsStore from "@administration/pages/my-bets/BetDetailsStore";
import { localizationService } from "@state/services";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { BetSlipInteractionTypeEnum } from "@data-types/analytics/BetSlipInteractionTypeEnum";

export class ChatBetSlipShareHandler {
	@observable activePopupBet: Bet | null = null;

	chatViewStore: ChatViewStore;
	betDetailsStore: BetDetailsStore;

	constructor(chatViewStore: ChatViewStore) {
		this.chatViewStore = chatViewStore;
	}

	initializeBetDetailsStore() {
		this.betDetailsStore = new BetDetailsStore(
			App.offer.rootStore,
			"ChatBetSlipSharePopup"
		);
	}

	async openChatSharedSlipPopup(betSlipNr: string) {
		await this.betDetailsStore.getSharedBetSlipDetails(betSlipNr);

		this.setActivePopupBet(this.betDetailsStore.bet);

		if (!this.betDetailsStore.bet) {
			this.onDispose();
			this.chatViewStore.rootStore.notificationStore.error(
				localizationService.t("MY_BETS.SHARE.BET_SLIP_NOT_FOUND")
			);
		}
	}

	addToSlip(bet: Bet) {
		AnalyticsService.logBetSlipSharePopupAddToSlip({
			category: BetSlipInteractionTypeEnum.ChatBetSlipShare,
		});
		App.offer.rootStore.betSlipStore.sharedSlipAndMap({
			...bet,
		});
		this.onDispose();
	}

	@action.bound
	setActivePopupBet(bet: Bet | null) {
		this.activePopupBet = bet;
	}

	@action.bound
	onDispose() {
		this.activePopupBet = null;
		if (this.betDetailsStore) {
			this.betDetailsStore.onDispose();
		}
	}
}
