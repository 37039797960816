import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { MyBetsStoreContext, MyBetsBetContext } from "../../../../context";

import { CashoutType } from "@gp/hub";
import { ISOToDisplayCode } from "@utils";

export default observer(function CashoutActions() {
	const bet = useContext(MyBetsBetContext);

	if (
		bet.cType !== CashoutType.JACKTIME ||
		bet.betStatus.abrv !== "open" ||
		bet.displayedAmount === null
	) {
		return null;
	}

	return (
		<>
			<CashoutButton />
			<AutosellButton />
			<CashoutHistoryButton />
		</>
	);
});

const CashoutHistoryButton = observer(function CashoutHistoryButton() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);
	const store = useContext(MyBetsStoreContext);

	if (store == null) {
		console.error("Expected Store, got null.");
		return null;
	}

	return (
		<button
			type="button"
			className="card--bet__btn card--bet__btn--history btn btn--tny btn--secondary--inverted"
			onClick={() =>
				store.cashoutStore.fetchCashoutHistoryForBetSlip(bet)
			}
		>
			{t("MY_BETS.ITEM.CASHOUT_HISTORY")}
		</button>
	);
});

const CashoutButton = observer(function CashoutButton() {
	const bet = useContext(MyBetsBetContext);
	const store = useContext(MyBetsStoreContext);
	if (store == null) {
		console.error("Expected store, got null");
		return null;
	}
	if (bet == null) {
		console.error("Expected bet got null");
		return null;
	}

	if (bet.cType == undefined) {
		console.error("cType can not be null");
		return null;
	}

	if (
		bet.displayedAmount == null ||
		(bet.displayedAmount != null && bet.displayedAmount < "1")
	) {
		return <EmptyCashoutButtonCore />;
	}

	const buttonClasses = classNames(
		"card--bet__btn card--bet__btn--cashout btn btn--tny btn--cashout btn--icon",
		{
			inc: bet.indicator === 1,
			dec: bet.indicator === -1,
		}
	);

	const cashOutModalData: Parameters<
		typeof store["cashoutStore"]["openCashoutModal"]
	>[0] = {
		id: bet.id,
		calculatedCashoutAmount: bet.calculatedCashoutAmount,
		displayedAmount: bet.displayedAmount,
		cType: bet.cType,
		isChangeAccepted: bet.isChangeAccepted,
		isLive: bet.isLive,
	};

	return (
		<button
			type="button"
			className={buttonClasses}
			disabled={store.cashoutStore.isCashoutFormSubmitting}
			onClick={(e) =>
				store.cashoutStore.openCashoutModal(cashOutModalData)
			}
		>
			<CashOutIndicator />
			<CashoutButtonCore />
		</button>
	);
});

function EmptyCashoutButtonCore() {
	const { t } = useTranslation();

	return (
		<button
			className="card--bet__btn card--bet__btn--cashout btn btn--tny btn--cashout btn--icon"
			disabled
		>
			{t<string>("MY_BETS.ITEM.CASHOUT")}
		</button>
	);
}

const CashoutButtonCore = observer(function CashoutButtonCore() {
	const { displayedAmount } = useContext(MyBetsBetContext);
	const { t } = useTranslation();

	return (
		<>
			{t("MY_BETS.ITEM.CASHOUT")} {displayedAmount}{" "}
			{ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)}
		</>
	);
});

const CashOutIndicator = observer(function CashOutIndicator() {
	const { indicator } = useContext(MyBetsBetContext);

	if (indicator === 0) {
		return null;
	}

	const indicatorClasses = classNames("u-icon u-icon--xsml", {
		"u-icon--caret--up u-color--ui--success": indicator === 1,
		"u-icon--caret--down u-color--ui--danger": indicator === -1,
	});

	return <i className={indicatorClasses} />;
});

const AutosellButton = observer(function AutosellButton() {
	const bet = useContext(MyBetsBetContext);
	const store = useContext(MyBetsStoreContext);

	if (store == null) {
		console.error("Expected store, got null.");
		return null;
	}
	const {
		cashoutStore: { openAutosellModal, isAutoCashoutEnabled },
	} = store;

	const { t } = useTranslation();

	if (!isAutoCashoutEnabled) {
		return null;
	}

	const autoCashoutButtonClasses = classNames(
		"card--bet__btn card--bet__btn--autosell btn btn--tny btn--secondary--inverted btn--icon",
		{
			"card--bet__btn--autosell-set": bet.isAutoCashoutSet ? true : false,
		}
	);

	const autoCashoutIconClasses = classNames("u-icon u-icon--sml", {
		"u-icon--settings": !bet.isAutoCashoutSet,
		"u-icon--set-stake": bet.isAutoCashoutSet,
	});

	if (
		isAutoCashoutEnabled &&
		bet.displayedAmount !== undefined &&
		bet.displayedAmount !== null &&
		bet.displayedAmount !== "0.00"
	) {
		return (
			<button
				type="button"
				className={autoCashoutButtonClasses}
				onClick={(e) => openAutosellModal(bet)}
			>
				<i className={autoCashoutIconClasses} />
				<span className="btn__label">
					{t("MY_BETS.CASHOUT.AUTO_CASHOUT")}{" "}
					{bet.isAutoCashoutSet && bet.betSlipSecureWinSetting != null
						? `${bet.betSlipSecureWinSetting.threshold.toFixed(
								2
						  )} ${ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)}`
						: ""}
				</span>
			</button>
		);
	} else {
		return null;
	}
});
