import React, { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { EventContext, OfferStoreContext } from "@gp/components";

export const BettingTypeGroupHeader = observer(
	function BettingTypeGroupHeader(props: {
		setSelectedGroup: (newGroup: string) => void;
		selectedGroup: string;
	}) {
		const event = useContext(EventContext);
		const { t } = useTranslation();

		const eventViewStore = useContext(OfferStoreContext);
		const favoritesBetting =
			// @ts-expect-error
			eventViewStore.favoritesBettingTypeGroups?.[event.id];
		const isFavoritesEmpty =
			// @ts-expect-error
			favoritesBetting && event.favoritesBettingTypesIds.length == 0;
		const allGroup = {
			abrv: "all",
			name: t("BETTING_GROUPS.ALL"),
		};

		const FavoritesGroup = {
			abrv: "favorites",
			name: t("LIVE.SIDEMENU.FAVORITES"),
		};

		return (
			<>
				<BettingTypeHeaderBettingGroupsButton
					bettingTypeGroup={allGroup}
					setSelectedGroup={props.setSelectedGroup}
					selectedGroup={props.selectedGroup}
				/>
				{!isFavoritesEmpty && (
					<BettingTypeHeaderBettingGroupsButton
						bettingTypeGroup={FavoritesGroup}
						setSelectedGroup={props.setSelectedGroup}
						selectedGroup={props.selectedGroup}
					/>
				)}
				{/* @ts-expect-error */}
				{event.bettingTypeGroups.map((btg) => (
					<BettingTypeHeaderBettingGroupsButton
						key={btg.abrv}
						bettingTypeGroup={btg}
						setSelectedGroup={props.setSelectedGroup}
						selectedGroup={props.selectedGroup}
					/>
				))}
			</>
		);
	}
);

const BettingTypeHeaderBettingGroupsButton = observer(
	function BettingTypeHeaderBettingGroupsButton(props: {
		bettingTypeGroup: { abrv: string; name: string };
		setSelectedGroup: (newGroup: string) => void;
		selectedGroup: string;
	}) {
		const { bettingTypeGroup, setSelectedGroup, selectedGroup } = props;

		return (
			<button
				className={classNames("tabs--primary__item", {
					"is-active": selectedGroup === bettingTypeGroup.abrv,
				})}
				onClick={() => setSelectedGroup(bettingTypeGroup.abrv)}
				type="button"
			>
				{bettingTypeGroup.name}
			</button>
		);
	}
);
