import React, { useState, useEffect, useMemo } from "react";

const loadedScripts = new Map<string, boolean>();

export function ReactScriptLoad(props: {
	src: string;
	onError: (error: ErrorEvent) => void;
	fallBack?: JSX.Element | null;
	children: JSX.Element | null;
}): JSX.Element | null {
	const [isLoaded, setIsLoaded] = useState(
		loadedScripts.get(props.src) ?? false
	);
	const [isError, setIsError] = useState(false);
	const setLoaded = useMemo(
		() => (ev: Event) => {
			loadedScripts.set(props.src, true);
			setIsLoaded(true);
		},
		[]
	);
	const setError = useMemo(
		() => (ev: ErrorEvent) => {
			setIsError(true);
			props.onError(ev);
		},
		[]
	);

	useEffect(() => {
		if (isLoaded) {
			return;
		}
		let script = document.querySelector(`script[src="${props.src}"]`);

		if (script == null) {
			script = document.createElement("script");
			// @ts-expect-error
			script.type = "application/javascript";
			// @ts-expect-error
			script.src = props.src;
			// @ts-expect-error
			script.async = true;
			document.head.appendChild(script);
		}
		script.addEventListener("load", setLoaded);
		script.addEventListener("error", setError);

		return () => {
			document.removeEventListener("load", setLoaded);
			document.removeEventListener("error", setError);
		};
	}, []);

	if (isLoaded) {
		return props.children;
	}

	if (isError && props.onError == null) {
		throw "Error loading external script";
	}

	return props.fallBack || null;
}
