import React from "react";
import { useTranslation } from "react-i18next";

export default function RestrictedSection() {
	const t = useTranslation().t;

	return (
		<div className="message message--danger">
			<i className="u-icon u-icon--xlrg u-icon--restricted u-icon--animated--bounce-in u-color--ui--danger u-mar--right--sml" />
			{t<string>("RESTRICTION.COUNTRY_ACCESS_RESTRICTION_INFO", {
				0: t("RESTRICTION.COMPANY_LABEL"),
			})}
		</div>
	);
}
