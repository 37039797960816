import {
	CountryDataType,
	LanguageDataType,
} from "@data-types/membership/registration";
import { DateTime } from "luxon";
import { PhoneNumberUtil } from "google-libphonenumber";
import { isNullOrWhitespace } from "@utils";

export const isPhoneValidOnRegistration = (phone: string) => {
	const phoneUtil = PhoneNumberUtil.getInstance();

	if (phone != undefined) {
		if (phone.length > 5) {
			if (isNullOrWhitespace(phone)) {
				return true;
			} else {
				try {
					return phoneUtil.isValidNumber(
						phoneUtil.parseAndKeepRawInput(phone)
					);
				} catch (error) {
					return false;
				}
			}
		} else {
			return true;
		}
	} else {
		return true;
	}
};

export function checkLeapYear(year: string) {
	const leapYear = false;
	if (
		(parseInt(year) % 4 == 0 && parseInt(year) % 100 != 0) ||
		parseInt(year) % 400 == 0
	) {
		return !leapYear;
	} else {
		return leapYear;
	}
}

export function validateCountry(
	countryId: string,
	countryData: CountryDataType[] | null,
	dateOfBirth: string
) {
	if (
		countryData?.find(
			(country) => country.id === countryId && country.abrv === "estonia"
		)
	) {
		//estonia - 21 years
		const dobYearDiff =
			DateTime.fromISO(dateOfBirth).diffNow("years").years;
		return dobYearDiff <= -21 || "MEMBERSHIP.REGISTRATION.INVALID_DOB";
	}
}

export const mapLanguagesForFormSelect = (
	defaultLangs: LanguageDataType[] | null
) => {
	if (!defaultLangs) {
		return;
	}

	return defaultLangs.map((lang) => {
		return {
			id: lang.culture,
			name: lang.name,
		};
	});
};

export const mapNationalityForFormSelect = (
	countryData: CountryDataType[] | null | undefined
) => {
	return countryData
		?.filter((c) => c.nationality !== "")
		.map((c) => {
			return {
				id: c.id,
				name: c.nationality,
			};
		});
};

const prepareData = (min: number, max: number) => {
	const arr = [];
	for (var i = min; i <= max; i++) {
		arr.push({
			id: i,
			name: i,
		});
	}

	return arr;
};

export const months = prepareData(1, 12);

export const days = prepareData(1, 31);

export const years = prepareData(
	DateTime.now().minus({ years: 100 }).year,
	DateTime.now().minus({ years: 18 }).year
);
