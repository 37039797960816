import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fixBody, resetBody } from "@utils";

export default function useFixBody(useFixedPosition, isOpened, elementId) {
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname.includes("live")) {
			return;
		}

		if (isOpened) {
			fixBody(useFixedPosition, elementId);
		} else {
			resetBody(useFixedPosition, elementId);
		}

		return () => resetBody(useFixedPosition, elementId);
	}, [isOpened]);
}
