import { httpClient } from "@utils";
import { CapacitorVersion } from "@api-types/capacitor/CapacitorVersion";

export default class CapacitorUpdaterApiService {
	public static get s3BucketUpdatePath(): string {
		return `${WEBPACK_CDN_ROOT_URL}/${WEBPACK_CDN_CAPACITOR_PATH}`;
	}

	static async getCurrentMobileVersion(): Promise<CapacitorVersion> {
		const url = `${this.s3BucketUpdatePath}/version.json`;
		return httpClient.get(
			url,
			{
				"Content-Type": "application/json",
			},
			{
				responseType: "json",
				cache: "no-store",
			}
		);
	}
}
