import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LimitsHistoryTableSection, LimitsHistoryFilterSection } from "./";
import { useLimitsHistory } from "@v2/hooks/account-settings/limits-history/useLimitsHistory";
import { AccountTabs } from "../../shared/header";
import { GamingLimitHistoryFormSubmit } from "@data-types";
import { useUser } from "@v2/hooks";
import { AccountTypes } from "@utils";

export default function LimitsHistoryPage() {
	const user = useUser();
	const { t } = useTranslation();

	const [activeAccount, setActiveAccount] = useState(
		user?.secondaryUser ? AccountTypes.ONLINE : AccountTypes.OFFLINE
	);

	return (
		<div className="l--content__main">
			<div className="container">
				<div className="at-limitsHistoyCard card--primary card--limits">
					<div className="card--primary__header">
						<div className="card--primary__title title--primary">
							{t<string>(
								`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.TITLE`
							)}
						</div>
					</div>

					<AccountTabs
						activeAccount={activeAccount}
						setActiveAccount={setActiveAccount}
					/>
				</div>
				<InternetAccountSection activeAccount={activeAccount} />

				<ShopAccountSection activeAccount={activeAccount} />
			</div>
		</div>
	);
}

function InternetAccountSection(props: AccountSectionTypes) {
	const { activeAccount } = props;

	const methods = useForm({ mode: "onChange" });
	const { reset } = methods;

	const [filter, setFilter] = useState<GamingLimitHistoryFormSubmit>({
		gamingTypeId: "default",
		page: 1,
		period: "last6months",
	});

	const { onInitializePage, lookups, onInitializeTableSection } =
		useLimitsHistory(reset, activeAccount);

	useEffect(() => {
		if (activeAccount == AccountTypes.ONLINE) {
			onInitializePage();
			return () =>
				setFilter({
					gamingTypeId: "default",
					page: 1,
					period: "last6months",
				});
		}
	}, [activeAccount]);

	if (activeAccount != AccountTypes.ONLINE) {
		return null;
	}

	return (
		<>
			<FormProvider {...methods}>
				<LimitsHistoryFilterSection
					setFilter={setFilter}
					periods={lookups.limitPeriods}
					categories={lookups.availableCategories}
				/>
			</FormProvider>

			<LimitsHistoryTableSection
				filter={filter}
				setFilter={setFilter}
				onInitializeTableSection={onInitializeTableSection}
				activeAccount={activeAccount}
			/>
		</>
	);
}

function ShopAccountSection(props: AccountSectionTypes) {
	const { activeAccount } = props;

	const methods = useForm({ mode: "onChange" });
	const { reset } = methods;

	const [filter, setFilter] = useState<GamingLimitHistoryFormSubmit>({
		gamingTypeId: "default",
		page: 1,
		period: "last6months",
	});

	const { onInitializePage, lookups, onInitializeTableSection } =
		useLimitsHistory(reset);

	useEffect(() => {
		if (activeAccount == AccountTypes.OFFLINE) {
			onInitializePage();
			return () =>
				setFilter({
					gamingTypeId: "default",
					page: 1,
					period: "last6months",
				});
		}
	}, [activeAccount]);

	if (activeAccount != AccountTypes.OFFLINE) {
		return null;
	}

	return (
		<>
			<FormProvider {...methods}>
				<LimitsHistoryFilterSection
					setFilter={setFilter}
					periods={lookups.limitPeriods}
					categories={lookups.availableCategories}
				/>
			</FormProvider>

			<LimitsHistoryTableSection
				filter={filter}
				setFilter={setFilter}
				onInitializeTableSection={onInitializeTableSection}
				activeAccount={activeAccount}
			/>
		</>
	);
}

type AccountSectionTypes = {
	activeAccount: AccountTypes;
};
