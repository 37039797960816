export type ModalInfoType = {
	modalName: ModalType;
};

export enum ModalType {
	CONSENT_MODAL = "consentModal",
	PREFERENCES_MODAL = "preferencesModal",
}

export enum ModalStatus {
	SHOW = "show",
	HIDE = "hide",
}
