import { getCurrentCulture } from "@utils";
import { DateTime } from "luxon";

/**
 * Validates string
 * @param {string} value
 */
export function isNullOrWhitespace(
	value: string | null | undefined
): value is string {
	if (typeof value === "undefined" || value == null) {
		return true;
	}
	return value.replace(/\s/g, "").length < 1;
}

export const formatDateTime = (date: string) => {
	return DateTime.fromISO(date).toLocaleString(
		DateTime.DATETIME_SHORT_WITH_SECONDS
	);
};

export function formValueAvailabilityDebounceMethod(): [
	/** bouncer  */
	<Value, Result>(
		value: Value,
		method: (value: Value) => Promise<Result>
	) => Promise<Result | undefined>,
	/** Cancel request */
	() => void
] {
	let timeoutId: ReturnType<typeof setTimeout> | undefined | number =
		undefined;
	let prevReject: undefined | ((reason?: any) => void) = undefined;
	let prevResolve: undefined | ((value?: any) => void) = undefined;
	return [
		function debouncer<Value, Result>(
			value: Value,
			method: (value: Value) => Promise<Result>
		) {
			clearTimeout(timeoutId);
			return new Promise<Awaited<Result | undefined>>((res, rej) => {
				prevResolve?.();
				prevResolve = res;
				prevReject = rej;

				timeoutId = setTimeout(async () => {
					const availabilityCheck = await method(value);
					res(availabilityCheck);
				}, 500);
			});
		},
		() => {
			clearTimeout(timeoutId);
			// Maybe should be resolve too?
			prevReject?.("Canceled.");
			prevResolve?.("Cancelled");
		},
	];
}

export function generateImgNameWithLangAndDevice(
	pictureName: string,
	device: "web" | "mobile"
): string {
	const currentLang = getCurrentCulture().toUpperCase();
	return `${pictureName}_${device}_${currentLang}`;
}
