import React from "react";
import { useTranslation } from "react-i18next";

//#region lazy

import { getCurrentCulture } from "@utils";
import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const ReactMarkdown = lazy(loadFailPath, () => import("react-markdown"));

//#endregion lazy

export default function PopoverMessage(props: {
	popoverMsg: React.ReactNode | string | null;
	mouseOverState: boolean;
}) {
	const { popoverMsg = null, mouseOverState = false } = props;

	if (popoverMsg == null) {
		return null;
	}

	return (
        <i className="form__field__info u-icon u-icon--sml u-icon--info--alt popover">
			<PopoverContainer
				mouseOverState={mouseOverState}
				popoverMsg={popoverMsg}
			/>
		</i>
	);
}

function PopoverContainer(props: {
	popoverMsg: string | React.ReactNode;
	mouseOverState: boolean;
}) {
	const { t } = useTranslation();

	const { popoverMsg, mouseOverState } = props;

	if (mouseOverState == false) {
		return null;
	}

	if (typeof popoverMsg === "string") {
		return (
			<div className="popover__wrapper popover--bottom__wrapper popover__wrapper--big">
				<div className="popover__content">
					<ReactMarkdown>{t(popoverMsg)}</ReactMarkdown>
				</div>
				<span className="popover__arrow popover--bottom__arrow" />
			</div>
		);
	}

	return (
		<div className="popover__wrapper popover--bottom__wrapper popover__wrapper--big">
			<div className="popover__content">{popoverMsg}</div>
			<span className="popover__arrow popover--bottom__arrow" />
		</div>
	);
}
