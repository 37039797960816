import React, { useContext } from "react";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { SportOffer } from "@gp/offer";
import {
	SportOfferContext,
	OfferOptionsContext,
	LookupContext,
	OfferStoreContext,
} from "@gp/components";

import { Dropdown, HeaderQuoteRow } from "./column-type-selectors";

export const ColumnTypeSelectors = observer(
	function ColumnTypeSelectors(props: {
		isLive?: boolean;
		isHome?: boolean;
	}) {
		if (props.isLive) {
			return <Live isHome={props.isHome} />;
		}

		return <Prematch />;
	}
);

const Prematch = observer(function Prematch() {
	const sport = useContext(SportOfferContext) as SportOffer;
	const viewStore = useContext(OfferStoreContext);
	const lookups = useContext(LookupContext);
	const options = useContext(OfferOptionsContext);
	const t = useTranslation().t;

	const sportSelectors =
		viewStore.configuration.bettingTypeSelectorsStore.getSportSelector(
			sport
		) || {};

	const availableSelectors = sport.headers?.selectors.filter((s) =>
		s.primary.some((p) => lookups.bettingTypesByAbrv.has(p))
	);

	const allAvailableMapped = availableSelectors?.map((s) => ({
		abrv: s.name,
		displayName:
			lookups.bettingTypesByAbrv.get(s.name)?.simpleName ||
			t("BETTING_TYPE.HEADER." + s.name),
	}));

	function updateSelectors(column: string, newSelector: string) {
		runInAction(() => {
			// NOTE: every change on sportSelectors will update state that is why it is wrapped in the runInAction

			// pick selector configuration for available sport selectors
			const chosenSelector = availableSelectors?.find(
				(s) => s.name === newSelector
			);

			if (chosenSelector == null) {
				console.error("Failed to find chosen selector");
				return;
			}

			const existingSelectorColumn =
				Object.values(sportSelectors).findIndex(
					(ss) => ss.name === chosenSelector?.name
				) + 1; // +1 because index is 0-based and columns are 1-based

			if (existingSelectorColumn > 0) {
				// we need to swap
				const temp = sportSelectors[column];

				sportSelectors[column] = {
					name: chosenSelector.name,
					tips: chosenSelector.tips,
					displayTips: chosenSelector.displayTips,
					bettingTypes: chosenSelector.primary,
					secondaryBettingTypes: chosenSelector.secondary,
				};
				sportSelectors[`${existingSelectorColumn}`] = temp;
			} else {
				// do normal update
				sportSelectors[column] = {
					name: chosenSelector.name,
					tips: chosenSelector.tips,
					displayTips: chosenSelector.displayTips,
					bettingTypes: chosenSelector.primary,
					secondaryBettingTypes: chosenSelector.secondary,
				};
			}

			viewStore.configuration.bettingTypeSelectorsStore.setSportSelector(
				sport,
				sportSelectors
			);
		});
	}

	const disabledOptions = Object.keys(sportSelectors)
		.filter(
			(colIdx) =>
				sportSelectors[colIdx] != null &&
				// @ts-expect-error
				colIdx <= options.numberOfColumns
		)
		.map((colIdx) => sportSelectors[colIdx].name);

	return (
		<>
			{[...Array(options.numberOfColumns)].map((_, i) => {
				const colIndex = i + 1;

				if (
					sportSelectors == null ||
					sportSelectors[`${colIndex}`] == null
				) {
					return (
						<div
							key={i}
							className="header--sport__item header--sport__type"
						/>
					);
				}

				const columnSelector = sportSelectors[`${colIndex}`];

				return (
					<Dropdown
						key={i}
						disabledOptions={disabledOptions}
						value={columnSelector}
						allAvailable={allAvailableMapped}
						onChange={(newSelectorValue) => {
							// newSelectorValue is selector name and NOT betting type abrv!
							updateSelectors(`${colIndex}`, newSelectorValue);
						}}
					/>
				);
			})}
		</>
	);
});

const Live = observer(function Live(props: { isHome?: boolean }) {
	const { t } = useTranslation();

	const sport = useContext(SportOfferContext) as SportOffer;
	const viewStore = useContext(OfferStoreContext);
	const lookups = useContext(LookupContext);
	const options = useContext(OfferOptionsContext);

	const sportSelectors =
		viewStore.configuration.bettingTypeSelectorsStore.getSportSelector(
			sport
		);

	if (sportSelectors == null) {
		return null;
	}

	// const mainHeaders = sport.headers.primary;
	const availableSelectors = sport.headers?.selectors.filter((s) =>
		s.primary.some((p) => lookups.bettingTypesByAbrv.has(p))
	);

	const allAvailableMapped = availableSelectors?.map((s) => ({
		abrv: s.name,
		displayName:
			lookups.bettingTypesByAbrv.get(s.name)?.simpleName ||
			t("BETTING_TYPE.HEADER." + s.name),
	}));

	function updateSelectors(column: string, newSelector: string) {
		runInAction(() => {
			// NOTE: every change on sportSelectors will update state that is why it is wrapped in the runInAction

			// pick selector configuration for available sport selectors
			const chosenSelector = availableSelectors?.find(
				(s) => s.name === newSelector
			);

			const existingSelectorColumn =
				Object.values(sportSelectors || {}).findIndex(
					(ss) => ss.name === chosenSelector?.name
				) + 1; // +1 because index is 0-based and columns are 1-based

			if (chosenSelector == null) {
				console.error("Failed to find chosen selector");
				return;
			}

			if (sportSelectors == null) {
				console.error("Expected sport selectors, got null.");
				return;
			}

			if (existingSelectorColumn > 0) {
				// we need to swap
				const temp = sportSelectors[column];

				sportSelectors[column] = {
					name: chosenSelector.name,
					tips: chosenSelector.tips,
					displayTips: chosenSelector.displayTips,
					bettingTypes: chosenSelector.primary,
					secondaryBettingTypes: chosenSelector.secondary,
				};
				sportSelectors[`${existingSelectorColumn}`] = temp;
			} else {
				// do normal update
				sportSelectors[column] = {
					name: chosenSelector.name,
					tips: chosenSelector.tips,
					displayTips: chosenSelector.displayTips,
					bettingTypes: chosenSelector.primary,
					secondaryBettingTypes: chosenSelector.secondary,
				};
			}

			viewStore.configuration.bettingTypeSelectorsStore.setSportSelector(
				sport,
				sportSelectors
			);
		});
	}

	const disabledOptions = Object.keys(sportSelectors)
		.filter(
			(colIdx) =>
				sportSelectors[colIdx] != null &&
				// @ts-expect-error
				colIdx <= options.numberOfColumns
		)
		.map((colIdx) => sportSelectors[colIdx].name);

	return (
		<>
			{[...Array(options.numberOfColumns)].map((_, i) => {
				const colIndex = i + 1;

				if (
					sportSelectors == null ||
					sportSelectors[`${colIndex}`] == null
				) {
					return (
						<div
							key={i}
							className="offer__head__data offer__type"
						/>
					);
				}

				const columnSelector = sportSelectors[`${colIndex}`];

				return (
					<div key={i} className="offer__head__data offer__type">
						<div
							className={classNames("offer__type__row", {
								"u-mar--bottom--tny": props.isHome,
							})}
						>
							<Dropdown
								disabledOptions={disabledOptions}
								value={columnSelector}
								allAvailable={allAvailableMapped}
								onChange={(newValue) =>
									updateSelectors(`${colIndex}`, newValue)
								}
								isLive={true}
							/>
						</div>
						<HeaderQuoteRow columnSelector={columnSelector} />
					</div>
				);
			})}
		</>
	);
});
