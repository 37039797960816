import { WithdrawalApiService } from "@api-services/withdrawal/WithdrawalApiService";
import LookupApiService from "@api-services/LookupApiService";
import { BaseLookupDto } from "@api-types/lookups/BaseLookupDto";
import {
	WithdrawalDataDto,
	WithdrawalResponseDto,
	UsedPaymentMethodDto,
	WithdrawalInitRequestDataDto,
} from "@api-types/withdrawal";
import { PaymentRequestFilter } from "@data-types/withdrawal";
import { FindResultDto } from "@api-types/FindResultDto";
import { UserPaymentTransactionsApiService } from "@api-services/user-payment-transactions/UserPaymentTransactionsApiService";
import { PaymentManagerApiService } from "@api-services/withdrawal/PaymentManagerApiService";

export class WithdrawalService {
	static async getAvailableMethods(
		bettingAccountTypeId: string
	): Promise<WithdrawalDataDto> {
		return await WithdrawalApiService.getAvailableMethods(
			bettingAccountTypeId
		);
	}

	static async initializeNewWithdrawalProcess(
		data: WithdrawalInitRequestDataDto
	): Promise<WithdrawalDataDto> {
		return await WithdrawalApiService.initializeNewWithdrawalProcess(data);
	}

	static async getUserPaymentMethods(): Promise<UsedPaymentMethodDto[]> {
		const isDeposit = false;
		return await LookupApiService.getUserPaymentMethods(isDeposit);
	}

	static async getTransactionStatuses(): Promise<
		FindResultDto<BaseLookupDto>
	> {
		return await LookupApiService.getTransactionStatuses();
	}

	static async getWithdrawalData(
		bodyData: PaymentRequestFilter
	): Promise<WithdrawalResponseDto> {
		return await UserPaymentTransactionsApiService.getWithdrawalData(
			bodyData
		);
	}

	static async cancelWithdrawalTransaction(
		transactionId: string
	): Promise<any> {
		return await PaymentManagerApiService.cancelWithdrawalTransaction(
			transactionId
		);
	}
}
