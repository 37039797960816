import React, { useEffect, useMemo, FC } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { HeaderWithSideMenu } from "@v2/components/desktop/shared/header";
import { Footer } from "@v2/components/desktop/shared/footer";
import WithdrawalStore from "@state/stores/WithdrawalStore";
import { getCurrentCulture, setTabTitle } from "@utils";
import { WithdrawalContext } from "@context";
import { Redirect, Route, Switch } from "react-router-dom";
import { MyWithdrawalsBody, NewWithdrawalBody } from "./components";
import { AdministrationSideMenu } from "../shared/side-menu";
import { LoaderSpinner } from "@v2/components/shared";

export const WithdrawalPage: FC = observer(function WithdrawalPage() {
	const { t } = useTranslation();

	const withdrawalStore = useMemo(
		() => new WithdrawalStore(App.state.rootStore),
		[]
	);

	useEffect(() => {
		const fetchData = async () => {
			setTabTitle(t("WITHDRAWAL.TITLE"));
			//on mount scroll to top
			document.querySelector("html, body")?.scrollTo(0, 0);
			withdrawalStore.onInitialize();
			return () => withdrawalStore.onDispose();
		};

		fetchData();
	}, []);

	return (
		<WithdrawalContext.Provider value={withdrawalStore}>
			<LoaderSpinner isLoading={withdrawalStore.isLoading} />
			<HeaderWithSideMenu />
			<aside className="l--aside--left">
				<AdministrationSideMenu />
			</aside>
			<main className="l--main l--main--med">
				<section className="l--content l--content--full">
					<div className="l--content__main">
						<div className="container">
							<div className="container">
								<WithdrawalTabs />
							</div>
						</div>
					</div>
				</section>
			</main>
			<footer className="l--footer">
				<Footer />
			</footer>
		</WithdrawalContext.Provider>
	);
});

const WithdrawalTabs: FC = function WithdrawalTabs() {
	return (
		<Switch>
			<Route exact path={["/:culture/withdrawal"]}>
				<NewWithdrawalBody />
			</Route>

			<Route exact path={["/:culture/withdrawal/history"]}>
				<MyWithdrawalsBody />
			</Route>

			<Redirect to={`/${getCurrentCulture()}/home/full/highlights`} />
		</Switch>
	);
};
