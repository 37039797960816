import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";

export function RecoverPasswordSuccess(props: any) {
	const { isRecoverPasswordSuccess } = props;

	const { t } = useTranslation();

	if (!isRecoverPasswordSuccess) {
		return null;
	}

	return (
		<div className="card--message">
			<div className="card--message__thumb" />
			<div className="card--message__body">
				<div className="card--message__content">
					<i className="card--message__icon u-icon u-icon--xlrg u-icon--check-circle u-icon--animated--bounce-in u-color--ui--success" />
					<div className="card--message__title">
						{t<string>("MEMBERSHIP.RECOVER_PASSWORD.PASSWORD_CHANGE_SUCCESS")}
					</div>
					<div>
						{t<string>("MEMBERSHIP.RECOVER_PASSWORD.USE_NEW_PASSWORD")}
					</div>
					<div className="card--message__footer">
						<a
							href={`/${getCurrentCulture()}/auth/login`}
							className="card--message__cta btn btn--lrg btn--primary btn--icon btn--animate-forward"
						>
							<span className="btn__label">{t<string>("MEMBERSHIP.RECOVER_PASSWORD.LOGIN")}</span>
							<i className="btn__icon u-icon u-icon--xsml u-icon--arrow--right u-color--core--snow" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}