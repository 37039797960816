import React, { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormInputFieldCurrency } from "@v2/components/desktop/shared/inputs";
import { useTranslation } from "react-i18next";
import { LimitSubmit, UserLimit } from "@data-types";
import { MyLimitsStoreContext } from "@v2/context/MyLimitsContext";
import { SubmitLimitAction } from "@v2/state/myLimits/MyLimitsStore";
import { observer } from "mobx-react";
import Button from "@components/buttons/Button";
import FormButton from "@components/buttons/FormButton";

const MyLimitsLimit = observer(function MyLimitsLimit(props: UserLimit) {
	const {
		name,
		canBeDeleted,
		canBeUpdated,
		amount,
		categoryId,
		durationId,
		typeId,
		limitId,
	} = props;

	const { t } = useTranslation();
	const myLimitsStore = useContext(MyLimitsStoreContext);

	const methods = useForm({
		mode: "onChange",
		defaultValues: { amount: amount },
	});

	const {
		reset,
		handleSubmit,
		formState: { isSubmitted },
	} = methods;

	useEffect(() => {
		reset({ amount: amount });
	}, [amount, isSubmitted]);

	async function submitForm(
		formData: LimitSubmit,
		action: SubmitLimitAction
	) {
		const model = {
			amount: formData.amount,
			action,
			categoryId,
			durationId,
			typeId,
			limitId,
		};

		await myLimitsStore.submitLimit(model);
	}

	return (
		<FormProvider {...methods}>
			<form>
				<div className="card--primary u-mar--bottom--big">
					<div className="card--primary__body u-padd--xsml">
						<div className="form__group u-mar--bottom--xsml">
							<div className="title--secondary">{name}</div>
							<div className="row">
								<FormInputFieldCurrency
									label="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_AMOUNT_LABEL"
									name="amount"
									type="number"
									className="col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col--ssvga-6 col-hdlow-6 col-xxlrg-6 col-huge-6 col-xxxlrg-6 form__field"
									placeholder="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_AMOUNT_PLACEHOLDER"
									validate={{
										isNotNegative: (value: number) =>
											value >= 0 ||
											t(
												"USER.ACCOUNT_SETTINGS.MY_LIMITS.ZERO_OR_GREATER"
											),
									}}
									maxLength={10}
									required
								/>
							</div>
							<div className="btn-group u-mar--top--xsml">
								{canBeDeleted && (
									<Button
										className="btn btn--sml btn--secondary--inverted"
										onClick={handleSubmit(
											(data: LimitSubmit) =>
												submitForm(
													data,
													SubmitLimitAction.REMOVE
												)
										)}
										btnText="COMMON.DEACTIVATE_BUTTON"
										isDisabled={
											myLimitsStore.isRequestPending
										}
									/>
								)}

								{canBeUpdated ? (
									<FormButton
										className="btn btn--sml btn--primary"
										onClick={handleSubmit(
											(data: LimitSubmit) =>
												submitForm(
													data,
													SubmitLimitAction.UPDATE
												)
										)}
										btnText="COMMON.SAVE_BUTTON"
									/>
								) : (
									<FormButton
										className="btn btn--sml btn--primary"
										onClick={handleSubmit(
											(data: LimitSubmit) =>
												submitForm(
													data,
													SubmitLimitAction.ADD
												)
										)}
										btnText="COMMON.SAVE_BUTTON"
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</form>
		</FormProvider>
	);
});

export default MyLimitsLimit;
