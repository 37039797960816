import {
	androidAppStoreUrl,
	getCapacitorVersion,
	iOSAppStoreURL,
	isCapacitorPlatform,
} from "@utils/specific/capacitor";
import { observable, action, runInAction, computed } from "mobx";
import CapacitorUpdaterApiService from "@api-services/capacitor/CapacitorUpdaterApiService";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import RootStore from "@state/stores/RootStore";
import { StorageStateKeysEnum } from "@utils/storage";
import CapacitorUpdatePopupStore from "./CapacitorUpdatePopupStore";
import { localizationService } from "@state";
import { Capacitor } from "@capacitor/core";

class CapacitorUpdaterStore {
	@observable isLoading = false;
	@observable declinedVersion: string | null = null;

	versionToUpdateTo: string | null = null;

	@computed get isPopupVisible(): boolean {
		return this.capacitorUpdatePopupStore.isPopupVisible;
	}

	@computed get shouldShowUpdateAvailable(): boolean {
		return this.declinedVersion != null;
	}

	rootStore: RootStore;
	capacitorUpdatePopupStore: CapacitorUpdatePopupStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.capacitorUpdatePopupStore = new CapacitorUpdatePopupStore(this);
	}

	async onInitialize() {
		if (!isCapacitorPlatform()) {
			return;
		}
		this.checkVersionDiff();
	}

	@action.bound
	async checkVersionDiff(userForcesUpdate: boolean = false) {
		this.getDeclinedVersion();
		let newAvailableVersion;
		try {
			const response =
				await CapacitorUpdaterApiService.getCurrentMobileVersion();
			newAvailableVersion = response.version;
		} catch (err) {
			console.error(
				"Cap update: something went wrong with fetching newest version",
				err
			);
		}

		if (!newAvailableVersion) {
			console.error(
				"Cap update: mobile version does not exist in fetched version.json"
			);
			return;
		}

		if (
			!userForcesUpdate &&
			this.declinedVersion &&
			this.declinedVersion == newAvailableVersion
		) {
			console.warn(
				"Cap update: User already declined update: ",
				newAvailableVersion
			);
			return;
		}

		const checkResult = await this.checkIfUpdateExits(newAvailableVersion);

		if (checkResult.shouldShowUpdate == false) {
			console.log("Cap update: Update not needed");
			return;
		}

		this.removeDeclinedVersion();

		runInAction(() => {
			this.capacitorUpdatePopupStore.isPopupVisible =
				checkResult.shouldShowUpdate;
			this.capacitorUpdatePopupStore.shouldForceUpdate =
				checkResult.shouldForceUpdate;
			this.capacitorUpdatePopupStore.shouldForceNativeUpdate =
				checkResult.shouldForceNativeUpdate;
			this.versionToUpdateTo = checkResult.versionToUpdateTo;
		});
	}

	async checkIfUpdateExits(
		newMobileVersion: string
	): Promise<CheckVersionResult> {
		const currentVersion = await getCapacitorVersion();

		let result: CheckVersionResult = {
			shouldShowUpdate: false,
			shouldForceUpdate: false,
			versionToUpdateTo: null,
			shouldForceNativeUpdate: false,
		};

		if (currentVersion != newMobileVersion && currentVersion) {
			const currentVersionSplit = currentVersion.split(".");
			const newVersionSplit = newMobileVersion.split(".");

			if (currentVersionSplit.length < 3 || newVersionSplit.length < 3) {
				console.error("Cap update: Versions not properly formatted");
				return result;
			}

			const shouldCheckNativeUpdateForIOS =
				Capacitor.getPlatform() === "ios" &&
				WEBPACK_CAPACITOR_USE_APPLE_STORE;
			const shouldCheckNativeUpdateForAndroid =
				Capacitor.getPlatform() === "android" &&
				WEBPACK_CAPACITOR_USE_ANDROID_STORE;

			if (
				currentVersionSplit[0] < newVersionSplit[0] &&
				(shouldCheckNativeUpdateForIOS ||
					shouldCheckNativeUpdateForAndroid)
			) {
				//major version diff - force native version update
				result.shouldForceNativeUpdate = true;
				result.shouldShowUpdate = true;
				result.shouldForceUpdate = true;
				return result;
			}

			if (currentVersionSplit[1] < newVersionSplit[1]) {
				//patch version diff
				result.shouldShowUpdate = true;
				result.versionToUpdateTo = newMobileVersion;
				result.shouldForceUpdate = true;
				return result;
			}

			if (currentVersionSplit[2] < newVersionSplit[2]) {
				//minor version diff
				result.shouldShowUpdate = true;
				result.versionToUpdateTo = newMobileVersion;
				return result;
			}
		}

		return result;
	}

	@action.bound
	getDeclinedVersion() {
		const localStorageVersion = this.rootStore.localStorageProvider.get(
			StorageStateKeysEnum.DECLINED_CAP_UPDATE
		) as string | null;
		this.declinedVersion = localStorageVersion;
	}

	@action.bound
	setDeclinedVersion() {
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.DECLINED_CAP_UPDATE,
			this.versionToUpdateTo
		);
		this.declinedVersion = this.versionToUpdateTo;
	}

	@action.bound
	removeDeclinedVersion() {
		this.rootStore.localStorageProvider.remove(
			StorageStateKeysEnum.DECLINED_CAP_UPDATE
		);
		this.declinedVersion = null;
	}

	@action.bound
	declineUpdate() {
		this.setDeclinedVersion();
	}

	@action.bound
	async acceptUpdate(forceNativeUpdate: boolean = false) {
		if (forceNativeUpdate) {
			//redirect user to store
			if (Capacitor.getPlatform() === "ios") {
				window.location.href = iOSAppStoreURL;
			} else if (Capacitor.getPlatform() === "android") {
				window.location.href = androidAppStoreUrl;
			}
			return;
		}

		if (!this.versionToUpdateTo) {
			console.error("Cap update: No version to update to");
			this.rootStore.notificationStore.error(
				localizationService.t("CAPACITOR_UPDATE.DOWNLOAD_ERROR")
			);
			return;
		}

		await this.startUpdate(this.versionToUpdateTo);
	}

	@action.bound
	async startUpdate(versionToUpdateTo: string) {
		this.isLoading = true;
		let newFetchedVersion;
		try {
			newFetchedVersion = await CapacitorUpdater.download({
				version: versionToUpdateTo,
				url: `${CapacitorUpdaterApiService.s3BucketUpdatePath}/app.zip`,
			});
		} catch (err) {
			console.error(
				"Cap update: Something went wrong with fetching new bundle version",
				err
			);
		}

		if (!newFetchedVersion) {
			this.rootStore.notificationStore.error(
				localizationService.t("CAPACITOR_UPDATE.DOWNLOAD_ERROR")
			);
			this.isLoading = false;
			return;
		}

		try {
			// set deletes older version if new version is successfuly set, if older version is builtin, it will not be deleted
			await CapacitorUpdater.set({ id: newFetchedVersion.id });
		} catch (error) {
			console.error(
				"Cap update: Failed setting new version bundle",
				error
			);
			this.rootStore.notificationStore.error(
				localizationService.t("CAPACITOR_UPDATE.DOWNLOAD_ERROR")
			);
		}

		this.isLoading = false;
	}

	@action.bound
	onDispose() {
		this.capacitorUpdatePopupStore.onDispose();
		this.versionToUpdateTo = null;
		this.declinedVersion = null;
	}
}

type CheckVersionResult = {
	shouldShowUpdate: boolean;
	shouldForceUpdate: boolean;
	versionToUpdateTo: string | null;
	shouldForceNativeUpdate: boolean;
};

export default CapacitorUpdaterStore;
