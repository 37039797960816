import { SUPPORTED_LANGUAGES } from "../../ui/common/constants/SupportedLanguages";
import LookupApiService from "@api-services/LookupApiService";
import { getCurrentCulture, UserTypes } from "@utils";
import { BasicLookupModel } from "@api-types";
/**
 * TODO: create "cached" lookup, try to get first from there
 * this gets lookup values with localization
 */
class LookupService {
	static async findBetStatuses(activeWallet = UserTypes.PRIMARY) {
		var response = await LookupApiService.findLookup(
			"bet-statuses",
			activeWallet
		);
		return this.mapLookupResponse(response);
	}

	static async findBetSlipStatuses(activeWallet = UserTypes.PRIMARY) {
		var response = await LookupApiService.findLookup(
			"bet-slip-statuses",
			activeWallet
		);
		return this.mapLookupResponse(response);
	}

	static async findBettingAccountTypes(activeWallet = UserTypes.PRIMARY) {
		var response = await LookupApiService.findLookup(
			"betting-account-types",
			activeWallet
		);
		return this.mapLookupResponse(response);
	}

	static mapLookupResponse(response: BasicLookupModel[]) {
		let result = response.map((el) => ({
			id: el.id,
			abrv: el.abrv,
			name: this.getLocalizedName(el),
		}));
		return result;
	}

	static getLocalizedName(lookupItem: BasicLookupModel) {
		const culture = getCurrentCulture();
		const cultureKey =
			SUPPORTED_LANGUAGES.find((lang) => lang.key == culture)?.keyLong ||
			"en";

		return culture == "en"
			? lookupItem.name
			: // @ts-expect-error
			  lookupItem.languageMetadata[cultureKey].name;
	}
}
export default LookupService;
