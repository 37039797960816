import { useUser } from "@v2/hooks/shared";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormInputFieldCurrency } from "../../shared/inputs";

export default observer(function DepositLimitPartial() {
	const { t } = useTranslation();
	const user = useUser();

	return (
		<div className="row u-mar--bottom--sml">
			<FormInputFieldCurrency
				name="depositLimit"
				className="col col-sml-12 form__field"
				label="MEMBERSHIP.ACCOUNT_ACTIVATION.MONTHLY_DEPOSIT_LIMIT"
				placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.MONTHLY_DEPOSIT_LIMIT_PLACEHOLDER"
				validate={{
					isEqual: (value: number) =>
						// @ts-expect-error
						(value >= user.accountActivation?.minDepositLimit &&
							// @ts-expect-error
							value <= user.accountActivation?.maxDepositLimit) ||
						t("MEMBERSHIP.ACCOUNT_ACTIVATION.DEPOSIT_RANGE_ERROR", {
							0: user.accountActivation?.minDepositLimit,
							1: user.accountActivation?.maxDepositLimit,
						}),
				}}
				type="number"
				providedCurrency={user.accountActivation?.currencyDisplayCode}
				required
			/>
		</div>
	);
});
