export class CasinoRequestFilter {
	casinoGameType: string;
	maxGamesPerCategory: number | null;
	casinoGameGroupAbrvs: string;
	casinoGameTypeAbrvs: string;
	liveGameTableLobbyIds?: string;
	isNew?: boolean | null;
	searchQuery?: string;
	rpp: number;
	gameCategoryIds?: string;
	page?: number;

	constructor(
		gameType: string,
		maxGamesPerCategory: number | null,
		gameGroupAbrvs: string,
		isNew?: boolean | null,
		rpp: number = 100,
		searchQuery?: string | null,
		gameCategoryIds?: string,
		page?: number
	) {
		this.casinoGameType = gameType;
		this.maxGamesPerCategory = maxGamesPerCategory;
		this.casinoGameGroupAbrvs = gameGroupAbrvs;
		this.isNew = isNew;
		this.rpp = rpp;
		if (searchQuery != null) {
			this.searchQuery = searchQuery;
		}
		if (gameCategoryIds != null) {
			this.gameCategoryIds = gameCategoryIds;
		}
		if (page != null) {
			this.page = page;
		}
	}
}
