import React from "react";
import { observer } from "mobx-react";
import { DateTime } from "luxon";
import { Tooltip } from "react-tooltip";

import { Event } from "@gp/models";

export const LiveMatchTime = observer(function LiveMatchTime(props: {
	event: Event;
	className: string;
	isSpotlight?: boolean;
}) {
	const {
		event: { matchTime },
		isSpotlight,
	} = props;

	const tooltipId = `match-time-${props.event.id}`;

	if (props.event.matchTime != undefined) {
		let timeToDisplay = null;

		if (matchTime?.displayTime != null) {
			timeToDisplay = matchTime.displayTime;
		} else if (matchTime != null) {
			timeToDisplay = matchTime.regularTime;
		}

		if (isSpotlight) {
			return <div className={props.className}>{timeToDisplay}</div>;
		}

		return (
			<div className={props.className}>
				<div
					className="offer__time__inner"
					data-tooltip-id={tooltipId}
					data-tooltip-position-strategy="fixed"
				>
					{timeToDisplay}
				</div>
				<LiveMatchTimeTooltip
					event={props.event}
					tooltipId={tooltipId}
				/>
			</div>
		);
	}

	if (isSpotlight) {
		return (
			<div className={props.className}>
				{DateTime.fromISO(props.event.startTime).toFormat("HH:mm")}
			</div>
		);
	}

	return (
		<div className={props.className}>
			<div
				className="offer__time__inner"
				data-tooltip-id={tooltipId}
				data-tooltip-position-strategy="fixed"
			>
				{DateTime.fromISO(props.event.startTime).toFormat("HH:mm")}
			</div>
			<LiveMatchTimeTooltip event={props.event} tooltipId={tooltipId} />
		</div>
	);
});

const LiveMatchTimeTooltip = observer(function LiveMatchTimeTooltip(props: {
	event: Event;
	tooltipId: string;
}) {
	const { event, tooltipId } = props;

	if (event.result?.scorePerPeriod == null) {
		return null;
	}

	return (
		<Tooltip
			id={tooltipId}
			className="at-scorePerPeriod react-tooltip--primary react-tooltip--periods"
			place="left-start"
			closeOnResize={true}
		>
			{event.result?.scorePerPeriod.map((score, idx) => {
				return (
					<div key={idx} className="react-tooltip__col">
						<div className="react-tooltip__header">
							{`P${idx + 1}`}
						</div>
						<div className="react-tooltip__body">
							{`${score.home ? score.home : 0}:${
								score.away ? score.away : 0
							}`}
						</div>
					</div>
				);
			})}
		</Tooltip>
	);
});
