import React, { useEffect, Suspense } from "react";
import { Switch } from "react-router-dom";

import { useRootAppStore, useRootOfferStore } from "@hooks";

import { NoMatchRoute, RouteElement } from "@v2/components/shared/navigation";
import { Error404, ErrorPage } from "@v2/components/desktop/shared/error";
import { ErrorHandler, LayoutAppWrapper } from "@v2/components/shared";

import { NotificationContainer, AppContextContainer } from "@v2/containers";

import GlobalLoader from "@v2/components/desktop/shared/loader/GlobalLoader";

import {
	isCasinoEnabled,
	isLiveCasinoEnabled,
	isRegistrationEnabled,
} from "@v2/helpers/features";
import { LoadingContainer } from "@v2/hoc";
import { PopupDisplay } from "@v2/components/desktop/shared/popups";
import { ResultsPage } from "@v2/components/desktop/results";
import { AccountStatementPage, OfferPrintPage } from "@v2/components/desktop";
import {
	EmailActivationPage,
	LoginPage,
} from "@v2/components/desktop/membership";
import { RestrictedPage } from "@v2/components/desktop/restricted";
import AccountSettingsLayout from "./AccountSettingsLayout";
import InfoPagesLayout from "./InfoPagesLayout";
import SportsBettingLayout from "@v2/components/desktop/offer/layout/SportsBettingLayout";
import { GamingHistoryPage } from "@v2/components/desktop/gaming-history";
import { RegistrationPage } from "../membership/registration";
import { PaymentInfoPage } from "@v2/components/desktop/payment-info";
import { DepositPage } from "@v2/components/desktop/deposit";
import { WithdrawalPage } from "@v2/components/desktop/withdrawal";
import { RegistrationActivationPage } from "@v2/components/desktop/membership/registration-activation";
import AppUpdatedPage from "@v2/components/desktop/shared/app-updated-page/AppUpdatedPage";

import { LiveWidgetProvider } from "@state/context/live-widgets";
import { logger } from "@state/providers/consoleLogger";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
//#region lazy imports

import { getCurrentCulture } from "@utils";

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
import useBrowserClose from "@v2/hooks/shared/useBrowserClose";
import { DepositInfoPage } from "@v2/components/desktop/deposit-info";
import { ChatWrap } from "@features/chat/desktop";
const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const MyBetSlipSharedPage = lazy(
	loadFailPath,
	() => import("@v2/components/desktop/my-bets/MyBetSlipSharedPage")
);
// prettier-ignore
const MembershipLayout = lazy(loadFailPath, () => import("./MembershipLayout"));
// prettier-ignore
const CasinoLayout = lazy(loadFailPath, () => import("./CasinoLayout"));
// prettier-ignore
const AccountRecheckPage = lazy(loadFailPath, () => import("@v2/components/desktop/account-recheck/AccountRecheckPage"));
// prettier-ignore
const LimitIncreasePage = lazy(loadFailPath, () => import("@v2/components/desktop/limit-increase/LimitIncreasePage"));
// prettier-ignore
const MyBetsPageLayout = lazy(loadFailPath, () => import("./MyBetsPageLayout"));
// prettier-ignore
const QuickPlayLayout = lazy(loadFailPath, () => import("@features/quick-play/desktop/QuickPlayLayout"));

//#endregion lazy imports

// @observer
export default function MainLayoutWrapper() {
	const rootOfferStore = useRootOfferStore();
	const rootStateStore = useRootAppStore();

	useEffect(() => {
		rootOfferStore.resizeStore.onInitialize();
		return () => {
			rootOfferStore.resizeStore.onDispose();
		};
	}, []);

	const menuStore = React.useMemo(
		() => new PrematchOfferMenuStore(rootOfferStore),
		[]
	);

	useEffect(() => {
		rootStateStore.quickPlayStore.quickBetStore.initializeMenuStore(
			menuStore
		);

		return () => {
			menuStore.onDispose();
			rootStateStore.quickPlayStore.quickBetStore.removeMenuStore();
		};
	}, []);

	return (
		<Suspense fallback={<GlobalLoader />}>
			<LiveWidgetProvider
				logger={logger}
				shouldSuspendWidgets={App.state.rootStore.isSomePopupActive}
			>
				<AppContextContainer>
					<NotificationContainer>
						<ErrorPage full>
							<ChatWrap>
								<MainLayout menuStore={menuStore} />
							</ChatWrap>
						</ErrorPage>
					</NotificationContainer>
				</AppContextContainer>
			</LiveWidgetProvider>
		</Suspense>
	);
}

function MainLayout(props: { menuStore: PrematchOfferMenuStore }) {
	useBrowserClose();

	return (
		<LayoutAppWrapper>
			<LoadingContainer>
				<ErrorHandler />
				<PopupDisplay />
				<Switch>
					<RouteElement exact path="/:culture/app-update">
						<AppUpdatedPage
							full
							reload={() => {
								window.location.reload();
							}}
						/>
					</RouteElement>
					<RouteElement exact path={["/:culture/results"]}>
						<ResultsPage />
					</RouteElement>

					<RouteElement
						path={[
							"/:culture/home/",
							"/:culture/sports/",
							"/:culture/live/",
							"/:culture/search/",
						]}
					>
						<SportsBettingLayout menuStore={props.menuStore} />
					</RouteElement>

					<RouteElement path={["/:culture/info"]}>
						<InfoPagesLayout />
					</RouteElement>

					<RouteElement
						privateRoute
						path={["/:culture/account-settings/"]}
					>
						<AccountSettingsLayout
							prematchOfferMenuStore={props.menuStore}
						/>
					</RouteElement>

					<RouteElement exact path="/:culture/auth/login">
						<LoginPage />
					</RouteElement>

					{isRegistrationEnabled && (
						<RouteElement exact path="/:culture/auth/register">
							<RegistrationPage />
						</RouteElement>
					)}

					{isRegistrationEnabled && (
						<RouteElement
							exact
							path="/:culture/auth/register-activation"
						>
							<RegistrationActivationPage />
						</RouteElement>
					)}

					<RouteElement path={["/:culture/auth"]}>
						<MembershipLayout />
					</RouteElement>

					{isCasinoEnabled ? (
						<RouteElement path="/:culture/games">
							<CasinoLayout />
						</RouteElement>
					) : null}

					<RouteElement
						path="/:culture/account-statement"
						privateRoute
					>
						<AccountStatementPage />
					</RouteElement>

					<RouteElement path="/:culture/my-bets" privateRoute>
						<MyBetsPageLayout />
					</RouteElement>

					<RouteElement path="/:culture/bet-slip-shared/:betSlipNumber">
						<MyBetSlipSharedPage />
					</RouteElement>

					{isCasinoEnabled || isLiveCasinoEnabled ? (
						<RouteElement
							path="/:culture/gaming-history"
							privateRoute
						>
							<GamingHistoryPage />
						</RouteElement>
					) : null}

					<RouteElement path="/:culture/payment-info/:status">
						<PaymentInfoPage />
					</RouteElement>

					<RouteElement path="/:culture/deposit" privateRoute>
						<DepositPage />
					</RouteElement>

					<RouteElement path="/:culture/deposit-info" privateRoute>
						<DepositInfoPage />
					</RouteElement>

					<RouteElement path="/:culture/withdrawal" privateRoute>
						<WithdrawalPage />
					</RouteElement>

					<RouteElement path="/:culture/offer">
						<OfferPrintPage />
					</RouteElement>

					<RouteElement path="/:culture/email-activation">
						<EmailActivationPage />
					</RouteElement>

					<RouteElement
						path="/:culture/user-profile-recheck"
						privateRoute
					>
						<AccountRecheckPage />
					</RouteElement>

					<RouteElement path="/:culture/limit-increase">
						<LimitIncreasePage />
					</RouteElement>

					<RouteElement path="/:culture/error/404">
						<Error404 />
					</RouteElement>

					<RouteElement path="/:culture/restrict">
						<RestrictedPage />
					</RouteElement>

					<RouteElement path="/:culture/quick-play">
						<QuickPlayLayout />
					</RouteElement>

					<NoMatchRoute full />
				</Switch>
			</LoadingContainer>
		</LayoutAppWrapper>
	);
}
