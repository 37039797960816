import React, { useState } from "react";
import { useBRContainerCycles } from "../hooks";
import { WidgetLocation } from "../models";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";
import { useTranslation } from "react-i18next";

export const BRWidgetContainer = (props: {
	eventId: string;
	location: WidgetLocation;
	divProps?: React.HTMLProps<HTMLDivElement>;
}) => {
	const [ref, setRef] = useState<HTMLDivElement | null>(null);
	const isMounted = useBRContainerCycles(ref, props.eventId, props.location);
	const t = useTranslation().t;

	if (!isMounted) {
		return (
			<div className={props.location == "SIDE" ? "u-padd--xsml" : ""}>
				<div className="card--empty card--empty--borderless u-mar--bottom--reset">
					<i className="event__actions__icon u-icon u-icon--huge u-icon--field--disabled" />
					{t<string>("LIVE_WIDGET.FALLBACK_MESSAGE")}
				</div>
			</div>
		);
	}

	return <div ref={(newRef) => setRef(newRef)} {...props.divProps} />;
};
