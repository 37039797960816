import React, { useMemo, useEffect } from "react";

import { IConsoleLogger } from "@interface";
import { LiveWidgetStoreContext } from "./";
import { LiveWidgetStore } from "@state/stores/live-widgets";

export function LiveWidgetProvider(props: {
	children: JSX.Element | JSX.Element[];
	logger: IConsoleLogger;
	/** If true it will disable  */
	shouldSuspendWidgets: boolean;
}) {
	const store = useMemo(() => new LiveWidgetStore(props.logger), []);

	useEffect(() => {
		store.onInitialize();
		return () => store.onDispose();
	}, []);

	store.setWidgetSuspend(props.shouldSuspendWidgets);

	return (
		<LiveWidgetStoreContext.Provider value={store}>
			{props.children}
		</LiveWidgetStoreContext.Provider>
	);
}
