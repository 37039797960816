import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useFixBody } from "@v2/hooks";
import { logger } from "@state";
import { getRedirectOriginUrl } from "@utils/specific/capacitor";
import { useRootOfferStore } from "@hooks";
import { Modal } from "@lib/modal";
import { Button } from "@v2/components/mobile/shared/buttons/Button";

export default observer(function ShareBetSlipModal() {
	const store = useRootOfferStore().myBetsViewStore;
	const chatViewStore = App.state.rootStore.chatViewStore;
	const t = useTranslation().t;
	useFixBody(false, store?.betSlipShareStore.isActive, "reuse-form");

	if (store == null) {
		logger.logError("Expected store, got null.");
		return null;
	}

	const sharedBetSlip = store.betSlipShareStore.activeSharedBetSlip;

	if (!store.betSlipShareStore.isActive || !sharedBetSlip) {
		return null;
	}

	return (
		<Modal>
			<div id="reuse-form" className="popup">
				<div className="popup__card">
					<div className="popup__header">
						{t("MY_BETS.SHARE.BUTTON_LABEL")}
						<Button
							btnType="button"
							className="btn--square btn--square--sml btn--secondary--inverted"
							iconOnly={true}
							iconClassName="u-icon--sml u-icon--close"
							onClick={() => store.betSlipShareStore.hidePopup()}
						/>
					</div>
					<div className="popup__body">
						<button
							type="button"
							className="btn btn--wrap btn--wrap--base btn--block btn--secondary--inverted btn--icon"
							onClick={() => {
								store.betSlipShareStore.copyLink();
							}}
						>
							<i className="u-icon u-icon--med u-icon--hyperlink" />
							{t("MY_BETS.SHARE.COPY_LINK")}
						</button>

						<a
							href={`https://api.whatsapp.com/send?text=${getRedirectOriginUrl(
								`/bet-slip-shared/${sharedBetSlip.betSlipNumber}`
							)}`}
							data-action="share/whatsapp/share"
							target="_blank"
							className="btn btn--wrap btn--wrap--base btn--block btn--secondary--inverted btn--icon"
						>
							<i className="u-icon u-icon--med u-icon--whatsapp" />
							WhatsApp
						</a>

						<a
							className="btn btn--wrap btn--wrap--base btn--block btn--secondary--inverted btn--icon"
							href={`mailto:?subject=Bet%20slip&body=${getRedirectOriginUrl(
								`/bet-slip-shared/${sharedBetSlip.betSlipNumber}`
							)}`}
							title="Share by Email"
						>
							<i className="u-icon u-icon--med u-icon--email" />
							Email
						</a>
						{WEBPACK_IS_CHAT_AVAILABLE && (
							<button
								className="btn btn--wrap btn--wrap--base btn--block btn--secondary--inverted btn--icon"
								title="Share to Chat"
								onClick={() => {
									chatViewStore.openBetSlipMessagePreview(
										sharedBetSlip
									);
									store.betSlipShareStore.hidePopup();
								}}
							>
								<i className="u-icon u-icon--med u-icon--med--responsive u-icon--chat--active"></i>
								Chat
							</button>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
});
