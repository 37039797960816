import React from "react";

export default function BetCombinationItem(props: {
	combination: {
		combinationStatus: number;
		combinationOffers: { systemIdentifier: string }[];
		combinationQuota: number;
	};
	ordinalNumber: string;
}) {
	const { combination, ordinalNumber } = props;

	const getCombinationStatus = (statusNumber: number) => {
		switch (statusNumber) {
			case 1:
				return "active";
			case 2:
				return "win";
			case 3:
				return "lose";
		}
		return "";
	};

	const getSystemIdentifierString = (
		combinationOffers: { systemIdentifier: string }[]
	) => {
		return combinationOffers.map((o) => o.systemIdentifier).join("");
	};

	const getFormatedCombinationQuota = (quota: number) => {
		if (quota < 100) {
			return quota.toFixed(2);
		}
		return quota.toFixed();
	};

	return (
		<tr className={"table--primary__body__row u-color--offer--" + getCombinationStatus(combination.combinationStatus)}>
			<td className="table--primary__body__data u-type--center">
				{ordinalNumber}
			</td>
			<td className="table--primary__body__data u-type--center">
				{getSystemIdentifierString(combination.combinationOffers)}
			</td>
			<td className="table--primary__body__data u-type--center">
				{getFormatedCombinationQuota(combination.combinationQuota)}
			</td>
		</tr>
	);
}
