import React, { useState } from "react";
import { Switch } from "react-router-dom";
import { observer } from "mobx-react";
import { SearchPage } from "@v2/components/desktop/offer/pages";
import { AsideWidgets } from "@v2/components/desktop/side-widgets";
import { Footer } from "@v2/components/desktop/shared/footer";
import { ErrorPage } from "@v2/components/desktop/shared/error";
import { PrematchOfferMenu } from "@v2/components/desktop/offer/offer-menu";
import { HeaderWithSideMenu } from "@v2/components/desktop/shared/header";
import { NoMatchRoute, RouteElement } from "@v2/components/shared/navigation";
import { useRootOfferStore } from "@hooks";
import classNames from "classnames";
import { AdditionalOfferContainerRefContext } from "@context";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import { SetIsAdditionalOfferOpenCtxProviderForParentLayout } from "@context/SetIsAdditionalOfferOpenCtxProviderForParentLayout";
import { useFavoritesCounter } from "@v2/hooks/offer/useFavoritesCounter";
import { LiveStatus } from "@gp/models";

export default observer(function SearchRouteLayout(props: {
	menuStore: PrematchOfferMenuStore;
}) {
	const [additionalOfferContainerRef, setAdditionalOfferContainerRef] =
		useState<HTMLDivElement | null>(null);
	const rootStore = useRootOfferStore();
	const { isSideMenuExpanded: isMenuExpanded } = rootStore;

	const [isAdditionalOfferVisible, setIsAdditionalOfferOpen] =
		React.useState(false);

	useFavoritesCounter(LiveStatus.PREMATCH);

	//#region css classes

	const mainClassNames = classNames("l--main l--main--offer l--search", {
		"sidebar-left-expanded": isMenuExpanded,
		"sidebar-left-collapsed": !isMenuExpanded,
	});

	const asideClasses = classNames("l--aside--left", {
		"is-expanded": isMenuExpanded,
	});

	const contentClassNames = classNames("l--content", {
		"additional-is-visible": isAdditionalOfferVisible,
		"additional-is-hidden": !isAdditionalOfferVisible,

		"sidebar-left-expanded": isMenuExpanded,
		"sidebar-left-collapsed": !isMenuExpanded,
	});

	const additionalOfferClassNames = classNames("l--content__additional", {
		"is-visible": isAdditionalOfferVisible,
		"is-hidden": !isAdditionalOfferVisible,

		"sidebar-left-expanded": isMenuExpanded,
		"sidebar-left-collapsed": !isMenuExpanded,
	});

	const footerClassNames = classNames("l--footer", {
		"sidebar-left-expanded": isMenuExpanded,
		"sidebar-left-collapsed": !isMenuExpanded,
	});

	//#endregion css classes

	return (
		<SetIsAdditionalOfferOpenCtxProviderForParentLayout.Provider
			value={{
				setIsAdditionalOpen: setIsAdditionalOfferOpen,
				isAdditionalOpen: isAdditionalOfferVisible,
			}}
		>
			<HeaderWithSideMenu />
			<main role="main" className={mainClassNames}>
				<aside className={asideClasses}>
					<PrematchOfferMenu
						prematchOfferMenuStore={props.menuStore}
					/>
				</aside>

				<section className={contentClassNames}>
					<ErrorPage>
						<Switch>
							<RouteElement
								exact
								path="/:culture/search/:period/:searchTerm"
							>
								<AdditionalOfferContainerRefContext.Provider
									value={additionalOfferContainerRef}
								>
									<SearchPage />
									<div
										id="additional-offer-container"
										ref={(newRef: HTMLDivElement | null) =>
											setAdditionalOfferContainerRef(
												newRef
											)
										}
										className={additionalOfferClassNames}
									/>
								</AdditionalOfferContainerRefContext.Provider>
							</RouteElement>
							<NoMatchRoute />
						</Switch>
					</ErrorPage>
				</section>

				<AsideWidgets />
			</main>

			<footer className={footerClassNames}>
				<Footer />
			</footer>
		</SetIsAdditionalOfferOpenCtxProviderForParentLayout.Provider>
	);
});
