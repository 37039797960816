import React, { useEffect, useState } from "react";
import classNames from "classnames";

export default function Clock (props: {
    hasOnboardingStyling?: boolean;
}) {
	const [currentTime, setCurrentTime] = useState(new Date());
	const timezoneOffset = currentTime.getTimezoneOffset();
	const hh = currentTime.getHours();
	const mm = currentTime.getMinutes();
	const timeStr = `${hh < 10 ? "0" : ""}${hh}:${mm < 10 ? "0" : ""}${mm}`;

	const offset = timezoneOffset / 60;
	const calculatedOffset = () => {
		if (offset < 0) {
			return `(UTC+${Math.abs(offset)})`;
		} else if (offset == 0) {
			return `(UTC+0)`;
		} else {
			return `(UTC${-Math.abs(offset)})`;
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(new Date());
		}, 20 * 1000); // Interval set to 20 seconds
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const offsetConvert = calculatedOffset();

	return (
		<time className={classNames(
            "header__time",
            {
                "u-type--xxsml u-color--text--secondary": props.hasOnboardingStyling
            }
        )}>
            <strong className={classNames(
                "header__time__clock",
                {
                    "u-type--wgt--regular": props.hasOnboardingStyling
                }
            )}>
                {timeStr}
            </strong>
			{offsetConvert}
		</time>
	);
};
