import { getWebApiUrl } from "@utils";
import { httpClient } from "@utils/http/httpClient";

const requestHeaders = {
	"Content-Type": "application/json",
};

export default class RestrictionService {
	private static get baseUrl(): string {
		return `${getWebApiUrl()}/api/redirect-user`;
	}

	static async getRestrictionConfigurationState(): Promise<boolean> {
		try {
			return await httpClient.get(
				`${this.baseUrl}/restriction-configuration-state`,
				requestHeaders,
				{
					responseType: "json",
				}
			);
		} catch (e) {
			console.log(e);
			return false;
		}
	}
}
