import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { SportOffer, EventOffer as EVentOfferType } from "@gp/offer";
import {
	EventContext,
	OfferOptionsContext,
	OfferColumnConfigurationResult,
	SportOfferContext,
	LookupContext,
	SportContext,
} from "@gp/components";
import { EventDetails, EventOffer } from ".";
import { useIsAdditionalOfferVisible } from "@offer/common";
import useGetOffer from "./useGetOffer";
import useGetIsEventFeatured from "./useGetIsEventFeatured";
import EmptyOfferColumns from "./EmptyOfferColumns";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { AdditionalOfferInteractionTypeEnum } from "@data-types/analytics/AdditionalOfferInteractionTypeEnum";

export const Event = observer(function Event(props: {
	isAlternate?: boolean;
	displaySport?: boolean;
	showSecondaryOffer?: boolean;
	hasSecondaryOffer?: boolean;
	noSecondaryOffer?: boolean;
	secondaryOfferCount?: number;
	offerCategory?: string;
}) {
	//#region offer

	const { t } = useTranslation();
	const sport = useContext(SportOfferContext) as SportOffer;
	const event = useContext(EventContext) as EVentOfferType;

	const { mainColumns, secondaryColumns, additionalOfferCount } = useGetOffer(
		props,
		sport,
		event
	);

	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();
	const isEventFeatured = useGetIsEventFeatured();

	//#endregion offer

	return (
		<>
			<MainOffer
				secondaryColumns={secondaryColumns}
				mainColumns={mainColumns}
				additionalOfferCount={additionalOfferCount}
				isAlternate={props.isAlternate}
				displaySport={props.displaySport}
				offerCategory={props.offerCategory}
			/>

			{/* SECONDARY OFFER */}
			{props.showSecondaryOffer && !secondaryColumns.isEmpty ? (
				<div
					className={classNames(
						"offer__body__row offer__ht offer--sports__ht",
						{
							"is-selected": isEventFeatured,
							odd: !props.isAlternate,
						}
					)}
				>
					<div
						className={classNames(
							"offer__body__data offer__ht__row offer--sports__ht__row",
							{
								"is-collapsed": isAdditionalOfferVisible,
								"is-selected": isEventFeatured,
							}
						)}
					>
						<div className="offer__ht__inner offer--sports__ht__inner">
							<span className="offer__ht__title">
								{t("LIVE.FIRST_HALF_TIME")}
							</span>
						</div>

						<EventOffer columns={secondaryColumns} />
					</div>
				</div>
			) : null}
		</>
	);
});

const MainOffer = observer(function MainOffer(props: {
	secondaryColumns: OfferColumnConfigurationResult;
	mainColumns: OfferColumnConfigurationResult;
	additionalOfferCount: number;
	isAlternate?: boolean;
	displaySport?: boolean;
	offerCategory?: string;
}) {
	const event = useContext(EventContext);
	const isUpcoming = event.isUpcoming;

	const isEventFeatured = useGetIsEventFeatured();

	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();

	const primaryOfferDivClasses = classNames("offer__body__row", {
		"offer--sports__main__row offer__main__row":
			!props.secondaryColumns.isEmpty,
		"offer__main__row--sml": !props.secondaryColumns.isEmpty && isUpcoming,
		"is-collapsed": isAdditionalOfferVisible,
		"is-selected": isEventFeatured,
		odd: props.isAlternate,
	});

	return (
		<div className={primaryOfferDivClasses}>
			<EventDetails
				offerCategory={props.offerCategory}
				hasSecondaryOffer={!props.secondaryColumns.isEmpty}
				displaySport={props.displaySport}
				isEventFeatured={isEventFeatured}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
			/>
			<EventOfferWrapper mainColumns={props.mainColumns} />

			<AdditionalOfferButton
				additionalOfferCount={props.additionalOfferCount}
				isSecondaryColumnsEmpty={props.secondaryColumns.isEmpty}
				isEventFeatured={isEventFeatured}
				displaySport={props.displaySport}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
			/>
		</div>
	);
});

const EventOfferWrapper = observer(function EventOfferWrapper(props: {
	mainColumns: OfferColumnConfigurationResult | null;
}) {
	const { mainColumns } = props;

	if (mainColumns == null || mainColumns.isEmpty) {
		return <EmptyOfferColumns />;
	}

	return <EventOffer columns={mainColumns} />;
});

//#region additional offer Button

const AdditionalOfferButton = observer(function AdditionalOfferButton(props: {
	additionalOfferCount: number;
	isSecondaryColumnsEmpty?: boolean;
	isEventFeatured?: boolean;
	displaySport?: boolean;
	isAdditionalOfferVisible?: boolean;
}) {
	if (props.additionalOfferCount <= 0) {
		return (
			<div
				className={classNames(
					"offer__body__data offer__more",
					{
						// "offer__more--sml": props.displaySport || props.isAdditionalOfferVisible,
						"offer__more--sml": props.isAdditionalOfferVisible,
						"offer__more--stretch": !props.isSecondaryColumnsEmpty,
						"is-selected": props.isEventFeatured,
					},
					"is-empty"
				)}
			>
				&nbsp;
			</div>
		);
	}

	const wrapperDivClasses = classNames("offer__body__data offer__more", {
		// "offer__more--sml":props.displaySport || props.isAdditionalOfferVisible,
		"offer__more--sml": props.isAdditionalOfferVisible,
		"offer__more--stretch": !props.isSecondaryColumnsEmpty,
		"is-selected": props.isEventFeatured,
	});

	return (
		<div className={wrapperDivClasses}>
			<AdditionalOfferButtonCore
				additionalOfferCount={props.additionalOfferCount}
				isSecondaryColumnsEmpty={props.isSecondaryColumnsEmpty}
				isEventFeatured={props.isEventFeatured}
			/>
		</div>
	);
});

const AdditionalOfferButtonCore = observer(
	function AdditionalOfferButtonCore(props: {
		additionalOfferCount: number;
		isSecondaryColumnsEmpty?: boolean;
		isEventFeatured?: boolean;
	}) {
		const event = useContext(EventContext);

		const sport = useContext(SportOfferContext);
		const lookupContext = useContext(LookupContext);
		const isAdditionalOfferVisible = useIsAdditionalOfferVisible();

		const homeTeam = lookupContext.teams.get(event.teamOneId || "");
		const awayTeam = lookupContext.teams.get(event.teamTwoId || "");

		const {
			enableInlineAdditionalOffer,
			//@ts-expect-error
			additionalOfferToggle,
		} = useContext(OfferOptionsContext);

		const [isHover, setIsHover] = useState(false);

		const buttonClasses = classNames(
			"offer__more__btn btn btn--tny btn--ghost btn--icon",
			{
				"offer__more--stretch__btn": !props.isSecondaryColumnsEmpty,
				"is-active":
					enableInlineAdditionalOffer && isAdditionalOfferVisible,
				"is-selected": props.isEventFeatured,
			}
		);

		return (
			<button
				type="button"
				className={buttonClasses}
				onClick={() => {
					AnalyticsService.logAdditionalOfferInteraction({
						category:
							window.location.pathname.includes("live") ||
							event.isLive == true
								? AdditionalOfferInteractionTypeEnum.LiveAdditionalOffer
								: AdditionalOfferInteractionTypeEnum.PrematchAdditionalOffer,
						label: `${sport.abrv}-${homeTeam?.name}-${awayTeam?.name}`,
					});
					additionalOfferToggle(event);
				}}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				<span className="offer__more__count">
					+{props.additionalOfferCount}
				</span>
				<i
					className={classNames(
						"offer__more__icon u-icon u-icon--tny u-icon--caret--right",
						{
							// "u-icon--caret--right": (!enableInlineAdditionalOffer && !isAdditionalOfferVisible) && !isEventFeatured,
							"u-color--core--snow":
								enableInlineAdditionalOffer &&
								isAdditionalOfferVisible,
							"u-color--brand--highlight":
								props.isEventFeatured || isHover,
							"is-hovered": isHover,
						}
					)}
				/>
			</button>
		);
	}
);
