import React, { useState } from "react";
import { observer } from "mobx-react";
import { useAccountActivation } from "@v2/hooks/membership";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AccountActivationFormData, ActivationResponse } from "@data-types";
import {
	EmptyFieldsInfoPopup,
	AccountActivationError,
	AccountActivationPasswordSection,
	AccountActivationEmailSection,
	DepositLimitPartial,
	TermsAndNewsletter,
} from ".";
import { Button, FormButton } from "@components/buttons";

export default observer(function AccountActivationForm() {
	const {
		onCancel,
		onSuccess,
		accountActivationData,
		activationError,
		emptyFields,
		closeEmptyFieldsPopup,
		createAccountActivation,
		clearAccountActivationError,
	} = useAccountActivation();

	if (activationError) {
		return (
			<AccountActivationError
				activationError={activationError}
				clearAccountActivationError={clearAccountActivationError}
			/>
		);
	}

	return (
		<>
			<Form
				onHandleFormSubmit={onSuccess}
				aaData={accountActivationData}
				onCancel={onCancel}
			/>
			<EmptyFieldsInfoPopup
				emptyFields={emptyFields}
				closeEmptyFieldsPopup={closeEmptyFieldsPopup}
				createAccountActivation={createAccountActivation}
			/>
		</>
	);
});

function Form(props: {
	onHandleFormSubmit: (formData: AccountActivationFormData) => void;
	aaData?: ActivationResponse | null;
	onCancel: () => void;
}) {
	const { handleSubmit } = useFormContext();
	const accountActivation = useAccountActivation();

	const [isCancelButtonDisabled, setIsCancelButtonDisabled] =
		useState<boolean>(false);

	const t = useTranslation().t;

	return (
		<>
			<form
				className="at-accActivationForm card--primary"
				autoComplete="off"
				onSubmit={handleSubmit(props.onHandleFormSubmit)}
			>
				<div className="card--primary__header">
					<div className="card--primary__title title--primary">
						{t<string>(
							"MEMBERSHIP.ACCOUNT_ACTIVATION.HEADER_TITLE"
						)}
					</div>
				</div>

				<div className="card--primary__body">
					{props.aaData?.userInfo?.isNewPasswordRequired && (
						<AccountActivationPasswordSection
							accountActivationData={props.aaData}
						/>
					)}
					{!props.aaData?.userInfo?.isOfflineUserMailApproved && (
						<AccountActivationEmailSection
							accountActivationData={props.aaData || null}
						/>
					)}
					{WEBPACK_SHOULD_USER_SET_MONTHLY_DEPOSIT_LIMIT && (
						<DepositLimitPartial />
					)}
					<TermsAndNewsletter />
				</div>

				<div className="card--primary__footer">
					<div className="card--primary__footer__inner u-type--left">
						<FormButton
							btnText="MEMBERSHIP.ACCOUNT_ACTIVATION.SAVE_BUTTON"
							className="card--primary__footer__btn btn btn--lrg btn--primary"
						/>
						<Button
							className="card--primary__footer__btn btn btn--lrg btn--secondary--inverted"
							btnText="MEMBERSHIP.BETTING_ACTIVITY.BUTTON_CANCEL"
							onClick={() => {
								props.onCancel();
								setIsCancelButtonDisabled(true);
							}}
							type="button"
							isDisabled={isCancelButtonDisabled}
						/>
					</div>
				</div>
			</form>
		</>
	);
}
