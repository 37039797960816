import React from "react";
import { useFormContext } from "react-hook-form";
import { NoLockSection } from ".";
import { PasswordConfirmation } from "../../shared/password-confirmation";
import { mapDataForPasswordConfirmation } from "@v2/helpers/account-settings/account-lock/utils";
import usePasswordConfirmation from "@v2/hooks/shared/usePasswordConfirmation";
import { AccountClosureViewModel } from "@data-types";
import {
	UserAccountLockDurationTypesModel,
	UserAccountLockReasonTypesModel,
} from "@api-types";

class PasswordConfirmationWrapperPropsTypes {
	fetchedData: AccountClosureViewModel;
	lookups: {
		reasonTypes: UserAccountLockReasonTypesModel[];
		durationTypes: UserAccountLockDurationTypesModel[];
	};
	onSubmitData: () => void;
	onSuccess: (password: string) => Promise<void>;
	onCancel: () => void;
}

export default function PasswordConfirmationWrapper(
	props: PasswordConfirmationWrapperPropsTypes
) {
	const { onSuccess, onCancel, lookups, fetchedData, onSubmitData } = props;
	const { showPasswordConfirmation } = usePasswordConfirmation();
	const {
		getValues,
		formState: { dirtyFields },
	} = useFormContext();
	if (fetchedData == null) {
		return <></>;
	}

	if (showPasswordConfirmation) {
		return (
			<PasswordConfirmation
				data={mapDataForPasswordConfirmation(
					getValues(),
					dirtyFields,
					lookups.reasonTypes,
					lookups.durationTypes
				)}
				onSuccess={onSuccess}
				onCancel={onCancel}
				optionalMessage="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.NOT_ABLE_PLACE_BET"
			/>
		);
	}

	return (
		<NoLockSection
			lookups={lookups}
			fetchedData={fetchedData}
			onSubmitData={onSubmitData}
		/>
	);
}
