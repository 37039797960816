import { useRootAppStore } from "@hooks";
import { useFixBody } from "@v2/hooks";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import DepositLimitInfo from "./welcomePopups/DepositLimitInfo";
import AccountVerificationInfo from "./welcomePopups/AccountVerificationInfo";

import { Tooltip as ReactTooltip } from "react-tooltip";

import DocumentExpirationInfo from "./welcomePopups/DocumentExpirationInfo";
import BettingDisabledInfo from "./welcomePopups/BettingDisabledInfo";
import { DateTime } from "luxon";

export default observer(function RequiredUserActionsPopup() {
	const { t } = useTranslation();

	const rootStore = useRootAppStore();

	useFixBody(true, rootStore.requiredUserActionStore.isActive);

	if (!rootStore.userAuthStore.user?.lastRefreshTime) {
		return null;
	}

	const refreshUserTime = DateTime.fromISO(
		rootStore.userAuthStore.user?.lastRefreshTime
	)
		.plus({ hours: 1 })
		.toLocaleString(DateTime.TIME_24_SIMPLE);

	const componentsList = [
		DepositLimitInfo(),
		BettingDisabledInfo(),
		AccountVerificationInfo(),
		DocumentExpirationInfo(),
	];

	return (
		<div className="at-welcomeModal popup popup--mega">
			<div className="popup__card popup--mega__card">
				<div className="popup__header popup__header--top u-padd--lrg">
					<div className="popup__title popup__title--vertical">
						<div className="u-mar--bottom--xtny u-type--xxlrg u-type--wgt--bold">
							{t("WELCOME_POPUP.REQUIRED_ACTIONS_TITLE", {
								gp_company_placeholder: "TipWin",
							})}
							!
						</div>
						<div className="u-type--xmed u-type--wgt--regular u-color--text--secondary">
							{t("WELCOME_POPUP.REQUIRED_ACTIONS_HINT_PART_1")}{" "}
							<span className="u-type--wgt--medium u-color--text--primary">
								{t("WELCOME_POPUP.INFO_TEXT_PART_2")}
							</span>
							.
						</div>
					</div>

					<button
						type="button"
						className="popup__close btn btn--square btn--square--tny btn--secondary--inverted"
						title={t("COMMON.CLOSE")}
						onClick={() =>
							rootStore.requiredUserActionStore.hidePopup()
						}
					>
						<i className="u-icon u-icon--xsml u-icon--close" />
					</button>
				</div>
				<div className="popup__body u-padd--lrg">
					<div className="popup__section">
						<div className="popup__row row">
							{componentsList.map((component, id) => {
								return (
									<React.Fragment key={id}>
										{component}
									</React.Fragment>
								);
							})}
						</div>
					</div>

					{rootStore.userAuthStore.isRefreshButtonDisabled ? (
						<ReactTooltip
							className="react-tooltip--primary"
							id="my-tooltip"
						/>
					) : null}
				</div>

				<div className="popup__footer">
					<button
						className="btn btn--base btn--secondary--inverted"
						type="button"
						disabled={
							rootStore.userAuthStore.isRefreshButtonDisabled
						}
						onClick={() => {
							rootStore.userAuthStore.refreshLoggedInUser();
							rootStore.requiredUserActionStore.hidePopup();
						}}
					>
						{rootStore.userAuthStore.isRefreshButtonDisabled
							? `${t(
									"WELCOME_POPUP.TRY_AGAIN_BUTTON"
							  )} ${refreshUserTime}`
							: `${t("WELCOME_POPUP.REFRESH_DATA_BUTTON")}`}
					</button>
				</div>
			</div>
		</div>
	);
});
