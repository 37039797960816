import React from "react";

export default function MessageWrapper(props: {
	children: React.ReactNode;
	isLink?: boolean;
	anchor?: string;
}) {
	if (props.isLink) {
		return (
			<a href={props.anchor} target="_blank">
				{props.children}
			</a>
		);
	}
	return <>{props.children}</>;
}
