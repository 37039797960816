import { get } from "lodash";
import { useFormState } from "react-hook-form";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorMessage = (props) => {
	const { t } = useTranslation();
	const { errors } = useFormState();
	let error = get(errors, props.name);
	const { customErrorMessage = null } = props;

	if (error != null && error.root != null) {
		error = error.root;
	}

	if (error === undefined || error.message == undefined) {
		return null;
	}

	return (
		<span className="message--validation field-validation-error">
			<span>
				{customErrorMessage != null
					? t(customErrorMessage)
					: t(error.message)}
			</span>
		</span>
	);
};
export default ErrorMessage;
