import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { DepositContext } from "@context";

export const SelectedDepositAmount: FC = observer(
	function SelectedDepositAmount() {
		const { currentFormData, selectedPaymentMethod } =
			useContext(DepositContext);
		const { t } = useTranslation();

		return (
			<>
				<label htmlFor="currentAmount" className="label">
					{t<string>("DEPOSIT.DEPOSIT_MONEY")}
				</label>
				<div className="form__field--icon">
					<input
						type="number"
						id="currentAmount"
						className="form__field--icon__input input input--base"
						value={currentFormData?.amount}
						disabled
					/>
					<div className="form__field--icon__symbol">
						{selectedPaymentMethod?.currencyDisplayCode}
					</div>
				</div>
			</>
		);
	}
);
