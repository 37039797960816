import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

import { isEqual } from "lodash";
import { DateTime } from "luxon";

import { PlayerPromoSendingMode } from "@api-types/user/AgencyUserDto";
import { useLoading } from "@hooks";
import { useConfirmationHandlingPopup } from "@v2/hooks/shared/useConfirmationHandlingPopup";
import { localizationService, logger } from "@state";
import { UserData } from "@v2/state";

import { Button } from "../../shared/buttons";
import {
	DatePicker,
	FormInputField,
	FormSelectField,
} from "../../shared/inputs";
import { setTabTitle } from "@utils";
import { RootAppStoreContext } from "@context";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const PersonalDataService = lazy(loadFailPath, ()=>import("@services/account-settings/PersonalDataService"));
export const UserDataConfirmPage = observer(function UserDataConfirmPage() {
	const {
		confirmVerification,
		confirmOnlineUserVerification,
		cancelVerification,
		userData,
		countryName,
	} = useConfirmationHandlingPopup();
	const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

	const t = useTranslation().t;
	const { setIsLoading } = useLoading();
	const { userAuthStore } = useContext(RootAppStoreContext);
	const methods = useForm();
	const { handleSubmit, reset, watch, getValues } = methods;

	useEffect(() => {
		setTabTitle(t("USER.POPUPS.DATA_CONFIRMATION.DATA_CONFIRMATION_TITLE"));
		if (
			userAuthStore.user != null &&
			userAuthStore.loginStore.userDataToBeConfirmed == null
		) {
			const fetch = async () => {
				await userAuthStore.loginStore.resolveUserDataConfirmationData({
					primary: {
						accountActivation: userAuthStore.accountActivation!,
						loginHistory: userAuthStore.loginHistory!,
						token: userAuthStore.token!,
						user: userAuthStore.user!,
						userAccounts: userAuthStore.userAccounts!,
					},
					secondary: {
						accountActivation: userAuthStore.accountActivation!,
						loginHistory: userAuthStore.loginHistory!,
						token: userAuthStore.secondaryToken!,
						user: userAuthStore.secondaryUser!,
						userAccounts: userAuthStore.userAccounts!,
					},
				});
			};

			fetch();
		} else {
			if (
				userAuthStore.user === null &&
				userAuthStore.loginStore.userDataToBeConfirmed == null
			) {
				userAuthStore.loginStore.setConfirmationDataState();
			}
		}
	}, []);

	useEffect(() => {
		if (userData != null && userData.isInternetUser) {
			reset({
				...userData!,
			});
		}
	}, [countryName]);

	useEffect(() => {
		if (
			userData != null &&
			!isEqual(
				{
					...userData,
					dob: new Date(userData!.dob).getDate(),
				},
				{ ...getValues(), dob: new Date(getValues("dob")).getDate() }
			)
		) {
			setIsSaveDisabled(false);
		} else {
			setIsSaveDisabled(true);
		}
	}, [watch()]);

	if (userData == null) {
		return null;
	}

	const onSuccess = async (data: UserData) => {
		try {
			setIsLoading(true);
			if (!isSaveDisabled) {
				const response = await (
					await PersonalDataService
				).default.updatePersonalDataForOnlineUser({
					...userData,
					...data,
					dob: DateTime.fromISO(data.dob)
						.toUTC()
						.toFormat("yyyy-MM-dd"),
					receiveNewsletter: userData.receiveNewsletter
						? PlayerPromoSendingMode.enabled
						: PlayerPromoSendingMode.disabled,
				});
				if (response != null) {
					reset(response);
					await userAuthStore.loginStore.setUserDataToBeConfirmed({
						...userData,
						...response,
					});
					App.state.rootStore.notificationStore.success(
						localizationService.t(
							"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONAL_DATA_UPDATE_SUCCESS"
						)
					);
				}
			}
			confirmOnlineUserVerification();
		} catch (error) {
			logger.logError(error);
			if (error?.message) {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error?.message
					)
				);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const {
		birthPlace,
		city,
		//@ts-expect-error
		dateOfBirth,
		firstName,
		lastName,
		street,
		houseNumber,
		zipCode,
		userName,
		shopInfo,
		isInternetUser,
	} = userData;

	if (isInternetUser) {
		return (
			<main role="main" className="at-dataConfirmInternet l--main--full">
				<div className="container">
					<div className="card--primary">
						<div className="card--primary__header">
							<div className="card--primary__title title--primary">
								{t<string>("LIVE.INFO")}
							</div>
						</div>

						<FormProvider {...methods}>
							<form
								className="form--grid"
								onSubmit={handleSubmit(onSuccess)}
							>
								<div className="card--primary__body">
									<InfoMessageComponent
										type="note"
										message={t<string>(
											`USER.POPUPS.DATA_CONFIRMATION.ONLINE_USER_DATA_CONFIRMATION_REQUIRED`
										)}
										addClassName="u-mar--bottom--med"
									/>
									<FormInputField
										id="dataConfirmUsername"
										name="userName"
										label="USER.POPUPS.DATA_CONFIRMATION.USER_NAME_LABEL"
										className="form__group"
										placeholder="MEMBERSHIP.REGISTRATION.USERNAME_PLACEHOLDER"
										readOnly
									/>

									<div className="form--grid__row form__group row">
										<div className="form--grid__col col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col-ssvga-12 col-hdlow-12 col-xxlrg-12 col-huge-12 col-xxxlrg-6">
											<FormInputField
												id="dataConfirmFName"
												name="firstName"
												label="USER.POPUPS.DATA_CONFIRMATION.FIRST_NAME_LABEL"
												className="form__field"
												placeholder="MEMBERSHIP.REGISTRATION.FIRST_NAME_PLACEHOLDER"
												required
											/>
										</div>

										<div className="form--grid__col col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col-ssvga-12 col-hdlow-12 col-xxlrg-12 col-huge-12 col-xxxlrg-6">
											<FormInputField
												id="dataConfirmLName"
												name="lastName"
												label="USER.POPUPS.DATA_CONFIRMATION.LAST_NAME_LABEL"
												className="form__field"
												placeholder="MEMBERSHIP.REGISTRATION.LAST_NAME_PLACEHOLDER"
												required
											/>
										</div>
									</div>

									<FormInputField
										id="dataConfirmBirthPlace"
										name="birthPlace"
										label="USER.POPUPS.DATA_CONFIRMATION.BIRTH_PLACE_LABEL"
										className="form__group"
										placeholder="MEMBERSHIP.REGISTRATION.BIRTH_PLACE_PLACEHOLDER"
										required
									/>

									<DatePicker
										name="dob"
										label="USER.POPUPS.DATA_CONFIRMATION.DOB_LABEL"
										className="form__group"
										hasBorder={true}
										required
									/>

									<FormInputField
										id="dataConfirmStreet"
										name="street"
										label="USER.POPUPS.DATA_CONFIRMATION.STREET_LABEL"
										className="form__group"
										placeholder="MEMBERSHIP.REGISTRATION.STREET_PLACEHOLDER"
										required
									/>

									<FormInputField
										id="dataConfirmHouseNo"
										name="houseNumber"
										label="USER.POPUPS.DATA_CONFIRMATION.HOUSE_NUMBER_LABEL"
										className="form__group"
										placeholder="MEMBERSHIP.REGISTRATION.HOUSE_NUMBER_PLACEHOLDER"
										required
									/>

									<div className="form--grid__row form__group row">
										<div className="form--grid__col col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col-ssvga-12 col-hdlow-12 col-xxlrg-12 col-huge-12 col-xxxlrg-3">
											<FormInputField
												id="dataConfirmZIP"
												name="zipCode"
												label="USER.POPUPS.DATA_CONFIRMATION.ZIP_CODE_LABEL"
												className="form__field"
												placeholder="MEMBERSHIP.REGISTRATION.ZIP_PLACEHOLDER"
												required
											/>
										</div>

										<div className="form--grid__col col col-sml-12 col-med-12 col-lrg-12 col-xlrg-12 col-ssvga-12 col-hdlow-12 col-xxlrg-12 col-huge-12 col-xxxlrg-9">
											<FormInputField
												id="dataConfirmCity"
												name="city"
												label="USER.POPUPS.DATA_CONFIRMATION.CITY_LABEL"
												className="form__field"
												placeholder="MEMBERSHIP.REGISTRATION.CITY_PLACEHOLDER"
												required
											/>
										</div>
									</div>

									<FormSelectField
										className="form__field"
										name="countryId"
										label="MEMBERSHIP.REGISTRATION.COUNTRY_LABEL"
										labelClassName="label"
										placeholder="MEMBERSHIP.REGISTRATION.COUNTRY_PLACEHOLDER"
										popoverMsg={
											"MEMBERSHIP.REGISTRATION.COUNTRY_DESCRIPTION"
										}
										dropdownData={
											userAuthStore.loginStore
												.userDataConfirmationCountryLookups ||
											[]
										}
										required
									/>
								</div>

								<div className="card--primary__footer">
									<Button
										type="submit"
										atClassName="dataConfirmInternetBtnConfirm"
										className="card--primary__footer__btn btn--lrg btn--primary"
										btnText="COMMON.CONFIRM"
									/>

									<Button
										type="button"
										atClassName="dataConfirmInternetBtnClose"
										className="card--primary__footer__btn btn--lrg btn--secondary--inverted"
										btnText="COMMON.CLOSE"
										onClick={cancelVerification}
									/>
								</div>
							</form>
						</FormProvider>
					</div>
				</div>
			</main>
		);
	}

	return (
		<>
			<div className="at-dataConfirmRetail card--primary">
				<div className="card--primary__header">
					<div className="card--primary__title title--primary">
						{t<string>("LIVE.INFO")}
					</div>
				</div>

				<div className="card--primary__body">
					<InfoMessageComponent
						type="warning"
						message={t<string>(
							`USER.POPUPS.DATA_CONFIRMATION.USER_DATA_CONFIRMATION_REQUIRED`,
							{
								0: shopInfo,
							}
						)}
						addClassName="u-mar--bottom--med"
					/>

					<table className="table table--fixed">
						<tbody className="table__body">
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.USER_NAME_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{userName || "---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.FIRST_NAME_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{firstName || "---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.LAST_NAME_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{lastName || "---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.BIRTH_PLACE_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{birthPlace || "---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.DOB_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{DateTime.fromISO(
										dateOfBirth
									).toLocaleString(DateTime.DATE_SHORT) ||
										"---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.STREET_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{street || "---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.HOUSE_NUMBER_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{houseNumber || "---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.ZIP_CODE_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{zipCode || "---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.CITY_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{city || "---"}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell">
									{t<string>(
										"USER.POPUPS.DATA_CONFIRMATION.COUNTRY_LABEL"
									)}
								</td>
								<td className="table__body__cell table__cell u-color--text--primary">
									{countryName || "---"}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="card--primary__footer">
					<div className="card--primary__footer__inner u-type--left">
						<Button
							type="submit"
							atClassName="dataConfirmRetailBtnConfirm"
							className="card--primary__footer__btn btn--lrg btn--primary"
							btnText="COMMON.CONFIRM"
							onClick={confirmVerification}
						/>

						<Button
							type="button"
							atClassName="dataConfirmRetailBtnClose"
							className="card--primary__footer__btn btn--lrg btn--secondary--inverted"
							btnText="COMMON.CLOSE"
							onClick={cancelVerification}
						/>
					</div>
				</div>
			</div>
		</>
	);
});
