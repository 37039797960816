import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccountStatementBetContext } from "../../../../context/AccountStatementBetContext";
import { getDisplayedAmount } from "@utils";
import classNames from "classnames";

export default function AccountStatementPaymentTable() {
	const { t } = useTranslation();
	const contextValue = useContext(AccountStatementBetContext);
	if (contextValue === null) {
		return null;
	}
	const { bettingAndPaymentTableData } = contextValue;
	if (
		!bettingAndPaymentTableData ||
		!bettingAndPaymentTableData.userActivity
	) {
		return null;
	}

	const {
		NumberOfTransactions,
		Deposit,
		Withdrawal,
		Gain,
		Loss,
		CurrencyDisplayCode,
	} = bettingAndPaymentTableData.userActivity;

	const depositClass = classNames(
		"table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium",
		{
			"u-color--ui--danger": Deposit < 0,
		}
	);

	const withdrawalClass = classNames(
		"table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium",
		{
			"u-color--ui--success": Withdrawal > 0,
		}
	);

	const gainClass = classNames(
		"table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium",
		{
			"u-color--ui--success": Gain > 0,
		}
	);

	const lossClass = classNames(
		"table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium",
		{
			"u-color--ui--danger": Loss != 0,
		}
	);

	return (
		<>
			<thead className="table__head table__head--accent">
				<tr className="table__head__row table__row">
					<th
						className="table__head__cell table__cell table__cell--sml u-type--center"
						colSpan={5}
					>
						{t<string>("ACC_STATEMENT.TABLES.TABLE_PAYMENT")}
					</th>
				</tr>
			</thead>
			<tbody className="table__body">
				<tr className="table__body__tr table__row">
					<td className="table_body__cell table__cell table__cell--sml u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.NUMBER_TRANSACTIONS")}
					</td>
					<td className="table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_DEPOSIT")}
					</td>
					<td className="table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_WITHDRAWAL")}
					</td>
					<td className="table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_GAIN")}
					</td>
					<td className="table_body__cell table__cell table__cell--sml u-type--right u-type--wgt--medium">
						{t<string>("ACC_STATEMENT.TABLES.TABLE_LOSS")}
					</td>
				</tr>
				<tr className="table__body__tr table__row">
					<td className="table_body__cell table__cell table__cell--sml u-color--text--primary">
						{NumberOfTransactions}
					</td>
					<td className={depositClass}>
						{getDisplayedAmount(Deposit, CurrencyDisplayCode)}
					</td>
					<td className={withdrawalClass}>
						{getDisplayedAmount(Withdrawal, CurrencyDisplayCode)}
					</td>
					<td className={gainClass}>
						{getDisplayedAmount(Gain, CurrencyDisplayCode)}
					</td>
					<td className={lossClass}>
						{getDisplayedAmount(Loss, CurrencyDisplayCode)}
					</td>
				</tr>
			</tbody>
		</>
	);
}
