import {
	getAgencyKey,
	httpClient,
	getApplicationHeaders,
	DeviceDetector,
	AccountTypes,
} from "@utils";
import { GameCategoryDto } from "@api-types/casino/GameCategoryDto";
import { TransferFundsDto } from "@api-types/casino/TransferFundsDto";
import {
	CasinoRequestFilter,
	Games,
	MenuDataResponse,
	TransactionInfoRequestData,
} from "@data-types/casino";
import { GameLaunchDto } from "@api-types/casino/GameLaunchDto";

class CasinoService {
	get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}`;
	}

	get casinoBaseUrl() {
		return `${CasinoApiAddress}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}`;
	}

	getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	async getCasinoLobbyAsync(
		filter: CasinoRequestFilter
	): Promise<MenuDataResponse> {
		let url = `${this.casinoBaseUrl}/casino/lobby${
			DeviceDetector.isMobileTheme ? "/mobile" : ""
		}`;

		const urlParams = new URLSearchParams();
		for (const [key, value] of Object.entries(filter)) {
			if (value == null) {
				continue;
			}

			urlParams.append(key, value);
		}

		return await httpClient.get(
			`${url}?${urlParams.toString()}`,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	async getFeaturedGames(
		filter: CasinoRequestFilter
	): Promise<MenuDataResponse> {
		let url = `${this.casinoBaseUrl}/casino/lobby${
			DeviceDetector.isMobileTheme ? "/mobile" : ""
		}/featured-games`;

		const urlParams = new URLSearchParams();
		for (const [key, value] of Object.entries(filter)) {
			if (value == null) {
				continue;
			}

			urlParams.append(key, value);
		}

		return await httpClient.get(
			`${url}?${urlParams.toString()}`,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}

	async lobbyLaunch(providerAbrv: string) {
		const url = `${this.casinoBaseUrl}/casino/${providerAbrv}/launch${
			DeviceDetector.isMobileTheme ? "/mobile" : ""
		}/lobby`;

		return await httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	async getGame(gameId: string): Promise<MenuDataResponse> {
		const url = `${this.casinoBaseUrl}/casino/lobby${
			DeviceDetector.isMobileTheme ? "/mobile" : ""
		}/games/${gameId}`;

		return await httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	async getGamesByCategory(
		categoryId: string,
		isNew: boolean
	): Promise<MenuDataResponse> {
		const url = `${this.casinoBaseUrl}/casino/lobby${
			DeviceDetector.isMobileTheme ? "/mobile" : ""
		}/categories/${categoryId}?rpp=200${isNew ? `&isNew=true` : ""}`;

		return await httpClient.get(url, this.getRequestHeaders(), {
			responseType: "json",
		});
	}

	async transferFunds(
		transactionInfoData: TransactionInfoRequestData
	): Promise<TransferFundsDto> {
		return await httpClient.post(
			`${this.baseUrl}/tw-user-accounts/casino-transfer`,
			JSON.stringify(transactionInfoData),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	async launchGame(
		casinoProviderAbrv: string,
		gameId: string | null
	): Promise<GameLaunchDto> {
		try {
			return await httpClient.get(
				`${this.casinoBaseUrl}/casino/${casinoProviderAbrv}/launch${
					DeviceDetector.isMobileTheme ? "/mobile" : ""
				}/${gameId}`,
				this.getRequestHeaders(),
				{ responseType: "json" }
			);
		} catch (ex) {
			return ex;
		}
	}

	async getCategories(): Promise<Array<GameCategoryDto>> {
		return await httpClient.get(
			`${CasinoApiAddress}platform/casino-game-categories/actual-casino-game-categories`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	async getGames(filter: CasinoRequestFilter): Promise<Games> {
		const url = `${this.casinoBaseUrl}/casino/lobby${
			DeviceDetector.isMobileTheme ? "/mobile" : ""
		}/games`;

		const urlParams = new URLSearchParams();
		for (const [key, value] of Object.entries(filter)) {
			if (value == null) {
				continue;
			}

			urlParams.append(key, value);
		}

		return await httpClient.get(
			`${url}?${urlParams.toString()}`,
			this.getRequestHeaders(),
			{
				responseType: "json",
			}
		);
	}
}

const casinoService = new CasinoService();

export { casinoService };
