import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@v2/components/desktop/shared/buttons";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default function AccountStatementBetDetailsError({
	setDetailsType,
}: {
	setDetailsType: Function;
}) {
	const { t } = useTranslation();

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					<div className="popup__header__title u-type--xmed u-type--wgt--regular">
						{t<string>(
							"ACC_STATEMENT.BET_DETAILS.BET_SLIP_DETAILS"
						)}
					</div>

					<Button
						btnType="button"
						// btnText="ACC_STATEMENT.BET_DETAILS.CLOSE_BTN"
						className="btn--square btn--square--tny btn--secondary--inverted"
						iconOnly={true}
						iconClassName="u-icon--sml u-icon--close"
						onClick={() => setDetailsType(false)}
					/>
				</div>
				<div className="popup__body">
					<InfoMessageComponent
						type={"danger"}
						message={t<string>(
							"ACC_STATEMENT.BET_DETAILS.ERROR_OCCURED"
						)}
					/>
				</div>
			</div>
		</div>
	);
}
