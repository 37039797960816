export class CountryLookupModel {
	id: string;
	name: string;

	constructor(country: { id: string; name: string }) {
		this.id = country.id;
		this.name = country.name;
	}
}

export class Response<T> {
	data: T | null;
	success: boolean;

	constructor(data: T | null = null) {
		this.data = data;
		this.success = true;
	}
}

export class ErrorResponse<E> {
	error: E | null;
	success: boolean;

	constructor(error: E | null = null) {
		this.error = error;
		this.success = false;
	}
}
