import React from "react";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, FormButton } from "@components/buttons";
import { getUserBettingAccountTypeId } from "@utils";

export function VoucherBonusForm(props: {
	activateVoucher: Function;
	isInput: boolean;
	setIsInput: Function;
}) {
	const { t } = useTranslation();
	const { handleSubmit, getValues } = useFormContext();

	const bettingAccountTypeId = getUserBettingAccountTypeId();

	if (props.isInput == false) {
		return null;
	}

	async function onSubmit(data: any) {
		await props.activateVoucher(
			bettingAccountTypeId,
			data,
			Number(getValues("userTypes"))
		);
	}

	return (
		<form
			id="voucherForm"
			className="form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="card--primary__body">
				<div className="u-mar--bottom--med u-type--med">
					{t<string>(
						`USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_BONUS`
					)}
				</div>
				<FormInputField
					type="text"
					id="bonusVoucherCode"
					className="form__group"
					name="voucher"
					placeholder="USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER_CODE_HINT"
					label="USER.ACCOUNT_SETTINGS.MY_BONUSES.VOUCHER"
					required
				/>
			</div>
			<div className="card--primary__footer">
				<FormButton
					type="submit"
					name="voucher"
					className="card--primary__footer__btn btn--lrg btn--primary"
					btnText="USER.ACCOUNT_SETTINGS.MY_BONUSES.SEND"
				/>
				<Button
					type="button"
					name="voucher"
					className="card--primary__footer__btn btn--lrg btn--secondary--inverted"
					btnText="USER.ACCOUNT_SETTINGS.MY_BONUSES.BACK"
					onClick={() => props.setIsInput(false)}
				/>
			</div>
		</form>
	);
}
