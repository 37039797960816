import React from "react";
import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";

import { getCurrentCulture } from "@utils";
import { EventOffer } from "@gp/offer";
import { TeamName } from "../details";
import { AdditionalOfferViewStore } from "../../../../state/offer/components/AdditionalOfferViewStore";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { AdditionalOfferInteractionTypeEnum } from "@data-types/analytics/AdditionalOfferInteractionTypeEnum";

export default observer(function TeamNames(props: {
	isInAdditionalOffer: boolean;
	store: AdditionalOfferViewStore;
	event: EventOffer;
}) {
	const homeTeam = props.store.lookupsStore.teams.get(
		props.event.teamOneId || ""
	);
	const awayTeam = props.store.lookupsStore.teams.get(
		props.event.teamTwoId || ""
	);
	const params = useParams<{
		culture: string;
		period: string;
		segments: string;
	}>();

	let link = `/${params.culture}/sports/${params.period}/event/${
		props.event.id
	}${params.segments == null ? "" : "/" + params.segments}`;

	if (props.event.isLive) {
		link = `/${params.culture}/live/events/${props.event.id}`;
	}

	return (
		<Link
			className="event__teams event__teams--anchor"
			to={link}
			onClick={() => {
				AnalyticsService.logAdditionalOfferInteraction({
					category:
						AdditionalOfferInteractionTypeEnum.PrematchAdditionalOffer,
					//@ts-expect-error
					label: `${props.event.sport.abrv}-${homeTeam?.name}-${awayTeam?.name}`,
				});
			}}
		>
			<TeamName
				team={homeTeam}
				isInAdditionalOffer={props.isInAdditionalOffer}
			/>
			<span className="u-mar--x--xtny">vs</span>
			<TeamName
				team={awayTeam}
				isInAdditionalOffer={props.isInAdditionalOffer}
			/>
		</Link>
	);
});
