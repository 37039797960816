import { observable, action, runInAction } from "mobx";
import RootOfferStore from "@offer/stores/RootStore";
import { MainOfferStore } from "@gp/offer";
import { Subscription } from "rxjs";

export class ActiveEventsStore extends MainOfferStore {
	rootStore: RootOfferStore;

	constructor(rootStore: RootOfferStore) {
		super();
		this.rootStore = rootStore;
	}

	@observable liveFeedSubscription: Subscription | null = null;

	@action.bound
	async fetchActiveEvents(
		eventIds: string[],
		selectedBettingType:
			| string
			| (string | undefined)[]
			| null
			| undefined = undefined
	) {
		this.onDispose();

		if (selectedBettingType == "closed" || eventIds.length === 0) {
			return;
		}

		try {
			this.liveFeedSubscription = await this.rootStore.hub
				.getOfferSubscription({
					subscriptionId: `active-events-/${eventIds.join("/")}`,
					channels: [
						{
							name: "event",
							filter: {
								id: {
									eq: eventIds,
								},
							},
						},
					],
					compress: WEBPACK_OFFER_COMPRESS,
				})
				.subscribe({
					next: (response) => {
						runInAction(() => {
							this.assignOfferData(response);
						});
					},
					error: (err) => {
						console.error("console.error", err);
					},
				});
		} catch (error) {
			console.error(error);
		}
	}

	@action.bound
	onDispose() {
		if (this.liveFeedSubscription) {
			this.liveFeedSubscription.unsubscribe();
			this.liveFeedSubscription = null;
		}
	}
}
