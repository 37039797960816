import { action, observable, runInAction } from "mobx";
import { ResultsMenuStore } from "./index";
import ResultsService from "@services/results/ResultsService";
import {
	ResultDataRequestFilter,
	ResultMenuTournament,
} from "@data-types/results";
import RootOfferStore from "@offer/stores/RootStore";
import {
	MappedEvent,
	ResultFormattedData,
} from "@data-types/results/ResultResponseData";

export class ResultsDataStore {
	rootStore: RootOfferStore;
	menuStore: ResultsMenuStore;
	@observable resultData:
		| ResultFormattedData<Map<string, MappedEvent[]>>[]
		| null;
	@observable dayParam: number = 0;
	@observable isSearch = false;

	constructor(rootStore: RootOfferStore, menuStore: ResultsMenuStore) {
		this.rootStore = rootStore;
		this.menuStore = menuStore;
	}

	@action.bound
	async onInitialize(): Promise<void> {
		const selectedTournaments = this.menuStore.selectedTournaments;
		const searchString = this.rootStore.searchBarViewStore.searchTerm;

		this.isSearch = searchString != null && searchString !== "";

		const requestFilter: ResultDataRequestFilter = {
			previousDays:
				selectedTournaments.length > 0 ||
				(searchString != null && searchString !== "")
					? 7
					: this.dayParam || 0,
			tournamentIds:
				selectedTournaments.length > 0 &&
				(searchString === null || searchString === "")
					? selectedTournaments.map((t: ResultMenuTournament) => t.id)
					: null,
			searchPhrase: this.isSearch ? searchString.toLowerCase() : null,
		};

		const resultDataRequest = await ResultsService.getResults(
			requestFilter
		);

		if (resultDataRequest.success) {
			this.resultData = resultDataRequest.data;
		}
	}

	@action.bound
	changeDayParam(dayParamValue: number): void {
		if (this.dayParam !== dayParamValue) {
			this.dayParam = dayParamValue;
		}
	}

	@action.bound
	resetResultsData(): void {
		this.dayParam = 0;
		this.isSearch = false;
		this.rootStore.searchBarViewStore.onTermChange("");
	}
}
