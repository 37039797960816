import { useLoading } from "@hooks";
import {
	forgotUsernameServiceInstance,
	ForgetUserNameFormData,
} from "@v2/data-access/membership";
import { useNotification } from "@v2/hooks/shared";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useFormContext } from "react-hook-form";

export default function useForgotUsernameForm(params: {
	setIsForgotUsernameSuccess: (value: boolean) => void;
}) {
	const { showError } = useNotification();

	const { reset } = useFormContext();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const { setIsLoading } = useLoading();

	const onFormSubmit = async (formData: ForgetUserNameFormData) => {
		try {
			setIsLoading(true);
			const recaptchaToken = await executeRecaptcha?.("forgotUsername");
			const response = await forgotUsernameServiceInstance.forgotUsername(
				formData,
				recaptchaToken
			);
			if (response.success) {
				params.setIsForgotUsernameSuccess(true);
			}
		} catch (error) {
			console.error(error);
			reset();
			if (error?.error?.options) {
				showError([
					{
						message:
							"MEMBERSHIP.FORGOT_USERNAME." +
							error?.error?.message,
						options: { 0: error?.error?.options },
					},
				]);
			} else {
				showError(
					"MEMBERSHIP.FORGOT_USERNAME." + error?.error?.message
				);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return { onFormSubmit };
}
