import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { IConsoleLogger } from "@interface";

export function Modal(props: {
	children: JSX.Element | JSX.Element[] | string;
	logger?: IConsoleLogger;
}) {
	const [elContainer] = useState(document.createElement("div"));
	const modalContainer = document.getElementById("app-modal");

	useEffect(() => {
		if (modalContainer == null) {
			if (props.logger == null) {
				console.error("Modal container is null and logger is null!");
			} else {
				props.logger.logError("Modal container is null!");
			}
			return;
		}
		modalContainer.appendChild(elContainer);

		return () => {
			modalContainer.removeChild(elContainer);
		};
	}, []);

	return ReactDOM.createPortal(props.children, elContainer);
}
