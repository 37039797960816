import { observable, action, runInAction } from "mobx";
import { PaymentOptionsData } from "@data-types/payment-options";
import { PaymentOptionDto } from "@api-types/payment-options/PaymentOptionDto";
import { getCurrentCulture } from "@utils";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const  PaymentOptionsService = lazy(loadFailPath, ()=>import("@services/payment-options/PaymentOptionsService"));
export default class PaymentOptionsStore {
	@observable footerPaymentOptions: PaymentOptionDto[] = [];
	@observable paymentOptions: PaymentOptionsData = new PaymentOptionsData();

	@action.bound
	async onStoreInitialize() {
		const response = await (
			await PaymentOptionsService
		).default.getFooterMethods();

		runInAction(() => {
			this.footerPaymentOptions = response;
		});
	}

	@action.bound
	async onInitialize() {
		const response = await (
			await PaymentOptionsService
		).default.getPaymentOptions();

		runInAction(() => {
			if (response != null) {
				this.paymentOptions = response;
			}
		});
	}

	@action.bound
	onDispose() {
		this.paymentOptions = new PaymentOptionsData();
	}
}
