import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLogin } from "@v2/hooks/membership/login";
import { isRegistrationEnabled } from "@v2/helpers";
import { LoginStatusMessage, LoginForm } from "./";
import { getCurrentCulture } from "@utils";
import {
	OnBoardingSlider,
	OnBoardingFooter,
} from "@components-desktop/membership/shared";
import { MaintenanceNotice } from "../../shared/maintenance";
import LogonMitIdButton from "../../../shared/membership/LogonMitIdButton";

export default function LoginPage() {
	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			password: "",
		},
	});
	const { resetField } = methods;

	const { loginError } = useLogin(resetField);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<main role="main" className="at-login l--main l--main--grow">
			<section className="l--content l--content--full">
				<div className="l--content__main u-padd--reset">
					<div className="container container--med container--grow">
						<div className="card--onboarding card--onboarding--grow">
							<OnBoardingSlider />
							<div className="card--onboarding__form card--onboarding__form--center">
								<div className="card--onboarding__header card--onboarding__header--vertical">
									<LoginStatusMessage
										loginError={loginError}
									/>

									<MaintenanceNotice />
								</div>

								<FormProvider {...methods}>
									<LoginForm />
								</FormProvider>

								<div>
									<LogonMitIdButton btnSize="base" />
								</div>

								<div className="card--onboarding__footer u-type--sml u-color--text--secondary">
									<SignUpButton />
									<AccountLockSettingsUpdateButton />
								</div>
							</div>
						</div>

						<OnBoardingFooter />
					</div>
				</div>
			</section>
		</main>
	);
}

function SignUpButton() {
	const { t } = useTranslation();

	if (!isRegistrationEnabled) {
		return null;
	}

	return (
		<div>
			{t("MEMBERSHIP.LOGIN.NONEXISTENT_ACCOUNT")}
			<Link
				to={`/${getCurrentCulture()}/auth/register`}
				className="u-anchor u-anchor--primary u-mar--left--xtny"
			>
				{t("MEMBERSHIP.LOGIN.SIGN_UP_LABEL")}
			</Link>
		</div>
	);
}

function AccountLockSettingsUpdateButton() {
	const { t } = useTranslation();

	if (!WEBPACK_ENABLE_ACCOUNT_LOCK_UPDATE_ON_LOGIN) {
		return null;
	}

	return (
		<div>
			{t("MEMBERSHIP.ACCOUNT_LOCK_UPDATE.LOGIN_LINK_MESSAGE")}
			<Link
				to={`/${getCurrentCulture()}/auth/account-lock-update`}
				className="u-anchor u-anchor--primary u-mar--left--xtny"
			>
				{t("MEMBERSHIP.ACCOUNT_LOCK_UPDATE.LOGIN_LINK_LABEL")}
			</Link>
		</div>
	);
}
