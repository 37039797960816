import React, { useContext } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";
import { SportOffer } from "@gp/offer";
import { EventOffer } from "@gp/offer";
import { EventContext, SportOfferContext } from "@gp/components";

import { useOfferColumnResult } from "@v2/hooks/offer";

export const OfferColumnResult = observer(function OfferColumnResult() {
	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);
	const columResults = useOfferColumnResult(
		event as EventOffer,
		sport as SportOffer
	);

	if (columResults.lastPeriodScore == null) {
		return (
			<div className="offer__body__data offer__score is-empty">
				&nbsp;
			</div>
		);
	}

	const offerColumnResultClassNames = classnames(
		"offer__body__data offer__score",
		{
			"server-only":
				columResults.gameScore == null &&
				(columResults.serve === "home" ||
					columResults.serve === "away"),
			"set-only":
				columResults.gameScore == null &&
				// @ts-expect-error
				(columResults.serve == null || columResults.serve === ""),
		}
	);

	return (
		<div className={offerColumnResultClassNames}>
			<CurrentServer serve={columResults.serve} />
			<GameScore gameScore={columResults.gameScore} />
			<div className="offer__score__col offer__score__set">
				<div className="offer__score__item">
					{columResults.lastPeriodScore.home || 0}
				</div>
				<div className="offer__score__item">
					{columResults.lastPeriodScore.away || 0}
				</div>
			</div>
		</div>
	);
});

function CurrentServer(props: { serve: "home" | "away" | undefined }) {
	const offerScoreServerClassNames = classnames(
		"offer__score__col offer__score__server",
		{
			// @ts-expect-error
			"is-empty": props.serve == null || props.serve === "",
		}
	);

	return (
		<div className={offerScoreServerClassNames}>
			<div className="offer__score__item">
				{props.serve === "home" && <ServeIcon />}
			</div>
			<div className="offer__score__item">
				{props.serve === "away" && <ServeIcon />}
			</div>
		</div>
	);
}

function GameScore(props: GameScoreProps) {
	if (props.gameScore == null) {
		return (
			<div className="offer__score__col offer__score__game¨is-empty" />
		);
	}

	return (
		<div className="offer__score__col offer__score__game">
			<div className="offer__score__item">
				{props.gameScore.home || 0}
			</div>
			<div className="offer__score__item">
				{props.gameScore.away || 0}
			</div>
		</div>
	);
}

type GameScoreProps = {
	gameScore:
		| {
				home: string;
				away: string;
		  }
		| undefined;
};

function ServeIcon() {
	return <i className="offer__score__serve" />;
}
