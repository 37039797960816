import React from "react";
import { BetOffer } from "@administration/pages/my-bets/model";
import { EventType } from "@gp/models";
import classNames from "classnames";
import useDayAndTimeFromEventStartTime from "@hooks/useDayAndTimeFromEventStartTime";

export function EventOfferGroups_Time(props: {
	offer: BetOffer;
	displayTime?: boolean;
	isPage?: boolean;
	isPopup?: boolean;
}) {
	if (!props.displayTime) {
		return null;
	}
	const { day, time } = useDayAndTimeFromEventStartTime(
		props.offer.sportData.startTime,
		true
	);

	const timeClasses = classNames({
		bet__time: !props.isPopup,
		"bet__time--popup": props.isPopup || props.isPage,
	});

	return (
		<div className={timeClasses}>
			{day} {time}
		</div>
	);
}

export function EventOfferGroups_BettingType(props: {
	offer: BetOffer;
	withCoefficient?: boolean;
	isPopup?: boolean;
	isPage?: boolean;
	isQuickBetPage?: boolean;
	isWidget?: boolean;
}) {
	const { offer } = props;

	const bettingTypeClasses = classNames({
		"slip--most__pick u-padd--top--xtny": props.isPopup,
		"slip--quick__inner slip--quick__inner--type": !props.isPopup,
		"u-type--xxsml": props.isPage,
	});

	// const bettingTypeTitleClasses = classNames({
	// 	"slip--quick__pick__title": props.isPopup || props.isWidget,
	// });

	if (
		offer.sportData.eventType &&
		offer.sportData.eventType !== EventType.NORMAL
	) {
		return <>{offer.formattedBettingTypeTip}</>;
	}

	return (
		<div className={bettingTypeClasses}>
			<span className="slip--quick__pick__title">
				{offer.formattedBettingType}:
			</span>
			<strong className="slip__match__pick__tip slip--quick__pick__tip">
				{offer.formattedBettingTypeTip}
			</strong>
			{props.withCoefficient && (
				<span
					className={`quote--tny ${
						props.isPopup ? "popup-most" : ""
					}`}
				>
					{" "}
					{offer.value.toFixed(2)}
				</span>
			)}
		</div>
	);
}

export function EventOfferGroups_EventName(props: {
	offer: BetOffer;
	isDetails?: boolean;
	isPopup?: boolean;
	isPage?: boolean;
}) {
	const { offer, isDetails = false } = props;

	const nameClasses = classNames("bet__name", {
		"bet__name--multi": offer.isMulti,
		"bet__name--event": offer.sportData.eventType !== EventType.NORMAL,
		"bet__name--teams":
			!offer.isMulti && offer.sportData.eventType === EventType.NORMAL,
		"bet__name--full": isDetails,
		"u-type--sml": props.isPopup || props.isPage,
	});

	if (offer.isMulti) {
		return <span className={nameClasses}>{offer.multiEventName}</span>;
	}

	if (
		offer.sportData.eventType &&
		offer.sportData.eventType !== EventType.NORMAL &&
		offer.sportData.eventName != ""
	) {
		return <span className={nameClasses}>{offer.sportData.eventName}</span>;
	}

	return (
		<span className={nameClasses}>
			{offer.teamOneString} - {offer.teamTwoString}
		</span>
	);
}
