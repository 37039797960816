import React from "react";
import { useTranslation } from "react-i18next";

export default function BetItemPaymentDetail(props: {
	betStatusAbrv: string;
	betSlipStatusAbrv: string;
	maxGain: string;
	payout: string;
}) {
	const { t } = useTranslation();

	if (
		props.betStatusAbrv == "won" &&
		(props.betSlipStatusAbrv == "paid-out" ||
			props.betSlipStatusAbrv == "cashout")
	) {
		return (
			<>
				<div className="widget--bets__label">
					{t<string>("MY_BETS.ITEM.INFO_PAYOUT_LABEL")}:
				</div>
				<div className="widget--bets__amount">{props.payout}</div>
			</>
		);
	}

	return (
		<>
			<div className="widget--bets__label">
				{t<string>("MY_BETS.ITEM.INFO_MAX_GAIN_LABEL")}:
			</div>
			<div className="widget--bets__amount">{props.maxGain}</div>
		</>
	);
}
