import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Footer } from "@v2/components/desktop/shared/footer";
import { Header } from "@v2/components/desktop/shared/header";
import { useAppContext } from "@v2/hooks/shared";
import { setTabTitle } from "@utils";
import { isLoginFlow, isOnboardingPage } from "@v2/helpers/membership/utils";

export default function Error404(props: { path404?: string; full?: boolean }) {
	const { path404, full } = props;
	const { t } = useTranslation();
	const { culture } = useAppContext();
	const { pathname } = useLocation();

	const accountSettingsNoMatchActive = pathname.includes(
		`/${culture}/account-settings/`
	);

	useEffect(() => {
		setTabTitle("404");
		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
	}, []);

	if (isOnboardingPage(pathname) || isLoginFlow(pathname)) {
		return (
			<>
				<div className="card--primary">
					<div className="card--primary__header">
						<div className="title--primary">
							{t<string>("ERROR.404.PAGE_NOT_FOUND")}
						</div>
					</div>
					<div
						className="u-type--sml u-type--color--grey u-mar--left--xsml u-mar--right--xsml u-mar--top--xsml"
						style={{ overflowWrap: "break-word" }}
					>
						{path404}
					</div>
					<div className="card--primary__body">
						<div className="u-mar--bottom--xsml">
							<b>
								{t<string>("ERROR.404.PAGE_HAS_NOT_BEEN_FOUND")}
							</b>
						</div>
						<div>{t<string>("ERROR.404.REASONS")}</div>
						<ul className="list">
							<li>{t<string>("ERROR.404.URL_RELOCATED")}</li>
							<li>{t<string>("ERROR.404.URL_INCORRECT")}</li>
							<li>{t<string>("ERROR.404.URL_UNAVAILABLE")}</li>
							<li>
								{t<string>("ERROR.404.SUPPORT_HELP")}{" "}
								<Link
									to={`/${culture}/info/contact`}
									className="u-anchor u-anchor--primary u-type--wgt--medium"
								>
									{t<string>("ERROR.404.HERE_LINK")}
								</Link>
							</li>
						</ul>
					</div>
					<div className="card--primary__footer">
						<Link
							className="card--primary__footer__btn btn btn--lrg btn--primary"
							to={`/${culture}/home/full`}
						>
							{t<string>("ERROR.404.BACK_TO_HOME")}
						</Link>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<Header />
			<main className="l--main l--main--full">
				<section className="l--content l--content--full">
					<div className="l--content__main">
						<div className="container">
							<div className="card--primary">
								<div className="card--primary__header">
									<div className="title--primary">
										{t<string>("ERROR.404.PAGE_NOT_FOUND")}
									</div>
								</div>
								<div
									className="u-type--sml u-type--color--grey u-mar--left--xsml u-mar--right--xsml u-mar--top--xsml"
									style={{ overflowWrap: "break-word" }}
								>
									{path404}
								</div>
								<div className="card--primary__body">
									<div className="u-mar--bottom--xsml">
										<b>
											{t<string>(
												"ERROR.404.PAGE_HAS_NOT_BEEN_FOUND"
											)}
										</b>
									</div>
									<div>{t<string>("ERROR.404.REASONS")}</div>
									<ul className="list">
										<li>
											{t<string>(
												"ERROR.404.URL_RELOCATED"
											)}
										</li>
										<li>
											{t<string>(
												"ERROR.404.URL_INCORRECT"
											)}
										</li>
										<li>
											{t<string>(
												"ERROR.404.URL_UNAVAILABLE"
											)}
										</li>
										<li>
											{t<string>(
												"ERROR.404.SUPPORT_HELP"
											)}{" "}
											<Link
												to={`/${culture}/info/contact`}
												className="u-anchor u-anchor--primary u-type--wgt--medium"
											>
												{t<string>(
													"ERROR.404.HERE_LINK"
												)}
											</Link>
										</li>
									</ul>
								</div>
								<div className="card--primary__footer">
									<Link
										className="card--primary__footer__btn btn btn--lrg btn--primary"
										to={`/${culture}/home/full`}
									>
										{t<string>("ERROR.404.BACK_TO_HOME")}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{!accountSettingsNoMatchActive ? (
				<footer className="l--footer">
					<Footer />
				</footer>
			) : null}
		</>
	);
}
