import React from "react";
import { observer } from "mobx-react";

import { Sport, TeamStatistics } from "@gp/models";

const DISPLAY_SPORTS = ["soccer"];

export const RedCards = observer(function RedCards(props: {
	sport: Sport;
	statistics?: TeamStatistics;
}) {
	const { sport, statistics } = props;

	if (!DISPLAY_SPORTS.includes(sport.abrv) || statistics == undefined) {
		return null;
	}

	let redCards = 0;

	if (statistics.redCards != undefined) {
		redCards += statistics.redCards;
	}
	if (statistics.redYellowCards != undefined) {
		redCards += statistics.redYellowCards;
	}

	if (redCards === 0) {
        return null;
	}

	return <span className="offer__match__card">{redCards}</span>;
});
