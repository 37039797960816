import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { ResultDataTabs, ResultTables, ResultTablesSelected } from "./";
import { ResultsMenuStoreContext } from "@context/results";

export const ResultDataWrapper = function ResultDataWrapper() {
	const { t } = useTranslation();

	return (
		<section className="l--content l--content--full">
			<div className="l--content__main">
				<div className="container">
					<div className="card--primary">
						<div className="card--primary__header">
							<h1 className="card--primary__title title--primary">
								{t<string>("RESULTS.TITLE")}
							</h1>
						</div>

						<ResultDataContent />
					</div>
				</div>
			</div>
		</section>
	);
};

const ResultDataContent = observer(function ResultDataContent() {
	const { selectedTournaments } = useContext(ResultsMenuStoreContext);

	if (selectedTournaments == null || selectedTournaments.length === 0) {
		return (
			<>
				<ResultDataTabs />
				<ResultTables />
			</>
		);
	}

	return <ResultTablesSelected />;
});
