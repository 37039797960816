import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useRegistrationStore } from "@v2/context/RegistrationStoreContext";
import { Button } from "@components/buttons";
import {
	RegistrationCity,
	RegistrationConditions,
	RegistrationHouseNumber,
	RegistrationLanguage,
	RegistrationLimit,
	RegistrationPromotion,
	RegistrationStreet,
	RegistrationZIPCode,
} from "@components-desktop/membership/registration";
import { Link } from "react-router-dom";
import { getCurrentCulture } from "@utils";
import { useFormContext } from "react-hook-form";
import RegistrationPlayersMoneyProtection from "@components-desktop/membership/registration/inputs/RegistrationPlayersMoneyProtection";

export const RegistrationContactDetails = observer(
	function RegistrationContactDetails() {
		const {
			formState: { isSubmitting },
		} = useFormContext();

		const { t } = useTranslation();

		const {
			previousStep,
			fetchLimitDuration,
			gamingLimitsData,
			limitDurationData,
			languageData,
		} = useRegistrationStore();

		useEffect(() => {
			//on mount scroll to top
			document.querySelector("html, body")?.scrollTo(0, 0);

			fetchLimitDuration();
		}, []);

		return (
			<>
				<div className="card--onboarding__content">
					<RegistrationStreet labelClassName="label--strong" />

					<RegistrationHouseNumber labelClassName="label--strong" />

					<div className="form__field">
						<div className="row">
							<div className="col col-sml-3">
								<RegistrationZIPCode labelClassName="label--strong" />
							</div>
							<div className="col col-sml-9">
								<RegistrationCity labelClassName="label--strong" />
							</div>
						</div>
					</div>

					<RegistrationLimit
						limitDurationData={limitDurationData}
						gamingLimitsData={gamingLimitsData}
						labelClassName="label--strong"
						required
					/>

					<RegistrationLanguage
						languageData={languageData}
						labelClassName="label--strong"
					/>

					<RegistrationPromotion />

					<RegistrationConditions />

					<RegistrationPlayersMoneyProtection />
				</div>

				<div className="form__field row">
					<div className="col col-sml-6">
						<Button
							btnType="button"
							atClassName="regAccountBtnPrev"
							className="btn--base btn--block btn--secondary--inverted"
							iconLeft
							iconClassName="u-icon--xsml u-icon--arrow--left"
							btnText="COMMON.PREVIOUS_BUTTON"
							onClick={previousStep}
						/>
					</div>

					<div className="col col-sml-6">
						<Button
							isDisabled={isSubmitting}
							atClassName="regAccountBtnNext"
							className="btn--base btn--block btn--primary"
							btnContent={
								<>
									{t<string>(
										"MEMBERSHIP.REGISTRATION.OPEN_ACCOUNT"
									)}{" "}
									*
								</>
							}
						/>
					</div>
				</div>

				<div className="card--onboarding__footer u-type--sml u-color--text--secondary">
					{t("MEMBERSHIP.REGISTRATION.EXISTING_ACCOUNT")}
					<Link
						to={`/${getCurrentCulture()}/auth/login`}
						className="u-anchor u-anchor--primary u-mar--left--xtny"
					>
						{t("MEMBERSHIP.LOGIN.LOGIN_BUTTON")}
					</Link>
				</div>
			</>
		);
	}
);
