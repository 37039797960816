import {
	observable,
	computed,
	action,
	runInAction,
	reaction,
	IReactionDisposer,
} from "mobx";
import { DateTime } from "luxon";
import { casinoService } from "@v2/data-access";
import { PaymentRequestFilter, RawFilterData } from "@data-types/deposit";
import RootStore from "@state/stores/RootStore";
import DepositStore from "./DepositStore";

function createDefaultRawData() {
	const defaultRawFilterData = observable.object(new RawFilterData());

	defaultRawFilterData.period = null;
	defaultRawFilterData.from = null;
	defaultRawFilterData.to = null;
	defaultRawFilterData.pageNumber = 1;
	defaultRawFilterData.paymentMethodId = null;
	defaultRawFilterData.transactionStatusId = null;

	return defaultRawFilterData;
}

export default class DepositFilterStore {
	constructor(rootStore: RootStore, depositStore: DepositStore) {
		this.rootStore = rootStore;
		this.depositStore = depositStore;

		this.setDefaultFilterData();

		this.getUrlData = this.getUrlData.bind(this);
	}

	rootStore: RootStore;
	depositStore: DepositStore;
	urlParamsUpdatedReaction: IReactionDisposer | null;
	@observable rawData = observable.object(new RawFilterData());
	@observable isDefaultFilter: boolean = false;

	@computed get filter(): PaymentRequestFilter {
		const filter = new PaymentRequestFilter();

		if (this.dateRange != null) {
			filter.from = this.dateRange.from;
			filter.to = this.dateRange.to;
		}

		if (this.pageNumber != null) {
			filter.pageNumber = this.pageNumber;
		}

		if (this.paymentMethodId != null) {
			filter.paymentMethodId = this.paymentMethodId;
		}

		if (this.transactionStatusId != null) {
			filter.transactionStatusId = this.transactionStatusId;
		}

		return filter;
	}

	//#region filter components

	@computed get dateRange(): {
		from: string | null | undefined;
		to: string | null | undefined;
	} | null {
		const { period, from, to } = this.rawData;

		if (
			(period == null && from == null && to == null) ||
			period === "all"
		) {
			return null;
		}

		if (period !== "custom") {
			const timeInterval = this.depositStore.betPeriods
				.find((p) => p.value === period)
				?.getTimeInterval();
			if (timeInterval == null) {
				// Return default
				return this.depositStore.betPeriods[0].getTimeInterval();
			}
			return timeInterval;
		}

		return {
			from: DateTime.fromISO(from || "")
				.startOf("day")
				.toString(),
			to: DateTime.fromISO(to || "")
				.endOf("day")
				.toString(),
		};
	}

	@computed get pageNumber(): number {
		return this.rawData.pageNumber;
	}

	@computed get paymentMethodId(): string | null {
		return this.rawData.paymentMethodId;
	}

	@computed get transactionStatusId(): string | null {
		return this.rawData.transactionStatusId;
	}

	//#endregion filter components

	//#region setters

	@action.bound
	setPeriod(period: string): void {
		this.rawData.period = period;
		this.isDefaultFilter = false;
	}

	@action.bound
	setFrom(from: string): void {
		this.rawData.from = from;
		this.isDefaultFilter = false;
	}

	@action.bound
	setTo(to: string): void {
		this.rawData.to = to;
		this.isDefaultFilter = false;
	}

	@action.bound
	setRawFilterData(filterData: RawFilterData): void {
		this.rawData = Object.assign(createDefaultRawData(), filterData);
		this.isDefaultFilter = false;
	}

	@action.bound
	setDefaultFilterData(): void {
		this.rawData = createDefaultRawData();
		this.isDefaultFilter = true;
	}

	@action.bound
	setPageNumber(number: number): void {
		this.rawData.pageNumber = number;
		this.isDefaultFilter = false;
	}

	//#endregion setters

	@action.bound
	async onInitialize(): Promise<void> {
		const urlData = this.getUrlData();

		if (App.state.history.location.search !== "") {
			this.setRawFilterData(
				Object.assign(createDefaultRawData(), urlData)
			);
		}

		this.onDisposeUrlParamsUpdated();
		this.urlParamsUpdatedReaction = reaction(
			() => ({
				filter: this.filter,
			}),
			() => this.updateUrlParamsFromRawData(),
			{
				fireImmediately: false,
			}
		);
	}

	getUrlData() {
		const urlData = App.state.getUrlParamsAsObj<{
			pageNumber?: string | number;
		}>();

		if ("pageNumber" in urlData) {
			urlData.pageNumber = parseInt(urlData.pageNumber as string);
			if (isNaN(urlData.pageNumber) || urlData.pageNumber < 1) {
				urlData.pageNumber = 1;
			}
		}

		return urlData;
	}

	@action.bound
	updateUrlParamsFromRawData(): void {
		// Filter out null params to not clutter url
		const newUrlParams = {};
		for (const [key, value] of Array.from(Object.entries(this.rawData))) {
			if (value == null) {
				continue;
			}
			//@ts-expect-error
			newUrlParams[key] = value;
		}

		if (!this.isDefaultFilter) {
			App.state.updateUrlParams(newUrlParams, true);
		}
	}

	//#region disposers

	@action.bound
	onDispose(): void {
		this.onDisposeUrlParamsUpdated();
		this.setDefaultFilterData();
	}

	@action.bound
	onDisposeUrlParamsUpdated(): void {
		if (this.urlParamsUpdatedReaction != null) {
			this.urlParamsUpdatedReaction();
			this.urlParamsUpdatedReaction = null;
		}
	}

	//#endregion disposers
}
