import React, { FC, useContext, useEffect } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { PaymentOption } from "@api-types/withdrawal";
import { WithdrawalContext } from "@context";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";
import PaymentOptionImage from "@v2/components/shared/icons/PaymentOptionImage";
import { getSVGExtension } from "@utils/common/helpers";

export const WithdrawalPaymentSelect: FC = observer(
	function WithdrawalPaymentSelect(props) {
		const {
			paymentMethods,
			fetchPaymentMethods,
			isFetchingPaymentMethods,
			hasWalletChangedFromDefault,
			changeWalletFromDefault,
			paymentMethodError,
		} = useContext(WithdrawalContext);
		const { t } = useTranslation();
		const { watch } = useFormContext();

		const bettingAccountTypeId = watch("bettingAccountTypeId");

		useEffect(() => {
			//refetch payment methods when wallet changes
			if (bettingAccountTypeId && hasWalletChangedFromDefault) {
				fetchPaymentMethods(bettingAccountTypeId);
			} else {
				changeWalletFromDefault();
			}
		}, [bettingAccountTypeId]);

		if (isFetchingPaymentMethods === true) {
			//skeleton loader?
			return null;
		}

		if (paymentMethodError?.errorCode === 8) {
			return (
				<InfoMessageComponent
					type="warning"
					message={paymentMethodError.message}
					textClass="message__text"
				/>
			);
		}

		if (paymentMethods == null || paymentMethods.length == 0) {
			//return view of no payment methods
			return (
				<>
					<div className="label">
						{t("WITHDRAWAL.USER_PAYMENT_METHODS")}
					</div>
					<InfoMessageComponent
						type="note"
						message={t("WITHDRAWAL.NO_PAYMENT_OPTIONS")}
						textClass="message__text"
					/>
				</>
			);
		}

		return <PaymentMethodList />;
	}
);

export const PaymentMethodList: FC = observer(function PaymentMethodList() {
	const { otherPaymentMethods, userPaymentSavedMethods } =
		useContext(WithdrawalContext);
	const { t } = useTranslation();
	const { resetField } = useFormContext();
	useEffect(() => {
		resetField("paymentMethodId", {
			defaultValue:
				userPaymentSavedMethods?.length != 0
					? userPaymentSavedMethods[0]?.paymentMethodId
					: otherPaymentMethods[0]?.paymentMethodId,
		});
	}, []);

	return (
		<>
			{userPaymentSavedMethods?.length != 0 && (
				<div className="form__group">
					<div
						className={classNames("label", {
							"u-mar--bottom--xsml":
								userPaymentSavedMethods?.length > 2,
						})}
					>
						{t("WITHDRAWAL.USER_PAYMENT_METHODS")}
					</div>
					<div className="row">
						{userPaymentSavedMethods?.map(
							(userPaymentMethod: PaymentOption) => {
								return (
									<PaymentMethodOption
										key={userPaymentMethod.paymentMethodId}
										paymentMethod={userPaymentMethod}
									/>
								);
							}
						)}
					</div>
				</div>
			)}

			{otherPaymentMethods?.length != 0 && (
				<div className="form__group">
					<div
						className={classNames("label", {
							"u-mar--bottom--xsml":
								otherPaymentMethods?.length > 2,
						})}
					>
						{t("WITHDRAWAL.OTHER_PAYMENT_METHODS")}
					</div>
					<div className="row">
						{otherPaymentMethods?.map(
							(paymentMethod: PaymentOption) => {
								return (
									<PaymentMethodOption
										key={paymentMethod.paymentMethodId}
										paymentMethod={paymentMethod}
									/>
								);
							}
						)}
					</div>
				</div>
			)}
		</>
	);
});

const PaymentMethodOption: FC<{
	paymentMethod: PaymentOption;
}> = observer(function PaymentMethodOption(props: {
	paymentMethod: PaymentOption;
}) {
	const { paymentMethod } = props;

	const { register } = useFormContext();

	const { t } = useTranslation();

	const providerLogoClasses = classNames(
		"card--payment__logo u-icon u-icon--payment u-icon--payment--med",
		[`u-icon--payment--${paymentMethod.paymentMethodAbrv.toLowerCase()}`]
	);

	return (
		<div key={paymentMethod.paymentMethodId} className="col col-sml-6">
			<div className="card--payment">
				<input
					id={`${paymentMethod.paymentMethodId}`}
					className="input--radio"
					value={paymentMethod.paymentMethodId}
					type="radio"
					{...register("paymentMethodId")}
					required
				/>
				<label
					htmlFor={`${paymentMethod.paymentMethodId}`}
					className="card--payment__area"
				>
					<span className="card--payment__icon">
						{/* <i className={providerLogoClasses}></i> */}
						<PaymentOptionImage
							className={providerLogoClasses}
							svgName={
								paymentMethod.paymentMethodAbrv.toLowerCase() +
								getSVGExtension()
							}
						/>
						<span className="card--payment__card">
							{paymentMethod.maskedPan}
						</span>
					</span>
					<span className="card--payment__details">
						<span className="card--payment__title">
							{paymentMethod.displayName}
						</span>
						<span className="card--payment__row">
							<span className="card--payment__col">
								{t<string>("DEPOSIT.MIN_AMOUNT")}:
							</span>
							<span className="card--payment__col">
								{paymentMethod.minPayout}
								{paymentMethod.currencyDisplayCode}
							</span>
						</span>
						<span className="card--payment__row">
							<span className="card--payment__col">
								{t<string>("DEPOSIT.MAX_AMOUNT")}:
							</span>
							<span className="card--payment__col">
								{paymentMethod.maxPayout}
								{paymentMethod.currencyDisplayCode}
							</span>
						</span>
					</span>
				</label>
			</div>
		</div>
	);
});
