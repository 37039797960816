import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../shared/buttons";
import { EAccountActivationEmptyFields } from "@data-types";

export default function EmptyFieldsInfoPopup(props: PropTypes) {
	const { getValues } = useFormContext();
	const { t } = useTranslation();

	if (props.emptyFields === EAccountActivationEmptyFields.NONE) {
		return null;
	}

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					{t<string>(
						"MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRMATION_LABEL"
					)}
				</div>

				<PopupMessage emptyFields={props.emptyFields} />

				<div className="popup__footer">
					<Button
						className="popup__footer__btn btn btn--sml btn--secondary--inverted"
						btnType="button"
						onClick={props.closeEmptyFieldsPopup}
						btnText="MEMBERSHIP.ACCOUNT_ACTIVATION.NO_BTN"
					/>

					<Button
						className="popup__footer__btn btn btn--sml btn--primary"
						btnType="button"
						onClick={() =>
							props.createAccountActivation(getValues())
						}
						btnText="MEMBERSHIP.ACCOUNT_ACTIVATION.YES_BTN"
					/>
				</div>
			</div>
		</div>
	);
}

function PopupMessage(props: { emptyFields: EAccountActivationEmptyFields }) {
	const { t } = useTranslation();
	const { emptyFields } = props;
	if (emptyFields === EAccountActivationEmptyFields.MAIL_AND_PASSWORD) {
		return (
			<div className="popup__body">
				{t<string>(
					"MEMBERSHIP.ACCOUNT_ACTIVATION.MAIL_AND_PASSWORD_NOT_ENTERED"
				)}
			</div>
		);
	} else if (emptyFields === EAccountActivationEmptyFields.PASSWORD) {
		return (
			<div className="popup__body">
				{t<string>(
					"MEMBERSHIP.ACCOUNT_ACTIVATION.PASSWORD_NOT_ENTERED"
				)}
			</div>
		);
	}
	return (
		<div className="popup__body">
			{t<string>("MEMBERSHIP.ACCOUNT_ACTIVATION.MAIL_NOT_ENTERED")}
		</div>
	);
}

type PropTypes = {
	emptyFields: EAccountActivationEmptyFields;
	closeEmptyFieldsPopup: () => void;
	createAccountActivation: Function;
};
