import React from "react";
import { useTranslation } from "react-i18next";

export default function MaintenanceNotice() {
	const { t } = useTranslation();

	if (!WEBPACK_IS_MAINTENANCE) {
		return null;
	}

	return (
		<div className="message message--warning u-mar--bottom--sml">
			<div className="u-padd--xsml u-type--center">
				<i className="u-icon u-icon--huge u-icon--empty--maintenance u-color--ui--warning" />
				<div className="u-mar--y--tny u-type--xmed u-type--wgt--medium u-type--left">
					{t("MAINTENANCE.TITLE")}
				</div>
				<div className="u-mar--bottom--xtny u-type--base u-type--left">
					{t("MAINTENANCE.CASHOUT_DATE_MESSAGE")}
				</div>
				{/* <div className="u-type--base u-type--left">{t("MAINTENANCE.DETAILS_MESSAGE")}</div> */}
			</div>
		</div>
	);
}
