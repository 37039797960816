import { useState } from "react";
import { useLoading } from "@hooks";
import { useNotification } from "../../../ui/common/hooks";
import { AccountStatementService } from "@v2/data-access/account-statement";
import {
	BetSlipEventInOffer,
	Params,
	TransactionTableResponse,
	UserActivityTypes,
} from "@v2/data-access/account-statement/AccountStatementService";
import { UserPaymentTransactionLimited } from "@api-types/account-settings/UserPaymentTransactionLimited";
import { addToUrlParams, logger } from "@state";
import { UserAccountType } from "@common/constants";
import ModalService from "@services/modals/ModalService";

export default function useAccountTransactions({
	statementFilter,
}: {
	statementFilter: Params | { bettingAccountTypeIds?: string };
}) {
	const { setIsLoading } = useLoading();
	const { showError } = useNotification();
	const [DetailsType, setDetailsType] = useState<DetailsTypeEnum>(0);
	const [slipDetails, setSlipDetails] = useState<
		BetSlipEventInOffer | UserPaymentTransactionLimited | null
	>(null);
	const [tableData, setTableData] = useState<TransactionTableResponse | null>(
		null
	);
	const [bettingAndPaymentTableData, setBettingAndPaymentTableData] =
		useState<null | UserActivityTypes>(null);

	async function fetchBettingAndPaymentTableData(filter: Params) {
		try {
			setIsLoading(true);

			const onlineWallet =
				App.state.rootStore.userAuthStore.userAccounts?.find(
					(type) =>
						type.abrv === UserAccountType.SportBettingAccountOnline
				);

			const shopOnlineWallet =
				App.state.rootStore.userAuthStore.userAccounts?.find(
					(type) =>
						type.abrv ===
						UserAccountType.SportBettingAccountShopOnline
				);
			const offlineWallet =
				App.state.rootStore.userAuthStore.userAccounts?.find(
					(type) =>
						type.abrv === UserAccountType.SportBettingAccountShop
				);

			const casinoWallet =
				App.state.rootStore.userAuthStore.userAccounts?.find(
					(type) => type.abrv === UserAccountType.CasinoAccount
				);

			const liveCasinoWallet =
				App.state.rootStore.userAuthStore.userAccounts?.find(
					(type) => type.abrv === UserAccountType.LiveCasinoAccount
				);

			const gigWallet =
				App.state.rootStore.userAuthStore.userAccounts?.find(
					(type) => type.abrv === UserAccountType.GigAccount
				);

			let activeWalletAbrv = null;

			if (filter.bettingAccountTypeIds === onlineWallet?.accountTypeId) {
				activeWalletAbrv = onlineWallet.abrv;
			} else if (
				filter.bettingAccountTypeIds === shopOnlineWallet?.accountTypeId
			) {
				activeWalletAbrv = shopOnlineWallet.abrv;
			} else if (
				filter.bettingAccountTypeIds === casinoWallet?.accountTypeId
			) {
				activeWalletAbrv = casinoWallet.abrv;
			} else if (
				filter.bettingAccountTypeIds === liveCasinoWallet?.accountTypeId
			) {
				activeWalletAbrv = liveCasinoWallet.abrv;
			} else if (
				filter.bettingAccountTypeIds === gigWallet?.accountTypeId
			) {
				activeWalletAbrv = gigWallet.abrv;
			} else {
				activeWalletAbrv = offlineWallet?.abrv;
			}

			const response =
				await AccountStatementService.getBettingAndPaymentTableData(
					filter,
					statementFilter.bettingAccountTypeIds,
					activeWalletAbrv
				);
			if (response) {
				setBettingAndPaymentTableData(response);
			}
		} catch (error) {
			showError("ACC_STATEMENT.TABLES." + error?.message);
		} finally {
			setIsLoading(false);
		}
	}

	async function fetchTransactionsTable(pageNr = 1) {
		try {
			setIsLoading(true);
			let response = undefined;
			if ("asPeriod" in statementFilter) {
				response =
					await AccountStatementService.fetchTransactionsTableData(
						statementFilter,
						pageNr
					);
			}
			if (response) {
				setTableData(response);
				addToUrlParams({
					...statementFilter,
					pageNumber: response.page,
				});
			}
		} catch (error) {
			setTableData(null);
			showError("ACC_STATEMENT.TABLES." + error?.message);
		} finally {
			setIsLoading(false);
			window.scrollTo(0, 0);
		}
	}

	const fetchSlipDetails = async (
		transactionId: string,
		detailType: DetailsTypeEnum,
		walletId = statementFilter.bettingAccountTypeIds
	) => {
		ModalService.onOpen();
		if (detailType == DetailsTypeEnum.BetSlipDetails) {
			try {
				setIsLoading(true);
				const response = await AccountStatementService.getSlipDetails(
					transactionId,
					walletId
				);
				if (response) {
					for (const key in response.betSlipOffers) {
						const offer = response.betSlipOffers[key];
						if (
							offer?.specifier?.player == null ||
							![
								"player-total-assists",
								"player-total-rebounds",
								"player-total-3-point-field-goals",
								"player-total-points",
								"player-total-rushing-yards",
								"player-total-passing-touchdowns",
								"player-total-passing-yards",
								"player-total-receiving-yards",
							].includes(offer.sportData.bettingTypeAbrv || "")
						) {
							continue;
						}

						offer.specifier.player =
							offer.playerFirstName +
							" " +
							offer.playerLastName +
							` (${
								offer.sportData.teamOneId === offer.teamId
									? offer.sportData.teamOneName
									: offer.sportData.teamTwoName
							})`;
						offer.playerId = null;
						offer.teamId = null;
					}

					setSlipDetails(response);
				}
			} catch (error) {
				logger.logError(error);
				setSlipDetails(null);
			} finally {
				setDetailsType(1);
				setIsLoading(false);
			}
		} else {
			try {
				setIsLoading(true);
				const response =
					await AccountStatementService.getTransactionDetails(
						transactionId,
						walletId
					);

				if (response) {
					setSlipDetails(response);
				}
			} catch (error) {
				setSlipDetails(null);
			} finally {
				setDetailsType(2);
				setIsLoading(false);
			}
		}
	};

	return {
		bettingAndPaymentTableData,
		fetchSlipDetails,
		tableData,
		DetailsType: DetailsType,
		setDetailsType: setDetailsType,
		slipDetails,
		fetchTransactionsTable,
		fetchBettingAndPaymentTableData,
	};
}

export class AccountTransactions {
	bettingAndPaymentTableData: UserActivityTypes | null;
	fetchBettingAndPaymentTableData: Function;
	fetchSlipDetails: Function;
	tableData: TransactionTableResponse | null;
	DetailsType: DetailsTypeEnum;
	slipDetails: BetSlipEventInOffer | UserPaymentTransactionLimited | null;
	setDetailsType: Function;
	fetchTransactionsTable: Function;
}

export enum DetailsTypeEnum {
	None,
	BetSlipDetails,
	TransactionDetails,
}
