import {
	WithdrawalDataDto,
	WithdrawalInitRequestDataDto,
} from "@api-types/withdrawal";
import {
	AccountTypes,
	getAgencyKey,
	getApplicationHeaders,
	httpClient,
} from "@utils";

export class WithdrawalApiService {
	//use only Online USER headers
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}/online-payments/payout`;
	}

	static getRequestHeaders = () => {
		//use only Online USER headers
		const internetUser =
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			);
		const appHeaders = getApplicationHeaders(internetUser);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		appHeaders["Accept" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getAvailableMethods(
		bettingAccountTypeId: string
	): Promise<WithdrawalDataDto> {
		return await httpClient.get(
			`${this.baseUrl}/available-methods?bettingAccountTypeId=${bettingAccountTypeId}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async initializeNewWithdrawalProcess(
		data: WithdrawalInitRequestDataDto
	): Promise<WithdrawalDataDto> {
		return await httpClient.post(
			`${this.baseUrl}/initialize`,
			JSON.stringify(data),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}
}
