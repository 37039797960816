import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isCapacitorPlatform } from "@utils/specific/capacitor";

export const CapacitorUpdateLoader = observer(function CapacitorUpdateLoader() {
	const { t } = useTranslation();

	if (!isCapacitorPlatform()) {
		return null;
	}

	if (!App.state.rootStore.capacitorUpdaterStore.isLoading) {
		return null;
	}

	return (
		<div className="loader loader--full">
			<div className="loader__spinner">
				<div className="loader__spinner__wrapper">
					<div />
					<div />
					<div />
				</div>
				<div className="loader__title">
					{t("CAPACITOR_UPDATE.DOWNLOAD_UPDATE")}
				</div>
			</div>
		</div>
	);
});
