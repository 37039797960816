import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { setTabTitle } from "@utils";

export default function useForcePasswordChange() {
	const loginStore = App.state.rootStore.userAuthStore.loginStore;
	const formCtx = useFormContext();
	const t = useTranslation().t;

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.FORCE_PASSWORD_CHANGE.TITLE_FPC"));
		document.querySelector("html, body")?.scrollTo(0, 0);
		loginStore.onInitializePasswordReset();
		return () => {
			loginStore.onDisposePwdReset();
		};
	}, []);

	useEffect(() => {
		if (loginStore.forcePwdResetData == null) {
			return;
		}

		formCtx.reset({
			userCardNumber: loginStore.forcePwdResetData.userInfo.userName,
		});
	}, [loginStore.forcePwdResetData?.userInfo?.userName]);

	return {
		handlePwdReset: loginStore.handlePwdForceResetSubmit,
		onCancel: loginStore.onCancelPwdForceReset,
		forcePasswordChangeData: loginStore.forcePwdResetData,
		hasError: loginStore.hasForcePwdResetError,
		clearError: loginStore.clearForcePwdError,
	};
}
