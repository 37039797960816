import { getDefaultCountryCodeForTheme } from "@utils";
import { ErrorMessage, RequiredMark } from "@v2/components/shared/inputs";
import { isPhoneValidOnRegistration } from "@v2/helpers/membership/registration/utils";
import classnames from "classnames";
import React from "react";
import { get } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput, { Country } from "react-phone-number-input";

export default function RegistrationPhone(props: {
	required?: boolean;
	labelClassName?: string;
}) {
	const translationHook = useTranslation();
	const {
		formState: { errors },
		getValues,
	} = useFormContext();

	const error = get(errors, "mobilePhone");

	const phoneInputClass = classnames("col form__field", {
		"input input--base input-validation-error": error != undefined,
	});

	const defaultCountryCode = getDefaultCountryCodeForTheme();

	return (
		<div className="form__field u-mar--bottom--reset">
			<label className="label label--strong">
				{translationHook.t<string>(
					"MEMBERSHIP.REGISTRATION.MOBILE_PHONE_LABEL"
				)}
				<RequiredMark required={props.required} />
			</label>

			<ErrorMessage name="mobilePhone" />
			<Controller
				name="mobilePhone"
				rules={{
					required: {
						value: props?.required || false,
						message: "COMMON.REQUIRED_FIELD",
					},
					validate: {
						isPhoneValidOnRegistration: (e) => {
							return isPhoneValidOnRegistration(e)
								? true
								: "USER.ACCOUNT_SETTINGS.PERSONAL_DATA.INVALID_PHONE";
						},
					},
				}}
				render={({ field }) => {
					return (
						<PhoneInput
							className={phoneInputClass}
							{...field}
							international
							defaultCountry={defaultCountryCode as Country}
							onChange={field.onChange}
							value={getValues("mobilePhone")}
						/>
					);
				}}
			/>
		</div>
	);
}
