import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Selector as BettingTypeSelector } from "@gp/offer";

import { constants } from "@gp/offer";

export const HeaderQuoteRow = observer(function HeaderQuoteRow(props: {
	columnSelector: BettingTypeSelector;
}) {
	const t = useTranslation().t;

	return (
		<div className="offer__type__row">
			{props.columnSelector.tips.map((tip) => {
				if (
					[
						constants.setnrPlaceholder,
						constants.gamenrPlaceholder,
						constants.marginPlaceholder,
						constants.framenrPlaceholder,
					].includes(tip)
				) {
					return (
						<div key={tip} className="offer__type__col">
							&nbsp;
						</div>
					);
				}

				return (
					<div key={tip} className="offer__type__col">
						{t<string>([`BETTING_TYPE.TIPS.${tip}`, tip])}
					</div>
				);
			})}
		</div>
	);
});
