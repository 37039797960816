import { observable, action, autorun } from "mobx";
import { StorageStateKeysEnum } from "@utils/storage";
import { isCapacitorPlatform } from "@utils/specific/capacitor";
import RootStore from "@state/stores/RootStore";

class ThemeStore {
	@observable isLightTheme: null | boolean = null;

	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		autorun(() => {
			if (this.isLightTheme === null) {
				return;
			}

			this.setTheme();
		});
	}

	@action.bound
	setInitialValue() {
		const themeLSValue = this.rootStore.localStorageProvider.get(
			StorageStateKeysEnum.THEME
		);

		if (themeLSValue == null && isCapacitorPlatform()) {
			//set system theme if theme key does not exist
			this.isLightTheme = !window.matchMedia(
				"(prefers-color-scheme: dark)"
			).matches;
		} else {
			this.isLightTheme = themeLSValue !== "dark";
		}
	}

	@action.bound
	toggleTheme() {
		this.isLightTheme = !this.isLightTheme;
	}

	@action.bound
	setTheme() {
		if (window.location !== window.parent.location) {
			//always set to dark theme if window is opened in iframe
			document.body.classList.remove("is-light");
			document.body.classList.add("is-dark");
			document.body.classList.add("cc--darkmode");
		} else if (this.isLightTheme) {
			document.body.classList.remove("is-dark");
			document.body.classList.add("is-light");
			document.body.classList.remove("cc--darkmode");
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.THEME,
				"light"
			);
		} else {
			document.body.classList.remove("is-light");
			document.body.classList.add("is-dark");
			document.body.classList.add("cc--darkmode");
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.THEME,
				"dark"
			);
		}
	}
}

export default ThemeStore;
