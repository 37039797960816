import { Response, ErrorResponse } from "../../shared";
import { isNullOrWhitespace } from "@utils";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));

class EmailActivationService {
	async emailActivation(mailkey, username) {
		if (isNullOrWhitespace(mailkey)) {
			throw new ErrorResponse({ message: "EMAIL_TOKEN_REQUIRED" });
		}

		try {
			const newEmail = await (
				await AgencyUserApiService
			).default.activateEmail({
				EmailChangeToken: mailkey,
				UserName: username,
			});
			if (!isNullOrWhitespace(newEmail)) {
				App.state.rootStore.userAuthStore.resolveUserEmail(newEmail);
				return new Response();
			}
			throw new ErrorResponse({ message: "EMAIL_ACTIVATE_ERROR" });
		} catch (error) {
			console.error(error);
			if (error?.data?.errorCode == 4000022004) {
				throw new ErrorResponse({
					message: "ACCOUNT_ALREADY_ACTIVATED",
				});
			} else if (error?.data?.errorCode == 4040020000) {
				throw new ErrorResponse({
					message: "CONFIRMATION_LINK_INVALID",
				});
			}
			throw new ErrorResponse({ message: "EMAIL_ACTIVATE_ERROR" });
		}
	}
}

export const emailActivationService = new EmailActivationService();
