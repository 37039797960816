import React, { useContext } from "react";
import { observer } from "mobx-react";

import classNames from "classnames";

import { OfferStoreContext } from "@gp/components";
import { EventKeyBettingOffer } from "@gp/models";
import { useRootOfferStore } from "@hooks/useRootOfferStore";
import OfferTipButton from "./OfferTipButton";

export const OfferTip = observer(function OfferTip(props: TOfferTipProps) {
	const offerStore = useContext(OfferStoreContext);
	const rootOfferStore = useRootOfferStore();

	const marginClasses = classNames("quote quote--margin", {
		"quote--margin--long":
			props.isMargin && (props.margin?.length || 0) > 8,
		"quote--margin--res": props.isResult && (props.result?.length || 0) > 8,
	});

	if (props.isMargin) {
		return (
			<div className={props.className}>
				<div className={marginClasses}>{props.margin}</div>
			</div>
		);
	}

	if (props.isResult) {
		return (
			<div className={props.className}>
				<div className={marginClasses}>{props.result}</div>
			</div>
		);
	}

	if (props.tip == undefined || !props.tip.value) {
		if (props.isGroupedOfferTip) {
			return (
				<div className="offer--additional__inline__item is-empty">
					<div className="quote quote--placeholder" />
				</div>
			);
		}

		return <div className="quote quote--placeholder" />;
	}

	const event = offerStore.eventsMap.get(props.tip.eventId);
	const eventKeys = offerStore.eventKeysMap.get(props.tip.eventId);
	const key = eventKeys?.get(props.tip.keyId);

	const isSelected = rootOfferStore.betSlipStore.isTipSelected(props.tip.id);

	return (
		<div className={props.className}>
			<OfferTipButton
				hasMargin={props.tipLabelHasMargin}
				value={props.tip.value}
				label={props.tip.displayTip}
				indicator={props.tip.indicator}
				displayTipName={props.displayTipName}
				isLocked={
					isSelected
						? false
						: event?.isLocked || key?.isLocked || props.tip.isLocked
				}
				onClick={() =>
					rootOfferStore.betSlipStore.addRemoveOffer({
						...props.tip,
						eventResultData: event?.result,
						matchTime: event?.matchTime,
					})
				}
				isSelected={isSelected}
				isOneClickBetActive={
					rootOfferStore.betSlipStore.isOneClickBetActive
				}
				isPlayerMarket={props.isPlayerMarket}
				isAdditionalOffer={props.isAdditionalOffer}
			/>
		</div>
	);
});

type TOfferTipProps = {
	className?: string;
} & (
	| {
			isMargin: true;
			margin?: string;

			isResult?: false;
			result?: never;
	  }
	| {
			isMargin?: false;
			margin?: never;

			isResult: true;
			result: string;
	  }
	| {
			isMargin?: false;
			margin?: never;

			isResult?: false;
			result?: never;

			tipLabelHasMargin?: boolean;
			tip?: EventKeyBettingOffer;
			displayTipName?: string;
			isGroupedOfferTip?: boolean;
			isPlayerMarket?: boolean;
			isAdditionalOffer?: boolean;
	  }
);
