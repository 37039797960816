import { useRootAppStore } from "@hooks";
import { UserTypes, getUserFromLocalStorage } from "@utils";
import { useTranslation } from "react-i18next";

export function useDocumentExpiration() {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();

	if (rootStore == null) {
		throw Error("should be used in components");
	}

	const offlineUser = getUserFromLocalStorage(
		rootStore.userAuthStore.isPrimaryUserOnline
			? UserTypes.SECONDARY
			: UserTypes.PRIMARY
	);

	if (
		!offlineUser?.userDocumentDateNotSet &&
		!offlineUser?.userDocumentDateExpiresSoon &&
		!offlineUser?.userDocumentDateExpired
	) {
		return null;
	}

	let message;

	if (offlineUser?.userDocumentDateNotSet) {
		message = t("DOCUMENT_EXPIRATION.DATE_NOT_SET");
	} else if (offlineUser?.userDocumentDateExpiresSoon) {
		message = t("DOCUMENT_EXPIRATION.DATE_EXPIRE_SOON");
	} else {
		message = t("DOCUMENT_EXPIRATION.BETTING_EXPIRED");
	}

	return {
		message,
	};
}
