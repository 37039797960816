import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { EmailPattern } from "@common/constants";
import useEmailChange from "@v2/hooks/account-settings/email-change/useEmailChange";

export default function EmailChangeNewEmail() {
	const newEmail = useWatch({ name: "newEmail" });

	const { checkEmail } = useEmailChange();

	useEffect(() => {
		checkEmail(newEmail);
	}, [newEmail]);

	return (
		<FormInputField
			name="newEmail"
			label="USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.NEW_EMAIL_LABEL"
			className="col col-sml-12 form__field"
			placeholder="USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.NEW_EMAIL_PLACEHOLDER"
			pattern={{
				value: EmailPattern,
				message: "USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.INVALID_EMAIL",
			}}
			maxLength={150}
			required
		/>
	);
}
