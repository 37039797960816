import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DocumentUploadForm } from ".";
import { setTabTitle } from "@utils";
import {
	UseDocumentUpload,
	useDocumentUpload,
} from "@v2/hooks/Account-verification";
import { InsicWidget } from "@v2/components/shared";

export default function AccountVerificationPage(): JSX.Element {
	const documentUploadData = useDocumentUpload();
	const { t } = useTranslation();

	useEffect(() => {
		setTabTitle(t("ACCOUNT_VERIFICATION.TITLE"));
		window.scrollTo(0, 0);

		documentUploadData.fetchData();
	}, []);

	return (
		<>
			<section className="l--content l--content--full">
				<div className="l--content__main">
					<div className="container">
						<Body documentUploadData={documentUploadData} />
					</div>
				</div>
			</section>
		</>
	);
}

function Body({
	documentUploadData,
}: {
	documentUploadData: UseDocumentUpload;
}): JSX.Element {
	if (documentUploadData.status == null) {
		return (
			<div className="card--primary">
				<Header />
			</div>
		);
	}

	if (documentUploadData.status.shouldDisplayExternalWidget) {
		if (
			documentUploadData.status.widget == null ||
			documentUploadData.status.widget.widgetParameters == null
		) {
			return (
				<div className="card--primary">
					<Header />
				</div>
			);
		}
		return (
			<div className="card--primary">
				<Header />
				<InsicWidget status={documentUploadData.status.widget} />
			</div>
		);
	}

	return (
		<DocumentUploadForm
			documentUploadData={{
				...documentUploadData,
				status: documentUploadData.status,
			}}
		/>
	);
}

export function Header() {
	const { t } = useTranslation();

	return (
		<div className="card--primary__header">
			<div className="card--primary__title title--primary">
				{t("ACCOUNT_VERIFICATION.TITLE")}
			</div>
		</div>
	);
}
