import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { OfferStoreContext, LookupContext, EventContext } from "@gp/components";

import {
	StatisticsButton,
	TvCoverageButton,
	WidgetToggleButton,
	LiveStreamToggleButton,
	ChatButton,
} from "../../../buttons";

import { BackButton } from ".";
import { LiveEventSideMenuContext } from "@v2/context";
import FavoritesButton from "../../../buttons/FavoritesButton";
import OfferIcon from "@v2/components/shared/icons/Offer";

import { BetsList } from "@v2/components/desktop/my-bets/Body";
import MyBetsToggleButton from "../../../buttons/MyBetsToggleButton";
import { ChannelResourceTypeDto } from "@api-types/chat";
import { useRootOfferStore } from "@hooks";
import { BrWidgetOtherConfig } from "@features/live-widgets/shared";

// eslint-disable-next-line no-undef
const isLMTEnabled = BrWidgetOtherConfig;

export const EventHeader = observer(function EventHeader() {
	// @ts-expect-error
	const { isMultiEvent } = useContext(OfferStoreContext);

	const event = useContext(EventContext);

	//#region css classes

	const headerClasses = classNames("event__header", {
		"event__header--borderless": !isLMTEnabled,
	});

	const headerInfoClasses = classNames("event__header__info", {
		"event__header__info--spaced": !isMultiEvent,
		"event--multi__info": isMultiEvent,
	});

	const headerActionClasses = classNames("event__actions", {
		"event--full__actions": !isMultiEvent,
		"event--multi__actions u-mar--left--tny": isMultiEvent,
	});

	//#endregion css classes

	return (
		<div className={headerClasses}>
			<div className={headerInfoClasses}>
				{isMultiEvent ? <EventHeaderTitle /> : <BackButton />}

				<div className={headerActionClasses}>
					<FavoritesButton />
					<StatisticsButton />
					<TvCoverageButton />
					<WidgetToggleButton />
					<LiveStreamToggleButton />
					<ChatButton
						channelType={ChannelResourceTypeDto.EVENT}
						resourceId={event.id}
					/>
					<MyBetsToggleButton />
				</div>
			</div>

			<CloseButton />
		</div>
	);
});

const EventHeaderTitle = function EventHeaderTitle() {
	const event = useContext(EventContext);

	const { teams } = useContext(LookupContext);

	const homeTeam = teams.get(event.teamOneId || "");
	const awayTeam = teams.get(event.teamTwoId || "");

	return (
		<div className="event__teams event--multi__teams">
			<OfferIcon
				type="sport"
				// @ts-expect-error
				sport={event.sport.abrv}
				className="event__teams__icon u-icon u-icon--xmed"
			/>
			<span className="event__teams__name">{homeTeam?.name}</span>
			<span className="u-mar--x--xtny">vs</span>
			<span className="event__teams__name">{awayTeam?.name}</span>
		</div>
	);
};

const CloseButton = function CloseButton() {
	// @ts-expect-error
	const { isMultiEvent } = useContext(OfferStoreContext);

	const event = useContext(EventContext);

	const liveEventMenuViewStore = useContext(LiveEventSideMenuContext);

	const rootOfferStore = useRootOfferStore();

	if (!isMultiEvent) {
		return null;
	}

	return (
		<button
			type="button"
			className="event__close btn btn--square btn--square--xtny btn--secondary--inverted"
			onClick={() => {
				liveEventMenuViewStore.removeEvent(event.id);
				rootOfferStore.myBetsViewStore.cashoutStore.disposeSubscription(
					event.id
				);
			}}
		>
			<i className="u-icon u-icon--xsml u-icon--close" />
		</button>
	);
};
