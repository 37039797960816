import { useUser } from "@v2/hooks/shared";
import { useState } from "react";
import { DateTime } from "luxon";
import { isUserAuthenticated, getCurrentCulture, UserTypes } from "@utils";
import { realityCheckService } from "@services/reality-check/RealityCheckService";
import { UserAuthStore } from "@v2/state";
import { RealityCheckDto } from "@data-types/reality-check/RealityCheckDto";

export default function useRealityCheckPopup() {
	const [fetchedData, setFetchedData] = useState<RealityCheckDto>();
	const [timePlayedData, setTimePlayedData] = useState<{
		hours: number;
		minutes: number;
	} | null>(null);
	const userData: UserAuthStore = useUser();

	function timeConvert(n: number) {
		const num = n;
		const hours = num / 60;
		const rhours = Math.floor(hours);
		const minutes = (hours - rhours) * 60;
		const rminutes = Math.round(minutes);
		return {
			hours: rhours,
			minutes: rminutes,
		};
	}

	const logout = async (): Promise<void> => {
		if (isUserAuthenticated()) {
			await realityCheckService.resolveRealityCheckData(
				userData.user?.id || "",
				true
			);
			await App.state.rootStore.userAuthStore.logoutUser({
				isRealityCheckLogout: true,
				isAutomaticLogout: false,
			});
		}
		App.state.rootStore.stopSession();
		const homeUrlLink = `/${getCurrentCulture()}/home/full/highlights`;
		if (window.location.pathname !== homeUrlLink) {
			App.state.redirect(homeUrlLink);
		}
	};

	const resume = async (): Promise<void> => {
		if (!isUserAuthenticated()) {
			await App.state.rootStore.userAuthStore.logoutUser();
			App.state.rootStore.stopSession();

			const returnURL = window.location.pathname.slice(3);
			const redirectURL = `/${getCurrentCulture()}/auth/login?returnUrl=${returnURL}&isSessionExpire=true`;

			App.state.redirect(redirectURL);
		} else {
			//extend play time
			const newRealityCheckValue = DateTime.now()
				.plus({
					minutes:
						App.state.rootStore.userAuthStore.user
							?.realityCheckDuration ||
						WEBPACK_REALITY_CHECK_INTERVAL,
				})
				.toMillis();

			App.state.rootStore.userAuthStore.setRealityCheckTime(
				newRealityCheckValue
			);
			await realityCheckService.resolveRealityCheckData(
				userData.user?.id || ""
			);
			const currentDate = new Date();
			const expiresIn =
				(App.state.rootStore.userAuthStore.realityCheckTime ||
					new Date().getTime()) - currentDate.getTime();

			if (expiresIn != null && !isNaN(expiresIn)) {
				App.state.rootStore.realityCheckStore.startTimer(expiresIn);
				App.state.rootStore.realityCheckStore.deactivatePopup();
			}
		}
	};

	const onInitialize = async (): Promise<void> => {
		try {
			const primaryResponse =
				await realityCheckService.getRealityCheckData(
					UserTypes.PRIMARY
				);

			let responseData = new RealityCheckDto();

			let secondaryResponse = null;

			if (App.state.rootStore.userAuthStore.secondaryUser != null) {
				secondaryResponse =
					await realityCheckService.getRealityCheckData(
						UserTypes.SECONDARY
					);
			}

			if (App.state.rootStore.userAuthStore.isPrimaryUserOnline) {
				responseData = {
					casinoInfo: primaryResponse.casinoInfo,
					casinoLiveInfo: primaryResponse.casinoLiveInfo,
					sportBettingOnlineInfo: primaryResponse.sportBettingInfo,
					timePlayed: primaryResponse.timePlayed,
				};
				if (secondaryResponse != null) {
					responseData.sportBettingInfo =
						secondaryResponse.sportBettingInfo;
				}
			} else {
				responseData = {
					casinoInfo: primaryResponse.casinoInfo,
					casinoLiveInfo: primaryResponse.casinoLiveInfo,
					sportBettingInfo: primaryResponse.sportBettingInfo,
					timePlayed: primaryResponse.timePlayed,
				};
				if (secondaryResponse != null) {
					responseData.sportBettingOnlineInfo =
						secondaryResponse.sportBettingInfo;
				}
			}

			if (responseData != null) {
				setFetchedData(responseData);
				setTimePlayedData(timeConvert(responseData.timePlayed));
			}
		} catch (error) {
			console.error(error);
		}
	};

	return {
		timeConvert,
		onInitialize,
		resume,
		logout,
		fetchedData,
		timePlayedData,
	};
}
