import { observable, action, computed } from "mobx";
import { BettingOfferManagementHub } from "@gp/hub";
import { MenuSubscriptionResponse } from "@gp/models";

import RootOfferStore from "@offer/stores/RootStore";
import { LoaderStore } from "../../../../state/stores/common";

import NodeStore from "./node/NodeStore";
import {
	FormattedUrlSegments,
	FormattedUrlSegmentsNode,
} from "./node/NodeTypes";
import { SportMenuNodeType, FormattedMenuNode } from "./SportNodeType";

export type TRootStore = {
	hub: RootOfferStore["hub"];
	isSideMenuExpanded: boolean;
	openSideMenu: () => void;
	closeSideMenu: () => void;
};

export class BaseOfferMenuStore {
	loader: LoaderStore;
	rootStore: TRootStore;
	hub: BettingOfferManagementHub;
	selectedSport: this["menu"] | null;

	//#region observable

	@observable menu = new NodeStore<SportMenuNodeType>({
		id: "",
		count: 0,
		type: "root",
		name: "",
	});
	@observable isStoreInitialized = false;
	@observable isFetchingData = false;

	//#endregion observable

	//#region computed

	@computed public get isLoading() {
		return this.loader.isLoading;
	}

	@computed get isEmpty() {
		return (
			this.menu.children.length === 0 &&
			!this.isFetchingData &&
			this.isStoreInitialized
		);
	}

	//#endregion computed

	//#region constructor

	constructor(rootStore: TRootStore) {
		this.rootStore = rootStore;
		this.hub = rootStore.hub;
		this.loader = new LoaderStore();
	}

	//#endregion constructor

	//#region fetching data

	@action.bound
	async onInitialize() {
		this.onStartFetching();
		this.onBeforeInitialize();

		// If we mount and action is not history back clear selected data
		if (App.state.history.action !== "POP") {
			this.resetCheckedItems();
		}

		await this.fetchData();
	}

	async fetchData(arg0?: () => any, fetchData?: any): Promise<any> {
		throw new Error("Method not implemented.");
	}

	/**
	 * Implement this in a subclass to perform actions before store is initialized
	 */
	@action.bound
	onBeforeInitialize() {
		// To be overridden by subclass.
	}

	@action.bound
	onStartFetching() {
		this.isFetchingData = true;
		this.loader.suspend();
	}

	@action.bound
	onDoneFetching() {
		this.isStoreInitialized = true;
		this.isFetchingData = false;
		this.loader.resume();
	}

	@action.bound
	assignMenu(rawMenu: MenuSubscriptionResponse["menu"]) {
		const formattedMenu: FormattedMenuNode[] = rawMenu.map(
			(sportLevel) => ({
				n: {
					...sportLevel.sport,
					count: sportLevel.count,
					type: "sport",
				},
				c: sportLevel.subItems.map((categoryLevel) => ({
					n: {
						...categoryLevel.sportCategory,
						count: categoryLevel.count,
						type: "category",
					},
					c: categoryLevel.subItems.map((tournamentLevel) => ({
						n: {
							...tournamentLevel.tournament,
							id:
								tournamentLevel.tournament.abrv ===
								"special-offer"
									? "special"
									: tournamentLevel.tournament.id,
							count: tournamentLevel.count,
							type: "tournament",
						},
						c: [],
					})),
				})),
			})
		);

		this.menu.assignStructure({
			n: {
				isRoot: true,
				id: "root",
				count: formattedMenu.length,
				type: "root",
				name: "root",
			},
			c: formattedMenu,
		});
	}

	@action.bound
	protected selectFirstSportOnFirstResponse(isFirst?: boolean) {
		if (
			!this.isStoreInitialized && // Do this only once on first data load
			this.menu.checkState === 0 && // IF menu has nothing selected select first child
			// Else there is previous selection

			this.menu?.children?.length > 0 // If menu has no children there is nothing to select
		) {
			this.selectFirstSport();
		}
	}

	@action.bound
	selectFirstSport() {
		this.selectedSport = this.menu?.children?.[0];
		this.selectedSport?.onCheck(true);
	}

	//#endregion fetching data

	//#region actions

	@action.bound
	resetCheckedItems() {
		this.menu.onCheck(false);
	}

	//#endregion actions

	//#region disposers

	@action.bound
	onBaseDispose() {
		this.isStoreInitialized = false;
	}

	//#endregion disposers

	//#region reset menu on url segments

	/**
	 * @param {string} segments menu check state encoded in url form
	 *
	 * Sets menu check state based on ids in segments
	 */
	@action.bound
	resetMenuOnUrlParamsFromRouter(
		segments?: string | null,
		isFilteredSportMenu?: boolean
	) {
		if (segments != null) {
			this.selectInMenuFromUrlSegments(segments, isFilteredSportMenu);
		} else {
			this.menu.onCheck(false);
		}
	}

	@action.bound
	selectInMenuFromUrlSegments(
		segments?: string,
		isFilteredSportMenu?: boolean
	): void {
		if (segments == null) {
			return;
		}

		const formattedSegments = this.formatUrlSegments(
			segments,
			isFilteredSportMenu
		);

		if (formattedSegments != null) {
			this.selectInMenuFromFormattedSegments(formattedSegments);
		}
	}

	@action.bound
	protected selectInMenuFromFormattedSegments(
		formattedSegments: FormattedUrlSegments
	): void {
		if (formattedSegments == null) {
			return;
		}

		this.menu.resetCheckState(formattedSegments);
	}

	protected formatUrlSegments = (
		segments: string,
		isFilteredSportMenu?: boolean
	): FormattedUrlSegments | undefined => {
		if (segments == null) {
			return;
		}

		const formattedSegments: FormattedUrlSegmentsNode[] = segments
			.split("/")
			.map((sportSegment) => {
				const sportSegments = sportSegment.split("|");
				const sportId = sportSegments.shift() as string;

				const sport: FormattedUrlSegmentsNode = {
					id: sportId,
					children: [],
				};

				sport.children = sportSegments.map((catSegment) => {
					const catSegments = catSegment.split("-");
					const catId = catSegments.shift() as string;

					const category: FormattedUrlSegmentsNode = {
						id: catId,
						children: [],
					};

					category.children = catSegments.map((tSegment) => ({
						id: tSegment,
					}));

					if (category.children.length === 0) {
						category.children = undefined;
					}
					return category;
				});

				if (sport.children.length === 0) {
					sport.children = undefined;
				}
				return sport;
			});

		return { id: "root", children: formattedSegments };
	};

	//#endregion reset menu on url segments
}
