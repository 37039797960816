import {
	httpClient,
	getAgencyKey,
	getApplicationHeaders,
	UserTypes,
} from "@utils";
import { LimitCategories } from "@api-types";

// TODO: rename class and file (wrong sufix, should be ApiService)
export class GamingLimitCategoryService {
	static baseUrl(activeAccount: UserTypes = UserTypes.PRIMARY): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			activeAccount
		)}/gaming-limit-categories`;
	}

	static getRequestHeaders = (
		activeAccount: UserTypes = UserTypes.PRIMARY
	) => {
		const appHeaders = getApplicationHeaders(activeAccount);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	//TODO: switch params (activeaccount then category id), change also where this is used
	static getAvailableCategories(
		activeAccount: UserTypes = UserTypes.PRIMARY,
		categoryId?: string | null
	): Promise<LimitCategories> {
		let apiUrl;

		if (categoryId == null) {
			apiUrl = `${this.baseUrl(
				activeAccount
			)}/available-categories?translate=true`;
		} else {
			apiUrl = `${this.baseUrl(
				activeAccount
			)}/available-categories/?categoryId=${categoryId}&translate=true`;
		}

		return httpClient.get(apiUrl, this.getRequestHeaders(activeAccount), {
			responseType: "json",
		});
	}
}
