import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import useCurrency from "@v2/hooks/shared/useCurrency";
import GamingHistoryStoreContext from "@v2/context/GamingHistoryStoreContext";
import { isGamingHistoryReportEnabled } from "@v2/helpers";

export default observer(function GamingHistoryReport() {
	const {
		gamingHistoryReport: { gamingHistoryReport },
	} = useContext(GamingHistoryStoreContext);

	const { t } = useTranslation();

	if (gamingHistoryReport == null || !isGamingHistoryReportEnabled) {
		return null;
	}
	return (
		<>
			<ProfitPaymentInfoTable />
		</>
	);
});

const ProfitPaymentInfoTable = observer(function ProfitPaymentInfoTable() {
	const { t } = useTranslation();

	const {
		gamingHistoryReport: { from, to },
	} = useContext(GamingHistoryStoreContext);

	return (
		<table className="table--primary u-mar--bottom--xtny no-print">
			<caption className="table--primary__caption">
				{t<string>("GAMING_HISTORY.REPORTS.PROFIT_PAYMENT_RATIO", {
					0: from,
					1: to,
					interpolation: { escapeValue: false },
				})}
			</caption>
			<BetsReportsTableHead />
			<BetsReportTableData />
		</table>
	);
});

function BetsReportsTableHead() {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	return (
		<thead className="table--primary__header">
			<tr className="table--primary__header__row">
				<th className="table--primary__header__data u-type--left">
					{t<string>("GAMING_HISTORY.REPORTS.GAME_ROUNDS")}
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("GAMING_HISTORY.REPORTS.PAYMENT")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("GAMING_HISTORY.REPORTS.NET_GAIN")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("GAMING_HISTORY.REPORTS.LOSS")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("GAMING_HISTORY.REPORTS.PROFIT")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("GAMING_HISTORY.REPORTS.PROFIT")}/
					{t<string>("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_PAYMENT")}{" "}
					(%)
				</th>
			</tr>
		</thead>
	);
}

const BetsReportTableData = observer(function BetsReportTableData() {
	const store = useContext(GamingHistoryStoreContext);
	const gamingHistoryReport = store.gamingHistoryReport.gamingHistoryReport;

	if (gamingHistoryReport == null) {
		return null;
	}

	const { GameRounds, Payment, NetGain, Loss, Profit, ProfitPercentage } =
		gamingHistoryReport;

	return (
		<tbody className="table--primary__body">
			<tr className="table--primary__body__row">
				<td className="table--primary__body__data u-type--left">
					{GameRounds}
				</td>
				<td className="table--primary__body__data u-type--right">
					{Payment.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						NetGain
					)}`}
				>
					{NetGain.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						Loss
					)}`}
				>
					{Loss.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						Profit
					)} `}
				>
					{Profit.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						ProfitPercentage
					)} `}
				>
					{ProfitPercentage.toFixed(2)}
				</td>
			</tr>
		</tbody>
	);
});

//#endregion details

/**
 * @parm {number} number value for which to get indicator className name
 * @returns {string} indicator className name
 */
function repIndicCss(number: number) {
	if (number < 0) {
		return "u-color--ui--danger";
	}

	if (number > 0) {
		return "u-color--ui--success";
	}

	return "";
}
