import { DateTime } from "luxon";
import { UserStatus } from "./LoginResponseDto";

// properties in comments require embed - will be added if needed
export class AgencyUserDto {
	// agency: Agency;
	agencyId: string;
	// autoLogoutPeriod: AutoLogoutPeriod;
	autoLogoutPeriodId?: string;
	autoLogoutPeriodStartDate?: string;
	// bettingTimeout: BettingTimeout;
	bettingTimeoutId?: string;
	bettingTimeoutStartDate?: string;
	bIC: string;
	birthName: string;
	birthPlace: string;
	city: string;
	// country: Country;
	countryId: string | null;
	customerCardId: string;
	dateOfBirth: string;
	documentExpiration?: null | string;
	email: string;
	firstName: string;
	houseBox: string;
	houseNumber: string;
	iBAN: string;
	iPAddress: string;
	isAdminLock: boolean;
	isAgencyWideCashierEnabled?: boolean;
	isApproved: boolean;
	isLockedOut: boolean;
	isPep?: boolean;
	playerPromoSendingMode: PlayerPromoSendingMode;
	isValid: boolean;
	json: string;
	lastBettingReviewCheckDate?: DateTime;
	lastLoginDate: Date;
	lastName: string;
	lastPepCheckDate?: DateTime;
	mailVerificationStatus?: number;
	mobilePhone: string;
	nationality?: string;
	numericalId: number;
	passportId: string;
	payPin: string;
	personalIdentificator: string;
	pin: string;
	profession: string;
	regionId?: string;
	// roles: Role[];
	secondAddressReason: string;
	secondCity: string;
	secondCountryId?: string;
	secondHouseNumber: string;
	secondStreet: string;
	secondZipCode: string;
	cultureInfoName: string;
	shopId: string;
	street: string;
	telephoneNumber: string;
	titleId: string;
	// userAccountLockDurationType: UserAccountLockDurationType;
	userAccountLockDurationTypeId?: string;
	userAccountLockEndDate?: DateTime;
	userAccountLockExplanation: string;
	// userAccountLockReasonType: UserAccountLockReasonType;
	userAccountLockReasonTypeId?: string;
	userAccountLockStartDate?: DateTime;
	// userAccountRelationMatchStatus: UserAccountRelationMatchStatus;
	// userAccountType: UserAccountType;
	userAccountTypeId?: string;
	userId?: string;
	userMonitorStartDate?: DateTime;
	// userMonitorType: UserMonitorType;
	userMonitorTypeId: string;
	userName: string;
	// userTitle: UserTitle;
	validationDate?: DateTime;
	zipCode: string;
	insicStatus: UserStatus;
	occupationId: string;
}

export enum PlayerPromoSendingMode {
	disabled,
	enabled,
	forbidden,
}
