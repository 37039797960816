import React from "react";
import { useTranslation } from "react-i18next";

export function BonusMessage(props: { message: string; verified?: string }) {
	const { t } = useTranslation();

	return (
        <div className="message message--base message--note u-mar--bottom--med">
            <i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
            <div className="message__content">
                <div className="message__text">
                    {t<string>(props.message)}
                </div>
                
                {props.verified && (
                    <div className="message__text u-mar--top--xsml">{t<string>(`USER.ACCOUNT_SETTINGS.MY_BONUSES.${props.verified}`)}</div>
                )}
            </div>
        </div>
	);
}