import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Button } from "@components/buttons";
import {
	BettingActivityLoader,
	BettingActivityError,
	AccountActivitySection,
	BettingActivitySection,
} from ".";
import { useBettingActivity } from "@v2/hooks/membership";
import { setTabTitle } from "@utils";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default observer(function BettingActivityPage() {
	const { t } = useTranslation();
	const { updateBettingActivityReview, logoutUser } = useBettingActivity();

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.BETTING_ACTIVITY.TITLE"));
		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
	}, []);

	const bettingActivityStore =
		App.state.rootStore.userAuthStore.bettingActivityStore;

	if (bettingActivityStore.bettingActivityFetchedData == null) {
		return null;
	}

	const { userActivity, bettingActivity } =
		bettingActivityStore.bettingActivityFetchedData;

	if (bettingActivityStore.isLoading) {
		return <BettingActivityLoader />;
	}

	if (bettingActivityStore.bettingActivityError != null) {
		return <BettingActivityError logoutUser={logoutUser} />;
	}

	return (
		<div className="card--primary">
			<div className="card--primary__header">
				<h1 className="card--primary__title title--primary">
					{t<string>("MEMBERSHIP.BETTING_ACTIVITY.TITLE")}
				</h1>
			</div>
			<div className="card--primary__body">
				<InfoMessageComponent
					type="note"
					message={t<string>(
						"MEMBERSHIP.BETTING_ACTIVITY.DESCRIPTION"
					)}
				/>

				{userActivity && userActivity.length != 0 && (
					<AccountActivitySection userActivity={userActivity} />
				)}

				{bettingActivity && bettingActivity.length != 0 && (
					<BettingActivitySection bettingActivity={bettingActivity} />
				)}
			</div>

			<div className="card--primary__footer">
				<div className="card--primary__footer__inner u-type--left">
					<Button
						className="card--primary__footer__btn btn btn--lrg btn--primary"
						btnText="MEMBERSHIP.BETTING_ACTIVITY.BUTTON_CONTINUE"
						onClick={updateBettingActivityReview}
					/>
					<Button
						className="card--primary__footer__btn btn btn--lrg btn--secondary--inverted"
						btnText="MEMBERSHIP.BETTING_ACTIVITY.BUTTON_CANCEL"
						btnType="button"
						onClick={logoutUser}
					/>
				</div>
			</div>
		</div>
	);
});
