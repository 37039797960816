import React, { Suspense, useState } from "react";
import { NotificationContext } from "@shared/context";
import DesktopPopupMessage from "@v2/components/mobile/shared/popups/PopupMessage";
import MobilePopupMessage from "@v2/components/mobile/shared/popups/PopupMessage";
import { DeviceDetector } from "@utils";
import Loader from "@v2/components/mobile/shared/loader/Loader";

function NotificationContainer(props: { children: JSX.Element }) {
	const [notification, setNotification] = useState(null);

	const contextValue = {
		showNotification: (data) => {
			setNotification(data);
		},
		notification: notification,
	};
	return (
		<Suspense fallback={<Loader isLoading />}>
			<NotificationContext.Provider value={contextValue}>
				{DeviceDetector.isDesktopTheme ? (
					<DesktopPopupMessage />
				) : (
					<MobilePopupMessage />
				)}
				{props.children}
			</NotificationContext.Provider>
		</Suspense>
	);
}

export default NotificationContainer;
