import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
	ErrorMessage,
	RequiredMark,
} from "@v2/components/desktop/shared/inputs";
const ReactMarkdown = React.lazy(() => import("react-markdown"));

export default function RegistrationPlayersMoneyProtection() {
	const { t } = useTranslation();

	return (
		<div className="form__group">
			<CheckboxInput
				id="protectionOfPlayerMoneyCheckbox"
				name="acceptProtectionOfPlayerMoney"
			/>
		</div>
	);
}

function CheckboxInput(props: { id: string; name: string }) {
	const { name, id } = props;

	const { t } = useTranslation();
	const { register } = useFormContext();

	const field = register(name, {
		required: {
			value: true,
			message: "COMMON.REQUIRED_FIELD",
		},
	});

	return (
		<div>
			<ErrorMessage name={name} />
			<input
				id={id}
				className="input--check input--check--sml"
				type="checkbox"
				{...field}
			/>
			<label
				htmlFor={id}
				className="u-display--f u-type--base u-color--text--secondary"
			>
				<ReactMarkdown
					linkTarget={"_blank"}
					className="u-anchor--nested"
				>
					{t("MEMBERSHIP.REGISTRATION.PLAYERS_MONEY_PROTECTION")}
				</ReactMarkdown>
			</label>
		</div>
	);
}
