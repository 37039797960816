import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import classNames from "classnames";

import { Modal } from "@v2/components/shared";

import { CashoutType } from "@gp/hub";
import { ISOToDisplayCode } from "@utils";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";
import { MyBetsBetContext, MyBetsStoreContext } from "@v2/context";
import { logger } from "@state";
import { OpenCashoutModalType } from "@administration/pages/my-bets/model";
import { useRootOfferStore } from "@hooks";
import { EventContext } from "@gp/components";

export default observer(function BetItemCashout(props: { isMini?: boolean }) {
	const { isMini } = props;

	const bet = useContext(MyBetsBetContext);
	const store = useRootOfferStore().myBetsViewStore;

	const {
		cashoutStore: { isCashoutModalOpen, cashoutModalData },
	} = store;

	if (
		bet.cType !== CashoutType.JACKTIME ||
		bet.displayedAmount == null ||
		Number(bet.displayedAmount) < 1
	) {
		return null;
	}

	if (isCashoutModalOpen && cashoutModalData?.id === bet.id) {
		if (cashoutModalData.message != null) {
			return <CashoutMessageModal />;
		}
		return <CashoutConfirmationForm />;
	}

	return <StartCashoutProcessButton isMini={isMini} />;
});

const StartCashoutProcessButton = observer(
	function StartCashoutProcessButton(props: { isMini?: boolean }) {
		const { isMini } = props;
		const bet = useContext(MyBetsBetContext);
		const store = useRootOfferStore().myBetsViewStore;
		const event = useContext(EventContext);

		if (bet.cType == undefined) {
			console.error("cType can not be null");
			return null;
		}
		if (bet.displayedAmount == undefined) {
			console.error("displayedAmount can not be null");
			return null;
		}

		const cType = bet.cType;
		const displayedAmount = bet.displayedAmount;

		const {
			cashoutStore: { openEventCashoutModal, isCashoutFormSubmitting },
		} = store;

		const wrapperClasses = classNames("bet__actions__col col col-sml-6", {
			"is-full": !isMini,
		});

		const buttonClasses = classNames(
			{
				"bet__btn bet__btn--cashout": !isMini,
				"bet--mini__btn": isMini,
			},
			"btn btn--base btn--block btn--cashout",
			{
				"btn--icon inc": bet.indicator > 0,
				"btn--icon dec": bet.indicator < 0,
			}
		);

		return (
			<div className={wrapperClasses}>
				<button
					className={buttonClasses}
					type="button"
					disabled={isCashoutFormSubmitting}
					onClick={() =>
						openEventCashoutModal(
							{
								id: bet.id,
								calculatedCashoutAmount:
									bet.calculatedCashoutAmount,
								cType: cType,
								isChangeAccepted: false,
								isLive: bet.isLive,
								displayedAmount: displayedAmount,
							},
							event.id
						)
					}
				>
					<CashOutIndicator />
					<CashoutButtonCore />
				</button>
			</div>
		);
	}
);

const CashOutIndicator = observer(function CashOutIndicator() {
	const { indicator } = useContext(MyBetsBetContext);

	if (indicator === 0) {
		return null;
	}

	const indicatorClasses = classNames("u-icon u-icon--xsml", {
		"u-icon--caret--up u-color--ui--success": indicator === 1,
		"u-icon--caret--down u-color--ui--danger": indicator === -1,
	});

	return <i className={indicatorClasses} />;
});

const CashoutButtonCore = observer(function CashoutButtonCore() {
	const { displayedAmount } = useContext(MyBetsBetContext);
	const { t } = useTranslation();

	return (
		<span>
			{t<string>("MY_BETS.ITEM.CASHOUT")} {displayedAmount}{" "}
			{ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)}
		</span>
	);
});

const CashoutConfirmationForm = observer(function CashoutConfirmationForm() {
	const { t } = useTranslation();
	const methods = useForm();
	const { register, handleSubmit } = methods;
	const bet = useContext(MyBetsStoreContext);

	if (bet == null || bet.type != "MyBetsStore") {
		logger.logError("Expected MyBetsStore got something else.");
		return null;
	}

	const {
		cashoutStore: {
			cashout,
			closeCashoutModal,
			cashoutModalData,
			//@ts-expect-error
			isAutoSellEnabled,
		},
	} = bet;

	const onSubmit = (data: { isChangeAccepted: boolean }) => {
		const obj: OpenCashoutModalType = {
			calculatedCashoutAmount: 0,
			cType: CashoutType.NO_CACHE_OUT,
			displayedAmount: "",
			id: "",
			isLive: false,
			message: "",
		};
		if (cashoutModalData?.displayedAmount != undefined) {
			obj.displayedAmount = cashoutModalData.displayedAmount;
		} else {
			console.error("Displayed Amount can not be undefined");
			return;
		}
		if (cashoutModalData?.cType != undefined) {
			obj.cType = cashoutModalData.cType;
		}
		if (cashoutModalData?.id != undefined) {
			obj.id = cashoutModalData.id;
		}
		if (cashoutModalData?.calculatedCashoutAmount != undefined) {
			obj.calculatedCashoutAmount =
				cashoutModalData.calculatedCashoutAmount;
		}
		if (cashoutModalData?.isLive != undefined) {
			obj.cType = cashoutModalData.cType;
		}
		if (cashoutModalData?.message != undefined) {
			obj.message = cashoutModalData.message;
		}
		cashout(
			{
				...cashoutModalData,
				isChangeAccepted: data.isChangeAccepted,
			},
			"offer"
		);
	};

	const betActionsClass = classNames({
		"bet__actions__col col col-sml-6": isAutoSellEnabled,
		"bet__actions__col col col-sml-12": !isAutoSellEnabled,
	});

	const checkFieldClasses = classNames("u-mar--y--xsml");

	return (
		<>
			<FormProvider {...methods}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					id="cashout-form"
					className={betActionsClass}
				>
					<button
						className="bet__btn btn btn--wrap btn--wrap--base btn--block btn--success btn--icon"
						type="submit"
					>
						<i className="u-icon u-icon--sml u-icon--check u-color--core--snow"></i>
						<span>{t<string>("MY_BETS.ITEM.CASHOUT")}</span>
					</button>
					<button
						className="bet__btn btn btn--wrap btn--wrap--base btn--block btn--secondary--inverted btn--icon u-color--ui--danger"
						type="button"
						onClick={closeCashoutModal}
					>
						<i className="u-icon u-icon--sml u-icon--remove u-color--ui--danger" />
						<span>{t<string>("COMMON.CANCEL")}</span>
					</button>

					<div className={checkFieldClasses}>
						<input
							id="isChangeAccepted"
							className="input--check"
							type="checkbox"
							{...register("isChangeAccepted")}
						/>
						<label
							htmlFor="isChangeAccepted"
							className="u-display--b u-type--wgt--regular"
						>
							{t<string>("MY_BETS.CASHOUT.ACCEPT_CHANGED_AMOUNT")}
						</label>
					</div>
				</form>
			</FormProvider>
		</>
	);
});

const CashoutMessageModal = observer(function CashoutMessageModal() {
	const { t } = useTranslation();
	const methods = useForm();
	const { register, handleSubmit } = methods;
	const bet = useContext(MyBetsStoreContext);
	if (bet == null || bet.type != "MyBetsStore") {
		logger.logError("Expected MyBetsStore got something else.");
		return null;
	}
	const {
		cashoutStore: { cashout, closeCashoutModal, cashoutModalData },
	} = bet;

	const onSubmit = (data: { isChangeAccepted: boolean }) => {
		const obj: OpenCashoutModalType = {
			calculatedCashoutAmount: 0,
			cType: CashoutType.NO_CACHE_OUT,
			displayedAmount: "",
			id: "",
			isLive: false,
			message: "",
		};
		if (cashoutModalData?.displayedAmount != undefined) {
			obj.displayedAmount = cashoutModalData.displayedAmount;
		} else {
			console.error("Displayed Amount can not be undefined");
			return;
		}
		if (cashoutModalData?.cType != undefined) {
			obj.cType = cashoutModalData.cType;
		}
		if (cashoutModalData?.id != undefined) {
			obj.id = cashoutModalData.id;
		}
		if (cashoutModalData?.calculatedCashoutAmount != undefined) {
			obj.calculatedCashoutAmount =
				cashoutModalData.calculatedCashoutAmount;
		}
		if (cashoutModalData?.isLive != undefined) {
			obj.cType = cashoutModalData.cType;
		}
		if (cashoutModalData?.message != undefined) {
			obj.message = cashoutModalData.message;
		}
		cashout(
			{
				...cashoutModalData,
				isChangeAccepted: data.isChangeAccepted,
			},
			"offer"
		);
	};

	return (
		<Modal>
			<FormProvider {...methods}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					id="cashout-form"
					className="popup"
				>
					<div className="popup__card">
						<div className="popup__header">
							{t<string>("MY_BETS.CASHOUT.TITLE")}
						</div>

						<div className="popup__body">
							{/* <div className="message message--sml message--warning">
								<i className="message__icon u-icon u-icon--big u-icon--alert-circle u-color--ui--warning" />
								<div className="message__content">
									{cashoutModalData.message}
								</div>
							</div> */}

							<InfoMessageComponent
								type="warning"
								size="sml"
								message={cashoutModalData!.message}
							/>
							<div className="form__group u-mar--top--xsml">
								<input
									id="isChangeAccepted"
									className="input--check"
									type="checkbox"
									{...register("isChangeAccepted")}
									name="isChangeAccepted"
								/>
								<label htmlFor="isChangeAccepted">
									{t<string>(
										"MY_BETS.CASHOUT.ACCEPT_CHANGED_AMOUNT"
									)}
								</label>
							</div>
						</div>

						<div className="popup__footer">
							<button
								id="close"
								className="at-cashoutModalSecondaryBtn popup__footer__btn btn btn--base btn--secondary--inverted"
								type="button"
								onClick={closeCashoutModal}
							>
								{t<string>("COMMON.CANCEL")}
							</button>
							<button
								className="at-cashoutModalPrimaryBtn popup__footer__btn btn btn--base btn--cashout--alt"
								type="submit"
							>
								{t<string>("MY_BETS.CASHOUT.TITLE")}
							</button>
						</div>
					</div>
				</form>
			</FormProvider>
		</Modal>
	);
});
