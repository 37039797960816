import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { observer } from "mobx-react";
import { EventContext } from "@gp/components";
import { TvInfoService } from "@services/offer/TvInfoService";

import { useIsAdditionalOfferVisible } from "@offer/common";

export default observer(function TvCoverageButton(props: {
	isOfferButton?: boolean;
	isOnHomepage?: boolean;
}) {
	const { isOfferButton = false, isOnHomepage } = props;

	const [isHover, setIsHover] = useState<boolean>(false);
	const [tvCoverage, setTvCoverage] = useState<string | null>(null);
	const timeOutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>(
		undefined
	);

	const { t } = useTranslation();
	const event = useContext(EventContext);
	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();

	const dataTip =
		event.hasTvCoverage && tvCoverage !== ""
			? tvCoverage
			: t("COMMON.NO_DATA");

	//#region eventHandlers

	const handleOnMouseEnter: React.MouseEventHandler<
		HTMLButtonElement
	> = (): void => {
		setIsHover(true);
		clearTimeout(timeOutIdRef.current);

		if (!event.hasTvCoverage) {
			return;
		}

		timeOutIdRef.current = setTimeout(async () => {
			try {
				const result = await TvInfoService.getTvInfo(event.id);
				setTvCoverage(result);
			} catch (err) {
				console.error("Failed to fetch TV info", err);
				setTvCoverage(null);
			}
		}, 250);
	};

	const handleOnMouseLeave: React.MouseEventHandler<
		HTMLButtonElement
	> = (): void => {
		setIsHover(false);
		clearTimeout(timeOutIdRef.current);
	};

	//#endregion eventHandlers

	//#region css

	// class order matters here
	const buttonClasses = classNames(
		{
			offer__actions__btn: isOfferButton && !isOnHomepage,
			event__actions__btn: !isOfferButton,
		},
		"btn",
		{
			"btn--square--reset btn--ghost": isOfferButton,
			"btn--square btn--square--xtny btn--ghost": !isOfferButton,
			"is-disabled": !event.hasTvCoverage,
		}
	);

	const iconClasses = classNames(
		{
			offer__actions__icon: !isAdditionalOfferVisible,
			event__actions__icon: isAdditionalOfferVisible,
		},
		"u-icon",
		{
			// "u-icon--sml": isOnHomepage,
			"u-icon--med u-icon--med--responsive": isOfferButton,
			"u-icon--med": !isOfferButton,
			"u-icon--action--tv--active": !isHover && event.hasTvCoverage,
			"u-icon--action--tv--selected": isHover && event.hasTvCoverage,
			"u-icon--action--tv--disabled": !event.hasTvCoverage,
		}
	);

	//#endregion css

	return (
		<button
			data-tooltip-id="tv-description"
			data-tooltip-content={dataTip!}
			data-tooltip-position-strategy="fixed"
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
			disabled={!event.hasTvCoverage}
			className={buttonClasses}
			type="button"
		>
			<i className={iconClasses} />
		</button>
	);
});
