import React, { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { WithdrawalContext } from "@context";
import PaymentOptionImage from "@v2/components/shared/icons/PaymentOptionImage";
import { getSVGExtension } from "@utils";

export const SelectedWithdrawalPaymentMethod: FC = observer(
	function SelectedWithdrawalPaymentMethod() {
		const { currentFormData, paymentMethods } =
			useContext(WithdrawalContext);
		const { t } = useTranslation();

		const selectedPaymentMethod = paymentMethods?.find((pm) => {
			return pm.paymentMethodId === currentFormData?.paymentMethodId;
		});

		return (
			<>
				<div className="label">
					{t("WITHDRAWAL.SELECTED_PAYMENT_METHOD")}
				</div>
				<div className="row">
					<div className="col col-sml-6">
						<div className="card--payment u-mar--bottom--reset">
							<div className="card--payment__area">
								<span className="card--payment__icon">
									<PaymentOptionImage
										className={`card--payment__logo u-icon u-icon--payment u-icon--payment--med u-icon--payment--${selectedPaymentMethod?.paymentMethodAbrv.toLowerCase()}`}
										svgName={
											selectedPaymentMethod?.paymentMethodAbrv.toLowerCase() +
											getSVGExtension()
										}
									/>
								</span>
								<span className="card--payment__details card--payment__details--centered">
									<span className="card--payment__title">
										{selectedPaymentMethod?.displayName}
									</span>
									<span className="card--payment__card u-type--xxsml u-type--wgt--medium">
										{selectedPaymentMethod?.maskedPan}
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
);
