import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";

export default function Statistics() {
	const { t } = useTranslation();
	const culture = getCurrentCulture();

	return (
		<div className="widget widget--stats">
			<div className="widget__banner">
				<a
					href={
						"https://s5.sir.sportradar.com/tipwin/" + culture + "/"
					}
					className="widget__banner__link"
					target="_blank"
				>
					<i className="widget__banner__icon u-icon u-icon--huge u-icon--bet-statistics" />
					<div className="widget__banner__content">
						<div className="widget__banner__tag">
							<div className="tag tag--med tag--sunny">Bet</div>
						</div>
						<div className="widget__banner__title">
							{t<string>("STATISTICS.TITLE")}
						</div>
					</div>
				</a>
			</div>
		</div>
	);
}
