import { IStoreLifeCycle } from "@interface";

import { getCurrentCulture } from "@utils";

import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const VersionChangeDetectorService = lazy(loadFailPath, ()=>import("@services/version-change-detector/VersionChangeDetectorService"));

export default class VersionChangeDetector implements IStoreLifeCycle {
	public isStoreInitialized: boolean = false;
	private interval: ReturnType<typeof setTimeout> | undefined;
	private onVersionChange: () => void;
	private checkInterval: number;

	/**
	 * Automatically fetches /dist/version.json ( my change, look service )
	 * @param onVersionChange call back when version change is detected
	 * @param checkInterval interval between version checks in ms
	 */
	constructor(onVersionChange: () => void, checkInterval: number) {
		this.onVersionChange = onVersionChange;
		this.checkInterval = checkInterval;
	}

	onInitialize = () => {
		this.interval = setInterval(async () => {
			const current = await (
				await VersionChangeDetectorService
			).default.FetchLatestVersion();
			if (current == null) {
				console.error("Can't fetch latest version.");
				return;
			}

			const my = {
				branch: WEBPACK_GIT_BRANCH,
				version: WEBPACK_GIT_VERSION,
				commitHash: WEBPACK_GIT_COMMIT_HASH,
			};
			if (
				current.branch.localeCompare(my.branch) !== 0 ||
				// current.version.localeCompare(my.version) !== 0 ||
				current.commitHash.localeCompare(my.commitHash) !== 0
			) {
				this.onVersionChange();
			}
		}, this.checkInterval);

		this.isStoreInitialized = true;
	};

	onDispose = () => {
		clearInterval(this.interval);
		this.isStoreInitialized = false;
	};
}
