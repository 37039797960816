import ChatApiService from "@api-services/chat/ChatApiService";
import { ChatSDKService } from "@services/chat";
import { ChatViewStore } from "./ChatViewStore";
import { ChatApiError } from "./ChatApiErrorModel";
import { action } from "mobx";

export class ChatClientHandler {
	chatViewStore: ChatViewStore;

	constructor(chatViewStore: ChatViewStore) {
		this.chatViewStore = chatViewStore;
	}

	get currentClient() {
		return ChatSDKService.returnCurrentClient();
	}

	async handleClient() {
		//handle creating client
		await this.createClientInstance();
		if (this.chatViewStore.userId) {
			await this.loginUserTroughClient(this.chatViewStore.userId);
		}
	}

	async createClientInstance() {
		if (this.currentClient) {
			//client already initialized
			return;
		}

		const { apiKey, apiRegion } = await this.fetchAmityApiInfo();

		ChatSDKService.createClientInstance(apiKey, apiRegion);
	}

	async fetchAmityApiInfo() {
		try {
			return await ChatApiService.fetchAmityApiInfo();
		} catch (error) {
			throw new ChatApiError(true);
		}
	}

	async loginUserTroughClient(userId: string) {
		if (this.currentClient?.userId) {
			//user potentially already logged in
			return;
		}

		const response = await this.fetchAuthToken(userId);

		const sessionHandler = this.createSessionHandler(
			response.authenticationToken
		);

		const loggedInStatus = await this.loginUser(
			response.userId,
			response.authenticationToken,
			response.displayName,
			sessionHandler
		);

		return loggedInStatus;
	}

	async loginUser(
		userId: string,
		authToken: string,
		displayName: string,
		sessionHandler: Amity.SessionHandler
	) {
		try {
			return await ChatSDKService.loginUser(
				userId,
				authToken,
				displayName,
				sessionHandler
			);
		} catch (error) {
			throw error;
		}
	}

	createSessionHandler(authToken: string) {
		try {
			return ChatSDKService.createSessionHandler(authToken);
		} catch (error) {
			throw error;
		}
	}

	@action.bound
	async disposeChatClient() {
		if (this.currentClient) {
			await this.revokeUserSession();
			this.destroyClientInstance();
		}
	}

	destroyClientInstance() {
		try {
			ChatSDKService.destroyClientInstance();
		} catch (error) {
			console.error("CHAT SDK ERR: Failed destroying client instance");
			throw error;
		}
	}

	async revokeUserSession() {
		try {
			if (this.chatViewStore.userId && this.currentClient?.userId) {
				await ChatApiService.revokeUserSession(
					this.chatViewStore.userId
				);
			}
		} catch (error) {
			// silent catch
			console.error("CHAT API ERR: Failed revoking user session");
		}
	}

	async fetchAuthToken(userId: string) {
		try {
			return await ChatApiService.fetchAuthToken(userId);
		} catch (error) {
			throw new ChatApiError(true);
		}
	}
}
