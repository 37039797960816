import React from "react";

import { Selector as BettingTypeSelector } from "@gp/offer";
import classNames from "classnames";

export type BetGroupOption = {
	abrv: string;
	displayName: string | undefined;
};

export function Dropdown(props: {
	isLive?: boolean;
	allAvailable?: BetGroupOption[];
	value: BettingTypeSelector;
	disabledOptions: string[];
	onChange: (value: string) => void;
}) {
	const wrapperClass = props.isLive ? "offer__type__col" : "header--sport__item header--sport__type";

	const selectClass = props.isLive ? "offer__type__select input input--tny input--select" : "offer__type__select input input--tny input--select";

	return (
		<div className={wrapperClass}>
			<select
				className={selectClass}
				value={props.value.name}
				onChange={(e) => props.onChange(e.target.value)}
			>
				{props.allAvailable?.map((bt) => (
					<DropDownOption
						key={bt.abrv}
						btOption={bt}
						disabledOptions={props.disabledOptions}
					/>
				))}
			</select>
		</div>
	);
}

function DropDownOption(props: {
	btOption: BetGroupOption;
	disabledOptions: string[];
}) {
	const isDisabled = props.disabledOptions.includes(props.btOption.abrv);

	return (
		<option
			value={props.btOption.abrv}
			className={classNames("offer__type__option", {
				"is-disabled": isDisabled,
			})}
			disabled={isDisabled}
		>
			{props.btOption.displayName}
		</option>
	);
}
