import React, { useState } from "react";
import {
	PasswordConfirmationGetterContext,
	PasswordConfirmationSetterContext,
} from "@shared/context";

export default function PasswordConfirmationContainer(props) {
	const [showPasswordConfirmation, setShowPasswordConfirmation] =
		useState(false);

	const passwordConfirmationSetterCtxValue = {
		setShowPasswordConfirmation: (condition) => {
			setShowPasswordConfirmation(condition);
		},
	};

	const passwordConfirmationGetterCtxValue = {
		showPasswordConfirmation: showPasswordConfirmation,
	};

	return (
		<PasswordConfirmationGetterContext.Provider
			value={passwordConfirmationGetterCtxValue}
		>
			<PasswordConfirmationSetterContext.Provider
				value={passwordConfirmationSetterCtxValue}
			>
				{props.children}
			</PasswordConfirmationSetterContext.Provider>
		</PasswordConfirmationGetterContext.Provider>
	);
}
