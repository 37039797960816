import { observable, action } from "mobx";
import { getCurrentLanguage } from "@utils";
import { ChatViewStore } from "./ChatViewStore";
import { ChatSDKService } from "@services/chat";
import { ChannelDto } from "@api-types/chat";
import ChatApiService from "@api-services/chat/ChatApiService";
import { LoaderStore } from "../common";
import { ChatApiError } from "./ChatApiErrorModel";

export class ChannelListHandler {
	@observable channelList: ChannelDto[] = [];

	chatViewStore: ChatViewStore;
	loaderStore: LoaderStore;

	constructor(chatViewStore: ChatViewStore) {
		this.chatViewStore = chatViewStore;
		this.loaderStore = new LoaderStore();
	}

	async initialize(userId: string) {
		try {
			if (this.channelList.length > 0) {
				//channel list already fetched
				return;
			}

			const currentActiveClient = ChatSDKService.returnCurrentClient();

			if (!currentActiveClient?.token?.accessToken) {
				//trying to initialize channel list without user logged in
				throw new ChatApiError(true);
			}

			this.loaderStore.suspend();

			const response = await this.fetchUserChannels(
				userId,
				currentActiveClient.token.accessToken
			);

			const sortedList = response.channels.sort((a, b) => {
				const aSort = a?.sortOrder == null ? 99999 : a.sortOrder;
				const bSort = b?.sortOrder == null ? 99999 : b.sortOrder;
				return Math.sign(aSort - bSort);
			});

			this.setChannelList(sortedList);
			this.loaderStore.resume();
		} catch (error) {
			this.chatViewStore.handleError(error);
		}
	}

	async fetchUserChannels(userId: string, accessToken: string) {
		try {
			return await ChatApiService.fetchUserChannels(
				userId,
				getCurrentLanguage(),
				accessToken
			);
		} catch (error) {
			throw new ChatApiError(true);
		}
	}

	@action.bound
	setChannelList(list: ChannelDto[]) {
		this.channelList = list;
	}

	@action.bound
	disposeChannelList() {
		this.channelList = [];
	}
}
