import { useRootAppStore } from "@hooks";
import { getCurrentCulture } from "@utils";
import { isAccountVerificationSectionEnabled } from "@v2/helpers";
import { useAccountVerification } from "@v2/hooks/shared/useAccountVerification";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

export default function AccountVerificationInfo() {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();
	const data = useAccountVerification();

	if (!isAccountVerificationSectionEnabled) {
		return null;
	}

	if (data == null) {
		return null;
	}

	return (
		<div className="popup__col col col-sml-12">
			<div className="message message--base">
				<div className="message__content u-padd--reset">
					<div className="message__title u-type--xmed">
						<i className="message__icon u-icon u-icon--lrg u-icon--user-unverified u-color--ui--danger u-mar--right--tny" />
						<span className="u-align--v--middle">
							{t(`${data.title || "ACCOUNT_VERIFICATION.TITLE"}`)}
						</span>
					</div>

					<ReactMarkdown className="message__text">
						{data.message || ""}
					</ReactMarkdown>

					<Link
						className="at-welcomeModalBtnVerif u-anchor u-anchor--primary u-anchor--underline has-arrow"
						to={`/${getCurrentCulture()}/${
							data.link || "account-verification"
						}`}
						onClick={() => {
							rootStore.welcomeStore.hidePopup(),
								rootStore.requiredUserActionStore.hidePopup();
						}}
					>
						{t(`${data.title || "ACCOUNT_VERIFICATION.TITLE"}`)}
					</Link>
				</div>
			</div>
		</div>
	);
}
