import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import { getCurrentCulture } from "@utils";
import { isRegistrationDomain } from "@v2/helpers/membership/utils";
import { observer } from "mobx-react";
import { useRootAppStore } from "@hooks";

export default observer(function OnBoardingFooter(props: {
	isRegistration?: boolean;
	hasOnboardingStyling?: boolean;
}) {
	const { t } = useTranslation();

	const { isIpRestricted } = useRootAppStore();

	const { isRegistration, hasOnboardingStyling } = props;

	const date = new Date();

	const footerClasses = classNames({
		"footer--mini": !hasOnboardingStyling,
		"l--membership__footer": hasOnboardingStyling,
	});

	const copyrightClasses = classNames({
		"footer--mini__copy": !hasOnboardingStyling,
		"l--membership__copy": hasOnboardingStyling,
	});

	const isRegCurrentDomain = isRegistrationDomain();

	return (
		<footer className={footerClasses}>
			{isRegistration && !isIpRestricted && (
				<ReactMarkdown
					linkTarget={"_blank"}
					className="u-anchor--nested"
				>
					{`* ${t<string>("MEMBERSHIP.REGISTRATION.FOOTER", {
						tcLink: isRegCurrentDomain
							? `${WEBPACK_WEBSITE_URL}/${getCurrentCulture()}/info/terms-and-conditions?noBack=true`
							: "../info/terms-and-conditions?noBack=true",
						ppLink: isRegCurrentDomain
							? `${WEBPACK_WEBSITE_URL}/${getCurrentCulture()}/info/privacy?noBack=true`
							: "../info/privacy?noBack=true",
					})}`}
				</ReactMarkdown>
			)}
			<div className={copyrightClasses}>
				&#169;
				{t<string>("specific:FOOTER.ALL_RIGHTS_RESERVED", {
					0: date.getFullYear(),
				})}
				{t<string>("specific:FOOTER.FOOTER_RIGHTS_RESERVED")}
			</div>
		</footer>
	);
});
