import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrentCulture } from "@utils";

function NotAuth() {
	const { t } = useTranslation();
	const widgetBodyClassNames =
		"widget__body widget--bets widget--bets--" + getCurrentCulture();
	return (
		<div className={widgetBodyClassNames}>
			<div className="widget--bets__empty">
				<i className="u-icon u-icon--huge u-icon--empty--bets u-align--h--center u-mar--top--xsml u-mar--bottom--big" />
				<div>{t<string>("MY_BETS.NOT_AUTH")}</div>
			</div>
		</div>
	);
}

export default NotAuth;