import React, { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { OfferStoreContext } from "@gp/components";
import { ToggleIndicator } from ".";
import { BaseOfferMenuStore as OfferMenuStore } from "@offer/stores/components/offer-menu/BaseOfferMenuStore";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { SportInteractionTypeEnum } from "@data-types/analytics/SportInteractionTypeEnum";
import OfferIcon from "@v2/components/shared/icons/Offer";

export const MenuSportItemButton = observer(
	function MenuSportItemButton(props: {
		sportMenuItem: OfferMenuStore["menu"];
	}) {
		const {
			// @ts-expect-error
			toggleSport,
			// @ts-expect-error
			selectedSport,
			// @ts-expect-error
			periodSubMenu: { selectedPeriodKey },
		} = useContext(OfferStoreContext);

		const buttonClasses = classNames("nav--secondary__link", {
			"is-selected":
				props.sportMenuItem.checkState > 0 &&
				((selectedPeriodKey === "events" && selectedSport != null) ||
					selectedPeriodKey !== "events"), // If period on live page is events and selected sport is null we are in all menu and no sport should be selected :{
			"is-expanded":
				selectedSport?.node?.id === props.sportMenuItem.node.id,
		});

		return (
			<li className="nav--secondary__item">
				<button
					className={buttonClasses}
					type="button"
					onClick={() => {
						if (
							window.location.pathname.includes("live") &&
							selectedSport?.node?.id !==
								props.sportMenuItem.node.id
						) {
							AnalyticsService.logSportInteraction({
								category: SportInteractionTypeEnum.LiveSport,
								label: props.sportMenuItem.node.abrv,
							});
						}
						toggleSport(props.sportMenuItem);
					}}
				>
					<MenuSportItemButtonCore
						sportMenuItem={props.sportMenuItem}
						isSelected={
							props.sportMenuItem.checkState > 0 &&
							((selectedPeriodKey === "events" &&
								selectedSport != null) ||
								selectedPeriodKey !== "events")
						}
					/>
					<MenuSportItemButtonCount
						sportMenuItem={props.sportMenuItem}
					/>
				</button>
			</li>
		);
	}
);

export const MenuSportItemButtonCore = observer(
	function MenuSportItemButtonCore(props: {
		sportMenuItem: OfferMenuStore["menu"];
		isSelected?: boolean;
	}) {
		const iconClass = classNames(
			"nav--secondary__icon u-icon u-icon--lrg u-icon--sport u-icon--sport--color",
			{
				"is-selected": props.isSelected,
			}
		);

		return (
			<>
				<OfferIcon
					type="sport"
					sport={props.sportMenuItem.node.abrv || ""}
					className={iconClass}
					treeIcon={true}
				/>
				<div className="nav--secondary__title">
					{props.sportMenuItem.node.name}
				</div>

				<ToggleIndicator
					sportMenuItem={props.sportMenuItem}
					isSelected={props.isSelected}
				/>
			</>
		);
	}
);

export const MenuSportItemButtonCount = observer(
	function MenuSportItemButtonCount(props: {
		sportMenuItem: { node: { count: number } };
	}) {
		return (
			<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
				{props.sportMenuItem.node.count}
			</span>
		);
	}
);
