import React from "react";
import { useTranslation } from "react-i18next";
import { useFixBody } from "@v2/hooks/shared";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import ReactMarkdown from "react-markdown";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { MarketingInteractionTypeEnum } from "@data-types/analytics/MarketingInteractionTypeEnum";

export default observer(function OnboardingRegisterPopup(props: {
	isOpened?: boolean;
}) {
	const { t } = useTranslation();

	const rootStore = useRootAppStore();
	useFixBody(true, props.isOpened);

	return (
		<div className="at-welcomeBonusModal popup popup--bonus">
			<div className="popup__card popup--bonus__card">
				<div className="popup--bonus__header">
					<img
						className="popup--bonus__logo"
						src="/assets/images/logo-vibrant.svg"
						alt=""
					/>

					<button
						type="button"
						className="popup--bonus__close btn btn--round btn--round--tny btn--secondary--inverted btn--icon"
						onClick={() => {
							AnalyticsService.logMarketingInteraction({
								category:
									MarketingInteractionTypeEnum.RegisterClose,
							});
							rootStore.onboardingWelcomePopupStore.onboardingRegistrationStore.hidePopup();
						}}
					>
						<i className="u-icon u-icon--xsml u-icon--close u-color--text--primary" />
					</button>
				</div>

				<div className="popup--bonus__body">
					<div className="popup--bonus__content">
						<div className="popup--bonus__text">
							<div className="popup--bonus__title">
								<ReactMarkdown>
									{t(
										"USER.POPUPS.ONBOARDING_WELCOME_POPUPS.REGISTRATION.TITLE"
									)}
								</ReactMarkdown>
							</div>
							<div className="popup--bonus__desc">
								{t(
									"USER.POPUPS.ONBOARDING_WELCOME_POPUPS.REGISTRATION.DESCRIPTION"
								)}
							</div>
						</div>

						<div className="popup--bonus__footer">
							<button
								type="button"
								className="popup--bonus__btn btn btn--base btn--primary"
								onClick={() => {
									AnalyticsService.logMarketingInteraction({
										category:
											MarketingInteractionTypeEnum.RegisterConfirm,
									});
									rootStore.onboardingWelcomePopupStore.onboardingRegistrationStore.onOnboardingRedirection();
								}}
							>
								{t(
									"USER.POPUPS.ONBOARDING_WELCOME_POPUPS.REGISTRATION.REGISTER_BTN"
								)}
							</button>
						</div>
					</div>

					<div className="popup--bonus__media">
						<img
							className="popup--bonus__img"
							src="/assets/images/bg/athlete.png"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
});
