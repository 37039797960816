import React from "react";
import { useFormContext } from "react-hook-form";
import { passwordValidationPattern } from "@gp/common";
import { get } from "lodash";
import { PasswordInput } from "@v2/components/desktop/shared/inputs";
import { useFieldValidation } from "@v2/helpers/shared";
import { PasswordSimilarityRequestModel } from "@data-types/membership/registration";
import { useValueAvailabilityDebounce } from "@v2/hooks";

export default function RegistrationPassword(props: {
	checkPasswordSimilarity: (
		dataset: PasswordSimilarityRequestModel
	) => Promise<boolean>;
	labelClassName?: string;
}) {
	const { labelClassName } = props;
	const {
		formState: { errors },
		clearErrors,
		getValues,
	} = useFormContext();

	useFieldValidation("password", "confirmPassword");

	const debouncer = useValueAvailabilityDebounce();

	const passwordSimilarityCheck = async (passwordInput: string) => {
		const currentFormValues = getValues();
		const dataset: PasswordSimilarityRequestModel = {
			password: passwordInput,
			email: currentFormValues.email,
			dob: currentFormValues.dob,
			userName: currentFormValues.username,
			firstName: currentFormValues.firstName,
			lastName: currentFormValues.lastName,
		};

		const { password, ...valuesToCompareTo } = dataset;

		//if atleast one field has values then check validity on BE
		const hasValidData = Object.values(valuesToCompareTo).some(
			(value) => value != null && value !== ""
		);

		if (!hasValidData) {
			//return no errors, form will be validated again on submit with new data
			return true;
		}

		return await props.checkPasswordSimilarity(dataset);
	};

	// do not delete, maybe will be used in the future

	// const { t } = useTranslation();
	// const passwordPopover = (
	// 	<>
	// 		<div>
	// 			{t(
	// 				"MEMBERSHIP.REGISTRATION.PASSWORD_DESCRIPTION.PWD_REQUIREMENTS_VALID"
	// 			)}
	// 		</div>
	// 		<ul className="list u-mar--bottom--ytny u-pad--left--med">
	// 			<li>
	// 				{t(
	// 					"specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_1",
	// 					{ 0: "5" }
	// 				)}
	// 			</li>
	// 			<li>
	// 				{t(
	// 					"specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_4"
	// 				)}
	// 			</li>
	// 			<li>
	// 				{t(
	// 					"specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_2"
	// 				)}
	// 			</li>
	// 			<li>
	// 				{t(
	// 					"specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_3"
	// 				)}
	// 			</li>
	// 		</ul>
	// 		<p>
	// 			{t("specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS")}
	// 		</p>
	// 		<ul className="list u-padd--left--med">
	// 			<li>
	// 				{t(
	// 					"specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_3"
	// 				)}
	// 			</li>
	// 			<li>
	// 				{t(
	// 					"specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_4"
	// 				)}
	// 			</li>
	// 			<li>
	// 				{t(
	// 					"specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_5"
	// 				)}
	// 			</li>
	// 		</ul>
	// 	</>
	// );

	return (
		<>
			<PasswordInput
				className="form__field"
				name="password"
				label="MEMBERSHIP.REGISTRATION.PASSWORD_LABEL"
				labelClassName={labelClassName}
				// popoverMsg={passwordPopover}
				placeholder="MEMBERSHIP.REGISTRATION.PASSWORD_PLACEHOLDER"
				pattern={{
					value: passwordValidationPattern,
					message:
						"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
				}}
				validate={{
					isPasswordNotSimilar: async (value: string) => {
						if (get(errors, "password")) {
							clearErrors("password");
						}

						const isAvailable = await debouncer(
							value,
							passwordSimilarityCheck
						);

						if (isAvailable == null || isAvailable == undefined) {
							return true;
						}

						return (
							isAvailable ||
							"USER.ACCOUNT_SETTINGS.ERR_HANDLING.ERROR_PWD_CHANGE_SIMILAR"
						);
					},
				}}
				required
			/>

			<PasswordInput
				className="form__field"
				name="confirmPassword"
				label="MEMBERSHIP.REGISTRATION.CONFIRM_PASSWORD_LABEL"
				labelClassName={labelClassName}
				placeholder="MEMBERSHIP.REGISTRATION.CONFIRM_PASSWORD_PLACEHOLDER"
				// popoverMsg={
				// 	"MEMBERSHIP.REGISTRATION.CONFIRM_PASSWORD_DESCRIPTION"
				// }
				pattern={{
					value: passwordValidationPattern,
					message:
						"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
				}}
				validate={{
					isEqual: (value: string) => {
						const { password } = getValues();
						return (
							value === password ||
							"MEMBERSHIP.REGISTRATION.DOES_NOT_MATCH_PWD"
						);
					},
				}}
				required
			/>
		</>
	);
}
