import { ILogger } from "@gp/utility/src/logger";
import { IConsoleLogger } from "@interface";

enum LogLevel {
	TRACE,
	INFO,
	WARN,
	ERROR,
}

export class ConsoleLogger implements ILogger, IConsoleLogger {
	private isEnabled: boolean;
	private logLevel: number;

	constructor() {
		this.isEnabled = true; //WEBPACK_IS_LOGGING_ENABLED || false;
		this.logLevel = LogLevel.TRACE;
		LogLevel[WEBPACK_LOG_LEVEL as keyof typeof LogLevel] || LogLevel.INFO;

		this.logTrace = this.logTrace.bind(this);
		this.logInfo = this.logInfo.bind(this);
		this.logWarn = this.logWarn.bind(this);
		this.logError = this.logError.bind(this);
	}

	setLogLevel(value: number) {
		this.isEnabled = true;
		this.logLevel = value;
	}

	logTrace(message?: any, ...optionalParams: any[]) {
		if (this.isEnabled && this.logLevel <= LogLevel.TRACE) {
			console.groupCollapsed(message);
			console.trace(...optionalParams);
			console.groupEnd();
		}
	}

	logInfo(message?: any, ...optionalParams: any[]) {
		if (this.isEnabled && this.logLevel <= LogLevel.INFO) {
			console.info(message, ...optionalParams);
		}
	}

	logWarn(message?: any, ...optionalParams: any[]) {
		if (this.isEnabled && this.logLevel <= LogLevel.WARN) {
			console.groupCollapsed(message);
			console.warn(...optionalParams);
			console.groupEnd();
		}
	}

	logError(message?: any, ...optionalParams: any[]) {
		if (this.isEnabled && this.logLevel <= LogLevel.ERROR) {
			console.error(message, ...optionalParams);
		}
	}
}

/** pass this to where it is needed as prop or argument.  */
export const logger = new ConsoleLogger();
