import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { PasswordInput } from "@v2/components/desktop/shared/inputs";
import FormButton from "@components/buttons/FormButton";
import { VerifyAndChangePasswordParamsDto } from "@api-types/user/VerifyAndChangePasswordParamsDto";
import { useFieldValidation } from "@v2/helpers/shared";
import { AccountTabs } from "@v2/components/desktop/shared/header";
import { passwordValidationPattern } from "@gp/common";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export function PasswordChangeFormSection(props: {
	onSubmitData: (formData: VerifyAndChangePasswordParamsDto) => Promise<void>;
	activeAccount: number;
	setActiveAccount: Function;
}) {
	const { onSubmitData, activeAccount, setActiveAccount } = props;

	const { t } = useTranslation();
	useFieldValidation("newPassword", "repeatPassword");
	const { getValues, handleSubmit } = useFormContext();

	return (
		<form className="form" onSubmit={handleSubmit(onSubmitData)}>
			<div className="card--primary">
				<div className="card--primary__header">
					<div className="card--primary__title title--primary">
						{t<string>(
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.TITLE_PC"
						)}
					</div>
				</div>

				<AccountTabs
					activeAccount={activeAccount}
					setActiveAccount={setActiveAccount}
				/>

				<div className="card--primary__body">
					<div className="form__group">
						<div className="row">
							<PasswordInput
								name="oldPassword"
								className="col col-sml-12 form__field"
								label="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.CURRENT_PASSWORD_LABEL"
								placeholder="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.CURRENT_PASSWORD_LABEL"
								required
							/>

							<PasswordInput
								name="newPassword"
								className="col col-sml-12 form__field"
								label="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.NEW_PASSWORD_LABEL"
								placeholder="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.NEW_PASSWORD_PLACEHOLDER"
								pattern={{
									value: passwordValidationPattern,
									message:
										"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
								}}
								required
								validate={{
									isNotEqual: (value: string) =>
										value !== getValues("oldPassword") ||
										"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.SAME_PWD",
								}}
							/>

							<PasswordInput
								name="repeatPassword"
								className="col col-sml-12 form__field"
								label="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.REPEAT_NEW_PASSWORD"
								placeholder="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.REPEAT_NEW_PASSWORD_PLACEHOLDER"
								pattern={{
									value: passwordValidationPattern,
									message:
										"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
								}}
								validate={{
									isEqual: (value: string) => {
										return (
											value ===
												getValues("newPassword") ||
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.DOES_NOT_MATCH_PWD"
										);
									},
								}}
								required
							/>
						</div>
					</div>

					<div className="form__group">
						<div className="row">
							<div className="col col-sml-12 form__field">
								<InfoMessageComponent
									type="warning"
									message={t<string>(
										"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.AUTOMATIC_LOGOUT"
									)}
								/>
							</div>
							<div className="col col-sml-12 form__field">
								<div className="message message--base message--note">
									<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
									<div className="message__content">
										<div className="message__title">
											{t<string>(
												"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENTS_VALID"
											)}
										</div>
										<ul className="list list--unordered">
											<RequirementItem translationKey="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_1" />
											<RequirementItem translationKey="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_5" />
											<RequirementItem translationKey="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_2" />
											<RequirementItem translationKey="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_3" />
											<RequirementItem translationKey="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_4" />
										</ul>
									</div>
								</div>
							</div>
							<div className="col col-sml-12 form__field">
								<div className="message message--base message--muted">
									<i className="message__icon u-icon u-icon--lrg u-icon--help u-color--ui--muted" />
									<div className="message__content">
										<div className="message__title">
											{t<string>(
												"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS"
											)}
										</div>
										<ul className="list list--unordered">
											<li>
												{t<string>(
													"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_1"
												)}
											</li>
											<li>
												{t<string>(
													"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_2"
												)}
											</li>
											<li>
												{t<string>(
													"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_3"
												)}
											</li>
											<li>
												{t<string>(
													"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_4"
												)}
											</li>
											<li>
												{t<string>(
													"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_5"
												)}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card--primary__footer">
					<FormButton
						btnText="COMMON.SAVE_BUTTON"
						className="card--primary__foter__btn btn btn--lrg btn--primary"
					/>
				</div>
			</div>
		</form>
	);
}

function RequirementItem(props: { translationKey: string }) {
	const { t } = useTranslation();
	const text = t<string>(props.translationKey);
	if (text == props.translationKey) {
		return null;
	}
	return <li>{text}</li>;
}
