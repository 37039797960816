import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const modalContainer = document.getElementById("app-modal");

/** @deprecated use from @lib/modal instead */
export default function Modal(props) {
	const { children, ...rest } = props;

	const [elContainer] = useState(document.createElement("div"));

	useEffect(() => {
		modalContainer.appendChild(elContainer);

		return () => modalContainer.removeChild(elContainer);
	});

	return ReactDOM.createPortal(
		React.cloneElement(children, rest),
		elContainer
	);
}
