import { DateTime } from "luxon";
import { helpers } from "./index";
import { observable } from "mobx";

import { EventType } from "@gp/models";
import { NameProvider } from "@gp/utility";

/**
 * @
 */
export class BetOffer {
	nameProvider: NameProvider;

	bettingOfferId: string;
	bettingOfferKeyId: string;
	@observable bettingOfferStatus: number;
	bettingTypeId: string;
	bettingTypeTip: string;
	// TODO
	currentResult: any;
	eventId: string;
	id: string;
	isBank: boolean;
	isLive: boolean;
	playerFirstName: string;
	playerId?: string;
	playerLastName?: string;
	// TODO
	specifier: any;
	// TODO
	sportData: any;
	systemIdentifier: string;
	teamGender: number;
	teamId?: string;
	teamName?: string;
	value: number;
	// TODO
	displayedAmount: any;
	indicator: number;
	isMulti: boolean;
	multiEventName: string;
	submitCount?: number;

	constructor(betOffer: any) {
		this.nameProvider = new NameProvider();

		this.bettingOfferId = betOffer.bettingOfferId;
		this.bettingOfferKeyId = betOffer.bettingOfferKeyId;
		this.bettingOfferStatus = betOffer.bettingOfferStatus;
		this.bettingTypeId = betOffer.bettingTypeId;
		this.bettingTypeTip = betOffer.bettingTypeTip;
		this.currentResult = helpers.safeJsonParse(betOffer.currentResult); // TODO it is not primitive
		this.eventId = betOffer.eventId;
		this.id = betOffer.id;
		this.isBank = betOffer.isBank;
		this.isLive = betOffer.isLive;
		this.playerFirstName = betOffer.playerFirstName;
		this.playerId = betOffer.playerId;
		this.playerLastName = betOffer.playerLastName;
		this.specifier = betOffer.specifier;
		this.sportData = betOffer.sportData; // TODO it is not primitive
		this.sportData.result = helpers.safeJsonParse(this.sportData.result);
		this.systemIdentifier = betOffer.systemIdentifier;
		this.teamGender = betOffer.teamGender;
		this.teamId = betOffer.teamId;
		this.teamName = betOffer.teamName;
		this.value = betOffer.value;
		this.displayedAmount = betOffer.displayedAmount;
		this.indicator = betOffer.indicator;

		// These are missing from response
		this.isMulti = betOffer.isMulti;
		this.multiEventName = betOffer.multiEventName;
	}

	//#region getters

	/**
	 * Betting offer status formatted to string.
	 */
	get formattedBettingOfferStatus() {
		return helpers.formatBettingOfferStatus(this.bettingOfferStatus);
	}

	/**
	 * Betting offer formatted time string
	 */
	get timeString() {
		return DateTime.fromISO(this.sportData.eventStartTime).toFormat(
			"dd.MM. HH:mm"
		);
	}

	/**
	 * Betting offer formatted string or empty for multi event
	 */
	get emptyForMultiTimeString() {
		if (this.hasOwnProperty("isMulti") && this.isMulti == true) {
			return "";
		}

		return this.timeString;
	}

	/**
	 * Team one name string
	 */
	get teamOneString() {
		return helpers.formatTeamName(
			this.sportData.teamOneName,
			this.sportData.teamOneGender,
			this.sportData.eventType
		);
	}

	/**
	 * Team two name string
	 */
	get teamTwoString() {
		return helpers.formatTeamName(
			this.sportData.teamTwoName,
			this.sportData.teamTwoGender,
			this.sportData.eventType
		);
	}

	/**
	 * Current score string.
	 * Note this is not computed since there is no live subscription for score.
	 */
	scorePerPeriodString(liveResult?: any) {
		const sportData = liveResult || this.sportData;
		if (sportData.result?.scorePerPeriod == null) {
			return "";
		}

		const periodScores = sportData.result.scorePerPeriod
			// TODO
			.reduce((acc: string[], value: any) => {
				acc.push(`${value.home || 0}:${value.away || 0}`);
				return acc;
			}, [])
			.join("; ");

		return `(${periodScores})`;
	}

	/**
	 * Current score string.
	 * Note this is not computed since there is no live subscription for score.
	 */
	get scorePerPeriodAtBetTimeString() {
		if (this.currentResult?.scorePerPeriod == null) {
			return "";
		}

		return (
			this.currentResult.scorePerPeriod
				// TODO
				.reduce((acc: string[], value: any) => {
					acc.push(`(${value.home || 0}:${value.away || 0})`);
					return acc;
				}, [])
				.join(" ")
		);
	}

	/**
	 * Formatted current result
	 */
	formattedCurrentResult(liveResult: any) {
		const sportData = liveResult || this.sportData;
		if (sportData?.result?.fullTimeScore != null) {
			return `${sportData.result.fullTimeScore.home || 0}:${
				sportData.result.fullTimeScore.away || 0
			}`;
		}

		if (sportData?.result?.currentScore != null) {
			return `${sportData.result.currentScore.home || 0}:${
				sportData.result.currentScore.away || 0
			}`;
		}

		return "";
	}

	/**
	 * Formatted result at bet time
	 */
	get formattedResultAtBetTime() {
		if (this.currentResult?.currentScore != null) {
			return `${this.currentResult.currentScore.home || 0}:${
				this.currentResult.currentScore.away || 0
			}`;
		}

		return "";
	}

	/**
	 * Returns formatted betting type string.
	 */
	get formattedBettingType() {
		const bettingType = this.nameProvider.getName(
			this.sportData.bettingTypeNameForBettingSlip,
			this.formattedOfferSpecifier
		);
		return bettingType;
	}

	/**
	 * Returns formatted tip string.
	 */
	get formattedBettingTypeTip() {
		if (
			this.sportData.eventType != undefined &&
			this.sportData.eventType != EventType.NORMAL
		) {
			if (this.playerId != null) {
				return `${this.playerLastName}, ${this.playerFirstName}`;
			} else if (this.teamId != null) {
				return this.teamName;
			}
			return this.bettingTypeTip;
		}
		const bettingTypeTip = this.nameProvider.getName(
			this.bettingTypeTip,
			this.formattedOfferSpecifier
		);
		return bettingTypeTip;
	}

	/**
	 * Returns offer specifiers.
	 */
	get formattedOfferSpecifier() {
		return {
			competitor1: this.sportData.teamOneName,
			competitor2: this.sportData.teamTwoName,
			...this.specifier,
		};
	}

	//#endregion getters
}
