import React, { FC, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { WithdrawalContext } from "@context";
import { FormProvider, useForm } from "react-hook-form";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { Link, NavLink } from "react-router-dom";
import { getCurrentCulture } from "@utils";
import {
	SelectedWithdrawalAmount,
	SelectedWithdrawalPaymentMethod,
	SelectedWithdrawalWallet,
	WithdrawalPasswordConfirmation,
	WithdrawalPaymentSelect,
	WithdrawalWalletSelector,
} from "./components";
import { useAppContext } from "@v2/hooks";
import { UsedBonusMessage } from "../../../../../../../src/components/desktop/account-settings/my-bonuses/components";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";
import Button from "@components/buttons/Button";

export const NewWithdrawalBody = observer(function NewWithdrawalBody() {
	const {
		resetMultiStepFlags,
		userBonusInfo,
		paymentMethodError,
		isStoreInitialized,
	} = useContext(WithdrawalContext);

	const { t } = useTranslation();

	useEffect(() => {
		return () => {
			resetMultiStepFlags();
		};
	}, []);

	if (!isStoreInitialized) {
		return null;
	}

	if (paymentMethodError && paymentMethodError.errorCode !== 8) {
		return (
			<div className="at-depositFormMultiPwd card--primary">
				<div className="card--primary__header">
					<h1 className="card--primary__title title--primary">
						{t<string>("WITHDRAWAL.TITLE")}
					</h1>
				</div>
				<WithdrawalTabSwitcher />
				<div className="card--primary__body">
					<InfoMessageComponent
						type="warning"
						message={paymentMethodError.message}
						textClass="message__text"
					/>
				</div>
				<div className="card--primary__footer">
					<FooterButtons errorCode={paymentMethodError.errorCode} />
				</div>
			</div>
		);
	} else if (userBonusInfo?.isBonusActive) {
		return (
			<div className="at-depositFormMultiPwd card--primary">
				<div className="card--primary__header">
					<h1 className="card--primary__title title--primary">
						{t<string>("WITHDRAWAL.TITLE")}
					</h1>
				</div>
				<WithdrawalTabSwitcher />
				<div className="card--primary__body">
					<UsedBonusMessage
						status={userBonusInfo}
						infoMessage="USER.ACCOUNT_SETTINGS.MY_BONUSES.ACTIVE_BONUS_WITHDRAWAL"
					/>
				</div>
			</div>
		);
	}

	return <WithdrawalProcess />;
});

function FooterButtons(props: { errorCode: number }) {
	const { errorCode } = props;
	const { t } = useTranslation();
	if (errorCode === 18) {
		return (
			<Link
				to={`account-settings/account-verification`}
				className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
			>
				{t("ACCOUNT_VERIFICATION.TITLE")}
			</Link>
		);
	} else if (errorCode === 8) {
		return (
			<Link
				to={`deposit`}
				className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
			>
				{t("DEPOSIT.TITLE")}
			</Link>
		);
	}

	return (
		<Link
			to={`info/contact`}
			className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
		>
			{t("FOOTER.CONTACT")}
		</Link>
	);
}

const WithdrawalProcess: FC = observer(function WithdrawalProcess() {
	const { t } = useTranslation();

	const {
		withdrawalResult,
		paymentMethods,
		onlineWallet,
		finalizeWithdrawal,
		initializeWithdrawalProcess,
		isMultiStepWithdrawal,
		resetMultiStepFlags,
		isRedirectingToPaymentPage,
		isLoadingProcess,
		shouldShowWalletSelectors,
		gigWallet,
	} = useContext(WithdrawalContext);

	const methods = useForm({
		defaultValues: {
			bettingAccountTypeId: onlineWallet?.accountTypeId,
			paymentMethodId: "",
			amount: "",
		},
	});

	useEffect(() => {
		methods.reset();
	}, [withdrawalResult]);

	const { handleSubmit, getValues } = methods;

	const onSubmit = (data: any) => {
		initializeWithdrawalProcess(data);
	};

	const onMultiStepSubmit = (data: any) => {
		finalizeWithdrawal(data);
	};

	if (isMultiStepWithdrawal) {
		return (
			<FormProvider {...methods}>
				<form
					className="at-depositFormMultiPwd card--primary"
					onSubmit={handleSubmit(onMultiStepSubmit)}
				>
					<div className="card--primary__header">
						<h1 className="card--primary__title title--primary">
							{t<string>("WITHDRAWAL.TITLE")}
						</h1>
					</div>

					<WithdrawalTabSwitcher />

					<div className="card--primary__body">
						<div className="form__section">
							{shouldShowWalletSelectors && (
								<div className="form__group">
									<SelectedWithdrawalWallet />
								</div>
							)}
							<div className="form__group">
								<SelectedWithdrawalPaymentMethod />
							</div>
							<div className="form__group">
								<SelectedWithdrawalAmount />
							</div>
						</div>
						<div className="form__section">
							<WithdrawalPasswordConfirmation />
						</div>
					</div>

					<div className="card--primary__footer">
						<button
							className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
							type="submit"
							disabled={
								isLoadingProcess || isRedirectingToPaymentPage
							}
						>
							<span className="btn__label">
								{t<string>("COMMON.NEXT_BUTTON")}
							</span>
							<i className="u-icon u-icon--xsml u-icon--arrow--right u-color--core--snow" />
						</button>
						<button
							className="card--primary__footer__btn btn btn--lrg btn--secondary--inverted"
							onClick={resetMultiStepFlags}
						>
							<span className="btn__label">
								{t<string>("COMMON.BACK")}
							</span>
						</button>
					</div>
				</form>
			</FormProvider>
		);
	}

	return (
		<FormProvider {...methods}>
			<form
				id="newWithdrawalForm"
				className="card--primary"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="card--primary__header">
					<h1 className="card--primary__title title--primary">
						{t<string>("WITHDRAWAL.TITLE")}
					</h1>
				</div>

				<WithdrawalTabSwitcher />

				<div className="card--primary__body">
					{gigWallet && (
						<InfoMessageComponent
							type="note"
							message={t(
								"WITHDRAWAL.UNABLE_TO_WITHDRAW_GIG_NOTICE"
							)}
							textClass="message__text"
							addClassName="u-mar--bottom--med"
						/>
					)}

					{shouldShowWalletSelectors ? (
						<div className="form__group">
							<div className="label">
								{t("WITHDRAWAL.MY_WALLETS")}
							</div>
							<WithdrawalWalletSelector />
						</div>
					) : (
						<WithdrawalWalletSelector />
					)}

					<div className="form__group">
						<WithdrawalPaymentSelect />
					</div>

					<FormInputField
						required
						label={"WITHDRAWAL.WITHDRAWAL_MONEY"}
						name="amount"
						className="form__group"
						type="text"
						maxLength={12}
						placeholder="WITHDRAWAL.AMOUNT_PLACEHOLDER"
						pattern={{
							value: /^\d+(\.\d{1,2})?$/,
							message: t(
								"CASINO.BALANCE_TRANSFER.AMOUNT_VALIDATION_ERROR"
							),
						}}
						validate={{
							isInRange: (value: number) => {
								//get payment method object from payment method id
								const currentChosenPaymentMethodId =
									getValues("paymentMethodId");
								const paymentMethodObject =
									paymentMethods?.find(
										(paymentMethod) =>
											paymentMethod.paymentMethodId ===
											currentChosenPaymentMethodId
									);
								//get min and max payout (withdrawal) amount from payment method object
								const minPayoutAmount =
									paymentMethodObject?.minPayout!;
								const maxPayoutAmount =
									paymentMethodObject?.maxPayout!;

								return (
									(value >= minPayoutAmount &&
										value <= maxPayoutAmount) ||
									t<string>(
										"WITHDRAWAL.WITHDRAWAL_MONEY_NOT_IN_RANGE",
										{
											0: minPayoutAmount,
											1: maxPayoutAmount,
										}
									)
								);
							},
						}}
					/>
				</div>

				<div className="card--primary__footer">
					<Button
						className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
						type="submit"
						isDisabled={
							isLoadingProcess ||
							isRedirectingToPaymentPage ||
							paymentMethods == null ||
							paymentMethods.length == 0
						}
						btnText={t<string>("WITHDRAWAL.CONTINUE_BTN")}
						iconClassName="u-icon u-icon--xsml u-icon--arrow--right u-color--core--snow"
					></Button>
				</div>
			</form>
		</FormProvider>
	);
});

const WithdrawalTabSwitcher: FC = observer(function WithdrawalTabSwitcher() {
	const { t } = useTranslation();
	const { culture } = useAppContext();

	return (
		<div className="card--primary__tabs u-padd--x--reset">
			<nav className="tabs--primary">
				<NavLink
					className="tabs--primary__item"
					activeClassName="is-active"
					replace={true}
					to={`/${culture}/withdrawal`}
					isActive={(match, location) => {
						return (
							location.pathname ==
							`/${getCurrentCulture()}/withdrawal`
						);
					}}
				>
					{t<string>("WITHDRAWAL.NEW_WITHDRAWAL")}
				</NavLink>
				<NavLink
					to={`/${culture}/withdrawal/history`}
					className="tabs--primary__item"
					replace={true}
					activeClassName="is-active"
					isActive={(match, location) => {
						return (
							location.pathname ==
							`/${getCurrentCulture()}/withdrawal/history`
						);
					}}
				>
					{t<string>("WITHDRAWAL.MY_WITHDRAWALS")}
				</NavLink>
			</nav>
		</div>
	);
});
