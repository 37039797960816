import React from "react";
import classnames from "classnames";

import { Event, Sport } from "@gp/models";
import { useLiveMatchResult } from "../../../../../hooks/offer";
import { observer } from "mobx-react";

export const LiveMatchResult = observer(function LiveMatchResult(props: {
	isOnHomepage?: boolean;
	isEventFeatured?: boolean;
	isAdditionalOfferVisible?: boolean;
	event: Event;
	sport: Sport;
	isSpotlight?: boolean;
}) {
	const { isScore, home, away } = useLiveMatchResult(
		props.event,
		props.sport
	);

	const {
		isOnHomepage,
		isEventFeatured,
		isAdditionalOfferVisible,
		isSpotlight,
	} = props;

	// if (isAdditionalOfferVisible) {
	// 	return null;
	// }

	if (isSpotlight) {
		return (
			<div className="spotlight__match__result">
				<div className="spotlight__match__home">{home}</div>
				<div className="spotlight__match__away">{away}</div>
			</div>
		);
	}

	return (
		<div
			className={classnames("offer__body__data offer__result", {
				"offer__result--sml": isAdditionalOfferVisible,
				"is-score": isScore,
				"is-selected": isEventFeatured,
			})}
		>
			<div>{home}</div>
			<div>{away}</div>
		</div>
	);
});
