import { SessionStorageProvider } from "@state/providers";
import { ChatViewStore } from "./ChatViewStore";
import {
	ChannelInfoDataFromSession,
	ChatEntryPoint,
	ChatWindowState,
} from "@data-types/chat";
import { action } from "mobx";

const chatSessionExpiryInMs = 600000; //10 mins

export class ChannelSessionHandler {
	chatViewStore: ChatViewStore;

	timeoutId: NodeJS.Timeout | null;

	constructor(chatViewStore: ChatViewStore) {
		this.chatViewStore = chatViewStore;
	}

	onInitialize() {
		document.addEventListener("click", this.clickHandler);
		this.startSession();
	}

	@action.bound
	clickHandler() {
		this.startSession();
	}

	@action.bound
	startSession() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		this.timeoutId = setTimeout(() => {
			this.onChatSessionExpiry();
		}, chatSessionExpiryInMs);
	}

	handleJoiningChannelFromSession() {
		const channelInfo = this.getChannelInfoFromSession();
		if (channelInfo && channelInfo.shouldOpenOnRefresh) {
			this.chatViewStore.onInitialize(
				ChatEntryPoint.SESSION,
				channelInfo
			);
		}
	}

	getChannelInfoFromSession(): ChannelInfoDataFromSession | null {
		const channelInfo = JSON.parse(
			SessionStorageProvider.get("activeChannelInfo") as string
		);
		if (channelInfo && channelInfo?.channelId && channelInfo?.chatState) {
			return channelInfo;
		}
		return null;
	}

	setChannelInfoIntoSession(
		channelId: string,
		chatState: ChatWindowState,
		shouldOpenOnRefresh: boolean = true
	) {
		SessionStorageProvider.set(
			"activeChannelInfo",
			JSON.stringify({
				channelId,
				chatState,
				shouldOpenOnRefresh,
			})
		);
	}

	updateChannelChatStateIntoSession(entriesToReplace: {
		channelId?: string;
		chatState?: ChatWindowState;
		shouldOpenOnRefresh?: boolean;
	}) {
		const oldValues = this.getChannelInfoFromSession();
		if (oldValues) {
			const newValues = Object.assign(oldValues, entriesToReplace);
			this.setChannelInfoIntoSession(
				newValues.channelId,
				newValues.chatState,
				newValues.shouldOpenOnRefresh
			);
		}
	}

	removeSessionChannelInfo() {
		SessionStorageProvider.remove("activeChannelInfo");
	}

	disposeChannelSessionInfo() {
		this.removeSessionChannelInfo();
	}

	@action.bound
	onChatSessionExpiry() {
		this.chatViewStore.closeChat();
	}

	@action.bound
	disposeSessionExpiry() {
		document.removeEventListener("click", this.clickHandler);
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}
	}
}
