import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { useRootAppStore, useRootOfferStore } from "@hooks";
import { EventContext, OfferStoreContext } from "@gp/components";
import { useTranslation } from "react-i18next";
import { AdditionalOfferViewStore } from "@v2/state/offer/components/AdditionalOfferViewStore";

export default observer(function AddOfferMyBetsToggleButton() {
	const rootStore = useRootAppStore();
	const rootOfferStore = useRootOfferStore();
	const store = useContext(OfferStoreContext) as AdditionalOfferViewStore;
	const { t } = useTranslation();

	if (!rootStore.userAuthStore.isLoggedIn) {
		return null;
	}

	const buttonClasses = classNames(
		"at-showBets event__actions__btn event__actions__bets btn btn--xtny btn--primary btn--icon"
	);

	const iconClasses = classNames("u-icon u-icon--sml", {
		"u-icon--my-bets--bold u-color--core--snow": store.isButtonToggled,
		"u-icon--my-bets--alt u-color--core--snow": !store.isButtonToggled,
	});

	return (
		<button
			className={buttonClasses}
			type="button"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (
					rootOfferStore?.myBetsViewStore != null &&
					store.isButtonToggled === true &&
					store.eventId != null
				) {
					rootOfferStore.myBetsViewStore.cashoutStore.disposeSubscription(
						store.eventId
					);
				}
				store.handleUrls(!store.isButtonToggled);
			}}
		>
			<i className={iconClasses} />
			<span className="btn__label">
				{t("MY_BETS.SHOW_MY_BETS_BUTTON")}
			</span>
		</button>
	);
});
