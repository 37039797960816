import React, { useState, useContext, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import classNames from "classnames";
import { MyBetsStoreContext, MyBetsBetContext } from "../../../../context";
import {
	BetCombinations,
	BetDetails,
	BetOfferItem,
	CashoutActions,
	ShareBetSlipButton,
} from "./";
import { CashoutType } from "@gp/hub";
import { Bet } from "@administration/pages/my-bets/model";
import { WalletIcon } from "@v2/components/shared/utility";

export default observer(function BetDetailsItem(props: { bet: Bet | null }) {
	const [showCombinations, setShowCombinations] = useState(false);
	if (!props.bet) {
		return null;
	}

	if (props.bet.isRemoved) {
		return null;
	}

	return (
		<MyBetsBetContext.Provider value={props.bet}>
			<div id={`bet-item-${props.bet.id}`}>
				<div className="logo"></div>
				<BetItemCardHeader bet={props.bet} />

				{/* BET ITEM TABLE */}
				<BetItemTable
					bet={props.bet}
					setShowCombinations={setShowCombinations}
				/>
				{showCombinations == true && (
					<BetCombinations
						setShowCombinations={setShowCombinations}
						showCombinations={showCombinations}
					/>
				)}
			</div>
		</MyBetsBetContext.Provider>
	);
});

// @ts-expect-error
const BetItemCardHeader = observer(function BetItemCardHeader(props) {
	const { bet } = props;

	return (
		<div
			className={classNames("card--primary__header card--bet__header", {
				// "card--bet__title--full": bet.cType !== CashoutType.JACKTIME || bet.betStatus.abrv !== "open" || bet.displayedAmount === null,
			})}
		>
			<button
				className="btn btn--tny btn--ghost btn--icon u-padd--x--reset"
				onClick={App.state.history.goBack}
			>
				<i className="u-icon u-icon--sml u-icon--arrow--left" />
			</button>
			<BetStatus bet={bet} />
			{/* <BetItemActions bet={bet} /> */}
		</div>
	);
});

const BetStatus = observer(function BetStatus(props: { bet: Bet }) {
	const { bet } = props;

	const { t } = useTranslation();

	let text = "";

	if (bet.isLive) {
		text = t("MY_BETS.ITEM.BET_SLIP_NUMBER_LIVE", {
			0: bet.betSlipNumber,
			// 1: bet.betStatus.name,
		});
	} else {
		text = t("MY_BETS.ITEM.BET_SLIP_NUMBER", {
			0: bet.betSlipNumber,
			// 1: bet.betStatus.name,
		});
	}

	// if (bet.shouldShowBetSlipStatus) {
	//     text = text + " (" + bet.betSlipStatus.name + ")";
	// }

	const bettingAccountType =
		App.state.rootStore.userAuthStore.userAccounts?.find(
			(type) => type.accountTypeId === bet.bettingAccountTypeId
		)?.abrv;

	return (
		<div className="card--bet__ticket">
			<div className="card--bet__tags">
				<div className="card--bet__number">
					{t<string>(
						"ACC_STATEMENT.BET_DETAILS.BET_SLIP_NUMBER_SINGLE",
						{
							0: bet.betSlipNumber,
						}
					)}
				</div>

				<span
					className={
						"card--bet__status tag tag--big tag--bet tag--bet--" +
						bet.betStatus.abrv
					}
				>
					{bet.betStatus.name}
					{bet.isLive && (
						<>
							{" "}
							-{" "}
							{t<string>("MY_BETS.ITEM.LIVE_SCORE").toUpperCase()}
						</>
					)}
					{bet.isBonus && <> ({t<string>("COMMON.BONUS")})</>}
				</span>

				{bet.shouldShowBetSlipStatus && (
					<span
						className={
							"card--bet__status tag tag--big tag--slip tag--slip--" +
							bet.betSlipStatus.abrv +
							"--inverted"
						}
					>
						{bet.betSlipStatus.name}
					</span>
				)}

				<WalletIcon
					bettingAccountTypeAbrv={bettingAccountType || ""}
					iconSize="u-icon--med"
					classList="u-mar--right--reset u-mar--left--reset"
				/>
			</div>

			{/* <div className="card--bet__number">{text}</div> */}
		</div>
	);
});

const BetItemActions = observer(function BetItemActions(props: { bet: Bet }) {
	const { bet } = props;

	return (
		<div
			className={classNames("card--bet__actions no-print", {
				"card--bet__actions--full":
					bet.cType !== CashoutType.JACKTIME ||
					bet.betStatus.abrv !== "open" ||
					bet.displayedAmount === null,
			})}
		>
			<CashoutActions />
			<ReuseButton />
			<ShareBetSlipButton bet={bet} />
		</div>
	);
});

const ReuseButton = observer(function ReuseButton() {
	const { t } = useTranslation();
	const { betSlipOffers, id, betSlipNumber, isLive } =
		useContext(MyBetsBetContext);

	const store = useContext(MyBetsStoreContext);

	if (store == null) {
		console.error("Expected store, got null.");
		return null;
	}

	const {
		activeEventsStore,
		reuseBetSlipStore: { openReuseBetSlipModal },
	} = store;

	const hasActiveOffer = betSlipOffers.some((offer) =>
		activeEventsStore.eventsMap.has(offer.eventId)
	);

	if (!hasActiveOffer) {
		return null;
	}

	const reuseClasses = classNames(
		"card--bet__btn card--bet__btn--reuse",
		"btn btn--tny btn--secondary--inverted btn--icon btn--animate-rotate no-print"
	);

	return (
		<button
			type="button"
			className={reuseClasses}
			onClick={(e) =>
				openReuseBetSlipModal({ id, betSlipNumber, isLive })
			}
		>
			<i className="u-icon u-icon--sml u-icon--refresh" />

			<span>{t<string>("MY_BETS.REUSE.BUTTON_LABEL")}</span>
		</button>
	);
});

function BetItemTable(props: {
	bet: Bet;
	setShowCombinations: (value: boolean) => void;
}) {
	const { bet, setShowCombinations } = props;

	const [isPrinting, setIsPrinting] = useState(false);

	return (
		<div className="card--primary__body">
			<BetItemActions bet={bet} />
			<div className="card--bet">
				<table className="table--bets" cellSpacing={0} cellPadding={0}>
					<BetItemTableHead
						bet={bet}
						isPrinting={isPrinting}
						setIsPrinting={setIsPrinting}
					/>
					<tbody className="table--bets__body">
						<BetOffers bet={bet} />

						<BetDetails setShowCombinations={setShowCombinations} />
					</tbody>
				</table>
			</div>
		</div>
	);
}

const BetOffers = observer(function BetOffers(props: { bet: Bet }) {
	if (props.bet.betSlipOffers == null) {
		return null;
	}

	return (
		<>
			{props.bet.betSlipOffers.map((offer) => (
				<BetOfferItem key={offer.id} offer={offer} />
			))}
		</>
	);
});

//#region header

const BetItemTableHead = observer(function BetItemTableHead(props: {
	bet: Bet;
	isPrinting?: boolean;
	setIsPrinting: (value: boolean) => void;
}) {
	const { bet, isPrinting, setIsPrinting } = props;

	const { t } = useTranslation();

	return (
		<thead className="table--bets__head">
			<tr className="table--bets__head__row">
				<th className="u-padd--reset" colSpan={8}>
					<BetItem_HeaderTable
						bet={bet}
						isPrinting={isPrinting}
						setIsPrinting={setIsPrinting}
					/>
				</th>
			</tr>
			<tr className="table--bets__subhead">
				<th className="table--bets__ts table--bets__ts--time">
					{t<string>("MY_BETS.ITEM.TIME_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--combi">
					&nbsp;
				</th>
				<th className="table--bets__ts table--bets__ts--event">
					{t<string>("MY_BETS.ITEM.EVENT_NAME_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--bank">
					&nbsp;
				</th>
				<th className="table--bets__ts table--bets__ts--bet-type">
					{t<string>("MY_BETS.ITEM.TIP_NAME_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--odds">
					{t<string>("MY_BETS.ITEM.QUOTA_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--results">
					{t<string>("MY_BETS.ITEM.RESULT_COLUMN")}
				</th>
				<th className="table--bets__ts table--bets__ts--icon">
					&nbsp;
				</th>
			</tr>
		</thead>
	);
});

const BetItem_HeaderTable = observer(function BetItem_HeaderTable(props: {
	bet: Bet;
	isPrinting?: boolean;
	setIsPrinting: (value: boolean) => void;
}) {
	const { bet, isPrinting, setIsPrinting } = props;

	const { t } = useTranslation();

	// TODO remove jquery
	const startPrint = useCallback(() => {
		// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); //check if safari
		// if (isSafari) {
		//     let printWindow = window.open('', 'Print', 'width=5,height=5,top=200,left=200,toolbars=no,scrollbars=no,status=no,resizable=no');
		//     printWindow.document.write('<!DOCTYPE html>');
		//     printWindow.document.write('<html><head>');
		//     printWindow.document.write('<link rel="stylesheet" href="/css/app.css" />');
		//     printWindow.document.write('</head><body><div className="bet-card is-printing">');
		//     printWindow.document.write(document.querySelector(`#bet-item-${bet.id}`).innerHTML);
		//     printWindow.document.write('</div></body></html>');

		//     printWindow.document.close();
		//     printWindow.focus();
		//     printWindow.print();
		//     printWindow.close();
		// }
		// else {
		//     document.querySelector(`#bet-item-${bet.id}`).classList.add("is-printing");
		//     window.print();
		//     document.querySelector(`#bet-item-${bet.id}`).classList.remove("is-printing");
		// }
		document
			.querySelector(`#bet-item-${bet.id}`)
			?.classList.add("is-printing");
		window.print();
		document
			.querySelector(`#bet-item-${bet.id}`)
			?.classList.remove("is-printing");
	}, []);

	useEffect(() => {
		if (isPrinting) {
			startPrint();
			setIsPrinting(false);
		}
	}, [isPrinting]);

	const [isHover, setIsHover] = useState(false);

	return (
		<table className="table--bets--reset" cellSpacing={0} cellPadding={0}>
			<tbody>
				<tr>
					<th className="table--bets__th table--bets__th--time">
						{t<string>("MY_BETS.ITEM.INFO_DATE")}{" "}
						{DateTime.fromISO(bet.dateCreated).toLocaleString(
							DateTime.DATETIME_SHORT
						)}
					</th>
					<th className="table--bets__th table--bets__th--type">
						<BetItem_HeaderTableType />
					</th>
					<th className="table--bets__th table--bets__th--price">
						{t<string>("MY_BETS.ITEM.INFO_PAYMENT", {
							0: bet.displayPayment,
						})}
					</th>
					<BetItem_Payment bet={bet} />
					<th className="table--bets__th table--bets__th--print">
						<button
							className="btn btn--square--reset btn--ghost no-print"
							type="button"
							onClick={() => setIsPrinting(true)}
							onMouseEnter={() => setIsHover(true)}
							onMouseLeave={() => setIsHover(false)}
						>
							<i
								className={classNames(
									"u-icon u-icon--big u-icon--print",
									{
										"u-color--brand--highlight": isHover,
									}
								)}
							/>
						</button>
					</th>
				</tr>
			</tbody>
		</table>
	);
});

function BetItem_HeaderTableType() {
	const bet = useContext(MyBetsBetContext);

	return <>{bet.formattedBetSystemString}</>;
}
function BetItem_Payment(props: any) {
	const { t } = useTranslation();

	if (
		props.bet.betStatus?.abrv == "won" &&
		(props.bet.betSlipStatus.abrv == "paid-out" ||
			props.bet.betSlipStatus.abrv == "cashout")
	) {
		return (
			<th className="table--bets__th table--bets__th--price">
				{t<string>("MY_BETS.ITEM.PAYOUT", {
					0: props.bet.displayPayout,
				})}
			</th>
		);
	}

	return (
		<th className="table--bets__th table--bets__th--price">
			{t<string>("MY_BETS.ITEM.INFO_MAX_GAIN", {
				0: props.bet.displayMaxGain,
			})}
		</th>
	);
}

//#endregion header
