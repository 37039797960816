import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Checkbox } from "@v2/components/shared/inputs";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { SportInteractionTypeEnum } from "@data-types/analytics/SportInteractionTypeEnum";
import OfferIcon from "@v2/components/shared/icons/Offer";

export const TournamentItems = observer(function TournamentItems(props: {
	store: Parameters<typeof TournamentItem>[0]["store"];
	sport: PrematchOfferMenuStore["menu"];
	category: PrematchOfferMenuStore["menu"];
}) {
	if (props.category.children == null || !props.category.isExpanded) {
		return null;
	}

	return (
		<ul className="nav--quaternary">
			{props.category.children.map((tournament) => (
				<TournamentItem
					key={tournament.node.id}
					tournament={tournament}
					category={props.category}
					sport={props.sport}
					store={props.store}
				/>
			))}
		</ul>
	);
});

export const TournamentItem = observer(function TournamentItem(props: {
	sport: PrematchOfferMenuStore["menu"];
	category: PrematchOfferMenuStore["menu"];
	tournament: PrematchOfferMenuStore["menu"];
	store: {
		toggleUpdateProcessing?: PrematchOfferMenuStore["toggleUpdateProcessing"];
		type: "PrematchOfferMenuStore" | "LiveOfferMenuStore";
	};
}) {
	return (
		<li className="nav--quaternary__item">
			<div
				className={classNames("nav--quaternary__link", {
					"is-active": props.tournament.checkState === 1,
				})}
			>
				<span>
					<Checkbox
						id={`sport-${props.sport.node.id}-category-${props.category.node.id}-tournament-${props.tournament.node.id}`}
						className={`at--${props.tournament.node.abrv} input--check`}
						name={props.tournament.node.name}
						title={props.tournament.node.name}
						checked={props.tournament.checkState === 1}
						indeterminate={props.tournament.checkState === 2}
						// @ts-expect-error
						onChange={(e) => {
							e.stopPropagation();
							if (props.store.toggleUpdateProcessing != null) {
								props.store.toggleUpdateProcessing();
							}
							if (props.store.type === "PrematchOfferMenuStore") {
								App.state.updateUrlParams({ page: 1 }, true);
							}
							props.tournament.onToggleCheck();
							if (props.tournament.checkState === 1) {
								AnalyticsService.logSportInteraction({
									category: window.location.pathname.includes(
										"live"
									)
										? SportInteractionTypeEnum.LiveTournament
										: SportInteractionTypeEnum.PrematchTournament,
									label: `${props.tournament.parent?.parent?.node.abrv}-${props.tournament.parent?.node.abrv}-${props.tournament.node.abrv}`,
								});
							}
							window.scrollTo(0, 0);
						}}
					/>
					<label
						htmlFor={`sport-${props.sport.node.id}-category-${props.category.node.id}-tournament-${props.tournament.node.id}`}
					>
						<OfferIcon
							type="tournament"
							sport={props.sport.node.abrv || ""}
							category={props.category.node.abrv || ""}
							tournament={props.tournament.node.abrv || ""}
							className="nav--tertiary__icon u-icon u-icon--med u-mar--right--micro"
							treeIcon={true}
						/>
						<span>{props.tournament.node.name}</span>
					</label>
				</span>
				<span className="nav--quaternary__counter counter counter--base counter--simple">
					{props.tournament.node.count}
				</span>
			</div>
		</li>
	);
});
