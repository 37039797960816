import React, { useEffect, useRef } from "react";

// @ts-expect-error
export function Checkbox(props) {
	const { indeterminate = false, ...otherProps } = props;

	const checkbox = useRef(null);

	function updateDeterminateProperty() {
		// @ts-expect-error
		checkbox.current.indeterminate = indeterminate;
	}

	useEffect(() => {
		updateDeterminateProperty();
	});

	return <input {...otherProps} ref={checkbox} type="checkbox" />;
}
