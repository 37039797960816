import React, { useContext, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react";
import { OfferStoreContext } from "@gp/components";
import { ExpandedOfferMenu, ExpandedPeriodMenu, MainMenu } from "./components";
import { Period } from "@state/common/PeriodConverter";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { FavoritesMenuItems } from "./components/FavoritesMenuItems";
import { useRootAppStore } from "@hooks";

export const PrematchOfferMenu = observer(function PrematchOfferMenu(props: {
	prematchOfferMenuStore: PrematchOfferMenuStore;
}) {
	const match = useRouteMatch<{ period: Period; segments: string }>();
	const favoritesStore = useContext(FavoritesStoreContext);
	const favoritesPage = match.url.includes("my-favorites");
	const rootStateStore = useRootAppStore();

	useEffect(() => {
		props.prematchOfferMenuStore?.setRouterMatchObj(match);
		props.prematchOfferMenuStore?.onInitialize();
		if (favoritesPage) {
			props.prematchOfferMenuStore.openFavoritesMenu();
		}

		return () => props.prematchOfferMenuStore?.onDispose();
	}, []);

	useEffect(() => {
		props.prematchOfferMenuStore?.setRouterMatchObj(match);
		if (favoritesStore.period != match.params.period) {
			favoritesStore.setPeriod(match.params.period);
		}
	}, [match]);

	useEffect(() => {
		rootStateStore.quickPlayStore.quickBetStore.initializeMenuStore(
			props.prematchOfferMenuStore
		);

		return () => {
			rootStateStore.quickPlayStore.quickBetStore.removeMenuStore();
		};
	}, []);

	return (
		// @ts-expect-error
		<OfferStoreContext.Provider value={props.prematchOfferMenuStore}>
			<MainMenu store={props.prematchOfferMenuStore} />
			<ExpandedMenuWrapper store={props.prematchOfferMenuStore} />
		</OfferStoreContext.Provider>
	);
});

const ExpandedMenuWrapper = observer(function ExpandedMenuWrapper(props: {
	store: {
		isOfferMenuOpen: boolean;
		toggleUpdateProcessing: PrematchOfferMenuStore["toggleUpdateProcessing"];
		periodSubMenu: {
			isPeriodMenuExpanded: boolean;
			setPeriodKey: (p: Period) => void;
			periodMenu: { period: Period; name: string }[];
			selectedPeriodKey: Period;
		};
		isEmpty: boolean;
		isLoading: boolean;
		selectedSport: PrematchOfferMenuStore["selectedSport"];
		menu: PrematchOfferMenuStore["menu"];
		isFavoritesSubMenuOpen: boolean;
	};
}) {
	if (props.store.isOfferMenuOpen) {
		//@ts-expect-error
		return <ExpandedOfferMenu store={props.store} />;
	}

	if (props.store.periodSubMenu.isPeriodMenuExpanded) {
		return <ExpandedPeriodMenu store={props.store} />;
	}
	if (props.store.isFavoritesSubMenuOpen) {
		return <FavoritesMenuItems store={props.store} />;
	}

	return null;
});
