import { useUser } from "@v2/hooks";

export function getShouldOfferBonus() {
	const userStore = useUser();

	return userStore.isPrimaryUserOnline
		? userStore.user?.shouldOfferBonus
		: userStore.secondaryUser?.shouldOfferBonus;
}

export function getBonusDeclineStatus() {
	const userStore = useUser();

	return userStore.isPrimaryUserOnline
		? userStore.user?.isBonusDeclined
		: userStore.secondaryUser?.isBonusDeclined;
}

export function getDepositBonusInfo() {
	const userStore = useUser();

	return userStore.isPrimaryUserOnline
		? userStore.user?.depositBonusInfo
		: userStore.secondaryUser?.depositBonusInfo;
}

export function shouldShowMarketplaceBonus() {
	const depositBonusInfoData = getDepositBonusInfo();

	return (
		!getShouldOfferBonus() ||
		getBonusDeclineStatus() ||
		depositBonusInfoData == null
	);
}
