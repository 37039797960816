import MitIdRegistrationStore from "@v2/state/registration/MitIdRegistrationStore";
import { useContext, createContext } from "react";

export const MitIdRegistrationStoreContext = createContext(
	{} as MitIdRegistrationStore
);

export const useMitIdRegistrationStore = () => {
	return useContext(MitIdRegistrationStoreContext);
};
