import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { CheckboxField } from "../../shared/inputs";

export default function TermsAndNewsletter() {
	const { t } = useTranslation();
	const label = (
		<Trans>
			{t("MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRMATION_TEXT_PART1")}{" "}
			<a
				className="u-anchor u-anchor--underline u-anchor--primary"
				href={`/assets/docs/terms/${t(
					"specific:INFO_PAGES.TERMS_AND_CONDITIONS.TERMS_PDF"
				)}`}
				target="_blank"
			>
				{t<string>(
					"MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRMATION_TEXT_TERMS"
				)}
			</a>{" "}
			{t("MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRMATION_TEXT_PART2")}
		</Trans>
	);
	return (
		<>
			{WEBPACK_IS_RECEIVING_PROMO_MAIL_ENABLED && (
				<div className="row u-mar--bottom--xsml">
					<CheckboxField
						id="isReceivingPromoEmailsEnabled"
						name="isReceivingPromoEmailsEnabled"
						label="MEMBERSHIP.ACCOUNT_ACTIVATION.NEWSLETTER_LABEL"
						checkboxClassname="col col-sml-12 form__field u-mar--top--xsml"
					/>
				</div>
			)}

			<div className="row">
				<CheckboxField
					id="confirmationCheckbox"
					name="confirmationCheckbox"
					customLabel={label}
					checkboxClassname="col col-sml-12 form__field"
					astersisksClass
					required
				/>
			</div>
		</>
	);
}
