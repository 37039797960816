export enum SportInteractionTypeEnum {
	PrematchSport = "pSport",
	PrematchCategory = "pCat",
	PrematchTournament = "pLeague",
	PrematchEvent = "pEvent",
	LiveSport = "lSport",
	LiveCategory = "lCat",
	LiveTournament = "lLeague",
	LiveEvent = "lEvent",
	TopTournament = "tt",
	LiveMenuConfirm = "live-betting-menu-confirm",
	PrematchMenuConfirm = "prematch-betting-menu-confirm",
}
