import { useTranslation } from "react-i18next";
import { useLoading } from "@hooks";
import { useNotification } from "../../../../ui/common/hooks";
import {
	recoverPasswordService,
	RecoverPasswordFormData,
} from "@v2/data-access/membership/recover-password";
import { getCurrentCulture } from "@utils";
import { setTabTitle } from "@utils";

export function useRecoverPassword(
	reset: () => void,
	setIsRecoverPasswordSuccess: (value: boolean) => void
) {
	const { showErrorWithRedirect } = useNotification();
	const { setIsLoading } = useLoading();
	const { showError } = useNotification();

	const { t } = useTranslation();

	const urlParams = new URLSearchParams(App.state.history.location.search);

	const redirectToHome = () => {
		window.location.href = `/${getCurrentCulture()}/home/full/highlights`;
	};

	const redirectToLogin = () => {
		window.location.href = `/${getCurrentCulture()}/auth/login`;
	};

	const onInitialize = async () => {
		setTabTitle(t("MEMBERSHIP.FORGOT_PASSWORD.TITLE"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
		try {
			setIsLoading(true);
			const tokenFromURL = urlParams.get("passwordRecoveryToken");
			const userNameFromURL = urlParams.get("username");
			await recoverPasswordService.checkPasswordRecoveryToken(
				tokenFromURL || "",
				userNameFromURL || ""
			);
		} catch (error) {
			showErrorWithRedirect(error?.error?.message, [
				{
					btnText: "MEMBERSHIP.RECOVER_PASSWORD.BACK_TO_HOME_PAGE",
					redirectFunction: redirectToHome,
				},
				{
					btnText: "MEMBERSHIP.RECOVER_PASSWORD.LOGIN",
					redirectFunction: redirectToLogin,
				},
			]);
		} finally {
			setIsLoading(false);
		}
	};

	const onSubmitData = async (formData: RecoverPasswordFormData) => {
		const tokenFromURL = urlParams.get("passwordRecoveryToken");
		const userNameFromURL = urlParams.get("username");
		setIsLoading(true);
		try {
			const response = await recoverPasswordService.recoverPassword(
				tokenFromURL || "",
				userNameFromURL || "",
				formData
			);
			if (response.success == true) {
				setIsRecoverPasswordSuccess(true);
			}
		} catch (error) {
			console.error(error);
			showError("MEMBERSHIP.RECOVER_PASSWORD." + error?.error?.message);
			reset();
		} finally {
			setIsLoading(false);
		}
	};
	return {
		onInitialize,
		onSubmitData,
	};
}
