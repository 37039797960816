import React from "react";
import {
	ChatCloseButton,
	ChannelInfoContainer,
	ChatMinimizeButton,
	ChatUserActionsButton,
	ChannelSportsHeader,
} from "@features/chat/desktop";

export default function ChatHeader() {
	return (
		<div className="chat__header">
			<ChannelSportsHeader />

			<div className="chat__header__actions">
				{/* <ChatUserActionsButton /> */}
				<ChatMinimizeButton />
				<ChatCloseButton />
			</div>
		</div>
	);
}
