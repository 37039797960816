import { observable, action, computed } from "mobx";

import { getCurrentCulture } from "@utils";
import RootOfferStore from "@offer/stores/RootStore";

// TODO duplicated from MyBetsViewStore.js
// @ts-expect-error
import MyBetsStore from "./MyBetsStore.ts";
import LoaderStore from "@state/stores/common/LoaderStore.js";
import { Bet } from "./model/Bet.js";
import ModalService from "@services/modals/ModalService";

type ModalData = {
	id?: string;
	betSlipNumber?: string;
	isLive?: boolean;
	userId?: string;
};

export class ReuseBetSlipStore {
	rootStore: RootOfferStore;
	myBetsStore: MyBetsStore;
	loader: LoaderStore;
	constructor(rootStore: RootOfferStore, myBetsStore: MyBetsStore) {
		this.rootStore = rootStore;
		this.myBetsStore = myBetsStore;
	}

	//#region observables

	/** Array of eventIds */

	@observable isReuseModalOpen = false;
	@observable reuseModalData: ModalData | null;

	//#endregion observables

	@computed get isLoadingProcess(): boolean {
		return this.loader.isLoadingProcess;
	}

	//#region modals

	@action.bound
	reuseBetSlip({ id, isLive }: { id: string; isLive?: boolean }) {
		// this.rootStore.prematchOfferMenuStore.closeOfferMenu();

		if (isLive) {
			App.state.redirect(
				`/${getCurrentCulture()}/live/events?bid=${id}&accType=${
					this.myBetsStore.activeAccountOwner
				}`
			);
		} else {
			App.state.redirect(
				`/${getCurrentCulture()}/sports/full?bid=${id}&accType=${
					this.myBetsStore.activeAccountOwner
				}`
			);
		}

		this.closeReuseBetSlipModal();
	}

	/**
	 * Opens reuse bet slip modal
	 * @param {{id, betSlipNumber}} modalData
	 */
	@action.bound
	openReuseBetSlipModal(modalData: ModalData) {
		this.isReuseModalOpen = true;
		this.reuseModalData = modalData;
		ModalService.onOpen();
	}

	/**
	 * Closes reuse bet slip modal
	 */
	@action.bound
	closeReuseBetSlipModal() {
		this.isReuseModalOpen = false;
		this.reuseModalData = null;
	}

	//#endregion modals

	//#region disposers

	@action.bound
	onDispose() {
		this.isReuseModalOpen = false;
		this.reuseModalData = null;
	}

	//#endregion disposers
}
