import React from "react";
import classNames from "classnames";

import { Bet } from "@administration/pages/my-bets/model";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ISOToDisplayCode } from "@utils";

export default observer(function MyBetsAutosellButton(props: {
	bet: Bet;
	openAutosellModal: (bet: Bet) => void;
	isAutoCashoutEnabled: boolean;
}) {
	const { bet, openAutosellModal, isAutoCashoutEnabled } = props;
	const { t } = useTranslation();

	if (!isAutoCashoutEnabled) {
		return null;
	}

	const autoCashoutButtonClasses = classNames(
		"card--bet__btn card--bet__btn--autosell btn btn--tny btn--block btn--secondary--inverted btn--icon u-mar--top--tny",
		{
			"card--bet__btn--autosell-set": bet.isAutoCashoutSet ? true : false,
		}
	);

	const autoCashoutIconClasses = classNames("u-icon u-icon--xsml", {
		"u-icon--settings": !bet.isAutoCashoutSet,
		"u-icon--set-stake": bet.isAutoCashoutSet,
	});

	if (
		isAutoCashoutEnabled &&
		bet.displayedAmount !== undefined &&
		bet.displayedAmount !== null &&
		bet.displayedAmount !== "0.00"
	) {
		return (
			<button
				className={autoCashoutButtonClasses}
				type="button"
				onClick={(e) => openAutosellModal(bet)}
			>
				<i className={autoCashoutIconClasses} />
				<span>
					{t("MY_BETS.CASHOUT.AUTO_CASHOUT")}{" "}
					{bet.isAutoCashoutSet && bet.betSlipSecureWinSetting != null
						? `${bet.betSlipSecureWinSetting.threshold.toFixed(
								2
						  )} ${ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)}`
						: ""}
				</span>
			</button>
		);
	} else {
		return null;
	}
});
