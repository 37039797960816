export type ChannelDto = {
	channelId: string;
	displayName: string;
	sortOrder?: number | null;
	sportAbrv?: string;
	categoryAbrv?: string;
	tournamentAbrv?: string;
};

export type ChannelDataResponseDto = {
	channels: ChannelDto[];
	message: string | null;
};

export type ChannelDataDto = {
	channelResourceType: ChannelResourceTypeDto;
	agencyId: string;
	resourceId: string;
	culture: string;
};

export enum ChannelResourceTypeDto {
	EVENT = 0,
	TOURNAMENT = 1,
	SPORT = 2,
}

export type ChannelIconsDto = {
	sportAbrv?: string;
	categoryAbrv?: string;
	tournamentAbrv?: string;
};

export type UserSessionStatusDto = {
	success: boolean;
};
