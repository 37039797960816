import { useMemo, useEffect } from "react";
import LmtLsStore from "./LmtLsEventStore";

import { useRootOfferStore } from "@hooks";
export default function useLmtLsEventStore() {
	const hub = useRootOfferStore().hub;
	const store = useMemo(() => new LmtLsStore(hub), []);
	useEffect(() => {
		store.onInitialize();
		return () => store.onDispose();
	}, []);
	return store;
}
