import React from "react";
import { observer } from "mobx-react";
import { useFormContext, useFormState } from "react-hook-form";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { RequiredMark, ErrorMessage } from "./";

export default observer(function GroupedDropdownOptions(props) {
	const {
		groupData,
		data,
		name,
		required,
		message,
		placeholder,
		label,
		labelClassName,
		className,
		...rest
	} = props;

	const { register } = useFormContext();

	const { errors } = useFormState();
	const { t } = useTranslation();

	let error = get(errors, name);

	const inputClass = classNames("input input--base input--select", {
		"input-validation-error": error != undefined,
	});

	return (
		<div className={className}>
			<label className={classNames("label", labelClassName)}>
				{t(label)} <RequiredMark required={required} />
			</label>
			<ErrorMessage name={name} />
			<select
				name={name}
				className={inputClass}
				{...register(name, {
					required: {
						value: required,
						message: message || "COMMON.REQUIRED_FIELD",
					},
				})}
				{...rest}
			>
				{placeholder ? (
					<option value="">{t(placeholder)}</option>
				) : null}
				{groupData?.map((group) => (
					<optgroup key={group.id} label={group.name}>
						{data
							.filter(
								(content) =>
									group.id === content.occupationGroupId
							)
							.map((content) => (
								<option key={content.id} value={content.id}>
									{content.name}
								</option>
							))}
					</optgroup>
				))}
			</select>
		</div>
	);
});
