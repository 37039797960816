import React, { useContext, useState } from "react";
import classNames from "classnames";
import { LookupContext } from "@gp/components";
import { openTournamentStatistics } from "@utils";

export default function TournamentStatisticsButton(props: {
	tournament: {
		id: string;
	};
	isAdditionalOfferVisible?: boolean;
}) {
	const {
		tournament: { id: tournamentId },
		isAdditionalOfferVisible,
	} = props;

	const { tournaments } = useContext(LookupContext);

	const tournament = tournaments.get(tournamentId);
	const srSeasonId = tournament?.srSeasonId;

	const [isHover, setIsHover] = useState(false);

	if (srSeasonId == null) {
		return null;
	}

	const buttonCLasses = classNames(
		"jqLeagueStats offer__stats btn btn--square",
		{
			"btn--square--tny": !isAdditionalOfferVisible,
			"btn--square--reset": isAdditionalOfferVisible,
		},
		"btn--ghost",
		{
			"u-mar--left--xtny": isAdditionalOfferVisible,
		}
	);

	const iconClasses = classNames("offer__stats__icon u-icon u-icon--med", {
		"u-icon--action--stats--active": !isHover && srSeasonId != null,
		"u-icon--action--stats--selected": isHover && srSeasonId != null,
		"u-icon--action--stats--disabled": srSeasonId == null,
	});

	return (
		<button
			className={buttonCLasses}
			type="button"
			disabled={srSeasonId == null}
			onClick={() => openTournamentStatistics(srSeasonId)}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<i className={iconClasses} />
		</button>
	);
}
