import {
	computed,
	autorun,
	action,
	runInAction,
	observable,
	reaction,
} from "mobx";

import {
	BettingOfferManagementHub as Hub,
	BettingOfferManagementHubOptions,
	HubConnectionFactory,
	IConnection,
	HubState,
} from "@gp/hub";

import { getCurrentLanguage } from "@utils";
import { logger } from "@state";
import { UserAuthStore } from "@v2/state/shared";

/** This is not expected to be disposed during use. */
export class OfferHubStore {
	@observable public hub: Hub;
	@observable public isSessionExpired: boolean = false;
	private connection: IConnection;
	private userAuthStore: UserAuthStore;
	private _disconnectDebounce: ReturnType<typeof setTimeout> | undefined;

	@computed public get connectionState() {
		return this.connection?.state;
	}

	@computed private get connectionFactory() {
		const query = new URLSearchParams();
		query.append("shopId", this.userAuthStore.shopId);
		query.append("agency", this.userAuthStore.agencyKey);
		query.append("version", encodeURIComponent(WEBPACK_OFFER_VERSION));
		query.append("language", getCurrentLanguage());
		query.append("callerEnvironment", WEBPACK_ENVIRONMENT_KEY);

		const connectionFactory = new HubConnectionFactory(
			{ get: () => query.toString() },
			{
				serverUrl: WEBPACK_BASE_PROXY_ADDRESS + "sync",
				enableLogging: false,
			}
		);

		return connectionFactory;
	}

	constructor(userAuthStore: UserAuthStore) {
		this.userAuthStore = userAuthStore;

		autorun(() => {
			if (this.hub == null || this.isSessionExpired) {
				return;
			}

			if (this.hub.dispatchers.size === 0) {
				this.destroy();
				document.removeEventListener("click", this.clickHandler);
				return;
			}

			document.addEventListener("click", this.clickHandler);
			this.startSession();
		});

		reaction(
			() => this.connectionFactory,
			(connectionFactory) => {
				const prevHubState =
					this.connection?.state || HubState.NOT_INITIALIZED;
				logger.logInfo("Create new connection.");
				// Disconnect prev connection
				this.connection?.disconnect();
				this.connection = connectionFactory.createConnection();

				// Don't move this it's removes name from connection
				this.hub?.initialize(this.connection);

				switch (prevHubState) {
					case HubState.CONNECTING:
					case HubState.CONNECTED:
					case HubState.AUTO_RECONNECT:
					case HubState.RECONNECTING:
					case HubState.ERROR:
					case HubState.RECONNECTING_AFTER_ERROR:
						logger.logTrace(
							"Autorun offer hub connect",
							this._disconnectDebounce
						);
						if (this.hub.dispatchers.size > 0) {
							this.startSession();
						}
					default:
					// Empty
				}
			}
		);

		const options = new BettingOfferManagementHubOptions();
		this.connection = this.connectionFactory.createConnection();
		this.connection.connect();
		this.hub = new Hub(options, this.connection);
		this.hub.initialize();
	}

	@action.bound
	public restartSession() {
		this.isSessionExpired = false;
	}

	@action.bound
	public startSession() {
		logger.logTrace("Offer hub start session call.");

		if (this.isSessionExpired) {
			// Requires manual session restart.
			return;
		}

		this.restartSession();

		this.connection.connect().then(() => {
			clearTimeout(this._disconnectDebounce);
			this._disconnectDebounce = undefined;

			this._disconnectDebounce = setTimeout(() => {
				runInAction(() => {
					if (this.hub.dispatchers.size === 0) {
						logger.logError("There are no dispatchers");
						return;
					}

					logger.logInfo("Offer hub session expire");
					this.connection.disconnect();
					this.isSessionExpired = true;
				});
			}, 600_000);
		});
	}

	@action.bound
	private destroy() {
		logger.logTrace("Offer hub disconnect call.");
		clearTimeout(this._disconnectDebounce);
		this._disconnectDebounce = setTimeout(() => {
			runInAction(() => {
				if (this.hub.dispatchers.size > 0) {
					logger.logError("There are dispatchers");
				}
				logger.logInfo("Offer hub disconnect");
				this.connection.disconnect();
				this.isSessionExpired = false;
			});
		}, 600_000);
	}

	@action.bound
	private clickHandler = () => {
		if (this.hub.dispatchers.size > 0) {
			this.startSession();
		}
	};
}
