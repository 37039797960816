import {
	observable,
	autorun,
	computed,
	action,
	runInAction,
	reaction,
} from "mobx";
import {
	CashoutHub,
	CashoutHubOptions,
	HubConnectionFactory,
	IConnection,
	HubState,
} from "@gp/hub";

import { getCurrentLanguage } from "@utils";
import { logger } from "@state";
import { UserAuthStore } from "@v2/state/shared";
import RootStore from "@state/stores/RootStore";
import { StorageStateKeysEnum } from "@utils/storage";

export class CashoutHubStore {
	@observable public hub: CashoutHub;
	@observable public isSessionExpired: boolean = false;
	private connection: IConnection;
	private rootStore: RootStore;
	private _disconnectDebounce: ReturnType<typeof setTimeout> | undefined;

	@computed private get connectionFactory() {
		const query = new URLSearchParams();
		query.append("shopId", this.rootStore.userAuthStore.shopId);
		query.append("agency", this.rootStore.userAuthStore.agencyKey);
		query.append("version", encodeURIComponent(WEBPACK_OFFER_VERSION));
		query.append("language", getCurrentLanguage());
		query.append("callerEnvironment", WEBPACK_ENVIRONMENT_KEY);

		const connectionFactory = new HubConnectionFactory(
			{ get: () => query.toString() },
			{
				serverUrl: WEBPACK_CASHOUT_PROXY_URL + "sync",
				enableLogging: false,
			}
		);

		return connectionFactory;
	}

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@action.bound
	public async initializeCashoutHub() {
		if (!WEBPACK_IS_JACK_TIME_ENABLED) {
			return this;
		}

		if (
			!this.rootStore.userAuthStore.user &&
			!this.rootStore.localStorageProvider.get(
				StorageStateKeysEnum.USER_KEY
			)
		) {
			return;
		}

		autorun(() => {
			if (this.hub == null || this.isSessionExpired) {
				return;
			}
			if (this.rootStore.userAuthStore.user == null) {
				return;
			}

			if (this.hub.dispatchers.size === 0) {
				this.destroy();
				document.removeEventListener("click", this.clickHandler);
				return;
			}

			document.addEventListener("click", this.clickHandler);
			this.startSession();
		});

		reaction(
			() => this.connectionFactory,
			(connectionFactory) => {
				const prevHubState =
					this.connection?.state || HubState.NOT_INITIALIZED;
				// Disconnect prev connection
				this.connection?.disconnect();
				if (!this.rootStore.userAuthStore.user) {
					return null;
				}
				this.connection = connectionFactory.createConnection();
				logger.logInfo("Create new connection cash out.");

				this.hub?.initialize(this.connection);

				switch (prevHubState) {
					case HubState.CONNECTING:
					case HubState.CONNECTED:
					case HubState.AUTO_RECONNECT:
					case HubState.RECONNECTING:
					case HubState.ERROR:
					case HubState.RECONNECTING_AFTER_ERROR:
						logger.logTrace(
							"Autorun cache out hub connect",
							this._disconnectDebounce
						);
						if (this.hub.dispatchers.size > 0) {
							this.startSession();
						}
					default:
					// Empty
				}
			}
		);

		const options = new CashoutHubOptions();
		this.connection = this.connectionFactory.createConnection();
		this.hub = new CashoutHub(options, this.connection);
		this.hub.initialize();
		this.connection.connect();
	}

	@action.bound
	public restartSession() {
		this.isSessionExpired = false;
	}

	@action.bound
	private startSession() {
		if (!WEBPACK_IS_JACK_TIME_ENABLED) {
			return;
		}
		logger.logTrace("Cache out hub start call.");

		if (this.isSessionExpired) {
			// Requires manual session restart.
			return;
		}

		this.restartSession();
		clearTimeout(this._disconnectDebounce);
		this._disconnectDebounce = undefined;

		this.connection.connect();

		this._disconnectDebounce = setTimeout(() => {
			runInAction(() => {
				logger.logInfo("Cache out hub session expire.");
				this.connection.disconnect();
				if (this.hub.dispatchers.size > 0) {
					this.isSessionExpired = true;
				} else {
					logger.logInfo(
						"Cash out hub session expire without dispatchers."
					);
				}
			});
		}, 600_000);
	}

	@action.bound
	private destroy() {
		logger.logTrace("Cache out hub disconnect call!");
		clearTimeout(this._disconnectDebounce);
		this._disconnectDebounce = setTimeout(() => {
			runInAction(() => {
				logger.logInfo("Cash out hub disconnected.");
				this.connection.disconnect();
				this.isSessionExpired = false;
			});
		}, 600_000);
	}

	@action.bound
	public destroyConnectionOnUserLogout() {
		logger.logTrace("Cache out hub disconnect call!");
		clearTimeout(this._disconnectDebounce);
		logger.logInfo("Cash out hub disconnected.");
		this.connection.disconnect();
		this.isSessionExpired = false;
	}

	@action.bound
	private clickHandler = () => {
		if (this.hub.dispatchers.size > 0) {
			this.startSession();
		}
	};
}
