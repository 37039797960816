import { ErrorResponse, Response } from "../../shared";
import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const UserDataApiService = lazy(loadFailPath, ()=>import("@api-services/user/UserDataApiService"));

export class AcceptNewTermsService {
	async acceptRegulation() {
		try {
			await (await UserDataApiService).default.acceptRegulation();
			return new Response();
		} catch (error) {
			console.log(error);
			throw new ErrorResponse("GENERIC_ERROR");
		}
	}
}
