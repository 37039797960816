import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Button } from "@components/buttons";
import { DateTime } from "luxon";
import OfferIcon from "@v2/components/shared/icons/Offer";

export default observer(function CustomMessagePreview() {
	const chatViewStore = App.state.rootStore.chatViewStore;
	const { t } = useTranslation();

	if (!chatViewStore.sharedBetSlipData) {
		return null;
	}

	const {
		eventCount,
		maxCoefficient,
		dateCreated,
		sportAbrv,
		firstEventName,
	} = chatViewStore.sharedBetSlipData;

	return (
		<div className="chat__slip">
			<div className="chat__slip__header">
				<OfferIcon
					type="sport"
					sport={sportAbrv}
					className="chat__icon__primary u-icon u-icon--lrg"
				/>
				<span className="chat__slip__count">{eventCount}</span>
				<span className="chat__slip__title">{firstEventName}</span>

				<Button
					btnType="button"
					onClick={() => {
						chatViewStore.setSharedBetSlipData(null);
					}}
					iconOnly
					iconClassName="u-icon--sml u-icon--close u-color--brand--highlight"
					className="btn--square btn--square--sml btn--secondary--inverted u-mar--left--auto"
				/>
			</div>

			<div className="chat__slip__footer">
				<span>
					{t("BET_SLIP.MAX_COEF")}: <strong>{maxCoefficient}</strong>
				</span>
				<span className="u-color--text--tertiary">{dateCreated}</span>
			</div>
		</div>
	);
});
