export const LiveStreamConfigWeb: VideoPlayerConfig = {
	areControlsEnabled: false,
	allowFullScreen: false,
	allowPlayBack: false,
	isAutoPlayEnabled: true,
	playerWidth: "100%",
	playerHeight: "100%",
	maxWidth: getMaxViewAxis(),
	playerInlineStyle: { margin: "auto" },
	isMuteOnShowEnabled: true,
	isTablet: false,
	pip: true,
};

export const LiveStreamConfigMobile: VideoPlayerConfig = {
	areControlsEnabled: false,
	isAutoPlayEnabled: true,
	allowFullScreen: true,
	allowPlayBack: false,
	playerWidth: "100%",
	playerHeight: "100%",
	playerInlineStyle: { margin: "auto" },
	isMuteOnShowEnabled: true,
	isTablet: isTablet(),
};

export function getMaxViewAxis() {
	if (
		document.documentElement.clientHeight >
		document.documentElement.clientWidth
	) {
		return "33vh";
	}
	return "33vw";
}

export function isTablet() {
	return (
		Math.min(
			document.documentElement.clientWidth,
			document.documentElement.clientHeight
		) >= 600
	);
}

export type VideoPlayerConfig = {
	areControlsEnabled: boolean;
	allowFullScreen: boolean;
	allowPlayBack: boolean;
	isAutoPlayEnabled: boolean;
	playerWidth: string;
	playerHeight: string;
	playerInlineStyle: Object;
	isMuteOnShowEnabled: boolean;
	isTablet: boolean;
	maxWidth?: string;
	pip?: boolean;
};
