import React from "react";
import { observer } from "mobx-react";
import { UserAccount } from "@data-types";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";

export const WalletItem = observer(function WalletItem({
	wallet,
	isDisabled = false,
	isDefaultChecked = false,
	shouldRegister = true,
	hidden = false,
	showRadioSelectIfDisabled = false,
}: {
	wallet: UserAccount | undefined;
	isDisabled?: boolean;
	isDefaultChecked?: boolean;
	shouldRegister?: boolean;
	hidden?: boolean;
	showRadioSelectIfDisabled?: boolean;
}) {
	const { t } = useTranslation();
	const { register } = useFormContext();

	if (wallet == null) {
		return null;
	}

	//we dont want to register this input when it shows already submitted wallet
	const field =
		shouldRegister &&
		register("bettingAccountTypeId", {
			required: true,
		});

	if (hidden) {
		return (
			<input
				className="input--radio"
				type="radio"
				id={wallet.accountTypeId}
				{...field}
				value={wallet.accountTypeId}
				disabled={isDisabled}
				defaultChecked={isDefaultChecked}
				hidden={hidden}
			/>
		);
	}

	return (
		<li className={`wallet__item wallet__item--${wallet.abrv}`}>
			<input
				className={classNames("input--radio", {
					"is-disabled": isDisabled && !showRadioSelectIfDisabled,
				})}
				type="radio"
				id={wallet.accountTypeId}
				{...field}
				value={wallet.accountTypeId}
				disabled={isDisabled}
				defaultChecked={isDefaultChecked}
			/>
			<label
				htmlFor={wallet.accountTypeId}
				className={`wallet__account wallet__account--${wallet.abrv} wallet__account--menu`}
			>
				<span className="wallet__type">
					<i
						className={`wallet__icon u-icon u-icon--sml u-icon--wallet u-icon--wallet--${wallet.abrv}`}
					/>
					<span className="wallet__name">
						{t<string>(`HEADER.${wallet.abrv.toUpperCase()}`)}
					</span>
				</span>
				<span className="wallet__balance">
					<span className="wallet__amount">{wallet.balance}</span>
					<span className="wallet__currency">
						{wallet.currency.displayCode}
					</span>
				</span>
			</label>
		</li>
	);
});
