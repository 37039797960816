import { Duration } from "luxon";

export function formatLoginLimitTime(
	dailyLoginLimit: number,
	intervalType: IntervalType
) {
	const limitInterval = Duration.fromMillis(
		dailyLoginLimit * 60000 * intervalType
	)
		.shiftTo("days", "hours", "minutes")
		.toObject();
	if (!dailyLoginLimit) {
		return null;
	}
	const timeObject: TtimeInterval = {};
	if (limitInterval.days && limitInterval.days > 0) {
		timeObject.days = {
			text:
				limitInterval.days > 1
					? "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_DAYS"
					: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_DAY",
			amount: limitInterval.days,
		};
	}

	if (limitInterval.hours && limitInterval.hours > 0) {
		timeObject.hours = {
			text:
				limitInterval.hours > 1
					? "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_HOURS"
					: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_HOUR",
			amount: limitInterval.hours,
		};
	}

	if (limitInterval.minutes && limitInterval.minutes > 0) {
		timeObject.minutes = {
			text:
				limitInterval.minutes > 1
					? "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_MINUTES"
					: "specific:USER.ACCOUNT_SETTINGS.MY_LIMITS.TIME_INTERVAL.INTERVAL_MINUTE",
			amount: limitInterval.minutes,
		};
	}

	return timeObject;
}

export enum IntervalType {
	Month = 30,
	Week = 7,
}

type TtimeInterval = {
	days?: TtimeIntervalObject;
	hours?: TtimeIntervalObject;
	minutes?: TtimeIntervalObject;
};

type TtimeIntervalObject = {
	text: string;
	amount: number;
};
