import React, { FC, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
	useForm,
	FormProvider,
	useFormContext,
	useWatch,
} from "react-hook-form";

import GamingHistoryStoreContext from "@v2/context/GamingHistoryStoreContext";
import { DatePicker } from "@v2/components/desktop/shared/inputs";
import { GamingHistoryRawFilterData } from "@data-types/gaming-history";

const LiveCasinoFilterForm: FC = observer(function LiveCasinoFilterForm() {
	const { t } = useTranslation();
	const methods = useForm();
	const { register, handleSubmit, reset } = methods;

	const {
		betPeriods,
		disposeCurrentGamingHistoryState,
		onLiveCasinoInitialize,
		games,
		gamingHistoryFilterStore: { setRawFilterData, setDefaultFilterData },
	} = useContext(GamingHistoryStoreContext);

	const onSubmit = (data: GamingHistoryRawFilterData) => {
		setRawFilterData({
			...data,
		});
	};

	useEffect(() => {
		const fetchData = async () => {
			await onLiveCasinoInitialize();
		};

		if (location.search !== "") {
			const params = App.state.getUrlParamsAsObj();
			// @ts-expect-error
			reset(params);
		} else {
			disposeCurrentGamingHistoryState();
		}
		fetchData();

		return setDefaultFilterData;
	}, []);

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} className="form">
				<div className=" card--primary__body">
					<div className="form__field">
						{games.length > 0 ? (
							<>
								<label
									htmlFor="liveCasinoGame"
									className="label"
								>
									{t<string>(
										"specific:GAMING_HISTORY.FILTER.SELECT_CASINO_GAME"
									)}
								</label>
								<select
									id="liveCasinoGame"
									className="input input--base input--select"
									{...register("liveGameTableGroup")}
								>
									<option value="all">
										{t<string>(
											"specific:GAMING_HISTORY.FILTER.ALL_CASINO_GAMES"
										)}
									</option>
									{games.map((game) => (
										<option key={game} value={game}>
											{game}
										</option>
									))}
								</select>
							</>
						) : null}
					</div>

					<div className="form__field">
						<label htmlFor="liveCasinoPeriod" className="label">
							{t<string>(
								"GAMING_HISTORY.FILTER.SELECT_GAME_ROUND_STATUS"
							)}
						</label>
						<select
							id="liveCasinoGameRoundStatus"
							className="input input--base input--select"
							defaultValue={"all"}
							{...register("roundStatus")}
						>
							<option value="all">
								{t<string>("GAMING_HISTORY.FILTER.STATUS.ALL")}
							</option>
							<option value="1">
								{t<string>(
									"GAMING_HISTORY.FILTER.STATUS.WON_BETS"
								)}
							</option>
							<option value="2">
								{t<string>(
									"GAMING_HISTORY.FILTER.STATUS.LOST_BETS"
								)}
							</option>
							<option value="3">
								{t<string>(
									"GAMING_HISTORY.FILTER.STATUS.CANCELLED_BETS"
								)}
							</option>
						</select>
					</div>

					<div className="form__field">
						<label htmlFor="liveCasinoPeriod" className="label">
							{t<string>("GAMING_HISTORY.FILTER.SELECT_PERIOD")}
						</label>
						<select
							id="liveCasinoPeriod"
							className="input input--base input--select"
							{...register("period")}
						>
							{betPeriods.map((betPeriod) => (
								<option
									key={betPeriod.value}
									value={betPeriod.value}
								>
									{t<string>(
										`GAMING_HISTORY.FILTER.PERIODS.${betPeriod.name}`
									)}
								</option>
							))}
						</select>
					</div>

					<CustomPeriodInput />
				</div>

				<div className="card--primary__footer">
					<button className="btn btn--lrg btn--primary" type="submit">
						{t<string>("GAMING_HISTORY.FILTER.SUBMIT_BUTTON")}
					</button>
				</div>
			</form>
		</FormProvider>
	);
});

const CustomPeriodInput: FC = function CustomPeriodInput() {
	const { setValue, getValues } = useFormContext();
	const currentPeriod = useWatch({ name: "period" });
	const {
		gamingHistoryFilterStore: { rawData },
	} = useContext(GamingHistoryStoreContext);

	if (currentPeriod !== "custom") {
		return null;
	} else {
		setValue("from", rawData.from);
		setValue("to", rawData.to);
	}

	return (
		<div>
			<DatePicker
				label="MY_BETS.PAGE.FILTER_FORM.START_DATE"
				name="from"
				className="form__field"
				required
			/>

			<DatePicker
				label="MY_BETS.PAGE.FILTER_FORM.END_DATE"
				name="to"
				className="form__field"
				validate={{
					isBiggerThanStartDate: (value: string) =>
						value > getValues("from") ||
						"COMMON.ERROR_MESSAGES.END_DATE_LOWER",
				}}
				required
			/>
		</div>
	);
};

export default LiveCasinoFilterForm;
