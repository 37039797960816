import React from "react";
import { WithdrawalContext } from "@context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { WithdrawalResponseItem } from "@api-types/withdrawal";
import { observer } from "mobx-react";
import { WithdrawalHistoryTableItem } from "./WithdrawalHistoryTableItem";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export const MyWithdrawalsTable = observer(function MyWithdrawalsTable() {
	const { withdrawalList } = useContext(WithdrawalContext);
	const { t } = useTranslation();

	if (withdrawalList == null || withdrawalList.item.length === 0) {
		return (
			<InfoMessageComponent
				type="note"
				message={t<string>("WITHDRAWAL.TABLE.EMPTY")}
				addClassName="u-mar--top--med"
				textClass="message__text"
			/>
		);
	}

	return (
		<table className="table--primary u-mar--top--med">
			<thead className="table--primary__header table--primary__header--light">
				<tr className="table--primary__header__row">
					<th className="table--primary__header__data">ID</th>
					<th className="table--primary__header__data">
						{t<string>("WITHDRAWAL.TABLE.HEADER.AMOUNT")}
					</th>
					<th className="table--primary__header__data">
						{t<string>("WITHDRAWAL.TABLE.HEADER.METHOD")}
					</th>
					<th className="table--primary__header__data">
						{t<string>("WITHDRAWAL.TABLE.HEADER.START_DATE")}
					</th>
					<th className="table--primary__header__data">
						{t<string>("WITHDRAWAL.TABLE.HEADER.END_DATE")}
					</th>
					<th className="table--primary__header__data u-type--center">
						{t<string>("Wallet")}
					</th>
					<th className="table--primary__header__data">
						{t<string>("WITHDRAWAL.TABLE.HEADER.STATUS")}
					</th>
				</tr>
			</thead>
			<tbody className="table--primary__body">
				{withdrawalList.item.map(
					(withdrawalHistoryItem: WithdrawalResponseItem) => {
						return (
							<WithdrawalHistoryTableItem
								key={withdrawalHistoryItem.id}
								withdrawalHistoryItem={withdrawalHistoryItem}
							/>
						);
					}
				)}
			</tbody>
		</table>
	);
});
