import React, { useState } from "react";
import { DeviceDetector } from "@utils";

type Props = {
	image?: string;
	onLoad?: () => void;
	className?: string;
	wrapperClassName?: string;
	page?: string;
	fallback?: string;
};

export default function PromoContentImage(props: Props) {
	const [errorSrcCount, setErrorSrcCount] = React.useState(-1);
	React.useEffect(() => {
		setErrorSrcCount(-1);
	}, [props]);

	const [isFallbackSrcSet, setIsFallbackSrcSet] = useState<boolean>(false);

	const sources = useGetSources(props);

	return (
		<picture className={props.wrapperClassName}>
			{sources
				// Error count counts how many urls returned error from start of the list
				.filter((src, idx) => idx > errorSrcCount)
				.map((src) => (
					<source key={src} srcSet={src} />
				))}
			<img
				onError={() => {
					setErrorSrcCount(1 + errorSrcCount);
					setIsFallbackSrcSet(true);
				}}
				src={
					isFallbackSrcSet
						? `/assets/images/promo-content-fallbacks/${props.fallback}`
						: ""
				}
				onLoad={props.onLoad}
				className={props.className}
				alt=""
			/>
		</picture>
	);
}

function useGetSources(params: Props): string[] {
	return React.useMemo(() => getBaseSources(params), [params]);
}

function getBaseSources(params: Props): string[] {
	const device = DeviceDetector.isMobileTheme ? "mobile" : "desktop";
	if (params.page == "casino") {
		return [
			`${WEBPACK_CDN_ROOT_URL}/web/${params.page}/promo/${device}/${params.image}`,
		];
	} else if (params.page == "news") {
		return [
			`${WEBPACK_CDN_ROOT_URL}/marketing/web/${params.page}/${device}/${params.image}`,
		];
	} else {
		return [
			`${WEBPACK_CDN_ROOT_URL}/web/${params.page}/${device}/${params.image}`,
		];
	}
}
