import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Currency, Modal } from "@v2/components/shared";
import { useFixBody } from "@v2/hooks/shared";
import { CashoutStore } from "@administration/pages/my-bets/CashoutStore";
import { Button } from "@components/buttons";

export default observer(function MyBetsAutosellConfirmation(props: {
	store: CashoutStore;
}) {
	const t = useTranslation().t;
	const { isAutosellModalOpen, isAutoCashoutEnabled } = props.store;

	useFixBody(true, isAutosellModalOpen);

	if (!isAutoCashoutEnabled || !isAutosellModalOpen) {
		return null;
	}

	return (
		<Modal id="myBetsAutosellConfirmation">
			<div className="popup">
				<div className="popup__card">
					<div className="popup__header">
						{t("MY_BETS.CASHOUT.AUTO_CASHOUT_HEADER_TITLE")}
					</div>
					<div className="popup__body">
						<ModalBody store={props.store} />
					</div>
					<div className="popup__footer">
						<ModalFooter store={props.store} />
					</div>
				</div>
			</div>
		</Modal>
	);
});

const ModalBody = observer(function ModalBody(props: { store: CashoutStore }) {
	const t = useTranslation().t;
	const { store } = props;
	const {
		minThreshold,
		maxThreshold,
		isAutocashoutUnavailable,
		isMinThresholdValid,
		isMaxThresholdValid,
		isAutosellModalCashedOut,
		autosellModalCashoutGain,
		isConfigurationExists,
		isAutosellFormValid,
		handleChange,
		secureWinAmount,
	} = store;

	if (store.openedAutosellModalBetSlipId == null) {
		console.error("Expected cashout modal, got null.");
		return null;
	}

	const thresholdInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			thresholdInputRef.current?.focus();
		}, 1);
		return () => clearTimeout(timer);
	}, []);

	if (isAutosellModalCashedOut) {
		return (
			<div className="message--base message--success--alt">
				{t("MY_BETS.CASHOUT.SUCCESS_MESSAGE", {
					amount: autosellModalCashoutGain,
					currency: App.state.rootStore.userAuthStore.currency,
				})}
			</div>
		);
	} else if (isAutocashoutUnavailable) {
		return (
			<>
				{isConfigurationExists && (
					<div className="form__field">
						<label
							htmlFor="definedAutoCashoutAmount"
							className="label"
						>
							{t("MY_BETS.CASHOUT.SECURE_WIN_DESCRIPTION")}
						</label>
						<input
							id="definedAutoCashoutAmount"
							className="input input--base"
							type="number"
							value={secureWinAmount}
							disabled
						/>
					</div>
				)}
				<div className="message--base message--warning">
					{t("MY_BETS.CASHOUT.AUTO_CASHOUT_UNAVAILABLE")}
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className="form__field">
					<label htmlFor="setAutoCashoutAmount" className="label">
						{t("MY_BETS.CASHOUT.SECURE_WIN_DESCRIPTION")}:
					</label>
					<input
						id="setAutoCashoutAmount"
						className={classNames("input input--base", {
							"input-validation-error": !isAutosellFormValid,
						})}
						type="number"
						step=".01"
						min="0"
						value={secureWinAmount}
						// @ts-expect-error
						onChange={handleChange}
						ref={thresholdInputRef}
					/>
				</div>
				<div className="form__field u-mar--bottom--reset u-type--sml">
					<div
						className={classNames("row", {
							"u-type--color--text--secondary":
								isMinThresholdValid,
							"u-type--color--error": !isMinThresholdValid,
						})}
					>
						<div className="col col-sml-6">
							{t("MY_BETS.CASHOUT.MIN")}:
						</div>
						<div className="col col-sml-6 u-type--right">
							{minThreshold} <Currency component="span" />
						</div>
					</div>
					<div
						className={classNames("row", {
							"u-type--color--text--secondary":
								isMaxThresholdValid,
							"u-type--color--error": !isMaxThresholdValid,
						})}
					>
						<div className="col col-sml-6">
							{t("MY_BETS.CASHOUT.MAX")}:
						</div>
						<div className="col col-sml-6 u-type--right">
							{maxThreshold} <Currency component="span" />
						</div>
					</div>
				</div>
			</>
		);
	}
});

const ModalFooter = observer(function ModalFooter(props: {
	store: CashoutStore;
}) {
	const t = useTranslation().t;
	const { store } = props;

	const {
		isAutocashoutUnavailable,
		isAutosellModalCashedOut,
		closeAutosellModal,
		isConfigurationExists,
		removeSecureWinConfiguration,
		setSecureWinConfiguration,
		isSaveBtnDisabled,
		secureWinAmount,
	} = store;

	const [shouldSaveButtonBeDisabled, setShouldSaveButtonBeDisabled] =
		useState<boolean>(false);
	const [shouldDeleteButtonBeDisabled, setShouldDeleteButtonBeDisabled] =
		useState<boolean>(false);
	const openedAutosellModalBetSlipId = store.openedAutosellModalBetSlipId;

	if (isAutosellModalCashedOut) {
		return (
			<button
				type="button"
				className="popup__footer__btn btn btn--sml btn--secondary--inverted"
				onClick={closeAutosellModal}
			>
				{t("COMMON.CLOSE")}
			</button>
		);
	} else if (isAutocashoutUnavailable) {
		if (openedAutosellModalBetSlipId == null) {
			console.error("Expected cashout modal, got null.");
			return null;
		}

		return (
			<>
				{isConfigurationExists && (
					<div className="popup__footer__left">
						<button
							type="button"
							className="popup__footer__btn btn btn--sml btn--secondary--inverted u-color--ui--danger"
							disabled
						>
							{t("COMMON.DELETE")}
						</button>
					</div>
				)}

				<div className="popup__footer__right">
					<button
						type="button"
						className="popup__footer__btn btn btn--sml btn--secondary--inverted"
						onClick={closeAutosellModal}
					>
						{t("COMMON.CLOSE")}
					</button>

					<Button
						type="button"
						className="popup__footer__btn btn btn--sml btn--primary u-mar--left--tny"
						onClick={async (e) => {
							await setSecureWinConfiguration(
								openedAutosellModalBetSlipId,
								Number(secureWinAmount)
							);
						}}
						isDisabled={isSaveBtnDisabled}
						btnText={t("COMMON.SAVE")}
					></Button>
				</div>
			</>
		);
	} else {
		if (openedAutosellModalBetSlipId == null) {
			console.error("Expected cashout modal, got null.");
			return null;
		}
		return (
			<>
				{isConfigurationExists && (
					<div className="popup__footer__left">
						<Button
							type="button"
							className="popup__footer__btn btn btn--sml btn--secondary--inverted u-color--ui--danger"
							onClick={async (e) => {
								setShouldDeleteButtonBeDisabled(true);
								await removeSecureWinConfiguration(
									openedAutosellModalBetSlipId
								);
								setShouldDeleteButtonBeDisabled(false);
							}}
							isDisabled={shouldDeleteButtonBeDisabled}
							btnText={t("COMMON.DELETE")}
						></Button>
					</div>
				)}

				<div className="popup__footer__right">
					<button
						type="button"
						className="popup__footer__btn btn btn--sml btn--secondary--inverted"
						onClick={closeAutosellModal}
					>
						{t("COMMON.CANCEL")}
					</button>

					<Button
						type="button"
						className="popup__footer__btn btn btn--sml btn--primary u-mar--left--tny"
						onClick={async (e) => {
							setShouldSaveButtonBeDisabled(true);
							await setSecureWinConfiguration(
								openedAutosellModalBetSlipId,
								Number(secureWinAmount)
							);
							setShouldSaveButtonBeDisabled(false);
						}}
						isDisabled={
							isSaveBtnDisabled || shouldSaveButtonBeDisabled
						}
						btnText={t("COMMON.SAVE")}
					></Button>
				</div>
			</>
		);
	}
});
