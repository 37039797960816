import { ChannelResourceTypeDto } from "@api-types/chat";

export enum ChatEntryPoint {
	OFFER,
	SESSION,
	LIST,
	BET_SLIP_SHARE,
	SUPPORT,
	USER_MENU,
}

export enum ChatWindowState {
	OPENED = "opened",
	MINIMIZED = "minimized",
}

export enum ChatFlagState {
	FLAGGED = "flagged",
	UNFLAGGED = "unflagged",
}

export type ChannelInfoDataFromOffer = {
	channelType: ChannelResourceTypeDto;
	resourceId: string;
	sportAbrv?: string;
	categoryAbrv?: string;
	tournamentAbrv?: string;
};

export type ChannelInfoDataFromList = {
	channelId: string;
	displayName: string;
	sportAbrv?: string;
	categoryAbrv?: string;
	tournamentAbrv?: string;
};

export type ChannelInfoDataFromSession = {
	channelId: string;
	chatState: ChatWindowState;
	shouldOpenOnRefresh: boolean;
};

export type ActiveSharedBetSlip = {
	eventCount: number;
	maxCoefficient: number;
	betSlipNumber: string;
	dateCreated: string;
	sportAbrv: string;
	firstEventName: string;
};
