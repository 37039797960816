import React from "react";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { DateTime } from "luxon";

export default function RegistrationPersonalNumber(props: {
	labelClassName?: string;
}) {
	const { getValues } = useFormContext();
	const translationHook = useTranslation();

	return (
		<FormInputField
			className="form__field"
			name="personalIdentificator"
			label="MEMBERSHIP.REGISTRATION.PERSONAL_NUMBER_LABEL"
			labelClassName={props.labelClassName}
			popoverMsg={"MEMBERSHIP.REGISTRATION.PERSONAL_NUMBER_DESCRIPTION"}
			placeholder={translationHook.t(
				"MEMBERSHIP.REGISTRATION.PERSONAL_NUMBER_PLACEHOLDER"
			)}
			maxLength={12}
			pattern={{
				value: /^[0-9]{12}$/u,
				message: "MEMBERSHIP.REGISTRATION.INVALID_PERSONAL_NUMBER",
			}}
			validate={{
				isValid: (value: string) => {
					const year = value.substring(0, 4);
					const month = value.substring(4, 6);
					const day = value.substring(6, 8);
					const date = DateTime.fromObject({
						year: parseInt(year),
						month: parseInt(month),
						day: parseInt(day),
					});

					const dob = DateTime.fromISO(getValues("dob"));

					if (
						date.startOf("day").toISODate() !==
						dob.startOf("day").toISODate()
					) {
						return translationHook.t<string>(
							"MEMBERSHIP.REGISTRATION.INVALID_PERSONAL_NUMBER"
						);
					}
				},
			}}
			required
		/>
	);
}
