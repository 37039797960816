import { CountriesLookupModel } from "@api-types";
import { OasisPersonalDetailsListModel } from "@data-types/account-settings/OasisLockDto";
import React from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import {
	DatePicker,
	FormInputField,
	FormSelectField,
} from "../../shared/inputs";

class OasisPersonalDetailsPropTypes {
	personalDetails: OasisPersonalDetailsListModel[] | [];
	countries: CountriesLookupModel[];
}

export default function OasisPersonalDetails(
	props: OasisPersonalDetailsPropTypes
) {
	const { t } = useTranslation();

	const { personalDetails, countries } = props;

	const maxDate = DateTime.now().minus({ years: 18 }).toJSDate();
	const minDate = DateTime.now().minus({ years: 100 }).toJSDate();

	return (
		<div className="form__group">
			<div className="row">
				<div className="col col-sml-12 form__field">
					<div className="u-type--base u-type--wgt--medium">
						{t(
							"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.PROFILE_DETAILS_MISSING"
						)}
					</div>
				</div>
				{personalDetails.map((item) => {
					const splitLabelWithCapitalLetter =
						item.fieldName.split(/(?=[A-Z])/);
					const labelWithUpperCase = splitLabelWithCapitalLetter
						.join("_")
						.toUpperCase();
					if (item.fieldName === "birthday") {
						return (
							<div
								className="col col-sml-12 form__field"
								key={item.fieldName}
							>
								<DatePicker
									label={t(
										`USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.${labelWithUpperCase}_LABEL`
									)}
									name="personalDetails.birthday"
									className="form__field"
									id="birthday"
									defaultValue={DateTime.now().toString()}
									maxDate={maxDate}
									minDate={minDate}
									required
								/>
							</div>
						);
					} else if (item.fieldName === "country") {
						return (
							<div
								className="col col-sml-12 form__field"
								key={item.fieldName}
							>
								<FormSelectField
									label={t(
										`USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.${labelWithUpperCase}_LABEL`
									)}
									name="personalDetails.country"
									className="form__field"
									dropdownData={
										countries ? countries : undefined
									}
									required
								/>
							</div>
						);
					} else {
						return (
							<div
								className="col col-sml-12 form__field"
								key={item.fieldName}
							>
								<FormInputField
									id={item.fieldName}
									name={`personalDetails.${item.fieldName}`}
									className="form__field"
									label={t(
										`USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.${labelWithUpperCase}_LABEL`
									)}
									required
								/>
							</div>
						);
					}
				})}
			</div>
		</div>
	);
}
