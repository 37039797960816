import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	OfferStoreContext,
	LookupContext,
	SportOfferContext,
	EventContext,
} from "@gp/components";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import { GroupedEventOffer } from "../../../grouped-event-offer";

import { EventTime, TeamName, ScoreSummary } from "../../../details";
import { useRootOfferStore } from "@hooks";

import { MyBetsTab } from "../../../common/myBetsAdditionalOffer/MyBetsTab";
import OfferIcon from "@v2/components/shared/icons/Offer";
import {
	LiveWidgetContainer,
	LiveStreamConfigWeb,
} from "@features/live-widgets/shared";
import { SportOffer } from "@gp/offer";
import { ProviderNameEnum } from "@gp/models";

export const EventBody = observer(function EventBody() {
	//#region hooks

	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext) as SportOffer;
	const store = useContext(OfferStoreContext);
	const offerStore = useRootOfferStore();

	const teamOneName =
		store.lookupsStore.teams.get(event.teamOneId || "")?.name || "";
	const teamTwoName =
		store.lookupsStore.teams.get(event.teamTwoId || "")?.name || "";

	const { addWidget, removeWidget, findActiveWidget, toggleLmtWidget } =
		useLiveWidgetStore();

	const matchId: string | null =
		event.source?.matchId && !event.isOutright
			? event.source.matchId
			: null;

	useEffect(() => {
		if (!event.isLive) return;

		addWidget(
			event.source!.providerName,
			event.liveStreamStatus,
			matchId,
			event.id,
			teamOneName,
			teamTwoName,
			"SIDE",
			sport.abrv,
			true
		);

		return () => {
			removeWidget(event.id, "EVENT_OFFER");
			offerStore.myBetsViewStore.cashoutStore.disposeSubscription(
				event.id
			);
		};
	}, []);

	//#endregion hooks

	const isActive = findActiveWidget(event.id, "EVENT_OFFER");

	return (
		<div className="event__body event--page__body u-padd--reset">
			<EventStatistics />

			<div
				className={classNames("event__stream", {
					"is-active": isActive,
					"is-inactive": !isActive,
				})}
			>
				{/*
				 * This DOM node has to be present when widget is activated.
				 * This is required by sport radar widget function.
				 */}
				<LiveWidgetContainer
					eventId={event.id}
					location="EVENT_OFFER"
					lmtProps={{
						divProps: {
							className: classNames(
								"event__stream__widget widget--stream",
								{
									"widget--betradar":
										event.source?.providerName ===
										ProviderNameEnum.BetRadar,
									"widget--statscore":
										event.source?.providerName ===
										ProviderNameEnum.LSports,
								}
							),
						},
					}}
					lsProps={{
						divProps: {
							className:
								"event__stream__widget widget--stream is-live",
						},
						config: LiveStreamConfigWeb,
					}}
				/>
			</div>

			{/* OFFER FILTER */}
			<GroupedEventOffer />
			<MyBetsTab />
		</div>
	);
});

const EventStatistics = observer(function EventStatistics(props: {
	isMultiEvent?: boolean;
}) {
	const { isMultiEvent } = props;

	const { t } = useTranslation();

	//#region hooks

	const sport = useContext(SportOfferContext);
	const event = useContext(EventContext);

	const {
		// @ts-expect-error
		rootStore: {
			eventSwitcherViewStore: { moveLeft, moveRight, eventIds },
		},
	} = useContext(OfferStoreContext);

	const { categories, tournaments, teams } = useContext(LookupContext);

	//#endregion hooks

	const category = categories.get(event.sportCategoryId);
	const tournament = tournaments.get(event.tournamentId);

	const homeTeam = teams.get(event.teamOneId || "");
	const awayTeam = teams.get(event.teamTwoId || "");

	const competitorClasses = classNames("event__competitor", {
		"u-padd--reset": !isMultiEvent,
		"event__competitor--sml": isMultiEvent,
	});

	return (
		<div className="event__match">
			<EventSwitcherButton action={moveLeft} direction={"left"} />

			<div className="event__match__content event__match__middle">
				<div className="event__tournament event__tournament--centered">
					<OfferIcon
						type="sport"
						// @ts-expect-error
						sport={event.sport.abrv}
						className="event__icon event__icon--sport u-icon u-icon--med"
					/>
					{/* @ts-expect-error */}
					<span>{event.sport.name}</span>

					<span className="u-mar--x--xtny">/</span>

					<OfferIcon
						type="category"
						sport={sport.abrv}
						category={category?.abrv || ""}
						className="event__icon event__icon--category u-icon u-icon--med"
					/>
					{/* @ts-expect-error */}
					<span>{category.name}</span>

					<span className="u-mar--x--xtny">/</span>

					{tournament?.iconUrl && (
						<OfferIcon
							type="tournament"
							sport={sport.abrv}
							category={category?.abrv || ""}
							tournament={tournament.abrv}
							className="event__icon event__icon--tournament u-icon u-icon--med"
						/>
					)}
					{/* @ts-expect-error */}
					<span>{tournament.name}</span>
				</div>

				<div className="event__details">
					{!homeTeam || !awayTeam ? (
						<div className="event__competitor">
							<OfferIcon
								type="sport"
								sport={sport.abrv}
								className="u-icon u-icon--xxxlrg u-icon--xxxlrg--responsive u-icon--rounded"
							/>
							<div className="event__competitor__name">
								{event.name}
							</div>
						</div>
					) : (
						<>
							<div className={competitorClasses}>
								<TeamName team={homeTeam} isEventTitle={true} />
								{window.location.pathname.includes("live") && (
									<div
										className="event__score u-type--wgt--medium"
										// title={event.currentScore?.home || 0}
									>
										{event.currentScore?.home || 0}
									</div>
								)}
							</div>

							<div className="event__details__inner">
								<EventTime isEventView={true} />
								{event.hasLiveCoverage && !event.isLive && (
									<span className="event__details__tag tag tag--med tag--primary">
										{t<string>("COMMON.LIVE")}
									</span>
								)}
							</div>

							<div className={competitorClasses}>
								{window.location.pathname.includes("live") && (
									<div
										className="event__score u-type--wgt--medium"
										// title={event.currentScore?.away || 0}
									>
										{event.currentScore?.away || 0}
									</div>
								)}
								<TeamName team={awayTeam} isEventTitle={true} />
							</div>
						</>
					)}
				</div>

				{event.isLive && <ScoreSummary isPage={true} />}
			</div>

			<EventSwitcherButton action={moveRight} direction={"right"} />
		</div>
	);
});

const EventSwitcherButton = observer(function EventSwitcherButton(props: {
	action: (eventIds: string) => void;
	direction: "left" | "right";
}) {
	const params = useParams<{ eventIds: string }>();

	const {
		eventSwitcherViewStore: { onInitialize, onDispose },
	} = useRootOfferStore();
	const rootOfferStore = useRootOfferStore();

	useEffect(() => {
		onInitialize();
		return () => onDispose();
	}, []);

	if (
		!rootOfferStore.eventSwitcherViewStore.isAvailable ||
		params.eventIds.split("/").length > 1
	) {
		return null;
	}

	return (
		<button
			className={
				"event__arrow event__arrow--" +
				props.direction +
				" btn btn--square btn--square--tny btn--secondary--inverted"
			}
			type="button"
			onClick={() => props.action(params.eventIds)}
		>
			<i
				className={
					"u-icon u-icon--xsml u-icon--arrow--" + props.direction
				}
			/>
		</button>
	);
});
