import React, { FC, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DepositContext } from "@context";
import { DepositProcess } from "@data-types";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import {
	DepositBonusConfirmation,
	DepositMultiStepFooter,
	DepositPasswordConfirmation,
	DepositPaymentMethodSelection,
	DepositWalletSelector,
	SelectedDepositAmount,
	SelectedDepositPaymentMethod,
	SelectedDepositWallet,
} from "./components";
import { getCurrentCulture } from "@utils";
import { useAppContext } from "@v2/hooks";
import { Link, NavLink } from "react-router-dom";
import Button from "@components/buttons/Button";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export const NewDepositBody = observer(function NewDepositBody() {
	const { t } = useTranslation();
	const {
		initializeNewDepositProcess,
		finalizeMultiStepDeposit,
		isPasswordConfirmation,
		isBonusConfirmation,
		isMultiStepDeposit,
		isStoreInitialized,
		onlineWallet,
		paymentMethods,
		resetMultiStepFlags,
		isRedirectingToPaymentPage,
		isLoadingProcess,
		paymentMethodError,
		shouldShowWalletSelectors,
	} = useContext(DepositContext);

	const methods = useForm({
		defaultValues: {
			bettingAccountTypeId: onlineWallet?.accountTypeId,
			paymentMethodId: "",
			amount: "",
			isBonusAccepted: false,
		},
	});
	const { handleSubmit, getValues, watch, trigger } = methods;

	useEffect(() => {
		return () => {
			resetMultiStepFlags();
		};
	}, []);

	useEffect(() => {
		if (
			isStoreInitialized &&
			getValues("paymentMethodId") != "" &&
			getValues("amount") != ""
		) {
			trigger("amount");
		}
	}, [watch("paymentMethodId")]);

	if (!isStoreInitialized) {
		return null;
	}

	if (paymentMethodError) {
		return (
			<div className="at-depositFormMultiPwd card--primary">
				<div className="card--primary__header">
					<h1 className="card--primary__title title--primary">
						{t<string>("DEPOSIT.TITLE")}
					</h1>
				</div>
				<DepositTabSwitcher />
				<div className="card--primary__body">
					<InfoMessageComponent
						type="warning"
						message={paymentMethodError.message}
						textClass="message__text"
					/>
				</div>
				<div className="card--primary__footer">
					<DepositErrorMessageFooter
						errorCode={paymentMethodError.errorCode}
					/>
				</div>
			</div>
		);
	}

	const onSubmit = async (data: DepositProcess) => {
		initializeNewDepositProcess(data);
		window.scrollTo(0, 0);
	};

	const onMultiStepSubmit = async (data: {
		isBonusAccepted?: boolean;
		password?: string;
		bettingAccountTypeId?: string;
	}) => {
		finalizeMultiStepDeposit(data);
	};

	if (isMultiStepDeposit && isPasswordConfirmation && isBonusConfirmation) {
		return (
			<FormProvider {...methods}>
				<form
					id="multiStepForm"
					className="at-depositFormMultiPwdBonus card--primary"
					onSubmit={handleSubmit(onMultiStepSubmit)}
				>
					<div className="card--primary__header">
						<h1 className="card--primary__title title--primary">
							{t<string>("DEPOSIT.TITLE")}
						</h1>
					</div>

					<DepositTabSwitcher />

					<div className="card--primary__body">
						<div className="form__section">
							{shouldShowWalletSelectors && (
								<div className="form__group">
									<SelectedDepositWallet />
								</div>
							)}
							<div className="form__group">
								<SelectedDepositPaymentMethod />
							</div>
							<div className="form__group">
								<SelectedDepositAmount />
							</div>
						</div>

						<div className="form__section">
							<DepositBonusConfirmation />
						</div>

						<div className="form__section">
							<DepositPasswordConfirmation />
						</div>
					</div>

					<div className="card--primary__footer">
						<DepositMultiStepFooter />
					</div>
				</form>
			</FormProvider>
		);
	}

	if (isMultiStepDeposit && isPasswordConfirmation) {
		return (
			<FormProvider {...methods}>
				<form
					id="multiStepForm"
					className="at-depositFormMultiPwd card--primary"
					onSubmit={handleSubmit(onMultiStepSubmit)}
				>
					<div className="card--primary__header">
						<h1 className="card--primary__title title--primary">
							{t<string>("DEPOSIT.TITLE")}
						</h1>
					</div>

					<DepositTabSwitcher />

					<div className="card--primary__body">
						<div className="form__section">
							{shouldShowWalletSelectors && (
								<div className="form__group">
									<SelectedDepositWallet />
								</div>
							)}
							<div className="form__group">
								<SelectedDepositPaymentMethod />
							</div>
							<div className="form__group">
								<SelectedDepositAmount />
							</div>
						</div>

						<div className="form__section">
							<DepositPasswordConfirmation />
						</div>
					</div>

					<div className="card--primary__footer">
						<DepositMultiStepFooter />
					</div>
				</form>
			</FormProvider>
		);
	}

	if (isMultiStepDeposit && isBonusConfirmation) {
		return (
			<FormProvider {...methods}>
				<form
					id="multiStepForm"
					className="at-depositFormMultiBonus card--primary"
					onSubmit={handleSubmit(onMultiStepSubmit)}
				>
					<div className="card--primary__header">
						<h1 className="card--primary__title title--primary">
							{t<string>("DEPOSIT.TITLE")}
						</h1>
					</div>

					<DepositTabSwitcher />

					<div className="card--primary__body">
						<div className="form__section">
							{shouldShowWalletSelectors && (
								<div className="form__group">
									<SelectedDepositWallet />
								</div>
							)}
							<div className="form__group">
								<SelectedDepositPaymentMethod />
							</div>
							<div className="form__group">
								<SelectedDepositAmount />
							</div>
						</div>

						<div className="form__section">
							<DepositBonusConfirmation />
						</div>
					</div>

					<div className="card--primary__footer">
						<DepositMultiStepFooter />
					</div>
				</form>
			</FormProvider>
		);
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="card--primary">
					<div className="card--primary__header">
						<h1 className="card--primary__title title--primary">
							{t<string>("DEPOSIT.TITLE")}
						</h1>
					</div>

					<DepositTabSwitcher />

					<div className="card--primary__body">
						{shouldShowWalletSelectors ? (
							<section className="form__section">
								<div className="u-mar--bottom--med u-type--med">
									{t("DEPOSIT.SELECT_DEPOSIT_WALLET")}
								</div>

								<span className="label">
									{t("DEPOSIT.MY_WALLETS")}
								</span>
								<DepositWalletSelector />
							</section>
						) : (
							<DepositWalletSelector />
						)}

						<section className="form__section">
							<div className="u-mar--bottom--med u-type--med">
								{t<string>("DEPOSIT.SELECT_PAYMENT_METHOD")}
							</div>
							<DepositPaymentMethodSelection />
						</section>

						<section className="form__section">
							<div className="u-mar--bottom--med u-type--med">
								{t("DEPOSIT.DEPOSIT_MONEY_TO_ACCOUNT")}
							</div>

							<FormInputField
								required
								label={"DEPOSIT.DEPOSIT_MONEY"}
								name="amount"
								className="form__group"
								type="text"
								pattern={{
									value: /^\d+(\.\d{1,2})?$/,
									message: t(
										"CASINO.BALANCE_TRANSFER.AMOUNT_VALIDATION_ERROR"
									),
								}}
								placeholder="DEPOSIT.AMOUNT_PLACEHOLDER"
								validate={{
									isInRange: (value: number) => {
										const paymentMethod =
											paymentMethods?.find(
												(pm) =>
													pm.paymentMethodId ===
													getValues("paymentMethodId")
											);

										return (
											(paymentMethod?.minDeposit !=
												null &&
												value >=
													paymentMethod?.minDeposit &&
												value <=
													paymentMethod?.maxDeposit) ||
											t<string>(
												"DEPOSIT.DEPOSIT_MONEY_NOT_IN_RANGE",
												{
													0: paymentMethod?.minDeposit,
													1: paymentMethod?.maxDeposit,
												}
											)
										);
									},
								}}
							/>
						</section>
					</div>

					<div className="card--primary__footer">
						<Button
							className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
							type="submit"
							isDisabled={
								isLoadingProcess ||
								isRedirectingToPaymentPage ||
								paymentMethods == null ||
								paymentMethods.length === 0
							}
							btnText={t<string>("DEPOSIT.CONTINUE_BTN")}
							iconClassName="u-icon u-icon--xsml u-icon--arrow--right u-color--core--snow"
						></Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
});

const DepositErrorMessageFooter = function DepositErrorMessageFooter(props: {
	errorCode: number;
}) {
	const { t } = useTranslation();
	const { errorCode } = props;

	if (errorCode === 14) {
		return (
			<Link
				to={`account-settings/account-verification`}
				className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
			>
				{t("ACCOUNT_VERIFICATION.TITLE")}
			</Link>
		);
	} else if (errorCode === 15) {
		return (
			<Link
				to={`account-settings/my-limits`}
				className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
			>
				{t("USER.ACCOUNT_SETTINGS.MY_LIMITS.TITLE")}
			</Link>
		);
	} else if (errorCode === 69) {
		return (
			<Link
				to="user-profile-recheck"
				className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
			>
				{t("ACCOUNT_RECHECK.TITLE")}
			</Link>
		);
	}

	return (
		<Link
			to={`info/contact`}
			className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon"
		>
			{t("FOOTER.CONTACT")}
		</Link>
	);
};

const DepositTabSwitcher: FC = observer(function DepositTabSwitcher() {
	const { t } = useTranslation();
	const { culture } = useAppContext();

	return (
		<div className="card--primary__tabs u-padd--x--reset">
			<nav className="tabs--primary">
				<NavLink
					className="tabs--primary__item"
					activeClassName="is-active"
					replace={true}
					to={`/${culture}/deposit`}
					isActive={(match, location) => {
						return (
							location.pathname ==
							`/${getCurrentCulture()}/deposit`
						);
					}}
				>
					{t<string>("DEPOSIT.NEW_DEPOSIT")}
				</NavLink>
				<NavLink
					to={`/${culture}/deposit/history`}
					className="tabs--primary__item"
					replace={true}
					activeClassName="is-active"
					isActive={(match, location) => {
						return (
							location.pathname ==
							`/${getCurrentCulture()}/deposit/history`
						);
					}}
				>
					{t<string>("DEPOSIT.MY_DEPOSITS")}
				</NavLink>
			</nav>
		</div>
	);
});
