import { observable, action } from "mobx";
import { StorageStateKeysEnum } from "@utils/storage";

class WelcomeStore {
	@observable isActive: boolean = false;
	@observable shouldDisplayWelcomePopup: boolean = false;
	@observable isUseBonusConfirmActive: boolean = false;

	rootStore: typeof App.state.rootStore;

	constructor(rootStore: typeof App.state.rootStore) {
		this.rootStore = rootStore;
	}

	@action.bound
	onInitialize() {
		if (
			this.rootStore.localStorageProvider.get(
				StorageStateKeysEnum.WELCOME_POPUP
			)
		) {
			this.shouldDisplayWelcomePopup =
				this.rootStore.localStorageProvider.get(
					StorageStateKeysEnum.WELCOME_POPUP
				) == "true";
		}
	}

	@action.bound
	showPopup() {
		this.isActive = true;
	}

	@action.bound
	showUseBonusConfirmationPopup() {
		this.isUseBonusConfirmActive = true;
		this.hidePopup();
	}

	@action.bound
	hideUseBonusConfirmationPopup() {
		this.isUseBonusConfirmActive = false;
	}

	@action.bound
	hidePopup() {
		this.isActive = false;
		if (
			this.rootStore.localStorageProvider.get(
				StorageStateKeysEnum.WELCOME_POPUP
			) == "true"
		) {
			this.rootStore.localStorageProvider.set(
				StorageStateKeysEnum.WELCOME_POPUP,
				"false"
			);
		}
		this.shouldDisplayWelcomePopup = false;
	}
	@action.bound
	setShouldDisplay() {
		this.rootStore.localStorageProvider.set(
			StorageStateKeysEnum.WELCOME_POPUP,
			"true"
		);
		this.shouldDisplayWelcomePopup = true;
	}

	@action.bound
	onDispose() {
		this.isActive = false;
		this.shouldDisplayWelcomePopup = false;
		this.rootStore.localStorageProvider.remove(
			StorageStateKeysEnum.WELCOME_POPUP
		);
	}
}

export default WelcomeStore;
