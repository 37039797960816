import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "@v2/components/shared/inputs";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";
import OfferIcon from "@v2/components/shared/icons/Offer";
import { TournamentItems } from "./";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { SportInteractionTypeEnum } from "@data-types/analytics/SportInteractionTypeEnum";

export const SelectedSportCheckBox = observer(function SelectSpCheckBox(props: {
	sport: PrematchOfferMenuStore["menu"];
	store: {
		toggleUpdateProcessing?: PrematchOfferMenuStore["toggleUpdateProcessing"];
	};
}) {
	const t = useTranslation().t;

	if (props.sport == null) {
		return null;
	}

	return (
		<li className="nav--tertiary__item">
			<button
				className="nav--tertiary__link nav--tertiary__select-all"
				type="button"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					if (props.store.toggleUpdateProcessing != null) {
						props.store.toggleUpdateProcessing();
					}

					props.sport.onToggleCheck();
					if (props.sport.checkState === 1) {
						AnalyticsService.logSportInteraction({
							category: window.location.pathname.includes("live")
								? SportInteractionTypeEnum.LiveSport
								: SportInteractionTypeEnum.PrematchSport,
							label: props.sport.node.abrv,
						});
					}
					window.scrollTo(0, 0);
				}}
			>
				<span>
					<Checkbox
						id={`sport-${props.sport.node.id}-select-all`}
						className="input--check"
						name={props.sport.node.name}
						title={props.sport.node.name}
						checked={props.sport.checkState === 1}
						indeterminate={props.sport.checkState === 2}
						onClick={(e: Event) => {
							e.preventDefault();
							e.stopPropagation();

							if (props.store.toggleUpdateProcessing != null) {
								props.store.toggleUpdateProcessing();
							}
							props.sport.onToggleCheck();
							if (props.sport.checkState === 1) {
								AnalyticsService.logSportInteraction({
									category: window.location.pathname.includes(
										"live"
									)
										? SportInteractionTypeEnum.LiveSport
										: SportInteractionTypeEnum.PrematchSport,
									label: props.sport.node.abrv,
								});
							}
							window.scrollTo(0, 0);
						}}
						onChange={() => {}}
					/>
					<label htmlFor={`sport-${props.sport.node.id}-select-all`}>
						{t<string>("COMMON.SELECT_ALL")}
					</label>
				</span>
				<span className="nav--tertiary__counter counter counter--base counter--simple">
					{props.sport.node.count}
				</span>
			</button>
		</li>
	);
});

export const CategoryItems = observer(function CategoryItems(props: {
	sport: PrematchOfferMenuStore["menu"];
	store: {
		selectedSport: PrematchOfferMenuStore["selectedSport"];
	} & Parameters<typeof CategoryItem>[0]["store"];
}) {
	if (
		props.sport?.children == null ||
		(props.store.selectedSport == null && !props.sport.isExpanded)
	) {
		return null;
	}

	return (
		<ul className="nav--tertiary__menu">
			{props.sport.children.map((category) => (
				<CategoryItem
					key={category.node.id}
					sport={props.sport}
					category={category}
					store={props.store}
				/>
			))}
		</ul>
	);
});

const CategoryItem = observer(function CategoryItem(props: {
	sport: PrematchOfferMenuStore["menu"];
	category: PrematchOfferMenuStore["menu"];
	store: Parameters<typeof CategoryItemButton>[0]["store"] &
		Parameters<typeof TournamentItems>[0]["store"];
}) {
	return (
		<li className="nav--tertiary__item">
			<CategoryItemButton category={props.category} store={props.store} />
			<TournamentItems
				category={props.category}
				sport={props.sport}
				store={props.store}
			/>
		</li>
	);
});

export const CategoryItemButton = observer(function CategoryItemButton(props: {
	category: PrematchOfferMenuStore["menu"];
	store: {
		toggleUpdateProcessing?: PrematchOfferMenuStore["toggleUpdateProcessing"];
		type: "PrematchOfferMenuStore" | "LiveOfferMenuStore";
	};
}) {
	const labeId = `sport-${props.category.parent?.node.id}-category-${props.category.node.id}`;

	return (
		<button
			className={classNames("nav--tertiary__link", {
				"is-expanded": props.category.isExpanded,
				"is-active": props.category.checkState === 1,
			})}
			type="button"
			onClick={(e) => {
				e.stopPropagation();
				props.category.toggleExpanded();
			}}
		>
			<span className="nav--tertiary__title">
				<span>
					<Checkbox
						id={labeId}
						className="input--check"
						name={props.category.node.name}
						title={props.category.node.name}
						checked={props.category.checkState === 1}
						indeterminate={props.category.checkState === 2}
						// @ts-expect-error
						onClick={(e) => {
							e.stopPropagation();
							if (props.store.toggleUpdateProcessing != null) {
								props.store.toggleUpdateProcessing();
							}
							if (props.store.type === "PrematchOfferMenuStore") {
								App.state.updateUrlParams({ page: 1 }, true);
							}

							props.category.onToggleCheck();
							if (props.category.checkState === 1) {
								AnalyticsService.logSportInteraction({
									category: window.location.pathname.includes(
										"live"
									)
										? SportInteractionTypeEnum.LiveCategory
										: SportInteractionTypeEnum.PrematchCategory,
									label: `${props.category.parent?.node.abrv}-${props.category.node.abrv}`,
								});
							}
							window.scrollTo(0, 0);
						}}
						onChange={() => {}}
					/>
					<label htmlFor={labeId} />
				</span>
				<OfferIcon
					type="category"
					sport={props.category.parent?.node.abrv || ""}
					category={props.category.node.abrv || ""}
					treeIcon={true}
					className="nav--tertiary__icon u-icon u-icon--med u-mar--right--micro"
				/>
				<span>{props.category.node.name}</span>
			</span>
			<span className="nav--tertiary__counter counter counter--base counter--simple">
				{props.category.node.count}
			</span>
		</button>
	);
});
