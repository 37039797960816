import React from "react";
import { useFixBody } from "@v2/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons";
import { useRootAppStore } from "@hooks";
import { AircashMarketplaceBonusLabel } from "@common/constants/BonusConstant";

export default function UseMarketplaceBonusConfirmPopup(props: {
	hidePopup: () => void;
	sendConfirmation: (value: boolean) => void;
	fetchData?: Function;
}) {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();
	const welcomeStore = rootStore.welcomeStore;

	useFixBody(true, welcomeStore.isUseBonusConfirmActive);

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					<span>{AircashMarketplaceBonusLabel}</span>
				</div>
				<div className="popup__body">
					<span>
						{t(
							"USER.ACCOUNT_SETTINGS.MY_BONUSES.MARKETPLACE_BONUS.CONFIRMATION_TEXT"
						)}
					</span>
				</div>
				<div className="popup__footer">
					<Button
						className="btn--sml btn--block btn--primary"
						btnText={t("COMMON.APPLY_BUTTON")}
						onClick={async () => {
							props.hidePopup();

							await props.sendConfirmation(false);

							if (props.fetchData != null) {
								props.fetchData();
							}
						}}
					/>
					<Button
						className="btn--sml btn--block btn--secondary--inverted"
						btnText={t("COMMON.CANCEL_BUTTON")}
						onClick={async () => {
							if (!location.pathname.includes("my-bonuses")) {
								await props.sendConfirmation(true);
							}
							props.hidePopup();
						}}
					/>
				</div>
			</div>
		</div>
	);
}
