import React from "react";
import { FormInputField } from "@v2/components/desktop/shared/inputs";

export default function RegistrationHouseNumber(props: {
	labelClassName?: string;
}) {
	const { labelClassName } = props;

	return (
		<FormInputField
			className="form__field"
			name="houseNumber"
			label="MEMBERSHIP.REGISTRATION.HOUSE_NUMBER_LABEL"
			labelClassName={labelClassName}
			placeholder="MEMBERSHIP.REGISTRATION.HOUSE_NUMBER_PLACEHOLDER"
			pattern={{
				value: /^[\\\p{L}0-9 /'\-\.]{1,20}$/u,
				message: "MEMBERSHIP.REGISTRATION.INVALID_HOUSE_NUMBER",
			}}
			required
		/>
	);
}
