import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "@v2/components/desktop/shared/inputs";
import { FormButton } from "@v2/components/desktop/shared/buttons";
import { useRecoverPassword } from "@v2/hooks/membership/recover-password";
import { useFieldValidation } from "@v2/helpers/shared";
import { passwordValidationPattern } from "@gp/common";

export function RecoverPasswordFormSection(props: any) {
	const { reset, isRecoverPasswordSuccess, setIsRecoverPasswordSuccess } =
		props;

	const { t } = useTranslation();

	const { handleSubmit, getValues, getFieldState, trigger } =
		useFormContext();

	const { onSubmitData } = useRecoverPassword(
		reset,
		setIsRecoverPasswordSuccess
	);

	useFieldValidation("newPassword", "repeatPassword");

	if (isRecoverPasswordSuccess) {
		return null;
	}

	return (
		<form className="card--primary" onSubmit={handleSubmit(onSubmitData)}>
			<div className="card--primary__header">
				<div className="card--primary__title title--primary">
					{t<string>("MEMBERSHIP.RECOVER_PASSWORD.RECOVER_PASSWORD")}
				</div>
			</div>
			<div className="card--primary__body">
				<div className="row form__group">
					<PasswordInput
						name="newPassword"
						className="col col-sml-12 form__field"
						label="MEMBERSHIP.RECOVER_PASSWORD.NEW_PASSWORD"
						placeholder="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.NEW_PASSWORD_PLACEHOLDER"
						pattern={{
							value: passwordValidationPattern,
							message:
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
						}}
						required
					/>
				</div>
				<div className="row form__group">
					<PasswordInput
						name="repeatPassword"
						className="col col-sml-12 form__field"
						label="MEMBERSHIP.RECOVER_PASSWORD.REPEAT_NEW_PASSWORD"
						placeholder="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.REPEAT_NEW_PASSWORD_PLACEHOLDER"
						pattern={{
							value: passwordValidationPattern,
							message:
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
						}}
						validate={{
							isEqual: (value: string) =>
								value === getValues("newPassword") ||
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.DOES_NOT_MATCH_PWD",
						}}
						maxLength={40}
						required
					/>
				</div>

				<div className="row form__group">
					<div className="col col-sml-12">
						<div className="message message--base message--warning">
							<i className="message__icon u-icon u-icon--lrg u-icon--alert-circle u-color--ui--warning" />
							<div className="message__content">
								<div className="message__title">
									{t<string>(
										"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENTS_VALID"
									)}
								</div>
								<ul className="list list--unordered">
									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_1"
										)}
									</li>

									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_5"
										)}
									</li>

									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_2"
										)}
									</li>
									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_3"
										)}
									</li>
									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_4"
										)}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="row form__group">
					<div className="col col-sml-12">
						<div className="message message--base message--muted">
							<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--muted" />
							<div className="message__content">
								<div className="message__title">
									{t<string>(
										"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS"
									)}
								</div>
								<ul className="list list--unordered">
									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_1"
										)}
									</li>
									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_2"
										)}
									</li>
									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_3"
										)}
									</li>
									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_4"
										)}
									</li>
									<li>
										{t<string>(
											"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_5"
										)}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card--primary__footer">
				<FormButton
					className="card--primary__footer__btn btn btn--lrg btn--primary"
					btnText="MEMBERSHIP.RECOVER_PASSWORD.SAVE_BUTTON"
				/>
			</div>
		</form>
	);
}
