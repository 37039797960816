import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DateTime } from "luxon";
import classNames from "classnames";

import { UserPaymentTransactionsApiService } from "@api-services/user-payment-transactions/UserPaymentTransactionsApiService";
import { UserPaymentTransactionLimited } from "@api-types/account-settings/UserPaymentTransactionLimited";
import { useNotification } from "@v2/hooks";
import { useUser } from "@common/hooks";
import { observer } from "mobx-react";
import PaymentOptionImage from "@v2/components/shared/icons/PaymentOptionImage";
import { UserTypes, getSVGExtension, getUserFromLocalStorage } from "@utils";
import { useFormContext } from "react-hook-form";
import { RootAppStoreContext } from "@context";
import { useRootAppStore } from "@hooks";
import { getPaymentMethodAbrv } from "@v2/helpers/shared";

export const TransactionDetails = observer(function TransactionDetails(props: {
	isDetailsDisplayed: boolean;
	setIsDetailsDisplayed: Function;
	transactionId: string;
}) {
	const { t } = useTranslation();
	const { showError } = useNotification();
	const [fetchedData, setFetchedData] = useState<
		undefined | null | UserPaymentTransactionLimited
	>(null);
	const { getValues } = useFormContext();
	const rootStore = useRootAppStore();

	const userType = rootStore.userAuthStore.getUserAccountType(
		Number(getValues("userTypes"))
	);

	const user = getUserFromLocalStorage(userType);

	async function fetchTransactionDetails(
		transactionId: string,
		userType: UserTypes
	) {
		try {
			if (user?.isInternetUser) {
				return await UserPaymentTransactionsApiService.getTransactionDetails(
					transactionId,
					userType
				);
			} else {
				return await UserPaymentTransactionsApiService.getTransactionDetailsForRetail(
					transactionId,
					userType
				);
			}
		} catch (e) {
			console.error(e);
			showError("COMMON.ERROR_MESSAGES.DEFAULT");
		}
	}

	const setData = async () => {
		const userType = App.state.rootStore.userAuthStore.getUserAccountType(
			Number(getValues("userTypes"))
		);
		let data = await fetchTransactionDetails(props.transactionId, userType);
		if (data == null) {
			props.setIsDetailsDisplayed(false);
		}
		setFetchedData(data);
	};

	useEffect(() => {
		setData();
	}, []);

	if (fetchedData == null) {
		return null;
	}

	const {
		paymentMethod,
		amount,
		currency,
		transactionStatus,
		ccMaskedPan,
		ccExpiryDate,
		startDate,
		transactionDate,
		endDate,
		numericalId,
	} = fetchedData;

	const paymentMethodIcon = classNames(
		"card--payment__logo u-icon u-icon--payment u-icon--payment--sml",
		`u-icon--payment--${paymentMethod?.abrv.toLowerCase()}`
	);

	const statusTagClasses = classNames("u-type--xsml", {
		"u-color--ui--muted": transactionStatus?.abrv == "cancelled",
		"u-color--ui--danger": transactionStatus?.abrv == "failed",
		"u-color--text--primary": transactionStatus?.abrv == "pending",
		"u-color--ui--success": transactionStatus?.abrv == "success",
	});

	const statusIconClasses = classNames(
		"u-align--v--middle u-icon u-icon--xsml",
		{
			"u-icon--delete u-color--ui--muted":
				transactionStatus?.abrv == "cancelled",
			"u-icon--remove u-color--ui--danger":
				transactionStatus?.abrv == "failed",
			"u-icon--clock": transactionStatus?.abrv == "pending",
			"u-icon--check u-color--ui--success":
				transactionStatus?.abrv == "success",
		}
	);

	const paymentMethodAbrv = getPaymentMethodAbrv(paymentMethod.abrv);

	return (
		<div key={numericalId} className="card--bonus__body">
			<table className="table">
				<thead className="table__head">
					<tr className="table__head__row table__row">
						<th
							className="table__head__cel table__cell table__cell--sml"
							colSpan={2}
						>
							{t(
								"ACC_STATEMENT.TRANS_DETAILS.TRANSACTION_DETAILS"
							)}
						</th>
					</tr>
				</thead>
				<tbody className="table__body">
					<tr className="table__body__row table__row">
						<td className="table__body__cell table__cell table__cell--sml">
							{t<string>(
								"ACC_STATEMENT.TRANS_DETAILS.TRANSACTION_ID"
							)}
						</td>
						<td className="table__body__cell table__cell table__cell--sml u-color--text--primary">
							{numericalId}
						</td>
					</tr>
					<tr className="table__body__row table__row">
						<td className="table__body__cell table__cell table__cell--sml">
							{t<string>("ACC_STATEMENT.TRANS_DETAILS.AMOUNT")}
						</td>
						<td className="table__body__cell table__cell table__cell--sml u-color--text--primary">
							{amount.toFixed(2)}
						</td>
					</tr>
					<tr className="table__body__row table__row">
						<td className="table__body__cell table__cell table__cell--sml">
							{t<string>("ACC_STATEMENT.TRANS_DETAILS.CURRENCY")}
						</td>
						<td className="table__body__cell table__cell table__cell--sml u-color--text--primary">
							{currency.displayCode}
						</td>
					</tr>
					{user?.isInternetUser && (
						<>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell table__cell--sml">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.STATUS"
									)}
								</td>
								<td className="table__body__cell table__cell table__cell--sml u-color--text--primary">
									<div className={statusTagClasses}>
										<i className={statusIconClasses} />
										<span className="u-align--v--middle u-mar--left--micro">
											{transactionStatus?.name}
										</span>
									</div>
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell table__cell--sml">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.PAYMENT_METHOD"
									)}
								</td>
								<td className="table__body__cell table__cell table__cell--sml">
									<div className="card--payment card--payment--borderless u-mar--bottom--reset">
										<div className="card--payment__area u-padd--reset">
											<span className="card--payment__icon u-mar--right--xtny u-type--wgt--regular">
												<PaymentOptionImage
													className={`card--payment__logo u-icon u-icon--payment u-icon--payment--sml u-icon--payment--${paymentMethodAbrv}`}
													svgName={
														paymentMethodAbrv +
														getSVGExtension()
													}
												/>
											</span>
											<span className="card--payment__details card--payment__details--centered">
												<span className="card--payment__title u-mar--bottom--reset u-type--wgt--regular">
													{ccMaskedPan}
												</span>
												<span className="card--payment__type">
													{ccExpiryDate}
												</span>
											</span>
										</div>
									</div>
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell table__cell--sml">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.START_DATE"
									)}
								</td>
								<td className="table__body__cell table__cell table__cell--sml u-color--text--primary">
									{DateTime.fromISO(startDate).toLocaleString(
										DateTime.DATETIME_SHORT_WITH_SECONDS
									)}
								</td>
							</tr>
							<tr className="table__body__row table__row">
								<td className="table__body__cell table__cell table__cell--sml">
									{t<string>(
										"ACC_STATEMENT.TRANS_DETAILS.END_DATE"
									)}
								</td>
								<td className="table__body__cell table__cell table__cell--sml u-color--text--primary">
									{DateTime.fromISO(endDate).toLocaleString(
										DateTime.DATETIME_SHORT_WITH_SECONDS
									)}
								</td>
							</tr>
						</>
					)}
					{transactionDate != null ? (
						<tr className="table__body__row table__row">
							<td className="table__body__cell table__cell table__cell--sml">
								{t<string>(
									"ACC_STATEMENT.TRANS_DETAILS.TRANSACTION_DATE"
								)}
							</td>
							<td className="table__body__cell table__cell table__cell--sml u-color--text--primary">
								{DateTime.fromISO(
									transactionDate
								).toLocaleString(
									DateTime.DATETIME_SHORT_WITH_SECONDS
								)}
							</td>
						</tr>
					) : (
						<></>
					)}
				</tbody>
			</table>
		</div>
	);
});
