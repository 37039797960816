import React, { useState } from "react";
import { DateTime } from "luxon";
import classNames from "classnames";
import { Button } from "@v2/components/desktop/shared/buttons";
import { UserPaymentTransactionLimitedHasDetails } from "@v2/data-access/account-statement/AccountStatementService";
import { getDisplayedAmount } from "@utils";
import { DetailsTypeEnum } from "@v2/hooks/account-statement/useAccountTransactions";

const SGUID_empty = "0000000000000000000000";

export default function AccountStatementTableTransaction({
	transaction,
	fetchSlipDetails,
}: PropType) {
	return (
        <tr
            key={transaction.numericalId}
            className="table__body__row table__row"
        >
            <td className="table__body__cell table__cell">
                {transaction.numericalId}
            </td>
            <td className="table__body__cell table__cell">
                {DateTime.fromISO(transaction.dateCreated).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </td>
            <td className="table__body__cell table__cell">
                {transaction.transactionType.name}
            </td>
            <td className="table__body__cell table__cell u-type--right u-type--wgt--medium">
                {getDisplayedAmount(
                    transaction.amount,
                    transaction.currency.displayCode
                )}
            </td>
            <td className="table__body__cell table__cell u-type--right">
                {getDisplayedAmount(
                    transaction.netAmount,
                    transaction.currency.displayCode
                )}
            </td>
            <td className="table__body__cell table__cell u-type--right">
                <TransactionHasDetails
                    transaction={transaction}
                    fetchSlipDetails={fetchSlipDetails}
                />
            </td>
        </tr>
	);
}

function TransactionHasDetails(props: PropType) {
	const {
		transaction: { betSlipId, hasDetails, userPaymentTransactionId },
		fetchSlipDetails,
	} = props;

	if (!hasDetails) {
		return null;
	}

	let transactionId: string | null;
	let detailType: DetailsTypeEnum;
	if (betSlipId != SGUID_empty) {
		detailType = DetailsTypeEnum.BetSlipDetails;
		transactionId = betSlipId;
	} else {
		detailType = DetailsTypeEnum.TransactionDetails;
		transactionId = userPaymentTransactionId;
	}

    const [isHover, setIsHover] = useState(false);

    const iconClasses = classNames(
        "u-icon--xsml u-icon--caret--right",
        {
            "u-color--brand--highlight": isHover
        }
    );

	return (
		<Button
			btnType="button"
			className="btn btn--square--reset btn--ghost"
			iconOnly={true}
            iconClassName={iconClasses}
			onClick={() => fetchSlipDetails(transactionId, detailType)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
		/>
	);
}

type PropType = {
	transaction: UserPaymentTransactionLimitedHasDetails;
	fetchSlipDetails: Function;
};