import {
	httpClient,
	getAgencyKey,
	getCurrentLanguage,
	getApplicationHeaders,
} from "@utils";

import { MenuSubscriptionResponse } from "@gp/models";
import { EventFilterParams } from "@gp/hub";
import _ from "lodash";

class SportsMenuService {
	constructor() {
		this.getPrematchMenu = this.getPrematchMenu.bind(this);
	}

	getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	async getPrematchMenu(
		filter:
			| Omit<EventFilterParams, "offers">
			| Omit<EventFilterParams, "offers">[]
	): Promise<MenuSubscriptionResponse["menu"]> {
		const url = new URL(
			`${WEBPACK_BASE_PROXY_ADDRESS}${getAgencyKey()}/offer/sport-menu`
		);
		const requestBody = {
			Language: getCurrentLanguage(),
			Filter: filter,
		};

		const options = { responseType: "json" };

		return await httpClient.post(
			url,
			JSON.stringify(requestBody),
			this.getRequestHeaders(),
			options
		);
	}

	prepareSearchParams = (url: URL, query: Object) => {
		_.each(Object.keys(query), (key) => {
			// @ts-expect-error
			url.searchParams.append(key, query[key]);
		});
	};
}

export default new SportsMenuService();
