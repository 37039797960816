import { useEffect, useContext, useMemo } from "react";

import { AdditionalOfferViewStore } from "../../../../state/offer/components/AdditionalOfferViewStore";
import { useRootOfferStore } from "@hooks";
import {
	useGetAdditionalOfferEvent,
	useGetIsAdditionalLive,
} from "@offer/common";
import { AdditionalOfferContainerRefContext } from "@context";
import { SetIsAdditionalOfferOpenCtxProviderForParentLayout } from "@context/SetIsAdditionalOfferOpenCtxProviderForParentLayout";
import useRemoveAdditionalOfferCount from "./useRemoveAdditionalOfferCount";
import FavoritesStore from "@offer/stores/FavoritesStore";

export default function useAdditionalOffer(props: {
	type?: "prematch" | "live" | undefined;
	favoritesStore: FavoritesStore;
	parentOfferStore: {
		eventsInSports: AdditionalOfferViewStore["eventsInSports"];
		eventsMap: AdditionalOfferViewStore["eventsMap"];
	};
}) {
	const eventId = useGetAdditionalOfferEvent()?.eventId;
	const isAdditionalLive = useGetIsAdditionalLive();
	const setParentIsAdditionalOpen = useContext(
		SetIsAdditionalOfferOpenCtxProviderForParentLayout
	).setIsAdditionalOpen;
	const rootStore = useRootOfferStore();
	const viewStore = useMemo(() => {
		return new AdditionalOfferViewStore(rootStore, props.favoritesStore, {
			type: props.type || "prematch",
			isLive: isAdditionalLive || false,
		});
	}, []);

	useEffect(() => {
		return () => {
			viewStore.onDispose();
			setParentIsAdditionalOpen(false);
		};
	}, []);

	viewStore.updateEventId(eventId || null, isAdditionalLive);
	const containerElement = useContext(AdditionalOfferContainerRefContext);

	const isThereOfferToDisplay =
		containerElement != null &&
		viewStore.orderedEvents.length !== 0 &&
		eventId != null;

	useRemoveAdditionalOfferCount(
		props.parentOfferStore,
		eventId != null &&
			viewStore.orderedEvents.length === 0 &&
			!viewStore.isFetchingData &&
			viewStore.isStoreInitialized
	);
	useEffect(() => {
		setParentIsAdditionalOpen(isThereOfferToDisplay);
	}, [isThereOfferToDisplay]);

	return {
		viewStore,
		isThereOfferToDisplay,
		containerElement,
	};
}
