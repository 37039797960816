import { useContext, useEffect } from "react";
import { useLoading } from "@hooks";
import { setTabTitle } from "@utils";
import { useTranslation } from "react-i18next";
import { LoaderGetterContext } from "@context";

export default function useAccountActivation() {
	const user = App.state.rootStore.userAuthStore;
	const loginStore = user.loginStore;
	const t = useTranslation().t;
	useLoading().setIsLoading(loginStore.isAccountActivationWorking);

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.ACCOUNT_ACTIVATION.TITLE"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);

		loginStore.onInitializeAccountActivation();

		return () => {
			loginStore.onDisposeAccountActivation();
		};
	}, []);

	return {
		onCancel: loginStore.onCancelAccountActivation,
		onSuccess: loginStore.onAccountActivationFormSubmit,
		accountActivationData: loginStore.accountActivationData,
		activationError: loginStore.accountActivationError,
		emptyFields: loginStore.emptyFields,
		closeEmptyFieldsPopup: loginStore.closeEmptyFieldsPopup,
		createAccountActivation: loginStore.onAccountActivationSubmit,
		clearAccountActivationError: loginStore.clearAccountActivationError,
		isCongratulations: loginStore.isAccountActivationCongratulationsActive,
		closeCongratulations: loginStore.closeAccountActivationCongratulations,
	};
}
