import { useMemo } from "react";
import { useAppContext } from "../../../ui/common/hooks";

export default function useLocaleDateFormatForDatePicker() {
	const { culture } = useAppContext();

	return useMemo(
		() =>
			new Intl.DateTimeFormat(culture)
				.formatToParts(Date.now())
				.filter((part) => part.type !== "literal")
				.map((part) => {
					if (part.type === "month") return "M";
					if (part.type === "day") return "dd";
					if (part.type === "year") return "y";
				})
				.join("-"),
		[culture]
	);
}
