import React, { useContext } from "react";
import { range } from "lodash";

import { ResultContext, ResultEventContext } from "@context/results";
import { ScoreType } from "@common/constants/ScoreType";

export function ScoreRenderer() {
	return (
		<>
			<FullTimeScore />
			<ScoreWrapper />
		</>
	);
}

const ScoreWrapper = function ScoreWrapper() {
	const result = useContext(ResultContext);

	if (result.scoreType === ScoreType.FTONLY) {
		return null;
	}

	return (
		<>
			<ScorePerPeriod />
			<HalfTimeScore />
			<OverTimeScore />
			<OverTimeEmpty />
		</>
	);
};

const FullTimeScore = function FullTimeScore() {
	const event = useContext(ResultEventContext);

	if (event.fullTime == null) {
		return <td className="table--results__body__data">-</td>;
	}

	return (
		<td
			className="u-type--color--danger"
			style={{ textAlign: "center", fontWeight: "bold" }}
		>
			{event.fullTime.home}:{event.fullTime.away}
		</td>
	);
};

const ScorePerPeriod = function ScorePerPeriod() {
	const result = useContext(ResultContext);
	const event = useContext(ResultEventContext);

	if (
		result.scoreType != ScoreType.PERPERIOD &&
		result.scoreType != ScoreType.PERPERIODWITHOT
	) {
		return null;
	}

	if (event.scorePerPeriod == null) {
		return <ScorePerPeriodEmptySpaces />;
	}

	return (
		<>
			<ScorePerPeriodList />
			<ScorePerPeriodEmptySpaces />
		</>
	);
};

const ScorePerPeriodList = function ScorePerPeriodList() {
	const event = useContext(ResultEventContext);

	return (
		<>
			{event.scorePerPeriod?.map((period, idx) => {
				return (
					<td
						key={`${event.id}-spp-${idx}`}
						className="table--results__body__data table--results__body__data--period"
					>
						{period.home}:{period.away}
					</td>
				);
			})}
		</>
	);
};

const ScorePerPeriodEmptySpaces = function ScorePerPeriodEmptySpaces() {
	const result = useContext(ResultContext);
	const event = useContext(ResultEventContext);

	if (
		event.scorePerPeriod != null &&
		event.scorePerPeriod.length > result.columnsCount
	) {
		return null;
	}

	const difference = result.columnsCount - (event.scorePerPeriod?.length | 0);
	return (
		<>
			{range(difference).map((item, idx) => {
				return (
					<td
						key={`${event.id}-spp-empty-${idx}`}
						className="table--results__body__data table--results__body__data--period"
					>
						-
					</td>
				);
			})}
		</>
	);
};

const HalfTimeScore = function HalfTimeScore() {
	const result = useContext(ResultContext);
	const event = useContext(ResultEventContext);

	if (
		result.scoreType === ScoreType.HEAD2HEAD ||
		result.scoreType === ScoreType.HEAD2HEADWITHOT
	) {
		if (event.halfTime != null) {
			return (
				<td className="table--results__body__data table--results__body__data--ht">
					{event.halfTime.home}:{event.halfTime.away}
				</td>
			);
		} else {
			return <td className="table--results__body__data">-</td>;
		}
	}

	return null;
};

const OverTimeScore = function OverTimeScore() {
	const event = useContext(ResultEventContext);

	if (event.overTimeScore == null) {
		return null;
	}

	return (
		<td className="table--results__body__data table--results__body__data--otap">
			{event.overTimeScore.home}:{event.overTimeScore.away}{" "}
			{event.penalties != null
				? `(${event.penalties.home}:${event.penalties.away})`
				: ""}
		</td>
	);
};

const OverTimeEmpty = function OverTimeEmpty() {
	const result = useContext(ResultContext);
	const event = useContext(ResultEventContext);

	if (
		event.overTimeScore == null &&
		(result.scoreType === ScoreType.PERPERIODWITHOT ||
			result.scoreType === ScoreType.HEAD2HEADWITHOT ||
			result.scoreType === ScoreType.FTONLYWITHOT)
	) {
		return <td className="table--results__body__data">-</td>;
	}

	return null;
};
