import React, { useContext } from "react";
import { observer } from "mobx-react";
import { GroupedBettingTypes } from "./components";
import { EventContext, OfferStoreContext } from "@gp/components";
import { AdditionalOfferViewStore } from "@v2/state/offer/components/AdditionalOfferViewStore";

export default observer(function GroupedEventOfferTemplate() {
	const store = useContext(OfferStoreContext) as AdditionalOfferViewStore;
	const event = useContext(EventContext);

	const eventInList: { eventId: string; isMyBetsOpen: boolean } =
		//@ts-expect-error
		store.myBetsToggleStateList.find(
			(eItem: { eventId: string; isMyBetsOpen: boolean }) =>
				eItem.eventId == event.id
		);

	if (eventInList == null || eventInList.isMyBetsOpen) {
		return null;
	}
	return <GroupedBettingTypes />;
});
