import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getCurrentCulture, setTabTitle } from "@utils";

import { RegistrationActivationApiService } from "@v2/data-access/membership/registration";
import {
	OnBoardingSlider,
	OnBoardingFooter,
} from "@components-desktop/membership/shared";

import { Loader } from "@v2/components/desktop/shared/loader";
import { Link } from "react-router-dom";

/**
	This component is used for "Send the email for verification" on administration (for retail and online user) and for completing the user registration process
	API endpoint: platform/gp/register/activate/{username}/{mailkey}
 */
export function RegistrationActivationPage() {
	const { t } = useTranslation();
	const [activationState, setActivationState] = useState<boolean | null>(
		null
	);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [errorCode, setErrorCode] = useState<number>(0);

	useEffect(() => {
		setTabTitle(t("MEMBERSHIP.ACCOUNT_ACTIVATION.TITLE"));

		window.scrollTo(0, 0);

		async function registrationActivation() {
			const activationToken = new URLSearchParams(
				App.state.history.location.search
			)?.get("mailkey");
			const username = new URLSearchParams(
				App.state.history.location.search
			)?.get("username");
			if (activationToken != null && username != null) {
				try {
					await RegistrationActivationApiService.registrationActivate(
						username,
						activationToken
					);
					setActivationState(true);
				} catch (error) {
					console.error(error);
					try {
						const errorObj = await error.rawResponse.json();
						setErrorCode(errorObj?.errorCode);
						switch (errorObj?.errorCode) {
							// account already activated
							case 400116:
								setErrorMessage(
									"MEMBERSHIP.REGISTRATION.VALIDATION.ALREADY_ACTIVATED"
								);
								break;
							default:
								setErrorMessage(
									"COMMON.ERROR_MESSAGES.DEFAULT"
								);
						}
						setActivationState(false);
					} catch (e) {
						setActivationState(false);
					}
				}
			} else {
				setActivationState(false);
			}
		}
		registrationActivation();
		return () => {
			setActivationState(null);
		};
	}, []);

	return (
		<main
			role="main"
			className="at-registrationActivation l--main l--main--grow"
		>
			<Loader isLoading={activationState === null} />

			<section className="l--content l--content--full">
				<div className="l--content__main u-padd--reset">
					<div className="container container--med container--grow">
						<div className="card--onboarding card--onboarding--grow">
							<OnBoardingSlider />
							<div className="card--onboarding__form card--onboarding__form--center">
								<RegistrationActivationResult
									activationState={activationState}
									message={errorMessage}
									errorCode={errorCode}
								/>
							</div>
						</div>

						<OnBoardingFooter />
					</div>
				</div>
			</section>
		</main>
	);
}

function RegistrationActivationResult(props: {
	activationState: boolean | null;
	message: string;
	errorCode: number;
}) {
	const { t } = useTranslation();

	if (props.activationState === null) {
		//activation is pending
		return (
			<>
				<div className="card--onboarding__header card--onboarding__header--vertical">
					<i className="u-icon u-icon--xxxlrg u-icon--pending u-icon--animated--bounce-in" />
					<div className="card--onboarding__title u-mar--top--sml">
						{t<string>(
							"MEMBERSHIP.REGISTRATION.ACTIVATION_WAIT_TITLE"
						)}
					</div>
				</div>

				<div className="card--onboarding__content card--onboarding__content--shrink u-type--center">
					<div>
						{t<string>(
							"MEMBERSHIP.REGISTRATION.ACTIVATION_WAIT_MESSAGE"
						)}
					</div>
				</div>
			</>
		);
	} else if (props.activationState) {
		//activation is successful
		return (
			<>
				<div className="card--onboarding__header card--onboarding__header--vertical">
					<i className="u-icon u-icon--xxxlrg u-icon--check-circle u-icon--animated--bounce-in u-color--ui--success" />
					<div className="card--onboarding__title u-mar--top--sml">
						{t<string>(
							"MEMBERSHIP.REGISTRATION.ACTIVATION_SUCCESS_MESSAGE"
						)}
					</div>
				</div>
				<div className="card--onboarding__content card--onboarding__content--shrink">
					<div className="u-mar--bottom--xsml">
						<div className="u-type--med u-type--wgt--medium">
							{t<string>(
								"MEMBERSHIP.ACCOUNT_ACTIVATION.PLAY_RESPONSIBLY"
							)}
						</div>
						<div>
							{t<string>(
								"MEMBERSHIP.ACCOUNT_ACTIVATION.RESPONSIBLE_GAMING_MESSAGE_ACTIVATED"
							)}
						</div>
					</div>

					<div className="u-type--wgt--medium">
						{t<string>(
							"MEMBERSHIP.ACCOUNT_ACTIVATION.WISHES_MESSAGE"
						)}
					</div>
				</div>

				<Link
					to={`/${getCurrentCulture()}/auth/login`}
					className="btn btn--base btn--block btn--primary"
				>
					{t<string>("MEMBERSHIP.LOGIN.LOGIN_BUTTON")}
				</Link>
			</>
		);
	} else if (props.errorCode === 400116) {
		return (
			//account already activated
			<>
				<div className="card--onboarding__header card--onboarding__header--vertical">
					<i className="u-icon u-icon--xxxlrg u-icon--info-circle u-icon--animated--bounce-in" />
				</div>

				<div className="card--onboarding__content card--onboarding__content--shrink u-type--center">
					<div>{t<string>(props.message)}</div>
				</div>

				<Link
					to={`/${getCurrentCulture()}/auth/login`}
					className="btn btn--base btn--block btn--primary"
				>
					{t<string>("MEMBERSHIP.LOGIN.LOGIN_BUTTON")}
				</Link>
			</>
		);
	}

	return (
		//activation failed
		<>
			<div className="card--onboarding__header card--onboarding__header--vertical">
				<i className="u-icon u-icon--xxxlrg u-icon--remove-circle u-icon--animated--bounce-in u-color--ui--danger" />
				<div className="card--onboarding__title u-mar--top--sml">
					{t<string>("COMMON.ERROR_MESSAGES.TITLE")}
				</div>
			</div>

			<div className="card--onboarding__content card--onboarding__content--shrink u-type--center">
				<div>{t<string>(props.message)}</div>
			</div>
		</>
	);
}
