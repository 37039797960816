import React from "react";
import { observer } from "mobx-react";
import { EventOffer, SportOffer } from "@gp/offer";
import OfferIcon from "@v2/components/shared/icons/Offer";

import { AdditionalOfferViewStore } from "../../../../state/offer/components/AdditionalOfferViewStore";
import EventScoreInfo from "./AdditionalOfferEventScoreInfo";

export default observer(function EventInfo(props: {
	event: EventOffer;
	sport: SportOffer;
	store: AdditionalOfferViewStore;
}) {
	const category = props.store.lookupsStore.categories.get(
		props.event.sportCategoryId
	);
	const tournament = props.store.lookupsStore.tournaments.get(
		props.event.tournamentId
	);

	return (
		<div className="event__match">
			<div className="event__match__content">
				<div className="event__tournament">
					{/*  <i className={sportIconClasses} /> */}
					<OfferIcon
						type="sport"
						sport={props.sport.abrv}
						className={
							"event__icon event__icon--sport u-icon u-icon--med"
						}
					/>
					{props.sport.name}

					<span className="u-mar--x--xtny">/</span>

					{/*       <i className={flagIconClasses} /> */}
					<OfferIcon
						type="category"
						sport={props.sport.abrv}
						category={category?.abrv || ""}
						className={
							"event__icon event__icon--category u-icon u-icon--med"
						}
					/>
					{category?.name}

					<span className="u-mar--x--xtny">/</span>

					{tournament?.iconUrl && (
						<OfferIcon
							type="tournament"
							sport={props.sport.abrv}
							category={category?.abrv || ""}
							tournament={tournament?.abrv || ""}
							className={
								"event__icon event__icon--tournament u-icon u-icon--med"
							}
						/>
						//<i className={tournamentIconClasses} />
					)}
					{tournament?.name}
				</div>

				<EventScoreInfo event={props.event} store={props.store} />
			</div>
		</div>
	);
});
