import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { EventType } from "@gp/models";

import {
	MyBetsCashoutConfirmation,
	MyBetsReuseConfirmation,
	MyBetsAutosellConfirmation,
} from "../../../my-bets/modals";
import {
	MyBetsCashoutButton,
	MyBetsAutosellButton,
} from "../../../my-bets/components";
import {
	getTip,
	getType,
	shouldShowBetSlipStatus,
} from "../../../../../helpers";
import { getCurrentCulture } from "@utils";
import { WalletIcon } from "@v2/components/shared/utility";

import { DisplayDate } from "./";
import { MyBetsViewStore } from "@offer/stores/components/my-bets";
import BetItemPaymentDetail from "./BetItemPaymentDetail";
import BetOfferEventTime from "./BetOfferEventTime";

const BetDisplay = observer(function BetDisplay(props: {
	myBetsViewStore: MyBetsViewStore;
}) {
	const { t } = useTranslation();

	if (
		props.myBetsViewStore.openedBet == null ||
		props.myBetsViewStore.openedBet.isRemoved
	) {
		return null;
	}

	const headerClasses = classNames("widget--bets__header", [
		`widget--bets__header--${getCurrentCulture()}`,
	]);

	// show bet details
	return (
		<div className="widget">
			<div
				className={
					"widget__header widget__header--" + getCurrentCulture()
				}
			>
				<div className="widget__header__title">
					{t<string>("MY_BETS.PAGE.TITLE")}
				</div>
				<a
					href={`/${getCurrentCulture()}/my-bets`}
					className="widget__header__btn btn btn--xtny btn--ghost btn--icon btn--animate-forward u-padd--x--reset"
				>
					<span>{t<string>("MY_BETS.VIEW_OLDER_BETS")}</span>
					<i className="u-icon u-icon--xsml u-icon--arrow--right" />
				</a>
			</div>
			<div
				className={
					"widget__body widget--bets widget--bets--" +
					getCurrentCulture()
				}
			>
				<div className="widget--bets__list widget--bets__details">
					<div className="widget--bets__item">
						<div className={headerClasses}>
							<div className="widget--bets__status">
								<span
									className={
										"tag tag--med tag--bet tag--bet--" +
										props.myBetsViewStore.openedBet
											.betStatus.abrv
									}
									title={
										props.myBetsViewStore.openedBet
											.betStatus.name
									}
								>
									{
										props.myBetsViewStore.openedBet
											.betStatus.name
									}
									{props.myBetsViewStore.openedBet
										.isBonus && (
										<> ({t<string>("COMMON.BONUS")})</>
									)}
								</span>

								{shouldShowBetSlipStatus(
									props.myBetsViewStore.openedBet
								) && (
									<span
										className={
											"tag tag--med tag--slip tag--slip--" +
											props.myBetsViewStore.openedBet
												.betSlipStatus.abrv +
											"--inverted"
										}
									>
										{
											props.myBetsViewStore.openedBet
												.betSlipStatus.name
										}
									</span>
								)}

								<WalletIcon
									bettingAccountTypeAbrv={
										props.myBetsViewStore.openedBet
											.bettingAccountType?.abrv
									}
									classList="u-mar--left--reset"
								/>

								<div className="widget--bets__date">
									<DisplayDate
										betDateTimeStamp={
											props.myBetsViewStore.openedBet
												?.dateCreated || ""
										}
									/>
								</div>
							</div>

							<button
								className="widget--bets__close btn btn--square btn--square--xtny btn--secondary--inverted"
								type="button"
								onClick={props.myBetsViewStore.onClose}
							>
								<i className="u-icon u-icon--xsml u-icon--close" />
							</button>
						</div>

						<div className="widget--bets__details__list">
							<div className="widget--bets__title u-mar--bottom--xsml">
								{
									props.myBetsViewStore.openedBet.betSlipType
										.name
								}
							</div>

							{props.myBetsViewStore.openedBet.betSlipOffers.map(
								(o, oIdx) => (
									<div
										key={oIdx}
										className="widget--bets__info widget--bets__details__item"
									>
										<div className="widget--bets__title widget--bets__title--sml">
											{o.sportData.eventType ==
											EventType.NORMAL
												? `${o.sportData.teamOneName}-${o.sportData.teamTwoName}`
												: o.sportData.eventName}
										</div>

										<BetOfferEventTime
											myBetsViewStore={
												props.myBetsViewStore
											}
											bet={o}
										/>

										<div className="widget--bets__pick">
											<div className="widget--bets__offer">
												{o.sportData.eventType ==
													EventType.NORMAL && (
													<div className="widget--bets__type">
														{getType(o)}:
													</div>
												)}
												<div className="widget--bets__tip">
													{getTip(o)}
												</div>
											</div>
											<div className="widget--bets__quote">
												{o.value}
											</div>
										</div>
									</div>
								)
							)}
						</div>

						<div className="widget--bets__row">
							<div className="widget--bets__col">
								<div className="widget--bets__label">
									{t<string>(
										"MY_BETS.ITEM.INFO_PAYMENT_LABEL"
									)}
									:
								</div>
								<div className="widget--bets__amount">
									{
										props.myBetsViewStore.openedBet
											.displayPayment
									}
								</div>
							</div>
							<div className="widget--bets__col">
								<BetItemPaymentDetail
									betStatusAbrv={
										props.myBetsViewStore.openedBet
											.betStatus.abrv
									}
									betSlipStatusAbrv={
										props.myBetsViewStore.openedBet
											.betSlipStatus.abrv
									}
									payout={
										props.myBetsViewStore.openedBet
											.displayPayout
									}
									maxGain={
										props.myBetsViewStore.openedBet
											.displayMaxGain
									}
								/>
							</div>
							<div className="widget--bets__col">
								<div className="widget--bets__label">
									{t<string>(
										"MY_BETS.ITEM.ADDITIONAL_MAX_COEFFICIENT"
									)}
									:
								</div>
								<div className="widget--bets__amount">
									{
										props.myBetsViewStore.openedBet
											.maxCoefficient
									}
								</div>
							</div>
						</div>

						{props.myBetsViewStore.openedBet.displayedAmount !=
							null && (
							<div className="widget--bets__actions">
								<MyBetsCashoutButton
									bet={props.myBetsViewStore.openedBet}
									openCashoutModal={
										props.myBetsViewStore.cashoutStore
											.openCashoutModal
									}
								/>
								<MyBetsAutosellButton
									bet={props.myBetsViewStore.openedBet}
									openAutosellModal={
										props.myBetsViewStore.cashoutStore
											.openAutosellModal
									}
									isAutoCashoutEnabled={
										props.myBetsViewStore.cashoutStore
											.isAutoCashoutEnabled
									}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			{props.myBetsViewStore.cashoutStore.isCashoutModalOpen &&
				props.myBetsViewStore.cashoutStore.cashoutModalData != null && (
					<MyBetsCashoutConfirmation
						isOpen={
							props.myBetsViewStore.cashoutStore
								.isCashoutModalOpen
						}
						{...props.myBetsViewStore.cashoutStore.cashoutModalData}
						onAccept={props.myBetsViewStore.cashoutStore.cashout}
						onClose={
							props.myBetsViewStore.cashoutStore.closeCashoutModal
						}
						isSubmitting={
							props.myBetsViewStore.cashoutStore
								.isCashoutFormSubmitting
						}
					/>
				)}
			{props.myBetsViewStore.isReuseModalOpen && (
				<MyBetsReuseConfirmation
					isOpen={props.myBetsViewStore.isReuseModalOpen}
					{...props.myBetsViewStore.reuseModalData}
					onAccept={props.myBetsViewStore.reuseBetSlip}
					onClose={props.myBetsViewStore.closeReuseBetSlipModal}
				/>
			)}
			{props.myBetsViewStore.cashoutStore.isAutosellModalOpen && (
				<MyBetsAutosellConfirmation
					store={props.myBetsViewStore.cashoutStore}
				/>
			)}
		</div>
	);
});

export default BetDisplay;
