import LookupApiService from "@api-services/LookupApiService";
import {
	filterLanguageList,
	mapDataForPersonalDataUpdate,
} from "@v2/helpers/account-settings/personal-data/utils";
import {
	PersonalDataResponseModel,
	UpdatePersonalDataRequestModel,
} from "src/data/types";
import {
	AccountTypes,
	UserTypes,
	getCurrentCulture,
	getUserFromLocalStorage,
	isNullOrWhitespace,
} from "@utils";
import {
	AgencyUserDto,
	PlayerPromoSendingMode,
} from "@api-types/user/AgencyUserDto";
import { logger } from "@state";
import { RegistrationLookupService } from "@v2/data-access/membership/registration";
import { DateTime } from "luxon";
import { SUPPORTED_LANGUAGES } from "@common/constants/SupportedLanguages";

// import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));

export default class PersonalDataService {
	static async getPersonalData(shouldFetchLookups: boolean = true) {
		try {
			const lookupService = new RegistrationLookupService();
			const getIsoFromSupportedLanguages = SUPPORTED_LANGUAGES.map(
				(lang) => {
					return lang.iso;
				}
			).join(",");

			let countries = null;
			let languages = null;
			let occupation = null;
			let groupedOccupation = null;
			let countryRegions = null;
			let titles = null;
			let supportedLanguageList = null;

			if (shouldFetchLookups) {
				countries = await LookupApiService.fetchCountriesLookup();
				languages = await LookupApiService.fetchLanguagesLookupByIso(
					getIsoFromSupportedLanguages
				);
				occupation = await lookupService.findOccupations();
				groupedOccupation = await lookupService.findOccupationGroups();
				getIsoFromSupportedLanguages;
				countryRegions = await lookupService.findCountryRegions();
				titles = await LookupApiService.fetchTitlesLookup();
				supportedLanguageList = filterLanguageList(
					SUPPORTED_LANGUAGES,
					languages.item
				);
			}
			const userData = await (
				await AgencyUserApiService
			).default.getUserInfo();

			const primaryUser = getUserFromLocalStorage();
			if (!primaryUser || userData.countryId == null) {
				throw { message: "GENERIC_ERROR" };
			}

			const { isInternetUser, isOfflineUserMailApproved } = primaryUser;

			const model: PersonalDataResponseModel | null = {
				data: {
					userName: userData.userName,
					firstName: userData.firstName,
					lastName: userData.lastName,
					dob: userData.dateOfBirth,
					birthPlace: userData.birthPlace,
					titleId: userData.titleId,
					countryId: userData.countryId,
					city: userData.city,
					street: userData.street,
					zipCode: userData.zipCode,
					houseNumber: userData.houseNumber,
					currentEmail:
						!isInternetUser && !isOfflineUserMailApproved
							? "---"
							: userData.email,
					mobilePhone: userData.mobilePhone,
					telephoneNumber: userData.telephoneNumber,
					language: userData.cultureInfoName,
					payPin: userData.payPin,
					occupation: userData.occupationId,
					birthName: userData.birthName,
					nationality: userData.nationality,
					receiveNewsletter:
						userData.playerPromoSendingMode ==
						PlayerPromoSendingMode.enabled
							? true
							: false,
					insicStatus: userData.insicStatus,
					state: userData.regionId,
					personalIdentificator:
						userData.personalIdentificator || null,
					documentExpiration: userData.documentExpiration,
				},
				countries: countries != null ? countries.item : [],
				languages:
					supportedLanguageList != null ? supportedLanguageList : [],
				userTitles: titles != null ? titles.item : [],
				occupationData: occupation != null ? occupation.item : [],
				occupationGroupData:
					groupedOccupation != null ? groupedOccupation.item : [],
				countryRegions:
					countryRegions != null ? countryRegions.item : [],
			};
			return model;
		} catch (error) {
			logger.logError(error);
			if (error.message == "Unauthorized") {
				return;
			}
			throw { message: "GENERIC_ERROR" };
		}
	}

	static async updateUserPin(payPin: string) {
		if (isNullOrWhitespace(payPin)) {
			throw { message: "GENERIC_ERROR" };
		}

		let offlineUserPos = UserTypes.PRIMARY;
		if (App.state.rootStore.userAuthStore.secondaryUser) {
			offlineUserPos =
				App.state.rootStore.userAuthStore.getUserAccountType(
					AccountTypes.OFFLINE
				);
		}

		try {
			await (
				await AgencyUserApiService
			).default.updateUserPin(payPin, offlineUserPos);
			// TODO: return data for ui update
			return null;
		} catch (error) {
			logger.logError(error);
			if (error.message == "Unauthorized") {
				// TODO: return error for unauthorized
				// return new ErrorResponse();
				return new Error();
			}
			throw { message: "PERSONAL_DATA_ERR" };
		}
	}

	static async updatePersonalData(model: UpdatePersonalDataRequestModel) {
		if (model == null || model == null) {
			throw { message: "GENERIC_ERROR" };
		}

		model.dob = DateTime.fromISO(model.dob).toFormat("yyyy-MM-dd");
		if (model.documentExpiration) {
			model.documentExpiration = DateTime.fromISO(
				model.documentExpiration
			).toFormat("yyyy-MM-dd");
		}

		let agencyUser: AgencyUserDto | null = null;

		try {
			agencyUser = await (
				await AgencyUserApiService
			).default.getUserInfo();
		} catch (error) {
			logger.logError(error);
			if (error.message == "Unauthorized") {
				return;
			}
			throw { message: "GENERIC_ERROR" };
		}

		if (model?.receiveNewsletter != agencyUser?.playerPromoSendingMode) {
			try {
				await (
					await AgencyUserApiService
				).default.promoMaterialsApproval(model.receiveNewsletter);
			} catch (error) {
				logger.logError(error);

				const errorObj = await error.rawResponse.json();

				if (errorObj[0].errorCode === 400229) {
					throw {
						message:
							"MEMBERSHIP.ERR_HANDLING.SPELPAUS_MARKETING_EXCLUSION_ACTIVE",
						isFull: true,
					};
				}
			}
		}

		try {
			await (
				await AgencyUserApiService
			).default.updatePersonalData(
				mapDataForPersonalDataUpdate(agencyUser, model)
			);
			return model;
		} catch (error) {
			if (error.message == "Unauthorized") {
				return;
			} else if (error.data.errorCode === 400216) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isBlocked=true`
				);
				return;
			} else if (error.data.errorCode === 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
				return;
			} else if (error.data.errorCode === 400176) {
				throw { message: "INVALID_PASSWORD" };
			} else if (error.data.errorCode === 400230) {
				throw {
					message:
						"MEMBERSHIP.ERR_HANDLING.SPELPAUS_USER_BLOCKED_FROM_PLAYING",
					isFull: true,
				};
			} else if (error.data.errorCode === 400242) {
				throw {
					message: "MEMBERSHIP.ERR_HANDLING.LUGAS_VALIDATION_ERROR",
					isFull: true,
				};
			} else if (error.data.errorCode === 400243) {
				throw {
					message:
						"MEMBERSHIP.ERR_HANDLING.LUGAS_PLAYER_NOT_REGISTERED",
					isFull: true,
				};
			} else if (error.data.errorCode === 400256) {
				throw {
					message: "MEMBERSHIP.ERR_HANDLING.LUGAS_UNSPECIFIED",
					isFull: true,
				};
			} else {
				throw { message: "GENERIC_ERROR" };
			}
		}
	}

	static async updatePersonalDataForOnlineUser(
		model: UpdatePersonalDataRequestModel
	) {
		if (model == null || model == null) {
			throw { message: "GENERIC_ERROR" };
		}

		let agencyUser: AgencyUserDto | null = null;

		try {
			agencyUser = await (
				await AgencyUserApiService
			).default.getUserInfo();
		} catch (error) {
			logger.logError(error);
			if (error.message == "Unauthorized") {
				return;
			}
			throw { message: "GENERIC_ERROR" };
		}

		// if (model?.receiveNewsletter != agencyUser?.playerPromoSendingMode) {
		// 	try {
		// 		await AgencyUserApiService.promoMaterialsApproval(
		// 			model.receiveNewsletter
		// 		);
		// 	} catch (error) {
		// 		logger.logError(error);
		// 	}
		// }

		try {
			await (
				await AgencyUserApiService
			).default.updatePersonalDataWithoutPassword(model);
			return model;
		} catch (error) {
			if (error.message == "Unauthorized") {
				return;
			} else if (error.data.errorCode === 400216) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isBlocked=true`
				);
				return;
			} else if (error.data.errorCode === 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
				return;
			} else if (error.data.errorCode === 400176) {
				throw { message: "INVALID_PASSWORD" };
			} else if (error.data.errorCode === 400230) {
				throw {
					message:
						"MEMBERSHIP.ERR_HANDLING.SPELPAUS_USER_BLOCKED_FROM_PLAYING",
					isFull: true,
				};
			} else if (error.data.errorCode === 400242) {
				throw {
					message: "MEMBERSHIP.ERR_HANDLING.LUGAS_VALIDATION_ERROR",
					isFull: true,
				};
			} else if (error.data.errorCode === 400243) {
				throw {
					message:
						"MEMBERSHIP.ERR_HANDLING.LUGAS_PLAYER_NOT_REGISTERED",
					isFull: true,
				};
			} else if (error.data.errorCode === 400256) {
				throw {
					message: "MEMBERSHIP.ERR_HANDLING.LUGAS_UNSPECIFIED",
					isFull: true,
				};
			}
			throw { message: "GENERIC_ERROR" };
		}
	}

	static getNewPayPin() {
		return Math.floor(1000 + Math.random() * 9000);
	}
}
