import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { range } from "lodash";
import { SelectedSportCheckBox, CategoryItems, SportsList } from "./";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";

export const ExpandedOfferMenu = observer(function ExpandedOfferMenu(props: {
	store: {
		isOfferMenuOpen: boolean;
		isEmpty: boolean;
		isLoading: boolean;
		toggleUpdateProcessing?: PrematchOfferMenuStore["toggleUpdateProcessing"];
		selectedSport: PrematchOfferMenuStore["selectedSport"];
		menu: PrematchOfferMenuStore["menu"];
		resetMenu: PrematchOfferMenuStore["resetMenu"];
		type: "PrematchOfferMenuStore" | "LiveOfferMenuStore";
	};
}) {
	if (!props.store.isOfferMenuOpen) {
		return null;
	}

	return (
		<nav className="nav--tertiary">
			<ul className="nav--tertiary__menu">
				<MenuCore store={props.store} />
			</ul>
		</nav>
	);
});

const MenuCore = observer(function MenuCore(props: {
	store: {
		isEmpty: boolean;
		isLoading: boolean;
		toggleUpdateProcessing?: PrematchOfferMenuStore["toggleUpdateProcessing"];
		selectedSport: PrematchOfferMenuStore["selectedSport"];
		menu: PrematchOfferMenuStore["menu"];
		type: "PrematchOfferMenuStore" | "LiveOfferMenuStore";
	};
}) {
	const t = useTranslation().t;

	if (props.store.isEmpty) {
		return (
			<div className="u-padd--xsml u-padd--top--reset">
				<div className="card--empty u-mar--bottom--reset u-mar--top--xsml">
					<div className="card--empty__content">
						<i className="card--empty__icon u-icon u-icon--xlrg u-icon--empty u-icon--empty--search" />
						<div className="card--empty__message u-mar--bottom--reset">
							{t<string>("PREMATCH.SIDEMENU.EMPTY_STATE")}
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (props.store.isLoading) {
		return (
			<div className="loader--skeleton loader--skeleton--content loader--skeleton--nav-tertiary">
				{range(1, 11).map((i) => (
					<div key={i} className="loader--skeleton__item">
						<div className="loader--skeleton__icon" />
						<div className="loader--skeleton__title loader--skeleton__title--med" />
					</div>
				))}
			</div>
		);
	}

	if (props.store.selectedSport != null) {
		return (
			<>
				<SelectedSportCheckBox
					sport={props.store.selectedSport}
					store={props.store}
				/>
				<CategoryItems
					sport={props.store.selectedSport}
					store={props.store}
				/>
			</>
		);
	}

	return <SportsList store={props.store} />;
});
