import LookupApiService from "@api-services/LookupApiService";

import { GamingLimitCategoryService } from "@api-services/account-settings/GamingLimitCategoryService";
import { GamingLimitService } from "@api-services/account-settings/GamingLimitService";
import { LimitCategories, MappedCategories } from "@api-types";
import {
	ResolveLimit,
	ResolveLimitRequest,
	LimitCategoriesViewModel,
} from "@data-types";
import { AccountTypes } from "@utils";
import { sortDurations } from "@v2/helpers/account-settings/my-limits/sortDurations";
const SGUID_empty = "0000000000000000000000";
const limitTypesList = ["stake-limit", "loss-limit"];

export class MyLimitsService {
	static async fetchLimitLookups() {
		try {
			const limitDurationsLookup =
				await LookupApiService.fetchGamingLimitDurationsLookup();
			const limitTypesLookup =
				await LookupApiService.fetchGamingLimitTypes();
			const limitTypes: LimitTypeItem[] = [];
			limitTypesLookup?.item.forEach((e: LimitTypeItem) => {
				if (limitTypesList.includes(e.abrv)) {
					limitTypes.push(e);
				}
			});

			return {
				limitDurations: sortDurations(limitDurationsLookup.item),
				limitTypes: limitTypes,
			};
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	static async getTabs() {
		try {
			//multi wallet - fetch categories for online user
			//otherwise fetch categories for user that is logged in
			const user = App.state.rootStore.userAuthStore.user;
			const secondaryUser =
				App.state.rootStore.userAuthStore.secondaryUser;

			const onlineUser =
				App.state.rootStore.userAuthStore.getUserAccountType(
					AccountTypes.ONLINE
				);
			const offlineUser =
				App.state.rootStore.userAuthStore.getUserAccountType(
					AccountTypes.OFFLINE
				);

			if (secondaryUser === null) {
				if (user?.isInternetUser === true) {
					const primaryGamingLimitResult =
						await GamingLimitCategoryService.getAvailableCategories(
							onlineUser,
							null
						);

					const primary = this.mapToCategories({
						accountType: AccountTypes.ONLINE,
						result: primaryGamingLimitResult,
					});

					return primary;
				} else {
					const secondaryGamingLimitResult =
						await GamingLimitCategoryService.getAvailableCategories(
							offlineUser,
							null
						);

					const secondary = this.mapToCategories({
						accountType: AccountTypes.OFFLINE,
						result: secondaryGamingLimitResult,
					});

					return secondary;
				}
			} else {
				const onlineUserGamingLimitResult =
					await GamingLimitCategoryService.getAvailableCategories(
						onlineUser,
						null
					);

				const tabResult = this.mapToCategories({
					accountType: AccountTypes.ONLINE,
					result: onlineUserGamingLimitResult,
				});

				return {
					tabs: tabResult.tabs,
					isMultiModeEnabled: tabResult.isMultiModeEnabled,
				};
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	static async getTabData(activeAccount: AccountTypes, categoryId: string) {
		const userType =
			App.state.rootStore.userAuthStore.getUserAccountType(activeAccount);

		try {
			const gamingLimitResult =
				await GamingLimitCategoryService.getAvailableCategories(
					userType,
					categoryId
				);

			return this.mapToViewModel(gamingLimitResult);
		} catch (error) {
			throw {
				error: error,
				success: false,
			};
		}
	}

	static async resolveLimit(
		activeAccount: AccountTypes,
		model: ResolveLimit
	) {
		const userType =
			App.state.rootStore.userAuthStore.getUserAccountType(activeAccount);

		const resolveUserLimit = {
			amount: model.amount,
			gamingLimitAction: model.action,
			gamingLimitCategoryId: model.categoryId,
			gamingLimitDurationId: model.durationId,
			gamingLimitTypeId: model.typeId,
			limitId: model.limitId === SGUID_empty ? null : model.limitId,
		};

		try {
			const result = await GamingLimitService.resolveGamingLimit(
				userType,
				resolveUserLimit
			);
			if (result.isSuccessful) {
				const gamingLimitResult =
					await GamingLimitCategoryService.getAvailableCategories(
						userType,
						model.categoryId
					);
				return this.mapToViewModel(
					gamingLimitResult,
					result?.infoMessage
				);
			}
		} catch (error) {
			console.error(error);
			if (error?.data?.infoMessage) {
				throw {
					message: error.data.infoMessage,
					errorCode: error.data.validationError,
				};
			}
			throw "ERROR_GAMINGLIMIT";
		}
	}

	static async resolveRequest(
		activeAccount: AccountTypes,
		model: ResolveLimitRequest
	) {
		const userType =
			App.state.rootStore.userAuthStore.getUserAccountType(activeAccount);

		const resolveUserRequestModel = {
			action: model.action,
			gamingLimitCategoryId: model.categoryId,
			userLimitId: model.userLimitId,
			userLimitRequestId: model.userLimitRequestId,
		};

		try {
			const result = await GamingLimitService.resolveGamingLimitRequest(
				userType,
				resolveUserRequestModel
			);
			if (result.isSuccessful) {
				const gamingLimitResult =
					await GamingLimitCategoryService.getAvailableCategories(
						userType,
						model.categoryId
					);
				return this.mapToViewModel(
					gamingLimitResult,
					result?.infoMessage
				);
			}
		} catch (error) {
			console.error(error);
			if (error?.data?.infoMessage) {
				throw { message: error.data.infoMessage };
			}
			throw "ERROR_GAMINGLIMIT";
		}
	}

	static mapToCategories({
		accountType,
		result,
	}: {
		accountType: AccountTypes;
		result: LimitCategories;
	}): MappedCategories {
		return {
			tabs: result.limitCategories.map((category) => ({
				id: category.gamingLimitCategoryId,
				name: category.gamingLimitCategory.name,
				accountType: accountType,
			})),
			isMultiModeEnabled: result.selectedCategory.isMultiModeEnabled,
		};
	}

	static mapToViewModel(
		model: LimitCategories,
		infoMessage?: string
	): LimitCategoriesViewModel {
		let mappedModel = {
			categoryId: model.selectedCategory.gamingLimitCategoryId,
			categoryName: model.selectedCategory.gamingLimitCategory.name,
			maxAmount: model.selectedCategory.maxAmount,
			minAmount: model.selectedCategory.minAmount,
			isMultiLimitModeEnabled: model.selectedCategory.isMultiModeEnabled,
			isMultiTypeMode: model.selectedCategory.isMultiTypeMode,
			description: this.mapDescriptionToViewModel(
				model.selectedCategory.gamingLimitCategory.description,
				model.selectedCategory.gamingLimitCategory.abrv,
				model.selectedCategory.isMultiModeEnabled,
				model.selectedCategory.isAutoConfirmationEnabled
			),

			activeCategoryAbrv: model.selectedCategory.gamingLimitCategory.abrv,
			durations: model.userLimits.map((ul) => ({
				limitId: ul.id,
				name: ul.gamingLimitDuration?.name,
				canBeUpdated: ul.id !== SGUID_empty,
				canBeDeleted:
					ul.isDeactivationAllowed && !ul.isDeactivationPending, // false
				categoryId: ul.gamingLimitCategoryId,
				durationId: ul.gamingLimitDurationId,
				typeId: ul.gamingLimitTypeId,
				units: ul.gamingLimitDuration?.units,
				amount: ul.id == SGUID_empty ? null : ul.amount,
			})),
			updateInterval: model.selectedCategory.updateInterval,
			requests: model.limitsUpdateRequests.map((lr) => ({
				requestType: RequestTypeEnum[lr.requestType],
				limitType: model.selectedCategory.isMultiModeEnabled
					? lr.gamingLimit.gamingLimitDuration.name
					: lr.newGamingLimitCombination?.gamingLimitType.name,
				requestedAmount: lr.amount,
				currentAmount: lr.gamingLimit.amount,
				categoryId: lr.gamingLimit.gamingLimitCategoryId,
				requestDate: lr.requestDate,
				expirationDate: lr.expirationDate,
				limitId: model.selectedCategory.isMultiModeEnabled
					? lr.gamingLimitId
					: lr?.newGamingLimitCombinationId || lr.gamingLimit.id,
				requestId: lr.id,
				//
				limitInterval:
					lr?.newGamingLimitCombination?.gamingLimitDuration?.name,
				hasLimitAmountChanged: lr.amount !== lr.gamingLimit.amount,
				hasLimitTypeChanged:
					lr.gamingLimit.gamingLimitTypeId !==
					lr?.newGamingLimitCombination?.gamingLimitTypeId,
				hasLimitDurationChanged:
					lr.gamingLimit.gamingLimitDurationId !==
					lr?.newGamingLimitCombination?.gamingLimitDurationId,
				isDeactivation:
					lr.requestType ===
					RequestTypeEnum[
						"USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DEACTIVATE"
					],
			})),
			infoMessage: infoMessage,
			currency: model.currencyCode,
		};
		if (
			!mappedModel.isMultiLimitModeEnabled &&
			mappedModel.durations != null &&
			mappedModel.durations.length > 0 &&
			model.userLimits != null &&
			model.userLimits.length > 0
		) {
			mappedModel.durations[0].canBeDeleted =
				model.selectedCategory?.isDeactivationAllowed &&
				!model.userLimits[0].isDeactivationPending;
		}
		return mappedModel;
	}

	static mapDescriptionToViewModel(
		title: string,
		tabAbrv: string,
		isMultiMode: boolean,
		isAutoConfirmationEnabled: boolean
	): string[] {
		/*
			possible abrvs:
			deposit
			sports-betting
			games-deposit
			live-casino
			online-casino
			login-time
			casino-deposit
			live-casino-deposit
		*/
		const descriptionArray = [title];
		if (isMultiMode) {
			//DE only for now
			switch (tabAbrv) {
				case "deposit":
				case "games-deposit":
					descriptionArray.push("DESC_FIRST_DEPOSIT", "DESC_SECOND");
					break;
				case "sports-betting":
					descriptionArray.push("DESC_FIRST_FIRST", "DESC_SECOND");
					break;
				case "live-casino":
					descriptionArray.push(
						"DESC_FIRST_LIVE_CASINO",
						"DESC_SECOND"
					);
					break;
				case "online-casino":
					descriptionArray.push("DESC_FIRST_CASINO", "DESC_SECOND");
					break;
			}
		} else {
			//AT AND COM
			switch (tabAbrv) {
				case "deposit":
				case "games-deposit":
				case "casino-deposit":
				case "live-casino-deposit":
					descriptionArray.push("DESC_FIRST_DEPOSIT", "DESC_SECOND");
					break;
				case "login-time":
					descriptionArray.push("DESC_FIRST_LOGIN_TIME");
					break;
				case "sports-betting":
					descriptionArray.push(
						"DESC_FIRST_FIRST",
						"DESC_FIRST_SECOND",
						"DESC_FIRST_THIRD",
						"DESC_SECOND"
					);
					break;
				case "live-casino":
					descriptionArray.push(
						"DESC_FIRST_LIVE_CASINO",
						"DESC_FIRST_SECOND",
						"DESC_FIRST_THIRD",
						"DESC_SECOND"
					);
					break;
				case "online-casino":
					descriptionArray.push(
						"DESC_FIRST_CASINO",
						"DESC_FIRST_SECOND",
						"DESC_FIRST_THIRD",
						"DESC_SECOND"
					);
					break;
			}
		}
		descriptionArray.push("DESC_THIRD", "DESC_FOURTH");

		if (isAutoConfirmationEnabled) {
			descriptionArray.push("DESC_FIFTH_AUTO_CONFIRMATION");
		} else {
			descriptionArray.push("DESC_FIFTH");
		}

		return descriptionArray;
	}
}

export enum RequestTypeEnum {
	"USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_INCREASE" = 1,
	"USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_CHANGE" = 2,
	"USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DEACTIVATE" = 3,
}

type LimitTypeItem = {
	abrv: string;
	dateCreated: string;
	dateUpdated: string;
	description: string;
	id: string;
	name: string;
};
