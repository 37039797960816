import React from "react";
import { FormInputField } from "@v2/components/desktop/shared/inputs";

export default function RegistrationStreet(props: { labelClassName?: string }) {
	const { labelClassName } = props;

	return (
		<FormInputField
			className="form__field"
			name="street"
			label="MEMBERSHIP.REGISTRATION.STREET_LABEL"
			labelClassName={labelClassName}
			placeholder="MEMBERSHIP.REGISTRATION.STREET_PLACEHOLDER"
			// do not delete, maybe will be used in the future
			// popoverMsg={"MEMBERSHIP.REGISTRATION.STREET_DESCRIPTION"}
			pattern={{
				value: /^[\\\p{L}0-9 /'\-\.]{1,80}$/u,
				message: "MEMBERSHIP.REGISTRATION.STREET_CHARACTER",
			}}
			required
		/>
	);
}
