import LookupApiService from "@api-services/LookupApiService";
import { ValidatePlayerApiService } from "@api-services/locks/ValidatePlayerApiService";
import { AgencyUserLocksApiService } from "@api-services/locks/AgencyUserLocksApiService";
import { UserOasisLockBlockReasonsTypesModel } from "@api-types";
import { PlayerPromoSendingMode } from "@api-types/user/AgencyUserDto";
import { SelfExclusionInfoDto } from "@api-types/locks/SelfExclusionInfoDto";
import {
	AccountClosureUpdateRequestDataModel,
	AccountClosureViewModel,
	AccountLockLookupsInitialDataModel,
	AccountLockWithPromoMatterialsViewModel,
	OasisLockGetViewModel,
	OasisLockLookupsInitialDataModel,
} from "@data-types";
import { logger } from "@state";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const TwAgencyUserLocksApiService = lazy(loadFailPath, ()=>import("@api-services/locks/TwAgencyUserLocksApiService"));
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));

export class AccountLockService {
	static getAccountLockDurationLookups() {
		const response = LookupApiService.fetchUserAccountLockDurationTypes();
		return response;
	}

	static getAccountLockReasonTypesLookups() {
		const response = LookupApiService.fetchUserAccountLockReasonTypes();
		return response;
	}

	static getOasisLockReasonTypesLookups() {
		const response = LookupApiService.fetchOasisLockReasonsTypes();
		return response;
	}

	static getCountriesLookup() {
		const response = LookupApiService.fetchCountriesLookup();
		return response;
	}

	static async getAccountClosureViewData(
		lookupOptions: AccountLockLookupsInitialDataModel
	) {
		try {
			const user = await (
				await AgencyUserApiService
			).default.getUserInfo();
			const durationTypes = await this.getAccountLockDurationLookups();
			const reasonTypes = await this.getAccountLockReasonTypesLookups();

			const accountClosureViewModel = new AccountClosureViewModel();
			accountClosureViewModel.durationTypes = durationTypes.item
				.map((x) => {
					return {
						id: x.id,
						name: x.name,
						abrv: x.abrv,
						units: x.units,
					};
				})
				.filter((y) => {
					return lookupOptions.durationAbrvs.includes(y.abrv);
				});

			accountClosureViewModel.reasonTypes = reasonTypes.item
				.map((x) => {
					return {
						id: x.id,
						abrv: x.abrv,
						name: x.name,
					};
				})
				.filter((y) => {
					return lookupOptions.reasonAbrvs.includes(y.abrv);
				});
			const reasonTypeNone = reasonTypes.item.filter(
				(x) => x.abrv == "none"
			);

			accountClosureViewModel.isAdminLock = user.isAdminLock;
			accountClosureViewModel.playerPromoSendingMode =
				user.playerPromoSendingMode;
			return accountClosureViewModel;
		} catch (error) {
			logger.logError(error);
			if (error.message == "Unauthorized") {
				return;
			}
			throw { message: "GENERIC_ERROR" };
		}
	}

	static async createAccountLock(
		model: AccountClosureUpdateRequestDataModel
	) {
		const accountClosureModel: Omit<
			AccountLockWithPromoMatterialsViewModel,
			"isPromoApproved"
		> & { isPromoApproved: 1 | 0 } = {
			userAccountLockExplanation: model.explanation,
			userAccountLockDurationTypeId: model.lockDuration,
			userAccountLockReasonTypeId: model.lockReason,
			password: model.password,
			agencyUserIds: [
				{
					agencyId: model.agencyUserIds.agencyId,
					userId: model.agencyUserIds.userId,
				},
			],
			isPromoApproved:
				model.isPromoApproved == PlayerPromoSendingMode.enabled ? 1 : 0,
		};

		try {
			const response = await AgencyUserLocksApiService.createAccountLock(
				accountClosureModel
			);
			if ("isPromoApproved" in accountClosureModel) {
				await (
					await AgencyUserApiService
				).default.promoMaterialsApproval(
					accountClosureModel.isPromoApproved
				);
			}
			// if user is locked, response is null
			if (response == null) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isUserLockedOut=true`
				);
				return null;
			}

			return true;
		} catch (error) {
			let errorMsg;
			if (error.message == "Unauthorized") {
				return null;
			}

			if (error.data.errorCode == 400176) {
				errorMsg = "INVALID_PASSWORD";
			} else if (error.data.errorCode == 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
				return null;
			} else {
				errorMsg = "ACCOUNT_LOCK_ERR";
			}
			throw { message: errorMsg };
		}
	}

	// Oassis lock methods

	static async getOasisBlockData(
		lookupOptions: OasisLockLookupsInitialDataModel
	): Promise<OasisLockGetViewModel | undefined> {
		try {
			const viewModel = new OasisLockGetViewModel();

			const durationTypes = await this.getAccountLockDurationLookups();
			const reasonTypes = await this.getOasisLockReasonTypesLookups();
			const countries = await this.getCountriesLookup();

			viewModel.durationTypes = durationTypes.item
				.map((x) => {
					return {
						id: x.id,
						name: x.name,
						abrv: x.abrv,
						units: x.units,
					};
				})
				.filter((y) => {
					return lookupOptions.oasisDurationAbrvs.includes(y.abrv);
				});

			viewModel.countries = countries.item.map((country) => {
				return {
					id: country.id,
					name: country.name,
					abrv: country.abrv,
					nationality: country.nationality,
				};
			});

			viewModel.blockReasons = reasonTypes.item
				.map((x) => {
					return {
						id: x.id,
						abrv: x.abrv,
						name: x.name,
						code: x.code,
					};
				})
				.filter((y) => {
					return lookupOptions.oasisReasonCodes.includes(y.code);
				});

			if (lookupOptions.oasisReasonCodesDisableCode == "99") {
				let disableOption: UserOasisLockBlockReasonsTypesModel[] = [];
				reasonTypes.item.map((x) => {
					if (x.code == "99") {
						disableOption.push({
							id: x.id,
							abrv: x.abrv,
							name: x.name,
							code: x.code,
						});
					}
				});
				viewModel.blockReasonsDisableOption = disableOption[0];
			}
			let personalDetailsArray: any = [];

			if(App.state.rootStore.userAuthStore.user == null){
				console.error("Expected user, got null.");
				return;
			}
			const userId = App.state.rootStore.userAuthStore.user?.id;
			const { shopId, agencyKey } =
				App.state.rootStore.userAuthStore.user;

			const validateResponse =
				await ValidatePlayerApiService.validatePlayer(
					userId,
					shopId,
					agencyKey
				);
			if (validateResponse.validationErrors.length != 0) {
				validateResponse.validationErrors.map((missingField) => {
					if (missingField.fieldName) {
						personalDetailsArray.push({
							fieldName:
								missingField.fieldName.charAt(0).toLowerCase() +
								missingField.fieldName.slice(1),
							value: "",
						});
					}
				});

				viewModel.personalDetails = personalDetailsArray;
				viewModel.isPersonalDetailsMissing = true;
			} else {
				viewModel.isPersonalDetailsMissing = false;
			}
			return viewModel;
		} catch (error) {
			if (error.message == "Unauthorized") {
				return;
			}
			throw { message: "GENERIC_ERROR" };
		}
	}

	static async createOasisBlock(model: SelfExclusionInfoDto) {
		// TODO: test this model after oasis response is fixed from BE
		const dataUpdateResponse = {
			isDataUpdated: false,
			isPasswordValid: false,
			isUserLockedOut: false,
		};

		try {
			const response = await (
				await TwAgencyUserLocksApiService
			).default.createOasisSelfTemporaryLock(model);
			
			// field isSuccess doesn't exist on array and string
			if (response == null || Array.isArray(response) || typeof response === 'string' || !response.isSuccess) {
				dataUpdateResponse.isDataUpdated = false;
				dataUpdateResponse.isUserLockedOut = false;
				throw { message: "GENERIC_ERROR" };
			} else {
				dataUpdateResponse.isDataUpdated = true;
				dataUpdateResponse.isUserLockedOut = true;
			}

			if (dataUpdateResponse.isDataUpdated) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isOasisLockedOut=true`
				);
			}
		} catch (error) {
			let errorMsg;

			if (error.message == "Unauthorized") {
				return false;
			}

			if (error?.data?.errorCode == 400176) {
				errorMsg = "INVALID_PASSWORD";
			} else if (error?.data?.errorCode == 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
			} else {
				errorMsg = "GENERIC_ERROR";
			}

			throw { message: errorMsg };
		}
	}
}
