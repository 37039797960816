import React, { useMemo, useContext } from "react";
import { Tooltip } from "react-tooltip";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams, useRouteMatch } from "react-router-dom";
import { getCurrentCulture } from "@utils";
import {
	LookupContext,
	OfferOptionsContext,
	OfferStoreContext,
} from "@gp/components";

import { AdditionalOffer } from "../../additional-offer";
import { ErrorPage } from "../../../shared/error";
import { PrematchSportsList } from "../../views/PrematchSportsList";
import { SearchEmptyState } from "./components";

import SkeletonLoader from "../../selected-offer-categories/SkeletonLoader";
import { useSearchStore } from "../../../../../hooks/offer/pages/useSearchStore";
import {
	additionalOfferToggle,
	useNumberOfAvailableColums,
} from "@offer/common";

function SearchPageWrapper() {
	return (
		<>
			<SearchPage />
			<AdditionalOffer />
		</>
	);
}
const SearchPage = observer(function SearchPage() {
	const { searchTerm } = useParams<{ searchTerm: string }>();

	const searchViewStore = useSearchStore(searchTerm);

	const match = useRouteMatch();

	const eventId = useMemo(() => {
		return App.state.getUrlParamsAsObj<{ event?: string }>().event;
	}, [match]);

	const {
		eventsInSports,
		isEmpty,
		isFetchingData,

		lookupsStore,

		rootStore: { calculatedOfferColumnsNumber },
		onDispose,
	} = searchViewStore;

	const numberOfColumns = useNumberOfAvailableColums(
		searchViewStore.rootStore.resizeStore.availableColumns
	);

	if (isFetchingData) {
		return (
			<SkeletonLoader
				isFullLoader={eventId != null}
				quotasCount={calculatedOfferColumnsNumber}
			/>
		);
	}

	if (isEmpty) {
		return <SearchEmptyState searchTerm={searchTerm} />;
	}

	return (
		<ErrorPage onError={onDispose}>
			<div className="l--content__main">
				{/* @ts-expect-error */}
				<OfferStoreContext.Provider value={searchViewStore}>
					<SearchHeader searchTerm={searchTerm} />

					<LookupContext.Provider value={lookupsStore}>
						<OfferOptionsContext.Provider
							value={{
								numberOfColumns,
								scoreChangeDuration: 3500,
								enableInlineAdditionalOffer: false,
								// @ts-expect-error
								additionalOfferToggle,
								isLive: false,
							}}
						>
							<PrematchSportsList
								sports={eventsInSports.sports}
							/>
						</OfferOptionsContext.Provider>
					</LookupContext.Provider>
				</OfferStoreContext.Provider>
			</div>

			<Tooltip
				id="bt-description"
				className="at-tooltipBettingType react-tooltip--primary"
				place="bottom"
				closeOnResize={true}
			/>
		</ErrorPage>
	);
});

const SearchHeader = observer(function SearchHeader(props: {
	searchTerm: string;
}) {
	const { searchTerm } = props;

	const { t } = useTranslation();
	const {
		// @ts-expect-error
		rootStore: {
			searchBarViewStore: { onReset: onSearchTermReset },
		},

		// @ts-expect-error
		searchElementsCount,
	} = useContext(OfferStoreContext);

	return (
		<div className="header--search">
			<div className="header--search__title">
				<div className="title--primary">
					{t<string>("SEARCH.TITLE", { searchTerm })}
				</div>
				<span className="counter counter--lrg counter--dark--outlined u-mar--left--xsml">
					{searchElementsCount}
				</span>
			</div>
			<button
				className="btn btn--square btn--square--tny btn--secondary--inverted btn--animate-grow"
				type="button"
				onClick={() => {
					onSearchTermReset();
					App.state.redirect(
						`/${getCurrentCulture()}/home/full/highlights`
					);
				}}
			>
				<i className="u-icon u-icon--sml u-icon--close" />
			</button>
		</div>
	);
});

export default SearchPageWrapper;
