import {
	action,
	observable,
	computed,
	reaction,
	IReactionDisposer,
} from "mobx";
import { ResultsDataStore } from "./index";
import { LoaderStore } from "@state/stores/common";
import ResultsService from "@services/results/ResultsService";
import { ResultMenuSport, ResultMenuTournament } from "@data-types/results";
import RootOfferStore from "@offer/stores/RootStore";
import { SearchBarViewStore } from "@offer/stores/components/search";

export class ResultsMenuStore {
	rootStore: RootOfferStore;
	resultsDataStore: ResultsDataStore;
	loader: LoaderStore = new LoaderStore();
	searchStore: SearchBarViewStore;
	searchReaction: IReactionDisposer;

	@observable resultMenu: ResultMenuSport[] | null;
	@observable selectedTournaments: ResultMenuTournament[] = [];
	@observable selectedSport: string | null = null;
	@observable expandedCategories: string[] = [];
	@observable isMenuExpanded = false;

	@computed get isLoading() {
		return this.loader.isLoading;
	}

	@computed get selectedSportObj(): ResultMenuSport | undefined {
		return this.resultMenu?.find((s) => s.id === this.selectedSport);
	}

	constructor(rootStore: RootOfferStore) {
		this.rootStore = rootStore;
		this.resultsDataStore = new ResultsDataStore(rootStore, this);
		this.searchStore = rootStore.searchBarViewStore;

		this.searchReaction = reaction(
			() => this.searchStore.searchTerm,
			async () => {
				this.loader.suspend();
				this.resultsDataStore.changeDayParam(0);
				this.resetSelectedTournaments();
				await this.resultsDataStore.onInitialize();
				this.loader.resume();
			}
		);
	}

	@action.bound
	async onInitialize(): Promise<void> {
		const resultMenuRequest = await ResultsService.getMenu({
			previousDays: 7,
		});

		if (resultMenuRequest.success) {
			this.resultMenu = resultMenuRequest.data;
		}
	}

	@action.bound
	setSelectedTournaments(tournament: ResultMenuTournament): void {
		const selectedTournamentIdx = this.selectedTournaments.findIndex(
			(t) => t.id === tournament.id
		);
		if (selectedTournamentIdx > -1) {
			this.selectedTournaments.splice(selectedTournamentIdx, 1);
			if (this.selectedTournaments.length === 0) {
				this.resultsDataStore.changeDayParam(0);
			}
		} else {
			this.selectedTournaments.unshift(tournament);
		}
		if (this.selectedTournaments.length > 0) {
			this.rootStore.searchBarViewStore.onTermChange("");
			this.resultsDataStore.changeDayParam(0);
		}
	}

	@action.bound
	setExpandedCategories(categoryId: string): void {
		const expandedCategoryIdx = this.expandedCategories.findIndex(
			(c) => c === categoryId
		);
		if (expandedCategoryIdx > -1) {
			this.expandedCategories.splice(expandedCategoryIdx, 1);
		} else {
			this.expandedCategories.push(categoryId);
		}
	}

	@action.bound
	toggleMenuExpanded(isManualToggle = false): void {
		if (isManualToggle && this.resultMenu != null) {
			const firstSportId =
				this.resultMenu.length > 0 ? this.resultMenu[0].id : null;

			if (firstSportId != null && this.selectedSport === null) {
				this.selectedSport = firstSportId;
			}
		}
		this.isMenuExpanded = !this.isMenuExpanded;
	}

	@action.bound
	setSelectedSport(sportId: string | null): void {
		if (sportId != null) {
			this.selectedSport = sportId;
		} else {
			this.selectedSport = null;
		}
	}

	@action.bound
	resetSelectedTournaments(): void {
		this.selectedTournaments = [];
	}
}
