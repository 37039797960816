import useScrollDirection from "@hooks/useScrollDirection";
import classnames from "classnames";
import React from "react";
import { ScrollDirectionContext } from "@v2/context";
interface LayoutAppWrapperProps {
	children: React.ReactNode;
}

export function LayoutAppWrapper(props: LayoutAppWrapperProps) {
	const scrollDirection = useScrollDirection();

	const wrapperClassName = classnames("l--wrapper", `is-${scrollDirection}`);

	return (
		<ScrollDirectionContext.Provider value={scrollDirection}>
			<div className={wrapperClassName}>{props.children}</div>
		</ScrollDirectionContext.Provider>
	);
}

export default LayoutAppWrapper;
