import { Response, ErrorResponse } from "../../shared";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));
// prettier-ignore
const UserDataApiService = lazy(loadFailPath, ()=>import("@api-services/user/UserDataApiService"))

class RecoverPasswordService {
	async checkPasswordRecoveryToken(
		passwordRecoveryToken: string,
		username: string
	) {
		if (passwordRecoveryToken == null || username == null) {
			throw new ErrorResponse({
				message:
					"MEMBERSHIP.FORGOT_PASSWORD.PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED",
			});
		}

		try {
			await (
				await AgencyUserApiService
			).default.verifyPasswordRecoveryToken(
				passwordRecoveryToken,
				username
			);
		} catch (error) {
			console.error(error);
			if (
				error?.data?.errorCode == 400163 ||
				error?.data?.errorCode == 4040020000
			) {
				throw new ErrorResponse({
					message:
						"MEMBERSHIP.FORGOT_PASSWORD.PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED",
				});
			}
			throw new ErrorResponse({
				message:
					"MEMBERSHIP.FORGOT_PASSWORD.PASSWORD_RECOVERY_REQUEST_ERROR",
			});
		}
	}

	async recoverPassword(
		token: string,
		username: string,
		formData: RecoverPasswordFormData
	) {
		try {
			await (
				await UserDataApiService
			).default.recoverPassword({
				passwordRecoveryToken: token,
				username: username,
				...formData,
			});
			return new Response();
		} catch (error) {
			console.error(error);
			if (error.statusCode == 500) {
				const errorObject = await error.rawResponse.json();
				if (errorObject?.errorCode === 400163) {
					throw new ErrorResponse({
						message: "PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED",
					});
				} else if (errorObject?.errorCode === 400165) {
					throw new ErrorResponse({
						message: "ERROR_PWD_CHANGE_SIMILAR",
					});
				} else if (
					errorObject?.errorCode === 400170 ||
					errorObject?.errorCode === 400171
				) {
					throw new ErrorResponse({
						message: "ERROR_PWD_CHANGE_OLD_SAME",
					});
				}
			}
			throw new ErrorResponse({ message: "PASSWORD_RECOVERY_ERROR" });
		}
	}
}

export const recoverPasswordService = new RecoverPasswordService();
export type RecoverPasswordFormData = {};
