import { LoginResponseUser } from "@api-types/user/LoginResponseDto";
import { BetSlipInteractionTypeEnum } from "@data-types/analytics/BetSlipInteractionTypeEnum";
import { CasinoInteractionTypeEnum } from "@data-types/analytics/CasinoInteractionTypeEnum";
import {
	MostPlayTicketActionsTypeEnum,
	QuickBetActionsTypeEnum,
} from "@data-types/analytics/QuickPlayInteractionTypeEnum";
import { isCapacitorPlatform } from "@utils/specific/capacitor/helpers";
import { StorageStateKeysEnum } from "@utils/storage/StorageTypes";

type CustomEventData = {
	category: string;
	label?: string;
	value?: string;
};

type CustomEventDataGeneric<T> = {
	category: T;
	label?: string;
	value?: string;
};

export default class AnalyticsService {
	private static isCustomGAActive(): boolean {
		return WEBPACK_USE_CUSTOM_GA;
	}

	private static isGAActive(): boolean {
		return WEBPACK_GA_ENABLED;
	}

	private static sendCustomEvent(data: CustomEventData) {
		if (
			isCapacitorPlatform() ||
			!this.isGAActive() ||
			!this.isCustomGAActive()
		) {
			return;
		}

		try {
			//@ts-expect-error
			gtag(
				"event",
				`${data.category}${data.label != null ? `-${data.label}` : ""}`,
				{
					event_category: data.category,
					event_label: data.label != null ? data.label : "",
					value: data.value != null ? data.value : "",
				}
			);
		} catch {}
	}

	static setUserId(userId: string | null): void {
		if (
			isCapacitorPlatform() ||
			!this.isGAActive() ||
			!this.isCustomGAActive()
		) {
			return;
		}

		try {
			//@ts-expect-error
			gtag("set", { user_id: userId != null ? userId : "anon" });
		} catch {}
	}

	static logSportInteraction(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}

	static logBetSlipCancelInteraction(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}

	static logBetSlipInteraction(
		type: BetSlipInteractionTypeEnum,
		label?: string,
		value?: string
	) {
		let dataObj: CustomEventData = {
			category: type,
		};

		if (label != null) {
			dataObj.label = label;
		}
		if (value != null) {
			dataObj.value = value;
		}

		this.sendCustomEvent(dataObj);
	}

	static logCasinoInteraction(
		type: CasinoInteractionTypeEnum,
		value: string
	) {
		this.sendCustomEvent({ category: type, label: value });
	}

	static logQuickActionInteraction(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}

	static logMarketingInteraction(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}

	static logAdditionalOfferInteraction(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}

	static logLiveSideNavigationInteraction(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}

	static logHomePageOfferTabsInteraction(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}

	static logOpenChatInteraction(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}
	static logQuickPlayInteraction(
		data: CustomEventDataGeneric<
			QuickBetActionsTypeEnum | MostPlayTicketActionsTypeEnum
		>
	): void {
		this.sendCustomEvent(data);
	}

	static logBetSlipSharePopupAddToSlip(data: CustomEventData): void {
		this.sendCustomEvent(data);
	}
}
