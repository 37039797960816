import { UserPaymentTransactionLimited } from "@api-types/account-settings/UserPaymentTransactionLimited";
import { DepositResponseDto } from "@api-types/deposit";
import { PaymentRequestFilter } from "@data-types";
import {
	httpClient,
	getAgencyKey,
	getApplicationHeaders,
	UserTypes,
	AccountTypes,
} from "@utils";

export class UserPaymentTransactionsApiService {
	static get baseUrlPaymentTransactionsForDepositAndWithdrawal() {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}/user-payment-transactions`;
	}

	static baseUrlPaymentTransactions(
		userType: UserTypes = UserTypes.PRIMARY
	): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			userType
		)}/user-payment-transactions`;
	}

	static baseUrlTransactions(
		userType: UserTypes = UserTypes.PRIMARY
	): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			userType
		)}/user-transactions`;
	}

	static getRequestHeaders = (userType: UserTypes = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(userType);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		appHeaders["Accept" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static getRequestHeadersForDepositAndWithdrawal = () => {
		//use only Online USER headers
		const internetUser =
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			);
		const appHeaders = getApplicationHeaders(internetUser);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		appHeaders["Accept" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getDepositData(
		bodyData: PaymentRequestFilter
	): Promise<DepositResponseDto> {
		return await httpClient.post(
			`${this.baseUrlPaymentTransactionsForDepositAndWithdrawal}/deposits`,
			JSON.stringify(bodyData),
			this.getRequestHeadersForDepositAndWithdrawal(),
			{ responseType: "json" }
		);
	}

	static async getWithdrawalData(bodyData: any): Promise<any> {
		return await httpClient.post(
			`${this.baseUrlPaymentTransactionsForDepositAndWithdrawal}/payouts`,
			JSON.stringify(bodyData),
			this.getRequestHeadersForDepositAndWithdrawal(),
			{ responseType: "json" }
		);
	}

	static async getTransactionDetails(
		transactionId: string,
		userType: UserTypes
	): Promise<UserPaymentTransactionLimited> {
		return await httpClient.get(
			`${this.baseUrlPaymentTransactions(
				userType
			)}/foruser/${transactionId}?embed=paymentMethod,currency,transactionStatus&translate=true`,
			this.getRequestHeaders(userType),
			{
				responseType: "json",
			}
		);
	}

	static async getTransactionDetailsForRetail(
		transactionId: string,
		userType: UserTypes
	): Promise<UserPaymentTransactionLimited> {
		return await httpClient.get(
			`${this.baseUrlTransactions(
				userType
			)}/forRetailUser/${transactionId}?translate=true`,
			this.getRequestHeaders(userType),
			{
				responseType: "json",
			}
		);
	}
}
