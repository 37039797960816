import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@v2/components/desktop/shared/buttons";
import { useAppContext, useUser } from "@v2/hooks/shared";

export default function AcceptNewTermsError(props: {
	acceptNewTermsError: any | null;
	setAcceptNewTermsError: (_: null) => void;
}) {
	const { t } = useTranslation();

	if (props.acceptNewTermsError == null) {
		return null;
	}
	const user = useUser();

	const { culture } = useAppContext();

	const handleErrorResponse = async () => {
		await user.logoutUser();
		App.state.history.push(`/${culture}/home/full/highlights`);
		props.setAcceptNewTermsError(null);
	};

	return (
		<div className="at-acceptNewTermsError card--message">
			<div className="card--message-full__thumb" />
			<div className="card--message__body">
				<div className="card--message__content">
					<i className="card--message__icon u-icon u-icon--xlrg u-icon--remove-circle u-icon--animated--bounce-in u-color--ui--danger" />
					<div className="card--message__title">
						{t<string>(
							"MEMBERSHIP.ACCOUNT_ACTIVATION.ERROR_HAS_OCCURED"
						)}
					</div>
					<div className="u-type--left">
						{t<string>(
							"MEMBERSHIP.ACCOUNT_ACTIVATION.ERROR_MESSAGE_ACCOUNT_ACTIVATION"
						)}
					</div>
				</div>
				<div className="card--message__footer">
					<Button
						className="card--message__cta btn--lrg btn--primary"
						btnText={t("COMMON.OK_BTN")}
						onClick={handleErrorResponse}
					/>
				</div>
			</div>
		</div>
	);
}
