import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { useRootAppStore, useRootOfferStore } from "@hooks";
import { EventContext, OfferStoreContext } from "@gp/components";
import { useTranslation } from "react-i18next";
import EventViewStore from "@v2/state/offer/pages/EventViewStore";

export default observer(function MyBetsToggleButton() {
	const rootStore = useRootAppStore();
	const rootOfferStore = useRootOfferStore();
	const store = useContext(OfferStoreContext) as EventViewStore;
	const event = useContext(EventContext);
	const { t } = useTranslation();

	const eventInList: { eventId: string; isMyBetsOpen: boolean } | undefined =
		store.myBetsToggleStateList.find(
			(eItem: { eventId: string; isMyBetsOpen: boolean }) =>
				eItem.eventId == event.id
		);

	if (!rootStore.userAuthStore.isLoggedIn || eventInList == null) {
		return null;
	}

	const buttonClasses = classNames(
		"at-showBets event__actions__btn event__actions__bets btn btn--xtny btn--primary btn--icon"
	);

	const iconClasses = classNames("u-icon u-icon--sml", {
		"u-icon--my-bets--bold u-color--core--snow": eventInList.isMyBetsOpen,
		"u-icon--my-bets--alt u-color--core--snow": !eventInList.isMyBetsOpen,
	});

	return (
		<button
			className={buttonClasses}
			type="button"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (
					rootOfferStore?.myBetsViewStore != null &&
					eventInList.isMyBetsOpen === true
				) {
					rootOfferStore.myBetsViewStore.cashoutStore.disposeSubscription(
						event.id
					);
				}
				store.handleUrls(!eventInList.isMyBetsOpen, event.id);
			}}
		>
			<i className={iconClasses} />
			<span className="btn__label">
				{t("MY_BETS.SHOW_MY_BETS_BUTTON")}
			</span>
		</button>
	);
});
