import { Button } from "@components/buttons";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomMessagePreview from "./CustomMessagePreview";

export default observer(function SendMessage() {
	const { chatViewStore } = useRootAppStore();
	const [inputValue, setInputValue] = useState<string>("");
	const { t } = useTranslation();

	const shouldDisableSubmit =
		chatViewStore.messageSendingLoader ||
		((!inputValue || inputValue.trim().length == 0) &&
			!chatViewStore.sharedBetSlipData);

	const checkIfMessageIsLink = (messageText: string) => {
		return /(?:https?|ftp):\/\/[\n\S]+/i.test(messageText);
	};

	const sendMsg = async (
		e:
			| React.FormEvent<HTMLFormElement>
			| React.KeyboardEvent<HTMLTextAreaElement>
	) => {
		e.preventDefault();
		const isLink = checkIfMessageIsLink(inputValue);
		chatViewStore.chatChannelHandler.sendMessage(inputValue, isLink);
		setInputValue("");
	};

	const handleTextAreaSubmit = (
		e: React.KeyboardEvent<HTMLTextAreaElement>
	) => {
		if (e.key === "Enter" && e.shiftKey == false && !shouldDisableSubmit) {
			e.preventDefault();
			if (inputValue || chatViewStore.sharedBetSlipData) {
				sendMsg(e);
			}
		}
	};

	return (
		<form className="chat__footer" onSubmit={sendMsg}>
			<CustomMessagePreview />
			<div className="chat__textbox">
				<textarea
					onKeyDown={handleTextAreaSubmit}
					rows={3}
					value={inputValue}
					className="chat__textarea input input--base input--textarea"
					placeholder={t("CHAT.ENTER_MESSAGE")}
					onChange={(e) => setInputValue(e.target.value)}
				></textarea>

				<Button
					className="chat__send btn btn--square btn--square--sml btn--icon btn--primary"
					iconClassName="u-icon u-icon--med u-icon--arrow--send u-color--core--snow"
					iconOnly
					isDisabled={shouldDisableSubmit}
				/>
			</div>
		</form>
	);
});
