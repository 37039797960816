export class Result<T, E> {
	public result: T | null;
	public error: E | null;

	public get hasResult() {
		return this.result != null;
	}

	public get hasError() {
		return this.error != null;
	}

	constructor(result: T | null, error: E | null) {
		this.result = result;
		this.error = error;
	}
}
