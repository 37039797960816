import {
	LoginRequestDto,
	LogoutRequestDto,
	LoginSSORequestDto,
	LogoutReason,
	LogoutUserDto,
} from "@api-types/user/LoginRequestDto";
import {
	LoginResponseDto,
	LoginResponseUser,
	LoginUserMatchingResponseDto,
	RedirectLoginResponseDto,
} from "@api-types/user/LoginResponseDto";
import { AccountActivationRequestDto } from "@api-types/user/AccountActivationRequestDto";
import { ForcePasswordChangeRequestDto } from "@api-types/user/ForcePasswordChangeRequestDto";
import { UserMatchingRequestDto } from "@api-types/user/UserMatchingRequestDto";
import {
	UserTypes,
	getAgencyId,
	getAgencyKey,
	getApplicationHeaders,
	getMvcApplicationHeaders,
	getSecondaryUser,
	getShopId,
	getUserToken,
	getWebApiUrl,
	httpClient,
} from "@utils";
import qs from "qs";
import { MitAuthDto } from "@api-types/integrations/MitAuthDto";
import { Result } from "@lib/result";

const requestHeaders = {
	"Content-Type": "application/json",
};
export default class UserApiService {
	private static get baseUrl(): string {
		return `${getWebApiUrl()}/api/user`;
	}

	static getRequestHeaders = (
		user: UserTypes = UserTypes.PRIMARY
	): HeadersInit => {
		const appHeaders = getMvcApplicationHeaders(user);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async initGigGetUser(): Promise<LoginResponseDto> {
		const headers = this.getRequestHeaders();
		return httpClient.get(`${this.baseUrl}/casino/login`, headers, {
			responseType: "json",
		});
	}

	static async loginUser(
		userData: LoginRequestDto
	): Promise<LoginResponseDto | RedirectLoginResponseDto> {
		const headers = this.getRequestHeaders();
		headers["Content-Type" as keyof HeadersInit] =
			"application/x-www-form-urlencoded";
		const url = `${this.baseUrl}/login`;
		return httpClient.post(url, qs.stringify(userData), headers, {
			responseType: "json",
		});
	}

	static async loginUserFromGig(
		userToken: string
	): Promise<LoginResponseDto & { gigToken?: string }> {
		const headers = this.getRequestHeaders();
		return httpClient.post(
			`${this.baseUrl}/casino/user`,
			JSON.stringify({ authToken: userToken }),
			{ ...headers, ...requestHeaders },
			{ responseType: "json" }
		);
	}

	static async loginUserWithMit(
		mitAuthDto: MitAuthDto
	): Promise<LoginResponseDto> {
		const headers = this.getRequestHeaders();
		const url = `${this.baseUrl}/login/mit`;
		return httpClient.post(
			url,
			JSON.stringify(mitAuthDto),
			{ ...headers, ...requestHeaders },
			{ responseType: "json" }
		);
	}

	static async findMatchingData(): Promise<string[]> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/agency-user-account-relation-matches`;

		const authHeaders = this.getRequestHeaders();
		return httpClient.get(
			url,
			{ ...requestHeaders, ...authHeaders },
			{ responseType: "json" }
		);
	}

	static async confirmUserMatching(
		userData: UserMatchingRequestDto
	): Promise<LoginUserMatchingResponseDto> {
		const url = `${this.baseUrl}/confirm-user-matching`;
		const authHeaders = this.getRequestHeaders();
		return httpClient.post(
			url,
			JSON.stringify(userData),
			{ ...requestHeaders, ...authHeaders },
			{
				responseType: "json",
			}
		);
	}

	static async denyUserMatching(): Promise<void> {
		const url = `${WEBPACK_BASE_ADDRESS}platform/agency-user-account-relation-matches/deny`;

		const authHeaders = this.getRequestHeaders();
		return httpClient.post(
			url,
			null,
			{ ...requestHeaders, ...authHeaders },
			{ responseType: "json" }
		);
	}

	static async ssoLogin(
		token: LoginSSORequestDto
	): Promise<LoginResponseDto> {
		const url = `${this.baseUrl}/sso`;
		const headers = this.getRequestHeaders();
		const response = httpClient.get(
			`${url}?token=${token}`,
			{ ...headers, ...requestHeaders },
			{ responseType: "json" }
		);
		return response;
	}

	static async logoutUser(logoutReason?: LogoutReason): Promise<void> {
		// TODO MG this model mapping should  be done in business service
		let requestModel = {} as LogoutRequestDto;
		requestModel.primary = {} as LogoutUserDto;
		const primaryToken = getUserToken();
		requestModel.primary.token = primaryToken ? primaryToken.token : "";
		requestModel.primary.agencyId = getAgencyId();
		requestModel.primary.agencyKey = getAgencyKey();
		requestModel.primary.shopId = getShopId();

		if (getSecondaryUser()) {
			requestModel.secondary = {} as LogoutUserDto;
			const secondaryToken = getUserToken(UserTypes.SECONDARY);
			requestModel.secondary.token = secondaryToken
				? secondaryToken.token
				: "";
			requestModel.secondary.agencyId = getAgencyId(UserTypes.SECONDARY);
			requestModel.secondary.agencyKey = getAgencyKey(
				UserTypes.SECONDARY
			);
			requestModel.secondary.shopId = getShopId(UserTypes.SECONDARY);
		}
		if (logoutReason) {
			requestModel.primary.isAutomaticLogout =
				logoutReason.isAutomaticLogout;
			requestModel.primary.isRealityCheckLogout =
				logoutReason.isRealityCheckLogout;
		}
		const url = `${this.baseUrl}/logout`;
		const headers = this.getRequestHeaders();
		await httpClient.post(url, JSON.stringify(requestModel), {
			...headers,
			...requestHeaders,
		});
	}

	static async accountActivation(
		requestModel: AccountActivationRequestDto
	): Promise<LoginResponseDto> {
		const url = `${this.baseUrl}/account-activation`;
		const headers = this.getRequestHeaders();
		return httpClient.post(
			url,
			JSON.stringify(requestModel),
			{ ...headers, ...requestHeaders },
			{ responseType: "json" }
		);
	}

	static async forcePasswordChange(
		requestModel: ForcePasswordChangeRequestDto
	): Promise<LoginResponseDto> {
		const url = `${this.baseUrl}/force-password-change`;
		const headers = this.getRequestHeaders();
		return httpClient.post(
			url,
			JSON.stringify(requestModel),
			{ ...headers, ...requestHeaders },
			{ responseType: "json" }
		);
	}

	static async acceptGDPRConsent(): Promise<boolean> {
		const url = `${this.baseUrl}/accept-gdpr`;
		const headers = this.getRequestHeaders();
		return httpClient.get(
			url,
			{ ...headers, ...requestHeaders },
			{ responseType: "json" }
		);
	}

	static async acceptDataVerification(body: {
		username: string | undefined;
		agencyKey: string | undefined;
		agencyId: string | undefined;
		shopId: string | undefined;
		activationToken: string | undefined;
	}): Promise<Result<LoginResponseDto, any>> {
		const url = `${getWebApiUrl()}/api/user/data-confirmation`;
		try {
			const response = await httpClient.post(
				url,
				JSON.stringify(body),
				this.getRequestHeaders(),
				{ responseType: "json" }
			);
			return new Result(response, null);
		} catch (error) {
			return new Result<LoginResponseDto, any>(null, error);
		}
	}

	static async refreshUser(
		userId: string,
		isPrimary: boolean
	): Promise<LoginResponseUser> {
		const url = `${this.baseUrl}/refresh-user?userId=${userId}`;
		const headers = this.getRequestHeaders(
			isPrimary ? UserTypes.PRIMARY : UserTypes.SECONDARY
		);
		return httpClient.get(
			url,
			{ ...headers, ...requestHeaders },
			{ responseType: "json" }
		);
	}
}
