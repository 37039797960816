import { observable, action, runInAction } from "mobx";

class LoaderStore {
	private loaderTimerId: ReturnType<typeof setTimeout> | undefined;

	@observable public isLoading = false;
	@observable public isLoadingProcess = false;

	@action.bound
	public suspend(delay: number = 350) {
		runInAction(() => {
			this.isLoadingProcess = true;
		});
		clearTimeout(this.loaderTimerId);
		this.loaderTimerId = setTimeout(() => {
			runInAction(() => {
				this.isLoading = true;
			});
		}, delay);
	}

	@action.bound
	public resume() {
		if (this.loaderTimerId) {
			clearTimeout(this.loaderTimerId);
			this.loaderTimerId = undefined;
		}
		this.isLoadingProcess = false;
		this.isLoading = false;
	}
}

export default LoaderStore;
