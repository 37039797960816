import { UserLockSettingsDto } from "@api-types/locks/UserLockSettingsDto";
import { getApplicationHeaders, httpClient } from "@utils";

export class AgencyUserLocksApiService {
	static getRequestHeaders = () => {
		const appHeaders = getApplicationHeaders();
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}platform/agency-user-locks`;
	}

	static async createAccountLock(
		userLockSettings: UserLockSettingsDto
	): Promise<void> {
		return httpClient.put(
			`${this.baseUrl}/lock-with-password`,
			JSON.stringify(userLockSettings),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}
}
