import {
	BonusDepositDetailsREST,
	HistoryStatus,
	MarketplaceBonusResponse,
	MarketplaceBonusStatus,
	UserBonusInfoREST,
} from "@api-types/myBonuses/Bonus";
import {
	UserTypes,
	getAgencyId,
	getAgencyKey,
	getApplicationHeaders,
	httpClient,
} from "@utils";

export class WebBonusesApiService {
	static baseUrl(userType: UserTypes = UserTypes.PRIMARY): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(userType)}/web-bonuses/`;
	}

	static platformBaseUrl(): string {
		return `${WEBPACK_BASE_ADDRESS}platform/`;
	}

	static getRequestHeaders = (userType: UserTypes = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(userType);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async getBonusStatus(
		userType: UserTypes
	): Promise<UserBonusInfoREST> {
		return await httpClient.get(
			`${this.baseUrl(userType)}bonus-status`,
			this.getRequestHeaders(userType),
			{ responseType: "json" }
		);
	}
	static async getHistoryStatus(
		id: string,
		userType: UserTypes
	): Promise<HistoryStatus[]> {
		let url =
			id == ""
				? `${this.baseUrl(userType)}bonus-histories?translate=True`
				: `${this.baseUrl(
						userType
				  )}bonus-histories?translate=True&activeBonusId=${id}`;
		return await httpClient.get(url, this.getRequestHeaders(userType), {
			responseType: "json",
		});
	}
	static async activateVoucher(
		bettingAccountTypeId: string,
		code: string,
		userType: UserTypes
	): Promise<BonusDepositDetailsREST> {
		const data = {
			userBettingAccountTypeId: bettingAccountTypeId,
			webBonusCode: code,
		};

		return await httpClient.post(
			`${this.baseUrl(userType)}voucher?webBonusCode`,
			JSON.stringify(data),
			this.getRequestHeaders(userType),
			{ responseType: "json" }
		);
	}

	static async useMarketplaceBonus(
		bettingAccountTypeId: string,
		userId: string,
		isBonusDeclined: boolean,
		userType: UserTypes = UserTypes.PRIMARY
	): Promise<MarketplaceBonusResponse> {
		return await httpClient.put(
			`${this.platformBaseUrl()}user-marketplace-bonus-eligibility/use-marketplace-bonus/${getAgencyId(
				userType
			)}/${userId}`,
			JSON.stringify({
				userBettingAccountTypeId: bettingAccountTypeId,
				isBonusDeclined: isBonusDeclined,
			}),
			this.getRequestHeaders(userType),
			{ responseType: "json" }
		);
	}

	static async getMarketplaceBonusInfo(
		userType: UserTypes = UserTypes.PRIMARY
	): Promise<UserBonusInfoREST> {
		return await httpClient.get(
			`${WEBPACK_BASE_ADDRESS}${getAgencyKey(
				userType
			)}/marketplace-bonus/info`,
			this.getRequestHeaders(userType),
			{ responseType: "json" }
		);
	}

	static async getMarketplaceBonusStatusAndData(
		userId: string,
		userType: UserTypes = UserTypes.PRIMARY
	): Promise<MarketplaceBonusStatus> {
		return await httpClient.get(
			`${WEBPACK_BASE_ADDRESS}${getAgencyKey(
				userType
			)}/marketplace-bonus/info-deposit-bonus/${getAgencyId(
				userType
			)}/${userId}`,
			this.getRequestHeaders(userType),
			{ responseType: "json" }
		);
	}
}
