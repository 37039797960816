import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
	ErrorMessage,
	RequiredMark,
} from "@v2/components/desktop/shared/inputs";
import { PlayerPromoSendingModeEnum } from "@api-types/user/AccountActivationRequestDto";

export default function RegistrationPromotion() {
	const { t } = useTranslation();

	return (
		<div className="form__group u-mar--top--sml">
			<label className="label u-type--base u-type--wgt--medium u-color--text--primary">
				{t("MEMBERSHIP.REGISTRATION.NEWSLETTER_LABEL")}
				<RequiredMark required />
			</label>
			<ErrorMessage name="playerPromoSendingMode" />

			<RadioInput
				id="newsletterCheckboxChecked"
				label="MEMBERSHIP.REGISTRATION.NEWSLETTER_CHECKED"
				checkboxClassName="form__field"
				value={PlayerPromoSendingModeEnum.enabled}
			/>
			<RadioInput
				id="newsletterCheckboxUnchecked"
				label="MEMBERSHIP.REGISTRATION.NEWSLETTER_UNCHECKED"
				checkboxClassName="form__field"
				value={PlayerPromoSendingModeEnum.disabled}
			/>
		</div>
	);
}

function RadioInput(props: {
	id: string;
	label: string;
	checkboxClassName: string;
	value: PlayerPromoSendingModeEnum;
}) {
	const { id, label, value, checkboxClassName } = props;

	const { t } = useTranslation();
	const { register } = useFormContext();

	const field = register("playerPromoSendingMode", {
		required: {
			value: true,
			message: "COMMON.REQUIRED_FIELD",
		},
	});

	return (
		<div className={checkboxClassName}>
			<input
				id={id}
				className="input--radio"
				type="radio"
				value={value}
				{...field}
			/>
			<label
				htmlFor={id}
				className="u-type--base u-color--text--secondary"
			>
				{t(label)}
			</label>
		</div>
	);
}
