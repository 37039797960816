import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { isCasinoEnabled, isLiveCasinoEnabled } from "@v2/helpers/features";
import { useAppContext, useFixBody } from "@v2/hooks/shared";
import { isNullOrWhitespace } from "@utils";
import { observer } from "mobx-react";
import { useRegulationAgency } from "@v2/hooks/useRegulationAgency";
import Markdown from "@v2/components/shared/Markdown";
import { Modal } from "@lib/modal";
import { useRootAppStore } from "@hooks";
import { LugasCasinoService } from "@v2/data-access/casino/LugasCasinoService";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default observer(function HeaderNavigation(props: {
	onTargetClick?: (target: "live" | "sports") => void;
}) {
	const { culture } = useAppContext();
	const rootStore = useRootAppStore();
	const { t } = useTranslation();
	const [
		isResponsibleGamingConfirmationPopupOpened,
		setIsResponsibleGamingConfirmationPopupOpened,
	] = useState(false);

	return (
		<nav className="header__nav">
			<ul className="nav--primary">
				<li className="nav--primary__item">
					<NavLink
						to={`/${culture}/home/full/highlights`}
						className="nav--primary__link"
						activeClassName="is-active"
						isActive={(match, location) =>
							location.pathname.includes(`/home/`)
						}
					>
						{t("HOME.HOME")}
					</NavLink>
				</li>
				<li className="nav--primary__item">
					<NavLink
						to={`/${culture}/sports/`}
						className="nav--primary__link"
						activeClassName="is-active"
						isActive={(match, location) => {
							return (
								location.pathname.includes(
									`/${culture}/sports/`
								) || match != null
							);
						}}
						onClick={() => props.onTargetClick?.("sports")}
					>
						{t("HOME.SPORTS_BETTING")}
					</NavLink>
				</li>
				<li className="nav--primary__item">
					<NavLink
						to={`/${culture}/live/events`}
						className="nav--primary__link"
						activeClassName="is-active"
						isActive={(match, location) =>
							location.pathname == `/${culture}/live` ||
							match != null
						}
						onClick={() => props.onTargetClick?.("live")}
					>
						{t("HOME.LIVE_BETTING")}
					</NavLink>
				</li>

				<CasinoNavItem
					setFlag={setIsResponsibleGamingConfirmationPopupOpened}
				/>

				<Modal>
					<ResponsibleGamingConfirmationWrapper
						setFlag={setIsResponsibleGamingConfirmationPopupOpened}
						flag={isResponsibleGamingConfirmationPopupOpened}
					/>
				</Modal>

				{isCasinoEnabled &&
				(rootStore.userAuthStore.user == null ||
					rootStore.userAuthStore.isInternetUser) ? (
					<li className="nav--primary__item">
						<NavLink
							to={`/${culture}/games`}
							className="nav--primary__link"
							activeClassName="is-active"
							isActive={(match, location) =>
								location.pathname == `/${culture}/games` ||
								match != null
							}
						>
							{t("specific:HEADER.CASINO")}
						</NavLink>
					</li>
				) : null}

				{isLiveCasinoEnabled &&
				(rootStore.userAuthStore.user == null ||
					rootStore.userAuthStore.isInternetUser) ? (
					<li className="nav--primary__item">
						<NavLink
							to={`/${culture}/live-games`}
							className="nav--primary__link"
							activeClassName="is-active"
							isActive={(match, location) =>
								location.pathname == `/${culture}/live-games` ||
								match != null
							}
						>
							{t("specific:HEADER.CASINO-LIVE")}
						</NavLink>
					</li>
				) : null}
			</ul>
		</nav>
	);
});

const CasinoNavItem = observer(function CasinoNavItem(props: {
	setFlag: (value: boolean) => void;
}) {
	const rootStore = useRootAppStore();
	const t = useTranslation().t;

	if (
		!(WEBPACK_IS_GIG_ENABLED && !isNullOrWhitespace(WEBPACK_GIG_CASINO_URL))
	) {
		return null;
	}

	if (rootStore.userAuthStore.user == null) {
		return (
			<li className="nav--primary__item">
				<a
					className="nav--primary__link"
					href={WEBPACK_GIG_CASINO_URL}
					target="_blank"
				>
					{t("specific:HEADER.CASINO")}
				</a>
			</li>
		);
	}

	if (
		rootStore.userAuthStore.user != null &&
		(rootStore.userAuthStore.user.isGigCasinoAllowed ||
			rootStore.userAuthStore.secondaryUser?.isGigCasinoAllowed)
	) {
		return (
			<li className="nav--primary__item">
				<div
					className="nav--primary__link"
					onClick={() => {
						props.setFlag(true);
					}}
				>
					{t("specific:HEADER.CASINO")}
				</div>
			</li>
		);
	}

	return null;
});

const ResponsibleGamingConfirmationWrapper = observer(
	function ResponsibleGamingConfirmationWrapper(props: {
		flag: boolean;
		setFlag: (value: boolean) => void;
	}) {
		if (!props.flag) {
			return null;
		}

		return (
			<ResponsibleGamingConfirmationPopup
				flag={props.flag}
				setFlag={props.setFlag}
			/>
		);
	}
);

const ResponsibleGamingConfirmationPopup = observer(
	function ResponsibleGamingConfirmationPopup(props: {
		flag: boolean;
		setFlag: (value: boolean) => void;
	}) {
		const t = useTranslation().t;
		const regulationDefinitionsStore = useRegulationAgency();
		const rootStore = useRootAppStore();

		useFixBody(true, props.flag);

		if (
			regulationDefinitionsStore == null ||
			regulationDefinitionsStore.responsibleGamingWeb == null
		) {
			return null;
		}

		return (
			<div className="popup popup--mega">
				<div className="popup__card popup--mega__card">
					<div className="popup__header">
						<div className="popup__title">
							{t("RESPONSIBLE_GAMING_CONFIRMATION.TITLE")}
						</div>
					</div>

					<div
						id="responsible-gaming"
						className="popup__body markdown markdown--responsible-popup"
					>
						<InfoMessageComponent
							type="warning"
							message={t("RESPONSIBLE_GAMING_CONFIRMATION.TEXT")}
							addClassName="u-mar--bottom--xsml"
							textClass="message__text"
						/>

						<Markdown
							content={
								regulationDefinitionsStore.responsibleGamingWeb
									?.content
							}
						></Markdown>
					</div>

					<div className="popup__footer">
						<button
							type="button"
							className="popup__footer__btn btn btn--base btn--secondary--inverted"
							onClick={() => props.setFlag(false)}
						>
							{t("COMMON.CANCEL")}
						</button>

						<button
							type="button"
							className="popup__footer__btn btn btn--base btn--primary"
							onClick={async () => {
								await LugasCasinoService.sendSportToCasinoAreaChange(
									{
										userId: rootStore.userAuthStore.user
											?.id!,
										agencyId:
											rootStore.userAuthStore.user
												?.agencyId!,
									}
								);
								props.setFlag(false);
								window.open(WEBPACK_GIG_CASINO_URL, "_blank");
							}}
						>
							{t("COMMON.CONFIRM")}
						</button>
					</div>
				</div>
			</div>
		);
	}
);
