import {
	httpClient,
	getAgencyKey,
	getApplicationHeaders,
	UserTypes,
} from "@utils";
import { ResolveLimit, ResolveResponse } from "@api-types";

// TODO: rename class and file (wrong sufix, should be ApiService)
export class GamingLimitService {
	static baseUrl(activeAccount: UserTypes = UserTypes.PRIMARY): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			activeAccount
		)}/gaming-limits`;
	}

	static getRequestHeaders = (
		activeAccount: UserTypes = UserTypes.PRIMARY
	) => {
		const appHeaders = getApplicationHeaders(activeAccount);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static resolveGamingLimit(
		activeAccount: UserTypes = UserTypes.PRIMARY,
		resolveUserLimit: ResolveLimit
	): Promise<ResolveResponse> {
		const url = `${this.baseUrl(activeAccount)}/resolve`;
		return httpClient.post(
			url,
			JSON.stringify(resolveUserLimit),
			this.getRequestHeaders(activeAccount),
			{ responseType: "json" }
		);
	}

	static resolveGamingLimitRequest(
		activeAccount: UserTypes = UserTypes.PRIMARY,
		gamingLimitRequest: ResolveLimit
	): Promise<ResolveResponse> {
		const url = `${this.baseUrl(activeAccount)}/resolve-request`;
		return httpClient.post(
			url,
			JSON.stringify(gamingLimitRequest),
			this.getRequestHeaders(activeAccount),
			{ responseType: "json" }
		);
	}
}
