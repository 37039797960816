import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	App as CapacitorApp,
	URLOpenListenerEvent,
	BackButtonListenerEvent,
} from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { isCapacitorPlatform, setCapacitorDeviceOrientation } from "./helpers";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { SessionStorageProvider } from "@state";
import RestrictionService from "@services/restriction/RestrictionService";
import { useRootAppStore } from "@hooks";

/**
 * if capacitor platform:
 * attach appurlopen listener - deep link integration,
 * on resume listener
 * attach back button listener - hardware back button,
 * removes splash screen
 * start new relic capacitor plugin
 * ip restriction
 */
export function useCapacitorPlatform() {
	let history = useHistory();
	const rootStore = useRootAppStore();
	useEffect(() => {
		if (isCapacitorPlatform()) {
			//signal that App is ready
			CapacitorUpdater.notifyAppReady();

			CapacitorApp.addListener("resume", () => {
				rootStore.capacitorUpdaterStore.checkVersionDiff();
				rootStore.checkIfIpRestricted();
			});

			CapacitorApp.addListener(
				"appUrlOpen",
				(event: URLOpenListenerEvent) => {
					const slug = event.url.split(WEBPACK_WEBSITE_URL).pop();
					if (slug) {
						history.push(slug);
					}
				}
			);

			CapacitorApp.addListener(
				"backButton",
				(event: BackButtonListenerEvent) => {
					if (event.canGoBack) {
						window.history.back();
						return;
					}

					CapacitorApp.exitApp();
				}
			);

			setCapacitorDeviceOrientation("portrait");

			SplashScreen.hide();
		}
		return () => {
			CapacitorApp.removeAllListeners();
		};
	}, []);
}
