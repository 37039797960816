import React, { FC, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DepositContext } from "@context";

export const DepositPaymentMethodFilterSection: FC = observer(
	function DepositPaymentMethodFilterSection() {
		const { t } = useTranslation();
		const { register } = useFormContext();
		const { fetchUserPaymentMethods } = useContext(DepositContext);
		const { userPaymentMethods } = useContext(DepositContext);

		useEffect(() => {
			fetchUserPaymentMethods();
		}, []);

		return (
			<div className="form__group">
				<label htmlFor="paymentMethodSelect" className="label">
					{t<string>("DEPOSIT.PAYMENT_METHOD")}
				</label>
				<select
					id="paymentMethodSelect"
					className="input input--base input--select"
					{...register("paymentMethodId")}
				>
					<option key="paymentMethod-all" value="all">
						{t<string>("DEPOSIT.ALL_METHODS")}
					</option>
					{userPaymentMethods?.map((paymentMethod) => (
						<option
							key={paymentMethod.paymentMethodId}
							value={paymentMethod.paymentMethodId}
						>
							{paymentMethod.displayName}
						</option>
					))}
				</select>
			</div>
		);
	}
);