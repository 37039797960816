import React from "react";
import { useTranslation } from "react-i18next";
import { useRootAppStore } from "@hooks";
import classnames from "classnames";

export default function FailedLoginsCheckButton({ btnSize }: { btnSize?: string }) {
	const { failedLoginsCheckPopupStore } = useRootAppStore();
	const { t } = useTranslation();

	const openPopup = () => {
		failedLoginsCheckPopupStore.togglePopup();
	};

	return (
		<button
			onClick={openPopup}
			className={classnames(
				"header__logins btn",
				btnSize ? `btn--${btnSize}` : "btn--tny",
				"btn--secondary--inverted btn--icon"
			)}
		>
			<i className="u-icon u-icon--med u-icon--failed-login" />
            <span className="header__logins__label">{t("specific:USER.CHECK_FAILED_LOGINS.FAILED_LOGINS_TITLE")}</span>
		</button>
	);
}