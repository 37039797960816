import React from "react";
import { DateTime } from "luxon";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { validateCountry } from "@v2/helpers/membership/registration/utils";
import { CountryDataType } from "@data-types/membership/registration";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function RegistrationDOB(props: {
	countryData: CountryDataType[] | null;
	labelClassName?: string;
	disabled?: boolean;
}) {
	const { countryData, labelClassName, disabled = false } = props;

	const { getValues, trigger } = useFormContext();
	const translationHook = useTranslation();

	const maxDate = DateTime.now().minus({ years: 18 });
	const minDate = DateTime.now().minus({ years: 100 });

	return (
		<FormInputField
			id="registrationDob"
			type="date"
			name="dob"
			className="form__field"
			label="MEMBERSHIP.REGISTRATION.DOB_LABEL"
			labelClassName={labelClassName}
			onClick={(e) => e.preventDefault()}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.keyCode === 13) {
					e.preventDefault();
				}
			}}
			validate={{
				isPassValid: () => {
					if (getValues("password")) {
						trigger("password");
					}
				},
				isEstonia: (value: string) =>
					validateCountry(
						getValues("countryId"),
						countryData || [],
						value
					),
				isOldEnough: (value: string) => {
					const dateValue = DateTime.fromISO(value);
					if (dateValue.startOf("day") > maxDate.startOf("day")) {
						return translationHook.t<string>(
							"MEMBERSHIP.REGISTRATION.DOB_TOO_YOUNG"
						);
					}
				},
				isTooOld: (value: string) => {
					const dateValue = DateTime.fromISO(value);
					if (dateValue.startOf("day") < minDate.startOf("day")) {
						return translationHook.t<string>(
							"MEMBERSHIP.REGISTRATION.DOB_INVALID"
						);
					}
				},
				isYearInvalid: (value: string) => {
					const dateValue = DateTime.fromISO(value);
					if (isNaN(dateValue.year)) {
						return translationHook.t<string>(
							"MEMBERSHIP.REGISTRATION.DOB_INVALID"
						);
					}
				},
			}}
			required
			disabled={disabled}
		/>
	);
}
