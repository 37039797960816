import React from "react";

import { ColumnType } from "@gp/components";
import { OfferColumnConfigurationResult } from "@gp/components";
import { OfferColumn, OfferColumnResult } from "./common";

export function EventOffer(props: { columns: OfferColumnConfigurationResult }) {
	return (
		<>
			{props.columns.columns.map((col, idx) => {
				if (col.type === ColumnType.RESULT) {
					return <OfferColumnResult key={col.id} />;
				}

				return (
					<OfferColumn
						key={col.id}
						isEmpty={col.type === ColumnType.EMPTY}
						{...col.componentProps}
					/>
				);
			})}
		</>
	);
}
