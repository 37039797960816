import { offerPrintServiceData } from "./";
import {
	OfferCategory,
	OfferFile,
	OfferType,
	TableType,
} from "helpers/offer-print";

const initOfferPrintFiles = (files, categories) => {
	categories.forEach((offerPrintCategory) => {
		offerPrintCategory.offerPrintFiles.forEach((offerPrintFile) => {
			const availableOfferPrintFile = files.find(
				(o) =>
					o.OfferType == offerPrintFile.offerType &&
					o.TableType == offerPrintFile.tableType
			);

			if (availableOfferPrintFile != null) {
				offerPrintFile.url = availableOfferPrintFile.Url;
				offerPrintFile.dateModified =
					availableOfferPrintFile.DateModified;
			}
		});
	});
};

const createOfferPrintFile = (name, offerType, tableType) => {
	return {
		name: name,
		offerType: offerType,
		tableType: tableType,
	};
};

const createTopOfferCategory = () => {
	return {
		name: OfferCategory.TOP_OFFER,
		offerPrintFiles: [
			createOfferPrintFile(
				OfferFile.TOP_MATCHES,
				OfferType.TOP_OFFER,
				TableType.NO_TABLE
			),
		],
	};
};

const createDailyOfferCategory = () => {
	return {
		name: OfferCategory.DAILY_OFFER,
		offerPrintFiles: [
			createOfferPrintFile(
				OfferFile.BASIC_OFFER_WITH_SHORT_CHARTS,
				OfferType.DAILY_OFFER,
				TableType.SHORT_TABLE
			),
			createOfferPrintFile(
				OfferFile.BASIC_OFFER_WITH_LONG_CHARTS,
				OfferType.DAILY_OFFER,
				TableType.LONG_TABLE
			),
			createOfferPrintFile(
				OfferFile.ADDITIONAL_OFFER_WITHOUT_CHARTS,
				OfferType.ADDITIONAL_OFFER_DAILY,
				TableType.NO_TABLE
			),
			createOfferPrintFile(
				OfferFile.BASIC_OFFER_WITHOUT_CHARTS,
				OfferType.DAILY_OFFER,
				TableType.NO_TABLE
			),
		],
	};
};

const createWeeklyOfferCategory = () => {
	return {
		name: OfferCategory.WEEKLY_OFFER,
		offerPrintFiles: [
			createOfferPrintFile(
				OfferFile.BASIC_OFFER_WITH_SHORT_CHARTS,
				OfferType.WEEKLY_OFFER,
				TableType.SHORT_TABLE
			),
			createOfferPrintFile(
				OfferFile.BASIC_OFFER_WITH_LONG_CHARTS,
				OfferType.WEEKLY_OFFER,
				TableType.LONG_TABLE
			),
			createOfferPrintFile(
				OfferFile.ADDITIONAL_OFFER_WITHOUT_CHARTS,
				OfferType.ADDITIONAL_OFFER_WEEKLY,
				TableType.NO_TABLE
			),
			createOfferPrintFile(
				OfferFile.BASIC_OFFER_WITHOUT_CHARTS,
				OfferType.WEEKLY_OFFER,
				TableType.NO_TABLE
			),
		],
	};
};

const createAdditionalOfferCategory = () => {
	return {
		name: OfferCategory.ADDITIONAL_OFFER,
		offerPrintFiles: [
			createOfferPrintFile(
				OfferFile.LONG_TERM_BETS,
				OfferType.OUTRIGHT_OFFER,
				TableType.NO_TABLE
			),
		],
	};
};

const createResultsCategory = () => {
	return {
		name: OfferCategory.RESULTS,
		offerPrintFiles: [
			createOfferPrintFile(
				OfferFile.TODAY,
				OfferType.RESULTS_TODAY,
				TableType.NO_TABLE
			),
			createOfferPrintFile(
				OfferFile.YESTERDAY,
				OfferType.RESULTS_YESTERDAY,
				TableType.NO_TABLE
			),
			createOfferPrintFile(
				OfferFile.LAST_THREE_DAYS,
				OfferType.RESULTS_LAST_3_DAYS,
				TableType.NO_TABLE
			),
		],
	};
};

const initCategories = () => {
	return [
		createTopOfferCategory(),
		createDailyOfferCategory(),
		createWeeklyOfferCategory(),
		createAdditionalOfferCategory(),
		createResultsCategory(),
	];
};

class OfferPrintService {
	constructor() {}

	async getOfferPrintData() {
		const response = await offerPrintServiceData.getOfferPrintData();

		const createOfferPrintObject = (hexState) => {
			return {
				isHex: hexState,
				offerPrintCategories: [],
			};
		};

		const decimalOfferPrintFiles = response.filter((f) => !f.IsHex);
		const hexadecimalOfferPrintFiles = response.filter((f) => f.IsHex);

		let decimalOfferPrintFinal = createOfferPrintObject(false);
		let hexadecimalOfferPrintFinal = createOfferPrintObject(true);

		decimalOfferPrintFinal.offerPrintCategories = initCategories();
		initOfferPrintFiles(
			decimalOfferPrintFiles,
			decimalOfferPrintFinal.offerPrintCategories
		);

		hexadecimalOfferPrintFinal.offerPrintCategories = initCategories();
		initOfferPrintFiles(
			hexadecimalOfferPrintFiles,
			hexadecimalOfferPrintFinal.offerPrintCategories
		);

		const offerPrintData = {
			decimalOfferPrint: decimalOfferPrintFinal,
			hexadecimalOfferPrint: hexadecimalOfferPrintFinal,
		};

		return offerPrintData;
	}
}

const offerPrintService = new OfferPrintService();

export default offerPrintService;
