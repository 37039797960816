import React from "react";
import { ChatWrapper } from "@features/chat/desktop";

export default function ChatWrap(props: {
	children: JSX.Element[] | JSX.Element;
}) {
	return (
		<>
			{props.children}
			<ChatWrapper />
		</>
	);
}
