import { EventLiveStreamApiService } from "@api-services/event/EventLiveStreamApiService";
import { LiveStreamResponse } from "@api-types/event/LiveStreamResponse";
// import { ErrorCodeMainEnum } from "@api-types/common/ErrorCodeConstants";
// import { LiveStreamCodeEnum } from "@api-types/event/LiveStreamCodeConstant";

export class EventLiveStreamService {
	static async getLiveStreamUrl(
		eventId: string
	): Promise<LiveStreamResponse> {
		try {
			// return {
			// 	statusCode: ErrorCodeMainEnum.Success,
			// 	errorCode: LiveStreamCodeEnum.StreamDisabled,
			// 	response:
			// 		"https://live-par-1-abr-cdn.livepush.io/live/bigbuckbunnyclip/index.m3u8",
			// };
			return await EventLiveStreamApiService.getStreamUrl(eventId);
		} catch (error) {
			console.error(error);
			throw "LIVE_WIDGETS.LIVE_STREAM.STREAM_UNAVAILABLE";
		}
	}
}
