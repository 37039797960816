import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import SideWidgetBody from "./SideWidgetBody";
import SideWidgetDraggableContainer from "./SideWidgetDraggableContainer";
import SideWidgetHeader from "./SideWidgetHeader";
import { TSideWidgetView } from "./TSideWidgetView";

import useLmtLsEventStore from "./useLiveEventMenuViewStore";

export default observer(function LmtSideContainer() {
	const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>(null);
	const isFirstMount = React.useRef(true);
	const lmtLsStore = useLmtLsEventStore();
	const store = useLiveWidgetStore();
	const activeSideWidget = store.activeSideWidget;
	const [currentView, setCurrentView] = useState<TSideWidgetView>(
		activeSideWidget?.type === "LMT" ? "LIVE_MATCH_TRACKER" : "LIVE_STREAM"
	);

	useEffect(() => {
		if (!isFirstMount.current) {
			if (activeSideWidget != null) {
				store.expandSideWidget();
			} else if (currentView != "EVENT_LIST") {
				store.collapseSideWidget();
			}
		}
		isFirstMount.current = false;
	}, [activeSideWidget?.eventId, currentView]);

	return (
		<SideWidgetDraggableContainer headerRef={headerRef}>
			<SideWidgetHeader
				headerRef={headerRef}
				setHeaderRef={setHeaderRef}
				currentView={currentView}
				setView={setCurrentView}
				lmtLsStore={lmtLsStore}
			/>
			<SideWidgetBody
				view={currentView}
				lmtLsEventStore={lmtLsStore}
				setView={setCurrentView}
			/>
		</SideWidgetDraggableContainer>
	);
});
