import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ForcePasswordChangeForm } from ".";
export default function ForcePasswordChangePage() {
	const methods = useForm({
		mode: "onChange",
	});

	return (
		<FormProvider {...methods}>
			<ForcePasswordChangeForm />
		</FormProvider>
	);
}
