import React from "react";
import { useRootAppStore } from "@hooks";
import { observer } from "mobx-react";

export default observer(function ChatErrorMessage() {
	const { chatViewStore } = useRootAppStore();

	/* if (!chatInitErrorMessage) {
		return null;
	} */
	return (
		<div>
			<span style={{ color: "#000000" }}>
				{" "}
				{/* {chatInitErrorMessage} */}
			</span>
		</div>
	);
});
