import React from "react";
import { observer } from "mobx-react";

import {
	NotAuth,
	CountDown,
	BetListDisplayHeader,
	BetListDisplayAuthUser,
} from "./";
import { MyBetsViewStore } from "@offer/stores/components/my-bets";

const BetListDisplay = observer(function BetListDisplay(props: {
	myBetsViewStore: MyBetsViewStore;
}) {
	return (
		<div className="widget">
			<CountDown myBetsViewStore={props.myBetsViewStore} />
			<BetListDisplayHeader myBetsViewStore={props.myBetsViewStore} />
			{App.state.rootStore.userAuthStore.user == null ? (
				<NotAuth />
			) : (
				<BetListDisplayAuthUser
					myBetsViewStore={props.myBetsViewStore}
				/>
			)}
		</div>
	);
});

export default BetListDisplay;
