import React from "react";
import { formValueAvailabilityDebounceMethod } from "../../helpers/utility";

export default function useValueAvailabilityDebounce(): <Value, Result>(
	value: Value,
	method: (value: Value) => Promise<Result>
) => Promise<Result | undefined> {
	const [availabilityFunction, cancelDebounce] = React.useMemo(
		() => formValueAvailabilityDebounceMethod(),
		[]
	);

	React.useEffect(() => {
		return () => cancelDebounce();
	}, []);

	return availabilityFunction;
}
