import React from "react";
import { useFixBody } from "@v2/hooks/shared";
import { useTranslation } from "react-i18next";

export default function AutomaticLogoutPopupTemplate(props: { isOpened?: boolean }) {
    const { t } = useTranslation();
    const logout = async () => {
        App.state.rootStore.stopSession();
    };

    useFixBody(true, props.isOpened);

    return (
        <div className="popup">
            <div className="popup__card popup__card--sml">
                <div className="popup__header">{t("SESSION.AUTO_LOGOUT.TITLE")}</div>
                <div className="popup__body">
                    <div>{t("SESSION.AUTO_LOGOUT.LOGOUT_MESSAGE")}</div>
                </div>
                <div className="popup__footer">
                    <button className="popup__footer__btn btn btn--sml btn--primary" type="button" onClick={logout}>
                        {t("SESSION.AUTO_LOGOUT.BUTTON_OK")}
                    </button>
                </div>
            </div>
        </div>
    );
}
