import React, { useContext, useMemo } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { AsideWidgets } from "@v2/components/desktop/side-widgets";
import { Footer } from "@v2/components/desktop/shared/footer";
import { ErrorPage } from "@v2/components/desktop/shared/error";
import { HeaderWithSideMenu } from "@v2/components/desktop/shared/header";
import EventPage from "@v2/components/desktop/offer/pages/event/EventPage";
import { LiveEventSideMenu } from "@v2/components/desktop/offer/sidemenu";
import { useRootOfferStore } from "@hooks";
import LiveEventMenuViewStore from "@offer/stores/components/sports-menu/LiveEventMenuViewStore";
import { LiveEventSideMenuContext } from "@v2/context";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";

import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";

export default observer(function LiveEventRouteLayout(props: {
	liveOfferMenuStore: LiveOfferMenuStore;
	prematchOfferMenuStore: PrematchOfferMenuStore;
}) {
	//#region eventViewStore
	// TODO this should be moved to EventPage
	const offerStore = useRootOfferStore();
	const { isSideMenuExpanded } = offerStore;
	const favoriteStore = useContext(FavoritesStoreContext);

	const liveEventMenuViewStore = useMemo(
		() =>
			new LiveEventMenuViewStore(
				offerStore,
				props.liveOfferMenuStore,
				favoriteStore
			),
		[]
	);

	//#region eventViewStore

	//#region css class

	const mainClassNames = classNames("l--main", {
		"sidebar-left-expanded": isSideMenuExpanded,
		"sidebar-left-collapsed": !isSideMenuExpanded,
	});

	const contentClassNames = classNames("l--content is-event", {
		"sidebar-left-expanded": isSideMenuExpanded,
		"sidebar-left-collapsed": !isSideMenuExpanded,
	});

	const footerClassNames = classNames("l--footer", {
		"sidebar-left-expanded": isSideMenuExpanded,
	});

	const asideClassNames = classNames("l--aside--left", {
		"is-expanded": isSideMenuExpanded,
	});

	//#endregion css class

	return (
		<React.Fragment>
			<HeaderWithSideMenu />
			<main role="main" className={mainClassNames}>
				<LiveEventSideMenuContext.Provider
					value={liveEventMenuViewStore}
				>
					<aside className={asideClassNames}>
						<LiveEventSideMenu />
					</aside>

					<section className={contentClassNames}>
						<ErrorPage>
							<EventPage
								prematchOfferMenuStore={
									props.prematchOfferMenuStore
								}
								liveOfferMenuStore={props.liveOfferMenuStore}
							/>
						</ErrorPage>
					</section>
				</LiveEventSideMenuContext.Provider>

				<AsideWidgets />
			</main>

			<footer className={footerClassNames}>
				<Footer />
			</footer>
		</React.Fragment>
	);
});
