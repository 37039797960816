import React, { useEffect, useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { EventOffer } from "@gp/offer";
import { ScoreSummary } from "../details";

import { AdditionalOfferViewStore } from "../../../../state/offer/components/AdditionalOfferViewStore";
import TeamNames from "./AdditionalOfferTeamNames";

export default observer(function EventScoreInfo(props: {
	event: EventOffer;
	store: AdditionalOfferViewStore;
}) {
	if (props.event.isLive) {
		return <ScoreSummary isPage={false} />;
	}

	return (
		<TeamNames
			isInAdditionalOffer={true}
			event={props.event}
			store={props.store}
		/>
	);
});
