import { EventType } from "@gp/models";
import { localizationService } from "@state";

export function formatBettingOfferStatus(bettingOfferStatus: number): string {
	switch (bettingOfferStatus) {
		case 0:
			return "inactive";
		case 1:
			return "active";
		case 2:
			return "win";
		case 3:
			return "win-with-reduced-odds";
		case 4:
			return "lose";
		case 5:
			return "refund";
		case 6:
			return "refund-half-bet-and-win-other-half";
		case 7:
			return "refund-half-bet-and-lose-other-half";
		case 8:
			return "cancelled";
		case 9:
			return "cashout";
		default:
			return "";
	}
}

export function formatTeamName(
	teamName: string,
	teamGender: number,
	eventType: number
): string {
	if (
		eventType === EventType.NORMAL &&
		teamGender != null &&
		teamGender === 1
	) {
		return `${teamName} (${localizationService.t("OFFER.GENDER.WOMEN")})`;
	}
	return teamName;
}

// TODO: this should be placed in common utils and used instead of JSON.parse
export function safeJsonParse(str: string) {
	try {
		return JSON.parse(str);
	} catch (err) {
		return {};
	}
}
