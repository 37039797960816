import { Bet } from "@administration/pages/my-bets/model";
import { LookupDto } from "@api-types";

import {
	UserTypes,
	getAgencyKey,
	getApplicationHeaders,
	httpClient,
} from "@utils";

export class AdditionalOfferApiService {
	static Type = "AdditionalOfferApiService";
	static getRequestHeaders = (walletOwner = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(walletOwner);
		// @ts-expect-error
		appHeaders["Content-Type"] = "application/json";
		return appHeaders;
	};

	// TODO: calls to API should be in API SERVICE !
	// there is already service for bet-slips requests, put it there !!!!
	static async findBets(
		owner: UserTypes,
		filter: {
			eventId: string;
			page: number;
			pageSize: number;
		}
	): Promise<LookupDto<Bet>> {
		let query = new URLSearchParams();
		query.set(
			"embed",
			"betStatus,betSlipStatus,betSlipType,betSlipOffers,betSlipSecureWinSetting"
		);
		query.set("rpp", String(filter.pageSize));
		query.set("page", String(filter.page));
		query.set("eventIds", filter.eventId);
		query.set("sort", "dateCreated|desc");
		query.set("translate", String(true));

		const baseUrl = `bet-slips/get-bet-slips-for-events`;
		return await httpClient.get(
			`${WEBPACK_BASE_ADDRESS}${getAgencyKey(owner)}/${baseUrl}?${query}`,
			this.getRequestHeaders(owner),
			{ responseType: "json" }
		);
	}
}
