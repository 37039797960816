import React, { useEffect, useState } from "react";
import { LimitsHistoryTableData, LimitsHistoryMessageSection } from ".";
import { Pager } from "../../shared/inputs";
import {
	GamingLimitHistoryFormSubmit,
	LimitHistoryViewModel,
} from "@data-types";

export default function LimitsHistoryTableSection(props: PropTypes) {
	const [fetchedData, setFetchedData] =
		useState<LimitHistoryViewModel>(Object);

	const { filter, activeAccount, onInitializeTableSection, setFilter } =
		props;

	useEffect(() => {
		onInitializeTableSection(filter, setFetchedData);
	}, [filter, activeAccount]);

	return (
		<div className="u-mar--top--xsml">
			<div className="table--responsive u-mar--top--xsml">
				<LimitsHistoryTableData fetchedData={fetchedData} />
			</div>

			<div className="u-mar--top--xsml">
				<nav className="pagination u-mar--top--reset">
					<Pager
						pageNumber={filter.page || 1}
						pageSize={fetchedData?.recordsPerPage}
						totalItems={fetchedData?.totalRecords || 0}
						onPageChange={(page: number) =>
							setFilter({ ...filter, page: page })
						}
						isRemoveAllVisible={false}
						onCloseAll={() => {}}
					/>
				</nav>
			</div>

			<LimitsHistoryMessageSection fetchedData={fetchedData} />
		</div>
	);
}

type PropTypes = {
	filter: GamingLimitHistoryFormSubmit;
	activeAccount: number;
	onInitializeTableSection: Function;
	setFilter: React.Dispatch<
		React.SetStateAction<GamingLimitHistoryFormSubmit>
	>;
};
