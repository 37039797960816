import {
	AutomaticWithdrawalPost,
	AutomaticWithdrawalPostResponse,
	AutomaticWithdrawalResponse,
} from "@api-types/account-settings/AutomaticWithrawal";
import {
	AccountTypes,
	getAgencyKey,
	getApplicationHeaders,
	httpClient,
} from "@utils";

export class AutomaticWithdrawalApiService {
	static get baseUrl(): string {
		return `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		)}/user-auto-payout-settings`;
	}

	static get getRequestHeaders() {
		const appHeaders = getApplicationHeaders(
			App.state.rootStore.userAuthStore.getUserAccountType(
				AccountTypes.ONLINE
			)
		);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	}

	static async getAutomaticWithdrawal(): Promise<AutomaticWithdrawalResponse> {
		return await httpClient.get(this.baseUrl, this.getRequestHeaders, {
			responseType: "json",
		});
	}

	static async setAutomaticWithdrawal(
		data: AutomaticWithdrawalPost
	): Promise<AutomaticWithdrawalPostResponse> {
		const params = new URLSearchParams();
		if (data.action) {
			params.append("action", "" + data.action);
		}
		return await httpClient.post(
			`${this.baseUrl}?${params.toString()}`,
			JSON.stringify(data.userAutoPayoutSetting),
			this.getRequestHeaders,
			{
				responseType: "json",
			}
		);
	}
}
