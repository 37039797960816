import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useAppContext } from "@v2/hooks";

import { EventType } from "@gp/models";
import { WalletIcon } from "@v2/components/shared/utility";
import { shouldShowBetSlipStatus } from "@v2/helpers";
import { MyBetsBetContext } from "@v2/context";
import BetItemCashout from "./BetItemCashout";
import { ISOToDisplayCode } from "@utils";
import { useRootOfferStore } from "@hooks";
import MyBetsPageBetItemDetails from "./MyBetsPageBetItemDetails";
import { ShareBetSlipButton } from "@v2/components/desktop/my-bets/components";

export default observer(function MybetsTabItem() {
	const store = useRootOfferStore().myBetsViewStore;

	return (
		<div className="bet bet--mini">
			<div className="bet--mini__body">
				<BetCardLink />

				<div className="bet__actions">
					<div className="bet__actions__inner row">
						<BetItemCashout
							isMini={store?.cashoutStore.isAutoCashoutEnabled}
						/>
						<AutosellButton />
					</div>
				</div>
			</div>
			<MyBetsPageBetItemDetails />
		</div>
	);
});

const BetCardLink = observer(function BetCardLink() {
	return (
		<div className="bet--mini__link">
			<BetCardLinkHeader />
			<BetCardBody />
		</div>
	);
});

const BetCardLinkHeader = observer(function BetCardLinkHeader() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);

	const betAccountTypeAbrv =
		App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) => wallet.accountTypeId === bet?.bettingAccountTypeId
		)?.abrv || null;

	return (
		<div className="bet--mini__header">
			<div className="bet--mini__info bet--mini__info--inline">
				<span
					className={`bet--mini__tag tag tag--sml tag--bet--${
						// @ts-expect-error
						bet.status === "cashout" ? "won" : bet.betStatus.abrv
					}`}
				>
					{bet.betStatus.name}{" "}
					{bet.isLive ? (
						<> - {t("MY_BETS.ITEM.LIVE_SCORE").toUpperCase()}</>
					) : null}
					{bet.isBonus && <> ({t<string>("COMMON.BONUS")})</>}
				</span>
				{shouldShowBetSlipStatus(bet) && (
					<span
						className={
							"bet__tag tag tag--sml tag--slip--" +
							bet.betSlipStatus.abrv +
							"--inverted"
						}
					>
						{bet.betSlipStatus.name}
					</span>
				)}
				<WalletIcon bettingAccountTypeAbrv={betAccountTypeAbrv || ""} />
				<span className="bet--mini__date">
					{DateTime.fromISO(bet.dateCreated).toFormat(
						"dd.MM.yy HH:mm"
					)}
				</span>
			</div>
			<ShareBetSlipButton bet={bet} />
		</div>
	);
});

function BetCardBody() {
	const bet = useContext(MyBetsBetContext);
	const owner =
		App.state.rootStore.userAuthStore.getUserTypeFromBettingAccountTypeId(
			bet.bettingAccountTypeId
		);
	const { culture } = useAppContext();
	const url = `/${culture}/my-bets/details/${bet.id}/${owner}`;
	return (
		<React.Fragment>
			<Link to={url}>
				<BetCardBodyTitle />
				<BetCardBodyDetails />
			</Link>
		</React.Fragment>
	);
}

//#region body title

const BetCardBodyTitle = observer(function BetCardBodyTitle() {
	const bet = useContext(MyBetsBetContext);

	if (bet.betSlipOffers.length === 1 && bet.betSlipType !== "system") {
		return <BetCardBodyTitleSingleOffer />;
	}

	return (
		<div className="bet__title u-type--wgt--regular">
			{bet.formattedBetSystemString}
		</div>
	);
});

const BetCardBodyTitleSingleOffer = observer(
	function BetCardBodyTitleSingleOffer() {
		const bet = useContext(MyBetsBetContext);

		const offer = bet.betSlipOffers[0];

		return (
			<div className="bet--mini__match">
				{offer.sportData.eventType === EventType.NORMAL ? (
					<div className="bet__title u-type--wgt--regular">
						{offer.sportData.teamOneName} -{" "}
						{offer.sportData.teamTwoName}
					</div>
				) : (
					<div className="bet__title u-type--wgt--regular">
						{offer.sportData.eventName}
					</div>
				)}
				<div className="bet--mini__pick">
					{offer.sportData.eventType == EventType.NORMAL && (
						<span className="bet--mini__pick__type">
							{offer.formattedBettingType}:
						</span>
					)}
					<span className="bet--mini__pick__tip">
						{offer.formattedBettingTypeTip}
					</span>
				</div>
			</div>
		);
	}
);

//#endregion body title

//#region body details

const BetCardBodyDetails = observer(function BetCardBodyDetails() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);

	return (
		<div className="bet--mini__details">
			<div className="row">
				<div className="col col-sml-4">
					<div>
						{t<string>("MY_BETS.ITEM.ADDITIONAL_MAX_COEFFICIENT")}:
					</div>
					<div className="u-type--wgt--medium">
						{bet.maxCoefficient.toFixed(2)}
					</div>
				</div>
				<div className="col col-sml-4">
					<div className="u-mar--right--xtny">
						{t<string>("MY_BETS.ITEM.INFO_PAYMENT_LABEL")}:
					</div>
					<div className="u-type--wgt--medium">
						{bet.displayPayment}
					</div>
				</div>
				<div className="col col-sml-4">
					<BetItem_Payment />
				</div>
			</div>
		</div>
	);
});

//#endregion body details

const AutosellButton = observer(function AutosellButton() {
	const bet = useContext(MyBetsBetContext);
	const store = useRootOfferStore().myBetsViewStore;
	const t = useTranslation().t;

	if (store == null || !store.cashoutStore.isAutoCashoutEnabled) {
		return null;
	}

	const isFull =
		store.cashoutStore.isCashoutModalOpen &&
		store.cashoutStore.cashoutModalData?.id === bet.id &&
		store.cashoutStore.cashoutModalData.message == null;

	const colClasses = classNames("bet__actions__col col", {
		"col-sml-6": !isFull,
		"col-sml-12": isFull,
	});

	const autoCashoutButtonClasses = classNames(
		"bet__btn bet__btn--autosell btn btn--base btn--block btn--secondary--inverted btn--icon btn--wrap u-type--sml",
		{
			"bet__btn--autosell--set": bet.isAutoCashoutSet,
		}
	);

	const autoCashoutIconClasses = classNames("u-icon u-icon--sml", {
		"u-icon--settings": !bet.isAutoCashoutSet,
		"u-icon--set-stake": bet.isAutoCashoutSet,
	});

	if (
		store.cashoutStore.isAutoCashoutEnabled &&
		bet.displayedAmount !== undefined &&
		bet.displayedAmount !== null &&
		bet.displayedAmount !== "0.00"
	) {
		return (
			<div className={colClasses}>
				<button
					type="button"
					className={autoCashoutButtonClasses}
					onClick={(e) =>
						store.cashoutStore.openAutosellModal(bet, true)
					}
				>
					<i className={autoCashoutIconClasses} />
					<span>
						{t("MY_BETS.CASHOUT.AUTO_CASHOUT")}{" "}
						{bet.isAutoCashoutSet &&
						bet.betSlipSecureWinSetting != null
							? `${bet.betSlipSecureWinSetting.threshold.toFixed(
									2
							  )} ${ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)}`
							: ""}
					</span>
				</button>
			</div>
		);
	} else {
		return null;
	}
});

function BetItem_Payment() {
	const { t } = useTranslation();
	const bet = useContext(MyBetsBetContext);

	if (
		bet.betStatus.abrv == "won" &&
		(bet.betSlipStatus.abrv == "paid-out" ||
			bet.betSlipStatus.abrv == "cashout")
	) {
		return (
			<>
				<div className="u-mar--right--xtny">
					{t<string>("MY_BETS.ITEM.INFO_PAYOUT_LABEL")}:
				</div>
				<div className="u-type--wgt--medium">{bet.displayPayout}</div>
			</>
		);
	}

	return (
		<>
			<div className="u-mar--right--xtny">
				{t<string>("MY_BETS.ITEM.INFO_MAX_GAIN_LABEL")}:
			</div>
			<div className="u-type--wgt--medium">{bet.displayMaxGain}</div>
		</>
	);
}
