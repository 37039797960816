import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { ColumnTypeSelectors } from "./ColumnTypeSelectors";

import { useIsAdditionalOfferVisible } from "@offer/common";
import OfferIcon from "@v2/components/shared/icons/Offer";
import { ChatButton } from "../buttons";
import { ChannelResourceTypeDto } from "@api-types/chat";

export const SportHeader = observer(function SportHeader(props: {
	visible?: boolean;
	eventCount: number;
	isLive?: boolean;
	isHome?: boolean;
	toggleDisabled?: boolean;
	sport: {
		abrv: string;
		name: string;
		id: string;
	};
	onShowHide: (sportId: string) => void;
}) {
	const { t } = useTranslation();

	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();

	const headClasses = classNames("offer__head", {
		"offer--home__head": props.isHome,
		"offer--virtual__head offer__head--sticky":
			!props.isHome && props.isLive,
		"offer__head--bordered": props.toggleDisabled,
		"is-collapsed": !props.visible,
		"is-expanded": props.visible,
	});

	const titleClasses = classNames("offer__head__data offer__title", {
		"offer--home__title": props.isHome,
	});

	const wrapperClasses = classNames("offer__title__wrapper", {
		"offer--live__title__wrapper": props.isLive,
	});

	const nameClasses = classNames("offer__name", {
		"offer--home__name": props.isHome,
	});

	const toggleClasses = classNames("offer__head__data offer__toggle", {
		"offer__toggle--sml": isAdditionalOfferVisible,
	});

	return (
		<div className={headClasses}>
			<div className="offer__head__row">
				<div className={titleClasses}>
					<div className={wrapperClasses}>
						<div className={nameClasses}>
							{!props.isHome && !props.toggleDisabled ? (
								<span className="offer__icon">
									<OfferIcon
										type="sport"
										sport={props.sport.abrv}
										className="offer__icon__primary u-icon u-icon--lrg"
									/>
								</span>
							) : null}

							<span className="offer__name__clip">
								{t(props.sport.name)}
							</span>

							{!props.isHome && !props.toggleDisabled ? (
								<EventCount eventCount={props.eventCount} />
							) : null}
						</div>

						<ChatButton
							channelType={ChannelResourceTypeDto.SPORT}
							resourceId={props.sport.id}
						/>
					</div>
				</div>

				<ColumnTypeSelectors isLive isHome={props.isHome} />

				<div className={toggleClasses}>
					{!props.isHome && !props.toggleDisabled ? (
						<button
							className="offer__toggle__btn btn btn--square btn--square--tny btn--ghost"
							type="button"
							onClick={() => props.onShowHide(props.sport.id)}
						>
							<i
								className={classNames(
									"offer__toggle__icon u-icon u-icon--xsml",
									{
										"u-icon--caret--down": props.visible,
										"u-icon--caret--right": !props.visible,
									}
								)}
							/>
						</button>
					) : null}
				</div>
			</div>
		</div>
	);
});

const EventCount = observer(function EventCount(props: { eventCount: number }) {
	if (props.eventCount <= 0) {
		return null;
	}

	return (
		<span className="offer__title__counter counter counter--base counter--dark--outlined">
			{props.eventCount}
		</span>
	);
});
