import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import EmailChangeContent from "./components/EmailChangeContent";

export default function EmailChangePage() {
	const methods = useForm({ mode: "onChange" });

	return (
		<FormProvider {...methods}>
			<EmailChangeContent />
		</FormProvider>
	);
}
