import {
	observable,
	action,
	autorun,
	reaction,
	computed,
	runInAction,
	IReactionDisposer,
} from "mobx";

import { BettingOfferManagementHub } from "@gp/hub";
import {
	groupBy,
	each,
	find,
	isMatch,
	map,
	uniqBy,
	includes,
	unionBy,
} from "lodash";

import {
	BetSlipStore as GpBetSlipStore,
	EventType,
	BetSlipErrorCodes,
	parseSpecifier,
	BetSlipBetType,
	createEmptySlip,
	OddTypeStore,
	INotificationProviderError,
	INotificationProviderSuccess,
} from "@gp/slip";

import { BetSlipTypes, BetSlipPaymentType, BetSlipOffer } from "@gp/models";

import { getTip, playerSports } from "@v2/helpers";
import LoaderStore from "@state/stores/common/LoaderStore";
import { BetSlipService } from "@v2/data-access/bet-slip";
import {
	isUserAuthenticated,
	getCurrentCulture,
	UserTypes,
	AccountTypes,
	DeviceDetector,
} from "@utils";
import { SessionStorageProvider, localizationService } from "@state";
import { NotificationStore } from "@v2/state/shared";
import RootOfferStore from "@offer/stores/RootStore";
import { IConsoleLogger } from "@interface";
import { UserAuthStore } from "@v2/state/shared/UserAuthStore";
import { getType } from "@v2/helpers";
import PlayedLiveEventsStore from "@offer/stores/components/offer-menu/counters/PlayedLiveEventsStore";
import { DocumentExpirationErrorCodes } from "@api-types/documentExpiration/documentExpiration";
import { StorageStateKeysEnum } from "@utils/storage";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { BetSlipInteractionTypeEnum } from "@data-types/analytics/BetSlipInteractionTypeEnum";
import { DateTime } from "luxon";
import { LoginResponseUser } from "@api-types/user/LoginResponseDto";
import { Bet, BetOffer } from "@administration/pages/my-bets/model";
import { useUser } from "@common/hooks";

const stakeErrorCodes = [
	400017, 400018, 400019, 400020, 400024, 400030, 400032, 400033, 400036,
	400037, 400038,
];
const valueErrorCodesWithDetails = ["400065", "400068"];
const errorCodesWithoutDetails = ["400091", "400092"];

const keysList = [
	"paymentPerCombination",
	"gain",
	"totalStake",
	"payment",
	"stakePerCombination",
	"maxGain",
	"gainBonusAmount",
	"gainTaxAmount",
];

const AccountVerificationErrorCodes = {
	AccountVerification: 400185,
	AccountRecheck: 400535,
};

function getName(name: string, gender: undefined | number) {
	if (gender === 1) {
		return `${name} (${localizationService.t("OFFER.GENDER.WOMEN")})`;
	}

	return name;
}

/** slip store. */
export default class BetSlipStore {
	// ------------- COUNTDOWN -------------
	@observable public isCountdownVisible = false;
	@observable public countdownCounter = 0;
	@observable private countdownInterval: number | undefined = undefined;

	// ---------- Reuse ----------
	@observable public isReuseInProgress = false;

	// ---------- Modals ----------
	@observable public fullErrorMessage = "";
	@observable public showSubmitSuccessModal = false;

	public betSlipState: GpBetSlipStore;
	public playedLiveEventsStore: PlayedLiveEventsStore | undefined;
	private betSlipService: BetSlipService;

	@computed public get isLoading() {
		return this.loader.isLoading;
	}

	@computed public get isLoadingProcess() {
		return this.loader.isLoadingProcess;
	}

	@computed public get isBettingDisabled() {
		return this.userStore.isBettingDisabledPerWallet;
	}

	@observable public isSubmitDisabled = false;

	/** @deprecated this should not be used. */
	private rootOfferStore: RootOfferStore;
	private userStore: UserAuthStore;
	private offerHub: BettingOfferManagementHub;
	private notificationStore: NotificationStore;
	private logger: IConsoleLogger;
	private loader: LoaderStore;

	constructor(params: {
		offerHub: BettingOfferManagementHub;
		notificationStore: NotificationStore;
		userStore: UserAuthStore;
		rootOfferStore: RootOfferStore;
		balanceResolver: InstanceType<typeof UserAuthStore>["resolveBalance"];
		logger: IConsoleLogger;
	}) {
		this.offerHub = params.offerHub;
		this.notificationStore = params.notificationStore;
		this.rootOfferStore = params.rootOfferStore;
		this.logger = params.logger;
		this.userStore = params.userStore;

		this.betSlipService = new BetSlipService();
		this.loader = new LoaderStore();

		const user = JSON.parse(
			App.state.rootStore.localStorageProvider.get(
				StorageStateKeysEnum.USER_KEY
			) as string
		) as LoginResponseUser;

		this.betSlipState = new GpBetSlipStore(
			this.betSlipService,
			{
				hub: this.offerHub,
				isUserAuthenticated: isUserAuthenticated,
				notificationProvider: {
					onError: this.handleError,
					onSuccess: this.onSuccess,
				},
				oddTypeStore: new OddTypeStore(),

				updateBalance: (value: number) => {
					if (!this.betSlipState.hasErrors) {
						params.balanceResolver();
					} else {
						this.logger.logError("Error", this.betSlipState.error);
					}
				},
				slipIndicatorDuration: 2000,
				isReuseEnabled: true,
				actionControllerOptions: {
					actionThrottle: 400,
				},
			},
			{
				totalStake: user?.stakeAmount || WEBPACK_DEFAULT_STAKE,
			}
		);

		this.initSlipState();
	}

	/** Initialize slip state from the local storage. */
	@action.bound
	private initSlipState() {
		//#region read slip from storage

		let storageIsOneClickBet = Boolean(
			JSON.parse(SessionStorageProvider.get("ocb") as string)
		);

		if (storageIsOneClickBet) {
			this.betSlipState.betSlipBetType = BetSlipBetType.OneClickBet;
		}

		let slip =
			JSON.parse(SessionStorageProvider.get("slip") as string) || {};

		if (slip == null || Object.entries(slip).length == 0) {
			this.betSlipState.slip = createEmptySlip();
			slip = Object.assign(
				{},
				this.betSlipState.slip,
				this.betSlipState.initialState
			);
		} else {
			this.betSlipState.slip = slip;
		}

		this.betSlipState.maxGain = slip.maxGain;
		this.betSlipState.gain = slip.gain;
		this.betSlipState.payment = slip.payment;
		this.betSlipState.stakePerCombination = slip.stakePerCombination;
		this.betSlipState.paymentPerCombination = slip.paymentPerCombination;
		this.betSlipState.totalStake = slip.stake;
		this.betSlipState.combinations = slip.combinations;

		this.betSlipState.handlingFeeAmount = slip.handlingFee || 0;
		this.betSlipState.handlingFeePercentage =
			slip.handlingFeePercentage || 0;
		this.betSlipState.gainBonusAmount = slip.gainBonus || 0;
		this.betSlipState.gainBonusPercentage = slip.gainBonusPercentage || 0;
		this.betSlipState.gainTaxAmount = slip.gainTax || 0;
		this.betSlipState.gainTaxPercentage = slip.gainTaxPercentage || 0;

		this.betSlipState.eventCount = slip.eventCount || 0;

		//#endregion read slip from storage

		this.betSlipState.betSlipType =
			JSON.parse(SessionStorageProvider.get("type") as string) ||
			BetSlipBetType.Normal;

		this.betSlipState.paymentType =
			(JSON.parse(
				sessionStorage.getItem("payment_type") as string
			) as BetSlipPaymentType | null) || BetSlipPaymentType.TOTAL;

		if (this.betSlipState.betSlipType === BetSlipTypes.SYSTEM) {
			this.betSlipState.availableCombinations = JSON.parse(
				SessionStorageProvider.get("system") as string
			);
			this.betSlipState.selectedCombinations = JSON.parse(
				SessionStorageProvider.get("ssystem") as string
			);
		}

		this.betSlipState.clickedTips =
			JSON.parse(SessionStorageProvider.get("clicked-tips") as string) ||
			[];

		this.betSlipState.acceptOddsChanges =
			JSON.parse(
				SessionStorageProvider.get(
					"betslip-accept-odds-change"
				) as string
			) || false;

		this.betSlipState.bankEvents = [
			...new Set(
				this.betSlipState.slip.betSlipOffers
					.filter((offer) => offer.isBank)
					.map((offer) => offer.eventId)
			),
		];
	}

	//#region slip submit

	@observable public showConfirmBetSlipSubmit = false;

	@action.bound
	public cancelBetSlipSubmit() {
		this.showConfirmBetSlipSubmit = false;
	}

	@action.bound
	public startBetSlipSubmitProcess() {
		if (isUserAuthenticated()) {
			this.showConfirmBetSlipSubmit = true;
		} else {
			window.location.href = `/${getCurrentCulture()}/auth/login`;
		}
	}

	@action.bound
	public async onSubmitSlip() {
		this.loader.suspend();
		this.isSubmitDisabled = true;
		this.showConfirmBetSlipSubmit = false;

		if (this.betSlipState.slip.isLive) {
			this.startCountdown();
		}

		// needs to happen because of possible wrong data formatting in acc key in localStorage
		// !!!! - can be removed after some time but doesn't hurt
		await App.state.rootStore.userAuthStore.checkBalanceEntry();
		const currentAccountType = App.state.rootStore.localStorageProvider.get(
			StorageStateKeysEnum.ACC
		) as string;
		await this.betSlipState.submitSlip(
			false,
			false,
			false,
			currentAccountType
		);

		runInAction(() => {
			if (!this.betSlipState.hasErrors) {
				// @ts-expect-error
				this.rootOfferStore?.liveMenuViewStore?.liveEventsFromMyBets?.updateEvents(
					this.betSlipState.subscription.events
				);
				const owner =
					this.userStore.getUserTypeFromBettingAccountTypeId(
						currentAccountType
					);
				if (DeviceDetector.isMobileTheme) {
					App.state.redirect(
						`/${getCurrentCulture()}/bet-slip/bet-slip-receipt/${
							this.betSlipState?.slip?.id
						}/${owner}`
					);
				}
				this.betSlipState.reset({ resetSubmitDelay: false });
			}
			this.loader.resume();
			this.isSubmitDisabled = false;
		});
	}

	@action.bound
	public async addRemoveOffer(
		tip: Parameters<
			typeof this["betSlipState"]["addRemoveOfferWithTypeChange"]
		>[1]
	) {
		if (this.isBettingDisabled) {
			this.notificationStore.error(
				localizationService.t("WELCOME_POPUP.BETTING_DISABLE_HINT")
			);
			return;
		}

		if (this.betSlipState.isSubmitting) {
			return;
		}

		if (this.betSlipState.isOneClickBetActive) {
			this.loader.suspend();
		}

		if (
			this.betSlipState.isOneClickBetActive &&
			(this.betSlipState.oneClickBetAmount || 0) <= 0
		) {
			this.notificationStore.error(
				localizationService.t("BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE")
			);
			return;
		}

		if (this.betSlipState.isOneClickBetActive) {
			if (tip.isLive) {
				this.startCountdown();
			}

			if (this.playedLiveEventsStore != null) {
				this.playedLiveEventsStore.addPlayedOneClickTip(tip);
			}
		}

		const currentAccountType = App.state.rootStore.localStorageProvider.get(
			StorageStateKeysEnum.ACC
		) as string;

		await this.betSlipState.addRemoveOfferWithTypeChange(
			tip,
			currentAccountType
		);
		if (this.betSlipState.isOneClickBetActive) {
			this.loader.resume();
		}
	}

	@action.bound
	private startCountdown() {
		this.countdownCounter = this.countDownValue;
		if (this.countdownCounter !== 0) {
			this.isCountdownVisible = true;
			this.countdownInterval = Number(
				setInterval(async () => {
					--this.countdownCounter;
					if (this.countdownCounter === 0) {
						clearInterval(this.countdownInterval);
						this.isCountdownVisible = false;
					}
				}, 1000)
			);
		} else {
			this.isCountdownVisible = false;
		}
	}

	@action.bound
	/** Toggle is offer in slip ignored for submit and validate. */
	public async toggleTipExclude(id: string) {
		await this.betSlipState.toggleTipExclude(id);
		SessionStorageProvider.set(
			"clicked-tips",
			JSON.stringify(this.betSlipState.clickedTips)
		);
	}

	//#endregion slip submit

	//#region clear all form bet slip

	@observable public showResetModal = false;

	@action.bound
	public startBetSlipReset() {
		this.showResetModal = true;
	}

	@action.bound
	public cancelBetSlipReset() {
		this.showResetModal = false;
	}

	@action.bound
	public acceptBetSlipReset() {
		this.showResetModal = false;
		this.onResetSlip();
	}

	//#endregion clear all from bet slip

	//#region change bet slip type

	@observable public showOneClickBetClearSlipModal = false;

	@action.bound
	public acceptSlipClearAndSetToOneClick() {
		this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.OneClickBet);
		if (!this.betSlipState.acceptOddsChanges && this.isOneClickBetActive) {
			this.betSlipState.onToggleAcceptOddsChangesChange();
		}
		runInAction(() => {
			this.cancelBetSlipSubmit();
			this.showOneClickBetClearSlipModal = false;
		});
	}

	@action.bound
	onBetSlipBetTypeChangeAccept() {
		this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.OneClickBet);
		this.showOneClickBetClearSlipModal = false;
	}

	@action.bound
	onCloseOneClickBetChangeModal() {
		this.showOneClickBetClearSlipModal = false;
	}

	//#endregion change bet slip type

	//#region computed and getters

	@computed private get countDownValue(): number {
		return this.betSlipState.submitDelay || 0;
	}

	@computed public get isOneClickBetActive(): boolean {
		return this.betSlipState.isOneClickBetActive;
	}

	@computed public get isSlipEmpty(): boolean {
		return this.betSlipState.isSlipEmpty;
	}

	@computed public get betSlipType(): "single" | "combination" | "system" {
		return this.betSlipState.betSlipType;
	}

	@computed public get betSlipBetType(): "one-click" | "normal" {
		return this.betSlipState.betSlipBetType;
	}

	@computed public get displaySlipEventEntries() /**TODO */ {
		return this.betSlipState.displaySlipEventEntries;
	}

	@computed public get currentSlipOffers() /**TODO */ {
		return this.betSlipState.currentSlipOffers;
	}

	@computed public get slipSize(): number {
		return this.betSlipState.slipSize;
	}

	@computed public get combinations() /** TODO */ {
		return this.betSlipState.combinations;
	}

	@computed public get isProcessingSlipEmpty(): boolean {
		return this.betSlipState.isProcessingSlipEmpty;
	}

	@computed public get paymentPerCombination(): number {
		return this.betSlipState.paymentPerCombination;
	}

	@computed public get payment(): number {
		return this.betSlipState.payment;
	}

	@computed public get availableCombinations() /**TODO */ {
		return this.betSlipState.availableCombinations;
	}

	@computed public get bankOfferCount(): number {
		return this.betSlipState.bankOfferCount;
	}

	@computed public get eventCount(): number {
		return this.betSlipState.eventCount;
	}

	@computed public get isHandlingFeeActive(): boolean {
		return this.betSlipState.isHandlingFeeActive;
	}

	@computed public get handlingFeePercentage(): number {
		return this.betSlipState.handlingFeePercentage;
	}

	@computed public get handlingFeeAmount(): number {
		return this.betSlipState.handlingFeeAmount;
	}

	@computed public get slipMaxCoefficient(): number {
		return this.betSlipState.slipMaxCoefficient;
	}

	@computed public get gainBonusAmount(): number {
		return this.betSlipState.gainBonusAmount;
	}

	@computed public get gainBonusPercentage(): number {
		return this.betSlipState.gainBonusPercentage;
	}

	@computed public get gainTaxPercentage(): number {
		return this.betSlipState.gainTaxPercentage;
	}

	@computed public get gainTaxAmount(): number {
		return this.betSlipState.gainTaxAmount;
	}

	@computed public get maxGain(): number {
		return this.betSlipState.maxGain;
	}

	@computed public get gain(): number {
		return this.betSlipState.gain;
	}

	@computed public get acceptOddsChanges() /** TODO */ {
		return this.betSlipState.acceptOddsChanges;
	}

	public get isSystemEnabled(): boolean {
		// console.warn(
		// 	"isSystemEnabled is never changed on the store and default is always true"
		// );
		return true;
	}

	@computed public get oneClickBetAmount(): number {
		return this.betSlipState.oneClickBetAmount;
	}

	@computed public get invalidBetsCount(): number {
		return this.betSlipState.offers.filter(
			(o) =>
				(o.isEventLocked ||
					o.isKeyLocked ||
					o.isOfferLocked ||
					o.isDeleted) &&
				o.errors != null
		).length;
	}

	@computed public get hasInvalidBets(): boolean {
		return this.invalidBetsCount > 0;
	}

	@computed public get isSubmitting(): boolean {
		return this.betSlipState.isSubmitting;
	}
	//#endregion computed and getters

	//#region actions

	@action.bound
	public setEventSwitcherEventIds(eventId: string) {
		const list = this.betSlipState.subscription.events;
		const eventIndex = list.indexOf(eventId);

		this.rootOfferStore.eventSwitcherViewStore.setEventList(
			list,
			eventIndex
		);
	}

	@action.bound
	public async onInitialize() {
		this.startReactions();
		this.betSlipState.onInitialize();

		if (this.isReuseInProgress) {
			this.isReuseInProgress = false;
			return;
		}

		try {
			// check if bet slip is not empty and revalidate slip!
			await this.betSlipState.processCurrentBetSlipState();

			const response = await this.betSlipService.delay();
			this.betSlipState.setSubmitDelay(response ? response.value : null);
		} catch (err) {
			this.logger.logError(err);
			this.betSlipState.setSubmitDelay(0);
		}
	}

	@action.bound
	public async getSlipAndMap(
		slipId: string,
		activeOwner = UserTypes.PRIMARY
	) {
		runInAction(() => {
			this.isReuseInProgress = true;
		});

		let response = await this.betSlipService.getSlip(slipId, activeOwner);
		this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.Normal);
		// display tip comes unformatted from API, we need to format it (pre:outcometext from API)
		response.betSlipOffers.forEach(
			// @ts-expect-error
			(offer) => (offer.displayTip = getTip(offer))
		);
		response.betSlipOffers = unionBy(
			this.betSlipState.slip.betSlipOffers.slice(),
			response.betSlipOffers,
			"bettingOfferId"
		);

		if (response !== null) {
			runInAction(async () => {
				await this.betSlipState.useExistingSlip(response, {
					acceptOddsChanges: true,
					useValidOffersOnly: false,
					paymentType: response.betSlipOffers.some(
						// @ts-expect-error
						(element, index) => {
							return (
								response.betSlipOffers.findIndex(
									// @ts-expect-error
									(item) => item.eventId === element.eventId
								) !== index
							);
						}
					)
						? "PerCombination"
						: "Total",
				});
			});
			AnalyticsService.logBetSlipInteraction(
				BetSlipInteractionTypeEnum.Reuse
			);
		}

		const params = new URLSearchParams(window.location.search);
		params.delete("bid");
		params.delete("accType");
		const finalParams = params.toString();
		window.history.pushState(
			{},
			"",
			`${window.location.pathname}${
				finalParams !== "" ? "?" + finalParams : ""
			}`
		);
	}
	@action.bound
	public sharedSlipAndMap(sharedSlip: any, stake?: number) {
		runInAction(() => {
			this.isReuseInProgress = true;
		});

		const slip = sharedSlip;

		if (!App.state.rootStore.userAuthStore.user) {
			slip.payment = WEBPACK_DEFAULT_STAKE;
		}
		if (App.state.rootStore.userAuthStore.user) {
			slip.payment = App.state.rootStore.userAuthStore.user?.stakeAmount;
		}

		if (stake) {
			slip.payment = stake;
		}

		this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.Normal);
		// display tip comes unformatted from API, we need to format it (pre:outcometext from API)
		slip.betSlipOffers.forEach(
			// @ts-expect-error
			(offer) => (offer.displayTip = getTip(offer))
		);
		slip.betSlipOffers = unionBy(
			this.betSlipState.slip.betSlipOffers.slice(),
			slip.betSlipOffers,
			"bettingOfferId"
		);

		if (slip !== null) {
			runInAction(async () => {
				await this.betSlipState.useExistingSlip(slip, {
					acceptOddsChanges: true,
					useValidOffersOnly: false,
					paymentType: slip.betSlipOffers.some(
						// @ts-expect-error
						(element, index) => {
							return (
								slip.betSlipOffers.findIndex(
									// @ts-expect-error
									(item) => item.eventId === element.eventId
								) !== index
							);
						}
					)
						? "PerCombination"
						: "Total",
				});
			});
			AnalyticsService.logBetSlipInteraction(
				BetSlipInteractionTypeEnum.Reuse
			);
		}

		const params = new URLSearchParams(window.location.search);
		params.delete("bid");
		params.delete("accType");
		const finalParams = params.toString();
		window.history.pushState(
			{},
			"",
			`${window.location.pathname}${
				finalParams !== "" ? "?" + finalParams : ""
			}`
		);
	}

	@action.bound
	public setDefaultBetSlipStake(value: number | null = null) {
		try {
			const newValue = value ? value : this.betSlipState.payment;
			this.betSlipService.saveNewDefaultStake(newValue);

			if (this.userStore.user != null) {
				this.userStore.user.stakeAmount = newValue;
				App.state.rootStore.localStorageProvider.set(
					StorageStateKeysEnum.USER_KEY,
					JSON.stringify(this.userStore.user)
				);
				this.betSlipState.shouldApplyTotalStake = true;
				this.onTotalStakeChange(newValue);
				this.onTotalStakeApply();
			}
		} catch (error) {
			this.logger.logError(
				"Could not save new default stake internally",
				error
			);
		}
		this.notificationStore.success(
			localizationService.t("BET_SLIP.DEFAULT_STAKE_MESSAGE", {
				stake: value ? value : this.betSlipState.payment,
				currency: App.state.rootStore.userAuthStore.currency,
			})
		);
	}

	@action.bound
	public changeBetSlipBetType(value: this["betSlipBetType"]) {
		if (
			value === BetSlipBetType.OneClickBet &&
			this.betSlipState.isSlipEmpty
		) {
			this.betSlipState.onBetSlipBetTypeChange(value);
			return;
		} else if (
			value === BetSlipBetType.OneClickBet &&
			!this.betSlipState.isSlipEmpty
		) {
			this.showOneClickBetClearSlipModal = true;
			return;
		}

		this.betSlipState.onBetSlipBetTypeChange(value);
	}

	@action.bound
	public onBetSlipTypeChange(value: "single" | "combination" | "system") {
		this.betSlipState.onBetSlipTypeChange(value);
	}

	@action.bound
	public deactivateOneClickBet() {
		this.betSlipState.onDeactivateOneClickBet();
	}

	//#endregion actions

	//#region handlers

	@action.bound
	private onSuccess(m: INotificationProviderSuccess) {
		this.notificationStore.success(
			localizationService.t("BET_SLIP.SUBMIT_SUCCESS")
		);
		const type = this.betSlipState.isOneClickBetActive
			? BetSlipInteractionTypeEnum.OneClickBet
			: BetSlipInteractionTypeEnum.Submit;
		AnalyticsService.logBetSlipInteraction(type);

		if (this.isCountdownVisible) {
			this.isCountdownVisible = false;
		}

		//#region inject newly submitted events into played events counter

		if (this.playedLiveEventsStore != null) {
			let eventIds = [];

			if (this.betSlipState.isOneClickBetActive) {
				this.playedLiveEventsStore.successfulOneClickTips(
					this.betSlipState._oneClickTips
				);
			} else {
				eventIds = this.betSlipState.slip.betSlipOffers.reduce<
					string[]
				>((acc, offer) => {
					acc.push(offer.eventId);
					return acc;
				}, []);
				this.playedLiveEventsStore.updateEvents(eventIds);
			}
		}

		//#endregion inject newly submitted events into played events counter

		if (this.rootOfferStore.myBetsViewStore) {
			this.rootOfferStore.myBetsViewStore.refresh();
		}
	}

	/**
	 * Handles bet slip error notification
	 */
	@action.bound
	private handleError(rawError: INotificationProviderError) {
		// clear interval for submitting live ticket
		clearInterval(this.countdownInterval);
		this.isCountdownVisible = false;

		if (rawError == null) {
			return;
		}

		if (
			rawError.error &&
			!Array.isArray(rawError.error) &&
			rawError.error.statusCode === 401
		) {
			//the user is logged out on some way and needs to be redirected to login page
			window.location.href = `/${getCurrentCulture()}/auth/login?isSessionTerminated=true`;
		}

		if (
			rawError.error &&
			!Array.isArray(rawError.error) &&
			rawError.error.errorCode === 400007
		) {
			this.notificationStore.error(rawError.error.details);
			return;
		}

		if (rawError.mappedError != null) {
			this.handleMappedError(rawError);
		} else if (rawError.statusCode === BetSlipErrorCodes.Generic) {
			this.notificationStore.error(rawError.message);
		} else {
			this.notificationStore.error(
				localizationService.t(rawError.message)
			);
		}
	}

	@action.bound
	private handleMappedError(rawError: INotificationProviderError) {
		if (!Array.isArray(rawError.mappedError)) {
			// @ts-expect-error by TS this will never happen, mappedError will always be array
			if (rawError.mappedError?.message != null) {
				// @ts-expect-error
				this.notificationStore.error(rawError.mappedError.message);
			}
			return;
		}

		const bettingDisabledError = rawError.mappedError.filter(
			(err) => err.errorCode === 400259 // error code for betting disabled
		);

		if (bettingDisabledError.length) {
			this.notificationStore.error(
				localizationService.t(bettingDisabledError[0]?.errorMessage)
			);
			this.userStore.setBettingDisabled(true);
		}

		const verificationError = rawError.mappedError.filter(
			(err) =>
				err.errorCode ===
					AccountVerificationErrorCodes.AccountVerification ||
				err.errorCode ===
					AccountVerificationErrorCodes.AccountRecheck ||
				err.errorCode ===
					DocumentExpirationErrorCodes.UserDocumentExpirationDateExpired ||
				err.errorCode ===
					DocumentExpirationErrorCodes.UserDocumentExpirationDateNotSet
		);
		if (verificationError.length) {
			let link: string | null = null;
			let titleText: string | null = null;
			if (
				verificationError[0].errorCode ==
				AccountVerificationErrorCodes.AccountVerification
			) {
				link = "account-settings/account-verification";
				titleText = "ACCOUNT_VERIFICATION.TITLE";
			}
			if (
				verificationError[0].errorCode ==
				AccountVerificationErrorCodes.AccountRecheck
			) {
				link = "user-profile-recheck";
				titleText = "ACCOUNT_RECHECK.TITLE";
			}
			if (
				verificationError[0].errorCode ==
				DocumentExpirationErrorCodes.UserDocumentExpirationDateExpired
			) {
				link = "account-settings/document-expiration";
				titleText = "DOCUMENT_EXPIRATION.TITLE";
			}
			if (
				verificationError[0].errorCode ==
				DocumentExpirationErrorCodes.UserDocumentExpirationDateNotSet
			) {
				link = "account-settings/document-expiration";
				titleText = "DOCUMENT_EXPIRATION.TITLE";
			}
			App.state.rootStore.AccountVerificationStore.showPopup(
				link as string, // TODO what if null?
				titleText as string, // TODO what if null?
				verificationError[0].errorMessage
			);
			return;
		}

		if (!this.betSlipState.isSubmitting) {
			rawError.mappedError = rawError.mappedError.filter(
				(errorItem) =>
					errorItem.errorCode !== 400062 &&
					errorItem.errorCode !== 400063
			);
		}

		each(rawError.mappedError, (errorItem) => {
			if (errorItem.errorMessage.includes("{currency}")) {
				errorItem.errorMessage = errorItem.errorMessage.replaceAll(
					"{currency}",
					App.state.rootStore.userAuthStore.currency
				);
			}

			if (errorItem.errorCode === 400262) {
				errorItem.errorMessage = errorItem.errorMessage.replace(
					"{0}",
					//@ts-expect-error
					DateTime.fromISO(errorItem.previouslyClosedAt).toFormat("f")
				);
				errorItem.errorMessage = errorItem.errorMessage.replace(
					"{1}",
					//@ts-expect-error
					DateTime.fromISO(errorItem.nextOpening).toFormat("f")
				);
			}

			if (
				errorItem.errorMessage.includes("{0}") &&
				errorItem.sportData != null
			) {
				const currentSportData = errorItem.sportData[0];
				if (errorItem.errorCode === 400091) {
					const eventName = currentSportData.sportData.eventName
						? currentSportData.sportData.eventName
						: `${currentSportData.sportData.teamOneName} - ${currentSportData.sportData.teamTwoName}`;
					errorItem.errorMessage = `<p class="slip-error">${errorItem.errorMessage.replace(
						"{0}",
						eventName
					)}</p>`;
				} else if (errorItem.errorCode === 400092) {
					const bettingType = parseSpecifier(
						currentSportData.sportData
							.bettingTypeNameForBettingSlip,
						currentSportData.specifier
							? currentSportData.specifier
							: {}
					);

					errorItem.errorMessage = `<p class="slip-error">${errorItem.errorMessage.replace(
						"{0}",
						bettingType.parsedString
					)}</p>`;
				} else if (errorItem.errorCode === 400098) {
					errorItem.errorMessage = errorItem.errorMessage.replace(
						"{0}",
						errorItem.totalNumberOfOffersPerBetSlip || "0"
					);
				}
			}
		});
		const errorsByErrorCode = groupBy(rawError.mappedError, "errorCode");
		let errorMessages = Object.keys(errorsByErrorCode);
		each(errorMessages, (key) => {
			//key is error code
			const containsSportData =
				find(
					errorsByErrorCode[key],
					(item) =>
						item.sportData != null && item.sportData.length > 0
				) !== undefined;

			if (
				containsSportData == null ||
				errorCodesWithoutDetails.includes(key.toString())
			) {
				return;
			}

			var errorMessageContainsSuggestedValue = find(
				errorsByErrorCode[key],
				(item) => /\d/.test(item.errorMessage)
			);

			if (
				errorMessageContainsSuggestedValue != null &&
				!valueErrorCodesWithDetails.includes(key.toString())
			) {
				return;
			}

			const currentMessageList = errorsByErrorCode[key];
			const eventIdNotExists = currentMessageList.some((o) => !o.eventId);
			const groupedCurrentMessageList = groupBy(
				currentMessageList,
				eventIdNotExists ? "sportData[0].eventId" : "eventId"
			);
			// @ts-expect-error
			let currentMessageDetailsList = [];
			each(Object.values(groupedCurrentMessageList), (message) => {
				let details = {
					bettingType: "",
					teams: "",
				};
				const sportDataList = message[0].sportData;

				if (sportDataList == null || sportDataList.length <= 0) {
					return;
				}
				let sportData = sportDataList[0].sportData;
				if (key === "400039") {
					const offer = message[0];
					const bettingTypeOneFromResponse = offer.bettingTypeOne;
					const bettingTypeTwoFromResponse = offer.bettingTypeTwo;
					let bettingTypeOneFormatted = "";
					let bettingTypeTwoFormatted = "";

					if (bettingTypeOneFromResponse?.includes("{")) {
						bettingTypeOneFormatted = getType(
							this.betSlipState.slip.betSlipOffers.find(
								(o: any) =>
									o.bettingOfferId === offer.correlationId
							)
						);
					} else {
						bettingTypeOneFormatted =
							bettingTypeOneFromResponse || "";
					}

					if (bettingTypeTwoFromResponse?.includes("{")) {
						bettingTypeTwoFormatted = getType(
							this.betSlipState.slip.betSlipOffers.find(
								(o: any) =>
									o.bettingOfferId === offer.correlationTwoId
							)
						);
					} else {
						bettingTypeTwoFormatted =
							bettingTypeTwoFromResponse || "";
					}

					details = {
						teams: "",
						bettingType: `${bettingTypeOneFormatted}\n${bettingTypeTwoFormatted}`,
					};
				} else if (key === "400043") {
					const secondTournament =
						this.betSlipState.slip.betSlipOffers.find(
							(offer) => offer.eventId === message[0].eventTwoId
						);
					let secondTournamentName = null;
					if (secondTournament) {
						secondTournamentName =
							secondTournament.sportData.tournamentName;
					}
					details = {
						teams: `${sportData.tournamentName}${
							secondTournamentName
								? `  ${secondTournamentName}`
								: ""
						}`,
						bettingType: "",
					};
				} else if (key === "400040" || key === "400041") {
					const eventOne = this.betSlipState.slip.betSlipOffers.find(
						(offer) => offer.eventId === message[0].eventId
					);
					const eventTwo = this.betSlipState.slip.betSlipOffers.find(
						(offer) => offer.eventId === message[0].eventTwoId
					);
					let eventOneName = null;
					let eventTwoName = null;

					if (eventOne && eventOne.sportData) {
						eventOneName = eventOne.sportData.eventName
							? eventOne.sportData.eventName
							: `${eventOne.sportData.teamOneName} - ${eventOne.sportData.teamTwoName}`;
					}
					if (eventTwo && eventTwo.sportData) {
						eventTwoName = eventTwo.sportData.eventName
							? eventTwo.sportData.eventName
							: `${eventTwo.sportData.teamOneName} - ${eventTwo.sportData.teamTwoName}`;
					}

					details = {
						teams:
							eventOneName && eventTwoName
								? `${eventOneName} ${eventTwoName}`
								: "",
						bettingType: "",
					};
				} else if (key === "400044") {
					details = {
						teams: sportData.tournamentName,
						bettingType: "",
					};
				} else {
					const homeTeamName =
						sportData.eventType === EventType.Normal
							? getName(
									sportData.teamOneName,
									sportData.teamOneGender
							  )
							: "";
					const awayTeamName =
						sportData.eventType === EventType.Normal
							? getName(
									sportData.teamTwoName,
									sportData.teamTwoGender
							  )
							: "";

					details = {
						teams:
							sportData.eventType === EventType.Normal
								? `${homeTeamName} - ${awayTeamName}`
								: `${sportData.eventName}`,
						bettingType: "",
					};
				}
				if (
					![
						"400039",
						"400040",
						"400041",
						"400043",
						"400044",
					].includes(key.toString())
				) {
					each(message, (o, idx) => {
						if (o.sportData == null) return;
						const currentSportData = o.sportData[0];

						let c1Key = "OFFER.COMPETITORS.HOME";
						let c2Key = "OFFER.COMPETITORS.AWAY";
						if (
							playerSports.includes(
								currentSportData.sportData.sportAbrv || ""
							)
						) {
							c1Key = "OFFER.COMPETITORS.PLAYER1";
							c2Key = "OFFER.COMPETITORS.PLAYER2";
						}

						const bettingType = parseSpecifier(
							currentSportData.sportData
								.bettingTypeNameForBettingSlip,
							currentSportData.specifier
								? currentSportData.specifier
								: {},
							{
								competitor1: localizationService.t(c1Key),
								competitor2: localizationService.t(c2Key),
							}
						);

						let tip = currentSportData.bettingTypeTip;
						if (currentSportData.playerId != null) {
							tip = `${currentSportData.playerFirstName} ${currentSportData.playerLastName}`;
						} else if (
							currentSportData.teamId != null &&
							currentSportData.teamName != null
						) {
							tip = currentSportData.teamName;
						} else if (currentSportData.displayTip != null) {
							tip = currentSportData.displayTip;
						}

						const stringComposition = `${bettingType.parsedString} ${tip}`;
						if (idx === 0) {
							details.bettingType += stringComposition;
						} else {
							details.bettingType += `\n${stringComposition}`;
						}
					});
				}
				const existingDetails = find(
					// @ts-expect-error
					currentMessageDetailsList,
					(o) => {
						return isMatch(o, details);
					}
				);
				if (!existingDetails) {
					currentMessageDetailsList.push(details);
				}
			});

			if (currentMessageDetailsList.length > 0) {
				each(currentMessageList, (errorItem) => {
					each(
						// @ts-expect-error
						currentMessageDetailsList,
						(detailObj) => {
							errorItem.errorMessage += `<p class="slip-error">${
								detailObj.teams
							}${
								key === "400065"
									? ` : ${parseInt(
											errorItem.suggestedValue || "0"
									  )}`
									: ""
							}</p>${
								key === "400065"
									? ""
									: `<p class="bet-stop">${detailObj.bettingType}</p>`
							}`;
						}
					);
				});
			}
		});

		// If errors contain stake error, reset stake to last valid
		const stakeErrorCodes = [
			400017, 400018, 400019, 400020, 400024, 400030, 400032, 400033,
			400036, 400037, 400038, 400064,
		];
		const hasStakeErrors = rawError.mappedError.some((i) =>
			stakeErrorCodes.includes(i.errorCode || -1)
		);
		if (hasStakeErrors && this.betSlipState.isChangingSlipValues) {
			this.betSlipState.totalStake = this.betSlipState._memory.totalStake;
			this.betSlipState.stakePerCombination =
				this.betSlipState._memory.stakePerCombination;
			this.betSlipState.paymentPerCombination =
				this.betSlipState._memory.paymentPerCombination;
			this.betSlipState.gain = this.betSlipState._memory.gain;
			this.betSlipState.payment = this.betSlipState._memory.payment;
			this.betSlipState.maxGain = this.betSlipState._memory.maxGain;
			this.betSlipState.gainBonusPercentage =
				this.betSlipState._memory.gainBonusPercentage;
			this.betSlipState.gainBonusAmount =
				this.betSlipState._memory.gainBonusAmount;
			this.betSlipState.gainTaxPercentage =
				this.betSlipState._memory.gainTaxPercentage;
			this.betSlipState.gainTaxAmount =
				this.betSlipState._memory.gainTaxAmount;
			this.betSlipState.handlingFeePercentage =
				this.betSlipState._memory.handlingFeePercentage;
			this.betSlipState.handlingFeeAmount =
				this.betSlipState._memory.handlingFeeAmount;
			this.betSlipState.combinationBettingOffersCount =
				this.betSlipState._memory.combinationBettingOffersCount;
		}

		const errorMessage = map(
			uniqBy(rawError.mappedError, (o) => o.errorMessage),
			"errorMessage"
		).join("\n");
		if (errorMessage !== "") {
			this.notificationStore.error(errorMessage);
		}
	}

	//#endregion handlers

	//#region is methods

	public isTipSelected = (tipId: string): boolean => {
		if (this.betSlipState.isOneClickBetActive) {
			return this.betSlipState.isInOneClickBet(tipId);
		}
		return this.betSlipState.isTipClicked(tipId);
	};

	public isEventBank = (eventId: string): boolean => {
		return this.betSlipState.bankEvents.indexOf(eventId) > -1;
	};

	public isCombinationSelected = (key: number): boolean => {
		return this.betSlipState.selectedCombinations.indexOf(key) > -1;
	};

	//#endregion is methods

	//#region add/remove methods

	@action.bound
	public addRemoveBank(eventId: string) {
		this.betSlipState.addRemoveBank(eventId);
	}

	@action.bound
	public addRemoveCombination(comboKey: number) {
		this.betSlipState.paymentType = BetSlipPaymentType.PER_COMBINATION;
		this.betSlipState.addRemoveCombination(comboKey);
	}

	@action.bound
	public async removeInactiveBets() {
		this.betSlipState.removeInactiveOffers();
	}

	@action.bound
	public removeFromBetSlip(item: { id: string }) {
		this.betSlipState.removeFromBetSlip(item);
	}

	//#endregion add/remove methods

	//#region on action methods

	@action.bound
	public async onGainApply() {
		await this.betSlipState.onGainApply();
		this.checkBetSlipValues();
	}

	@action.bound
	public async checkLengthValue(e: Event) {
		// @ts-expect-error
		if (e.target.value.length > 16) {
			// @ts-expect-error
			e.target.value = e.target.value.substr(0, 16);
		}
	}

	@action.bound
	public async onPaymentPerCombinationApply() {
		await this.betSlipState.onPaymentPerCombinationApply();
		this.checkBetSlipValues();
	}

	@action.bound
	public onPaymentPerCombinationChange(value: number) {
		if (isNaN(value) || value <= 0) {
			this.notificationStore.error(
				localizationService.t("BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE")
			);
			return;
		}
		this.betSlipState.onPaymentPerCombinationChange(value);
	}

	@action.bound
	public onTotalStakeChange(value: number) {
		if (isNaN(value) || value <= 0) {
			this.notificationStore.error(
				localizationService.t("BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE")
			);
			return;
		}
		this.betSlipState.onTotalStakeChange(value);
	}

	@action.bound
	public async onTotalStakeApply() {
		await this.betSlipState.onTotalStakeApply();
		this.checkBetSlipValues();
	}

	@action.bound
	public onGainChange(value: number) {
		if (isNaN(value) || value < 0) {
			this.notificationStore.error(
				localizationService.t("BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE")
			);
			return;
		}
		this.betSlipState.onGainChange(value);
	}

	@action.bound
	public onToggleAcceptOddsChangesChange() {
		this.betSlipState.onToggleAcceptOddsChangesChange();
	}

	@action.bound
	public onOneClickBetAmountChange(value: number) {
		if (this.betSlipState.isOneClickBetActive && value < 0) {
			this.notificationStore.error(
				localizationService.t("BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE")
			);
		}

		this.betSlipState.onOneClickBetAmountChange(value);
	}

	@action.bound
	public onDeactivateOneClickBet() {
		this.betSlipState.onDeactivateOneClickBet();
	}

	//#endregion on action methods

	//#region helpers

	private checkBetSlipValues = (): void => {
		if (
			this.betSlipState.error &&
			this.betSlipState.error.error &&
			Array.isArray(this.betSlipState.error.error)
		) {
			const containsStakeError =
				find(this.betSlipState.error.error, (errorItem) =>
					includes(stakeErrorCodes, errorItem.errorCode)
				) !== undefined;

			if (containsStakeError) {
				each(
					keysList,
					(
						key: keyof GpBetSlipStore &
							keyof GpBetSlipStore["_memory"]
					) => {
						this.betSlipState[key] = this.betSlipState._memory[key];
					}
				);
			} else {
				each(
					keysList,
					(
						key: keyof GpBetSlipStore &
							keyof GpBetSlipStore["_memory"]
					) => {
						this.betSlipState._memory[key] = this.betSlipState[key];
					}
				);
			}
		}
	};

	public redirectToLogin = (redirectToCurrentUrl = false) => {
		let url = "";
		if (redirectToCurrentUrl) {
			const currentUrl = window.location.pathname.slice(3);
			url = `/${getCurrentCulture()}/auth/login?returnUrl=${currentUrl}`;
		} else {
			url = `/${getCurrentCulture()}/auth/login?returnUrl=/bet-slip`;
		}

		App.state.redirect(url);
	};

	//#endregion helpers

	//#region sessionStorageSync

	private storeReactions: IReactionDisposer[] = [];

	@action.bound
	private startReactions() {
		this.stopStoreReactions();

		// prettier-ignore
		this.storeReactions.push(reaction(
			() => this.userStore.user?.stakeAmount || WEBPACK_DEFAULT_STAKE,
			(newStake) => {
				runInAction(() => {
					this.betSlipState.initialState.payment = newStake;
					this.betSlipState.initialState.totalStake = newStake;
					this.betSlipState.initialState.paymentPerCombination = newStake;

					if(this.betSlipState.eventCount === 0) {
						this.betSlipState.payment = newStake;
						this.betSlipState.paymentPerCombination = newStake;
						this.betSlipState.totalStake = newStake;
					}
				}) 
			},{
				fireImmediately: true
			}
		));

		// prettier-ignore
		this.storeReactions.push(autorun(() => {
			if (this.betSlipState.slipSize === 1) {
				var lastOffer = this.betSlipState.slip.betSlipOffers[0];
				const isExcluded = lastOffer.isExcluded;
				if (isExcluded) {
					this.betSlipState.toggleTipExclude(
						lastOffer.bettingOfferId
					);
				}
			}
		}));

		// prettier-ignore
		this.storeReactions.push(autorun(() => {
			if(
				this.isSlipEmpty &&
				this.betSlipBetType !== BetSlipBetType.OneClickBet &&
				!this.isOneClickBetActive
			) {
				SessionStorageProvider.set("slip", "{}");
			} else{
				SessionStorageProvider.set(
					"slip",
					JSON.stringify(this.betSlipState.slip)
				);
			}
		}));

		// prettier-ignore
		this.storeReactions.push(autorun(() => {
			SessionStorageProvider.set(
				"type",
				JSON.stringify(this.betSlipState.betSlipType)
			);
		}));

		this.storeReactions.push(
			autorun(() => {
				sessionStorage.setItem(
					"payment_type",
					JSON.stringify(this.betSlipState.paymentType)
				);
			})
		);
		// prettier-ignore
		this.storeReactions.push(autorun(() => {
			if (this.betSlipState.availableCombinations == null) {
				SessionStorageProvider.set("system", JSON.stringify([]));
			} else {
				SessionStorageProvider.set(
					"system",
					JSON.stringify(this.betSlipState.availableCombinations)
				);
			}
		}));

		// prettier-ignore
		this.storeReactions.push(autorun(() => {
			if (this.betSlipState.selectedCombinations == null) {
				SessionStorageProvider.set("ssystem", JSON.stringify([]));
			} else {
				SessionStorageProvider.set(
					"ssystem",
					JSON.stringify(this.betSlipState.selectedCombinations)
				);
			}
		}));

		// prettier-ignore
		this.storeReactions.push(autorun(() => {
			SessionStorageProvider.set(
				"betslip-accept-odds-change",
				JSON.stringify(this.acceptOddsChanges)
			);
		}));

		// prettier-ignore
		this.storeReactions.push(autorun(() => {
			SessionStorageProvider.set(
				"clicked-tips",
				JSON.stringify(this.betSlipState.clickedTips)
			);
		}));

		// prettier-ignore
		this.storeReactions.push(reaction(
			() => 
				this.betSlipState.actionController.actionInProgress ||
				this.betSlipState.isSubmitting,
			(value) => {
				runInAction(() => {
					if(value) {
						this.isSubmitDisabled = true;
					} else {
						this.isSubmitDisabled = false;
					}
				})
			}
		));

		// prettier-ignore
		this.storeReactions.push(reaction(
			() => ({
				isOneClick: this.betSlipState.betSlipBetType === BetSlipBetType.OneClickBet, 
				isLoggedIn: this.userStore.isLoggedIn
			}),
			(arg) => {
				if(arg.isOneClick && arg.isLoggedIn) {
					SessionStorageProvider.set("ocb", "true")
				} else {
					SessionStorageProvider.remove("ocb")
				}
			}
		));
	}

	@action.bound
	private stopStoreReactions() {
		for (const disposer of this.storeReactions) {
			disposer();
		}
	}

	//#endregion sessionStorageSync

	//#region disposers

	@action.bound
	public onDispose() {
		this.betSlipState.onDispose();
		this.stopStoreReactions();
		clearInterval(this.countdownInterval);
	}

	@action.bound
	public onResetSlip() {
		this.betSlipState.reset({ resetSubmitDelay: false });

		runInAction(() => {
			this.cancelBetSlipReset();
			this.fullErrorMessage = "";
		});
	}

	@action.bound
	public resetBetSlipTypeToDefault() {
		this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.Normal);
	}

	//#endregion disposers
}
