import {
	observable,
	computed,
	action,
	runInAction,
	reaction,
	Reaction,
	IReactionDisposer,
} from "mobx";
import { DateTime } from "luxon";

import { ReportApiService } from "@api-services/reports/ReportApiService";
import { UserTypes } from "@utils";
import RootStateStore from "@state/stores/RootStore";
import GamingHistoryStore from "@v2/state/gaming-history/GamingHistoryStore";

// @ts-expect-error
import MyBetsStore from "./MyBetsStore.ts";
import { GamingActivityReviewReportDto } from "@api-types/reports/ReportResultDto";

export class GamingHistoryReportStore {
	rootStore: RootStateStore;
	gamingHistoryStore: GamingHistoryStore;
	filterReaction: IReactionDisposer | null;

	constructor(rootStore: RootStateStore, gamingHistoryStore: MyBetsStore) {
		this.rootStore = rootStore;
		this.gamingHistoryStore = gamingHistoryStore;
	}

	//#region observables

	@observable gamingHistoryReport: GamingActivityReviewReportDto | null =
		null;

	@observable from: string | null = null;
	@observable to: string | null = null;

	//#endregion observables

	//#region actions

	@action.bound
	async fetchReports(
		filter: {
			from: string | null | undefined;
			to: string | null | undefined;
		},
		walletOwners: UserTypes[] = [UserTypes.PRIMARY]
	) {
		const accountOwner = walletOwners[0];

		const gamingHistoryReport =
			await ReportApiService.getGamingHistoryReport(
				filter.from as string,
				filter.to as string,
				accountOwner
			);

		const from = DateTime.fromISO(filter.from as string).toLocaleString(
			DateTime.DATE_SHORT
		);
		const to = DateTime.fromISO(filter.to as string).toLocaleString(
			DateTime.DATE_SHORT
		);

		runInAction(() => {
			this.from = from;
			this.to = to;
			this.gamingHistoryReport = gamingHistoryReport
				?.result?.[0] as GamingActivityReviewReportDto;
		});
	}

	//#endregion actions

	//#region disposers

	@action.bound
	onDispose() {
		this.disposeFilterReaction();
		this.gamingHistoryReport = null;
	}

	@action.bound
	disposeFilterReaction() {
		if (this.filterReaction != null) {
			this.filterReaction();
			this.filterReaction = null;
		}
	}

	//#endregion disposers
}
