import {
	UserAccountLockDurationTypesModel,
	UserAccountLockReasonTypesModel,
} from "@api-types";

import { PlayerPromoSendingMode } from "@api-types/user/AgencyUserDto";

import {
	AgencyUserIdentifiers,
	UserLockSettingsDto,
} from "@api-types/locks/UserLockSettingsDto";
import { DateTime } from "luxon";

export class AccountClosureViewModel {
	data: AccountClosureUpdateModelData;
	durationTypes: UserAccountLockDurationTypesModel[];
	reasonTypes: UserAccountLockReasonTypesModel[];
	isLocked: boolean;
	isPermanentLocked: boolean;
	isAdminLock: boolean;
	accountLockStartDate?: DateTime;
	accountLockEndDate?: DateTime;
	accountLockDurationTypeId?: DateTime;
	playerPromoSendingMode: PlayerPromoSendingMode;
}

export class AccountClosureUpdateModelData {
	explanation: string;
	lockDuration: string;
	lockReason: string;
	isPromoApproved: PlayerPromoSendingMode;
	password: string;
}
export class AccountClosureUpdateRequestDataModel extends AccountClosureUpdateModelData {
	agencyUserIds: AgencyUserIdentifiers;
}

export class AccountLockLookupsInitialDataModel {
	durationAbrvs: string[];
	reasonAbrvs: string[];
}

export class AccountLockWithPromoMatterialsViewModel extends UserLockSettingsDto {
	isPromoApproved?: PlayerPromoSendingMode;
}
