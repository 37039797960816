import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { Modal } from "@v2/components/shared";
import { useFixBody } from "@v2/hooks/shared";
import { MyBetsStoreContext } from "../../../../context";
import { ISOToDisplayCode, getDisplayedAmount } from "@utils";

export default observer(function CashoutHistoryModal() {
	const store = useContext(MyBetsStoreContext);
	const { t } = useTranslation();
	useFixBody(true, store?.cashoutStore.isCashoutHistoryOpen);

	if (
		store == null ||
		!store.cashoutStore.isCashoutHistoryOpen ||
		store.cashoutStore.cashoutHistory == null
	) {
		return null;
	}

	return (
		<Modal>
			<div>
				<div className="popup">
					<div className="popup__card popup__card--lrg">
						<div className="popup__header">
							<div className="popup__header__title">
								<span className="u-mar--right--micro">
									{t<string>("MY_BETS.ITEM.CASHOUT_HISTORY")}:{" "}
								</span>
								<span className="u-mar--right--xsml">
									{t<string>(
										"MY_BETS.CASHOUT.BET_SLIP_NUMBER",
										{
											0: store.cashoutStore
												.cashoutHistorySlipId,
										}
									)}
								</span>

								<span
									className={
										"card--bet__status tag tag--med tag--bet tag--bet--" +
										store.cashoutStore.bet?.betStatus.abrv
									}
								>
									{store.cashoutStore.bet?.betStatus.name}
									{store.cashoutStore.bet?.isLive && (
										<>
											{" "}
											-{" "}
											{t<string>(
												"MY_BETS.ITEM.LIVE_SCORE"
											).toUpperCase()}
										</>
									)}
									{store.cashoutStore.bet?.isBonus && (
										<> ({t<string>("COMMON.BONUS")})</>
									)}
								</span>
							</div>
						</div>
						<CashoutHistoryBody />
						<CloseCashoutHistory />
					</div>
				</div>
			</div>
		</Modal>
	);
});

function CashoutHistoryBody() {
	return (
		<div className="popup__body">
			<table className="table--primary">
				<thead className="table--primary__header table--primary__header--light">
					<CashoutHistoryTableHeaderRow />
				</thead>
				<tbody className="table--primary__body">
					<CashoutHistoryTableRows />
				</tbody>
			</table>
		</div>
	);
}

function CashoutHistoryTableHeaderRow() {
	const { t } = useTranslation();

	// TODO translation for header

	return (
		<tr className="table--primary__header__row">
			<th className="table--primary__header__data u-type--left">
				{t<string>("MY_BETS.ITEM.BET_SLIP_COMBINATIONS")}
			</th>
			<th className="table--primary__header__data u-type--left">
				{t<string>("MY_BETS.ITEM.INFO_DATE")}
			</th>
			<th className="table--primary__header__data u-type--right">
				{t<string>("MY_BETS.ITEM.CASHOUT_VALUE")}
			</th>
		</tr>
	);
}

const CashoutHistoryTableRows = observer(function CashoutHistoryTableRows() {
	const store = useContext(MyBetsStoreContext);

	if (store == null) {
		return null;
	}

	return (
		<>
			{store.cashoutStore.cashoutHistory?.betSlipCashoutHistoryValues.map(
				(value, index) => (
					<CashoutHistoryTableRow
						key={value.timestamp}
						count={index + 1}
						rowData={value}
					/>
				)
			)}
		</>
	);
});

const CashoutHistoryTableRow = observer(function CashoutHistoryTableRow(props: {
	count: number;
	rowData: { timestamp: string; cashoutValue: number };
}) {
	const time = DateTime.fromISO(props.rowData.timestamp, {
		zone: "utc",
	})
		.toLocal()
		.toLocaleString(DateTime.DATETIME_SHORT);

	return (
		<tr className="table--primary__body__row">
			<td className="table--primary__body__data u-type--left">
				{props.count}
			</td>
			<td className="table--primary__body__data u-type--left">{time}</td>
			<td className="table--primary__body__data u-type--right">
				{getDisplayedAmount(
					props.rowData.cashoutValue,
					ISOToDisplayCode(WEBPACK_DEFAULT_CURRENCY)
				)}
			</td>
		</tr>
	);
});

function CloseCashoutHistory() {
	const store = useContext(MyBetsStoreContext);
	const t = useTranslation().t;

	if (store == null) {
		return null;
	}

	return (
		<div className="popup__footer">
			<button
				className="popup__footer__btn btn btn--tny btn--secondary--inverted"
				type="button"
				onClick={store.cashoutStore.closeCashoutHistory}
			>
				{t<string>("COMMON.CLOSE")}
			</button>
		</div>
	);
}
