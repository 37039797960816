import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	legalLockTranslationKeys,
	mapArray,
} from "@v2/helpers/account-settings/account-lock/utils";

import { isNullOrWhitespace } from "@v2/helpers/utility";
import { OasisPersonalDetails } from ".";
import {
	FormSelectField,
	PasswordInput,
	CheckboxArrayField,
} from "../../shared/inputs";
import FormButton from "@components/buttons/FormButton";
import { useOasisLock } from "@v2/hooks/account-settings/account-lock";
import { setTabTitle } from "@utils";

export default function OasisLockView() {
	const { handleSubmit } = useFormContext();
	const { t } = useTranslation();

	const { onInitialize, onSuccess, lookups, fetchedData } = useOasisLock();
	useEffect(() => {
		setTabTitle(
			t("USER.ACCOUNT_SETTINGS.NAVIGATION.ACCOUNT_CLOSURE_OASIS")
		);

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
		onInitialize();
	}, []);

	return (
		<form className="form" method="post" onSubmit={handleSubmit(onSuccess)}>
			<div className="card--primary__body">
				<div className="col col-sml-12 form__field">
					<div className="message message--base message--note">
						<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
						<div className="message__content">
							<ul className="list--unordered">
								<li>
									{t(
										"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.OASIS_LOCK_NO_LOCK"
									)}
								</li>
								<li>
									{legalLockTranslationKeys
										.map((key) => t(key))
										.join(" ")}
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="form__group">
					<div className="row">
						<FormSelectField
							label={t<string>(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_DURATION_LABEL"
							)}
							placeholder={t<string>(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_DURATION_PLACEHOLDER"
							)}
							name="lockDuration"
							className="col col-sml-12 form__field"
							dropdownData={mapArray(lookups.lockDurationTypes)}
							required
						/>

						<CheckboxArrayField
							arrayList={lookups.lockReasonTypes}
							disabledOption={
								fetchedData.blockReasonsDisableOption
							}
							checkboxFieldsLabel={
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_REASON_LABEL"
							}
							name="reasonCodes"
							required
						/>
					</div>
				</div>

				{fetchedData.isPersonalDetailsMissing &&
					fetchedData.personalDetails != null && (
						<OasisPersonalDetails
							personalDetails={fetchedData.personalDetails}
							countries={fetchedData.countries}
						/>
					)}

				<div className="form__group">
					<div className="row">
						<PasswordInput
							name="password"
							className="col col-sml-12 form__field"
							label={t<string>(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.PASSWORD_CONF_LABEL"
							)}
							placeholder={t<string>(
								"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.PASSWORD_CONF_PLACEHOLDER"
							)}
							validate={{
								isNullOrWhitespace: (value: string) =>
									isNullOrWhitespace(value) !== true ||
									"COMMON.REQUIRED_FIELD",
							}}
							required
						/>
					</div>
				</div>
			</div>

			<div className="card--primary__footer">
				<FormButton
					className="card--primary__footer__btn btn--lrg btn--primary btn--animate-grow"
					btnText="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.OASIS_LOCK_SAVE_BUTTON"
				/>
			</div>
		</form>
	);
}
