import { action, observable, computed, reaction } from "mobx";
import { ResultsDataStoreMobile } from "./index";
import ResultsService from "@services/results/ResultsService.mobile";
import RootOfferStore from "@offer/stores/RootStore";
import {
	ResultMenuCategory,
	ResultMenuSport,
	ResultMenuTournament,
} from "@data-types/results";

export class ResultsMenuStoreMobile {
	rootStore: RootOfferStore;
	resultsDataStore: ResultsDataStoreMobile;

	@observable resultMenu: ResultMenuSport[] | null;
	@observable selectedSport: ResultMenuSport | null = null;
	@observable selectedCategory: ResultMenuCategory | null = null;
	@observable selectedTournament: ResultMenuTournament | null = null;

	constructor(rootStore: RootOfferStore) {
		this.rootStore = rootStore;
		this.resultsDataStore = new ResultsDataStoreMobile(rootStore, this);
	}

	@action.bound
	async onInitialize() {
		const resultMenuRequest = await ResultsService.getMenu({
			previousDays: 7,
		});

		if (resultMenuRequest.success) {
			this.resultMenu = resultMenuRequest.data;
		}
	}

	@action.bound
	setSelectedTournament(tournament: ResultMenuTournament) {
		this.selectedTournament = tournament;
	}

	@action.bound
	setSelectedCategory(category: ResultMenuCategory) {
		this.selectedCategory = category;
	}

	@action.bound
	setSelectedSport(sport: ResultMenuSport) {
		this.selectedSport = sport;
	}

	@action.bound
	goBack() {
		if (this.selectedTournament != null) {
			this.selectedTournament = null;
			this.resultsDataStore.onReset();
		} else if (this.selectedCategory != null) {
			this.selectedCategory = null;
			this.resultsDataStore.onReset();
		} else if (this.selectedSport != null) {
			this.selectedSport = null;
		} else if (
			this.rootStore.searchBarViewStore.persistentTerm != null &&
			this.rootStore.searchBarViewStore.persistentTerm !== ""
		) {
			this.rootStore.searchBarViewStore.onReset();
			this.resultsDataStore.onReset();
		} else {
			App.state.history.goBack();
		}
	}
}
