import React from "react";
import { observer } from "mobx-react";
import { useRootAppStore } from "@hooks";
import { useTranslation } from "react-i18next";
import {
	ChatHeader,
	ChatContent,
	ChatNameUpdate,
} from "@features/chat/desktop";

export default observer(function ChatBox() {
	const { chatViewStore } = useRootAppStore();
	const { t } = useTranslation();

	if (!chatViewStore.chatBoxVisible) {
		return null;
	}

	if (!chatViewStore.chatDisplayName) {
		return <ChatNameUpdate />;
	}

	//not used
	// if (chatViewStore.chatListVisible) {
	// 	return (
	// 		<div className="chat__card">
	// 			<div className="chat__header">
	// 				<div className="chat__info">
	// 					{chatViewStore.chatChannelHandler.activeChannelInfo && (
	// 						<button
	// 							className="btn btn--square btn--square--tny btn--ghost btn--icon btn--icon--left u-padd--x--reset"
	// 							onClick={() =>
	// 								chatViewStore.toggleChatList(false)
	// 							}
	// 						>
	// 							<i className="u-icon u-icon--xsml u-icon--arrow--left" />
	// 						</button>
	// 					)}

	// 					<div className="chat__title">
	// 						<div className="chat__name">
	// 							{t("CHAT.JOINED_CHANNELS")}
	// 						</div>
	// 					</div>
	// 				</div>

	// 				<div className="chat__actions">
	// 					<ChatMinimizeButton />
	// 					<ChatCloseButton />
	// 				</div>
	// 			</div>

	// 			<div
	// 				className="chat__box chat__box__container chat__scroll chat__list"
	// 				// style={{
	// 				// 	flexDirection: "column",
	// 				// 	justifyContent: "normal",
	// 				// }}
	// 			>
	// 				{chatViewStore.chatListHandler.channelList.map(
	// 					(channel) => (
	// 						<button
	// 							key={channel.channelId}
	// 							className="chat__list__item btn btn--base btn--block btn--secondary--inverted btn--icon btn--icon--left"
	// 							onClick={() =>
	// 								chatViewStore.onInitialize(
	// 									ChatEntryPoint.LIST,
	// 									{
	// 										channelId: channel.channelId,
	// 										displayName: channel.displayName,
	// 										sportAbrv: channel.sportAbrv,
	// 										categoryAbrv: channel.categoryAbrv,
	// 										tournamentAbrv:
	// 											channel.tournamentAbrv,
	// 									}
	// 								)
	// 							}
	// 						>
	// 							{channel.displayName == "Global" && (
	// 								<i className="chat__list__icon u-icon u-icon--big u-icon--all" />
	// 							)}

	// 							{channel.displayName != "Global" &&
	// 								channel.sportAbrv && (
	// 									<OfferIcon
	// 										type="sport"
	// 										sport={channel.sportAbrv}
	// 										className={
	// 											"chat__list__icon u-icon u-icon--big"
	// 										}
	// 									/>
	// 								)}

	// 							{channel.displayName}

	// 							{/* {channel.displayName}
	// 							{channel.unreadCount > 0 ? (
	// 								<>- {channel.unreadCount}</>
	// 							) : null} */}
	// 						</button>
	// 					)
	// 				)}
	// 			</div>
	// 		</div>
	// 	);
	// }

	return (
		<>
			<div className="chat__card">
				<ChatHeader />
				<ChatContent />
			</div>
		</>
	);
});
