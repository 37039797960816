import React from "react";
import { observer } from "mobx-react";
import { BetSlipTypes } from "@gp/models";

import { useTranslation } from "react-i18next";
import BetSlipStore from "@v2/state/bet-slip/BetSlipStore";

export default observer(function BetSlipTypeSelector(props: {
	store: BetSlipStore;
}) {
	const { t } = useTranslation();

	return (
		<nav>
			<ul className="tabs--tertiary">
				<li className="tabs--tertiary__item">
					<button
						onClick={(e) =>
							props.store.onBetSlipTypeChange(
								BetSlipTypes.COMBINATION
							)
						}
						className={
							"tabs--tertiary__link" +
							(props.store.betSlipType ===
							BetSlipTypes.COMBINATION
								? " is-active"
								: "")
						}
					>
						{t<string>("BET_SLIP.TYPE.COMBINATION")}
					</button>
				</li>
				<li className="tabs--tertiary__item">
					<button
						onClick={(e) =>
							props.store.onBetSlipTypeChange(BetSlipTypes.SYSTEM)
						}
						className={
							"tabs--tertiary__link" +
							(props.store.betSlipType === BetSlipTypes.SYSTEM
								? " is-active"
								: "")
						}
						disabled={!props.store.isSystemEnabled}
					>
						{t<string>("BET_SLIP.TYPE.SYSTEM")}
					</button>
				</li>
			</ul>
		</nav>
	);
});
