export class GamingHistoryRawFilterData {
	period: string | null;
	from?: string | null;
	to?: string | null;
	casinoGameCategoryId: string | null;
	liveGameTableGroup: string | null;
	roundStatus: string | null;
	onlyWin: boolean | null;
	pageNumber: number;
	embed: string | null;
}
