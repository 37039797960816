import React from "react";
import { DateTime } from "luxon";

import { TimeAgo } from "../../../shared/display";

function DisplayDate(props: { betDateTimeStamp: string }) {
	const betDate = DateTime.fromISO(props.betDateTimeStamp);
	const today = DateTime.now();
	const yesterday = DateTime.now().minus({ days: 1 });

	if (betDate.hasSame(today, "day") || betDate.hasSame(yesterday, "day")) {
		return <TimeAgo datetime={betDate} />;
	} else {
		betDate.toLocaleString();
		return <time>{betDate.toFormat("dd LLL yyyy")}</time>;
	}
}

export default DisplayDate;
