import React, { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ScrollDirectionContext } from "@v2/context";

import { SportOffer } from "@gp/offer";

import { ColumnTypeSelectors } from "../../sport-header/ColumnTypeSelectors";
import { useIsAdditionalOfferVisible } from "@offer/common";
import OfferIcon from "@v2/components/shared/icons/Offer";
import { ChatButton } from "../../buttons";
import { ChannelResourceTypeDto } from "@api-types/chat";

const SportHeader = observer(function PrematchSport_SportHeader(props: {
	isSportCollapsed: boolean;
	setIsSportCollapsed: (value: boolean) => void;
	sport: SportOffer;
}) {
	const { t } = useTranslation();
	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();
	const scrollDirection = useContext(ScrollDirectionContext);

	//#region classNames

	const headerClasses = classNames(
		"header--sport",
		{
			"header--sport--sml": isAdditionalOfferVisible,
			"is-collapsed": props.isSportCollapsed,
		},
		[`is-${scrollDirection}`]
	);

	const toggleClasses = classNames(
		"header--sport__item header--sport__toggle",
		{
			"header--sport__toggle--sml": isAdditionalOfferVisible,
		}
	);

	const toggleCaretClasses = classNames(
		"u-icon u-icon--xsml",
		{
			"u-icon--caret--down": !props.isSportCollapsed,
			"u-icon--caret--right": props.isSportCollapsed,
		},
		"u-color--core--snow u-align--v--middle"
	);

	//#endregion classNames

	return (
		<div className={headerClasses}>
			<div
				className="header--sport__item header--sport__title"
				title={props.sport.name}
			>
				<div className="header--sport__title__inner">
					<OfferIcon
						type="sport"
						sport={props.sport.abrv}
						className="header--sport__icon u-icon u-icon--big"
					/>

					<div>
						{props.sport.name}
						{props.sport.isLive ? (
							<> - {t<string>("OFFER.LIVE")}</>
						) : null}
						{props.sport.isOutright ? (
							<> - {t<string>("OFFER.SPECIAL")}</>
						) : null}
					</div>

					<span className="header--sport__counter counter counter--light counter--base">
						{props.sport.eventCount}
					</span>
				</div>

				<ChatButton
					channelType={ChannelResourceTypeDto.SPORT}
					resourceId={props.sport.id}
					isInSportHeader
				/>
			</div>

			{/* Display selectors only for prematch and live */}
			{!props.sport.isOutright ? <ColumnTypeSelectors /> : null}

			<div className={toggleClasses}>
				<button
					className="btn btn--square btn--square--tny btn--ghost"
					onClick={() =>
						props.setIsSportCollapsed(!props.isSportCollapsed)
					}
					data-type="sport"
				>
					<i className={toggleCaretClasses} />
				</button>
			</div>
		</div>
	);
});

export default SportHeader;
