import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@v2/hooks/shared";
import { ScrollToTop } from ".";
import { getCurrentCulture } from "@utils";
import {
	isLiveCasinoEnabled,
	isPlayerMoneyProtectionEnabled,
	isWhistleblowingEnabled,
} from "@v2/helpers";
import { PaymentOptions } from "./components";
import { isLoginFlow } from "@v2/helpers/membership/utils";
import { LicensingIconsObj } from "@v2/helpers/shared/LicensingIcons/licensingIcons";
import * as Cookie from "vanilla-cookieconsent";

export function Footer() {
	const { t } = useTranslation();
	const { culture } = useAppContext();
	const { pathname } = useLocation();
	const date = new Date();

	if (isLoginFlow(pathname)) {
		return null;
	}

	return (
		<>
			<div className="footer">
				<div className="footer__section">
					<div className="row">
						<div className="col col-sml-12 col-med-12 col-lrg-6 col-xlrg-3">
							<div className="footer__title">
								{t<string>("FOOTER.COMPANY")}
							</div>
							<nav>
								<ul>
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/about-us`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>("FOOTER.ABOUTUS")}
										</Link>
									</li>
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/contact`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>("FOOTER.CONTACT")}
										</Link>
									</li>
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/our-partners`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>("FOOTER.OURPARTNERS")}
										</Link>
									</li>
									<li>
										<a
											className="u-anchor u-anchor--underline u-anchor--primary"
											href="https://franchise.tipwin.com/"
											target="_blank"
										>
											{t<string>("FOOTER.FRANCHISE")}
										</a>
									</li>
									{WEBPACK_IS_AFFILIATE_PROGRAM_ENABLED && (
										<li className="u-mar--top--xtny">
											<a
												className="u-anchor u-anchor--underline u-anchor--primary"
												href="https://affiliate.tipwin.com/"
											>
												{t<string>("FOOTER.AFFILIATE")}
											</a>
										</li>
									)}
								</ul>
							</nav>
						</div>
						<div className="col col-sml-12 col-med-12 col-lrg-6 col-xlrg-3">
							<div className="footer__title">
								{t<string>("FOOTER.INFORMATION")}
							</div>
							<nav>
								<ul>
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/terms-and-conditions`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>("FOOTER.TAC")}
										</Link>
									</li>
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/bonus-terms`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>("FOOTER.BONUS_TERMS")}
										</Link>
									</li>
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/privacy`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>("FOOTER.PRIVACY")}
										</Link>
									</li>
									<button
										type="button"
										className="btn btn--reset btn--ghost u-color--text--secondary u-anchor u-anchor--secondary"
										onClick={() => {
											Cookie.show(true);
										}}
									>
										{t("FOOTER.COOKIE_POLICY")}
									</button>
									{isWhistleblowingEnabled && (
										<li>
											<Link
												to={`/${culture}/info/whistleblowing-channel`}
												className="u-anchor u-anchor--secondary"
											>
												{t<string>(
													"FOOTER.WHISTLEBLOWING_CHANNEL"
												)}
											</Link>
										</li>
									)}
								</ul>
							</nav>
						</div>
						<div className="col col-sml-12 col-med-12 col-lrg-6 col-xlrg-3">
							<div className="footer__title">
								{t<string>("FOOTER.HELP")}
							</div>
							<nav>
								<ul>
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/help`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>("FOOTER.HELP")}
										</Link>
									</li>
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/betting-rules`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>("FOOTER.BET_RULES")}
										</Link>
									</li>
									{isLiveCasinoEnabled ? (
										<>
											<li className="u-mar--bottom--xtny">
												<Link
													to={`/${culture}/info/live-casino-terms`}
													className="u-anchor u-anchor--secondary"
												>
													{t<string>(
														"FOOTER.LIVE_CASINO_TERMS"
													)}
												</Link>
											</li>
											<li className="u-mar--bottom--xtny">
												<Link
													to={`/${culture}/info/live-casino-rules`}
													className="u-anchor u-anchor--secondary"
												>
													{t<string>(
														"FOOTER.LIVE_CASINO_RULES"
													)}
												</Link>
											</li>
										</>
									) : null}
									<li className="u-mar--bottom--xtny">
										<Link
											to={`/${culture}/info/responsible-gaming`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>(
												"FOOTER.RESPONSIBLE_GAMING"
											)}
										</Link>
									</li>
									<li className="u-mar--top--xtny">
										<Link
											to={`/${culture}/info/payment-options`}
											className="u-anchor u-anchor--secondary"
										>
											{t<string>(
												"FOOTER.PAYMENT_OPTIONS"
											)}
										</Link>
									</li>
									{isPlayerMoneyProtectionEnabled && (
										<li className="u-mar--top--xtny">
											<Link
												to={`/${culture}/info/player-money-protection`}
												className="u-anchor u-anchor--secondary"
											>
												{t<string>(
													"specific:FOOTER.PLAYER_MONEY_PROTECTION"
												)}
											</Link>
										</li>
									)}
								</ul>
							</nav>
						</div>
						<div className="col col-sml-12 col-med-12 col-lrg-6 col-xlrg-3">
							<div className="footer__title">
								{t<string>("FOOTER.FOLLOWUS")}
							</div>
							<nav>
								<ul>
									<li className="u-mar--bottom--xtny">
										<a
											href="https://www.facebook.com/TipwinSport/"
											className="u-anchor u-anchor--secondary"
											target="_blank"
										>
											<i className="u-icon u-icon--med u-icon--facebook u-mar--right--tny" />
											{t<string>("Facebook")}
										</a>
									</li>
									<li className="u-mar--bottom--xtny">
										<a
											href="https://twitter.com/TipWinCompany"
											className="u-anchor u-anchor--secondary"
											target="_blank"
										>
											<i className="u-icon u-icon--med u-icon--twitter u-mar--right--tny" />
											{t<string>("Twitter")}
										</a>
									</li>
									<li className="u-mar--bottom--xtny">
										<a
											href="https://www.instagram.com/tipwinofficial/"
											className="u-anchor u-anchor--secondary"
											target="_blank"
										>
											<i className="u-icon u-icon--med u-icon--instagram u-mar--right--tny" />
											{t<string>("Instagram")}
										</a>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>

				<div className="footer__section footer__section--bordered">
					<div className="row">
						<div className="col col-sml-12 col-med-12 col-lrg-12 col-xlrg-6">
							<div className="footer__title">
								{t<string>("FOOTER.SECURITY_AND_TRUST")}
							</div>
							<div className="footer__icons">
								<i
									className="footer__icons__item u-icon u-icon--bv-iso-cert"
									data-cert-no="IND17.0542/U"
								/>
								{WEBPACK_LICENSING_ICONS.map(
									(icon: keyof typeof LicensingIconsObj) => (
										<a
											key={icon}
											href={LicensingIconsObj[icon].url}
											className="footer__icons__item"
											rel="nofollow"
											target="_blank"
										>
											<i
												className={`u-icon u-icon--${LicensingIconsObj[icon].icon}`}
											/>
										</a>
									)
								)}
								<i className="footer__icons__item u-icon u-icon--lrg u-icon--age-restriction u-color--ui--danger" />
							</div>
						</div>

						<div className="col col-sml-12 col-med-12 col-lrg-12 col-xlrg-4">
							<PaymentOptions />
						</div>

						<div className="col col-sml-12 col-med-12 col-lrg-12 col-xlrg-6">
							<div className="footer__title">
								{t<string>("FOOTER.OURPARTNERS")}
							</div>
							<a
								className="footer__partner u-mar--bottom--xsml"
								title="Bayer 04 Leverkusen"
								href={`/${getCurrentCulture()}/info/our-partners`}
							>
								<i className="footer__partner__icon u-icon u-icon--xxxlrg u-icon--partner u-icon--partner--bayer" />
								<div className="footer__partner__text">
									<div className="u-type--xxsml u-color--text--tertiary">
										{t<string>("FOOTER.PREMIUM_PARTNER")}
									</div>
									<div className="u-type--xsml">
										BAYER 04 LEVERKUSEN
									</div>
								</div>
							</a>
							<a
								href={`/${getCurrentCulture()}/info/our-partners`}
								className="footer__partner"
								title={t("specific:FOOTER.DBB_NAME")}
							>
								<i className="footer__partner__icon u-icon u-icon--xxxlrg u-icon--partner u-icon--partner--dbb" />
								<div className="footer__partner__text">
									<div className="u-type--xxsml u-color--text--tertiary">
										{t<string>("FOOTER.PREMIUM_PARTNER")}
									</div>
									<div className="u-type--xsml">
										{t<string>("specific:FOOTER.DBB_NAME")}
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>

				<div className="footer__section footer__section--bordered u-type--xsml">
					<div className="u-mar--bottom--xsml">
						<span className="u-mar--right--micro u-type--wgt--bold">
							{t<string>("FOOTER.WARNING_LABEL")}
						</span>
						{t<string>("specific:FOOTER.WARNING_TEXT")}
					</div>
					<div>
						{t("specific:FOOTER.OWNED", {
							interpolation: {
								escapeValue: false,
							},
						})}
					</div>
				</div>

				<div className="footer__section footer__section--bordered u-type--xsml">
					&#169;
					{t<string>("specific:FOOTER.ALL_RIGHTS_RESERVED", {
						0: date.getFullYear(),
					})}{" "}
					{t<string>("specific:FOOTER.FOOTER_RIGHTS_RESERVED")}
				</div>
			</div>

			<ScrollToTop />
		</>
	);
}
