import React from "react";
import { observer } from "mobx-react";
import { useRootAppStore } from "@hooks";

type Props = {
	sportAbrv: string;
	isActive: boolean;
	iconClassName: string;
};

export default observer(function ChatSportIcon(props: Props) {
	const {
		themeStore: { isLightTheme },
	} = useRootAppStore();

	const fallbackImagePath = "/assets/images/sportFallback.svg";

	const [SRC, setSrc] = React.useState(
		createSource({ ...props, isLightTheme })
	);

	const [isLoading, setLoadingState] = React.useState(true);

	const [errorCount, setErrorCount] = React.useState(0);

	React.useEffect(() => {
		if (errorCount == 0) {
			setSrc(createSource({ ...props, isLightTheme }));
		}
	}, [props.isActive, isLightTheme]);

	const handleError = () => {
		setErrorCount((prev) => prev + 1);
		setSrc(fallbackImagePath);
	};

	return (
		<img
			className={props.iconClassName}
			src={SRC}
			style={isLoading ? { opacity: "0" } : {}}
			onError={handleError}
			onLoad={() => setLoadingState(false)}
		/>
	);
});

function createSource(props: Props & { isLightTheme: boolean | null }) {
	let attributes = "";

	if (!props.isActive) {
		attributes += "--mono";
	}

	if (props.isLightTheme) {
		attributes += "--light";
	} else {
		attributes += "--dark";
	}

	return `${WEBPACK_CDN_ROOT_URL}/web/chat/channels/${props.sportAbrv}/${props.sportAbrv}${attributes}.svg`;
}
