import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { PasswordConfirmation } from "@v2/components/desktop/shared/password-confirmation";
import { mapDataForPC } from "@v2/helpers/account-settings/email-change/utils";
import { EmailChangeFormSection } from "./";
import useEmailChange from "@v2/hooks/account-settings/email-change/useEmailChange";

export default function EmailChangeContent() {
	const { getValues } = useFormContext();

	const urlParams = new URLSearchParams(App.state.history.location.search);

	const {
		onCancel,
		fetchOrActivateUserEmail,
		onSuccess,
		showPasswordConfirmation,
		setShowPasswordConfirmation,
	} = useEmailChange();

	useEffect(() => {
		fetchOrActivateUserEmail(urlParams.get("mailkey") as string);

		return () => setShowPasswordConfirmation(false);
	}, []);

	return (
		<div className="l--content__main">
			<div className="container">
				{!showPasswordConfirmation ? (
					<EmailChangeFormSection />
				) : (
					<div className="form form--grid card--primary">
						<PasswordConfirmation
							data={mapDataForPC(getValues("newEmail"))}
							onSuccess={onSuccess}
							onCancel={onCancel}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
