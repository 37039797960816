import { UserTypes, getApplicationHeaders, httpClient } from "@utils";

export default class BrowserCloseApiSerivce {
	private static get baseUrl(): string {
		return `${WEBPACK_BASE_ADDRESS}platform/gp/login/browser-close`;
	}

	private static getRequestHeaders = (owner = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(owner);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async notifyCloseWebsite() {
		const url = `${this.baseUrl}`;
		return httpClient.post(url, null, this.getRequestHeaders(), {
			responseType: "json",
			keepalive: true,
		});
	}
}
