import { LoginResponseUser } from "@api-types/user/LoginResponseDto";
import { useRootAppStore } from "@hooks";
import { StorageStateKeysEnum } from "@utils/storage";
import { useEffect } from "react";

export function useNewRelicHandler(error: Error) {
	const rootStore = useRootAppStore();
	useEffect(() => {
		const user = JSON.parse(
			//@ts-expect-error
			rootStore.localStorageProvider.get(StorageStateKeysEnum.USER_KEY)
		) as LoginResponseUser;
		const secondaryUser = JSON.parse(
			//@ts-expect-error
			rootStore.localStorageProvider.get(
				StorageStateKeysEnum.SECONDARY_USER
			)
		) as LoginResponseUser;

		// web logging
		if (window.newrelic) {
			const newrelicInteraction = newrelic.interaction();
			if (user) {
				newrelicInteraction.setAttribute("userId", user.id);
			}
			if (secondaryUser) {
				newrelic
					.interaction()
					.setAttribute("userIdSecond", secondaryUser.id);
			}
			window.newrelic.noticeError(error);
		}
	}, []);
}
