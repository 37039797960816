import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useRootAppStore } from "@hooks";

export default observer(function ChatUserActionsButton() {
	const [isHover, setIsHover] = useState(false);

	const { t } = useTranslation();
	const { chatViewStore } = useRootAppStore();

	const { leaveChannelDropdownOpened, setLeaveChannelDropdownOpened } =
		chatViewStore;

	const toggleMuteDropdown = () => {
		setLeaveChannelDropdownOpened(!leaveChannelDropdownOpened);
	};

	const handleButtonClick = () => {
		toggleMuteDropdown();
		setIsHover((prevIsHover) => !prevIsHover);
	};

	const iconClasses = classNames(
		"u-icon u-icon--xsml u-color--text--primary",
		{
			"u-icon--chat-menu": !isHover,
			"u-icon--chat-menu--selected": isHover,
		}
	);

	return (
		<>
			<button
				className="chat__btn--context btn btn--square btn--square--tny btn--icon btn--secondary--inverted"
				onClick={handleButtonClick}
			>
				<i className={iconClasses} />
			</button>

			{leaveChannelDropdownOpened && (
				<div className="chat__options">
					<button
						onClick={() => {
							setLeaveChannelDropdownOpened(false);
							chatViewStore.leaveCurrentChannel();
						}}
						className="btn btn--base btn--block btn--icon btn--secondary--inverted"
					>
						<i className="u-icon u-icon--sml u-icon--chat--leave" />
						<span className="u-color--ui--danger">
							{t("CHAT.LEAVE_CHAT")}
						</span>
					</button>
				</div>
			)}
		</>
	);
});
