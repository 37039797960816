import { LockResponseDto } from "@api-types/locks/LockResponseDto";
import { action, observable } from "mobx";
import BasePopupStore from "./BasePopupStore";
import { logger, localizationService } from "@state";
import RootAppStore from "@state/stores/RootStore";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const TwAgencyUserLocksApiService = lazy(loadFailPath, ()=>import("@api-services/locks/TwAgencyUserLocksApiService"));

export default class User24hLockStore extends BasePopupStore {
	@observable responseData: LockResponseDto | null;
	constructor(rootStore: RootAppStore) {
		super(rootStore);
	}
	@action.bound
	async lockUser() {
		try {
			this.responseData = await (
				await TwAgencyUserLocksApiService
			).default.oasisTemporaryLock();
			await App.state.rootStore.userAuthStore.logoutUser();
			this.activatePopup();
		} catch (error) {
			console.error(error);
			logger.logError(error);
			App.state.rootStore.notificationStore.error(
				localizationService.t(
					"USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"
				)
			);
		}
	}
}
