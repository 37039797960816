import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import {
	SportOffer,
	TournamentOffer,
	SportCategoryOffer,
	getEventSortByFavorite,
	EventOffer,
} from "@gp/offer";
import { SportOfferContext, EventContext } from "@gp/components";

import { Event, EventOutright } from "../../event-row";

import { TournamentHeader } from "./";
import { useIsAdditionalOfferVisible } from "@offer/common";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { Sorter } from "@gp/utility";

export const TournamentEvents = observer(function TournamentEvents(props: {
	sport: SportOffer;
	category: SportCategoryOffer;
	tournament: TournamentOffer;
	hidden?: boolean;
}) {
	const [hidden, setHidden] = useState(false);

	const isAdditionalOfferVisible = useIsAdditionalOfferVisible();

	const tableClasses = classNames("offer", {
		"offer--sports": !props.sport.isLive,
		"offer--sports--live": props.sport.isLive,
		"offer--full": !isAdditionalOfferVisible,
		"offer--sml": isAdditionalOfferVisible,
		"is-collapsed": props.hidden, // !isTournamentShown
	});

	return (
		<div className={tableClasses}>
			<TournamentHeader
				sport={props.sport}
				category={props.category}
				tournament={props.tournament}
				setHidden={setHidden}
				hidden={hidden}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
			/>
			<TournamentBody tournament={props.tournament} hidden={hidden} />
		</div>
	);
});

function TournamentBody(props: {
	tournament: TournamentOffer;
	hidden: boolean;
}) {
	if (props.hidden) {
		return null;
	}

	return (
		<div className="offer__body">
			<TournamentEventsList tournament={props.tournament} />
		</div>
	);
}

const TournamentEventsList = observer(function TournamentEventsList(props: {
	tournament: TournamentOffer;
}) {
	const sport = useContext(SportOfferContext);
	const favoritesStore = useContext(FavoritesStoreContext);
	const events =
		favoritesStore.userFavoriteEventsSet.size > 0
			? props.tournament.events.sort(
					Sorter.sort((a, b) => {
						return Sorter.sort(
							(a1: EventOffer, b1: EventOffer) =>
								getEventSortByFavorite(a1, b1, favoritesStore),

							"startTime"
						)(a, b);
					})
			  )
			: props.tournament.events;
	return (
		<>
			{events.map((event, index) => (
				<EventContext.Provider key={event.id} value={event}>
					{sport.isOutright ? (
						<EventOutright />
					) : (
						<Event
							noSecondaryOffer={!event.isLive}
							showSecondaryOffer={event.isLive}
							isAlternate={index % 2 === 0}
						/>
					)}
				</EventContext.Provider>
			))}
		</>
	);
});
