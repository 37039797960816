import { useContext } from "react";
import { LoaderGetterContext, LoaderSetterContext } from "@context";

export function useLoading() {
	const loaderSetterCtx = useContext(LoaderSetterContext);
	const loaderGetterCtx = useContext(LoaderGetterContext);

	return {
		setIsLoading: (condition: boolean) => {
			loaderSetterCtx.setIsLoading(condition);
		},
		isLoading: loaderGetterCtx.isLoading,
	};
}
