import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
	RequiredMark,
	ErrorMessage,
} from "@v2/components/desktop/shared/inputs";
import { TitleDataType } from "@data-types/membership/registration";

export default function RegistrationTitle(props: {
	titleData: TitleDataType[] | null;
}) {
	const { titleData } = props;

	const { t } = useTranslation();

	if (titleData == null || titleData.length === 0) {
		return null;
	}

	return (
		<div className="form__field">
			<label className="label label--strong">
				{t("MEMBERSHIP.REGISTRATION.TITLE_LABEL")}
				<RequiredMark required />
			</label>
			<ErrorMessage name={"titleId"} />

			<div className="row">
				{titleData?.reverse().map((data) => (
					<RadioInput
						key={data.id}
						id={data.id}
						name="titleId"
						label={data.name}
						value={data.id}
						title={data.abrv}
					/>
				))}
			</div>
		</div>
	);
}

function RadioInput(props: {
	id: string;
	name: string;
	label: string;
	value: string;
	title: string;
}) {
	const { id, name, label, value, title } = props;

	const { t } = useTranslation();
	const { register } = useFormContext();

	const field = register(name, {
		required: {
			value: true,
			message: "COMMON.REQUIRED_FIELD",
		},
	});

	const inputClasses = classNames("input--radio input--radio--title", [
		`input--radio--${title}`,
	]);

	return (
		<div className="col col-sml-6">
			<input
				id={id}
				className={inputClasses}
				type="radio"
				value={value}
				{...field}
			/>
			<label htmlFor={id}>{t(label)}</label>
		</div>
	);
}
