import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useRootAppStore } from "@hooks";
import { useFixBody } from "@v2/hooks";
import { FailedLogin } from "@api-types/user/FailedLoginDataDto";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default observer(function FailedLoginsPopup() {
	const { failedLoginsCheckPopupStore } = useRootAppStore();
	const { t } = useTranslation();
	useFixBody(true, failedLoginsCheckPopupStore.isActive);

	useEffect(() => {
		failedLoginsCheckPopupStore.onInitialize();

		return () => {
			failedLoginsCheckPopupStore.onDispose();
		};
	}, []);

	if (failedLoginsCheckPopupStore.isFetchingDataOnOpen) {
		//data isnt fetched yet, dont display popup
		return null;
	}

	return (
		<div className="at-failedLogins popup">
			<div className="popup__card">
				<div className="popup__header">
					{t("specific:USER.CHECK_FAILED_LOGINS.FAILED_LOGINS_TITLE")}
				</div>
				<FailedLoginsPopupContent
					failedLoginData={
						failedLoginsCheckPopupStore.failedLoginData
					}
					reportFromDate={failedLoginsCheckPopupStore.reportFromDate}
				/>

				<div className="popup__footer">
					<button
						type="button"
						className="at-failedLoginsBtnClose popup__footer__btn btn btn--sml btn--secondary--inverted"
						onClick={failedLoginsCheckPopupStore.togglePopup}
					>
						{t("COMMON.CLOSE")}
					</button>
				</div>
			</div>
		</div>
	);
});

function FailedLoginsPopupContent(props: {
	failedLoginData: FailedLogin[] | null;
	reportFromDate: string | null;
}) {
	const { t } = useTranslation();
	const { failedLoginData, reportFromDate } = props;

	if (failedLoginData && failedLoginData?.length > 0 && reportFromDate) {
		return (
			<div className="popup__body">
				<FailedLoginsFromTimeMessage time={reportFromDate} />
				<FailedLoginsTable failedLoginData={failedLoginData} />
			</div>
		);
	} else if (failedLoginData?.length == 0) {
		return (
			<div className="popup__body">
				<InfoMessageComponent
					type="note"
					message={t(
						"specific:USER.CHECK_FAILED_LOGINS.NO_LOGIN_ATTEMPTS"
					)}
				/>
			</div>
		);
	}

	return (
		<div className="popup__body">
			<InfoMessageComponent
				type={"danger"}
				message={t("COMMON.ERROR_MESSAGES.DEFAULT")}
			/>
		</div>
	);
}

function FailedLoginsFromTimeMessage(props: { time: string }) {
	const { time } = props;
	const { t } = useTranslation();
	return (
		<div className="row u-mar--bottom--xsml">
			<div className="col col-sml-6">
				{t(
					"specific:USER.CHECK_FAILED_LOGINS.FAILED_LOGIN_ATTEMPTS_FROM"
				)}
			</div>
			<div className="col col-sml-6 u-type--color--primary">
				<strong>{time}</strong>
			</div>
		</div>
	);
}

function FailedLoginsTable(props: { failedLoginData: FailedLogin[] }) {
	const { t } = useTranslation();
	const { failedLoginData } = props;

	return (
		<table className="table--primary u-mar--bottom--xsml u-type--base">
			<thead className="table--primary__header table--primary__header--light">
				<tr className="table--primary__header__row">
					<th className="table--primary__header__data">
						{t("specific:USER.CHECK_FAILED_LOGINS.TIME_TITLE")}
					</th>
					<th className="table--primary__header__data">
						{t(
							"specific:USER.CHECK_FAILED_LOGINS.IP_ADDRESS_TITLE"
						)}
					</th>
				</tr>
			</thead>
			<tbody className="table--primary__body">
				{failedLoginData.map((entry: FailedLogin, i: number) => {
					return (
						<tr key={i} className="table--primary__body__row">
							<td className="table--primary__body__data">
								{DateTime.fromISO(
									entry.sessionStartTime
								).toLocaleString(
									DateTime.DATETIME_SHORT_WITH_SECONDS
								)}
							</td>
							<td className="table--primary__body__data">
								{entry.ipAddress}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}
