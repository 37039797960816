import { observable, action, computed, runInAction } from "mobx";
import { getCurrentCulture } from "@utils";
import { EventFilterParams } from "@gp/hub";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

type TConfig = {
	onTermChange?: (searchTerm: string) => void;
};

class SearchBarViewStore {
	private config: TConfig = {};
	@observable searchTerm = "";

	@observable displayValidationPopup = false;

	@computed get trimSearchTerm() {
		return this.searchTerm ? this.searchTerm.trim() : "";
	}

	@computed get applySearch() {
		return this.searchTerm != null && this.trimSearchTerm !== "";
	}

	@computed get searchFilterArray(): EventFilterParams[] {
		return [
			this._getSearchObject("teamOne"),
			this._getSearchObject("teamTwo"),
			this._getEventSearchObject(),
		];
	}

	constructor(config: TConfig = {}) {
		this.config = config;

		this._getSearchObject = this._getSearchObject.bind(this);
		this._getSearchString = this._getSearchString.bind(this);
	}

	@action.bound
	closeValidationPopup() {
		this.displayValidationPopup = false;
	}

	@action.bound
	public async onChange(term: string) {
		console.log(term);
		this.searchTerm = term;

		const isValid = (await lazy(loadFailPath, () => import("xregexp")))
			.default("^[\\p{L}0-9-. ]{4,20}$")
			.test(term);

		runInAction(() => {
			// validate
			if (!isValid) {
				this.displayValidationPopup = true;
				return;
			} else {
				this.displayValidationPopup = false;
			}

			App.state.redirect(
				`/${getCurrentCulture()}/search/full/${this.trimSearchTerm}`
			);
		});
	}

	@action.bound
	setValue(searchTerm: string) {
		this.searchTerm = searchTerm;

		if (this.config.onTermChange) {
			this.config.onTermChange(this.trimSearchTerm);
		}
	}

	@action.bound
	onReset() {
		this.searchTerm = "";

		if (this.config.onTermChange) {
			this.config.onTermChange("");
		}
	}

	private _getSearchString(value: string) {
		return `%${value}%`;
	}

	private _getSearchObject(key: keyof EventFilterParams): EventFilterParams {
		return {
			[key]: {
				name: {
					ilike: this._getSearchString(this.searchTerm),
				},
			},
		};
	}

	private _getEventSearchObject(): EventFilterParams {
		return {
			name: {
				ilike: this._getSearchString(this.searchTerm),
			},
		};
	}
}

export default SearchBarViewStore;
