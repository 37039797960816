export const LicensingIconsObj = {
	ggl: { url: "https://www.gluecksspiel-behoerde.de/de/", icon: "ggl" },
	ssl: { url: "https://ssl.comodo.com/", icon: "comodo-secure" },
	"gambling-therapy": {
		url: "https://www.gamblingtherapy.org/",
		icon: "gambling-therapy",
	},
	gamcare: { url: "http://www.gamcare.org.uk/", icon: "gamcare" },
	dswv: { url: "https://dswv.de/", icon: "dswv" },
	mga: {
		url: "https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=9733bb6f-3517-4875-86b8-01bb8c8685fa&details=1",
		icon: "mga",
	},
	spillemyndigheden: {
		url: "https://spillemyndigheden.dk/",
		icon: "spillemyndigheden",
	},
	stopspillet: { url: "https://www.stopspillet.dk", icon: "stopspillet" },
	rofus: { url: "https://www.spillemyndigheden.dk/en/rofus", icon: "rofus" },
	mitid: { url: "https://www.mitid.dk/en-gb/", icon: "mitid" },
	ludomani: { url: "https://ludomani.dk/", icon: "ludomani" },
	ludomanibehandling: {
		url: "https://frederiksberg-centeret.dk/ludomani/ ",
		icon: "ludomanibehandling",
	},
	spelinspektionen: {
		url: "https://www.spelinspektionen.se/licensansokan/bolag-med-spellicens/",
		icon: "spelinspektionen",
	},
	stodlinjen: { url: "https://www.stodlinjen.se", icon: "stodlinjen" },
    spelberoendes: { url: "https://spelberoende.se", icon: "spelberoendes" },
	spelberoendegruppen: {
		url: "https://spelberoendegruppen.com",
		icon: "spelberoendegruppen",
	},
	folkhalsomyndigheten: {
		url: "https://www.folkhalsomyndigheten.se/livsvillkor-levnadsvanor/alkohol-narkotika-dopning-tobak-och-spel-andts/spel/",
		icon: "folkhalsomyndigheten",
	},
};

/* AT
Hrefs: ['https://ssl.comodo.com/', 'https://www.gamblingtherapy.org/', 'http://www.gamcare.org.uk/', 'https://dswv.de/'] */

// com
/* 
{'icon': 'u-icon--bv-iso-cert', 'url': None}
{'icon': 'u-icon--comodo-secure', 'url': 'https://ssl.comodo.com/'}
{'icon': 'u-icon--gambling-therapy', 'url': 'https://www.gamblingtherapy.org/'}
{'icon': 'u-icon--gamcare', 'url': 'http://www.gamcare.org.uk/'}
{'icon': 'u-icon--dswv', 'url': 'https://dswv.de/'} 
mgs*/

// de
/* {'icon': 'u-icon--ggl', 'url': 'https://www.gluecksspiel-behoerde.de/de/'}
{'icon': 'u-icon--comodo-secure', 'url': 'https://ssl.comodo.com/'}
{'icon': 'u-icon--gambling-therapy', 'url': 'https://www.gamblingtherapy.org/'}
{'icon': 'u-icon--gamcare', 'url': 'http://www.gamcare.org.uk/'}
{'icon': 'u-icon--dswv', 'url': 'https://dswv.de/'} */

// dk
/* "spillemyndigheden",
	"stopspillet",
	"rofus",
	"mitid",
	"ludomani",
	"ludomanibehandling";
 */

/* swe {
Spelinspektionen
Stödlinjen - Första hjälpen vid spelproblem
Spelberoendes
spelberoendegruppen 
folkhalsomyndigheten
} */
