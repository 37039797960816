import React from "react";
import { observer } from "mobx-react";
import { PaymentOption } from "@api-types/deposit";

export const DepositPaymentMethodMultipleReferencesCounter = observer(
	function DepositPaymentMethodMultipleReferencesCounter(props: {
		paymentMethod: PaymentOption;
	}) {
		const { paymentMethod } = props;

		//show if payment reference is available and there are multiple references
		if (
			paymentMethod.hasPaymentReference === true ||
			paymentMethod.hasMultipleReferences === false ||
			paymentMethod.paymentReferences == null
		) {
			return null;
		}

		return (
            <span className="card--payment__count counter counter--rounded counter--rounded--base counter--rounded counter--secondary">
				{paymentMethod.paymentReferences.length}
			</span>
		);
	}
);
