import {
	observable,
	computed,
	action,
	runInAction,
	reaction,
	IReactionDisposer,
} from "mobx";
import DepositFilterStore from "./DepositFilterStore";

import { LoaderStore } from "@state/stores/common";
import { betPeriods } from "@administration/pages/my-bets/BetPeriods";
import { DepositService } from "@services/deposit/DepositService";
import RootStore from "@state/stores/RootStore";
import {
	DepositResponseDto,
	PaymentOption,
	UsedPaymentMethodDto,
	DepositBonusInfo,
} from "@api-types/deposit";
import {
	DepositProcess,
	PaymentRequestFilter,
	RawFilterData,
	DepositFinalizeMultistep,
} from "@data-types/deposit";
import { localizationService } from "@state";
import { BaseLookupDto } from "@api-types/lookups/BaseLookupDto";
import { AccountTypes, getCurrentCulture, getUserId } from "@utils";
import { UserAccountType } from "@common/constants";

import { Capacitor } from "@capacitor/core";
import {
	getRedirectOriginUrl,
	isCapacitorPlatform,
} from "@utils/specific/capacitor";

export default class DepositStore {
	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		// Helper stores
		this.depositFilterStore = new DepositFilterStore(rootStore, this);
		this.loader = new LoaderStore();

		// Method bindings
		this.getDateRange = this.getDateRange.bind(this);
	}

	//#region observables

	@observable isStoreInitialized: boolean = false;
	@observable isFetchingData: boolean = true; // When store first starts it will always start fetching
	@observable isRedirectingToPaymentPage: boolean = false;

	@observable depositList: DepositResponseDto | null = null;
	@observable paymentMethods: PaymentOption[];
	@observable userPaymentMethods: UsedPaymentMethodDto[];
	@observable transactionStatuses: BaseLookupDto[];
	@observable paymentOption: PaymentOption | null = null;
	@observable isAmountInserted: boolean = false;
	@observable insertedAmount: number | undefined = 0;
	@observable currentFormData:
		| (Omit<DepositProcess, "amount"> & { amount: number })
		| null = null;
	@observable isMultiStepDeposit: boolean = false;
	@observable isPasswordConfirmation: boolean = false;
	@observable isBonusConfirmation: boolean = false;
	@observable paymentModeHash: string = "";
	@observable submittedWallet: string | null = null;

	@observable paymentProcessingMode: number;
	@observable withdrawalMethodSelectionMode: number;

	@observable iFrameRedirectUrl: string | null = null;

	@observable notVerifiedMessage: string | null = null;

	@computed get selectedPaymentMethod() {
		return this.paymentMethods?.find(
			(method) =>
				method.paymentMethodId === this.currentFormData?.paymentMethodId
		);
	}

	@computed get userPaymentSavedMethods() {
		return this.paymentMethods?.filter(
			(method) =>
				method.hasPaymentReference === true ||
				method.hasMultipleReferences === true
		);
	}

	@computed get otherPaymentMethods() {
		return this.paymentMethods?.filter(
			(method) =>
				method.hasPaymentReference === false &&
				method.hasMultipleReferences === false
		);
	}

	@computed get onlineWallet() {
		return App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) =>
				wallet.abrv === UserAccountType.SportBettingAccountOnline
		);
	}

	@computed get casinoWallet() {
		return App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) => wallet.abrv === UserAccountType.CasinoAccount
		);
	}

	@computed get liveCasinoWallet() {
		return App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) => wallet.abrv === UserAccountType.LiveCasinoAccount
		);
	}

	@computed get gigWallet() {
		return App.state.rootStore.userAuthStore.userAccounts?.find(
			(wallet) => wallet.abrv === UserAccountType.GigAccount
		);
	}

	@computed get isEmpty(): boolean {
		return (
			this.depositList != null &&
			this.depositList.item.length === 0 &&
			!this.isFetchingData &&
			this.isStoreInitialized
		);
	}

	@computed get betPeriods() {
		return betPeriods;
	}

	@computed get pageNumber(): number {
		return this.depositFilterStore.pageNumber;
	}

	@computed get shouldShowWalletSelectors(): boolean {
		if (App.state.rootStore.userAuthStore.userAccounts == null) {
			return false;
		}
		return App.state.rootStore.userAuthStore.userAccounts.length > 1;
	}

	pageSize: number = 10;
	loader: LoaderStore;
	depositFilterStore: DepositFilterStore;
	filterReaction: IReactionDisposer | null;
	rootStore: RootStore;
	@observable totalItems: number = 0;
	@observable initialLoad: boolean = true;

	@observable depositBonusInfo: DepositBonusInfo | null = null;

	@observable paymentMethodError: {
		message: string;
		errorCode: number;
	} | null = null;

	//#endregion observables

	//#region computed

	@computed get isLoading(): boolean {
		return this.loader.isLoading;
	}

	@computed get isLoadingProcess(): boolean {
		return this.loader.isLoadingProcess;
	}

	//#endregion computed

	//#region actions

	@action.bound
	async onInitialize(): Promise<void> {
		this.loader.suspend();

		try {
			const paymentMethods = await DepositService.getAvailableMethods();

			runInAction(() => {
				this.paymentMethods = paymentMethods.availablePaymentMethods;
				this.paymentModeHash = paymentMethods.paymentModeHash;
				this.paymentProcessingMode =
					paymentMethods.paymentProcessingMode;
				this.withdrawalMethodSelectionMode =
					paymentMethods.withdrawalMethodSelectionMode;
				this.isPasswordConfirmation =
					paymentMethods.isPasswordConfirmationRequired;
			});
		} catch (error) {
			console.error(error);
			this.paymentMethodError = {
				message:
					error?.data?.errorMessage?.shift() ||
					localizationService.t("ERROR.TITLE"),
				errorCode: error?.data?.error,
			};
		}

		runInAction(() => {
			this.isStoreInitialized = true;
			this.loader.resume();
		});

		this.depositFilterStore.onInitialize(); // Initialize filter after this store, we need to know if search params are empty

		this.disposeFilterReaction();
		this.filterReaction = reaction(
			() => ({
				filter: this.depositFilterStore.filter,
				isStoreInitialized: this.isStoreInitialized,
			}),
			() => {
				if (
					this.isStoreInitialized && // Wait for lookups to load
					!this.depositFilterStore.isDefaultFilter // Don't fetch for default filter
				) {
					this.fetchDeposit(this.depositFilterStore.filter);
				}
			},
			{
				fireImmediately: true,
			}
		);
	}

	@action.bound
	async fetchUserPaymentMethods(): Promise<void> {
		try {
			this.loader.suspend();
			const userPaymentMethods =
				await DepositService.getUserPaymentMethods();
			runInAction(() => {
				this.userPaymentMethods = userPaymentMethods;
			});
		} catch (error) {
			console.error(error);
			const errorMessage =
				error.data?.errorMessage?.shift() ||
				localizationService.t("ERROR.TITLE");
			App.state.rootStore.notificationStore.error(errorMessage);
		} finally {
			this.loader.resume();
		}
	}

	@action.bound
	async fetchTransactionStatuses() {
		try {
			this.loader.suspend();
			const transactionStatuses =
				await DepositService.getTransactionStatuses();
			runInAction(() => {
				this.transactionStatuses = transactionStatuses.item;
			});
		} catch (error) {
			console.error(error);
			const errorMessage =
				error.data?.errorMessage?.shift() ||
				localizationService.t("ERROR.TITLE");
			App.state.rootStore.notificationStore.error(errorMessage);
		} finally {
			this.loader.resume();
		}
	}

	@action.bound
	async fetchDeposit(filter: PaymentRequestFilter): Promise<void> {
		this.loader.suspend();
		this.isFetchingData = true;

		try {
			const requestFilter: PaymentRequestFilter = {
				from: filter.from,
				to: filter.to,
				pageNumber: filter.pageNumber,
				pageSize: this.pageSize,
				userId: getUserId(
					App.state.rootStore.userAuthStore.getUserAccountType(
						AccountTypes.ONLINE
					)
				),
				isDeposit: true,
			};

			if (filter.paymentMethodId !== "all") {
				requestFilter.paymentMethodId = filter.paymentMethodId;
			}

			if (filter.transactionStatusId !== "all") {
				requestFilter.transactionStatusId = filter.transactionStatusId;
			}

			const response = await DepositService.getDepositData(requestFilter);
			const data = {
				...response,
				item: response.item.map((item) => ({
					...item,
					status: this.transactionStatuses?.find(
						(status) => status.id === item.transactionStatusId
					),
				})),
			};
			runInAction(() => {
				this.depositList = data;
				this.totalItems = data.totalRecords;
			});
		} catch (error) {
			console.error(error);
			const errorMessage =
				error.data?.errorMessage?.shift() ||
				localizationService.t("ERROR.TITLE");
			App.state.rootStore.notificationStore.error(errorMessage);
			runInAction(() => {
				this.depositList = null;
			});
		} finally {
			this.loader.resume();
			this.isFetchingData = false;
			this.initialLoad = false;
		}
	}

	@action.bound
	refresh(): void {
		this.fetchDeposit(this.depositFilterStore.filter);
	}

	@action.bound
	submitFilter(data: RawFilterData): void {
		this.depositFilterStore.setRawFilterData(data);
	}

	@action.bound
	onPageChange(newPageNumber: number): void {
		this.depositFilterStore.setPageNumber(newPageNumber);
	}

	@action.bound
	setPaymentOption(paymentOption: PaymentOption | null): void {
		this.paymentOption = paymentOption;
	}

	@action.bound
	setSubmittedWallet(wallet: string): void {
		this.submittedWallet = wallet;
	}

	@action.bound
	setAmountFlag(state: boolean): void {
		this.isAmountInserted = state;
	}

	@action.bound
	setInsertedAmount(amount?: number): void {
		this.insertedAmount = amount;
	}

	@action.bound
	async initializeNewDepositProcess(data: DepositProcess): Promise<void> {
		this.loader.suspend();
		//form returns amount as a string always so we need to convert it to number
		const amount = parseFloat(data.amount);

		this.currentFormData = {
			...data,
			amount: amount,
		};

		const paymentMethodObject = this.paymentMethods.find(
			(method) => method.paymentMethodId === data.paymentMethodId
		);

		try {
			const initResponse =
				await DepositService.initializeNewDepositProcess({
					amount: amount,
					paymentMethodId: data.paymentMethodId,
					bettingAccountTypeId:
						this.submittedWallet || data.bettingAccountTypeId,
					paymentProviderId: paymentMethodObject?.paymentProviderId!,
					isDeposit: true,
					paymentModeHash: this.paymentModeHash,
					paymentProcessingMode: this.paymentProcessingMode,
					withdrawalMethodSelectionMode:
						this.withdrawalMethodSelectionMode,
					redirectUrls: {
						successUrl: getRedirectOriginUrl(
							"payment-info/success"
						),
						backUrl: getRedirectOriginUrl("deposit"),
						failureUrl: getRedirectOriginUrl(
							"payment-info/failure"
						),
						pendingUrl: getRedirectOriginUrl(
							"payment-info/pending"
						),
						returnUrl: getRedirectOriginUrl("deposit-info"),
					},
				});
			runInAction(() => {
				if (initResponse.isValid === true) {
					//comment this if case if u wish to go to final step
					if (initResponse.redirectUrl) {
						this.isRedirectingToPaymentPage = true;
					} else {
						//set all this to true if u wish to see final step and password and bonus
						this.isMultiStepDeposit =
							initResponse.isPasswordConfirmationRequired ||
							initResponse.isBonusAvailable;
						this.isPasswordConfirmation =
							initResponse.isPasswordConfirmationRequired;

						this.isBonusConfirmation =
							initResponse.isBonusAvailable;
						this.depositBonusInfo = initResponse.depositBonusInfo;
					}
				}
			});
			if (this.isRedirectingToPaymentPage && initResponse.redirectUrl) {
				window.location.href = initResponse.redirectUrl;
				if (isCapacitorPlatform()) {
					//only on native
					//reset state so user can submit again if we comes back from browser into app
					runInAction(() => {
						this.isRedirectingToPaymentPage = false;
					});
					this.loader.resume();
				}
			}
		} catch (error) {
			console.error(error);
			//nice
			if (error?.data?.error === 69) {
				App.state.rootStore.AccountVerificationStore.showPopup(
					"user-profile-recheck",
					"ACCOUNT_RECHECK.TITLE",
					error?.data?.errorMessage?.shift()
				);
			} else if (error?.data?.error === 15) {
				App.state.rootStore.AccountVerificationStore.showPopup(
					"account-settings/my-limits",
					"USER.ACCOUNT_SETTINGS.MY_LIMITS.TITLE",
					error?.data?.errorMessage?.shift()
				);
			} else if (error?.data?.error === 400242) {
				const errorMessage = localizationService.t(
					"MEMBERSHIP.ERR_HANDLING.LUGAS_VALIDATION_ERROR"
				);
				this.rootStore.notificationStore.error(errorMessage);
			} else if (error?.data?.error === 400250) {
				const errorMessage = localizationService.t(
					"DEPOSIT.LUGAS_DEPOSIT_EXCEEDS_BUDGET"
				);
				this.rootStore.notificationStore.error(errorMessage);
			} else if (error?.data?.error === 400256) {
				const errorMessage = localizationService.t(
					"MEMBERSHIP.ERR_HANDLING.LUGAS_UNSPECIFIED"
				);
				this.rootStore.notificationStore.error(errorMessage);
			} else {
				const errorMessage =
					error.data?.errorMessage?.shift() ||
					localizationService.t("ERROR.TITLE");
				App.state.rootStore.notificationStore.error(errorMessage);
			}
		} finally {
			this.loader.resume();
		}
	}

	@action.bound
	async finalizeMultiStepDeposit(data: {
		isBonusAccepted?: boolean;
		password?: string;
		bettingAccountTypeId?: string;
		isGiG?: boolean;
	}) {
		this.loader.suspend();

		const paymentMethodObject = this.paymentMethods.find(
			(method) =>
				method.paymentMethodId === this.currentFormData?.paymentMethodId
		);

		const finalizeObject: DepositFinalizeMultistep = {
			amount: this.currentFormData?.amount,
			bettingAccountTypeId:
				this.submittedWallet ||
				this.currentFormData?.bettingAccountTypeId,
			isDeposit: true,
			isPasswordConfirmationRequired: this.isPasswordConfirmation,
			paymentModeHash: this.paymentModeHash,
			paymentMethodId: this.currentFormData?.paymentMethodId,
			userPassword: data?.password,
			paymentProviderId: paymentMethodObject?.paymentProviderId,
			isBonusAccepted: data?.isBonusAccepted,
			paymentProcessingMode: this.paymentProcessingMode,
			withdrawalMethodSelectionMode: this.withdrawalMethodSelectionMode,
			redirectUrls: {
				successUrl: getRedirectOriginUrl("payment-info/success"),
				backUrl: getRedirectOriginUrl("deposit"),
				failureUrl: getRedirectOriginUrl("payment-info/failure"),
				pendingUrl: getRedirectOriginUrl("payment-info/pending"),
				returnUrl: getRedirectOriginUrl("deposit-info"),
			},
		};
		try {
			const finalizeResponse =
				await DepositService.finalizeMultiStepDeposit(finalizeObject);
			runInAction(() => {
				if (finalizeResponse.isValid === true) {
					if (finalizeResponse.redirectUrl) {
						this.isRedirectingToPaymentPage = true;
					}
				}
			});
			if (
				this.isRedirectingToPaymentPage &&
				finalizeResponse.redirectUrl
			) {
				window.location.href = finalizeResponse.redirectUrl;
				if (isCapacitorPlatform()) {
					//reset state so user can submit again if we comes back from browser into app
					runInAction(() => {
						this.isRedirectingToPaymentPage = false;
					});
					this.loader.resume();
				}
			}
		} catch (error) {
			console.error(error);
			//nice
			if (error?.data?.error === 69) {
				App.state.rootStore.AccountVerificationStore.showPopup(
					"user-profile-recheck",
					"ACCOUNT_RECHECK.TITLE",
					error?.data?.errorMessage?.shift()
				);
				return;
			}

			if (error?.data?.errorCode === 400176) {
				App.state.rootStore.notificationStore.error(
					localizationService.t(
						"USER.ACCOUNT_SETTINGS.ERR_HANDLING.INVALID_PASSWORD"
					)
				);
				return;
			}
			const errorMessage =
				error.data?.errorMessage?.shift() ||
				error.data?.details ||
				localizationService.t("ERROR.TITLE");
			App.state.rootStore.notificationStore.error(errorMessage);
		} finally {
			this.loader.resume();
		}
	}

	@action.bound
	resetMultiStepFlags(): void {
		this.isPasswordConfirmation = false;
		this.isBonusConfirmation = false;
		this.isMultiStepDeposit = false;
	}

	//#endregion actions

	//#region helpers

	getDateRange(data: RawFilterData): {
		from: string | null | undefined;
		to: string | null | undefined;
	} | null {
		const { period, from, to } = data;

		if (
			(period == null && from == null && to == null) ||
			period === "all"
		) {
			return null;
		}

		if (period !== "custom") {
			const timeInterval = this.betPeriods
				.find((p) => p.value === period)
				?.getTimeInterval();
			if (timeInterval == null) {
				// Return default
				return this.betPeriods[0].getTimeInterval();
			}
			return timeInterval;
		}

		return { from, to };
	}

	//#endregion helpers

	//#region disposers

	@action.bound
	onDispose(): void {
		this.depositFilterStore.onDispose();
		this.disposeFilterReaction();

		this.isStoreInitialized = false;
		this.isFetchingData = true;
		this.paymentMethodError = null;

		this.disposeCurrentDepositState();
	}

	@action.bound
	disposeCurrentDepositState(): void {
		this.depositList = null;
		this.initialLoad = true;
		this.pageSize = 10;
		this.totalItems = 0;
	}

	@action.bound
	disposeFilterReaction(): void {
		if (this.filterReaction != null) {
			this.filterReaction();
			this.filterReaction = null;
		}
	}

	@action.bound
	disposeNewDepositProcess(): void {
		// this.paymentOption = null;
		this.isAmountInserted = false;
		this.insertedAmount = 0;
	}

	//#endregion disposers
}
