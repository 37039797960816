import React from "react";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { useValueAvailabilityDebounce } from "@v2/hooks";

export default function RegistrationUsername(props: {
	checkUserNameAvailability: (data: string) => Promise<boolean>;
	labelClassName?: string;
}) {
	const {
		formState: { errors },
		clearErrors,
	} = useFormContext();
	const { checkUserNameAvailability, labelClassName } = props;

	const debouncer = useValueAvailabilityDebounce();

	// do not delete, maybe will be used in the future

	// const { t } = useTranslation();
	// const usernamePopover = (
	// 	<>
	// 		<div>{t("MEMBERSHIP.REGISTRATION.USERNAME_DESCRIPTION.TITLE")}</div>
	// 		<ul className="list u-mar--bottom--xtny u-pad--left--med">
	// 			<li>
	// 				{t(
	// 					"MEMBERSHIP.REGISTRATION.USERNAME_DESCRIPTION.REQUIREMENT_1"
	// 				)}
	// 			</li>
	// 			<li>
	// 				{t(
	// 					"MEMBERSHIP.REGISTRATION.USERNAME_DESCRIPTION.REQUIREMENT_2"
	// 				)}
	// 			</li>
	// 			<li>
	// 				{t(
	// 					"MEMBERSHIP.REGISTRATION.USERNAME_DESCRIPTION.REQUIREMENT_3"
	// 				)}
	// 			</li>
	// 			<li>
	// 				{t(
	// 					"MEMBERSHIP.REGISTRATION.USERNAME_DESCRIPTION.REQUIREMENT_4"
	// 				)}
	// 			</li>
	// 		</ul>
	// 	</>
	// );

	return (
		<FormInputField
			className="form__field"
			name="username"
			// do not delete, maybe will be used in the future
			// popoverMsg={usernamePopover}
			label="MEMBERSHIP.REGISTRATION.USERNAME_LABEL"
			labelClassName={labelClassName}
			placeholder="MEMBERSHIP.REGISTRATION.USERNAME_PLACEHOLDER"
			pattern={{
				value: /^[a-zA-Z0-9_-]{6,20}$/,
				message: "MEMBERSHIP.REGISTRATION.USERNAME_CHARACTERS_TW",
			}}
			validate={{
				isAvailable: async (value: string) => {
					if (get(errors, "username")) {
						clearErrors("username");
					}
					const isAvailable = await debouncer(
						value,
						checkUserNameAvailability
					);
					return (
						isAvailable ||
						"MEMBERSHIP.REGISTRATION.USERNAME_NOT_AVAILABLE"
					);
				},
			}}
			required
		/>
	);
}
