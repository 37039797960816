import {
	observable,
	action,
	computed,
	reaction,
	IReactionDisposer,
} from "mobx";
import { Subscription } from "rxjs";
import { getSecondaryOfferColumns } from "@gp/components";
import {
	MainOfferStore,
	SportOffer,
	EventOffer,
	LiveSportStructureOffer,
	getEventSortByFavorite,
} from "@gp/offer";
import { EventType, LiveStatus, OfferSubscriptionResponse } from "@gp/models";
import { ConsoleLogger, Sorter } from "@gp/utility";
import { ISubscriptionRequest, EventFilterParams } from "@gp/hub";
import betRules from "@betting-rules";
import RootOfferStore from "@offer/stores/RootStore";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";

import { BettingTypeSelectorsStore } from "../components/BettingTypeSelectorsStore";
import { LoaderStore } from "../../../../state/stores/common";
import { TFlatVirtualizedSportItem } from "./TFlatVirtualizedSportItem.js";

export type TRootStore = {
	hub: RootOfferStore["hub"];
};

class DefaultFilter {
	eventType: EventType;
	liveStatus: LiveStatus;

	constructor() {
		this.eventType = EventType.NORMAL;
		this.liveStatus = LiveStatus.LIVE;
	}
}

export default class LiveViewStore extends MainOfferStore {
	protected _liveSubscriptionCounter: number = 0;
	protected rootStore: TRootStore;
	protected liveOfferMenuStore: LiveOfferMenuStore;
	private bettingTypes: any;
	private loader: LoaderStore;

	//#region disposers
	private whenHubStartedDisposer: IReactionDisposer | null = null;
	private subscription: Subscription | null = null;

	//#endregion disposers

	//#region  observable

	@observable protected filter = new DefaultFilter();
	@observable protected numberOfColumns: number = 4;
	@action.bound
	public setNumberOfColumns(newNumber: number): void {
		if (newNumber === this.numberOfColumns) {
			return;
		}
		this.numberOfColumns = newNumber;
	}

	@computed public get period() {
		return this.liveOfferMenuStore.periodSubMenu.selectedPeriodKey;
	}

	@observable public isStoreInitialized = false;
	@observable public isFetchingData = false;

	@observable protected eventsWithErrors: Array<string> = [];

	//#endregion observable

	//#region  computed

	@computed public get isLive() {
		if (["events", "my-favorites"].includes(this.period)) {
			return true;
		}
		return false;
	}

	@computed public get isUpcoming() {
		return this.period === "upcoming";
	}

	@computed public get bettingTypesByAbrv() {
		let btArr = [...this.bettingTypes.values()];

		return btArr.reduce((acc, item) => {
			acc[item.abrv] = item;
			return acc;
		}, {});
	}

	@computed public get isLoading() {
		return this.loader.isLoading;
	}

	@computed public get isEmpty() {
		return (
			this.virtualizedLiveSportsList.length === 0 &&
			!this.isFetchingData &&
			this.isStoreInitialized
		);
	}

	@computed public get eventsToDisplayIsEmpty() {
		return this.isEmpty;
	}

	@computed public get eventIdsForEventSwitcher() {
		const list: string[] = [];

		this.eventsInSports.sports.forEach((sport) => {
			this.filterSportEvents(sport)?.forEach((event) =>
				list.push(event.id)
			);
		});

		return list;
	}

	/**
	 * Returns favorite events
	 */
	@computed get activeFavorites() {
		return [...this.liveOfferMenuStore.favoritesStore.userFavoriteEventsSet]
			.map((feId) => this.eventsMap.get(feId))
			.filter(<T>(item: T | undefined): item is T => item != null);
	}

	/**
	 * Returns number of favorite events that are currently in active offer
	 */
	@computed get activeFavoritesCount() {
		return this.activeFavorites.length;
	}

	@computed public get totalEventCountEmpty() {
		return this.eventsInSports.eventCount === 0;
	}

	//#endregion computed

	constructor(
		rootStore: TRootStore,
		liveOfferMenuStore: LiveOfferMenuStore,
		bettingTypeSelectorStore?: BettingTypeSelectorsStore
	) {
		super({
			logger: new ConsoleLogger(false),
			removeDelay: 10,
			throttle: 4,
			enableThrottling: true,
			bettingTypeSelectorsStore:
				bettingTypeSelectorStore || new BettingTypeSelectorsStore(),
			customConfiguration: betRules,
		});

		this.rootStore = rootStore;
		this.liveOfferMenuStore = liveOfferMenuStore;
		this.loader = new LoaderStore();
	}

	//#region  fetching data

	@action.bound
	public onInitialize() {
		this.loader.suspend();
		this.isFetchingData = true;

		this.disposeWhenHubStarted();
		this.whenHubStartedDisposer = reaction(
			() => ({
				period: this.liveOfferMenuStore.periodSubMenu.selectedPeriod
					.period,
				isMenuStoreInitialized:
					this.liveOfferMenuStore.isStoreInitialized,
			}),
			(a: any) => {
				if (a.isMenuStoreInitialized) {
					if (!this.isFetchingData) {
						this.loader.suspend();
						this.isFetchingData = true;
					}

					this.fetchData();
				}
			},
			{
				fireImmediately: true,
			}
		);
	}

	@action.bound
	private fetchData() {
		this.disposeSubscription();

		const subscriptionRequest = this.generateSubscriptionRequest();

		if (!subscriptionRequest) {
			console.error(
				`filter can not have empty id while requesting offer id subscription. Selected period: ${this.period} that has given an empty array `
			);
			this.onDoneFetching();
			return;
		}
		this.subscription = this.rootStore.hub
			.getOfferSubscription(subscriptionRequest)
			.subscribe({
				next: this.handleOfferAssignee,
				error: (err: any) => {
					console.error(err);
					this.onDoneFetching();
				},
			});
	}

	@action.bound
	protected handleOfferAssignee(response: OfferSubscriptionResponse) {
		this.assignOfferData(response);
		this.onDoneFetching();
	}
	//#region subscription object

	protected generateSubscriptionRequest(): ISubscriptionRequest | null {
		let offerFilter = this.generateSubscriptionOfferFilter(
			this.period === "upcoming" ? "prematch" : "live"
		);

		const eventFilter = this.getEventFilters();
		if (
			eventFilter.id &&
			typeof eventFilter.id == "object" &&
			eventFilter.id.eq?.length == 0
		) {
			return null;
		}
		// Use this to discard data from previous subscriptions
		this._liveSubscriptionCounter++;
		return {
			subscriptionId: `live-${this._liveSubscriptionCounter}`,
			compress: WEBPACK_OFFER_COMPRESS,
			channels: [
				{
					name: "event",
					filter: eventFilter,
				},
				{
					name: "betOffer",
					filter: offerFilter,
				},
			],
		};
	}

	protected getEventFilters = (): EventFilterParams => {
		let filter: EventFilterParams = {};

		switch (this.period) {
			case "events":
				filter = {
					liveStatus: LiveStatus.LIVE,
				};
				break;

			case "upcoming":
				filter = {
					liveStatus: LiveStatus.PREMATCH,
					isUpcoming: true,
				};
				break;

			case "my-favorites":
				filter = {
					liveStatus: LiveStatus.LIVE,
					id: {
						eq: [
							...this.liveOfferMenuStore.favoritesStore
								.userFavoriteEventsSet,
						],
					},
				};
				break;

			case "played-events":
				filter = {
					liveStatus: LiveStatus.LIVE,
					id: {
						eq: this.liveOfferMenuStore.liveEventsFromMyBets
							.eventIds,
					},
				};
				break;

			case "highlights":
				filter = {
					liveStatus: LiveStatus.LIVE,
					isTopEvent: true,
				};
				break;
			case "live-stream":
				filter = {
					liveStatus: LiveStatus.LIVE,
					liveStreamStatus: {
						eq: ["upcoming", "live"],
					},
				};

			default:
				// Empty
				break;
		}

		return filter;
	};

	protected generateSubscriptionOfferFilter(eventType: "prematch" | "live") {
		const bts = this.OfferMapper.getBettingTypes(eventType, betRules);
		return [
			{
				bettingType: {
					abrv: {
						eq: bts.normal,
					},
				},
			},
			{
				bettingType: {
					abrv: {
						eq: bts.marginal,
					},
				},
				isFavorite: true,
			},
		];
	}

	//#endregion subscription object

	@action.bound
	protected onDoneFetching() {
		this.loader.resume();
		this.initialized = false;
		this.isFetchingData = false;
		this.isStoreInitialized = true;
	}

	//#endregion fetching data

	//#region disposers

	@action.bound
	public onDispose() {
		this.disposeSubscription();
		this.disposeWhenHubStarted();

		this.isStoreInitialized = false;
		this._liveSubscriptionCounter = 0;

		this.onReset();
	}

	@action.bound
	protected disposeSubscription() {
		this.eventsWithErrors = [];
		if (this.subscription != null) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	@action.bound
	protected disposeWhenHubStarted() {
		if (this.whenHubStartedDisposer != null) {
			this.whenHubStartedDisposer();
			this.whenHubStartedDisposer = null;
		}
	}

	@action.bound
	protected onReset() {
		this.reset();

		this.eventsInSports = new LiveSportStructureOffer();
		//this.configuration.bettingTypeSelectorsStore.reset();
	}

	//#endregion disposers

	//#region  setters

	@action.bound
	eventFavoritesFilter = (eventId: string) => {
		return this.liveOfferMenuStore.favoritesStore.userFavoriteEventsSet.has(
			eventId
		);
	};

	@action.bound
	public registerEventWithError(eventId: string) {
		if (this.eventsWithErrors.includes(eventId)) {
			return;
		}
		this.eventsWithErrors.push(eventId);
	}

	//#endregion setters

	//#region sport collapse

	@computed public get collapsedSports(): string[] {
		return Array.from(this.liveOfferMenuStore.collapsedSports);
	}

	@action.bound
	public expandSport(sportId: string) {
		this.liveOfferMenuStore.expandSport(sportId);
	}

	@action.bound
	public collapseSport(sportId: string) {
		this.liveOfferMenuStore.collapseSport(sportId);
	}

	@action.bound
	public toggleSportCollapse(sportId: string) {
		this.liveOfferMenuStore.toggleSportCollapse(sportId);
	}

	//#endregion sport collapse

	//#region flat sports

	@computed
	public get virtualizedLiveSportsList(): TFlatVirtualizedSportItem[][] {
		return this.eventsInSports.sports.reduce<TFlatVirtualizedSportItem[][]>(
			(acc, sp: SportOffer) => {
				const flatSport: TFlatVirtualizedSportItem = {
					sport: sp,
					eventCount: 0,
					event: null,
				};

				const baseEvents = this.filterSportEvents(sp)
					?.map<TFlatVirtualizedSportItem>((event) => {
						return { sport: null, event, secondaryCount: 0 };
					})
					.reduce<TFlatVirtualizedSportItem[]>((acc, item) => {
						if (item.sport != null || item.isSecondary) {
							return acc;
						}

						acc.push(item);

						const eventOffer = this.eventKeysMap.get(
							item.event?.id || ""
						);
						const eventKeys = Array.from(
							eventOffer?.values() || []
						);
						if (eventKeys != null) {
							const bettingTypeSelector =
								this.configuration.bettingTypeSelectorsStore.getSportSelector(
									sp
								);

							if (bettingTypeSelector == null) {
								console.error(
									"Expected betting type slector, got null."
								);
							} else {
								const secondaryColumns =
									getSecondaryOfferColumns(
										eventKeys,
										bettingTypeSelector,
										this.numberOfColumns
									);

								if (!secondaryColumns.isEmpty) {
									item.secondaryCount =
										secondaryColumns.nonEmptyColumns;
									acc.push({
										event: item.event,
										sport: null,
										secondaryOffer: secondaryColumns,
										isSecondary: true,
									});
								}
							}
						}

						return acc;
					}, []);

				if (baseEvents == null || baseEvents.length <= 0) {
					return acc;
				}

				flatSport.eventCount =
					baseEvents?.filter((e) => !e.isSecondary).length || 0;

				if (this.liveOfferMenuStore.collapsedSports.has(sp.id)) {
					acc.push([flatSport]);
				} else {
					baseEvents.unshift(flatSport);
					acc.push(baseEvents);
				}

				return acc;
			},
			[]
		);
	}

	protected filterSportEvents(sport: SportOffer): EventOffer[] | undefined {
		if (this.period === "my-favorites") {
			return sport.events?.filter(({ id }) =>
				this.liveOfferMenuStore.favoritesStore.userFavoriteEventsSet.has(
					id
				)
			);
		}

		if (
			this.liveOfferMenuStore.menu.checkState === 0 &&
			// This is live offer menu magic
			// If we are on live events and there is sport selected in side menu, but all is deselected
			// Then display that sport, if nothing is selected in sport menu for that sport then display all from sport
			// Else display what is selected
			(this.period !== "events" ||
				(this.period === "events" &&
					(this.liveOfferMenuStore.selectedSport?.node.id ===
						sport.id ||
						this.liveOfferMenuStore.selectedSport == null)))
		) {
			if (!sport.events) {
				return sport.events;
			}

			if (
				this.liveOfferMenuStore.favoritesStore.userFavoriteEventsSet
					.size > 0
			) {
				return sport.events?.sort(
					Sorter.sort((a, b) => {
						return Sorter.sort((a1: EventOffer, b1: EventOffer) =>
							getEventSortByFavorite(
								a1,
								b1,
								this.liveOfferMenuStore.favoritesStore
							)
						)(a, b);
					})
				);
			}

			return sport.events;
		}

		const sportInMenu = this.liveOfferMenuStore.menu.children.find(
			({ node: { id } }) => id === sport.id
		);

		if (sportInMenu == null) {
			// Sport in menu was removed or not added yet
			return [];
		}

		const sportSelectedTournaments = sportInMenu.checkedLeafNodeIds;

		return sport.events
			?.sort(
				Sorter.sort((a, b) => {
					return Sorter.sort((a1: EventOffer, b1: EventOffer) =>
						getEventSortByFavorite(
							a1,
							b1,
							this.liveOfferMenuStore.favoritesStore
						)
					)(a, b);
				})
			)
			.filter((event) =>
				sportSelectedTournaments.includes(event.tournamentId)
			);
	}

	//#endregion flat sports
}
