import { DateTime } from "luxon";
import { Response, ErrorResponse } from "../../shared";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { getRedirectOriginUrl } from "@utils/specific/capacitor";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const UserDataApiService = lazy(loadFailPath, ()=>import("@api-services/user/UserDataApiService"));

class ForgotPasswordService {
	async requestPasswordRecovery(
		formData: RequestPasswordRecoveryFormData,
		recaptchaToken: string | null = null
	) {
		try {
			const model = {
				dateOfBirth: DateTime.fromISO(formData.dateOfBirth).toFormat(
					"MM-dd-yyyy"
				),
				recoverUrl: `${getRedirectOriginUrl(
					`auth/password-recovery{?passwordRecoveryToken}&username=${formData.userName}`
				)}`,
				userName: formData.userName,
				challengeResponse: recaptchaToken,
			};

			await (
				await UserDataApiService
			).default.requestPasswordRecovery(model);
			return new Response();
		} catch (error) {
			console.log(error);
			if (error?.data?.errorCode === 400158) {
				throw new ErrorResponse({
					message: "FORGOT_PASSWORD_USERNAME_OR_DATEOFBIRTH_INVALID",
				});
			} else if (error?.data?.errorCode === 400159) {
				throw new ErrorResponse({
					message: "ERROR_PWD_CHANGE_SHOP",
					options: error?.data?.details,
				});
			} else if (error?.data?.errorCode === 400160) {
				throw new ErrorResponse({
					message: "FORGOT_USERNAME_NOT_VERIFIED",
				});
			} else if (error?.data?.errorCode === 400170) {
				throw new ErrorResponse({
					message: "ERROR_PWD_CHANGE_CURRENT_SAME",
				});
			} else if (error?.data?.errorCode === 400171) {
				throw new ErrorResponse({
					message: "ERROR_PWD_CHANGE_OLD_SAME",
				});
			} else if (error?.data?.errorCode === 400163) {
				throw new ErrorResponse({
					message: "PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED",
				});
			}

			throw new ErrorResponse({
				message: "PASSWORD_RECOVERY_REQUEST_ERROR",
			});
		}
	}
}

export const forgotPasswordServiceInstance = new ForgotPasswordService();
export type RequestPasswordRecoveryFormData = {
	/** Time string */
	dateOfBirth: string;
	userName: string;
};
