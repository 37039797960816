import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react";
import {
	ExpandedOfferMenu,
	MainSportsList,
	PrimaryLiveMenuLinkItems,
	SecondaryLiveMenuLinkItems,
} from "./components";
import { OfferStoreContext } from "@gp/components";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";
import { TLivePeriod } from "@offer/stores/components/offer-menu/period-sub-menu/LivePeriodSubMenuStore";

export const LiveOfferMenu = observer(function LiveOfferMenu(props: {
	liveOfferMenuStore: LiveOfferMenuStore;
}) {
	const match = useRouteMatch<{
		period: TLivePeriod;
		segments: string;
	}>();

	useEffect(() => {
		props.liveOfferMenuStore.setRouterMatchObj(match);
		props.liveOfferMenuStore.onInitialize();
		return () => props.liveOfferMenuStore.onDispose();
	}, []);

	useEffect(() => {
		props.liveOfferMenuStore.setRouterMatchObj(match);
	}, [match]);

	return (
		// @ts-expect-error
		<OfferStoreContext.Provider value={props.liveOfferMenuStore}>
			<nav className="nav--secondary">
				<ul className="nav--secondary__menu">
					<PrimaryLiveMenuLinkItems />
					<MainSportsList store={props.liveOfferMenuStore} />
					<SecondaryLiveMenuLinkItems />
				</ul>
			</nav>
			{/* @ts-expect-error */}
			<ExpandedOfferMenu store={props.liveOfferMenuStore} />
		</OfferStoreContext.Provider>
	);
});
