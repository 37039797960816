import { AgencyUserDto } from "@api-types/user/AgencyUserDto";
import { AgencyUserFullUpdateDto } from "@api-types/user/AgencyUserFullUpdateDto";

import { EmailChangeDto } from "@api-types/user/EmailChangeDto";
import { EmailChangeRequestParamsDto } from "@api-types/user/EmailChangeRequestParamsDto";
import { OfflineUserDataDto } from "@api-types/user/OfflineUserDataDto";
import { UserLimitSettingsDto } from "@api-types/user/UserLimitSettingsDto";
import { UserNameRecoveryParamsDto } from "@api-types/user/UserNameRecoveryParamsDto";
import { VerifyAndChangePasswordParamsDto } from "@api-types/user/VerifyAndChangePasswordParamsDto";
import { PlayerPromoSendingMode } from "@api-types/user/AgencyUserDto";
import {
	getAccountActivationToken,
	getAgencyId,
	getUserId,
	getUsername,
	httpClient,
	getApplicationHeaders,
	UserTypes,
} from "@utils";
import { map } from "lodash";

export default class AgencyUserApiService {
	static get baseUrl() {
		return `${WEBPACK_BASE_ADDRESS}platform/agency-users`;
	}

	static getRequestHeaders = (
		activeAccount: UserTypes = UserTypes.PRIMARY
	) => {
		const appHeaders = getApplicationHeaders(activeAccount);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	static async setUserAutomaticLogoutSettings(
		userLimitSettings: UserLimitSettingsDto
	): Promise<void> {
		return httpClient.put(
			`${this.baseUrl}/${getAgencyId()}/${getUserId()}/limit-settings`,
			JSON.stringify(userLimitSettings),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async updateBettingActivityReview(): Promise<void> {
		return httpClient.put(
			`${this.baseUrl}/update-last-betting-review-check-date`,
			null,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	// returns new email if successful
	static async activateEmail(
		emailChangeTo: EmailChangeDto
	): Promise<string | void> {
		return httpClient.put(
			`${this.baseUrl}/email-change`,
			JSON.stringify(emailChangeTo),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async checkIsEmailAvailable(
		checkEmail: string
	): Promise<boolean | void> {
		const body = JSON.stringify(checkEmail);
		return httpClient.post(
			`${this.baseUrl}/email-availability`,
			body,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	// returns true if successful
	static async changeUserEmail(
		emailChange: EmailChangeRequestParamsDto
	): Promise<boolean | void> {
		const body = JSON.stringify(emailChange);
		return httpClient.post(
			`${this.baseUrl}/email-change`,
			body,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async updateUserPin(
		pin: string,
		userType: UserTypes = UserTypes.PRIMARY
	): Promise<void> {
		return httpClient.put(
			`${this.baseUrl}/update-pin/${getUsername(userType)}/${pin}`,
			null,
			this.getRequestHeaders(userType),
			{ responseType: "json" }
		);
	}

	static async promoMaterialsApproval(
		isAccepted: PlayerPromoSendingMode
	): Promise<void> {
		return httpClient.put(
			`${
				this.baseUrl
			}/promo-material-update/${getUserId()}/${isAccepted}`,
			null,
			this.getRequestHeaders()
		);
	}

	static async changeUserPassword(
		model: VerifyAndChangePasswordParamsDto,
		activeAccount: UserTypes = UserTypes.PRIMARY
	): Promise<boolean> {
		return httpClient.put(
			`${this.baseUrl}/${getAgencyId(activeAccount)}/${getUsername(
				activeAccount
			)}/verify-and-change-password`,
			JSON.stringify(model),
			this.getRequestHeaders(activeAccount),
			{ responseType: "json" }
		);
	}

	static async updatePersonalData(
		model: AgencyUserFullUpdateDto
	): Promise<void> {
		return httpClient.put(
			`${this.baseUrl}/${getAgencyId()}/${getUserId()}/with-password`,
			JSON.stringify(model),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async updatePersonalDataWithoutPassword(
		model: AgencyUserFullUpdateDto
	): Promise<void> {
		return httpClient.put(
			`${this.baseUrl}/${getAgencyId()}/${getUserId()}`,
			JSON.stringify(model),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async recoverUsername(
		model: UserNameRecoveryParamsDto
	): Promise<void> {
		return httpClient.post(
			`${this.baseUrl}/recover-username`,
			JSON.stringify(model),
			this.getRequestHeaders()
		);
	}

	// this can get with embed - for future reference check AgencyUserDto model
	static async getUserInfo(): Promise<AgencyUserDto> {
		return httpClient.get(
			`${this.baseUrl}/${getAgencyId()}/${getUserId()}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async verifyPasswordRecoveryToken(
		passwordToken: string,
		username: string
	): Promise<boolean> {
		return httpClient.get(
			`${this.baseUrl}/verify-recover-password-token?passwordToken=${passwordToken}&username=${username}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async getCardUserInfoAsync(
		accountActivationToken: string,
		agencyId: string
	): Promise<OfflineUserDataDto> {
		return httpClient.get(
			`${this.baseUrl}/offline-user-data/${agencyId}/${accountActivationToken}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async getForcePasswordChangeCardUserInfoAsync(): Promise<OfflineUserDataDto> {
		return httpClient.get(
			`${
				this.baseUrl
			}/offline-user-data/password/${getAgencyId()}/${getAccountActivationToken()}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async saveNewDefaultStake(defaultStake: number) {
		return httpClient.put(
			`${this.baseUrl}/update-stake-amount`,
			JSON.stringify(defaultStake),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async subscribeToNewsletter(
		email: string,
		challengeResponse: string
	) {
		const parameters = {
			email: email,
			challengeResponse: challengeResponse,
		};

		return httpClient.post(
			`${this.baseUrl}/accept-newsletter-anonymous`,
			JSON.stringify(parameters),
			this.getRequestHeaders()
			//{ responseType: "json" }
		);
	}

	static async updateRealityCheckDuration(
		durationValue: string
	): Promise<void> {
		return httpClient.put(
			`${this.baseUrl}/update-reality-check`,
			durationValue,
			this.getRequestHeaders()
		);
	}

	static async updateChatName(
		chatName: string,
		agencyId: string,
		userId: string
	): Promise<void> {
		return httpClient.put(
			`${this.baseUrl}/update-chat-name/${agencyId}/${userId}`,
			JSON.stringify(chatName),
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}

	static async checkLoginInfo(data: {
		userName: string;
		password: string;
	}): Promise<{
		userAccountLockDurationTypeId: string;
		userAccountLockReasonTypeId: string;
		userAccountLockExplanation: string;
	} | null> {
		try {
			const response = await httpClient.post(
				`${this.baseUrl}/check-login-info`,
				JSON.stringify(data),
				this.getRequestHeaders()
			);

			const responseJson = (await response.json()) as {
				userAccountLockDurationTypeId: string;
				userAccountLockReasonTypeId: string;
				userAccountLockExplanation: string;
			} | null;

			return responseJson;
		} catch (e) {
			throw e;
		}
	}

	static async updateLockData(data: {
		userName: string;
		password: string;
		userAccountLockDurationTypeId: string;
	}) {
		return httpClient.post(
			`${this.baseUrl}/update-lock-data`,
			JSON.stringify(data),
			this.getRequestHeaders()
		);
	}
}
