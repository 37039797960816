import React from "react";
import { EventOffer as EventOfferType } from "@gp/offer";

export default class EventRenderErrorBoundary extends React.Component<
	{ event: EventOfferType },
	{ hasError: boolean }
> {
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	constructor(props: { event: EventOfferType }) {
		super(props);

		this.state = {
			hasError: false,
		};
	}

	componentDidCatch(error: any, errorInfo: any) {
		console.error(
			`--- ERROR WHILE RENDERING EVENT ${this.props.event.id} ---`
		);
		console.error(`Event: ${this.props.event}`, error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// Don't render event if there was an error while rendering
			return null;
		}

		return this.props.children;
	}
}
