import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { isNullOrWhitespace } from "@utils";
import { useAppContext } from "@v2/hooks/shared";
import { useLogin } from "@v2/hooks/membership/login";
import {
	CheckboxField,
	FormInputField,
	PasswordInput,
} from "../../shared/inputs";
import { LoginErrorMessage } from ".";
import { FormButton } from "@components/buttons";

export default function LoginForm() {
	const { culture } = useAppContext();

	const { handleSubmit, resetField } = useFormContext();

	const { onSuccess, loginError } = useLogin(resetField);

	const { t } = useTranslation();

	return (
		<form
			className="card--onboarding__content card--onboarding__content--shrink"
			onSubmit={handleSubmit(onSuccess)}
		>
			<div className="form__group">
				<FormInputField
					id="loginUsername"
					name="username"
					className="form__field"
					label="MEMBERSHIP.LOGIN.EMAIL_OR_USERNAME_LABEL"
					labelClassName="label--strong"
					placeholder="MEMBERSHIP.LOGIN.EMAIL_OR_USERNAME_PLACEHOLDER"
					validate={{
						isNullOrWhitespace: (value: string) =>
							isNullOrWhitespace(value) !== true ||
							"COMMON.REQUIRED_FIELD",
						isEmail: (value: string) =>
							!value.includes("@") ||
							"MEMBERSHIP.LOGIN.USERNAME_MAIL_ERROR_MESSAGE",
					}}
					autoComplete="username"
					required
					tabIndex={1}
				/>
				<div className="u-type--right">
					<Link
						to={`/${culture}/auth/forgot-username`}
						className="u-anchor u-anchor--secondary u-anchor--underline u-type--xsml"
					>
						{t<string>("MEMBERSHIP.LOGIN.FORGOT_USERNAME_LINK")}
					</Link>
				</div>
			</div>

			<div className="form__group">
				<PasswordInput
					id="loginPassword"
					className="form__field"
					label="MEMBERSHIP.LOGIN.PASSWORD_LABEL"
					labelClassName="label--strong"
					name="password"
					placeholder="MEMBERSHIP.LOGIN.PASSWORD_PLACEHOLDER"
					validate={{
						isNullOrWhitespace: (value: string) =>
							isNullOrWhitespace(value) !== true ||
							"COMMON.REQUIRED_FIELD",
					}}
					autoComplete="current-password"
					required
					tabIndex={2}
				/>
				<div className="u-type--right">
					<Link
						to={`/${culture}/auth/forgot-password`}
						className="u-anchor u-anchor--secondary u-anchor--underline u-type--xsml"
					>
						{t<string>("MEMBERSHIP.LOGIN.FORGOT_PASSWORD_LINK")}
					</Link>
				</div>
			</div>

			<div className="form__group">
				<CheckboxField
					name="stayLoggedIn"
					id="sliCheck"
					label="MEMBERSHIP.LOGIN.STAY_LOGGED_IN"
					checkboxClassname="col col-sml-12 form__field"
					labelClassName="u-type--sml"
				/>
			</div>

			<LoginErrorMessage loginError={loginError} />

			<FormButton
				atClassName="loginSubmitBtn"
				className="btn btn--lrg btn--block btn--primary"
				btnText="MEMBERSHIP.LOGIN.LOGIN_BUTTON"
			/>
		</form>
	);
}
