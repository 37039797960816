import { AircashMarketplaceBonusLabel } from "@common/constants/BonusConstant";
import { useRootAppStore } from "@hooks";
import {
	getDepositBonusInfo,
	shouldShowMarketplaceBonus,
} from "@v2/helpers/shared/getUserFlags";
import React from "react";
import { useTranslation } from "react-i18next";

export default function UseMarketplaceBonus() {
	const { t } = useTranslation();
	const rootStore = useRootAppStore();
	const welcomeStore = rootStore.welcomeStore;

	const depositBonusInfoData = getDepositBonusInfo();

	if (shouldShowMarketplaceBonus()) {
		return null;
	}

	return (
		<div className="popup__col col col-sml-12">
			<div className="message message--base">
				<div className="message__content u-padd--reset">
					<div className="message__title u-type--xmed">
						<i className="message__icon u-icon u-icon--lrg u-icon--marketplace-bonus--alt u-mar--right--tny" />
						<span className="u-align--v--middle">
							{AircashMarketplaceBonusLabel}
						</span>
					</div>

					<div className="message__text u-mar--bottom--xsml">
						<span>
							{t<string>(
								"USER.ACCOUNT_SETTINGS.MY_BONUSES.MARKETPLACE_BONUS.MARKETPLACE_BONUS_DESCRIPTION",
								{
									0: depositBonusInfoData?.bonusInfo
										.bonusAmount,
									1: depositBonusInfoData?.bonusInfo
										.currencyDisplayCode,
								}
							)}
						</span>
					</div>

					<button
						className="btn btn--sml btn--secondary--inverted"
						onClick={welcomeStore.showUseBonusConfirmationPopup}
					>
						{t("COMMON.APPLY_BUTTON")}
					</button>
				</div>
			</div>
		</div>
	);
}
