import { observable, action, computed } from "mobx";
import { IStoreLifeCycle } from "@interface";
import { ValidationStore } from "./common";
import { ThemeStore } from "./components/subheader";
import {
	UserAuthStore,
	AutomaticLogoutStore,
	RealityCheckStore,
	SessionExpireStore,
	NotificationStore,
	User24hLockStore,
	WelcomeStore,
	FailedLoginsCheckPopupStore,
	CapacitorUpdaterStore,
	OnboardingWelcomePopupStore,
	RequiredUserActionsStore,
	CookieConsentStore,
} from "@v2/state/shared";
import { OfferHubStore, CashoutHubStore } from "@state/hubs";
import { PaymentOptionsStore } from "@v2/state/shared";
import { AccountVerificationStore } from "@v2/state/account-verification";

import { LocalStorageProvider } from "@utils/storage";
import QuickPlayStore from "@v2/state/quick-play-store/QuickPlayStore";
import RestrictionService from "@services/restriction/RestrictionService";
import { ChatViewStore } from "./chat/ChatViewStore";

class RootStore implements IStoreLifeCycle {
	userAuthStore: UserAuthStore;
	isStoreInitialized: boolean = false;
	localStorageProvider: LocalStorageProvider;
	validationStore: ValidationStore;
	themeStore: ThemeStore;
	automaticLogoutStore: AutomaticLogoutStore;
	realityCheckStore: RealityCheckStore;
	sessionExpireStore: SessionExpireStore;
	user24hLockStore: User24hLockStore;
	notificationStore: NotificationStore;
	paymentOptionsStore: PaymentOptionsStore;
	AccountVerificationStore: AccountVerificationStore;
	welcomeStore: WelcomeStore;
	onboardingWelcomePopupStore: OnboardingWelcomePopupStore;
	failedLoginsCheckPopupStore: FailedLoginsCheckPopupStore;
	capacitorUpdaterStore: CapacitorUpdaterStore;
	requiredUserActionStore: RequiredUserActionsStore;
	quickPlayStore: QuickPlayStore;
	chatViewStore: ChatViewStore;
	cookieConsentStore: CookieConsentStore;

	@computed public get isSomePopupActive(): boolean {
		return (
			this.automaticLogoutStore.isActive ||
			this.offerHub.isSessionExpired ||
			this.realityCheckStore.isActive ||
			this.user24hLockStore.isActive
		);
	}

	offerHub: OfferHubStore;
	cashoutHubStore: CashoutHubStore;
	uniqueId: number;

	@observable isSideMenuExpanded = true;
	@observable isDepositLimitUpdatePopupActive = false;
	@observable isLocalStorageLoaded = false;
	@observable isIpRestricted = false;
	/** @deprecated is this even used? */
	myBetsViewStore: any;

	constructor() {
		this.localStorageProvider = new LocalStorageProvider();
		this.userAuthStore = new UserAuthStore(this);
		this.validationStore = new ValidationStore();

		this.themeStore = new ThemeStore(this);
		this.notificationStore = new NotificationStore();

		//this.errorStore = new ErrorStore(this);

		this.offerHub = new OfferHubStore(this.userAuthStore);
		this.cashoutHubStore = new CashoutHubStore(this);

		this.automaticLogoutStore = new AutomaticLogoutStore(this);
		this.realityCheckStore = new RealityCheckStore(this);
		this.sessionExpireStore = new SessionExpireStore(this);
		this.user24hLockStore = new User24hLockStore(this);
		this.paymentOptionsStore = new PaymentOptionsStore();
		this.onboardingWelcomePopupStore = new OnboardingWelcomePopupStore(
			this
		);
		this.userAuthStore.syncToken();
		// needs to happen because of possible wrong data formatting in acc key in local storage !!!!
		// can be removed after some time but doesn't hurt
		this.userAuthStore.checkBalanceEntry();

		this.AccountVerificationStore = new AccountVerificationStore();
		this.welcomeStore = new WelcomeStore(this);
		this.requiredUserActionStore = new RequiredUserActionsStore(
			this.userAuthStore
		);

		this.failedLoginsCheckPopupStore = new FailedLoginsCheckPopupStore();
		// used for requestId query param
		this.uniqueId = Date.now();

		this.cookieConsentStore = new CookieConsentStore();
		this.quickPlayStore = new QuickPlayStore(this);

		this.chatViewStore = new ChatViewStore(this);
	}

	async onInitialize() {
		// need to fetch local storage values,
		// all other stores need to consume local storage after fetching
		await this.localStorageProvider.onInitialize();
		this.setLocalStorageLoadStatus(true);
		this.themeStore.setInitialValue();
		this.isStoreInitialized = true;
		this.realityCheckStore.onInitialize();
		this.paymentOptionsStore.onStoreInitialize();
		this.userAuthStore.onInitialize();
		this.cashoutHubStore.initializeCashoutHub();
		this.sessionExpireStore.onInitialize();
		this.welcomeStore.onInitialize();

		this.onboardingWelcomePopupStore.onInitialize();

		// used for requestId query param
		this.uniqueId = Date.now();
		this.chatViewStore.updateChatOnLoginChange(
			this.userAuthStore.isLoggedIn
		);

		this.checkIfIpRestricted();
	}

	async checkIfIpRestricted() {
		if (
			location.pathname.includes("register/terminal") ||
			location.pathname.includes("register/partner")
		) {
			return;
		}

		const shouldRestrict =
			await RestrictionService.getRestrictionConfigurationState();

		if (typeof shouldRestrict === "boolean") {
			this.setIsIpRestricted(shouldRestrict);
		}
	}

	@action.bound
	setIsIpRestricted(value: boolean) {
		this.isIpRestricted = value;
	}

	@action.bound
	setLocalStorageLoadStatus(status: boolean) {
		this.isLocalStorageLoaded = status;
	}

	@action.bound
	resetPopups() {
		this.isSideMenuExpanded = false;
		this.isDepositLimitUpdatePopupActive = false;
	}

	@action.bound
	toggleSideMenu() {
		if (this.isSideMenuExpanded) {
			this.closeSideMenu();
		} else {
			this.openSideMenu();
		}
	}

	@action.bound
	openSideMenu() {
		this.isSideMenuExpanded = true;
	}

	@action.bound
	closeSideMenu() {
		this.isSideMenuExpanded = false;
	}

	@action.bound
	stopSession() {
		this.sessionExpireStore.clearTimer();
		this.realityCheckStore.clearTimer();
		this.automaticLogoutStore.clearTimer();
		this.realityCheckStore.deactivatePopup();
		this.automaticLogoutStore.deactivatePopup();
	}

	@action.bound
	closePopups() {
		this.realityCheckStore.deactivatePopup();
		this.automaticLogoutStore.deactivatePopup();
		this.AccountVerificationStore.closePopup();
		this.welcomeStore.hidePopup();
	}

	@action.bound
	initClick() {
		this.realityCheckStore.onClick();
		this.sessionExpireStore.onClick();
	}

	@action.bound
	onDispose() {
		this.isStoreInitialized = true;
		this.paymentOptionsStore.onDispose();
		this.userAuthStore.onDispose();
	}
}

export default RootStore;
