import {
	observable,
	action,
	computed,
	reaction,
	IReactionDisposer,
} from "mobx";
import { Subscription } from "rxjs";

import { ISubscriptionRequest, Channels } from "@gp/hub";
import { OfferSubscriptionResponse } from "@gp/models";
import { Sorter, insert } from "@gp/utility";
import { EventOffer, SportOffer } from "@gp/offer";

import { IStoreLifeCycle } from "@interface";
import betRules from "@betting-rules";

import { GroupOfferStore, Options } from "../GroupOfferStore";
import { LoaderStore } from "@state/stores/common";
import { logger } from "@state";
import RootOfferStore from "@offer/stores/RootStore";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";
import FavoritesStore from "@offer/stores/FavoritesStore";
import { getCurrentCulture } from "@utils";

export default class EventViewStore
	extends GroupOfferStore
	implements IStoreLifeCycle
{
	//#region observable

	@observable public isFetchingData = false;
	@observable public isStoreInitialized = false;
	@observable public expandedEvents: string[] = [];

	@observable public eventIdsRaw = "";
	protected loader: LoaderStore;
	protected liveOfferMenuStore: LiveOfferMenuStore | undefined | null;
	public subscription: Subscription | null = null;
	public favoritesStore: FavoritesStore;
	favoritesBettingTypeReaction: IReactionDisposer | null;
	@observable favoritesBettingTypeGroups = {};
	//#endregion observable

	//#region computed

	@computed public get isMultiEvent() {
		return this.eventIds.length > 1;
	}

	@computed public get eventIds() {
		// slice ensures to remove first empty element because of leading '/'
		let currentIds = this.eventIdsRaw.split("/").slice(1);
		return currentIds.map((id) => id.split("-")[0]);
	}

	@computed public get myBetsToggleStateList(): {
		eventId: string;
		isMyBetsOpen: boolean;
	}[] {
		const currentIdsList = this.eventIdsRaw.split("/").slice(1);

		let myBetsToggleArr: { eventId: string; isMyBetsOpen: boolean }[] = [];
		currentIdsList.forEach((id) => {
			const currentIdParams = id.split("-");
			myBetsToggleArr.push({
				eventId: currentIdParams[0],
				isMyBetsOpen:
					currentIdParams[1].split("=")[1] === "true" ? true : false,
			});
		});

		return myBetsToggleArr;
	}

	@computed public get orderedEvents(): (EventOffer & {
		sport: SportOffer;
	})[] {
		// @ts-expect-error
		return this.events.sort((a, b) => {
			return this.eventIds.indexOf(b.id) - this.eventIds.indexOf(a.id);
		});
	}

	@computed public get isLoading() {
		return this.loader.isLoading;
	}

	@computed public get isEmpty() {
		return (
			this.eventsMap.size === 0 &&
			this.isStoreInitialized &&
			!this.isLoading &&
			!this.isFetchingData
		);
	}

	//#endregion computed

	//#region constructor

	constructor(
		rootStore: RootOfferStore,
		liveOfferMenuStore: LiveOfferMenuStore | undefined | null,
		favoritesStore: FavoritesStore,
		options: Options = null
	) {
		super(rootStore, options);
		this.loader = new LoaderStore();
		this.liveOfferMenuStore = liveOfferMenuStore;
		this.favoritesStore = favoritesStore;
	}

	//#endregion constructor

	//#region expand collapse event

	@action.bound
	expandEvent(eventId: string): void {
		if (this.expandedEvents.indexOf(eventId) === -1) {
			this.expandedEvents.push(eventId);
			this.onInitialize();
		}
	}

	@action.bound
	collapseEvent(eventId: string): void {
		if (this.expandedEvents.includes(eventId)) {
			// @ts-expect-error
			this.expandedEvents.remove(eventId);
			this.onInitialize();
		}
	}

	//#endregion expand collapse event

	//#region actions

	@action.bound
	handleUrls(myBetsToggleValue: boolean, eventId: string) {
		const isEventIdPresent = this.eventIds.some((eId) => eId === eventId);

		if (isEventIdPresent) {
			let currentIds = this.eventIdsRaw.split("/").slice(1);
			let eventInUrl = currentIds.find((item) => item.includes(eventId));
			if (eventInUrl != null) {
				const eventInUrlSplitted = eventInUrl.split("-");
				const toggleValue = eventInUrlSplitted[1].split("=")[1];

				this.eventIdsRaw = this.eventIdsRaw.replace(
					`${eventInUrlSplitted[0]}-myBets=${toggleValue}`,
					`${eventId}-myBets=${myBetsToggleValue}`
				);

				const culture = getCurrentCulture();
				const period = location.pathname.includes("my-favorites")
					? "my-favorites"
					: "events";

				if (window.location.pathname.includes("/live/")) {
					App.state.redirect(
						`/${culture}/live/${period}${this.eventIdsRaw}`,
						true
					);
				} else {
					let splittedPathname = window.location.pathname
						.split("/")
						.splice(1);
					splittedPathname[4] = this.eventIdsRaw.replace("/", "");
					App.state.redirect(`/${splittedPathname.join("/")}`, true);
				}
			}
		}
	}

	@action.bound
	updateEventsMap() {
		this.events.forEach((event) => {
			// Add sport to event
			// @ts-expect-error
			if (event.sport == null) {
				// @ts-expect-error
				event.sport = this.getMappedSport(event);
			}

			// Add default betting type groups to event
			// @ts-expect-error
			event.bettingTypeGroups = [];

			const eventKeys = this.eventKeysMap.get(event.id);
			// @ts-expect-error
			const favoritesBettingTypesIds = [];
			// @ts-expect-error
			if (eventKeys?.size > 0) {
				// Loop over all event keys and add there betting type group to event
				// Also add each unique betting type into betting type group
				// @ts-expect-error
				const btGroups = Array.from(eventKeys.values())
					.reduce((acc, eventKey) => {
						// if it's a favorite
						const isFavoriteBettingGroup =
							this.favoritesStore.isUserFavoriteBettingType(
								eventKey.bettingTypeId,
								eventKey.specifier || null
							);
						if (isFavoriteBettingGroup) {
							if (
								// @ts-expect-error
								favoritesBettingTypesIds.find(
									(btGroup) =>
										btGroup.abrv ===
										eventKey.bettingType.groupId
								) == null
							) {
								favoritesBettingTypesIds.push({
									...this.lookupsStore.bettingTypeGroups.get(
										// @ts-expect-error
										eventKey.bettingType.groupId
									),
									bettingTypes: [],
								});
							}

							//#endregion insert betting type group

							//#region  insert betting type into betting type group
							// @ts-expect-error
							const bettingGroup = favoritesBettingTypesIds.find(
								(btGroup) =>
									btGroup.abrv ===
									eventKey.bettingType.groupId
							);

							if (bettingGroup.bettingTypes == null) {
								bettingGroup.bettingTypes = [];
							}

							if (
								bettingGroup.bettingTypes.find(
									// @ts-expect-error
									(bt) => bt.id === eventKey.bettingTypeId
								) == null
							) {
								insert(
									bettingGroup.bettingTypes,
									eventKey.bettingType,
									Sorter.sort((a, b) => {
										const aSort =
											a?.settingsPerSport?.[event.sportId]
												?.sortOrder;
										const bSort =
											b?.settingsPerSport?.[event.sportId]
												?.sortOrder;

										const aSortOrder =
											aSort == null ? 99999 : aSort;
										const bSortOrder =
											bSort == null ? 99999 : bSort;

										return Math.sign(
											aSortOrder - bSortOrder
										);
									}, "id")
								);
							}
						}

						//#region insert betting type group

						if (
							acc.find(
								(btGroup) =>
									// @ts-expect-error
									btGroup.abrv ===
									eventKey.bettingType.groupId
							) == null
						) {
							acc.push(
								// @ts-expect-error
								this.lookupsStore.bettingTypeGroups.get(
									// @ts-expect-error
									eventKey.bettingType.groupId
								)
							);
						}

						//#endregion insert betting type group

						//#region  insert betting type into betting type group

						const bettingGroup = acc.find(
							(btGroup) =>
								// @ts-expect-error
								btGroup.abrv === eventKey.bettingType.groupId
						);

						// @ts-expect-error
						if (bettingGroup.bettingTypes == null) {
							// @ts-expect-error
							bettingGroup.bettingTypes = [];
						}

						if (
							// @ts-expect-error
							bettingGroup.bettingTypes.find(
								// @ts-expect-error
								(bt) => bt.id === eventKey.bettingTypeId
							) == null
						) {
							insert(
								// @ts-expect-error
								bettingGroup.bettingTypes,
								eventKey.bettingType,
								Sorter.sort((a, b) => {
									const aSort =
										a?.settingsPerSport?.[event.sportId]
											?.sortOrder;
									const bSort =
										b?.settingsPerSport?.[event.sportId]
											?.sortOrder;

									const aSortOrder =
										aSort == null ? 99999 : aSort;
									const bSortOrder =
										bSort == null ? 99999 : bSort;

									return Math.sign(aSortOrder - bSortOrder);
								}, "id")
							);
						}

						//#endregion insert betting type into betting type group

						return acc;
					}, [])
					.sort(Sorter.sort("sortOrder", "id"));

				// @ts-expect-error
				event.bettingTypeGroups = btGroups;
				// @ts-expect-error
				event.favoritesBettingTypesIds = favoritesBettingTypesIds.sort(
					Sorter.sort("sortOrder", "id")
				);
				this.favoritesBettingTypeGroups = {
					...this.favoritesBettingTypeGroups,
					// @ts-expect-error
					[event.id]: event.favoritesBettingTypesIds,
				};
			}
		});
		return this.events.sort((a, b) => {
			return this.eventIds.indexOf(b.id) - this.eventIds.indexOf(a.id);
		});
	}
	@action.bound
	public async updateEventIds(rawEventIds: string): Promise<void> {
		if (this.eventIdsRaw != rawEventIds) {
			const currentRawIds =
				this.eventIdsRaw.charAt(0) === "/"
					? this.eventIdsRaw.split("/").splice(1)
					: this.eventIdsRaw.split("/");
			let newRawIds = rawEventIds.split("/");

			newRawIds = newRawIds.map((rawId) => {
				const eventId = rawId.split("-")[0];
				const isRawIdFound = currentRawIds.some((item) =>
					item.includes(eventId)
				);

				if (!isRawIdFound && !rawId.includes("-myBets=")) {
					rawId = rawId + "-myBets=false";
				}

				return rawId;
			});

			this.eventIdsRaw = "/" + newRawIds.join("/");

			const culture = getCurrentCulture();
			const period = location.pathname.includes("my-favorites")
				? "my-favorites"
				: "events";

			if (window.location.pathname.includes("/live/")) {
				App.state.redirect(
					`/${culture}/live/${period}${this.eventIdsRaw}`,
					true
				);
			} else {
				let splittedPathname = window.location.pathname
					.split("/")
					.splice(1);
				splittedPathname[4] = this.eventIdsRaw.replace("/", "");
				App.state.redirect(`/${splittedPathname.join("/")}`, true);
			}
		}

		if (!this.isMultiEvent) {
			this.resetDisplayBettingGroup();
		}
		await this.onInitialize();
	}

	//#endregion actions

	//#region offer fetch

	@action.bound
	public async onInitialize(): Promise<void> {
		this.disposeSubscription();

		if (!this.eventIdsRaw || this.eventIdsRaw === "") {
			logger.logError("EventIdsRaw is empty!");
			return;
		}

		if (this.eventIdsRaw.includes("events")) {
			logger.logError(
				"EventIdsRaw is equal to 'events', could not fetch offer!"
			);
			return;
		}

		this.onStartFetching();

		//#region subscription

		let betOfferFilter: Channels["1"] = {
			name: "betOffer",
		};

		if (this.isMultiEvent) {
			if (this.expandedEvents.length > 0) {
				// @ts-expect-error
				betOfferFilter.excludeFilter = {
					id: {
						eq: this.expandedEvents,
					},
				};
			}

			const mainBettingTypes = this.OfferMapper.getBettingTypes(
				"live",
				betRules
			);
			betOfferFilter.filter = [
				{
					bettingType: {
						abrv: {
							eq: mainBettingTypes.normal,
						},
					},
				},
				{
					bettingType: {
						abrv: {
							eq: mainBettingTypes.marginal,
						},
					},
					isFavorite: true,
				},
			];
		}

		let subscriptionRequest: ISubscriptionRequest = {
			subscriptionId: `events-/${this.eventIds.join("/")}`,
			compress: WEBPACK_OFFER_COMPRESS,
			channels: [
				{
					name: "event",
					filter: {
						id: {
							eq: this.eventIds,
						},
					},
				},
				betOfferFilter,
			],
			// throttle: 3
		};

		//#endregion subscription

		this.subscription = this.rootStore.hub
			.getOfferSubscription(subscriptionRequest)
			.subscribe({ next: this.handleResponse, error: this.handleError });

		this.favoritesBettingTypeReaction = reaction(
			() =>
				JSON.stringify(this.favoritesStore.userFavoriteBettingTypesMap),

			() => {
				this.updateEventsMap();
			}
		);
	}

	@action.bound
	public handleResponse(response: OfferSubscriptionResponse): void {
		// response.offerChanges.updates?.forEach(
		// 	(e) => (e.event.liveStreamStatus = "live")
		// );
		this.assignOfferData(response);
		this.trimEvents(response);

		if (this.events.length === 0 && !this.isMultiEvent) {
			this.rootStore?.eventSwitcherViewStore?.removeEventId(
				this.eventIds.find((el) => el !== "") || ""
			);

			if (this.rootStore?.eventSwitcherViewStore?.eventIds.length >= 1) {
				/** If requested event is not in offer then we will fetch next one from event switcher.
				 * If event is removed from event switcher and there are 2 or more events left
				 * Don't stop the loader
				 * Set store state to fetching to prevent isEmpty to resolving to true
				 */
				this.onStartFetching();
				return;
			}
		}
		this.onEndFetching();
		this.updateEventsMap();
	}

	@action.bound
	public handleError(err: any): void {
		console.error(err);
		this.onEndFetching();
	}

	@action.bound
	public onStartFetching(): void {
		if (!this.isFetchingData) {
			this.loader.suspend();
			this.isFetchingData = true;
		}
	}

	@action.bound
	public onEndFetching(): void {
		this.onBeforeFetchEnd();
		this.loader.resume();
		this.isFetchingData = false;
		this.isStoreInitialized = true;
	}

	@action.bound
	protected onBeforeFetchEnd(): void {
		this.liveOfferMenuStore?.rememberSelection();
	}

	@action.bound
	private trimEvents(data: OfferSubscriptionResponse) {
		if (data.startingVersion !== 0) {
			return;
		}

		for (const [eventId, event] of this.eventsMap) {
			// If event is in selected events then don't remove
			if (
				!this.eventIds.includes(eventId) ||
				!data.offerChanges?.updates?.some((e) => e.eventId === eventId)
			) {
				this.eventsMap.delete(eventId);

				// Remove offer
				this.eventKeysMap
					.get(eventId)
					?.forEach((_, keyId) => this.keyOffersMap.delete(keyId));

				this.eventKeysMap.delete(eventId);
				continue;
			}

			// This will remove offer that is no longer showed
			for (const [eventId, eventKey] of this.eventKeysMap) {
				const eventUpdate = data.offerChanges.updates.find(
					(e) => e.eventId === eventId
				);

				eventKey.forEach((eventKeyOffer, keyId) => {
					if (
						!eventUpdate?.offers?.updates?.some(
							(u) => u.keyId === eventKeyOffer.id
						)
					) {
						this.keyOffersMap.delete(keyId);
					}
					this.eventIds;
				});
			}
		}

		// this.reset();
	}

	//#endregion offer fetch

	//#region disposers

	@action.bound
	public onDispose() {
		this.eventIdsRaw = "";
		this.favoritesBettingTypeGroups = {};
		this.resetDisplayBettingGroup();
		this.reset();
		this.isStoreInitialized = true;
		this.disposeSubscription();
		this.disposeReaction();
		/** Clear event switcher selected ids on event component unmount. */
		this.rootStore.eventSwitcherViewStore.onDispose();
	}

	@action.bound
	public disposeSubscription() {
		if (this.subscription != null) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	@action.bound
	disposeReaction() {
		//maybe it works
		if (this.favoritesBettingTypeReaction != null) {
			this.favoritesBettingTypeReaction();
			this.favoritesBettingTypeReaction = null;
		}
	}
	//#endregion disposers
}
