import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { MyBetsStoreContext } from "../../../../context";
import { logger } from "@state/providers/consoleLogger";

export default observer(function CashoutCounter() {
	const store = useContext(MyBetsStoreContext);
	const t = useTranslation().t;

	if (store == null) {
		logger.logError("Expected store got something else.");
		return null;
	}

	if (store.cashoutStore.cashoutLocation !== "my-bets") {
		return null;
	}

	if (
		store.cashoutStore.isCountdownVisible &&
		store.cashoutStore.countdownCounter > 0
	) {
		return (
			<div id="loader" className="loader--full">
				<div className="loader--countdown">
					<div className="loader--countdown__box">
						{store.cashoutStore.countdownCounter}
					</div>
				</div>
			</div>
		);
	}

	if (store.cashoutStore.isCashoutFormSubmitting) {
		return (
			<div id="loader" className="loader--full">
				<div className="loader__card">
					<div className="loader__spinner u-display--b" />
					<div className="loader__title u-type--color--white">
						<>{t("HOME.LOADING")}...</>
					</div>
				</div>
			</div>
		);
	}

	return null;
});
