import {
	isNullOrWhitespace,
	getUserFromLocalStorage,
	isUserAuthenticated,
	getCurrentCulture,
	getSecondaryUser,
} from "@utils";
import { logger } from "@state";
import { EmailChangeRequestParamsDto } from "@api-types/user/EmailChangeRequestParamsDto";
import { EmailChangeDto } from "@data-types";
// import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { getRedirectOriginUrl } from "@utils/specific/capacitor";

const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));

export class EmailChangeService {
	static async getUser(mailKey: string) {
		let currentEmail: string | void = "";

		const primaryUser = getUserFromLocalStorage();
		const secondaryUser = getSecondaryUser();
		const internetAccount = [primaryUser, secondaryUser].find(
			(acc) => acc?.isInternetUser
		);

		if (primaryUser == null) {
			console.error("Expected user, got null");
			return;
		}

		const { username, isInternetUser, isOfflineUserMailApproved } =
			primaryUser;

		if (isNullOrWhitespace(mailKey)) {
			if (
				(!isInternetUser && !isOfflineUserMailApproved) ||
				!isUserAuthenticated()
			) {
				currentEmail = "----";
			} else {
				currentEmail =
					internetAccount != null
						? internetAccount.email
						: primaryUser?.email;
			}
		} else {
			try {
				const newEmail = await (
					await AgencyUserApiService
				).default.activateEmail({
					emailChangeToken: mailKey,
					userName: username,
				});
				if (!isNullOrWhitespace(newEmail)) {
					App.state.rootStore.userAuthStore.resolveUserEmail(
						newEmail as string
					);
					currentEmail = newEmail;
				} else {
					throw {
						message: "EMAIL_ACTIVATE_ERROR",
					};
				}
			} catch (error) {
				if (error.message == "Unauthorized") {
					return false;
				}

				let errorMessage = "EMAIL_ACTIVATE_ERROR";

				if (error.data.errorCode == 4000022004) {
					errorMessage = "ACCOUNT_ALREADY_ACTIVATED";
				} else if (error.data.errorCode == 4040020000) {
					errorMessage = "CONFIRMATION_LINK_INVALID";
				}

				throw {
					data: { CurrentEmail: currentEmail },
					message: errorMessage,
				};
			}
		}

		return currentEmail;
	}

	static async checkIsEmailAvailable(emailToCheck: string): Promise<boolean> {
		try {
			const result = await (
				await AgencyUserApiService
			).default.checkIsEmailAvailable(emailToCheck);
			if (typeof result === "boolean") {
				return result;
			}
			return false;
		} catch (error) {
			logger.logError(error);
			return false;
		}
	}

	static async changeUserEmail(model: EmailChangeDto) {
		if (App.state.rootStore.userAuthStore.user == null) {
			console.error("Expected user, got null");
			throw { message: "GENERIC_ERROR" };
		}
		const userId: string = App.state.rootStore.userAuthStore.user?.id;
		const agencyId: string =
			App.state.rootStore.userAuthStore.user?.agencyId;

		if (
			model == null ||
			isNullOrWhitespace(model.newEmailAddress) ||
			isNullOrWhitespace(model.password)
		) {
			throw { message: "GENERIC_ERROR" };
		}

		try {
			const emailChangeModel: EmailChangeRequestParamsDto = {
				emailChangeUrl: `${getRedirectOriginUrl(
					`email-activation?mailkey={mailkey}&username={username}`
				)}`,
				newEmailAddress: model.newEmailAddress,
				password: model.password,
				userId: userId,
				agencyId: agencyId,
			};

			await (
				await AgencyUserApiService
			).default.changeUserEmail(emailChangeModel);
			return true;
		} catch (error) {
			if (error.message == "Unauthorized") {
				return false;
			} else if (error.data.errorCode == 400176) {
				throw { message: "INVALID_PASSWORD" };
			} else if (error.data.errorCode == 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
				throw false;
			}
			throw { message: "GENERIC_ERROR" };
		}
	}
}
