import React, { FC, useContext } from "react";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { DepositResponseItem } from "@api-types/deposit";
import { DepositContext } from "@context";
import PaymentOptionImage from "@v2/components/shared/icons/PaymentOptionImage";
import { getSVGExtension } from "@utils";
import { getPaymentMethodAbrv } from "@v2/helpers/shared";

export const DepositHistoryTableItem: FC<{
	depositHistoryItem: DepositResponseItem;
}> = observer(function DepositHistoryTableItem(props) {
	const { t } = useTranslation();
	const { depositHistoryItem } = props;

	const itemWalletAbrv = App.state.rootStore.userAuthStore.userAccounts?.find(
		(w) => w.accountTypeId === depositHistoryItem.bettingAccountTypeId
	)?.abrv;

	const endDate = DateTime.fromISO(depositHistoryItem.endDate)
		.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
		.replace(",", "");

	const startDate = DateTime.fromISO(depositHistoryItem.startDate)
		.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
		.replace(",", "");

	const statusClass = classnames(
		"u-align--v--middle u-type--case--capitalize",
		{
			"u-color--ui--success":
				depositHistoryItem.status?.abrv === "success",
			"u-color--text--primary":
				depositHistoryItem.status?.abrv === "pending",
			"u-color--ui--muted":
				depositHistoryItem.status?.abrv === "canceled",
			"u-color--ui--danger": depositHistoryItem.status?.abrv === "failed",
		}
	);

	const iconClass = classnames(
		"u-icon u-icon--xsml",
		{
			"u-icon--check u-color--ui--success":
				depositHistoryItem.status?.abrv === "success",
			"u-icon--clock": depositHistoryItem.status?.abrv === "pending",
			"u-icon--delete u-color--ui--muted":
				depositHistoryItem.status?.abrv === "canceled",
			"u-icon--remove u-color--ui--danger":
				depositHistoryItem.status?.abrv === "failed",
		},
		"u-align--v--middle u-mar--right--xtny"
	);

	const paymentMethodAbrv = getPaymentMethodAbrv(
		depositHistoryItem.paymentMethodAbrv
	);

	return (
		<>
			<tr className="table--primary__body__row">
				<td className="table--primary__body__data u-padd--xsml">
					<div>{depositHistoryItem.numericalId}</div>
				</td>
				<td className="table--primary__body__data u-padd--xsml u-type--wgt--medium">
					{depositHistoryItem.amount}
					{depositHistoryItem.currency.displayCode}
				</td>
				<td className="table--primary__body__data u-padd--xsml">
					<div className="card--payment card--payment--borderless u-mar--bottom--reset">
						<div className="card--payment__area u-padd--reset">
							<span className="card--payment__icon">
								<PaymentOptionImage
									className={`card--payment__logo u-icon u-icon--payment u-icon--payment--sml u-icon--payment--${paymentMethodAbrv}`}
									svgName={
										paymentMethodAbrv + getSVGExtension()
									}
								/>
							</span>
							<span className="card--payment__details card--payment__details--centered">
								<span className="card--payment__name u-type--wgt--regular">
									{depositHistoryItem.ccmaskedPan}
								</span>
								<span className="card--payment__type">
									{depositHistoryItem.ccexpiryDate}
								</span>
							</span>
						</div>
					</div>
				</td>
				<td className="table--primary__body__data u-padd--xsml">
					{startDate}
				</td>
				<td className="table--primary__body__data u-padd--xsml">
					{depositHistoryItem.status?.abrv === "pending"
						? "--------"
						: endDate}
				</td>
				<td className="table--primary__body__data u-padd--xsml u-type--center">
					<i
						className={`wallet__icon wallet__icon--menu u-icon u-icon--sml u-icon--wallet u-icon--wallet--${itemWalletAbrv} u-mar--right--reset u-mar--left--reset`}
					/>
				</td>
				<td className="table--primary__body__data u-padd--xsml u-type--center">
					<i className={iconClass}></i>
					<span className={statusClass}>
						{depositHistoryItem.status?.name}
					</span>
				</td>
			</tr>
		</>
	);
});
