import React, { useState, useEffect } from "react";
import { AccountStatementBetContext } from "../context/AccountStatementBetContext";
import useAccountTransactions from "@v2/hooks/account-statement/useAccountTransactions";
import useAccountStatementFilter from "@v2/hooks/account-statement/useAccountStatementFilter";
import { Params } from "@v2/data-access/account-statement/AccountStatementService";

export function AccountStatementContextProvider(props: {
	children: JSX.Element;
}) {
	const [statementFilter, setStatementFilter] = useState<Params | {}>({});
	const table = useAccountTransactions({ statementFilter });
	const filter = useAccountStatementFilter({ setStatementFilter });
	const contextValue = {
		statementFilter,
		setStatementFilter,
		...table,
		...filter,
	};

	useEffect(() => {
		if (
			Object.keys(statementFilter).length !== 0 &&
			"asPeriod" in statementFilter
		) {
			table.fetchBettingAndPaymentTableData(statementFilter);
			const indexOfPageNumber =
				window.location.search.indexOf("pageNumber=");
			if (indexOfPageNumber == -1) {
				table.fetchTransactionsTable();
				return;
			}
			let slicedSearch = window.location.search.slice(indexOfPageNumber);
			let endOfPageNumber = slicedSearch.indexOf("&");
			if (endOfPageNumber == -1) {
				table.fetchTransactionsTable(
					+slicedSearch.slice(slicedSearch.indexOf("=") + 1)
				);
				return;
			}

			table.fetchTransactionsTable(
				+slicedSearch.slice(
					slicedSearch.indexOf("=") + 1,
					slicedSearch.indexOf("&")
				)
			);
		}
	}, [statementFilter]);

	return (
		<AccountStatementBetContext.Provider value={contextValue}>
			{props.children}
		</AccountStatementBetContext.Provider>
	);
}
