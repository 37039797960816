import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { some } from "lodash";
import { ScrollDirectionContext } from "@v2/context";
import {
	Logo,
	Search,
	Language,
	HeaderUserDetails,
	Clock,
	ThemeToggle,
	HeaderIconActions,
} from ".";
import HeaderNavigation from './HeaderNavigation'
import { useAppContext } from "@v2/hooks/shared";
import { isLoginFlow } from "@v2/helpers/membership/utils";

const noLoginButtonPages = [
	"/auth/login",
	"/auth/account-activation",
	"/auth/force-password-change",
	"/auth/accept-new-terms",
	"/auth/betting-activity",
	"/offer",
	"/payment-options",
	"/auth/match-user",
	"/auth/confirm-user-data",
];

export default function Header() {
	const { culture } = useAppContext();
	const { pathname } = useLocation();
	const scrollDirection = useContext(ScrollDirectionContext);

	const user = App.state.rootStore.userAuthStore;
	const loginStore = user.loginStore;
	if (
		loginStore.isAccountActivationCongratulationsActive ||
		loginStore.accountActivationError
	) {
		return null;
	}

	if (isLoginFlow(pathname) || pathname == `/${culture}/restrict`) {
		return (
			<header className={"l--header is-" + scrollDirection}>
				<div className={"header is-" + scrollDirection}>
					<div className="header__top">
						<Clock />

						<div className="header__settings">
							<Language rounded />
							<ThemeToggle rounded />
							<div id="lockSuccessContainer" />
						</div>
					</div>

					<div className="header__main">
						<div className="header__col header__col--left">
							<figure className="header__logo">
								<Logo />
							</figure>

							<HeaderIconActions />
						</div>
					</div>
				</div>
			</header>
		);
	}

	const shouldHideLoginButton = some(noLoginButtonPages, (pageUrl) =>
		pathname.includes(pageUrl)
	);

	return (
		<header className={"l--header is-" + scrollDirection}>
			<div className={"header is-" + scrollDirection}>
				<div className="header__top">
					<Clock />

					<div className="header__settings">
						<Search />
						<Language rounded />
						<ThemeToggle rounded />
					</div>
				</div>
				<div className="header__main">
					<div className="header__col header__col--left">
						<figure className="header__logo">
							<Logo />
						</figure>

						<HeaderIconActions />
					</div>

					<div className="header__col header__col--grow">
						<HeaderNavigation />
					</div>

					<div className="header__col header__col--right">
						{!shouldHideLoginButton ? <HeaderUserDetails /> : null}
					</div>
				</div>
			</div>
		</header>
	);
}
