import React from "react";
import { useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import {
	FormInputField,
	DatePicker,
} from "@v2/components/desktop/shared/inputs";
import { FormButton } from "@components/buttons";
import { useForgotUsernameForm } from "@v2/hooks/membership";
import { EmailPattern } from "@common/constants";

export default function ForgotUsernameFormSection(props: {
	isForgotUsernameSuccess: boolean;
	setIsForgotUsernameSuccess: (value: boolean) => void;
}) {
	const { handleSubmit } = useFormContext();
	const { isForgotUsernameSuccess, setIsForgotUsernameSuccess } = props;
	const { t } = useTranslation();

	const maxDate = DateTime.now().minus({ years: 18 }).toJSDate();
	const minDate = DateTime.now().minus({ years: 100 }).toJSDate();

	const { onFormSubmit } = useForgotUsernameForm({
		setIsForgotUsernameSuccess: props.setIsForgotUsernameSuccess,
	});

	if (props.isForgotUsernameSuccess) {
		return null;
	}

	return (
		<form
			className="at-forgotUsername form card--primary"
			onSubmit={handleSubmit(onFormSubmit)}
		>
			<div className="card--primary__header">
				<div className="card--primary__title title--primary">
					{t<string>("MEMBERSHIP.FORGOT_USERNAME.FORGOT_USERNAME")}
				</div>
			</div>
			<div className="card--primary__body">
				<div className="form__group row">
					<FormInputField
						id="emailAddress"
						className="col col-sml-12 form__field"
						label="MEMBERSHIP.FORGOT_USERNAME.EMAIL"
						required
						name="email"
						placeholder="MEMBERSHIP.FORGOT_USERNAME.EMAIL_PLACEHOLDER"
						maxLength={100}
						pattern={{
							value: EmailPattern,
							message: "MEMBERSHIP.FORGOT_USERNAME.INVALID_EMAIL",
						}}
					/>
				</div>

				<div className="form__group row">
					<DatePicker
						label="MEMBERSHIP.FORGOT_USERNAME.DOB"
						name="dateOfBirth"
						className="col col--sml-12 form__field"
						maxDate={maxDate}
						minDate={minDate}
						required
					/>
				</div>
			</div>
			<div className="card--primary__footer">
				<FormButton
					className="card--primary__footer__btn btn btn--lrg btn--primary"
					btnText="MEMBERSHIP.FORGOT_USERNAME.SEND"
				/>
			</div>
		</form>
	);
}
