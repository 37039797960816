import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import classNames from "classnames";

import {
	FormInputField,
	FormSelectField,
	DatePicker,
	CheckboxField,
} from "../../shared/inputs";
import { FormButton } from "../../shared/buttons";
import {
	isPhoneValid,
	mapLanguages,
} from "@v2/helpers/account-settings/personal-data/utils";
import { PersonalDataPayPinSection } from ".";
import { usePersonalData } from "@v2/hooks/account-settings/personal-data";
import { PersonalDataResponseModel } from "src/data/types";
import {
	ErrorMessage,
	GroupedDropdownOptions,
} from "@v2/components/shared/inputs";
import { RootAppStoreContext } from "@context";
import { mapNationalityForFormSelect } from "@v2/helpers/membership/registration/utils";
import { UserStatus } from "@api-types/user/LoginResponseDto";
import {
	getCurrentCulture,
	getDefaultCountryCodeForTheme,
	isNullOrWhitespace,
} from "@utils";
import { Link } from "react-router-dom";
import { isGamingSummaryEnabled, isPersonalNumberEnabled } from "@v2/helpers";
import PhoneInput, { Country } from "react-phone-number-input";
import FormCancelButton from "../../shared/buttons/FormCancelButton";

class PersonalDataFormSectionPropTypes {
	fetchedData: PersonalDataResponseModel | null;
}

export default function PersonalDataFormSection(
	props: PersonalDataFormSectionPropTypes
) {
	const { fetchedData } = props;
	const { t } = useTranslation();
	const { userAuthStore } = useContext(RootAppStoreContext);

	const { handleDiscard, onSubmitData, updateUserPin } = usePersonalData();

	const defaultCountryCode = getDefaultCountryCodeForTheme();

	const { handleSubmit, getValues } = useFormContext();
	const isFieldDisabled =
		!userAuthStore.isPrimaryUserOnline ||
		fetchedData?.data?.insicStatus == null ||
		fetchedData?.data?.insicStatus !== UserStatus.NotChecked;
	/*Check if user is online and status is not verified or if field is empty
	and user is able to change data if any of these is false */
	function shouldDisableEmptyField(
		value: Parameters<typeof isNullOrWhitespace>[0]
	): boolean {
		if (!WEBPACK_IS_INSIC_ENABLED) {
			return false;
		}
		if (!isFieldDisabled) {
			return false;
		} else if (isNullOrWhitespace(value)) {
			return false;
		}
		return true;
	}

	const accountStatusClasses = classNames(
		"u-align--v--middle u-mar--left--tny tag",
		{
			"tag--danger":
				fetchedData?.data.insicStatus == UserStatus.NotChecked ||
				fetchedData?.data.insicStatus == UserStatus.RecheckRequired,
			"tag--warning":
				fetchedData?.data.insicStatus == UserStatus.PreChecked,
			"tag--success": fetchedData?.data.insicStatus == UserStatus.Checked,
		}
	);

	return (
		<form
			className="at-formPersonalDataForm form--grid card--primary"
			onSubmit={handleSubmit(onSubmitData)}
		>
			<div className="card--primary__header">
				<div className="card--primary__title title--primary">
					{t("USER.ACCOUNT_SETTINGS.PERSONAL_DATA.TITLE_PD")}
				</div>
			</div>
			<div>
				<div className="card--primary__body">
					<div className="form__group">
						<div className="title--secondary u-type--wgt--regular">
							<span className="u-align--v--middle">
								{t(
									"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONAL_DETAILS"
								)}
							</span>

							{userAuthStore.isPrimaryUserOnline &&
								fetchedData?.data.insicStatus != null &&
								(fetchedData?.data.insicStatus ==
									UserStatus.NotChecked ||
									fetchedData?.data.insicStatus ==
									UserStatus.Checked) && (
									<span
										className={accountStatusClasses}
										title={t(
											"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.ACCOUNT_STATUS"
										)}
									>
										{fetchedData?.data.insicStatus ==
											UserStatus.NotChecked &&
											t(
												"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.NOT_VERIFIED"
											)}

										{fetchedData?.data.insicStatus ==
											UserStatus.Checked &&
											t(
												"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.VERIFIED"
											)}
									</span>
								)}

							{fetchedData?.data.insicStatus ==
								UserStatus.RecheckRequired && (
									<Link
										to={`/${getCurrentCulture()}/user-profile-recheck`}
										className={
											accountStatusClasses +
											" u-anchor u-anchor--underline"
										}
										title={t(
											"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.ACCOUNT_STATUS"
										)}
									>
										{t(
											"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.RECHECK_REQUIRED"
										)}
									</Link>
								)}
						</div>
						{isGamingSummaryEnabled && (
							<p className="u-mar--bottom--med">
								{t(
									"specific:USER.ACCOUNT_SETTINGS.PERSONAL_DATA.ACCUMULATED_LOSSES_LINK"
								)}{" "}
								<br />
								<Link
									className="u-anchor u-anchor--underline u-anchor--primary"
									to={`/${getCurrentCulture()}/account-settings/gaming-summary`}
								>
									{t(
										"specific:USER.ACCOUNT_SETTINGS.PERSONAL_DATA.ACCUMULATED_LOSSES_TITLE"
									)}
								</Link>
							</p>
						)}
						<div className="row">
							<FormInputField
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.USERNAME"
								name="userName"
								className="col col-sml-6 form__field"
								readOnly
							/>
							<FormSelectField
								className="col col-sml-6 form__field"
								name="titleId"
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.TITLEID"
								dropdownData={
									fetchedData
										? fetchedData.userTitles
										: undefined
								}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.TITLE_PLACEHOLDER"
								disabled={isFieldDisabled}
							/>
						</div>
						<div className="row">
							<FormInputField
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.FIRSTNAME"
								name="firstName"
								className="col col-sml-6 form__field"
								disabled={isFieldDisabled}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.FIRSTNAME"
							/>
							<FormInputField
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.LASTNAME"
								name="lastName"
								className="col col-sml-6 form__field"
								disabled={isFieldDisabled}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.LASTNAME"
							/>
						</div>
						<div className="row">
							<DatePicker
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.DOB"
								name="dob"
								className="col col-sml-6 form__field"
								disabled={isFieldDisabled}
								readOnly={isFieldDisabled}
								hasBorder={true}
							/>
							{!WEBPACK_IS_INSIC_ENABLED ||
								isPersonalNumberEnabled ? null : (
								<FormInputField
									label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.BIRTHPLACE"
									name="birthPlace"
									className="col col-sml-6 form__field"
									disabled={shouldDisableEmptyField(
										fetchedData?.data.birthPlace
									)}
									placeholder={
										"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.BIRTHPLACE"
									}
								/>
							)}
							{isPersonalNumberEnabled && (
								<FormInputField
									label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONALIDENTIFICATOR"
									name="personalIdentificator"
									className="col col-sml-6 form__field"
									disabled={isFieldDisabled}
									placeholder={
										"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONALIDENTIFICATOR"
									}
								/>
							)}
						</div>
						<div className="row">
							{!WEBPACK_IS_INSIC_ENABLED ||
								isPersonalNumberEnabled ? null : (
								<FormInputField
									label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.BIRTHNAME"
									name="birthName"
									className="col col-sml-6 form__field"
									disabled={shouldDisableEmptyField(
										fetchedData?.data.birthName
									)}
									placeholder={
										"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.BIRTHNAME"
									}
								/>
							)}
						</div>
					</div>
					<div className="form__group">
						<div className="title--secondary u-type--wgt--regular">
							{t("USER.ACCOUNT_SETTINGS.PERSONAL_DATA.ADDRESS")}
						</div>
						<div className="row">
							<FormSelectField
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.COUNTRYID"
								name="countryId"
								className="col col-sml-6 form__field"
								dropdownData={
									fetchedData
										? fetchedData.countries
										: undefined
								}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.COUNTRY_PLACEHOLDER"
								disabled={shouldDisableEmptyField(
									fetchedData?.data.city
								)}
							/>
							<FormInputField
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.CITY"
								name="city"
								className="col col-sml-6 form__field"
								disabled={shouldDisableEmptyField(
									fetchedData?.data.city
								)}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.CITY"
							/>
						</div>
						<div className="row">
							<FormInputField
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.STREET"
								name="street"
								className="col col-sml-6 form__field"
								disabled={shouldDisableEmptyField(
									fetchedData?.data.street
								)}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.STREET"
							/>
							<FormInputField
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.ZIPCODE"
								name="zipCode"
								className="col col-sml-6 form__field"
								disabled={shouldDisableEmptyField(
									fetchedData?.data.zipCode
								)}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.ZIPCODE"
							/>
						</div>
						<div className="row">
							<FormInputField
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.HOUSENUMBER"
								name="houseNumber"
								className="col col-sml-6 form__field"
								disabled={shouldDisableEmptyField(
									fetchedData?.data.houseNumber
								)}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.HOUSENUMBER"
							/>
							{!WEBPACK_IS_INSIC_ENABLED ||
								isPersonalNumberEnabled ? null : (
								<FormSelectField
									label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.STATE"
									name="state"
									dropdownData={
										fetchedData
											? fetchedData.countryRegions
											: undefined
									}
									placeholder="MEMBERSHIP.REGISTRATION.STATE_PLACEHOLDER"
									className="col col-sml-6 form__field"
									readOnly={shouldDisableEmptyField(
										fetchedData?.data.state
									)}
									disabled={shouldDisableEmptyField(
										fetchedData?.data.state
									)}
								/>
							)}
						</div>
					</div>
					<div className="form__group">
						<div className="title--secondary u-type--wgt--regular">
							{t(
								"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.CONTACT_DETAILS"
							)}
						</div>
						{!WEBPACK_IS_INSIC_ENABLED ? (
							<div className="row">
								<FormInputField
									label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PHONE"
									name="telephoneNumber"
									className="col col-sml-6 form__field"
									type="tel"
									placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PHONE"
									pattern={{
										value: /^\+[1-9]{1}[0-9]{6,40}/,
										message: t(
											"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.INVALID_PHONE"
										),
									}}
								/>
							</div>
						) : (
							<div className="row">
								<FormInputField
									label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.CURRENTEMAIL"
									name="currentEmail"
									className="col col-sml-6 form__field"
									disabled
								/>
							</div>
						)}
					</div>
					<div className="form__group">
						<div className="row">
							<div className="col col-sml-6 form__field">
								<label className="label">
									{t<string>(
										"MEMBERSHIP.REGISTRATION.MOBILE_PHONE_LABEL"
									)}
								</label>
								<ErrorMessage name="mobilePhone" />
								<Controller
									name="mobilePhone"
									rules={{
										validate: {
											isPhoneValid: (e) => {
												const disabled =
													!WEBPACK_IS_INSIC_ENABLED
														? true
														: !isNullOrWhitespace(
																fetchedData
																	?.data
																	.mobilePhone
														  );

												if (
													defaultCountryCode !=
														"DK" &&
													disabled == true
												) {
													return true;
												}

												return isPhoneValid(e)
													? true
													: "USER.ACCOUNT_SETTINGS.PERSONAL_DATA.INVALID_PHONE";
											},
										},
									}}
									render={({ field }) => {
										return (
											<PhoneInput
												{...field}
												international
												defaultCountry={
													defaultCountryCode as Country
												}
												onChange={field.onChange}
												value={getValues("mobilePhone")}
												disabled={
													!WEBPACK_IS_INSIC_ENABLED
														? false
														: !isNullOrWhitespace(
																fetchedData
																	?.data
																	.mobilePhone
														  )
												}
											/>
										);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="form__group">
						<div className="row">
							<FormSelectField
								className="col col-sml-6 form__field"
								name="language"
								label={t(
									"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.LANGUAGE"
								)}
								dropdownData={mapLanguages(
									fetchedData
										? fetchedData.languages
										: undefined
								)}
								placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.LANGUAGE_PLACEHOLDER"
							/>
						</div>
					</div>

					{userAuthStore.isPrimaryUserOnline &&
						WEBPACK_IS_INSIC_ENABLED &&
						!isPersonalNumberEnabled && (
							<>
								<div className="form__group">
									<div className="title--secondary u-type--wgt--regular">
										{t("HOME.OTHER")}
									</div>
									<div className="row">
										<FormSelectField
											label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.NATIONALITY"
											name="nationality"
											className="col col-sml-6 form__field"
											dropdownData={
												fetchedData
													? mapNationalityForFormSelect(
														fetchedData.countries
													)
													: undefined
											}
											placeholder={
												"MEMBERSHIP.REGISTRATION.NATIONALITY_PLACEHOLDER"
											}
										/>
									</div>
								</div>
								<div className="form__group">
									<div className="row">
										<GroupedDropdownOptions
											label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.OCCUPATION"
											name="occupation"
											placeholder="MEMBERSHIP.REGISTRATION.OCCUPATION_PLACEHOLDER"
											className="col col-sml-6 form__field"
											groupData={
												fetchedData
													? fetchedData.occupationGroupData
													: undefined
											}
											data={
												fetchedData
													? fetchedData.occupationData
													: undefined
											}
										/>
									</div>
								</div>
							</>
						)}
					<PersonalDataPayPinSection
						payPin={getValues("payPin")}
						updateUserPin={updateUserPin}
					/>

					<div className="form__group">
						<div className="row">
							<CheckboxField
								checkboxClassname="col col-sml-12 form__field"
								id="receiveNewsletter"
								name="receiveNewsletter"
								label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.RECEIVENEWSLETTER"
							/>
						</div>
					</div>
				</div>
				<div className="card--primary__footer">
					<FormButton
						btnText="COMMON.NEXT_BUTTON"
						btnContent={
							<i className="u-icon u-icon--xsml u-icon--arrow--right u-color--core--snow" />
						}
						className="card--primary__footer__btn btn--lrg btn--primary btn--icon"
					/>

					<FormCancelButton
						btnText="COMMON.DISCARD_BUTTON"
						btnType="button"
						className="card--primary__footer__btn btn--lrg btn--secondary--inverted"
						onClick={handleDiscard}
					/>
				</div>
			</div>
		</form>
	);
}
