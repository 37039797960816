import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { betPeriods } from "@administration/pages/my-bets/BetPeriods";

export const DepositPeriodsFilterSection: FC = observer(
	function DepositPeriodsFilterSection() {
		const { register } = useFormContext();

		const { t } = useTranslation();

		return (
			<div className="form__group">
				<label htmlFor="periodSelect" className="label">
					{t<string>("DEPOSIT.SELECT_PERIOD")}
				</label>
				<select
					id="periodSelect"
					className="input input--base input--select"
					{...register("period")}
				>
					{betPeriods.map((betPeriod) => (
						<option key={betPeriod.value} value={betPeriod.value}>
							{t<string>(`DEPOSIT.PERIODS.${betPeriod.name}`)}
						</option>
					))}
				</select>
			</div>
		);
	}
);
