import React, { useContext } from "react";
import { range } from "lodash";

import { ResultContext, ResultTournamentContext } from "@context/results";
import { ScoreType } from "@common/constants/ScoreType";
import { ScoreType as ScoreTypeEnum } from "@utils/common";

export const ScoreHeaderRenderer = function ScoreHeaderRenderer() {
	const result = useContext(ResultContext);

	if (
		result.scoreType === ScoreType.HEAD2HEAD ||
		result.scoreType === ScoreType.HEAD2HEADWITHOT
	) {
		return <HeadToHead />;
	}

	if (
		result.scoreType === ScoreType.FTONLY ||
		result.scoreType === ScoreType.FTONLYWITHOT
	) {
		return <FullTime />;
	}

	return <Periods />;
};

const HeadToHead = function HeadToHead() {
	return (
		<>
			<th className="table--results__subheader__data table--results__subheader__data--ft">
				{ScoreTypeEnum.FullTime}
			</th>
			<th className="table--results__subheader__data table--results__subheader__data--ht">
				{ScoreTypeEnum.HalfTime}
			</th>
			<OverTimeHeader />
		</>
	);
};

const FullTime = function FullTime() {
	return (
		<>
			<th className="table--results__subheader__data table--results__subheader__data--ft">
				{ScoreTypeEnum.FullTime}
			</th>
			<OverTimeHeader />
		</>
	);
};

const Periods = function Periods() {
	return (
		<>
			<th className="table--results__subheader__data table--results__subheader__data--ft">
				{ScoreTypeEnum.FullTime}
			</th>
			<PeriodHeaders />
			<OverTimeHeader />
		</>
	);
};

const PeriodHeaders = function PeriodHeaders() {
	const result = useContext(ResultContext);
	const tournament = useContext(ResultTournamentContext);

	if (result.columnsCount == null) {
		return null;
	}

	return (
		<>
			{range(result.columnsCount).map((item, idx) => {
				return (
					<th
						key={`${tournament.id}-tsh-${idx}`}
						className="table--results__subheader__data table--results__subheader__data--period"
					>{`P${item + 1}`}</th>
				);
			})}
		</>
	);
};

const OverTimeHeader = function OverTimeHeader() {
	const result = useContext(ResultContext);

	if (
		result.scoreType != ScoreType.HEAD2HEADWITHOT &&
		result.scoreType != ScoreType.FTONLYWITHOT &&
		result.scoreType != ScoreType.PERPERIODWITHOT
	) {
		return null;
	}

	return (
		<th className="table--results__subheader__data table--results__subheader__data--otap">
			{ScoreTypeEnum.Overtime}
		</th>
	);
};
