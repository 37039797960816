import { constants, MainOfferStore } from "@gp/offer";
import { EventKeyBettingOffer } from "@gp/models";

export type TipType =
	| {
			isMargin: true;
			margin?: string;

			isResult?: false;
			result?: never;

			tip?: EventKeyBettingOffer;
	  }
	| {
			isMargin?: false;
			margin?: never;

			isResult: true;
			result: string;

			tip?: EventKeyBettingOffer;
	  }
	| {
			isMargin?: false | never;
			margin?: never;

			isResult?: false | never;
			result?: never;

			tip?: EventKeyBettingOffer;
	  };

export function useGetColumnTipProps(
	offerStore: MainOfferStore,
	offerMap: Map<string, EventKeyBettingOffer>,
	tips: string[],
	isMarginal: boolean,
	hasFourthTip: boolean
) {
	const offers = Array.from(offerMap.values());

	let leftTip: TipType;
	let middleTip: TipType;
	let rightTip: TipType;
	let farRightTip: EventKeyBettingOffer | undefined;

	if (isMarginal) {
		leftTip = {
			isMargin: true,
			margin: undefined,
		};

		if (offers[0].specifier != undefined) {
			leftTip.margin = offerStore.getMarginByOffer(offers[0]);
		} else {
			leftTip.tip = offers.find((o) =>
				o.tip.toLowerCase().startsWith(tips[0])
			);
		}

		middleTip = {
			isMargin: false,
			tip: offers.find((o) => o.tip.toLowerCase().startsWith(tips[1])),
		};
		rightTip = {
			isMargin: false,
			tip: offers.find((o) => o.tip.toLowerCase().startsWith(tips[2])),
		};

		if (hasFourthTip) {
			farRightTip = offers.find((o) =>
				o.tip.toLowerCase().startsWith(tips[3])
			);
		}
	} else {
		leftTip = {
			isMargin: false,
			tip: offers.find((o) => o.tip.toLowerCase().startsWith(tips[0])),
		};

		if (
			[constants.setnrPlaceholder, constants.gamenrPlaceholder].includes(
				tips[1]
			)
		) {
			middleTip = {
				isResult: true,
				result: offers[0]?.specifier?.[tips[1]] + ". set",
			};
		} else if ([constants.framenrPlaceholder].includes(tips[1])) {
			middleTip = {
				isResult: true,
				result: offers[0]?.specifier?.[tips[1]] + ". frame",
			};
		} else {
			middleTip = {
				isMargin: false,
				tip: offers.find((o) =>
					o.tip.toLowerCase().startsWith(tips[1])
				),
			};
		}

		rightTip = {
			isMargin: false,
			tip: offers.find((o) => o.tip.toLowerCase().startsWith(tips[2])),
		};

		if (hasFourthTip) {
			farRightTip = offers.find((o) =>
				o.tip.toLowerCase().startsWith(tips[3])
			);
		}
	}

	return {
		leftTip,
		middleTip,
		rightTip,
		farRightTip,
	};
}
