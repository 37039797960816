import { action, computed, observable, autorun, runInAction } from "mobx";
import { RawStructure, FormattedUrlSegments } from "./NodeTypes";
import CheckStateEnum from "./CheckStateEnum";

export default class NodeStore<
	NodeType extends {
		id: string;
		count: number;
		type: string;
		sportId?: string;
		sportCategoryId?: string;
	}
> {
	private defaultEmptyNode: NodeType;
	@observable private rootParent: NodeStore<NodeType> | null;
	@observable public selectedItemsSet: Set<NodeStore<NodeType>> | null;
	@observable public shadowSelectedItemsSet: Set<NodeStore<NodeType>> | null;

	@observable public parent: NodeStore<NodeType> | null | undefined = null;
	// @ts-expect-error
	@observable public node: NodeType = { id: "" };
	@observable public children: NodeStore<NodeType>[] = [];

	@observable public isExpanded = false;
	@observable public shadowIsExpanded = false;
	@computed protected get checked(): boolean {
		if (
			this.rootParent != null &&
			this.rootParent?.selectedItemsSet == null
		) {
			console.error("Root node does not have selectedItemsSet");
		}
		return this.rootParent?.selectedItemsSet?.has(this) || false;
	}

	@computed public get selectedItems() {
		const selection: {
			sports: string[];
			categories: string[];
			tournaments: string[];
			specials: string[];
		} = {
			sports: [],
			categories: [],
			tournaments: [],
			specials: [],
		};

		if (this.selectedItemsSet == null) {
			return selection;
		}

		for (const selectedNode of this.selectedItemsSet) {
			if (
				selectedNode.node.type == "sport" ||
				selectedNode.node.type == "sport-all"
			) {
				selection.sports.push(selectedNode.node.id);
				continue;
			}

			if (
				selectedNode.node.type == "category" ||
				selectedNode.node.type == "category-all"
			) {
				selection.categories.push(selectedNode.node.id);
				continue;
			}

			if (selectedNode.node.type == "tournament") {
				if (selectedNode.node.id == "special") {
					if (selectedNode.parent?.node.id == null) {
						console.error("Special does not have parent.");
						continue;
					}
					selection.specials.push(selectedNode.parent.node.id);
					continue;
				}

				selection.tournaments.push(selectedNode.node.id);
				continue;
			}

			console.log(
				"I don't know what to do with " +
					selectedNode.node.type +
					" node type."
			);
		}

		return selection;
	}
	protected set checked(value: boolean) {
		if (
			this.rootParent != null &&
			this.rootParent?.selectedItemsSet == null
		) {
			console.error("Root node does not have selectedItemsSet");
		}
		runInAction(() => {
			if (value) {
				let item: NodeStore<NodeType> | null = this;
				while (item.parent != null) {
					item.parent?.selectedItemsSet?.add(this);
					item = item.parent;
				}
			} else {
				// delete tournament
				this.rootParent?.selectedItemsSet?.delete(this);
				// delete category
				let category = null;
				if (this.node.type == "tournament") {
					category = this.parent;
				}

				const deleteTournament = this;
				let item: NodeStore<NodeType> | null = this;
				while (item.parent != null) {
					item.parent?.selectedItemsSet?.delete(deleteTournament);
					if (category) {
						item?.parent?.selectedItemsSet?.delete(category);
					}
					item?.parent?.selectedItemsSet?.delete(item);

					item = item.parent;
				}
			}
		});
	}
	@computed protected get shadowChecked(): boolean {
		if (
			this.rootParent != null &&
			this.rootParent?.shadowSelectedItemsSet == null
		) {
			console.error("Root node does not have selectedItemsSet");
		}
		return this.rootParent?.shadowSelectedItemsSet?.has(this) || false;
	}
	protected set shadowChecked(value: boolean) {
		if (
			this.rootParent != null &&
			this.rootParent?.shadowSelectedItemsSet == null
		) {
			console.error("Root node does not have selectedItemsSet");
		}
		runInAction(() => {
			if (value) {
				this.rootParent?.shadowSelectedItemsSet?.add(this);
			} else {
				this.rootParent?.shadowSelectedItemsSet?.delete(this);
				let item: NodeStore<NodeType> | null = this;
				while (item.parent != null) {
					this.rootParent?.shadowSelectedItemsSet?.delete(
						item.parent
					);
					item = item.parent;
					if (item.isRoot) {
						break;
					}
				}
			}
		});
	}

	//#endregion observable

	//#region computed

	@computed public get nodesBySelectionOrder(): NodeStore<NodeType>[] {
		if (this.selectedItemsSet == null) {
			console.error("This is available ony on root menu node.");
			return [];
		}

		return Array.from(this.selectedItemsSet.values())
			.filter(
				(
					i // if parent is checked skip that node and take category only eg [englandId, premleague1,premleague2] take only england but if [premleague1,premleague2] take ids
				) => (i.parent != null && !i.parent.checked) || i.parent == null
			)
			.reverse();
	}

	@computed public get shadowNodesBySelectionOrder(): NodeStore<NodeType>[] {
		if (!this.isRoot || this.shadowSelectedItemsSet == null) {
			console.error("This is available ony on root menu node.");
			return [];
		}

		return Array.from(this.shadowSelectedItemsSet.values())
			.filter(
				(i) =>
					(i.parent != null && !i.parent.checked) || i.parent == null
			)
			.reverse();
	}

	/** @returns True if it has no parent. */
	@computed public get isRoot(): boolean {
		return this.parent == null;
	}

	/** @returns true if node is parent. */
	@computed private get isParent(): boolean {
		return this.children.length > 0;
	}

	/** true if node does not have children. */
	@computed public get isLeaf(): boolean {
		return !this.isParent;
	}

	/** @returns True if node has parent. */
	@computed private get isChild(): boolean {
		return this.parent != null;
	}

	@computed public get leafNodeIds(): string[] {
		if (this.isLeaf) {
			let id = this.node.id;
			if (id === "special") {
				id = this.parent?.node.id + "-" + id;
			}
			return [id];
		}

		return this.children.reduce<string[]>((acc, child) => {
			acc.push(...child.leafNodeIds);
			return acc;
		}, []);
	}

	@computed public get checkedLeafNodeIds(): string[] {
		if (this.isLeaf) {
			if (this.checkState > 0) {
				return [this.node.id];
			} else {
				return [];
			}
		}

		return this.children.reduce<string[]>((acc, child) => {
			acc.push(...child.checkedLeafNodeIds);
			return acc;
		}, []);
	}

	@computed public get checkState(): CheckStateEnum {
		if (this.isLeaf) {
			return this.checked
				? CheckStateEnum.checked
				: CheckStateEnum.unchecked;
		}

		if (this.isEveryChildChecked) {
			return CheckStateEnum.checked;
		}

		if (this.isSomeChildChecked) {
			return CheckStateEnum.indeterminate;
		}

		return CheckStateEnum.unchecked;
	}

	/** @returns True if every child node is checked. */
	@computed private get isEveryChildChecked(): boolean {
		return this.children.every((c) => c.checkState === 1);
	}

	/** @returns  True if at least one child node is checked. */
	@computed private get isSomeChildChecked(): boolean {
		return this.children.some((c) => c.checkState > 0);
	}

	@computed public get TotalLeafNodeCount(): number {
		if (this.isLeaf) {
			return 1;
		}

		return this.children.reduce((acc, child) => {
			return (acc += child.TotalLeafNodeCount);
		}, 0);
	}

	/** @returns url segments */
	@computed get urlStructure() {
		let result = "";

		if (this.checkState === CheckStateEnum.unchecked) {
			return "";
		}

		const map = new Map();
		for (let node of this.nodesBySelectionOrder) {
			const getSportId =
				(node.node.type == "sport" && node.node.id) ||
				node.node.sportId ||
				node.parent?.node.sportId;
			if (getSportId && !map.has(getSportId)) {
				map.set(getSportId, 1);
			}
		}

		const sorted = [...this.children];
		const keysOfMap = [...map.keys()];
		while (keysOfMap.length) {
			let sportsCategoryId = keysOfMap.pop();
			sorted.sort((a, b) => {
				if (a.node.id == sportsCategoryId) {
					return -1;
				} else if (b.node.id == sportsCategoryId) {
					return 1;
				}
				return 0;
			});
		}
		const stack = sorted.reverse();

		while (stack.length > 0) {
			const currentNode = stack.pop();
			if (!currentNode) {
				continue;
			}
			if (currentNode.checkState === CheckStateEnum.unchecked) {
				continue;
			}
			const sortEvents = [...currentNode.children];

			switch (currentNode.node.type) {
				case "sport": {
					result += `/${currentNode.node.id}`;
					const categoryMap = new Map();
					for (let categoryNodes of currentNode.nodesBySelectionOrder) {
						let getCategoryId;
						if (
							categoryNodes.node.id == "special" &&
							categoryNodes.parent?.node.id
						) {
							getCategoryId = categoryNodes.parent.node.id;
						} else if (
							categoryNodes.node.type == "tournament" &&
							categoryNodes.node.sportCategoryId
						) {
							getCategoryId = categoryNodes.node.sportCategoryId;
						} else if (
							categoryNodes.node.type == "category" &&
							categoryNodes?.node?.id
						) {
							getCategoryId = categoryNodes.node.id;
						}

						if (getCategoryId && !categoryMap.has(getCategoryId)) {
							categoryMap.set(getCategoryId, 1);
						}
					}

					if (categoryMap.size == 0) {
						sortEvents.reverse();
					}

					const keysOfMap = [...categoryMap.keys()].reverse();
					while (keysOfMap.length) {
						let sportsCategoryId = keysOfMap.pop();
						sortEvents.sort((a, b) => {
							if (a.node.id == sportsCategoryId) {
								return -1;
							} else if (b.node.id == sportsCategoryId) {
								return 1;
							}
							return 0;
						});
					}
					break;
				}
				case "category": {
					const selectedNodes = [
						...currentNode.nodesBySelectionOrder,
					].map((val) => val.node.id);
					while (selectedNodes.length) {
						let sportsCategoryId = selectedNodes.pop();
						sortEvents.sort((a, b) => {
							if (a.node.id == sportsCategoryId) {
								return -1;
							} else if (b.node.id == sportsCategoryId) {
								return 1;
							}
							return 0;
						});
					}
					sortEvents.reverse();
					result += `|${currentNode.node.id}`;
					break;
				}
				case "tournament":
					result += `-${currentNode.node.id}`;
					break;
				default:
				// Should not happen
			}

			sortEvents.forEach((child) => {
				stack.push(child);
			});
		}

		return result;

		// #########################################################################################
		// #
		// ## In case of too long url segments on sports page revert to code below instead of
		// ## `result += this.children...`
		// ## that is down below it
		// #
		// #########################################################################################

		// if (this.checkState === CheckStateEnum.indeterminate) {
		// 	result += this.children.reduce(
		// 		(acc, child) => (acc += child.urlStructure),
		// 		""
		// 	);
		// }

		// return result

		// just in case we want to continue to develop recursive solution for creating urls
		/* 	result += this.children
			.sort((a, b) => {
				const aIdx =
					this.rootParent?.nodesBySelectionOrder.findIndex(
						(node) => node.node.id === a.node.id
					) || 9999999;
				const bIdx =
					this.rootParent?.nodesBySelectionOrder.findIndex(
						(node) => node.node.id === b.node.id
					) || 9999999;

				return bIdx - aIdx;
			})
			.reduce((acc, child) => (acc += child.urlStructure), "");
		return result; */
	}

	/** @returns only children that are shadow selected */
	@computed public get selectedShadowSubStructure(): NodeStore<NodeType>[] {
		if (this.isLeaf) {
			return [];
		}

		// If there are no children selected return all
		// This is when selection in menu is cleared
		if (!this.isSomeChildShadowChecked) {
			return this.children;
		}

		return this.children.filter((child) => child.shadowCheckState > 0);
	}

	/** @returns  count of selected leaf nodes */
	@computed public get shadowSelectedLeafNodeCount() {
		return this.shadowSelectedLeafNodeIds.length;
	}

	/** @returns array of selected leaf node ids */
	@computed public get shadowSelectedLeafNodeIds(): string[] {
		// TODO maybe this should map over shadowSelectedLeafNodeIds and return there ids?
		// That way we cache both? like shadowSelectedLeafNodesSegments
		if (this.isLeaf) {
			if (this.shadowCheckState === CheckStateEnum.checked) {
				if (this.node.id === "special") {
					return [this.parent?.node.id + "-special"];
				} else {
					return [this.node.id];
				}
			} else {
				return [];
			}
		}

		return this.children.reduce<string[]>((acc, child) => {
			return acc.concat(child.shadowSelectedLeafNodeIds);
		}, []);
	}

	@computed public get shadowSelectedLeafNodes(): NodeStore<NodeType>[] {
		if (this.isLeaf) {
			if (this.shadowCheckState === CheckStateEnum.checked) {
				return [this];
			} else {
				[];
			}
		}

		return this.children.reduce<NodeStore<NodeType>[]>((acc, child) => {
			return acc.concat(child.shadowSelectedLeafNodes);
		}, []);
	}

	@computed public get shadowSelectedLeafNodesSegments(): string[] {
		return this.shadowSelectedLeafNodes.map((tournamentNode) => {
			const catNode = tournamentNode.parent;
			const sportNode = tournamentNode.parent?.parent;

			if (catNode == null || sportNode == null) {
				// Maybe should throw here?
				console.error(
					"cat node or sport node could be null",
					catNode,
					sportNode
				);
				return "";
			}

			if (tournamentNode?.node.type === "sport-all") {
				return `${catNode.node.id}`;
			}

			if (tournamentNode.node.type === "category-all") {
				return `${sportNode.node.id}|${catNode.node.id}`;
			}

			return `${sportNode.node.id}|${catNode.node.id}-${tournamentNode.node.id}`;
		});
	}

	@computed private get leafNode(): NodeStore<NodeType>[] {
		if (this.isLeaf) {
			return [this];
		}

		return this.children.reduce<NodeStore<NodeType>[]>((acc, child) => {
			return acc.concat(child.leafNode);
		}, []);
	}

	@computed public get leafNodeSegments(): string[] {
		return this.leafNode.map((tournamentNode) => {
			const catNode = tournamentNode.parent;
			const sportNode = tournamentNode.parent?.parent;

			if (catNode == null || sportNode == null) {
				// Maybe should throw here?
				console.error(
					"cat node or sport node could be null",
					catNode,
					sportNode
				);
				return "";
			}

			if (tournamentNode.node.type === "sport-all") {
				return `${catNode.node.id}`;
			}

			if (tournamentNode.node.type === "category-all") {
				return `${sportNode.node.id}|${catNode.node.id}`;
			}

			return `${sportNode.node.id}|${catNode.node.id}-${tournamentNode.node.id}`;
		});
	}

	@computed public get shadowCheckState() {
		if (this.isLeaf) {
			return this.shadowChecked
				? CheckStateEnum.checked
				: CheckStateEnum.unchecked;
		}

		if (this.isEveryChildShadowChecked) {
			return CheckStateEnum.checked;
		}

		if (this.isSomeChildShadowChecked) {
			return CheckStateEnum.indeterminate;
		}

		return CheckStateEnum.unchecked;
	}

	/** @returns True if every child node is checked. */
	@computed private get isEveryChildShadowChecked(): boolean {
		return this.children.every((c) => c.shadowCheckState === 1);
	}

	/** @returns True if at least one child node is checked. */
	@computed private get isSomeChildShadowChecked(): boolean {
		return this.children.some((c) => c.shadowCheckState > 0);
	}

	//#endregion computed

	//#region assign data

	@action.bound
	public assignStructure(rawStructure: RawStructure<NodeType>): void {
		if (rawStructure == null) {
			return;
		}

		const { n, c } = rawStructure;

		this.assignChildren(c);

		this.assignNodeData(n);
	}

	@action.bound
	private assignChildren(
		children: RawStructure<NodeType>[] | undefined
	): void {
		if (children == null) {
			this.children = [];
			return;
		}

		this.pruneChildren(children);

		// When ever assigning children assign them in order received
		// Children come sorted
		// We don't want to recreate children structure for non updated nodes to prevent rerenders
		const newMyChildrenSort = [];

		for (const childItem of children) {
			const { n } = childItem;

			const childInMyChildren = this.children.find(
				(child) => child.node.id === n.id
			);
			if (childInMyChildren != null) {
				childInMyChildren.assignStructure(childItem);
				newMyChildrenSort.push(childInMyChildren);
				continue;
			}

			this.insertNewChild(childItem, newMyChildrenSort);
		}

		this.children = newMyChildrenSort;
	}

	/**
	 * @param childItem to be inserted into children array
	 * @param childrenArray array into which to add new child.
	 * */
	@action.bound
	private insertNewChild(
		childItem: RawStructure<NodeType>,
		childrenArray: NodeStore<NodeType>[]
	): void {
		const newChild = new NodeStore<NodeType>(
			this.defaultEmptyNode,
			this.rootParent || this,
			this
		);
		newChild.assignStructure(childItem);

		childrenArray.push(newChild);
	}

	/**
	 * @param freshChildren remove all children of current node that are not in freshChildren
	 * Compares by child.n.id
	 */
	@action.bound
	private pruneChildren(freshChildren: RawStructure<NodeType>[]): void {
		for (let i = this.children.length - 1; i >= 0; i--) {
			const currentChild = this.children[i];

			if (currentChild == null) {
				continue;
			}

			const childInFreshWithCurrentChildId = freshChildren.find(
				({ n }) => n.id === currentChild.node.id
			);

			if (childInFreshWithCurrentChildId == null) {
				const deletedChildren = this.children.splice(i, 1);
				for (const deletedChild of deletedChildren) {
					deletedChild.onDispose();
				}
			}
		}
	}

	/** @param freshNodeData assigns node data to this.node */
	@action.bound
	private assignNodeData(freshNodeData: RawStructure<NodeType>["n"]): void {
		// Update only node count since the rest of the data does not change for the node on update
		if (this.node == null || this.node.count == null) {
			this.node = freshNodeData;
			return;
		}
		this.node.count = freshNodeData.count;
		return;
	}

	//#endregion assign data

	constructor(
		defaultEmptyNode: NodeType,
		rootParent?: NodeStore<NodeType>,
		parent?: NodeStore<NodeType>
	) {
		this.parent = parent;
		this.defaultEmptyNode = defaultEmptyNode;
		this.rootParent = rootParent || null;

		this.selectedItemsSet = new Set();
		this.shadowSelectedItemsSet = new Set();

		if (this.selectedItemsSet != null) {
			/* 
			in case of debuggin just uncomment to get selection orders and checked menu items
			autorun(() => {
				// @ts-expect-error
				console.log(this.nodesBySelectionOrder.map((n) => n.node.name));
				console.log(
					// @ts-expect-error
					this.shadowNodesBySelectionOrder.map((n) => n.node.name)
				);
				console.log(JSON.stringify(this.selectedItems));
			}); */
		}
		if (this.parent != null && !this.parent.isRoot) {
			this.checked = this.parent.checkState === CheckStateEnum.checked;
			this.shadowChecked =
				this.parent.shadowCheckState === CheckStateEnum.checked;
		}
	}

	//#region node actions

	/** Set check state. */
	@action.bound
	public onCheck(value: boolean) {
		this.checked = value;
		this.children.reverse().forEach((child) => {
			child.onCheck(value);
		});
	}

	@action.bound
	public onToggleCheck() {
		if (this.checkState === 0 || this.checkState === 2) {
			this.onCheck(true);
		} else {
			this.onCheck(false);
		}
	}

	/**
	 * @param {object} nodeCheckState has matching structure to node store.
	 * It is used to set check states of nodes based on it.
	 * */
	@action.bound
	public resetCheckState(
		nodeCheckState:
			| FormattedUrlSegments
			| FormattedUrlSegments["children"][0]
			| undefined
	) {
		if (nodeCheckState == null) {
			this.onCheck(false);
			this.onShadowCheck(false);
			return;
		}

		if (nodeCheckState.children == null) {
			this.onCheck(true);
			this.onShadowCheck(true);
			return;
		}

		for (const childCheckState of nodeCheckState.children.reverse()) {
			this.children
				.find((child) => child.node.id === childCheckState.id)
				?.resetCheckState(childCheckState);
		}
	}

	/** Set check state. */
	@action.bound
	public onShadowCheck(value: boolean) {
		this.shadowChecked = value;
		this.checked = value;

		this.children.reverse().forEach((child) => {
			child.onShadowCheck(value);
		});
	}

	@action.bound
	public onShadowToggleCheck() {
		if (this.checkState === 0 || this.checkState === 2) {
			this.onShadowCheck(true);
		} else {
			this.onShadowCheck(false);
		}
	}

	/**
	 * Sets shadowCheck value to checked value
	 */
	@action.bound
	public setShadow() {
		if (
			this.selectedItemsSet == null ||
			this.shadowSelectedItemsSet == null
		) {
			return;
		}

		this.shadowSelectedItemsSet.clear();
		this.shadowSelectedItemsSet = new Set(this.selectedItemsSet);
	}

	/**
	 * Resets shadowChecked value to checked value
	 */
	@action.bound
	public resetCheckStateOnShadow() {
		this.checked = this.shadowChecked;
		if (!this.isLeaf) {
			for (const child of this.children) {
				child.resetCheckStateOnShadow();
			}
		}
	}

	@action.bound
	public toggleExpanded() {
		if (!this.isLeaf || this.parent == null) {
			this.isExpanded = !this.isExpanded;
		}
	}

	@action.bound
	public expand() {
		if ((!this.isLeaf && !this.isExpanded) || this.parent == null) {
			this.isExpanded = true;
		}
	}

	@action.bound
	public collapse() {
		if ((!this.isLeaf && this.isExpanded) || this.parent == null) {
			this.isExpanded = false;
		}
	}

	@action.bound
	public collapseTree() {
		this.collapse();

		this.children?.forEach((child) => child.collapseTree());
	}

	@action.bound
	public shadowToggleExpanded() {
		if (!this.isLeaf || this.parent == null) {
			this.shadowIsExpanded = !this.shadowIsExpanded;
		}
	}

	@action.bound
	public shadowExpand() {
		if ((!this.isLeaf && !this.shadowIsExpanded) || this.parent == null) {
			this.shadowIsExpanded = true;
		}
	}

	@action.bound
	public shadowCollapse() {
		if ((!this.isLeaf && this.shadowIsExpanded) || this.parent == null) {
			this.shadowIsExpanded = false;
		}
	}

	@action.bound
	public shadowCollapseTree() {
		this.shadowCollapse();

		this.children?.forEach((child) => child.shadowCollapseTree());
	}

	//#endregion node actions

	//#region disposers

	@action.bound
	onDispose(): void {
		for (const child of this.children) {
			child.onDispose();
		}

		this.parent = null;
		// @ts-expect-error
		this.node = { id: "" };
		this.children = [];
	}

	//#endregion disposers

	//#region cleanup

	@action.bound
	removeEmptyNodes(): void {
		if (!this.isRoot || !this.selectedItemsSet) {
			return;
		}
		if (![...this?.selectedItemsSet].some((node) => node.node.id == "")) {
			return;
		}
		if (!this.selectedItemsSet) {
			return;
		}

		for (const node of this.selectedItemsSet?.values()) {
			const stack: NodeStore<NodeType>[] = [...this.children];
			if (node.node.id == "") {
				this.selectedItemsSet.delete(node);
				while (stack.length) {
					const childNode = stack.pop();
					if (childNode && childNode.selectedItemsSet?.delete(node)) {
						stack.push(...childNode.children);
					}
				}
			}
		}
	}

	//#endregion cleanup
}
