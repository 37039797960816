import React, { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { DepositContext } from "@context";
import {
	DepositPaymentMethodMaskedPan,
	DepositPaymentMethodMultipleReferencesCounter,
} from ".";
import PaymentOptionImage from "@v2/components/shared/icons/PaymentOptionImage";
import { getSVGExtension } from "@utils/common/helpers";
export const SelectedDepositPaymentMethod: FC = observer(
	function SelectedDepositPaymentMethod() {
		const { selectedPaymentMethod } = useContext(DepositContext);
		const { t } = useTranslation();

		return (
			<>
				<div className="label">
					{t("DEPOSIT.SELECTED_PAYMENT_METHOD")}
				</div>
				<div className="row">
					<div className="col col-sml-6">
						<div className="card--payment u-mar--bottom--reset">
							<div className="card--payment__area">
								<span className="card--payment__icon">
									<DepositPaymentMethodMultipleReferencesCounter
										paymentMethod={selectedPaymentMethod}
									/>
									<PaymentOptionImage
										className={`card--payment__logo u-icon u-icon--payment u-icon--payment--base u-icon--payment--${selectedPaymentMethod?.paymentMethodAbrv.toLowerCase()}`}
										svgName={
											selectedPaymentMethod?.paymentMethodAbrv.toLowerCase() +
											getSVGExtension()
										}
									/>
								</span>
								<span className="card--payment__details card--payment__details--centered">
									<span className="card--payment__title">
										{selectedPaymentMethod?.displayName}
									</span>
									<DepositPaymentMethodMaskedPan
										paymentMethod={selectedPaymentMethod}
									/>
								</span>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
);
