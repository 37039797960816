import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Router } from "react-router-dom";

import { GlobalLoader } from "themes/common/components/loader";

import ReactApp from "./App";
import { localizationService } from "@state";

if (module.hot) module.hot.accept();

ReactDOM.render(
	<Suspense fallback={<GlobalLoader />}>
		<Router history={App.state.history}>
			<I18nextProvider i18n={localizationService}>
				<ReactApp />
			</I18nextProvider>
		</Router>
	</Suspense>,
	document.getElementById("app")
);
