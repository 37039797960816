import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormButton } from "@v2/components/desktop/shared/buttons";
import { FormInputField } from "@v2/components/desktop/shared/inputs";
import { EmailPattern } from "@common/constants";
import { EmailChangeNewEmail } from "./";
import useEmailChange from "@v2/hooks/account-settings/email-change/useEmailChange";

export default function EmailChangeFormSection() {
	const { trigger, getFieldState, handleSubmit, getValues } =
		useFormContext();

	const { onSubmitData } = useEmailChange();

	const { t } = useTranslation();

	useEffect(() => {
		if (
			getFieldState("newEmail").isTouched &&
			getFieldState("repeatEmail").isTouched
		) {
			trigger("repeatEmail");
		}
	}, [useWatch({ name: "newEmail" })]);

	return (
		<form className="form" onSubmit={handleSubmit(onSubmitData)}>
			<div className="card--primary">
				<div className="card--primary__header">
					<div className="card--primary__title title--primary">
						{t("USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.TITLE_EC")}
					</div>
				</div>
				<div>
					<div className="card--primary__body">
						<div className="inputgroup">
							<div className="row">
								<FormInputField
									name="CurrentEmail"
									className="col col-sml-12 form__field"
									label="USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.CURRENT_EMAIL"
									disabled
								/>

								<EmailChangeNewEmail />

								<FormInputField
									name="repeatEmail"
									className="col col-sml-12 form__field"
									label="USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.REPEAT_NEW_EMAIL"
									placeholder="USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.REPEAT_NEW_EMAIL_PLACEHOLDER"
									pattern={{
										value: EmailPattern,
										message:
											"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.INVALID_EMAIL",
									}}
									validate={{
										isNotEqual: (value: string) => {
											const { newEmail } = getValues();
											return (
												newEmail === value ||
												"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.DOES_NOT_MATCH_EMAIL"
											);
										},
									}}
									maxLength={150}
									required
								/>
							</div>
						</div>
					</div>
					<div className="card--primary__footer">
						<FormButton
							className="card--primary__footer__btn btn btn--lrg btn--primary btn--icon btn--animate-forward"
							btnContent={
								<i className="u-icon u-icon--xsml u-icon--arrow--right u-color--core--snow" />
							}
							btnText="COMMON.NEXT_BUTTON"
						/>
					</div>
				</div>
			</div>
		</form>
	);
}
