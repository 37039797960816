import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { MyBetsStoreContext } from "../../../../context";
import useCurrency from "@v2/hooks/shared/useCurrency";

export default observer(function BetsReports() {
	const t = useTranslation().t;
	const store = useContext(MyBetsStoreContext);
	if (store?.type != "MyBetsStore") {
		console.error("Expected MyBetsStore, got null or something else");
		return null;
	}

	if (store.betsReportStore.betsReport == null) {
		// Such emptiness to be displayed
		return null;
	}
	return (
		<>
			<ProfitPaymentInfoTable />
			<BetsReportDetails />
			<div className="u-mar--bottom--big u-type--right no-print">
				<span
					id="showReportDetails"
					className="u-anchor u-anchor--primary u-anchor--underline"
					onClick={store.betsReportStore.openDetails}
				>
					{t<string>("MY_BETS.REPORTS.SHOW_DETAILS")}
				</span>
			</div>

			{/* // TODO i here by assume this is payment profit details list popup */}
			{/* // <partial name="Partials/MyBets/_BetReportDetails" model="@Model.ReportInfo" /> */}
		</>
	);
});

const ProfitPaymentInfoTable = observer(function ProfitPaymentInfoTable() {
	const t = useTranslation().t;
	const store = useContext(MyBetsStoreContext);
	if (store?.type != "MyBetsStore") {
		console.error("Expected MyBetsStore, got null or something else");
		return null;
	}

	return (
		<table className="table--primary u-mar--bottom--xtny no-print">
			<caption className="table--primary__caption">
				{t<string>("MY_BETS.REPORTS.PROFIT_PAYMENT_RATIO", {
					0: store.betsReportStore.from,
					1: store.betsReportStore.to,
					interpolation: { escapeValue: false },
				})}
			</caption>
			<BetsReportsTableHead />
			<BetsReportTableData />
		</table>
	);
});

function BetsReportsTableHead() {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	return (
		<thead className="table--primary__header">
			<tr className="table--primary__header__row">
				<th className="table--primary__header__data u-type--left">
					{t<string>("MY_BETS.REPORTS.NUMBER_OF_BET_SLIPS")}
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("MY_BETS.REPORTS.PAYMENT")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("MY_BETS.REPORTS.GAIN")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("MY_BETS.REPORTS.NET_GAIN")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("MY_BETS.REPORTS.LOSS")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("MY_BETS.REPORTS.PROFIT")} ({currency})
				</th>
				<th className="table--primary__header__data u-type--right">
					{t<string>("MY_BETS.REPORTS.PROFIT")}/
					{t<string>("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_PAYMENT")}{" "}
					(%)
				</th>
			</tr>
		</thead>
	);
}

const BetsReportTableData = observer(function BetsReportTableData() {
	const store = useContext(MyBetsStoreContext);
	if (store == null || store.type != "MyBetsStore") {
		console.error("Expected MyBetsStore, got null or something else");
		return null;
	}

	return (
		<tbody className="table--primary__body">
			<tr className="table--primary__body__row">
				<td className="table--primary__body__data u-type--left">
					{store.betsReportStore.betsReport?.Count}
				</td>
				<td className="table--primary__body__data u-type--right">
					{store.betsReportStore.betsReport?.Payment.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						store.betsReportStore.betsReport?.Gain
					)}`}
				>
					{store.betsReportStore.betsReport?.Gain.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						store.betsReportStore.betsReport?.NetGain
					)}`}
				>
					{store.betsReportStore.betsReport?.NetGain.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						store.betsReportStore.betsReport?.Loss
					)}`}
				>
					{store.betsReportStore.betsReport?.Loss.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						store.betsReportStore.betsReport?.Profit
					)} `}
				>
					{store.betsReportStore.betsReport?.Profit.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--right ${repIndicCss(
						store.betsReportStore.betsReport?.ProfitPercentage
					)} `}
				>
					{store.betsReportStore.betsReport?.ProfitPercentage.toFixed(
						2
					)}
				</td>
			</tr>
		</tbody>
	);
});

//#region details

const BetsReportDetails = observer(function BetsReportDetails() {
	const store = useContext(MyBetsStoreContext);
	if (store == null || store.type != "MyBetsStore") {
		console.error("Expected MyBetsStore, got null or DetailsStore");
		return null;
	}

	if (!store.betsReportStore.isDetailsOpen) {
		return null;
	}

	return (
		<div id="reportDetails" className="popup">
			<div className="popup__card popup__card--lrg">
				<DetailsHeader />
				<div className="popup__body">
					<div className="card--bet u-mar--top--reset">
						<table className="table--bets">
							<DetailsReportsTableHeader />
							<DetailsReportsTableBody />
						</table>
					</div>
				</div>
				{/* <DetailsCloseButton /> */}
			</div>
		</div>
	);
});

function DetailsReportsTableHeader() {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	return (
		<thead className="table--bets__subhead table--bets__subhead--secondary u-type--case--uppercase">
			<tr className="table--bets__subhead__row">
				<th className="table--bets__ts">
					{t<string>("ACC_STATEMENT.BET_DETAILS.BET_SLIP")}
				</th>
				<th className="table--bets__ts">
					{t<string>("MY_BETS.ITEM.INFO_DATE")}
				</th>
				<th className="table--bets__ts u-type--right">
					{t<string>("BET_SLIP.PAYMENT")} ({currency})
				</th>
				<th className="table--bets__ts u-type--right">
					{t<string>("ACC_STATEMENT.TABLES.TABLE_GAIN")}/
					{t<string>("ACC_STATEMENT.TABLES.TABLE_LOSS")} ({currency})
				</th>
				<th className="table--bets__ts u-type--right">
					{t<string>("MY_BETS.REPORTS.PROFIT")}/
					{t<string>("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_PAYMENT")}{" "}
					(%)
				</th>
			</tr>
		</thead>
	);
}

const DetailsReportsTableBody = observer(function DetailsReportsTableBody() {
	const store = useContext(MyBetsStoreContext);

	if (store == null || store.type != "MyBetsStore") {
		console.log("Expected store, got null or details store.");
		return null;
	}

	return (
		<tbody className="table--bets__body">
			{/* @ts-expect-error fix in bets report missing type */}
			{store.betsReportStore.betsReport?.MyBetsDetails?.map((item) => (
				<DetailsReportsTableBodyRow key={item.Id} item={item} />
			))}
		</tbody>
	);
});

const DetailsReportsTableBodyRow = observer(
	function DetailsReportsTableBodyRow(props: {
		item: {
			BetSlipNumber: number;
			DateCreated: string;
			Payment: number;
			GainLoss: number;
			ProfitPercentage: number;
			BetStatusAbrv: string;
		};
	}) {
		const date = DateTime.fromISO(props.item.DateCreated)
			.toLocaleString(DateTime.DATETIME_SHORT)
			// TODO remove comma from format
			.split(",")
			.join("");

		return (
			<tr className="table--bets__tr table--bets__tr--secondary">
				<td className="table--bets__td">{props.item.BetSlipNumber}</td>
				<td className="table--bets__td">{date}</td>
				<td className="table--bets__td u-type--right">
					{props.item.Payment.toFixed(2)}
				</td>
				<td
					className={`table--bets__td u-type--right u-color--bet--${props.item.BetStatusAbrv}`}
				>
					{props.item.GainLoss.toFixed(2)}
				</td>
				<td
					className={`table--bets__td u-type--right u-color--bet--${props.item.BetStatusAbrv}`}
				>
					{props.item.ProfitPercentage.toFixed(2)}
				</td>
			</tr>
		);
	}
);

function DetailsHeader() {
	const { t } = useTranslation();
	const store = useContext(MyBetsStoreContext);

	if (store == null || store.type != "MyBetsStore") {
		console.error("Expected store, got null");
		return null;
	}

	return (
		<div className="popup__header">
			<div className="popup__header__title u-type--base">
				{t<string>("MY_BETS.REPORTS.PROFIT_PAYMENT_RATIO", {
					interpolation: { escapeValue: false },
					0: store.betsReportStore.from,
					1: store.betsReportStore.to,
				})}
			</div>
			<button
				id="closeReportDetails"
				className="btn btn--square btn--square--tny btn--secondary--inverted btn--icon"
				type="button"
				title="Close"
				onClick={store.betsReportStore.closeDetails}
			>
				<i className="u-icon u-icon--sml u-icon--remove" />
			</button>
		</div>
	);
}

// function DetailsCloseButton() {
// 	const { t } = useTranslation();

// 	const {
// 		betsReportStore: { closeDetails },
// 	} = useContext(MyBetsStoreContext);

// 	return (
// 		<div className="popup__footer">
// 			<button
// 				id="closeReportDetails"
// 				className="popup__footer__btn btn btn--tny btn--secondary--inverted btn--icon"
// 				type="button"
// 				onClick={closeDetails}
// 			>
// 				<i className="u-icon u-icon--xsml u-icon--close" />
// 				{t<string>("COMMON.CLOSE")}
// 			</button>
// 		</div>
// 	);
// }

//#endregion details

/**
 * @parm {number} number value for which to get indicator className name
 * @returns {string} indicator className name
 */
function repIndicCss(number: number | null | undefined) {
	if (number == null) {
		return "";
	}

	if (number < 0) {
		return "u-color--ui--danger";
	}

	if (number > 0) {
		return "u-color--ui--success";
	}

	return "";
}
