import React, { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { WithdrawalContext } from "@context";

export const WithdrawalPaymentMethodFilterSection: FC = observer(
	function WithdrawalPaymentMethodFilterSection() {
		const { register } = useFormContext();

		const { userPaymentMethods } = useContext(WithdrawalContext);

		const { t } = useTranslation();

		return (
			<div className="form__group">
				<label htmlFor="MethodId" className="label">
					{t<string>("WITHDRAWAL.PAYMENT_METHOD")}
				</label>
				<select
					id="wpaymentMethodId"
					className="input input--base input--select"
					{...register("paymentMethodId")}
				>
					<option key="paymentMethod-all" value="all">
						{t<string>("WITHDRAWAL.ALL_METHODS")}
					</option>
					{userPaymentMethods?.map((paymentMethod) => (
						<option
							key={paymentMethod.paymentMethodId}
							value={paymentMethod.paymentMethodId}
						>
							{paymentMethod.displayName}
						</option>
					))}
				</select>
			</div>
		);
	}
);
