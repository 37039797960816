import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { MyBetsStoreContext } from "../../../../context";
import { useFixBody } from "@v2/hooks/shared";
import { logger } from "@state/providers/consoleLogger";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default observer(function CancelBetSlipMessagePopUp() {
	const store = useContext(MyBetsStoreContext);
	const t = useTranslation().t;
	useFixBody(true, store?.betCancelStore.betCancelMessage);

	if (store == null) {
		logger.logError("Expected MyBetsStore got something else.");
		return null;
	}

	if (store.betCancelStore.betCancelMessage == null) {
		return null;
	}

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__header">
					{t("MY_BETS.BET_CANCEL.INFO_TITLE")}
				</div>
				<div className="popup__body">
					<InfoMessageComponent
						type="note"
						message={
							store.betCancelStore.betCancelMessage as string
						}
					/>
				</div>
				<div className="popup__footer">
					<button
						className="popup__footer__btn btn btn--sml btn--secondary--inverted btn--icon"
						onClick={store.betCancelStore.onExitBetCancel}
					>
						<i className="u-icon u-icon--xsml u-icon--close" />
						{t("COMMON.CLOSE")}
					</button>
				</div>
			</div>
		</div>
	);
});
