import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getCurrentCulture } from "@utils";

class AccountLockTabsPropsType {
	pathName: string;
	tabTitle: string;
	index: number;
}

export default function AccountLockTabButtons() {
	const isOasisLockEnabled =
		App.state.rootStore.userAuthStore.user?.isOasisEnabled;

	if (!isOasisLockEnabled) {
		return null;
	}

	return (
		<div className="card--primary__tabs">
			<nav className="tabs--primary">
				<AccountLockTabs
					pathName="account-settings/account-lock/account-closure"
					tabTitle="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.NAV_TITLE_ACC_CLOSURE"
					index={0}
				/>

				<AccountLockTabs
					pathName="account-settings/account-lock/oasis-lock"
					tabTitle="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.NAV_TITLE_OASIS_LOCK"
					index={1}
				/>
			</nav>
		</div>
	);
}

function AccountLockTabs(props: AccountLockTabsPropsType) {
	const { t } = useTranslation();

	const { pathName, tabTitle, index } = props;

	return (
		<NavLink
			to={`/${getCurrentCulture()}/${pathName}`}
			className="tabs--primary__item"
			activeClassName="is-active"
			isActive={(match, location) => {
				if (
					index == 0 &&
					location.pathname ==
						`/${getCurrentCulture()}/account-settings/account-lock`
				) {
					return true;
				} else if (match) {
					return true;
				}
				return false;
			}}
		>
			{t(tabTitle)}
		</NavLink>
	);
}
