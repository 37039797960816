import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getCurrentCulture, getSVGExtension } from "@utils";
import { PaymentOptionDto } from "@api-types/payment-options/PaymentOptionDto";
import { observer } from "mobx-react";
import PaymentOptionImage from "@v2/components/shared/icons/PaymentOptionImage";

export const PaymentOptions = observer(function PaymentOptions() {
	const { t } = useTranslation();

	const { footerPaymentOptions } = App.state.rootStore.paymentOptionsStore;

	if (footerPaymentOptions.length === 0) {
		return null;
	}

	return (
		<>
			<div className="footer__title">
				{t<string>("FOOTER.PAYMENT_OPTIONS")}
			</div>
			<div className="footer__icons">
				{footerPaymentOptions.map(
					(paymentOption: PaymentOptionDto, idx: number) => (
						<NavLink
							key={idx}
							className="footer__icons__item btn btn--reset"
							to={`/${getCurrentCulture()}/info/payment-options`}
						>
							<PaymentOptionImage
								className={`u-icon u-icon--payment u-icon--payment--base u-icon--payment--${paymentOption.paymentMethodAbrv.toLowerCase()}`}
								svgName={
									paymentOption.paymentMethodAbrv.toLowerCase() +
									getSVGExtension()
								}
							/>
						</NavLink>
					)
				)}
			</div>
		</>
	);
});
