import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
	MyBetsCashoutButton,
	MyBetsAutosellButton,
	ShareBetSlipButton,
} from "../../../my-bets/components";
import { MyBetsViewStore } from "@offer/stores/components/my-bets";

import { Bet } from "@administration/pages/my-bets/model";
import { shouldShowBetSlipStatus } from "../../../../../helpers";
import { getCurrentCulture } from "@utils";
import { WalletIcon } from "@v2/components/shared/utility";

import { DisplayDate } from "./";
import BetItemPaymentDetail from "./BetItemPaymentDetail";

const MutliItem = observer(function MutliItem(props: {
	bet: Bet;
	isFull: boolean;
	myBetsViewStore: MyBetsViewStore;
}) {
	const { t } = useTranslation();
	/**
	 * TODO
	 * null check would crash home page
	 */
	if (props.bet.betSlipOffers == null) {
		return null;
	}

	const hasActiveOffers = props.bet.betSlipOffers.some(
		(offer) => offer.bettingOfferStatus == 1
	);

	const reuseLabelLength = t<string>("MY_BETS.REUSE.BUTTON_LABEL").length;

	const titleClasses = classNames(
		"widget--bets__header",
		[`widget--bets__header--${getCurrentCulture()}`],
		{
			"widget--bets__header--col": reuseLabelLength > 14,
		}
	);

	const statusClasses = classNames("widget--bets__status");

	const reuseBtnClasses = classNames(
		"btn btn--wrap btn--wrap--xtny btn--secondary--inverted btn--icon btn--animate-rotate",
		{
			"btn--block": reuseLabelLength > 14,
		}
	);

	if (props.bet.isRemoved) {
		return null;
	}

	return (
		<div key={props.bet.id} className="widget--bets__item">
			<div className={titleClasses}>
				<div
					className={statusClasses}
					title={
						props.bet.betStatus.name +
						" - " +
						props.bet.betSlipType.name
					}
				>
					<span
						className={
							"tag tag--med tag--bet tag--bet--" +
							props.bet.betStatus.abrv
						}
					>
						{props.bet.betStatus.name}
						{props.bet.isBonus && (
							<> ({t<string>("COMMON.BONUS")})</>
						)}
					</span>

					{shouldShowBetSlipStatus(props.bet) && (
						<span
							className={
								"tag tag--med tag--slip tag--slip--" +
								props.bet.betSlipStatus.abrv +
								"--inverted"
							}
						>
							{t<string>(
								`MY_BETS.STATUS.${props.bet.betSlipStatus.abrv.toUpperCase()}`
							)}
						</span>
					)}

					<WalletIcon
						bettingAccountTypeAbrv={
							props.bet.bettingAccountType?.abrv
						}
						classList={"u-mar--left--reset"}
					/>
				</div>

				<ShareBetSlipButton bet={props.bet} isMini={true} btnTiny />

				{hasActiveOffers ? (
					<button
						className={reuseBtnClasses}
						type="button"
						onClick={() =>
							props.myBetsViewStore.openReuseBetSlipModal({
								id: props.bet.id,
								betSlipNumber: props.bet.betSlipNumber,
								isLive: props.bet.isLive,
							})
						}
						title={t<string>("MY_BETS.REUSE.BUTTON_LABEL")}
					>
						<i className="u-icon u-icon--sml u-icon--refresh" />
					</button>
				) : null}
			</div>

			<div className="widget--bets__info">
				<div className="widget--bets__title">
					{props.bet.betSlipType.name}
				</div>
			</div>

			<div className="widget--bets__row">
				<div className="widget--bets__col">
					<div className="widget--bets__label">
						{t<string>("MY_BETS.ITEM.INFO_PAYMENT_LABEL")}:
					</div>
					<div className="widget--bets__amount">
						{props.bet.displayPayment}
					</div>
				</div>
				<div className="widget--bets__col">
					<BetItemPaymentDetail
						betStatusAbrv={props.bet.betStatus.abrv}
						betSlipStatusAbrv={props.bet.betSlipStatus.abrv}
						payout={props.bet.displayPayout}
						maxGain={props.bet.displayMaxGain}
					/>
				</div>
				<div className="widget--bets__col">
					<div className="widget--bets__label">
						{t<string>("MY_BETS.ITEM.ADDITIONAL_MAX_COEFFICIENT")}:
					</div>
					<div className="widget--bets__amount">
						{props.bet.maxCoefficient}
					</div>
				</div>
			</div>

			{props.bet.displayedAmount != null && (
				<div className="widget--bets__actions">
					<MyBetsCashoutButton
						bet={props.bet}
						openCashoutModal={
							props.myBetsViewStore.cashoutStore.openCashoutModal
						}
					/>
					<MyBetsAutosellButton
						bet={props.bet}
						openAutosellModal={
							props.myBetsViewStore.cashoutStore.openAutosellModal
						}
						isAutoCashoutEnabled={
							props.myBetsViewStore.cashoutStore
								.isAutoCashoutEnabled
						}
					/>
				</div>
			)}
			<div className="widget--bets__footer">
				{/* <DisplayDate betDateTimeStamp={props.bet.dateCreated} /> */}
				<div className="widget--bets__date">
					<DisplayDate betDateTimeStamp={props.bet.dateCreated} />
				</div>

				<OpenSingleBet
					bet={props.bet}
					myBetsViewStore={props.myBetsViewStore}
				/>
			</div>
		</div>
	);
});

const OpenSingleBet = observer(function OpenSingleBet(props: {
	bet: Bet;
	myBetsViewStore: MyBetsViewStore;
}) {
	return (
		<button
			className="widget--bets__more btn btn--square btn--square--xtny btn--secondary--inverted btn--icon btn--animate-forward"
			type="button"
			onClick={() => props.myBetsViewStore.onOpen(props.bet.id)}
		>
			<i className="u-icon u-icon--xsml u-icon--arrow--right" />
		</button>
	);
});

export default MutliItem;
