import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { useAppContext } from "../../../../../ui/common/hooks";
import { AccountSettingsSideMenu } from ".";
import { isCasinoEnabled, isLiveCasinoEnabled } from "@v2/helpers";
import { useUser } from "../../../../../ui/common/hooks";

export default observer(function AdministrationSideMenu(props: {
	isUserSettingsActive?: boolean;
	setIsSideMenuExpanded?: (b: boolean) => void;
	isSideMenuExpanded?: boolean;
}) {
	const pathLocation = useLocation();
	const { culture } = useAppContext();
	const { t } = useTranslation();
	const user = useUser();

	// const hamburgerBtnSideMenuClassName = classNames("l--header__toggle__btn", {
	// 	"is-expanded": isSideMenuExpanded,
	// });

	return (
		<>
			<nav className="nav--secondary">
				<ul className="nav--secondary__menu">
					{/* <div className="l--header__toggle">
						{!isUserSettingsActive ? (
							<button
								className={hamburgerBtnSideMenuClassName}
								type="button"
								disabled
							>
								<span></span>
								<span></span>
								<span></span>
							</button>
						) : (
							<button
								className={hamburgerBtnSideMenuClassName}
								type="button"
								onClick={() =>
									setIsSideMenuExpanded(!isSideMenuExpanded)
								}
							>
								<span></span>
								<span></span>
								<span></span>
							</button>
						)}
					</div> */}

					<li className="nav--secondary__item">
						<NavLink
							to={`/${culture}/my-bets`}
							className="nav--secondary__link"
							activeClassName="is-selected"
							isActive={(_, location) =>
								location.pathname.includes(
									`/${culture}/my-bets`
								)
							}
						>
							<i
								className={classNames(
									"nav--secondary__icon u-icon u-icon--big u-icon--my-bets--alt",
									{
										"u-icon--my-bets--bold u-color--core--snow":
											pathLocation.pathname.includes(
												`my-bets`
											),
									}
								)}
							/>
							<div className="nav--secondary__title">
								{t("HEADER.MY_BETS")}
							</div>
						</NavLink>
					</li>

					<li className="nav--secondary__item">
						<NavLink
							to={`/${culture}/account-statement`}
							className="nav--secondary__link"
							activeClassName="is-selected"
							isActive={(_, location) =>
								location.pathname.includes(`account-statement`)
							}
						>
							<i
								className={classNames(
									"nav--secondary__icon u-icon u-icon--big u-icon--account-statement--alt",
									{
										"u-icon--account-statement--bold u-color--core--snow":
											pathLocation.pathname.includes(
												`account-statement`
											),
									}
								)}
							/>
							<div className="nav--secondary__title">
								{t("HEADER.ACCOUNT_STATEMENT")}
							</div>
						</NavLink>
					</li>

					{user.isInternetUser ? (
						<>
							<li className="nav--secondary__item">
								<NavLink
									to={`/${culture}/deposit`}
									className="nav--secondary__link"
									activeClassName="is-selected"
									isActive={(_, location) =>
										location.pathname.includes("deposit")
									}
								>
									<i
										className={classNames(
											"nav--secondary__icon u-icon u-icon--big u-icon--deposit--alt",
											{
												"u-icon--deposit--bold u-color--core--snow":
													pathLocation.pathname.includes(
														"deposit"
													),
											}
										)}
									/>
									<div className="nav--secondary__title">
										{t("DEPOSIT.TITLE")}
									</div>
								</NavLink>
							</li>
							<li className="nav--secondary__item">
								<NavLink
									to={`/${culture}/withdrawal`}
									className="nav--secondary__link"
									activeClassName="is-selected"
									isActive={(_, location) =>
										location.pathname.includes(
											"/withdrawal"
										)
									}
								>
									<i
										className={classNames(
											"nav--secondary__icon u-icon u-icon--big u-icon--withdrawal--alt",
											{
												"u-icon--withdrawal--bold u-color--core--snow":
													pathLocation.pathname.includes(
														"/withdrawal"
													),
											}
										)}
									/>
									<div className="nav--secondary__title">
										{t("WITHDRAWAL.TITLE")}
									</div>
								</NavLink>
							</li>
						</>
					) : null}

					{isCasinoEnabled || isLiveCasinoEnabled ? (
						<NavLink
							to={`/${culture}/gaming-history`}
							className="nav--secondary__link"
							activeClassName="is-selected"
							isActive={(_, location) =>
								location.pathname.includes(`gaming-history`)
							}
						>
							<i
								className={classNames(
									"nav--secondary__icon u-icon u-icon--big u-icon--gaming-history--alt",
									{
										"u-icon--gaming-history--bold u-color--core--snow":
											pathLocation.pathname.includes(
												`gaming-history`
											),
									}
								)}
							/>
							<div className="nav--secondary__title">
								{t("USER.ACCOUNT_SETTINGS.NAVIGATION.GH_HEAD")}
							</div>
						</NavLink>
					) : null}

					<li className="nav--secondary__item">
						<NavLink
							to={`/${culture}/account-settings/personal-data`}
							className="nav--secondary__link u-mar--top--xsml"
							activeClassName="is-selected"
							isActive={(match, location) =>
								location.pathname.includes("account-settings")
							}
						>
							<i
								className={classNames(
									"nav--secondary__icon u-icon u-icon--big u-icon--account-settings--alt",
									{
										"u-icon--account-settings--bold u-color--core--snow":
											pathLocation.pathname.includes(
												`account-settings`
											),
									}
								)}
							/>
							<div className="nav--secondary__title">
								{t("HEADER.ACCOUNT_SETTINGS")}
							</div>
						</NavLink>
					</li>
				</ul>
			</nav>

			{props.isUserSettingsActive && <AccountSettingsSideMenu />}
		</>
	);
});
