import { observable, action, computed } from "mobx";
import { DeviceDetector, getCurrentCulture } from "@utils";
class EventSwitcherViewStore {
	//#region observables

	/** Event ids to loop over. */
	@observable eventIds: string[] = [];
	@observable eventsWithBets: {
		eventId: string;
		isButtonToggled: boolean;
	}[] = [];

	@observable currentIndex: number = 0;
	@observable lastUsedDirection: string = "right";

	//#endregion observables

	//#region computed

	@computed get isAvailable(): boolean {
		return this.eventIds?.length >= 2;
	}

	//#endregion computed

	//#region actions

	@action.bound
	onInitialize() {
		this.eventIds =
			App.state.history.location.state?.eventIdsForEventSwitcher || [];
		(
			App.state.history.location.state?.eventIdsForEventSwitcher || []
		).forEach((eId) => {
			const currentParams = new URLSearchParams(window.location.search);
			const isToggled =
				currentParams.get("mybets") === "true" ? true : false;

			this.eventsWithBets.push({
				eventId: eId,
				isButtonToggled: window.location.pathname.includes(eId)
					? isToggled
					: false,
			});
		});
	}

	@action.bound
	setEventList(newList: string[], currentEventIndex: number) {
		this.eventIds = newList;
		this.currentIndex = currentEventIndex;
	}

	//#endregion actions

	//#region switcher actions

	@action.bound
	moveLeft(eventId: string) {
		if (this.eventIds.length <= 1) {
			return;
		}

		const extractedEventId = eventId.includes("-myBets=")
			? eventId.split("-")[0]
			: eventId;

		const currentIndex = this.eventIds.indexOf(extractedEventId);

		let prevIndex = currentIndex - 1;

		if (prevIndex < 0) {
			prevIndex = this.eventIds.length - 1;
		}

		this.lastUsedDirection = "left";
		const currentUrl = window.location.pathname.split("/");
		currentUrl[currentUrl.length - 1] = this.eventIds[prevIndex];
		let url = currentUrl.join("/");

		if (!DeviceDetector.isMobileTheme && !url.includes("-myBets=")) {
			url += "-myBets=false";
		}

		App.state.history.replace(url, App.state.history.location?.state);
	}

	@action.bound
	moveRight(eventId: string) {
		if (this.eventIds.length <= 1) {
			return;
		}

		const extractedEventId = eventId.includes("-myBets=")
			? eventId.split("-")[0]
			: eventId;

		const currentIndex = this.eventIds.indexOf(extractedEventId);

		let nextIndex = currentIndex + 1;

		if (nextIndex >= this.eventIds.length) {
			nextIndex = 0;
		}

		this.lastUsedDirection = "right";
		const currentUrl = window.location.pathname.split("/");
		currentUrl[currentUrl.length - 1] = this.eventIds[nextIndex];
		let url = currentUrl.join("/");

		if (!DeviceDetector.isMobileTheme && !url.includes("-myBets=")) {
			url += "-myBets=false";
		}

		App.state.history.replace(url, App.state.history.location?.state);
	}

	@action.bound
	removeEventId(eventId: string) {
		if (this.lastUsedDirection === "right") {
			this.moveRight(eventId);
		} else if (this.lastUsedDirection === "left") {
			this.moveLeft(eventId);
		} else {
			// Oops this should not happen
		}

		this.eventIds.splice(this.eventIds.indexOf(eventId), 1);

		this.currentIndex = this.eventIds.indexOf(eventId);
	}

	@action.bound
	updateEventsWithBets(eventId: string, isButtonToggled: boolean) {
		const eventIdx = this.eventsWithBets.findIndex(
			(eventBetComb) => eventBetComb.eventId === eventId
		);

		if (eventIdx > -1) {
			this.eventsWithBets[eventIdx].isButtonToggled = isButtonToggled;
		}
	}

	//#endregion switcher actions

	//#region  disposers

	@action.bound
	onDispose() {
		this.currentIndex = 0;
	}

	//#endregion disposers
}

export default EventSwitcherViewStore;
