import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { OfferStoreContext } from "@gp/components";
import { SideMenuLink, SideMenuLinkItem } from "./";
import { getCurrentCulture } from "@utils";

export const PrimaryLiveMenuLinkItems = observer(
	function PrimaryLiveMenuLinkItems() {
		const culture = getCurrentCulture();

		const {
			// @ts-expect-error
			menu: { onCheck },
			// @ts-expect-error
			rootStore: { toggleSideMenu: toggleOfferMenu, closeSideMenu },
			// @ts-expect-error
			setSelectedSport,
			// @ts-expect-error
			selectedSport,

			// @ts-expect-error
			isPlayedLiveEventsCountEnabled,
			// @ts-expect-error
			playedLiveEventsCount,
			// @ts-expect-error
			liveFavoritesCount
		} = useContext(OfferStoreContext);

		const { period } = useParams<{ period: string }>();

		const menuLinkItems: SideMenuLinkItem[] = [
			{
				translationKey:
					period === "events"
						? "LIVE.SIDEMENU.ALL"
						: "LIVE.LIVE_SUB_MENU.LIVE_EVENTS",
				url: `/${culture}/live/events`,
				iconClassExtension: "all",
				countKey: "liveEventsCount",
				callOnUserAction: () => {
					window.scrollTo(0, 0);
					if (period === "events") {
						if (selectedSport != null) {
							onCheck(0);
							setSelectedSport(null);
							closeSideMenu();
						} else {
							toggleOfferMenu();
						}
					} else {
						closeSideMenu();
						setSelectedSport(null);
						onCheck(0);
					}
				},
			},
		];

		if (isPlayedLiveEventsCountEnabled && playedLiveEventsCount > 0) {
			menuLinkItems.push({
				translationKey: "LIVE.SIDEMENU.PLAYED_EVENTS",
				url: `/${culture}/live/played-events`,
				iconClassExtension: "played-events",
				countKey: "playedLiveEventsCount",
			});
		}

		if (liveFavoritesCount > 0) {
			menuLinkItems.push({
				translationKey: "LIVE.SIDEMENU.FAVORITES",
				url: `/${culture}/live/my-favorites`,
				iconClassExtension: "favorites",
				countKey: "liveFavoritesCount",
			});
		}

		return (
			<>
				{menuLinkItems.map((item, idx) => (
					<SideMenuLink key={item.countKey + item.url + idx} item={item} />
				))}
			</>
		);
	}
);

export const SecondaryLiveMenuLinkItems = observer(
	function SecondaryLiveMenuLinkItems() {
		const culture = getCurrentCulture();
		const menuStore = useContext(OfferStoreContext);

		const menuLinkItems = [
			{
				translationKey: "LIVE.SIDEMENU.UPCOMING",
				url: `/${culture}/live/upcoming`,
				iconClassExtension: "upcoming",
				countKey: "upcomingEventsCount",
			},
			{
				translationKey: "LIVE.SIDEMENU.HIGHLIGHTS",
				url: `/${culture}/live/highlights`,
				iconClassExtension: "live-highlights",
				countKey: "totalHighlightsCount",
			},
		];

		// @ts-expect-error
		if (menuStore.liveStreamEventCount > 0) {
			menuLinkItems.push({
				translationKey: "SIDE_MENU.EVENTS_WITH_LIVE_STREAM",
				url: `/${culture}/live/live-stream`,
				iconClassExtension: "live-stream",
				countKey: "liveStreamEventCount",
			});
		}

		return (
			<>
				{menuLinkItems.map((item) => (
					<SideMenuLink key={item.url} item={item} />
				))}
			</>
		);
	}
);
