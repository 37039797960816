import {
	observable,
	computed,
	action,
	reaction,
	runInAction,
	IReactionDisposer,
} from "mobx";

import { Subscription } from "rxjs";
import {
	IMenuSubscriptionRequest,
	EventFilterParams,
	ICountSubscription,
} from "@gp/hub";
import { LiveStatus } from "@gp/models";
import { logger } from "@state";

import { BaseOfferMenuStore } from "./BaseOfferMenuStore";
import { SportMenuNodeType } from "./SportNodeType";
import NodeStore from "./node/NodeStore";
import {
	LivePeriodSubMenuStore,
	TLivePeriod,
} from "./period-sub-menu/LivePeriodSubMenuStore";
import { FavoriteEventsCounterStore } from "./counters";
import PlayedLiveEventsStore from "./counters/PlayedLiveEventsStore";
import { TRootStore } from "./BaseOfferMenuStore";
import FavoritesStore from "@offer/stores/FavoritesStore";
import AnalyticsService from "@services/analytics/AnalyticsService";
import { LiveSideNavigationInteractionTypeEnum } from "@data-types/analytics/LiveSideNavigationTypeEnum";

export class LiveOfferMenuStore extends BaseOfferMenuStore {
	public type: "LiveOfferMenuStore" = "LiveOfferMenuStore";
	/** Used to remember what was selected in the menu. */
	protected _rememberSelectionSegmentsString: string | null = null;
	protected _rememberSelectedSport: this["menu"] | null = null;
	private fetchDataDisposer: IReactionDisposer | null;

	favoritesStore: FavoritesStore;
	public periodSubMenu: LivePeriodSubMenuStore;
	public liveEventsFromMyBets: PlayedLiveEventsStore;
	liveMenuSubscription: Subscription | null;
	menuCountSubscription: any;

	private liveMenuSubscriptionFilter: IMenuSubscriptionRequest | null = null;

	@observable selectedSport: NodeStore<SportMenuNodeType> | null = null;

	@observable public liveEventsCount = 0;
	@observable public upcomingEventsCount = 0;
	@observable public totalHighlightsCount = 0;
	@observable public liveStreamEventCount = 0;

	@computed get selectedPeriod() {
		return this.periodSubMenu.selectedPeriod;
	}
	@computed get period() {
		return this.periodSubMenu.selectedPeriodKey;
	}

	@computed get liveFavoritesCount() {
		return this.favoritesStore.liveFavoritesCounter;
	}

	@observable menu = new NodeStore<SportMenuNodeType>({
		id: "root",
		count: 0,
		type: "root",
		name: "",
	});

	//#region  constructor

	constructor(rootStore: TRootStore, favoritesStore: FavoritesStore) {
		super(rootStore);

		this.periodSubMenu = new LivePeriodSubMenuStore(this);
		this.favoritesStore = favoritesStore;
		this.liveEventsFromMyBets = new PlayedLiveEventsStore(this.rootStore);
	}

	@action.bound
	onBeforeInitialize() {
		// Offer menu should be closed by default on live page
		this.closeOfferMenu();
	}

	//#endregion constructor

	//#region sportCollapse

	@observable collapsedSports = new Set<string>();

	@action.bound
	expandSport(sportId: string): void {
		this.collapsedSports.delete(sportId);
	}

	@action.bound
	collapseSport(sportId: string): void {
		this.collapsedSports.add(sportId);
	}

	@action.bound
	toggleSportCollapse(sportId: string): void {
		if (this.collapsedSports.has(sportId)) {
			this.collapsedSports.delete(sportId);
		} else {
			this.collapsedSports.add(sportId);
		}
	}

	@action.bound
	clearCollapsedSports(): void {
		this.collapsedSports.clear();
	}

	//#endregion sportCollapse

	//#region sport menu

	@computed public get isOfferMenuOpen(): boolean {
		return this.rootStore.isSideMenuExpanded;
	}

	@computed get isExpandDisabled(): boolean {
		return (
			this.periodSubMenu.selectedPeriodKey === "my-favorites" ||
			this.menu.children.length <= 0
		);
	}

	@action.bound
	toggleOpenOfferMenu(): void {
		if (this.isOfferMenuOpen) {
			this.closeOfferMenu();
		} else {
			this.openOfferMenu();
		}
	}

	@action.bound
	openOfferMenu(): void {
		this.rootStore.openSideMenu();
	}

	@action.bound
	closeOfferMenu(): void {
		if (App.state.history.action === "POP") {
			this.selectedSport = this._rememberSelectedSport;
		} else {
			this.selectedSport = null;
		}
		this.rootStore.closeSideMenu();
	}

	/** Store current menu selection in state so we can reset to it on history back. */
	@action.bound
	rememberSelection() {
		// This may be called multiple times, we only want to do it once when using event switcher
		if (
			this._rememberSelectionSegmentsString == null &&
			App.state.history.action === "PUSH"
		) {
			this._rememberSelectionSegmentsString = this.menu.urlStructure;
			this._rememberSelectedSport = this.selectedSport;
		}
	}

	//#endregion sport menu

	//#region  fetch data
	//###################

	@action.bound
	async fetchData() {
		this.disposeFetchData();
		this.liveEventsFromMyBets.onInitialize();

		this.createMenuCountSubscription();
		this.fetchDataDisposer = reaction(
			() => ({ period: this.periodSubMenu.selectedPeriodKey }),
			() => {
				this.createMenuSubscription();
			},
			{
				fireImmediately: true,
			}
		);
	}

	//#region live menu subscription

	@action.bound
	createMenuSubscription() {
		const subscriptionRequest: IMenuSubscriptionRequest =
			this.generateSubscriptionRequestForMenuSubscription();
		if (
			JSON.stringify(subscriptionRequest) ===
				JSON.stringify(this.liveMenuSubscriptionFilter) &&
			this.liveMenuSubscription != null
		) {
			// If filter did not change and subscription exists, don't dispose menu subscription to create a new one
			return;
		}

		this.disposeLiveMenuSubscription();

		this.liveMenuSubscriptionFilter = subscriptionRequest;

		logger.logTrace("Fetch live menu", subscriptionRequest);
		let isFirst = true;

		this.liveMenuSubscription = this.rootStore.hub
			.getMenuSubscription(subscriptionRequest)
			.subscribe({
				next: (response) => {
					runInAction(() => {
						this.assignMenu(response.menu);
						this.selectFirstSportOnFirstResponse(isFirst);
						isFirst = false;
						this.onDoneFetching();
					});
				},
				error: (err) => {
					logger.logError(
						"LiveOfferMenu:LiveMenuFetch; error: ",
						err
					);
					this.onDoneFetching();
				},
			});
	}

	generateSubscriptionRequestForMenuSubscription(): IMenuSubscriptionRequest {
		const filter: EventFilterParams = {};
		if (
			["events", "my-favorites", "played-events"].includes(
				this.periodSubMenu.selectedPeriodKey
			)
		) {
			filter.liveStatus = LiveStatus.LIVE;
		} else if (this.periodSubMenu.selectedPeriodKey === "upcoming") {
			filter.liveStatus = LiveStatus.PREMATCH;
			filter.isUpcoming = true;
			AnalyticsService.logLiveSideNavigationInteraction({
				category: LiveSideNavigationInteractionTypeEnum.Upcoming,
			});
		} else if (this.periodSubMenu.selectedPeriodKey === "highlights") {
			filter.liveStatus = LiveStatus.LIVE;
			filter.isTopEvent = true;
			AnalyticsService.logLiveSideNavigationInteraction({
				category: LiveSideNavigationInteractionTypeEnum.Highlights,
			});
		} else if (this.periodSubMenu.selectedPeriodKey === "live-stream") {
			filter.liveStreamStatus = { eq: ["live", "upcoming"] };
			filter.liveStatus = LiveStatus.LIVE;
			AnalyticsService.logLiveSideNavigationInteraction({
				category: LiveSideNavigationInteractionTypeEnum.LiveStream,
			});
		}

		return {
			subscriptionId: "live-menu",
			filter: filter,
		};
	}

	//#endregion live menu subscription

	//#region live menu counts subscriptions

	@action.bound
	createMenuCountSubscription() {
		this.disposeMenuCountSubscription();

		logger.logTrace("Initialize menu count subscription");

		const menuCountSubscription: ICountSubscription = {
			subscriptionId: "live-menu-counter",
			count: {
				live: {
					liveStatus: 1,
				},
				upcoming: {
					liveStatus: 0,
					isUpcoming: true,
				},
				highlights: {
					liveStatus: 1,
					isTopEvent: true,
				},
				liveStream: {
					liveStreamStatus: { eq: ["live", "upcoming"] },
					liveStatus: LiveStatus.LIVE,
				},
			},
		};

		this.menuCountSubscription = this.hub
			.getCounts(menuCountSubscription)
			.subscribe({
				next: (response) => {
					const liveCount = Number(response.live);
					if (!isNaN(liveCount)) {
						this.liveEventsCount = liveCount;
					}
					const upcomingCount = Number(response.upcoming);
					if (!isNaN(upcomingCount)) {
						this.upcomingEventsCount = upcomingCount;
					}
					const highlightsCount = Number(response.highlights);
					if (!isNaN(highlightsCount)) {
						this.totalHighlightsCount = highlightsCount;
					}
					const liveStreamCount = Number(response.liveStream);
					if (!isNaN(liveStreamCount)) {
						this.liveStreamEventCount = liveStreamCount;
					}
				},
				error: (err) => {
					logger.logError(
						"LiveOfferMenu:CountSubscription; error: ",
						err
					);
					this.onDoneFetching();
				},
			});
	}

	//#endregion live menu counts subscriptions

	@action.bound
	protected selectFirstSportOnFirstResponse(isFirst: boolean) {
		// Do nothing for desktop theme
	}

	//#endregion fetch data
	//#####################

	//#region update params

	@action.bound
	setRouterMatchObj(routerMatch: {
		params: {
			period: TLivePeriod;
			segments: string;
		};
	}): void {
		const { params } = routerMatch;

		// On history back reset current menu to what was remember;
		if (App.state.history.action === "POP") {
			this.resetMenuOnUrlParamsFromRouter(
				this._rememberSelectionSegmentsString
			);
			this.selectedSport = this._rememberSelectedSport;
			this._rememberSelectionSegmentsString = null;
		}

		this.setParams(params);
	}

	@action.bound
	setParams(
		params: {
			period: TLivePeriod;
			segments: string;
		},
		shouldResetMenuOnUrl: boolean = false
	) {
		const { period } = params;

		if (shouldResetMenuOnUrl) {
			this.resetMenuOnUrlParamsFromRouter(params.segments);
		}

		if (
			period !== this.periodSubMenu.selectedPeriodKey &&
			this.periodSubMenu.selectedPeriodKey != "my-favorites" &&
			period != "events"
		) {
			this.menu.onCheck(false);
			this.selectedSport = null;
		}
		this.periodSubMenu.setPeriodKey(period);
	}

	//#endregion update params

	//#region sideMenu actions overrides

	/** Toggles is sport expanded in side menu. */
	@action.bound
	toggleSport(node: NodeStore<SportMenuNodeType>) {
		if (
			this.periodSubMenu.selectedPeriodKey === "events" ||
			this.periodSubMenu.selectedPeriodKey === "played-events" ||
			this.periodSubMenu.selectedPeriodKey === "my-favorites"
		) {
			if (this.selectedSport?.node?.id !== node.node.id) {
				this.menu.onCheck(false);
				this.selectSport(node);
				window.scrollTo(0, 0);
				return;
			}

			if (this.isOfferMenuOpen) {
				this.rootStore.closeSideMenu();
			} else {
				this.rootStore.openSideMenu();
			}

			return;
		}

		if (node?.node?.id === this.selectedSport?.node?.id) {
			this.toggleOpenOfferMenu();
		} else {
			this.selectSport(node);
			this.openOfferMenu();
		}
	}

	/** @param {NodeStore} node which is expanded in menu. */
	@action.bound
	setSelectedSport(node: NodeStore<SportMenuNodeType>) {
		this.selectedSport = node;
	}

	/** Adds sport to side menu and expands side menu. */
	@action.bound
	selectSport(node: NodeStore<SportMenuNodeType>) {
		this.selectedSport = node;
		this.collapsedSports.delete(node.node.id);

		if (
			["my-favorites", "played-events", "events"].includes(
				this.periodSubMenu.selectedPeriodKey
			)
		) {
			this.selectedSport?.onCheck(true);
			this.rootStore.closeSideMenu();
			App.state.redirect("events", true);
		} else {
			this.openOfferMenu();
		}
	}

	/** Removes sport from side menu and closes side menu. */
	@action.bound
	deselectSport() {
		this.selectedSport = null;
		this.closeOfferMenu();
	}

	//#endregion sideMenu actions overrides

	//#region disposers

	@action.bound
	onDispose() {
		this.onBaseDispose();
		this.liveEventsFromMyBets.onDispose();

		this.favoritesStore.favoriteEventsStoreCounter.disposeSubscription();

		this.disposeMenuCountSubscription();

		this.disposeLiveMenuSubscription();
		this.disposeFetchData();
		this.resetObservables();
	}

	@action.bound
	resetObservables() {
		this.periodSubMenu.selectedPeriodKey = "events";
		this.liveEventsCount = 0;
		this.totalHighlightsCount = 0;
		this.upcomingEventsCount = 0;
		this.liveStreamEventCount = 0;
		this.isStoreInitialized = false;

		//this.selectedSport = null;

		this.liveMenuSubscriptionFilter = null;
	}

	@action.bound
	disposeLiveMenuSubscription() {
		if (this.liveMenuSubscription != null) {
			this.liveMenuSubscription.unsubscribe();
			this.liveMenuSubscription = null;
		}
	}

	@action.bound
	disposeFetchData() {
		if (this.fetchDataDisposer != null) {
			this.fetchDataDisposer();
			this.fetchDataDisposer = null;
		}
	}

	@action.bound
	disposeMenuCountSubscription() {
		if (this.menuCountSubscription != null) {
			this.menuCountSubscription.unsubscribe();
			this.menuCountSubscription = null;
		}
	}

	@action.bound
	disposeMenu() {
		this.menu.onDispose();
		// this.menu = new CheckBoxMenuNode();
	}

	//#endregion disposers

	//#region played live events

	@computed get isPlayedLiveEventsCountEnabled() {
		return this.liveEventsFromMyBets.isEnabled;
	}
	@computed get playedLiveEventsCount() {
		return this.liveEventsFromMyBets.count;
	}

	//#endregion played live events
}
