import React from "react";
import classNames from "classnames";

import { toDisplayOdd } from "@gp/utility";

export default function OfferTipButton(props: {
	/** @default true */
	hasMargin?: boolean;
	isPlayerMarket?: boolean;
	isAdditionalOffer?: boolean;
	value: string | number;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	isLocked?: boolean;
	isOneClickBetActive?: boolean;
	isSelected?: boolean;
	indicator?: -1 | 0 | 1;
	displayTipName?: string | null;
	label?: string | null;
}) {
	const hasMargin = props.hasMargin != null ? props.hasMargin : true;
	const displayTipValue = toDisplayOdd(props.value);

	if (displayTipValue == null) {
		return null;
	}

	const tipLabelClasses = classNames("quote quote--margin", {
		"u-type--left": hasMargin,
		offer__player__tip: props.isPlayerMarket,
		"offer--additional__margin": props.isAdditionalOffer,
	});

	return (
		<>
			{props.displayTipName != null ? (
				<div className={tipLabelClasses} title={props.label || ""}>
					{props.label}
				</div>
			) : null}

			<button
				type="button"
				title={displayTipValue}
				onClick={props.onClick}
				disabled={props.isLocked}
				className={classNames("quote quote--button", {
					"one-click": props.isOneClickBetActive,
					"is-active": props.isSelected,
					"u-color--text--primary": props.displayTipName,
					desc: props.indicator === -1,
					inc: props.indicator === 1,
				})}
			>
				{displayTipValue}
			</button>
		</>
	);
}
