import React from "react";
import classnames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactLazyImportWithGuard } from "@lib/lazy-import-with-guard";

export const Pager = function Pager(props) {
	const {
		pageNumber,
		pageSize,
		totalItems,
		onPageChange: receaver,
		isRemoveAllVisible,
		onCloseAll,
		withDropdown = false,
	} = props;

	const onPageChange = (...args) => {
		window.scrollTo(0, 0);

		document.querySelector("main").scrollTo(0, 0);
		receaver(...args);
	};

	const remainder = totalItems % pageSize;
	const totalPages =
		remainder === 0
			? totalItems / pageSize
			: (totalItems - remainder) / pageSize + 1;

	if (totalPages <= 1) {
		if (isRemoveAllVisible) {
			return (
				<div className="pagination">
					{isRemoveAllVisible ? (
						<RemoveAll onCloseAll={onCloseAll} />
					) : null}
				</div>
			);
		}

		return null;
	}

	let start = 1;
	let end = totalPages;

	if (totalPages > 3) {
		end = 3;
	}
	if (totalPages > 3 && pageNumber > 1) {
		start = pageNumber - 1;
		end = pageNumber + 1;
		if (end > totalPages) {
			end = totalPages;
			start = totalPages - 2;
		}
	}

	const renderInnerPager = () => {
		const ip = [];

		for (let i = start; i <= end; ++i) {
			if (i === pageNumber) {
				ip.push(
					<PageButton
						key={i}
						page={i}
						onPageChange={onPageChange}
						disabled
						active
					/>
				);
			} else {
				ip.push(
					<PageButton key={i} page={i} onPageChange={onPageChange} />
				);
			}
		}

		return ip;
	};

	return (
		<React.Fragment>
			<nav className="pagination">
				<div className="pagination__tabs">
					{totalPages > 3 && pageNumber > 1 && start > 1 ? (
						<React.Fragment>
							<PageButton page={1} onPageChange={onPageChange} />
							<IconButton
								previous
								page={pageNumber - 1}
								onPageChange={onPageChange}
							/>
						</React.Fragment>
					) : null}
					{renderInnerPager()}
					{totalPages > 3 &&
					pageNumber < totalPages &&
					end < totalPages ? (
						<React.Fragment>
							<IconButton
								page={pageNumber + 1}
								onPageChange={onPageChange}
							/>
							<PageButton
								page={totalPages}
								onPageChange={onPageChange}
							/>
						</React.Fragment>
					) : null}
				</div>
				{withDropdown && (
					<DropdownPager
						totalPages={totalPages}
						onPageChange={onPageChange}
						pageNumber={pageNumber}
					/>
				)}
				{isRemoveAllVisible ? (
					<RemoveAll onCloseAll={onCloseAll} />
				) : null}
			</nav>
		</React.Fragment>
	);
};

function PagerButton(props) {
	return (
		<button
			type="button"
			disabled={props.disabled}
			onClick={() => props.onPageChange(props.page)}
			className={classnames(
				"pagination__item btn btn--square btn--square--sml",
				props.className,
				{
					"btn--secondary--inverted": !props.active,
					"btn--primary is-active": props.active,
				}
			)}
		>
			{props.children}
		</button>
	);
}

function PageButton(props) {
	return <PagerButton {...props}>{props.page}</PagerButton>;
}

function IconButton(props) {
	const iconClass = classnames("u-icon u-icon--xsml", {
		"u-icon--caret--left": props.previous,
		"u-icon--caret--right": !props.previous,
	});

	return (
		<PagerButton {...props}>
			<i className={iconClass} />
		</PagerButton>
	);
}

const RemoveAll = observer(function RemoveAll(props) {
	const { onCloseAll } = props;
	const { t } = useTranslation();

	if (!onCloseAll) {
		return null;
	}

	return (
		<div className="pagination__remove">
			<button
				type="button"
				className="pagination__remove__btn btn btn--tny btn--secondary--inverted u-color--ui--danger"
				onClick={onCloseAll}
			>
				{t("COMMON.REMOVE_ALL")}
			</button>
		</div>
	);
});

function DropdownPager(props) {
	const { totalPages, onPageChange, pageNumber } = props;
	const optionsArray = [];

	for (let i = 1; i <= totalPages; i++) {
		optionsArray.push(i);
	}

	return (
		<select
			className="pagination__input pagination--wrap__input input input--sml input--select"
			onChange={(e) => onPageChange(e.target.value)}
			value={pageNumber}
		>
			{optionsArray.map((pageNr) => (
				<option key={pageNr} value={pageNr}>
					{pageNr}
				</option>
			))}
		</select>
	);
}
