import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { Redirect, Switch } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { NoMatchRoute, RouteElement } from "@v2/components/shared/navigation";
import LiveRouteLayout from "@v2/components/desktop/offer/layout/LiveRouteLayout";
import SearchRouteLayout from "@v2/components/desktop/offer/layout/SearchRouteLayout";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";

import { getCurrentCulture } from "@utils";

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import FavoritesStore from "@offer/stores/FavoritesStore";
import { useRootAppStore, useRootOfferStore } from "@hooks";
const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const PrematchRouteLayout = lazy(loadFailPath, ()=>import("@v2/components/desktop/offer/layout/PrematchRouteLayout"))

function SportsBettingLayout(props: { menuStore: PrematchOfferMenuStore }) {
	const offerStore = useRootOfferStore();
	const rootStore = useRootAppStore();
	const favoritesStore = useMemo(() => new FavoritesStore(offerStore), []);
	const {
		onboardingWelcomePopupStore: { triggerWelcomePopups },
	} = useRootAppStore();

	useEffect(() => {
		if (rootStore.userAuthStore.isLoggedIn) {
			favoritesStore.fetchUserFavorites();
		}
		return () => {
			favoritesStore.onDispose();
		};
	}, [rootStore.userAuthStore.isLoggedIn]);

	if (WEBPACK_WELCOME_POPUP_ENABLED) {
		useEffect(() => {
			triggerWelcomePopups();
		}, []);
	}

	return (
		<FavoritesStoreContext.Provider value={favoritesStore}>
			<Switch>
				<RouteElement
					exact
					path={[
						"/:culture/sports/:period",
						"/:culture/home/:period/highlights",
						"/:culture/home/:period/top-live",
						"/:culture/home/:period/last-minute",
						"/:culture/home/:period/most-played",
						"/:culture/home/:period/highlights/:sport",
						"/:culture/home/:period/top-live/:sport",
						"/:culture/home/:period/last-minute/:sport",
						"/:culture/home/:period/most-played/:sport",
						"/:culture/sports/:period/my-favorites",
						"/:culture/sports/:period/my-favorites/events",
						"/:culture/sports/:period/my-favorites/tournaments",
						"/:culture/sports/:period/f/:segments*",
						"/:culture/sports/:period/promo/:tournamentIds*",
						"/:culture/sports/:period/event/:eventIds/:segments*",
					]}
				>
					<PrematchRouteLayout
						prematchOfferMenuStore={props.menuStore}
					/>
				</RouteElement>

				<RouteElement
					exact
					path={[
						"/:culture/live/:period",
						"/:culture/live/:period/f/:ids*",
						"/:culture/live/events/:eventIds+",
						"/:culture/live/my-favorites/:eventIds+",
					]}
				>
					<LiveRouteLayout prematchOfferMenuStore={props.menuStore} />
				</RouteElement>

				<RouteElement exact path="/:culture/search/:period/:searchTerm">
					<SearchRouteLayout menuStore={props.menuStore} />
				</RouteElement>

				<Redirect
					exact
					from="/:culture"
					to="/:culture/home/full/highlights"
				/>
				<Redirect
					exact
					from="/:culture/home"
					to="/:culture/home/full/highlights"
				/>
				<Redirect
					exact
					from="/:culture/home/full"
					to="/:culture/home/full/highlights"
				/>
				<Redirect
					exact
					from="/:culture/sports"
					to="/:culture/sports/full"
				/>
				<Redirect
					exact
					from="/:culture/live"
					to="/:culture/live/events"
				/>
				<Redirect
					exact
					from="/:culture/search"
					to="/:culture/home/full/highlights"
				/>
				<NoMatchRoute />
			</Switch>

			<Tooltip
				id="tv-description"
				className="at-tooltipTV react-tooltip--primary"
				place="top"
				closeOnResize={true}
			/>
		</FavoritesStoreContext.Provider>
	);
}

export default observer(SportsBettingLayout);
