import React from "react";
import { useFixBody } from "@v2/hooks/shared";
import {
	AccountStatementTransactionDetails,
	AccountStatementBetDetails,
} from ".";
import { useContext } from "react";
import { AccountStatementBetContext } from "../../../../context/AccountStatementBetContext";
import { DetailsTypeEnum } from "@v2/hooks/account-statement/useAccountTransactions";

export default function AccountStatementPopupDetails() {
	const contextValue = useContext(AccountStatementBetContext);
	if (contextValue === null) {
		return null;
	}
	const { DetailsType } = contextValue;
	if (DetailsType == DetailsTypeEnum.None) {
		return null;
	}
	return (
		<>
			<ShowBetDetails />
			<ShowTransactionDetails />
		</>
	);
}

function ShowBetDetails() {
	const contextValue = useContext(AccountStatementBetContext);
	if (contextValue === null) {
		return null;
	}
	const { DetailsType } = contextValue;
	useFixBody(true, DetailsType);

	if (DetailsType !== DetailsTypeEnum.BetSlipDetails) {
		return null;
	}

	return <AccountStatementBetDetails />;
}

function ShowTransactionDetails() {
	const contextValue = useContext(AccountStatementBetContext);
	if (contextValue === null) {
		return null;
	}
	const { DetailsType } = contextValue;
	if (DetailsType !== DetailsTypeEnum.TransactionDetails) {
		return null;
	}

	return <AccountStatementTransactionDetails />;
}
