import { useState } from "react";
import { useLoading } from "@hooks";
import { useNotification } from "../../../ui/common/hooks";
import { AccountStatementService } from "@v2/data-access/account-statement";
import {
	AccountStatementFilterData,
	Params,
} from "@v2/data-access/account-statement/AccountStatementService";
import { setUrlParams, removeFromUrlParams } from "@state";
import { DateTime } from "luxon";

export default function useAccountStatementFilter({
	setStatementFilter,
}: {
	setStatementFilter: React.Dispatch<React.SetStateAction<{} | Params>>;
}) {
	const [showCustomDateSection, setShowCustomDateSection] = useState(false);
	const [filterData, setFilterData] = useState<
		{} | AccountStatementFilterData
	>({});

	const { setIsLoading } = useLoading();
	const { showError } = useNotification();

	const onInitialize = async () => {
		try {
			setIsLoading(true);
			const response = await AccountStatementService.getFilterData();
			if (response) {
				setFilterData(response);
			}
		} catch (error) {
			console.error(error);
			showError("ACC_STATEMENT.FILTER." + error?.message);
		} finally {
			setIsLoading(false);
		}
	};
	//used on desktop
	const handleChange = (event: { target: { value: string } }) => {
		if (event.target.value === "custom" && !showCustomDateSection) {
			setShowCustomDateSection(true);
		} else if (showCustomDateSection) {
			setShowCustomDateSection(false);
			removeFromUrlParams(["startDate", "endDate"]);
		}
	};

	const onFilterSubmit = (formData: Params): void => {
		if (!formData.startDate) {
			delete formData.startDate;
		}
		if (!formData.endDate) {
			delete formData.endDate;
		}
		if (
			formData.bettingAccountTypeIds == null ||
			formData.bettingAccountTypeIds.length === 0
		) {
			formData.bettingAccountTypeIds =
				App.state.rootStore.userAuthStore.availableUserWallets![0];
		}

		if (formData.asPeriod === "custom") {
			formData.startDate = DateTime.fromISO(formData.startDate)
				.startOf("day")
				.toUTC()
				.toISO();
			formData.endDate = DateTime.fromISO(formData.endDate)
				.endOf("day")
				.toUTC()
				.toISO();
		}

		setStatementFilter(formData);
		setUrlParams({ ...formData, pageNumber: 1 });
	};

	return {
		onInitialize,
		handleChange,
		onFilterSubmit,
		filterData,
		showCustomDateSection,
		setShowCustomDateSection,
	};
}

export class AccountStatementFilter {
	onInitialize: () => Promise<void>;
	handleChange: Function;
	onFilterSubmit: (formData: Params) => void;
	filterData: {} | AccountStatementFilterData;
	showCustomDateSection: boolean;
	setShowCustomDateSection: Function;
}
