import React from "react";
import { useRootAppStore } from "@hooks";

export default function ChatCloseButton() {
	const { chatViewStore } = useRootAppStore();

	const closeChatWindow = () => {
		chatViewStore.closeChat();
	};

	return (
		<button
			onClick={closeChatWindow}
			className="chat__btn--close btn btn--square btn--square--tny btn--icon btn--secondary--inverted"
		>
			<i className="u-icon u-icon--xsml u-icon--close u-color--text--primary" />
		</button>
	);
}
