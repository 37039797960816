import React, { createRef } from "react";
import { useUser } from "../../../../hooks/shared";
import { Button } from ".";

export default function RefreshBalanceBtn(props) {
	const refreshBtnRef = createRef();
	const { refreshUserBalance } = useUser();

	const refreshBalance = async () => {
		refreshBtnRef.current.focus();
		await refreshUserBalance();
		refreshBtnRef.current.blur();
	};

	return <Button ref={refreshBtnRef} onClick={refreshBalance} {...props} />;
}
