import React from "react";
import { useTranslation } from "react-i18next";
import { LimitHistoryViewModel, LimitHistoryViewModelItem } from "@data-types";
import { getDisplayedAmount } from "@utils";

export default function LimitsHistoryTableData(props: TableDataProps) {
	const { t } = useTranslation();

	const { fetchedData } = props;

	if (fetchedData?.items?.length === 0) {
		return null;
	}

	return (
		<table className="table--primary">
			<thead className="table--primary__header table--primary__header--light">
				<tr className="table--primary__header__row">
					<th className="table--primary__header__data">
						{t<string>(`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.DATE`)}
					</th>
					<th className="table--primary__header__data">
						{t<string>(
							`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.LIMIT_TYPE`
						)}
					</th>
					<th className="table--primary__header__data">
						{t<string>(
							`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.LIMIT_INTERVAL`
						)}
					</th>
					<th className="table--primary__header__data u-type--right">
						<div>
							{t<string>(
								`USER.ACCOUNT_SETTINGS.GAMING_HISTORY.LIMIT_AMOUNT`
							)}
						</div>
					</th>
				</tr>
			</thead>
			<tbody className="table--primary__body">
				<List items={fetchedData.items} />
			</tbody>
		</table>
	);
}

function List(props: ListProps) {
	const { items } = props;

	if (!items?.length) return null;

	return (
		<>
			{items.map((item) => (
				<Data key={item.id} item={item} />
			))}
		</>
	);
}

function Data(props: DataProps) {
	const { item } = props;

	return (
		<tr className="table--primary__body__row">
			<td className="table--primary__body__data">{item.date}</td>
			<td className="table--primary__body__data">{item.limitType}</td>
			<td className="table--primary__body__data">{item.limitInterval}</td>
			<td className="table--primary__body__data u-type--right">
				{item.currencyDisplayCode != ""
					? getDisplayedAmount(
							item.limitAmount,
							item.currencyDisplayCode,
							0
					  )
					: item.limitAmount}
			</td>
		</tr>
	);
}

type TableDataProps = {
	fetchedData: LimitHistoryViewModel;
};

type ListProps = {
	items: LimitHistoryViewModelItem[];
};

type DataProps = {
	item: LimitHistoryViewModelItem;
};
