import React from "react";
import { withRouter } from "react-router-dom";

export function ErrorBoundaryHoc(TemplateComponent) {
	class ErrorBoundary extends React.Component {
		static getDerivedStateFromError(error) {
			/**
			 * Is called during the “render” phase, so side-effects are not permitted.
			 * For those use cases, use componentDidCatch() instead.
			 */
			return { hasError: true, error };
		}

		constructor(props) {
			super(props);

			this.state = {
				hasError: false,
				error: null,
				errorInfo: null,
			};
		}

		componentDidCatch(error, errorInfo) {
			/**
			 * Note
			 * In the event of an error, you can render a fallback UI with componentDidCatch() by calling setState, but this will be deprecated in a future release.
			 * Use static getDerivedStateFromError() to handle fallback rendering instead.
			 *
			 * Is called during the “commit” phase, so side-effects are permitted.
			 * It should be used for things like logging error
			 */
			this.unlisten = this.props.history.listen(() => {
				this.setState({
					hasError: false,
					error: null,
					errorInfo: null,
				});
			});

			this.setState({
				hasError: true,
				error,
				errorInfo,
			});

			if (this.props.onError) {
				this.props.onError(error, errorInfo);
			}
		}

		componentWillUnmount() {
			if (this.unlisten != null) {
				this.unlisten();
			}
		}

		reload() {
			this.setState({
				hasError: false,
				error: null,
				errorInfo: null,
			});

			window.location.reload();
		}

		render() {
			if (this.state.hasError) {
				return (
					<TemplateComponent
						error={this.state.error}
						errorInfo={this.state.error}
						reload={this.reload.bind(this)}
						{...this.props}
					/>
				);
			}
			return this.props.children;
		}
	}

	return withRouter(ErrorBoundary);
}
