import { computed, action, runInAction } from "mobx";
import { Subscription } from "rxjs";

import { LiveStatus, ProviderNameEnum } from "@gp/models";
import { BettingOfferManagementHub } from "@gp/hub";
import { ISubscriptionRequest } from "@gp/hub";
import { MainOfferStore, EventOffer } from "@gp/offer";

import LoaderStore from "@state/stores/common/LoaderStore";

export default class LmtLsEventStore extends MainOfferStore {
	private hub: BettingOfferManagementHub;
	private loaderStore: LoaderStore;
	private subscription: Subscription | null;

	@computed public get lmtEvents() {
		return this.events.filter((e) => {
			if (e.source != null && e.liveStatus === LiveStatus.LIVE) {
				if (e.source?.providerName == ProviderNameEnum.LSports) {
					return WEBPACK_IS_LS_WIDGET_ENABLED;
				}
				return true;
			}
		}) as EventOffer[];
	}

	@computed public get lsEvents() {
		return this.events.filter((e) =>
			["canceled", "live", "upcoming"].includes(
				e.liveStreamStatus as string
			)
		) as EventOffer[];
	}

	constructor(hub: BettingOfferManagementHub) {
		super();
		this.hub = hub;
		this.loaderStore = new LoaderStore();
	}

	@action.bound
	public onInitialize() {
		this.disposeSubscription();
		this.loaderStore.suspend();

		const subscriptionRequest: ISubscriptionRequest = {
			subscriptionId: "live-events-menu-store",
			compress: WEBPACK_OFFER_COMPRESS,
			channels: [
				{
					name: "event",
					filter: [
						{
							liveStreamStatus: {
								eq: ["live", "upcoming", "cancelled"],
							},
						},
						{
							liveStatus: LiveStatus.LIVE,
						},
					],
				},
			],
		};

		this.subscription = this.hub
			.getOfferSubscription(subscriptionRequest)
			.subscribe({
				next: (response) => {
					runInAction(() => {
						this.assignOfferData(response);
						this.loaderStore.resume();
					});
				},
				error: (err) => {
					console.error(err);
					this.loaderStore.resume();
				},
			});
	}

	@action.bound
	public onDispose() {
		this.disposeSubscription();
		this.loaderStore.resume();
	}

	@action.bound
	private disposeSubscription() {
		if (this.subscription != null) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}
}
