import { TOfferRequest } from "@gp/hub";
import { PeriodConverter } from "@state";
import {
	IReactionDisposer,
	action,
	computed,
	observable,
	reaction,
	runInAction,
} from "mobx";

import PrematchOfferApiService from "@api-services/offer/PrematchOfferApiService";
import { Period } from "@state/common/PeriodConverter";
import { FavoriteEventsCounterStore } from "./components/offer-menu/counters";
import { TRootStore } from "./components/offer-menu/BaseOfferMenuStore";
import { LiveStatus } from "@gp/models";
import { FavoritesStore as GPFavoriteStore } from "@gp/offer";
import { FavoritesService } from "@api-services/favorites/FavoritesService";
import { FavoriteTournamentsCounterStore } from "./components/offer-menu/counters/FavoriteTournamentCounterStore";

export default class FavoriteStore extends GPFavoriteStore {
	favoriteEventsStoreCounter: FavoriteEventsCounterStore;
	favoriteTournamentsCounterStore: FavoriteTournamentsCounterStore;

	@observable period: Period = "full";
	@observable isStoreInitialized = false;
	prematchFavoriteEventIdsReaction: IReactionDisposer | null;
	liveFavoriteEventIdsReaction: IReactionDisposer | null;

	constructor(rootStore: TRootStore) {
		super({
			httpClient: new FavoritesService(),
			notificationProvider: App.state.rootStore.notificationStore,
		});
		this.favoriteEventsStoreCounter = new FavoriteEventsCounterStore(
			rootStore,
			this
		);
		this.favoriteTournamentsCounterStore =
			new FavoriteTournamentsCounterStore(rootStore, this);
	}

	@computed get liveFavoritesCounter() {
		return this.favoriteEventsStoreCounter.liveFavoritesCount;
	}

	@computed get prematchFavoritesCount() {
		return this.favoriteEventsStoreCounter.prematchFavoritesCount;
	}

	@computed get favoriteTournamentsCount() {
		return this.favoriteTournamentsCounterStore.tournamentFavoritesCount;
	}

	//#region prematch favorites events
	@action.bound
	public async fetchUserFavorites() {
		await this.getUserFavorites();
		this.isStoreInitialized = true;
	}

	@action.bound
	public async onInitializePrematchFavorites() {
		this.favoriteTournamentsCounterStore.onInitialize();
		this.prematchFavoriteEventIdsReaction = reaction(
			() => ({
				userFavoriteEventsSet: this.userFavoriteEventsSet,
				period: this.period,
			}),
			() => {
				runInAction(() => {
					this.favoriteEventsStoreCounter.fetchPrematchFavoritesCounter();
				});
			},
			{
				fireImmediately: true,
			}
		);
	}

	@action.bound
	public onInitializeLiveFavorites() {
		this.liveFavoriteEventIdsReaction = reaction(
			() => ({ userFavoriteEventsSet: this.userFavoriteEventsSet }),
			() => {
				runInAction(() => {
					this.favoriteEventsStoreCounter.fetchLiveFavoritesCounter();
				});
			},
			{
				fireImmediately: true,
			}
		);
	}

	@action.bound
	async deselectFavoritesEvents(liveStatus: LiveStatus) {
		if (this.userFavoriteEventsSet.size === 0) {
			return;
		}

		const favFilter: TOfferRequest = {
			filter: [
				{
					id: {
						eq: [...this.userFavoriteEventsSet],
					},
					liveStatus: liveStatus,
					offers: null,
				},
			],
		};

		try {
			const data = await PrematchOfferApiService.GetOffer(favFilter);
			let eventsIds: string[];

			if (!data.offer) {
				eventsIds = [];
			} else {
				eventsIds = data?.offer?.map((event) => event.eventId);
			}

			if (!eventsIds) {
				return;
			}

			await this.removeMultipleUserFavoriteEvents(eventsIds);
		} catch (err) {
			console.error(err);
		}
	}

	@action.bound
	async deselectFavoritesTournaments() {
		if (this.userFavoriteTournamentsSet.size === 0) {
			return;
		}

		try {
			await this.removeMultipleUserFavoriteTournaments([
				...this.userFavoriteTournamentsSet,
			]);
		} catch (err) {
			console.error(err);
		}
	}

	@action.bound
	onDispose() {
		this.disposePrematchReaction();
		this.disposeLiveReaction();
		this.favoriteEventsStoreCounter.onDispose();
		this.reset();
		this.isStoreInitialized = false;
	}

	@action.bound
	disposePrematchReaction() {
		//maybe it works
		if (this.prematchFavoriteEventIdsReaction != null) {
			this.prematchFavoriteEventIdsReaction();
			this.prematchFavoriteEventIdsReaction = null;
		}
		this.favoriteEventsStoreCounter.onDispose();
		this.favoriteTournamentsCounterStore.onDispose();
	}

	@action.bound
	disposeLiveReaction() {
		if (this.liveFavoriteEventIdsReaction != null) {
			this.liveFavoriteEventIdsReaction();
			this.liveFavoriteEventIdsReaction = null;
		}
		this.favoriteEventsStoreCounter.onDispose();
	}

	//#region prematch favorites betting types

	//#region utility

	@action.bound
	setPeriod(period: Period) {
		this.period = period;
	}

	getTime() {
		if (this.period == "full") {
			return {};
		}
		return {
			startTime: {
				lt: PeriodConverter.CalculateOfferInterval(this.period),
			},
		};
	}

	//#endregion utility
}
