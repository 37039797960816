import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getCurrentCulture } from "@utils";

export default function ForgotPasswordSuccess(props: {
	isForgotPasswordSuccess: boolean;
}) {
	const { t } = useTranslation();

	if (!props.isForgotPasswordSuccess) {
		return null;
	}

	return (
		<div className="at-forgotPwdSuccess card--message">
			<div className="card--message__thumb" />
			<div className="card--message__body">
				<div className="card--message__content">
					<i className="card--message__icon u-icon u-icon--xlrg u-icon--check-circle u-icon--animated--bounce-in u-color--ui--success" />
					<div>
						{t<string>(
							"MEMBERSHIP.FORGOT_PASSWORD.SUCCESSFUL_REQUEST"
						)}
					</div>
					<div>
						{t<string>("MEMBERSHIP.FORGOT_PASSWORD.FP_CHECK_EMAIL")}
					</div>
				</div>
				<div className="card--message__footer">
					<Link
						to={`/${getCurrentCulture()}/home/full/highlights`}
						className="card--message__cta btn btn--lrg btn--primary btn--icon btn--animate-forward"
					>
						<span className="btn__label">
							{t<string>(
								"MEMBERSHIP.FORGOT_PASSWORD.BACK_TO_HOME_PAGE"
							)}
						</span>
						<i className="btn__icon u-icon u-icon--xsml u-icon--arrow--right u-color--core--snow" />
					</Link>
				</div>
			</div>
		</div>
	);
}
