import React, { forwardRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const ButtonCore = forwardRef(
	(
		props: PropTypes &
			Omit<
				React.DetailedHTMLProps<
					React.ButtonHTMLAttributes<HTMLButtonElement>,
					HTMLButtonElement
				>,
				"className" | "type" | "ref"
			>,
		ref: React.Ref<HTMLButtonElement> | null
	) => {
		const {
			className,
			btnContent = undefined,
			btnText,

			// Icons
			iconLeft = null,
			iconRight = null,
			iconOnly = null,
			iconClassName = null,

			// Test classes
			atClassName = null,

			// Types
			btnType = "submit",
			isLoading = false,
			...rest
		} = props;
		const { t } = useTranslation();

		const btnClasses = classNames(
			{
				[`at-${atClassName}`]: atClassName,
			},
			"btn",
			className,
			{
				"btn--icon": iconLeft || iconRight || iconOnly,
			}
		);

		const iconClasses = classNames("u-icon", iconClassName);

		return (
			<button className={btnClasses} type={btnType} ref={ref} {...rest}>
				{iconLeft && <i className={iconClasses} />}

				{!isLoading && btnText != undefined && (
					<span className="btn__label">{t<string>(btnText)}</span>
				)}

				{isLoading ? <span className="btn--load"></span> : null}

				{btnContent != undefined && btnContent}

				{!isLoading && (iconRight || iconOnly) && (
					<i className={iconClasses} />
				)}
			</button>
		);
	}
);

export const Button = forwardRef(function (
	props: PropTypes & Omit<Parameters<typeof ButtonCore>[0], "ref">,
	ref: React.Ref<HTMLButtonElement>
) {
	return <ButtonCore {...props} ref={ref} />;
});

type PropTypes = {
	className?: string;
	btnContent?: JSX.Element;
	btnText?: string;

	// Icons
	iconLeft?: boolean;
	iconRight?: boolean;
	iconOnly?: boolean;
	iconClassName?: string;

	// at theme class name
	atClassName?: string;

	// Type
	btnType?: "button" | "submit";

	isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
