import React, { useContext } from "react";
import { observer } from "mobx-react";

import { EventContext, OfferStoreContext } from "@gp/components";

import { EventDetails, EventOutrightOffer } from ".";

export const EventOutright = observer(function EventOutright() {
	const offerStore = useContext(OfferStoreContext);
	const event = useContext(EventContext);

	const eventOffer = offerStore.eventKeysMap.get(event.id);

	return (
		<div className="offer__body__row offer__outright">
			<div className="offer__body__data offer__outright__details">
				<EventDetails />
			</div>

			<EventOutrightOffer
				eventOffer={eventOffer}
				offerStore={offerStore}
			/>
		</div>
	);
});
