import React, { FC, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DepositContext } from "@context";

export const DepositTransactionStatusFilterSection: FC = observer(
	function DepositTransactionStatusFilterSection() {
		const { t } = useTranslation();
		const { register } = useFormContext();
		const { transactionStatuses, fetchTransactionStatuses } =
			useContext(DepositContext);

		useEffect(() => {
			fetchTransactionStatuses();
		}, []);

		return (
			<div className="form__group">
				<label htmlFor="transactionStatusSelect" className="label">
					{t<string>("DEPOSIT.STATUS")}
				</label>
				<select
					id="transactionStatusSelect"
					className="input input--base input--select"
					{...register("transactionStatusId")}
				>
					<option key="transactionStatus-all" value="all">
						{t<string>("DEPOSIT.ALL_STATUS")}
					</option>
					{transactionStatuses?.map((transactionStatus) => (
						<option
							key={transactionStatus.abrv}
							value={transactionStatus.id}
						>
							{transactionStatus.name}
						</option>
					))}
				</select>
			</div>
		);
	}
);