import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	FormSelectField,
	TextareaField,
	CheckboxField,
} from "../../shared/inputs";
import { FormButton } from "../../shared/buttons";
import {
	legalLockTranslationKeys,
	mapArray,
} from "@v2/helpers/account-settings/account-lock/utils";
import { useFormContext } from "react-hook-form";
import { setTabTitle } from "@utils";
import {
	UserAccountLockDurationTypesModel,
	UserAccountLockReasonTypesModel,
} from "@api-types";
import { AccountClosureViewModel } from "@data-types";
import { isSpelpausEnabled } from "@v2/helpers";
import { useRootAppStore } from "@hooks";

class NoLockSectionPropTypes {
	lookups: {
		reasonTypes: UserAccountLockReasonTypesModel[];
		durationTypes: UserAccountLockDurationTypesModel[];
	};
	fetchedData: AccountClosureViewModel;
	onSubmitData: () => void;
}

export default function NoLockSection(props: NoLockSectionPropTypes) {
	const { lookups, fetchedData, onSubmitData } = props;

	const { t } = useTranslation();
	const { handleSubmit } = useFormContext();
	const rootStore = useRootAppStore();
	useEffect(() => {
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.ACCOUNT_LOCK"));

		//on mount scroll to top
		document.querySelector("html, body")?.scrollTo(0, 0);
	}, []);

	if (fetchedData == null) {
		return null;
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmitData)}>
			<div className="card--primary__body">
				<div className="message message--base message--note u-mar--bottom--big">
					<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
					<div className="message__content">
						<ul>
							<li>
								{legalLockTranslationKeys
									.map((key) => t(key))
									.join(" ")}
							</li>
						</ul>
						{isSpelpausEnabled ? (
							<div>
								{t<string>(
									"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.SPELPAUS_EXCLUSION_MESSAGE"
								)}

								<div className="u-display--f u-display--f--center">
									<i className="u-icon u-icon--xsml u-icon--arrow--right u-mar--right--xsml" />
									<a
										className="u-anchor u-anchor--underline u-anchor--primary"
										href="https://www.spelpaus.se/"
										target="_blank"
									>
										Spelpaus
									</a>
								</div>
							</div>
						) : null}
						{rootStore.userAuthStore.user
							?.isRofusCSRPValidationEnabled ? (
							<div className="u-mar--top--sml">
								{t<string>(
									"specific:USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ROFUS_EXCLUSION"
								)}

								<div>
									<i className="u-icon u-icon--xsml u-icon--arrow--right u-mar--right--xsml" />
									<a
										className="u-anchor u-anchor--underline u-anchor--primary"
										href="https://www.spillemyndigheden.dk/en/rofus"
										target="_blank"
									>
										Rofus
									</a>
								</div>
							</div>
						) : null}
					</div>
				</div>
				<div className="row">
					<FormSelectField
						className="col col-sml-12 form__field"
						name="lockDuration"
						placeholder={t(
							"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_DURATION_PLACEHOLDER"
						)}
						label={t(
							"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_DURATION_LABEL"
						)}
						dropdownData={mapArray(lookups.durationTypes)}
						required
					/>
					<FormSelectField
						className="col col-sml-12 form__field"
						name="lockReason"
						placeholder={t(
							"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_REASON_PLACEHOLDER"
						)}
						label={t(
							"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_REASON_LABEL"
						)}
						dropdownData={mapArray(lookups.reasonTypes)}
						required
					/>

					<TextareaField
						className="col col-sml-12 form__field"
						name="explanation"
						label="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.EXPLANATION_LABEL"
						placeholder="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.EXPLANATION_PLACEHOLDER"
					/>
					<CheckboxField
						checkboxClassname="col col-sml-12 form__field u-mar--top--xsml"
						id="isPromoApproved"
						className="input--check"
						name="isPromoApproved"
						label={t(
							"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.NEWSLETTER_LABEL"
						)}
					/>
				</div>
			</div>
			<div className="card--primary__footer">
				<FormButton
					className="card--primary__footer__btn btn btn--lrg btn--primary"
					btnText="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.SAVE_BUTTON"
				/>
			</div>
		</form>
	);
}
