import React from "react";
import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

export default observer(function LiveEmptyState(props: {
	period: string;
	totalEventCountEmpty?: number;
}) {
	const params = useParams<{ culture: string }>();

	const t = useTranslation().t;

	let icon = "live-highlights",
		title,
		message;
	let link: {
		path: string;
		message: string | JSX.Element;
	} = { path: "", message: "" };
	switch (props.period) {
		case "upcoming":
			icon = "upcoming";
			title = t("LIVE.UPCOMING_LIVE_EVENTS");
			message = t("LIVE.UPCOMING.NO_EVENTS");
			link.path = `/${params.culture}/live/events`;
			link.message = t("SPORTS.GO_LIVE_BETTING");
			break;
		case "highlights":
			icon = "live-highlights";
			title = t("HOME.LIVE_HIGHLIGHTS.EMPTY.SUBTITLE");
			message = t("HOME.LIVE_HIGHLIGHTS.EMPTY.DESCRIPTION");
			link.path = `/${params.culture}/live/events`;
			link.message = t("SPORTS.GO_LIVE_BETTING");
			break;
		case "my-favorites":
			// TODO Missing "u-icon--empty--favorites"
			icon = "live-highlights";
			title = t("LIVE.LIVE_SUB_MENU.MY_LIVE_FAVORITES");
			message = t("LIVE.FAVORITES.NO_EVENTS");
			if (props.totalEventCountEmpty) {
				link.path = `/${params.culture}/sports/full`;
				link.message = t("LIVE.GO_SPORTS_BETTING");
			} else {
				message = (
					<>
						<div>
							{t("LIVE.FAVORITES.NO_EVENTS")}{" "}
							{t("LIVE.FAVORITES.MY_LIVE_FAVORITES")}.{" "}
							{t("LIVE.FAVORITES.CLICK_ON")}{" "}
							<i className="u-icon u-icon--big u-icon--favorite--alt" />{" "}
							{t("LIVE.FAVORITES.TO_ADD_EVENT")}{" "}
							<i className="u-icon u-icon--big u-icon--favorite--bold" />{" "}
							{t("LIVE.FAVORITES.REMOVE_EVENT")}
						</div>
					</>
				);
				link.path = `/${params.culture}/live/events`;
				link.message = <div>{t("SPORTS.GO_LIVE_BETTING")} </div>;
			}
			break;
		default:
			icon = "live-highlights";
			title = t("LIVE.NO_LIVE_EVENTS");
			message = t("LIVE.UPCOMING.NO_EVENTS");
			link.path = `/${params.culture}/sports/full`;
			link.message = t("LIVE.GO_SPORTS_BETTING");
			break;
	}

	return (
		<div className="l--content__main u-padd--y--xsml">
			<div className="container">
				<div className="row">
					<div className="col col-sml-12 u-mar--bottom--xsml">
						<div className="card--empty u-mar--bottom--reset">
							<div className="card--empty__content">
								<i
									className={
										"card--empty__icon u-icon u-icon--huge u-icon--empty--" +
										icon
									}
								/>
								<div className="card--empty__title">
									{title}
								</div>
								<div className="card--empty__message">
									{message}
								</div>
								<Link
									to={link.path}
									className="card--empty__btn btn btn--sml btn--secondary--inverted btn--icon btn--animate-forward"
								>
									{link.message}
									<i className="u-icon u-icon--xsml u-icon--arrow--right" />
								</Link>
							</div>
						</div>
					</div>

					{props.totalEventCountEmpty ? (
						<SportsAndStatisticsLink />
					) : null}
				</div>
			</div>
		</div>
	);
});

function SportsAndStatisticsLink() {
	const t = useTranslation().t;
	const params = useParams<{ culture: string }>();
	return (
		<>
			<div className="col col-sml-12 col-med-12 col-lrg-6 col-xlrg-6 u-mar--bottom--xsml">
				<div className="card--empty card--empty--grow">
					<div className="card--empty__content">
						<i className="card--empty__icon u-icon u-icon--huge u-icon--empty--sports" />
						<div className="card--empty__title">
							{t("LIVE.SPORTS_BETTING")}
						</div>
						<div className="card--empty__message">
							<div>
								<span className="u-separator--disc" />
								{t("LIVE.PLACE_BETS")}
							</div>
							<div>
								<span className="u-separator--disc" />
								{t("LIVE.CASH_OUT")}
							</div>
						</div>
						<Link
							to={`/${params.culture}/sports/full`}
							className="card--empty__btn btn btn--sml btn--secondary--inverted btn--icon btn--animate-forward"
						>
							{t("LIVE.GO_SPORTS_BETTING")}
							<i className="u-icon u-icon--xsml u-icon--arrow--right" />
						</Link>
					</div>
				</div>
			</div>
			<div className="col col-sml-12 col-med-12 col-lrg-6 col-xlrg-6 u-mar--bottom--xsml">
				<div className="card--empty card--empty--grow">
					<div className="card--empty__content">
						<i className="card--empty__icon u-icon u-icon--huge u-icon--empty--stats" />
						<div className="card--empty__title">
							{t("LIVE.BET_STATISTICS")}
						</div>
						<div className="card--empty__message">
							<div>
								<span className="u-separator--disc" />
								{t("LIVE.FOLLOW_STATISTICS")}
							</div>
							<div>
								<span className="u-separator--disc" />
								{t("LIVE.WE_OFFER_HUGE_OVERVIEW")}
							</div>
						</div>
						<a
							href={"https://s5.sir.sportradar.com/tipwin/"}
							className="card--empty__btn btn btn--sml btn--secondary--inverted btn--icon btn--animate-forward"
							target="_blank"
						>
							{t("LIVE.CHECK_STATISTICS")}
							<i className="u-icon u-icon--xsml u-icon--arrow--right" />
						</a>
					</div>
				</div>
			</div>
		</>
	);
}
