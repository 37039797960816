import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";
import { DateTime } from "luxon";
import { range } from "lodash";
import { useTranslation } from "react-i18next";

import { MatchStatuses } from "@common/constants/MatchStatuses";
import { useNotification } from "@v2/hooks/shared";
import { ScoreHeaderRenderer, ScoreRenderer } from "./";
import {
	openTournamentStatistics,
	openEventStatistics,
	SportAbrv,
} from "@utils";
import {
	ResultContext,
	ResultEventContext,
	ResultsMenuStoreContext,
	ResultTournamentContext,
} from "@context/results";
import { MappedEvent, ResultFormattedTournament } from "@data-types/results";
import OfferIcon from "@v2/components/shared/icons/Offer";

const tbodyColspanMax = 11;

export const ResultTables = observer(function ResultTables() {
	const resultMenuStore = useContext(ResultsMenuStoreContext);

	const { showError } = useNotification();

	useEffect(() => {
		async function fetchData() {
			try {
				resultMenuStore.loader.suspend();
				await resultMenuStore.resultsDataStore.onInitialize();
			} catch (error) {
				showError(error);
			} finally {
				resultMenuStore.loader.resume();
			}
		}

		fetchData();
	}, [resultMenuStore.resultsDataStore.dayParam]);

	return (
		<div className="card--primary__body">
			<ResultTablesWrapper />
		</div>
	);
});

const ResultTablesWrapper = observer(function ResultTablesWrapper() {
	const resultsMenuStore = useContext(ResultsMenuStoreContext);
	const translationHook = useTranslation();

	if (resultsMenuStore.resultsDataStore.resultData == null) {
		return null;
	}

	if (resultsMenuStore.resultsDataStore.resultData.length === 0) {
		return (
			<div className="card--primary__body">
				<div className="message message--base message--note">
					<i className="message__icon u-icon u-icon--lrg u-icon--info-circle u-color--ui--note" />
					<div className="message__content">
						<div className="message__text">
							{translationHook.t<string>(
								"RESULTS.RESULT_CONTENT.EMPTY_STATE_TITLE"
							)}
						</div>
						<div className="message__text">
							{translationHook.t<string>(
								"RESULTS.RESULT_CONTENT.EMPTY_STATE_DESCRIPTION"
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			{resultsMenuStore.resultsDataStore.resultData.map((result) => (
				<ResultContext.Provider
					value={result}
					key={`${result.sport.id}-${result.sportCategory.id}`}
				>
					<ResultTable />
				</ResultContext.Provider>
			))}
		</>
	);
});

const ResultTable = observer(function ResultTable() {
	const result = useContext(ResultContext);

	return (
		<table
			className={`table--results table--results--${result.sport.abrv}`}
		>
			<ResultTableHeader />
			<ResultTournamentWrapper />
		</table>
	);
});

const ResultTableHeader = function ResultTableHeader() {
	const result = useContext(ResultContext);

	return (
		<thead className="table--results__header">
			<tr className="table--results__header__row">
				<th
					className="table--results__header__data u-type--left"
					colSpan={12}
				>
					<OfferIcon
						type="sport"
						sport={result.sport.abrv}
						className="u-icon u-icon--med u-align--v--middle u-mar--right--xtny"
					/>
					<span className="u-align--v--middle">
						{result.sport.name} - {result.sportCategory.name}
					</span>
				</th>
			</tr>
		</thead>
	);
};

const ResultTournamentWrapper = function ResultTournamentWrapper() {
	const result = useContext(ResultContext);

	return (
		<>
			{result.tournaments.map(
				(
					tournament: ResultFormattedTournament<
						Map<string, MappedEvent[]>
					>
				) => (
					<ResultTournamentContext.Provider
						value={tournament}
						key={tournament.id}
					>
						<ResultTournament />
					</ResultTournamentContext.Provider>
				)
			)}
		</>
	);
};

const ResultTournament = function ResultTournament() {
	const tournament = useContext(ResultTournamentContext);

	const translationHook = useTranslation();

	return (
		<React.Fragment>
			<thead className="table--results__subheader">
				<tr className="table--results__subheader__row">
					<th
						className="table--results__subheader__data table--results__subheader__data--tournament"
						colSpan={2}
					>
						{tournament.name}
					</th>
					<ResultTournamentStatisticsButton />
					<ScoreHeaderRenderer />

					<th className="table--results__subheader__data table--results__subheader__data--tip">
						{translationHook.t<string>(
							"RESULTS.RESULT_CONTENT.TIP"
						)}
					</th>
					<th className="table--results__subheader__data table--results__subheader__data--odd">
						{translationHook.t<string>(
							"RESULTS.RESULT_CONTENT.ODD"
						)}
					</th>
					<EmptyHeaderSpacesWrapper />
					<th className="table--results__subheader__data is-empty">
						&nbsp;
					</th>
				</tr>
			</thead>
			<tbody className="table--results__body">
				<ResultTournamentEventsTable />
			</tbody>
		</React.Fragment>
	);
};

const ResultTournamentEventsTable = observer(
	function ResultTournamentEventsTable() {
		const tournament = useContext(ResultTournamentContext);

		return (
			<>
				{[...tournament.events].map(
					(eventGroup: [string, MappedEvent[]]) => {
						return (
							<EventGroup
								key={`${tournament.id}-${eventGroup[0]}`}
								eventGroup={eventGroup}
							/>
						);
					}
				)}
			</>
		);
	}
);

const EventGroup = observer(function EventGroup(props: {
	eventGroup: [string, MappedEvent[]];
}) {
	return (
		<React.Fragment>
			<tr className="table--results__body__row table--results__body__row--bordered">
				<td
					className="table--results__body__data table--results__body__data--date"
					colSpan={tbodyColspanMax}
				>
					{props.eventGroup[0]}
				</td>
			</tr>

			{props.eventGroup[1].map((event: MappedEvent) => {
				return (
					<ResultEventContext.Provider key={event.id} value={event}>
						<ResultEvent />
					</ResultEventContext.Provider>
				);
			})}
		</React.Fragment>
	);
});

const ResultEvent = function ResultEvent() {
	const event = useContext(ResultEventContext);

	return (
		<tr className="table--results__body__row">
			<td className="table--results__body__data table--results__body__data--time">
				{DateTime.fromISO(event.startTime).toFormat("T")}
			</td>
			<td className="table--results__body__data table--results__body__data--teams">
				<div>{event.teamOne}</div>
				<div>{event.teamTwo}</div>
			</td>

			<ResultEventData />
		</tr>
	);
};

const ResultEventData = function ResultEventData() {
	const event = useContext(ResultEventContext);
	const translationHook = useTranslation();

	if (MatchStatuses.includes(event.matchStatus.abrv)) {
		return (
			<>
				<td className="table--results__body__data table--results__body__data--stats">
					&nbsp;
				</td>
				<td
					className="table--results__body__data table--results__body__data--postponed"
					colSpan={tbodyColspanMax - 3}
				>
					{translationHook.t<string>(
						"RESULTS.RESULT_CONTENT.POSTPONED"
					)}
				</td>
			</>
		);
	}

	return (
		<>
			<ResultEventStatisticsButton />

			<ScoreRenderer />

			<td className="table--results__body__data table--results__body__data--tip">
				<strong>{event.tip}</strong>
			</td>

			<Odd />
			<ResultEventEmptySpaces />
		</>
	);
};

const ResultEventEmptySpaces = function ResultEventEmptySpaces() {
	const result = useContext(ResultContext);
	const event = useContext(ResultEventContext);
	const tbodyColspan = calculateTableBodyColspan(result.sport.abrv);

	if (tbodyColspan > tbodyColspanMax) {
		return null;
	}

	return (
		<>
			{range(tbodyColspanMax - tbodyColspan).map((item, idx) => {
				return (
					<th
						key={`${event.id}-${idx}`}
						className="table--results__body__data is-empty"
					>
						&nbsp;
					</th>
				);
			})}
		</>
	);
};

const Odd = function Odd() {
	const event = useContext(ResultEventContext);

	if (event.odd !== "-" && parseFloat(event.odd) % 1 === 0) {
		return (
			<td className="table--results__body__data table--results__body__data--odd">
				<span className="u-type--wgt--medium">
					{parseFloat(event.odd).toFixed(1)}
				</span>
			</td>
		);
	}

	return (
		<td className="table--results__body__data table--results__body__data--odd">
			<span className="u-type--wgt--medium">{event.odd}</span>
		</td>
	);
};

const ResultEventStatisticsButton = function ResultEventStatisticsButton() {
	const event = useContext(ResultEventContext);
	const [isHover, setIsHover] = useState(false);

	if (
		event.source != null &&
		event.source.providerId != null &&
		event.source.providerId !== ""
	) {
		return (
			<td className="table--results__body__data table--results__body__data--stats">
				<button
					className="btn btn--link"
					type="button"
					onClick={() => {
						if (event?.source?.providerId == null) {
							console.error("Expected event source but got null");
							return;
						}
						openEventStatistics(event.source.providerId);
					}}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
				>
					<i
						className={classnames("u-icon u-icon--med", {
							"u-icon--action--stats--active": !isHover,
							"u-icon--action--stats--selected": isHover,
						})}
					/>
				</button>
			</td>
		);
	}

	return (
		<td className="table--results__body__data table--results__body__data--stats" />
	);
};

const ResultTournamentStatisticsButton =
	function ResultTournamentStatisticsButton() {
		const tournament = useContext(ResultTournamentContext);

		const shouldDisplayStatisticsButton =
			tournament.source != null &&
			tournament.source.providerId != null &&
			tournament.source.providerId !== "";

		const [isHover, setIsHover] = useState(false);

		if (shouldDisplayStatisticsButton) {
			return (
				<th className="table--results__subheader__data table--results__subheader__data--stats">
					<button
						className="btn btn--square--reset btn--ghost"
						type="button"
						onClick={() => {
							if (tournament.source?.providerId) {
								openTournamentStatistics(
									tournament.source.providerId
								);
							}
						}}
						onMouseEnter={() => setIsHover(true)}
						onMouseLeave={() => setIsHover(false)}
					>
						<i
							className={classnames("u-icon u-icon--med", {
								"u-icon--action--stats--active": !isHover,
								"u-icon--action--stats--selected": isHover,
							})}
						/>
					</button>
				</th>
			);
		}

		return (
			<th className="table--results__subheader__data table--results__subheader__data--stats" />
		);
	};

const EmptyHeaderSpacesWrapper = function EmptyHeaderSpacesWrapper() {
	const result = useContext(ResultContext);
	const tournament = useContext(ResultTournamentContext);
	var tbodyColspan = calculateTableBodyColspan(result.sport.abrv);

	if (tbodyColspan >= tbodyColspanMax) {
		return null;
	}

	return (
		<>
			{range(tbodyColspanMax - tbodyColspan).map((item, idx) => {
				return (
					<th
						key={`${tournament.id}-${idx}`}
						className="table--results__subheader__data is-empty"
					>
						&nbsp;
					</th>
				);
			})}
		</>
	);
};

function calculateTableBodyColspan(abrv: string): number {
	let tbodyColspan = 0;
	switch (abrv) {
		case SportAbrv.SQUASH:
		case SportAbrv.MMA:
		case SportAbrv.DARTS:
		case SportAbrv.TABLE_TENNIS:
		case SportAbrv.BOXING:
		case SportAbrv.BOWLS:
		case SportAbrv.CRICKET:
		case SportAbrv.SNOOKER:
			tbodyColspan = 6;
			break;
		case SportAbrv.BASEBALL:
		case SportAbrv.GOLF:
		case SportAbrv.MOTORSPORT:
		case SportAbrv.PESAPALLO:
		case SportAbrv.BEACH_SOCCER:
		case SportAbrv.ESOCCER:
		case SportAbrv.BANDY:
			tbodyColspan = 7;
			break;
		case SportAbrv.FUTSAL:
		case SportAbrv.HANDBALL:
		case SportAbrv.RUGBY:
		case SportAbrv.SOCCER:
			tbodyColspan = 8;
			break;
		case SportAbrv.AUSSIE_RULES:
		case SportAbrv.ICE_HOCKEY:
		case SportAbrv.FIELD_HOCKEY:
		case SportAbrv.AMERICAN_FOOTBALL:
			tbodyColspan = 10;
			break;
		case SportAbrv.BEACH_VOLLEY:
		case SportAbrv.BADMINTON:
		case SportAbrv.BASKETBALL:
		case SportAbrv.TENNIS:
		case SportAbrv.WATERPOLO:
		case SportAbrv.VOLLEYBALL:
		case SportAbrv.FLOORBALL:
			tbodyColspan = 11;
			break;
		default:
			tbodyColspan = 7;
			break;
	}

	return tbodyColspan;
}
