import { useContext, useState } from "react";
import { useLoading } from "@hooks";
import {
	AutomaticWithdrawalPost,
	AutomaticWithdrawalResponse,
} from "@api-types/account-settings/AutomaticWithrawal";
import { useNotification } from "@v2/hooks/shared";
import { AutomaticWithdrawalService } from "@services/account-settings/AutomaticWithdrawalService";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { RootAppStoreContext } from "@context";

export function useAutomaticWithdrawal(): UseAutomaticWithdrawal {
	const [withdrawalData, setWithdrawalData] =
		useState<null | AutomaticWithdrawalResponse>(null);
	const { setIsLoading } = useLoading();
	const { showError, showSuccess } = useNotification();
	const { t } = useTranslation();
	const { reset } = useFormContext();
	const [isVisible, setIsVisable] = useState(false);
	const { AccountVerificationStore } = useContext(RootAppStoreContext);

	const onInitialize = async () => {
		try {
			setIsLoading(true);
			const response =
				await AutomaticWithdrawalService.getUserAutomaticWithdrawalData();
			if (response) {
				setWithdrawalData(response);
				if (
					response.autoPayoutSetting.payoutThreshold !== 0 &&
					response.autoPayoutSetting.payoutAmount !== 0
				) {
					reset({
						payoutThreshold:
							"" + response.autoPayoutSetting.payoutThreshold,
						payoutAmount:
							"" + response.autoPayoutSetting.payoutAmount,
					});
				}
			}
		} catch (error) {
			console.error(error);
			showError(error?.message);
		} finally {
			setIsLoading(false);
		}
	};

	const setAutomaticWithdrawal = async (data: AutomaticWithdrawalPost) => {
		try {
			setIsLoading(true);
			if (data == undefined) {
				throw { message: "ERROR_OCCURED" };
			}
			const response =
				await AutomaticWithdrawalService.setUserAutomaticWithdrawalData(
					data
				);
			if (response && response.error == 0) {
				showSuccess(response.message);
				if (data.action == 1) {
					reset({
						payoutThreshold:
							data.userAutoPayoutSetting!.payoutThreshold,
						payoutAmount: data.userAutoPayoutSetting!.payoutAmount,
					});
					setWithdrawalData((n: AutomaticWithdrawalResponse) => ({
						...n,
						autoPayoutSetting: {
							...n.autoPayoutSetting,
							...data.userAutoPayoutSetting,
						},
					}));
				} else {
					reset({
						payoutThreshold: "",
						payoutAmount: "",
					});
					setWithdrawalData((n: AutomaticWithdrawalResponse) => ({
						...n,
						autoPayoutSetting: {
							...n.autoPayoutSetting,
							payoutThreshold: 0,
							payoutAmount: 0,
						},
					}));
				}
			} else {
				console.error(response);
				if (response?.error == 13) {
					AccountVerificationStore.showPopup();
				} else {
					showError(response?.message);
				}
			}
		} catch (error) {
			console.error(error);
			showError(
				"USER.ACCOUNT_SETTINGS.AUTOMATIC_WITHDRAWAL." + error?.message
			);
		} finally {
			setIsLoading(false);
		}
	};
	const onSubmit = (data: any) => {
		setAutomaticWithdrawal({
			userAutoPayoutSetting: { ...data },
			action: 1,
		});
	};

	const handleDeactivate = () => {
		hidePopup();
		setAutomaticWithdrawal({
			action: 2,
		});
	};

	const showPopup = () => {
		setIsVisable(true);
	};
	const hidePopup = () => {
		setIsVisable(false);
	};
	function validateThreshold(value: number) {
		if (
			value > withdrawalData!.autoPayoutSetting.maxThreshold ||
			value < withdrawalData!.autoPayoutSetting.minThreshold
		)
			return t(
				"USER.ACCOUNT_SETTINGS.AUTOMATIC_WITHDRAWAL.THRESHOLD_RANGE_ERROR",
				{
					0: withdrawalData!.autoPayoutSetting.minThreshold,
					1: withdrawalData!.autoPayoutSetting.maxThreshold,
				}
			);
	}
	function validateMinAmount(value: number) {
		if (
			value > withdrawalData!.autoPayoutSetting.maxPayout ||
			value < withdrawalData!.autoPayoutSetting.minPayout
		)
			return t(
				"USER.ACCOUNT_SETTINGS.AUTOMATIC_WITHDRAWAL.AMOUNT_RANGE_ERROR",
				{
					0: withdrawalData!.autoPayoutSetting.minPayout,
					1: withdrawalData!.autoPayoutSetting.maxPayout,
				}
			);
	}

	return {
		onInitialize,
		handleDeactivate,
		withdrawalData,
		onSubmit,
		validateMinAmount,
		validateThreshold,
		isVisible,
		showPopup,
		hidePopup,
	};
}

export type UseAutomaticWithdrawal = {
	onInitialize: () => Promise<void>;
	handleDeactivate: () => void;
	withdrawalData: AutomaticWithdrawalResponse | null;
	onSubmit: (data: any) => void;
	validateMinAmount: (value: number) => string | undefined;
	validateThreshold: (value: number) => string | undefined;
	isVisible: boolean;
	showPopup: () => void;
	hidePopup: () => void;
};
