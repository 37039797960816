import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import useCurrency from "@v2/hooks/shared/useCurrency";
import { RequiredMark, ErrorMessage } from "./";

export default function FormInputFieldCurrency(props) {
	const {
		type = "text",
		name,
		className,
		labelClassName,
		label,
		inputClassName = undefined,
		placeholder = undefined,
		required = false,
		/* marker = undefined, */
		validate,
		errorMessage = undefined,
		pattern,
		providedCurrency,
		...rest
	} = props;
	const form = useFormContext();
	const field = form.register(props.name, {
		required: {
			value: required,
			message: errorMessage || "COMMON.REQUIRED_FIELD",
		},
		validate,
		pattern,
	});

	const { t } = useTranslation();
	const { currency } = useCurrency();

	const labelClasses = classNames("label", labelClassName);

	const inputClasses = classNames(
		"form__field--icon__input",
		inputClassName,
		{
			"input input--base": inputClassName == undefined || inputClassName == null
		}
	);

	return (
		<div className={className}>
			<label className={labelClasses}>
				{t(label)}
				<RequiredMark required={required} />
			</label>

			<ErrorMessage name={name} />

			<div className="form__field--icon">
				<input
					type={type}
					{...field}
					{...rest}
					className={inputClasses}
					placeholder={t(placeholder)}
				/>

				<span className="form__field--currency__symbol">
					{providedCurrency != null ? providedCurrency : currency}
				</span>
			</div>
		</div>
	);
}