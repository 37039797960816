import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoading } from "@hooks";
import { useNotification } from "../../../../ui/common/hooks";
import { LimitsHistoryService } from "@services/account-settings/LimitsHistoryService";
import { setTabTitle } from "@utils";
import {
	GamingLimitCategoryViewModel,
	GamingLimitHistoryLookup,
	GamingLimitHistoryFormSubmit,
} from "@data-types";
import { AccountTypes } from "@utils";

const periodsLookup = [
	{
		name: "LAST_3_MONTHS",
		id: "last3months",
	},
	{
		name: "LAST_6_MONTHS",
		id: "last6months",
	},
	{
		name: "LAST_9_MONTHS",
		id: "last9months",
	},
	{
		name: "LAST_1_YEAR",
		id: "last1year",
	},
];

const defaultModel = {
	period: "last6months",
	gamingTypeId: "default",
};

// passed reset as a parameter for setting default value, used in LimitsHistoryPage
export function useLimitsHistory(
	reset: Function,
	activeAccount: AccountTypes = AccountTypes.OFFLINE
) {
	const [lookups, setLookups] = useState<GamingLimitHistoryLookup>({
		availableCategories: [],
		limitPeriods: [],
	});

	const { t } = useTranslation();
	const { showError } = useNotification();
	const { setIsLoading } = useLoading();

	const onInitializePage = async () => {
		setTabTitle(t("USER.ACCOUNT_SETTINGS.NAVIGATION.LIMITS_HISTORY"));
		document.querySelector("html, body")?.scrollTo(0, 0);

		try {
			setIsLoading(true);
			const categoriesResponse =
				await LimitsHistoryService.getGamingLimitCategories(
					activeAccount
				);
			setLookups({
				limitPeriods: periodsLookup,
				availableCategories: categoriesResponse,
			});
			reset(defaultModel);
		} catch (error) {
			showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR");
		} finally {
			setIsLoading(false);
		}
	};

	const onInitializeTableSection = async (
		filter: GamingLimitHistoryFormSubmit,
		setFetchedData: Function
	) => {
		try {
			setIsLoading(true);
			const response = await LimitsHistoryService.getLimitHistoryData(
				{
					...filter,
				},
				activeAccount
			);

			if (response) {
				setFetchedData(response);
			}
		} catch (error) {
			showError(
				`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`
			);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		onInitializePage,
		onInitializeTableSection,
		lookups,
	};
}
