import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LiveStreamCodeEnum } from "@api-types/event/LiveStreamCodeConstant";
import { ErrorCodeMainEnum } from "@api-types/common/ErrorCodeConstants";
import { EventLiveStreamService } from "@services/event/EventLiveStreamService";
import { useUser } from "@v2/hooks";
import { useLiveWidgetStore } from "@state/hooks/live-widgets";

import { WidgetLocation } from "../models";

export function useLiveStreamURLFetch(
	eventId: string,
	location: WidgetLocation
) {
	const [url, setUrl] = useState<string>();
	const [infoMessage, setInfoMessage] = useState<string>();
	const [errorMessage, setErrorMessage] = useState<string>();

	const store = useLiveWidgetStore();
	const user = useUser();
	const { t } = useTranslation();

	const myWidget = store.activeLsWidgets?.find(
		(w) =>
			(eventId == "" && location === "SIDE" && w.location === "SIDE") ||
			(w.eventId === eventId && w.location === location)
	);

	useEffect(() => {
		if (myWidget != null && (infoMessage != null || errorMessage != null)) {
			store.updateWidget({
				...myWidget,
				message: { infoMessage, errorMessage },
			});
		}
	}, [myWidget?.eventId, myWidget?.location, infoMessage, errorMessage]);

	const onInitialize = async () => {
		try {
			if (!eventId) {
				return;
			}
			const response = await EventLiveStreamService.getLiveStreamUrl(
				eventId
			);
			if (
				response.statusCode === ErrorCodeMainEnum.Success &&
				response.errorCode != null &&
				response.response != null
			) {
				setUrl(response.response);
			} else if (response.statusCode === ErrorCodeMainEnum.Error) {
				if (
					response.errorCode ===
						LiveStreamCodeEnum.InvalidIPAddress ||
					response.errorCode ===
						LiveStreamCodeEnum.StreamGeorestricted ||
					response.errorCode ===
						LiveStreamCodeEnum.UnauthorizedIPAddress
				) {
					setInfoMessage(
						t<string>(
							"LIVE_WIDGETS.LIVE_STREAM.COUNTRY_IP_RESTRICTION"
						)
					);
				}
			} else {
				store.addEventWithLsError(eventId);
				store.removeLiveStreamWidget(
					eventId || "",
					location || "EVENT_OFFER"
				);
				throw "LIVE_WIDGETS.LIVE_STREAM.STREAM_UNAVAILABLE";
			}
		} catch (error) {
			setErrorMessage(t(error));
		}
	};

	useEffect(() => {
		if (!user.isLoggedIn) {
			setErrorMessage(undefined);
			setInfoMessage(undefined);
			setUrl(undefined);
			return;
		}

		onInitialize();
		return () => {
			setErrorMessage(undefined);
			setInfoMessage(undefined);
			setUrl(undefined);
		};
	}, [eventId, user.isLoggedIn]);

	return { myWidget, url, infoMessage, errorMessage, setErrorMessage, user };
}
