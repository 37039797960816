import React, { FC, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { WithdrawalContext } from "@context";
import { DatePicker } from "@v2/components/desktop/shared/inputs";
import { DateTime } from "luxon";

export const WithdrawalCustomPeriodInput: FC =
	function WithdrawalCustomPeriodInput() {
		const { setValue, getValues } = useFormContext();
		const currentPeriod = useWatch({ name: "period" });
		const {
			withdrawalFilterStore: { rawData },
		} = useContext(WithdrawalContext);

		if (currentPeriod !== "custom") {
			return null;
		} else {
			setValue("from", rawData.from);
			setValue("to", rawData.to);
		}

		return (
			<>
				<DatePicker
					label="MY_BETS.PAGE.FILTER_FORM.START_DATE"
					name="from"
					className="form__group"
					required
				/>

				<DatePicker
					label="MY_BETS.PAGE.FILTER_FORM.END_DATE"
					name="to"
					className="form__group"
					validate={{
						isBiggerThanStartDate: (value: string) => {
							const formattedStartDate = DateTime.fromISO(
								getValues("from")
							)
								.startOf("day")
								.toISO();
							const formattedEndDate = DateTime.fromISO(value)
								.endOf("day")
								.toISO();

							return (
								formattedEndDate > formattedStartDate ||
								"COMMON.ERROR_MESSAGES.END_DATE_LOWER"
							);
						},
					}}
					required
				/>
			</>
		);
	};
