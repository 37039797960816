import {
	httpClient,
	getAgencyKey,
	getApplicationHeaders,
	UserTypes,
	getWebApiUrl,
	getUserToken,
	getMvcApplicationHeaders,
} from "@utils";

import { CashoutType } from "@gp/hub";

import {
	IBetSlipService,
	IBetSlipRequest,
	IBetSlipRequestOptions,
	IBetSlipResponse,
} from "@gp/slip";

import { getCurrentCulture } from "@utils";
import { LazyImportWithLoadFailHandle as lazy } from "@lib/lazy-import-with-guard/LazyImportWithGuard";
import { Bet } from "@administration/pages/my-bets/model";

const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const BetSlipApiService = lazy(loadFailPath, ()=>import("@api-services/bet-slip/BetSlipApiService"));
// prettier-ignore
const AgencyUserApiService = lazy(loadFailPath, ()=>import("@api-services/user/AgencyUserApiService"));
// prettier-ignore
const UserDataApiService = lazy(loadFailPath, ()=>import("@api-services/user/UserDataApiService"))

const url = `${getWebApiUrl()}/api/slip`;

class BetSlipService implements IBetSlipService {
	constructor() {
		this.validate = this.validate.bind(this);
		this.submit = this.submit.bind(this);
	}

	getRequestHeaders = (walletOwner = UserTypes.PRIMARY) => {
		const appHeaders = getApplicationHeaders(walletOwner);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	getMvcRequestHeaders = (walletOwner = UserTypes.PRIMARY) => {
		const appHeaders = getMvcApplicationHeaders(walletOwner);
		appHeaders["Content-Type" as keyof HeadersInit] = "application/json";
		return appHeaders;
	};

	async validate(
		slip: IBetSlipRequest,
		options: IBetSlipRequestOptions | undefined = undefined
	): Promise<IBetSlipResponse> {
		options = this.getOptions(options);

		const walletOwner = App.state.rootStore.userAuthStore.getWalletOwner(
			App.state.rootStore.userAuthStore.activeAccountTypeId
		);

		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			walletOwner
		)}/bet-slips/betting-slip-validate/2`;

		return await httpClient.post(
			url,
			JSON.stringify(slip),
			this.getRequestHeaders(walletOwner),
			options
		);
	}

	async submit(
		slip: IBetSlipRequest,
		options: IBetSlipRequestOptions | undefined = undefined
	): Promise<IBetSlipResponse> {
		options = this.getOptions(options);

		const walletOwner = App.state.rootStore.userAuthStore.getWalletOwner(
			App.state.rootStore.userAuthStore.activeAccountTypeId
		);

		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			walletOwner
		)}/bet-slips/betting-slip-validate/4`;

		return await httpClient.post(
			url,
			JSON.stringify(slip),
			this.getRequestHeaders(walletOwner),
			options
		);
	}

	async delay() {
		var options = this.getOptions(undefined);

		return await httpClient.get(
			`${url}/delay`,
			this.getMvcRequestHeaders(),
			options
		);
	}

	async getSlip(slipId: string, activeOwner = UserTypes.PRIMARY) {
		return await (
			await BetSlipApiService
		).default.getBet(
			slipId,
			"betSlipOffers,player.coreUser,betSlipStatus,betStatus,betSlipType,betSlipCombinations,betSlipSecureWinSetting",
			activeOwner
		);
	}

	async getSlipWithoutCombinations(
		slipId: string,
		owner = UserTypes.PRIMARY
	) {
		return await (
			await BetSlipApiService
		).default.getBet(
			slipId,
			"betSlipOffers,player.coreUser,betSlipStatus,betStatus,betSlipType,betSlipSecureWinSetting",
			owner
		);
	}

	async getSlipByEncryptedBetSlipNumber(encryptedBetSlipNumber: string) {
		var options = this.getOptions(undefined);
		return await httpClient.get(
			`${url}/check-bet-slip/${encryptedBetSlipNumber}`,
			this.getRequestHeaders(),
			options
		);
	}

	async getUserBalance() {
		return await (await UserDataApiService).default.getUserAccounts();
	}

	async saveNewDefaultStake(defaultStake: number) {
		return await (
			await AgencyUserApiService
		).default.saveNewDefaultStake(defaultStake);
	}

	getOptions = (options: IBetSlipRequestOptions | undefined = undefined) => {
		options = options || {};
		options.responseType = "json";

		return options;
	};

	async cashout(
		id: string,
		calculatedCashoutAmount: number,
		cType: CashoutType,
		isChangeAccepted: boolean,
		isLive: boolean,
		owner: UserTypes
	) {
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			owner
		)}/bet-slips/${id}/cashout`;
		const response = await httpClient.put(
			url,
			JSON.stringify({
				betSlipId: id,
				oldCashoutAmount: calculatedCashoutAmount,
				cType: cType,
				isChangedAmountAccepted: isChangeAccepted,
			}),
			this.getRequestHeaders(owner),
			{
				responseType: "json",
			}
		);

		return response;
	}

	async getCashoutHistory(id: string, activeUser = UserTypes.PRIMARY) {
		return await (
			await BetSlipApiService
		).default.getCashoutHistory(id, activeUser);
	}

	async cashoutWithQrCode(
		id: string,
		calculatedCashoutAmount: number,
		isChangeAccepted: boolean,
		isLive: boolean,
		code: number,
		betSlipNumber: string,
		userId: string | null,
		owner: UserTypes
	) {
		const url = `${WEBPACK_BASE_ADDRESS}${getAgencyKey(
			owner
		)}/bet-slips/cashout-with-qrcode/${betSlipNumber}`;
		const response = await httpClient.put(
			url,
			JSON.stringify({
				betSlipId: id,
				oldCashoutAmount: calculatedCashoutAmount,
				isChangedAmountAccepted: isChangeAccepted,
				code: code,
			}),
			// User token should be sent to BE, if it is not there, the default headers are taken
			getUserToken(owner) != null ||
				(getUserToken(owner) == null && userId == null)
				? this.getMvcRequestHeaders(owner)
				: this.getMvcRequestHeaders(UserTypes.PRIMARY),
			{
				responseType: "json",
			}
		);

		return response;
	}

	async getBetSlipWithBetSlipNumber(betSlipNumber: string): Promise<Bet> {
		return await httpClient.get(
			`${WEBPACK_BASE_ADDRESS}${getAgencyKey()}/bet-slips/share/${betSlipNumber}`,
			this.getRequestHeaders(),
			{ responseType: "json" }
		);
	}
}

export default BetSlipService;
