import React from "react";
import { observer } from "mobx-react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { PrivateRoute } from ".";
import { useAppContext, useUser } from "../../../../ui/common/hooks";
import { RequiredUserAction } from "@v2/state";

import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
const loadFailPath = `/${getCurrentCulture()}/app-update`;
// prettier-ignore
const MobileError404 = lazy(loadFailPath, ()=>import("@v2/components/mobile/shared/error/Error404"));
// prettier-ignore
const DesktopError404 = lazy(loadFailPath, ()=>import("@v2/components/desktop/shared/error/Error404"));

import { DeviceDetector, getCurrentCulture } from "@utils";
import { isCapacitorPlatform } from "@utils/specific/capacitor";

export const RouteElement = observer(function RouteElement(props: {
	exact?: boolean;
	privateRoute?: boolean;
	path: string | string[];
	children: JSX.Element | JSX.Element[] | null;
}) {
	const { privateRoute, path, children, ...rest } = props;
	const { culture } = useAppContext();
	const userStore = useUser();

	const { pathname, search } = useLocation();
	const isPaymentRoute =
		pathname.includes("/deposit") ||
		pathname.includes("/withdrawal") ||
		pathname.includes("/automatic-withdrawal") ||
		pathname.includes("/account-settings/my-bonuses") ||
		pathname.includes("/user-profile-recheck") ||
		pathname.includes("/limit-increase");

	const isCasinoRoute =
		pathname.includes("/games") || pathname.includes("/live-games");
	const accountVerification = pathname.includes("/account-verification");
	const path404 = pathname + search;

	if (
		(isPaymentRoute || isCasinoRoute || accountVerification) &&
		userStore.user != null &&
		!userStore.isInternetUser &&
		!pathname.includes("/my-bonuses")
	) {
		if (DeviceDetector.isMobileTheme) {
			return <MobileError404 path404={path404} />;
		}

		return <DesktopError404 path404={path404} />;
	}

	const bettingActivityUrl = `/${culture}/auth/betting-activity`;
	const accountActivationUrl = `/${culture}/auth/account-activation`;
	const accountActivationCongratulationsUrl = `/${culture}/auth/account-activation-congratulations`;
	const forcePasswordChangeUrl = `/${culture}/auth/force-password-change`;
	const acceptNewTermsUrl = `/${culture}/auth/accept-new-terms`;
	const loginUrl = `/${culture}/auth/login`;
	const registrationUrl = `/${culture}/auth/register`;
	const homeUrl = `/${culture}/home/full/highlights`;
	const forgotPasswordUrl = `/${culture}/auth/forgot-password`;
	const forgotUsernameUrl = `/${culture}/auth/forgot-username`;
	const passwordRecoveryUrl = `/${culture}/auth/password-recovery`;
	const confirmUserDataUrl = `/${culture}/auth/confirm-user-data`;
	const userMatchingUrl = `/${culture}/auth/match-user`;
	const acceptGdprUrl = `/${culture}/auth/accept-gdpr`;

	const partnerRegistrationUrl = `/${culture}/auth/register/partner`;

	const doesRouteRequireUserAction =
		((pathname.includes(registrationUrl) || pathname.includes(loginUrl)) &&
			!search.includes("returnUrl")) ||
		pathname.includes(bettingActivityUrl) ||
		pathname.includes(accountActivationUrl) ||
		pathname.includes(accountActivationCongratulationsUrl) ||
		pathname.includes(forcePasswordChangeUrl) ||
		pathname.includes(acceptNewTermsUrl) ||
		pathname.includes(forgotPasswordUrl) ||
		pathname.includes(forgotUsernameUrl) ||
		pathname.includes(passwordRecoveryUrl) ||
		pathname.includes(confirmUserDataUrl) ||
		pathname.includes(userMatchingUrl) ||
		pathname.includes(acceptGdprUrl) ||
		pathname.includes(partnerRegistrationUrl);

	const next = userStore.loginStore.nextRequiredUserAction;
	const params = new URLSearchParams(search);
	const returnUrl = params.get("returnUrl");

	if (
		next === RequiredUserAction.ConfirmUserData &&
		!pathname.includes(confirmUserDataUrl)
	) {
		return <Redirect to={confirmUserDataUrl} />;
	}
	if (
		next === RequiredUserAction.AccountActivation &&
		!pathname.includes(accountActivationUrl)
	) {
		return <Redirect to={accountActivationUrl} />;
	}
	if (
		next === RequiredUserAction.AccActivationCongratulation &&
		!pathname.includes(accountActivationCongratulationsUrl)
	) {
		return <Redirect to={accountActivationCongratulationsUrl} />;
	}
	if (
		next === RequiredUserAction.PasswordChange &&
		!pathname.includes(forcePasswordChangeUrl)
	) {
		return <Redirect to={forcePasswordChangeUrl} />;
	}

	if (
		next === RequiredUserAction.UserMatching &&
		!pathname.includes(userMatchingUrl)
	) {
		return <Redirect to={userMatchingUrl} />;
	}

	if (
		next === RequiredUserAction.BettingActivity &&
		!pathname.includes(bettingActivityUrl)
	) {
		return <Redirect to={bettingActivityUrl} />;
	}

	if (
		next === RequiredUserAction.AcceptNewTerms &&
		!pathname.includes(acceptNewTermsUrl)
	) {
		return <Redirect to={acceptNewTermsUrl} />;
	}

	if (
		next === RequiredUserAction.AcceptGdpr &&
		!pathname.includes(acceptGdprUrl) &&
		!isCapacitorPlatform()
	) {
		//currently disabled for capacitor platform
		return <Redirect to={acceptGdprUrl} />;
	}

	if (
		userStore.isLoggedIn &&
		returnUrl !== null &&
		next === RequiredUserAction.None
	) {
		return <Redirect to={`/${getCurrentCulture()}${returnUrl}`} />;
	}

	if (
		doesRouteRequireUserAction &&
		next === RequiredUserAction.None &&
		userStore.isLoggedIn &&
		!pathname.includes(homeUrl)
	) {
		return <Redirect to={homeUrl} />;
	}

	if (privateRoute) {
		return (
			<PrivateRoute>
				<Route path={path} {...rest}>
					{children}
				</Route>
			</PrivateRoute>
		);
	}

	return (
		<Route path={path} {...rest}>
			{children}
		</Route>
	);
});
