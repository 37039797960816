import { httpClient, getApplicationHeaders } from "@utils";
import { UserTypes } from "@utils";

class KeepAliveService {
	/**
	 *
	 * @returns {promise}
	 */
	static ping(activeAccount?: UserTypes): Promise<any> {
		function getRequestHeaders() {
			const appHeaders = getApplicationHeaders(activeAccount);
			appHeaders["Content-Type" as keyof HeadersInit] =
				"application/json";
			appHeaders["Accept" as keyof HeadersInit] = "application/json";
			return appHeaders;
		}

		return httpClient.get(
			`${WEBPACK_BASE_ADDRESS}platform/keep-alive`,
			getRequestHeaders()
		);
	}
}

export default KeepAliveService;
