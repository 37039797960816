import { RouteElement } from "@v2/components/shared/navigation";
import { DateTime } from "luxon";

export class RoleModel {
	name: string;
}

export class BaseAppUser {
	json: string;
	userName: string;
}

export class AppUser extends BaseAppUser {
	creationDate: string;
	cultureInfoName: string;
	displayName: string;
	email: string;
	firstName: string;
	isApproved: boolean;
	isLockedOut: boolean;
	lastActivityDate: DateTime;
	lastLockoutDate: DateTime;
	lastLoginDate: DateTime;
	lastName: string;
	name: string;
	roles: RoleModel[];
	timeZoneId: string;
}

export class AgencyUserFullUpdateDto extends AppUser {
	password: string;
	birthName: string;
	birthPlace: string;
	city: string;
	countryId: string;
	diagnosticLevel?: number;
	dob: DateTime;
	documentExpiration?: string | null;
	houseBox: string;
	houseNumber: string;
	isAgencyWideCashierEnabled?: boolean;
	isDiagnosticEnabled?: boolean;
	isLogTransferEnabled?: boolean;
	language: string;
	mobilePhone: string;
	nationality?: string;
	numericalId: number;
	occupationId?: string;
	passportId: string;
	profession: string;
	regionId?: string;
	secondAddressReason: string;
	secondCity: string;
	secondCountryId?: string;
	secondHouseNumber: string;
	secondStreet: string;
	secondZIPCode: string;
	shopId: string;
	stakeAmount: number;
	street: string;
	telephoneNumber: string;
	titleId: string;
	userAccountRelationMatchStatus: UserAccountRelationMatchStatus;
	zipCode: string;
	personalIdentificator?: string;
}

export enum UserAccountRelationMatchStatus {
	AlgorithmNotRun = 0,
	NoMatches = 1,
	UserNeedsToConfirmMatch = 2,
	MatchConfirmed = 3,
	MatchDeclined = 4,
}
