import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { redirectUserToHomePageAfterRegistration } from "@utils";
import {
	OnBoardingSlider,
	OnBoardingFooter,
} from "@components-desktop/membership/shared";

export default function RegistrationSuccess() {
	const { t } = useTranslation();

	const redirectToHomePage = () => {
		redirectUserToHomePageAfterRegistration();
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<main role="main" className="at-regSuccess l--main l--main--grow">
			<section className="l--content l--content--full">
				<div className="l--content__main u-padd--reset">
					<div className="container container--med container--grow">
						<div className="card--onboarding card--onboarding--grow">
							<OnBoardingSlider />
							<div className="card--onboarding__form card--onboarding__form--center">
								<div className="card--onboarding__header card--onboarding__header--vertical">
									<i className="u-icon u-icon--xxxlrg u-icon--check-circle u-icon--animated--bounce-in u-color--ui--success" />
									<div className="u-mar--top--med u-type--xlrg u-type--wgt--medium u-type--center">
										{t(
											"MEMBERSHIP.REGISTRATION.REGISTRATION_SUCCESS.CONGRATULATIONS"
										)}
									</div>
								</div>

								<div className="card--onboarding__content card--onboarding__content--shrink">
									<div className="u-mar--bottom--big">
										<div className="u-mar--bottom--xsml">
											{t(
												"MEMBERSHIP.REGISTRATION.REGISTRATION_SUCCESS.SUCCESSFULY_CREATED"
											)}
										</div>
										<div>
											{t(
												"MEMBERSHIP.REGISTRATION.REGISTRATION_SUCCESS.REGISTRATION_NOTE"
											)}
										</div>
									</div>

									<div className="u-mar--bottom--big">
										<div className="u-mar--bottom--xtny u-type--base u-type--wgt--medium">
											{t(
												"MEMBERSHIP.REGISTRATION.REGISTRATION_SUCCESS.PLAY_RESPONSIBLY"
											)}
										</div>
										<div>
											{t(
												"MEMBERSHIP.REGISTRATION.REGISTRATION_SUCCESS.RESPONSIBLE_GAMING_MESSAGE"
											)}
										</div>
									</div>

									<div>
										{t(
											"MEMBERSHIP.REGISTRATION.REGISTRATION_SUCCESS.WISHES_MESSAGE"
										)}
									</div>
								</div>

								<button
									className="at-regSuccessBtnHome btn btn--base btn--block btn--primary"
									onClick={redirectToHomePage}
								>
									{t("COMMON.BACK_TO_HOME_PAGE")}
								</button>
							</div>
						</div>

						<OnBoardingFooter />
					</div>
				</div>
			</section>
		</main>
	);
}
