import BrowserCloseApiSerivce from "@api-services/browser-close/BrowserCloseApiService";
import { useRootAppStore } from "@hooks";
import { DeviceDetector } from "@utils";
import { useEffect } from "react";

export default function useBrowserClose() {
	// web event listener "beforeunload"  "pagehide" ""
	// [x]- safari beforeunload
	// [x]- chrome mac i windows beforeunload
	// [x]- firefox beforeunload
	// [] - reload

	// kad clozas browser safari [X]
	// kad ugasi tab [X]
	// ne radi na reload [X]
	// kad ugasi mac [X]
	// [] - reload

	const rootStore = useRootAppStore();

	function sendBrowserClose() {
		console.log(DeviceDetector.isDesktopChrome);
		if (DeviceDetector.isDesktopChrome) {
			setTimeout(() => {
				try {
					BrowserCloseApiSerivce.notifyCloseWebsite();
				} catch (e) {
					console.error(e);
				}
			}, 0);
		} else {
			try {
				BrowserCloseApiSerivce.notifyCloseWebsite();
			} catch (e) {
				console.error(e);
			}
			if (DeviceDetector.isDesktopFirefox) {
				//way to make firefox send request with keepalive stall closing for a sec
				const date = Date.now();
				while (Date.now() < date + 100) {}
			}
		}
	}

	useEffect(() => {
		if (localStorage.getItem("user")) {
			window.addEventListener("pagehide", sendBrowserClose);
		}

		return () => {
			if (localStorage.getItem("user")) {
				window.removeEventListener("pagehide", sendBrowserClose);
			}
		};
	}, [rootStore.userAuthStore.user]);
}
