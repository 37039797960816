import { ChatSDKService } from "@services/chat";
import { observable, action } from "mobx";
import { orderBy } from "lodash";
import { getAgencyId, getCurrentLanguage } from "@utils";
import { localizationService } from "@state/services";
import { ChatViewStore } from "./ChatViewStore";
import {
	ChannelInfoDataFromList,
	ChannelInfoDataFromOffer,
	ChannelInfoDataFromSession,
	ChatEntryPoint,
	ChatWindowState,
} from "@data-types/chat";
import ChatApiService from "@api-services/chat/ChatApiService";
import { ChannelIconsDto, ChannelResourceTypeDto } from "@api-types/chat";
import { LoaderStore } from "../common";
import { ChatApiError } from "./ChatApiErrorModel";

export class ChatChannelHandler {
	chatViewStore: ChatViewStore;
	messageLoader: LoaderStore;
	flaggingLoader: LoaderStore;
	channelInfoLoader: LoaderStore;
	messageListLoader: LoaderStore;

	disposerList: Amity.Unsubscriber[] = [];
	subscribedChannelId: string | null = null;
	subscribedActiveSubChannelId: string | null = null;

	@observable activeChannelInfo: Amity.Channel | null;
	@observable messagesPaginationObject: Amity.LiveCollection<
		Amity.Message<any>
	> | null = null;
	@observable messageList: AmityMessageMutated[] = [];
	@observable iconProps: ChannelIconsDto | undefined;

	constructor(chatViewStore: ChatViewStore) {
		this.chatViewStore = chatViewStore;
		this.messageLoader = new LoaderStore();
		this.flaggingLoader = new LoaderStore();
		this.channelInfoLoader = new LoaderStore();
		this.messageListLoader = new LoaderStore();
	}

	async joinUserIntoChannelPerType(
		entryPoint: ChatEntryPoint,
		channelData:
			| ChannelInfoDataFromOffer
			| ChannelInfoDataFromList
			| ChannelInfoDataFromSession
			| null
	) {
		if (
			entryPoint === ChatEntryPoint.BET_SLIP_SHARE ||
			entryPoint === ChatEntryPoint.USER_MENU ||
			!channelData
		) {
			const channelSessionData =
				this.chatViewStore.channelSessionHandler.getChannelInfoFromSession();

			if (channelSessionData) {
				await this.joinChannelFromSession(channelSessionData.channelId);
				return;
			}

			const { displayName, channelId } =
				this.chatViewStore.chatListHandler.channelList[0];

			await this.joinChannelFromList(channelId, displayName);
			return;
		}

		if (
			entryPoint === ChatEntryPoint.LIST &&
			"channelId" in channelData &&
			"displayName" in channelData
		) {
			//used for joining into channels from header list
			await this.joinChannelFromList(
				channelData.channelId,
				channelData.displayName,
				channelData.sportAbrv || "",
				channelData.categoryAbrv || "",
				channelData.tournamentAbrv || ""
			);
			return;
		}

		// if (
		// 	entryPoint === ChatEntryPoint.OFFER &&
		// 	"channelType" in channelData &&
		// 	"resourceId" in channelData
		// ) {
		// 	if (
		// 		!this.chatViewStore.chatWidgetVisible ||
		// 		!this.chatViewStore.chatBoxVisible
		// 	) {
		// 		this.chatViewStore.toggleChatWidget(true);
		// 		this.chatViewStore.toggleChatBox(true);
		// 	}
		// 	this.chatViewStore.toggleChatList(false);
		// 	await this.joinUserIntoChannel(
		// 		channelData.channelType,
		// 		channelData.resourceId
		// 	);
		// 	return;
		// }

		if (
			entryPoint === ChatEntryPoint.SESSION &&
			"channelId" in channelData &&
			"chatState" in channelData
		) {
			await this.joinChannelFromSession(channelData.channelId);
			return;
		}
	}

	async joinChannelFromSession(channelId: string) {
		const channelInfo = await this.queryChannelInfo(channelId);

		this.updateIconProps(channelInfo);

		const joinStatus = await ChatSDKService.joinChannel(
			channelInfo.channelId
		);

		if (!joinStatus) {
			console.error("Chat ERR: Failed joining user into channel");
			throw new ChatApiError(
				true,
				"CHAT.ERR_HANDLING.FAILED.JOIN_INTO_CHANNEL"
			);
		}

		this.handleChannelListeners(
			channelId,
			channelInfo.displayName,
			channelInfo.sportAbrv,
			channelInfo.categoryAbrv,
			channelInfo.tournamentAbrv
		);
	}

	async joinChannelFromList(
		channelId: string,
		displayName: string,
		sportAbrv?: string,
		categoryAbrv?: string,
		tournamentAbrv?: string
	) {
		this.updateIconProps({
			sportAbrv,
			categoryAbrv,
			tournamentAbrv,
		});

		const joinStatus = await ChatSDKService.joinChannel(channelId);

		if (!joinStatus) {
			console.error("Chat ERR: Failed joining user into channel");
			throw new ChatApiError(
				true,
				"CHAT.ERR_HANDLING.FAILED.JOIN_INTO_CHANNEL"
			);
		}

		// this.chatViewStore.toggleChatList(false);

		this.handleChannelListeners(
			channelId,
			displayName,
			sportAbrv,
			categoryAbrv,
			tournamentAbrv
		);
	}

	async joinUserIntoChannel(
		channelType: ChannelResourceTypeDto,
		resourceId: string
	) {
		const channelData = await this.fetchChannelData(
			channelType,
			resourceId
		);

		const queryChannel = channelData.channels?.[0];

		this.updateIconProps(queryChannel);

		const joinStatus = await ChatSDKService.joinChannel(
			queryChannel.channelId
		);

		if (!joinStatus) {
			console.error("Chat ERR: Failed joining user into channel");
			throw new ChatApiError(
				true,
				"CHAT.ERR_HANDLING.FAILED.JOIN_INTO_CHANNEL"
			);
		}

		this.handleChannelListeners(
			queryChannel.channelId,
			queryChannel.displayName,
			queryChannel.sportAbrv,
			queryChannel.categoryAbrv,
			queryChannel.tournamentAbrv
		);
	}

	async queryChannelInfo(channelId: string) {
		try {
			return await ChatApiService.queryChannelInfo(
				channelId,
				getCurrentLanguage()
			);
		} catch (error) {
			throw new ChatApiError(
				true,
				"CHAT.ERR_HANDLING.FAILED_QUERYING_CHANNEL_INFO"
			);
		}
	}

	async fetchChannelData(
		channelType: ChannelResourceTypeDto,
		resourceId: string
	) {
		try {
			return await ChatApiService.fetchChannelData({
				channelResourceType: channelType,
				resourceId: resourceId,
				agencyId: getAgencyId(),
				culture: getCurrentLanguage(),
			});
		} catch (error) {
			throw new ChatApiError(
				true,
				"CHAT.ERR_HANDLING.FAILED_FETCHING_CHANNEL_DATA"
			);
		}
	}

	async sendMessage(msg: string, isLink?: boolean) {
		if (!this.subscribedActiveSubChannelId) {
			return;
		}

		try {
			this.messageLoader.suspend();

			const sharedBetSlip = this.chatViewStore.sharedBetSlipData;

			if (sharedBetSlip) {
				await ChatSDKService.createCustomMessage(
					this.subscribedActiveSubChannelId,
					sharedBetSlip,
					msg,
					isLink
				);
			} else {
				await ChatSDKService.createMessage(
					this.subscribedActiveSubChannelId,
					msg,
					isLink
				);
			}
			this.chatViewStore.setSharedBetSlipData(null);
		} catch (error) {
			console.error("Chat ERR: Failed sending message into channel");
			this.chatViewStore.handleError(error);
		} finally {
			this.messageLoader.resume();
		}
	}

	handleChannelListeners(
		channelId: string,
		channelDisplayName: string,
		sportAbrv: string | undefined,
		categoryAbrv: string | undefined,
		tournamentAbrv: string | undefined
	) {
		this.createChannelInfoListener(
			channelId,
			channelDisplayName,
			sportAbrv,
			categoryAbrv,
			tournamentAbrv
		);
	}

	createChannelInfoListener(
		channelId: string,
		channelDisplayName: string,
		sportAbrv: string | undefined,
		categoryAbrv: string | undefined,
		tournamentAbrv: string | undefined
	) {
		this.channelInfoLoader.suspend();
		try {
			const channelInfoDisposer = ChatSDKService.getChannelInfo(
				channelId,
				(e) =>
					this.channelInfoCallback(
						e,
						channelDisplayName,
						sportAbrv,
						categoryAbrv,
						tournamentAbrv
					)
			);

			this.updateDisposerList(channelInfoDisposer);
		} catch (error) {
			console.error("Chat ERR: Failed querying channel info");
			this.channelInfoLoader.resume();
			throw error;
		}
	}

	channelInfoCallback = (
		channelInfo: Amity.LiveObject<Amity.Channel>,
		channelDisplayName: string,
		sportAbrv: string | undefined,
		categoryAbrv: string | undefined,
		tournamentAbrv: string | undefined
	) => {
		if (channelInfo.error) {
			//update error object
			console.error(
				"Chat ERR: channel info callback error",
				channelInfo.error
			);
			this.channelInfoLoader.resume();
			this.chatViewStore.handleError(channelInfo.error);
			return;
		}

		if (channelInfo.loading) {
			this.channelInfoLoader.suspend();
			return;
		}

		if (channelInfo.data.isDeleted) {
			return;
		}

		const channelData = {
			...channelInfo.data,
			displayName: channelDisplayName,
			sportAbrv: sportAbrv,
			categoryAbrv: categoryAbrv,
			tournamentAbrv: tournamentAbrv,
		};

		this.setActiveChannel(channelData);

		//update session once successfully joined channel
		this.chatViewStore.channelSessionHandler.setChannelInfoIntoSession(
			channelData.channelId,
			this.chatViewStore.chatBoxVisible
				? ChatWindowState.OPENED
				: ChatWindowState.MINIMIZED
		);

		this.createChannelMessageListener(
			channelData.channelId,
			channelInfo.data.defaultSubChannelId
		);

		this.subscribedActiveSubChannelId =
			channelInfo.data.defaultSubChannelId;

		this.channelInfoLoader.resume();
	};

	createChannelMessageListener(channelId: string, subChannelId: string) {
		if (channelId === this.subscribedChannelId) {
			//already created message listeners for this channel
			return;
		}

		try {
			this.messageListLoader.suspend();

			//resume in case main loader still loading
			this.chatViewStore.chatLoader.resume();

			const channelMessageListenerDisposer =
				ChatSDKService.subscribeToNewMessages(subChannelId, (e) =>
					this.channelMessagesCallback(e, channelId)
				);

			this.updateDisposerList(channelMessageListenerDisposer);
		} catch (error) {
			console.error("Chat ERR: channel info callback error");
			this.messageListLoader.resume();
			throw error;
		}
	}

	channelMessagesCallback = (
		messageInfo: Amity.LiveCollection<Amity.Message<any>>,
		channelId: string
	) => {
		if (messageInfo.error) {
			console.error("Chat ERR: channel messages callback error");
			this.messageListLoader.resume();
			this.chatViewStore.handleError(messageInfo.error);
		}

		if (messageInfo.loading) {
			return;
		}

		this.subscribedChannelId = channelId;

		this.setMessagePaginationObject(messageInfo);

		this.sortChannelMessages(messageInfo.data);

		this.messageListLoader.resume();
	};

	sortChannelMessages(messages: Amity.Message<any>[]) {
		const updatedMessages = this.updateMessageCreatorId(messages);

		const orderedMessages = orderBy(updatedMessages, "createdAt", "asc");

		this.updateMessageList(orderedMessages);
	}

	updateMessageCreatorId(
		messages: Amity.Message<any>[]
	): AmityMessageMutated[] {
		//SGUID-CHAT_NAME to CHAT_NAME
		//infinite scrolling plugin does array.concat, joining already modified messages and new messages
		//because of this needed to add a check if message is already mutated
		return messages.map((msg: AmityMessageMutated) => {
			if (msg?.creatorIdMutated) {
				return msg;
			}
			if (msg.creatorId.includes("_")) {
				msg.creatorId = msg.creatorId.split("_")[1];
			} else {
				console.error(
					"Chat messages: CreatorId of message does not contain _. This is not a valid user id.",
					msg
				);
				msg.creatorId = "ERR_NAME";
			}
			msg.creatorIdMutated = true;
			return msg;
		});
	}

	@action.bound
	updateMessageList(messages: AmityMessageMutated[]) {
		this.messageList = messages;
	}

	@action.bound
	updateIconProps(iconProps: ChannelIconsDto) {
		this.iconProps = iconProps;
	}

	@action.bound
	setMessagePaginationObject(
		object: Amity.LiveCollection<Amity.Message<any>>
	) {
		this.messagesPaginationObject = object;
	}

	@action.bound
	setActiveChannel(channelInfo: Amity.Channel) {
		this.activeChannelInfo = channelInfo;
	}

	async checkIfMessageShouldBeFlagged(
		messageId: string,
		creatorId: string
	): Promise<void> {
		if (this.flaggingLoader.isLoadingProcess) {
			//if already waiting for a response, do not call request again to prevent user spam
			return;
		}

		this.flaggingLoader.suspend();

		try {
			const flaggingStatus = await ChatSDKService.isMessageFlaggedByMe(
				messageId
			);
			if (!flaggingStatus) {
				await ChatSDKService.flagMessage(messageId);
				return this.chatViewStore.rootStore.notificationStore.success(
					localizationService.t("CHAT.FLAG_MESSAGE", {
						"0": creatorId,
					})
				);
			}
			return this.chatViewStore.rootStore.notificationStore.error(
				localizationService.t("CHAT.ALREADY_FLAGGED_MESSAGE", {
					"0": creatorId,
				})
			);
		} catch (error) {
			console.error(error);
			this.chatViewStore.handleError(error);
			return;
		} finally {
			this.flaggingLoader.resume();
		}
	}

	async leaveCurrentChannel(): Promise<boolean> {
		if (!this.activeChannelInfo?.channelId) {
			console.error("Cannot leave channel, not in any channel");
			return false;
		}
		try {
			this.chatViewStore.chatListHandler.loaderStore.suspend();
			return await ChatSDKService.leaveChannel(
				this.activeChannelInfo.channelId
			);
		} catch (error) {
			console.error(error);
			this.chatViewStore.chatListHandler.loaderStore.resume();
			this.chatViewStore.handleError(error);
			return false;
		}
	}

	@action.bound
	updateDisposerList(disposer: Amity.Unsubscriber) {
		this.disposerList.push(disposer);
	}

	@action.bound
	disposeChannelListeners() {
		this.disposerList.map((fn) => fn());
		this.disposerList = [];
	}

	@action.bound
	disposeChannelHandler() {
		this.disposeChannelListeners();
		this.subscribedChannelId = null;
		this.activeChannelInfo = null;
		this.messagesPaginationObject = null;
		this.messageList = [];
		this.subscribedActiveSubChannelId = null;
		this.chatViewStore.setLeaveChannelDropdownOpened(false);
	}
}

export type AmityMessageMutated = Amity.Message<any> & {
	creatorIdMutated: boolean;
};
