//#region BR widget configs
export const BrWidgetOtherConfig = {
	isEnabled: true,
	supportSports:
		"ice-hockey,handball,basketball,tennis,volleyball,beach-volley,table-tennis,soccer",
	maxTrackers: 0,
};

export const BrWidgetDefaultConfig = {
	disableWidgetHeader: false,
	disablePitch: false,
	disableComponents: false,
	expanded: false,
	disableAds: true,
	layout: "double",
	momentum: "compact",
	scoreboard: "compact",
	collapseTo: "disable",
	tabsPosition: "bottom",
	detailedScoreboard: "all",
	activeSwitcher: "momentum",
	maxSportTrackers: 1,
	maxTrackers: 5,
	trackerPosition: 1,
};

export const BrWidgetScriptUrl =
	"https://widgets.sir.sportradar.com/ac3bd23a36bd8166d7d6bee32ec3391f/widgetloader";

//#endregion BR widget configs

//#region STATSCORE widget configs

export const LsOtherConfig = {
	supportedSports:
		"ice-hockey,handball,basketball,tennis,volleyball,beach-volley,table-tennis,soccer,american-football,badminton,bowls,cricket,formula-1,futsal,ski-jumping,snooker",
};

export const LsWidgetDefaultConfig: LSOptions = {
	loader: { enabled: false, size: 60, color1: "#0097ec", color2: "#455c6b" },
	// to add mobileAppToken
	// to add satisfiesCoverage
};

export const LsWidgetConfigurationIds: { [key: string]: string } = {
	tennis: "65e581dcd2704c37f42ab96a",
	"american-football": "65e5830cd2704c37f42ab96f",
	baseball: "65e582fad2704c37f42ab96e",
	basketball: "65e5823cd2704c37f42ab96b",
	"formula-1": "65e5833ad2704c37f42ab971",
	handball: "65e58325d2704c37f42ab970",
	"ice-hockey": "65e5824fd2704c37f42ab96c",
	soccer: "65e581d0d2704c37f42ab969",
	volleyball: "65e582d9d2704c37f42ab96d",
	"table-tennis": "65e5834cd2704c37f42ab972",
};

export const LsWidgetScriptUrl =
	"https://wgt-s3-cdn.statscore.com/bundle/Embeder.js";

//#endregion STATSCORE widget configs
