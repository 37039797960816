import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getCurrentCulture } from "@utils";

export default function ActivationSuccess(props: {
	activationState?: boolean | null;
}) {
	const { t } = useTranslation();

	if (props.activationState == null || props.activationState == false) {
		return null;
	}

	return (
		<div className="card--message">
			<div className="card--message__thumb" />
			<div className="card--message__body">
				<div className="card--message__content">
					<i className="card--message__icon u-icon u-icon--xlrg u-icon--check-circle u-icon--animated--bounce-in u-color--ui--success" />
					<div className="card--message__title">
						{t<string>("MEMBERSHIP.ACCOUNT_ACTIVATION.INFO")}
					</div>
					<div>
						{t<string>(
							"MEMBERSHIP.ACCOUNT_ACTIVATION.EMAIL_VERIFICATION_SUCCESS"
						)}
						.
					</div>
					<div className="card--message__footer">
						<Link
							className="card--message__cta btn btn--lrg btn--block btn--primary"
							to={`/${getCurrentCulture()}/home/full/highlights`}
						>
							{t<string>("MEMBERSHIP.ACCOUNT_ACTIVATION.HOME")}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
