import { useContext } from "react";
import {
	PasswordConfirmationGetterContext,
	PasswordConfirmationSetterContext,
} from "@shared/context";

export default function usePasswordConfirmation() {
	const passwordConfirmationGetterCtx = useContext(
		PasswordConfirmationGetterContext
	);
	const passwordConfirmationSetterCtx = useContext(
		PasswordConfirmationSetterContext
	);

	return {
		setShowPasswordConfirmation: (condition: boolean) => {
			passwordConfirmationSetterCtx.setShowPasswordConfirmation(
				condition
			);
		},
		showPasswordConfirmation:
			passwordConfirmationGetterCtx.showPasswordConfirmation,
	};
}
