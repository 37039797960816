import React from "react";
import {
	AccountLockHeader,
	AccountLockTabButtons,
	AccountLockWithPasswordConfirmation,
	AccountLockWithOasisViewDisplay,
} from ".";

export default function AccountLockForm() {
	const isOasisLockEnabled =
		App.state.rootStore.userAuthStore.user?.isOasisEnabled;

	if (!isOasisLockEnabled) {
		return (
			<div className="l--content__main">
				<div className="container">
					<div className="card--primary">
						<AccountLockHeader />
						<AccountLockTabButtons />
						<AccountLockWithPasswordConfirmation />
					</div>
				</div>
			</div>
		);
	}

	return <AccountLockWithOasisViewDisplay />;
}