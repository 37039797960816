import React from "react";

type Props = {
	className?: string;
	svgName?: string;
};

export default function PaymentOptionImage(props: Props) {
	const [errorSrcCount, setErrorSrcCount] = React.useState(-1);
	React.useEffect(() => {
		setErrorSrcCount(-1);
	}, [props]);

	const sources = useGetSources(props);

	return (
		<picture>
			{sources
				// Error count counts how many urls returned error from start of the list
				.filter((src, idx) => idx > errorSrcCount)
				.map((src) => (
					<source key={src} srcSet={src} />
				))}
			<img
				// @ts-expect-error
				loading="lazy"
				className={props.className}
				onError={() => {
					setErrorSrcCount(1 + errorSrcCount);
				}}
			/>
		</picture>
	);
}

function useGetSources(params: Props): string[] {
	return React.useMemo(() => getBaseSources(params), [params]);
}

function getBaseSources(params: Props): string[] {
	return [`${WEBPACK_CDN_ROOT_URL}/web/payment-options/${params.svgName}`];
}
