import { Capacitor } from "@capacitor/core";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { getCurrentCulture } from "@utils/common";

export function isCapacitorPlatform(): boolean {
	return Capacitor.isNativePlatform();
}
/**
 *
 * @param route - pathname (everything after culture/) cannot contain leading /
 * @param doesRouteContainCulture - in case of using window.location.pathname with culture
 * @returns whole redirect url
 */
export function getRedirectOriginUrl(
	route: string,
	doesRouteContainCulture?: boolean
): string {
	const excludeFirstSlash = route[0] === "/" ? route.slice(1) : route;
	const pathname = doesRouteContainCulture
		? excludeFirstSlash
		: `${getCurrentCulture()}/${excludeFirstSlash}`;
	if (isCapacitorPlatform()) {
		return `${WEBPACK_WEBSITE_URL}/${pathname}`;
	}
	return `${window.location.origin}/${pathname}`;
}

/**
 * @param orientation "landscape" | "portrait"
 * @desc locks orientation only if isCapacitorPlatform
 */
export function setCapacitorDeviceOrientation(
	orientation: "landscape" | "portrait"
): void {
	if (isCapacitorPlatform()) {
		ScreenOrientation.lock({ orientation: orientation });
	}
}

export function unlockCapacitorDeviceOrientation(): void {
	if (isCapacitorPlatform()) {
		ScreenOrientation.unlock();
	}
}

export async function getCapacitorVersion(): Promise<null | string> {
	if (!isCapacitorPlatform()) {
		return null;
	}

	const getVersion = await CapacitorUpdater.current();
	if (getVersion.bundle.version === "builtin") {
		//if builtin, get native version set in capacitor.config.ts
		return getVersion.native;
	}
	return getVersion.bundle.version;
}
