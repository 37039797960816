import { AutomaticWithdrawalApiService } from "@api-services/account-settings/AutomaticWithdrawalApiService";
import { AutomaticWithdrawalPost } from "@api-types/account-settings/AutomaticWithrawal";

export class AutomaticWithdrawalService {
	static async getUserAutomaticWithdrawalData() {
		try {
			return await AutomaticWithdrawalApiService.getAutomaticWithdrawal();
		} catch (error) {
			console.error(error);
			throw { message: error.message };
		}
	}

	static async setUserAutomaticWithdrawalData(data: AutomaticWithdrawalPost) {
		try {
			const response =
				await AutomaticWithdrawalApiService.setAutomaticWithdrawal(
					data
				);
			return response;
		} catch (error) {
			console.error(error);
			if (error.message) {
				throw { message: error.message };
			}
			if (error.data) {
				throw { message: "ERROR_OCCURED" };
			}
		}
	}
}
