import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default function LoginStatusMessage(props: { loginError: any | null }) {
	const { search } = useLocation();
	const { t } = useTranslation();

	if (props.loginError) {
		return (
			<div className="card--onboarding__title card--onboarding__title--full">
				{t<string>("MEMBERSHIP.LOGIN.TITLE")}
			</div>
		);
	}

	const messageDisplayType = search.substring(
		search.indexOf("?") + 1,
		search.indexOf("=")
	);

	switch (messageDisplayType) {
		case "isUserLockedOut":
			return (
				<>
					<div className="card--onboarding__title card--onboarding__title--full">
						{t<string>("MEMBERSHIP.LOGIN.START_BETTING_NOW")}
					</div>
					<InfoMessageComponent
						type="danger"
						message={t<string>(
							"specific:MEMBERSHIP.LOGIN.ERR_HANDLING.USER_LOCKED"
						)}
						addClassName="u-mar--bottom--sml"
						isMarkdown={true}
					/>
				</>
			);
		case "isOasisLockedOut":
			return (
				<>
					<div className="card--onboarding__title card--onboarding__title--full">
						{t<string>("MEMBERSHIP.LOGIN.START_BETTING_NOW")}
					</div>
					<InfoMessageComponent
						type="danger"
						message={t<string>(
							"MEMBERSHIP.LOGIN.OASIS_GAMING_EXCLUSION"
						)}
						addClassName="u-mar--bottom--sml"
					/>
				</>
			);
		case "isSessionExpired":
			return (
				<>
					<div className="card--onboarding__title card--onboarding__title--full">
						{t<string>("MEMBERSHIP.LOGIN.START_BETTING_NOW")}
					</div>
					<InfoMessageComponent
						type="danger"
						message={t<string>("MEMBERSHIP.LOGIN.SESSION_EXPIRED")}
						addClassName="u-mar--bottom--sml"
					/>
				</>
			);

		case "isSessionTerminated":
			return (
				<>
					<div className="card--onboarding__title card--onboarding__title--full">
						{t<string>("MEMBERSHIP.LOGIN.START_BETTING_NOW")}
					</div>
					<InfoMessageComponent
						type="danger"
						message={t<string>(
							"MEMBERSHIP.LOGIN.SESSION_TERMINATED"
						)}
						addClassName="u-mar--bottom--sml"
					/>
				</>
			);
		case "isBlocked":
			return (
				<>
					<div className="card--onboarding__title card--onboarding__title--full">
						{t<string>("MEMBERSHIP.LOGIN.START_BETTING_NOW")}
					</div>
					<InfoMessageComponent
						type="danger"
						message={t<string>(
							"USER.ACCOUNT_SETTINGS.ERR_HANDLING.USER_BLOCKED"
						)}
						addClassName="u-mar--bottom--sml"
					/>
				</>
			);
		case "isActivatingAccount":
			return (
				<>
					<div className="card--onboarding__title card--onboarding__title--full">
						{t<string>("MEMBERSHIP.LOGIN.START_BETTING_NOW")}
					</div>
					<InfoMessageComponent
						type="warning"
						message={t<string>(
							"MEMBERSHIP.LOGIN.ACTIVATING_ACCOUNT_MESSAGE"
						)}
						addClassName="u-mar--bottom--sml"
					/>
				</>
			);

		case "isAutomaticLogout":
			return (
				<>
					<div className="card--onboarding__title card--onboarding__title--full">
						{t<string>("MEMBERSHIP.LOGIN.START_BETTING_NOW")}
					</div>
					<InfoMessageComponent
						type="warning"
						message={t<string>(
							"MEMBERSHIP.LOGIN.AUTOMATIC_LOGOUT_MESSAGE"
						)}
						addClassName="u-mar--bottom--sml"
					/>
				</>
			);

		default:
			return (
				<div className="card--onboarding__title card--onboarding__title--full">
					{t<string>("MEMBERSHIP.LOGIN.START_BETTING_NOW")}
				</div>
			);
	}
}
