import { LiveStatus } from "@gp/models";
import { EventFilterParams } from "@gp/hub";
import PeriodConverter, { Period } from "@state/common/PeriodConverter";

// const PeriodConverter = App.state.PeriodConverter;
/**
 * Takes period value as string and returns filter that is used in creating request for prematch offer menu.
 * @param {string} period
 * @returns {Filter}
 */
export function generatePrematchOfferMenuFilter(period: Period) {
	const filter: EventFilterParams = {
		liveStatus: LiveStatus.PREMATCH,
	};

	if (period !== "full" && PeriodConverter.IsValidPeriod(period)) {
		filter.startTime = {
			lt: PeriodConverter.CalculateOfferInterval(period),
		};
	}

	return [filter];
}
