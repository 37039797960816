import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useRegulationAgency } from "@v2/hooks/useRegulationAgency";
import { getCurrentCulture } from "@utils";
import { ReactLazyImportWithGuard as lazy } from "@lib/lazy-import-with-guard";
const loadFailPath = `/${getCurrentCulture()}/app-update`;

// prettier-ignore
const Markdown = lazy(loadFailPath, () => import("@v2/components/shared/Markdown"));

export default observer(function TermsAndConditionsSection() {
	const { t } = useTranslation();
	const regulationDefinitionsStore = useRegulationAgency();

	return (
		<>
			<div className="card--primary">
				<div className="card--primary__header card--primary__header--borderless u-padd--bottom--reset">
					<a
						href={`/assets/docs/terms/${t<string>(
							"specific:INFO_PAGES.TERMS_AND_CONDITIONS.TERMS_PDF"
						)}`}
						className="btn btn--sml btn--secondary--inverted btn--icon"
						target="_blank"
					>
						<i className="u-icon u-icon--med u-icon--file--pdf" />
						{t<string>("MEMBERSHIP.ACCEPT_NEW_TERMS.TOC_BUTTON")}
					</a>
				</div>
				<div className="card--primary__body markdown">
					<Markdown
						content={
							regulationDefinitionsStore.termsAndConditionsWeb?.content
						}
					></Markdown>
				</div>
			</div>
		</>
	);
});
