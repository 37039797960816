import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

export default function useDayAndTimeFromEventStartTime(
	startTime: string,
	shortDayLabel?: boolean
) {
	const t = useTranslation().t;

	let dt = DateTime.fromISO(startTime);

	const isToday = dt.hasSame(DateTime.local(), "day");
	const isTomorrow = dt.hasSame(DateTime.local().plus({ days: 1 }), "day");

	let day;
	if (isToday) {
		day = t("OFFER.TIME.TODAY");
	} else if (isTomorrow) {
		day = t(`OFFER.TIME.TOMORROW${shortDayLabel ? "_SHORT" : ""}`);
	} else {
		day = dt.toFormat("ccc dd.LL.");
	}

	const time = dt.toFormat("HH:mm");

	return {
		day,
		time,
	};
}
