import React, { useState, useMemo, useEffect, useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Switch } from "react-router-dom";

import { useRootAppStore, useRootOfferStore } from "@hooks";
import { AdditionalOfferContainerRefContext } from "@context";
import { LiveOfferMenuStore } from "@offer/stores/components/offer-menu/LiveOfferMenuStore";
import { SetIsAdditionalOfferOpenCtxProviderForParentLayout } from "@context/SetIsAdditionalOfferOpenCtxProviderForParentLayout";

import LivePage from "@v2/components/desktop/offer/pages/live/LivePage";
import { LiveOfferMenu } from "@v2/components/desktop/offer/offer-menu";
import { AsideWidgets } from "@v2/components/desktop/side-widgets";
import { Footer } from "@v2/components/desktop/shared/footer";
import { HeaderWithSideMenu } from "@v2/components/desktop/shared/header";
import { NoMatchRoute, RouteElement } from "@v2/components/shared/navigation";

import LiveEventRouteLayout from "@v2/components/desktop/offer/layout/LiveEventRouteLayout";
import PrematchOfferMenuStore from "@offer/stores/components/offer-menu/PrematchOfferMenuStore";

import { FavoritesStoreContext } from "@v2/context/FavoritesStoreContext";
import { LiveStatus } from "@gp/models";
import { useFavoritesCounter } from "@v2/hooks/offer/useFavoritesCounter";

function LiveRouteLayout(props: {
	prematchOfferMenuStore: PrematchOfferMenuStore;
}) {
	const [additionalOfferContainerRef, setAdditionalOfferContainerRef] =
		useState<HTMLDivElement | null>(null);
	const offerStore = useRootOfferStore();
	const { isSideMenuExpanded } = offerStore;

	const [isAdditionalOfferVisible, setIsAdditionalOfferOpen] =
		React.useState(false);
	const rootOfferStore = useRootOfferStore();
	const rootStateStore = useRootAppStore();
	const favoriteStore = useContext(FavoritesStoreContext);

	const liveOfferMenuStore = useMemo(
		() => new LiveOfferMenuStore(rootOfferStore, favoriteStore),
		[]
	);

	useFavoritesCounter(LiveStatus.LIVE);

	useEffect(() => {
		rootOfferStore.betSlipStore.playedLiveEventsStore =
			liveOfferMenuStore.liveEventsFromMyBets;

		rootStateStore.quickPlayStore.quickBetStore.initializeMenuStore(
			liveOfferMenuStore
		);
		return () => {
			rootOfferStore.betSlipStore.playedLiveEventsStore = undefined;
			rootStateStore.quickPlayStore.quickBetStore.removeMenuStore();
		};
	});

	//#region css classes

	const mainclassNames = classNames("l--main l--main--live", {
		"sidebar-left-expanded": isSideMenuExpanded,
		"sidebar-left-collapsed": !isSideMenuExpanded,
	});

	const contentClassNames = classNames("l--content", {
		"additional-is-hidden": !isAdditionalOfferVisible,
		"additional-is-visible": isAdditionalOfferVisible,
		"sidebar-left-expanded": isSideMenuExpanded,
		"sidebar-left-collapsed": !isSideMenuExpanded,
	});

	const additionalClassNames = classNames("l--content__additional", {
		"is-visible": isAdditionalOfferVisible,
		"is-hidden": !isAdditionalOfferVisible,
		"sidebar-left-expanded": isSideMenuExpanded,
		"sidebar-left-collapsed": !isSideMenuExpanded,
	});

	const footerClassNames = classNames("l--footer", {
		"sidebar-left-expanded": isSideMenuExpanded,
	});

	const asideLeftClasses = classNames("l--aside--left", {
		"is-expanded": isSideMenuExpanded,
	});

	//#endregion css classes

	return (
		<SetIsAdditionalOfferOpenCtxProviderForParentLayout.Provider
			value={{
				setIsAdditionalOpen: setIsAdditionalOfferOpen,
				isAdditionalOpen: isAdditionalOfferVisible,
			}}
		>
			<FavoritesStoreContext.Provider value={favoriteStore}>
				<Switch>
					<RouteElement
						exact
						path={[
							"/:culture/live/events/:eventIds+",
							"/:culture/live/my-favorites/:eventIds+",
						]}
					>
						<LiveEventRouteLayout
							liveOfferMenuStore={liveOfferMenuStore}
							prematchOfferMenuStore={
								props.prematchOfferMenuStore
							}
						/>
					</RouteElement>
					<RouteElement
						path={[
							"/:culture/live/:period",
							"/:culture/live/:period/f/:ids*",
						]}
					>
						<HeaderWithSideMenu
							onNavTargetClick={(target) => {
								if (target == "live") {
									liveOfferMenuStore.menu.onCheck(false);
									liveOfferMenuStore.deselectSport();
								}
							}}
						/>
						<main role="main" className={mainclassNames}>
							<aside className={asideLeftClasses}>
								<LiveOfferMenu
									liveOfferMenuStore={liveOfferMenuStore}
								/>
							</aside>

							<section className={contentClassNames}>
								<AdditionalOfferContainerRefContext.Provider
									value={additionalOfferContainerRef}
								>
									<LivePage
										liveOfferMenuStore={liveOfferMenuStore}
									/>
									<div
										ref={(newRef: HTMLDivElement | null) =>
											setAdditionalOfferContainerRef(
												newRef
											)
										}
										id="additional-offer-container"
										className={additionalClassNames}
									/>
								</AdditionalOfferContainerRefContext.Provider>
							</section>

							<AsideWidgets />
						</main>

						<footer className={footerClassNames}>
							<Footer />
						</footer>
					</RouteElement>
					<NoMatchRoute />
				</Switch>
			</FavoritesStoreContext.Provider>
		</SetIsAdditionalOfferOpenCtxProviderForParentLayout.Provider>
	);
}

export default observer(LiveRouteLayout);
