import React, { FC, useContext, useEffect } from "react";
import { observer } from "mobx-react";

import GamingHistoryStoreContext from "@v2/context/GamingHistoryStoreContext";
import {
	FormProvider,
	useForm,
	useWatch,
	useFormContext,
} from "react-hook-form";
import { betPeriods } from "@administration/pages/my-bets/BetPeriods";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@v2/components/mobile/shared/inputs";
import { GamingHistoryRawFilterData } from "@data-types/gaming-history";
import { DateTime } from "luxon";

const CasinoFilterForm: FC = observer(function CasinoFilterForm() {
	const {
		disposeCurrentGamingHistoryState,
		gamingHistoryFilterStore: {
			setRawFilterData,
			categories,
			setDefaultFilterData,
			isLoading,
		},
	} = useContext(GamingHistoryStoreContext);

	const methods = useForm();
	const { t } = useTranslation();
	const { register, handleSubmit, reset } = methods;

	const onSubmit = (data: GamingHistoryRawFilterData) => {
		setRawFilterData({
			...data,
		});
	};

	useEffect(() => {
		if (location.search !== "") {
			const params = App.state.getUrlParamsAsObj();
			// @ts-expect-error
			reset(params);
		} else {
			disposeCurrentGamingHistoryState();
		}

		return setDefaultFilterData;
	}, []);

	if (categories == null || categories.length === 0) {
		return null;
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} className="form">
				<div className="card--primary__body">
					<div className="form__field">
						<label
							htmlFor="gamingHistoryCategorySelect"
							className="label"
						>
							{t<string>("GAMING_HISTORY.FILTER.SELECT_CATEGORY")}
						</label>
						<select
							id="gamingHistoryCategorySelect"
							className="input input--base input--select"
							{...register("casinoGameCategoryId")}
						>
							<option value={"all"}>
								{t<string>(
									"GAMING_HISTORY.FILTER.ALL_CATEGORIES"
								)}
							</option>
							{categories.map((category) => (
								<option
									key={category.name}
									value={category.casinoGameCategoryId}
								>
									{category.name}
								</option>
							))}
						</select>
					</div>

					<div className="form__field">
						<label
							htmlFor="gamingHistoryPeriodSelect"
							className="label"
						>
							{t<string>("GAMING_HISTORY.FILTER.SELECT_PERIOD")}
						</label>
						<select
							id="gamingHistoryPeriodSelect"
							className="input input--base input--select"
							{...register("period")}
						>
							{betPeriods.map((betPeriod) => (
								<option
									key={betPeriod.value}
									value={betPeriod.value}
								>
									{t<string>(
										`GAMING_HISTORY.FILTER.PERIODS.${betPeriod.name}`
									)}
								</option>
							))}
						</select>
					</div>

					<CustomPeriodInput />

					<div>
						<input
							id="checkWin"
							className="input--check"
							type="checkbox"
							{...register("onlyWin")}
						/>
						<label htmlFor="checkWin">
							{t<string>(
								"GAMING_HISTORY.FILTER.DISPLAY_ONLY_WINNINGS"
							)}
						</label>
					</div>
				</div>

				<div className="card--primary__footer">
					<button className="btn btn--lrg btn--primary" type="submit">
						{t<string>("GAMING_HISTORY.FILTER.SUBMIT_BUTTON")}
					</button>
				</div>
			</form>
		</FormProvider>
	);
});

const CustomPeriodInput: FC = function CustomPeriodInput() {
	const { setValue, getValues } = useFormContext();
	const currentPeriod = useWatch({ name: "period" });
	const {
		gamingHistoryFilterStore: { rawData },
	} = useContext(GamingHistoryStoreContext);

	if (currentPeriod !== "custom") {
		return null;
	} else {
		setValue("from", rawData.from);
		setValue("to", rawData.to);
	}

	return (
		<div className="u-mar--bottom--xsml">
			<DatePicker
				label="MY_BETS.PAGE.FILTER_FORM.START_DATE"
				name="from"
				className="form__field"
				required
			/>

			<DatePicker
				label="MY_BETS.PAGE.FILTER_FORM.END_DATE"
				name="to"
				className="form__field"
				validate={{
					isBiggerThanStartDate: (value: string) => {
						const formattedStartDate = DateTime.fromISO(
							getValues("from")
						)
							.startOf("day")
							.toISO() as string;
						const formattedEndDate = DateTime.fromISO(value)
							.endOf("day")
							.toISO() as string;

						if (!formattedEndDate || !formattedStartDate) {
							return "COMMON.ERROR_MESSAGES.END_DATE_LOWER";
						}

						return (
							formattedEndDate > formattedStartDate ||
							"COMMON.ERROR_MESSAGES.END_DATE_LOWER"
						);
					},
				}}
				required
			/>
		</div>
	);
};

export default CasinoFilterForm;
