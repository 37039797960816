import { useFormContext } from "react-hook-form";
import { useLoading } from "@hooks";
import { useNotification } from "../../../../ui/common/hooks";
import useAccountLock from "./useAccountLock";
import {
	sortArray,
	sortCheckboxArray,
} from "@v2/helpers/account-settings/account-lock/utils";
import { DateTime } from "luxon";
import { useState } from "react";
import {
	OasisLockGetViewModel,
	OasisLockUpdateModel,
} from "@data-types/account-settings/OasisLockDto";
import { SelfExclusionInfoDto } from "@api-types/locks/SelfExclusionInfoDto";
import { logger } from "@state";

export default function useOasisLock() {
	const { getOasisBlockData, createOasisBlock } = useAccountLock();
	const { reset } = useFormContext();
	const [fetchedData, setFetchedData] = useState<OasisLockGetViewModel>(
		{} as OasisLockGetViewModel
	);
	const [lookups, setLookups] = useState({
		lockDurationTypes: [],
		lockReasonTypes: [],
	});
	const { showError } = useNotification();
	const { setIsLoading } = useLoading();

	const onInitialize = async () => {
		try {
			setIsLoading(true);
			const response = await getOasisBlockData();
			if (response != null) {
				setLookups({
					lockDurationTypes: sortArray(response.durationTypes),
					lockReasonTypes: sortCheckboxArray(response.blockReasons),
				});
				reset({
					"personalDetails.birthday": DateTime.now()
						.minus({ years: 18 })
						.toString(),
				});
				setFetchedData(response);
			}
		} catch (error) {
			logger.logError(error);
			showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.message}`);
		} finally {
			setIsLoading(false);
		}
	};

	const onSuccess = async (data: OasisLockUpdateModel) => {
		let reasons: string[] = [];
		const { lockDuration, password, personalDetails, reasonCodes } = data;
		if (typeof reasonCodes.disableOption === "boolean") {
			reasons = reasonCodes.fieldData as string[];
		} else {
			reasons = [reasonCodes.disableOption as string];
		}

		try {
			if (App.state.rootStore.userAuthStore.user == null) {
				throw "Expected user, got null";
			}

			const modelData: SelfExclusionInfoDto = {
				userAccountLockDurationTypeId: lockDuration,
				missingUserInfo: { ...personalDetails },
				exclusionReasonCodes: reasons,
				password: password,
				agencyId: App.state.rootStore.userAuthStore.user.agencyId,
				userId: App.state.rootStore.userAuthStore.user.id,
			};
			setIsLoading(true);
			await createOasisBlock(modelData);
			reset();
		} catch (error) {
			console.log(error);
			logger.logError(error);
			reset();
			showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.message}`);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		onSuccess,
		onInitialize,
		lookups,
		fetchedData,
	};
}
