import React from "react";
import { observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, FormButton } from "@v2/components/desktop/shared/buttons";
import { useForcePasswordChange } from "@v2/hooks/membership";
import { AccountActivationError } from "../account-activation";
import { FormInputField, PasswordInput } from "../../shared/inputs";
import { useFieldValidation } from "@v2/helpers/shared";
import { passwordValidationPattern } from "@gp/common";
import InfoMessageComponent from "@components-shared/InfoMessageComponent";

export default observer(function ForcePasswordChangeForm() {
	const { t } = useTranslation();
	const {
		handlePwdReset,
		onCancel,
		forcePasswordChangeData,
		hasError,
		clearError,
	} = useForcePasswordChange();

	const { handleSubmit, getValues } = useFormContext();

	useFieldValidation("password", "newPasswordConfirm");

	if (hasError) {
		return (
			<AccountActivationError
				// @ts-expect-error
				forcePasswordChangeError={hasError}
				setForcePasswordChangeError={clearError}
			/>
		);
	}

	return (
		<>
			<form
				id="forcePasswordForm"
				className="form card--primary"
				onSubmit={handleSubmit(handlePwdReset)}
			>
				<div className="card--primary__header">
					<div className="title--primary">
						{t<string>(
							"MEMBERSHIP.FORCE_PASSWORD_CHANGE.TITLE_FPC"
						)}
					</div>
				</div>

				<div className="card--primary__body">
					<InfoMessageComponent
						type="note"
						message={t<string>(
							"MEMBERSHIP.FORCE_PASSWORD_CHANGE.FPC_DESCRIPTION"
						)}
						addClassName="u-mar--bottom--big"
					/>

					<div className="form__group row">
						<FormInputField
							className="col col-sml-12 form__field"
							name="userCardNumber"
							label="MEMBERSHIP.ACCOUNT_ACTIVATION.USERNAME"
							autoComplete="none"
							readOnly
						/>
					</div>

					<div className="row u-mar--bottom--xsml">
						<PasswordInput
							name="password"
							className="col col-sml-12 form__field"
							label="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_PASSWORD_LABEL"
							placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_PASSWORD_PLACEHOLDER"
							pattern={{
								value: passwordValidationPattern,
								message:
									"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
							}}
							autoComplete="new-password"
						/>
					</div>

					<div className="form__group row">
						<PasswordInput
							name="newPasswordConfirm"
							className="col col-sml-12 form__field"
							label="MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRM_NEW_PASSWORD_LABEL"
							placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRM_NEW_PASSWORD_PLACEHOLDER"
							pattern={{
								value: passwordValidationPattern,
								message:
									"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PASS_CHARACTERS_TW",
							}}
							validate={{
								isEqual: (value: string) =>
									value === getValues("password") ||
									"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.DOES_NOT_MATCH_PWD",
							}}
							required={true}
							popoverMsg={t<string>(
								"MEMBERSHIP.FORCE_PASSWORD_CHANGE.REPEAT_PWD_INFO"
							)}
							autoComplete="new-password"
						/>
					</div>
				</div>

				<div className="card--primary__footer">
					<FormButton
						btnText="MEMBERSHIP.ACCOUNT_ACTIVATION.SAVE_BUTTON"
						className="card--primary__footer__btn btn btn--lrg btn--primary"
					/>
					<Button
						className="card--primary__footer__btn btn btn--lrg btn--secondary--inverted"
						btnText="MEMBERSHIP.BETTING_ACTIVITY.BUTTON_CANCEL"
						onClick={onCancel}
						type="button"
					/>
				</div>
			</form>
		</>
	);
});
