import { useLoading } from "@hooks";
import {
	forgotPasswordServiceInstance,
	RequestPasswordRecoveryFormData,
} from "@v2/data-access/membership/forgot-password";
import { useNotification } from "@v2/hooks/shared";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useFormContext } from "react-hook-form";

export default function useForgotPasswordForm(params: {
	setIsForgotPasswordSuccess: (value: boolean) => void;
}) {
	const { showError } = useNotification();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const { setIsLoading } = useLoading();

	const { reset, setValue } = useFormContext();

	const onInitialize = () => {
		const params = new URLSearchParams(window.location.search);
		if (params.get("username") && dateIsValid(params.get("dob"))) {
			const validDate = new Date();
			validDate.setFullYear(validDate.getFullYear() - 18);
			// @ts-expect-error
			if (new Date(params.get("dob")) < validDate) {
				//set input values to query values, validate and trigger dirty and touch so formButton is enabled
				setValue("userName", params.get("username"), {
					shouldValidate: true,
					shouldDirty: true,
					shouldTouch: true,
				});

				setValue("dateOfBirth", params.get("dob"), {
					shouldValidate: true,
					shouldDirty: true,
					shouldTouch: true,
				});
			}
		}
	};

	function dateIsValid(date: string | null) {
		if (date == null) {
			return false;
		}
		const dateFormat = /^\d{4}\-\d{2}\-\d{2}$/;
		const newDate = Date.parse(date);
		return dateFormat.test(date) && !isNaN(newDate);
	}

	const onFormSubmit = async (formData: RequestPasswordRecoveryFormData) => {
		try {
			setIsLoading(true);
			const recaptchaToken = await executeRecaptcha?.(
				"requestPasswordRecovery"
			);
			const response =
				await forgotPasswordServiceInstance.requestPasswordRecovery(
					formData,
					recaptchaToken
				);
			if (response.success) {
				params.setIsForgotPasswordSuccess(true);
			}
		} catch (error) {
			console.log(error);
			reset();
			if (error?.error?.options) {
				showError([
					{
						message:
							"MEMBERSHIP.FORGOT_PASSWORD." +
							error?.error?.message,
						options: { 0: error?.error?.options },
					},
				]);
			} else {
				showError(
					"MEMBERSHIP.FORGOT_PASSWORD." + error?.error?.message
				);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return {
		onFormSubmit,
		onInitialize,
	};
}
