import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { OfferStoreContext } from "@gp/components";

export type SideMenuLinkItem = {
	translationKey: string;
	iconClassExtension: string;
	countKey?: string;
	url: string;
	callOnUserAction?: () => void;
};

export const SideMenuLink = observer(function SideMenuLink(props: {
	item: SideMenuLinkItem;
}) {
	const {
		item: {
			translationKey,
			iconClassExtension,
			countKey,
			url,
			callOnUserAction, // Function to execute for this specific item
		},
	} = props;

	// @ts-expect-error
	const { selectedSport, isOfferMenuOpen, isExpandDisabled } =
		useContext(OfferStoreContext);
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const isActive =
		(!url.includes("event") && url === pathname) ||
		(url.includes("events") && url === pathname && selectedSport == null);

	const toggleClasses = classNames(
		"nav--secondary__toggle u-icon u-icon--tny",
		{
			"u-icon--caret--right u-color--core--snow":
				!isOfferMenuOpen && isActive,
			"u-icon--close u-color--core--snow":
				isOfferMenuOpen && !isExpandDisabled && isActive,
			"u-icon--caret--right": !isOfferMenuOpen && !isActive,
			"u-icon--close": isOfferMenuOpen && !isExpandDisabled && !isActive,
		}
	);

	return (
		<li className="nav--secondary__item">
			<button
				className={classNames("nav--secondary__link", {
					"is-selected": isActive,
				})}
				onClick={() => {
					App.state.redirect(url, true);
					if (typeof callOnUserAction === "function") {
						callOnUserAction();
					}
				}}
			>
				<i
					className={`nav--secondary__icon u-icon u-icon--lrg u-icon--${iconClassExtension}`}
				/>
				<div className="nav--secondary__title">
					{t<string>(translationKey)}
				</div>
				<SideMenuLinkCount countKey={countKey} />

				{iconClassExtension == "all" && <i className={toggleClasses} />}
			</button>
		</li>
	);
});

export const SideMenuLinkCount = observer(function SideMenuLinkCount(
	props: any
) {
	const { countKey } = props;

	// @ts-expect-error
	const count = useContext(OfferStoreContext)[countKey];

	if (!count || count <= 0) {
		return null;
	}

	return (
		<span className="nav--secondary__counter counter counter--rounded counter--rounded--base counter--secondary counter--sup">
			{count}
		</span>
	);
});
