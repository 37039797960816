export enum ETermRegParam {
	USERNAME = "username",
	SHOP_ID = "shopId",
	ERROR = "error",
	ERROR_DESCRIPTION = "error_description",
}

export enum ETermRegErrorParam {
	ACCESS_DENIED = "access_denied",
}

export enum ETermRegErrorDescParam {
	USER_ABORT = "mitid_user_aborted",
	P_TO_B_ABORT = "private_to_business_user_aborted",
}
