import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { urlLanguageDetector } from "./urlLanguageDetector";

const languageDetector = new LanguageDetector();
languageDetector.addDetector(urlLanguageDetector);

i18n.use(languageDetector)
	.use(initReactI18next)
	.use(
		resourcesToBackend((language: string, namespace: string) => {
			if (namespace === "specific") {
				return import(
					`../../ui/public/locales/${language}/translation.${WEBPACK_THEME_NAME}.json`
				);
			}
			return import(
				`../../ui/public/locales/${language}/translation.json`
			);
		})
	)
	.init({
		ns: ["translation", "specific"],
		defaultNS: "translation",
		fallbackLng: "en",
		debug: false,
		load: "currentOnly",
		react: {
			wait: true,
			bindI18n: false,
			bindStore: false,
		},
		preload: [WEBPACK_DEFAULT_CULTURE],
		detection: {
			lookupCookie: "lng",
			caches: ["cookie"],
			cookieMinutes: 525600,
			order: ["urlLanguageDetector"],
		},
	});

export default i18n;
